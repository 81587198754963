import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey } from "../SkuLib";
var NcpConfigBuilder = function (productConfig, sku, productName, productVariant) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    return {
        skuData: {
            sku: sku,
            decodedSKU: decodedSKU,
        },
        assets: {
            global: productConfig.ASSETS.globalAssets,
            product: productConfig.ASSETS.productAssets[productName][productVariant],
        },
        quantitiesSpec: productConfig.QUANTITIES,
        const: productConfig.CONSTS,
        dimensions: productConfig.DIMENSIONS[productName],
        productionTimes: productConfig.PRODUCTION_TIMES[productName][productVariant],
        variant: productConfig.VARIANTS[productName],
        weights: productConfig.WEIGHTS[productName][productVariant],
        ecoProperties: productConfig.ECO_PROPERTIES[productName],
    };
};
export default NcpConfigBuilder;
