import _throttle from "lodash/throttle"

import {
  NoPrintZoneCreator,
  NoPrintZoneOpacity,
  NoPrintZoneHighlightedOpacity,
} from "./no-print-zone-creator"
import {
  PackhelpObject,
  VirtualDielineSpace,
} from "../../object-extensions/packhelp-objects"
import type VirtualDielineEditor from "../../virtual-dieline-editor"
import { EditableSpaceConfig } from "../../../../../libs/products-render-config/types"
import { CanvasEvent } from "../../../../../stores/_events/domain-events"
import { Intersection } from "../../../collision-detection/intersection"

export class NoPrintModule {
  private readonly throttledToggleNoPrintZones: (event: any) => void

  constructor(private readonly virtualDielineEditor: VirtualDielineEditor) {
    this.throttledToggleNoPrintZones = _throttle(this.toggleNoPrintZones, 100)
  }

  public async createNoPrintZone(
    spaceConfigs: EditableSpaceConfig[]
  ): Promise<void> {
    this.clearExistingNoPrintZones()
    const noPrintZones = this.getNoPrintZonesFromVd()

    if (!noPrintZones || noPrintZones.length === 0) {
      return
    }

    const noPrintZoneCreator = new NoPrintZoneCreator(
      this.virtualDielineEditor.virtualDieline,
      this.virtualDielineEditor.dielineNavigator,
      this.virtualDielineEditor.eventEmitter
    )

    await Promise.all(
      spaceConfigs.map(async (config) => {
        noPrintZones.map(
          async (noPrintZone: VirtualDielineSpace, index: number) => {
            this.virtualDielineEditor.addOnCanvas(
              await noPrintZoneCreator.create(
                config.spaceId,
                noPrintZone,
                index
              )
            )
          }
        )

        this.virtualDielineEditor.fabricCanvas.requestRenderAll()
      })
    )
  }

  public switchOnEvents() {
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.objectMoving,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.objectScaling,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.objectRotating,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.textChanged,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.objectRestyled,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.selectionCreated,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.selectionUpdated,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.selectionCleared,
      this.offNoPrintZones
    )
  }

  public switchOffEvents() {
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.objectMoving,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.objectScaling,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.objectRotating,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.textChanged,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.objectRestyled,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.selectionCreated,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.selectionUpdated,
      this.throttledToggleNoPrintZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.selectionCleared,
      this.offNoPrintZones
    )
  }

  private toggleNoPrintZones = (event) => {
    const obj = event.target

    if (!obj || !obj.canvas) {
      this.toggleOpacityCurrentSpaceNoPrintZones(false)

      return
    }

    const isNoPrintZoneTouched = this.isObjectInNoPrintZone(obj)
    this.toggleOpacityCurrentSpaceNoPrintZones(isNoPrintZoneTouched)
  }

  private isObjectInNoPrintZone(obj: PackhelpObject): boolean {
    const noPrintZones = this.getNoPrintZonesFromCanvas()

    if (noPrintZones.length === 0 || obj.maskParentId) {
      return false
    }

    const activeSpaceId =
      this.virtualDielineEditor.dielineNavigator.getActiveSpaceId()
    obj.setCoords()

    return noPrintZones.some((noPrintZone) => {
      return (
        noPrintZone.originSpaceArea === activeSpaceId &&
        Intersection.intersect(obj, noPrintZone)
      )
    })
  }

  private toggleOpacityCurrentSpaceNoPrintZones(isActive: boolean) {
    const noPrintZonesOnSpace = this.getNoPrintZonesFromCanvas().filter(
      (obj) =>
        obj.originSpaceArea ===
        this.virtualDielineEditor.dielineNavigator.getActiveSpaceId()
    )

    noPrintZonesOnSpace.forEach((noPrintZone) => {
      noPrintZone.set({
        opacity: isActive ? NoPrintZoneHighlightedOpacity : NoPrintZoneOpacity,
      })
    })
  }

  private offNoPrintZones = () => {
    this.toggleOpacityCurrentSpaceNoPrintZones(false)
  }

  private getNoPrintZonesFromVd(): VirtualDielineSpace[] {
    return this.virtualDielineEditor.virtualDieline
      .getObjects()
      .filter((obj) => obj.id && obj.id.includes("no_print"))
  }

  private getNoPrintZonesFromCanvas(): PackhelpObject[] {
    return this.virtualDielineEditor.fabricCanvas
      .getObjects()
      .filter((obj) => obj.id && obj.id.includes("no_print"))
  }

  private clearExistingNoPrintZones() {
    const noPrintZones = this.getNoPrintZonesFromCanvas()
    if (noPrintZones.length === 0) {
      return
    }

    noPrintZones.forEach((noPrintZone: PackhelpObject) => {
      this.virtualDielineEditor.fabricCanvas.remove(noPrintZone)
    })

    this.virtualDielineEditor.fabricCanvas.requestRenderAll()
  }
}
