import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey, isProductTypeCustom, } from "../SkuLib";
import CustomPaperMailerProduct from "../CustomProducts/CustomPaperMailerProduct";
import PaperMailerProduct from "../Products/PaperMailerProduct/PaperMailerProduct";
import PaperMailerVariantManager from "../Products/PaperMailerProduct/behaviorsComposers/PaperMailerVariantManager";
function PaperMailerProductFactory(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    var productVariant = PaperMailerVariantManager.findVariant(decodedSKU.productName);
    if (isProductTypeCustom(sku)) {
        return new CustomPaperMailerProduct(sku, decodedSKU.productName);
    }
    else {
        return new PaperMailerProduct(sku, decodedSKU.productName, productVariant);
    }
}
export default PaperMailerProductFactory;
