import { AxiosResponse } from "axios"
import isNull from "lodash/isNull"

import { PackhelpApi } from "../../../packhelp-api"
import {
  Certificate,
  GetCertificatesQueryOptions,
  CertificateType,
  ServiceTypes,
} from "../certificates.api.types"
import { CertificateDto, GetCertificatesResponse } from "./utils/types"
import { transformCertificateToServiceNotation } from "./utils/helpers"

export class GetCertificatesQuery {
  private readonly priceToQtyMap: Record<number, number> = {}

  constructor(
    private readonly api: PackhelpApi,
    private readonly options: GetCertificatesQueryOptions
  ) {
    Object.entries(options.pricing)
      .filter(([_qty, price]) => !!price)
      .forEach(([qty, price]) => (this.priceToQtyMap[price] = Number(qty)))
  }

  public async call(): Promise<Certificate[]> {
    const responseRaw = await this.fetchCertificates()
    const responseTransformedToFormat =
      this.transformResponseToFormat(responseRaw)

    return responseTransformedToFormat.data.data.map(
      this.mapItemToFormat.bind(this)
    )
  }

  private fetchCertificates = async (): Promise<
    AxiosResponse<GetCertificatesResponse, any>
  > => {
    return this.api.ax.get<GetCertificatesResponse>(
      `v2/products/value_added_services`,
      {
        params: {
          region: this.options.region,
          tax_country_iso: this.options.taxCountryISO,
          product_prices: Object.values(this.options.pricing),
          filters: {
            product_type: this.options.productName,
            service_type: ServiceTypes.LineItemBased,
          },
        },
        ...this.api.withAuthToken(),
      }
    )
  }

  private transformResponseToFormat = (
    response: AxiosResponse<GetCertificatesResponse, any>
  ): AxiosResponse<GetCertificatesResponse, any> => {
    const { data } = response.data
    data.forEach((service) => {
      service.id = transformCertificateToServiceNotation(service.id)
      service.attributes.type = transformCertificateToServiceNotation(
        service.attributes.type
      ) as CertificateType
    })

    const fscFiltered = data.filter(
      (certificateDto) => certificateDto.id === CertificateType
    )

    response.data.data = fscFiltered

    return response
  }

  private mapItemToFormat(item: CertificateDto): Certificate {
    return new Certificate(
      item.attributes.variant_type,
      Number(item.attributes.variant_id),
      item.attributes.type,
      {
        taxRate: isNull(item.attributes.tax_rate)
          ? undefined
          : Number(item.attributes.tax_rate),
        prices: {
          [this.priceToQtyMap[item.attributes.base_price]]: {
            netPrice: item.attributes.net_price,
            grossPrice: isNull(item.attributes.gross_price)
              ? undefined
              : Number(item.attributes.gross_price),
          },
        },
      }
    )
  }
}
