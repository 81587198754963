import React, { useRef } from "react"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import ColorPicker, { ColourPickerProps } from "../../ColourPicker/ColourPicker"
import { PickerToggle } from "../../../atoms/PickerToggle/PickerToggle"
import { useTooltip } from "../../../../hooks/useTooltip"

import styles from "./ColorConfigurator.module.scss"
import PantonePicker from "../../PantonePicker/PantonePicker"
import {
  ColorListArray,
  OnApplyColor,
  PantonePickerColorObject,
} from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { Colour } from "../../../../../libs/value-objects/colour"
import { colorsListDemo } from "../../ColourPicker/colors-list"
import { PantoneColorsDatabase } from "../../../../../modules/colors/pantone-colors-database"
import {
  AvailableColourModes,
  PantoneColorsPreset,
} from "../../../../../libs/products-render-config/types"
import { useContainer } from "../../../../../_containers-react/_editor-app-hooks"

const defaultColorsList = colorsListDemo.map((color) => {
  return {
    color: new Colour(color.color).getHex(),
    uid: color.uid,
    description: "muu",
    pantoneName: new Colour(color.color).getPantoneName(),
  }
})

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
interface ColorConfiguratorProps
  extends Omit<
    ColourPickerProps,
    "selectedColor" | "colorsList" | "onDoneClick"
  > {
  itemName?: React.ReactNode
  preventHideClickOutside?: boolean
  selectedColor: Colour | undefined
  onClick?: () => void
  onDoneClick?: () => void
  colorsList?: ColorListArray[]
}

interface PantoneConfiguratorProps {
  selectedColor: Colour | undefined
  onColorSelect: (color: Colour) => void
  onBack: (e) => void
  onDone: () => void
  pantoneColorsPreset?: PantoneColorsPreset
}
export const PantoneConfigurator = ({
  selectedColor,
  onColorSelect,
  onBack,
  onDone,
  pantoneColorsPreset,
}: PantoneConfiguratorProps) => {
  const pantoneDb = new PantoneColorsDatabase()

  const getSelectedPantoneColor = (): PantonePickerColorObject | undefined => {
    if (!selectedColor) return undefined
    return pantoneDb.getByColour(selectedColor)
  }

  return (
    <PantonePicker
      selectedColor={getSelectedPantoneColor()}
      onApplyColor={(pantoneColor: OnApplyColor) => {
        onColorSelect(new Colour(pantoneColor.color, pantoneColor.pantoneName))
      }}
      onBackClick={onBack}
      onDoneClick={onDone}
      pantoneColorsPreset={pantoneColorsPreset}
    />
  )
}

const HexConfigurator = ({
  selectedColor,
  onColorSelect,
  onBack,
  onDone,
  colorsList = defaultColorsList,
}: {
  selectedColor: Colour | undefined
  onColorSelect: (color: Colour) => void
  onBack: (e) => void
  onDone: () => void
  colorsList?: ColorListArray[]
}) => {
  return (
    <ColorPicker
      onDoneClick={onDone}
      onColorSelect={onColorSelect}
      onBackClick={onBack}
      selectedColor={selectedColor ? selectedColor.getHex() : undefined}
      colorsList={colorsList}
    />
  )
}

export const ColorConfigurator = ({
  onBackClick,
  selectedColor,
  colorsList,
  itemName,
  onColorSelect,
  onClick,
  onDoneClick,
}: ColorConfiguratorProps) => {
  const wrapperRef = useRef<any>(null)
  const { openTooltip, closeTooltip, isOpen, Tooltip } = useTooltip({
    offsetLeft: -10,
    offsetTop: 50,
  })

  const onDone = () => {
    closeTooltip()
    onDoneClick && onDoneClick()
  }

  const onBack = (e) => {
    closeTooltip()
    onBackClick(e)
  }

  const onPickerClick = (e) => {
    isOpen ? closeTooltip(e) : openTooltip(e)

    if (onClick) {
      onClick()
    }
  }

  const [designAndProductDriverContainer] =
    useContainer().designAndProductDriver

  if (!designAndProductDriverContainer) {
    return null
  }
  const { productDriver } = designAndProductDriverContainer

  const { productRenderPilot } = productDriver.state
  const pantoneColorsPreset =
    productRenderPilot !== undefined
      ? productRenderPilot.getPantoneColorsPreset()
      : undefined

  const isMonoPantone =
    productRenderPilot.getColorMode() === AvailableColourModes.MONO_PANTONE

  return (
    <div className={styles.colorpicker_wrapper} ref={wrapperRef}>
      <div className={styles.editor_header__colorpicker_nav}>
        {itemName && (
          <Typography type={TType.Body15_350}>{itemName}</Typography>
        )}
        <PickerToggle
          background={selectedColor ? selectedColor.getHex() : undefined}
          onClick={onPickerClick}
          e2eTargetName="overrided-pattern-color"
          isHighlited={isOpen}
        />
      </div>
      <div className={styles.editor_header__colorpicker}>
        {isOpen && (
          <Tooltip>
            {isMonoPantone ? (
              <PantoneConfigurator
                selectedColor={selectedColor}
                onColorSelect={onColorSelect}
                onBack={onBack}
                onDone={onDone}
                pantoneColorsPreset={pantoneColorsPreset}
              />
            ) : (
              <HexConfigurator
                selectedColor={selectedColor}
                onColorSelect={onColorSelect}
                onBack={onBack}
                onDone={onDone}
                colorsList={colorsList}
              />
            )}
          </Tooltip>
        )}
      </div>
    </div>
  )
}
