import FiltersModule from "../../filters-module"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import FabricAssetsLoaderService from "../../../../../../libs/services/fabric-assets-loader-service"
import Colour from "../../../../../../libs/value-objects/colour"
import { PackhelpImage } from "../../../object-extensions/packhelp-objects"

export class SceneDecorationController {
  private image?: PackhelpImage

  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public async decorate(isZoomActive: boolean): Promise<void> {
    if (!this.canvasContainer) {
      return
    }

    const backgroundTextureUrl =
      this.vdEditor.productRenderPilot.getSceneBackgroundTextureUrl(
        this.vdEditor.editContext
      )

    if (isZoomActive || !backgroundTextureUrl) {
      this.canvasContainer.style.backgroundImage = ""

      return
    }

    this.image = await FabricAssetsLoaderService.loadAsset(
      backgroundTextureUrl,
      {}
    )

    this.applyBackgroundImage()
  }

  public async refresh() {
    this.applyBackgroundImage()
  }

  private applyBackgroundImage(): void {
    if (!this.image || !this.canvasContainer) {
      return
    }

    const backgroundColor = this.getBackgroundColor()

    if (!this.vdEditor.texture && backgroundColor) {
      FiltersModule.setBlendColorFilter(this.image, backgroundColor)
    }

    this.canvasContainer.style.backgroundImage = `url(${this.image.toDataURL(
      {}
    )})`
  }

  private getBackgroundColor(): Colour | undefined {
    const space = this.vdEditor.dielineNavigator.getActiveVirtualDielineSpace()

    if (!space?.fill) {
      return
    }

    return new Colour(space.fill as string)
  }

  private get canvasContainer(): HTMLElement | null {
    const canvas = this.vdEditor.fabricCanvas

    return canvas.wrapperEl?.parentElement
  }
}
