import React, { FunctionComponent } from "react"
import cxBinder from "classnames/bind"
import styles from "./Switch.module.scss"
import { ReactComponent as IconClose } from "../../assets/icons/UI/Close.svg"
import { CheckIcon } from "../../assets/_svgr/icons/Check"

const cx = cxBinder.bind(styles)

export interface SwitchProps extends React.HTMLProps<HTMLInputElement> {
  disabled?: boolean
  checked: boolean
  onChange?: () => void
  e2eTarget?: string
  e2eTargetName?: string
}

const Switch: FunctionComponent<SwitchProps> = (props) => {
  return (
    <div className={styles.wrapper}>
      <input
        type="checkbox"
        className={styles.input}
        e2e-target={props.e2eTarget}
        e2e-target-name={props.e2eTargetName}
        {...props}
      />
      <span className={styles.mark}>
        <span className={styles.toggler}>
          <CheckIcon className={cx("icon", "icon--check")} />
          <IconClose className={cx("icon", "icon--close")} />
        </span>
      </span>
    </div>
  )
}

export { Switch, Switch as default }
