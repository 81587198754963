import React from "react"
import { observer } from "mobx-react-lite"
import cxBinder from "classnames/bind"

import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import Tab from "../../../Tabs"
import { IconButton } from "../../../../atoms/IconButton/IconButton"

import { useContainer } from "../../../../../../_containers-react/_editor-app-hooks"
import { EditorToolbarItem } from "../../../../../../stores/editor-toolbar-store"
import { DotAlert } from "../../../../atoms/dot-alert/dot-alert"
import _kebabCase from "lodash/kebabCase"

import styles from "./EditorToolbarElement.module.scss"

const cx = cxBinder.bind(styles)

export interface EditorToolbarElementProps {
  name: EditorToolbarItem
  preventActiveState?: boolean
  isDisabled?: boolean
  Icon: React.ElementType
  IconActive?: React.ElementType
  label?: JSX.Element
  onClick?: () => void
  hasError?: boolean
  hasTopSeparator?: boolean
}

export const EditorToolbarElement = observer(
  ({
    name,
    label,
    onClick,
    Icon,
    IconActive,
    preventActiveState,
    isDisabled,
    hasError = false,
    hasTopSeparator,
  }: EditorToolbarElementProps) => {
    const [uiContainer] = useContainer().ui

    if (!uiContainer) {
      return null
    }

    const { editorToolbarStore } = uiContainer
    const isActive =
      editorToolbarStore.isTabSelected(name) ||
      editorToolbarStore.isSubTabSelected(name)
    const RenderedIcon = isActive && IconActive ? IconActive : Icon
    const iconWrapped = () => (
      <div className={styles.editor_menu__element_icon_wrapper}>
        <RenderedIcon />
      </div>
    )
    const e2eTargetName = `editor-toolbar-${_kebabCase(name)}`

    return (
      <Tab.Switch
        isDisabled={isDisabled}
        name={name}
        className={cx({
          editor_menu__element: true,
          selected: isActive,
          "editor_menu__element--top-separator": hasTopSeparator,
        })}
        tabComponent={
          <IconButton
            isActive={!preventActiveState && isActive}
            Icon={iconWrapped}
            onClick={onClick}
            e2eTargetName={e2eTargetName}
          >
            {hasError && (
              <div className={styles.alert_wrapper}>
                <DotAlert />
              </div>
            )}
            {label && <Typography type={TType.Body10_350}>{label}</Typography>}
          </IconButton>
        }
      />
    )
  }
)
