import React from "react"
import { observer } from "mobx-react-lite"
import { EditorToolbarItem } from "../../../../stores/editor-toolbar-store"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"
import _kebabCase from "lodash/kebabCase"

import styles from "./Tabs.module.scss"

interface TabProps {
  name: EditorToolbarItem
  isDisabled?: boolean
  className?: string
  // Optional custom toggle component
  tabComponent?: React.ReactNode
}

interface TabContentProps {
  name: EditorToolbarItem
}

export const Switch = ({
  isDisabled = false,
  name,
  className,
  tabComponent,
}: TabProps) => {
  const [uiContainer] = useContainer().ui

  if (!uiContainer) {
    return null
  }
  const { editorToolbarStore } = uiContainer

  const handleSelectTab = (nextTab: EditorToolbarItem) => () => {
    !isDisabled && editorToolbarStore.toggleTab(nextTab)
  }

  return (
    <li className={className} onClick={handleSelectTab(name)}>
      {tabComponent ? tabComponent : name}
    </li>
  )
}

export const Content = observer(
  React.forwardRef<HTMLDivElement, React.PropsWithChildren<TabContentProps>>(
    ({ name, children }, ref) => {
      const [uiContainer] = useContainer().ui

      if (!uiContainer) {
        return null
      }
      const { editorToolbarStore } = uiContainer

      const e2eTargetName = _kebabCase(name)

      return editorToolbarStore.isTabSelected(name) ? (
        <div
          className={styles.container}
          e2e-target="toolbar-tab-container"
          e2e-target-name={e2eTargetName}
          ref={ref}
        >
          {children}
        </div>
      ) : null
    }
  )
)
