import bagProductSpec from "../../../../source/bagProductSpec/bagProductSpec";
import ConfigBuilder from "../../configBuilders/ConfigBuilder";
// Global product behaviors composers
import ProductionTimeManager from "../../behaviorsComposers/ProductionTimeManager";
import GrammagesManager from "../../behaviorsComposers/GrammagesManager";
import AssetsManager from "../../behaviorsComposers/AssetsManager";
import WizzardManager from "../../behaviorsComposers/WizzardManager";
import SiblingsManager from "../../behaviorsComposers/SiblingsManager";
import QuantitiesManager from "../../behaviorsComposers/QuantitiesManager";
// Paper bags specific behaviors composers
import PaperBagTranslationsManager from "./behaviorsComposers/PaperBagTranslationsManager";
import PaperBagDimensionsManager from "./behaviorsComposers/PaperBagDimensionsManager";
import PaperBagAddonsManager from "./behaviorsComposers/PaperBagAddonsManager";
import PaperBagVariantManager from "./behaviorsComposers/PaperBagVariantManager";
import PaperBagRenderConfigManager from "./behaviorsComposers/PaperBagRenderConfigManager";
import PaperBagEditConfigManager from "./behaviorsComposers/PaperBagEditConfigManager";
import PaperBagWeightManager from "./behaviorsComposers/PaperBagWeightManager";
import ProductFactory from "../../ProductFactory";
var PaperBagProduct = /** @class */ (function () {
    function PaperBagProduct(sku, productName, productVariant) {
        this.config = this.builder(bagProductSpec, sku, productName, productVariant);
        this.assetsManager = new AssetsManager(this);
        this.variantManager = new PaperBagVariantManager(this);
        this.grammagesManager = new GrammagesManager(this);
        this.productionTimeManager = new ProductionTimeManager(this);
        this.weightsManager = new PaperBagWeightManager(this);
        this.wizzardManager = new WizzardManager(this);
        this.editConfigManager = new PaperBagEditConfigManager(this);
        this.translationsManager = new PaperBagTranslationsManager(this);
        this.addonsManager = new PaperBagAddonsManager(this);
        this.dimensionsManager = new PaperBagDimensionsManager(this);
        this.renderConfigManager = new PaperBagRenderConfigManager(this);
        this.siblingsManager = new SiblingsManager(this);
        this.quantitiesManager = new QuantitiesManager(this);
    }
    PaperBagProduct.prototype.builder = function (spec, sku, productName, productVariant) {
        return ConfigBuilder(spec, sku, productName, productVariant);
    };
    PaperBagProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    PaperBagProduct.prototype.getExtraTechnicalDetails = function () {
        return [
            {
                titleId: "box-configurator.box-dimensions.material-color",
                iconSvg: "v5-material-color",
                descId: "box-configurator.box-dimensions.paper-bag-materials",
            },
            {
                titleId: "box-configurator.box-dimensions.recycled-content",
                iconSvg: "v5-recycling-ico",
                desc: "100%",
            },
            {
                titleId: "box-configurator.box-dimensions.features",
                iconSvg: "v5-handle-bag-ico",
                descId: "box-configurator.box-dimensions.twisted-handle",
            },
        ];
    };
    PaperBagProduct.prototype.getEditorBackgroundPath = function () {
        var productCodeName = this.variantManager.getCodeName();
        var material = "white";
        if (this.variantManager.getMaterial() ===
            this.config.const.PRINT_MATERIAL_PAPER_NATURAL) {
            material = "eco";
        }
        var name = "/paper_bag_".concat(productCodeName, "_").concat(material, ".png");
        return ("https://packhelp-files.s3.amazonaws.com/app/editor/backgrounds/bags" +
            name);
    };
    PaperBagProduct.prototype.createBaseProductSku = function () {
        return this.addonsManager.setDefaultAddons();
    };
    PaperBagProduct.prototype.getBaseProduct = function () {
        return ProductFactory(this.createBaseProductSku());
    };
    return PaperBagProduct;
}());
export default PaperBagProduct;
