var _a, _b, _c, _d, _e, _f, _g, _h;
import POLY_MAILER_CONSTS from "../consts/polyMailerConsts";
import { PolyMailerBioProductVariant, PolyMailerProductVariant, PolyMailerRecycledProductVariant, } from "../consts/ProductVariant";
var assetsPath = "/product-key-visuals/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var customPath = "/product-custom-dimensions/";
var sizeAssetsPolyMailer = {
    Y43: {
        desktop: desktopPath + "Y43.png",
        mobile: mobilePath + "Y43.png",
    },
    Y60: {
        desktop: desktopPath + "Y60.png",
        mobile: mobilePath + "Y60.png",
    },
    Y80: {
        desktop: desktopPath + "Y80.png",
        mobile: mobilePath + "Y80.png",
    },
    Y90: {
        desktop: desktopPath + "Y90.png",
        mobile: mobilePath + "Y90.png",
    },
    Y100: {
        desktop: desktopPath + "Y100.png",
        mobile: mobilePath + "Y100.png",
    },
    custom43: {
        desktop: "".concat(customPath, "PolyMailer/Desktop/Predefined/custom43.png"),
        mobile: "".concat(customPath, "PolyMailer/Mobile/Predefined/custom43.png"),
    },
    custom60: {
        desktop: "".concat(customPath, "PolyMailer/Desktop/Predefined/custom60.png"),
        mobile: "".concat(customPath, "PolyMailer/Mobile/Predefined/custom60.png"),
    },
    custom80: {
        desktop: "".concat(customPath, "PolyMailer/Desktop/Predefined/custom80.png"),
        mobile: "".concat(customPath, "PolyMailer/Mobile/Predefined/custom80.png"),
    },
    custom90: {
        desktop: "".concat(customPath, "PolyMailer/Desktop/Predefined/custom90.png"),
        mobile: "".concat(customPath, "PolyMailer/Mobile/Predefined/custom90.png"),
    },
    custom100: {
        desktop: "".concat(customPath, "PolyMailer/Desktop/Predefined/custom100.png"),
        mobile: "".concat(customPath, "PolyMailer/Mobile/Predefined/custom100.png"),
    },
    custom: {
        desktop: customPath + "PolyMailer/Desktop/Custom/{locale}.png",
        mobile: customPath + "PolyMailer/Mobile/Custom/{locale}.png",
    },
};
var sizeAssetsBioPolyMailer = {
    Y60: {
        desktop: desktopPath + "Y60_bio.png",
        mobile: mobilePath + "Y60_bio.png",
    },
    Y80: {
        desktop: desktopPath + "Y80_bio.png",
        mobile: mobilePath + "Y80_bio.png",
    },
    Y90: {
        desktop: desktopPath + "Y90_bio.png",
        mobile: mobilePath + "Y90_bio.png",
    },
    custom43: {
        desktop: "".concat(customPath, "BioPolyMailer/Desktop/Predefined/custom43.png"),
        mobile: "".concat(customPath, "PolyMailer/Mobile/Predefined/custom43.png"),
    },
    custom60: {
        desktop: "".concat(customPath, "BioPolyMailer/Desktop/Predefined/custom60.png"),
        mobile: "".concat(customPath, "PolyMailer/Mobile/Predefined/custom60.png"),
    },
    custom80: {
        desktop: "".concat(customPath, "BioPolyMailer/Desktop/Predefined/custom80.png"),
        mobile: "".concat(customPath, "PolyMailer/Mobile/Predefined/custom80.png"),
    },
    custom90: {
        desktop: "".concat(customPath, "BioPolyMailer/Desktop/Predefined/custom90.png"),
        mobile: "".concat(customPath, "PolyMailer/Mobile/Predefined/custom90.png"),
    },
    custom100: {
        desktop: "".concat(customPath, "BioPolyMailer/Desktop/Predefined/custom100.png"),
        mobile: "".concat(customPath, "PolyMailer/Mobile/Predefined/custom100.png"),
    },
    custom: {
        desktop: customPath + "BioPolyMailer/Desktop/Custom/{locale}.png",
        mobile: customPath + "BioPolyMailer/Mobile/Custom/{locale}.png",
    },
};
var COLOR_MODES_IMAGES_PATHS = (_a = {},
    _a[POLY_MAILER_CONSTS.PRINT_MODE_MONO_PANTONE_ONE_SIDE] = "v5-print-one-site",
    _a[POLY_MAILER_CONSTS.PRINT_MODE_MONO_PANTONE_TWO_SIDES] = "v5-print-two-sites",
    _a);
var MATERIALS_IMAGES_PATHS = (_b = {},
    _b[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_TRANSPARENT] = "/product-material-picker/swatch_transparent.png",
    _b[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_WHITE] = "/product-material-picker/swatch_white.png",
    _b[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_BLACK] = "/product-material-picker/swatch_black.png",
    _b[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_WHITE_BLACK_INSIDE] = "/product-material-picker/swatch_white.png",
    _b);
var EditorImage;
(function (EditorImage) {
    EditorImage["FOIL_BLACK"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-plastic-black.png";
    EditorImage["FOIL_TRANSPARENT"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-plastic-transparent.png";
    EditorImage["FOIL_WHITE"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-plastic-white.png";
    EditorImage["CLASSIC"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/variants/classic-poly-mailer.png";
    EditorImage["BIO"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/variants/bio-poly-mailer.png";
    EditorImage["RECYCLED"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/variants/recycled-poly-mailer.png";
})(EditorImage || (EditorImage = {}));
var editorImagePaths = (_c = {},
    _c[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_BLACK] = EditorImage.FOIL_BLACK,
    _c[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_TRANSPARENT] = EditorImage.FOIL_TRANSPARENT,
    _c[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_WHITE] = EditorImage.FOIL_WHITE,
    _c[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_WHITE_BLACK_INSIDE] = EditorImage.FOIL_WHITE,
    _c[PolyMailerProductVariant.POLY_MAILER_VARIANT_STANDARD] = EditorImage.CLASSIC,
    _c[PolyMailerBioProductVariant.POLY_MAILER_BIO_VARIANT_STANDARD] = EditorImage.BIO,
    _c[PolyMailerRecycledProductVariant.POLY_MAILER_RECYCLED_VARIANT_STANDARD] = EditorImage.RECYCLED,
    _c);
var GLOBAL_ASSETS = Object.assign({}, { colorModesImagesPaths: COLOR_MODES_IMAGES_PATHS }, { materialImagesPaths: MATERIALS_IMAGES_PATHS }, { editorImagePaths: editorImagePaths });
var POLY_MAILER_PRODUCT_ASSETS = (_d = {},
    _d[POLY_MAILER_CONSTS.POLY_MAILER] = (_e = {},
        _e[POLY_MAILER_CONSTS.POLY_MAILER_VARIANT_STANDARD] = {
            keyVisual: [assetsPath + "polymailer_desktop.png"],
            sizeAssets: sizeAssetsPolyMailer,
        },
        _e),
    _d[POLY_MAILER_CONSTS.POLY_MAILER_BIO] = (_f = {},
        _f[POLY_MAILER_CONSTS.POLY_MAILER_BIO_VARIANT_STANDARD] = {
            keyVisual: [assetsPath + "bio_polymailer_desktop.png"],
            sizeAssets: sizeAssetsBioPolyMailer,
        },
        _f),
    _d[POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN] = (_g = {},
        _g[POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN_VARIANT_STANDARD] = {
            keyVisual: [assetsPath + "bio_poly_mailer_plain_desktop.png"],
            sizeAssets: sizeAssetsBioPolyMailer,
        },
        _g),
    _d[POLY_MAILER_CONSTS.POLY_MAILER_RECYCLED] = (_h = {},
        _h[POLY_MAILER_CONSTS.POLY_MAILER_RECYCLED_VARIANT_STANDARD] = {
            keyVisual: [assetsPath + "polymailer_desktop.png"],
            sizeAssets: sizeAssetsPolyMailer,
        },
        _h),
    _d);
var productAssets = {
    globalAssets: GLOBAL_ASSETS,
    productAssets: POLY_MAILER_PRODUCT_ASSETS,
};
export default productAssets;
