import React from "react"
import cxBinder from "classnames/bind"
import { PantoneColorGroupName } from "./PantoneColorsGroupingProvider"
import styles from "./PantonePickerGroupsSelector.module.scss"
import { PantonePickerColorObject } from "dsl/src/organisms/PickerColor/PickerColorTypes"
const cx = cxBinder.bind(styles)

interface GroupListItemProps {
  name: Exclude<PantoneColorGroupName, "Defaults">
  isSelected: boolean
  onClick: () => void
  disabled?: boolean
}

function GroupListItem({
  name,
  isSelected,
  onClick,
  disabled,
}: GroupListItemProps): JSX.Element {
  let color: string = "#fafafa"
  switch (name) {
    case "Blues":
      color = "#3b61ff"
      break
    case "Cyans":
      color = "#48c6f3"
      break
    case "Darks":
      color = "#2f2f2f"
      break
    case "Greens":
      color = "#5bba4a"
      break
    case "Lights":
      color = "#e7e7e7"
      break
    case "Magentas":
      color = "#f5a0c5"
      break
    case "Oranges":
      color = "#eeab1d"
      break
    case "Reds":
      color = "#c33934"
      break
    case "Violets":
      color = "#6b18b3"
      break
    case "Yellows":
      color = "#e9de2b"
      break
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isSelected) e.currentTarget.blur()
    onClick()
  }
  return (
    <li
      className={isSelected ? styles.listItemSelected : styles.listItem}
      title={name}
    >
      <button
        disabled={disabled}
        className={cx({
          groupItem: true,
          groupItemSelected: isSelected,
        })}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleClick(e)
        }
        style={{
          backgroundColor: color,
        }}
        type="button"
      />
    </li>
  )
}

export type PantonePickerGroupsSelectorItem = [
  Exclude<PantoneColorGroupName, "Defaults">,
  PantonePickerColorObject[]
]

interface PantonePickerGroupsSelectorProps {
  groups: PantonePickerGroupsSelectorItem[]
  selected?: PantoneColorGroupName
  onSelected: (group: PantoneColorGroupName | null) => void
  disabled?: boolean
}

export function PantonePickerGroupsSelector({
  groups,
  selected,
  onSelected,
  disabled,
}: PantonePickerGroupsSelectorProps) {
  const handleClick = (name: PantoneColorGroupName) => {
    if (selected === name) {
      onSelected(null)
    } else {
      onSelected(name)
    }
  }
  return (
    <ul className={styles.list}>
      {groups.map(([name]) => (
        <GroupListItem
          key={name}
          onClick={() => handleClick(name)}
          name={name}
          isSelected={selected !== undefined && name === selected}
          disabled={disabled}
        />
      ))}
    </ul>
  )
}
