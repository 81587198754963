import React from "react"
import styles from "./EditorHeaderProjectDetails.module.scss"
import { ReactComponent as IconLogo } from "editor/src/ui/assets/svg/logo-pictogram.svg"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

export const EditorHeaderLogo = () => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.envUtil,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, envUtil } = containerSet
  const editorMode =
    designAndProductDriver.productDriver.state.productRenderPilot.getEditorMode()
  const { uri, appConfig } = envUtil

  const onClick = () => {
    if (editorMode === "designer") {
      window.location.href = uri.getCmsUrl()

      return
    }

    uri.redirectToHomepage()
  }

  const { logo } = appConfig.branding

  return (
    <div
      e2e-target="button"
      e2e-target-name="home"
      className={styles.editor_header__project_details__logo}
      onClick={onClick}
    >
      {logo ? <img src={logo} alt="" /> : <IconLogo />}
    </div>
  )
}
