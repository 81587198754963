import { Pattern } from "../value-objects/pattern"

function paintSVGInDom(patternElement: SVGElement, pattern: Pattern) {
  const colorsMap = pattern.colorsConfig
  pattern.colorIds.map((id) => {
    const currentColor = colorsMap[id].colour.getHex()
    const currentElement = patternElement.querySelector(`#${id}`)
    if (!currentElement) {
      return
    }
    const domElements = Array.from(currentElement.children) as HTMLElement[]
    domElements.forEach((element) => {
      element.setAttribute("fill", currentColor)
    })
  })
}

export { paintSVGInDom }
