var _a;
import POLY_MAILER_CONSTS from "../consts/polyMailerConsts";
var POLY_MAILER_DIMENSIONS = {
    Y43: {
        outside: {
            width: 175,
            height: 255,
        },
    },
    Y60: {
        outside: {
            width: 250,
            height: 350,
        },
    },
    Y80: {
        outside: {
            width: 350,
            height: 450,
        },
    },
    Y90: {
        outside: {
            width: 400,
            height: 500,
        },
    },
    Y100: {
        outside: {
            width: 450,
            height: 550,
        },
    },
};
var POLY_MAILER_PRODUCT_DIMENSIONS = (_a = {},
    _a[POLY_MAILER_CONSTS.POLY_MAILER] = POLY_MAILER_DIMENSIONS,
    _a[POLY_MAILER_CONSTS.POLY_MAILER_BIO] = POLY_MAILER_DIMENSIONS,
    _a[POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN] = POLY_MAILER_DIMENSIONS,
    _a[POLY_MAILER_CONSTS.POLY_MAILER_RECYCLED] = POLY_MAILER_DIMENSIONS,
    _a);
export default POLY_MAILER_PRODUCT_DIMENSIONS;
