import React, { PropsWithChildren, useState, useEffect } from "react"

import { getWalletData } from "../../utils/wallet.helpers"
import { WalletWrapperProps } from "../../utils/wallet.types"

export const WalletWrapper = ({
  children,
  isUserLoggedIn,
}: PropsWithChildren<WalletWrapperProps>) => {
  const [walletData, setWalletData] = useState(null)

  useEffect(() => {
    // Prevents memory leak when component unmounted before promise ends
    let isMounted = true

    getWalletData().then((resp) => {
      if (isMounted) {
        setWalletData(resp)
      }
    })

    return () => (isMounted = false)
  }, [isUserLoggedIn])

  return (
    <div>
      {React.cloneElement(children as React.ReactElement, { walletData })}
    </div>
  )
}
