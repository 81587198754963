var _a;
import TISSUE_PAPER_CONSTS from "../generalConfig/consts/tissuePaperConsts";
var TISSUE_PAPER_PRODUCTS_EDITOR_LIMITATIONS = {
    shared: {
        initialActiveSide: {
            activeSideId: "front",
            editedSideId: "front",
        },
        isPatternsAvailable: true,
        disableImagesComponent: true,
        disableTextComponent: true,
        isLimitedColorsModeActive: true,
        isGlobalAssetDropDisabled: true,
        isPantoneColorsAvailable: true,
        sidesNameCodesMap: {
            front: "A",
        },
        reforestationSupport: {
            badge: false,
            trees: true,
        },
    },
    products: (_a = {},
        _a[TISSUE_PAPER_CONSTS.TISSUE_PAPER_MODEL_DEFAULT] = {
            productEditorMode: "2D",
        },
        _a[TISSUE_PAPER_CONSTS.PACKING_PAPER_MODEL_DEFAULT] = {
            productEditorMode: "2D",
        },
        _a),
};
export default TISSUE_PAPER_PRODUCTS_EDITOR_LIMITATIONS;
