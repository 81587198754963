import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey, isProductTypeCustom, } from "../SkuLib";
import CustomCardProduct from "../CustomProducts/CustomCardProduct";
import CardProduct from "../Products/CardProduct/CardProduct";
import CardVariantManager from "../Products/CardProduct/behaviorsComposers/CardVariantManager";
function CardProductFactory(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    var productName = decodedSKU.productName;
    var productVariant = CardVariantManager.findVariant(productName);
    if (isProductTypeCustom(sku)) {
        return new CustomCardProduct(sku, productName);
    }
    else {
        return new CardProduct(sku, productName, productVariant);
    }
}
export default CardProductFactory;
