var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ENVELOPE_CONSTS from "../../../../../source/envelopeProductSpec/generalConfig/consts/envelopeConsts";
var addAssetsAndMaterialsFiltersBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        // this is place where fabric filters are configured
        // http://fabricjs.com/docs/fabric.Image.filters.html
        // Custom filters are also available.
        // See: RenderEngine -> fabric.Image.filters to get list of available filters
        // General filters are stronger that product specific
        class_1.prototype.getFiltersConfigBuilder = function (params) {
            var filters = _super.prototype.getFiltersConfigBuilder.call(this, params);
            if (filters) {
                return filters;
            }
            if (this.product.variantManager.getMaterial() ===
                ENVELOPE_CONSTS.PRINT_MATERIAL_KRAFT) {
                return {
                    Noise: {
                        noise: 70,
                    },
                    Brightness: {
                        brightness: 30,
                    },
                    Contrast: {
                        contrast: 20,
                    },
                    Tint: {
                        color: "#C3936E",
                        opacity: 0.3,
                    },
                };
            }
        };
        return class_1;
    }(superclass));
};
export default addAssetsAndMaterialsFiltersBehaviors;
