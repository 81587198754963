import { I18N } from "../../../../../../../ui/i18n"
import { getFormatMessage } from "shared-libs/src/js/libs/others/i18n"
import fabric from "../../../../../../../libs/vendors/Fabric"
import {
  Colors,
  defaultBackgroundSettings,
  defaultTextSettings,
  placeholderMargin,
} from "./logo-placeholder-slot-helper"

export async function createUserNonInteractivePlaceholder(
  size: number
): Promise<fabric.Group> {
  const formatMessage = await getFormatMessage()
  const text = formatMessage({ id: I18N.editorTools.logoPlaceholder.header })
  const extraText = formatMessage({
    id: I18N.editorTools.logoPlaceholder.subheader,
  })

  const backgroundPlaceholder = new fabric.Circle(defaultBackgroundSettings)

  const placeholderText = new fabric.IText(text.toUpperCase(), {
    ...defaultTextSettings,
    top: -25,
    fill: Colors.black,
  })

  const extraPlaceholderText = new fabric.IText(extraText, {
    selectable: false,
    hasControls: false,
    originX: "center",
    originY: "center",
    fontSize: 8,
    fill: Colors.black,
    fontFamily: "ABCFavoritVariable",
    textAlign: "center",
    top: 25,
  })

  const placeholder = new fabric.Group(
    [backgroundPlaceholder, placeholderText, extraPlaceholderText],
    {
      width: size,
      originX: "center",
      originY: "center",
      scaleX: size / (backgroundPlaceholder.width! + placeholderMargin),
      scaleY: size / (backgroundPlaceholder.width! + placeholderMargin),
    }
  )
  return placeholder
}
