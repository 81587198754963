import { Debug } from "../../../services/logger"
import { PackhelpApi } from "../packhelp-api"
import { JSONApiParser } from "@ph/json-api"

const debug = Debug("ph:CartApi.service")

export type LineItemDTO = {
  variant_id: number
  box_design_id: string
  quantity: number
}

export type LineItemParams = {
  variantId: number
  designId: string
  quantity: number
}

export type TreeLineItemParams = {
  parent_id: number
  price: number
  quantity: number
}

class CartApi {
  public parser: JSONApiParser

  constructor(private api: PackhelpApi) {
    this.parser = new JSONApiParser()
  }

  public async getByNumber(cartId: string) {
    const data = await this.api.ax.get(`orders/${cartId}`, {
      ...this.api.withAuthToken(),
    })
    return data.data
  }

  public async createCart(currency: string) {
    const { data } = await this.api.ax.post(
      `v2/orders`,
      {
        currency,
      },
      this.api.withAuthToken()
    )

    return this.parser.dataMapper(data.data)
  }

  public async addLineItemToCart(
    orderNumber: string,
    lineItemParams: LineItemParams
  ) {
    debug("Adding line item to cart")
    const data = await this.api.ax.post(
      `v2/orders/${orderNumber}/line_item`,
      this.transformToDTO(lineItemParams),
      this.api.withAuthToken()
    )
    return data.data
  }

  public async addTreesToLineItem(payload: TreeLineItemParams) {
    debug("Adding tree to line item")

    const data = await this.api.ax.post(
      `v1/sustainability/add_tree_line_item`,
      payload,
      this.api.withAuthToken()
    )

    return data.data
  }

  private transformToDTO(lineItemParams: LineItemParams): LineItemDTO {
    return {
      variant_id: lineItemParams.variantId,
      box_design_id: lineItemParams.designId,
      quantity: lineItemParams.quantity,
    }
  }
}

export { CartApi }
