var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _round from "lodash/round";
import _each from "lodash/each";
import _isUndefined from "lodash/isUndefined";
import _last from "lodash/last";
import Big from "big.js";
import colorString from "color-string";
var addRenderConfigBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.DPI = 300;
            _this.product = product;
            _this.DPI = 300;
            _this.productData = _this.setProductData();
            _this.SIDES = Object.keys(_this.product.config.renderSpec.sidesMapping.availableSides);
            return _this;
        }
        class_1.prototype.getRenderConfig = function () {
            var productCodeName = this.product.variantManager.getCodeName();
            var renderSpec = this.getRenderSpec();
            return renderSpec.productRenderConfig[productCodeName] || {};
        };
        class_1.prototype.getRenderSpec = function () {
            return this.product.config.renderSpec;
        };
        class_1.prototype.getSideMapping = function () {
            var renderSpec = this.getRenderSpec();
            return renderSpec.sidesMapping;
        };
        class_1.prototype.getEditorCameraZoom = function () {
            var renderConfig = this.getRenderConfig();
            if (_isUndefined(renderConfig.editorCameraZoom)) {
                return 1;
            }
            else {
                return renderConfig.editorCameraZoom;
            }
        };
        class_1.prototype.initSideEditor = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, Promise.resolve()];
                });
            });
        };
        class_1.prototype.isSafeZonesEnabled = function () {
            return this.product.wizzardManager.isProductBox();
        };
        class_1.prototype.isCustomObjectCollisonEnabled = function () {
            return false;
        };
        class_1.prototype.prepareInitialInkColors = function () {
            var defaultColor = this.product.editConfigManager.getEditorDefaultColor();
            return {
                hexColor: defaultColor.color,
                pantoneName: defaultColor.pantoneName,
            };
        };
        class_1.prototype.getSafeZoneWidth = function () {
            return 0;
        };
        class_1.prototype.getOffsetForSide = function () {
            return undefined;
        };
        class_1.prototype.getProductData = function () {
            return this.productData;
        };
        class_1.prototype.setProductData = function () {
            var productAvailableSides = this.getAvailableSides();
            var sceneDimensions = this.getSceneDimensions();
            var sideMapping = this.getSideMapping();
            var productData = {};
            _each(productAvailableSides, function (sideId) {
                productData[sideId] = {};
                productData[sideId].width = sceneDimensions[sideMapping[sideId].w];
                productData[sideId].height = sceneDimensions[sideMapping[sideId].h];
            });
            return productData;
        };
        // See product related behaviors - leaved for now as another empty function :/
        // Should be removed in future implementations
        class_1.prototype.isColorSpaceMonochrome = function () {
            return false;
        };
        class_1.prototype.xyz = function () {
            var productSizeRenderConfig = this.getRenderConfig();
            var sideMapping = this.getSideMapping();
            return {
                x: productSizeRenderConfig[sideMapping.x],
                y: productSizeRenderConfig[sideMapping.y],
                z: productSizeRenderConfig[sideMapping.z],
            };
        };
        class_1.prototype.afterDataLoad = function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, Promise.resolve()];
                });
            });
        };
        class_1.prototype.getSceneDimensions = function () {
            var _a = this.xyz(), x = _a.x, y = _a.y, z = _a.z;
            // Magic formula adjusted manually to keep all boxes inside rendering area with adjusted scale to their size
            var scaleFactor = (Math.max(x, y, z) / 900) * 300 + 80; // wtf are those numbers?
            var scale = scaleFactor / Math.max(x, y, z);
            var productX = x * scale;
            var productY = y * scale;
            var productZ = z * scale;
            return {
                boxX: productX,
                boxY: productY,
                boxZ: productZ,
            };
        };
        class_1.prototype.getEditableSides = function () {
            return this.getAvailableSidesArr();
        };
        class_1.prototype.getAvailableSidesArr = function () {
            return Object.keys(this.getAvailableSides());
        };
        class_1.prototype.getAvailableSides = function () {
            var sideMapping = this.getSideMapping();
            return sideMapping.availableSides;
        };
        class_1.prototype.getSideInfoDimensions = function (sideId) {
            var sidesDimensions = this.getSidesInfoDimensions();
            return sidesDimensions[sideId];
        };
        class_1.prototype.calculateSideDimension = function (allSidesDimensions) {
            var _this = this;
            return function (side) {
                var realDims = _this.getSideDimensionsMilimeters(side);
                allSidesDimensions[side] = {
                    widthMm: realDims.width,
                    heightMm: realDims.height,
                    widthPx: (realDims.width * _this.DPI) / 25.4,
                    heightPx: (realDims.height * _this.DPI) / 25.4,
                };
            };
        };
        class_1.prototype.getSidesInfoDimensions = function () {
            var _this = this;
            var sidesDimensions = {};
            var productAvailableSides = this.getAvailableSidesArr();
            _each(productAvailableSides, function (side) {
                var realDims = _this.getSideDimensionsMilimeters(side);
                sidesDimensions[side] = {
                    widthMm: realDims.width,
                    heightMm: realDims.height,
                    widthCm: realDims.width / 10,
                    heightCm: realDims.height / 10,
                    widthPx: _round((realDims.width * _this.DPI) / 25.4, 4),
                    heightPx: _round((realDims.height * _this.DPI) / 25.4, 4),
                };
            });
            return sidesDimensions;
        };
        class_1.prototype.getAllSidesDimensions = function () {
            var _this = this;
            var allSidesDimensions = this.getSidesInfoDimensions();
            _each(["foldLine", "sideFold"], function (side) {
                var realDims = _this.getSideDimensionsMilimeters(side);
                allSidesDimensions[side] = {
                    widthMm: realDims.width,
                    heightMm: realDims.height,
                    widthPx: _round((realDims.width * _this.DPI) / 25.4, 4),
                    heightPx: _round((realDims.height * _this.DPI) / 25.4, 4),
                };
            });
            return allSidesDimensions;
        };
        class_1.prototype.getSideDimensionsMilimeters = function (sideId) {
            var width = 0, heightParam, height, widthParam;
            var sideMapping = this.getSideMapping();
            var productRenderConfig = this.getRenderConfig();
            heightParam = _last(sideMapping[sideId].h);
            heightParam = heightParam.toLowerCase();
            height = productRenderConfig[sideMapping[heightParam]];
            if (sideId === "foldLine") {
                width = productRenderConfig.foldLineWidth;
            }
            else if (sideId === "sideFold") {
                width = productRenderConfig.sideFoldWidth;
            }
            else {
                widthParam = _last(sideMapping[sideId].w);
                widthParam = widthParam.toLowerCase();
                width = productRenderConfig[sideMapping[widthParam]];
            }
            return {
                width: width,
                height: height,
            };
        };
        class_1.prototype.getSideDimensionsPx300Dpi = function (side) {
            var realDims = this.getSideDimensionsMilimeters(side);
            return {
                width: (realDims.width * this.DPI) / 25.4,
                height: (realDims.height * this.DPI) / 25.4,
            };
        };
        class_1.prototype.getProductZone = function (_a) {
            var sideId = _a.sideId, sideEditDimensions = _a.sideEditDimensions, canvasHeight = _a.canvasHeight, canvasWidth = _a.canvasWidth;
            var productRenderConfig = this.getRenderConfig();
            var foldLineWidth = productRenderConfig.foldLineWidth, sideFoldWidth = productRenderConfig.sideFoldWidth;
            var sideDimensionsMM = this.getSideDimensionsMilimeters(sideId);
            var ratio = new Big(sideEditDimensions.width).div(new Big(sideDimensionsMM.width));
            var width = sideEditDimensions.width;
            var height = parseFloat(new Big(sideDimensionsMM.height).times(ratio));
            var foldLineWidthPx = parseFloat(new Big(foldLineWidth).times(ratio));
            if (sideId === this.SIDES.front || sideId === this.SIDES.back) {
                width -= parseFloat(new Big(sideFoldWidth).times(ratio).times(2));
            }
            if (sideId === this.SIDES.top) {
                width -= parseFloat(new Big(foldLineWidthPx).times(2));
            }
            var top = parseFloat(new Big(canvasHeight).div(2).minus(new Big(height).div(2)));
            var left = parseFloat(new Big(canvasWidth).div(2).minus(new Big(width).div(2)));
            if (sideId === this.SIDES.left || sideId === this.SIDES.right) {
                height = parseFloat(new Big(height).plus(foldLineWidthPx));
                top = parseFloat(new Big(top).minus(foldLineWidthPx));
            }
            return {
                width: width,
                height: height,
                top: top,
                left: left,
            };
        };
        class_1.prototype.getProductClipingZone = function (sideId, productEditZones) {
            var productEditZone = productEditZones.side.zone;
            var left = productEditZone.left, top = productEditZone.top, width = productEditZone.width, height = productEditZone.height, shape = productEditZone.shape;
            return {
                left: left,
                top: top,
                width: width,
                height: height,
                shape: shape,
                fill: "grey",
                transparentCorners: false,
            };
        };
        class_1.prototype.createBackgroundShape = function (_a) {
            var sideEditorInstance = _a.sideEditorInstance, parsedColor = _a.parsedColor, editZone = _a.editZone, zoneName = _a.zoneName;
            var zone = editZone.zone;
            var fabricStatic = sideEditorInstance.fabricStatic;
            var shapeOptions = {
                left: zone.left,
                top: zone.top,
                fill: colorString.to.rgb(parsedColor),
                width: zone.width,
                height: zone.height,
                id: "background_" + zoneName,
                background_metadata: {},
            };
            return Promise.resolve({
                shape: new fabricStatic.Rect(shapeOptions),
                shapeOptions: shapeOptions,
            });
        };
        class_1.prototype.createClipObject = function (sideEditorInstance) {
            var productClipZone = sideEditorInstance.productClipZone, fabricStatic = sideEditorInstance.fabricStatic;
            var clipRectangle = new fabricStatic.Rect(productClipZone);
            return Promise.resolve(clipRectangle);
        };
        class_1.prototype.getClipingFunction = function () {
            return function (ctx) {
                function degToRad(degrees) {
                    return degrees * (Math.PI / 180);
                }
                this.setCoords();
                var productClipRect = this.productClipObject;
                var scaleXTo1 = 1 / this.scaleX;
                var scaleYTo1 = 1 / this.scaleY;
                ctx.save();
                var ctxLeft = -(this.width / 2) + productClipRect.strokeWidth;
                var ctxTop = -(this.height / 2) + productClipRect.strokeWidth;
                var ctxWidth = productClipRect.width - productClipRect.strokeWidth + 1;
                var ctxHeight = productClipRect.height - productClipRect.strokeWidth + 1;
                ctx.translate(ctxLeft, ctxTop);
                ctx.rotate(degToRad(this.angle * -1));
                ctx.scale(scaleXTo1, scaleYTo1);
                ctx.beginPath();
                ctx.rect(productClipRect.left - this.oCoords.tl.x, productClipRect.top - this.oCoords.tl.y, ctxWidth, ctxHeight);
                ctx.closePath();
                ctx.restore();
            };
        };
        class_1.prototype.getObjectMovingZone = function (currentObject, productEditZones) {
            var productEditZonesCords = productEditZones.side.zone;
            var objectCords = currentObject.getBoundingRect();
            return {
                maxTop: productEditZonesCords.top + productEditZonesCords.height + 1,
                minTop: productEditZonesCords.top - objectCords.height - 1,
                maxLeft: productEditZonesCords.left + productEditZonesCords.width - 1,
                minLeft: productEditZonesCords.left - objectCords.width + 1,
            };
        };
        class_1.prototype.getFiltersConfigBuilder = function (params) {
            if (this.isColorSpaceMonochrome()) {
                return {
                    Contrast: {
                        contrast: 255,
                    },
                    RemoveWhite: {
                        threshold: 210,
                        distance: 255,
                    },
                    Tint: {
                        color: params.monochromInkColorSettings.hexColor,
                    },
                };
            }
            else {
                return;
            }
        };
        return class_1;
    }(superclass));
};
export default addRenderConfigBehaviors;
