import React from "react"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { useTranslate } from "../../../../hooks/useTranslate"
import { ActionConfirmModal } from "../action-confirm-modal/action-confirm-modal"
import { ReactComponent as SaveIcon } from "../../../../assets/svg/shape-icons/save.svg"
import { I18N } from "../../../../i18n"

interface FinishDesignConfirmationModalProps {
  onClose: () => void
  onConfirm: () => void
  isLocking: boolean
}
export const FinishDesignConfirmationModal = ({
  onClose,
  onConfirm,
  isLocking,
}: FinishDesignConfirmationModalProps) => {
  const t = useTranslate()

  return (
    <ActionConfirmModal
      onClose={onClose}
      onConfirm={onConfirm}
      title={t(I18N.modals.bndl.title)}
      confirmText={t(I18N.modals.bndl.confirm)}
      Icon={SaveIcon}
      buttonDisabled={isLocking}
    >
      <Typography type={TType.Body15_350}>
        {t(I18N.modals.bndl.description)}
      </Typography>
    </ActionConfirmModal>
  )
}
