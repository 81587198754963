import React from "react"
import { FormattedMessage } from "react-intl"

import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import ModalTemplateConfirm from "../partials/ModalTemplateConfirm"
import { Button } from "dsl/src/atoms/Button/Button"
import { useTranslate } from "../../../../hooks/useTranslate"
import { InnerSize, Modal } from "../Modal"
import { ReactComponent as SuccessCheckmarkIcon } from "../../../../assets/svg/success-checkmark.svg"

import styles from "./SuccessfulRegistrationModal.module.scss"

export interface ModalProps {
  onClose: () => void
  email?: string
}

export const SuccessfulRegistrationModal = ({ onClose, email }: ModalProps) => {
  const t = useTranslate()

  return (
    <Modal onClose={onClose} maxWidth={InnerSize.small}>
      <ModalTemplateConfirm
        onClose={onClose}
        buttonComponent={
          <div className={styles.buttonWrapper}>
            <Button onClick={onClose}>
              <FormattedMessage id={"modals.registration.confirm"} />
            </Button>
          </div>
        }
      >
        <div className={styles.content}>
          <SuccessCheckmarkIcon />
          <Typography className={styles.header} type={TType.Header32_400}>
            {t("modals.registration.title")}
          </Typography>
          <p>
            <Typography type={TType.Body15_350}>
              {t("modals.registration.email-sent")}:
              <span className={styles.email}>{email}</span>
            </Typography>
          </p>
        </div>
      </ModalTemplateConfirm>
    </Modal>
  )
}
