import React from "react"
import cxBinder from "classnames/bind"
import styles from "./FormField.module.scss"

const cx = cxBinder.bind(styles)

export interface FormFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  field: React.ReactChild
  hint?: string
  label: string
  subtitle?: string
  fieldID?: string
  error?: React.ReactNode
  e2eTarget?: string
  e2eTargetName?: string
  withBasicSpacing?: boolean
}

const FormFieldError = (props: {
  children: React.ReactNode
  e2eTarget?: string
}) => {
  return (
    <span className={styles.error} e2e-target={props.e2eTarget}>
      {props.children}
    </span>
  )
}

const FormField = (props: FormFieldProps) => {
  const {
    fieldID,
    label,
    field,
    hint,
    subtitle,
    error,
    e2eTarget,
    e2eTargetName,
    withBasicSpacing,
    ...other
  } = props

  return (
    <div
      className={cx("wrapper", { "wrapper--with-spacing": withBasicSpacing })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...other}
    >
      <label className={styles.label} htmlFor={fieldID}>
        {label}
        {hint && <span className={styles.hint}>{hint}</span>}
      </label>
      {field}
      {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      {error && <FormFieldError>{error}</FormFieldError>}
    </div>
  )
}

export { FormField, FormFieldError, FormField as default }
