import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { EditorHeaderMenu } from "../EditorHeaderMenu/EditorHeaderMenu"
import DiscardChangesModal from "../../Modal/discard-changes/DiscardChangesModal"
import ShareDesignModal from "../../Modal/share-design/ShareDesignModal"
import GuidelinesModal from "../../Modal/guidelines/GuidelinesModal"
import { HowToModal } from "../../Modal/how-to/HowToModal"
import { DownloadDielinesModal } from "../../Modal/download-dielines-modal/DownloadDielinesModal"
import { useSetChatPosition } from "shared-libs/src/js/libs/services/live-chat/use-set-chat-position"

export const ProjectActions = observer(() => {
  const [headerSet, headerSetErrors] = useContainerSet((c) => [
    c.apiSession,
    c.designAndProductDriver,
    c.designerMode,
    c.dbyMode,
    c.envUtil,
    c.auth,
    c.analytics,
  ])

  const [isRestartModalOpen, setRestartModal] = useState(false)
  const [isShareModalOpen, setShareModal] = useState(false)
  const [isGuidelinesModalOpen, setGuidelinesModal] = useState(false)
  const [isHowToModalOpen, setHowToModalOpen] = useState(false)

  const { setChatPosition } = useSetChatPosition()

  useEffect(() => {
    headerSet?.dbyMode.dbyModeStore.isDownloadDielinesModalOpen
      ? setChatPosition(["0px", "400px"])
      : setChatPosition()

    return () => {
      setChatPosition()
    }
  }, [
    headerSet?.dbyMode.dbyModeStore.isDownloadDielinesModalOpen,
    setChatPosition,
  ])

  if (headerSet == null || headerSetErrors != null) return null
  const {
    designAndProductDriver,
    designerMode,
    dbyMode,
    envUtil,
    auth,
    analytics,
  } = headerSet

  const { productDesignStore, productDriver, productDesignUiController } =
    designAndProductDriver

  const { isRendererLoading, productRenderPilot } = productDriver.state

  const {
    isDesignInCart,
    isSharingWithEmail,
    isSharedWithEmail,
    isProductDesignSaving,
    isProductDesignSharing,
    shareWithEmailError,
  } = productDesignStore.state
  const sku = productDriver.productStore.productSku
  const isDataReadOnly = productDesignStore.state.meta.dataReadOnly
  const readonly = isDesignInCart || isDataReadOnly
  const { uri } = envUtil
  const { authController } = auth

  if (!sku || uri.isDtpPreviewMode() || designerMode.available || readonly) {
    return null
  }

  const isDesignPublic = productDesignStore.state.meta.public

  const onRestartDesign = async () => {
    analytics.analyticsController?.trackHeaderElementClicked("startAgain")
    await productDesignUiController.restart()
  }

  const onShareDesign = async () => {
    analytics.analyticsController?.trackHeaderElementClicked("share")
    await productDesignUiController.share()
  }

  const onSendEmailShare = async (email: string) => {
    await productDesignUiController.shareWithEmail(email)
  }

  const isEditorLoading = isRendererLoading || isProductDesignSaving

  const toggleRestartModal = () => {
    setRestartModal(!isRestartModalOpen)
  }

  const toggleShareModal = () => {
    setShareModal(!isShareModalOpen)
    analytics.analyticsController?.trackHeaderElementClicked("share")
  }

  const toggleGuidelinesModal = () => {
    setGuidelinesModal(!isGuidelinesModalOpen)
    analytics.analyticsController?.trackHeaderElementClicked("guidelines")
  }

  const toggleHowToModal = () => {
    setHowToModalOpen(!isHowToModalOpen)
    analytics.analyticsController?.trackHeaderElementClicked("howToUse")
  }

  const toggleDownloadDielinesModal = () => {
    dbyMode.dbyModeStore.toggleDownloadDielinesModal()
    analytics.analyticsController?.trackHeaderElementClicked("downloadDielines")
  }

  const onSaveDesign = async () => {
    // TODO: rename?
    analytics.analyticsController?.trackSaveButtonInModalClicked()

    // TODO: previously on opening modal
    // DO we want to keep it?
    analytics.analyticsController?.trackHeaderElementClicked("save")

    if (auth.available && !authController?.isUserLoggedIn()) {
      return authController?.openLoginModal()
    }

    await productDesignUiController.save({
      notifyOnSuccess: true,
    })
  }

  return (
    <>
      <EditorHeaderMenu
        onRestart={toggleRestartModal}
        onSave={onSaveDesign}
        onShare={toggleShareModal}
        onShowGuidelines={toggleGuidelinesModal}
        onShowHowTo={toggleHowToModal}
        onShowDownloadDielines={toggleDownloadDielinesModal}
      >
        {isRestartModalOpen && (
          <DiscardChangesModal
            onDiscard={onRestartDesign}
            onClose={toggleRestartModal}
          />
        )}
      </EditorHeaderMenu>
      {isShareModalOpen && (
        <ShareDesignModal
          onClose={toggleShareModal}
          onSendEmailShare={onSendEmailShare}
          shareDesign={onShareDesign}
          isSaving={isEditorLoading}
          isSharing={isProductDesignSharing}
          isPublic={isDesignPublic}
          shareWithEmailError={shareWithEmailError}
          isSharingWithEmail={isSharingWithEmail}
          isSharedWithEmail={isSharedWithEmail}
        />
      )}
      {isGuidelinesModalOpen && (
        <GuidelinesModal
          productRenderPilot={productRenderPilot}
          onClose={toggleGuidelinesModal}
        />
      )}
      {isHowToModalOpen && <HowToModal onClose={toggleHowToModal} />}
      {dbyMode.dbyModeStore.isDownloadDielinesModalOpen && (
        <DownloadDielinesModal onClose={toggleDownloadDielinesModal} />
      )}
    </>
  )
})
