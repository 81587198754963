import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import { degreesToRadians } from "../../../../../../libs/maths/angle-calculators"
import fabric from "../../../../../../libs/vendors/Fabric"
import { DielineNavigator } from "../../dieline-navigator/dieline-navigator"

export class AlignmentHelper {
  public static calcCenteredPosition(
    dielineNavigator: DielineNavigator,
    object: PackhelpEditableObject
  ): { left: number; top: number } {
    const position = dielineNavigator.getAssetPositionInSpace(
      object.originSpaceArea,
      {
        width: object.getScaledWidth(),
        height: object.getScaledHeight(),
      }
    )

    const newPosition = fabric.util.rotatePoint(
      new fabric.Point(position.left, position.top),
      new fabric.Point(
        position.left + object.getScaledWidth() / 2,
        position.top + object.getScaledHeight() / 2
      ),
      degreesToRadians(object.angle!)
    )

    return {
      left: newPosition.x,
      top: newPosition.y,
    }
  }

  public static alignCenter(
    dielineNavigator: DielineNavigator,
    object: PackhelpEditableObject
  ) {
    const position = this.calcCenteredPosition(dielineNavigator, object)

    object.set({
      top: position.top,
      left: position.left,
    })
  }

  public static alignHorizontal(
    dielineNavigator: DielineNavigator,
    object: PackhelpEditableObject
  ) {
    const position = this.calcCenteredPosition(dielineNavigator, object)

    object.set({
      left: position.left,
    })

    object.setCoords()
  }

  public static alignVertical(
    dielineNavigator: DielineNavigator,
    object: PackhelpEditableObject
  ) {
    const position = this.calcCenteredPosition(dielineNavigator, object)

    object.set({
      top: position.top,
    })

    object.setCoords()
  }
}
