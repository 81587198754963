var _a, _b, _c, _d, _e, _f, _g;
import NCP_CONSTS from "../consts/ncpConsts";
var NCP_PRODUCT_VARIANTS = (_a = {},
    _a[NCP_CONSTS.WOOD_WOOL] = {
        sizes: [5],
        prefix: "W",
        productCategory: NCP_CONSTS.WOOD_WOOL,
        addonsInVariants: [],
        variants: (_b = {},
            _b[NCP_CONSTS.WOOD_WOOL_VARIANT] = {
                materials: [NCP_CONSTS.PRINT_MATERIAL_PINE_SPRUCE],
                colorModes: [NCP_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [NCP_CONSTS.PRINT_FINISH_NONE],
            },
            _b),
    },
    _a[NCP_CONSTS.SELF_ADHESIVE_ENVELOPE] = {
        sizes: [6],
        prefix: "C",
        productCategory: NCP_CONSTS.SELF_ADHESIVE_ENVELOPE,
        addonsInVariants: [],
        variants: (_c = {},
            _c[NCP_CONSTS.SELF_ADHESIVE_ENVELOPE_VARIANT] = {
                materials: [NCP_CONSTS.PRINT_MATERIAL_FOIL_LDPE],
                colorModes: [NCP_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [NCP_CONSTS.PRINT_FINISH_NONE],
            },
            _c),
    },
    _a[NCP_CONSTS.STICKER_CIRCLE] = {
        sizes: [4],
        prefix: "SC",
        productCategory: NCP_CONSTS.STICKER_CIRCLE,
        addonsInVariants: [],
        variants: (_d = {},
            _d[NCP_CONSTS.STICKER_CIRCLE_VARIANT] = {
                materials: [NCP_CONSTS.PRINT_MATERIAL_PAPER_GLOSS],
                colorModes: [NCP_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [
                    NCP_CONSTS.PRINT_FINISH_DESIGN_BW_THANK_YOU,
                    NCP_CONSTS.PRINT_FINISH_DESIGN_COLOR_HELLO,
                ],
            },
            _d),
    },
    _a[NCP_CONSTS.STICKER_RECTANGLE] = {
        sizes: [14],
        prefix: "SR",
        productCategory: NCP_CONSTS.STICKER_RECTANGLE,
        addonsInVariants: [],
        variants: (_e = {},
            _e[NCP_CONSTS.STICKER_RECTANGLE_VARIANT] = {
                materials: [NCP_CONSTS.PRINT_MATERIAL_PAPER_GLOSS],
                colorModes: [NCP_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [
                    NCP_CONSTS.PRINT_FINISH_DESIGN_COLOR_OPEN_ME,
                    NCP_CONSTS.PRINT_FINISH_DESIGN_BW_HELLO,
                ],
            },
            _e),
    },
    _a[NCP_CONSTS.THANK_YOU_CARD] = {
        sizes: [6],
        prefix: "A",
        productCategory: NCP_CONSTS.THANK_YOU_CARD,
        addonsInVariants: [],
        variants: (_f = {},
            _f[NCP_CONSTS.THANK_YOU_CARD_VARIANT] = {
                materials: [NCP_CONSTS.PRINT_MATERIAL_PAPER_LINEN],
                colorModes: [NCP_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [
                    NCP_CONSTS.PRINT_FINISH_DESIGN_COLOR_THANK_YOU,
                    NCP_CONSTS.PRINT_FINISH_DESIGN_BW_HELLO,
                ],
            },
            _f),
    },
    _a[NCP_CONSTS.PAPER_BUBBLE_WRAP] = {
        sizes: [40],
        prefix: "E",
        productCategory: NCP_CONSTS.PAPER_BUBBLE_WRAP,
        addonsInVariants: [],
        variants: (_g = {},
            _g[NCP_CONSTS.PAPER_BUBBLE_WRAP_VARIANT] = {
                materials: [
                    NCP_CONSTS.PRINT_MATERIAL_BROWN,
                    NCP_CONSTS.PRINT_MATERIAL_WHITE,
                ],
                colorModes: [NCP_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [
                    NCP_CONSTS.PRINT_FINISH_BROWN,
                    NCP_CONSTS.PRINT_FINISH_WHITE,
                ],
            },
            _g),
    },
    _a);
export default NCP_PRODUCT_VARIANTS;
