import React, { Suspense, useRef } from "react"
import { observer } from "mobx-react-lite"
import styles from "./UniversalEditorToolbar.module.scss"

import Tab from "../../Tabs"
import { EditorToolbarItem } from "../../../../../stores/editor-toolbar-store"
import { EditorToolbar, MenuItem } from "../EditorToolbar/EditorToolbar"
import { EditorToolbarElement } from "../EditorToolbar/EditorToolbarElement/EditorToolbarElement"
import { EditorToolEco } from "../../tools/EditorToolEco"
import { EditorToolImagesUploaderWrapper } from "../../tools/editor-tool-images-uploader/EditorToolImagesUploader"

import { EditorToolText } from "../../tools/EditorToolText/EditorToolText"
import { EditorToolChangeSize } from "../../tools/editor-tool-change-size/EditorToolChangeSize"
import { EditorToolShapes } from "../../tools/EditorToolShapes"

import { useOutsideClick } from "../../../../hooks/useOutsideClick"
import { EditorToolFontsWired as EditorToolFonts } from "../../tools/EditorToolFonts"
import { EditorToolLogoUploaderWrapper } from "../../tools/EditorToolLogoUploader/EditorToolLogoUploader"
import { filterMenuItems, getMenuItemLabel } from "../toolbar-item-functions"
import { EditorToolTemplatesWired } from "../../tools/EditorToolTemplates"
import { EditorToolMaterialAndColors } from "../../tools/EditorToolMaterialAndColors"
import { EditorToolReplicablePatterns } from "../../tools/EditorToolReplicablePatterns"
import { isClickOutsideToolbarAllowed } from "../click-outside-checker"
import { EditorToolBackground } from "../../tools/editor-tool-background/editor-tool-background"
import { EditorToolPatterns } from "../../tools/editor-tool-patterns/editor-tool-patterns"
import { EditorToolFsc } from "../../tools/EditorToolFsc"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { I18N } from "../../../../i18n"
import { DesignAndProductDriverContainer } from "../../../../../_containers/design-and-product-driver-container"
import { UiContainer } from "../../../../../_containers/ui-container"
import { EditorToolProductConfiguration } from "../../tools/EditorToolProductConfiguration"
import { EditorToolProductSwitcher } from "../../tools/EditorToolProductSwitcher"
import { availableEditorToolbarItems } from "../../../../theater/ui-scenes-controller/available-editor-toolbar-items.config"

const i18n = I18N.tabs

const EditorToolDevTools = React.lazy(async () => {
  const mod = await import("../../tools/EditorToolDevTools")
  return {
    default: mod.EditorToolDevTools,
  }
})

export const UniversalEditorToolbarWired = observer(() => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ui,
  ])

  if (!containerSet) {
    return null
  }

  return (
    <UniversalEditorToolbar
      designAndProductDriverContainer={containerSet.designAndProductDriver}
      uiContainer={containerSet.ui}
    />
  )
})

const UniversalEditorToolbar = observer(
  ({
    designAndProductDriverContainer,
    uiContainer,
  }: {
    designAndProductDriverContainer: DesignAndProductDriverContainer
    uiContainer: UiContainer
  }) => {
    const [containerSet] = useContainerSet((c) => [
      c.ui,
      c.tooling,
      c.analytics,
      c.assets,
    ])

    const toolbarRef = useRef(null)
    const tabContentRef = useRef(null)

    const { productDriver, contextController } = designAndProductDriverContainer
    const { editorToolbarStore } = uiContainer
    const {
      activeSpace,
      activeContext,
      activeViewType,
      isRendererLoading,
      productRenderPilot,
    } = productDriver.state
    const { isBackgroundImageOverscaled } = productDriver.assetsDriver

    useOutsideClick(
      tabContentRef,
      (e) => {
        /**
         * activeSpace is here to keep the old behaviour:
         * - click outside closes the active tab in 2D view only
         * - in 3D view it works like before (in ThreeDimensionalEditorToolbar).
         *
         * If we want to unify the behaviour we need to fix e2e
         * (as waitForPageLoad function does a click, which would automatically
         * close forced initial tab).
         */
        if (
          activeSpace &&
          !e.defaultPrevented &&
          isClickOutsideToolbarAllowed(e, toolbarRef)
        ) {
          editorToolbarStore.closeTab()
        }
      },
      editorToolbarStore.isTabSelected()
    )

    if (!containerSet) {
      return null
    }

    const { tooling, assets, analytics, ui } = containerSet
    const { ecoShapesStore } = assets
    const devToolsEnabled = !!tooling?.available

    if (productRenderPilot.isDbyMode()) {
      const backgroundMenuItem = availableEditorToolbarItems.find(
        (item) => item.name === EditorToolbarItem.background
      )

      if (backgroundMenuItem) {
        backgroundMenuItem.nameIntl = i18n.printOptions
      }
    }

    const onAddLogoPlaceholderClick = async () => {
      const space = await contextController.forceSpaceView()
      await productDriver.addLogoPlaceholderSlot(space)

      editorToolbarStore.closeTab()
    }

    const editorMenuItemClick = (tab: EditorToolbarItem) => {
      if (!editorToolbarStore.isTabSelected(tab)) {
        analytics.analyticsController?.trackToolbarItemClicked(tab)
      }

      if (tab === EditorToolbarItem.logoPlaceholder) {
        onAddLogoPlaceholderClick()
      }
    }

    const hasMenuItemError = (item: MenuItem): boolean => {
      if (item.name === EditorToolbarItem.background) {
        return isBackgroundImageOverscaled
      }

      return false
    }

    const ecoShapesAvailable = Object.keys(ecoShapesStore.ecoShapes).length > 0
    const filteredMenuItems = filterMenuItems({
      items: availableEditorToolbarItems.filter((menuItem) =>
        ui.editorToolbarStore.availableToolbarItems.includes(menuItem.name)
      ),
      productRenderPilot,
      activeViewType,
      activeContext,
      activeSpace,
      isDevToolsEnabled: devToolsEnabled,
      ecoShapesAvailable,
    })

    const isTabAvailable = (tab: EditorToolbarItem) =>
      filteredMenuItems.some(({ name }) => name === tab)

    return (
      <div
        className={styles.universal_editor_toolbar}
        e2e-target="toolbar"
        e2e-target-name="universal"
      >
        <nav className={styles.universal_editor_toolbar_nav}>
          <div className={styles.toolbar_container}>
            {filteredMenuItems.length > 0 && (
              <EditorToolbar ref={toolbarRef}>
                {filteredMenuItems.map((item, index) => {
                  return (
                    <EditorToolbarElement
                      isDisabled={isRendererLoading}
                      hasTopSeparator={index !== 0 && item.topSeparator}
                      label={getMenuItemLabel(item)}
                      key={item.name}
                      hasError={hasMenuItemError(item)}
                      onClick={() => {
                        editorMenuItemClick(item.name)
                      }}
                      {...item}
                    />
                  )
                })}
              </EditorToolbar>
            )}
          </div>
          <div>
            {isTabAvailable(EditorToolbarItem.logo) && (
              <Tab.Content name={EditorToolbarItem.logo} ref={tabContentRef}>
                <EditorToolLogoUploaderWrapper />
              </Tab.Content>
            )}
            {isTabAvailable(EditorToolbarItem.templates) && (
              <Tab.Content
                name={EditorToolbarItem.templates}
                ref={tabContentRef}
              >
                <EditorToolTemplatesWired />
              </Tab.Content>
            )}
            {isTabAvailable(EditorToolbarItem.replicablePatterns) && (
              <Tab.Content
                name={EditorToolbarItem.replicablePatterns}
                ref={tabContentRef}
              >
                <EditorToolReplicablePatterns />
              </Tab.Content>
            )}
            {isTabAvailable(EditorToolbarItem.patterns) && (
              <Tab.Content
                name={EditorToolbarItem.patterns}
                ref={tabContentRef}
              >
                <EditorToolPatterns />
              </Tab.Content>
            )}
            {isTabAvailable(EditorToolbarItem.background) && (
              <Tab.Content
                name={EditorToolbarItem.background}
                ref={tabContentRef}
              >
                <EditorToolBackground />
              </Tab.Content>
            )}
            {isTabAvailable(EditorToolbarItem.materialAndColor) && (
              <Tab.Content
                name={EditorToolbarItem.materialAndColor}
                ref={tabContentRef}
              >
                <EditorToolMaterialAndColors />
              </Tab.Content>
            )}
            {isTabAvailable(EditorToolbarItem.productSwitcher) && (
              <Tab.Content
                name={EditorToolbarItem.productSwitcher}
                ref={tabContentRef}
              >
                <EditorToolProductSwitcher />
              </Tab.Content>
            )}
            {isTabAvailable(EditorToolbarItem.productConfiguration) && (
              <Tab.Content
                name={EditorToolbarItem.productConfiguration}
                ref={tabContentRef}
              >
                <EditorToolProductConfiguration />
              </Tab.Content>
            )}
            {isTabAvailable(EditorToolbarItem.text) && (
              <Tab.Content name={EditorToolbarItem.text} ref={tabContentRef}>
                <EditorToolText />
              </Tab.Content>
            )}
            <Tab.Content name={EditorToolbarItem.fonts} ref={tabContentRef}>
              <EditorToolFonts />
            </Tab.Content>
            {isTabAvailable(EditorToolbarItem.shapes) && (
              <Tab.Content name={EditorToolbarItem.shapes} ref={tabContentRef}>
                <EditorToolShapes />
              </Tab.Content>
            )}
            {isTabAvailable(EditorToolbarItem.images) && (
              <Tab.Content name={EditorToolbarItem.images} ref={tabContentRef}>
                <EditorToolImagesUploaderWrapper />
              </Tab.Content>
            )}
            {isTabAvailable(EditorToolbarItem.eco) && (
              <Tab.Content name={EditorToolbarItem.eco} ref={tabContentRef}>
                <EditorToolEco />
              </Tab.Content>
            )}
            {isTabAvailable(EditorToolbarItem.fsc) && (
              <Tab.Content name={EditorToolbarItem.fsc} ref={tabContentRef}>
                <EditorToolFsc />
              </Tab.Content>
            )}
            {devToolsEnabled && (
              <Tab.Content
                name={EditorToolbarItem.devTools}
                ref={tabContentRef}
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <>
                    <EditorToolDevTools />
                  </>
                </Suspense>
              </Tab.Content>
            )}

            <Tab.Content
              name={EditorToolbarItem.changeSize}
              ref={tabContentRef}
            >
              <EditorToolChangeSize />
            </Tab.Content>
          </div>
        </nav>
      </div>
    )
  }
)
