import { PackhelpMaskObject } from "../../../object-extensions/packhelp-objects"
import { Colour } from "../../../../../../libs/value-objects/colour"
import { EditorEventNames } from "../../../../../../stores/editor-events"
import { BackgroundsLayers } from "../../backgrounds-module/types"
import { TempLayers } from "../../../types/render-engine-types"

export const DEFAULT_INITIAL_MARGIN = 30
export const DEFAULT_MINIMAL_MARGIN = 10

export enum MaskType {
  covering = "covering",
  clipping = "clipping",
}

export interface MaskSize {
  width: number
  height: number
}

export interface MaskScale {
  scaleX: number
  scaleY: number
}

export interface MaskBorderRadius {
  rx: number
  ry: number
}

export interface MaskPosition {
  left: number
  top: number
}

export interface ScaledMaskSize {
  scaledWidth: number
  scaledHeight: number
  scaleX: number
  scaleY: number
}

export interface MaskConfig {
  shape: PackhelpMaskObject
  color: Colour
  size: MaskSize
  assetClipping: boolean
  minimalMargin?: number
  initialMargin?: number
  isEditingDisabled?: boolean
}

export type CoveringMaskConfigExport = {
  type: MaskType.covering
  maskObjectId: string
  assetClipping: boolean
  minimalMargin?: number
  isEditingDisabled?: boolean
}

export type ClippingMaskConfigExport = {
  type: MaskType.clipping
  shape: PackhelpMaskObject
  size: MaskSize
  assetClipping: boolean
  minimalMargin?: number
  isEditingDisabled?: boolean
}

export type MaskConfigExport =
  | CoveringMaskConfigExport
  | ClippingMaskConfigExport

export type MaskOffset = {
  offsetW: number
  offsetH: number
}

export interface MaskEvent {
  name: string
  fn: (...args) => void
}

export interface EditorEvent {
  name: EditorEventNames
  fn: (...args) => void
}

export interface BackgroundLayerDefinition {
  globalId: BackgroundsLayers
  tempId: TempLayers
}

export const backgroundLayerDefinitions = [
  {
    globalId: BackgroundsLayers.GLOBAL_PATTERN,
    tempId: TempLayers.TEMP_PATTERN,
  },
  {
    globalId: BackgroundsLayers.BACKGROUND_IMAGE,
    tempId: TempLayers.TEMP_BACKGROUND_IMAGE,
  },
]
