import React from "react"
import { useTranslate } from "../../../../../shared-components/i18n/useTranslate"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import Stepper, { StepsEnumType } from "dsl/src/atoms/Stepper/Stepper"
import { walletI18n } from "../../utils/wallet.translations"
import s from "./WalletSidebarValueProposition.module.scss"

export const WalletSidebarValueProposition = () => {
  const t = useTranslate()
  return (
    <>
      <Typography type={TType.Header13_500}>
        {t(walletI18n.valuePropositionWhatUGet)}:
      </Typography>
      <div className={s["value-proposition"]}>
        <div className={s.icon}>
          <Stepper status={StepsEnumType.Completed} />
        </div>
        <Typography type={TType.Header13_500}>
          {t(walletI18n.valuePropositionOne)}
        </Typography>
      </div>
      <div className={s["value-proposition"]}>
        <div className={s.icon}>
          <Stepper status={StepsEnumType.Completed} />
        </div>
        <Typography type={TType.Header13_500}>
          {t(walletI18n.valuePropositionTwo)}
        </Typography>
      </div>
      <div className={s["value-proposition"]}>
        <div className={s.icon}>
          <Stepper status={StepsEnumType.Completed} />
        </div>
        <Typography type={TType.Header13_500}>
          {t(walletI18n.valuePropositionThree)}
        </Typography>
      </div>
    </>
  )
}
