import {
  ColorModeUI,
  EditContext,
  EditContextIntl,
  EditorMode,
  FinishUI,
  MaterialUI,
  ModelContext,
  ModelEditableSpaces,
  RenderConfig,
  sideIdIntl,
} from "./types"
import { Product } from "@ph/product-api"

export function getEditContextByModelContext(
  modelContext: ModelContext
): EditContext {
  if (modelContext === ModelContext.OPENED) {
    return EditContext.INSIDE
  }

  return EditContext.OUTSIDE
}

export function getModelContextByEditContext(
  editContext: EditContext
): ModelContext {
  if (editContext === EditContext.INSIDE) {
    return ModelContext.OPENED
  }

  return ModelContext.CLOSED
}

export function findProductPropertyBySku<
  T extends FinishUI | MaterialUI | ColorModeUI
>(properties: T[], sku: string): T | undefined {
  return properties.find((property) => property.sku === sku)
}

export function loadProductConfig(
  product: Product,
  renderConfigMap
): RenderConfig {
  return renderConfigMap[product.variantManager.getSize()]
}

export function getSpaceNameIntl(spaceId: ModelEditableSpaces): string {
  const spaceMap: { [k: string]: sideIdIntl } = {
    [ModelEditableSpaces.FRONT]: "sides.front",
    [ModelEditableSpaces.BACK]: "sides.back",
    [ModelEditableSpaces.BOTTOM]: "sides.bottom",
    [ModelEditableSpaces.TOP]: "sides.top",
    [ModelEditableSpaces.LEFT]: "sides.left",
    [ModelEditableSpaces.RIGHT]: "sides.right",
    [ModelEditableSpaces.LID]: "sides.lid",
    [ModelEditableSpaces.FRONT_INSIDE]: "sides.front-inside",
    [ModelEditableSpaces.DEFAULT]: "sides.default",
    [ModelEditableSpaces.TOP_FRONT]: "sides.top-front",
    [ModelEditableSpaces.TOP_BACK]: "sides.top-back",
    [ModelEditableSpaces.BOTTOM_FRONT]: "sides.bottom-front",
    [ModelEditableSpaces.BOTTOM_BACK]: "sides.bottom-back",
  }

  return spaceMap[spaceId]
}

export function getEditContextIntl(editContext: EditContext): string {
  const editContextMap: { [k: string]: EditContextIntl } = {
    [EditContext.INSIDE]: "editContext.inside",
    [EditContext.OUTSIDE]: "editContext.outside",
    [EditContext.FRONT]: "editContext.front",
    [EditContext.BACK]: "editContext.back",
    [EditContext.SLEEVE]: "editContext.sleeve",
  }

  return editContextMap[editContext] || "missing.translation"
}

export const getTextureConfigCreator =
  (url: string) => (path: string, type: string) => ({
    path: url + path,
    type,
  })

export const bubble = <T>(keys: string[], bubbled: T) => {
  const result: Record<string, T> = {}

  keys.forEach((key) => {
    result[key] = bubbled
  })

  return result
}

interface TabsAvailability {
  logo: boolean
  logoPlaceholder: boolean
  style: boolean
  background: boolean
  patterns: boolean
  eco: boolean
  images: boolean
  text: boolean
  shapes: boolean
  templates: boolean
  materialAndColor: boolean
  replicablePatterns: boolean
  fsc: boolean
  productConfiguration: boolean
}

export const getDbyTabs = (
  isFscAvailable: boolean,
  isBackgroundTabAvailable: boolean,
  isAfterPurchaseEdit?: boolean
): TabsAvailability => {
  return {
    logo: false,
    logoPlaceholder: false,
    style: false,
    background: !!isBackgroundTabAvailable && !isAfterPurchaseEdit,
    patterns: false,
    eco: !isAfterPurchaseEdit,
    images: false,
    text: false,
    shapes: false,
    templates: false,
    materialAndColor: false,
    replicablePatterns: false,
    fsc: isFscAvailable && !isAfterPurchaseEdit,
    productConfiguration: !isAfterPurchaseEdit,
  }
}

export function isFscCertificateAvailable(
  editorMode: EditorMode,
  product: Product
): boolean {
  const isDesignerMode = editorMode === "designer"
  const isFscAvailable = product.variantManager.isFscCertificateAvailable()

  return !isDesignerMode && isFscAvailable
}
