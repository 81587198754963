var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import TAPE_CONSTS from "../../../../../source/tapeProductSpec/generalConfig/consts/tapeConsts";
import { getOldVariantCodeName } from "../../../../../definitions/products/newProductVariantsToOld";
import { ProductType } from "../../../types";
var addTapeVariantBehaviors = function (superclass) {
    function _findVariant(decodedSKU) {
        if (decodedSKU.productName === TAPE_CONSTS.TAPE_VARIANT_KRAFT_TAPE) {
            return TAPE_CONSTS.TAPE_VARIANT_KRAFT_TAPE;
        }
        else if (decodedSKU.productName === TAPE_CONSTS.PRE_PRINTED_KRAFT_TAPE) {
            return TAPE_CONSTS.PRE_PRINTED_KRAFT_TAPE_VARIANT;
        }
        else {
            return TAPE_CONSTS.TAPE_VARIANT_PACKING_TAPE;
        }
    }
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            return _super.call(this, props) || this;
        }
        class_1.prototype.getVariant = function () {
            return _findVariant(this.skuData.decodedSKU);
        };
        class_1.findVariant = function (decodedSKU) {
            return _findVariant(decodedSKU);
        };
        class_1.prototype.getVariantStructure = function () {
            return this.variant.variants[this.getVariant()];
        };
        class_1.prototype.getVariantDashed = function () {
            return getOldVariantCodeName(this.getVariant());
        };
        class_1.prototype.getProductType = function () {
            var productName = this.getName();
            switch (productName) {
                case TAPE_CONSTS.PRE_PRINTED_KRAFT_TAPE_VARIANT:
                    return ProductType.PrePrinted;
                default:
                    return ProductType.Custom;
            }
        };
        return class_1;
    }(superclass));
};
export default addTapeVariantBehaviors;
