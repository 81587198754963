var ECO_PROPERTIES_CONSTS = {
    BIO_DEGRADABLE: "bioDegradable",
    RECYCLED: "recycled",
    PLASTIC_FREE: "plasticFree",
    COMPOSTABLE: "compostable",
    VEGAN: "vegan",
    SOLVENT_BASED_INKS: "solventBasedInks",
    CERTIFIED_WOOD: "certifiedWood",
    RENEWABLE: "renewable",
    EFFIECIENT_FULFILMENT: "efficientFulfilment",
    RECYCLABLE: "recyclable",
    LOCAL_FABRICATION: "localFabrication",
    MULTIPLE_USE: "multipleUse",
};
export default ECO_PROPERTIES_CONSTS;
