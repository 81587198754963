var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { getOldVariantCodeName } from "../../../../definitions/products/newProductVariantsToOld";
import SAMPLE_PACK_CONSTS from "../../../../source/samplePackProductSpec/generalConfig/consts/samplePackConsts";
import { ProductType } from "../../types";
var addSamplePackVariantBehaviors = function (superclass) {
    function findVariant() {
        return SAMPLE_PACK_CONSTS.SAMPLE_PACK_DEFAULT_VARIANT;
    }
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getCodeName = function () {
            return SAMPLE_PACK_CONSTS.SAMPLE_PACK_CODE_NAME;
        };
        class_1.prototype.getVariant = function () {
            return findVariant();
        };
        class_1.prototype.getVariantDashed = function () {
            return getOldVariantCodeName(this.getVariant());
        };
        class_1.prototype.getVariantStructure = function () {
            return this.variant.variants[this.getVariant()];
        };
        class_1.findVariant = function (decodedSKU) {
            return findVariant();
        };
        class_1.prototype.getProductType = function () {
            return ProductType.Sample;
        };
        return class_1;
    }(superclass));
};
export default addSamplePackVariantBehaviors;
