var _a;
import LABEL_CONSTS from "../consts/labelConsts";
var LABEL_PRODUCT_DIMENSIONS = (_a = {},
    _a[LABEL_CONSTS.CIRCLE_LABEL] = {
        SC2: {
            outside: {
                width: 25,
                height: 25,
            },
        },
        SC4: {
            outside: {
                width: 40,
                height: 40,
            },
        },
        SC5: {
            outside: {
                width: 50,
                height: 50,
            },
        },
        SC6: {
            outside: {
                width: 65,
                height: 65,
            },
        },
        SC7: {
            outside: {
                width: 75,
                height: 75,
            },
        },
        SC10: {
            outside: {
                width: 100,
                height: 100,
            },
        },
    },
    _a[LABEL_CONSTS.SQUARE_LABEL] = {
        SS5: {
            outside: {
                width: 25,
                height: 25,
            },
        },
        SS10: {
            outside: {
                width: 50,
                height: 50,
            },
        },
        SS13: {
            outside: {
                width: 64,
                height: 64,
            },
        },
        SS15: {
            outside: {
                width: 75,
                height: 75,
            },
        },
        SS20: {
            outside: {
                width: 100,
                height: 100,
            },
        },
    },
    _a[LABEL_CONSTS.RECTANGLE_LABEL] = {
        SR7: {
            outside: {
                width: 25,
                height: 50,
            },
        },
        SR10i: {
            outside: {
                width: 35,
                height: 60,
            },
        },
        SR10ii: {
            outside: {
                width: 25,
                height: 79,
            },
        },
        SR11: {
            outside: {
                width: 50,
                height: 60,
            },
        },
        SR12: {
            outside: {
                width: 50,
                height: 75,
            },
        },
        SR12i: {
            outside: {
                width: 36,
                height: 79,
            },
        },
        SR12ii: {
            outside: {
                width: 54,
                height: 62,
            },
        },
        SR12iii: {
            outside: {
                width: 43,
                height: 80,
            },
        },
        SR13: {
            outside: {
                width: 23,
                height: 103,
            },
        },
        SR13i: {
            outside: {
                width: 55,
                height: 73,
            },
        },
        SR14: {
            outside: {
                width: 32,
                height: 103,
            },
        },
        SR14i: {
            outside: {
                width: 65,
                height: 76,
            },
        },
        SR14ii: {
            outside: {
                width: 40,
                height: 103,
            },
        },
        SR15: {
            outside: {
                width: 50,
                height: 100,
            },
        },
        SR15i: {
            outside: {
                width: 63,
                height: 84,
            },
        },
        SR15ii: {
            outside: {
                width: 35,
                height: 119,
            },
        },
        SR15iii: {
            outside: {
                width: 51,
                height: 103,
            },
        },
        SR16: {
            outside: {
                width: 63,
                height: 92,
            },
        },
        SR16i: {
            outside: {
                width: 27,
                height: 130,
            },
        },
        SR16ii: {
            outside: {
                width: 21,
                height: 143,
            },
        },
        SR17: {
            outside: {
                width: 35,
                height: 130,
            },
        },
        SR17i: {
            outside: {
                width: 65,
                height: 103,
            },
        },
        SR17ii: {
            outside: {
                width: 50,
                height: 119,
            },
        },
        SR18: {
            outside: {
                width: 75,
                height: 100,
            },
        },
        SR18i: {
            outside: {
                width: 54,
                height: 123,
            },
        },
        SR18ii: {
            outside: {
                width: 25,
                height: 157,
            },
        },
        SR18iii: {
            outside: {
                width: 70,
                height: 112,
            },
        },
        SR19: {
            outside: {
                width: 85,
                height: 103,
            },
        },
        SR19i: {
            outside: {
                width: 64,
                height: 127,
            },
        },
        SR19ii: {
            outside: {
                width: 44,
                height: 148,
            },
        },
        SR19iii: {
            outside: {
                width: 36,
                height: 157,
            },
        },
        SR20: {
            outside: {
                width: 75,
                height: 125,
            },
        },
        SR20i: {
            outside: {
                width: 65,
                height: 134,
            },
        },
        SR20ii: {
            outside: {
                width: 55,
                height: 145,
            },
        },
        SR20iii: {
            outside: {
                width: 43,
                height: 160,
            },
        },
        SR22: {
            outside: {
                width: 65,
                height: 152,
            },
        },
        SR22i: {
            outside: {
                width: 75,
                height: 148,
            },
        },
        SR23: {
            outside: {
                width: 23,
                height: 205,
            },
        },
        SR23i: {
            outside: {
                width: 63,
                height: 167,
            },
        },
        SR23ii: {
            outside: {
                width: 110,
                height: 120,
            },
        },
        SR24: {
            outside: {
                width: 32,
                height: 205,
            },
        },
        SR24i: {
            outside: {
                width: 100,
                height: 143,
            },
        },
        SR25: {
            outside: {
                width: 40,
                height: 205,
            },
        },
        SR25i: {
            outside: {
                width: 63,
                height: 183,
            },
        },
        SR26: {
            outside: {
                width: 51,
                height: 205,
            },
        },
        SR27: {
            outside: {
                width: 65,
                height: 205,
            },
        },
        SR27i: {
            outside: {
                width: 75,
                height: 199,
            },
        },
        SR29: {
            outside: {
                width: 27,
                height: 260,
            },
        },
        SR29i: {
            outside: {
                width: 85,
                height: 205,
            },
        },
        SR29ii: {
            outside: {
                width: 80,
                height: 211,
            },
        },
        SR29iii: {
            outside: {
                width: 70,
                height: 223,
            },
        },
        SR30: {
            outside: {
                width: 35,
                height: 260,
            },
        },
        SR31: {
            outside: {
                width: 21,
                height: 285,
            },
        },
        SR33: {
            outside: {
                width: 65,
                height: 267,
            },
        },
        SR34: {
            outside: {
                width: 44,
                height: 295,
            },
        },
        SR37: {
            outside: {
                width: 75,
                height: 295,
            },
        },
        SR39: {
            outside: {
                width: 100,
                height: 285,
            },
        },
        SR44: {
            outside: {
                width: 280,
                height: 160,
            },
        },
    },
    _a);
export default LABEL_PRODUCT_DIMENSIONS;
