import { ProductRenderPilot } from "../../libs/products-render-config/product-render-pilot"
import { action, computed, makeObservable, observable } from "mobx"
import { DielineGenerator } from "../../render-engine/modules/dieline-manager/dieline.generator"
import { VirtualDielineUrlProvider } from "../../render-engine/modules/dieline-manager/virtual-dieline-url.provider"
import { DbyDielineUrlProvider } from "../../render-engine/modules/dieline-manager/dby-dieline-url.provider"
import {
  DbyDielineType,
  EditContext,
} from "../../libs/products-render-config/types"
import _set from "lodash/set"
import { Product } from "@ph/product-api"
import { DielineSvgConverter } from "../../libs/services/dieline-svg-converter/dieline-svg.converter"

export class DielineStore {
  @observable
  public isLoadingDbyDielineUrls: boolean = false

  @observable
  private virtualDielineUrls: Record<string, Record<string, string>> = {}

  @observable
  private dbyDielineUrls: Record<string, Record<string, string>> = {}

  private readonly virtualDielineUrlProvider: VirtualDielineUrlProvider
  private readonly dbyDielineUrlProvider: DbyDielineUrlProvider

  public constructor(
    dielineGenerator: DielineGenerator,
    productRegion: string
  ) {
    makeObservable(this)
    this.virtualDielineUrlProvider = new VirtualDielineUrlProvider(
      dielineGenerator
    )
    this.dbyDielineUrlProvider = new DbyDielineUrlProvider(
      dielineGenerator,
      productRegion
    )
  }

  public async getVirtualDielineSvg(
    product: Product,
    editContext: EditContext
  ): Promise<string | undefined> {
    const url = this.getVirtualDielineUrl(product, editContext)

    if (!url) {
      return
    }

    const svg = await fetch(url).then((res) => res.text())

    return new DielineSvgConverter(svg).call({
      flipHorizontal: editContext === EditContext.INSIDE,
    })
  }

  public getVirtualDielineUrl(
    product: Product,
    editContext: EditContext
  ): string | undefined {
    const cacheKey = this.getCacheKey(product)

    return computed(
      () => this.virtualDielineUrls[cacheKey]?.[editContext]
    ).get()
  }

  public getDbyDielineUrl(product: Product, type: string): string | undefined {
    const cacheKey = this.getCacheKey(product)

    return computed(() => this.dbyDielineUrls[cacheKey]?.[type]).get()
  }

  public async loadDielineUrls(
    productRenderPilot: ProductRenderPilot
  ): Promise<void> {
    if (productRenderPilot.getEditorMode() !== "dby") {
      await this.loadVirtualDielineUrls(productRenderPilot)
    }

    /**
     * Editor doesn't require DBY dielines to be loaded when starting,
     * so we don't need to wait for them (can be loaded async).
     */
    this.loadDbyDielineUrls(productRenderPilot)
  }

  private async loadVirtualDielineUrls(
    productRenderPilot: ProductRenderPilot
  ): Promise<void> {
    const product = productRenderPilot.getProduct()
    const cacheKey = this.getCacheKey(product)
    const editContexts = this.getEditContexts(productRenderPilot)

    for (const editContext of editContexts) {
      if (this.getVirtualDielineUrl(product, editContext)) {
        continue
      }

      const url = await this.virtualDielineUrlProvider.get(
        productRenderPilot,
        editContext
      )

      _set(this.virtualDielineUrls, [cacheKey, editContext], url)
    }
  }

  private async loadDbyDielineUrls(
    productRenderPilot: ProductRenderPilot
  ): Promise<void> {
    this.setIsLoadingDbyDielineUrls(true)

    const product = productRenderPilot.getProduct()
    const cacheKey = this.getCacheKey(product)

    await Promise.all(
      ["ai", "pdf"].map(async (type) => {
        if (this.getDbyDielineUrl(product, type)) {
          return null
        }

        const url = await this.dbyDielineUrlProvider.get(
          productRenderPilot,
          type as DbyDielineType
        )

        _set(this.dbyDielineUrls, [cacheKey, type], url)
      })
    ).finally(() => {
      this.setIsLoadingDbyDielineUrls(false)
    })
  }

  @action
  private setIsLoadingDbyDielineUrls(isLoading: boolean): void {
    this.isLoadingDbyDielineUrls = isLoading
  }

  private getCacheKey(product: Product): string {
    const sku = product.variantManager.getSku()
    const size = product.variantManager.getSize()

    return `${sku}-${size}`
  }

  private getEditContexts(
    productRenderPilot: ProductRenderPilot
  ): EditContext[] {
    return productRenderPilot.getAvailableEditContexts()
  }
}
