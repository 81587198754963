import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey } from "../SkuLib";
var SamplePackConfigBuilder = function (sku) {
    return {
        skuData: {
            sku: sku,
            decodedSKU: _mapValues(getSkuData(sku), translateSkuPartToAttributeKey),
        },
        userInterfaceConfig: {
            UI_CONFIG: {
                shared: {
                    initialActiveSide: {},
                },
            },
            productPreferredColors: {},
        },
        quantitiesSpec: {},
        renderSpec: {
            productRenderConfig: {},
            sidesMapping: {},
        },
        const: {
            PRODUCT_TYPE: "samplePack",
        },
        productionTimes: {
            productionTime: {
                default: 2,
            },
        },
        variant: {
            prefix: "Sample",
        },
    };
};
export default SamplePackConfigBuilder;
