import React from "react"
import { observer } from "mobx-react-lite"
import cxBinder from "classnames/bind"
import styles from "./Rulers.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { Dimensions } from "../../atoms/dimensions/dimensions"

const cx = cxBinder.bind(styles)

export default observer(function EditZoneRulers() {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.envUtil,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, envUtil } = containerSet
  const { productDriver } = designAndProductDriver
  const { appConfig } = envUtil
  const {
    activeContext,
    activeSpace,
    productRenderPilot,
    isZoomActive,
    isRendererLoading,
  } = productDriver.state

  if (!activeContext || !activeSpace || isRendererLoading) {
    return null
  }

  const dielineNavigator = productDriver.getDielineNavigator()
  const editZoneBackground = dielineNavigator.getTempBackground()

  if (
    dielineNavigator.isSpaceLoading ||
    editZoneBackground === undefined ||
    editZoneBackground.angle === undefined
  ) {
    return null
  }

  const { heightCm, widthCm } = productRenderPilot.getSpaceDimensions(
    activeContext,
    activeSpace
  )

  const isRotated = Math.abs(editZoneBackground.angle) === 90

  const editZoneSize = productDriver.getDielineNavigator().editZoneSize

  if (editZoneSize === null) {
    return null
  }

  const rulerWidth = isRotated ? editZoneSize.height : editZoneSize.width
  const rulerHeight = isRotated ? editZoneSize.width : editZoneSize.height
  const MANUALLY_ADJUSTED_RULER_OFFSET = 35

  const editZoneConfig = productRenderPilot.uiConfig.editZone
  const rulersConfig = editZoneConfig.rulers
  const controlsTheme = editZoneConfig.controlsTheme

  const rulersClassName = cx({
    edit_zone_ruler: true,
    /**
     * Edit Zone Rulers are located outside Edit Zone,
     * so if zoom is active we need to show dashed lines.
     */
    edit_zone_ruler_dashed: isZoomActive || rulersConfig.lines === "dashed",
    /**
     * Edit Zone Rulers are located outside Edit Zone.
     *
     * If zoom is active (no product background),
     * rulers are placed on a light background (page background, which is light gray),
     * so they need to be dark.
     */
    edit_zone_ruler_light: !isZoomActive && controlsTheme === "light",
  })

  return (
    <div className={styles.rulers_container}>
      <div
        className={rulersClassName}
        style={{
          width: rulerWidth,
          top: `calc(50% - ${
            rulerHeight / 2 + MANUALLY_ADJUSTED_RULER_OFFSET
          }px)`,
          left: `calc(50% - ${rulerWidth / 2}px)`,
        }}
      >
        {rulersConfig.dimensions && (
          <span>
            <Dimensions
              dimensionsCm={[widthCm]}
              displayUnit={appConfig.locale.displayUnit}
            />
          </span>
        )}
      </div>

      <div
        className={rulersClassName}
        style={{
          width: rulerHeight,
          top: `calc(50% - ${rulerHeight / 2}px)`,
          left: `calc(50% - ${
            rulerWidth / 2 + MANUALLY_ADJUSTED_RULER_OFFSET
          }px)`,
          transform: `translateY(${rulerHeight}px) rotate(-90deg)`,
          transformOrigin: "left top",
        }}
      >
        {rulersConfig.dimensions && (
          <span>
            <Dimensions
              dimensionsCm={[heightCm]}
              displayUnit={appConfig.locale.displayUnit}
            />
          </span>
        )}
      </div>
    </div>
  )
})
