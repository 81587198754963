import React, { PropsWithChildren } from "react"
import cxBinder from "classnames/bind"

import styles from "./HorizontalTabs.module.scss"
import { DotAlert } from "../dot-alert/dot-alert"

const cx = cxBinder.bind(styles)

interface HorizontalTabProps {
  active?: boolean
  onClick: () => void
  e2eTarget?: string
  e2eTargetName?: string
  hasError?: boolean
}

export const HorizontalTab = ({
  active = false,
  children,
  onClick,
  e2eTarget,
  e2eTargetName,
  hasError = false,
}: PropsWithChildren<HorizontalTabProps>) => {
  return (
    <li
      className={cx({
        tabs__tab: true,
        "tabs__tab--active": active,
      })}
      onClick={onClick}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <span className={styles.tabs__tab_label}>
        {children}
        {hasError && (
          <div className={styles.alert_wrapper}>
            <DotAlert />
          </div>
        )}
      </span>
    </li>
  )
}

export const HorizontalTabs = ({ children }: PropsWithChildren<{}>) => (
  <ul className={styles.tabs}>{children}</ul>
)
