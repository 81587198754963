var _a;
import PAPER_MAILER_CONSTS from "../generalConfig/consts/paperMailerConsts";
var PAPER_MAILER_RENDER_CONFIG = (_a = {},
    _a[PAPER_MAILER_CONSTS.PAPER_MAILER] = {
        Y60: {
            width: 250,
            height: 350,
            length: 50,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 15,
                        left: 40,
                        width: 170,
                        height: 270,
                    },
                    back: {
                        top: 114,
                        left: 40,
                        width: 170,
                        height: 175,
                    },
                },
            },
        },
        Y80: {
            width: 350,
            height: 450,
            length: 80,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 15,
                        left: 55,
                        width: 240,
                        height: 340,
                    },
                    back: {
                        top: 114,
                        left: 55,
                        width: 240,
                        height: 240,
                    },
                },
            },
        },
        Y90: {
            width: 400,
            height: 500,
            length: 100,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 15,
                        left: 65,
                        width: 270,
                        height: 370,
                    },
                    back: {
                        top: 114,
                        left: 65,
                        width: 270,
                        height: 270,
                    },
                },
            },
        },
    },
    _a);
export default PAPER_MAILER_RENDER_CONFIG;
