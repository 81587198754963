var _a, _b, _c, _d;
import BOX_CONSTS from "../consts/boxConsts";
export default (_a = {},
    _a[BOX_CONSTS.SHIPPING_BOX_PLAIN] = {
        sizes: [90, 105, 130, 150, 180, 200],
        prefix: "K",
        productCategory: BOX_CONSTS.SHIPPING_BOX,
        addonsInVariants: [],
        variants: (_b = {},
            _b[BOX_CONSTS.SHIPPING_BOX_PLAIN_DEFAULT_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_NATURAL_CARDBOARD],
                colorModes: [BOX_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
                newPrice: true,
            },
            _b),
    },
    _a[BOX_CONSTS.SHIPPING_BOX] = {
        sizes: [90, 105, 130, 150, 180, 200],
        prefix: "K",
        addonsInVariants: [
            BOX_CONSTS.PRINT_FINISH_FOIL_GLOSSY,
            BOX_CONSTS.PRINT_FINISH_FOIL_MATTE,
            BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD_WHITE_INSIDE,
            BOX_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE,
        ],
        productCategory: BOX_CONSTS.SHIPPING_BOX,
        variants: (_c = {},
            _c[BOX_CONSTS.SHIPPING_BOX_DEFAULT_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_NATURAL_CARDBOARD],
                colorModes: [BOX_CONSTS.PRINT_MODE_MONOCHROME],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
                newPrice: true,
            },
            _c),
    },
    _a[BOX_CONSTS.SHIPPING_BOX_PLAIN_V2] = {
        sizes: [
            1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
        ],
        prefix: "K",
        addonsInVariants: [],
        productCategory: BOX_CONSTS.SHIPPING_BOX,
        variants: (_d = {},
            _d[BOX_CONSTS.SHIPPING_BOX_PLAIN_V2_DEFAULT_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_NATURAL_CARDBOARD],
                colorModes: [BOX_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
                newPrice: true,
            },
            _d),
    },
    _a);
