import React, { PropsWithChildren } from "react"
import cxBinder from "classnames/bind"
import { Card } from "dsl/src/atoms/Card/Card"

import styles from "./CardTool.module.scss"

const cx = cxBinder.bind(styles)

interface CardToolProps {
  sizeXs?: boolean
  sizeM?: boolean
  e2eTarget?: string
  e2eTargetName?: string
  cardName?: string
}

export const CardTool = ({
  sizeXs,
  sizeM,
  e2eTargetName,
  e2eTarget,
  children,
}: PropsWithChildren<CardToolProps>) => {
  return (
    <Card
      e2eTarget={e2eTarget}
      e2eTargetName={e2eTargetName}
      className={cx({
        card_tool: true,
        sizeXs,
        sizeM,
      })}
    >
      {children}
    </Card>
  )
}
