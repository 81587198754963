var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
import BOX_CONSTS from "../consts/boxConsts";
var DEFAULT = "default";
var BOX_PRODUCT_PRE_PRINTED_MAILER_BOX_FULL_COLOUR_WEIGHTS = (_a = {},
    _a[DEFAULT] = {
        F52: 0.08,
        F71: 0.15,
    },
    _a);
var BOX_PRODUCT_PRE_PRINTED_MAILER_BOX_ECO_WEIGHTS = (_b = {},
    _b[DEFAULT] = {
        F52: 0.16,
        F71: 0.2,
    },
    _b);
var BOX_PRODUCT_MAILER_BOX_ECO_WEIGHTS = (_c = {},
    _c[DEFAULT] = {
        F23: 0.02,
        F33: 0.05,
        F44: 0.09,
        F45: 0.07,
        F46: 0.05,
        F52: 0.08,
        F56: 0.13,
        F59: 0.13,
        F62: 0.13,
        F65: 0.13,
        F71: 0.2,
        F79: 0.28,
        F93: 0.34,
        F112: 0.5,
    },
    _c);
var BOX_PRODUCT_MAILER_BOX_ECO_COLOR_WEIGHTS = (_d = {},
    _d[DEFAULT] = {
        F23: 0.02,
        F33: 0.65,
        F44: 0.105,
        F45: 0.09,
        F46: 0.06,
        F52: 0.1,
        F56: 0.145,
        F59: 0.15,
        F62: 0.165,
        F65: 0.135,
        F71: 0.21,
        F79: 0.305,
        F93: 0.375,
        F112: 0.65,
    },
    _d);
var BOX_PRODUCT_MAILER_BOX_FULL_COLOR_WEIGHTS = (_e = {},
    _e[DEFAULT] = {
        F23: 0.06,
        F33: 0.125,
        F44: 0.17,
        F45: 0.17,
        F52: 0.17,
        F56: 0.25,
        F59: 0.218,
        F62: 0.275,
        F71: 0.38,
        F79: 0.3,
        F93: 0.715,
        F112: 0.95,
    },
    _e);
var BOX_PRODUCT_MAILER_BOX_PLAIN_WEIGHTS = (_f = {},
    _f[DEFAULT] = {
        F23: 0.02,
        F33: 0.05,
        F44: 0.09,
        F45: 0.07,
        F46: 0.5,
        F52: 0.08,
        F56: 0.13,
        F59: 0.13,
        F62: 0.13,
        F65: 0.13,
        F71: 0.2,
        F79: 0.28,
        F93: 0.34,
        F112: 0.5,
    },
    _f);
var BOX_PRODUCT_SLEEVED_MAILER_BOX_WEIGHTS = (_g = {},
    _g[DEFAULT] = {
        FS23: 0.02,
        FS33: 0.05,
        FS44: 0.09,
        FS45: 0.07,
        FS46: 0.05,
        FS52: 0.08,
        FS56: 0.13,
        FS59: 0.13,
        FS62: 0.13,
        FS65: 0.13,
        FS71: 0.2,
        FS79: 0.28,
    },
    _g);
var BOX_PRODUCT_MAILER_BOX_WHITE_INK_WEIGHTS = (_h = {},
    _h[DEFAULT] = {
        F23: 0.02,
        F33: 0.04,
        F44: 0.08,
        F45: 0.06,
        F46: 0.033,
        F52: 0.08,
        F56: 0.1,
        F59: 0.1,
        F62: 0.1,
        F65: 0.074,
        F71: 0.15,
        F79: 0.15,
        F93: 0.26,
        F112: 0.43,
    },
    _h);
var BOX_PRODUCT_DELIVERY_MAILER_BOX_WEIGHTS = (_j = {},
    _j[DEFAULT] = {
        AS55: 0.011,
        AS73: 0.018,
        AS80: 0.029,
        AS91: 0.038,
    },
    _j);
export default (_k = {},
    _k[BOX_CONSTS.MAILER_BOX] = (_l = {},
        _l[BOX_CONSTS.MAILER_BOX_ECO_VARIANT] = BOX_PRODUCT_MAILER_BOX_ECO_WEIGHTS,
        _l[BOX_CONSTS.MAILER_BOX_ECO_WHITE_VARIANT] = BOX_PRODUCT_MAILER_BOX_ECO_WEIGHTS,
        _l[BOX_CONSTS.MAILER_BOX_ECO_COLOR_VARIANT] = BOX_PRODUCT_MAILER_BOX_ECO_COLOR_WEIGHTS,
        _l[BOX_CONSTS.MAILER_BOX_FULL_COLOR_VARIANT] = BOX_PRODUCT_MAILER_BOX_FULL_COLOR_WEIGHTS,
        _l[BOX_CONSTS.MAILER_BOX_LITE_COLOR_VARIANT] = BOX_PRODUCT_MAILER_BOX_ECO_WEIGHTS,
        _l),
    _k[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR] = (_m = {},
        _m[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR_VARIANT] = BOX_PRODUCT_PRE_PRINTED_MAILER_BOX_FULL_COLOUR_WEIGHTS,
        _m),
    _k[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO] = (_o = {},
        _o[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO_VARIANT] = BOX_PRODUCT_PRE_PRINTED_MAILER_BOX_ECO_WEIGHTS,
        _o),
    _k[BOX_CONSTS.MAILER_BOX_WHITE_INK] = (_p = {},
        _p[BOX_CONSTS.MAILER_BOX_WHITE_ON_KRAFT_VARIANT] = BOX_PRODUCT_MAILER_BOX_WHITE_INK_WEIGHTS,
        _p),
    _k[BOX_CONSTS.MAILER_BOX_PLAIN] = (_q = {},
        _q[BOX_CONSTS.MAILER_BOX_PLAIN_DEFAULT_VARIANT] = BOX_PRODUCT_MAILER_BOX_PLAIN_WEIGHTS,
        _q[BOX_CONSTS.MAILER_BOX_PLAIN_WHITE_VARIANT] = BOX_PRODUCT_MAILER_BOX_PLAIN_WEIGHTS,
        _q),
    _k[BOX_CONSTS.SLEEVED_MAILER_BOX] = (_r = {},
        _r[BOX_CONSTS.SLEEVED_MAILER_BOX_VARIANT] = BOX_PRODUCT_SLEEVED_MAILER_BOX_WEIGHTS,
        _r),
    _k[BOX_CONSTS.DELIVERY_MAILER_BOX] = (_s = {},
        _s[BOX_CONSTS.DELIVERY_MAILER_BOX_VARIANT] = BOX_PRODUCT_DELIVERY_MAILER_BOX_WEIGHTS,
        _s),
    _k[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN] = (_t = {},
        _t[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN_VARIANT] = BOX_PRODUCT_DELIVERY_MAILER_BOX_WEIGHTS,
        _t[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN_WHITE_VARIANT] = BOX_PRODUCT_DELIVERY_MAILER_BOX_WEIGHTS,
        _t),
    _k);
