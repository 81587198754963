import React from "react"
import { observer } from "mobx-react-lite"
import { EditorHeaderAssetToolbar } from "./EditorHeaderAssetToolbar/EditorHeaderAssetToolbar"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { EditorHeaderSpaceToolbar } from "../tools/two-dim-toolbars/edit-tool-edit-space/EditorToolEditSpace"
import { ProjectActions } from "./EditorHeaderProjectActions/ProjectActions"
import { ProjectName } from "./EditorHeaderProjectActions/ProjectName"
import cxBinder from "classnames/bind"
import styles from "./EditorSecondaryTopBar.module.scss"
const cx = cxBinder.bind(styles)

export const EditorSecondaryTopBar = observer(() => {
  const [headerSet, headerSetErrors] = useContainerSet((c) => [
    c.designAndProductDriver,
  ])

  if (headerSet == null || headerSetErrors != null) return null
  const { designAndProductDriver } = headerSet

  const { activeObjectDriver } = designAndProductDriver.productDriver
  const { activeObject, activeObjectType } = activeObjectDriver
  const { activeSpace, productRenderPilot } =
    designAndProductDriver.productDriver.state

  const is3DProduct = productRenderPilot.is3DProduct()
  const isSpaceView = !!activeSpace

  const showActiveObjectToolbar = !!activeSpace && !!activeObjectType
  const showProjectName =
    !showActiveObjectToolbar && (!is3DProduct || !isSpaceView)
  const showSpaceToolbar = !showActiveObjectToolbar && isSpaceView
  const showProjectActions =
    !showActiveObjectToolbar && (!is3DProduct || !isSpaceView)

  return (
    <header
      className={styles.editor_header}
      e2e-target="editor-order-secondary-flow-bar"
    >
      {showProjectName && (
        <div
          className={cx("name_wrapper", {
            "name_wrapper--bordered": showSpaceToolbar,
          })}
        >
          <ProjectName />
        </div>
      )}
      {showActiveObjectToolbar && (
        <EditorHeaderAssetToolbar activeObject={activeObject} />
      )}
      {showSpaceToolbar && <EditorHeaderSpaceToolbar />}
      {showProjectActions && <ProjectActions />}
    </header>
  )
})
