var _a, _b, _c, _d;
import BAG_CONSTS from "../consts/bagConsts";
var BAG_PRODUCT_VARIANTS = (_a = {},
    _a[BAG_CONSTS.PAPER_BAG] = {
        sizes: [66, 70, 82, 95, 107],
        prefix: "B",
        addonsInVariants: [],
        productCategory: BAG_CONSTS.PAPER_BAG,
        variants: (_b = {},
            _b[BAG_CONSTS.PAPER_BAG_VARIANT_STANDARD] = {
                materials: [
                    BAG_CONSTS.PRINT_MATERIAL_PAPER_WHITE,
                    BAG_CONSTS.PRINT_MATERIAL_PAPER_NATURAL,
                ],
                colorModes: [
                    BAG_CONSTS.PRINT_MODE_MONO_PANTONE_ONE_SIDE,
                    BAG_CONSTS.PRINT_MODE_MONO_PANTONE_TWO_SIDES,
                ],
                finishes: [BAG_CONSTS.PRINT_FINISH_NONE],
            },
            _b),
    },
    _a[BAG_CONSTS.DOYPACK_BAG] = {
        sizes: [35, 42, 48, 56],
        prefix: "D",
        addonsInVariants: [],
        productCategory: BAG_CONSTS.DOYPACK_BAG,
        variants: (_c = {},
            _c[BAG_CONSTS.DOYPACK_BAG_VARIANT] = {
                materials: [
                    BAG_CONSTS.PRINT_MATERIAL_WHITE,
                    BAG_CONSTS.PRINT_MATERIAL_KRAFT,
                    BAG_CONSTS.PRINT_MATERIAL_BLACK,
                ],
                colorModes: [BAG_CONSTS.PRINT_MODE_INDIGO_COLOR],
                finishes: [BAG_CONSTS.PRINT_FINISH_NONE],
            },
            _c),
    },
    _a[BAG_CONSTS.TOTE_BAG] = {
        sizes: [79],
        prefix: "TB",
        addonsInVariants: [],
        productCategory: BAG_CONSTS.TOTE_BAG,
        variants: (_d = {},
            _d[BAG_CONSTS.TOTE_BAG_VARIANT] = {
                materials: [
                    BAG_CONSTS.PRINT_MATERIAL_ORGANIC_COTTON,
                    BAG_CONSTS.PRINT_MATERIAL_RECYCLED_COTTON,
                ],
                colorModes: [
                    BAG_CONSTS.PRINT_MODE_MONO_PANTONE_ONE_SIDE,
                    BAG_CONSTS.PRINT_MODE_MONO_PANTONE_TWO_SIDES,
                ],
                finishes: [BAG_CONSTS.PRINT_FINISH_NONE],
            },
            _d),
    },
    _a);
export default BAG_PRODUCT_VARIANTS;
