import axios, { AxiosRequestConfig, AxiosInstance } from "axios"
import { Debug } from "../../services/logger"
const debug = Debug("ph:editor:api")

type ApiConfig = {
  axios?: AxiosRequestConfig
}

export class ApiError extends Error {}
export class ApiAuthError extends ApiError {}
export class ApiMissingAssetError extends ApiError {}

export class AxiosService {
  public ax: AxiosInstance

  constructor(config: ApiConfig) {
    const axiosOptions: AxiosRequestConfig = {
      timeout: 65000,
    }

    const axiosInstance = axios.create({
      ...axiosOptions,
      ...config.axios,
    })
    this.ax = axiosInstance

    // // Add a response interceptor
    axiosInstance.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (axios.isAxiosError(error)) {
            if (error.response.status === 403) {
              throw new ApiAuthError()
            }

            if (error.response.status === 404) {
              throw new ApiMissingAssetError()
            }
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          debug(
            "No response from the server! API endpoint is incorrect or API server is offline"
          )
          console.log(error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message)
        }

        //console.log(error.config)
        return Promise.reject(error)
      }
    )

    return this
  }
}

export default AxiosService
