var _a;
import BOX_CONSTS from "./consts/boxConsts";
export default (_a = {
        default: {
            DEFAULT_QUANTITY: 30,
            QUANTITY_RANGES: [
                30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500, 3000, 4000, 5000,
                /* 10000, */
            ],
            QUANTITY_DENSITY: 500,
            QUANTITY_STEP: 10,
        }
    },
    _a[BOX_CONSTS.SHIPPING_BOX_PLAIN_DEFAULT_VARIANT] = {
        DEFAULT_QUANTITY: 30,
        QUANTITY_RANGES: [
            30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500, 3000, 4000, 5000,
            10000,
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a[BOX_CONSTS.SHIPPING_BOX_PLAIN_V2_DEFAULT_VARIANT] = {
        DEFAULT_QUANTITY: 20,
        QUANTITY_RANGES: [
            20, 40, 60, 90, 100, 160, 220, 280, 340, 480, 820, 1040, 1800, 2880,
            // 5750, 7480, 10080 - temporarily disabled
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a[BOX_CONSTS.MAILER_BOX_PLAIN_DEFAULT_VARIANT] = {
        DEFAULT_QUANTITY: 30,
        QUANTITY_RANGES: [
            30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500, 5000 /* 10000, */,
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a[BOX_CONSTS.MAILER_BOX_PLAIN_WHITE_VARIANT] = {
        DEFAULT_QUANTITY: 30,
        QUANTITY_RANGES: [
            30, 60, 90, 120, 240, 600, 750, 900, 1500, 2100, 2500, 5000 /* 10000, */,
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 30,
    },
    _a[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO] = {
        DEFAULT_QUANTITY: 30,
        QUANTITY_RANGES: [
            30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500, 5000 /* 10000, */,
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR] = {
        DEFAULT_QUANTITY: 30,
        QUANTITY_RANGES: [
            30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500, 5000 /* 10000, */,
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a[BOX_CONSTS.MAILER_BOX_ECO_COLOR] = {
        DEFAULT_QUANTITY: 30,
        QUANTITY_RANGES: [
            30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500, 3000, 4000, 5000,
            /* 10000, */
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX] = {
        DEFAULT_QUANTITY: 100,
        QUANTITY_RANGES: [
            100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
            1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400, 2500, 5000,
            /* 10000, */
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN] = {
        DEFAULT_QUANTITY: 120,
        QUANTITY_RANGES: [
            30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500, 5000 /* 10000, */,
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a[BOX_CONSTS.MAILER_BOX_WHITE_INK] = {
        DEFAULT_QUANTITY: 30,
        QUANTITY_RANGES: [30, 60, 90, 120, 240, 500, 1000, 2500, 5000],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a);
