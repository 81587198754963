import { DielineGenerator } from "./dieline.generator"
import { ProductRenderPilot } from "../../../libs/products-render-config/product-render-pilot"

import { Product } from "@ph/product-api"
import { EditContext } from "../../../libs/products-render-config/types"

export class VirtualDielineUrlProvider {
  constructor(private readonly dielineGenerator: DielineGenerator) {}

  public async get(
    productRenderPilot: ProductRenderPilot,
    editContext: EditContext
  ): Promise<string | undefined> {
    const predefinedDielineUrl =
      productRenderPilot.getPredefinedVirtualDielineUrl(editContext)

    if (predefinedDielineUrl) {
      return predefinedDielineUrl
    }

    return this.generateDieline(productRenderPilot.getProduct())
  }

  private async generateDieline(product: Product): Promise<string | undefined> {
    const dielineUrl = await this.dielineGenerator.generate(product, "svg")

    if (!dielineUrl) {
      return
    }

    const url = new URL(dielineUrl)
    url.searchParams.set("cb", Date.now().toString())

    return url.toString()
  }
}
