import { BackgroundLayerDefinition } from "../../types"
import VirtualDielineEditor from "../../../../../virtual-dieline-editor"
import {
  PackhelpCanvas,
  PackhelpEditableObject,
  PackhelpMaskObject,
  PackhelpObject,
} from "../../../../../object-extensions/packhelp-objects"
import { TempLayerClippingMaskCalculator } from "../../calculators/temp-layer-clipping-mask-calculator"
import { GlobalLayerClippingMaskCalculator } from "../../calculators/global-layer-clipping-mask-calculator"
import {
  isGroup,
  isInteractiveCanvas,
} from "../../../../../../../../modules/ph-api/asset-types"
import { MaskCalculator } from "../../calculators/mask-calculator"
import { MaskNotReadyError, NotValidOperationError } from "../../errors"
import { MaskParentCalculator } from "../../calculators/mask-parent-calculator"
import { ClippingHelper } from "../../../helpers/clipping-helper"

export class BackgroundLayerController {
  private readonly globalClippingMaskCalculator: GlobalLayerClippingMaskCalculator
  private maskCalculator?: MaskCalculator
  private tempClippingMaskCalculator?: TempLayerClippingMaskCalculator
  private maskParentCalculator: MaskParentCalculator

  private objectMask?: PackhelpMaskObject
  private globalClippingMask?: PackhelpMaskObject
  private tempClippingMask?: PackhelpMaskObject

  constructor(
    private readonly vdEditor: VirtualDielineEditor,
    private maskParent: PackhelpEditableObject,
    private readonly definition: BackgroundLayerDefinition
  ) {
    this.globalClippingMaskCalculator = new GlobalLayerClippingMaskCalculator()
    this.maskParentCalculator = new MaskParentCalculator(this.maskParent)
  }

  public async initTempClippingMask(
    objectMask: PackhelpMaskObject,
    clippingMask: PackhelpMaskObject
  ): Promise<void> {
    this.objectMask = objectMask
    this.tempClippingMask = await this.cloneMaskObject(clippingMask)

    this.tempClippingMaskCalculator = new TempLayerClippingMaskCalculator(
      this.maskParent,
      objectMask,
      this.tempClippingMask
    )
    this.maskCalculator = new MaskCalculator(this.maskParent, objectMask)
  }

  public async initGlobalClippingMask(
    fallbackMask: PackhelpMaskObject
  ): Promise<void> {
    const clippingMaskOnGlobalLayer = this.getClippingMaskFromGlobalLayer()

    if (clippingMaskOnGlobalLayer) {
      this.globalClippingMask = clippingMaskOnGlobalLayer
    } else {
      this.globalClippingMask = await this.cloneMaskObject(fallbackMask)
    }
  }

  public applyGlobalClippingMask() {
    const globalLayer = this.getGlobalLayer()

    if (!globalLayer || !this.globalClippingMask) {
      return
    }

    if (!globalLayer.clipPath) {
      globalLayer.clipPath = ClippingHelper.buildClipPathGroup()
    }

    if (isGroup(globalLayer.clipPath)) {
      const existingGlobalClippingMask = this.getClippingMaskFromGlobalLayer()

      if (existingGlobalClippingMask) {
        globalLayer.clipPath.remove(existingGlobalClippingMask)
      }

      globalLayer.clipPath.add(this.globalClippingMask)
    }
  }

  public applyTempClippingMask() {
    const tempLayer = this.getTempLayer()

    if (!tempLayer || !this.tempClippingMask) {
      return
    }

    if (!tempLayer.clipPath) {
      throw new NotValidOperationError("Temp layer clip path does not exist!")
    }

    if (!tempLayer.clipPath) {
      tempLayer.clipPath = ClippingHelper.buildClipPathGroup()
    }

    if (isGroup(tempLayer.clipPath)) {
      const existingTempClippingMask = this.getClippingMaskFromTempLayer()

      if (existingTempClippingMask) {
        tempLayer.clipPath.remove(existingTempClippingMask)
      }

      tempLayer.clipPath.add(this.tempClippingMask)
    }
  }

  public setTempClippingMaskPosition() {
    if (!this.tempClippingMask || !this.tempClippingMaskCalculator) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const { top, left } = this.tempClippingMaskCalculator.calcPosition()
    let angle = this.maskParent.angle!

    if (this.maskParent.group) {
      angle += this.maskParent.group.angle!
    }

    this.tempClippingMask.set({ left, top, angle })
  }

  public setGlobalClippingMaskPosition() {
    if (!this.globalClippingMask || !this.objectMask) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const rotation = this.getCurrentRotation()
    const position = this.globalClippingMaskCalculator.calcPosition(
      this.objectMask,
      rotation
    )

    this.globalClippingMask.set(position)
  }

  public setTempClippingMaskScale() {
    if (!this.tempClippingMask || !this.tempClippingMaskCalculator) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskScale = this.tempClippingMaskCalculator.calcScale()
    this.tempClippingMask.set(maskScale)

    this.setTempClippingMaskBorderRadius()
  }

  public setGlobalClippingMaskScale() {
    if (!this.globalClippingMask || !this.objectMask) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskScale = {
      scaleX: this.objectMask.scaleX!,
      scaleY: this.objectMask.scaleY!,
    }
    this.globalClippingMask.set(maskScale)

    this.setGlobalClippingMaskBorderRadius()
  }

  private setTempClippingMaskBorderRadius() {
    if (!this.maskCalculator || !this.tempClippingMask) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskBorderRadius = this.maskCalculator.calcBorderRadius()
    this.tempClippingMask.set(maskBorderRadius)
  }

  private setGlobalClippingMaskBorderRadius() {
    if (!this.maskCalculator || !this.globalClippingMask) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskBorderRadius = this.maskCalculator.calcBorderRadius()
    this.globalClippingMask.set(maskBorderRadius)
  }

  public setRotation() {
    if (!this.tempClippingMask || !this.globalClippingMask) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskParentRotation = this.maskParentCalculator.calcRotation()
    const currentRotation = this.getCurrentRotation()

    this.tempClippingMask.rotate(maskParentRotation - currentRotation)
    this.globalClippingMask.rotate(maskParentRotation - currentRotation)
  }

  public setVisibility(isVisible: boolean) {
    const isSelectable =
      isVisible && isInteractiveCanvas(this.canvas) && this.canvas.selection

    if (this.globalClippingMask) {
      this.globalClippingMask.set({
        visible: isVisible,
        selectable: isSelectable,
        evented: isSelectable,
      })
    }

    if (this.tempClippingMask) {
      this.tempClippingMask.set({
        visible: isVisible,
        selectable: isSelectable,
        evented: isSelectable,
      })
    }
  }

  public clear() {
    const globalLayer = this.getGlobalLayer()

    if (
      this.globalClippingMask &&
      globalLayer?.clipPath &&
      isGroup(globalLayer.clipPath)
    ) {
      globalLayer.clipPath.remove(this.globalClippingMask)
    }

    const tempLayer = this.getTempLayer()

    if (
      this.tempClippingMask &&
      tempLayer?.clipPath &&
      isGroup(tempLayer.clipPath)
    ) {
      tempLayer.clipPath.remove(this.tempClippingMask)
    }

    this.clearTemp()
  }

  public changeParent(newParent: PackhelpEditableObject) {
    this.maskParent = newParent
    this.maskParentCalculator = new MaskParentCalculator(this.maskParent)

    if (this.objectMask) {
      this.maskCalculator = new MaskCalculator(newParent, this.objectMask)
    }

    if (this.tempClippingMask) {
      this.tempClippingMask.set({ maskParentId: newParent.id })

      this.tempClippingMaskCalculator = new TempLayerClippingMaskCalculator(
        this.maskParent,
        this.objectMask!,
        this.tempClippingMask
      )
    }

    if (this.globalClippingMask) {
      this.globalClippingMask.set({ maskParentId: newParent.id })
    }
  }

  public clearTemp() {
    this.objectMask = undefined
    this.tempClippingMask = undefined
    this.tempClippingMaskCalculator = undefined
  }

  public getGlobalLayer(): PackhelpObject | undefined {
    return this.vdEditor.getCanvasObjectById(this.definition.globalId)
  }

  public getTempLayer(): PackhelpObject | undefined {
    return this.vdEditor.getCanvasObjectById(this.definition.tempId)
  }

  private getClippingMaskFromGlobalLayer(): PackhelpMaskObject | undefined {
    const globalLayer = this.getGlobalLayer()

    if (
      !globalLayer ||
      !globalLayer.clipPath ||
      !isGroup(globalLayer.clipPath)
    ) {
      return
    }

    return globalLayer.clipPath
      .getObjects()
      .find(
        (object) => object.maskParentId === this.maskParent.id
      ) as PackhelpMaskObject
  }

  private getClippingMaskFromTempLayer(): PackhelpMaskObject | undefined {
    const tempLayer = this.getTempLayer()

    if (!tempLayer || !tempLayer.clipPath || !isGroup(tempLayer.clipPath)) {
      return
    }

    return tempLayer.clipPath
      .getObjects()
      .find(
        (object) => object.maskParentId === this.maskParent.id
      ) as PackhelpMaskObject
  }

  private async cloneMaskObject(
    object: PackhelpMaskObject
  ): Promise<PackhelpMaskObject> {
    return new Promise((resolve) => {
      object.clone(
        async (clonedObject) => {
          resolve(clonedObject)
        },
        ["maskParentId", "assetObjectMeta", "keepRatio", "borderRadius"]
      )
    })
  }

  private getCurrentRotation(): number {
    return this.vdEditor.dielineNavigator.getCurrentRotation()
  }

  private get canvas(): PackhelpCanvas {
    return this.vdEditor.fabricCanvas
  }
}
