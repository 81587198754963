import { useState, useEffect } from "react"
import { useDeveloperMode } from "./useDeveloperMode"

export enum KeyboardEventKey {
  space = "Space",
}

function getEventKey(e: KeyboardEvent): KeyboardEventKey | undefined {
  if (e.code === "Space" || e.key === " " || e.key === "Spacebar")
    return KeyboardEventKey.space
  return undefined
}

export function useToggleOnKeyPress(
  onPress: () => void,
  isTogglingLocked: boolean,
  key: KeyboardEventKey,
  timeout?: number
) {
  const [isPress, setPress] = useState(false)
  const [isHidden, setHidden] = useState(false)

  const { enabled: enabledDeveloperMode } = useDeveloperMode()

  let releaseTimer
  function handlePress(e: KeyboardEvent) {
    if (getEventKey(e) === key && !isPress && !isTogglingLocked) {
      setPress(true)
      setHidden(false)
      onPress()
    }
  }

  const releasePress = () => {
    releaseTimer = setTimeout(() => {
      setPress(false)
    }, 500)
  }

  useEffect(() => {
    if (isHidden) {
      return
    }

    const hasAutohide = timeout !== undefined && timeout > 0
    const timer = hasAutohide
      ? setTimeout(() => {
          setHidden(true)
        }, timeout)
      : undefined

    return () => {
      releaseTimer && clearTimeout(releaseTimer)
      timer && clearTimeout(timer)
    }
  }, [isHidden, timeout])

  useEffect(() => {
    if (!enabledDeveloperMode) {
      window.addEventListener("keydown", handlePress)
      window.addEventListener("keyup", releasePress)

      return () => {
        window.removeEventListener("keydown", handlePress)
        window.removeEventListener("keyup", releasePress)
      }
    }
  })

  return {
    isHidden,
  }
}
