import { ShapesStore } from "../shapes-store/shapes.store"
import ProductDriver from "../product-driver/product.driver"
import { AllEditorEventsEmitter, eventTree } from "../editor-events"
import { PackhelpEditableObject } from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { CanvasObjectControllerFactory } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/canvas-object-controller-factory"
import VirtualDielineEditor from "../../render-engine/modules/vd-editor/virtual-dieline-editor"
import { Shape } from "../../libs/value-objects/shape"
import Colour from "../../libs/value-objects/colour"
import { AvailableColourModes } from "../../libs/products-render-config/types"
import { isInteractiveCanvas } from "../../modules/ph-api/asset-types"

export class MaskUiController {
  constructor(
    private readonly shapesStore: ShapesStore,
    private readonly productDriver: ProductDriver,
    private readonly ee: AllEditorEventsEmitter
  ) {}

  public isMaskUiAvailable(): boolean {
    const { productRenderPilot, activeContext } = this.productDriver.state

    if (!productRenderPilot.uiConfig.features.mask) {
      return false
    }

    const isMonochrome =
      productRenderPilot.getColorMode() === AvailableColourModes.MONOCHROME
    const isMonoPantone =
      productRenderPilot.getColorMode() === AvailableColourModes.MONO_PANTONE

    const isObjectMaskAvailable = !isMonochrome && !isMonoPantone
    const isClippingMaskAvailable =
      this.productDriver.backgroundsDriver.isClippingMaskAvailable(
        activeContext
      )

    return isObjectMaskAvailable || isClippingMaskAvailable
  }

  public async toggleMask(object: PackhelpEditableObject): Promise<void> {
    const vdEditor = this.getVirtualDielinEditor()

    if (!isInteractiveCanvas(vdEditor.fabricCanvas)) {
      return
    }

    const objectController = new CanvasObjectControllerFactory(
      vdEditor
    ).getController(object)

    if (objectController.hasMask()) {
      vdEditor.fabricCanvas.setActiveObject(object)
      object.maskController?.dispose()
    } else {
      await vdEditor.assetsModule.createMaskAndAttachItToObject(object)
    }

    this.ee.emit(
      eventTree.productDriver.maskToggled,
      object,
      objectController.hasMask()
    )
  }

  public getMaskShape(object: PackhelpEditableObject): Shape {
    if (object.maskController) {
      return object.maskController.getShape()
    }

    return this.shapesStore.maskShapes[0]
  }

  public getMaskColor(object: PackhelpEditableObject): Colour {
    if (!object.maskController) {
      return new Colour()
    }

    return object.maskController.getColor()
  }

  public async changeMaskShape(
    object: PackhelpEditableObject,
    shape: Shape
  ): Promise<void> {
    const maskController = object.maskController

    if (!maskController) {
      return
    }

    this.shapesStore.setIsLoadingSingleShape(true)

    await maskController.changeShape(shape)

    this.ee.emit(
      eventTree.productDriver.maskModified,
      object,
      maskController.getShape(),
      maskController.getColor().getHex()
    )

    this.shapesStore.setIsLoadingSingleShape(false)
  }

  public async changeMaskColor(
    object: PackhelpEditableObject,
    color: Colour
  ): Promise<void> {
    const maskController = object.maskController

    if (!maskController) {
      return
    }

    await maskController.changeColor(color)

    this.ee.emit(
      eventTree.productDriver.maskModified,
      object,
      maskController.getShape(),
      maskController.getColor().getHex()
    )
  }

  public hasMask(object: PackhelpEditableObject): boolean {
    return !!object.maskController
  }

  public selectMaskParent(object: PackhelpEditableObject) {
    const maskController = object.maskController

    if (!maskController) {
      return
    }

    maskController.selectMaskParent()
  }

  private getVirtualDielinEditor(): VirtualDielineEditor {
    const { renderEngine, activeContext } = this.productDriver.state

    return renderEngine!.getVirtualDielineEditor(activeContext)
  }
}
