import { fabric } from "fabric"
import addThresholdFilter from "./filters/ThresholdFilter"
import addObjects from "./objects/AddObjects"
import overrideFabricOptions from "./overrides/fabric-options-overrides"
import { addDrawClipPathOnCache } from "./overrides/drawClipPathOnCache"
import overrideFabricGroup from "./overrides/fabric-group-overrides"

overrideFabricOptions(fabric)
overrideFabricGroup(fabric)
addThresholdFilter(fabric)
addObjects(fabric)
addDrawClipPathOnCache(fabric)

export default fabric
