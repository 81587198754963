var _a, _b;
import BOX_CONSTS from "../consts/boxConsts";
// All box sizes with smaller number has the same config as the border point number specified as the key of the box type object,
// so all boxes from 1 to 105 have config specified in 105, all boxes from 106 to 200 have config specified in 200.
export default {
    F93: {
        grammage: "335 g/m2",
        materialDescription: BOX_CONSTS.MATERIAL_CARDBOARD_WAVE_E,
        addons: (_a = {},
            _a[BOX_CONSTS.MAILER_BOX_FULL_COLOR_VARIANT] = "535 g/m2",
            _a[BOX_CONSTS.MAILER_BOX_ECO_COLOR_VARIANT] = "335 g/m2",
            _a[BOX_CONSTS.MAILER_BOX_ECO_WHITE_VARIANT] = "365 g/m2",
            _a[BOX_CONSTS.MAILER_BOX_ECO_VARIANT] = "335 g/m2",
            _a),
    },
    F112: {
        grammage: "395 g/m2",
        materialDescription: BOX_CONSTS.MATERIAL_CARDBOARD_WAVE_B,
        addons: (_b = {},
            _b[BOX_CONSTS.MAILER_BOX_FULL_COLOR_VARIANT] = "720 g/m2",
            _b[BOX_CONSTS.MAILER_BOX_ECO_COLOR_VARIANT] = "395 g/m2",
            _b[BOX_CONSTS.MAILER_BOX_ECO_WHITE_VARIANT] = "450 g/m2",
            _b[BOX_CONSTS.MAILER_BOX_ECO_VARIANT] = "395 g/m2",
            _b),
    },
};
