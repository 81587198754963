import React, { useState } from "react"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { ReactComponent as CloseIcon } from "editor/src/ui/assets/svg/Close.svg"

import styles from "./CameraControlsToolbar.module.scss"

const i18n = I18N.component.cameraControlsToolbar

export const LimitedSpacesToEditWarning = () => {
  const [isVisible, setIsVisible] = useState(true)
  const t = useTranslate()

  if (!isVisible) {
    return null
  }

  return (
    <div className={styles.warning_wrapper}>
      <div className={styles.warning_content}>
        <Typography type={TType.Body15_350}>
          {t(i18n.limitedSpacesWarning)}
        </Typography>
        <CloseIcon
          onClick={() => {
            setIsVisible(false)
          }}
        />
      </div>
      <div className={styles.arrow}></div>
    </div>
  )
}
