import { configure, observable, action, makeObservable } from "mobx"
import { ShapesStore } from "./shapes-store/shapes.store"
import { PredefinedTextsStore } from "./predefined-texts-store/predefined-texts.store"
import { AllowedEditorFeatureFlagNames } from "../services/feature-flags/feature-flag-client"
import { FeatureFlags } from "@ph/feature-flags"
import { PatternController } from "./_controllers/patterns-controller"
import { ContextController } from "./_controllers/context-controller"
import { PredefinedTextsController } from "./_controllers/predefined-texts-controller"
import { datadogRum } from "@datadog/browser-rum"
import { DesignerModeStore } from "./designer-mode-stores/designer-mode-store"
import { DesignerModeControllable } from "./_controllers/designer-mode/designer-mode-controllable.interface"
import { EcoShapesStore } from "./eco-shapes-store/eco-shapes.store"
import { ShapesController } from "./_controllers/shapes-controller"
import type { DesignerModeMaybeContainer } from "../_containers/designer-mode-maybe-container"
import type { EditorMode } from "../libs/products-render-config/types"
import type { SessionService } from "../services/session-service"
import type { UrlManipulatorProvider } from "../services/url-manipulator"
import type ProductDriver from "./product-driver/product.driver"
import type { ProductDesignStore } from "./product-design-store/product-design.store"
import type { AssetsStore } from "./assets-store/assets.store"
import type { SessionStore } from "./session-store/session.store"
import { AssetsContainer } from "../_containers/assets-container"
import { DesignAndProductDriverContainer } from "../_containers/design-and-product-driver-container"
import { UtilEnvContainer } from "../_containers/util-container"

// don't allow state modifications outside actions
configure({ enforceActions: "always" })

export class RootStore {
  @observable public isDeveloperMode: boolean = false
  @observable public isBootstraped: boolean = false

  // ECOMMERCE DOMAIN
  public patternController?: PatternController
  public ecoShapesStore: EcoShapesStore
  public shapesController: ShapesController

  // DESIGNER MODE DOMAIN
  public designerModeStore?: DesignerModeStore
  public designerModeController?: DesignerModeControllable
  // DESIGNER MODE DOMAIN END

  public shapesStore: ShapesStore
  public predefinedTextsStore: PredefinedTextsStore
  public predefinedTextsController: PredefinedTextsController
  public featureFlagsStore: FeatureFlags<AllowedEditorFeatureFlagNames>
  public contextController: ContextController

  // Design and Product
  public readonly assetStore: AssetsStore
  public readonly productDriver: ProductDriver
  public readonly productDesignStore: ProductDesignStore

  constructor(
    public readonly sessionService: SessionService,
    public readonly sessionStore: SessionStore,
    public readonly editorMode: EditorMode,
    public readonly uri: UrlManipulatorProvider,
    designAndProductDriverContainer: DesignAndProductDriverContainer,
    designerModeContainer: DesignerModeMaybeContainer,
    assetsContainer: AssetsContainer,
    utilEnvContainer: UtilEnvContainer
  ) {
    makeObservable(this)
    datadogRum.addTiming("start_root_store")
    datadogRum["startView"]("root_store_stage")

    // PRODUCT DRIVER NEEDED
    this.shapesStore = assetsContainer.shapesStore
    this.ecoShapesStore = assetsContainer.ecoShapesStore
    this.shapesController = assetsContainer.shapesController

    this.assetStore = designAndProductDriverContainer.assetStore
    this.productDriver = designAndProductDriverContainer.productDriver
    this.productDesignStore = designAndProductDriverContainer.productDesignStore
    this.contextController = designAndProductDriverContainer.contextController

    this.predefinedTextsStore = assetsContainer.predefinedTextsStore
    this.predefinedTextsController = assetsContainer.predefinedTextsController

    if (designerModeContainer.available) {
      this.designerModeStore = designerModeContainer.designerModeStore
      this.designerModeController = designerModeContainer.designerModeController
    }

    // DOMAIN ENDED

    this.featureFlagsStore = utilEnvContainer.featureFlagStore
    globalThis.rootStore = this
  }
  /**
   * root store starter is using them for now
   */
  @action
  public setAllBootstraped(isBootstraped) {
    this.isBootstraped = isBootstraped
    datadogRum.addTiming("editor_is_bootstrapped")
    datadogRum["startView"]("resize_stage")
  }

  @action
  public setDeveloperMode(state: boolean) {
    this.isDeveloperMode = state
  }
}
