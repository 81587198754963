import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { MaskController } from "./mask-controller"

export class MaskLoader {
  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public async loadMasks(): Promise<void> {
    for (const object of this.vdEditor.assetsModule.getEditableObjects()) {
      if (!!object.maskConfig && !object.maskController) {
        const maskController = new MaskController(object, this.vdEditor)
        await maskController.load(object.maskConfig)
      }
    }
  }
}
