import { Colour } from "./colour"
import { Fonts } from "../services/fonts-loader-service/fonts-loader.config"

export type TextConfigParams = {
  text: string
  fontFamily: Fonts
  colour: {
    hex: string
    pantoneName?: string
  }
}

export type TextConfig = {
  text: string
  colour: Colour
  fontFamily: Fonts
}

export class Text {
  private textConfig: TextConfig
  constructor(textConfig?: TextConfigParams) {
    if (textConfig == null) {
      const colour = new Colour("#000")
      const fontFamily = Fonts.Lato
      textConfig = {
        text: "Hello Packhelp",
        fontFamily: fontFamily,
        colour: colour.toSource(),
      }
    }
    this.textConfig = this.deserialize(textConfig)
  }

  public getText() {
    return this.textConfig.text
  }

  public getColour() {
    return this.textConfig.colour
  }

  public getFontFamily() {
    return this.textConfig.fontFamily
  }

  public toSource() {
    return {
      text: this.getText(),
      fontFamily: this.getFontFamily(),
      colour: this.getColour().toSource(),
    }
  }

  private deserialize(textConfig: TextConfigParams) {
    return {
      text: textConfig.text,
      fontFamily: textConfig.fontFamily,
      colour: new Colour(textConfig.colour.hex, textConfig.colour.pantoneName),
    }
  }
}
