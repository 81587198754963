import {
  PackhelpGroup,
  PackhelpObject,
} from "../../object-extensions/packhelp-objects"
import {
  ModelEditableSpaces,
  EditableSpaceConfig,
  NonEditableSpaceConfig,
  ModelNonEditableSpaces,
} from "../../../../../libs/products-render-config/types"
import { isGroup } from "../../../../../modules/ph-api/asset-types"
import { DielineNavigator } from "../dieline-navigator/dieline-navigator"
import { CANVAS_DIM } from "../../../../types"
import { ClippingHelper } from "../assets-module/helpers/clipping-helper"

export class SpaceClippingController {
  constructor(
    private readonly dielineNavigator: DielineNavigator,
    private readonly objectToClip: PackhelpObject
  ) {}

  public getClipSpaceIds(): ModelEditableSpaces[] {
    if (!this.clipPath) {
      return []
    }

    return this.clipPath
      .getObjects()
      .map((object) => object.id)
      .filter((id) => {
        return !!Object.values(ModelEditableSpaces).find(
          (editableSpaceId) => editableSpaceId === id
        )
      }) as ModelEditableSpaces[]
  }

  public async toggleClipping(
    spaceConfig: EditableSpaceConfig | NonEditableSpaceConfig
  ): Promise<void> {
    if (this.isClippingActive(spaceConfig.spaceId)) {
      return this.removeClipping(spaceConfig)
    }

    await this.addClipping(spaceConfig)
  }

  public async refreshClipping(
    spaceConfig: EditableSpaceConfig | NonEditableSpaceConfig
  ): Promise<void> {
    this.removeClipping(spaceConfig)
    await this.addClipping(spaceConfig)
  }

  private removeClipping(
    spaceConfig: EditableSpaceConfig | NonEditableSpaceConfig
  ) {
    if (!this.clipPath) {
      return
    }

    const spaceIdsToRemove = spaceConfig.children.map(
      (childItem) => childItem.spaceId
    ) as (ModelEditableSpaces | ModelNonEditableSpaces)[]

    spaceIdsToRemove.push(spaceConfig.spaceId)

    for (const clipPathObject of this.clipPath.getObjects()) {
      if (
        spaceIdsToRemove.includes(
          clipPathObject.id as ModelEditableSpaces | ModelNonEditableSpaces
        )
      ) {
        this.clipPath.remove(clipPathObject)
        this.clipPath.set({
          dirty: true,
        })
      }
    }

    if (this.clipPath.getObjects().length === 0) {
      this.objectToClip.set({
        clipPath: undefined,
      })
    }

    this.objectToClip.set({
      dirty: true,
    })
  }

  private async addClipping(
    spaceConfig: EditableSpaceConfig | NonEditableSpaceConfig
  ): Promise<void> {
    const mainSpace = await this.dielineNavigator.cloneSpaceToClipPath(
      spaceConfig.spaceId,
      false,
      false
    )

    const dependantSpaces = await Promise.all(
      spaceConfig.children.map((child) => {
        return this.dielineNavigator.cloneSpaceToClipPath(
          child.spaceId,
          false,
          false
        )
      })
    )

    const spaceClipPathObjects = [mainSpace, ...dependantSpaces]

    if (!this.clipPath) {
      this.objectToClip.set({
        dirty: true,
        clipPath: ClippingHelper.buildClipPathGroup(),
      })
    }

    this.applySpaceClipObjects(spaceClipPathObjects)
  }

  public isClippingActive(
    spaceId: ModelEditableSpaces | ModelNonEditableSpaces
  ): boolean {
    if (!this.clipPath) {
      return false
    }

    const spaceClipPath = this.clipPath
      .getObjects()
      .find((obj) => obj.id === spaceId)

    return !!spaceClipPath
  }

  private applySpaceClipObjects(spaceClipPathObjects: PackhelpObject[]) {
    if (!this.clipPath) {
      return
    }

    for (const spaceClipPathObject of spaceClipPathObjects) {
      spaceClipPathObject.set({
        absolutePositioned: true,
        top: spaceClipPathObject.top! - CANVAS_DIM / 2,
        left: spaceClipPathObject.left! - CANVAS_DIM / 2,
      })

      this.clipPath.add(spaceClipPathObject)
    }

    this.objectToClip.set({
      dirty: true,
    })

    this.clipPath.set({
      dirty: true,
    })
  }

  private get clipPath(): PackhelpGroup | undefined {
    const clipPath = this.objectToClip.clipPath as PackhelpObject

    if (!clipPath || !isGroup(clipPath)) {
      return
    }

    return clipPath
  }
}
