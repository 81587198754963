var _a;
import NCP_CONSTS from "../consts/ncpConsts";
import ECO from "../../../productSharedConsts/ecoPropertiesConsts";
var NCP_PRODUCTS_ECO_PROPERTIES = (_a = {},
    _a[NCP_CONSTS.WOOD_WOOL] = [
        ECO.BIO_DEGRADABLE,
        ECO.RECYCLABLE,
        ECO.PLASTIC_FREE,
        ECO.COMPOSTABLE,
    ],
    _a[NCP_CONSTS.SELF_ADHESIVE_ENVELOPE] = [ECO.RECYCLABLE, ECO.VEGAN],
    _a[NCP_CONSTS.STICKER_CIRCLE] = [
        ECO.CERTIFIED_WOOD,
        ECO.RENEWABLE,
        ECO.VEGAN,
        ECO.EFFIECIENT_FULFILMENT,
    ],
    _a[NCP_CONSTS.STICKER_RECTANGLE] = [
        ECO.CERTIFIED_WOOD,
        ECO.RENEWABLE,
        ECO.VEGAN,
        ECO.EFFIECIENT_FULFILMENT,
    ],
    _a[NCP_CONSTS.THANK_YOU_CARD] = [ECO.RECYCLED, ECO.PLASTIC_FREE, ECO.RECYCLABLE],
    _a[NCP_CONSTS.PAPER_BUBBLE_WRAP] = [
        ECO.PLASTIC_FREE,
        ECO.RECYCLED,
        ECO.LOCAL_FABRICATION,
        ECO.MULTIPLE_USE,
    ],
    _a);
export default NCP_PRODUCTS_ECO_PROPERTIES;
