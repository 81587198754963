var _a, _b, _c;
import BOX_CONSTS from "../consts/boxConsts";
var DEFAULT = "default";
var WEIGHTS_PRODUCT_BOXES = (_a = {},
    _a[DEFAULT] = {
        P13: 0.004,
        P15: 0.005,
        P15i: 0.007,
        P18: 0.007,
        P18i: 0.01,
        P18ii: 0.008,
        P20: 0.01,
        P21: 0.009,
        P21i: 0.013,
        P24: 0.018,
        P24i: 0.011,
        P24ii: 0.013,
        P24iii: 0.016,
        P25: 0.016,
        P26: 0.018,
        P28i: 0.017,
        P29: 0.02,
        P30: 0.035,
        P34: 0.028,
        P35: 0.031,
        P36: 0.034,
        P39: 0.04,
        P40i: 0.037,
        P43: 0.18,
        P45: 0.053,
    },
    _a);
export default (_b = {},
    _b[BOX_CONSTS.PRODUCT_BOX] = (_c = {},
        _c[BOX_CONSTS.PRODUCT_BOX_DEFAULT_VARIANT] = WEIGHTS_PRODUCT_BOXES,
        _c),
    _b);
