import { action, makeObservable, observable } from "mobx"
import { getFormatMessage } from "shared-libs/src/js/libs/others/i18n"

import {
  EditorNotification,
  NotificationVariant,
} from "../../ui/dsl/atoms/Notification/Notification"
import { AllEditorEventsEmitter, eventTree } from "../editor-events"

export type UniqueNotifications = "reupload" | "productSwitched"

type UniqueNotificationStates = {
  [key in UniqueNotifications]: boolean
}

export class NotificationStore {
  @observable public notifications: EditorNotification[] = []
  @observable public uniqueNotificationStates: UniqueNotificationStates = {
    reupload: false,
    productSwitched: false,
  }

  constructor(private readonly ee: AllEditorEventsEmitter) {
    makeObservable(this)

    this.ee.on(eventTree.notification.designSaved, async () => {
      const formatMessage = await getFormatMessage()
      this.pushNotification({
        message: formatMessage({ id: "component.save-design.success" }),
        variant: NotificationVariant.success,
      })
    })

    this.ee.on(
      eventTree.notification.uniqueNotificationToggled,
      ({ name, isVisible }) => {
        this.toggleUniqueNotification({
          name,
          isVisible,
        })
      }
    )
  }

  @action pushNotification = (notification: EditorNotification) => {
    this.notifications.push(notification)
  }

  @action hideNotification = () => {
    this.notifications.shift()
  }

  @action toggleUniqueNotification = (payload: {
    name: UniqueNotifications
    isVisible: boolean
  }) => {
    this.uniqueNotificationStates[payload.name] = payload.isVisible
  }
}
