import React, { PropsWithChildren } from "react"

import { TType, Typography } from "dsl/src/atoms/Typography/Typography"

import styles from "./IconTextTile.module.scss"
import Loader, { LoaderSizes, LoaderTypes } from "dsl/src/atoms/Loader/Loader"

export type IconTextTileProps = {
  Icon: React.ElementType
  onClick: () => void
  isLoading?: boolean
  loadingText?: string
  e2eTarget: string
  e2eTargetName: string
}

export const IconTextTile = ({
  Icon,
  onClick,
  isLoading,
  loadingText,
  children,
  e2eTarget,
  e2eTargetName,
}: PropsWithChildren<IconTextTileProps>) => {
  return (
    <div
      className={styles.tile_wrapper}
      onClick={onClick}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <div className={styles.tile}>
        {isLoading ? (
          <>
            <Loader type={LoaderTypes.circular} size={LoaderSizes.small} />

            {loadingText && (
              <Typography type={TType.Body17_350} htmlElement="span">
                {loadingText}
              </Typography>
            )}
          </>
        ) : (
          <>
            <Icon />

            <Typography type={TType.Body17_350} htmlElement="span">
              {children}
            </Typography>
          </>
        )}
      </div>
    </div>
  )
}
