var _a, _b, _c, _d, _e, _f;
import TAPE_CONSTS from "../consts/tapeConsts";
var assetsPath = "/product-key-visuals/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var customPath = "/product-custom-dimensions/";
var packingTapesSizeAssets = {
    X48: {
        desktop: desktopPath + "X48.png",
        mobile: mobilePath + "X48.png",
    },
    custom: {
        desktop: customPath + "Tape/Desktop/Custom/{locale}.png",
        mobile: customPath + "Tape/Mobile/Custom/{locale}.png",
    },
};
var kraftTapesSizeAssets = {
    X50: {
        desktop: desktopPath + "X50.png",
        mobile: mobilePath + "X50.png",
    },
    custom: {
        desktop: customPath + "Tape/Desktop/Custom/{locale}.png",
        mobile: customPath + "Tape/Mobile/Custom/{locale}.png",
    },
};
var MATERIALS_IMAGES_PATHS = (_a = {},
    _a[TAPE_CONSTS.PRINT_MATERIAL_FOIL_TRANSPARENT] = "/product-material-picker/swatch_transparent.png",
    _a[TAPE_CONSTS.PRINT_MATERIAL_FOIL_WHITE] = "/product-material-picker/swatch_white.png",
    _a[TAPE_CONSTS.PRINT_MATERIAL_PAPER_KRAFT] = "/product-material-picker/natural-cardboard.png",
    _a);
var EditorMaterialImage;
(function (EditorMaterialImage) {
    EditorMaterialImage["FOIL_WHITE"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-tape-white.png";
    EditorMaterialImage["FOIL_TRANSPARENT"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-tape-transparent.png";
    EditorMaterialImage["PAPER_KRAFT"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-tape-kraft.png";
})(EditorMaterialImage || (EditorMaterialImage = {}));
var editorImagePaths = (_b = {},
    _b[TAPE_CONSTS.PRINT_MATERIAL_FOIL_WHITE] = EditorMaterialImage.FOIL_WHITE,
    _b[TAPE_CONSTS.PRINT_MATERIAL_FOIL_TRANSPARENT] = EditorMaterialImage.FOIL_TRANSPARENT,
    _b[TAPE_CONSTS.PRINT_MATERIAL_PAPER_KRAFT] = EditorMaterialImage.PAPER_KRAFT,
    _b);
var GLOBAL_ASSETS = Object.assign({}, { materialImagesPaths: MATERIALS_IMAGES_PATHS }, { editorImagePaths: editorImagePaths });
var TAPE_PRODUCT_ASSETS = (_c = {},
    _c[TAPE_CONSTS.TAPE_MODEL_PACKING_TAPE] = (_d = {},
        _d[TAPE_CONSTS.TAPE_VARIANT_PACKING_TAPE] = {
            keyVisual: [assetsPath + "packing_tapes_desktop.png"],
            sizeAssets: packingTapesSizeAssets,
        },
        _d),
    _c[TAPE_CONSTS.TAPE_MODEL_KRAFT_TAPE] = (_e = {},
        _e[TAPE_CONSTS.TAPE_VARIANT_KRAFT_TAPE] = {
            keyVisual: [assetsPath + "kraft_tapes_desktop.png"],
            sizeAssets: kraftTapesSizeAssets,
        },
        _e),
    _c[TAPE_CONSTS.TAPE_MODEL_PRE_PRINTED_KRAFT_TAPE] = (_f = {},
        _f[TAPE_CONSTS.PRE_PRINTED_KRAFT_TAPE_VARIANT] = {
            keyVisual: [assetsPath + "pre_printed_kraft_tapes_desktop.png"],
            sizeAssets: kraftTapesSizeAssets,
        },
        _f),
    _c);
var productAssets = {
    globalAssets: GLOBAL_ASSETS,
    productAssets: TAPE_PRODUCT_ASSETS,
};
export default productAssets;
