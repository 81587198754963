import React, { FunctionComponent, PropsWithChildren, SVGProps } from "react"

import { LowQualityNotification } from "./LowQualityNotification"
import { CameraControlsToolbarElement } from "./CameraControlsToolbarElement/CameraControlsToolbarElement"
import { CameraControlsToolbarElementCollapsible } from "./CameraControlsToolbarElementCollapsible/CameraControlsToolbarElementCollapsible"
import {
  CameraAngle,
  EditContext,
  ModelContext,
  ModelEditableSpaces,
  ViewMode,
  ViewType,
} from "../../../../../libs/products-render-config/types"
import { OverscaledObjectsMap } from "../../../../../stores/product-driver/assets.driver"
import { useContainer } from "../../../../../_containers-react/_editor-app-hooks"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { IconButton } from "../../../atoms/IconButton/IconButton"
import { ReactComponent as IconCameraRotate } from "../../../../../ui/assets/svg/camera-rotate.svg"
import { ReactComponent as IconCameraBoxTop } from "../../../../../ui/assets/svg/camera-box-top.svg"
import { ReactComponent as IconCameraBoxTopFront } from "../../../../../ui/assets/svg/camera-box-top-front.svg"
import { ReactComponent as IconCameraBoxTopBack } from "../../../../../ui/assets/svg/camera-box-top-back.svg"
import { ReactComponent as IconCameraBoxBottom } from "../../../../../ui/assets/svg/camera-box-bottom.svg"
import { ReactComponent as IconCameraBoxBottomFront } from "../../../../../ui/assets/svg/camera-box-bottom-front.svg"
import { ReactComponent as IconCameraBoxBottomBack } from "../../../../../ui/assets/svg/camera-box-bottom-back.svg"
import { ReactComponent as IconCameraBoxRight } from "../../../../../ui/assets/svg/camera-box-right.svg"
import { ReactComponent as IconCameraBoxLeft } from "../../../../../ui/assets/svg/camera-box-left.svg"
import { ReactComponent as IconCameraBoxFront } from "../../../../../ui/assets/svg/camera-box-front.svg"
import { ReactComponent as IconCameraBoxFrontInside } from "../../../../../ui/assets/svg/camera-box-front-inside.svg"
import { ReactComponent as IconCameraBoxBack } from "../../../../../ui/assets/svg/camera-box-back.svg"
import { ReactComponent as IconCameraBoxInsideLid } from "../../../../../ui/assets/svg/camera-box-inside-lid.svg"
import { ReactComponent as IconCameraBoxInsideBottom } from "../../../../../ui/assets/svg/camera-box-inside-bottom.svg"
import { ReactComponent as IconCameraBoxInsideFront } from "../../../../../ui/assets/svg/camera-box-inside-front.svg"
import { ReactComponent as IconCameraBoxInsideBack } from "../../../../../ui/assets/svg/camera-box-inside-back.svg"
import { ReactComponent as IconCameraBoxInsideLeft } from "../../../../../ui/assets/svg/camera-box-inside-left.svg"
import { ReactComponent as IconCameraBoxInsideRight } from "../../../../../ui/assets/svg/camera-box-inside-right.svg"
import { ReactComponent as IconCameraCanFront } from "../../../../../ui/assets/svg/camera-can-front.svg"

import styles from "./CameraControlsToolbar.module.scss"

const i18n = I18N.component.cameraControlsToolbar

const modelContexts = new Map<EditContext, ModelContext>([
  [EditContext.OUTSIDE, ModelContext.CLOSED],
  [EditContext.INSIDE, ModelContext.OPENED],
  [EditContext.SLEEVE, ModelContext.CLOSED],
])

type SpaceIconMap = Map<
  ModelEditableSpaces,
  FunctionComponent<SVGProps<SVGSVGElement>>
>

const iconsOutside: SpaceIconMap = new Map([
  [ModelEditableSpaces.DEFAULT, IconCameraCanFront],
  [ModelEditableSpaces.TOP, IconCameraBoxTop],
  [ModelEditableSpaces.FRONT, IconCameraBoxFront],
  [ModelEditableSpaces.LEFT, IconCameraBoxLeft],
  [ModelEditableSpaces.RIGHT, IconCameraBoxRight],
  [ModelEditableSpaces.BOTTOM, IconCameraBoxBottom],
  [ModelEditableSpaces.BACK, IconCameraBoxBack],
  [ModelEditableSpaces.FRONT_INSIDE, IconCameraBoxFrontInside],
  [ModelEditableSpaces.TOP_FRONT, IconCameraBoxTopFront],
  [ModelEditableSpaces.TOP_BACK, IconCameraBoxTopBack],
  [ModelEditableSpaces.BOTTOM_FRONT, IconCameraBoxBottomFront],
  [ModelEditableSpaces.BOTTOM_BACK, IconCameraBoxBottomBack],
])

const iconsInside: SpaceIconMap = new Map([
  [ModelEditableSpaces.TOP, IconCameraBoxInsideLid],
  [ModelEditableSpaces.FRONT, IconCameraBoxInsideFront],
  [ModelEditableSpaces.BOTTOM, IconCameraBoxInsideBottom],
  [ModelEditableSpaces.BACK, IconCameraBoxInsideBack],
  [ModelEditableSpaces.LEFT, IconCameraBoxInsideLeft],
  [ModelEditableSpaces.RIGHT, IconCameraBoxInsideRight],
])

const icons = new Map<
  EditContext,
  Map<ModelEditableSpaces, FunctionComponent<SVGProps<SVGSVGElement>>>
>([
  [EditContext.OUTSIDE, iconsOutside],
  [EditContext.INSIDE, iconsInside],
  [EditContext.SLEEVE, iconsOutside],
])

interface CameraControlsToolbarProps {
  currentViewType: ViewType
  currentSpace: CameraAngle
  isPrintInsideAvailable: boolean
  currentEditContext: EditContext | null
  currentModelContext: ModelContext | null
  onCameraSet: (
    viewType: ViewType,
    editContext: EditContext,
    editSpace: CameraAngle
  ) => void
  isAnyAssetOverscaled: boolean
  overscaledObjectsMap: OverscaledObjectsMap | {}
  availableContextsWithSpaces: {
    context: EditContext
    availableSpaces: ModelEditableSpaces[]
  }[]
}

export const CameraControlsToolbarList = (props: PropsWithChildren<{}>) => (
  <nav
    className={styles.toolbar}
    e2e-target="toolbar"
    e2e-target-name="camera-control"
  >
    <ul className={styles.toolbar__list_vertical}>{props.children}</ul>
  </nav>
)

export const CameraControlsToolbar = ({
  currentViewType,
  currentSpace = null,
  currentEditContext = null,
  currentModelContext = null,
  isAnyAssetOverscaled = false,
  overscaledObjectsMap = {},
  isPrintInsideAvailable,
  onCameraSet,
  availableContextsWithSpaces,
}: CameraControlsToolbarProps) => {
  const t = useTranslate()
  const [designAndProductDriverContainer] =
    useContainer().designAndProductDriver

  if (!designAndProductDriverContainer) {
    return null
  }
  const { productDriver } = designAndProductDriverContainer
  const { productRenderPilot } = productDriver.state

  const hasEditContextLowQualityAssets = (
    editContext: EditContext
  ): boolean => {
    for (const spaceKey in ModelEditableSpaces) {
      const space = ModelEditableSpaces[spaceKey]

      if (hasSpaceLowQualityAssets(editContext, space as CameraAngle)) {
        return true
      }
    }

    return false
  }

  const hasSpaceLowQualityAssets = (
    editContext: EditContext,
    editableSpace: CameraAngle
  ): boolean => {
    const contextMap = overscaledObjectsMap[editContext]

    if (!editableSpace || !contextMap || !contextMap[editableSpace]) {
      return false
    }

    return contextMap[editableSpace].length > 0
  }

  const cameraProps = (
    viewType: ViewType,
    editContext: EditContext,
    angle: CameraAngle
  ) => ({
    isActive: editContext === currentEditContext && angle === currentSpace,
    isAlert: hasSpaceLowQualityAssets(editContext, angle),
    onClick: () => onCameraSet(viewType, editContext, angle),
    e2eTargetName: `${editContext}-${angle}`,
    key: `${editContext}_${angle}`,
  })

  const cameraCollapsibleProps = (
    editContext: EditContext,
    modelContext: ModelContext
  ) => {
    const isSpaceView = currentViewType === ViewType.SPACE
    const isDielineView = currentViewType === ViewType.DIELINE
    const isModelView = currentViewType === ViewType.MODEL

    const isCurrentEditContext = editContext === currentEditContext
    const isCurrentModelContext = modelContext === currentModelContext

    return {
      isExpanded:
        (isSpaceView && isCurrentEditContext) ||
        (isDielineView && isCurrentEditContext) ||
        (isModelView && (isCurrentModelContext || !isPrintInsideAvailable)) ||
        hasEditContextLowQualityAssets(editContext),
      label: t(i18n[editContext]),
      e2eTargetName: editContext,
      key: `${editContext}_${modelContext}`,
    }
  }

  const hasOneEditableSpace =
    availableContextsWithSpaces.length === 1 &&
    availableContextsWithSpaces[0].availableSpaces.length === 1

  return (
    <>
      <LowQualityNotification
        isVisible={currentViewType !== ViewType.SPACE && isAnyAssetOverscaled}
      />
      <CameraControlsToolbarList>
        {productRenderPilot.isViewModeAvailable(ViewMode.THREE_DIMENSIONAL) && (
          <>
            <CameraControlsToolbarElementCollapsible
              Icon={IconCameraRotate}
              label={t(i18n.overview)}
              isActive={currentViewType === ViewType.MODEL}
              onClick={() =>
                onCameraSet(ViewType.MODEL, EditContext.OUTSIDE, null)
              }
              exposed
              e2eTargetName="3D"
            />
          </>
        )}
        {availableContextsWithSpaces.map((item) => {
          const modelContext = modelContexts.get(item.context)

          if (!modelContext || !item.availableSpaces.length) {
            return null
          }

          return (
            <div key={item.context}>
              <CameraControlsToolbarElementCollapsible
                {...cameraCollapsibleProps(item.context, modelContext)}
              >
                {productRenderPilot.isDielineViewAvailable(item.context) && (
                  <CameraControlsToolbarElement
                    isActive={
                      currentViewType === ViewType.DIELINE &&
                      currentEditContext === item.context
                    }
                    onClick={() =>
                      onCameraSet(ViewType.DIELINE, item.context, null)
                    }
                    e2eTarget={`${item.context}_flat_view`}
                    size="full"
                  >
                    {t(i18n.flatView)}
                  </CameraControlsToolbarElement>
                )}

                {item.availableSpaces.map((space: ModelEditableSpaces) => {
                  const icon = icons.get(item.context)?.get(space)

                  if (!icon) {
                    return null
                  }

                  return (
                    <CameraControlsToolbarElement
                      {...cameraProps(ViewType.SPACE, item.context, space)}
                      hasOneEditableSpace={hasOneEditableSpace}
                      key={space}
                      size={hasOneEditableSpace ? "full" : "half"}
                    >
                      {!!icon && <IconButton Icon={icon} />}
                    </CameraControlsToolbarElement>
                  )
                })}
              </CameraControlsToolbarElementCollapsible>
            </div>
          )
        })}
      </CameraControlsToolbarList>
    </>
  )
}
