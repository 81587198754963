var _a;
import ENVELOPE_CONSTS from "../consts/envelopeConsts";
var BAG_PRODUCT_DIMENSIONS = (_a = {},
    _a[ENVELOPE_CONSTS.ENVELOPE] = {
        C4: {
            outside: {
                width: 324,
                height: 229,
            },
        },
        C5: {
            outside: {
                width: 230,
                height: 166,
            },
        },
    },
    _a);
export default BAG_PRODUCT_DIMENSIONS;
