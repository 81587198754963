import React from "react"
import cxBinder from "classnames/bind"
import { getNextOrderDiscount } from "../../utils/wallet.helpers"
import { TType } from "dsl/src/atoms/Typography/Typography"
import { walletI18n } from "../../utils/wallet.translations"
import { UserBenefits } from "./UserBenefits"
import { SortedAccounts } from "./SortedAccounts"
import { NoCurrentStep } from "./NoCurrentStep"
import { TM } from "../../../../../shared-components/i18n/TypographyWithTranslate"
import styles from "./MoneyWalletCard.module.scss"
import { FormattedCurrency } from "../../../../../shared-components/formatted-currency/FormattedCurrency"

const cx = cxBinder.bind(styles)

export const MoneyWalletCard = ({ walletData, isHorizontal }) => {
  const { amount, currency, current_step: currentStep, accounts } = walletData

  const nextOrderDiscount = getNextOrderDiscount(walletData)
  return (
    <>
      <div
        className={cx({
          isHorizontal,
        })}
      >
        <div className={styles["balance-wrapper"]}>
          <div
            className={cx({
              "balance-content": !isHorizontal,
              "balance-content-horizontal": isHorizontal,
            })}
          >
            <SortedAccounts accounts={accounts} isHorizontal={isHorizontal} />
            <div
              className={styles["balance-details"]}
              e2e-target="balance-details"
            >
              <TM
                type={TType.Header13_500}
                id={walletI18n.yourBalance}
                className={styles.amount}
              />
              <FormattedCurrency currency={currency} value={amount} />
            </div>
            {!currentStep && <NoCurrentStep isHorizontal={isHorizontal} />}
          </div>
        </div>

        <UserBenefits
          currentStep={currentStep}
          isHorizontal={isHorizontal}
          nextOrderDiscount={nextOrderDiscount}
        />
      </div>
    </>
  )
}
