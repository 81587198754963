import { action, computed, makeObservable, observable } from "mobx"
import { ChangeProductEditorMode } from "./change-product-editor.controller"
import { ChangeProductDbyMode } from "./change-product-dby.controller"
import ProductDriver from "../../product-driver/product.driver"
import { ProductDesignStore } from "../../product-design-store/product-design.store"
import { AllEditorEventsEmitter, eventTree } from "../../editor-events"
import { EditorToolbarItem } from "../../editor-toolbar-store"
import { DbyModeStore } from "../../dby-mode-store/dby-mode-store"
import {
  ChangeProductControllable,
  ChangeType,
  DataAwaitingConfirmation,
} from "./change-product.interface"
import { VariantCustomization } from "@ph/product-api"
import { DielineStore } from "../../product-design-store/dieline.store"
import { EcommerceController } from "../ecommerce.controller"

export class ChangeProductController {
  private readonly modeController: ChangeProductControllable
  private changeType: ChangeType = "product"
  private alreadyIgnoredDesignChangeRisk: boolean = false
  @observable private dataAwaitingConfirmation?: DataAwaitingConfirmation

  constructor(
    private readonly editorMode: "editor" | "dby" | "designer",
    private readonly services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
      ecommerceController?: EcommerceController
    },
    private readonly stores: {
      productDesignStore: ProductDesignStore
      dielineStore: DielineStore
      dbyModeStore: DbyModeStore
    }
  ) {
    makeObservable(this)

    this.modeController =
      this.editorMode === "dby"
        ? new ChangeProductDbyMode(this.services, this.stores)
        : new ChangeProductEditorMode(this.services, this.stores)

    this.services.ee.on(eventTree.tab.tabChanged, (_newTab, oldTab) =>
      this.onTabChanged(oldTab)
    )
  }

  public setEcommerceController(
    ecommerceController: EcommerceController
  ): void {
    this.services.ecommerceController = ecommerceController
  }

  @action
  public setDataAwaitingConfirmation(data?: DataAwaitingConfirmation) {
    this.dataAwaitingConfirmation = data
  }

  @action
  public async changeProduct(
    type: ChangeType,
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    this.changeType = type

    if (this.changeType !== "size" || this.alreadyIgnoredDesignChangeRisk) {
      await this.changeSku(sku, customization)
      return
    }

    if (this.modeController.isChangeRisky()) {
      this.setDataAwaitingConfirmation({ sku, customization })
      return
    }

    await this.changeSku(sku, customization)
  }

  @action
  public async confirmChange(): Promise<void> {
    if (this.dataAwaitingConfirmation) {
      this.changeSku(
        this.dataAwaitingConfirmation.sku,
        this.dataAwaitingConfirmation.customization
      )
      this.dataAwaitingConfirmation = undefined
    }

    this.alreadyIgnoredDesignChangeRisk = true
  }

  @action
  public onConfirmationModalClose(): void {
    this.dataAwaitingConfirmation = undefined
  }

  public onApplyClick = async (): Promise<void> => {
    await this.apply()

    this.services.ee.emit(eventTree.activeTab.close)
  }

  public onCloseClick = async (): Promise<void> => {
    this.services.ee.emit(eventTree.activeTab.close)
  }

  @computed
  public get isSelectingDisabled(): boolean {
    const { isProductChanging, isRendererLoading } =
      this.services.productDriver.state

    return isProductChanging || isRendererLoading
  }

  @computed
  public get isConfirmationModalOpened(): boolean {
    return !!this.dataAwaitingConfirmation
  }

  @computed
  public get isApplyDisabled(): boolean {
    return !this.modeController.originalDesign || this.isSelectingDisabled
  }

  private async changeSku(
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    const { isProductChanging } = this.services.productDriver.state
    const { ecommerceController } = this.services

    if (isProductChanging) {
      return
    }

    try {
      await ecommerceController?.loadDynamicVariant(sku, customization)
    } catch (e) {
      return
    }

    await this.modeController.changeSku(this.changeType, sku, customization)
  }

  private async apply(): Promise<void> {
    if (!this.modeController.originalDesign) {
      return
    }

    this.modeController.clearOriginalDesign()
    this.services.ee.emit(
      eventTree.productDriver.productChangeApplied,
      this.changeType
    )
  }

  private async onTabChanged(oldTab?: EditorToolbarItem): Promise<void> {
    if (
      oldTab === EditorToolbarItem.changeSize ||
      oldTab === EditorToolbarItem.productConfiguration
    ) {
      await this.apply()
    }
  }
}
