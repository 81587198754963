import React from "react"

import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { FormattedMessage } from "react-intl"

import styles from "./Loader.module.scss"

interface LoaderProps {
  formattedMessageID?: string
}

export const Loader = ({ formattedMessageID }: LoaderProps) => (
  <div className={styles.loader_wrapper}>
    <div className={styles.loader} e2e-target="loader">
      <div className={styles.box} />
      <div className={styles.shadow} />
      {formattedMessageID && (
        <Typography type={TType.Body13_350}>
          <FormattedMessage id={formattedMessageID} />
        </Typography>
      )}
    </div>
  </div>
)
