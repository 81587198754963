import React from "react"
import cxBinder from "classnames/bind"

import { Button } from "dsl/src/atoms/Button/Button"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"

import { ReactComponent as IconArrowLeft } from "../../../../assets/svg/arrow-left.svg"

import styles from "./EditorHeaderProjectDetails.module.scss"
import { observer } from "mobx-react-lite"
import {
  getEditContextIntl,
  getSpaceNameIntl,
} from "../../../../../libs/products-render-config/helpers"
import { useTranslate } from "../../../../hooks/useTranslate"
import { ModelEditableSpaces } from "../../../../../libs/products-render-config/types"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { Dimensions } from "../../../atoms/dimensions/dimensions"
const cx = cxBinder.bind(styles)

export const EditorHeaderSpaceDetails = observer(() => {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.envUtil,
  ])

  if (!containerSet) return null

  const { designAndProductDriver, envUtil } = containerSet
  const { productDriver, contextController } = designAndProductDriver
  const { appConfig } = envUtil
  const { productRenderPilot, activeSpace, activeContext } = productDriver.state

  if (!activeSpace) {
    return null
  }

  const spaceIntl = getSpaceNameIntl(activeSpace)
  const contextIntl = getEditContextIntl(activeContext)
  const { displayUnit } = appConfig.locale

  const spaceDimensions = productRenderPilot.getSpaceDimensions(
    activeContext,
    activeSpace
  )

  const spaceName =
    activeSpace === ModelEditableSpaces.DEFAULT ? (
      <span>{`${t(contextIntl)}`}</span>
    ) : (
      <span>{`${t(spaceIntl)} (${t(contextIntl)})`}</span>
    )

  return (
    <div
      className={cx(
        styles.editor_header__project_details,
        styles.editor_header_project_details__fullheight
      )}
    >
      <div className={styles.editor_header__project_details__back_button}>
        <Button
          e2eTargetName="back"
          onClick={() => contextController.switchToLastPreview()}
        >
          <IconArrowLeft />
        </Button>
      </div>
      <div
        className={cx(
          styles.editor_header_project_details__info,
          styles.editor_header_project_details__bordered
        )}
      >
        <Typography
          e2eTargetName="product-name"
          type={TType.Body17_350}
          htmlElement="p"
        >
          {spaceName}
        </Typography>
        <Typography
          e2eTargetName="product-dimensions"
          type={TType.Body13_350}
          htmlElement="p"
        >
          <Dimensions
            dimensionsCm={[spaceDimensions.widthCm, spaceDimensions.heightCm]}
            displayUnit={displayUnit}
          />
        </Typography>
      </div>
    </div>
  )
})
