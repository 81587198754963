var _a;
import PAPER_TUBE_CONSTS from "../consts/paperTubeConsts";
var PAPER_CAN_PRODUCT_DIMENSIONS = (_a = {},
    _a[PAPER_TUBE_CONSTS.PAPER_CAN] = {
        T95: {
            outside: {
                width: 74,
                height: 95,
            },
        },
        T100: {
            outside: {
                width: 80,
                height: 100,
            },
        },
        T120: {
            outside: {
                width: 73,
                height: 120,
            },
        },
        T215: {
            outside: {
                width: 99,
                height: 215,
            },
        },
        T280: {
            outside: {
                width: 92,
                height: 280,
            },
        },
        T350: {
            outside: {
                width: 100,
                height: 350,
            },
        },
    },
    _a);
export default PAPER_CAN_PRODUCT_DIMENSIONS;
