import React, { useRef, useState } from "react"
import { FormattedPlural } from "react-intl"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { getFormattedPrice } from "shared-libs/src/js/shared-views/sustainability/planting-trees/reforestation-calculator/reforestation-calculator.utils"
import { SustainabilityModal } from "../Modal/sustainability/SustainabilityModal"
import { OutlineAddBlock } from "../../atoms/OutlineAddBlock/OutlineAddBlock"
import { useOutsideClick } from "../../../hooks/useOutsideClick"
import { TM } from "../../../TypographyI18n"
import { translateMsgOrTextWithValues } from "shared-libs/src/js/libs/others/i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { I18N } from "../../../i18n"
import { Shape } from "../../../../libs/value-objects/shape"
import { CheckboxFormBlock } from "../CheckboxFormBlock/CheckboxFormBlock"
import styles from "./EcoFormBlock.module.scss"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"

const i18n = I18N.editorTools.eco

interface EcoFormBlockProps {
  onChange(): void
  onAdd(shape: Shape): void
  isChecked: boolean
  treesAmount: number
  treesPrice: number
  currency: string
  badge?: Shape
}

export const EcoFormBlock = ({
  onChange,
  isChecked,
  onAdd,
  treesAmount,
  treesPrice,
  currency,
  badge,
}: EcoFormBlockProps) => {
  const t = useTranslate()
  const [isModalOpened, setModalOpened] = useState<boolean>(false)
  const modalRef = useRef(null)
  useOutsideClick(modalRef, () => setModalOpened(false), isModalOpened)

  const [designAndProductDriverContainer] =
    useContainer().designAndProductDriver

  if (!designAndProductDriverContainer) {
    return null
  }
  const { productDriver } = designAndProductDriverContainer

  const { productRenderPilot } = productDriver.state
  const isDbyMode = productRenderPilot.isDbyMode()
  const isEcoBadgeDisabled = productRenderPilot.uiConfig.ecoBadgeDisabled

  return (
    <CheckboxFormBlock
      isChecked={isChecked}
      onChange={onChange}
      title={t(i18n.supportForest)}
      price={treesPrice}
      currency={currency}
      e2eTargetName="support-forests"
    >
      <Typography type={TType.Body15_350} htmlElement="p">
        <FormattedEcoCopy
          price={getFormattedPrice(treesPrice, currency)}
          count={treesAmount}
        />{" "}
        <span
          className={styles.learn_more}
          onClick={() => setModalOpened(true)}
        >
          {t(i18n.learnMore)}
        </span>
      </Typography>

      {!isEcoBadgeDisabled && badge && !isDbyMode && (
        <div className={styles.badge_section}>
          <TM id={i18n.addBadge} type={TType.Body13_350} />
          <OutlineAddBlock
            onAdd={() => onAdd(badge)}
            e2eTargetName="add-plant-trees-badge"
          >
            <div className={styles.outline_block_content}>
              <img width={200} src={badge.src} alt="You helped plant a tree" />
            </div>
          </OutlineAddBlock>
        </div>
      )}

      {isModalOpened && (
        <div ref={modalRef}>
          <SustainabilityModal onClose={() => setModalOpened(false)} />
        </div>
      )}
    </CheckboxFormBlock>
  )
}

const FormattedEcoCopy = ({
  count,
  price,
}: {
  count: number
  price: string
}) => (
  <FormattedPlural
    value={count}
    one={translateMsgOrTextWithValues(
      "sustainability.planting-trees.pledge-3.one",
      { price }
    )}
    few={translateMsgOrTextWithValues(
      "sustainability.planting-trees.pledge-3.few",
      { price, count }
    )}
    many={translateMsgOrTextWithValues(
      "sustainability.planting-trees.pledge-3.many",
      { price, count }
    )}
    other={translateMsgOrTextWithValues(
      "sustainability.planting-trees.pledge-3.other",
      { price, count }
    )}
  />
)
