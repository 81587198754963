import * as shared from "../shared/shared-consts"

const Region = {
  trustpilotId: shared.TRUSTPILOT.trustpilotId,
  trustpilotBusinessId: shared.TRUSTPILOT.trustpilotBusinessId,
  trustpilotInvitationTemplateId:
    shared.TRUSTPILOT.trustpilotInvitationTemplateId,

  googleapisKey: shared.GOOGLEAPIS_KEY,

  secondaryAnalyticsCode: "UA-71580330-7",
  productRegion: "eu",
  countryCode: "eu",
  mainRegion: true,
  hasVat: true,
  languageTag: "en-US",
  iterableLocale: "en-EU",
  locale: "en",
  phoneCountryCode: "",
  defaultShippingCountryCode: "GB",
  emailAddress: "hello@packhelp.com",
  emailSenderName: "Packhelp.com",
  phoneNumber: "020 4540 6393",
  logoSVGIcon: "v3-packhelp_color_on_white",
  logoSVGIconWhite: "v3-packhelp_white_logo_on_black",

  additionalCustoms: 0,

  mainDomain: shared.EU_MAIN_DOMAIN,
  pageTitle: "Editor - Packhelp.com",
  footerLabel: "Packhelp",
  frontendBoxDesignRoute: "box/design",
  frontendAccountOrdersRoute: "account/orders",
  frontendTermsOfServiceRoute: "/terms_of_service/",
  frontendPrivacyPolicyRoute: "/privacy_policy/",
  domain: {
    basename: "packhelp",
    tld: "com",
  },
  externalLinks: {
    qrForm: "/quote-request",
    affiliates: "/affiliates/",
    partners: "/partners-page/",
    suppliers: "/suppliers/",
    fscCertification: "/fsc-certification/",
    helpPlantTrees: "/help-plant-trees/",
    ecoProperties: "/eco-properties/",
    sustainability: "/sustainability/",
    designShowcase: "/design-showcase/",
    inspirationsHandbook: "/become-an-inspiration/",
    mobileAppLandingPageUrl: "/mobile-app/",
    brief: "/brief/",
    artworkTipsDielines: "/artwork-tips-dielines/",
    help: "/help/",
    thankYou: "/thank-you/",
    designGuidelines: "/help/topics/box-editor/",
    about: "/about-us/",
    career: "/career/",
    shop: "/shop/",
    services: "/services/",
    industries: "/i/",
    inspirations: "/inspirations/",
    impressum: "/impressum/",
    returnPolicy: "/help/topic/can-return-boxes-ordered/",
    blog: "/blog/",
    contact: "/contact/",
    holidays: "/holidays/",
    privacyPolicy: "/privacy_policy/",
    termsOfService: "/terms_of_service/",
    termsOfServicePlus: "/terms-of-services-plus/",
    accountSignOut: "/sign_out/",
    accountSignIn: "/sign_in/",
    accountRegister: "/registration",
    accountOrders: "/account/orders",
    accountOrderDetails: "/account/orders/:orderNumber",
    accountSettings: "/account/settings",
    accountWallet: "/account/packhelp-wallet",
    accountBoxes: "/account/boxes",
    accountSupportReforestation: "/account/support-reforestation",
    accountCart: "/cart/", // old checkout key - to be removed,
    samples: "/sample-packs/",
    customOffer: "/offer/:token/review",
    payment: "/order/:orderId/payment",
    paymentSummary: "/order/:orderId/payment/summary",
    quoteRequest: "account/quote_requests/:qrNumber",
    cart: "/cart/",
    bundles: "/bundles",
    orderDetails: "/order",
    crossSell: "/cart/recommended-products-and-services/s",
    fullAndEcoColorHelpSite:
      "/help/topic/what-is-the-difference-between-full-color-and-eco-color-standards/",
    safeZonesHelpSite: "/help/topic/stay-away-edges/",
    samplePacksLink:
      "/box/design?sku=box-eu--samples--samples--samples--samples--samples&dielineMode=false",
    termsAcceptedSite: "/terms-successfully-accepted/",
    packhelpWallet: "/packhelp-wallet",
    ecoBadge: "/packhelp-eco-badge/",
    leadership: "/leadership/",
    press: "/press/",
    urlWhitelistedToBeFetchedFromUrl: shared.urlWhitelistedToBeFetchedFromUrl,
    products: {
      "mailer-box-plain": "/plain-mailer-box/",
      "mailer-box-eco": "/mailer-box-eco/",
      "mailer-box-eco-white": "/mailer-box-eco-white/",
      "mailer-box-full-color": "/mailer-box-full-color/",
      "mailer-box-eco-color": "/mailer-box-eco-color/",
      "delivery-mailer-box": "/ecommerce-delivery-box/",
      "delivery-mailer-box-plain": "/plain-delivery-box/",
      "shipping-box": "/shipping-boxes/",
      "shipping-box-plain": "/plain-shipping-box/",
      "product-box": "/classic-product-box/",
      "product-box-two-pieces": "/two-piece-product-box/",
      "poly-mailer": "/classic-poly-mailer/",
      "paper-mailer": "/expandable-kraft-mailer/",
      "paper-bag": "/twisted-handle-paper-bag/",
      "rigid-envelope": "/custom-cardboard-envelopes/",
      "product-box-wine-box": "/custom-wine-boxes/",
      "tissue-paper": "/custom-tissue-paper/",
      "packing-paper": "/packing-paper/",
      "packing-tape": "/custom-packaging-tapes/",
      "sleeved-mailer-box": "/sleeved-mailer-box/",
      "kraft-tape": "/kraft-tapes/",
      "bio-poly-mailer": "/bio-poly-mailers/",
      "paper-can": "/paper-cans/",
      "cake-box": "/cake-box/",
      "magnetic-box": "/magnetic-box/",
      "rigid-drawer-box": "/cardboard-drawer-box/",
      "rigid-box": "/rigid-box-with-print/",
      "rigid-box-two-pieces": "/rigid-box-with-lid/",
      "special-box": "/special-box/",
      "carrier-box": "/carrier-box/",
      "mailer-box-white-ink": "/white-on-kraft-mailer-box/",
      "mailer-box-full-color-double-sided": "/double-sided-mailer-box/",
      "bio-poly-mailer-plain": "/pre-printed-bio-poly-mailer/",
      "doypack-bag": "/stand-up-pouches/",
      "pre-printed-mailer-box-eco": "/pre-printed-mailer-box-eco/",
      "pre-printed-mailer-box-full-colour":
        "/pre-printed-mailer-box-full-color/",
      "square-label": "/custom-labels/",
      "tote-bag": "/tote-bag/",
      "printed-card": "/thank-you-cards/",
      "pre-printed-kraft-tape": "/pre-printed-kraft-tape/",
      "recycled-poly-mailer": "/recycled-poly-mailer/",
    },
    categories: {
      "mailer-boxes": "/mailer-boxes/",
      "custom-shipping-boxes": "/custom-shipping-boxes/",
      "primary-packaging": "/product-boxes/",
      "eco-friendly-packaging": "/shop/?eco-properties=recycled-materials/",
      "luxury-packaging": "/setup-boxes/",
    },
    fb_url: shared.EU_MAIN_DOMAIN,
    fb_sitename: "Packhelp",
    fb_image: "/share_image_1200x630.png",
    share_img: "/share_image_120x120.png", // Editor
    boxEditorPath: "/box/design",
    productEditor: "/editor/product/design",
    exampleDesign: "79485?language=en&templateMode=true",
    productQuotationSystem: "/quote",
    pqsSuccessPage: "/quote/success",
    plus: {
      catalogue: "/plus/catalogue/",
      successPage: "/plus/success",
      items: "/packhelp-plus/items",
      quotes: "/packhelp-plus/quotes",
      orders: "/packhelp-plus/orders",
      quoteShow: "/packhelp-plus/quotes/:token",
      orderShow: "/packhelp-plus/orders/:orderNumber/details",
      orderPlaced: "/packhelp-plus/orders/:orderNumber/placed",
      orderItems: "/packhelp-plus/orders/:orderNumber/items",
      orderPaymentSummary: "/packhelp-plus/orders/:orderNumber/payment-summary",
      "doypack-bag": "/plus/catalogue/stand-up-pouches/",
      personalDetails: "/packhelp-plus/personal-details",
      "tote-bag": "/plus/catalogue/printed-cotton-bags/",
      "product-boxes": "plus/catalogue/packaging/folding-boxes/",
      "mailer-boxes": "plus/catalogue/packaging/mailer-boxes/",
    },
    pro: {
      features: "pro/features/",
      suppliers: "pro/for-suppliers/",
      termsOfService: "pro/terms-conditions/",
    },
    servicesListing: "/services/",
    industriesListing: "/i/",
    whiteLabel: "/enterprise/",
  },

  outsidePackhelpDomainLinks: {
    ...shared.externalDomainPackhelpLinks,
  },

  ...shared.SHARED_ECOMMERCE_PROPERTIES.eu,
  ...shared.SHARED_CURRENCY_PROPERTIES.eur,

  remindMeOnDesktop: {
    remindme_form_url:
      "//packhelp.us12.list-manage.com/subscribe/post-json?u=2060c1de14ec30d28a9fab813&amp;id=f22157bb0b&c=?",
    remindme_hidden_input_name: "b_2060c1de14ec30d28a9fab813_f22157bb0b",
  },

  datesFormattingRules: shared.DATES_FORMATTING_RULES_EU,

  googleConversionId: "859901857",
  googleAnalytics: {
    primaryCode: "UA-71580330-2",
    secondaryCode: "UA-71580330-7",
    segmentCode: "uXDomf9GW2BCffFseKvLrarxNiGNLI5O",
  },
  googleAdWords: {
    remarketingCode: "39198",
    conversionCode: "859901857",
  },

  facebookAppId: "1660833110841347",
  supportsPlus: true,
}

export default Region
