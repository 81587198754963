import type { ApiSessionContainer } from "./api-session-container"
import { GetContainerFormat } from "iti"
import { ShapesStore } from "../stores/shapes-store/shapes.store"
import { EcoShapesStore } from "../stores/eco-shapes-store/eco-shapes.store"
import { ShapesController } from "../stores/_controllers/shapes-controller"
import type { DesignAndProductDriverContainer } from "./design-and-product-driver-container"
import { ShapesManager } from "../services/shapes-manager"
import { PredefinedTextsStore } from "../stores/predefined-texts-store/predefined-texts.store"
import { PredefinedTextsController } from "../stores/_controllers/predefined-texts-controller"
import { UtilEnvContainer } from "./util-container"
import { MaskUiController } from "../stores/_controllers/mask-ui-controller"
import { AppConfig } from "../app-config/app.config"
import { EcommerceApi, QrCodeApi } from "../modules/ecommerce-api/types"

function getDynamicQrConfig(
  appConfig: AppConfig,
  ecommerceApi: EcommerceApi
):
  | {
      api: QrCodeApi
      url: string
    }
  | undefined {
  const { dynamicQrCode } = appConfig.api.ecommerce.features.ecommerce
  const dynamicQrUrl = appConfig.locale.getUrl("ecoProperties")

  if (!dynamicQrCode || !dynamicQrUrl) {
    return
  }

  return {
    api: ecommerceApi.qrCode,
    url: dynamicQrUrl,
  }
}

export async function provideAssetsContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  designAndProduct: DesignAndProductDriverContainer
) {
  const { ee, appConfig } = utilEnvContainer
  const { directusApiService, ecommerceApi } = apiSessionContainer
  const { productDriver, productDesignStore } = designAndProduct

  const shapesManager = new ShapesManager(
    directusApiService,
    getDynamicQrConfig(appConfig, ecommerceApi)
  )
  const shapesStore = new ShapesStore(shapesManager)
  const ecoShapesStore = new EcoShapesStore(shapesManager, productDriver)
  const shapesController = new ShapesController(
    ecoShapesStore,
    shapesStore,
    productDriver,
    shapesManager,
    productDesignStore,
    ee
  )

  const predefinedTextsStore = new PredefinedTextsStore(
    productDriver,
    appConfig
  )
  const predefinedTextsController = new PredefinedTextsController(
    productDriver,
    predefinedTextsStore
  )

  const maskUiController = new MaskUiController(shapesStore, productDriver, ee)
  return {
    shapesStore,
    ecoShapesStore,
    shapesController,
    predefinedTextsStore,
    predefinedTextsController,
    maskUiController,
  }
}

export type AssetsContainer = GetContainerFormat<typeof provideAssetsContainer>
