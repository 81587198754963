import { Debug } from "../../services/logger"
import { EditorToolbarItem } from "../editor-toolbar-store"
import { AllEditorEventsEmitter, eventTree } from "../editor-events"
import { HeaderToolbarAction } from "../../ui/dsl/organisms/EditorHeader/EditorHeaderToolbar/HeaderToolbarAction"
import { EditableObjectTypes } from "../../modules/ph-api/asset-types"
let debug = Debug("ph:action-tracker")

export class UiActionTracker {
  constructor(private ee: AllEditorEventsEmitter) {}

  public trackEditorViewed(url: string) {
    debug("Viewed Editor")
    this.ee.emit(eventTree.ui.MainUISceneWithSKUViewed, {
      url,
    })
  }

  public ecoToolbarSectionClicked() {
    debug("Editor Eco Section Clicked")
    this.ee.emit(eventTree.ui.ecoFormBlockClicked)
  }

  public threeDimensionalToolbarElementClicked(element: EditorToolbarItem) {
    this.ee.emit(eventTree.ui.EditorToolbarElementClicked, element)
  }

  public twoDimensionalUpperRightToolbarElementClicked(
    action: HeaderToolbarAction,
    objectType: string,
    assetType: EditableObjectTypes
  ) {
    this.ee.emit(
      eventTree.ui.EditorUpperRightToolbarClicked,
      action,
      objectType,
      assetType
    )
  }

  public printInsideSwitched(
    active: boolean,
    location: "style_tab" | "side_view"
  ) {
    this.ee.emit(eventTree.ui.printInsideSwitched, { active, location })
  }
}
