import React from "react"
import {
  Typography,
  TType,
  TypographyProps,
} from "dsl/src/atoms/Typography/Typography"
import { TranslationValues, useTranslate } from "./useTranslate"

interface TypographiedMessageWithI18n extends TypographyProps {
  id: string
  values?: TranslationValues
}

export function TM({ id, values, ...other }: TypographiedMessageWithI18n) {
  const t = useTranslate()
  return <Typography {...other}>{t(id, values)}</Typography>
}

export { TType }
