var _a, _b, _c, _d, _e, _f;
import PRODUCTION_TIMES_CONSTS from "../../../productSharedConsts/productionTimeConsts";
import PAPER_TUBE_CONSTS from "../consts/paperTubeConsts";
var DEFAULT = PRODUCTION_TIMES_CONSTS.DEFAULT, EXPRESS = PRODUCTION_TIMES_CONSTS.EXPRESS, SUPEREXPRESS = PRODUCTION_TIMES_CONSTS.SUPEREXPRESS, PRODUCTION_TIME = PRODUCTION_TIMES_CONSTS.PRODUCTION_TIME, DTP_DEADLINE = PRODUCTION_TIMES_CONSTS.DTP_DEADLINE, HIGH_QUANTITY = PRODUCTION_TIMES_CONSTS.HIGH_QUANTITY;
var PAPER_TUBE_PRODUCTION_TIME = (_a = {},
    _a[PAPER_TUBE_CONSTS.PAPER_CAN] = (_b = {},
        _b[PAPER_TUBE_CONSTS.PAPER_CAN_PRODUCT_VARIANT_STANDARD] = (_c = {},
            _c[PRODUCTION_TIME] = (_d = {},
                _d[DEFAULT] = 11,
                _d[EXPRESS] = 8,
                _d[SUPEREXPRESS] = 8,
                _d[HIGH_QUANTITY] = (_e = {},
                    _e[500] = { min: 22, max: 27 },
                    _e),
                _d),
            _c[DTP_DEADLINE] = (_f = {},
                _f[DEFAULT] = 1,
                _f),
            _c),
        _b),
    _a);
export default PAPER_TUBE_PRODUCTION_TIME;
