var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import NCP_CONSTS from "../../../../source/ncpProductSpec/generalConfig/consts/ncpConsts";
import { getOldVariantCodeName } from "../../../../definitions/products/newProductVariantsToOld";
import { ProductType } from "../../types";
var addNcpVariantBehaviors = function (superclass) {
    function findVariant(productName) {
        if (productName === NCP_CONSTS.WOOD_WOOL_VARIANT) {
            return NCP_CONSTS.WOOD_WOOL_VARIANT;
        }
        else if (productName === NCP_CONSTS.SELF_ADHESIVE_ENVELOPE_VARIANT) {
            return NCP_CONSTS.SELF_ADHESIVE_ENVELOPE_VARIANT;
        }
        else if (productName === NCP_CONSTS.STICKER_CIRCLE_VARIANT) {
            return NCP_CONSTS.STICKER_CIRCLE_VARIANT;
        }
        else if (productName === NCP_CONSTS.STICKER_RECTANGLE_VARIANT) {
            return NCP_CONSTS.STICKER_RECTANGLE_VARIANT;
        }
        else if (productName === NCP_CONSTS.THANK_YOU_CARD) {
            return NCP_CONSTS.THANK_YOU_CARD_VARIANT;
        }
        else if (productName === NCP_CONSTS.PAPER_BUBBLE_WRAP) {
            return NCP_CONSTS.PAPER_BUBBLE_WRAP_VARIANT;
        }
    }
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            return _super.call(this, props) || this;
        }
        class_1.findVariant = function (productName) {
            return findVariant(productName);
        };
        class_1.prototype.getVariant = function () {
            var productName = this.getName();
            return findVariant(productName);
        };
        class_1.prototype.getVariantStructure = function () {
            return this.variant.variants[this.getVariant()];
        };
        class_1.prototype.getVariantDashed = function () {
            return getOldVariantCodeName(this.getVariant());
        };
        class_1.prototype.getProductType = function () {
            var productName = this.getName();
            switch (productName) {
                case NCP_CONSTS.WOOD_WOOL_VARIANT:
                case NCP_CONSTS.SELF_ADHESIVE_ENVELOPE_VARIANT:
                case NCP_CONSTS.PAPER_BUBBLE_WRAP:
                    return ProductType.Plain;
                default:
                    return ProductType.PrePrinted;
            }
        };
        return class_1;
    }(superclass));
};
export default addNcpVariantBehaviors;
