import { action } from "mobx"
import { useEditorEventListener } from "./use-editor-event-listener"
import { FontFamilyDefinition } from "../../libs/services/fonts-loader-service/fonts-loader.service"
import { Fonts } from "../../libs/services/fonts-loader-service/fonts-loader.config"
import { eventTree } from "../../stores/editor-events"
import { useContainer } from "../../_containers-react/_editor-app-hooks"

interface UseHighlightProps {
  onClick: (fontFamilyDefinition: FontFamilyDefinition) => void
  selectedFontFamily: Fonts
}

enum Step {
  UP = 1,
  DOWN = -1,
}

export const useHighlightFont = ({
  onClick,
  selectedFontFamily,
}: UseHighlightProps) => {
  const [uiContainer] = useContainer().ui

  const fontFamiliesDefinitions = uiContainer?.fontsConfigStore.fontFamiliesList
  const defaultSelectedFamily = fontFamiliesDefinitions?.find((font) => {
    return font.name === selectedFontFamily
  })

  const highlightedFontStore = {
    highlightedFont: defaultSelectedFamily,
    setHighlightedFont: action(function setHighlightedFont(
      highlightedFont: FontFamilyDefinition
    ) {
      highlightedFontStore.highlightedFont = highlightedFont
    }),
  }

  const getCurrentlyHighlightedFontIndex = () => {
    if (!highlightedFontStore.highlightedFont || !fontFamiliesDefinitions) {
      return 0
    }

    return fontFamiliesDefinitions.findIndex((fontFamily) => {
      return fontFamily.id === highlightedFontStore.highlightedFont?.id
    })
  }

  const highlightFont = (step: Step) => {
    if (!fontFamiliesDefinitions) {
      return
    }
    highlightedFontStore.setHighlightedFont(
      fontFamiliesDefinitions[getCurrentlyHighlightedFontIndex() + step] ||
        highlightedFontStore.highlightedFont
    )
  }

  const selectFont = () => {
    !!highlightedFontStore.highlightedFont &&
      onClick(highlightedFontStore.highlightedFont)
  }

  useEditorEventListener(eventTree.activeTab.down, () => highlightFont(Step.UP))
  useEditorEventListener(eventTree.activeTab.up, () => highlightFont(Step.DOWN))
  useEditorEventListener(eventTree.activeTab.select, () => selectFont())
  useEditorEventListener(eventTree.activeTab.select, () => selectFont())

  return { highlightedFontStore }
}
