/** @format */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addDimensionsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            _this.dimensions = product.config.dimensions;
            return _this;
        }
        class_1.prototype.getDimensions = function () {
            var productCodeName = this.product.variantManager.getCodeName();
            return this.dimensions[productCodeName];
        };
        class_1.prototype.getOutsideDimensions = function () {
            return this.getDimensions().outside;
        };
        class_1.prototype.getCapacity = function () {
            return this.getDimensions().capacity;
        };
        class_1.prototype.getSleeveDimensions = function () {
            return this.getDimensions().sleeve;
        };
        class_1.prototype.getInsideSizeDimensionFormatted = function () {
            return null;
        };
        class_1.prototype.getDivisorByUnits = function (units) {
            switch (units) {
                case "m":
                    return 1000;
                case "cm":
                    return 10;
                case "mm":
                default:
                    return 1;
            }
        };
        class_1.prototype.is3D = function () {
            var _a = this.getOutsideDimensions(), width = _a.width, height = _a.height, length = _a.length;
            return !!width && !!height && !!length;
        };
        return class_1;
    }(superclass));
};
export default addDimensionsBehaviors;
