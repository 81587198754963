import React from "react"
import { PantonePickerColorObject } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import styles from "./PantonePickerColorsList.module.scss"
import cxBinder from "classnames/bind"
import { CheckIcon } from "dsl/src/assets/_svgr/icons/Check"
const cx = cxBinder.bind(styles)

interface PantonePickerColorsListProps {
  colorsList: PantonePickerColorObject[]
  selectedColor?: PantonePickerColorObject
  onApplyColor: (color: PantonePickerColorObject) => void
  listClassName?: string
  renderNoResults?: JSX.Element
}

interface PantoneColorListItemProps {
  color: PantonePickerColorObject
  selectedColor?: PantonePickerColorObject
  onApplyColor: (color: PantonePickerColorObject) => void
}

const PantoneColorListItem = ({
  color,
  selectedColor,
  onApplyColor,
}: PantoneColorListItemProps) => {
  const isActive =
    selectedColor !== undefined &&
    color.pantoneName === selectedColor.pantoneName
  const isWhite = color.color.toUpperCase() === "#FFFFFF"

  const whiteContrastColor = "#86868b" // (148,148,148)
  const styleColor = color.color
    ? isWhite
      ? whiteContrastColor
      : color.color
    : "#000"

  const wrapperStyle: any = {}
  const innerStyle: any = {
    backgroundColor: color.color,
    borderColor: styleColor,
  }

  if (isActive) {
    wrapperStyle.boxShadow = `0 0 0 2px ${styleColor}`
    wrapperStyle.border = "solid 2px white"
    innerStyle.borderColor = color.color
  }

  return (
    <li className={styles.colorItem} title={color.pantoneName}>
      <button
        className={styles.colorItemInnerWrapper}
        style={wrapperStyle}
        title={color.pantoneName}
        onClick={() => onApplyColor(color)}
        type="button"
        e2e-target="button"
        e2e-target-name={color.pantoneName}
      >
        <div className={styles.colorItemInner} style={innerStyle}>
          {isActive ? (
            <CheckIcon
              className={isWhite ? styles.checkMarkGrey : styles.checkMarkWhite}
            />
          ) : null}
        </div>
      </button>
      <div
        className={isActive ? styles.textWrapperSelected : styles.textWrapper}
        title={color.pantoneName}
      >
        <Typography type={TType.Body13_350}>{color.pantoneName}</Typography>
      </div>
    </li>
  )
}

export default function PantonePickerColorsList({
  colorsList,
  selectedColor,
  onApplyColor,
  listClassName,
  renderNoResults,
}: PantonePickerColorsListProps) {
  return (
    <div className={styles.wrapper}>
      <ul
        className={cx(styles.list, listClassName)}
        e2e-target="pantone-picker"
      >
        {renderNoResults !== undefined && <>{renderNoResults}</>}
        {colorsList.map((color: PantonePickerColorObject) => {
          return (
            <PantoneColorListItem
              key={color.pantoneName}
              selectedColor={selectedColor}
              color={color}
              onApplyColor={onApplyColor}
            />
          )
        })}
      </ul>
    </div>
  )
}
