import { observable, action, computed, makeObservable } from "mobx"
import { EditContext } from "../../../libs/products-render-config/types"
import ProductDriver from "../../product-driver/product.driver"
import { ProductConfigurationUiController } from "../product-configuration-ui.controller"

export class EnablePrintingOptionModalController {
  public editContext?: EditContext

  @observable private isVisible = false
  private onConfirm?: () => void
  private onClose?: () => void

  constructor(
    private readonly productDriver: ProductDriver,
    private readonly productConfiguratorController: ProductConfigurationUiController
  ) {
    makeObservable(this)
  }

  @computed
  public get isOpen(): boolean {
    return this.isVisible
  }

  @action
  public showIfNecessary(
    editContext: EditContext,
    onConfirm: () => void,
    onClose?: () => void
  ) {
    if (!this.shouldShowModal(editContext)) {
      onConfirm()
      return
    }

    this.setIsVisible(true)
    this.editContext = editContext
    this.onConfirm = onConfirm
    this.onClose = onClose
  }

  @action
  public close() {
    if (this.onClose) {
      this.onClose()
    }

    this.setIsVisible(false)
    this.editContext = undefined
    this.onConfirm = undefined
    this.onClose = undefined
  }

  @action
  public async confirm() {
    if (this.editContext) {
      await this.productConfiguratorController.enablePrintingOption(
        this.editContext
      )
    }

    if (this.onConfirm) {
      this.onConfirm()
    }

    this.setIsVisible(false)
    this.editContext = undefined
    this.onConfirm = undefined
    this.onClose = undefined
  }

  @action
  private setIsVisible(isVisible: boolean) {
    this.isVisible = isVisible
  }

  private shouldShowModal(editContext: EditContext): boolean {
    return !this.productDriver.state.productRenderPilot.isPrintActiveFor(
      editContext
    )
  }
}
