import React, { useCallback } from "react"
import debounce from "lodash/debounce"
import { observer } from "mobx-react-lite"
import { ProductDesignStore } from "../../../../../stores/product-design-store/product-design.store"
import { InputUnderline } from "../../../atoms/InputUnderline/InputUnderline"
import { DesignerModeControllable } from "../../../../../stores/_controllers/designer-mode/designer-mode-controllable.interface"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { ProductDesignUiController } from "../../../../../stores/_controllers/product-design/product-design-ui.controller"
import { AuthController } from "../../../../../stores/_controllers/auth-controller"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import ProductDriver from "../../../../../stores/product-driver/product.driver"
import { DesignerModeStore } from "../../../../../stores/designer-mode-stores/designer-mode-store"

const ProjectNameEcommerceMode = observer(
  ({
    productDesignStore,
    productDesignUiController,
    authController,
    productDriver,
  }: {
    productDesignStore: ProductDesignStore
    productDesignUiController: ProductDesignUiController
    authController?: AuthController
    productDriver: ProductDriver
  }) => {
    const { isDesignInCart, isDraftDesignInCart } = productDesignStore.state
    const { name: designName } = productDesignStore.state.meta

    const isDataReadOnly = productDesignStore.state.meta.dataReadOnly
    const readonly = isDesignInCart || isDataReadOnly
    const { isAfterPurchaseEdit } = productDriver.state.productRenderPilot

    const saveProjectName = useCallback(
      debounce(() => {
        if (readonly) {
          return
        }

        if (!isDraftDesignInCart && !isAfterPurchaseEdit) {
          productDesignUiController?.save({ notifyOnSuccess: true })
        }
      }, 500),
      [readonly, productDesignUiController]
    )

    const onProjectNameChange = (name: string) => {
      if (readonly) {
        return
      }

      if (authController && !authController.isUserLoggedIn()) {
        return authController.openLoginModal()
      }

      productDesignStore.setDesignName(name)

      saveProjectName()
    }

    return (
      <InputUnderline
        e2eTargetName="design-name"
        value={designName}
        onChange={(e) => onProjectNameChange(e.currentTarget.value)}
        disabled={readonly}
      />
    )
  }
)

const ProjectNameDesignerMode = observer(
  ({
    designerModeStore,
    designerModeController,
  }: {
    designerModeStore: DesignerModeStore
    designerModeController?: DesignerModeControllable
  }) => {
    if (!designerModeController) {
      return null
    }

    if (designerModeStore.isLoading) {
      return (
        <Typography e2eTargetName="product-name" type={TType.Header17_500}>
          Loading designer mode...
        </Typography>
      )
    }

    return (
      <Typography e2eTargetName="product-name" type={TType.Header17_500}>
        {designerModeController.getName()}
      </Typography>
    )
  }
)

export const ProjectName = observer(() => {
  const [headerSet, headerSetErrors] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.designerMode,
    c.auth,
  ])

  if (headerSet == null || headerSetErrors != null) return null
  const { designAndProductDriver, designerMode, auth } = headerSet

  const { productDesignStore, productDesignUiController, productDriver } =
    designAndProductDriver
  const { authController } = auth

  if (designerMode && designerMode.available) {
    const { designerModeController, designerModeStore } = designerMode

    return (
      <ProjectNameDesignerMode
        designerModeStore={designerModeStore}
        designerModeController={designerModeController}
      />
    )
  }

  return (
    <ProjectNameEcommerceMode
      productDesignStore={productDesignStore}
      productDesignUiController={productDesignUiController}
      authController={authController}
      productDriver={productDriver}
    />
  )
})
