var _a;
import LABEL_CONSTS from "../generalConfig/consts/labelConsts";
var LABEL_RENDER_CONFIG = (_a = {},
    _a[LABEL_CONSTS.CIRCLE_LABEL_MODEL] = {
        SC2: {
            width: 25,
            height: 25,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug
             * This length is requred deep down in
             * getSceneDimensions (originBehaviors/renderConfigBehaviors/RenderConfigBehaviors.ts)
             *
             * If you miss it it will subtly propagate null / NaN / undefined values up the chain until it crashes
             * everythings
             */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 25,
                        height: 25,
                    },
                },
            },
        },
        SC4: {
            width: 40,
            height: 40,
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 40,
                        height: 40,
                    },
                },
            },
        },
        SC5: {
            width: 50,
            height: 50,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 50,
                        height: 50,
                    },
                },
            },
        },
        SC6: {
            width: 65,
            height: 65,
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 65,
                        height: 65,
                    },
                },
            },
        },
        SC7: {
            width: 75,
            height: 75,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 75,
                        height: 75,
                    },
                },
            },
        },
        SC10: {
            width: 100,
            height: 100,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 100,
                        height: 100,
                    },
                },
            },
        },
    },
    _a[LABEL_CONSTS.SQUARE_LABEL_MODEL] = {
        SS5: {
            width: 25,
            height: 25,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 25,
                        height: 25,
                    },
                },
            },
        },
        SS10: {
            width: 50,
            height: 50,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 50,
                        height: 50,
                    },
                },
            },
        },
        SS13: {
            width: 64,
            height: 64,
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 64,
                        height: 64,
                    },
                },
            },
        },
        SS15: {
            width: 75,
            height: 75,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 75,
                        height: 75,
                    },
                },
            },
        },
        SS20: {
            width: 100,
            height: 100,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 100,
                        height: 100,
                    },
                },
            },
        },
    },
    _a[LABEL_CONSTS.RECTANGLE_LABEL_MODEL] = {
        SR7: {
            width: 25,
            height: 50,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 25,
                        height: 50,
                    },
                },
            },
        },
        SR10i: {
            width: 60,
            height: 35,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 60,
                        height: 35,
                    },
                },
            },
        },
        SR10ii: {
            width: 79,
            height: 25,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 79,
                        height: 25,
                    },
                },
            },
        },
        SR11: {
            width: 60,
            height: 50,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 60,
                        height: 50,
                    },
                },
            },
        },
        SR12: {
            width: 50,
            height: 75,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 50,
                        height: 75,
                    },
                },
            },
        },
        SR12i: {
            width: 79,
            height: 36,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 79,
                        height: 36,
                    },
                },
            },
        },
        SR12ii: {
            width: 62,
            height: 54,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 62,
                        height: 54,
                    },
                },
            },
        },
        SR12iii: {
            width: 80,
            height: 43,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 80,
                        height: 43,
                    },
                },
            },
        },
        SR13: {
            width: 103,
            height: 23,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 103,
                        height: 23,
                    },
                },
            },
        },
        SR13i: {
            width: 73,
            height: 55,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 73,
                        height: 55,
                    },
                },
            },
        },
        SR14: {
            width: 103,
            height: 32,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 103,
                        height: 32,
                    },
                },
            },
        },
        SR14i: {
            width: 76,
            height: 65,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 76,
                        height: 65,
                    },
                },
            },
        },
        SR14ii: {
            width: 103,
            height: 40,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 103,
                        height: 40,
                    },
                },
            },
        },
        SR15: {
            width: 50,
            height: 100,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 50,
                        height: 100,
                    },
                },
            },
        },
        SR15i: {
            width: 84,
            height: 63,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 84,
                        height: 63,
                    },
                },
            },
        },
        SR15ii: {
            width: 119,
            height: 35,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 119,
                        height: 35,
                    },
                },
            },
        },
        SR15iii: {
            width: 119,
            height: 51,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 119,
                        height: 51,
                    },
                },
            },
        },
        SR16: {
            width: 92,
            height: 63,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 92,
                        height: 63,
                    },
                },
            },
        },
        SR16i: {
            width: 130,
            height: 27,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 130,
                        height: 27,
                    },
                },
            },
        },
        SR16ii: {
            width: 143,
            height: 21,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 143,
                        height: 21,
                    },
                },
            },
        },
        SR17: {
            width: 130,
            height: 35,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 130,
                        height: 35,
                    },
                },
            },
        },
        SR17i: {
            width: 103,
            height: 65,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 103,
                        height: 65,
                    },
                },
            },
        },
        SR17ii: {
            width: 119,
            height: 50,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 119,
                        height: 50,
                    },
                },
            },
        },
        SR18: {
            width: 100,
            height: 75,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 100,
                        height: 75,
                    },
                },
            },
        },
        SR18i: {
            width: 123,
            height: 54,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 123,
                        height: 54,
                    },
                },
            },
        },
        SR18ii: {
            width: 157,
            height: 25,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 157,
                        height: 25,
                    },
                },
            },
        },
        SR18iii: {
            width: 112,
            height: 70,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 112,
                        height: 70,
                    },
                },
            },
        },
        SR19: {
            width: 103,
            height: 85,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 103,
                        height: 85,
                    },
                },
            },
        },
        SR19i: {
            width: 127,
            height: 64,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 127,
                        height: 64,
                    },
                },
            },
        },
        SR19ii: {
            width: 148,
            height: 44,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 148,
                        height: 44,
                    },
                },
            },
        },
        SR19iii: {
            width: 157,
            height: 36,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 157,
                        height: 36,
                    },
                },
            },
        },
        SR20: {
            width: 75,
            height: 125,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 75,
                        height: 125,
                    },
                },
            },
        },
        SR20i: {
            width: 134,
            height: 65,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 134,
                        height: 65,
                    },
                },
            },
        },
        SR20ii: {
            width: 145,
            height: 55,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 145,
                        height: 55,
                    },
                },
            },
        },
        SR20iii: {
            width: 160,
            height: 43,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 160,
                        height: 43,
                    },
                },
            },
        },
        SR22: {
            width: 152,
            height: 65,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 152,
                        height: 65,
                    },
                },
            },
        },
        SR22i: {
            width: 148,
            height: 75,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 148,
                        height: 76,
                    },
                },
            },
        },
        SR23: {
            width: 105,
            height: 23,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 105,
                        height: 23,
                    },
                },
            },
        },
        SR23i: {
            width: 167,
            height: 63,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 167,
                        height: 63,
                    },
                },
            },
        },
        SR23ii: {
            width: 120,
            height: 110,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 120,
                        height: 110,
                    },
                },
            },
        },
        SR24: {
            width: 105,
            height: 32,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 105,
                        height: 32,
                    },
                },
            },
        },
        SR24i: {
            width: 143,
            height: 100,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 143,
                        height: 100,
                    },
                },
            },
        },
        SR25: {
            width: 205,
            height: 40,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 205,
                        height: 40,
                    },
                },
            },
        },
        SR25i: {
            width: 183,
            height: 63,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 183,
                        height: 63,
                    },
                },
            },
        },
        SR26: {
            width: 205,
            height: 51,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 205,
                        height: 51,
                    },
                },
            },
        },
        SR27: {
            width: 205,
            height: 65,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 205,
                        height: 65,
                    },
                },
            },
        },
        SR27i: {
            width: 199,
            height: 75,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 199,
                        height: 75,
                    },
                },
            },
        },
        SR29: {
            width: 260,
            height: 27,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 260,
                        height: 27,
                    },
                },
            },
        },
        SR29i: {
            width: 205,
            height: 85,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 205,
                        height: 85,
                    },
                },
            },
        },
        SR29ii: {
            width: 211,
            height: 80,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 211,
                        height: 80,
                    },
                },
            },
        },
        SR29iii: {
            width: 223,
            height: 70,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 223,
                        height: 70,
                    },
                },
            },
        },
        SR30: {
            width: 260,
            height: 35,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 260,
                        height: 35,
                    },
                },
            },
        },
        SR31: {
            width: 285,
            height: 21,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 285,
                        height: 21,
                    },
                },
            },
        },
        SR33: {
            width: 267,
            height: 65,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 267,
                        height: 65,
                    },
                },
            },
        },
        SR34: {
            width: 295,
            height: 44,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 295,
                        height: 44,
                    },
                },
            },
        },
        SR37: {
            width: 295,
            height: 75,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 295,
                        height: 75,
                    },
                },
            },
        },
        SR39: {
            width: 285,
            height: 100,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 285,
                        height: 100,
                    },
                },
            },
        },
        SR44: {
            width: 280,
            height: 160,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 280,
                        height: 160,
                    },
                },
            },
        },
    },
    _a);
export default LABEL_RENDER_CONFIG;
