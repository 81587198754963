var _a;
import productBoxesGrammages from "./productBoxesGrammages";
import mailerBoxesGrammages from "./mailerBoxesGrammages";
import productBoxesTwoPiecesGrammages from "./productBoxesTwoPiecesGrammages";
import productWineBoxesGrammages from "./productWineBoxesGrammages";
import mailerBoxesDeliveryGrammages from "./mailerBoxesDeliveryGrammages";
import shippingBoxesGrammages from "./shippingBoxesGrammages";
import BOX_CONSTS from "../consts/boxConsts";
var BOX_PRODUCT_GRAMMAGES = (_a = {},
    _a[BOX_CONSTS.SHIPPING_BOX] = shippingBoxesGrammages,
    _a[BOX_CONSTS.SHIPPING_BOX_PLAIN] = shippingBoxesGrammages,
    _a[BOX_CONSTS.SLEEVED_MAILER_BOX] = mailerBoxesGrammages,
    _a[BOX_CONSTS.MAILER_BOX] = mailerBoxesGrammages,
    _a[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO] = mailerBoxesGrammages,
    _a[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR] = mailerBoxesGrammages,
    _a[BOX_CONSTS.MAILER_BOX_PLAIN] = mailerBoxesGrammages,
    _a[BOX_CONSTS.MAILER_BOX_WHITE_INK] = mailerBoxesGrammages,
    _a[BOX_CONSTS.PRODUCT_BOX] = productBoxesGrammages,
    _a[BOX_CONSTS.PRODUCT_BOX_TWO_PIECES] = productBoxesTwoPiecesGrammages,
    _a[BOX_CONSTS.PRODUCT_BOX_WINE_BOX] = productWineBoxesGrammages,
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX] = mailerBoxesDeliveryGrammages,
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN] = mailerBoxesDeliveryGrammages,
    _a);
export default BOX_PRODUCT_GRAMMAGES;
