import { CreateTempPatternService } from "../../dieline-navigator/services/create-temp-pattern"
import { PackhelpObject } from "../../../object-extensions/packhelp-objects"
import { ModelEditableSpaces } from "../../../../../../libs/products-render-config/types"
import { TempLayers } from "../../../types/render-engine-types"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { isGroup } from "../../../../../../modules/ph-api/asset-types"
import { BackgroundsLayers } from "../types"

export class TempPatternController {
  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public async applyTempPattern(
    space: PackhelpObject,
    zoomRatio: number
  ): Promise<PackhelpObject> {
    const tempPattern = await this.createTempPattern(space, zoomRatio)

    this.removeTempPattern()
    this.vdEditor.addOnCanvas(tempPattern, false)

    return tempPattern
  }

  public removeTempPattern() {
    const tempPattern = this.getTempPattern()

    if (tempPattern) {
      this.vdEditor.fabricCanvas.remove(tempPattern)
    }
  }

  public async refreshTempPattern(
    space: PackhelpObject,
    zoomRatio: number
  ): Promise<void> {
    const globalPattern = this.getGlobalPattern()

    if (!globalPattern) {
      return this.removeTempPattern()
    }

    await this.applyTempPattern(space, zoomRatio)
  }

  public getTempPattern(): PackhelpObject | undefined {
    return this.vdEditor.getCanvasObjectById(TempLayers.TEMP_PATTERN)
  }

  private getGlobalPattern(): PackhelpObject | undefined {
    return this.vdEditor.getCanvasObjectById(BackgroundsLayers.GLOBAL_PATTERN)
  }

  private async createTempPattern(
    space: PackhelpObject,
    zoomRatio: number
  ): Promise<PackhelpObject> {
    const globalPattern = this.vdEditor.backgroundsModule.getGlobalPattern()

    if (!globalPattern) {
      throw new Error("Global pattern is not available")
    }

    const createTempPatternService = new CreateTempPatternService(
      this.vdEditor,
      globalPattern.patternSourceConfig!,
      globalPattern.patternSizePx
    )

    const tempPattern = await createTempPatternService.generate(
      space,
      zoomRatio
    )

    tempPattern.set({
      visible: this.shouldShowTempPattern(
        space.originSpaceArea as ModelEditableSpaces
      ),
    })

    return tempPattern
  }

  public toggleVisibleTempPattern(spaceId: ModelEditableSpaces) {
    const tempPattern = this.getTempPattern()

    if (!tempPattern) {
      return
    }

    tempPattern.set({
      visible: this.shouldShowTempPattern(spaceId),
    })
  }

  private shouldShowTempPattern(spaceId: ModelEditableSpaces): boolean {
    const globalPattern = this.vdEditor.backgroundsModule.getGlobalPattern()

    if (!globalPattern) {
      return false
    }

    if (globalPattern.clipPath && isGroup(globalPattern.clipPath)) {
      const clipPathGroup = globalPattern.clipPath
      const spaceClipPath = clipPathGroup
        .getObjects()
        .find((obj) => obj.id === spaceId)

      const hasPadding = spaceClipPath?.hasPadding
      if (!spaceClipPath) {
        return true
      }

      return spaceClipPath && !!hasPadding
    }

    if (globalPattern.clipPath) {
      const clipPathObject = globalPattern.clipPath as PackhelpObject
      const hasPadding = clipPathObject.hasPadding
      return clipPathObject.id === spaceId && !!hasPadding
    }

    return true
  }
}
