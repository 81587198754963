import React from "react"
import { Card } from "dsl/src/atoms/Card/Card"
import styles from "./Header.module.scss"
import { EditorHeaderLogo } from "../../../../dsl/organisms/EditorHeader/EditorHeaderProjectDetails/EditorHeaderLogo"

export const Header = () => {
  return (
    <Card fluid>
      <div className={styles.header}>
        <EditorHeaderLogo />
      </div>
    </Card>
  )
}

export default Header
