import VirtualDielineEditor from "../../../virtual-dieline-editor"
import {
  PackhelpMaskObject,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import { EditableObjectTypes } from "../../../../../../modules/ph-api/asset-types"
import { MaskConfig, MaskType } from "./types"
import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import { v4 as uuidv4 } from "uuid"
import { NotValidOperationError } from "./errors"

export class MaskBuilder {
  constructor(
    private maskParent: PackhelpEditableObject,
    private virtualDielineEditor: VirtualDielineEditor
  ) {}

  public async buildGlobalClippingMask(
    config: MaskConfig
  ): Promise<PackhelpMaskObject> {
    if (typeof this.maskParent.originSpaceArea === "undefined") {
      throw new NotValidOperationError(
        "Global mask works only with editable objects"
      )
    }

    return await this.getMaskShapeWithParams(config.shape, {
      left: this.maskParent.left!,
      top: this.maskParent.top!,
      width: config.size.width,
      height: config.size.height,
      originX: "left",
      originY: "top",
      clipPath: await this.buildGlobalClippingMaskClipPath(),
      maskParentId: this.maskParent.id,
    })
  }

  public async buildGlobalClippingMaskClipPath(): Promise<PackhelpObject> {
    const clipPath =
      await this.virtualDielineEditor.dielineNavigator.cloneSpaceToClipPath(
        this.maskParent.originSpaceArea,
        false,
        false
      )

    clipPath.absolutePositioned = true

    return clipPath
  }

  public async buildMask(
    config: MaskConfig,
    type: MaskType
  ): Promise<PackhelpMaskObject> {
    return this.getMaskShapeWithParams(config.shape, {
      fill: config.color.getHex(),
      fillPantoneName: config.color.getPantoneName(),
      left: 0,
      top: 0,
      width: config.size.width,
      height: config.size.height,
      originX: "left",
      originY: "top",
      maskParentId: this.maskParent.id,
      assetType: EditableObjectTypes.assetObject,
      originSpaceArea: this.maskParent.originSpaceArea,
      id: uuidv4(),
      lockUniScaling: !!config.shape.keepRatio,
      lockScalingX: config.isEditingDisabled,
      lockScalingY: config.isEditingDisabled,
      hasControls: !config.isEditingDisabled,
      indexConfig: this.maskParent.indexConfig,
      maskType: type,
      ...this.UIParams,
    })
  }

  public async buildTempClippingMask(
    config: MaskConfig
  ): Promise<PackhelpMaskObject> {
    return this.getMaskShapeWithParams(config.shape, {
      left: 0,
      top: 0,
      angle: 0,
      width: config.size.width,
      height: config.size.height,
      originX: "left",
      originY: "top",
      maskParentId: this.maskParent.id,
    })
  }

  private async getMaskShapeWithParams(
    shape: PackhelpMaskObject,
    params: Partial<PackhelpMaskObject>
  ): Promise<PackhelpMaskObject> {
    let scaleX = 1
    let scaleY = 1

    const shapeWidth = shape.width!
    const shapeHeight = shape.height!

    if (params.width) {
      scaleX = params.width! / shapeWidth
    }

    if (params.height) {
      scaleY = params.height! / shapeHeight
    }

    return new Promise((resolve) => {
      shape.clone((clonedShape) => {
        clonedShape.set({
          ...this.defaultParams,
          ...params,
          width: shapeWidth,
          height: shapeHeight,
          scaleX: scaleX,
          scaleY: scaleY,
          assetObjectMeta: shape.assetObjectMeta,
          borderRadius: shape.borderRadius || shape.rx || 0,
          keepRatio: shape.keepRatio,
        })

        resolve(clonedShape)
      })
    })
  }

  public get UIParams(): Partial<PackhelpMaskObject> {
    return {
      hasRotatingPoint: false,
      lockRotation: true,
      borderColor: "#3b61ff",
      stroke: "#3b61ff",
      strokeUniform: true,
      strokeDashArray: [5, 5],
      cornerSize: 12,
      centeredScaling: true,
      centeredRotation: true,
      lockScalingFlip: true,
    }
  }

  private get defaultParams(): Partial<PackhelpMaskObject> {
    return {
      clipPath: undefined,
      strokeWidth: 0,
      centeredScaling: true,
      centeredRotation: true,
      lockScalingFlip: true,
      assetType: undefined,
      originSpaceArea: undefined,
    }
  }
}
