import styles from "./Error.module.scss"
import React from "react"
import { reloadPage } from "../../utils/proscenium.helpers"
import { TM } from "../../../../TypographyI18n"
import { TType } from "dsl/src/atoms/Typography/Typography"
import { Button } from "dsl/src/atoms/Button/Button"
import { Header } from "../components/Header"

enum i18n {
  something_went_wrong = "best-practices-modal.fetch-error",
  please_reload = "component.error.please-reload",
  reload = "modals.error-view.reload-button",
}

export const Error = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.layout}>
        <Header />
        <div className={styles.header}>
          <TM id={i18n.something_went_wrong} type={TType.Header40_400} />
        </div>
        <div className={styles.subheader}>
          <TM id={i18n.please_reload} type={TType.Body17_350} />
        </div>
        <Button onClick={() => reloadPage()}>
          <TM id={i18n.reload} type={TType.Header17_500} />
        </Button>
      </div>
    </div>
  )
}
