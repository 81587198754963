var _a, _b;
import ENVELOPE_CONST from "../generalConfig/consts/envelopeConsts";
var GENERIC = [
    {
        uid: 1,
        color: "#FFFFFF",
        description: "box-editor.pantone-picker.color-descriptions.white",
    },
    {
        uid: 2,
        color: "#FFEE04",
        description: "box-editor.pantone-picker.color-descriptions.yellow",
    },
    {
        uid: 3,
        color: "#000000",
        description: "box-editor.pantone-picker.color-descriptions.black",
    },
    {
        uid: 4,
        color: "#DF5C25",
        description: "box-editor.pantone-picker.color-descriptions.orange",
    },
    {
        uid: 5,
        color: "#D31272",
        description: "box-editor.pantone-picker.color-descriptions.pink",
    },
    {
        uid: 6,
        color: "#D31F26",
        description: "box-editor.pantone-picker.color-descriptions.red",
    },
    {
        uid: 7,
        color: "#CCD52A",
        description: "box-editor.pantone-picker.color-descriptions.green",
    },
    {
        uid: 8,
        color: "#005C9B",
        description: "box-editor.pantone-picker.color-descriptions.blue",
    },
    {
        uid: 9,
        color: "#752771",
        description: "box-editor.pantone-picker.color-descriptions.purple",
    },
];
var ProductPrederedColors = {
    COLORS_CONFIG: (_a = {
            SHARED: {
                EDITOR_DEFAULT_COLOR: {
                    DEFAULT: {
                        color: "#000000",
                    },
                },
            }
        },
        _a[ENVELOPE_CONST.ENVELOPE_MODEL_DEFAULT] = {
            VARIANTS: (_b = {},
                _b[ENVELOPE_CONST.ENVELOPE_VARIANT_STANDARD] = {
                    PANTONE_COLORS: false,
                    LIMITED_COLORS: false,
                    LIMITED_COLORS_DESCRIPTION: true,
                },
                _b),
        },
        _a),
    COLORS_LISTS: {
        GENERIC: GENERIC,
    },
};
export default ProductPrederedColors;
