import React from "react"
import { EditorToolShapesHeaderWired } from "../../tools/EditorToolShapes"
import { EditorToolTextHeader } from "../../tools/EditorToolText/EditorToolText"
import { EditorToolHeaderImageAssets } from "../../tools/two-dim-toolbars/editor-tool-header-assets/EditorToolHeaderImageAssets"
import {
  isAssetImage,
  isAssetShape,
  isAssetSvgImage,
  isAssetText,
} from "../../../../../modules/ph-api/asset-types"
import { PackhelpEditableObject } from "../../../../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { EditorToolHeaderSvgImageAssets } from "../../tools/two-dim-toolbars/editor-tool-header-assets/EditorToolHeaderSvgImageAssets"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

interface EditorHeaderAssetToolbarProps {
  activeObject: PackhelpEditableObject | undefined
}

export const EditorHeaderAssetToolbar = ({
  activeObject,
}: EditorHeaderAssetToolbarProps) => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ui,
  ])

  if (!activeObject || !containerSet) {
    return null
  }

  const { designAndProductDriver, ui } = containerSet

  if (isAssetText(activeObject)) {
    return (
      <EditorToolTextHeader
        productDriver={designAndProductDriver.productDriver}
        uiContainer={ui}
      />
    )
  }

  if (isAssetShape(activeObject)) {
    return (
      <EditorToolShapesHeaderWired
        productDriver={designAndProductDriver.productDriver}
      />
    )
  }

  if (isAssetSvgImage(activeObject)) {
    return (
      <EditorToolHeaderSvgImageAssets
        productDriver={designAndProductDriver.productDriver}
      />
    )
  }

  if (isAssetImage(activeObject)) {
    return (
      <EditorToolHeaderImageAssets
        productDriver={designAndProductDriver.productDriver}
      />
    )
  }

  return null
}
