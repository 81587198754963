import { TypedEmitter, EESet } from "./_events/typed-event-emitter"
import {
  SessionEventNames,
  SessionEventTypes,
} from "./session-store/session.events"
import {
  ProductDesignEvents,
  ProductDesignTypes,
} from "./product-design-store/product-design.events"
import {
  PatternEvents,
  PatternsEventsTypes,
} from "./patterns-store/patterns.events"
import {
  ProductDriverEvents,
  ProductDriverTypes,
} from "./product-driver/product-driver.events"
import {
  TemplateEvents,
  TemplatesEventsTypes,
} from "./templates-store/templates.events"
import {
  TabEvents,
  TabEventsTypes,
} from "./editor-toolbar-store/editor-toolbar-events"
import { LogoEvents, LogoEventsTypes } from "./logo-store/logo.events"
import {
  NotificationEvents,
  NotificationEventsTypes,
} from "./notification-store/notification.events"

import { CartEvents, CartEventsTypes } from "./cart-store/cart.events"
import {
  FontLoaderEvents,
  FontLoaderTypes,
  EditorUIViewEvents,
  EditorUIViewTypes,
  GraphicAssetEvents,
  GraphicAssetTypes,
} from "./_events/domain-events"
import {
  BackgroundImageEvents,
  BackgroundImageEventTypes,
} from "./background-image-store/background-image.events"
import { KeyboardEventTypes, KeyboardEvents } from "./_events/keyboard-events"
import {
  ActiveObjectEvents,
  ActiveObjectEventTypes,
} from "./_events/active-object-events"
import {
  ActiveTabEvents,
  ActiveTabEventTypes,
} from "./_events/active-tab-events"
import {
  FscCertificateEvents,
  FscCertificateEventsTypes,
} from "./certificates-store/fsc-certificate.events"
import {
  PricingEvents,
  PricingEventsTypes,
} from "./pricing-store/pricing.events"

type LoaderEvents = CartEventsTypes & FontLoaderTypes

type EditorEvents = SessionEventTypes &
  ProductDesignTypes &
  CartEventsTypes &
  FontLoaderTypes &
  PatternsEventsTypes &
  TemplatesEventsTypes &
  LogoEventsTypes &
  ProductDriverTypes &
  GraphicAssetTypes &
  EditorUIViewTypes &
  TabEventsTypes &
  BackgroundImageEventTypes &
  KeyboardEventTypes &
  ActiveObjectEventTypes &
  ActiveTabEventTypes &
  NotificationEventsTypes &
  FscCertificateEventsTypes &
  PricingEventsTypes

export type EditorEventNames = keyof EditorEvents

export type EEWithLoaderEvents = EESet<LoaderEvents>
export type AllEditorEventsEmitter = EESet<EditorEvents>

export const eventTree = {
  session: SessionEventNames,
  pd: ProductDesignEvents,
  cart: CartEvents,
  font: FontLoaderEvents,
  patterns: PatternEvents,
  templates: TemplateEvents,
  logo: LogoEvents,
  productDriver: ProductDriverEvents,
  ui: EditorUIViewEvents,
  graphics: GraphicAssetEvents,
  tab: TabEvents,
  backgroundImages: BackgroundImageEvents,
  keyboard: KeyboardEvents,
  activeObject: ActiveObjectEvents,
  activeTab: ActiveTabEvents,
  notification: NotificationEvents,
  fsc: FscCertificateEvents,
  pricing: PricingEvents,
}

export const loaderEventTree = {
  fonts: FontLoaderEvents,
  cart: CartEvents,
}

export const ee: AllEditorEventsEmitter = new TypedEmitter()

export { eventTree as ET }

// Why? We already export it as `ee`
export { ee as EE }
