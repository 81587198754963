var LABEL_PRODUCTS_EDITOR_LIMITATIONS = {
    shared: {
        initialActiveSide: {
            activeSideId: "front",
            editedSideId: "front",
        },
        isPantoneColorsAvailable: false,
        sidesNameCodesMap: {
            front: "A",
        },
        reforestationSupport: {
            badge: true,
            trees: true,
        },
    },
    products: {},
};
export default LABEL_PRODUCTS_EDITOR_LIMITATIONS;
