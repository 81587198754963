import React from "react"
import { useTranslate } from "../../../../hooks/useTranslate"
import { EditorHeaderToolbarItem } from "../../EditorHeader/EditorHeaderToolbar/EditorHeaderToolbar"
import styles from "../EditorTools.module.scss"
import {
  FontSettings,
  FontAlign,
} from "../../../../../libs/services/fonts-loader-service/fonts-loader.service"

import { ReactComponent as IconTextCenter } from "../../../../assets/svg/text-center.svg"
import { ReactComponent as IconTextRight } from "../../../../assets/svg/text-right.svg"
import { ReactComponent as IconTextLeft } from "../../../../assets/svg/text-left.svg"
import { IconButton } from "../../../atoms/IconButton/IconButton"

interface EditorToolTextAlignOptionsProps {
  activeObjectController
  textAlign
  setTextAlignment
}

const editorToolTextAlignItems = [
  {
    fontAttribute: FontSettings.textAlign,
    fontProperty: FontAlign.alignLeft,
    translationIntl: "components.editor-tool-text.label.align-left",
    Icon: IconTextLeft,
  },
  {
    fontAttribute: FontSettings.textAlign,
    fontProperty: FontAlign.alignCenter,
    translationIntl: "components.editor-tool-text.label.align-center",
    Icon: IconTextCenter,
  },
  {
    fontAttribute: FontSettings.textAlign,
    fontProperty: FontAlign.alignRight,
    translationIntl: "components.editor-tool-text.label.align-right",
    Icon: IconTextRight,
  },
]

export const EditorToolTextAlignOptions = ({
  activeObjectController,
  textAlign,
  setTextAlignment,
}: EditorToolTextAlignOptionsProps) => {
  const t = useTranslate()

  const onAlignSelect = (textAlignParam): void => {
    activeObjectController.setStyles({
      textAlign: textAlignParam,
    })
    setTextAlignment(textAlignParam)
  }

  return (
    <div className={styles.header_toolbar_list_group}>
      {editorToolTextAlignItems.map((fontAlignItem, index) => (
        <li key={index} className={styles.header_toolbar_list_item}>
          <EditorHeaderToolbarItem
            onClick={() => {
              onAlignSelect(fontAlignItem.fontProperty)
            }}
            helperMessage={t(fontAlignItem.translationIntl)}
          >
            <IconButton
              isActive={fontAlignItem.fontProperty === textAlign}
              Icon={fontAlignItem.Icon}
              e2eTargetName={fontAlignItem.fontProperty}
            />
          </EditorHeaderToolbarItem>
        </li>
      ))}
    </div>
  )
}
