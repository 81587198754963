import React, { PropsWithChildren } from "react"
import cxBinder from "classnames/bind"

import styles from "../CameraControlsToolbar.module.scss"
import { DotAlert } from "../../../../atoms/dot-alert/dot-alert"
import { TType } from "shared-libs/src/js/shared-components/i18n/TypographyWithTranslate"
import Typography from "dsl/src/atoms/Typography/Typography"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { I18N } from "../../../../../i18n"
import { LimitedSpacesToEditWarning } from "../LimitedSpacesToEditWarning"

const cx = cxBinder.bind(styles)

type CameraControlsToolbarElementProps = {
  isActive?: boolean
  isAlert?: boolean
  onClick?: () => void
  e2eTarget?: string
  e2eTargetName?: string
  hasOneEditableSpace?: boolean
  size?: "full" | "half"
}

export const CameraControlsToolbarElement = ({
  isAlert = false,
  isActive = false,
  onClick,
  e2eTarget = "camera-view",
  e2eTargetName,
  children,
  hasOneEditableSpace,
  size = "half",
}: PropsWithChildren<CameraControlsToolbarElementProps>) => {
  const t = useTranslate()
  const i18n = I18N.component.cameraControlsToolbar

  return (
    <li
      className={cx({
        element: true,
        ["element--active"]: isActive,
        ["element--full"]: size === "full",
      })}
      onClick={onClick}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {children}
      {isAlert && (
        <div className={styles.alert_wrapper}>
          <DotAlert size="large" />
        </div>
      )}

      {hasOneEditableSpace && (
        <>
          <LimitedSpacesToEditWarning />

          <Typography
            type={TType.Header13_500}
            className={styles.tooltip_warning}
          >
            {t(i18n.limitedSpacesWarning)}
          </Typography>
        </>
      )}
    </li>
  )
}
