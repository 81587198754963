var _a;
import PAPER_TUBE_CONSTS from "../generalConfig/consts/paperTubeConsts";
var PAPER_TUBE_RENDER_CONFIG = (_a = {},
    _a[PAPER_TUBE_CONSTS.PAPER_CAN] = {
        T95: {
            width: 247,
            height: 95,
            length: 10,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
        },
        T100: {
            width: 266,
            height: 100,
            length: 10,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
        },
        T120: {
            width: 244,
            height: 120,
            length: 10,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
        },
        T215: {
            width: 326,
            height: 215,
            length: 10,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
        },
        T280: {
            width: 304,
            height: 280,
            length: 10,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
        },
        T350: {
            width: 329,
            height: 350,
            length: 10,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
        },
    },
    _a);
export default PAPER_TUBE_RENDER_CONFIG;
