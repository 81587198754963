import React from "react"
import { observer } from "mobx-react-lite"
import { DoubleSidePrintSwitcher } from "../../../dsl/organisms/tools/two-dim-toolbars/edit-context-switcher/DoubleSidePrintSwitcher"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"
import { ViewType } from "../../../../libs/products-render-config/types"

export const EditorHeaderContextToolbar = observer(() => {
  const [designAndProductDriverContainer] =
    useContainer().designAndProductDriver

  if (!designAndProductDriverContainer) {
    return null
  }

  const {
    productRenderPilot,
    isDesignPreviewMode,
    activeContext,
    activeViewType,
  } = designAndProductDriverContainer.productDriver.state

  const isPrintAdditionallyPaid =
    productRenderPilot.isPrintAdditionallyPaidFor(activeContext)
  const editorMode = productRenderPilot.getEditorMode()
  const isAfterPurchaseEdit = productRenderPilot.isAfterPurchaseEdit

  const showDoubleSidePrintSwitcher =
    editorMode === "editor" &&
    !isDesignPreviewMode &&
    isPrintAdditionallyPaid &&
    !isAfterPurchaseEdit &&
    activeViewType !== ViewType.MODEL

  if (!showDoubleSidePrintSwitcher) {
    return null
  }

  return <DoubleSidePrintSwitcher />
})
