import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import {
  isActiveSelection,
  isAssetGroup,
  isAssetText,
} from "../../../../../../modules/ph-api/asset-types"

const DEFAULT_CONTROLS = {
  bl: true,
  br: true,
  mb: true,
  ml: true,
  mr: true,
  mt: true,
  tl: true,
  tr: true,
}

const NON_UNI_SCALING_CONTROLS = {
  bl: true,
  br: true,
  mb: false,
  ml: false,
  mr: false,
  mt: false,
  tl: true,
  tr: true,
}

export class ControlsHelper {
  public static setControls(object: PackhelpEditableObject) {
    if (object.lockRotation) {
      this.hideRotationControl(object)
    }

    if (
      isAssetText(object) ||
      isAssetGroup(object) ||
      isActiveSelection(object) ||
      object.lockUniScaling
    ) {
      return this.setNonUniScalingControls(object)
    }

    this.setDefaultControls(object)
  }

  private static setNonUniScalingControls(object: PackhelpEditableObject) {
    object.set({
      lockUniScaling: true,
    })

    object.setControlsVisibility(NON_UNI_SCALING_CONTROLS)
  }

  private static setDefaultControls(object: PackhelpEditableObject) {
    object.setControlsVisibility(DEFAULT_CONTROLS)
  }

  private static hideRotationControl(object: PackhelpEditableObject) {
    object.setControlsVisibility({
      mtr: false,
    })
  }
}
