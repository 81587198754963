import React from "react"
import { EditorHeaderLogo } from "./EditorHeaderLogo"
import Button from "dsl/src/atoms/Button/Button"
import { ReactComponent as IconArrowLeft } from "../../../../assets/svg/arrow-left.svg"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import { FullProductDescription } from "../../../../../libs/products-render-config/types"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import cxBinder from "classnames/bind"
import styles from "./EditorHeaderProjectDetails.module.scss"

const cx = cxBinder.bind(styles)

const EditorHeaderBackButton = ({ onClick }) => {
  return (
    <div className={styles.editor_header__project_details__back_button}>
      <Button e2eTargetName="back" onClick={onClick}>
        <IconArrowLeft />
      </Button>
    </div>
  )
}

const ProductDescription = ({
  description,
}: {
  description: FullProductDescription
}) => {
  const t = useTranslate()

  const getProductDesc = () => {
    const { variantNameKey, whiteInsideKey, finishKey } = description
    const variantNameIntl = t(variantNameKey)
    const whiteInsideIntl = whiteInsideKey ? t(whiteInsideKey) : ""
    const finishIntl = finishKey ? t(finishKey) : ""

    return {
      variantNameIntl,
      whiteInsideIntl,
      finishIntl,
    }
  }

  const getProductDimensions = () => {
    return description.size
  }

  const getProductName = () => {
    const { variantNameIntl, whiteInsideIntl, finishIntl } = getProductDesc()
    return `${variantNameIntl} ${whiteInsideIntl} ${finishIntl}`
  }

  return (
    <div className={styles.product_details_wrapper__description}>
      <Typography e2eTargetName="product-name" type={TType.Header15_500}>
        {getProductName()}
      </Typography>
      <Typography
        e2eTargetName="product-dimensions"
        type={TType.Body13_350}
        className={styles.product_details_wrapper__dimensions}
      >
        {getProductDimensions()}
      </Typography>
    </div>
  )
}

export const EditorHeaderProjectDetails = observer(() => {
  const [containerSet] = useContainerSet((c) => [c.designAndProductDriver])

  if (!containerSet) {
    return null
  }

  const { productDriver, contextController } =
    containerSet.designAndProductDriver

  const { productRenderPilot } = productDriver.state
  const { activeSpace } = productDriver.state
  const product = productRenderPilot.getProduct()
  const is3DProduct = productRenderPilot.is3DProduct()
  const is2DProduct = !is3DProduct
  const isDefaultSpaceView = productDriver.isDefaultView()
  const isDbyMode = productRenderPilot.isDbyMode()

  if (!product) {
    return (
      <div className={styles.editor_header__project_details}>
        <EditorHeaderLogo />
      </div>
    )
  }

  const showButton =
    (is3DProduct && activeSpace) ||
    (is2DProduct && !isDefaultSpaceView && !isDbyMode)

  return (
    <div
      className={cx(
        styles.editor_header__project_details,
        styles.editor_header_project_details__bordered
      )}
    >
      {showButton ? (
        <EditorHeaderBackButton
          onClick={() => contextController.switchToLastPreview()}
        />
      ) : (
        <EditorHeaderLogo />
      )}

      <div className={styles.product_details_wrapper}>
        <ProductDescription
          description={productRenderPilot.getFullProductDescription()}
        />
      </div>
    </div>
  )
})
