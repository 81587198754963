import { MenuItem } from "../../dsl/organisms/toolbars/EditorToolbar/EditorToolbar"
import { EditorToolbarItem } from "../../../stores/editor-toolbar-store"
import {
  EditContext,
  ModelEditableSpaces,
} from "../../../libs/products-render-config/types"

import { ReactComponent as IconText } from "../../assets/svg/text.svg"
import { ReactComponent as IconShape } from "../../assets/svg/shapes.svg"
import { ReactComponent as IconImage } from "../../assets/svg/images.svg"
import { ReactComponent as IconLeaf } from "../../assets/svg/leaf.svg"
import { ReactComponent as IconFsc } from "../../assets/svg/fsc.svg"
import { ReactComponent as IconHeartLight } from "../../assets/svg/heart-outline.svg"
import { ReactComponent as IconLogoPlaceholder } from "../../assets/svg/logo-placeholder.svg"
import { ReactComponent as IconPatterns } from "../../assets/svg/patterns.svg"
import { ReactComponent as IconPatternsFull } from "../../assets/svg/patterns-full.svg"
import { ReactComponent as IconStar } from "../../assets/svg/star.svg"
import { ReactComponent as DesignOnline } from "../../assets/svg/designOnline.svg"
import { ReactComponent as Configuration } from "../../assets/svg/configuration.svg"
import { ReactComponent as IconProducts } from "../../assets/svg/boxes.svg"
import { ReactComponent as IconPalette } from "../../assets/svg/palette-outline.svg"
import { ReactComponent as IconPaletteFull } from "../../assets/svg/palette-full.svg"

import { I18N } from "../../i18n"
const i18n = I18N.tabs

export const availableEditorToolbarItems: MenuItem[] = [
  {
    name: EditorToolbarItem.productSwitcher,
    nameIntl: i18n.productSwitcher,
    Icon: IconProducts,
    visibility: {
      product2D: true,
      product3D: {
        spaceView: false,
        dielineView: true,
        modelView: true,
      },
    },
  },
  {
    name: EditorToolbarItem.productConfiguration,
    nameIntl: i18n.productConfiguration,
    Icon: Configuration,
    visibility: {
      product2D: true,
      product3D: {
        spaceView: false,
        dielineView: true,
        modelView: true,
      },
    },
  },
  {
    name: EditorToolbarItem.logo,
    nameIntl: i18n.logo,
    Icon: IconHeartLight,
    visibility: {
      product2D: true,
      product3D: {
        spaceView: true,
        dielineView: true,
        modelView: true,
      },
    },
  },
  {
    name: EditorToolbarItem.logoPlaceholder,
    nameIntl: i18n.logoPlaceholder,
    Icon: IconLogoPlaceholder,
    visibility: {
      product2D: true,
      product3D: {
        spaceView: true,
        dielineView: true,
        modelView: true,
      },
    },
  },
  {
    name: EditorToolbarItem.templates,
    nameIntl: i18n.templates,
    Icon: IconStar,
    visibility: {
      product2D: true,
      product3D: {
        spaceView: false,
        dielineView: [EditContext.OUTSIDE],
        modelView: true,
      },
    },
  },
  {
    name: EditorToolbarItem.replicablePatterns,
    nameIntl: i18n.patterns,
    Icon: IconPatterns,
    IconActive: IconPatternsFull,
    visibility: {
      product2D: [EditContext.FRONT],
      product3D: false,
    },
  },
  {
    name: EditorToolbarItem.materialAndColor,
    nameIntl: i18n.materialAndColor,
    Icon: IconPalette,
    IconActive: IconPaletteFull,
    visibility: {
      product2D: true,
      product3D: false,
    },
  },
  {
    name: EditorToolbarItem.patterns,
    nameIntl: i18n.patterns,
    Icon: IconPatterns,
    IconActive: IconPatternsFull,
    visibility: {
      product2D: [ModelEditableSpaces.FRONT, EditContext.FRONT],
      product3D: {
        spaceView: false,
        dielineView: [EditContext.OUTSIDE],
        modelView: true,
      },
    },
  },
  {
    name: EditorToolbarItem.background,
    nameIntl: i18n.background,
    Icon: IconPalette,
    IconActive: IconPaletteFull,
    visibility: {
      product2D: true,
      product3D: {
        spaceView: false,
        dielineView: true,
        modelView: true,
      },
    },
  },
  {
    name: EditorToolbarItem.text,
    nameIntl: i18n.text,
    Icon: IconText,
    visibility: {
      product2D: true,
      product3D: {
        spaceView: true,
        dielineView: false,
        modelView: false,
      },
    },
  },
  {
    name: EditorToolbarItem.shapes,
    nameIntl: i18n.shapes,
    Icon: IconShape,
    visibility: {
      product2D: true,
      product3D: {
        spaceView: true,
        dielineView: false,
        modelView: false,
      },
    },
  },
  {
    name: EditorToolbarItem.images,
    nameIntl: i18n.images,
    Icon: IconImage,
    visibility: {
      product2D: true,
      product3D: {
        spaceView: true,
        dielineView: true,
        modelView: true,
      },
    },
  },
  {
    name: EditorToolbarItem.eco,
    nameIntl: i18n.eco,
    Icon: IconLeaf,
    visibility: {
      product2D: true,
      product3D: {
        spaceView: true,
        dielineView: true,
        modelView: true,
      },
    },
    topSeparator: true,
  },
  {
    name: EditorToolbarItem.fsc,
    Icon: IconFsc,
    visibility: {
      product2D: true,
      product3D: {
        spaceView: true,
        dielineView: true,
        modelView: true,
      },
    },
    hideTitle: true,
  },
  {
    name: EditorToolbarItem.devTools,
    Icon: DesignOnline,
    visibility: {
      product2D: true,
      product3D: {
        spaceView: false,
        dielineView: true,
        modelView: true,
      },
    },
    topSeparator: true,
    devTools: true,
  },
]
