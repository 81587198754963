export default {
    F23: {
        outside: {
            width: 92,
            length: 92,
            height: 50,
        },
        inside: {
            width: 79,
            length: 87,
            height: 45,
        },
    },
    F33: {
        outside: {
            width: 118,
            length: 140,
            height: 80,
        },
        inside: {
            width: 102,
            length: 135,
            height: 75,
        },
    },
    F44: {
        outside: {
            width: 207,
            length: 156,
            height: 91,
        },
        inside: {
            width: 196,
            length: 151,
            height: 86,
        },
    },
    F45: {
        outside: {
            width: 231,
            length: 140,
            height: 80,
        },
        inside: {
            width: 220,
            length: 135,
            height: 75,
        },
    },
    F46: {
        outside: {
            width: 245,
            length: 194,
            height: 22,
        },
        inside: {
            width: 229,
            length: 190,
            height: 18,
        },
    },
    F52: {
        outside: {
            width: 265,
            length: 195,
            height: 60,
        },
        inside: {
            width: 249,
            length: 190,
            height: 55,
        },
    },
    F56: {
        outside: {
            width: 260,
            length: 200,
            height: 105,
        },
        inside: {
            width: 245,
            length: 195,
            height: 100,
        },
    },
    F59: {
        outside: {
            width: 320,
            length: 182,
            height: 90,
        },
        inside: {
            width: 305,
            length: 177,
            height: 85,
        },
    },
    F62: {
        outside: {
            width: 302,
            length: 233,
            height: 88,
        },
        inside: {
            width: 289,
            length: 228,
            height: 83,
        },
    },
    F65: {
        outside: {
            width: 350,
            length: 250,
            height: 50,
        },
        inside: {
            width: 345,
            length: 245,
            height: 45,
        },
    },
    F71: {
        outside: {
            width: 350,
            length: 260,
            height: 100,
        },
        inside: {
            width: 337,
            length: 255,
            height: 95,
        },
    },
    F79: {
        outside: {
            width: 364,
            length: 314,
            height: 125,
        },
        inside: {
            width: 347,
            length: 309,
            height: 120,
        },
    },
    F93: {
        outside: {
            width: 440,
            length: 339,
            height: 159,
        },
        inside: {
            width: 426,
            length: 334,
            height: 154,
        },
    },
    F112: {
        outside: {
            width: 425,
            length: 520,
            height: 180,
        },
        inside: {
            width: 400,
            length: 511,
            height: 174,
        },
    },
};
