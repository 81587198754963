import fabric from "../../../../../libs/vendors/Fabric"
import VirtualDielineEditor from "../../virtual-dieline-editor"
import {
  AvailableRotations,
  TranslationCalculator,
} from "./services/translation-calculator"
import {
  PackhelpImage,
  PackhelpObject,
  VirtualDielineSpace,
} from "../../object-extensions/packhelp-objects"
import {
  CartesianCoords,
  Size,
  TempLayers,
} from "../../types/render-engine-types"
import {
  EditContext,
  ModelEditableSpaces,
} from "../../../../../libs/products-render-config/types"
import { TempPatternController } from "../backgrounds-module/pattern/temp-pattern-controller"
import { TempBackgroundController } from "./TempBackgroundController"
import { BackgroundsModule } from "../backgrounds-module"
import AssetsModule from "../assets-module"
import FiltersModule from "../filters-module"
import { ProductRenderPilot } from "../../../../../libs/products-render-config/product-render-pilot"
import { action, makeObservable, observable } from "mobx"
import { TempBackgroundImageController } from "../backgrounds-module/background-image/temp-background-image-controller"
import { SpaceClippingController } from "../backgrounds-module/space-clipping-controller"
import {
  isAssetGroup,
  isEditableObject,
  isGroup,
} from "../../../../../modules/ph-api/asset-types"
import { RotationHelper } from "../assets-module/helpers/rotation-helper"
import { BackgroundsLayers } from "../backgrounds-module/types"
import { ClippingHelper } from "../assets-module/helpers/clipping-helper"
import FabricAssetsLoaderService from "../../../../../libs/services/fabric-assets-loader-service"
import Colour from "../../../../../libs/value-objects/colour"
import { SceneDecorationController } from "../backgrounds-module/scene-decoration/scene-decoration.controller"

export class DielineNavigator {
  @observable public isSpaceLoading = false
  @observable public editZoneSize: { width: number; height: number } | null =
    null

  private readonly virtualDielineEditor: VirtualDielineEditor
  private currentRotation: AvailableRotations = 0
  private activeSpaceId: ModelEditableSpaces | null = null
  private readonly translationCalculator: TranslationCalculator
  private readonly tempPatternController: TempPatternController
  private readonly tempBackgroundController: TempBackgroundController
  private readonly tempBackgroundImageController: TempBackgroundImageController
  private readonly sceneDecorationController: SceneDecorationController
  private productRenderPilot: ProductRenderPilot
  private readonly editContext: EditContext
  private isZoomActive: boolean

  constructor(
    virtualDielineEditor: VirtualDielineEditor,
    backgroundsModule: BackgroundsModule,
    assetsModule: AssetsModule,
    productRenderPilot: ProductRenderPilot,
    editContext: EditContext
  ) {
    makeObservable(this)
    this.virtualDielineEditor = virtualDielineEditor
    this.editContext = editContext
    const { fabricCanvas } = virtualDielineEditor

    this.translationCalculator = new TranslationCalculator(
      virtualDielineEditor.getCanvasDimensions().width
    )
    this.tempPatternController = new TempPatternController(virtualDielineEditor)
    this.tempBackgroundController = new TempBackgroundController(fabricCanvas)
    this.tempBackgroundImageController = new TempBackgroundImageController(
      virtualDielineEditor
    )
    this.sceneDecorationController = new SceneDecorationController(
      virtualDielineEditor
    )

    //TODO: use vdEditor.productRenderPilot instead
    this.productRenderPilot = productRenderPilot

    const zoomConfig = productRenderPilot.uiConfig.editZone.zoom
    this.isZoomActive = zoomConfig.available && zoomConfig.activeByDefault

    this.updateEditZoneSize(this.activeSpaceId)
  }

  @action
  public updateEditZoneSize(spaceId: ModelEditableSpaces | null) {
    this.editZoneSize = spaceId ? this.getEditZoneDimensions(spaceId) : null
  }

  public setIsZoomActive(isZoomActive: boolean) {
    this.isZoomActive = isZoomActive
  }

  public setProductRenderPilot(productRenderPilot: ProductRenderPilot) {
    this.productRenderPilot = productRenderPilot
  }

  public setCurrentRotation(rotation: AvailableRotations) {
    this.currentRotation = rotation
  }

  public getCurrentRotation() {
    return this.currentRotation
  }

  public async resetPanning(): Promise<void> {
    this.tempBackgroundController.removeFromCanvas()
    this.tempBackgroundImageController.removeTempBackgroundImage()
    await this.tempPatternController.removeTempPattern()
    this.resetPanningToDefault()
    this.resetRotation()
    this.setActiveSpace(null)
    this.updateEditZoneSize(null)

    this.virtualDielineEditor.fabricCanvas.clipPath = undefined
  }

  public getActiveSpaceId(): ModelEditableSpaces | null {
    return this.activeSpaceId
  }

  @action
  public async panToSpace(spaceId: ModelEditableSpaces | null): Promise<void> {
    if (!spaceId) {
      return
    }

    this.setIsSpaceLoading(true)

    this.setActiveSpace(spaceId)
    this.rotateDielineSpace(spaceId)
    this.zoomToSpace()
    await this.sceneDecorationController.decorate(this.isZoomActive)
    await this.applyTempBackground(spaceId)
    await this.applyTempBackgroundImage(spaceId)

    if (
      this.isGlobalPatternApplied() &&
      !this.tempPatternController.getTempPattern()
    ) {
      await this.applyTempPattern(spaceId)
    }

    await this.applyCanvasSpaceClipPath(spaceId)

    this.setIsSpaceLoading(false)
  }

  public panToDieline(): void {
    this.rotateDieline()
    this.zoomToDieline()
  }

  private async applyCanvasSpaceClipPath(spaceId: ModelEditableSpaces) {
    const clipPath = await this.cloneSpaceToClipPath(spaceId, true, false)

    ClippingHelper.resizeClipPathBy(
      clipPath,
      this.productRenderPilot.uiConfig.editZone.shadow?.blur || 1
    )

    this.virtualDielineEditor.fabricCanvas.clipPath = clipPath
  }

  public onResize(): void {
    this.zoomToSpace()
    this.zoomToDieline()
  }

  public zoomToDieline(): void {
    const tempDieline = this.getTempDieline()

    if (!tempDieline) {
      return
    }

    const sceneDimensions = this.virtualDielineEditor.getSceneDimensions()
    const size = {
      width: tempDieline.getScaledWidth(),
      height: tempDieline.getScaledHeight(),
    }

    const padding = 5
    const zoom = Math.min(
      sceneDimensions.width / size.width,
      (sceneDimensions.height - 2 * padding) / size.height,
      1
    )

    this.virtualDielineEditor.fabricCanvas.setZoom(zoom)
    this.virtualDielineEditor.fabricCanvas.absolutePan(
      new fabric.Point(
        (sceneDimensions.width - size.width * zoom) / -2,
        (sceneDimensions.height - size.height * zoom) / -2
      )
    )
  }

  public async refreshTempBackgroundOnActiveSpace() {
    if (!this.activeSpaceId) {
      return
    }

    await this.tempBackgroundController.refreshBackground(
      this.getVirtualDielineSpace(this.activeSpaceId)
    )
  }

  public async refreshTempBackground(spaceId) {
    await this.tempBackgroundController.refreshBackground(
      this.getVirtualDielineSpace(spaceId)
    )
  }

  public async refreshTempBackgroundImageOnActiveSpace(): Promise<void> {
    if (!this.activeSpaceId) {
      return
    }

    await this.refreshTempBackgroundImage(this.activeSpaceId)
  }

  public async refreshTempBackgroundImage(
    spaceId: ModelEditableSpaces
  ): Promise<void> {
    await this.tempBackgroundImageController.refreshTempBackgroundImage(
      await this.cloneSpace(spaceId)
    )
  }

  public async refreshTempPatternOnActiveSpace(): Promise<void> {
    if (!this.activeSpaceId) {
      return
    }

    await this.refreshTempPattern(this.activeSpaceId)
  }

  public async refreshTempPattern(spaceId: ModelEditableSpaces): Promise<void> {
    await this.tempPatternController.refreshTempPattern(
      await this.cloneSpace(spaceId),
      this.getSpaceZoomRatio(spaceId)
    )
  }

  public async refreshSceneDecoration() {
    await this.sceneDecorationController.refresh()
  }

  public getActiveVirtualDielineSpace(): VirtualDielineSpace | undefined {
    if (!this.activeSpaceId) {
      return
    }

    return this.getVirtualDielineSpace(this.activeSpaceId)
  }

  public getVirtualDielineSpace(spaceId): VirtualDielineSpace {
    const space = this.virtualDielineEditor.virtualDieline
      .getObjects()
      .find((space) => space.id === spaceId)

    if (!space) {
      throw new Error(`Can't find space with provided id: ${spaceId}`)
    }

    return space as VirtualDielineSpace
  }

  public getSpaceRotation(spaceId: ModelEditableSpaces): number {
    return this.getVirtualDielineSpace(spaceId).rotation || 0
  }

  public getSpaceBoundingRectWithRotation(spaceId: string) {
    return this.translationCalculator.calculateSpaceBoundingRectWithRotation(
      this.getVirtualDielineSpace(spaceId)
    )
  }

  public getSpaceBoundingRect(spaceId: string) {
    return this.translationCalculator.calculateSpaceBoundingRect(
      this.getVirtualDielineSpace(spaceId)
    )
  }

  public getAssetPositionInSpace(
    spaceId: string,
    objectSize: Size
  ): CartesianCoords {
    return this.translationCalculator.calculateAssetCenterPositionInSpace(
      this.getVirtualDielineSpace(spaceId),
      objectSize
    )
  }

  private getPaddingScale(spaceId: ModelEditableSpaces): {
    scaleX: number
    scaleY: number
    paddingZoneRatio: number
  } {
    const artworkSpaceSize = this.productRenderPilot.getSpaceDimensions(
      this.editContext,
      spaceId
    )
    const paddingZoneSizeMm =
      this.productRenderPilot.uiConfig.editZone.paddingZoneSizeMm || 0

    const scaleX =
      (artworkSpaceSize.widthCm * 10 - 2 * paddingZoneSizeMm) /
      (artworkSpaceSize.widthCm * 10)
    const scaleY =
      (artworkSpaceSize.heightCm * 10 - 2 * paddingZoneSizeMm) /
      (artworkSpaceSize.heightCm * 10)

    const paddingZoneRatio = paddingZoneSizeMm / (artworkSpaceSize.widthCm * 10)

    return { scaleX, scaleY, paddingZoneRatio }
  }

  public getPaddingZoneSize(spaceId) {
    const currentSideObjectCalc = this.getSpaceBoundingRect(spaceId)

    const paddingZoneSizeRatio = this.getPaddingScale(spaceId).paddingZoneRatio

    const paddingZoneSize =
      paddingZoneSizeRatio *
      Math.max(currentSideObjectCalc.width, currentSideObjectCalc.height)

    return paddingZoneSize
  }

  public async cloneSpaceToClipPath(
    spaceId,
    withRotation = true,
    withPadding = true
  ): Promise<PackhelpObject> {
    const currentSideObject = this.getVirtualDielineSpace(spaceId)

    return new Promise((resolve) => {
      currentSideObject.clone(
        (clonedSideObject) => {
          const currentSideObjectCalc = this.getSpaceBoundingRect(spaceId)

          let paddingZoneSize = 0
          let scaleX = 1
          let scaleY = 1

          if (withPadding) {
            paddingZoneSize = this.getPaddingZoneSize(spaceId)
            const paddingScale = this.getPaddingScale(spaceId)
            scaleX = paddingScale.scaleX
            scaleY = paddingScale.scaleY
          }

          clonedSideObject.set({
            fill: "red",
            evented: false,
            strokeWidth: 0,
            opacity: 1,
            selectable: false,
            visible: true,
            left: currentSideObjectCalc.left + paddingZoneSize,
            top: currentSideObjectCalc.top + paddingZoneSize,
            width: currentSideObjectCalc.width,
            height: currentSideObjectCalc.height,
            scaleX: scaleX,
            scaleY: scaleY,
            hasPadding: withPadding,
          })

          if (withRotation) {
            this.translateObject(clonedSideObject, this.currentRotation)
          }
          return resolve(clonedSideObject)
        },
        ["id", "rotation"]
      )
    })
  }

  public resetRotation(): void {
    if (this.currentRotation === AvailableRotations.none) {
      return
    }

    this.translateObjects(this.currentRotation * -1)
    this.currentRotation = AvailableRotations.none
  }

  private resetPanningToDefault() {
    this.virtualDielineEditor.fabricCanvas.setZoom(1)
    this.virtualDielineEditor.fabricCanvas.absolutePan(new fabric.Point(0, 0))
  }

  private setActiveSpace(spaceId: ModelEditableSpaces | null): void {
    this.activeSpaceId = spaceId
  }

  private rotateDielineSpace(spaceId: string): void {
    this.resetRotation()

    const object = this.getVirtualDielineSpace(spaceId)
    const rotation = Number(object.rotation)

    if (rotation) {
      this.currentRotation = rotation
      this.translateObjects(rotation)
    }
  }

  private rotateDieline(): void {
    this.resetRotation()

    const dielineRotation = this.productRenderPilot.getDielineRotation(
      this.editContext
    )

    if (dielineRotation) {
      this.currentRotation = dielineRotation
      this.translateObjects(this.currentRotation)
    }
  }

  public getSpaceZoomRatio(spaceId: string): number {
    return this.translationCalculator.calculateSpaceToDielineZoomRatio(
      this.getVirtualDielineSpace(spaceId),
      this.virtualDielineEditor.getSceneDimensions(),
      this.isZoomActive
    )
  }

  private zoomToSpace(): void {
    if (!this.activeSpaceId) {
      return
    }

    this.updateEditZoneSize(this.activeSpaceId)
    this.resetPanningToDefault()

    const { fabricCanvas } = this.virtualDielineEditor
    const sceneDimensions = this.virtualDielineEditor.getSceneDimensions()
    const spaceCenter = this.translationCalculator.calculateSpaceCenterOnScene(
      this.getVirtualDielineSpace(this.activeSpaceId),
      sceneDimensions
    )

    fabricCanvas.absolutePan(spaceCenter)
    fabricCanvas.zoomToPoint(
      new fabric.Point(sceneDimensions.centerX, sceneDimensions.centerY),
      this.getSpaceZoomRatio(this.activeSpaceId)
    )
  }

  public async applyTempBackground(spaceId: string): Promise<void> {
    const space = this.getVirtualDielineSpace(spaceId)
    if (typeof space.fill !== "string") {
      throw new Error("Space is not compatible")
    }
    this.tempBackgroundController.removeFromCanvas()
    const tempBackground = await this.tempBackgroundController.createBackground(
      space,
      await this.cloneSpace(spaceId),
      {
        shadow: this.productRenderPilot.uiConfig.editZone.shadow,
        texture: this.virtualDielineEditor.texture,
      }
    )
    this.virtualDielineEditor.addOnCanvas(tempBackground, false)
    this.translateObject(tempBackground, this.currentRotation)
    this.updateEditZoneSize(this.activeSpaceId)
  }

  public removeTempBackground() {
    this.tempBackgroundController.removeFromCanvas()
  }

  public getTempBackground() {
    return this.tempBackgroundController.getBackground()
  }

  public getTempDieline(): PackhelpImage | undefined {
    return this.virtualDielineEditor.getCanvasObjectById<PackhelpImage>(
      TempLayers.TEMP_DIELINE
    )
  }

  public async applyTempBackgroundImage(
    spaceId: ModelEditableSpaces
  ): Promise<void> {
    const globalBackgroundImage =
      this.virtualDielineEditor.backgroundsModule.getGlobalBackgroundImage()
    const isPrintActive = this.productRenderPilot.isPrintActiveFor(
      this.editContext
    )

    const hasPadding =
      !!this.virtualDielineEditor.productRenderPilot.uiConfig.editZone
        .paddingZoneSizeMm

    if (!globalBackgroundImage || !isPrintActive) {
      this.tempBackgroundImageController.removeTempBackgroundImage()

      return
    }

    await this.tempBackgroundImageController.applyTempBackgroundImage(
      await this.cloneSpace(spaceId),
      globalBackgroundImage
    )

    const spaceClippingController = new SpaceClippingController(
      this,
      globalBackgroundImage
    )
    const isVisible =
      !spaceClippingController.isClippingActive(spaceId) || hasPadding
    this.setTempBackgroundImageVisibility(isVisible)
  }

  public setTempBackgroundImageVisibility(isVisible: boolean) {
    this.tempBackgroundImageController.setTempBackgroundImageVisibility(
      isVisible
    )
  }

  public getTempBackgroundImage(): PackhelpObject | undefined {
    return this.tempBackgroundImageController.getTempBackgroundImage()
  }

  public async applyTempPattern(spaceId: ModelEditableSpaces): Promise<void> {
    const tempPattern = await this.tempPatternController.applyTempPattern(
      await this.cloneSpace(spaceId),
      this.getSpaceZoomRatio(spaceId)
    )

    this.translateObject(tempPattern, this.currentRotation)
  }

  public toggleVisibleTempPattern(spaceId) {
    this.tempPatternController.toggleVisibleTempPattern(spaceId)
  }

  public getTempPattern() {
    return this.tempPatternController.getTempPattern()
  }

  private isGlobalPatternApplied(): boolean {
    return !!this.virtualDielineEditor.backgroundsModule.getGlobalPattern()
  }

  public getEditZoneDimensions(
    spaceId: string
  ): { width: number; height: number } | null {
    const editZoneBackground = this.getTempBackground()

    if (
      editZoneBackground === undefined ||
      editZoneBackground.height === undefined ||
      editZoneBackground.width === undefined
    ) {
      return null
    }

    const spaceZoom = this.getSpaceZoomRatio(spaceId)

    const editZoneHeight = editZoneBackground.getScaledHeight() * spaceZoom
    const editZoneWidth = editZoneBackground.getScaledWidth() * spaceZoom

    return { width: editZoneWidth, height: editZoneHeight }
  }

  private async cloneSpace(spaceId): Promise<PackhelpObject> {
    const currentSpace = this.getVirtualDielineSpace(spaceId)
    return new Promise((resolve) => {
      currentSpace.clone(
        (clonedSpace) => {
          const currentSpaceCoords = this.getSpaceBoundingRect(spaceId)
          clonedSpace.set({
            evented: false,
            strokeWidth: 0,
            opacity: 0,
            selectable: false,
            visible: true,
            left: currentSpaceCoords.left,
            top: currentSpaceCoords.top,
            width: currentSpaceCoords.width,
            height: currentSpaceCoords.height,
            id: `temp_background_${spaceId}`,
            originSpaceArea: spaceId,
          })
          resolve(clonedSpace)
        },
        ["id", "rotation", "originSpaceArea"]
      )
    })
  }

  private translateObjects(rotation: number): void {
    const canvasClipping = this.virtualDielineEditor.fabricCanvas.clipPath

    if (canvasClipping) {
      this.translateObject(canvasClipping, rotation)
    }

    this.virtualDielineEditor.fabricCanvas.getObjects().forEach((obj) => {
      if (obj.id === BackgroundsLayers.BACKGROUND_TEXTURE) {
        return
      }

      this.translateObject(obj, rotation)

      // For editable assets, we need to rotate asset clipping group too
      if (!isEditableObject(obj)) {
        return
      }

      if (isAssetGroup(obj)) {
        for (const groupObject of obj.getObjects()) {
          if (groupObject.clipPath) {
            this.translateClipPath(groupObject.clipPath, rotation)
          }
        }
      } else if (obj.clipPath) {
        this.translateClipPath(obj.clipPath, rotation)
      }
    })
  }

  private translateObject(obj: fabric.Object, rotation: number): void {
    RotationHelper.rotateObjectAroundCanvasCenter(obj, rotation)
    obj.setCoords()
  }

  private translateClipPath(clipPath: fabric.Object, rotation: number): void {
    if (isGroup(clipPath)) {
      return this.translateClipPathGroup(clipPath, rotation)
    }

    return this.translateObject(clipPath, rotation)
  }

  private translateClipPathGroup(group: fabric.Group, rotation: number): void {
    for (const groupObject of group.getObjects()) {
      const positionMultiplier =
        RotationHelper.getPositionMultiplierForSpaceRotation(rotation)

      let left = groupObject.left!
      let top = groupObject.top!

      if (
        [
          AvailableRotations.verticalLeft,
          AvailableRotations.verticalRight,
        ].includes(rotation)
      ) {
        left = groupObject.top!
        top = groupObject.left!
      }

      groupObject.set({
        left: positionMultiplier.left * left,
        top: positionMultiplier.top * top,
        angle: groupObject.angle! + positionMultiplier.rotation * rotation,
      })

      if (groupObject.clipPath) {
        this.translateClipPath(groupObject.clipPath, rotation)
      }
    }
  }

  public getSpaceBoundries(spaceId: ModelEditableSpaces) {
    return this.translationCalculator.calculateSpaceBoundingRectWithRotation(
      this.getVirtualDielineSpace(spaceId)
    )
  }

  @action
  private setIsSpaceLoading(isSpaceLoading: boolean) {
    this.isSpaceLoading = isSpaceLoading
  }
}
