var _a, _b;
import CARD_CONSTS from "../consts/cardConsts";
var CARD_PRODUCT_VARIANTS = (_a = {},
    _a[CARD_CONSTS.PRINTED_CARD] = {
        sizes: ["A6", "A7", "DL"],
        prefix: "",
        addonsInVariants: [],
        productCategory: CARD_CONSTS.PRINTED_CARD,
        variants: (_b = {},
            _b[CARD_CONSTS.PRINTED_CARD_VARIANT] = {
                materials: [
                    CARD_CONSTS.PRINT_MATERIAL_WHITE_MATT,
                    CARD_CONSTS.PRINT_MATERIAL_KRAFT,
                ],
                colorModes: [
                    CARD_CONSTS.PRINT_MODE_INDIGO_PRINT_ONE_SIDE,
                    CARD_CONSTS.PRINT_MODE_INDIGO_PRINT_TWO_SIDES,
                ],
                finishes: [CARD_CONSTS.PRINT_FINISH_NONE],
            },
            _b),
    },
    _a);
export default CARD_PRODUCT_VARIANTS;
