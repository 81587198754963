var _a, _b, _c, _d, _e, _f, _g;
import TAPE_CONSTS from "../consts/tapeConsts";
var DEFAULT = "default";
var BAG_PRODUCTS_WEIGHTS = (_a = {},
    _a[TAPE_CONSTS.TAPE_MODEL_PACKING_TAPE] = (_b = {},
        _b[TAPE_CONSTS.TAPE_VARIANT_PACKING_TAPE] = (_c = {},
            _c[DEFAULT] = {
                X48: 0.15,
            },
            _c),
        _b),
    _a[TAPE_CONSTS.TAPE_MODEL_KRAFT_TAPE] = (_d = {},
        _d[TAPE_CONSTS.TAPE_VARIANT_KRAFT_TAPE] = (_e = {},
            _e[DEFAULT] = {
                X50: 0.25,
            },
            _e),
        _d),
    _a[TAPE_CONSTS.TAPE_MODEL_PRE_PRINTED_KRAFT_TAPE] = (_f = {},
        _f[TAPE_CONSTS.PRE_PRINTED_KRAFT_TAPE_VARIANT] = (_g = {},
            _g[DEFAULT] = {
                X50: 0.25,
            },
            _g),
        _f),
    _a);
export default BAG_PRODUCTS_WEIGHTS;
