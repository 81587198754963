import React, { Fragment } from "react"
import { observer } from "mobx-react-lite"
import { TType } from "dsl/src/atoms/Typography/Typography"
import { Shape } from "../../../../libs/value-objects/shape"
import { TM } from "../../../TypographyI18n"
import { I18N } from "../../../i18n"
import { CardTool } from "../../atoms/CardTool/CardTool"
import { EcoFormBlock } from "../EcoFormBlock/EcoFormBlock"
import { EditorToolList } from "./EditorToolList"
import { Thumb88 } from "../../atoms/Thumb88/Thumb88"
import { ReactComponent as IconLeafs } from "../../../assets/svg/leafs.svg"
import { useCurrency } from "../../../hooks/useCurrency"
import { useRootStore } from "../../../hooks/useRootStore"
import Skeleton from "react-loading-skeleton"
import _times from "lodash/times"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import styles from "./EditorTools.module.scss"

const EditorToolEcoTrees = observer(
  ({ onAddEcoShape }: { onAddEcoShape: (shape: Shape) => void }) => {
    const [containerSet] = useContainerSet((c) => [
      c.ecommerce,
      c.envUtil,
      c.assets,
      c.analytics,
    ])

    if (
      !containerSet ||
      !containerSet.ecommerce.available ||
      !containerSet.envUtil.appConfig.api.ecommerce.features.ecommerce.trees
    ) {
      return null
    }

    const { plantingTreesStore } = containerSet.ecommerce
    const isSelected = plantingTreesStore.isSelected
    const treesAmount = plantingTreesStore.amount
    const treesPrice = plantingTreesStore.grossPriceIfApplicable
    const { ecoShapesStore } = containerSet.assets
    const { actionTracker } = containerSet.analytics

    const togglePlantingTrees = () => {
      actionTracker?.ecoToolbarSectionClicked()
      plantingTreesStore.setIsSelected(!isSelected)
    }

    const onAddEcoBadgeClick = async (badge: Shape) => {
      if (!isSelected) {
        plantingTreesStore.setIsSelected(true)
      }

      await onAddEcoShape(badge)
    }

    return (
      <>
        <header className={styles.eco_header}>
          <IconLeafs />
          <TM id={I18N.editorTools.eco.plantTrees} type={TType.Header17_500} />
        </header>
        <EcoFormBlock
          onChange={togglePlantingTrees}
          onAdd={onAddEcoBadgeClick}
          isChecked={isSelected}
          treesAmount={treesAmount}
          treesPrice={treesPrice}
          currency={useCurrency()}
          badge={ecoShapesStore.plantingTreesBadges[0]}
        />
      </>
    )
  }
)

const EditorToolEcoList = observer(
  ({ onAddEcoShape }: { onAddEcoShape: (shape: Shape) => void }) => {
    const { ecoShapesStore } = useRootStore()
    const ecoShapes = ecoShapesStore.ecoShapes

    if (ecoShapesStore.isLoadingEcoShapes) {
      return (
        <EditorToolList size="small">
          {_times(9, () => (
            <Skeleton width={"100%"} height={88} />
          ))}
        </EditorToolList>
      )
    }

    return (
      <div className={styles.wrapper}>
        {Object.keys(ecoShapes).map((type) => {
          const shapesInType = ecoShapes[type]

          return (
            <Fragment key={type}>
              <TM id={`editor-tools.eco.${type}`} type={TType.Header17_500} />
              <EditorToolList size="small">
                {shapesInType.map((shape, index) => {
                  return (
                    <Thumb88
                      key={index}
                      onSelect={() => {
                        onAddEcoShape(shape)
                      }}
                      imageURL={shape.src}
                      e2eTarget="shape"
                      e2eTargetName={`eco-${type}-${shape.id}`}
                      imgLoading="lazy"
                    />
                  )
                })}
              </EditorToolList>
            </Fragment>
          )
        })}
      </div>
    )
  }
)

const EditorToolEco = observer(() => {
  const { shapesController } = useRootStore()

  const [containerSet] = useContainerSet((c) => [c.designAndProductDriver])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver } = containerSet
  const { productDriver, contextController } = designAndProductDriver

  const { productRenderPilot } = productDriver.state
  const isDbyMode = productRenderPilot.isDbyMode()

  const addShapeOnDieline = async (shape: Shape) => {
    const space = await contextController.forceSpaceView()
    await shapesController.addEcoShape(shape, space)
  }

  return (
    <CardTool sizeXs cardName="eco">
      {productRenderPilot.uiConfig.features.plantingTrees && (
        <EditorToolEcoTrees onAddEcoShape={addShapeOnDieline} />
      )}
      {!isDbyMode && <EditorToolEcoList onAddEcoShape={addShapeOnDieline} />}
    </CardTool>
  )
})

export { EditorToolEco }
