import {
  AvailableColourModes,
  ColoursPreset,
} from "../../../../../libs/products-render-config/types"
import Colour from "../../../../../libs/value-objects/colour"
import {
  isAssetGroup,
  isAssetImage,
  isAssetShape,
  isAssetSvgImage,
  isAssetText,
  isGroup,
  is2dInterfaceObject,
  isPattern,
} from "../../../../../modules/ph-api/asset-types"
import {
  PackhelpEditableGroup,
  PackhelpEditableImage,
  PackhelpEditableShape,
  PackhelpEditableSvgImage,
  PackhelpEditableText,
  PackhelpObject,
} from "../../object-extensions/packhelp-objects"
import { PantoneColorsDatabase } from "../../../../../modules/colors/pantone-colors-database"
import {
  CanvasEvent,
  CanvasObjectEvent,
} from "../../../../../stores/_events/domain-events"
import _compact from "lodash/compact"
import VirtualDielineEditor from "../../virtual-dieline-editor"
import { eventTree } from "../../../../../stores/editor-events"
import { ImageFilterApplier } from "../filters-module/image-filter-applier"
import FiltersModule from "../filters-module"

export const DEFAULT_COLOR = new Colour("#000000")
export const DEFAULT_MONO_PANTONE_COLOR = new Colour(
  "#222223",
  "Neutral Black C"
)

export class AssetColorController {
  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public getDefaultColor(): Colour {
    if (
      this.mode === AvailableColourModes.MONOCHROME &&
      this.preset.colourMonochrome
    ) {
      return new Colour(this.preset.colourMonochrome)
    }

    if (this.mode === AvailableColourModes.MONO_PANTONE) {
      return this.preset.colorPantoneDefault || DEFAULT_MONO_PANTONE_COLOR
    }

    return DEFAULT_COLOR
  }

  public getColorForcedByColorMode(): Colour | undefined {
    if (
      this.mode === AvailableColourModes.MONOCHROME &&
      this.preset.colourMonochrome
    ) {
      return new Colour(this.preset.colourMonochrome)
    }

    if (this.mode === AvailableColourModes.MONO_PANTONE) {
      return this.getDesignMonoPantoneColor()
    }
  }

  public getObjectColor(object: PackhelpObject): Colour {
    if (isAssetGroup(object) && object.groupController) {
      const groupObject = object.groupController.getObjects()[0]

      return this.getObjectColor(groupObject)
    }

    if (
      isAssetText(object) ||
      isAssetShape(object) ||
      isAssetSvgImage(object)
    ) {
      return new Colour(object.fill as string, object.fillPantoneName)
    }

    if (isAssetImage(object)) {
      const filter = object.filters.find((filter) => filter["mode"] === "tint")

      if (filter) {
        return new Colour(filter.color, object.fillPantoneName)
      }
    }

    if (isPattern(object)) {
      const colorDef =
        object.patternSourceConfig?.config.coloursMap.phpatterngroup1?.colour

      if (colorDef) {
        return new Colour(colorDef.hex, colorDef.pantoneName)
      }
    }

    return this.getDefaultColor()
  }

  public resetDielineColorableObjectsColorToDefaultIfNeeded() {
    this.resetColorToDefaultIfNeeded(this.dielineColorableObjects)
  }

  public recolorDielineColorableObjectsIfNeeded(color: Colour) {
    this.recolorObjectsIfNeeded(color, this.dielineColorableObjects)
  }

  public resetColorToDefaultIfNeeded(objects: PackhelpObject[]) {
    const currentColor = this.getColorForcedByColorMode()

    if (!currentColor) {
      for (const object of objects) {
        if (isAssetImage(object)) {
          this.getFiltersApplier(object).remove()
        }
      }

      return
    }

    if (this.mode === AvailableColourModes.MONOCHROME) {
      const defaultColor = this.getDefaultColor()

      for (const object of objects) {
        this.recolorObject(defaultColor, object)
      }
    } else if (this.mode === AvailableColourModes.MONO_PANTONE) {
      const pantonePreset =
        this.vdEditor.productRenderPilot.getPantoneColorsPreset()
      const pantoneDb = new PantoneColorsDatabase()

      const isCurrentColorAvailable = !!pantoneDb.getByColour(
        currentColor,
        pantonePreset
      )

      if (isCurrentColorAvailable) {
        return
      }

      const defaultColor = this.getDefaultColor()

      for (const object of objects) {
        this.recolorObject(defaultColor, object)
      }

      this.vdEditor.ee.emit(eventTree.patterns.refreshColor)
    }
  }

  public recolorObjectsIfNeeded(color: Colour, objects: PackhelpObject[]) {
    if (this.mode === AvailableColourModes.MONO_PANTONE) {
      for (const object of objects) {
        this.recolorObject(color, object)
      }

      this.vdEditor.ee.emit(eventTree.patterns.refreshColor)
    }
  }

  public recolorObject(color: Colour, object: PackhelpObject) {
    if (isAssetText(object)) {
      this.recolorText(color, object)
    }

    if (isAssetShape(object)) {
      this.recolorShape(color, object)
    }

    if (isAssetSvgImage(object)) {
      this.recolorSvgImage(color, object)
    }

    if (isAssetImage(object)) {
      this.recolorImage(color, object)
    }

    if (isAssetGroup(object)) {
      this.recolorGroup(color, object)
    }

    object.fire(CanvasObjectEvent.changed)
    this.vdEditor.fabricCanvas.fire(CanvasEvent.objectModified, {
      target: object,
    })
  }

  private recolorText(color: Colour, object: PackhelpEditableText) {
    this.setFill(color, object)

    if (object.assetTextMeta) {
      object.set({
        assetTextMeta: {
          ...object.assetTextMeta,
          colour: color.toSource(),
        },
      })
    }
  }

  private recolorShape(color: Colour, object: PackhelpEditableShape) {
    if (object.assetObjectMeta.colourConfig.colourParam === "none") {
      return
    }

    this.setFill(color, object)

    // SVG Object with nested elements
    if (isGroup(object)) {
      for (const groupObject of object.getObjects()) {
        this.setFill(color, groupObject)
      }
    }

    object.set({
      assetObjectMeta: {
        ...object.assetObjectMeta,
        colourConfig: {
          ...object.assetObjectMeta.colourConfig,
          colour: color.toSource(),
        },
      },
    })
  }

  private recolorSvgImage(color: Colour, object: PackhelpEditableSvgImage) {
    this.setFill(color, object)
    this.setStroke(color, object)

    // SVG Object with nested elements
    if (isGroup(object)) {
      for (const groupObject of object.getObjects()) {
        this.setFill(color, groupObject)
        this.setStroke(color, groupObject)
      }
    }
  }

  private recolorImage(color: Colour, object: PackhelpEditableImage) {
    const isColoringAvailable = [
      AvailableColourModes.MONOCHROME,
      AvailableColourModes.MONO_PANTONE,
    ].includes(this.mode)

    if (!isColoringAvailable) {
      return
    }

    this.getFiltersApplier(object).apply()

    FiltersModule.setTintFilter(object, color)
    object.set({ fillPantoneName: color.getPantoneName() })
  }

  private recolorGroup(color: Colour, object: PackhelpEditableGroup) {
    if (!object.groupController) {
      return
    }

    for (const groupObject of object.groupController.getObjects()) {
      this.recolorObject(color, groupObject)
    }
  }

  private setFill(color: Colour, object: PackhelpObject) {
    if (object.fill && object.fill !== "transparent") {
      object.set({
        fill: color.getHex(),
        fillPantoneName: color.getPantoneName(),
      })
    }
  }

  private setStroke(color: Colour, object: PackhelpObject) {
    if (object.stroke && object.stroke !== "transparent") {
      object.set({
        stroke: color.getHex(),
      })
    }
  }

  private getDesignMonoPantoneColor(): Colour {
    const editableObject = this.vdEditor.assetsModule
      .getEditableObjects()
      .find((object) => !object.maskParentId && !is2dInterfaceObject(object))

    if (editableObject) {
      return this.getObjectColor(editableObject)
    }

    const backgroundImage =
      this.vdEditor.backgroundsModule.getGlobalBackgroundImage()

    if (backgroundImage) {
      return this.getObjectColor(backgroundImage)
    }

    const pattern = this.vdEditor.backgroundsModule.getGlobalPattern()

    if (pattern) {
      return this.getObjectColor(pattern)
    }

    return this.getDefaultColor()
  }

  private get dielineColorableObjects(): PackhelpObject[] {
    const editableObjects = this.vdEditor.assetsModule.getEditableObjects()
    const tempBackgroundImage =
      this.vdEditor.dielineNavigator.getTempBackgroundImage()
    const backgroundImage =
      this.vdEditor.backgroundsModule.getGlobalBackgroundImage()

    return _compact([...editableObjects, tempBackgroundImage, backgroundImage])
  }

  private get preset(): ColoursPreset {
    return this.vdEditor.productRenderPilot.getColoursPreset()
  }

  private get mode(): AvailableColourModes {
    return this.preset.mode
  }

  private getFiltersApplier(image: PackhelpEditableImage): ImageFilterApplier {
    return new ImageFilterApplier(this.vdEditor, image)
  }
}
