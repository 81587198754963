var _a;
import CARD_CONSTS from "../consts/cardConsts";
var CARD_PRODUCT_DIMENSIONS = (_a = {},
    _a[CARD_CONSTS.PRINTED_CARD] = {
        A6: {
            outside: {
                width: 148,
                height: 105,
            },
        },
        A7: {
            outside: {
                width: 105,
                height: 74,
            },
        },
        DL: {
            outside: {
                width: 210,
                height: 99,
            },
        },
    },
    _a);
export default CARD_PRODUCT_DIMENSIONS;
