var _a, _b;
import ENVELOPE_CONSTS from "../consts/envelopeConsts";
var ENVELOPE_PRODUCT_VARIANTS = (_a = {},
    _a[ENVELOPE_CONSTS.ENVELOPE] = {
        sizes: [4, 5],
        productCategory: ENVELOPE_CONSTS.ENVELOPE,
        addonsInVariants: [],
        prefix: "C",
        variants: (_b = {},
            _b[ENVELOPE_CONSTS.ENVELOPE_VARIANT_STANDARD] = {
                materials: [
                    ENVELOPE_CONSTS.PRINT_MATERIAL_CARDBOARD_CARDSTOCK,
                    ENVELOPE_CONSTS.PRINT_MATERIAL_KRAFT,
                ],
                colorModes: [ENVELOPE_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR],
                finishes: [ENVELOPE_CONSTS.PRINT_FINISH_NONE],
            },
            _b),
    },
    _a);
export default ENVELOPE_PRODUCT_VARIANTS;
