import React from "react"

import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { ButtonAsLink } from "dsl/src/atoms/Button/Button"
import LinkAsButton from "dsl/src/atoms/Link/Link"
import { VasWarningBanner } from "shared-libs/src/js/shared-components/vas-warning-banner/VasWarningBanner"

import { Modal, InnerSize } from "../Modal"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"

import styles from "./about-fsc-modal.module.scss"

export type AboutFscModalProps = {
  learnMoreUrl?: string
  onClose: () => void
}

export const AboutFscModal = ({
  learnMoreUrl,
  onClose,
}: AboutFscModalProps) => {
  const t = useTranslate()

  return (
    <Modal onClose={onClose} maxWidth={InnerSize.small}>
      <div
        className={styles.wrapper}
        e2e-target="modal"
        e2e-target-name="about-fsc"
      >
        <div className={styles.header}>
          <Typography type={TType.Header32_400}>
            {t(I18N.modals.aboutFsc.title)}
          </Typography>
        </div>
        <div className={styles.body}>
          <div className={styles["line-wrapper"]}>
            {t(I18N.modals.aboutFsc.description)}
          </div>
          <div className={styles["line-wrapper"]}>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/sxxGLwVOMiM"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className={styles["line-wrapper"]}>
            <VasWarningBanner
              bannerTranslationKey={
                I18N.modals.aboutFsc.priceDependsOnProductPrice
              }
            />
          </div>
        </div>

        <div className={styles.footer}>
          <LinkAsButton onClick={onClose} e2eTargetName="cancel">
            {t(I18N.generic.cancel)}
          </LinkAsButton>
          {learnMoreUrl && (
            <ButtonAsLink href={learnMoreUrl} target="_blank">
              {t(I18N.modals.aboutFsc.learnMore)}
            </ButtonAsLink>
          )}
        </div>
      </div>
    </Modal>
  )
}
