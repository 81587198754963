import axios, { AxiosInstance } from "axios"

import { DirectusAssetApi } from "./endpoints/directus-asset-api"
import { DirectusTemplateApi } from "./endpoints/directus-template-api"
import { DirectusEcoShapeApi } from "./endpoints/directus-eco-shape-api"
import { DirectusShapeApi } from "./endpoints/directus-shape-api"
import { DirectusPatternApi } from "./endpoints/directus-pattern-api"
import { DirectusCertificateShapeApi } from "./endpoints/directus-certificate-shape-api"
import { AssetsApiEnvConfig } from "../../app-config/types"

export class DirectusApi {
  private readonly ax: AxiosInstance
  private readonly apiUrl: string

  public readonly asset: DirectusAssetApi
  public readonly template: DirectusTemplateApi
  public readonly ecoShape: DirectusEcoShapeApi
  public readonly shape: DirectusShapeApi
  public readonly pattern: DirectusPatternApi
  public readonly certificateShape: DirectusCertificateShapeApi

  constructor(apiConfig: AssetsApiEnvConfig, token: string | null) {
    this.apiUrl = new URL("editor-assets", apiConfig.baseUrl).toString()
    this.ax = this.getAxiosInstance(token)

    this.asset = new DirectusAssetApi(this.ax)
    this.template = new DirectusTemplateApi(this.ax)
    this.ecoShape = new DirectusEcoShapeApi(this.ax)
    this.shape = new DirectusShapeApi(this.ax)
    this.pattern = new DirectusPatternApi(this.ax)
    this.certificateShape = new DirectusCertificateShapeApi(this.ax)
  }

  private getAxiosInstance(token?: string | null): AxiosInstance {
    const params = {
      baseURL: this.apiUrl,
    }

    if (token) {
      params["headers"] = {
        Authorization: `Bearer ${token}`,
      }
    }

    return axios.create(params)
  }
}
