import React from "react"

import { BaseInput, BaseInputProps } from "dsl/src/atoms/BaseInput/BaseInput"

import styles from "./InputUnderline.module.scss"

interface InputUnderlineProps extends BaseInputProps {}

export const InputUnderline = (props: InputUnderlineProps) => (
  <BaseInput className={styles.input_underline} {...props} />
)
