import React from "react"
import styles from "./Upload.module.scss"

export const UploadDropZone = ({ inputProps, rootProps, children }) => {
  return (
    <div className={styles.dropzone} {...rootProps}>
      <input {...inputProps} e2e-target="upload-dropzone-input" />
      {children}
    </div>
  )
}
