import { ProductDesignStore } from "../stores/product-design-store/product-design.store"
import { Product, Variant } from "@ph/product-api"
import { AppConfig } from "../app-config/app.config"
import { EcommerceStore } from "../stores/ecommerce.store"

export class QuoteRequestFormUrlProvider {
  constructor(
    private readonly appConfig: AppConfig,
    private readonly product: Product
  ) {}

  public call({
    quantity,
    designId,
    estimationId,
  }: {
    quantity: number
    designId?: string | number
    estimationId?: number
  }): string | undefined {
    if (!this.baseUrl) {
      return
    }

    const url = new URL(this.baseUrl)

    this.appendContext(url)
    this.appendSku(url)
    this.appendQuantity(url, quantity)
    this.appendCustomSize(url)
    this.appendDesignId(url, designId)
    this.appendEstimationId(url, estimationId)

    return url.toString()
  }

  private appendSku(url: URL) {
    url.searchParams.append("sku", this.variant.sku)
  }

  private appendQuantity(url: URL, quantity: number) {
    url.searchParams.append(
      "quantity",
      (quantity * this.variant.piecesPerUnit).toString()
    )
  }

  private appendCustomSize(url: URL) {
    const { size } = this.variant

    if (!size.isCustom) {
      return
    }

    const getKey = (type: "value" | "unit", dimension: string) => {
      return `custom[size][${dimension}][${type}]`
    }

    for (const dimension of ["width", "height", "length", "diameter"]) {
      const value = size[dimension]

      if (!value) {
        continue
      }

      url.searchParams.append(getKey("value", dimension), value)
      url.searchParams.append(getKey("unit", dimension), size.units.length)
    }
  }

  private appendDesignId(url: URL, designId?: string | number) {
    if (!designId) {
      return
    }

    url.searchParams.append("design_id", designId.toString())
  }

  private appendContext(url: URL) {
    url.searchParams.append("context", "editor")
  }

  private appendEstimationId(url: URL, estimationId?: number) {
    if (!estimationId) {
      return
    }

    url.searchParams.append("estimation_id", estimationId.toString())
  }

  private get baseUrl(): string | undefined {
    return this.appConfig.locale.getUrl("quoteRequestForm")
  }

  private get variant(): Variant {
    return this.product.getDefaultVariant()
  }
}
