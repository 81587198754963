import React, { HTMLAttributes } from "react"

export const WalletIcon = (props: HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M18 10a4 4 0 000 8h6v3c0 .549-.45 1-1 1H1c-.55 0-1-.451-1-1V4c0-1.1.9-2 2-2h20c1.1 0 2 .9 2 2v6h-6zM2 6h20V4H2v2zm14 8c0-1.104.898-2 2-2h6v4h-6c-1.102 0-2-.898-2-2z"
          id="wallet__a"
        />
      </defs>
      <g fillRule="evenodd">
        <mask id="wallet__b">
          <use xlinkHref="#wallet__a" />
        </mask>
        <use fillRule="nonzero" xlinkHref="#wallet__a" />
        <g mask="url(#wallet__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}
