import React, { PropsWithChildren } from "react"

import { TagIcon } from "../TagIcon/TagIcon"
import { ReactComponent as IconPlus } from "../../../assets/svg/plus.svg"

import styles from "./OutlineAddBlock.module.scss"

interface OutlineAddBlockProps {
  onAdd(): void
  e2eTarget?: string
  e2eTargetName?: string
}

export const OutlineAddBlock = (
  props: PropsWithChildren<OutlineAddBlockProps>
) => (
  <div
    onClick={props.onAdd}
    className={styles.outline_add_block}
    e2e-target={props.e2eTarget || "button"}
    e2e-target-name={props.e2eTargetName}
  >
    {props.children}
    <span className={styles.add}>
      <TagIcon Icon={IconPlus} />
    </span>
  </div>
)
