var _a, _b, _c;
import PAPER_TUBE_CONSTS from "../consts/paperTubeConsts";
var DEFAULT = "default";
var BAG_PRODUCTS_WEIGHTS = (_a = {},
    _a[PAPER_TUBE_CONSTS.PAPER_CAN] = (_b = {},
        _b[PAPER_TUBE_CONSTS.PAPER_CAN_PRODUCT_VARIANT_STANDARD] = (_c = {},
            _c[DEFAULT] = {
                T95: 0.043,
                T100: 0.062,
                T120: 0.051,
                T215: 0.109,
                T280: 0.112,
                T350: 0.165,
            },
            _c),
        _b),
    _a);
export default BAG_PRODUCTS_WEIGHTS;
