var _a, _b, _c;
import PAPER_MAILER_CONSTS from "../consts/paperMailerConsts";
var DEFAULT = "default";
var PAPER_MAILER_PRODUCTS_WEIGHTS = (_a = {},
    _a[PAPER_MAILER_CONSTS.PAPER_MAILER] = (_b = {},
        _b[PAPER_MAILER_CONSTS.PAPER_MAILER_VARIANT_STANDARD] = (_c = {},
            _c[DEFAULT] = {
                Y60: 0.036,
                Y80: 0.0645,
                Y90: 0.081,
            },
            _c),
        _b),
    _a);
export default PAPER_MAILER_PRODUCTS_WEIGHTS;
