var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import mailerBoxesVariants from "./mailerBoxesVariants";
import productBoxesTwoPiecesVariants from "./productBoxesTwoPiecesVariants";
import productBoxesVariants from "./productBoxesVariants";
import productWineBoxesVariants from "./productWineBoxesVariants";
import shippingBoxesVariants from "./shippingBoxesVariants";
var BOX_PRODUCT_VARIANTS = __assign(__assign(__assign(__assign(__assign({}, mailerBoxesVariants), productBoxesTwoPiecesVariants), productBoxesVariants), productWineBoxesVariants), shippingBoxesVariants);
export default BOX_PRODUCT_VARIANTS;
