import { HTMLAttributes } from "react"

import { SupportedCurrency } from "../../../../../configs/region-config/interfaces/js/region-config.types"
import { Decoration } from "dsl/src/components/OutliningPanel/OutliningPanel"

export enum ThousandSeparators {
  Space = "space",
}

export type WalletDataAttributes = {
  text_reminder?: boolean
  email_reminder?: boolean
  amount: number
  currency: SupportedCurrency
  expires_at: string
  current_step: number
  step_discount_rules?: number[]
  accounts: {
    amount: string
    currency: string
    expires_at: string
    name: string | null
    type: string
  }[]
} | null

export enum WalletExpiryReminderOptions {
  ByEmail = "wallet.remind-me.by-email",
  ByTextMessage = "wallet.remind-me.by-text",
}

export interface FormattedPriceProps {
  amount: number
  currency: SupportedCurrency
  currencySymbolClassName?: string
  thousandSeparator?: ThousandSeparators
  withHundrethsUnits?: boolean
}

export interface WalletWrapperProps {
  isUserLoggedIn?: boolean | false
}

export interface WalletTooltipProps extends HTMLAttributes<HTMLDivElement> {
  onCloseButtonClick: () => void
  walletData?: WalletDataAttributes
  totalPrice?: number
  linkSegmentTrackClick: () => void
  orders?: Array<{}> | null
  type: "value-proposition" | "wallet-balance"
}

export interface WalletTooltipFutureCalculationProps
  extends React.HTMLAttributes<HTMLDivElement> {
  walletData?: WalletDataAttributes
  totalPrice: number
  currency: SupportedCurrency
}

export interface EditorWalletTileProps {
  currency: SupportedCurrency
  totalPrice: number
}

export interface MoneyWalletCardProps {
  totalPrice?: number
  walletData: WalletDataAttributes
  isHorizontal?: boolean
}

export interface WithoutMoneyWalletCardProps {
  discount?: number
  totalPrice?: number
  isHorizontal?: boolean
}

export interface WalletPanelProps {
  amount: number
  percentageLimit: number
  currency: string
  panelDecoration?: Decoration
  iconColor?: "blue" | "black"
}

export interface WalletInfoProps {
  walletData: WalletDataAttributes
}

export interface WalletTileProps extends FormattedPriceProps {
  expiryDate: string
}

export interface WalletButtonProps
  extends React.HTMLAttributes<HTMLDivElement> {
  onClick: () => void
  isWalletOpened: boolean
  walletData?: WalletDataAttributes
  simpleWallet?: boolean
  buttonVersion: ButtonVersion
}

export type ButtonVersion = "v1" | "v2"

export interface WalletDropdownProps {
  walletData?: WalletDataAttributes
  simpleWallet?: boolean
  totalPrice?: number
  linkSegmentTrackClick?: () => void
  forceOpen?: boolean
  buttonVersion?: ButtonVersion
}

export enum WalletLevelTypes {
  LEVEL_1 = 1,
  LEVEL_2 = 2,
  LEVEL_3 = 3,
  LEVEL_4 = 4,
}

export interface WalletLevelProps {
  active: boolean
  cashback: number
  level: number
}

export interface WalletLevelsProps {
  walletData: WalletDataAttributes
}

export interface WalletPageCardProps {
  walletData: WalletDataAttributes
  isHorizontal?: boolean
}

export interface OrdersWalletTileProps {
  completed?: boolean
  onDiscoverLinkClick: () => void
  orders?: Array<{}>
}

export interface WaitingForPaymentNotificationProps {
  orders: Array<{}>
}
