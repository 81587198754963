import { action, observable, makeObservable } from "mobx"
import { TextObjectController } from "../../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/text-object-controller"
import { EffectControllable } from "./controllers/effect-controllable.interface"
import {
  EffectControllerMap,
  EffectOptions,
  EffectType,
  EffectUiConfig,
} from "./types"
import { getEffectControllers, getInitialType } from "./controllers"

export class TextEffectsController {
  @observable public options: EffectOptions = {}
  @observable public uiConfig: EffectUiConfig = {}
  @observable public type!: EffectType
  private readonly controllers: EffectControllerMap
  private currentController!: EffectControllable

  constructor(objectController: TextObjectController) {
    makeObservable(this)
    this.controllers = getEffectControllers(objectController)
    const initialType = getInitialType(objectController)
    this.setType(initialType)
  }

  @action
  public setType(type: EffectType) {
    this.type = type

    this.initController(type)
    this.initOptions()
    this.initUiConfig()
  }

  @action
  public apply(options: EffectOptions) {
    this.options = {
      ...this.options,
      ...options,
    }

    this.currentController.apply(this.options)
  }

  private initController(type: EffectType) {
    if (this.currentController) {
      this.currentController.clear()
    }

    this.currentController = this.controllers[type]
    this.currentController.init()
  }

  @action
  private initOptions() {
    this.options = this.currentController.getOptions()
  }

  @action
  private initUiConfig() {
    this.uiConfig = this.currentController.getUiConfig()
  }
}
