import { PackhelpEditableSvgImage } from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { isGroup } from "../../modules/ph-api/asset-types"

export class ObjectCleanerService {
  /**
   * We need to remove clipPaths from objects generated from SVG file to prevent performance issues.
   *
   * https://packhelp.slack.com/archives/C039VBMUKHN/p1650010357041799
   */
  public static clearSvgObject(object: PackhelpEditableSvgImage) {
    if (isGroup(object)) {
      object.getObjects().forEach((object) => {
        object.clipPath = undefined
      })
    }
  }
}
