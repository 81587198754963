import { InnerSize, Modal } from "../Modal"
import { Button } from "dsl/src/atoms/Button"
import styles from "./action-confirm-modal.module.scss"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import ModalTemplateConfirm from "../partials/ModalTemplateConfirm"
import React from "react"

export const ActionConfirmModal = ({
  onClose,
  onConfirm,
  title,
  confirmText,
  cancelText,
  children,
  Icon,
  buttonDisabled,
}: {
  onClose: () => void
  onConfirm: () => void
  title: string
  confirmText: string
  cancelText?: string
  children: React.ReactNode
  Icon: React.ElementType
  buttonDisabled?: boolean
}) => {
  return (
    <Modal onClose={onClose} maxWidth={InnerSize.small}>
      <ModalTemplateConfirm
        onClose={onClose}
        buttonComponent={
          <Button onClick={onConfirm} disabled={buttonDisabled}>
            {confirmText}
          </Button>
        }
        withCancel
        cancelLinkText={cancelText}
        buttonDisabled={buttonDisabled}
      >
        <div className={styles.content}>
          <Icon />
          <Typography type={TType.Header32_400} className={styles.headline}>
            {title}
          </Typography>
          {children}
        </div>
      </ModalTemplateConfirm>
    </Modal>
  )
}
