import React, { useContext, useLayoutEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"

import {
  Notification,
  NotificationVariant,
} from "../../../atoms/Notification/Notification"

import { useTranslate } from "../../../../hooks/useTranslate"
import { RootStore } from "../../../../../stores/root.store"
import { RootStoreContext } from "../../../../../stores/store-contexts"
import { toolbarPositionCalculator } from "./toolbarPositionCalculator"

import {
  ActiveObjectType,
  PackhelpGroupType,
} from "../../../../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { EditableObjectTypes } from "../../../../../modules/ph-api/asset-types"
import {
  EditZoneControlsColorTheme,
  UIConfig,
} from "../../../../../libs/products-render-config/types"
import { useFontSizeCalculator } from "../../../../hooks/useFontSizeCalculator"
import { I18N } from "../../../../i18n"

import styles from "./FloatingToolbar.module.scss"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { Dimensions } from "../../../atoms/dimensions/dimensions"

const i18n = I18N.components.floatingToolbar

interface FloatingToolbarProps {
  activeObjectType: ActiveObjectType
  cmSizeValues: {
    cmWidth: number
    cmHeight: number
  }
  isOverScaled: boolean
  minScaleLimitReached: boolean
  isSafeZoneTouched: boolean
  style: React.CSSProperties
  uiConfig: UIConfig
  displayUnit: string
}

const FloatingToolbar = ({
  activeObjectType,
  cmSizeValues,
  isSafeZoneTouched,
  isOverScaled,
  minScaleLimitReached,
  style,
  uiConfig,
  displayUnit,
}: FloatingToolbarProps) => {
  const t = useTranslate()
  const fontSizeCalculator = useFontSizeCalculator()

  const getMinScaleLimitInfo = () => {
    const minFontSize = fontSizeCalculator.calcEditorMin()

    switch (activeObjectType) {
      case EditableObjectTypes.assetGroup:
      case PackhelpGroupType.activeSelection:
        return t(i18n.minScaleLimitInfo.group, { minFontSize })
      case EditableObjectTypes.assetText:
        return t(i18n.minScaleLimitInfo.text, { minFontSize })
      case EditableObjectTypes.assetObject:
        return t(i18n.minScaleLimitInfo.shape)
      default:
        return null
    }
  }

  const notificationThemedVariants: Record<
    EditZoneControlsColorTheme,
    NotificationVariant
  > = {
    dark: NotificationVariant.black,
    light: NotificationVariant.white,
  }
  return (
    <div className={styles.wrapper} style={style}>
      {cmSizeValues && (
        <Notification
          halfWidth
          variant={notificationThemedVariants[uiConfig.editZone.controlsTheme]}
          e2eTargetName="size-info"
        >
          {t(i18n.sizeInfo)}{" "}
          <Dimensions
            dimensionsCm={[cmSizeValues.cmWidth, cmSizeValues.cmHeight]}
            displayUnit={displayUnit}
            roundPrecision={2}
          />
        </Notification>
      )}

      {isOverScaled && (
        <Notification
          halfWidth
          variant={NotificationVariant.error}
          e2eTargetName="image-quality-info"
        >
          {t(i18n.imageQualityInfo)} <strong>{t(i18n.imageQuality)}</strong>
        </Notification>
      )}

      {minScaleLimitReached && getMinScaleLimitInfo() && (
        <Notification
          halfWidth
          variant={NotificationVariant.warning}
          e2eTargetName="image-min-scale-reached-info"
        >
          {getMinScaleLimitInfo()}
        </Notification>
      )}

      {isSafeZoneTouched && (
        <Notification
          halfWidth
          variant={NotificationVariant.warning}
          e2eTargetName="placement-info"
        >
          {t(i18n.placementInfo)}
        </Notification>
      )}
    </div>
  )
}

export const FloatingToolbarWired = observer(() => {
  const [containerSet] = useContainerSet((c) => [c.envUtil])

  const { productDriver } = useContext<RootStore>(RootStoreContext)
  const toolbarPlaygroundRef = useRef<HTMLDivElement>(null)
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 })

  const { activeObjectDriver } = productDriver
  const activeObjectType = activeObjectDriver.activeObjectType

  const {
    isOverscaled,
    minScaleLimitReached,
    cmSizeValues,
    isSafeZoneTouched,
    boundingTop,
    boundingLeft,
    boundingHeight,
    boundingWidth,
  } = activeObjectDriver.activeObjectComputable

  useLayoutEffect(() => {
    if (
      toolbarPlaygroundRef.current === null ||
      !activeObjectDriver.isObjectActive
    ) {
      return
    }

    setToolbarPosition(
      toolbarPositionCalculator(
        { boundingTop, boundingLeft, boundingHeight },
        toolbarPlaygroundRef.current
      )
    )
  }, [
    boundingTop,
    boundingLeft,
    boundingHeight,
    boundingWidth,
    activeObjectDriver.isObjectActive,
  ])

  if (!activeObjectDriver.isObjectActive) {
    return null
  }

  const { productRenderPilot } = productDriver.state

  if (!containerSet) {
    return null
  }

  const { appConfig } = containerSet.envUtil

  return (
    <div ref={toolbarPlaygroundRef} className={styles.scene_wrapper}>
      <FloatingToolbar
        activeObjectType={activeObjectType!}
        isOverScaled={isOverscaled}
        minScaleLimitReached={minScaleLimitReached}
        isSafeZoneTouched={isSafeZoneTouched}
        cmSizeValues={cmSizeValues}
        style={{
          transform: `translate(${toolbarPosition.left}px, ${toolbarPosition.top}px)`,
        }}
        uiConfig={productRenderPilot.uiConfig}
        displayUnit={appConfig.locale.displayUnit}
      />
    </div>
  )
})
