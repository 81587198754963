import fabric from "../../../../../../libs/vendors/Fabric"
import { VirtualDielineObject } from "../../../../../../modules/ph-api/asset-types"
import _cloneDeep from "lodash/cloneDeep"
import { FontHelper } from "../helpers/font-helper"

export class JsonToFabricTransformer {
  public static async transform<T>(
    data: VirtualDielineObject | VirtualDielineObject[]
  ): Promise<T[]> {
    const clonedData = _cloneDeep(data)
    const objects = Array.isArray(clonedData) ? clonedData : [clonedData]

    if (objects.length === 0) {
      return []
    }

    await FontHelper.preloadAndFixTextObjects(objects)

    return new Promise((resolve) => {
      fabric.util.enlivenObjects(
        objects,
        (transformedObjects) => {
          resolve(transformedObjects)
        },
        ""
      )
    })
  }

  public static async transformSingleObject<T>(
    data: VirtualDielineObject
  ): Promise<T> {
    return (await this.transform<T>(data))[0]
  }
}
