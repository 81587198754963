import {
  backgroundLayerDefinitions,
  ClippingMaskConfigExport,
  EditorEvent,
  MaskConfig,
  MaskEvent,
  MaskType,
} from "../types"
import { BaseMaskController } from "./base-mask-controller"
import { MaskCalculator } from "../calculators/mask-calculator"
import {
  PackhelpEditableObject,
  PackhelpMaskObject,
  PackhelpObject,
} from "../../../../object-extensions/packhelp-objects"
import {
  CanvasEvent,
  CanvasObjectEvent,
} from "../../../../../../../stores/_events/domain-events"
import { eventTree } from "../../../../../../../stores/editor-events"
import VirtualDielineEditor from "../../../../virtual-dieline-editor"
import { BackgroundLayerController } from "./clipping-mask/background-layer-controller"
import { ModelEditableSpaces } from "../../../../../../../libs/products-render-config/types"
import { AssetsController } from "./clipping-mask/assets-controller"
import { assetParamsToIncludeInClonedObject } from "../../types"
import {
  isEditableObject,
  isInteractiveCanvas,
} from "../../../../../../../modules/ph-api/asset-types"

export class ClippingMaskController extends BaseMaskController {
  private readonly editorEvents: EditorEvent[]
  private readonly canvasEvents: MaskEvent[]

  private isEditorAndCanvasEventsAttached = false
  private initializedOnSpace = false

  private readonly backgroundLayerControllers: BackgroundLayerController[]
  private assetsController?: AssetsController

  constructor(
    maskParent: PackhelpEditableObject,
    vdEditor: VirtualDielineEditor
  ) {
    super(maskParent, vdEditor)

    this.editorEvents = this.buildEditorEventMap()
    this.canvasEvents = this.buildCanvasEventMap()
    this.backgroundLayerControllers = backgroundLayerDefinitions.map(
      (definition) => {
        return new BackgroundLayerController(
          this.virtualDielineEditor,
          this.maskParent,
          definition
        )
      }
    )
  }

  public getConfigExport(): ClippingMaskConfigExport {
    return {
      type: MaskType.clipping,
      shape: this.config.shape.toJSON(assetParamsToIncludeInClonedObject),
      size: this.config.size,
      assetClipping: this.config.assetClipping,
      minimalMargin: this.config.minimalMargin,
      isEditingDisabled: this.config.isEditingDisabled,
    }
  }

  public getType(): MaskType {
    return MaskType.clipping
  }

  public async init(config: Partial<MaskConfig> = {}) {
    this.updateConfig(config)
    this.initAssetsControllerIfNeeded()

    if (this.isTempLayerApplied()) {
      await this.initOnSpace()
    } else {
      await this.initOnGlobalEdit()
    }

    this.attachEditorAndCanvasEvents()
  }

  public async load(config: MaskConfig) {
    this.updateConfig(config)
    this.initAssetsControllerIfNeeded()

    await this.initGlobalClippingMask(config.shape)
    this.attachEditorAndCanvasEvents()
  }

  protected updateConfig(config: Partial<MaskConfig>) {
    super.updateConfig(config)

    this.maskParent.maskConfig = this.getConfigExport()
  }

  private async onGlobalLayerRecreated() {
    this.applyMaskOnGlobalLayers()

    if (this.isTempLayerApplied()) {
      await this.onTempLayerCreated(
        this.maskParent.originSpaceArea as ModelEditableSpaces
      )
    } else {
      this.rerender()
    }
  }

  private async onTempLayerCreated(spaceId: ModelEditableSpaces) {
    if (spaceId !== this.maskParent.originSpaceArea) {
      return
    }

    if (this.initializedOnSpace) {
      this.onTempLayerRemoved()
    }

    const tempClippingMask = await this.maskBuilder.buildTempClippingMask(
      this.config
    )

    this.mask = await this.maskBuilder.buildMask(this.config, MaskType.clipping)
    this.maskCalculator = new MaskCalculator(this.maskParent, this.mask)

    for (const controller of this.backgroundLayerControllers) {
      await controller.initTempClippingMask(this.mask, tempClippingMask)
    }
    await this.assetsController?.initTempObjectMask(this.mask)

    this.setMaskVisibility(this.maskParent.visible!)
    await this.applyMaskOnTempLayers()
    await this.applyMask(this.mask)

    this.recalculateMargins()
    this.setMaskScale()
    this.setMaskRotationAndPosition()
    this.attachMaskEvents()

    if (this.isMaskParentActive()) {
      this.showMaskBorder()
    }

    this.initializedOnSpace = true
  }

  private onTempLayerRemoved() {
    this.detachMaskEvents()
    this.canvas.remove(this.mask!)

    for (const controller of this.backgroundLayerControllers) {
      controller.clearTemp()
    }

    this.initializedOnSpace = false
  }

  private async initGlobalClippingMask(
    globalClippingMask: PackhelpMaskObject
  ): Promise<void> {
    for (const controller of this.backgroundLayerControllers) {
      await controller.initGlobalClippingMask(globalClippingMask)
    }

    await this.assetsController?.initClippingMask(globalClippingMask)
  }

  private async initOnGlobalEdit() {
    const globalClippingMask = await this.maskBuilder.buildGlobalClippingMask(
      this.config
    )

    await this.initGlobalClippingMask(globalClippingMask)
    this.applyMaskOnGlobalLayers()
  }

  private async initOnSpace() {
    await this.initOnGlobalEdit()
    await this.onTempLayerCreated(
      this.maskParent.originSpaceArea as ModelEditableSpaces
    )
  }

  private applyMaskOnGlobalLayers() {
    for (const controller of this.backgroundLayerControllers) {
      controller.applyGlobalClippingMask()
    }
  }

  private async applyMaskOnTempLayers() {
    for (const controller of this.backgroundLayerControllers) {
      controller.applyTempClippingMask()
    }

    await this.assetsController?.applyClippingMask()
  }

  protected setMaskPosition() {
    super.setMaskPosition()

    this.setTempClippingMaskPosition()
    this.setGlobalClippingMaskPosition()
  }

  protected setTempClippingMaskPosition() {
    for (const controller of this.backgroundLayerControllers) {
      controller.setTempClippingMaskPosition()
    }
  }

  protected setGlobalClippingMaskPosition() {
    for (const controller of this.backgroundLayerControllers) {
      controller.setGlobalClippingMaskPosition()
    }

    this.assetsController?.setClippingMasksPosition()
  }

  protected setMaskScale() {
    super.setMaskScale()

    this.setTempClippingMaskScale()
    this.setGlobalClippingMaskScale()
  }

  protected setTempClippingMaskScale() {
    for (const controller of this.backgroundLayerControllers) {
      controller.setTempClippingMaskScale()
    }
  }

  protected setGlobalClippingMaskScale() {
    for (const controller of this.backgroundLayerControllers) {
      controller.setGlobalClippingMaskScale()
    }
    this.assetsController?.setClippingMasksScale()
  }

  protected setMaskRotation() {
    super.setMaskRotation()

    for (const controller of this.backgroundLayerControllers) {
      controller.setRotation()
    }

    this.assetsController?.setClippingMasksRotation()
  }

  private setTempClippingMaskScaleAndPosition() {
    this.setTempClippingMaskScale()
    this.setTempClippingMaskPosition()
  }

  protected setGlobalClippingMaskScaleAndPosition() {
    this.setGlobalClippingMaskScale()
    this.setGlobalClippingMaskPosition()
  }

  protected clearMask() {
    super.clearMask()

    for (const controller of this.backgroundLayerControllers) {
      controller.clear()
    }

    this.assetsController?.clear()

    this.initializedOnSpace = false

    this.detachEditorAndCanvasEvents()
  }

  protected disposeIfNeeded() {
    if (!!this.assetsController) {
      return
    }

    for (const controller of this.backgroundLayerControllers) {
      if (!!controller.getGlobalLayer()) {
        return
      }
    }

    this.dispose()
  }

  protected buildMaskEventMap(): MaskEvent[] {
    return [
      ...super.buildMaskEventMap(),
      {
        name: CanvasObjectEvent.scaling,
        fn: this.setTempClippingMaskScaleAndPosition.bind(this),
      },
      {
        name: CanvasObjectEvent.scaling,
        fn: this.setGlobalClippingMaskScaleAndPosition.bind(this),
      },
      {
        name: CanvasObjectEvent.moving,
        fn: this.setTempClippingMaskPosition.bind(this),
      },
      {
        name: CanvasObjectEvent.moving,
        fn: this.setGlobalClippingMaskPosition.bind(this),
      },
    ]
  }

  private attachEditorAndCanvasEvents() {
    if (this.isEditorAndCanvasEventsAttached) {
      return
    }

    for (const event of this.editorEvents) {
      this.virtualDielineEditor.ee.on(event.name, event.fn)
    }

    for (const event of this.canvasEvents) {
      this.virtualDielineEditor.fabricCanvas.on(event.name, event.fn)
    }

    this.isEditorAndCanvasEventsAttached = true
  }

  private detachEditorAndCanvasEvents() {
    if (!this.isEditorAndCanvasEventsAttached) {
      return
    }

    for (const event of this.editorEvents) {
      this.virtualDielineEditor.ee.off(event.name, event.fn)
    }

    for (const event of this.canvasEvents) {
      this.virtualDielineEditor.fabricCanvas.off(event.name, event.fn)
    }

    this.isEditorAndCanvasEventsAttached = false
  }

  private buildEditorEventMap(): EditorEvent[] {
    return [
      {
        name: eventTree.backgroundImages.applied,
        fn: this.onGlobalLayerRecreated.bind(this),
      },
      {
        name: eventTree.patterns.applied,
        fn: this.onGlobalLayerRecreated.bind(this),
      },
      {
        name: eventTree.productDriver.backgroundLayersRefreshed,
        fn: this.onGlobalLayerRecreated.bind(this),
      },
      {
        name: eventTree.backgroundImages.removed,
        fn: this.disposeIfNeeded.bind(this),
      },
      {
        name: eventTree.patterns.removed,
        fn: this.disposeIfNeeded.bind(this),
      },
      {
        name: eventTree.productDriver.escapeEditModeEnded,
        fn: this.onTempLayerRemoved.bind(this),
      },
      {
        name: eventTree.productDriver.showSpaceStarted,
        fn: this.onTempLayerRemoved.bind(this),
      },
      {
        name: eventTree.productDriver.showSpaceEnded,
        fn: this.onTempLayerCreated.bind(this),
      },
      {
        name: eventTree.productDriver.renderEngineDeregistered,
        fn: this.dispose.bind(this),
      },
    ]
  }

  private buildCanvasEventMap(): MaskEvent[] {
    return [
      {
        name: CanvasEvent.objectAdded,
        fn: async (event: { target: PackhelpObject }) => {
          if (!this.config.assetClipping) {
            return
          }

          const object = event.target

          if (
            isEditableObject(object) &&
            object.originSpaceArea === this.maskParent.originSpaceArea &&
            object.maskType !== MaskType.clipping
          ) {
            await this.assetsController?.applyClippingMaskForSingleAsset(
              event.target
            )
            this.rerender()
          }
        },
      },
    ]
  }

  private isTempLayerApplied(): boolean {
    if (!!this.assetsController) {
      return true
    }

    for (const controller of this.backgroundLayerControllers) {
      if (!!controller.getTempLayer()) {
        return true
      }
    }

    return false
  }

  private isMaskParentActive(): boolean {
    if (!isInteractiveCanvas(this.canvas)) {
      return false
    }

    const activeObject = this.canvas.getActiveObject()

    return this.maskParent === activeObject
  }

  public setMaskVisibility(isVisible: boolean) {
    super.setMaskVisibility(isVisible)

    for (const controller of this.backgroundLayerControllers) {
      controller.setVisibility(isVisible)
    }

    this.assetsController?.setVisibility(isVisible)
  }

  public changeParent(newParent: PackhelpEditableObject) {
    super.changeParent(newParent)

    for (const controller of this.backgroundLayerControllers) {
      controller.changeParent(newParent)
    }

    this.assetsController?.changeParent(newParent)
  }

  private initAssetsControllerIfNeeded() {
    if (!this.config.assetClipping || !!this.assetsController) {
      return
    }

    this.assetsController = new AssetsController(
      this.virtualDielineEditor,
      this.maskParent
    )
  }
}
