import Cookie from "../storage/Cookie"
import RegionConfig from "../../../configs/region-config/interfaces/js/region-config"
import {
  cookieKeyWithServerInstance,
  REGION_COOKIE_NAME,
} from "./cookie.helpers"
import { Country, PackhelpRegion } from "@ph/api-studio"
import {
  countriesWithAlwaysAppliedTax,
  regionsWithAlwaysAppliedTax,
} from "../../../configs/region-config/interfaces/js/region-config.types"

export const isCountryWithAlwaysAppliedTax = (
  country: any
): country is Country => countriesWithAlwaysAppliedTax.includes(country)

export const isRegionWithAlwaysAppliedTax = (
  region: any
): region is PackhelpRegion => regionsWithAlwaysAppliedTax.includes(region)

export const isShippingToRegionFromCookiesAllowed = () => {
  const allowedShippingCountries = RegionConfig.getKeyForCurrentRegion(
    "allowedShippingCountries"
  )

  let regionFromCookie = Cookie.get(
    cookieKeyWithServerInstance(REGION_COOKIE_NAME)
  )

  if (regionFromCookie === null) {
    regionFromCookie = ""
  }

  let regionFromCookieUpperCase = regionFromCookie.toUpperCase()

  /**
   * Although iso 3166 says country code is GR,
   * in europe people are using EL abbreviation
   */
  if (regionFromCookieUpperCase === "EL") {
    regionFromCookieUpperCase = "GR"
  }

  /**
   * Localization ini introduce different region naming
   * and some of the clients were having trouble placing orders
   * due to the difference.
   * This is a hotfix to match the pre- and post- localization
   * naming and should be cleaned/unified across our platform.
   */
  if (regionFromCookieUpperCase === "UK") {
    regionFromCookieUpperCase = "GB"
  }

  return (
    regionFromCookieUpperCase == "" ||
    regionFromCookieUpperCase == "EU" ||
    regionFromCookieUpperCase == "EN" ||
    allowedShippingCountries.includes(regionFromCookieUpperCase)
  )
}

export const getShipingCountryCode = () => {
  // FIXME
  // This function should be revisited and regionswitcher should match regions from region_config.rb
  // Once regionswitcher is updated and in sync, this won't be needed
  // author: Marcin Bartoszyk

  let countryCode = ""

  if (Cookie.get(cookieKeyWithServerInstance(REGION_COOKIE_NAME))) {
    if (Cookie.get(cookieKeyWithServerInstance(REGION_COOKIE_NAME)) === "uk") {
      //maping UK to GB - not correct ISO code
      countryCode = "GB"
    } else {
      // ISO from cookie
      countryCode = Cookie.get(cookieKeyWithServerInstance(REGION_COOKIE_NAME))
    }
  } else {
    // Default eu working when none is set
    countryCode = "DE"
  }

  return countryCode.toLowerCase()
}

export const isDielineDownloadSupportedCountry = () => {
  const supportedRegions = RegionConfig.getDigitalDesignAllowedCountries()
  const currentShippingRegion = getShipingCountryCode().toUpperCase()
  return supportedRegions.includes(currentShippingRegion)
}

export const isShippingCountryComingSoon = () => {
  const currentCountry = getShipingCountryCode().toUpperCase()
  return RegionConfig.getKeyForCurrentRegion(
    "partiallySupportedCountries"
  ).includes(currentCountry)
}
