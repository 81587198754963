import React from "react"
import { observer } from "mobx-react-lite"
import { CardTool } from "../../../atoms/CardTool/CardTool"
import PatternsConfigurator from "../../PatternsConfigurator/PatternsConfiguratorWithCategories"
import styles from "./editor-tool-patterns.module.scss"

export const EditorToolPatterns = observer(() => {
  return (
    <CardTool e2eTargetName="patterns" cardName="patterns">
      <div className={styles.pattern_config_wrapper}>
        <PatternsConfigurator />
      </div>
    </CardTool>
  )
})
