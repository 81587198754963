var _a, _b;
import LABEL_CONST from "../generalConfig/consts/labelConsts";
var PRINT_MATERIAL_PAPER_MID_GLOSS = [
    {
        uid: 1,
        pantoneName: "010 White",
        color: "#FFFFFF",
        description: "box-editor.pantone-picker.color-descriptions.white",
    },
    {
        uid: 2,
        pantoneName: "Yellow 012 C",
        color: "#FFD719",
        description: "box-editor.pantone-picker.color-descriptions.yellow",
    },
    {
        uid: 3,
        pantoneName: "Black C",
        color: "#222223",
        description: "box-editor.pantone-picker.color-descriptions.black",
    },
    {
        uid: 4,
        pantoneName: "Orange 021 C",
        color: "#FF4700",
        description: "box-editor.pantone-picker.color-descriptions.orange",
    },
    {
        uid: 5,
        pantoneName: "212 C",
        color: "#DB4A82",
        description: "box-editor.pantone-picker.color-descriptions.pink",
    },
    {
        uid: 6,
        pantoneName: "Red 032 C",
        color: "#D8383C",
        description: "box-editor.pantone-picker.color-descriptions.red",
    },
    {
        uid: 7,
        pantoneName: "7738 C",
        color: "#45A53F",
        description: "box-editor.pantone-picker.color-descriptions.green",
    },
    {
        uid: 8,
        pantoneName: "Process Blue C",
        color: "#0078B0",
        description: "box-editor.pantone-picker.color-descriptions.blue",
    },
    {
        uid: 9,
        pantoneName: "Violet C",
        color: "#38276D",
        description: "box-editor.pantone-picker.color-descriptions.purple",
    },
];
var LABEL_PRODUCT_PREFERED_COLORS = (_a = {},
    _a[LABEL_CONST.PRINT_MATERIAL_PAPER_MID_GLOSS] = PRINT_MATERIAL_PAPER_MID_GLOSS,
    _a);
var ProductPreferedColors = {
    COLORS_CONFIG: {
        SHARED: {
            EDITOR_DEFAULT_COLOR: (_b = {
                    DEFAULT: {
                        color: "#ffffff",
                        pantoneName: "White",
                    }
                },
                _b[LABEL_CONST.PRINT_MATERIAL_PAPER_MID_GLOSS] = {
                    color: "#000000",
                    pantoneName: "Black C",
                },
                _b),
        },
    },
    COLORS_LISTS: LABEL_PRODUCT_PREFERED_COLORS,
};
export default ProductPreferedColors;
