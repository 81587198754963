import React from "react"
import cxBinder from "classnames/bind"
import styles from "./option-picker.module.scss"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { TagIcon } from "../TagIcon/TagIcon"
import { ReactComponent as IconChecked } from "../../../assets/svg/check.svg"

const cx = cxBinder.bind(styles)

export function OptionPicker({
  label,
  badge,
  isDisabled,
  isActive,
  onSelect,
  e2eTarget,
  e2eTargetName,
}: {
  label: string
  badge?: React.ReactNode
  isDisabled?: boolean
  isActive?: boolean
  onSelect?(): void
  e2eTarget?: string
  e2eTargetName?: string
}) {
  return (
    <button
      className={cx(styles.wrapper, {
        disabled: isDisabled,
        active: isActive,
      })}
      onClick={onSelect}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <div className={styles.label_wrapper}>
        <Typography type={TType.Header13_500} htmlElement="figcaption">
          {label}
        </Typography>
      </div>

      {badge && <div className={styles.badge_wrapper}>{badge}</div>}

      {isActive && (
        <span className={styles.checked}>
          <TagIcon Icon={IconChecked} />
        </span>
      )}
    </button>
  )
}
