import { action, makeObservable, observable, toJS } from "mobx"
import _cloneDeep from "lodash/cloneDeep"
import { Debug } from "../../services/logger"
import {
  RenderEngine,
  RenderEngineExportOptions,
} from "../../render-engine/render-engine"
import {
  RenderEngineExport,
  RendererMountPoints,
  TwoDimensionalMountPoints,
} from "../../render-engine/types"
import {
  EditContext,
  ModelContext,
  ModelEditableSpaces,
  ViewMode,
  ViewType,
} from "../../libs/products-render-config/types"
import ActiveObjectDriver from "./active-object.driver"
import { AssetsDriver } from "./assets.driver"
import ConverterDriver from "./converter.driver"
import BackgroundsDriver from "./backgrounds.driver"
import { AllEditorEventsEmitter, eventTree } from "../editor-events"
import { Text } from "../../libs/value-objects/text"
import { PredefinedText } from "../../modules/predefined-assets/text-asset"
import { PredefinedTextAssetDto } from "../../modules/predefined-assets/asset-types"
import { ProductRenderPilot } from "../../libs/products-render-config/product-render-pilot"
import { ProductDesignStore } from "../product-design-store/product-design.store"
import { ImageAsset } from "../../libs/value-objects/image-asset"
import { ProductDesignModificationListener } from "./product-design-modification.listener"
import VirtualDielineEditor from "../../render-engine/modules/vd-editor/virtual-dieline-editor"
import { ProductRenderPilotFactory } from "../../libs/products-render-config/product-render-pilot-factory"
import { ProductStore } from "./product.store"
import { DielineNavigator } from "../../render-engine/modules/vd-editor/modules/dieline-navigator/dieline-navigator"
import EventEmitter from "eventemitter3"
import { RenderEngineBuilder } from "../../render-engine/services/render-engine-builder"
import { DielineStore } from "../product-design-store/dieline.store"
import { HighlightDefinition } from "../../render-engine/modules/vd-editor/modules/space-highlight-module"
import { isInteractiveCanvas } from "../../modules/ph-api/asset-types"
import { VariantCustomization } from "@ph/product-api"

const debug = Debug("ph:editor:stores:product-design")

type ProductDriverRenderParams = RendererMountPoints & {
  isDesignPreviewMode: boolean
  isDtpPreviewMode: boolean
}

type ProductDriverState = {
  renderEngine: RenderEngine | undefined
  isRendererLoading: boolean
  isVirtualDielineLoading: boolean
  isRendererDeregistered: boolean
  isProductLoading: boolean
  isProductChanging: boolean
  is3DPreviewOn: boolean
  isLoading3DModel: boolean
  isDesignPreviewMode: boolean
  isDtpPreviewMode: boolean
  isResizing: boolean
  modelContext: ModelContext
  activeViewType: ViewType
  activeContext: EditContext
  activeSpace: ModelEditableSpaces | null
  productRenderPilot: ProductRenderPilot
  virtualDielineMountPoints: TwoDimensionalMountPoints
  threeDimensionalRendererMountPoint: HTMLDivElement | undefined
  isZoomActive: boolean
  isEditModeEventListenersAttached: boolean
}

type RenderEngineSnapshotOptions = { force?: boolean; withPreview?: boolean }

enum CURSORS {
  INITIAL = "initial",
  POINTER = "pointer",
  GRAB = "grab",
}

export class ProductDriver {
  public state: ProductDriverState
  private converterDriver: ConverterDriver | undefined
  private lastPreviewType: ViewType

  @observable
  public activeObjectDriver: ActiveObjectDriver
  @observable
  public backgroundsDriver: BackgroundsDriver
  public readonly assetsDriver: AssetsDriver

  private renderExportSnapshot: RenderEngineExport | null = null
  private tempResizeRenderExportSnapshot: RenderEngineExport | null = null
  private readonly productDesignTouchListener: ProductDesignModificationListener

  constructor(
    private readonly renderEngineBuilder: RenderEngineBuilder,
    productRenderPilot: ProductRenderPilot,
    public readonly productStore: ProductStore,
    private readonly productDesignStore: ProductDesignStore,
    private readonly dielineStore: DielineStore,
    public readonly eventEmitter: EventEmitter,
    private readonly ee: AllEditorEventsEmitter
  ) {
    makeObservable(this)
    const zoomConfig = productRenderPilot.uiConfig.editZone.zoom
    const isZoomActive = zoomConfig.available && zoomConfig.activeByDefault
    const defaultView = productRenderPilot.getDefaultView()

    this.lastPreviewType = defaultView.viewType
    this.state = observable<ProductDriverState>({
      renderEngine: undefined,
      isRendererLoading: true,
      isRendererDeregistered: true,
      isProductLoading: false,
      isProductChanging: false,
      isResizing: false,
      isVirtualDielineLoading: true,
      is3DPreviewOn: false,
      isDesignPreviewMode: false,
      isDtpPreviewMode: false,
      modelContext: ModelContext.CLOSED,
      activeViewType: defaultView.viewType,
      activeContext: defaultView.editContext,
      activeSpace:
        defaultView.viewType === ViewType.SPACE ? defaultView.spaceId : null,
      isLoading3DModel: false,
      productRenderPilot: productRenderPilot,
      virtualDielineMountPoints: {},
      threeDimensionalRendererMountPoint: undefined,
      isZoomActive: isZoomActive,
      isEditModeEventListenersAttached: false,
    })
    this.eventEmitter = eventEmitter
    globalThis.productDriver = this
    this.activeObjectDriver = new ActiveObjectDriver(this, this.ee)
    this.backgroundsDriver = new BackgroundsDriver(this)
    this.assetsDriver = new AssetsDriver(this)
    this.productDesignTouchListener = new ProductDesignModificationListener(
      this.eventEmitter,
      this.ee
    )

    this.ee.on(eventTree.pd.skuChangeStarted, this.onChangeProductSkuStarted)
    this.ee.on(eventTree.pd.skuChangeEnded, this.onChangeProductSkuEnded)
  }

  public async changeSku(
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    this.ee.emit(eventTree.pd.skuChangeStarted)

    const product = await this.productStore.setProductBySku(sku, customization)
    const productRenderPilot = await ProductRenderPilotFactory(
      product,
      ProductDesignStore.designFormatVersion,
      this.state.productRenderPilot.getEditorMode()
    )
    await this.dielineStore.loadDielineUrls(productRenderPilot)

    this.ee.emit(
      eventTree.pd.skuChangeEnded,
      toJS(this.productStore.productSku),
      productRenderPilot
    )
  }

  @action
  public async setIsZoomActive(isZoomActive: boolean): Promise<void> {
    const zoomConfig = this.state.productRenderPilot.uiConfig.editZone.zoom

    if (this.state.isZoomActive === isZoomActive || !zoomConfig.available) {
      return
    }

    this.setRendererLoading(true)
    this.state.isZoomActive = isZoomActive
    await this.converterDriver?.refresh(this.state.productRenderPilot)
    this.setRendererLoading(false)
  }

  @action
  private setIsResizing(isResizing: boolean): void {
    this.state.isResizing = isResizing
  }

  @action
  public async onDeregisterMountPoints(): Promise<void> {
    this.setIsResizing(true)

    if (!this.state.renderEngine || this.state.isRendererDeregistered) {
      return
    }

    this.setRendererDeregistered(true)
    this.state.renderEngine.clearActiveObject()
    await this.cacheDesignData()
    this.state.renderEngine.unMountRenderEngine()
    this.setRenderEngine(undefined)
    this.ee.emit(eventTree.productDriver.renderEngineDeregistered)
    this.setVirtualDielineLoading(true)
    this.setRendererLoading(true)
  }

  @action
  public async renderEngineDomMounter({
    threeDimensionalRendererMountPoint,
    virtualDielineMountPoints,
    isDesignPreviewMode = false,
    isDtpPreviewMode = false,
  }: ProductDriverRenderParams): Promise<void> {
    this.state.threeDimensionalRendererMountPoint =
      threeDimensionalRendererMountPoint
    this.state.virtualDielineMountPoints = virtualDielineMountPoints
    await this.initRenderer({
      threeDimensionalRendererMountPoint,
      virtualDielineMountPoints,
      isDesignPreviewMode,
      isDtpPreviewMode,
    })
  }

  @action
  public dbyInit(isDesignPreviewMode = false): void {
    this.setDesignPreviewMode(isDesignPreviewMode)

    this.setRendererDeregistered(false)
    this.setRendererLoading(false)
    this.setVirtualDielineLoading(false)
    this.setIsResizing(false)

    this.ee.emit(eventTree.productDriver.dbyEngineInitiated)
  }

  @action
  public async initRenderer({
    threeDimensionalRendererMountPoint,
    virtualDielineMountPoints,
    isDesignPreviewMode,
    isDtpPreviewMode,
  }: ProductDriverRenderParams): Promise<void> {
    this.setVirtualDielineLoading(true)
    this.setRendererLoading(true)
    this.setDesignPreviewMode(isDesignPreviewMode)
    this.setDtpPreviewMode(isDtpPreviewMode)

    const { productRenderPilot, modelContext } = this.state

    const renderEngine = await this.renderEngineBuilder.init(
      productRenderPilot,
      {
        virtualDielines: virtualDielineMountPoints,
        threeDimensionalRenderer: productRenderPilot.isViewModeAvailable(
          ViewMode.THREE_DIMENSIONAL
        )
          ? threeDimensionalRendererMountPoint
          : undefined,
      },
      modelContext
    )

    this.setRenderEngine(renderEngine)

    if (this.tempResizeRenderExportSnapshot) {
      const initRenderExport = _cloneDeep(this.tempResizeRenderExportSnapshot)
      this.setRenderExportSnapshot(initRenderExport)
      await renderEngine.loadDesign(initRenderExport)
      this.tempResizeRenderExportSnapshot = null

      // ! We must set design as touched to be able to save changes we've made before resizing the window
      this.productDesignStore.setDesignTouched(true)
    } else if (this.productDesignStore.isDesignSaved) {
      const productDesignData = this.productDesignStore.getEditorDesignData()
      debug(`loading product desing data to renderer`, productDesignData)
      // cloneDeep fix reference fabric filter error.
      // Bootstrap after screen resizing - for further reference and debug
      const initRenderExport = _cloneDeep(productDesignData)
      this.setRenderExportSnapshot(initRenderExport)
      await renderEngine.loadDesign(initRenderExport)
    }

    if (!isDesignPreviewMode) {
      await renderEngine.prepareEditing()
    }

    this.setActiveObjectDriver(this)
    this.setConverterDriver(this)
    this.assetsDriver.init()
    this.backgroundsDriver.init()
    this.attachModelEventListeners()

    await this.forceView(
      this.state.activeViewType,
      this.state.activeContext,
      this.state.activeSpace
    )

    if (isDesignPreviewMode) {
      for (const vdEditor of renderEngine.getVirtualDielineEditors()) {
        vdEditor.setPreviewMode()
        vdEditor.fabricCanvas.renderAll()
      }
    }

    this.ee.emit(eventTree.productDriver.renderEngineInitiated)
    this.setRendererDeregistered(false)
    this.setRendererLoading(false)
    this.setVirtualDielineLoading(false)
    this.setIsResizing(false)
  }

  public async switchToDefaultSpace() {
    const { productRenderPilot } = this.state
    const defaultView = productRenderPilot.getDefaultView()

    await this.setView(
      ViewType.SPACE,
      defaultView.editContext,
      defaultView.spaceId
    )
  }

  public async switchToLastPreview(): Promise<void> {
    if (this.lastPreviewType === ViewType.SPACE) {
      return this.switchToDefaultView()
    }

    await this.setView(this.lastPreviewType, this.state.activeContext, null)
  }

  public async switchToDefaultView(): Promise<void> {
    const { productRenderPilot } = this.state
    const defaultView = productRenderPilot.getDefaultView()

    await this.setView(
      defaultView.viewType,
      defaultView.editContext,
      defaultView.spaceId
    )
  }

  public isDefaultView(): boolean {
    const { productRenderPilot } = this.state

    if (!productRenderPilot) {
      return false
    }

    if (productRenderPilot.getEditorMode() === "dby") {
      return true
    }

    const {
      spaceId: defaultSpace,
      editContext: defaultEditContext,
      viewType: defaultViewType,
    } = productRenderPilot.getDefaultView()
    const { activeContext, activeSpace, activeViewType } = this.state

    return (
      defaultViewType === activeViewType &&
      defaultSpace === activeSpace &&
      activeContext === defaultEditContext
    )
  }

  @action
  private setDesignPreviewMode(isDesignPreviewMode: boolean): void {
    this.state.isDesignPreviewMode = isDesignPreviewMode
  }

  @action
  private setDtpPreviewMode(isDtpPreviewMode: boolean): void {
    this.state.isDtpPreviewMode = isDtpPreviewMode
  }

  public setDesignTouched(touched: boolean): void {
    this.productDesignStore.setDesignTouched(touched)
  }

  @action
  private setRenderEngine(renderEngine): void {
    this.state.renderEngine = renderEngine
  }

  @action
  private setProductRenderPilot(productRenderPilot: ProductRenderPilot): void {
    this.state.productRenderPilot = productRenderPilot

    const availableEditContexts = productRenderPilot.getAvailableEditContexts()

    if (!availableEditContexts.includes(this.state.activeContext)) {
      this.state.activeContext = productRenderPilot.getDefaultEditContext()
    }
  }

  @action
  private setProductLoading(isLoading: boolean): void {
    this.state.isProductLoading = isLoading
  }

  @action
  public setIsProductChanging(isChanging: boolean): void {
    this.state.isProductChanging = isChanging
  }

  @action
  private setRendererLoading(isLoading: boolean): void {
    this.state.isRendererLoading = isLoading
  }

  @action
  private setRendererDeregistered(isDeregistered: boolean): void {
    this.state.isRendererDeregistered = isDeregistered
  }

  @action
  private setVirtualDielineLoading(isLoading: boolean): void {
    this.state.isVirtualDielineLoading = isLoading
  }

  @action
  public async toggle3DPreview(): Promise<void> {
    const { is3DPreviewOn } = this.state
    this.set3DPreview(!is3DPreviewOn)

    const renderEngine = this.getRenderEngine()
    const renderer = renderEngine.getThreeDimRenderer()

    if (!renderer) {
      return
    }

    if (!is3DPreviewOn) {
      await this.clearView()
      return renderer.startAnimate().touchTextures()
    }

    await this.refreshView()
  }

  @action
  private set3DPreview(isOn: boolean): void {
    this.state.is3DPreviewOn = isOn
  }

  @action
  private setActiveObjectDriver(context): void {
    if (!this.activeObjectDriver) {
      this.activeObjectDriver = new ActiveObjectDriver(context, this.ee)
    }
  }

  @action
  private setConverterDriver(context): void {
    this.converterDriver = new ConverterDriver(context, this.ee)
  }

  private saveLastPreviewType(): void {
    const { activeViewType } = this.state

    if (activeViewType === ViewType.SPACE) {
      return
    }

    this.lastPreviewType = activeViewType
  }

  @action
  public async changeModelContext(modelContext: ModelContext): Promise<void> {
    if (
      this.state.isLoading3DModel ||
      this.state.modelContext === modelContext
    ) {
      return
    }

    this.setModelContext(modelContext)

    const { renderEngine } = this.state

    this.setLoading3DModel(!renderEngine?.isModelFoldingSupported())

    await renderEngine?.changeModelContext(modelContext)

    this.setLoading3DModel(false)
  }

  public async reloadModel(): Promise<void> {
    this.setLoading3DModel(true)

    const { renderEngine } = this.state
    await renderEngine?.reloadModel(this.state.modelContext)

    this.setLoading3DModel(false)
  }

  @action
  private setLoading3DModel(isLoading): void {
    this.state.isLoading3DModel = isLoading
  }

  @action
  private setModelContext(context: ModelContext): void {
    this.state.modelContext = context
  }

  /*
   * @TODO: Move addImage, addText, addPredefinedText, addLogoPlaceholderSlot from ProductDriver to a specific UIController
   *
   * See:
   * - editor/src/stores/_controllers/fsc-certificate-ui.controller.ts
   * - editor/src/stores/_controllers/shapes-controller.ts
   */
  @action
  public async addImage(
    imageAsset: ImageAsset,
    options: { templateId?: number } = {}
  ): Promise<void> {
    const { renderEngine, productRenderPilot, activeContext, activeSpace } =
      this.state

    let context = activeContext
    let space = activeSpace
    let shouldSelect = true

    const isImageUnavailable =
      productRenderPilot.isPrintAvailableFor(context) &&
      !productRenderPilot.getAvailableSpaces(context).length

    if (!activeSpace) {
      space = productRenderPilot.getDefaultSpace(context)
      shouldSelect = false
    }

    if (isImageUnavailable) {
      context = productRenderPilot.getDefaultEditContext()
      space = productRenderPilot.getDefaultSpace(context)
    }

    const canvasObject = await renderEngine!
      .getVirtualDielineEditor(context)
      .assetsModule.addImage(imageAsset, {
        spaceId: space!,
        shouldSelect,
        templateId: options.templateId,
      })

    const isMaskApplied = !!canvasObject.maskController

    this.setDesignTouched(true)

    this.ee.emit(
      eventTree.productDriver.imageAdded,
      imageAsset,
      activeContext,
      activeSpace,
      isMaskApplied
    )
  }

  @action
  public async addText(
    text: Text,
    forcedSpace?: ModelEditableSpaces | null
  ): Promise<void> {
    const { renderEngine, activeSpace, activeContext, productRenderPilot } =
      this.state
    const vdEditor = renderEngine!.getVirtualDielineEditor(activeContext)
    const space = forcedSpace || activeSpace

    if (!space || !productRenderPilot) {
      throw new Error("Space must be selected")
    }

    const canvasObject = await vdEditor.assetsModule.addText(space, text, true)
    const isMaskApplied = !!canvasObject.maskController

    this.setDesignTouched(true)

    this.ee.emit(
      eventTree.productDriver.textAdded,
      activeContext,
      activeSpace,
      isMaskApplied
    )
  }

  public async addPredefinedText(
    asset: PredefinedTextAssetDto,
    predefinedText: PredefinedText,
    forcedSpace?: ModelEditableSpaces
  ): Promise<void> {
    const { renderEngine, activeSpace, activeContext } = this.state
    const space = forcedSpace || activeSpace

    const vdEditor = renderEngine!.getVirtualDielineEditor(activeContext)

    if (!space) {
      throw new Error("Space must be selected")
    }

    const addedAsset = await vdEditor.assetsModule.addPredefinedText(
      space,
      { asset, predefinedText },
      true
    )
    const isMaskApplied = !!addedAsset.maskController

    this.setDesignTouched(true)

    this.ee.emit(eventTree.productDriver.predefinedTextAdded, {
      activeContext,
      activeSpace,
      isMaskApplied,
      predefinedText,
    })
  }

  @action
  public async addLogoPlaceholderSlot(
    forcedSpace?: ModelEditableSpaces | null
  ): Promise<void> {
    const { renderEngine, activeSpace, activeContext, productRenderPilot } =
      this.state
    const vdEditor = renderEngine!.getVirtualDielineEditor(activeContext)
    const space = forcedSpace || activeSpace

    if (!space || !productRenderPilot) {
      throw new Error("Space must be selected")
    }

    const canvasObject = await vdEditor.assetsModule.addLogoPlaceholderSlot(
      space,
      true
    )

    const isMaskApplied = !!canvasObject.maskController

    this.setDesignTouched(true)

    this.ee.emit(eventTree.productDriver.logoPlaceholderSlotAdded, {
      activeContext,
      activeSpace,
      isMaskApplied,
    })
  }

  @action
  public async setView(
    viewType: ViewType,
    editContext: EditContext,
    spaceId: ModelEditableSpaces | null
  ): Promise<void> {
    const {
      isVirtualDielineLoading,
      isRendererLoading,
      isDesignPreviewMode,
      isResizing,
      productRenderPilot,
      activeSpace,
      activeContext,
      activeViewType,
    } = this.state
    // We want to allow dtp to use camera controls
    const isDtpPreviewMode = this.state.isDtpPreviewMode

    if (
      isResizing ||
      isVirtualDielineLoading ||
      isRendererLoading ||
      (isDesignPreviewMode &&
        !isDtpPreviewMode &&
        productRenderPilot.isViewModeAvailable(ViewMode.THREE_DIMENSIONAL))
    ) {
      return
    }

    if (
      viewType === activeViewType &&
      editContext === activeContext &&
      spaceId === activeSpace
    ) {
      return
    }

    await this.forceView(viewType, editContext, spaceId)

    this.ee.emit(eventTree.productDriver.viewChanged, {
      space: spaceId,
      context: editContext,
      viewType: viewType,
      previousSpace: activeSpace,
    })
  }

  public async refreshView(): Promise<void> {
    const { activeViewType, activeContext, activeSpace } = this.state

    return this.forceView(activeViewType, activeContext, activeSpace)
  }

  private async forceView(
    viewType: ViewType,
    editContext: EditContext,
    spaceId: ModelEditableSpaces | null
  ): Promise<void> {
    this.saveLastPreviewType()
    await this.clearView()
    this.setActiveViewType(viewType)

    if (viewType === ViewType.MODEL) {
      return this.showModel(editContext)
    }

    if (viewType === ViewType.DIELINE) {
      return this.showDieline(editContext)
    }

    if (viewType === ViewType.SPACE && spaceId) {
      return this.showSpace(editContext, spaceId)
    }
  }

  private async clearView(): Promise<void> {
    const { activeViewType } = this.state
    const vdEditor = this.getVdEditor(this.state.activeContext)

    await vdEditor.escapeEditMode()
    await vdEditor.resetDielinePosition()

    if (activeViewType === ViewType.MODEL) {
      return this.clearModelEventListeners()
    }

    if (activeViewType === ViewType.DIELINE) {
      this.clearDielineEventListeners()
      return vdEditor.hideDieline()
    }
  }

  @action
  public setEditContext(editContext: EditContext): void {
    const { activeContext, productRenderPilot } = this.state

    if (activeContext === editContext) {
      return
    }

    if (!productRenderPilot.isPrintAvailableFor(editContext)) {
      return
    }

    this.state.activeContext = editContext
  }

  private async showDieline(editContext: EditContext): Promise<void> {
    const vdEditor = this.getVdEditor(editContext)

    this.setVirtualDielineLoading(true)

    this.setEditContext(editContext)
    this.setEditSpace(null)

    await vdEditor.showDieline()
    this.attachDielineEventListeners()

    this.setVirtualDielineLoading(false)
  }

  private async showModel(editContext: EditContext): Promise<void> {
    const renderEngine = this.getRenderEngine()
    const { modelContext, productRenderPilot } = this.state

    const renderer = renderEngine.getThreeDimRenderer()

    if (!renderer) {
      return
    }

    this.setVirtualDielineLoading(true)

    renderEngine.set2dInterfaceObjectsVisibility(false)
    renderEngine.rerender()

    const newModelContext =
      productRenderPilot.getModelContextByEditContext(editContext)

    if (newModelContext === modelContext) {
      renderer.touchTextures()
    } else {
      this.changeModelContext(newModelContext)
    }

    this.setEditContext(editContext)
    this.setEditSpace(null)

    this.attachModelEventListeners()
    this.productDesignTouchListener.clearEditModeEventListeners()
    this.setVirtualDielineLoading(false)
  }

  public async generateDielinePreview(
    editContext: EditContext
  ): Promise<string | undefined> {
    const renderEngine = this.getRenderEngine()

    return renderEngine.generateDielinePreview(editContext)
  }

  private getRenderEngine(): RenderEngine {
    const { renderEngine } = this.state

    if (!renderEngine) {
      throw new Error("Render engine is not ready")
    }

    return renderEngine
  }

  public getDielineNavigator(): DielineNavigator {
    return this.getVdEditor(this.state.activeContext).dielineNavigator
  }

  private async showSpace(
    editContext: EditContext,
    spaceId: ModelEditableSpaces
  ): Promise<void> {
    return new Promise(async (resolve) => {
      const renderEngine = this.getRenderEngine()
      renderEngine.offRendererTextures()
      renderEngine.onNextRendererTick(async () => {
        this.setVirtualDielineLoading(true)

        this.setEditContext(editContext)
        this.setEditSpace(spaceId)

        const vdEditor = this.getVdEditor(editContext)
        this.productDesignTouchListener.attachEditModeEventListeners()

        if (!this.state.isDesignPreviewMode) {
          await vdEditor.enterEditMode()
        }

        await vdEditor.showSpace(spaceId)

        this.setVirtualDielineLoading(false)

        this.ee.emit(eventTree.productDriver.changedDielinePosition, {
          activeContext: this.state.activeContext,
          activeSpace: this.state.activeSpace,
        })

        resolve()
      })
    })
  }

  @action
  public setActiveViewType(viewType: ViewType): void {
    this.state.activeViewType = viewType
  }

  @action
  private setEditSpace(spaceId: ModelEditableSpaces | null): void {
    this.state.activeSpace = spaceId
  }

  private onTemplateSelected = (): void => {
    const renderEngine = this.getRenderEngine()

    if (renderEngine.has3D) {
      renderEngine.set2dInterfaceObjectsVisibility(false)
      renderEngine.rerender()
    }
  }

  private attachDielineEventListeners(): void {
    if (this.state.isDesignPreviewMode) {
      return
    }

    const vdEditor = this.getVdEditor(this.state.activeContext)

    vdEditor.fabricCanvas.on("mouse:move", this.onDielineMouseMove)
    vdEditor.fabricCanvas.on("mouse:down", this.onDielineMouseClick)
  }

  private clearDielineEventListeners(): void {
    const vdEditor = this.getVdEditor(this.state.activeContext)

    vdEditor.fabricCanvas.off("mouse:move", this.onDielineMouseMove)
    vdEditor.fabricCanvas.off("mouse:down", this.onDielineMouseClick)
  }

  private attachModelEventListeners(): void {
    this.eventEmitter.on("onThreeDimMouseMove", this.onModelMouseMove)
    this.eventEmitter.on("onThreeDimMouseClick", this.onModelMouseClick)
    this.eventEmitter.on("onThreeDimTouchEnd", this.onModelTouchEnd)
    this.eventEmitter.on(
      "onVirtualDielineObjectAdded",
      this.onVirtualDielineObjectAdded
    )

    this.ee.on(eventTree.templates.selected, this.onTemplateSelected)
    this.ee.on(eventTree.templates.designerModeLoaded, this.onTemplateSelected)
  }

  private clearModelEventListeners(): void {
    this.eventEmitter.off("onThreeDimMouseMove", this.onModelMouseMove)
    this.eventEmitter.off("onThreeDimMouseClick", this.onModelMouseClick)
    this.eventEmitter.off("onThreeDimTouchEnd", this.onModelTouchEnd)
    this.eventEmitter.off(
      "onVirtualDielineObjectAdded",
      this.onVirtualDielineObjectAdded
    )

    this.ee.off(eventTree.templates.selected, this.onTemplateSelected)
  }

  public async generateRenderEngineSnapshot(
    options: RenderEngineSnapshotOptions = {
      force: false,
      withPreview: false,
    }
  ): Promise<{
    isVersionGenerated: boolean
    exportedData: RenderEngineExport
  }> {
    if (
      this.renderExportSnapshot &&
      !options.force &&
      !this.productDesignStore.state.isDesignTouched
    ) {
      return {
        isVersionGenerated: false,
        exportedData: this.renderExportSnapshot,
      }
    }

    const exportedData = await this.getRenderEngineDataForExport(options)
    this.setRenderExportSnapshot(exportedData)
    this.setDesignTouched(false)

    return {
      isVersionGenerated: true,
      exportedData,
    }
  }

  private async cacheDesignData(): Promise<void> {
    this.tempResizeRenderExportSnapshot = await this.getRenderEngine().export()
  }

  private async getRenderEngineDataForExport(
    options: RenderEngineExportOptions
  ): Promise<RenderEngineExport> {
    /*
     * https://packhelp.slack.com/archives/G014LL8KU0J/p1642672784009100
     *
     * If "tempResizeDataExport" is not empty it means that the window is being resized.
     * During this operation, the canvas and VD Editors are being removed
     * (and reinitialized after resizing is finished).
     *
     * In that case "renderEngine.fullExport()" returns empty data (because VD Editors are not reinitialized yet),
     * so we need to return "tempResizeDataExport" (a snapshot made just before the resize event).
     */
    if (!!this.tempResizeRenderExportSnapshot) {
      return this.tempResizeRenderExportSnapshot
    }

    return await this.getRenderEngine().export(options)
  }

  @action
  private onChangeProductSkuStarted = async (): Promise<void> => {
    this.setRendererLoading(true)
  }

  @action
  private onChangeProductSkuEnded = async (
    sku: string,
    productRenderPilot: ProductRenderPilot
  ): Promise<void> => {
    const previousRenderPilot = this.state.productRenderPilot
    this.setProductRenderPilot(productRenderPilot)

    if (
      productRenderPilot.hasObjModel() &&
      !productRenderPilot.hasObjModel(this.state.modelContext)
    ) {
      this.setModelContext(productRenderPilot.getDefaultModelContext())
    }

    await this.converterDriver?.refresh(previousRenderPilot)
    this.assetsDriver.init()

    this.setRendererLoading(false)
  }

  private onVirtualDielineObjectAdded = (): void => {
    this.state.renderEngine?.touchModelTextures()
  }

  private setRenderExportSnapshot(exportedData: RenderEngineExport): void {
    this.renderExportSnapshot = exportedData
  }

  private onDielineMouseMove = (e): void => {
    const point = e.absolutePointer
    const vdEditor = this.getVdEditor(this.state.activeContext)

    vdEditor.spaceHighlightModule.offHighlightSpace()

    const sceneDimensions = vdEditor.getCanvasDimensions()
    vdEditor.spaceHighlightModule.toggleHighlightedSpace({
      x: point.x / sceneDimensions.width,
      y: point.y / sceneDimensions.height,
    })
  }

  private onDielineMouseClick = (e): void => {
    const point = e.absolutePointer
    const vdEditor = this.getVdEditor(this.state.activeContext)
    const sceneDimensions = vdEditor.getCanvasDimensions()

    this.showSpaceByCursorDefinition({
      editContext: this.state.activeContext,
      x: point.x / sceneDimensions.width,
      y: point.y / sceneDimensions.height,
    })
  }

  private onModelMouseMove = (definition: HighlightDefinition): void => {
    const { renderEngine } = this.state

    if (this.state.isDesignPreviewMode || !renderEngine) {
      return
    }

    const shouldUpdateModelTextures = renderEngine
      .getVirtualDielineEditors()
      .map((virtualDielineEditor) => {
        if (virtualDielineEditor.spaceHighlightModule.isSpaceHighlighted()) {
          virtualDielineEditor.spaceHighlightModule.offHighlightSpace()
          return true
        }
        return false
      })
      .find((sideHighlightUpdated) => sideHighlightUpdated === true)

    if (shouldUpdateModelTextures) {
      renderEngine.touchModelTextures()
    }

    const mountPoint = this.state.threeDimensionalRendererMountPoint

    if (typeof mountPoint === "undefined") {
      return
    }

    const vdEditor = this.getVdEditors().find(
      (vdEditor) => vdEditor.editContext === definition.editContext
    )

    if (!vdEditor) {
      mountPoint.style.cursor = CURSORS.GRAB
      return
    }

    vdEditor.spaceHighlightModule.toggleHighlightedSpace(definition)

    if (!vdEditor.spaceHighlightModule.isSpaceHighlighted()) {
      mountPoint.style.cursor = CURSORS.GRAB
      return
    }

    mountPoint.style.cursor = CURSORS.POINTER

    renderEngine.onNextRendererTick(() => {
      renderEngine.touchModelTextures()
    })
  }

  private onModelMouseClick = (definition: HighlightDefinition): void => {
    if (this.state.isDesignPreviewMode) {
      return
    }

    const { editContext } = definition

    if (!editContext || !Object.values(EditContext).includes(editContext)) {
      return
    }

    this.showSpaceByCursorDefinition(definition)
  }

  private showSpaceByCursorDefinition(definition: HighlightDefinition) {
    const { editContext } = definition

    if (!editContext) {
      return
    }

    const vdEditor = this.getVdEditors().find(
      (vdEditor) => vdEditor.editContext === definition.editContext
    )

    if (!vdEditor) {
      return
    }

    const space = vdEditor.spaceHighlightModule.findSpace(definition)

    if (!space) {
      return
    }

    const spaceId = Object.values(ModelEditableSpaces).find(
      (spaceId) => spaceId === space.originSpaceArea
    )

    if (spaceId) {
      vdEditor.spaceHighlightModule.offHighlightSpace()
      this.setView(ViewType.SPACE, editContext, spaceId)
      this.ee.emit(eventTree.productDriver.spaceSelectedForEdit, {
        space: spaceId,
        context: editContext,
      })
    }
  }

  private onModelTouchEnd = (definition: HighlightDefinition): void => {
    this.onModelMouseClick(definition)
  }

  public deselectObjectsOnCanvas(): void {
    if (!this.state.renderEngine) {
      return
    }

    const canvas = this.state.renderEngine.getVirtualDielineEditor(
      this.state.activeContext
    ).fabricCanvas

    if (!isInteractiveCanvas(canvas)) {
      return
    }

    canvas.discardActiveObject().renderAll()
  }

  public getVdEditors(): VirtualDielineEditor[] {
    return this.getRenderEngine().getVirtualDielineEditors()
  }

  public getVdEditor(
    editContext: EditContext = this.state.activeContext
  ): VirtualDielineEditor {
    return this.getRenderEngine().getVirtualDielineEditor(editContext)
  }
}

export default ProductDriver
