export default {
    FS23: {
        outside: {
            width: 92,
            length: 92,
            height: 50,
        },
        inside: {
            width: 79,
            length: 87,
            height: 45,
        },
        sleeve: {
            width: 58,
            height: 51,
            length: 93,
        },
    },
    FS33: {
        outside: {
            width: 118,
            length: 140,
            height: 80,
        },
        inside: {
            width: 102,
            length: 135,
            height: 75,
        },
        sleeve: {
            width: 71,
            length: 141,
            height: 81,
        },
    },
    FS44: {
        outside: {
            width: 207,
            length: 156,
            height: 91,
        },
        inside: {
            width: 196,
            length: 151,
            height: 86,
        },
        sleeve: {
            width: 117,
            height: 92,
            length: 157,
        },
    },
    FS45: {
        outside: {
            width: 231,
            length: 140,
            height: 80,
        },
        inside: {
            width: 220,
            length: 135,
            height: 75,
        },
        sleeve: {
            width: 109,
            height: 81,
            length: 141,
        },
    },
    FS46: {
        outside: {
            width: 245,
            length: 194,
            height: 22,
        },
        inside: {
            width: 240,
            length: 189,
            height: 18,
        },
        sleeve: {
            width: 132,
            height: 25,
            length: 195,
        },
    },
    FS52: {
        outside: {
            width: 265,
            length: 195,
            height: 60,
        },
        inside: {
            width: 249,
            length: 190,
            height: 55,
        },
        sleeve: {
            width: 158,
            height: 61,
            length: 196,
        },
    },
    FS56: {
        outside: {
            width: 260,
            length: 200,
            height: 105,
        },
        inside: {
            width: 245,
            length: 195,
            height: 100,
        },
        sleeve: {
            width: 158,
            height: 106,
            length: 201,
        },
    },
    FS59: {
        outside: {
            width: 320,
            length: 182,
            height: 90,
        },
        inside: {
            width: 305,
            length: 177,
            height: 85,
        },
        sleeve: {
            width: 158,
            height: 91,
            length: 183,
        },
    },
    FS62: {
        outside: {
            width: 302,
            length: 233,
            height: 88,
        },
        inside: {
            width: 289,
            length: 228,
            height: 83,
        },
        sleeve: {
            width: 158,
            height: 89,
            length: 234,
        },
    },
    FS65: {
        outside: {
            width: 350,
            length: 250,
            height: 50,
        },
        inside: {
            width: 345,
            length: 245,
            height: 45,
        },
        sleeve: {
            width: 158,
            height: 52,
            length: 251,
        },
    },
    FS71: {
        outside: {
            width: 350,
            length: 260,
            height: 100,
        },
        inside: {
            width: 337,
            length: 255,
            height: 95,
        },
        sleeve: {
            width: 192,
            height: 101,
            length: 261,
        },
    },
    FS79: {
        outside: {
            width: 364,
            length: 314,
            height: 125,
        },
        inside: {
            width: 347,
            length: 309,
            height: 120,
        },
        sleeve: {
            width: 222,
            height: 126,
            length: 315,
        },
    },
};
