var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import POLY_MAILER_CONSTS from "../consts/polyMailerConsts";
var DEFAULT = "default";
var BAG_PRODUCTS_WEIGHTS = (_a = {},
    _a[POLY_MAILER_CONSTS.POLY_MAILER] = (_b = {},
        _b[POLY_MAILER_CONSTS.POLY_MAILER_VARIANT_STANDARD] = (_c = {},
            _c[DEFAULT] = {
                Y43: 0.01,
                Y60: 0.012,
                Y80: 0.02,
                Y90: 0.027,
                Y100: 0.05,
            },
            _c),
        _b),
    _a[POLY_MAILER_CONSTS.POLY_MAILER_BIO] = (_d = {},
        _d[POLY_MAILER_CONSTS.POLY_MAILER_BIO_VARIANT_STANDARD] = (_e = {},
            _e[DEFAULT] = {
                Y60: 0.018,
                Y80: 0.03,
                Y90: 0.04,
            },
            _e),
        _d),
    _a[POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN] = (_f = {},
        _f[POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN_VARIANT_STANDARD] = (_g = {},
            _g[DEFAULT] = {
                Y60: 0.018,
                Y80: 0.03,
                Y90: 0.04,
            },
            _g),
        _f),
    _a[POLY_MAILER_CONSTS.POLY_MAILER_RECYCLED] = (_h = {},
        _h[POLY_MAILER_CONSTS.POLY_MAILER_RECYCLED_VARIANT_STANDARD] = (_j = {},
            _j[DEFAULT] = {
                Y43: 0.01,
                Y60: 0.012,
                Y80: 0.02,
                Y90: 0.027,
                Y100: 0.05,
            },
            _j),
        _h),
    _a);
export default BAG_PRODUCTS_WEIGHTS;
