import { GetContainerFormat } from "iti"
import { initDatadog } from "../services/integrations/datadog.service"
import { UtilEnvContainer } from "./util-container"

export async function provideIntegrationsContainer(
  utilEnvContainer: UtilEnvContainer
) {
  const { appConfig } = utilEnvContainer
  const { datadog } = appConfig.integrations

  if (datadog) {
    initDatadog(datadog)
  }
}

export type IntegrationsContainer = GetContainerFormat<
  typeof provideIntegrationsContainer
>
