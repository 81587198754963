import React, { useCallback, useEffect, useState } from "react"

import { TType } from "shared-libs/src/js/shared-components/i18n/TypographyWithTranslate"
import Typography from "dsl/src/atoms/Typography/Typography"
import { useTranslate } from "shared-libs/src/js/shared-components/i18n/useTranslate"

import { useRootStore } from "../../../../hooks/useRootStore"
import { I18N } from "../../../../i18n"

import styles from "./GlueStripCursorTooltip.module.scss"

export const GlueStripCursorTooltip = () => {
  const [isShown, setIsShown] = useState(false)
  const [coords, setCoords] = useState<[number, number]>([0, 0])
  const t = useTranslate()

  const { productDriver } = useRootStore()

  const show = useCallback((event) => {
    setIsShown(true)
    setCoords([event.offsetX, event.offsetY])
  }, [])

  const hide = useCallback(() => {
    setIsShown(false)
  }, [])

  useEffect(() => {
    productDriver.eventEmitter.on("glueStripMouseOver", show)
    productDriver.eventEmitter.on("glueStripMouseOut", hide)

    return () => {
      productDriver.eventEmitter.off("glueStripMouseOver", show)
      productDriver.eventEmitter.off("glueStripMouseOut", hide)
    }
  }, [show, hide])

  return (
    <div className={styles.scene_wrapper}>
      <div
        className={styles.wrapper}
        style={{
          transform: `translate(${coords[0]}px, ${coords[1]}px)`,
          display: isShown ? "block" : "none",
        }}
      >
        <Typography type={TType.Header13_500} className={styles.tooltip}>
          {t(I18N.component.cursorTooltip.glueStrip)}
        </Typography>
      </div>
    </div>
  )
}
