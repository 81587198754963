import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import TextField from "dsl/src/atoms/TextField/TextField"
import { SelectSizes } from "dsl/src/atoms/Select/types"
import Select from "dsl/src/atoms/Select/Select"
import { ReactComponent as IconTextBold } from "../../../../assets/svg/text-bold.svg"
import { ReactComponent as IconTextItalic } from "../../../../assets/svg/text-italic.svg"
import { I18N } from "../../../../i18n"
import { useTranslate } from "../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { IconButton } from "../../../atoms/IconButton/IconButton"
import { FontList } from "../../FontList/FontList"
import { Fonts } from "../../../../../libs/services/fonts-loader-service/fonts-loader.config"
import {
  FontStyle,
  FontWeight,
} from "../../../../../libs/services/fonts-loader-service/fonts-loader.service"

import styles from "./EditorToolReplicablePatterns.module.scss"

export const TextTab = observer(() => {
  const t = useTranslate()
  const [isFontDropdownOpen, setIsFontDropdownOpen] = useState(false)

  const [containerSet] = useContainerSet((c) => [c.replicablePatterns, c.ui])

  if (!containerSet) {
    return null
  }

  const { replicablePatterns, ui } = containerSet
  if (!replicablePatterns.available) {
    return null
  }

  const { replicablePatternsStore, replicablePatternUiController } =
    replicablePatterns

  const fontFamiliesDefinitions = ui.fontsConfigStore.fontFamiliesList
  const selectItems = fontFamiliesDefinitions.map((fontFamily) => {
    return {
      ...fontFamily,
      name: fontFamily.config.name,
    }
  })

  const toggleFontStyle = () => {
    replicablePatternUiController.setTextOptions({
      fontStyle:
        replicablePatternsStore.textOptions.fontStyle === FontStyle.italic
          ? FontStyle.normal
          : FontStyle.italic,
    })
  }

  const toggleFontWeight = () => {
    replicablePatternUiController.setTextOptions({
      fontWeight:
        replicablePatternsStore.textOptions.fontWeight === FontWeight.normal
          ? FontWeight.bold
          : FontWeight.normal,
    })
  }

  const onTextChange = (value: string) => {
    replicablePatternUiController.setTextOptions({ text: value })
  }

  return (
    <>
      <Typography type={TType.Header15_500} className={styles.title}>
        {t(I18N.editorTools.patterns.yourText)}
      </Typography>

      <TextField
        value={replicablePatternsStore.textOptions.text}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onTextChange(e.target.value)
        }
        autofocus
      />
      <div className={styles.container}>
        <Select
          handleSelectedItemChange={() => {}}
          items={selectItems}
          size={SelectSizes.small}
          withMenuFluid
          placeholder={t(I18N.components.editorToolText.label.selectFont)}
          onClick={() => {
            setIsFontDropdownOpen(!isFontDropdownOpen)
          }}
          disableNoResultsIndicator
          selectedItem={selectItems.find(
            (item) => item.id === replicablePatternsStore.textOptions.fontFamily
          )}
          hideMenu
          isOpen={isFontDropdownOpen}
        />
        <div className={styles.icon}>
          <IconButton
            isActive={
              replicablePatternsStore.textOptions.fontStyle === FontStyle.italic
            }
            disabled={!replicablePatternsStore.isFontStyleAvailable}
            Icon={IconTextItalic}
            onClick={toggleFontStyle}
          />
        </div>
        <div className={styles.icon}>
          <IconButton
            isActive={
              replicablePatternsStore.textOptions.fontWeight === FontWeight.bold
            }
            disabled={!replicablePatternsStore.isFontWeightAvailable}
            Icon={IconTextBold}
            onClick={toggleFontWeight}
          />
        </div>
      </div>
      {isFontDropdownOpen && (
        <div className={styles.font_list_wrapper}>
          <FontList
            onClick={(fontFamilyDefinition) => {
              replicablePatternUiController.setTextOptions({
                fontFamily: fontFamilyDefinition.id as Fonts,
                fontStyle: FontStyle.normal,
                fontWeight: FontWeight.normal,
              })
            }}
            selectedFontFamily={
              replicablePatternsStore.textOptions.fontFamily as Fonts
            }
          />
        </div>
      )}
    </>
  )
})
