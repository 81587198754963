export default {
    P48: {
        outside: {
            width: 80,
            length: 80,
            height: 320,
        },
        inside: {
            width: 80,
            length: 80,
            height: 320,
        },
    },
};
