import { getUiConfig } from "./predefined-text-ui-config"
import {
  EditContext,
  EditorMode,
  FullProductDescription,
  ModelEditableSpaces,
  ColorModeUI,
  FinishUI,
  MaterialUI,
  SpaceDimensions,
  TextureDefinition,
} from "../types"
import { ProductRenderPilot } from "../product-render-pilot"
import { PredefinedText } from "./configs/predefined-text"

export class PredefinedTextRenderPilot extends ProductRenderPilot {
  constructor(public readonly editorMode: EditorMode) {
    super(undefined, undefined, editorMode, PredefinedText, getUiConfig())
  }

  public setAfterPurchaseEdit(isAfterPurchaseEdit: boolean): void {}

  public getProduct(): undefined {
    return
  }

  public getProductName(): string {
    return "Predefined Text"
  }

  public getVirtualDielineTexture(
    editContext: EditContext,
    isZoomActive: boolean
  ): TextureDefinition {
    const texture =
      this.renderConfig.texturesConfig.models["predefined-text"]?.variants[
        "predefined-text"
      ]?.materials[0]?.texturesPaths?.virtualDielineTexture

    if (!texture) {
      throw new Error("Virtual dieline texture is not defined in render config")
    }

    return texture
  }

  public getFullProductDescription(): FullProductDescription {
    return {
      variantNameKey: "TODO VARIANT",
      codeName: "TODO CODENAME",
      materialKey: "MATERIAL",
      printModeSummaryKey: "PRINT MODE",
      size: "SIZE",
    }
  }

  public getSceneBackgroundTextureUrl(editContext: EditContext): undefined {
    return
  }

  public isPrintActiveFor(editContext: EditContext): boolean {
    return true
  }

  public isPrintAvailableFor(editContext: EditContext): boolean {
    return [EditContext.FRONT].includes(editContext)
  }

  public isPrintAdditionallyPaidFor(editContext: EditContext): boolean {
    return false
  }

  public getAvailableColorModes(): ColorModeUI[] {
    return []
  }

  public getAvailableFinishes(): FinishUI[] {
    return []
  }

  public getAvailableMaterials(): MaterialUI[] {
    return []
  }

  public getSpaceDimensions(
    editContext: EditContext,
    spaceId: ModelEditableSpaces
  ): SpaceDimensions {
    return {
      widthCm: 30,
      heightCm: 30,
      widthPx: 3000,
      heightPx: 3000,
    }
  }

  public isEditContextDisabled(editContext: EditContext): boolean {
    return false
  }
}
