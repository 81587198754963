import React from "react"

import { EditorMainTopBar } from "../../../dsl/organisms/EditorHeader/EditorMainTopBar"
import { EditorHeaderInfo } from "./EditorHeaderInfo"
import { EditorHeaderToolbars } from "./EditorHeaderToolbars"
import { EditorHeaderProductActions } from "./EditorHeaderProductActions"

export const UniversalHeader = () => {
  return (
    <EditorMainTopBar>
      <EditorHeaderInfo />
      <EditorHeaderToolbars />
      <EditorHeaderProductActions />
    </EditorMainTopBar>
  )
}
