import { EditorToolbarItem } from "./editor-toolbar-store"

export enum TabEvents {
  tabChanged = "ph.tab.changed",
}

export type TabEventsTypes = {
  [TabEvents.tabChanged]: (
    newTab: EditorToolbarItem | undefined,
    oldTab: EditorToolbarItem | undefined
  ) => void
}
