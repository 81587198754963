import { DebugLogger, ErrorLogger } from "../../services/logger"
import { Client } from "filestack-js"
import { eachOf } from "async"
import EventEmitter from "eventemitter3"
import { getInfo } from "@ph/editor-assets"
import {
  vectorTransformations,
  rasterTransformations,
  Transformations,
} from "@ph/editor-assets/dist/transformation-config"
import { imageUploadConf } from "../env-sniffer"
import { parseFileNaming, uniqueID } from "./utils"
import { RuntimeImageAssetError, AssetError } from "./asset-errors"
import {
  ImageAssetMetaT,
  OriginalAssetInfo,
  TransformedAssetInfo,
} from "../../modules/ph-api/asset-types"

// Init
const log = {
  debug: function (m: any) {},
  log: DebugLogger("ph:editor:image:upload:log"),
  error: ErrorLogger("ph:editor:image:upload:error"),
}

enum E {
  OnUploadComplete = "onUploadComplete",
  OnProgress = "onProgress",
  OnError = "onError",
}

export { E as ImageUploaderEvents }

//  {
//       container: 'packhelp-files-dev',
//       filename: 'funny_name_18_19.png',
//       handle: 'Sd7GgcVxQaCPhb7pHDB2',
//       key: 'dev-test-asset-storage/nick-yolo/z4wqvHr9TZqsowlR7MOs/svg.png',
//       size: 46596,
//       type: 'image/png',
//       url: 'https://cdn.filestackcontent.com/Sd7GgcVxQaCPhb7pHDB2',
//       mimetype: 'image/png'
//     }
type FilestackS3UploadResult = {
  container: string
  filename: string
  handle: string
  key: string
  size: number
  type: string
  url: string
  mimetype: string
}

export type InputFile = import("filestack-js").InputFile

export type ImageUploaderConfig = {
  apiKey: string
  bucket: BucketConfig
}

export type BucketConfig = {
  name: string
  region: string
  prefix: string
  regionUri: string
  domain: string
}

/**
 * Used for a single image upload pipeline
 *
 * Steps:
 * - upload sourceAsset to S3 (storeFileToS3)
 * - analyze and convert asset (convert images)
 *
 * TODO:
 * - change `private getFilename()`
 */
export class ImageUploader extends EventEmitter {
  private bucket: BucketConfig
  private client: Client

  private sourceImageInfo: ReturnType<typeof getInfo> | undefined
  private clientNaming: ReturnType<typeof parseFileNaming> | undefined

  // client side generated unique id
  private phHandle: string

  constructor(
    private file: InputFile,
    conf: ImageUploaderConfig = imageUploadConf
  ) {
    super()
    this.phHandle = uniqueID()
    this.clientNaming = parseFileNaming(file)

    this.bucket = conf.bucket
    this.client = new Client(conf.apiKey)
    // this.client = new Client(conf.apiKey, { forwardErrors: true }) // TODO: We could try this to pass logs to Sentry
  }

  public getClientNaming() {
    if (this.clientNaming == null) {
      throw new Error(
        "client naming or sourceImageInfo  is not ready. " +
          " File me must be parsed"
      )
    }
    return this.clientNaming
  }

  /**
   * Browser fails to recognize mime type if the extension does not match the actual file.
   * Filestack fails to recognize mime type if the browser provided invalid one.
   * We clear the mime type, so the filestack can figure it out by itself.
   * It does recognize it, fortunately.
   *
   * We also have a problem with .ai files:
   * The browser was recognizing .ai files as application/illustrator,
   * Filestack was recognizing them as application/illustrator as well.
   *
   * Once we removed the type before sending to Filestack, we got:
   * - `application/postscript` for a file saved as version 8
   * - `application/pdf` for files saved as version 9+
   *
   * It's kinda correct according to this topic:
   * https://community.adobe.com/t5/illustrator/pdf-vs-ai/m-p/9710802#M87037
   *
   * V8 was kind of a postscript structure, while V9+ has the structure of a PDF file.
   *
   * The problem now is, that if we recognize .ai files as PDF format,
   * we try to remove the white background,
   * which causes way worse quality of .ai file uploaded to the editor.
   * The text, for example, may be hard or impossible to read.
   * It will work on the DTP side though because they get the original file.
   *
   * These issues had been raised here:
   * https://github.com/filestack/filestack-js/issues/447
   */
  private getSanitizedFile = () => {
    const file = this.file

    const isInputOfTypeFile = (file: any): file is File => {
      return !!file.name
    }

    if (isInputOfTypeFile(file)) {
      const removeMimeTypeRecognizedByBrowser = (file: File) =>
        new File([file], file.name, {
          type: undefined,
        })

      return removeMimeTypeRecognizedByBrowser(file)
    }

    return file
  }

  public async uploadAndConvertFiles(): Promise<ImageAssetMetaT> {
    const file = this.getSanitizedFile()
    this.clientNaming = parseFileNaming(file)

    const uploaded = await this.storeOriginalFileToS3(file)

    const meta = await this.client.metadata(uploaded.filestackHandle, {
      width: true,
      height: true,
      md5: true,
      mimetype: true,
    })

    log.log(
      `Stored original: ${meta.width}x${meta.height} at "${uploaded.url}"`
    )
    try {
      this.sourceImageInfo = getByMimeType(meta.mimetype)
    } catch (e) {
      log.error(`invalid file: ${uploaded.url}`)
      return Promise.reject(new RuntimeImageAssetError(AssetError.invalidData))
    }

    // @ts-ignore
    this.file = null // Set file to null to save RAM. Sorry typescript

    const original: OriginalAssetInfo = {
      phHandle: this.phHandle,
      url: uploaded.url,
      width: meta.width || 0,
      height: meta.height || 0,
      extension: this.sourceImageInfo.ext,
      mimetype: this.sourceImageInfo.mimeType,
      filestackHandle: uploaded.filestackHandle,
      filestackUrl: uploaded.filestackUrl,
      md5: meta.md5,
      originalFilename: this.clientNaming.fileName,
      originalName: this.clientNaming.name,
      metaInfo: this.sourceImageInfo,
    }

    let transformations = this.getTransformationsForThisImage()
    const converted = await this.convertImages(
      uploaded.filestackHandle,
      transformations
    )
    log.log(converted)

    return {
      original: original,
      converted: converted,
    }
  }

  private getTransformationsForThisImage(): Transformations {
    if (this.sourceImageInfo == null) {
      throw new Error("Can't work before initial analysis")
    }
    if (this.sourceImageInfo.isRaster) {
      return rasterTransformations
    } else {
      return vectorTransformations
    }
  }

  private async storeOriginalFileToS3(file: InputFile) {
    try {
      const s3StoreOptions = this.getS3StoreOptionsForOriginal()

      const rez: FilestackS3UploadResult = await this.client.upload(
        file,
        {
          onProgress: (e) => {
            this.emit(E.OnProgress, e)
          },
        },
        s3StoreOptions
      )

      const storeUri = this.getDirectS3Uri(rez.key)
      const OriginalUpload = {
        filestackHandle: rez.handle,
        filestackUrl: rez.url,
        url: storeUri,
      }

      return OriginalUpload
    } catch (e) {
      throw e
    }
  }

  private convertImages(
    originalFilestackHandle: string,
    transformations: Transformations = rasterTransformations
  ): Promise<{ [key: string]: TransformedAssetInfo }> {
    return new Promise((resolve, reject) => {
      const processedFiles: {
        [key: string]: TransformedAssetInfo
      } = {}

      // WARNING (!) DONT CONVERT THIS TO ASYNC! This will break typescript
      // https://packhelp.slack.com/archives/CD9KYEVDX/p1580832613003600
      /**
       * Daily WTF
       * Dziś pisałem sobie kod. Webpack dev działa, prod nie. Kod jest poniżej. Dlaczego? Typescript i kompilacja.
       * W przypadku target ES2017 używamy normalnych async/await.
       * W przypadku ES2015 kod się kompiluję z generatorami i dziła po prostu inaczej.
       * Polecam wejść w link i zmienić sobie target 2017/2015.
       */
      eachOf(transformations, (transformation, transformationId, cb) => {
        if (transformation == null) {
          throw new Error("transformation should not be empty")
        }

        const transoformUri = this.client.transform(
          originalFilestackHandle,
          transformation.filestackTransformOptions
        )
        log.debug(`transformation url: ${transoformUri}`)

        // ----
        // Step 2: filename generation
        // ----

        // Optional dimension string in filename
        let dimms = ""
        if (transformation.filestackTransformOptions.resize != null) {
          const { width, height } =
            transformation.filestackTransformOptions.resize
          dimms = `-${width}-${height}`
        }
        const ext =
          transformation.filestackTransformOptions.output!.format || ""
        const convertedFilename = `${transformation.keyName}${dimms}.${ext}`
        const prefix = this.getS3Prefix(this.phHandle).converted
        const s3Key = `${prefix}/${convertedFilename}`

        // ----
        // Step 3: transformation
        // ----

        const s3TransformConfig = this.getS3TransformOptions(s3Key)

        return this.client
          .storeURL(transoformUri, s3TransformConfig)
          .then(async (rez) => {
            const convertedResult = rez as any as FilestackS3UploadResult
            const meta = await this.client.metadata(convertedResult.handle, {
              width: true,
              height: true,
              md5: true,
              mimetype: true,
            })

            const convertedMime = getByMimeType(meta.mimetype)
            const convertedS3Url = this.getDirectS3Uri(s3Key)

            processedFiles[transformationId] = {
              phHandle: this.phHandle,
              url: convertedS3Url,
              width: meta.width || 0,
              height: meta.height || 0,
              extension: convertedMime.ext,
              mimetype: convertedMime.mimeType,
              filestackHandle: convertedResult.handle,
              filestackUrl: convertedResult.url,
              md5: meta.md5,
              colorMode: transformation.mediaInfo.colorMode,
              maxSize: transformation.mediaInfo.maxSize,
            }

            log.log(
              `transformed and saved [${transformationId}]: ${meta.width}x${meta.height} at "${convertedS3Url}"`
            )
            cb(null)
          })
          .catch((e) => {
            log.error(e)
            cb(new RuntimeImageAssetError(AssetError.assetTransformation))
          })
      })
        .then(() => {
          resolve(processedFiles)
        })
        .catch((e) => {
          log.error(e)
          reject(e)
        })
    })
  }

  /**
   * @param path - s3 keyPath. `very/cool/file.gif`
   */
  private getS3TransformOptions(path: string) {
    if (path.charAt(0) === "/") {
      // prettier-ignore
      throw new Error(`Your path should look like "very/simple/file.png". No slash. Yours: ${path}`)
    }

    const s3StoreOptions = {
      location: "s3",
      path: path,
      container: this.bucket.name,
      region: this.bucket.region,
    }

    return s3StoreOptions
  }

  private getS3StoreOptionsForOriginal() {
    if (this.clientNaming == null) {
      throw new Error(
        "client naming or sourceImageInfo  is not ready. " +
          " File me must be parsed"
      )
    }

    // keep the trailing slash !!!!!!
    const keyPrefix = `${this.getS3Prefix(this.phHandle).originals}/`
    log.log("Users S3 path is: ", keyPrefix)
    const s3StoreOptions = {
      location: "s3",
      filename: `${this.clientNaming.fileName}`,
      path: keyPrefix,
      container: this.bucket.name,
      region: this.bucket.region,
      disableStorageKey: true,
      access: "public",
    }

    return s3StoreOptions
  }

  /**
   * Don't be tempted to remove this helper
   * S3 is PIA to seach. AWS has `SQL: Amazon Athena`.
   * But it is still meh.
   *
   * So lets store date carefully.
   *
   * Prefixes is a reasonable way
   *
   * Please don't be tempted to use any userId. It is profoundly unrealiable
   *
   * Also, S3 seems to be linearizabile. It looks like it is also a linked list,
   * so you can find first, and 100 next files.
   */
  private getS3Prefix(handle: string) {
    if (typeof this.bucket.prefix !== "string") {
      log.error("bucket prefix is invalid", this.bucket.prefix)
      throw new RuntimeImageAssetError(AssetError.invalidS3Config)
    }

    const now = new Date()
    const y = now.getFullYear()
    const m = now.getMonth()

    return {
      originals: `${this.bucket.prefix}/${y}/${m}/${handle}`, // no trailing slash!
      converted: `${this.bucket.prefix}/${y}/${m}/${handle}/converted`, // no trailing slash!
    }
  }

  private getDirectS3Uri(fullS3Key: string): string {
    const urlSafeKey = encodeURIComponent(fullS3Key)
    return `${this.bucket.domain}/${urlSafeKey}`
  }
}

function getByMimeType(mime: string) {
  try {
    return getInfo(mime)
  } catch (e) {
    log.error("Provided mimetype is not valid: ", mime)
    throw e
  }
}
