import { urls } from "../api-endpoint-urls"
import { getDesiredOrderState } from "../api-endpoint-helpers"
import zc from "../../../constants"

export const getOrder = (orderId) => ({
  method: "get",
  url: `${urls.API_ORDERS}/${orderId}`,
})

export const createCartForUser = (currency) => ({
  method: "post",
  url: `${urls.API_ORDERS_v2}`,
  data: { currency },
})

export const getUserOrders = () => ({
  method: "get",
  url: `${urls.API_ORDERS}/${urls.API_ORDERS_CURRENT_USER}?q[state_cont_any][]=complete&q[state_cont_any][]=canceled`,
})

export const addOrderItem = (orderNumber, itemData) => ({
  method: "post",
  url: `${urls.API_ORDERS}/${orderNumber}/${urls.API_ORDER_ITEMS}`,
  data: { line_item: itemData },
})

interface LineItemsParams {
  box_design_id: number | null
  express_delivery_option_id: number | null
  parent_id: number | null
  quantity: number
  variant_id: number
}

export const bulkAddOrderItems = (
  orderNumber: number,
  lineItemsParams: LineItemsParams[],
  bundleId?: number | null
) => {
  const url = urls.API_LINE_ITEMS_BULK_CREATE.replace(
    /\/:id\//,
    `/${orderNumber.toString()}/`
  )

  return {
    method: "post",
    url,
    data: { bundle_id: bundleId, line_items_params: lineItemsParams },
  }
}

export const updateOrderItem = (orderId, itemId, itemData) => ({
  method: "put",
  url: `${urls.API_ORDERS}/${orderId}/${urls.API_ORDER_ITEMS}/${itemId}`,
  data: { line_item: itemData },
})

export const deleteOrderItem = (orderId, itemId) => ({
  method: "delete",
  url: `${urls.API_ORDERS}/${orderId}/${urls.API_ORDER_ITEMS}/${itemId}`,
})

export const addOrderPromo = (orderId, code) => ({
  method: "put",
  url: `${urls.API_ORDERS}/${orderId}/${urls.API_PROMO_ADD}`,
  data: { coupon_code: code },
})

export const deleteOrderPromo = (orderId) => ({
  method: "delete",
  url: `${urls.API_ORDERS}/${orderId}/${urls.API_PROMO}`,
})

export const changeOrderState = (order, state) => ({
  method: "put",
  url: `${urls.API_CHECKOUTS}/${order.number}?state=${getDesiredOrderState(
    state
  )}`,
})

export const submitOrderDetails = (orderNumber, orderDetails) => ({
  method: "put",
  url: `${urls.API_ORDERS}/${orderNumber}`,
  data: { order: orderDetails },
})

export const createNewTransferPayment = (
  orderNumber,
  rejectPaymentSchedule
) => ({
  method: "post",
  url: `${zc.API_TRANSFER_PAYMENTS}`,
  data: {
    order_number: orderNumber,
    reject_payment_schedule: rejectPaymentSchedule,
  },
})

export const completeOrderWithTransferPaymentCall = (
  orderNumber,
  paymentMethodId,
  rejectPaymentSchedule
) => ({
  method: "put",
  url: `${zc.API_CHECKOUTS}/${orderNumber}`,
  data: {
    order: {
      payments_attributes: [
        {
          payment_method_id: paymentMethodId,
          reject_payment_schedule: rejectPaymentSchedule,
        },
      ],
      payment_source: {
        [paymentMethodId]: {},
      },
    },
  },
})
