var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addOriginQuantityBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getQuantitySpec = function () {
            var productName = this.product.variantManager.getVariant();
            var quantitiesSpec = this.product.config.quantitiesSpec;
            var quantitiesSpecForModel = quantitiesSpec[productName];
            if (quantitiesSpecForModel) {
                // PerModelQuantities
                return quantitiesSpecForModel;
            }
            if (quantitiesSpec["default"]) {
                // DefaultQuantities
                return quantitiesSpec["default"];
            }
            // FlatQuantities
            return quantitiesSpec;
        };
        class_1.prototype.getDefaultQuantity = function () {
            return this.getQuantitySpec().DEFAULT_QUANTITY;
        };
        class_1.prototype.getQuantityRanges = function () {
            return this.getQuantitySpec().QUANTITY_RANGES;
        };
        class_1.prototype.getQuantityPickerDensity = function () {
            return this.getQuantitySpec().QUANTITY_DENSITY;
        };
        class_1.prototype.getQuantityPickerStep = function () {
            return this.getQuantitySpec().QUANTITY_STEP;
        };
        return class_1;
    }(superclass));
};
export default addOriginQuantityBehaviors;
