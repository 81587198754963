var _a;
import BOX_CONSTS from "../../generalConfig/consts/boxConsts";
import SleevedMailerBoxDimensions from "../../generalConfig/dimensions/mailerBoxesWithSleevesDimensions";
var PRE_PRINTED_MAILER_BOXES_SPEC = {
    F52: {
        width: 265.06,
        length: 193.29,
        height: 59.07,
        foldLineWidth: 4.5,
        sideFoldWidth: 2.5,
        textureDirectory: "56",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
    },
    F71: {
        width: 350,
        length: 262,
        height: 100,
        foldLineWidth: 4.5,
        sideFoldWidth: 1.5,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.3,
        editorCameraZoom: 1,
    },
};
var MAILER_BOXES_SPEC = {
    F23: {
        width: 90,
        length: 90,
        height: 49.4,
        foldLineWidth: 4.5,
        sideFoldWidth: 1.5,
        textureDirectory: "33",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
    },
    F33: {
        width: 114.5,
        length: 138.5,
        height: 78,
        foldLineWidth: 5.5,
        sideFoldWidth: 1.03,
        textureDirectory: "33",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
    },
    F44: {
        width: 210,
        length: 153.7,
        height: 89.5,
        foldLineWidth: 4.5,
        sideFoldWidth: 2.54,
        textureDirectory: "45",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
    },
    F45: {
        width: 228.8,
        length: 140.5,
        height: 77,
        foldLineWidth: 5.52,
        sideFoldWidth: 2.54,
        textureDirectory: "45",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
    },
    F46: {
        width: 245,
        length: 194,
        height: 22,
        foldLineWidth: 5.52,
        sideFoldWidth: 2.54,
        textureDirectory: "45",
        thumbnailCameraZoom: 0.5,
    },
    F52: {
        width: 265.06,
        length: 193.29,
        height: 59.07,
        foldLineWidth: 4.5,
        sideFoldWidth: 2.5,
        textureDirectory: "56",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
    },
    F56: {
        width: 256.8,
        length: 197.5,
        height: 103,
        foldLineWidth: 5.46,
        sideFoldWidth: 2.54,
        textureDirectory: "56",
        thumbnailCameraZoom: 0.4,
        editorCameraZoom: 1,
    },
    F59: {
        width: 320,
        length: 185.39,
        height: 85,
        foldLineWidth: 4.5,
        sideFoldWidth: 2.54,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.4,
        editorCameraZoom: 1,
    },
    F62: {
        width: 299.5,
        length: 231,
        height: 87,
        foldLineWidth: 5.4,
        sideFoldWidth: 2.49,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.3,
        editorCameraZoom: 1,
    },
    F65: {
        width: 350,
        length: 250,
        height: 50,
        foldLineWidth: 5.4,
        sideFoldWidth: 2.49,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.3,
        editorCameraZoom: 1,
    },
    F71: {
        width: 350,
        length: 262,
        height: 100,
        foldLineWidth: 4.5,
        sideFoldWidth: 1.5,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.3,
        editorCameraZoom: 1,
    },
    F79: {
        width: 358,
        length: 310,
        height: 122.4,
        foldLineWidth: 4.5,
        sideFoldWidth: 1.5,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.3,
        editorCameraZoom: 1,
    },
    F93: {
        width: 435,
        length: 336.5,
        height: 157,
        foldLineWidth: 4.5,
        sideFoldWidth: 1.5,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.3,
        editorCameraZoom: 1,
    },
    F112: {
        width: 420,
        length: 520,
        height: 180,
        foldLineWidth: 9.56,
        sideFoldWidth: 2.75,
        textureDirectory: "112",
        thumbnailCameraZoom: 0.3,
        editorCameraZoom: 1,
    },
};
// Sleeved mailer boxes has editable only front, top, bottom, back sides
// however we need to generate left and right sides and we need setupt edit zones positon for them
// dummy but for now its hard to change
var SLEEVED_MAILER_BOXES_SPEC = {
    FS23: {
        width: 90,
        length: 90,
        height: 49.4,
        foldLineWidth: 4.5,
        sideFoldWidth: 1.5,
        textureDirectory: "33",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: (90 - SleevedMailerBoxDimensions["FS23"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS23"].sleeve.width,
                    height: 49.4,
                },
                top: {
                    top: 0,
                    left: (90 - SleevedMailerBoxDimensions["FS23"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS23"].sleeve.width,
                    height: 90,
                },
                back: {
                    top: 0,
                    left: (90 - SleevedMailerBoxDimensions["FS23"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS23"].sleeve.width,
                    height: 49.4,
                },
                bottom: {
                    top: 0,
                    left: (90 - SleevedMailerBoxDimensions["FS23"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS23"].sleeve.width,
                    height: 90,
                },
                left: {
                    top: 0,
                    left: (90 - SleevedMailerBoxDimensions["FS23"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS23"].sleeve.width,
                    height: 90,
                },
                right: {
                    top: 0,
                    left: (90 - SleevedMailerBoxDimensions["FS23"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS23"].sleeve.width,
                    height: 90,
                },
            },
        },
    },
    FS33: {
        width: 114.5,
        length: 138.5,
        height: 78,
        foldLineWidth: 5.5,
        sideFoldWidth: 1.03,
        textureDirectory: "33",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: (114.5 - SleevedMailerBoxDimensions["FS33"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS33"].sleeve.width,
                    height: 78,
                },
                top: {
                    top: 0,
                    left: (114.5 - SleevedMailerBoxDimensions["FS33"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS33"].sleeve.width,
                    height: 138.5,
                },
                back: {
                    top: 0,
                    left: (114.5 - SleevedMailerBoxDimensions["FS33"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS33"].sleeve.width,
                    height: 78,
                },
                bottom: {
                    top: 0,
                    left: (114.5 - SleevedMailerBoxDimensions["FS33"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS33"].sleeve.width,
                    height: 138.5,
                },
                left: {
                    top: 0,
                    left: (114.5 - SleevedMailerBoxDimensions["FS33"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS33"].sleeve.width,
                    height: 78,
                },
                right: {
                    top: 0,
                    left: (114.5 - SleevedMailerBoxDimensions["FS33"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS33"].sleeve.width,
                    height: 78,
                },
            },
        },
    },
    FS44: {
        width: 210,
        length: 153.7,
        height: 89.5,
        foldLineWidth: 4.5,
        sideFoldWidth: 2.54,
        textureDirectory: "45",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: (210 - SleevedMailerBoxDimensions["FS44"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS44"].sleeve.width,
                    height: 89.5,
                },
                top: {
                    top: 0,
                    left: (210 - SleevedMailerBoxDimensions["FS44"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS44"].sleeve.width,
                    height: 153.7,
                },
                back: {
                    top: 0,
                    left: (210 - SleevedMailerBoxDimensions["FS44"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS44"].sleeve.width,
                    height: 89.5,
                },
                bottom: {
                    top: 0,
                    left: (210 - SleevedMailerBoxDimensions["FS44"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS44"].sleeve.width,
                    height: 153.7,
                },
                left: {
                    top: 0,
                    left: (210 - SleevedMailerBoxDimensions["FS44"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS44"].sleeve.width,
                    height: 89.5,
                },
                right: {
                    top: 0,
                    left: (210 - SleevedMailerBoxDimensions["FS44"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS44"].sleeve.width,
                    height: 89.5,
                },
            },
        },
    },
    FS45: {
        width: 228.8,
        length: 140.5,
        height: 77,
        foldLineWidth: 5.52,
        sideFoldWidth: 2.54,
        textureDirectory: "45",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: (228.8 - SleevedMailerBoxDimensions["FS45"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS45"].sleeve.width,
                    height: 77,
                },
                top: {
                    top: 0,
                    left: (228.8 - SleevedMailerBoxDimensions["FS45"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS45"].sleeve.width,
                    height: 140.5,
                },
                back: {
                    top: 0,
                    left: (228.8 - SleevedMailerBoxDimensions["FS45"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS45"].sleeve.width,
                    height: 77,
                },
                bottom: {
                    top: 0,
                    left: (228.8 - SleevedMailerBoxDimensions["FS45"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS45"].sleeve.width,
                    height: 140.5,
                },
                left: {
                    top: 0,
                    left: (228.8 - SleevedMailerBoxDimensions["FS45"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS45"].sleeve.width,
                    height: 77,
                },
                right: {
                    top: 0,
                    left: (228.8 - SleevedMailerBoxDimensions["FS52"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS52"].sleeve.width,
                    height: 85,
                },
            },
        },
    },
    FS46: {
        width: 245,
        length: 194,
        height: 24,
        foldLineWidth: 5.52,
        sideFoldWidth: 2.54,
        textureDirectory: "45",
        thumbnailCameraZoom: 0.5,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: (245 - SleevedMailerBoxDimensions["FS46"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS46"].sleeve.width,
                    height: 24,
                },
                top: {
                    top: 0,
                    left: (245 - SleevedMailerBoxDimensions["FS46"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS46"].sleeve.width,
                    height: 194,
                },
                back: {
                    top: 0,
                    left: (245 - SleevedMailerBoxDimensions["FS46"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS46"].sleeve.width,
                    height: 24,
                },
                bottom: {
                    top: 0,
                    left: (245 - SleevedMailerBoxDimensions["FS46"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS46"].sleeve.width,
                    height: 194,
                },
                left: {
                    top: 0,
                    left: (245 - SleevedMailerBoxDimensions["FS46"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS46"].sleeve.width,
                    height: 85,
                },
                right: {
                    top: 0,
                    left: (245 - SleevedMailerBoxDimensions["FS52"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS52"].sleeve.width,
                    height: 85,
                },
            },
        },
    },
    FS52: {
        width: 265.06,
        length: 193.29,
        height: 59.07,
        foldLineWidth: 4.5,
        sideFoldWidth: 2.5,
        textureDirectory: "56",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: (265.06 - SleevedMailerBoxDimensions["FS52"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS52"].sleeve.width,
                    height: 59.07,
                },
                top: {
                    top: 0,
                    left: (265.06 - SleevedMailerBoxDimensions["FS52"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS52"].sleeve.width,
                    height: 193.29,
                },
                back: {
                    top: 0,
                    left: (265.06 - SleevedMailerBoxDimensions["FS52"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS52"].sleeve.width,
                    height: 59.07,
                },
                bottom: {
                    top: 0,
                    left: (265.06 - SleevedMailerBoxDimensions["FS52"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS52"].sleeve.width,
                    height: 193.29,
                },
                left: {
                    top: 0,
                    left: (265.06 - SleevedMailerBoxDimensions["FS52"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS52"].sleeve.width,
                    height: 85,
                },
                right: {
                    top: 0,
                    left: (265.06 - SleevedMailerBoxDimensions["FS52"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS52"].sleeve.width,
                    height: 85,
                },
            },
        },
    },
    FS56: {
        width: 256.8,
        length: 197.5,
        height: 103,
        foldLineWidth: 5.46,
        sideFoldWidth: 2.54,
        textureDirectory: "56",
        thumbnailCameraZoom: 0.4,
        editorCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: (256.8 - SleevedMailerBoxDimensions["FS56"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS56"].sleeve.width,
                    height: 103,
                },
                top: {
                    top: 0,
                    left: (256.8 - SleevedMailerBoxDimensions["FS56"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS56"].sleeve.width,
                    height: 197.5,
                },
                back: {
                    top: 0,
                    left: (256.8 - SleevedMailerBoxDimensions["FS56"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS56"].sleeve.width,
                    height: 103,
                },
                bottom: {
                    top: 0,
                    left: (256.8 - SleevedMailerBoxDimensions["FS56"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS56"].sleeve.width,
                    height: 197.5,
                },
                left: {
                    top: 0,
                    left: (256.8 - SleevedMailerBoxDimensions["FS56"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS56"].sleeve.width,
                    height: 85,
                },
                right: {
                    top: 0,
                    left: (256.8 - SleevedMailerBoxDimensions["FS56"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS56"].sleeve.width,
                    height: 85,
                },
            },
        },
    },
    FS59: {
        width: 320,
        length: 185.39,
        height: 85,
        foldLineWidth: 4.5,
        sideFoldWidth: 2.54,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.4,
        editorCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: (320 - SleevedMailerBoxDimensions["FS59"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS59"].sleeve.width,
                    height: 85,
                },
                top: {
                    top: 0,
                    left: (320 - SleevedMailerBoxDimensions["FS59"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS59"].sleeve.width,
                    height: 185.39,
                },
                back: {
                    top: 0,
                    left: (320 - SleevedMailerBoxDimensions["FS59"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS59"].sleeve.width,
                    height: 85,
                },
                bottom: {
                    top: 0,
                    left: (320 - SleevedMailerBoxDimensions["FS59"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS59"].sleeve.width,
                    height: 185.39,
                },
                left: {
                    top: 0,
                    left: (320 - SleevedMailerBoxDimensions["FS59"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS59"].sleeve.width,
                    height: 85,
                },
                right: {
                    top: 0,
                    left: (320 - SleevedMailerBoxDimensions["FS59"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS59"].sleeve.width,
                    height: 85,
                },
            },
        },
    },
    FS62: {
        width: 299.5,
        length: 231,
        height: 87,
        foldLineWidth: 5.4,
        sideFoldWidth: 2.49,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.3,
        editorCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: (299.5 - SleevedMailerBoxDimensions["FS62"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS62"].sleeve.width,
                    height: 87,
                },
                top: {
                    top: 0,
                    left: (299.5 - SleevedMailerBoxDimensions["FS62"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS62"].sleeve.width,
                    height: 231,
                },
                back: {
                    top: 0,
                    left: (299.5 - SleevedMailerBoxDimensions["FS62"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS62"].sleeve.width,
                    height: 87,
                },
                bottom: {
                    top: 0,
                    left: (299.5 - SleevedMailerBoxDimensions["FS62"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS62"].sleeve.width,
                    height: 231,
                },
                left: {
                    top: 0,
                    left: (299.5 - SleevedMailerBoxDimensions["FS62"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS62"].sleeve.width,
                    height: 87,
                },
                right: {
                    top: 0,
                    left: (299.5 - SleevedMailerBoxDimensions["FS62"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS62"].sleeve.width,
                    height: 87,
                },
            },
        },
    },
    FS65: {
        width: 350,
        length: 250,
        height: 50,
        foldLineWidth: 5.4,
        sideFoldWidth: 2.49,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.3,
        editorCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: (350 - SleevedMailerBoxDimensions["FS65"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS65"].sleeve.width,
                    height: 50,
                },
                top: {
                    top: 0,
                    left: (350 - SleevedMailerBoxDimensions["FS65"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS65"].sleeve.width,
                    height: 250,
                },
                back: {
                    top: 0,
                    left: (350 - SleevedMailerBoxDimensions["FS65"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS65"].sleeve.width,
                    height: 50,
                },
                bottom: {
                    top: 0,
                    left: (350 - SleevedMailerBoxDimensions["FS65"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS65"].sleeve.width,
                    height: 250,
                },
                left: {
                    top: 0,
                    left: (350 - SleevedMailerBoxDimensions["FS65"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS65"].sleeve.width,
                    height: 50,
                },
                right: {
                    top: 0,
                    left: (350 - SleevedMailerBoxDimensions["FS65"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS65"].sleeve.width,
                    height: 50,
                },
            },
        },
    },
    FS71: {
        width: 350,
        length: 262,
        height: 100,
        foldLineWidth: 4.5,
        sideFoldWidth: 1.5,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.3,
        editorCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: (350 - SleevedMailerBoxDimensions["FS71"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS71"].sleeve.width,
                    height: 100,
                },
                top: {
                    top: 0,
                    left: (350 - SleevedMailerBoxDimensions["FS71"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS71"].sleeve.width,
                    height: 262,
                },
                back: {
                    top: 0,
                    left: (350 - SleevedMailerBoxDimensions["FS71"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS71"].sleeve.width,
                    height: 100,
                },
                bottom: {
                    top: 0,
                    left: (350 - SleevedMailerBoxDimensions["FS71"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS71"].sleeve.width,
                    height: 262,
                },
                left: {
                    top: 0,
                    left: (350 - SleevedMailerBoxDimensions["FS71"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS71"].sleeve.width,
                    height: 100,
                },
                right: {
                    top: 0,
                    left: (350 - SleevedMailerBoxDimensions["FS71"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS71"].sleeve.width,
                    height: 100,
                },
            },
        },
    },
    FS79: {
        width: 358,
        length: 310,
        height: 122.4,
        foldLineWidth: 4.5,
        sideFoldWidth: 1.5,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.3,
        editorCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: (358 - SleevedMailerBoxDimensions["FS79"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS79"].sleeve.width,
                    height: 122.4,
                },
                top: {
                    top: 0,
                    left: (358 - SleevedMailerBoxDimensions["FS79"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS79"].sleeve.width,
                    height: 310,
                },
                back: {
                    top: 0,
                    left: (358 - SleevedMailerBoxDimensions["FS79"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS79"].sleeve.width,
                    height: 122.4,
                },
                bottom: {
                    top: 0,
                    left: (358 - SleevedMailerBoxDimensions["FS79"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS79"].sleeve.width,
                    height: 310,
                },
                left: {
                    top: 0,
                    left: (358 - SleevedMailerBoxDimensions["FS79"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS79"].sleeve.width,
                    height: 122.4,
                },
                right: {
                    top: 0,
                    left: (358 - SleevedMailerBoxDimensions["FS79"].sleeve.width) / 2,
                    width: SleevedMailerBoxDimensions["FS79"].sleeve.width,
                    height: 122.4,
                },
            },
        },
    },
};
var DELIVERY_MAILER_BOX_SPEC = {
    AS55: {
        width: 300,
        length: 190,
        height: 75,
        foldLineWidth: 8,
        sideFoldWidth: 1.5,
        textureDirectory: "45",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
        clippingDecreaseValue: 20,
        safeZoneWidth: 8,
    },
    AS73: {
        width: 390,
        length: 300,
        height: 75,
        foldLineWidth: 8,
        sideFoldWidth: 1.03,
        textureDirectory: "56",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
        clippingDecreaseValue: 12,
        safeZoneWidth: 8,
    },
    AS80: {
        width: 390,
        length: 300,
        height: 145,
        foldLineWidth: 8,
        sideFoldWidth: 2.54,
        textureDirectory: "56",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
        clippingDecreaseValue: 12,
        safeZoneWidth: 8,
    },
    AS91: {
        width: 440,
        length: 380,
        height: 130,
        foldLineWidth: 10,
        sideFoldWidth: 2.54,
        textureDirectory: "62",
        thumbnailCameraZoom: 0.5,
        editorCameraZoom: 1,
        clippingDecreaseValue: 12,
        safeZoneWidth: 10,
    },
};
var MAILER_BOXES_RENDER_CONFIG = (_a = {},
    _a[BOX_CONSTS.MAILER_BOX] = MAILER_BOXES_SPEC,
    _a[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR] = PRE_PRINTED_MAILER_BOXES_SPEC,
    _a[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO] = PRE_PRINTED_MAILER_BOXES_SPEC,
    _a[BOX_CONSTS.SLEEVED_MAILER_BOX] = SLEEVED_MAILER_BOXES_SPEC,
    _a[BOX_CONSTS.MAILER_BOX_PLAIN] = MAILER_BOXES_SPEC,
    _a[BOX_CONSTS.MAILER_BOX_WHITE_INK] = MAILER_BOXES_SPEC,
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX] = DELIVERY_MAILER_BOX_SPEC,
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN] = DELIVERY_MAILER_BOX_SPEC,
    _a);
export default MAILER_BOXES_RENDER_CONFIG;
