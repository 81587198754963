import { VirtualDielineMigration } from "./virtual-dieline-migration.interface"
import { DesignVersion } from "../../../../../modules/design/version"
import VirtualDielineEditor from "../../virtual-dieline-editor"
import {
  PackhelpGroup,
  PackhelpMaskObject,
  PackhelpObject,
} from "../../object-extensions/packhelp-objects"
import { CANVAS_DIM } from "../../../../types"
import { BackgroundLayerClippingHelper } from "../../modules/assets-module/helpers/background-layer-clippinng-helper"
import { isAssetMask, isGroup } from "../../../../../modules/ph-api/asset-types"
import { RotationHelper } from "../../modules/assets-module/helpers/rotation-helper"
import fabric from "../../../../../libs/vendors/Fabric"

/**
 * Migrates pattern and background image clip paths from relative to absolute positioned.
 */
export default class V224 implements VirtualDielineMigration {
  readonly designVersion = DesignVersion.v224

  public async migrate(vdEditor: VirtualDielineEditor): Promise<void> {
    const globalPattern = vdEditor.backgroundsModule.getGlobalPattern()
    const globalBackgroundImage =
      vdEditor.backgroundsModule.getGlobalBackgroundImage()

    if (globalPattern) {
      await this.migrateObject(vdEditor, globalPattern)
    }

    if (globalBackgroundImage) {
      await this.migrateObject(vdEditor, globalBackgroundImage)
    }
  }

  public async migrateObject(
    vdEditor: VirtualDielineEditor,
    object: PackhelpObject
  ): Promise<void> {
    const { clipPath } = object

    if (!clipPath || !isGroup(clipPath) || clipPath.absolutePositioned) {
      return
    }

    clipPath.set({
      absolutePositioned: true,
      left: 0,
      top: 0,
      width: CANVAS_DIM,
      height: CANVAS_DIM,
      dirty: true,
    })

    await BackgroundLayerClippingHelper.refreshSpaceClipping(vdEditor, object)
    await this.refreshMasks(vdEditor, clipPath)
  }

  private async refreshMasks(
    vdEditor: VirtualDielineEditor,
    clipPath: PackhelpGroup
  ): Promise<void> {
    const masks = clipPath
      .getObjects()
      .filter((clipPath): clipPath is PackhelpMaskObject =>
        isAssetMask(clipPath)
      )

    for (const mask of masks) {
      const maskParent = vdEditor.getCanvasObjectById(mask.maskParentId!)

      if (!maskParent) {
        clipPath.remove(mask)

        continue
      }

      const spaceBoundingRect = vdEditor.dielineNavigator.getSpaceBoundingRect(
        maskParent.originSpaceArea!
      )

      mask.set({
        originX: "left",
        originY: "top",
        scaleX: mask.scaleX! / 2,
        scaleY: mask.scaleY! / 2,
        clipPath: new fabric.Rect({
          width: spaceBoundingRect.width,
          height: spaceBoundingRect.height,
          left: spaceBoundingRect.left,
          top: spaceBoundingRect.top,
          absolutePositioned: true,
        }),
        dirty: true,
      })

      const maskParentPositionWithoutRotation = RotationHelper.rotatePoint(
        new fabric.Point(maskParent.left!, maskParent.top!),
        maskParent.getCenterPoint(),
        maskParent.angle ? -maskParent.angle : 0
      )

      const offsetW = (mask.getScaledWidth() - maskParent.getScaledWidth()) / 2
      const offsetH =
        (mask.getScaledHeight() - maskParent.getScaledHeight()) / 2

      const newMaskPosition = RotationHelper.rotatePoint(
        new fabric.Point(
          maskParentPositionWithoutRotation.x - offsetW,
          maskParentPositionWithoutRotation.y - offsetH
        ),
        maskParent.getCenterPoint(),
        maskParent.angle || 0
      )

      mask.set({
        left: newMaskPosition.x - CANVAS_DIM / 2,
        top: newMaskPosition.y - CANVAS_DIM / 2,
      })
    }

    clipPath.set({
      dirty: true,
    })
  }
}
