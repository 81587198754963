import FontFaceObserver from "fontfaceobserver"
import _flatten from "lodash/flatten"
import { LOADABLE_FONTS_CONFIGS, FontDef } from "./fonts-loader.config"
import Colour from "../../value-objects/colour"

export enum FontSettings {
  fontStyle = "fontStyle",
  fontWeight = "fontWeight",
  fontFamily = "fontFamily",
  textAlign = "textAlign",
}

export enum FontStyle {
  italic = "italic",
  normal = "normal",
}

export enum FontAlign {
  alignLeft = "left",
  alignRight = "right",
  alignCenter = "center",
}

export enum FontWeight {
  bold = "bold",
  normal = "normal",
}

export enum FontVariant {
  bold = "bold",
  normal = "normal",
  italic = "italic",
  boldItalic = "boldItalic",
}

export type FontConfig = {
  bold: boolean
  italic: boolean
  boldItalic: boolean
}

export type FontParams = {
  style: FontStyle
  weight: FontWeight
}

export type FontFamilyConfig = {
  normal: boolean
  bold: boolean
  italic: boolean
  boldItalic: boolean
}

export type FontFamilyDefinition = {
  id: string
  name: string
  config: FontDef
}

export type FontSizeDefinition = {
  id: number
  name: number
}

export type FontShadow = {
  offsetX: number
  offsetY: number
  color?: Colour
}

export type FontStroke = {
  width: number
  color?: Colour
}

export class FontLoaderService {
  public preloadDesignFonts(
    fontsIds: string[]
  ): Promise<FontFamilyDefinition[]> {
    const configs = this.mapIdToConfigs(fontsIds)
    const arr = this.load(configs)
    return (
      Promise.all(arr)
        // eslint-disable-next-line no-unused-vars
        .then((results) => {
          return this.prepareFontFamiliesList(
            Object.assign({}, LOADABLE_FONTS_CONFIGS, configs)
          )
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          throw new Error("Fonts are not available")
        })
    )
  }

  public preloadAllFonts(): Promise<FontFamilyDefinition[]> {
    return (
      Promise.all(this.load(LOADABLE_FONTS_CONFIGS))
        // eslint-disable-next-line no-unused-vars
        .then((results) => {
          return this.prepareFontFamiliesList(
            Object.assign({}, LOADABLE_FONTS_CONFIGS)
          )
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          throw new Error("Fonts are not available")
        })
    )
  }

  private prepareFontFamiliesList(fontsWithSettings): FontFamilyDefinition[] {
    return Object.keys(fontsWithSettings)
      .map((fontFamily) => {
        return {
          id: fontFamily,
          name: fontFamily,
          config: {
            ...fontsWithSettings[fontFamily],
          },
        }
      })
      .sort((fontFamilyA, fontFamilyB) => {
        if (fontFamilyA.name < fontFamilyB.name) {
          return -1
        }
        if (fontFamilyA.name > fontFamilyB.name) {
          return 1
        }
        return 0
      })
  }

  private mapIdToConfigs(fontsIds: string[]) {
    const allFontsConfig = LOADABLE_FONTS_CONFIGS
    const currentFontsConfig = {}
    fontsIds
      .filter(
        (fontId) => Object.keys(LOADABLE_FONTS_CONFIGS).indexOf(fontId) > -1
      )
      .forEach((fontId) => {
        currentFontsConfig[fontId] = allFontsConfig[fontId]
      })
    return currentFontsConfig
  }

  private load(fontsToLoad): Promise<object>[] {
    return _flatten(
      Object.keys(fontsToLoad).map((fontFamilyName) => {
        const fontFamilyConfig: FontFamilyConfig = fontsToLoad[fontFamilyName]
        return Object.keys(fontFamilyConfig)
          .map((fontConfigParamName) => {
            if (fontFamilyConfig[fontConfigParamName]) {
              const font = new FontFaceObserver(
                fontFamilyName,
                this.mapFontConfigToParam(fontConfigParamName)
              )
              return font
            }
          })
          .filter((it) => !!it)
          .map((it) => {
            return it.load(null, 5000)
          })
      })
    ).map((p) => p.catch((e) => e))
  }

  public getSettingsForFont(fontFamily) {
    return Object.assign({}, LOADABLE_FONTS_CONFIGS)[fontFamily]
  }

  private mapFontConfigToParam(fontConfigParam): FontParams {
    if (fontConfigParam === FontVariant.boldItalic) {
      return {
        style: FontStyle.italic,
        weight: FontWeight.bold,
      }
    } else if (fontConfigParam === FontVariant.bold) {
      return {
        style: FontStyle.normal,
        weight: FontWeight.bold,
      }
    } else if (fontConfigParam === FontVariant.italic) {
      return {
        style: FontStyle.italic,
        weight: FontWeight.normal,
      }
    } else {
      return {
        style: FontStyle.normal,
        weight: FontWeight.normal,
      }
    }
  }
}
