var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BAG_CONSTS from "../../../../../source/bagProductSpec/generalConfig/consts/bagConsts";
import _kebabCase from "lodash/kebabCase";
var addPaperBagWeightsBehavior = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            return _super.call(this, props) || this;
        }
        class_1.prototype.getWeight = function () {
            var variantManager = this.product.variantManager;
            var productName = _kebabCase(variantManager.getName());
            var productCodeName = variantManager.getCodeName();
            switch (productName) {
                case BAG_CONSTS.SKU_MODEL_TOTE_BAG:
                    return this.getWeightBySkuPart(variantManager.getColorMode(), productCodeName);
                case BAG_CONSTS.SKU_MODEL_DOYPACK_BAG:
                case BAG_CONSTS.SKU_MODEL_PAPER_BAG_DEFAULT:
                    return this.getWeightBySkuPart(variantManager.getMaterial(), productCodeName);
            }
            return _super.prototype.getWeight.call(this);
        };
        class_1.prototype.getWeightBySkuPart = function (skuPart, productCodeName) {
            var skuPartKebabCase = _kebabCase(skuPart);
            if (!this.weights[skuPartKebabCase]) {
                return _super.prototype.getWeight.call(this);
            }
            return this.weights[skuPartKebabCase][productCodeName];
        };
        return class_1;
    }(superclass));
};
export default addPaperBagWeightsBehavior;
