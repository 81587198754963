import React from "react"
import cxBinder from "classnames/bind"

import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import {
  Section,
  SectionPickerProps,
  SectionProps,
} from "../utils/how-to.types"

import styles from "./SectionPicker.module.scss"

const cx = cxBinder.bind(styles)

const SectionLabel = ({ active, label, onClick }: SectionProps) => (
  <div
    className={cx("section", {
      "section--active": active,
    })}
    onClick={onClick}
  >
    <Typography type={active ? TType.Header17_500 : TType.Body17_350}>
      {label}
    </Typography>
  </div>
)

export const SectionPicker = ({
  onSectionClick,
  sections,
  selectedSection,
}: SectionPickerProps) => {
  const sectionProps = (section: Section) => ({
    active: selectedSection === section,
    label: section.label,
    onClick() {
      onSectionClick(section)
    },
  })

  return (
    <div className={styles["section-picker"]}>
      {sections.map((section) => (
        <SectionLabel key={section.label} {...sectionProps(section)} />
      ))}
    </div>
  )
}
