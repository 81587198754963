var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import _round from "lodash/round";
var outsideSize3DimensionsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getOutsideSizeDimensionFormatted = function (units) {
            if (units === void 0) { units = "cm"; }
            var precision = 2;
            var divisor = this.getDivisorByUnits(units);
            var outsideDimensions = this.getOutsideDimensions();
            return "".concat([
                _round(outsideDimensions.width / divisor, precision),
                _round(outsideDimensions.length / divisor, precision),
                _round(outsideDimensions.height / divisor, precision),
            ].join(" x "), " ").concat(units);
        };
        return class_1;
    }(superclass));
};
export default outsideSize3DimensionsBehaviors;
