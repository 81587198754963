import { AxiosInstance } from "axios"
import { DesignMetaPayload } from "../../types"

export class UserApi {
  constructor(private readonly api: AxiosInstance) {}

  public async assignDesign(payload: DesignMetaPayload): Promise<void> {
    await this.api.post("/users/me/designs", payload, {
      headers: {
        "Design-Token": payload.write_token,
      },
    })
  }

  public async duplicateDesign(): Promise<void> {
    return Promise.resolve()
  }

  public async updateDesign(): Promise<void> {
    return Promise.resolve()
  }
}
