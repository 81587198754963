import _flow from "lodash/flow";
import RenderConfigManager from "../../../behaviorsComposers/RenderConfigManger";
import getGeneralNonBoxRenderBehaviors from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/getGeneralNonBoxRenderBehaviors";
import addGetOffsetForSideBehavior from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/getOffsetForSide";
import addGetProductEditZonesBehaviors from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/getProductEditZones";
import addFinishDuplicationBehaviors from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/addFinishDuplicationBehaviors";
export default _flow([
    getGeneralNonBoxRenderBehaviors,
    addGetOffsetForSideBehavior,
    addGetProductEditZonesBehaviors,
    addFinishDuplicationBehaviors,
])(RenderConfigManager);
