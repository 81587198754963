import React from "react"
import { FormattedMessage } from "react-intl"

import { useRootStore } from "../../../hooks/useRootStore"
import { Button } from "dsl/src/atoms/Button/Button"

import styles from "./EditorHeaderDesignerModeActions.module.scss"
import { observer } from "mobx-react-lite"
import { isTemplateController } from "../../../../stores/_controllers/designer-mode/types"
import type { DesignerModeStore } from "../../../../stores/designer-mode-stores/designer-mode-store"
import { DesignerModeControllable } from "../../../../stores/_controllers/designer-mode/designer-mode-controllable.interface"
import { ProductRenderPilot } from "../../../../libs/products-render-config/product-render-pilot"
import { TemplateDesignerModeController } from "../../../../stores/_controllers/designer-mode/template-designer-mode-controller"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

const i18n = {
  saving: "box-editor.top.actions.label.saving",
  save: "box-editor.top.actions.label.save",
}

const DefaultActions = observer(
  ({
    designerModeStore,
    designerModeController,
  }: {
    designerModeStore: DesignerModeStore
    designerModeController: DesignerModeControllable
  }) => {
    const { uri } = useRootStore()
    const { isSaving } = designerModeStore

    const save = async () => {
      await designerModeController.saveAsTemplate()

      window.location.href = uri.getCmsCurrentAssetUrl()
    }

    return (
      <div className={styles.editor_header__menu}>
        <Button disabled={isSaving} onClick={save} e2eTargetName="save">
          <FormattedMessage id={isSaving ? i18n.saving : i18n.save} />
        </Button>
      </div>
    )
  }
)

const TemplateActions = observer(
  ({
    designerModeStore,
    designerModeController,
    productRenderPilot,
  }: {
    designerModeStore: DesignerModeStore
    designerModeController: TemplateDesignerModeController
    productRenderPilot: ProductRenderPilot
  }) => {
    const product = productRenderPilot.getProduct()
    const template = designerModeController.getTemplate()

    if (!product || !template || !template.isMatchMainProduct(product)) {
      return null
    }

    return (
      <DefaultActions
        designerModeStore={designerModeStore}
        designerModeController={designerModeController}
      />
    )
  }
)

export const EditorHeaderDesignerModeActions = observer(() => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.designerMode,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, designerMode } = containerSet

  const { productDriver } = designAndProductDriver
  const { productRenderPilot } = productDriver.state
  const editorMode = productRenderPilot.getEditorMode()

  if (
    editorMode !== "designer" ||
    !designerMode.available ||
    designerMode.designerModeStore.isLoading
  ) {
    return null
  }
  const { designerModeController, designerModeStore } = designerMode

  if (isTemplateController(designerModeController)) {
    return (
      <TemplateActions
        designerModeStore={designerModeStore}
        designerModeController={designerModeController}
        productRenderPilot={productRenderPilot}
      />
    )
  }

  return (
    <DefaultActions
      designerModeStore={designerModeStore}
      designerModeController={designerModeController}
    />
  )
})
