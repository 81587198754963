import { GetContainerFormat } from "iti"
import { RootStoreStarter } from "../stores/root.store-starter"
import { RootStore } from "../stores/root.store"
import type { ApiSessionContainer } from "./api-session-container"
import type { DesignAndProductDriverContainer } from "./design-and-product-driver-container"
import type { DesignerModeMaybeContainer } from "./designer-mode-maybe-container"
import type { EcommerceMaybeContainer } from "./ecommerce-maybe-container"
import type { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap-container"
import type { ReplicablePatternMaybeContainer } from "./replicable-patter-maybe-container"
import type { PatternMaybeContainer } from "./asset-pattern-maybe-container"
import type { UiContainer } from "./ui-container"
import type { FscCertificateMaybeContainer } from "./fsc-certificate-maybe-container"
import type { AssetsContainer } from "./assets-container"
import type { UtilEnvContainer } from "./util-container"
import type { TemplatesMaybeContainer } from "./templates-maybe-container"

export async function provideClassicRootStoreContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  rootBootstrapper: BootstrapClassicRootStoreContainer,
  designAndProduct: DesignAndProductDriverContainer,
  designerModeContainer: DesignerModeMaybeContainer,
  ecommerceContainer: EcommerceMaybeContainer,
  assetsContainer: AssetsContainer,
  replicablePatterns: ReplicablePatternMaybeContainer,
  patternMaybe: PatternMaybeContainer,
  fscCertificate: FscCertificateMaybeContainer,
  uiContainer: UiContainer,
  templates: TemplatesMaybeContainer
) {
  const { sessionService, sessionStore, user } = apiSessionContainer
  const { uri, appConfig } = utilEnvContainer
  const { productRenderPilot } = rootBootstrapper
  const {
    assetStore,
    productDesignStore,
    productDriver,
    loadProductDesignController,
  } = designAndProduct
  const { fontsConfigStore } = uiContainer
  const { cart } = ecommerceContainer
  const editorMode = productRenderPilot.getEditorMode()

  const rootStore = new RootStore(
    sessionService,
    sessionStore,
    editorMode,
    uri,
    designAndProduct,
    designerModeContainer,
    assetsContainer,
    utilEnvContainer
  )

  const rootStoreStarter = new RootStoreStarter(
    appConfig,
    editorMode,
    uri,
    assetStore,
    productDriver,
    productDesignStore,
    loadProductDesignController,
    fontsConfigStore,
    ecommerceContainer,
    patternMaybe,
    fscCertificate,
    templates,
    rootStore
  )
  rootStoreStarter.launch({ user, cart })

  return {
    rootStore,
  }
}

export type ClassicRootStoreContainer = GetContainerFormat<
  typeof provideClassicRootStoreContainer
>
