import React from "react"
import { useTranslate } from "../../../hooks/useTranslate"
import { useCurrency } from "../../../hooks/useCurrency"
import { CheckboxFormBlock } from "../CheckboxFormBlock/CheckboxFormBlock"
import { TM } from "../../../TypographyI18n"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { I18N } from "../../../i18n"
import { Button, ButtonModes } from "dsl/src/atoms/Button"
import Link from "dsl/src/atoms/Link/Link"

const i18n = I18N.editorTools.fsc.addFscFormBlock

export const AddFscFormBlock = ({
  isChecked,
  isDisabled,
  onChange,
  price,
  onButtonClick,
  learnMoreUrl,
}: {
  isChecked: boolean
  isDisabled?: boolean
  onChange: () => void
  price: number
  onButtonClick?: () => void
  learnMoreUrl?: string
}) => {
  const t = useTranslate()
  const currency = useCurrency()

  return (
    <CheckboxFormBlock
      isChecked={isChecked}
      isDisabled={isDisabled}
      onChange={onChange}
      title={t(i18n.title)}
      price={price}
      currency={currency}
      e2eTargetName="fsc-checkbox"
      footer={
        onButtonClick && (
          <Button mode={ButtonModes.secondary} fluid onClick={onButtonClick}>
            {t(i18n.addToDesign)}
          </Button>
        )
      }
    >
      <TM id={i18n.description} type={TType.Body15_350} htmlElement="p" />
      {learnMoreUrl && (
        <Typography type={TType.Body15_350} htmlElement="p">
          <Link href={learnMoreUrl} target="_blank">
            {t(i18n.learnMore)}
          </Link>
        </Typography>
      )}
    </CheckboxFormBlock>
  )
}
