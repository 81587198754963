import React from "react"
import cxBinder from "classnames/bind"
import styles from "./Thumb.module.scss"
const cx = cxBinder.bind(styles)

export enum SizeEnumType {
  Small = "small",
  MediumSmall = "medium-small",
  Medium = "medium",
  MediumBig = "medium-big",
  Big = "big",
  Large = "large",
  MediumLarge = "medium-large",
  XLarge = "xlarge",
  XXLarge = "xxlarge"
}

interface ThumbProps {
  size?: SizeEnumType
  round?: boolean
  active?: boolean
  src?: string
  alt?: string
  children?: React.ReactNode
  overlay?: React.ReactNode
  applyMonochromeFilter?: boolean
  noHover?: boolean
  blured?: boolean
}

const Thumb = (props: ThumbProps) => {
  const {
    children,
    size,
    round,
    src,
    alt,
    active,
    overlay,
    applyMonochromeFilter,
    noHover,
    blured,
    ...other
  } = props

  let element: React.ReactNode | null = null

  if (src) {
    element = (
      <>
        <figure className={cx("image-wrapper")}>
          <img
            src={src}
            alt={alt}
            className={cx("image", {
              "image--monochrome": applyMonochromeFilter
            })}
            {...other}
          />
        </figure>
        {overlay && <div className={styles["image-overlay"]}>{overlay}</div>}
      </>
    )
  } else {
    element = (
      <div
        className={cx("image-wrapper", {
          "image-wrapper--placeholder": !children
        })}
        {...other}
      >
        {children}
      </div>
    )
  }

  return (
    <div
      className={cx({
        wrapper: true,
        "wrapper--round": round,
        "wrapper--small": size === SizeEnumType.Small,
        "wrapper--medium-small": size === SizeEnumType.MediumSmall,
        "wrapper--medium": size === SizeEnumType.Medium,
        "wrapper--big": size === SizeEnumType.Big,
        "wrapper--medium-big": size === SizeEnumType.MediumBig,
        "wrapper--large": size === SizeEnumType.Large,
        "wrapper--medium-large": size === SizeEnumType.MediumLarge,
        "wrapper--xlarge": size === SizeEnumType.XLarge,
        "wrapper--xxlarge": size === SizeEnumType.XXLarge,
        "wrapper--active": active,
        "wrapper--no-hover": noHover,
        "wrapper--blured": blured
      })}
    >
      {element}
    </div>
  )
}

export { Thumb, Thumb as default }
