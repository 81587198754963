var _a;
import PAPER_MAILER_CONSTS from "../generalConfig/consts/paperMailerConsts";
var PAPER_MAILER_PRODUCTS_EDITOR_LIMITATIONS = {
    shared: {
        initialActiveSide: {
            activeSideId: "front",
            editedSideId: "front",
        },
        isPantoneColorsAvailable: true,
        sidesNameCodesMap: {
            front: "A",
            back: "B",
        },
        isSafeZoneLight: {
            DEFAULT: false,
        },
        showSideExtraPrice: {
            back: true,
        },
        reforestationSupport: {
            badge: true,
            trees: true,
        },
    },
    products: (_a = {},
        _a[PAPER_MAILER_CONSTS.PAPER_MAILER] = {
            productEditorMode: "2D",
        },
        _a),
};
export default PAPER_MAILER_PRODUCTS_EDITOR_LIMITATIONS;
