import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Proscenium } from "./proscenium/Proscenium"
import { Curtain } from "./curtain/Curtain"
import { ModalProvider } from "../dsl/organisms/Modal/Modal"
import useTitle from "../hooks/useTitle"
import { useFeatureFlagWithPooling } from "../hooks/useFeatureFlagWithPooling"
import { useGlobalKeyboardShortcuts } from "../hooks/useGlobalKeyboardShortcuts"
import styles from "./Theater.module.scss"
import { useTranslate } from "../hooks/useTranslate"
import { EnablePrintingOptionModal } from "../dsl/organisms/Modal/enable-printing-option-modal/enable-printing-option-modal"
import { useContainerSet } from "../../_containers-react/_editor-app-hooks"

export enum ViewMode {
  Editor,
  Preview,
  DesignInCart,
}

export const Theater = observer(() => {
  const t = useTranslate()
  const [containerSet, containerSetErrors] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ecommerce,
    c.envUtil,
    c.analytics,
  ])

  useTitle(t("editor.document.title"))
  const { handleGlobalKeyboardShortcuts } = useGlobalKeyboardShortcuts()

  const [
    shouldEnableErrorSegmentNotifications,
    isFlagSegmentNotificationReady,
  ] = useFeatureFlagWithPooling<boolean>(
    "shouldEnableErrorSegmentNotifications"
  )

  useEffect(() => {
    const segmentErrorNotificator =
      containerSet?.analytics.segmentErrorNotificator

    if (!segmentErrorNotificator) {
      return
    }

    if (
      !segmentErrorNotificator.contextsAdded &&
      isFlagSegmentNotificationReady &&
      shouldEnableErrorSegmentNotifications
    ) {
      segmentErrorNotificator.addContextListeners()
    } else if (
      segmentErrorNotificator.contextsAdded &&
      isFlagSegmentNotificationReady &&
      !shouldEnableErrorSegmentNotifications
    ) {
      segmentErrorNotificator.removeContextListeners()
    }

    return () => {
      segmentErrorNotificator.removeContextListeners()
    }
  }, [isFlagSegmentNotificationReady])

  if (containerSet == null || containerSetErrors != null) return null

  const { designAndProductDriver, ecommerce, envUtil } = containerSet
  const { uri } = envUtil

  const { productDesignStore, productDriver } = designAndProductDriver

  const { is404, isUnauthorized } = productDesignStore.state

  const routerWillLeave = () => {}

  window.onbeforeunload = routerWillLeave

  return (
    <ModalProvider>
      <div
        className={styles.wrapper}
        onKeyUpCapture={handleGlobalKeyboardShortcuts}
      >
        <Curtain>
          <Proscenium
            is404={is404}
            isUnauthorized={isUnauthorized}
            ecommerce={ecommerce}
            uri={uri}
            productDriver={productDriver}
          />
        </Curtain>

        <EnablePrintingOptionModal />
        {
          /**
           * DO NOT remove, this is a VERY helpfull debug
           * feature.
           */
          // rootStore.productDriver.state.renderEngine && (
          //   <div
          //     style={{
          //       position: "absolute",
          //       width: "1000px",
          //       right: "-200px",
          //       top: "200px",
          //     }}
          //   >
          //     <div>{rootStore.productDriver.state.renderEngine.thumbCount}</div>
          //     <img
          //       style={{ width: "100%", border: "1px dashed plum" }}
          //       src={rootStore.productDriver.state.renderEngine.baseThumb}
          //     />
          //      <img
          //       id="thumbPreview"
          //       style={{ width: "100%", border: "1px dashed plum" }}
          //     />
          //   </div>
          // )
        }
      </div>
    </ModalProvider>
  )
})
