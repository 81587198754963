var _a, _b, _c, _d, _e, _f, _g;
import NCP_CONSTS from "../consts/ncpConsts";
var assetsPath = "/product-key-visuals/";
var GLOBAL_ASSETS = Object.assign({}, { colorModesImagesPaths: {} }, { materialImagesPaths: {} });
var NCP_PRODUCT_ASSETS = (_a = {},
    _a[NCP_CONSTS.WOOD_WOOL] = (_b = {},
        _b[NCP_CONSTS.WOOD_WOOL_VARIANT] = {
            keyVisual: [assetsPath + "wood_wool_desktop.png"],
            sizeAssets: {},
        },
        _b),
    _a[NCP_CONSTS.SELF_ADHESIVE_ENVELOPE] = (_c = {},
        _c[NCP_CONSTS.SELF_ADHESIVE_ENVELOPE_VARIANT] = {
            keyVisual: [assetsPath + "self_adhesive_envelope_desktop.png"],
            sizeAssets: {},
        },
        _c),
    _a[NCP_CONSTS.STICKER_CIRCLE] = (_d = {},
        _d[NCP_CONSTS.STICKER_CIRCLE_VARIANT] = {
            keyVisual: [assetsPath + "stickers.png"],
            sizeAssets: {},
        },
        _d),
    _a[NCP_CONSTS.STICKER_RECTANGLE] = (_e = {},
        _e[NCP_CONSTS.STICKER_RECTANGLE_VARIANT] = {
            keyVisual: [assetsPath + "stickers.png"],
            sizeAssets: {},
        },
        _e),
    _a[NCP_CONSTS.THANK_YOU_CARD] = (_f = {},
        _f[NCP_CONSTS.THANK_YOU_CARD_VARIANT] = {
            keyVisual: [assetsPath + "thank_you_card.png"],
            sizeAssets: {},
        },
        _f),
    _a[NCP_CONSTS.PAPER_BUBBLE_WRAP] = (_g = {},
        _g[NCP_CONSTS.PAPER_BUBBLE_WRAP_VARIANT] = {
            keyVisual: [assetsPath + "paper_bubble_wrap.png"],
            sizeAssets: {},
        },
        _g),
    _a);
var productAssets = {
    globalAssets: GLOBAL_ASSETS,
    productAssets: NCP_PRODUCT_ASSETS,
};
export default productAssets;
