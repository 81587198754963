import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { CardTool } from "../../atoms/CardTool/CardTool"
import {
  ChangeTemplateModal,
  ChangeTemplateModalMode,
} from "../../organisms/Modal/change-template/ChangeTemplateModal"
import { TemplateDesignItem } from "../../../../modules/predefined-assets/template-asset"
import { TemplateCard } from "../TemplateCard/TemplateCard"
import { TemplateSkeletonCard } from "../TemplateCard/TemplateSkeletonCard"
import { TemplateEmptyCard } from "../TemplateCard/TemplateEmptyCard"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

interface EditorToolTemplatesProps {
  isLoadingSingleTemplate: boolean
  isLoadingTemplates: boolean
  onSelectTemplate: (templateId: number) => void
  onRemoveTemplate: () => void
  templates: TemplateDesignItem[]
  selectedTemplateId: number | null
}

export const EditorToolTemplates = ({
  isLoadingSingleTemplate,
  isLoadingTemplates,
  onSelectTemplate,
  onRemoveTemplate,
  templates,
  selectedTemplateId,
}: EditorToolTemplatesProps) => {
  return (
    <CardTool sizeXs cardName="templates">
      {isLoadingTemplates ? (
        <>
          <TemplateSkeletonCard />
          <TemplateSkeletonCard />
          <TemplateSkeletonCard />
        </>
      ) : (
        <>
          <TemplateEmptyCard
            isSelected={selectedTemplateId === null}
            onSelect={!isLoadingSingleTemplate ? onRemoveTemplate : () => {}}
            inactive={isLoadingSingleTemplate}
          />

          {templates.map((template) => (
            <TemplateCard
              key={template.id}
              template={template}
              isSelected={template.id === selectedTemplateId}
              onSelect={
                !isLoadingSingleTemplate
                  ? () => onSelectTemplate(template.id)
                  : () => {}
              }
              inactive={isLoadingSingleTemplate}
            />
          ))}
        </>
      )}
    </CardTool>
  )
}

export const EditorToolTemplatesWired = observer(() => {
  const [selectedTemplateIdCandidate, setSelectedTemplateIdCandidate] =
    useState<number | null>(null)
  const [changeTemplateModalMode, setChangeTemplateModalMode] =
    useState<ChangeTemplateModalMode | null>(null)

  const [containerSet] = useContainerSet((c) => [
    c.templates,
    c.logo,
    c.analytics,
    c.designAndProductDriver,
  ])

  if (!containerSet || !containerSet.templates.available) {
    return null
  }

  const { templates, logo, analytics, designAndProductDriver } = containerSet
  const { logoStore } = logo
  const { templatesController, templatesStore } = templates
  const { productDriver } = designAndProductDriver

  const onSelectTemplate = (templateId: number) => {
    if (templatesStore.isTemplateSafeToSet) {
      templatesController.selectTemplate(templateId)
    } else {
      setSelectedTemplateIdCandidate(templateId)
      setChangeTemplateModalMode(
        templatesStore.selectedTemplateId ? "changeTemplate" : "applyTemplate"
      )
    }
  }

  const onRemoveTemplate = () => {
    if (templatesStore.isTemplateSafeToSet) {
      templatesController.removeTemplate()
    } else {
      setChangeTemplateModalMode("removeTemplate")
    }
  }

  const onChangeTemplateModalClose = () => {
    setSelectedTemplateIdCandidate(null)
    setChangeTemplateModalMode(null)

    if (selectedTemplateIdCandidate) {
      analytics.analyticsController?.trackTemplatePopUpClicked("change", "no")
    } else {
      analytics.analyticsController?.trackTemplatePopUpClicked("remove", "no")
    }
  }

  const onChangeTemplateModalConfirm = () => {
    if (selectedTemplateIdCandidate) {
      templatesController.selectTemplate(selectedTemplateIdCandidate!)
      analytics.analyticsController?.trackTemplatePopUpClicked("change", "yes")
    } else {
      templatesController.removeTemplate()
      analytics.analyticsController?.trackTemplatePopUpClicked("remove", "yes")
    }

    setSelectedTemplateIdCandidate(null)
    setChangeTemplateModalMode(null)
  }

  return (
    <>
      <EditorToolTemplates
        isLoadingSingleTemplate={
          templatesStore?.isLoadingSingleTemplate ||
          logoStore.isLoading ||
          productDriver.state.isRendererLoading
        }
        isLoadingTemplates={templatesStore?.isLoadingTemplates}
        onSelectTemplate={onSelectTemplate}
        templates={templatesStore.templates}
        selectedTemplateId={templatesStore.selectedTemplateId}
        onRemoveTemplate={onRemoveTemplate}
      />
      {!!changeTemplateModalMode && (
        <ChangeTemplateModal
          onClose={onChangeTemplateModalClose}
          onConfirm={onChangeTemplateModalConfirm}
          mode={changeTemplateModalMode}
        />
      )}
    </>
  )
})
