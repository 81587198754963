import Link from "dsl/src/atoms/Link/Link"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import {
  Notification,
  NotificationVariant,
} from "../../../dsl/atoms/Notification/Notification"
import { useTranslate } from "../../../hooks/useTranslate"
import { I18N } from "../../../i18n"
import styles from "./SceneUIRightMessageController.module.scss"

export const SceneUIRightMessageController = observer(() => {
  const t = useTranslate()
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.envUtil,
  ])

  useEffect(() => {
    if (!containerSet) {
      return
    }

    const { notificationStore } = containerSet.envUtil

    const notificationDisplay = setTimeout(() => {
      notificationStore.hideNotification()
      // Display time of each notification
    }, 3000)
    return () => clearTimeout(notificationDisplay)
  }, [containerSet?.envUtil.notificationStore.notifications.length])

  useEffect(() => {
    if (!containerSet) {
      return
    }

    const { notificationStore } = containerSet.envUtil

    if (!notificationStore.uniqueNotificationStates.productSwitched) {
      return
    }

    const notificationDisplay = setTimeout(() => {
      notificationStore.toggleUniqueNotification({
        name: "productSwitched",
        isVisible: false,
      })
    }, 8000)

    return () => clearTimeout(notificationDisplay)
  }, [
    containerSet?.envUtil.notificationStore.uniqueNotificationStates
      .productSwitched,
  ])

  if (!containerSet) {
    return null
  }

  const {
    envUtil: { notificationStore, uri },
    designAndProductDriver: {
      productDriver: {
        state: { isZoomActive, productRenderPilot },
      },
    },
  } = containerSet

  const { notifications, uniqueNotificationStates } = notificationStore

  // copy and reverse
  const notificationsReversed = notifications.slice().reverse()
  const zoomConfig = productRenderPilot.uiConfig.editZone.zoom
  const hasZoomNotification = !isZoomActive && zoomConfig.notificationIntl

  if (
    notificationsReversed.length === 0 &&
    !hasZoomNotification &&
    !uniqueNotificationStates.productSwitched
  ) {
    return null
  }

  const handleGoBackClick = (): void => {
    const designId = uri.getPreviousDesignId()

    uri.redirectToDesign(designId!)
  }

  return (
    <div className={styles.notifications_wrapper}>
      {uniqueNotificationStates.productSwitched && (
        <Notification variant={NotificationVariant.warning} plain wide>
          <div className={styles.product_switched}>
            <div>{t(I18N.notifications.productSwitched.productChanged)}</div>
            <div>
              <Link onClick={handleGoBackClick}>
                {t(I18N.notifications.productSwitched.goBackLink)}
              </Link>
              {t(I18N.notifications.productSwitched.goBackTo)}
            </div>
          </div>
        </Notification>
      )}
      {notificationsReversed.map(({ variant, message }, index) => (
        <Notification key={index} halfWidth variant={variant}>
          {message}
        </Notification>
      ))}
      {hasZoomNotification && (
        <Notification halfWidth variant={NotificationVariant.info}>
          {t(zoomConfig.notificationIntl!)}
        </Notification>
      )}
    </div>
  )
})
