var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addPolyMailerAddonsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getAvailableMaterials = function () {
            var availableMaterials = _super.prototype.getAvailableMaterials.call(this);
            var materialImagesPaths = this.product.assetsManager.getGlobalInProductAssets().materialImagesPaths;
            return availableMaterials.map(function (material) {
                return Object.assign({}, material, {
                    pic: materialImagesPaths[material.id],
                    name: "product-config.paperBag.material.".concat(material.id, ".name"),
                });
            });
        };
        class_1.prototype.getAvailableColorModes = function () {
            var availableColorModes = _super.prototype.getAvailableColorModes.call(this);
            var colorModesImagesPaths = this.product.assetsManager.getGlobalInProductAssets().colorModesImagesPaths;
            return availableColorModes.map(function (colorMode) {
                return Object.assign({}, colorMode, {
                    icon: colorModesImagesPaths[colorMode.id],
                    name: "product-config.paperBag.colorMode.".concat(colorMode.id, ".name"),
                });
            });
        };
        return class_1;
    }(superclass));
};
export default addPolyMailerAddonsBehaviors;
