import React, { PropsWithChildren } from "react"
import cxBinder from "classnames/bind"

import { TType, Typography } from "dsl/src/atoms/Typography/Typography"

import styles from "./Notification.module.scss"
const cx = cxBinder.bind(styles)

export enum NotificationVariant {
  success = "success",
  error = "error",
  info = "info",
  warning = "warning",
  black = "black",
  white = "white",
}

export interface EditorNotification {
  message: string
  variant: NotificationVariant
}

interface NotificationProps {
  variant: NotificationVariant
  halfWidth?: boolean
  textCenter?: boolean
  e2eTarget?: string
  e2eTargetName?: string
  plain?: boolean
  wide?: boolean
}

export const Notification = ({
  halfWidth,
  textCenter,
  variant,
  children,
  e2eTarget = "notification",
  e2eTargetName = variant,
  plain = false,
  wide = false,
}: PropsWithChildren<NotificationProps>) => (
  <div
    className={cx({
      notification: true,
      textCenter,
      [variant]: true,
      [variant + "_xs"]: halfWidth,
      plain,
      wide,
    })}
    e2e-target={e2eTarget}
    e2e-target-name={e2eTargetName}
  >
    <Typography type={TType.Header15_500}>{children}</Typography>
  </div>
)
