export default {
    P19: {
        inside: {
            width: 75,
            length: 75,
            height: 27,
        },
        outside: {
            width: 80,
            length: 80,
            height: 29,
        },
    },
    P27: {
        inside: {
            width: 110,
            length: 110,
            height: 46,
        },
        outside: {
            width: 115,
            length: 115,
            height: 48,
        },
    },
    P28: {
        inside: {
            width: 150,
            length: 64,
            height: 57,
        },
        outside: {
            width: 155,
            length: 69,
            height: 59,
        },
    },
    P37: {
        inside: {
            width: 165,
            length: 165,
            height: 22,
        },
        outside: {
            width: 170,
            length: 170,
            height: 24,
        },
    },
    P40: {
        inside: {
            width: 164,
            length: 164,
            height: 78,
        },
        outside: {
            width: 169,
            length: 169,
            height: 80,
        },
    },
    P41: {
        inside: {
            width: 240,
            length: 109,
            height: 45,
        },
        outside: {
            width: 245,
            length: 114,
            height: 47,
        },
    },
};
