import React from "react"
import { observer } from "mobx-react-lite"
import { useDropzone } from "react-dropzone"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { Upload } from "../../Upload/Upload"
import { UploadDropZone } from "../../Upload/UploadDropzone"
import { StateLogoUploaded } from "../../Upload/ImageUpload"
import { AssetsUploaderFileSpec } from "../../Upload/UploadFiletypesInfo"
import { UploadFailedFilePreview } from "../../Upload/UploadFailedFilePreview"
import { getTranslationKeyForAssetError } from "../../../../../services/asset-service/asset-errors"

import styles from "./EditorToolReplicablePatterns.module.scss"
import { ReplicablePatternsStore } from "../../../../../stores/replicable-patterns-store/replicable-patterns.store"
import { ReplicablePatternUiController } from "../../../../../stores/_controllers/replicable-pattern-ui-controller"

export const ArtworkTab = observer(
  ({
    replicablePatternsStore,
    replicablePatternUiController,
  }: {
    replicablePatternsStore: ReplicablePatternsStore
    replicablePatternUiController: ReplicablePatternUiController
  }) => {
    const t = useTranslate()

    const { getRootProps, getInputProps } = useDropzone({
      onDrop: (acceptedFiles: File[]) => {
        replicablePatternUiController!.selectArtwork(acceptedFiles[0])
      },
      multiple: false,
    })

    if (!replicablePatternUiController || !replicablePatternsStore) {
      return null
    }

    const patternImageUrl = replicablePatternsStore.imageUrl
    const patternImageError = replicablePatternsStore.imageError
    const isUploadedState = !!(
      !replicablePatternsStore.isArtworkLoading && patternImageUrl
    )

    return (
      <>
        <UploadDropZone
          key={Number(isUploadedState)}
          inputProps={getInputProps()}
          rootProps={getRootProps()}
        >
          {isUploadedState ? (
            <div className={styles.wrapper}>
              <StateLogoUploaded
                imageURL={patternImageUrl}
                onClick={() => {}}
                caption="pattern"
              />
            </div>
          ) : (
            <Upload isUploading={replicablePatternsStore.isArtworkLoading} />
          )}
        </UploadDropZone>

        {replicablePatternsStore.isArtworkReady && (
          <>
            {patternImageUrl && (
              <div className={styles.container}>
                <UploadDropZone
                  inputProps={getInputProps()}
                  rootProps={getRootProps()}
                >
                  <Typography type={TType.Header15_500} className={styles.link}>
                    {t(I18N.editorTools.patterns.uploadNew)}
                  </Typography>
                </UploadDropZone>

                <div
                  onClick={() => {
                    replicablePatternUiController.removeArtwork()
                  }}
                >
                  <Typography type={TType.Header15_500} className={styles.link}>
                    {t(I18N.editorTools.patterns.remove)}
                  </Typography>
                </div>
              </div>
            )}
          </>
        )}
        <AssetsUploaderFileSpec />
        {patternImageError && (
          <UploadFailedFilePreview
            name=""
            error={t(getTranslationKeyForAssetError(patternImageError))}
            onRemove={() => {
              replicablePatternsStore.setImageError(undefined)
            }}
          />
        )}
      </>
    )
  }
)
