import React, { PropsWithChildren } from "react"

import { EditorToolbarItem } from "../../../../../stores/editor-toolbar-store"

import styles from "./EditorToolbar.module.scss"
import {
  EditContext,
  ModelEditableSpaces,
} from "../../../../../libs/products-render-config/types"

export interface MenuItem {
  name: EditorToolbarItem
  preventActiveState?: boolean
  nameIntl?: string
  Icon: React.ElementType
  IconActive?: React.ElementType
  visibility: {
    product2D: boolean | (EditContext | ModelEditableSpaces)[]
    product3D:
      | false
      | {
          spaceView: boolean
          dielineView: boolean | EditContext[]
          modelView: boolean
        }
  }
  topSeparator?: boolean
  devTools?: boolean
  hideTitle?: boolean
  subItems?: EditorToolbarItem[]
}

export const EditorToolbar = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<{}>
>((props, ref) => (
  <div className={styles.editor_toolbar} ref={ref}>
    <ul className={styles.editor_toolbar_list}>{props.children}</ul>
  </div>
))
