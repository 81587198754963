import React from "react"
import { observer } from "mobx-react-lite"

import { CardTool } from "../../atoms/CardTool/CardTool"

import { FontFamilyDefinition } from "../../../../libs/services/fonts-loader-service/fonts-loader.service"
import { Fonts } from "../../../../libs/services/fonts-loader-service/fonts-loader.config"

import { TextObjectController } from "../../../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/text-object-controller"
import { FontList } from "../FontList/FontList"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"

export const EditorToolFontsWired = observer(() => {
  const [designAndProductDriverContainer] =
    useContainer().designAndProductDriver

  if (!designAndProductDriverContainer) {
    return null
  }
  const { productDriver } = designAndProductDriverContainer
  const activeObjectController = productDriver.activeObjectDriver
    .activeObjectController as TextObjectController

  if (!activeObjectController) {
    return null
  }

  const activeObjectFontFamily =
    productDriver.activeObjectDriver.activeObjectComputable.fontFamily

  const updateActiveObject = (fontFamilyDefinition: FontFamilyDefinition) => {
    activeObjectController.setStyles({
      fontFamily: fontFamilyDefinition.id as Fonts,
    })
  }

  return (
    <div>
      <CardTool sizeXs cardName="fonts">
        <FontList
          onClick={updateActiveObject}
          selectedFontFamily={activeObjectFontFamily}
        />
      </CardTool>
    </div>
  )
})
