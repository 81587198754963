var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import LABEL_CONST from "../../../../../source/labelProductSpec/generalConfig/consts/labelConsts";
import { getOldVariantCodeName } from "../../../../../definitions/products/newProductVariantsToOld";
import { ProductType } from "../../../types";
import ProductConfigFactory from "../../../ProductFactory";
import LabelProductVariant from "../../../../../source/labelProductSpec/generalConfig/consts/ProductVariant";
import _kebabCase from "lodash/kebabCase";
import _sortedIndex from "lodash/sortedIndex";
var addLabelVariantBehaviors = function (superclass) {
    function findVariant(productName) {
        if (productName === LABEL_CONST.CIRCLE_LABEL) {
            return LABEL_CONST.CIRCLE_LABEL_VARIANT;
        }
        else if (productName === LABEL_CONST.SQUARE_LABEL) {
            return LABEL_CONST.SQUARE_LABEL_VARIANT;
        }
        else if (productName === LABEL_CONST.RECTANGLE_LABEL) {
            return LABEL_CONST.RECTANGLE_LABEL_VARIANT;
        }
        else {
            return LABEL_CONST.RECTANGLE_VARIANT;
        }
    }
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        class_1.prototype.getVariant = function () {
            var productName = this.getName();
            return findVariant(productName);
        };
        class_1.findVariant = function (productName) {
            return findVariant(productName);
        };
        class_1.prototype.getVariantStructure = function () {
            return this.variant.variants[this.getVariant()];
        };
        class_1.prototype.getVariantDashed = function () {
            return getOldVariantCodeName(this.getVariant());
        };
        class_1.prototype.getProductType = function () {
            return ProductType.Custom;
        };
        class_1.prototype.getEditorRelatedProducts = function () {
            var _a;
            var _this = this;
            var shapes = [
                LabelProductVariant.SQUARE_LABEL_VARIANT,
                LabelProductVariant.RECTANGLE_LABEL_VARIANT,
                LabelProductVariant.CIRCLE_LABEL_VARIANT,
            ];
            var defaultSize = (_a = {},
                _a[LabelProductVariant.SQUARE_LABEL_VARIANT] = 5,
                _a[LabelProductVariant.RECTANGLE_LABEL_VARIANT] = 7,
                _a[LabelProductVariant.CIRCLE_LABEL_VARIANT] = 2,
                _a);
            return shapes.map(function (shape) {
                var isCurrent = shape === _this.getName();
                var sku = _this.buildSku({
                    name: _kebabCase(shape),
                    material: "paper-mid-gloss",
                    colorMode: "print-indigo-color",
                    finish: "none",
                    size: isCurrent ? _this.getSize() : defaultSize[shape],
                });
                var defaultProduct = ProductConfigFactory(sku);
                if (isCurrent) {
                    return _this.mapToEditorRelatedProduct(defaultProduct);
                }
                var allSizes = defaultProduct.variantManager.getDefaultSizesArr();
                var newSizeIndex = _sortedIndex(allSizes.map(function (size) { return parseInt(size); }), parseInt(_this.getSize()));
                if (newSizeIndex >= allSizes.length) {
                    newSizeIndex -= 1;
                }
                var newSize = allSizes[newSizeIndex];
                return _this.mapToEditorRelatedProduct(ProductConfigFactory(defaultProduct.variantManager.buildSku({
                    size: newSize,
                })));
            });
        };
        return class_1;
    }(superclass));
};
export default addLabelVariantBehaviors;
