import React from "react"
import { CardNotification } from "../card-notification/card-notification"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { ReactComponent as InfoIcon } from "../../../assets/svg/info.svg"

type InfoBoxProps = {
  text: string
}

export const InfoBox = ({ text }: InfoBoxProps) => {
  return (
    <CardNotification Icon={InfoIcon}>
      <Typography type={TType.Body13_350}>{text}</Typography>
    </CardNotification>
  )
}
