import React from "react"

import { SectionsProps } from "../utils/how-to.types"

import styles from "./Sections.module.scss"

export const Sections = ({ selectedSection }: SectionsProps) => (
  <div
    className={styles.container}
    dangerouslySetInnerHTML={{ __html: selectedSection.content }}
  />
)
