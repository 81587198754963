import React from "react"

import styles from "./ModalTemplate.module.scss"

interface ModalTemplateProps {
  children: React.ReactNode
  header: React.ReactNode
  footer: React.ReactNode
}

export const ModalTemplate = (props: ModalTemplateProps) => {
  const { children, header, footer } = props
  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.header}>{header}</div>
        <div className={styles.body}>{children}</div>
      </div>
      <div className={styles.footer}>{footer}</div>
    </div>
  )
}
