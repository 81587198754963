import React from "react"
import Guidelines, { GuidelinesProps } from "./Guidelines"
import { InnerSize, Modal } from "../Modal"

const GuidelinesModal = ({ onClose, productRenderPilot }: GuidelinesProps) => (
  <Modal
    onClose={onClose}
    maxWidth={InnerSize.default}
    e2eTargetName="guidelines"
  >
    <Guidelines onClose={onClose} productRenderPilot={productRenderPilot} />
  </Modal>
)

export { GuidelinesModal as default }
