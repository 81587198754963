var _a, _b, _c, _d;
import CARD_CONST from "../generalConfig/consts/cardConsts";
var PRINT_MATERIAL_WHITE_MATT = [
    {
        uid: 1,
        pantoneName: "010 White",
        color: "#FFFFFF",
        description: "box-editor.pantone-picker.color-descriptions.white",
    },
    {
        uid: 2,
        pantoneName: "Yellow 012 C",
        color: "#FFD719",
        description: "box-editor.pantone-picker.color-descriptions.yellow",
    },
    {
        uid: 3,
        pantoneName: "Black C",
        color: "#222223",
        description: "box-editor.pantone-picker.color-descriptions.black",
    },
    {
        uid: 4,
        pantoneName: "Orange 021 C",
        color: "#FF4700",
        description: "box-editor.pantone-picker.color-descriptions.orange",
    },
    {
        uid: 5,
        pantoneName: "212 C",
        color: "#DB4A82",
        description: "box-editor.pantone-picker.color-descriptions.pink",
    },
    {
        uid: 6,
        pantoneName: "Red 032 C",
        color: "#D8383C",
        description: "box-editor.pantone-picker.color-descriptions.red",
    },
    {
        uid: 7,
        pantoneName: "7738 C",
        color: "#45A53F",
        description: "box-editor.pantone-picker.color-descriptions.green",
    },
    {
        uid: 8,
        pantoneName: "Process Blue C",
        color: "#0078B0",
        description: "box-editor.pantone-picker.color-descriptions.blue",
    },
    {
        uid: 9,
        pantoneName: "Violet C",
        color: "#38276D",
        description: "box-editor.pantone-picker.color-descriptions.purple",
    },
];
var PRINT_MATERIAL_KRAFT = [
    {
        uid: 2,
        pantoneName: "Yellow 012 C",
        color: "#FFD719",
        description: "box-editor.pantone-picker.color-descriptions.yellow",
    },
    {
        uid: 3,
        pantoneName: "Black C",
        color: "#222223",
        description: "box-editor.pantone-picker.color-descriptions.black",
    },
    {
        uid: 4,
        pantoneName: "Orange 021 C",
        color: "#FF4700",
        description: "box-editor.pantone-picker.color-descriptions.orange",
    },
    {
        uid: 5,
        pantoneName: "212 C",
        color: "#DB4A82",
        description: "box-editor.pantone-picker.color-descriptions.pink",
    },
    {
        uid: 6,
        pantoneName: "Red 032 C",
        color: "#D8383C",
        description: "box-editor.pantone-picker.color-descriptions.red",
    },
    {
        uid: 7,
        pantoneName: "7738 C",
        color: "#45A53F",
        description: "box-editor.pantone-picker.color-descriptions.green",
    },
    {
        uid: 8,
        pantoneName: "Process Blue C",
        color: "#0078B0",
        description: "box-editor.pantone-picker.color-descriptions.blue",
    },
    {
        uid: 9,
        pantoneName: "Violet C",
        color: "#38276D",
        description: "box-editor.pantone-picker.color-descriptions.purple",
    },
];
var CARD_PRODUCT_PREFERED_COLORS = (_a = {},
    _a[CARD_CONST.PRINT_MATERIAL_KRAFT] = PRINT_MATERIAL_KRAFT,
    _a[CARD_CONST.PRINT_MATERIAL_WHITE_MATT] = PRINT_MATERIAL_WHITE_MATT,
    _a);
var ProductPrederedColors = {
    COLORS_CONFIG: (_b = {
            SHARED: {
                EDITOR_DEFAULT_COLOR: (_c = {
                        DEFAULT: {
                            color: "#ffffff",
                            pantoneName: "White",
                        }
                    },
                    _c[CARD_CONST.PRINT_MATERIAL_KARFT] = {
                        color: "#000000",
                        pantoneName: "Black C",
                    },
                    _c),
            }
        },
        _b[CARD_CONST.PRINTED_CARD] = {
            VARIANTS: (_d = {},
                _d[CARD_CONST.PRINTED_CARD_VARIANT] = {
                    PANTONE_COLORS: true,
                    LIMITED_COLORS: false,
                    LIMITED_COLORS_DESCRIPTION: true,
                },
                _d),
        },
        _b),
    COLORS_LISTS: CARD_PRODUCT_PREFERED_COLORS,
};
export default ProductPrederedColors;
