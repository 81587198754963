import React from "react"
import styles from "./RangeOption.module.scss"
import { Slider } from "dsl/src/atoms/Slider/Slider"
import TextField from "dsl/src/atoms/TextField/TextField"
import cxBinder from "classnames/bind"

const cx = cxBinder.bind(styles)

interface RangeOptionProps {
  label: string
  min: number
  max: number
  value: number
  onChange: (value: number) => void
  step?: number
  isPreviewDisabled?: boolean
}
export const RangeOption = ({
  value,
  label,
  min,
  max,
  step = 0.1,
  onChange,
  isPreviewDisabled,
}: RangeOptionProps) => {
  const humanReadableRangeValue = (Math.round(value * 10) / 10).toString()

  return (
    <div className={styles.row}>
      <div
        className={cx({
          label: true,
          label__long: isPreviewDisabled,
        })}
      >
        {label}
      </div>
      <div className={styles.slider}>
        <Slider
          step={step}
          value={value}
          min={min}
          max={max}
          onChange={onChange}
        />
      </div>
      <div
        className={isPreviewDisabled ? styles.preview_hidden : styles.preview}
      >
        <TextField
          value={humanReadableRangeValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(parseFloat(e.target.value))
          }}
          disabled
        />
      </div>
    </div>
  )
}
