import React, { PropsWithChildren, useState, useEffect } from "react"
import cxBinder from "classnames/bind"

import { IconButton } from "../../../../atoms/IconButton/IconButton"
import { ReactComponent as IconArrowUp } from "../../../../../../ui/assets/svg/ArrowUp.svg"
import { ReactComponent as IconArrowDown } from "../../../../../../ui/assets/svg/ArrowDown.svg"

import styles from "../CameraControlsToolbar.module.scss"
import { DotAlert } from "../../../../atoms/dot-alert/dot-alert"

const cx = cxBinder.bind(styles)

type CameraControlsToolbarElementCollapsibleProps = {
  label: string
  isActive?: boolean
  isAlert?: boolean
  isExpanded?: boolean
  onClick?: () => void
  Icon?: React.ElementType
  exposed?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

export const CameraControlsToolbarElementCollapsible = ({
  label,
  isActive = false,
  isAlert = false,
  isExpanded = false,
  onClick,
  Icon,
  exposed = false,
  children,
  e2eTarget = "camera-placement",
  e2eTargetName,
}: PropsWithChildren<CameraControlsToolbarElementCollapsibleProps>) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded)

  useEffect(() => {
    setExpanded(isExpanded)
  }, [isExpanded])

  const ExpandIcon = expanded ? IconArrowUp : IconArrowDown

  const icon = Icon || ExpandIcon

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  return (
    <li>
      <div
        className={cx({
          element: true,
          "element--big": true,
          "element--expandable": true,
          "element--exposed": exposed,
          "element--active": isActive,
        })}
        onClick={onClick || toggleExpanded}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        <span className={styles.element__label}>{label}</span>
        <IconButton Icon={icon} />
        {isAlert && (
          <div className={styles.alert_wrapper}>
            <DotAlert size="large" />
          </div>
        )}
      </div>
      {expanded && <ul className={styles.element__children}>{children}</ul>}
    </li>
  )
}
