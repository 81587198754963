import ConfigBuilder from "../../configBuilders/ConfigBuilder";
import paperTubeProductSpec from "../../../../source/paperTubeProductSpec/paperTubeProductSpec";
import WizzardManager from "../../behaviorsComposers/WizzardManager";
import QuantitiesManager from "../../behaviorsComposers/QuantitiesManager";
import AssetsManager from "../../behaviorsComposers/AssetsManager";
import WeightManager from "../../behaviorsComposers/WeightManager";
import ProductionTimeManager from "../../behaviorsComposers/ProductionTimeManager";
import GrammagesManager from "../../behaviorsComposers/GrammagesManager";
import SiblingsManager from "../../behaviorsComposers/SiblingsManager";
import PaperTubeEditConfigManager from "./behaviorsComposers/PaperTubeEditConfigManager";
import PaperTubeVariantManager from "./behaviorsComposers/PaperTubeVariantManager";
import PaperTubeAddonsManager from "./behaviorsComposers/PaperTubeAddonsManager";
import PaperTubeDimensionsManager from "./behaviorsComposers/PaperTubeDimensionsManager";
import PaperTubeRenderConfigManager from "./behaviorsComposers/PaperTubeRenderConfigManager";
import PaperTubeTranslationsManager from "./behaviorsComposers/PaperTubeTranslationsManager";
import ProductFactory from "../../ProductFactory";
var PaperTubeProduct = /** @class */ (function () {
    function PaperTubeProduct(sku, productName, productVariant) {
        this.config = this.builder(paperTubeProductSpec, sku, productName, productVariant);
        this.assetsManager = new AssetsManager(this);
        this.variantManager = new PaperTubeVariantManager(this);
        this.grammagesManager = new GrammagesManager(this);
        this.productionTimeManager = new ProductionTimeManager(this);
        this.weightsManager = new WeightManager(this);
        this.wizzardManager = new WizzardManager(this);
        this.editConfigManager = new PaperTubeEditConfigManager(this);
        this.translationsManager = new PaperTubeTranslationsManager(this);
        this.addonsManager = new PaperTubeAddonsManager(this);
        this.dimensionsManager = new PaperTubeDimensionsManager(this);
        this.renderConfigManager = new PaperTubeRenderConfigManager(this);
        this.siblingsManager = new SiblingsManager(this);
        this.quantitiesManager = new QuantitiesManager(this);
    }
    PaperTubeProduct.prototype.builder = function (spec, sku, productName, productVariant) {
        return ConfigBuilder(spec, sku, productName, productVariant);
    };
    PaperTubeProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    PaperTubeProduct.prototype.getEditorBackgroundPath = function () {
        return "";
    };
    PaperTubeProduct.prototype.createBaseProductSku = function () {
        return this.addonsManager.setDefaultAddons();
    };
    PaperTubeProduct.prototype.getBaseProduct = function () {
        return ProductFactory(this.createBaseProductSku());
    };
    return PaperTubeProduct;
}());
export default PaperTubeProduct;
