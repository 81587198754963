import React, { PropsWithChildren, useEffect, useState } from "react"

import { observer } from "mobx-react-lite"

import { ColorConfigurator } from "../configurators/ColorConfigurator/ColorConfigurator"
import {
  colorsListDemo,
  colorsListDemoWithBlankColor,
} from "../../../dsl/organisms/ColourPicker/colors-list"

import { AvailableColourModes } from "../../../../libs/products-render-config/types"

import { EditorToolHeaderCheckbox } from "../../molecues/editor-tool-header-checkbox/EditorToolHeaderCheckbox"
import styles from "./EditorTools.module.scss"
import { ShapeConfigurator } from "../configurators/ShapeConfigurator/ShapeConfigurator"
import { Shape } from "../../../../libs/value-objects/shape"
import { ColorListArray } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { PackhelpGroupType } from "../../../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { Colour } from "../../../../libs/value-objects/colour"
import { DesignAndProductDriverContainer } from "../../../../_containers/design-and-product-driver-container"
import { AssetsContainer } from "../../../../_containers/assets-container"

interface EditorToolMaskColorProps {
  objectIdentified: string | null
  currentColor: Colour
  colorsList: { uid: string; color?: string }[]
  onClick: () => void
  onChange: (color: Colour) => Promise<void>
}

interface EditorToolMaskShapeProps {
  objectIdentified: string | null
  currentShape: Shape
  shapes: Shape[]
  onClick: () => void
  onChange: (shape: Shape) => Promise<void>
}

export const EditorToolMaskColor = ({
  objectIdentified,
  currentColor,
  colorsList,
  onClick,
  onChange,
}: PropsWithChildren<EditorToolMaskColorProps>) => {
  const [selectedColor, selectColor] = useState<Colour>(currentColor)

  useEffect(() => {
    selectColor(currentColor)
  }, [objectIdentified, currentColor])

  const onColorChange = async (color: Colour) => {
    if (color !== selectedColor) {
      await onChange(color)
      selectColor(color)
    }
  }

  const onColorBackClick = async () => {
    await onColorChange(currentColor)
  }

  return (
    <li className={styles.header_toolbar_list_item}>
      <ColorConfigurator
        onColorSelect={onColorChange}
        onBackClick={onColorBackClick}
        selectedColor={selectedColor}
        colorsList={colorsList as ColorListArray[]}
        onClick={onClick}
      />
    </li>
  )
}

export const EditorToolMaskShape = ({
  objectIdentified,
  currentShape,
  shapes,
  onClick,
  onChange,
}: PropsWithChildren<EditorToolMaskShapeProps>) => {
  const [selectedShape, selectShape] = useState<Shape>(currentShape)

  useEffect(() => {
    selectShape(currentShape)
  }, [objectIdentified, currentShape])

  const onShapeChange = async (shape: Shape) => {
    const newMaskShape = shape === undefined ? currentShape : shape

    if (newMaskShape !== selectedShape) {
      await onChange(newMaskShape)
      selectShape(shape)
    }
  }

  const onShapeDoneClick = async () => {
    await onShapeChange(selectedShape)
  }

  const onShapeBackClick = async () => {
    await onShapeChange(currentShape)
  }

  return (
    <li className={styles.header_toolbar_list_item}>
      <ShapeConfigurator
        selectedShape={selectedShape}
        shapes={shapes}
        onDoneClick={onShapeDoneClick}
        onBackClick={onShapeBackClick}
        onShapeSelect={onShapeChange}
        onClick={onClick}
      />
    </li>
  )
}

export const EditorHeaderMaskToolbar = observer(
  ({
    designAndProductDriver,
    assets,
  }: {
    designAndProductDriver: DesignAndProductDriverContainer
    assets: AssetsContainer
  }) => {
    const { productDriver } = designAndProductDriver
    const { shapesStore, maskUiController } = assets

    const { productRenderPilot, activeContext } = productDriver.state
    const activeObjectDriver = productDriver.activeObjectDriver
    const objectIdentified = activeObjectDriver.objectIdentified
    const activeObject = activeObjectDriver.activeObject
    const activeObjectController = activeObjectDriver.activeObjectController
    const activeObjectType = activeObjectDriver.activeObjectType
    const isClippingMaskAvailable =
      productDriver.backgroundsDriver.isClippingMaskAvailable(activeContext)
    const isMaskUiAvailable =
      !activeObject.maskController?.getConfig().isEditingDisabled &&
      maskUiController.isMaskUiAvailable()

    const [isMaskApplied, setIsMaskApplied] = useState(false)

    useEffect(() => {
      setIsMaskApplied(maskUiController.hasMask(activeObject))
    }, [activeObject])

    if (
      !activeObject ||
      !activeObjectController ||
      !isMaskUiAvailable ||
      activeObjectType === PackhelpGroupType.activeSelection ||
      activeObjectController.hasGroup()
    ) {
      return null
    }

    const colorsPreset = productRenderPilot.getColoursPreset()

    const toggleMask = async () => {
      await maskUiController.toggleMask(activeObject)
      setIsMaskApplied(maskUiController.hasMask(activeObject))
    }

    const changeMaskColor = async (color: Colour) => {
      await maskUiController.changeMaskColor(activeObject, color)
    }

    const changeMaskShape = async (shape: Shape) => {
      await maskUiController.changeMaskShape(activeObject, shape)
    }

    const selectMaskParent = () => {
      maskUiController.selectMaskParent(activeObject)
    }

    const shouldShowColorPicker =
      isMaskApplied &&
      colorsPreset.mode !== AvailableColourModes.MONOCHROME &&
      colorsPreset.mode !== AvailableColourModes.MONO_PANTONE

    const shouldShowShapePicker = isMaskApplied

    const getCurrentShape = () => {
      return maskUiController.getMaskShape(activeObject)
    }

    const getCurrentColor = () => {
      return maskUiController.getMaskColor(activeObject)
    }

    const getColorsList = () => {
      if (isClippingMaskAvailable) {
        return colorsListDemoWithBlankColor
      }

      return colorsListDemo
    }

    return (
      <nav className={styles.header_nav} e2e-target="mask">
        <ul className={styles.header_toolbar_list}>
          <li className={styles.header_toolbar_list_item}>
            <EditorToolHeaderCheckbox
              isChecked={isMaskApplied}
              onChange={toggleMask}
              optionLabelIntl="component.editor-tool.checkbox.toggle-asset-mask"
              e2eTarget="mask-toggler"
            />
          </li>

          {shouldShowColorPicker && (
            <EditorToolMaskColor
              objectIdentified={objectIdentified}
              currentColor={getCurrentColor()}
              colorsList={getColorsList()}
              onClick={selectMaskParent}
              onChange={changeMaskColor}
            />
          )}

          {shouldShowShapePicker && (
            <EditorToolMaskShape
              objectIdentified={objectIdentified}
              currentShape={getCurrentShape()}
              shapes={shapesStore.maskShapes}
              onClick={selectMaskParent}
              onChange={changeMaskShape}
            />
          )}
        </ul>
      </nav>
    )
  }
)
