var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addAssetsBaseBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getAssetConfig = function () {
            return this.product.config.assets;
        };
        class_1.prototype.getKeyVisualArray = function () {
            var assetConfig = this.getAssetConfig();
            return assetConfig.product.keyVisual;
        };
        class_1.prototype.getMainKeyVisualPath = function () {
            return this.getKeyVisualArray()[0];
        };
        class_1.prototype.getGlobalInProductAssets = function () {
            var assetConfig = this.getAssetConfig();
            return assetConfig.global;
        };
        class_1.prototype.getAddonsImagePaths = function () {
            var globals = this.getGlobalInProductAssets();
            return globals.addonImagePaths;
        };
        class_1.prototype.getEditorImagePaths = function () {
            var globals = this.getGlobalInProductAssets();
            return globals.editorImagePaths || {};
        };
        class_1.prototype.getAddonsSvgIconNames = function () {
            var globals = this.getGlobalInProductAssets();
            return globals.addonsSvgIcons;
        };
        class_1.prototype.getDimensionsImages = function () {
            var assetConfig = this.getAssetConfig();
            var productCodeName = this.product.variantManager.getCodeName();
            return assetConfig.product.sizeAssets[productCodeName];
        };
        class_1.prototype.getCustomDimensionsImage = function (locale, predefinedCustomSizeId) {
            var assetConfig = this.getAssetConfig();
            if (!assetConfig.product.sizeAssets.custom)
                return null;
            if (predefinedCustomSizeId) {
                var _a = assetConfig.product.sizeAssets[predefinedCustomSizeId], desktop_1 = _a.desktop, mobile_1 = _a.mobile;
                return { desktop: desktop_1, mobile: mobile_1 };
            }
            var _b = assetConfig.product.sizeAssets.custom, desktop = _b.desktop, mobile = _b.mobile;
            return {
                desktop: desktop.replace("{locale}", locale.toUpperCase()),
                mobile: mobile.replace("{locale}", locale.toUpperCase()),
            };
        };
        class_1.prototype.getVariantEditorPreview = function () {
            var editorImages = this.getEditorImagePaths();
            return editorImages[this.product.variantManager.getVariant()];
        };
        return class_1;
    }(superclass));
};
export default addAssetsBaseBehaviors;
