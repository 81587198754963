var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import _each from "lodash/each";
import BOX_CONSTS from "../../../../../source/boxProductSpec/generalConfig/consts/boxConsts";
import { DeliveryMailerBoxVariant, SleevedMailerBoxVariant, } from "../../../../../source/boxProductSpec/generalConfig/consts/ProductVariant";
var addBoxAddonsBehaviors = function (superclass) {
    var addonsTypeMap = {
        colorModes: "colorMode",
        materials: "material",
        finishes: "finish",
    };
    var addonsTypesToBuildConfig = {
        colorModes: "colorMode",
        materials: "material",
        finishes: "finish",
    };
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getAvailableMaterialsAcrossVariants = function () {
            var variants = this.product.variantManager.getModelConfig().variants;
            var availableMaterials = [];
            Object.keys(variants).forEach(function (variantName) {
                var currentVariantMaterials = variants[variantName].materials;
                var materialsConfigInVariant = currentVariantMaterials.map(function (material) {
                    return {
                        materialId: material,
                        translationKey: "box-attribute.".concat(addonsTypeMap["materials"], ".").concat(material, ".name"), // TO DO MOVE: TO TRANSLATION MAP
                    };
                });
                availableMaterials.push.apply(availableMaterials, materialsConfigInVariant);
            });
            return availableMaterials;
        };
        class_1.prototype.getAddonsPickerDefaultItem = function () {
            var productName = this.product.variantManager.getName();
            if (productName === BOX_CONSTS.DELIVERY_MAILER_BOX ||
                productName === BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN) {
                return {
                    addonType: "finish",
                    id: "doubleStrip",
                    isActive: true,
                    name: "box-attribute.finish.doubleStrip.name",
                };
            }
            else {
                return _super.prototype.getAddonsPickerDefaultItem.call(this);
            }
        };
        class_1.prototype.getAvailableMaterials = function (withAddon) {
            var _this = this;
            if (withAddon === void 0) { withAddon = false; }
            var availableMaterials = _super.prototype.getAvailableMaterials.call(this);
            var addonsArray = this.product.variantManager.getModelConfig().addonsInVariants;
            var filteredMaterials = availableMaterials;
            if (!withAddon) {
                // If material is added to addons, skip it
                filteredMaterials = availableMaterials.filter(function (material) {
                    return addonsArray.indexOf(material.id) == -1;
                });
            }
            return filteredMaterials.map(function (material) {
                return Object.assign({}, material, {
                    pic: _this.texturesConsts[material.id],
                    sku: _this.product.variantManager.buildSku({ material: material.id }),
                });
            });
        };
        class_1.prototype.getAvailableAddons = function () {
            var _this = this;
            var addonsArray = this.product.variantManager.getModelConfig().addonsInVariants;
            // disabling "singleStrip" addon for delivery boxes without removing it from UPC
            // https://app.clubhouse.io/packhelp/story/27217/update-features-in-upc-universal-product-config-to-support-only-double-stripes
            var isDeliveryBox = this.product.wizzardManager.isProductDeliveryMailerBox();
            var isDeliveryBoxPlain = this.product.wizzardManager.isProductDeliveryMailerBoxPlain();
            if (isDeliveryBox || isDeliveryBoxPlain) {
                var unavailableAddon = "singleStrip";
                var index = addonsArray.indexOf(unavailableAddon);
                if (index !== -1) {
                    addonsArray.splice(index, 1);
                }
            }
            var variantConfig = this.product.variantManager.getVariantStructure();
            var filteredAvailableAddons = [];
            _each(variantConfig, function (variantConfigParameter, key) {
                var addonType = addonsTypeMap[key];
                if (addonsTypeMap.hasOwnProperty(key) &&
                    variantConfigParameter.length > 1) {
                    _each(variantConfigParameter, function (addon) {
                        var _a;
                        var addonArrayIndex = variantConfigParameter.indexOf(addon);
                        // If addon is not specified in addonsMap, skip it
                        if (addonsArray.indexOf(addon) == -1)
                            return;
                        if (addonArrayIndex !== 0) {
                            filteredAvailableAddons.push({
                                id: addon,
                                icon: _this.addonIcons[addon],
                                addonType: addonType,
                                name: "box-attribute.".concat(addonType, ".").concat(addon, ".name"),
                                sku: _this.product.variantManager.buildSku((_a = {},
                                    _a[addonType] = addon,
                                    _a)),
                                isActive: addon === _this.getCurrentAddon(addonType),
                            });
                        }
                    });
                }
            });
            return filteredAvailableAddons;
        };
        class_1.prototype.isPrintInsideActive = function () {
            var colorMode = this.product.variantManager.getColorMode();
            return [
                BOX_CONSTS.PRINT_MODE_MONOCHROME_WITH_PRINT_INSIDE,
                BOX_CONSTS.PRINT_MODE_MONOCHROME_WHITE_WITH_PRINT_INSIDE,
                BOX_CONSTS.PRINT_MODE_COLOR_ECO_WITH_PRINT_INSIDE,
                BOX_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE,
            ].includes(colorMode);
        };
        class_1.prototype.isPrintInsideAvailable = function () {
            return this.product.addonsManager
                .getAvailableColorModes()
                .find(function (colourMode) {
                return [
                    BOX_CONSTS.PRINT_MODE_MONOCHROME_WITH_PRINT_INSIDE,
                    BOX_CONSTS.PRINT_MODE_MONOCHROME_WHITE_WITH_PRINT_INSIDE,
                    BOX_CONSTS.PRINT_MODE_COLOR_ECO_WITH_PRINT_INSIDE,
                    BOX_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE,
                ].includes(colourMode.id);
            });
        };
        class_1.prototype.getAvailableConfigurablePropertiesGroupedByType = function () {
            var groups = _super.prototype.getAvailableConfigurablePropertiesGroupedByType.call(this);
            var finishGroup = groups.find(function (_a) {
                var type = _a.type;
                return type === "finishes";
            });
            if (!finishGroup) {
                return groups;
            }
            var variant = this.product.variantManager.getVariant();
            switch (variant) {
                case SleevedMailerBoxVariant.SLEEVED_MAILER_BOX_VARIANT:
                    finishGroup.title = "box-type.sleeve.name";
                    break;
                case DeliveryMailerBoxVariant.DELIVERY_MAILER_BOX_VARIANT:
                    finishGroup.title = "modal.download-or-print.closure";
                    break;
            }
            return groups;
        };
        return class_1;
    }(superclass));
};
export default addBoxAddonsBehaviors;
