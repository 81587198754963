var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var PRODUCTS_VENDORS = {
    ALTMASTER: "altmaster",
    MAREX: "marex",
    COMGRAPH: "comgraph",
    TORBOWO: "torbowo",
    CUSTOM_CUT: "customCut",
    EU_PACK: "eupack",
    BOX_MARKET: "boxMarket",
    SUPER_TAPE: "superTape",
    FENIKS: "feniks",
    WAREHOUSE: "warehouse",
    ETICOD: "eticod studio",
    ADA: "adams",
    MARTS_BAGS: "mart's bags",
    ENAF_STUDIO: "enaf studio",
};
var VENDOR_CONSTS = {
    VENDORS: __assign({}, PRODUCTS_VENDORS),
    DEFAULT: "default",
    VENDOR: "vendor",
};
export default VENDOR_CONSTS;
