import React, { useState } from "react"
import styles from "./PantonePickerComponent.module.scss"
import {
  OnApplyColor,
  PantonePickerColorObject,
} from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { Typography, TType } from "dsl/src/atoms/Typography/Typography"
import PantoneColorsGroupingProvider, {
  PantoneColorGroupName,
} from "./PantoneColorsGroupingProvider"
import {
  PantonePickerGroupsSelector,
  PantonePickerGroupsSelectorItem,
} from "./PantonePickerGroupsSelector"
import PantonePickerColorsList from "./PantonePickerColorsList"
import TextField from "dsl/src/atoms/TextField/TextField"

interface PantonePickerWithGroupingComponentProps
  extends PantonePickerComponentProps {
  groupingProvider: PantoneColorsGroupingProvider
  colorsList?: never
}

export interface PantonePickerComponentProps {
  selectedColor?: PantonePickerColorObject
  onApplyColor: (color: OnApplyColor) => void
  placeholderString: string
  noSearchResultsString: (searchValue: string) => string
  groupingProvider?: PantoneColorsGroupingProvider
  colorsList?: PantonePickerColorObject[]
}

function NoResults({
  noSearchResultsString,
  pantoneSearchValue,
}: {
  pantoneSearchValue: string
  noSearchResultsString: (searchValue: string) => string
}): JSX.Element {
  return (
    <div className={styles.noResults}>
      <Typography type={TType.Body15_350}>
        {noSearchResultsString(pantoneSearchValue)}
      </Typography>
    </div>
  )
}

function PantonePickerWithGroups({
  selectedColor,
  onApplyColor,
  placeholderString,
  groupingProvider,
  noSearchResultsString,
}: PantonePickerWithGroupingComponentProps): JSX.Element {
  const [pantoneSearchValue, setPantoneSearchValue] = useState<string>("")
  const [selectedGroup, setSelectedGroup] =
    useState<PantoneColorGroupName | null>(null)

  const handleSearchValue = (ev: any) => {
    setPantoneSearchValue(ev.target.value)
    setSelectedGroup(null)
  }

  const [defaultColors, setDefaultColors] = useState<
    PantonePickerColorObject[]
  >(groupingProvider.groups.Defaults)

  function addColorToDefaultGroup(color: PantonePickerColorObject) {
    const foundRecentColor = defaultColors.find(
      (value) =>
        value.pantoneName.toUpperCase() === color.pantoneName.toUpperCase()
    )
    const foundDefaultColor = groupingProvider.groups.Defaults.find(
      (value) =>
        value.pantoneName.toUpperCase() === color.pantoneName.toUpperCase()
    )
    if (foundRecentColor === undefined && foundDefaultColor === undefined) {
      setDefaultColors([color].concat(defaultColors))
    }
  }

  const handleApplyColor = (color: PantonePickerColorObject) => {
    addColorToDefaultGroup(color)
    onApplyColor(color)
  }

  // remove the Defaults, we don't want to select these
  const groupsList = Object.entries(groupingProvider.groups).filter(
    ([name]) => {
      // "as" for type safety autocompletion
      return (name as PantoneColorGroupName) !== "Defaults"
    }
  ) as PantonePickerGroupsSelectorItem[]

  const colors: PantonePickerColorObject[] = []
  if (pantoneSearchValue) {
    const searched = groupingProvider.searchByPantoneName(pantoneSearchValue)
    colors.push(...searched)
  } else {
    const groupColors =
      selectedGroup !== null
        ? groupingProvider.groups[selectedGroup]
        : defaultColors
    colors.push(...groupColors)
  }

  const renderNoResults =
    pantoneSearchValue && colors.length === 0 ? (
      <NoResults
        pantoneSearchValue={pantoneSearchValue}
        noSearchResultsString={noSearchResultsString}
      />
    ) : undefined

  return (
    <div className={styles.wrapper}>
      <TextField
        name="search"
        onChange={handleSearchValue}
        type="text"
        value={pantoneSearchValue}
        placeholder={placeholderString}
      />
      <PantonePickerGroupsSelector
        selected={selectedGroup !== null ? selectedGroup : undefined}
        onSelected={(group) => {
          setSelectedGroup(group)
        }}
        groups={groupsList}
        disabled={pantoneSearchValue.length > 0}
      />
      <hr className={styles.divider} />
      <PantonePickerColorsList
        listClassName={styles.colorsList}
        colorsList={colors}
        onApplyColor={handleApplyColor}
        selectedColor={selectedColor}
        renderNoResults={renderNoResults}
      />
    </div>
  )
}

export function PantonePickerComponent({
  selectedColor,
  onApplyColor,
  placeholderString,
  noSearchResultsString,
  groupingProvider,
  colorsList,
}: PantonePickerComponentProps) {
  if (groupingProvider) {
    return (
      <PantonePickerWithGroups
        selectedColor={selectedColor}
        onApplyColor={onApplyColor}
        placeholderString={placeholderString}
        noSearchResultsString={noSearchResultsString}
        groupingProvider={groupingProvider}
      />
    )
  }

  if (colorsList) {
    return (
      <PantonePickerColorsList
        listClassName={styles.colorsList}
        colorsList={colorsList}
        onApplyColor={onApplyColor}
        selectedColor={selectedColor}
      />
    )
  }

  return null
}
