var _a;
import BOX_CONSTS from "../../generalConfig/consts/boxConsts";
var SHIPING_BOXES_CONFG = {
    K90: {
        width: 353,
        length: 303,
        height: 256,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "90",
        editorCameraZoom: 0.7,
        thumbnailCameraZoom: 0.2,
    },
    K105: {
        width: 453,
        length: 353,
        height: 256,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "105",
        editorCameraZoom: 0.7,
        thumbnailCameraZoom: 0.2,
    },
    K130: {
        width: 503,
        length: 403,
        height: 406,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "130",
        editorCameraZoom: 0.7,
        thumbnailCameraZoom: 0.2,
    },
    K150: {
        width: 603,
        length: 503,
        height: 406,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "150",
        editorCameraZoom: 0.7,
        thumbnailCameraZoom: 0.2,
    },
    K180: {
        width: 803,
        length: 503,
        height: 506,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "180",
        editorCameraZoom: 0.7,
        thumbnailCameraZoom: 0.2,
    },
    K200: {
        width: 1003,
        length: 603,
        height: 406,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "200",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
};
var SHIPING_BOXES_PLAIN_V2_CONFG = {
    K1: {
        width: 200,
        length: 150,
        height: 70,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.7,
        thumbnailCameraZoom: 0.2,
    },
    K2: {
        width: 200,
        length: 150,
        height: 100,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.7,
        thumbnailCameraZoom: 0.2,
    },
    K3: {
        width: 200,
        length: 200,
        height: 70,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.7,
        thumbnailCameraZoom: 0.2,
    },
    K4: {
        width: 250,
        length: 200,
        height: 100,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.7,
        thumbnailCameraZoom: 0.2,
    },
    K7: {
        width: 350,
        length: 250,
        height: 70,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K9: {
        width: 350,
        length: 250,
        height: 150,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K10: {
        width: 400,
        length: 300,
        height: 70,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K11: {
        width: 400,
        length: 300,
        height: 180,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K12: {
        width: 400,
        length: 300,
        height: 200,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K13: {
        width: 400,
        length: 400,
        height: 200,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K14: {
        width: 450,
        length: 350,
        height: 250,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K15: {
        width: 400,
        length: 400,
        height: 300,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K16: {
        width: 500,
        length: 300,
        height: 300,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K17: {
        width: 300,
        length: 200,
        height: 200,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K18: {
        width: 630,
        length: 370,
        height: 180,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K19: {
        width: 600,
        length: 400,
        height: 400,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K20: {
        width: 600,
        length: 400,
        height: 400,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K21: {
        width: 600,
        length: 400,
        height: 400,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
    K22: {
        width: 640,
        length: 380,
        height: 410,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        textureDirectory: "common",
        editorCameraZoom: 0.6,
        thumbnailCameraZoom: 0.2,
    },
};
var SHIPPING_BOXES_RENDER_CONFIG = (_a = {},
    _a[BOX_CONSTS.SHIPPING_BOX_DEFAULT_VARIANT] = SHIPING_BOXES_CONFG,
    _a[BOX_CONSTS.SHIPPING_BOX_PLAIN] = SHIPING_BOXES_CONFG,
    _a[BOX_CONSTS.SHIPPING_BOX_PLAIN_V2] = SHIPING_BOXES_PLAIN_V2_CONFG,
    _a);
export default SHIPPING_BOXES_RENDER_CONFIG;
