import ProductDriver from "./product.driver"
import { AssetColorController } from "../../render-engine/modules/vd-editor/modules/assets-module/asset-color-controller"
import { AllEditorEventsEmitter, eventTree } from "../editor-events"
import { ProductRenderPilot } from "../../libs/products-render-config/product-render-pilot"
import _difference from "lodash/difference"
import { Product } from "@ph/product-api"

class ConverterDriver {
  constructor(
    private readonly productDriver: ProductDriver,
    private readonly ee: AllEditorEventsEmitter
  ) {}

  public async refresh(previousRenderPilot: ProductRenderPilot): Promise<void> {
    if (this.productRenderPilot.isDbyMode()) {
      return
    }

    if (this.shouldReinitRenderer(previousRenderPilot)) {
      await this.reinitRenderer()
    }

    await this.refreshRenderer()
  }

  private shouldReinitRenderer(
    previousRenderPilot: ProductRenderPilot
  ): boolean {
    const isNewProduct =
      previousRenderPilot.getProductVariantName() !==
      this.productRenderPilot.getProductVariantName()

    if (isNewProduct && this.productRenderPilot.hasObjModel()) {
      return true
    }

    const hadObjModel = previousRenderPilot.hasObjModel()
    const hasObjModel = this.productRenderPilot.hasObjModel()

    if (hadObjModel !== hasObjModel) {
      return true
    }

    const initializedEditContexts = this.productDriver
      .getVdEditors()
      .map(({ editContext }) => editContext)
    const editContexts = this.productRenderPilot.getAvailableEditContexts()

    return (
      _difference(initializedEditContexts, editContexts).length > 0 ||
      _difference(editContexts, initializedEditContexts).length > 0
    )
  }

  private async reinitRenderer(): Promise<void> {
    const {
      virtualDielineMountPoints,
      threeDimensionalRendererMountPoint,
      isDesignPreviewMode,
      isDtpPreviewMode,
    } = this.productDriver.state

    if (!virtualDielineMountPoints || !threeDimensionalRendererMountPoint) {
      return
    }

    await this.productDriver.onDeregisterMountPoints()
    await this.productDriver.initRenderer({
      threeDimensionalRendererMountPoint,
      virtualDielineMountPoints,
      isDesignPreviewMode,
      isDtpPreviewMode,
    })
  }

  private async refreshRenderer(): Promise<void> {
    const {
      renderEngine,
      productRenderPilot,
      modelContext,
      activeContext,
      activeSpace,
      isZoomActive,
    } = this.productDriver.state

    if (!renderEngine) {
      throw new Error("Renderer is not ready")
    }

    /**
     * TODO: Architecure, find a better solution
     *
     * Render engine was always stuck with an old pilot.
     * The one from productDriver is refreshed
     *
     * Render engine should be pilot free
     *
     * @degregar We could add listener to RenderEngine on skuChangeEnded
     * but we must pass eventEmitter along with the ProductRenderPilot
     */
    renderEngine.updateProductRenderPilot(productRenderPilot)

    this.productDriver.setDesignTouched(true)

    await Promise.all(
      renderEngine.getVirtualDielineEditors().map(async (vdEditor) => {
        vdEditor.dielineNavigator.setIsZoomActive(isZoomActive)
        vdEditor.assetsModule.clearActiveObject()

        const isEditContextDisabled = productRenderPilot.isEditContextDisabled(
          vdEditor.editContext
        )

        if (isEditContextDisabled) {
          vdEditor.assetsModule.setEditableObjectsVisibility(false)
          await vdEditor.backgroundsModule.applyColorOnProduct(null)
          vdEditor.showGlobalLayers(false)
        } else {
          vdEditor.assetsModule.setEditableObjectsVisibility(true)
          vdEditor.assetsModule.set2dInterfaceObjectsVisibility(false)

          const isActiveContext = activeContext === vdEditor.editContext

          if (isActiveContext && activeSpace) {
            vdEditor.assetsModule.set2dInterfaceObjectsVisibility(
              true,
              activeSpace
            )
            await vdEditor.dielineNavigator.refreshTempPatternOnActiveSpace()
          }

          vdEditor.showGlobalLayers(!isActiveContext || !activeSpace)
        }

        vdEditor.fabricCanvas.renderAll()
        vdEditor.setProductRenderPilot(productRenderPilot)

        const colorController = new AssetColorController(vdEditor)
        colorController.resetDielineColorableObjectsColorToDefaultIfNeeded()

        await vdEditor.setTexture(
          productRenderPilot.getVirtualDielineTexture(
            vdEditor.getEditContext(),
            isZoomActive
          )
        )
      })
    )

    await this.productDriver.refreshView()
    await renderEngine.reloadModelTextures(modelContext)

    this.ee.emit(eventTree.productDriver.renderEngineRefreshed)
  }

  private get productRenderPilot(): ProductRenderPilot {
    return this.productDriver.state.productRenderPilot
  }
}

export default ConverterDriver
