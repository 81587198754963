import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { EditorToolList } from "./EditorToolList"
import { CardTool } from "../../atoms/CardTool/CardTool"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { findProductPropertyBySku } from "../../../../libs/products-render-config/helpers"
import { SelectSizes } from "dsl/src/atoms/Select/types"
import { EditorToolbarItem } from "../../../../stores/editor-toolbar-store"
import { Select } from "dsl/src/atoms/Select/Select"
import { useTranslate } from "../../../hooks/useTranslate"
import { Thumb88 } from "../../atoms/Thumb88/Thumb88"
import { CurrencyLabel } from "../../atoms/CurrencyLabel/CurrencyLabel"
import { useCurrency } from "../../../hooks/useCurrency"
import { FormattedMessage } from "react-intl"
import { InfoBox } from "../../atoms/InfoBox/InfoBox"
import { I18N } from "../../../i18n"
import { ChangeProductConfirmationModal } from "../Modal/change-product-confirmation-modal/ChangeSizeConfirmationModal"
import { Tooltip, TooltipSideEnum } from "../../atoms/Tooltip/Tooltip"
import styles from "./EditorToolProductConfiguration.module.scss"
import { OptionPicker } from "../../atoms/option-picker/option-picker"
import { prepareImgixUrl } from "../../../../libs/helpers/prepare-imgix-url"

const SizeSection = ({
  size,
  isChangeSizeAvailable,
  onClick,
}: {
  size: string
  isChangeSizeAvailable: boolean
  onClick: () => void
}) => {
  return (
    <>
      <Typography type={TType.Header17_500} htmlElement="h2">
        <FormattedMessage id="box-configurator.size-modal.size-table" />
      </Typography>

      <Select
        items={[]}
        selectedItem={{
          id: size,
          name: size,
        }}
        size={SelectSizes.medium}
        disabled={!isChangeSizeAvailable}
        onClick={onClick}
        className={styles.select_wrapper}
      />
    </>
  )
}

const ListSection = ({
  title,
  type,
  currentItemId,
  items,
  isLoading,
  onClick,
  isAvailable = () => true,
  tooltipContent,
  isAdditionalPrice = false,
}: {
  title: string
  type: string
  currentItemId?: string
  items: {
    id: string
    title: string
    sku?: string
    imageUrl?: string
    price?: number
    isCustomSizeEnabled?: boolean
  }[]
  isLoading: boolean
  onClick: (sku: string) => void
  isAvailable?: (sku?: string, isCustomSizeEnabled?: boolean) => boolean
  tooltipContent?: React.ReactNode
  isAdditionalPrice?: boolean
}) => {
  const t = useTranslate()
  const currency = useCurrency()
  const [isDisabledTooltipVisible, setDisabledTooltipVisible] = useState(false)

  if (!items.length) {
    return null
  }

  const isNoImage = items.every(({ imageUrl }) => !imageUrl)

  return (
    <>
      <Typography type={TType.Header17_500} htmlElement="h2">
        {t(title)}
      </Typography>

      <EditorToolList size={isNoImage ? "full" : "small"}>
        {items.map((item) => {
          const isSelected = currentItemId === item.id
          const isDisabled = !isAvailable(item.sku, item.isCustomSizeEnabled)
          const price = item.price
          const badge = !isDisabled && price && (
            <CurrencyLabel
              isPerPiece={true}
              isAdditionalPrice={isAdditionalPrice}
              price={price}
              currency={currency}
              isFloating={false}
            />
          )

          const onSelect = () => {
            if (!item.sku || isSelected || isLoading || isDisabled) {
              return
            }

            onClick(item.sku)
          }

          return (
            <div
              key={item.id}
              onMouseEnter={() => isDisabled && setDisabledTooltipVisible(true)}
              onMouseLeave={() => setDisabledTooltipVisible(false)}
            >
              {isNoImage ? (
                <OptionPicker
                  label={t(item.title)}
                  isDisabled={isDisabled || isLoading}
                  isActive={isSelected}
                  badge={badge}
                  onSelect={onSelect}
                  e2eTarget={type}
                  e2eTargetName={item.id}
                ></OptionPicker>
              ) : (
                <Thumb88
                  isActive={isSelected}
                  disabled={isDisabled || isLoading}
                  imageURL={
                    item.imageUrl &&
                    prepareImgixUrl(item.imageUrl, {
                      auto: "format",
                      w: 176,
                      q: 75,
                    })
                  }
                  caption={t(item.title)}
                  truncateCaption={false}
                  onSelect={onSelect}
                  e2eTarget={type}
                  e2eTargetName={item.id}
                  hoverIcon={null}
                  imgLoading="lazy"
                  badge={badge}
                  badgePosition="bottom"
                />
              )}
            </div>
          )
        })}
      </EditorToolList>

      {tooltipContent && isDisabledTooltipVisible && (
        <div className={styles.tooltip_wrapper}>
          <Tooltip side={TooltipSideEnum.BottomLeft}>
            <Typography type={TType.Body13_350} htmlElement="p">
              {tooltipContent}
            </Typography>
          </Tooltip>
        </div>
      )}
    </>
  )
}

export const EditorToolProductConfiguration = observer(() => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ui,
    c.ecommerce,
    c.analytics,
  ])
  const t = useTranslate()

  if (!containerSet) {
    return null
  }

  const { productDriver, productDesignStore, changeProductController } =
    containerSet.designAndProductDriver
  const { productRenderPilot } = productDriver.state
  const { product, customization } = productDriver.productStore
  const { editorToolbarStore } = containerSet.ui
  const { analyticsController } = containerSet.analytics
  const { size } = productRenderPilot.getFullProductDescription()
  const { isAfterPurchaseEdit } = productRenderPilot
  const {
    isEditMode,
    state: {
      meta: { quantity },
    },
  } = productDesignStore
  const { productPricingStore } = containerSet.ecommerce

  const isChangeSizeAvailable =
    !isAfterPurchaseEdit &&
    isEditMode &&
    productRenderPilot.getAvailableSizes().length > 1

  const availablePropertyGroups =
    product.addonsManager.getAvailableConfigurablePropertiesGroupedByType()
  const relatedProducts = product.variantManager.getEditorRelatedProducts()
  const isDbyMode = productRenderPilot.isDbyMode()

  const getPrice = (sku?: string, piecesPerUnit?: number) => {
    if (!sku || !piecesPerUnit) {
      return
    }

    const price = productPricingStore?.getUnitPrice(quantity, sku).value

    if (!price) {
      return
    }

    return price / piecesPerUnit
  }

  return (
    <CardTool sizeXs cardName="product-configuration">
      <div className={styles.wrapper}>
        {isDbyMode && (
          <div className={styles.infobox_wrapper}>
            <InfoBox
              text={t(I18N.component.dbyUploader.configurationWarning)}
            />
          </div>
        )}

        <SizeSection
          size={size}
          isChangeSizeAvailable={isChangeSizeAvailable}
          onClick={() => {
            if (!isChangeSizeAvailable) {
              return
            }

            analyticsController?.trackChangeSizePickerOpened()
            editorToolbarStore.toggleTab(EditorToolbarItem.changeSize)
          }}
        />

        {!!relatedProducts.length && (
          <>
            <ListSection
              title={t("generic.products")}
              type="related-products"
              items={relatedProducts.map((relatedProduct) => {
                const { defaultVariant } = relatedProduct
                const sku = defaultVariant?.sku
                const pricePerUnit =
                  !customization && getPrice(sku, defaultVariant?.piecesPerUnit)

                return {
                  id: relatedProduct.id,
                  title: relatedProduct.title,
                  sku: sku,
                  isCustomSizeEnabled: relatedProduct.isCustomSizeEnabled,
                  imageUrl: relatedProduct.imageUrl,
                  price: pricePerUnit,
                }
              })}
              isLoading={productDriver.state.isRendererLoading}
              onClick={(sku: string) =>
                changeProductController.changeProduct(
                  "product",
                  sku,
                  customization
                )
              }
              currentItemId={product.variantManager.getProductId()}
              isAvailable={(sku?: string, isCustomSizeEnabled?: boolean) => {
                if (!sku) {
                  return false
                }

                if (customization && !isCustomSizeEnabled) {
                  return false
                }

                return !!productPricingStore?.hasPricing(sku)
              }}
              tooltipContent={t(I18N.generic.notAvailable.forSelectedSize)}
            />
          </>
        )}

        {availablePropertyGroups.map((propertyGroup) => {
          const currentProperty = findProductPropertyBySku(
            propertyGroup.properties,
            product.variantManager.getSku()
          )

          return (
            <ListSection
              key={propertyGroup.type}
              title={propertyGroup.title}
              type={propertyGroup.type}
              items={propertyGroup.properties}
              isLoading={productDriver.state.isRendererLoading}
              onClick={(sku) =>
                changeProductController.changeProduct(
                  "variant",
                  sku,
                  customization
                )
              }
              currentItemId={currentProperty?.id}
            />
          )
        })}

        {changeProductController.isConfirmationModalOpened && (
          <ChangeProductConfirmationModal
            onClose={() => changeProductController.onConfirmationModalClose()}
            onConfirm={() => changeProductController.confirmChange()}
            isDbyMode={isDbyMode}
          />
        )}
      </div>
    </CardTool>
  )
})
