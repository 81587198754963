import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { RendererScenesControllerWired as RendererScenesController } from "../renderer-scenes-controller/RendererScenesController"
import { UISceneController } from "../ui-scenes-controller/UIScenesController"
import styles from "./Orchestra.module.scss"
import {
  useContainer,
  useContainerSet,
} from "../../../_containers-react/_editor-app-hooks"
import { useTranslate } from "../../hooks/useTranslate"
import { I18N } from "../../i18n"
import { SuccessfulRegistrationModal } from "../../dsl/organisms/Modal/successful-registration-modal"
import { AuthMaybeContainer } from "../../../_containers/auth-maybe-container"

const AuthAppWrapper = React.memo(
  ({ authContainer }: { authContainer: AuthMaybeContainer }) => {
    if (!authContainer.available) {
      return null
    }

    const { authController, AuthApp } = authContainer

    return (
      <>
        <AuthApp
          authEventsEmitter={authContainer.authController.authEventEmitter}
          location="editor"
        />
        {authController.isSuccessfulRegistrationModalOpened && (
          <SuccessfulRegistrationModal
            onClose={() =>
              authController.setIsSucccessfulRegistrationModalOpened(false)
            }
            email={authController.registeredUserEmail}
          />
        )}
      </>
    )
  }
)

const OnBeforeUnload = observer(() => {
  const [designAndProductDriver] = useContainer().designAndProductDriver
  const t = useTranslate()
  const i18n = I18N.confirmations.closeWindow

  const productDesignStore = designAndProductDriver?.productDesignStore
  const isProductDesignSaving = productDesignStore?.state.isProductDesignSaving
  const isDesignTouched = productDesignStore?.state.isDesignTouched

  const shouldPrompt = () => {
    /**
     * Important!!
     *
     * We need to check it again, because the value can change in the meantime.
     */
    if (productDesignStore?.state.isProductDesignSaving) {
      return t(i18n.savingInProgress)
    }

    if (
      productDesignStore?.state.isDesignTouched &&
      productDesignStore?.isEditMode
    ) {
      return t(i18n.unsavedData)
    }
  }

  useEffect(() => {
    window.onbeforeunload = shouldPrompt

    return () => {
      window.onbeforeunload = shouldPrompt
    }
  }, [isProductDesignSaving, isDesignTouched])

  return null
})

export const Orchestra = observer(() => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ui,
    c.auth,
    c.analytics,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, ui, auth, analytics } = containerSet

  const isDbyMode =
    designAndProductDriver.productDriver.state.productRenderPilot.isDbyMode()

  if (isDbyMode) {
    //@todo move initialisation to better place
    designAndProductDriver.productDriver.dbyInit()
  }

  return (
    <div className={styles.orchestra_wrapper}>
      <OnBeforeUnload />
      <AuthAppWrapper authContainer={auth} />
      {!isDbyMode && <RendererScenesController />}
      <UISceneController
        designAndProductDriverContainer={designAndProductDriver}
        uiContainer={ui}
        analyticsContainer={analytics}
      />
    </div>
  )
})
