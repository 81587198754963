import React from "react"
import cxBinder from "classnames/bind"
import { walletI18n } from "../../utils/wallet.translations"
import { useFormatMessage } from "../../../../../libs/others/i18n"
import { UserBenefit } from "./UserBenefit"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import styles from "./UserBenefits.module.scss"

const cx = cxBinder.bind(styles)

export const UserBenefits = ({
  currentStep,
  isHorizontal,
  nextOrderDiscount,
}) => {
  const translate = useFormatMessage()

  return (
    <div
      className={cx({
        "benefits-horizontal": isHorizontal,
      })}
    >
      <Typography type={TType.Header13_500}>
        {translate({ id: walletI18n.yourBenefits })}
      </Typography>

      <div
        className={cx({
          "border-wrapper": !isHorizontal,
        })}
      >
        <div className={styles["benefits"]}>
          <div className={styles["check-mark"]} />
          <Typography type={TType.Header15_500} className={styles["separator"]}>
            {nextOrderDiscount}%
          </Typography>

          <Typography type={TType.Body15_350}>
            {translate({ id: walletI18n.tooltipCashback })}
          </Typography>
        </div>

        {currentStep >= 2 && (
          <UserBenefit
            type={TType.Body15_350}
            description={translate({
              id: walletI18n.priceRibbonShipmentFreeNew,
            })}
          />
        )}
      </div>
    </div>
  )
}
