import {
  EditContext,
  ModelContext,
  ModelEditableSpaces,
  ViewType,
} from "../../libs/products-render-config/types"
import { Shape } from "../../libs/value-objects/shape"
import { ImageAsset } from "../../libs/value-objects/image-asset"
import { CameraAngle } from "../../libs/products-render-config/types"
import { PredefinedText } from "../../modules/predefined-assets/text-asset"
import { PackhelpObject } from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"

// Events
export enum ProductDriverEvents {
  changedDielinePosition = "ph.productDriver.changedDielinePosition",
  renderEngineInitiated = "ph.productDriver.renderEngine.initiated",
  renderEngineDeregistered = "ph.productDriver.renderEngine.deregistered",
  renderEngineRefreshed = "ph.productDriver.renderEngine.refreshed",
  spaceSelectedForEdit = "ph.productDriver.spaceSelectedForEdit",
  spaceHighlighted = "ph.productDriver.spaceHighlighted",
  escapeEditModeEnded = "ph.productDriver.escapeEditModeEnded",
  showSpaceStarted = "ph.productDriver.showSpaceStarted",
  showSpaceEnded = "ph.productDriver.showSpaceEnded",
  backgroundLayersRefreshed = "ph.productDriver.backgroundLayersRefreshed",
  productChanged = "ph.productDriver.product.changed",
  productChangeApplied = "ph.productDriver.product.applied",
  dbyEngineInitiated = "ph.productDriver.dbyEngine.initiated",
  backgroundChanged = "ph.productDriver.background.changed",

  /**
   * Shape added to a design
   */
  shapeAdded = "ph.productDriver.shape.added",

  /**
   * Text added to a design
   */
  textAdded = "ph.productDriver.text.added",

  /**
   * Logo placeholder slot added to a design
   */
  logoPlaceholderSlotAdded = "ph.productDriver.logoPlaceholderSlot.added",

  /**
   * Predefined Text added to a design
   */
  predefinedTextAdded = "ph.productDriver.predefined-text.added",

  /**
   * Image added to a design
   */
  imageAdded = "ph.productDriver.image.added",

  /**
   * Any object from design / canvas was removed
   */
  objectRemoved = "ph.productDriver.object.removed",

  /**
   * Design should be mark as touched
   */
  touchDesign = "ph.productDriver.touchDesign",

  /**
   * Design has been marked as touched
   */
  designTouched = "ph.productDriver.designTouched",

  /**
   * View changed
   */
  viewChanged = "ph.productDriver.viewChanged",

  /**
   * Mode changed
   */
  modeChanged = "ph.productDriver.modeChanged",

  /**
   * Mask added to object / Mask removed from object
   */
  maskToggled = "ph.productDriver.mask.toggled",

  /**
   * Mask shape or color changed
   */
  maskModified = "ph.productDriver.mask.modified",
}

export type ProductDriverTypes = {
  [ProductDriverEvents.changedDielinePosition]: ({
    activeSpace,
    activeContext,
  }: {
    activeSpace: ModelEditableSpaces | null
    activeContext: EditContext
  }) => void
  [ProductDriverEvents.renderEngineInitiated]: () => void
  [ProductDriverEvents.dbyEngineInitiated]: () => void
  [ProductDriverEvents.renderEngineDeregistered]: () => void
  [ProductDriverEvents.renderEngineRefreshed]: () => void
  [ProductDriverEvents.shapeAdded]: (
    object: Shape,
    maskApplied: boolean,
    designId?: number
  ) => void
  [ProductDriverEvents.textAdded]: (
    context: EditContext,
    side: CameraAngle,
    maskApplied: boolean
  ) => void
  [ProductDriverEvents.logoPlaceholderSlotAdded]: ({
    activeContext,
    activeSpace,
    isMaskApplied,
  }) => void
  [ProductDriverEvents.predefinedTextAdded]: ({
    activeContext,
    activeSpace,
    isMaskApplied,
    predefinedText,
  }: {
    activeContext: EditContext
    activeSpace: CameraAngle
    isMaskApplied: boolean
    predefinedText: PredefinedText
  }) => void
  [ProductDriverEvents.imageAdded]: (
    object: ImageAsset,
    context: EditContext,
    side: CameraAngle,
    maskApplied: boolean
  ) => void
  [ProductDriverEvents.objectRemoved]: (object: PackhelpObject) => void
  [ProductDriverEvents.touchDesign]: () => void
  [ProductDriverEvents.designTouched]: () => void
  [ProductDriverEvents.viewChanged]: ({
    space,
    context,
    viewType,
    previousSpace,
  }: {
    space: CameraAngle
    context: EditContext
    viewType: ViewType
    previousSpace?: CameraAngle
  }) => void
  [ProductDriverEvents.modeChanged]: ({
    modelContext,
  }: {
    modelContext: ModelContext
  }) => void
  [ProductDriverEvents.maskToggled]: (
    assetType: PackhelpObject,
    maskApplied: boolean
  ) => void
  [ProductDriverEvents.maskModified]: (
    assetType: PackhelpObject,
    shape: Shape,
    color: string
  ) => void
  [ProductDriverEvents.spaceSelectedForEdit]: ({
    space,
    context,
  }: {
    space: ModelEditableSpaces
    context: EditContext
  }) => void
  [ProductDriverEvents.spaceHighlighted]: (space, context) => void
  [ProductDriverEvents.escapeEditModeEnded]: () => void
  [ProductDriverEvents.showSpaceStarted]: () => void
  [ProductDriverEvents.showSpaceEnded]: (spaceId: ModelEditableSpaces) => void
  [ProductDriverEvents.backgroundLayersRefreshed]: () => void
  [ProductDriverEvents.productChanged]: (type?: string) => void
  [ProductDriverEvents.productChangeApplied]: (type?: string) => void
  [ProductDriverEvents.backgroundChanged]: () => void
}
