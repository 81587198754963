import _flow from "lodash/flow";
import RenderConfigManager from "../../../behaviorsComposers/RenderConfigManger";
import addEnvelopeRenderConfigBehaviors from "../../../behaviors/envelopeBehaviors/renderConfigBehaviors/EnvelopeRenderConfigBehaviors";
import getGeneralNonBoxRenderBehaviors from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/getGeneralNonBoxRenderBehaviors";
import addGetOffsetForSideBehavior from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/getOffsetForSide";
import addGetProductEditZonesBehaviors from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/getProductEditZones";
import addClipObjectBehaviors from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/addClipObjectBehaviors";
import addAssetsAndMaterialsFiltersBehaviors from "../../../behaviors/envelopeBehaviors/renderConfigBehaviors/addAssetsAndMaterialsFiltersBehaviors";
import addFinishDuplicationBehaviors from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/addFinishDuplicationBehaviors";
export default _flow([
    getGeneralNonBoxRenderBehaviors,
    addGetOffsetForSideBehavior,
    addGetProductEditZonesBehaviors,
    addClipObjectBehaviors,
    addAssetsAndMaterialsFiltersBehaviors,
    addFinishDuplicationBehaviors,
    addEnvelopeRenderConfigBehaviors,
])(RenderConfigManager);
