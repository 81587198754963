import _flow from "lodash/flow";
import cardProductSpec from "../../../source/cardProductSpec/cardProductSpec";
import CustomProductConfigBuilder from "../configBuilders/CustomProductConfigBuilder";
import addCustomProductDimensionsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductDimensionsBehaviors";
import addCustomProductProductionTimesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductProductionTimesBehaviors";
import addCustomProductVariantBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductVariantBehaviors";
import addCustomProductEditConfigBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductEditConfigBehaviors";
import addCustomProductGrammagesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductGrammagesBehaviors";
import addCustomProductTranslationsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductTranslationsBehaviors";
import CardTranslationsManager from "../Products/CardProduct/behaviorsComposers/CardTranslationsManager";
import CardVariantManager from "../Products/CardProduct/behaviorsComposers/CardVariantManager";
import GrammagesManager from "../behaviorsComposers/GrammagesManager";
import WizzardManager from "../behaviorsComposers/WizzardManager";
import EditConfigManager from "../behaviorsComposers/EditConfigManager";
import ProductionTimeManager from "../behaviorsComposers/ProductionTimeManager";
import CardDimensionsManager from "../Products/CardProduct/behaviorsComposers/CardDimensionsManager";
var CustomProductionTimeManager = addCustomProductProductionTimesBehaviors(ProductionTimeManager);
var CustomProductGrammagesManager = addCustomProductGrammagesBehaviors(GrammagesManager);
var CustomProductDimmensionsManager = addCustomProductDimensionsBehaviors(CardDimensionsManager);
var CustomProductEditorConfigManager = addCustomProductEditConfigBehaviors(EditConfigManager);
var CustomProductTranslationsManager = addCustomProductTranslationsBehaviors(CardTranslationsManager);
var CustomProductVariantManager = _flow([addCustomProductVariantBehaviors])(CardVariantManager);
var CustomCardProduct = /** @class */ (function () {
    function CustomCardProduct(sku, productName) {
        this.config = CustomProductConfigBuilder(cardProductSpec, sku, productName);
        this.wizzardManager = new WizzardManager(this);
        this.productionTimeManager = new CustomProductionTimeManager(this);
        this.editConfigManager = new CustomProductEditorConfigManager(this);
        this.variantManager = new CustomProductVariantManager(this);
        this.grammagesManager = new CustomProductGrammagesManager(this);
        this.dimensionsManager = new CustomProductDimmensionsManager(this);
        this.translationsManager = new CustomProductTranslationsManager(this);
    }
    CustomCardProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    CustomCardProduct.prototype.getMaterialDescription = function () {
        return null;
    };
    return CustomCardProduct;
}());
export default CustomCardProduct;
