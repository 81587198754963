import React, { useEffect, useState } from "react"
import { TType } from "dsl/src/atoms/Typography/Typography"
import { Line } from "dsl/src/atoms/Line/Line"
import { TM } from "shared-libs/src/js/shared-components/i18n/TypographyWithTranslate"
import { InnerSize, Modal } from "../Modal"
import { ModalTemplate } from "../partials/ModalTemplate"
import { SectionPicker } from "./components/SectionPicker"
import { Sections } from "./components/Sections"
import { HowToModalProps, Section } from "./utils/how-to.types"
import { i18n } from "./utils/how-to.translations"

import styles from "./HowToModal.module.scss"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

export const HowToModal = ({ onClose }: HowToModalProps) => {
  const [sections, setSections] = useState([])
  const [selectedSection, setSelectedSection] = useState<Section | null>(null)
  const [containerSet] = useContainerSet((c) => [c.analytics])

  useEffect(() => {
    const data = document.querySelector("#__DATA__")

    if (data) {
      // @ts-ignore
      const json = JSON.parse(data.text)
      const sectionsData = json.helpModal

      if (sectionsData) {
        setSections(sectionsData)
        setSelectedSection(sectionsData[0])
      }
    }
  }, [])

  if (!containerSet) {
    return null
  }

  const { analytics } = containerSet

  const onSectionClick = (section: Section) => {
    analytics.analyticsController?.trackHowToModalCategoryClicked(section.label)

    setSelectedSection(section)
  }

  return (
    <Modal maxWidth={InnerSize.large} onClose={onClose}>
      <ModalTemplate
        footer={<div />}
        header={
          <>
            <TM
              className={styles["modal-title"]}
              id={i18n.HOW_TO_USE}
              type={TType.Header32_400}
            />
            <Line />
          </>
        }
      >
        <div className={styles["modal-content"]}>
          {sections.length > 0 && (
            <SectionPicker
              onSectionClick={onSectionClick}
              sections={sections}
              selectedSection={selectedSection}
            />
          )}
          {selectedSection && <Sections selectedSection={selectedSection} />}
        </div>
      </ModalTemplate>
    </Modal>
  )
}
