var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addWeightBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.weights = product.config.weights;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getWeights = function () {
            return this.weights;
        };
        class_1.prototype.getWeight = function () {
            if (!this.weights.default) {
                return null;
            }
            var codeName = this.product.variantManager.getCodeName();
            return this.weights.default[codeName];
        };
        return class_1;
    }(superclass));
};
export default addWeightBehaviors;
