import { KeyboardScope } from "../../libs/services/keyboard-controls"

export enum KeyboardEvents {
  setScope = "keyboard.scope.set",
  clearScope = "keyboard.scope.clear",
}

export type KeyboardEventTypes = {
  [KeyboardEvents.setScope]: (scope: KeyboardScope) => void
  [KeyboardEvents.clearScope]: (scope: KeyboardScope) => void
}
