import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { FormattedMessage } from "react-intl"
import { ColorConfigurator } from "../../../configurators/ColorConfigurator/ColorConfigurator"
import { Colour } from "../../../../../../libs/value-objects/colour"
import { EditorToolHeaderCheckbox } from "../../../../molecues/editor-tool-header-checkbox/EditorToolHeaderCheckbox"
import { useRootStore } from "../../../../../hooks/useRootStore"
import { colorsListDemoWithBlankColor } from "../../../ColourPicker/colors-list"
import styles from "../../EditorTools.module.scss"

const SpaceBackgroundImageToggler = observer(() => {
  const { productDriver } = useRootStore()
  const { backgroundsDriver } = productDriver
  const { activeContext, activeSpace, productRenderPilot } = productDriver.state

  if (
    !activeSpace ||
    !backgroundsDriver.isGlobalBackgroundImageActive(activeContext) ||
    !productRenderPilot.isBackgroundAssetToggleAvailableForSpace(activeContext)
  ) {
    return null
  }

  const onChange = async () => {
    await backgroundsDriver.toggleBackgroundImageVisibilityOnSpace(activeSpace)
  }

  return (
    <EditorToolHeaderCheckbox
      isChecked={backgroundsDriver.isBackgroundImageVisibleOnEditedSpace}
      onChange={onChange}
      optionLabelIntl="component.hide-on-space.background-fill"
    />
  )
})

const SpacePatternToggler = observer(() => {
  const { productDriver } = useRootStore()
  const { backgroundsDriver } = productDriver
  const { activeContext, activeSpace, productRenderPilot } = productDriver.state

  if (
    !activeSpace ||
    !backgroundsDriver.isGlobalPatternActive(activeContext) ||
    !productRenderPilot.isBackgroundAssetToggleAvailableForSpace(activeContext)
  ) {
    return null
  }

  const onChange = async () => {
    await backgroundsDriver.togglePatternVisibilityOnSpace(activeSpace)
  }

  return (
    <EditorToolHeaderCheckbox
      isChecked={backgroundsDriver.isPatternVisibleOnEditedSpace}
      onChange={onChange}
      optionLabelIntl="component.hide-on-space.pattern"
    />
  )
})

const SpaceBackgroundColorPicker = observer(() => {
  const { productDriver } = useRootStore()
  const { backgroundsDriver } = productDriver
  const { activeSpace, productRenderPilot } = productDriver.state
  const [baseFontColor] = useState<Colour | undefined>(
    backgroundsDriver.editedSpaceColor
  )

  if (
    !activeSpace ||
    !productRenderPilot.uiConfig.features.backgroundColor ||
    !productRenderPilot.is3DProduct()
  ) {
    return null
  }

  const onColorSelect = async (color: Colour) => {
    await onChangeSpaceColor(color)
  }

  const onBackClick = () => {
    baseFontColor && onChangeSpaceColor(baseFontColor)
  }

  const onChangeSpaceColor = async (colour: Colour) => {
    await backgroundsDriver.paintProductCurrentSpace(colour)
  }

  return (
    <ColorConfigurator
      itemName={
        <FormattedMessage id="component.editor-tool-background.title.background-fill-color" />
      }
      onColorSelect={onColorSelect}
      onBackClick={onBackClick}
      selectedColor={backgroundsDriver.editedSpaceColor}
      colorsList={colorsListDemoWithBlankColor}
    />
  )
})

export const EditorHeaderSpaceToolbar = observer(() => {
  const { productDriver } = useRootStore()
  const { productRenderPilot, activeContext, isDesignPreviewMode } =
    productDriver.state

  if (
    isDesignPreviewMode ||
    !productRenderPilot.isPrintActiveFor(activeContext)
  ) {
    return null
  }

  return (
    <div className={styles.header_nav}>
      <SpaceBackgroundColorPicker />
      <SpacePatternToggler />
      <SpaceBackgroundImageToggler />
    </div>
  )
})
