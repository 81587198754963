var _a;
import TAPE_CONSTS from "../generalConfig/consts/tapeConsts";
var KRAFT_TAPES_RENDER_CONFIG = {
    X50: {
        width: 376.8,
        height: 48,
        length: 10,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        thumbnailCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: 0,
                    width: 376.8,
                    height: 48,
                    shape: '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 780 104" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><polygon id="path-1" points="0 0 768 0 780 104 12 104"></polygon></g></svg>',
                },
            },
        },
        metadata: {
            printSizes: {
                XXX_LARGE: {
                    width: 376.8,
                    height: 48,
                },
                XX_LARGE: {
                    width: 351.7,
                    height: 48,
                },
                X_LARGE: {
                    width: 326.6,
                    height: 48,
                },
                XXX_MEDIUM: {
                    width: 301.5,
                    height: 48,
                },
                XX_MEDIUM: {
                    width: 276.3,
                    height: 48,
                },
                X_MEDIUM: {
                    width: 251.2,
                    height: 48,
                },
                MEDIUM: {
                    width: 238.6,
                    height: 48,
                },
                XXX_SMALL: {
                    width: 226,
                    height: 48,
                },
                XX_SMALL: {
                    width: 201,
                    height: 48,
                },
                X_SMALL: {
                    width: 188.5,
                    height: 48,
                },
                SMALL: {
                    width: 150.7,
                    height: 48,
                },
            },
            availableRotations: [0, 45],
        },
    },
};
var PACKING_TAPES_RENDER_CONFIG = {
    X48: {
        width: 395.6,
        height: 48,
        length: 10,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        thumbnailCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 0,
                    left: 0,
                    width: 395.6,
                    height: 48,
                    shape: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve" viewBox="0 0 780 95"><g><polygon class="st0" points="7,95 0,85.5 7,76 0,66.5 7,57 0,47.5 7,38 0,28.5 7,19 0,9.5 7,0 772.9,0 780,9.5 772.9,19 780,28.5 772.9,38 780,47.5 772.9,57 780,66.5 772.9,76 780,85.5 772.9,95"/></g></svg>',
                },
            },
        },
        metadata: {
            printSizes: {
                XXX_LARGE: {
                    width: 395.6,
                    height: 48,
                },
                XX_LARGE: {
                    width: 370.5,
                    height: 48,
                },
                X_LARGE: {
                    width: 345.4,
                    height: 48,
                },
                LARGE: {
                    width: 314,
                    height: 48,
                },
                XXX_MEDIUM: {
                    width: 282.6,
                    height: 48,
                },
                XX_MEDIUM: {
                    width: 251.2,
                    height: 48,
                },
                X_MEDIUM: {
                    width: 245,
                    height: 48,
                },
                MEDIUM: {
                    width: 219.8,
                    height: 48,
                },
                X_SMALL: {
                    width: 188.5,
                    height: 48,
                },
                SMALL: {
                    width: 150.7,
                    height: 48,
                },
            },
            availableRotations: [0, 45],
        },
    },
};
var TAPES_RENDER_CONFIG = (_a = {},
    _a[TAPE_CONSTS.TAPE_MODEL_PACKING_TAPE] = PACKING_TAPES_RENDER_CONFIG,
    _a[TAPE_CONSTS.TAPE_MODEL_KRAFT_TAPE] = KRAFT_TAPES_RENDER_CONFIG,
    _a[TAPE_CONSTS.TAPE_MODEL_PRE_PRINTED_KRAFT_TAPE] = KRAFT_TAPES_RENDER_CONFIG,
    _a);
export default TAPES_RENDER_CONFIG;
