import { isProductDesignTemplate, isProductPattern } from "../../SkuLib";
var DesignItemWizzardManager = /** @class */ (function () {
    function DesignItemWizzardManager(product) {
        this.product = product;
    }
    DesignItemWizzardManager.prototype.isProductPattern = function () {
        return isProductPattern(this.product.sku);
    };
    DesignItemWizzardManager.prototype.isProductDesignTemplate = function () {
        return isProductDesignTemplate(this.product.sku);
    };
    DesignItemWizzardManager.prototype.isProductExtension = function () {
        return true;
    };
    return DesignItemWizzardManager;
}());
var DesignItemProduct = /** @class */ (function () {
    function DesignItemProduct(sku) {
        this.sku = sku;
        this.wizzardManager = new DesignItemWizzardManager(this);
    }
    return DesignItemProduct;
}());
export { DesignItemProduct };
