var _a, _b, _c, _d, _e, _f, _g;
import LABEL_CONSTS from "../consts/labelConsts";
var DEFAULT = "default";
var LABEL_PRODUCTS_WEIGHTS = (_a = {},
    _a[LABEL_CONSTS.CIRCLE_LABEL] = (_b = {},
        _b[LABEL_CONSTS.CIRCLE_LABEL_VARIANT] = (_c = {},
            _c[DEFAULT] = {
                SC2: 0.001,
                SC4: 0.001,
                SC5: 0.001,
                SC6: 0.001,
                SC7: 0.001,
                SC10: 0.001,
            },
            _c),
        _b),
    _a[LABEL_CONSTS.SQUARE_LABEL] = (_d = {},
        _d[LABEL_CONSTS.SQUARE_LABEL_VARIANT] = (_e = {},
            _e[DEFAULT] = {
                SS5: 0.001,
                SS10: 0.001,
                SS13: 0.001,
                SS15: 0.001,
                SS20: 0.001,
            },
            _e),
        _d),
    _a[LABEL_CONSTS.RECTANGLE_LABEL] = (_f = {},
        _f[LABEL_CONSTS.RECTANGLE_LABEL_VARIANT] = (_g = {},
            _g[DEFAULT] = {
                SR7: 0.001,
                SR10i: 0.001,
                SR10ii: 0.001,
                SR11: 0.001,
                SR12: 0.001,
                SR12i: 0.001,
                SR12ii: 0.001,
                SR12iii: 0.001,
                SR13: 0.001,
                SR13i: 0.001,
                SR14: 0.001,
                SR14i: 0.001,
                SR14ii: 0.001,
                SR15: 0.001,
                SR15i: 0.001,
                SR15ii: 0.001,
                SR15iii: 0.001,
                SR16: 0.001,
                SR16i: 0.001,
                SR16ii: 0.001,
                SR17: 0.001,
                SR17i: 0.001,
                SR17ii: 0.001,
                SR18: 0.001,
                SR18i: 0.001,
                SR18ii: 0.001,
                SR18iii: 0.001,
                SR19: 0.001,
                SR19i: 0.001,
                SR19ii: 0.001,
                SR19iii: 0.001,
                SR20: 0.001,
                SR20i: 0.001,
                SR20ii: 0.001,
                SR20iii: 0.001,
                SR22: 0.001,
                SR22i: 0.001,
                SR23: 0.001,
                SR23i: 0.001,
                SR23ii: 0.001,
                SR24: 0.001,
                SR24i: 0.001,
                SR25: 0.001,
                SR25i: 0.001,
                SR26: 0.001,
                SR27: 0.001,
                SR27i: 0.001,
                SR29: 0.001,
                SR29i: 0.001,
                SR29ii: 0.001,
                SR29iii: 0.001,
                SR30: 0.001,
                SR31: 0.001,
                SR33: 0.001,
                SR34: 0.001,
                SR37: 0.001,
                SR39: 0.001,
                SR44: 0.001,
            },
            _g),
        _f),
    _a);
export default LABEL_PRODUCTS_WEIGHTS;
