import _flow from "lodash/flow";
import DimensionsManager from "../../../behaviorsComposers/DimensionsManager";
import addInsideSize3DimensionsBehaviors from "../../../behaviors/originBehaviors/generalBehaviors/dimmensionsBehaviors/insideSize3DimensionsBehaviors";
import addOutsideSize3DimensionsBehaviors from "../../../behaviors/originBehaviors/generalBehaviors/dimmensionsBehaviors/outsideSize3DimensionsBehaviors";
import addSleevedMailerBoxOutsideSize3dDimensionsBehaviors from "../../../behaviors/boxBehaviors/general/SleevedMailerBoxDimensionsBehaviors/SleevedMailerBoxDimensionsBehaviors";
var OriginBoxDimensionsManager = _flow([
    addOutsideSize3DimensionsBehaviors,
    addInsideSize3DimensionsBehaviors,
])(DimensionsManager);
var SleevedMailerDimensionsManager = addSleevedMailerBoxOutsideSize3dDimensionsBehaviors(OriginBoxDimensionsManager);
export { OriginBoxDimensionsManager, SleevedMailerDimensionsManager };
