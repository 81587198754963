import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { ProductRenderPilot } from "../../../../../../libs/products-render-config/product-render-pilot"
import { DesignTemplateData } from "../../../../../../modules/design/types"
import { TemplateLoaderOptions } from "./template-loader/types"
import { TemplateVdLoader } from "./template-loader/template-vd-loader"
import _cloneDeep from "lodash/cloneDeep"

export class TemplateLoader {
  public constructor(
    private readonly vdEditors: VirtualDielineEditor[],
    private readonly productRenderPilot: ProductRenderPilot,
    private readonly options: TemplateLoaderOptions = {}
  ) {}

  public async load(
    templateData: DesignTemplateData,
    templateId?: number
  ): Promise<void> {
    for (const vdEditor of this.vdEditors) {
      const vdTemplateData = _cloneDeep(
        templateData.virtualDielines[vdEditor.editContext]
      )

      if (vdTemplateData) {
        const vdLoader = new TemplateVdLoader(
          vdEditor,
          vdTemplateData,
          templateData.designDataFormatVersion,
          this.options
        )
        await vdLoader.load(templateId)
      }
    }
  }

  public async clearTemplateRelatedElements() {
    for (const vdEditor of this.vdEditors) {
      await vdEditor.backgroundsModule.applyColorOnProduct(null)
      await vdEditor.backgroundsModule.removeBackgroundImage()
      vdEditor.clearEditableObjects()
    }
  }
}
