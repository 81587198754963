import { ModelEditableSpaces } from "../../../../../../libs/products-render-config/types"
import { PackhelpEditableShape } from "../../../object-extensions/packhelp-objects"
import { v4 as uuidv4 } from "uuid"
import { EditableObjectTypes } from "../../../../../../modules/ph-api/asset-types"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { ClipPathModes } from "../../../services/clip-path-generator"
import { AlignmentHelper } from "../helpers/alignment-helper"
import { SpaceClippingHelper } from "../helpers/space-clipping-helper"
import { ScaleHelper } from "../helpers/scale-helper"
import { ColorHelper } from "../helpers/color-helper"
import { Shape } from "../../../../../../libs/value-objects/shape"
import { ShapeHelper } from "../helpers/shape-helper"
import { DEFAULT_COLOR } from "../asset-color-controller"

export class ShapeCreator {
  public constructor(
    private readonly virtualDielineEditor: VirtualDielineEditor
  ) {}

  public async create(
    shape: Shape,
    spaceId: ModelEditableSpaces
  ): Promise<PackhelpEditableShape> {
    const object = await ShapeHelper.shapeToPackhelpObject(shape)

    if (
      typeof object === "undefined" ||
      typeof object.width === "undefined" ||
      typeof object.height === "undefined"
    ) {
      throw new Error("object is not initialized correctly")
    }

    object.set({
      lockUniScaling: true,
      assetType: EditableObjectTypes.assetObject,
      id: uuidv4(),
      originSpaceArea: spaceId,
    })

    // We need to reset it to the default color (#000000),
    // because SVG images have a fill property set to #2F2F2F
    if (object.assetObjectMeta?.colourConfig.colourParam === "fill") {
      object.fill = DEFAULT_COLOR.getHex()
    }

    this.scaleObject(object, spaceId)

    AlignmentHelper.alignCenter(
      this.virtualDielineEditor.dielineNavigator,
      object
    )

    ColorHelper.applyDefaultColor(this.virtualDielineEditor, object)
    await SpaceClippingHelper.setSpaceClipping(
      this.virtualDielineEditor,
      spaceId,
      object,
      ClipPathModes.FillMode
    )

    return object
  }

  private scaleObject(
    object: PackhelpEditableShape,
    spaceId: ModelEditableSpaces
  ) {
    const minScale = ScaleHelper.calcMinScaleLimit(
      this.virtualDielineEditor,
      object,
      object.originSpaceArea as ModelEditableSpaces
    )

    if (minScale) {
      object.set({
        scaleX: minScale,
        scaleY: minScale,
      })

      return
    }

    const MANUALLY_ADJUSTED_SIZE = 256
    ScaleHelper.scaleObjectToSpace(
      this.virtualDielineEditor,
      object,
      {
        width: MANUALLY_ADJUSTED_SIZE,
        height: MANUALLY_ADJUSTED_SIZE,
      },
      spaceId
    )
  }
}
