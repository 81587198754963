export var shippingBoxPlainV2Dimensions = {
    K1: {
        outside: {
            width: 200,
            length: 150,
            height: 70,
        },
        inside: {
            width: 194,
            length: 144,
            height: 58,
        },
    },
    K2: {
        outside: {
            width: 200,
            length: 150,
            height: 100,
        },
        inside: {
            width: 194,
            length: 144,
            height: 88,
        },
    },
    K3: {
        outside: {
            width: 200,
            length: 200,
            height: 70,
        },
        inside: {
            width: 194,
            length: 194,
            height: 58,
        },
    },
    K4: {
        outside: {
            width: 250,
            length: 200,
            height: 100,
        },
        inside: {
            width: 244,
            length: 194,
            height: 88,
        },
    },
    K7: {
        outside: {
            width: 350,
            length: 250,
            height: 70,
        },
        inside: {
            width: 344,
            length: 244,
            height: 58,
        },
    },
    K9: {
        outside: {
            width: 350,
            length: 250,
            height: 150,
        },
        inside: {
            width: 344,
            length: 244,
            height: 138,
        },
    },
    K10: {
        outside: {
            width: 400,
            length: 300,
            height: 70,
        },
        inside: {
            width: 394,
            length: 294,
            height: 58,
        },
    },
    K11: {
        outside: {
            width: 400,
            length: 300,
            height: 180,
        },
        inside: {
            width: 394,
            length: 294,
            height: 168,
        },
    },
    K12: {
        outside: {
            width: 400,
            length: 300,
            height: 200,
        },
        inside: {
            width: 394,
            length: 294,
            height: 188,
        },
    },
    K13: {
        outside: {
            width: 400,
            length: 400,
            height: 200,
        },
        inside: {
            width: 394,
            length: 394,
            height: 188,
        },
    },
    K14: {
        outside: {
            width: 450,
            length: 350,
            height: 250,
        },
        inside: {
            width: 444,
            length: 344,
            height: 238,
        },
    },
    K15: {
        outside: {
            width: 400,
            length: 400,
            height: 300,
        },
        inside: {
            width: 394,
            length: 394,
            height: 288,
        },
    },
    K16: {
        outside: {
            width: 500,
            length: 300,
            height: 300,
        },
        inside: {
            width: 494,
            length: 294,
            height: 288,
        },
    },
    K17: {
        outside: {
            width: 300,
            length: 200,
            height: 200,
        },
        inside: {
            width: 294,
            length: 194,
            height: 188,
        },
    },
    K18: {
        outside: {
            width: 634,
            length: 374,
            height: 190,
        },
        inside: {
            width: 634,
            length: 374,
            height: 190,
        },
    },
    K19: {
        outside: {
            width: 600,
            length: 400,
            height: 400,
        },
        inside: {
            width: 594,
            length: 394,
            height: 388,
        },
    },
    K20: {
        outside: {
            width: 600,
            length: 400,
            height: 400,
        },
        inside: {
            width: 594,
            length: 394,
            height: 388,
        },
    },
    K21: {
        outside: {
            width: 600,
            length: 400,
            height: 400,
        },
        inside: {
            width: 594,
            length: 394,
            height: 388,
        },
    },
    K22: {
        outside: {
            width: 640,
            length: 380,
            height: 410,
        },
        inside: {
            width: 634,
            length: 374,
            height: 398,
        },
    },
};
export var shippingBoxesDimensions = {
    K90: {
        outside: {
            width: 355,
            length: 305,
            height: 255,
        },
        inside: {
            width: 350,
            length: 300,
            height: 250,
        },
    },
    K105: {
        outside: {
            width: 455,
            length: 355,
            height: 255,
        },
        inside: {
            width: 450,
            length: 350,
            height: 250,
        },
    },
    K130: {
        outside: {
            width: 510,
            length: 410,
            height: 410,
        },
        inside: {
            width: 500,
            length: 400,
            height: 400,
        },
    },
    K150: {
        outside: {
            width: 610,
            length: 510,
            height: 410,
        },
        inside: {
            width: 600,
            length: 500,
            height: 400,
        },
    },
    K180: {
        outside: {
            width: 810,
            length: 510,
            height: 510,
        },
        inside: {
            width: 800,
            length: 500,
            height: 500,
        },
    },
    K200: {
        outside: {
            width: 1010,
            length: 610,
            height: 410,
        },
        inside: {
            width: 1000,
            length: 600,
            height: 400,
        },
    },
};
