import { PackhelpObject } from "../../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { SpaceClippingController } from "../../backgrounds-module/space-clipping-controller"
import { isGroup } from "../../../../../../modules/ph-api/asset-types"
import { ClippingHelper } from "./clipping-helper"

export class BackgroundLayerClippingHelper {
  public static async addBackgroundPaddingClipPathIfNeeded(
    vdEditor: VirtualDielineEditor,
    backgroundLayerObject: PackhelpObject
  ) {
    if (!vdEditor.productRenderPilot.uiConfig.editZone.paddingZoneSizeMm) {
      return
    }

    if (!backgroundLayerObject.clipPath) {
      backgroundLayerObject.clipPath = ClippingHelper.buildClipPathGroup()
    }

    if (!isGroup(backgroundLayerObject.clipPath)) {
      return
    }

    const objectClipPath = backgroundLayerObject.clipPath

    const contexts = vdEditor.productRenderPilot.getAvailableEditContexts()

    await Promise.all(
      contexts.map(async (context) => {
        const editableSpaces =
          vdEditor.productRenderPilot.getContextEditableSpaces(context)

        await Promise.all(
          editableSpaces.map(async (space) => {
            const outerClipPath =
              await vdEditor.dielineNavigator.cloneSpaceToClipPath(
                space.spaceId,
                true,
                false
              )

            outerClipPath.set({ id: `${space.spaceId}_outer_padding` })

            const innerClipPath =
              await vdEditor.dielineNavigator.cloneSpaceToClipPath(
                space.spaceId
              )

            const currentSpace =
              vdEditor.dielineNavigator.getVirtualDielineSpace(space.spaceId)

            innerClipPath.set({ inverted: true, absolutePositioned: true })
            outerClipPath.set({ clipPath: innerClipPath })

            const canvasDimensions = vdEditor.getCanvasDimensions()
            const virtualDieline = vdEditor.getProductVirtualDieline()

            /**
             * We need this offset to quick fix a problem with shifted clipping,
             * because for horizontal dielines we don't center them vertically (dunno why).
             *
             * @TODO: We need to better investigate it in the future.
             *
             * https://packhelp.slack.com/archives/C03RDDNLGLR/p1662040876484449
             */
            const topOffset =
              (canvasDimensions.height - virtualDieline.height) / 2

            outerClipPath.left = currentSpace.left
            outerClipPath.top = currentSpace.top - topOffset

            const currentOuterClipPath = objectClipPath
              .getObjects()
              .find((object) => object.id === outerClipPath.id)

            if (currentOuterClipPath) {
              objectClipPath.remove(currentOuterClipPath)
            }

            objectClipPath.add(outerClipPath)
          })
        )
      })
    )
  }

  public static async refreshSpaceClipping(
    vdEditor: VirtualDielineEditor,
    object: PackhelpObject
  ): Promise<void> {
    const spaceClippingController = new SpaceClippingController(
      vdEditor.dielineNavigator,
      object
    )

    const clipSpaceIds = spaceClippingController.getClipSpaceIds()

    if (clipSpaceIds.length === 0) {
      return
    }

    await Promise.all(
      clipSpaceIds.map(async (spaceId) => {
        const spaceConfig = vdEditor.productRenderPilot.getSpaceConfig(
          vdEditor.editContext,
          spaceId
        )

        await spaceClippingController.refreshClipping(spaceConfig)
      })
    )
  }
}
