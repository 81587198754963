import { observer } from "mobx-react-lite"
import React from "react"
import _defer from "lodash/defer"
import { Switch } from "dsl/src/atoms/Switch/Switch"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { I18N } from "../../../../../i18n"
import styles from "./PrintInsideBox.module.scss"
import { EditContext } from "../../../../../../libs/products-render-config/types"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"

export interface SwitchablePrintInsideBoxProps {
  isChecked: boolean
  onChange: () => void
  isProduct3d: boolean
  isOnlyColorInsideAvailable: boolean
  isDisabled: boolean
}

export const SwitchablePrintInsideBoxPureComponent = ({
  isChecked,
  onChange,
  isProduct3d,
  isDisabled,
  isOnlyColorInsideAvailable,
}: SwitchablePrintInsideBoxProps) => {
  const t = useTranslate()

  const i18n3dProduct = isOnlyColorInsideAvailable
    ? I18N.component.colorInside
    : I18N.component.printInside
  const i18n = isProduct3d ? i18n3dProduct : I18N.component.printBack

  return (
    <div className={styles.box}>
      <Switch
        checked={isChecked}
        onChange={onChange}
        e2eTarget="print-inside-switcher"
        disabled={isDisabled}
      />
      <div>
        <div className={styles.box__container}>
          <span className={styles.box__info}>{t(i18n.title)}</span>
        </div>
        <p className={styles.box__description}>{t(i18n.description)}</p>
      </div>
    </div>
  )
}

export const SwitchablePrintInsideBox = observer(() => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ecommerce,
    c.analytics,
  ])

  if (!containerSet) {
    return null
  }

  const { productDriver, contextController, productConfigurationUiController } =
    containerSet.designAndProductDriver
  const { actionTracker } = containerSet.analytics

  const { productRenderPilot } = productDriver.state

  if (
    productRenderPilot.getEditorMode() !== "editor" &&
    productRenderPilot.getEditorMode() !== "dby"
  ) {
    return null
  }

  const isDoubleSidedPrintActive =
    productRenderPilot.isPrintActiveFor(EditContext.INSIDE) ||
    productRenderPilot.isPrintActiveFor(EditContext.BACK)

  const isProduct3d = productRenderPilot.is3DProduct()
  const isProductWithOnlyColorInside =
    productRenderPilot.isPrintAvailableFor(EditContext.INSIDE) &&
    !productRenderPilot.getAvailableSpaces(EditContext.INSIDE).length

  const onChangePrintInside = async () => {
    await productConfigurationUiController.toggleDoubleSidedPrintingOption()

    contextController.openModel()

    _defer(() => {
      actionTracker?.printInsideSwitched(!isDoubleSidedPrintActive, "style_tab")
    })
  }

  return (
    <SwitchablePrintInsideBoxPureComponent
      isChecked={isDoubleSidedPrintActive}
      onChange={onChangePrintInside}
      isProduct3d={isProduct3d}
      isOnlyColorInsideAvailable={isProductWithOnlyColorInside}
      isDisabled={productDriver.state.isRendererLoading}
    />
  )
})
