import React from "react"
import { FormattedPlural } from "react-intl"

import Button from "dsl/src/atoms/Button/Button"
import Highlight, { HighlightTypes } from "dsl/src/atoms/Highlight/Highlight"
import {
  translateMsgOrTextWithValues,
  useFormatMessage,
} from "../../../../../libs/others/i18n"
import { DetailsProps } from "../utils/reforestation-support.types"

import styles from "../ReforestationSupport.module.scss"

const Details = (props: DetailsProps) => {
  const { formattedPrice, count, withButton, onButtonClick } = props

  const translate = useFormatMessage()

  return (
    <div className={styles["details"]}>
      <div className={styles["details__inner"]}>
        <aside className={styles["details__sidebar"]}>
          <span className={styles["details__label"]}>
            {translate({ id: "sustainability.planting-trees.modal.subheader" })}
          </span>
          <h3 className={styles["details__heading"]}>
            {translate({ id: "sustainability.planting-trees.modal.header" })}
          </h3>
        </aside>
        <article className={styles["details__article"]}>
          <div className={styles["details__content"]}>
            <Highlight color={HighlightTypes.FrenchPass}>
              {translateMsgOrTextWithValues(
                "sustainability.planting-trees.modal.description",
                {}
              )}
            </Highlight>
            {withButton && (
              <div className={styles["details__button-wrapper"]}>
                <Button fluid onClick={onButtonClick}>
                  <FormattedPlural
                    value={count}
                    one={translateMsgOrTextWithValues(
                      "sustainability.planting-trees.pledge-2.one",
                      { price: formattedPrice }
                    )}
                    few={translateMsgOrTextWithValues(
                      "sustainability.planting-trees.pledge-2.few",
                      { count, price: formattedPrice }
                    )}
                    many={translateMsgOrTextWithValues(
                      "sustainability.planting-trees.pledge-2.many",
                      { count, price: formattedPrice }
                    )}
                    other={translateMsgOrTextWithValues(
                      "sustainability.planting-trees.pledge-2.other",
                      { count, price: formattedPrice }
                    )}
                  />
                </Button>
              </div>
            )}
          </div>
          <p className={styles["details__footnote"]}>
            {translateMsgOrTextWithValues(
              "sustainability.planting-trees.modal.disclaimer",
              {}
            )}
          </p>
        </article>
      </div>
      <div className={styles["details__decor"]}>
        <span className={styles["details__decor__image"]} />
      </div>
    </div>
  )
}

export { Details, Details as default }
