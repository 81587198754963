var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import _round from "lodash/round";
import _flow from "lodash/flow";
import DimensionsManager from "../../../behaviorsComposers/DimensionsManager";
var addNcpOutsideSizeDimensionsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getOutsideSizeDimensionFormatted = function () {
            var _this = this;
            var outsideDimensions = this.getOutsideDimensions();
            var sizes = Object.values(outsideDimensions).map(function (dimension) {
                var unit = _this.getProperUnit(dimension);
                var divisor = _this.getDivisorByUnits(unit);
                var precision = 2;
                return { dimension: _round(dimension / divisor, precision), unit: unit };
            });
            var hasMixedUnits = sizes.find(function (o) { return o.unit !== sizes[0].unit; });
            return sizes
                .map(function (size) {
                return " ".concat(size.dimension, " ").concat(hasMixedUnits ? size.unit : "");
            })
                .join(" x")
                .concat(hasMixedUnits ? "" : sizes[0].unit);
        };
        class_1.prototype.getProperUnit = function (number) {
            if (number > 1000) {
                return "m";
            }
            else if (number > 10) {
                return "cm";
            }
            else {
                return "mm";
            }
        };
        return class_1;
    }(superclass));
};
export default _flow([addNcpOutsideSizeDimensionsBehaviors])(DimensionsManager);
