import { FontSizeCalculator } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/calculators/font-size-calculator"
import { useRootStore } from "./useRootStore"

export function useFontSizeCalculator(): FontSizeCalculator {
  const { productDriver } = useRootStore()
  const { productRenderPilot, renderEngine, activeContext, activeSpace } =
    productDriver.state

  const fontSizeCalculator = new FontSizeCalculator(
    productRenderPilot,
    activeContext,
    renderEngine!
      .getVirtualDielineEditor(activeContext)
      .dielineNavigator.getVirtualDielineSpace(activeSpace)
  )

  return fontSizeCalculator
}
