// @ts-nocheck
import _isNil from "lodash/isNil"
import _isObject from "lodash/isObject"
import _isString from "lodash/isString"
import _isEmpty from "lodash/isEmpty"
import _upperCase from "lodash/upperCase"
import _find from "lodash/find"
import _findKey from "lodash/findKey"
import _has from "lodash/has"
import _includes from "lodash/includes"
import _startsWith from "lodash/startsWith"
import _each from "lodash/each"
import URI from "urijs"
import getRegionConfig from "../../source/region-config-source"
import { objectLeafsList } from "../../../../js/libs/helpers/object-leafs-list"
import type {
  SupportedCurrency,
  SupportedLocales,
  ZpkjObjectType,
} from "./region-config.types"
import { getEnvVariable } from "./env-config"
import { getCountryFromCookie } from "./region-cookies"
import { getForcedRegionConfigSetting } from "./region-config-forced-settings"
import type { Locale } from "@ph/api-studio"

export type { Locale }
// @todo remove in post-sign-in cleanup
enum SignInVersions {
  V1 = "v1",
  V2 = "v2",
}

const configData = getRegionConfig()

declare global {
  interface Window {
    zpkj: ZpkjObjectType
    HubSpotConversations: {
      widget: {
        close: () => void
        open: () => void
        remove: () => void
        refresh: () => void
        status: () => { loaded: boolean; pending: boolean }
      }
    }
  }
}

/**
 * A locale key is the abbreviated symbol idicating country (of interest).
 * To get configuration section from RegionConfig it is necessary to map
 * in some cases the locale key to region config key - see implementation
 */
export const translateLocaleKeyToRegionConfigKey = (localeKey) => {
  switch (localeKey) {
    case "en":
      return "eu"
    case "cs":
      return "cz"
    case "sv":
      return "se"
    default:
      return localeKey
  }
}

export const regions = [
  "ch",
  "cz",
  "de",
  "es",
  "eu",
  "fr",
  "gb",
  "it",
  "nl",
  "no",
  "pl",
  "ro",
  "se",
  "us",
  "shared",
] as const

export type PackhelpRegion = (typeof regions)[number]

export const productRegions = [
  "cz",
  "de",
  "es",
  "eu",
  "fr",
  "gb",
  "it",
  "nl",
  "pl",
  "ro",
  "sv",
  "us",
] as const

export type ProductRegion = (typeof productRegions)[number]

export type CurrencySign = "zł" | "€" | "£" | "L" | "kr"

export type Platform = "studio" | "plus"

export const polishTaxRate = 23 as const

/**
 * Singletons are bad, but this is a reasonable shortcut
 */
let isRegionConfigInitialized = false

/**
 * Kinda temporary dev check that we have covered all app with
 * our checks
 */
function checkRegionConfiguratorInitiliazed() {
  return
  if (isRegionConfigInitialized === false) {
    if (global) {
      console.warn(
        "you moron, you forgot to init region config in this app. This will work, but, damn fix it"
      )
    } else {
      // initRegionConfig()
      console.log(process.env)
      throw new Error("this is not ready")
    }
  }
}
export default class RegionConfig {
  static currentRegionConfig = null
  static currentRegion: null | PackhelpRegion = null
  static currentEnv = null
  static currentApplicationType = null

  static setCurrentRegion(region: PackhelpRegion) {
    RegionConfig.currentRegion = region

    if (_isNil(configData[region])) {
      throw new Error(`Cannot find region in Region Configuration: ${region}`)
    }

    RegionConfig.currentRegionConfig = configData[region]
  }

  static isRegionConfigured(region) {
    return !_isNil(configData[region])
  }

  /**
   * Returns domain without locale
   * - [pl] zapakuj.to
   * - [en] packhelp.com
   * - [se] packhelp.com
   */
  static getMainDomainForCurrentRegion() {
    checkRegionConfiguratorInitiliazed()
    return RegionConfig.getUrl(RegionConfig.getHost(null, "landing"))
  }

  /**
   * Returns domain for given region with locale
   * @param region
   */
  static getMainDomainForSpecificRegion(region: string) {
    checkRegionConfiguratorInitiliazed()
    return RegionConfig.getUrl(RegionConfig.getHost(region, "landing"))
  }

  /**
   * Returns domain with locale and region if is path based domain
   * - [pl] zapakuj.to
   * - [en] packhelp.com
   * - [se] packhelp.com/sv-se
   */
  static getMainDomainWithLocaleForCurrentRegion() {
    checkRegionConfiguratorInitiliazed()
    if (RegionConfig.isPathBasedRegion()) {
      return (
        RegionConfig.getUrl(RegionConfig.getHost(null, "landing")) +
        RegionConfig.getPathRegion()
      )
    }
    return RegionConfig.getUrl(RegionConfig.getHost(null, "landing"))
  }

  static getDomainHostnameWithDot(region?: string) {
    checkRegionConfiguratorInitiliazed()
    const domain = RegionConfig.getHost(region, "landing")
    // for a domain like staging.packhelp.fr, return `.staging.packhelp.fr` to allow cookies on all subdomains
    return `.${URI(RegionConfig.getUrl(domain)).hostname()}`
  }

  static getCurrentRegion() {
    checkRegionConfiguratorInitiliazed()
    return RegionConfig.currentRegion
  }

  static getPathBasedLocales() {
    checkRegionConfiguratorInitiliazed()
    return RegionConfig.getSharedSettings()["pathBasedLocales"]
  }

  static pathBasedRegions() {
    checkRegionConfiguratorInitiliazed()
    return ["se", "nl", "ro", "us"]
  }

  static getPathRegion() {
    const domain = RegionConfig.getKeyForCurrentRegion("domain")
    return domain["pathBased"] ? domain["pathRegion"] : false
  }

  static getUrlWithProperPathForRegion(url) {
    const regex = /^[\/]/g
    const pathRegion = RegionConfig.getPathRegion()

    // checks for slash at the begining of string
    if (url && !String(url).match(regex)) {
      url = "/" + url
    }

    if (RegionConfig.isPathBasedRegion()) {
      if (!url) {
        // If UNDEFINED url is passed by some of the components they will redirect to homepage based on host
        // so we return pathRegion as homepage if region is pathBased
        return pathRegion
      } else {
        return `${pathRegion}${url}`
      }
    } else {
      return url
    }
  }

  static isPathBasedRegion() {
    const region = RegionConfig.getCurrentRegion()
    const pathBasedRegions = RegionConfig.pathBasedRegions()
    return pathBasedRegions.includes(region)
  }

  static getProductionTime() {
    checkRegionConfiguratorInitiliazed()
    return RegionConfig.getSharedSettings()["productionTime"]
  }

  static isCurrentEnvLocal() {
    checkRegionConfiguratorInitiliazed()
    return RegionConfig.getCurrentEnv() === "local"
  }

  static hasRegionCustomDomains(regionConfig) {
    return _has(regionConfig, "customDomains")
  }

  /**
   * @deprecated
   * when adding new functionality, you most likely want to use getCurrentLocaleIso639 instead
   * as getCurrentLocale incorrectly mixes langs/regions
   */
  static getCurrentLocale() {
    return RegionConfig.getKeyForCurrentRegion("locale")
  }

  static convertRegionToIso639Locale(region: string) {
    const sharedSettings = RegionConfig.getSharedSettings()
    return sharedSettings.regionsToIso639LanguageCodes[region]
  }

  static getCurrentLocaleIso639() {
    const region = RegionConfig.getCurrentRegion()

    const sharedSettings = RegionConfig.getSharedSettings()
    return sharedSettings.regionsToIso639LanguageCodes[region]
  }

  static getCurrentLocaleWithCountryCode() {
    const region = RegionConfig.getCurrentRegion().toUpperCase()
    const locale =
      RegionConfig.getSharedSettings()["countriesToLocaleTable"][region]

    return `${locale}-${region}`
  }

  static getKeyForCurrentRegion(key) {
    if (_isNil(RegionConfig.currentRegionConfig)) {
      throw new Error(`Current region is not set!`)
    }
    if (_isNil(RegionConfig.currentRegionConfig[key])) {
      throw new Error(`Cannot find key in Region Configuration: ${key}`)
    }
    return RegionConfig.currentRegionConfig[key]
  }

  static getKeyForCurrentPlusAvailableRegion(key: string): string {
    checkRegionConfiguratorInitiliazed()
    const isCurrentRegionUnavalibleInPlus =
      RegionConfig.isCurrentRegionUntranslatedInPackhelpPlus()

    switch (key) {
      case "region": {
        return isCurrentRegionUnavalibleInPlus
          ? "eu"
          : RegionConfig.getCurrentRegion()
      }
      case "locale": {
        return isCurrentRegionUnavalibleInPlus
          ? "en"
          : RegionConfig.getCurrentLocaleIso639()
      }
      case "currency": {
        return isCurrentRegionUnavalibleInPlus
          ? "EUR"
          : RegionConfig.getKeyForCurrentRegion("currency")
      }
      default: {
        throw new Error("Key is not defined!")
      }
    }
  }

  static getKeyForRegion(region, key) {
    checkRegionConfiguratorInitiliazed()
    if (!region) {
      return RegionConfig.getKeyForCurrentRegion(key)
    }
    const regionData = configData[region]
    return regionData ? regionData[key] : undefined
  }

  static setCurrentEnv(env) {
    if (_isNil(env)) {
      throw new Error(`Provided env is empty`)
    }
    RegionConfig.currentEnv = env
  }

  static getCurrentEnv() {
    checkRegionConfiguratorInitiliazed()
    if (_isNil(RegionConfig.currentEnv)) {
      throw new Error(`RegionConfig environment is not set`)
    }
    return RegionConfig.currentEnv
  }

  static setCurrentApplicationType(currentApplicationType) {
    if (_isNil(currentApplicationType)) {
      throw new Error(`Provided application type is empty`)
    }
    RegionConfig.currentApplicationType = currentApplicationType
  }

  static getCurrentApplicationType() {
    checkRegionConfiguratorInitiliazed()
    if (_isNil(RegionConfig.currentApplicationType)) {
      throw new Error(`RegionConfig current apllication type is not set`)
    }
    return RegionConfig.currentApplicationType
  }

  /**
   * Returns WordPress url link for a functionality of choice e.g.:
   * getLandingExternalLinkForPage('blog')
   * getLandingExternalLinkForPage('products', 'mailer-box-full-color')
   */
  static getLandingExternalLinkForPage(key, subkey?: any) {
    checkRegionConfiguratorInitiliazed()
    const externalLinks = RegionConfig.getKeyForCurrentRegion("externalLinks")
    const value = externalLinks[key]

    if (typeof value === "undefined") {
      return null
    } else if (_isObject(value) && subkey && _isString(value[subkey])) {
      return RegionConfig.getHomePageUrlForRegion(value[subkey])
    } else {
      return value.length > 0 || _isObject(value)
        ? RegionConfig.getHomePageUrlForRegion(value)
        : null
    }
  }

  static getUserAreaExternalLinkForPage(key, subkey?: any) {
    checkRegionConfiguratorInitiliazed()
    const externalLinks = RegionConfig.getKeyForCurrentRegion("externalLinks")
    const value = externalLinks[key]

    if (typeof value === "undefined") {
      return null
    } else if (_isObject(value) && subkey && _isString(value[subkey])) {
      return RegionConfig.getAppHomePageUrlForRegion(value[subkey])
    } else {
      return value.length > 0 || _isObject(value)
        ? RegionConfig.getAppHomePageUrlForRegion(value)
        : null
    }
  }
  static getCheckoutLinkForPage(page) {
    checkRegionConfiguratorInitiliazed()
    const externalLinks = RegionConfig.getKeyForCurrentRegion("externalLinks")
    const url = page ? externalLinks[page] : ""
    const dir = "app" // note that the url already contains "/"
    return RegionConfig.getHomePageUrlForRegion(dir + url)
  }

  static getAuthLinkForPage(page: string) {
    checkRegionConfiguratorInitiliazed()
    const url = page
    const dir = "auth"
    return RegionConfig.getHomePageUrlForRegion(`${dir}/${url}/`)
  }

  // @todo remove in post-sign-in cleanup
  static signInVersion = SignInVersions.V2

  // @todo remove in post-sign-in cleanup
  static getSignInUrl() {
    checkRegionConfiguratorInitiliazed()
    if (RegionConfig.signInVersion === SignInVersions.V1) {
      return RegionConfig.getEditorExternalLinkForPage("accountSignIn")
    } else {
      return RegionConfig.getAuthLinkForPage("login")
    }
  }

  // @todo remove in post-sign-in cleanup
  static getSignOutUrl() {
    checkRegionConfiguratorInitiliazed()
    if (RegionConfig.signInVersion === SignInVersions.V1) {
      return RegionConfig.getEditorExternalLinkForPage("accountSignOut")
    } else {
      return RegionConfig.getAuthLinkForPage("logout")
    }
  }

  // @todo remove in post-sign-in cleanup
  static getRegisterUrl() {
    checkRegionConfiguratorInitiliazed()
    if (RegionConfig.signInVersion === SignInVersions.V1) {
      return RegionConfig.getEditorExternalLinkForPage("accountRegister")
    } else {
      return RegionConfig.getAuthLinkForPage("register")
    }
  }

  static getEditorExternalLinkForPage(page) {
    checkRegionConfiguratorInitiliazed()
    const externalLinks = RegionConfig.getKeyForCurrentRegion("externalLinks")
    const url = RegionConfig.getUrlWithProperPathForRegion(externalLinks[page])

    return RegionConfig.getEditorExternalUrl(url)
  }

  static getEditorSharedLink(link) {
    checkRegionConfiguratorInitiliazed()
    const links = RegionConfig.getSharedSettings()["links"]["app"]
    const url = links[link]
    return RegionConfig.getEditorExternalUrl(url)
  }

  static getLandingSharedLink(link) {
    checkRegionConfiguratorInitiliazed()
    const links = RegionConfig.getSharedSettings["links"]["landing"]
    const url = links[link]
    return RegionConfig.getEditorExternalUrl(url)
  }

  static getTermsOfServiceUrl() {
    checkRegionConfiguratorInitiliazed()
    const default_url = RegionConfig.getEditorSharedLink("termsOfService")
    const localised_url =
      RegionConfig.currentRegionConfig["frontendTermsOfServiceRoute"]
    return localised_url || default_url
  }

  static getPrivacyPolicyUrl() {
    const default_url = RegionConfig.getEditorSharedLink("privacyPolicy")
    const localised_url =
      RegionConfig.currentRegionConfig["frontendPrivacyPolicyRoute"]
    return localised_url || default_url
  }

  static getFSCCertificationLink(): string {
    checkRegionConfiguratorInitiliazed()
    // TODO: make sure the url has not changed and check if it works for all regions
    return RegionConfig.currentRegionConfig["fscCertification"]
  }

  static getUrl(host, resource = "") {
    checkRegionConfiguratorInitiliazed()
    // host = e.g. app.local.zapakuj.to:3000
    // resource = e.g. /sign_up?something#foo
    let protocol = ""

    // If host doesn't end in / and resource doesn't start with /, add `/` to
    // avoid URLs like https://packhelp.comsignup_page
    if (
      !_isEmpty(resource) &&
      resource[0] !== "/" &&
      resource[resource.length - 1] !== "/"
    ) {
      resource = "/" + resource
    }

    //@ts-ignore
    // For compilation, we use domain template. In such case, return it as is,
    // just adding path, e.g. [Z_EDITOR_DOMAIN]/sign_up
    if (__IS_SSR__) {
      return `${host}${resource}`
    }

    // If host doesn't start with `http` or `//`, add `http://` or `https://`
    // depending on environment
    if (host.indexOf("http") !== 0 && host.indexOf("//") !== 0) {
      const isHttps = getEnvVariable("Z_INSTANCE_HTTPS")

      if (isHttps === false || isHttps === "false") {
        protocol = "http://"
      } else {
        protocol = "https://"
      }
    }
    return `${protocol}${host}${resource}`
  }

  static getSharedSettings() {
    checkRegionConfiguratorInitiliazed()
    return configData.shared
  }

  static getCurrencyIbans() {
    return RegionConfig.getSharedSettings()["currencyIbans"]
  }

  static getDigitalDesignAllowedCountries(): string[] {
    return RegionConfig.getSharedSettings()["digitalDesignAllowedCountries"]
  }

  static getCurrencySignByCurrency(currency) {
    checkRegionConfiguratorInitiliazed()
    return _find(configData, ["currency", currency])["currencyFormatter"][
      "currencySign"
    ]
  }

  static getCurrencyCode(
    localeKey: SupportedLocales = null
  ): SupportedCurrency {
    checkRegionConfiguratorInitiliazed()
    let region = RegionConfig.getCurrentRegion()
    if (localeKey) {
      region = translateLocaleKeyToRegionConfigKey(localeKey)
    }

    return RegionConfig.getKeyForRegion(region, "currency")
  }

  static getCurrencySign(localeKey = null) {
    let region = RegionConfig.getCurrentRegion()
    if (localeKey) {
      region = translateLocaleKeyToRegionConfigKey(localeKey)
    }

    const key = RegionConfig.getKeyForRegion(region, "currencyFormatter")
    return key ? key.currencySign : "€"
  }

  static getPhoneCountryCode() {
    checkRegionConfiguratorInitiliazed()
    return RegionConfig.getKeyForCurrentRegion("phoneCountryCode")
  }

  static getSharedOutsidePackhelpDomainsLinks(page): string {
    checkRegionConfiguratorInitiliazed()
    const externalDomainlinks = RegionConfig.getKeyForCurrentRegion(
      "outsidePackhelpDomainLinks"
    )
    if (!externalDomainlinks[page]) {
      throw new Error(
        `Cannot find outside packhelp domain shared link to this page: ${page}`
      )
    }
    return externalDomainlinks[page]
  }

  static translateLocaleToRegionId(locale) {
    const sharedSettings = RegionConfig.getSharedSettings()
    return sharedSettings.localeToRegionTable[locale.toLowerCase()]
  }

  static countryToLocale(country: string): string {
    return (
      RegionConfig.getSharedSettings().countryToLocaleTable[
        _upperCase(country)
      ] || "en"
    )
  }

  /**
   * Exists for VAT adjustments
   */
  static countryToRegion(countryISO: string): PackhelpRegion {
    let anyRegion: PackhelpRegion | undefined =
      RegionConfig.getSharedSettings().countryToRegionTable[countryISO]

    if (typeof anyRegion !== "string") {
      anyRegion = "eu"
    }
    return anyRegion
  }

  /**
   * Try to return a valid ISO string
   *
   * - try to get ISO from a special cookie
   * - alternatively try to map region to a "default" country and get ISO back
   * - nothing we can do for EU region. Return undefined
   */
  static getCurrentISOCountry(): string | undefined {
    checkRegionConfiguratorInitiliazed()
    let country = getCountryFromCookie()
    if (typeof country === "string") {
      return country
    }

    let region = this.getCurrentRegion()
    let ISO = _find(
      RegionConfig.getSharedSettings().countryToLocaleTable,
      (locale, iso) => locale === region
    )
    if (typeof ISO === "string") {
      return ISO.toUpperCase()
    }

    // for EU region return undefined
  }

  static getBuildUrlRoot(): string {
    checkRegionConfiguratorInitiliazed()
    return "/build"
  }

  static getImagesUrlRoot(): string {
    checkRegionConfiguratorInitiliazed()
    return (
      RegionConfig.getUrl(RegionConfig.getLandingHost()) +
      RegionConfig.getBuildUrlRoot() +
      "/shared-images"
    )
  }

  // function moved after the creation of upc package
  static getImageUrl(path: string = ""): string {
    checkRegionConfiguratorInitiliazed()
    return `${RegionConfig.getImagesUrlRoot()}${path}`
  }

  static getLandingHost(): string {
    checkRegionConfiguratorInitiliazed()
    let host
    //@ts-ignore
    if (__IS_SSR__) {
      host = "[ENV_LANDING_DOMAIN]"
    } else {
      host = RegionConfig.getHost(null, "landing")
    }
    return host
  }

  static getAppHost(): string {
    checkRegionConfiguratorInitiliazed()
    let host
    //@ts-ignore
    if (__IS_SSR__) {
      host = "[ENV_LANDING_DOMAIN]"
    } else {
      host = RegionConfig.getHost(null, "app")
    }
    return host
  }

  static getHomePageUrlForRegion(resource?: any) {
    return RegionConfig.getUrl(
      RegionConfig.getLandingHost(),
      RegionConfig.getUrlWithProperPathForRegion(resource)
    )
  }

  static getAppHomePageUrlForRegion(resource?: any) {
    return RegionConfig.getUrl(
      RegionConfig.getAppHost(),
      RegionConfig.getUrlWithProperPathForRegion(resource)
    )
  }

  static getEditorExternalUrl(
    resource,
    regionName: string | null | undefined = null
  ) {
    checkRegionConfiguratorInitiliazed()
    let host
    //@ts-ignore
    if (__IS_SSR__) {
      host = "[ENV_EDITOR_DOMAIN]"
    } else {
      host = RegionConfig.getHost(regionName, "app")
    }
    return RegionConfig.getUrl(host, resource)
  }

  static getEditorExternalUrlNoApp(
    resource,
    regionName: string | null | undefined = null
  ) {
    checkRegionConfiguratorInitiliazed()
    let host
    //@ts-ignore
    if (__IS_SSR__) {
      host = "[ENV_EDITOR_DOMAIN]"
    } else {
      host = RegionConfig.getHost(regionName)
    }
    return RegionConfig.getUrl(host, resource)
  }

  static getHost(
    regionName: string | null = null,
    appType: string | null = null,
    env: string | null = null
  ) {
    checkRegionConfiguratorInitiliazed()
    // host = domain[:port]
    // https://tools.ietf.org/html/rfc3986#section-3.2
    const region = regionName || RegionConfig.getCurrentRegion()
    const deployEnv = env || getEnvVariable("Z_INSTANCE_ENV", "local")
    const instanceName = getEnvVariable("Z_INSTANCE_NAME")

    // If instanceName is empty and we're on dev/staging/prod, then check for
    // custom host, e.g. kreator.zapakuj.to
    if (
      !instanceName &&
      _includes(["dev", "staging", "prod", "production"], deployEnv)
    ) {
      const customDomains = RegionConfig.getKeyForRegion(
        region,
        "customDomains"
      )
      if (!_isNil(customDomains)) {
        return customDomains[appType][deployEnv]
      }
    }

    // Otherwise, construct host

    // 1) Subdomain `app.queens.dev.`packhelp.com
    let appPart = appType == "app" ? "app." : ""
    const instancePart = instanceName ? `${instanceName}.` : ""
    let envPart = ""
    if (_includes(["dev", "staging", "local"], deployEnv)) {
      envPart = `${deployEnv}.`
    }
    if (deployEnv == "local" && globalThis.__IS_ASTRO__ === true) {
      appPart = "astro."
    }

    const subdomain = `${appPart}${instancePart}${envPart}`

    // 2) Domain app.queens.dev.`packhelp.com`
    const domainConfig = RegionConfig.getKeyForRegion(region, "domain")
    const domain = `${domainConfig.basename}.${domainConfig.tld}`

    // 3) Port app.queens.dev.packhelp.com`:8082`
    let port = ""
    const envPort = getEnvVariable("Z_INSTANCE_PORT")
    if (appType == "app" && envPort) {
      port = `:${envPort}`
    }
    const host = `${subdomain}${domain}${port}`
    // console.log("host", host)

    // Return constructed host
    return host
  }

  static getDomain(region?: string, appType?: string, env?: string) {
    checkRegionConfiguratorInitiliazed()
    if (region === null) {
      region = RegionConfig.getCurrentRegion()
    }

    if (appType === null) {
      appType = RegionConfig.getCurrentApplicationType()
    }

    if (env === null) {
      env = RegionConfig.getCurrentEnv()
    }

    return RegionConfig.getUrl(RegionConfig.getHost(region, appType, env))
  }

  static getAutolinkerDomains() {
    checkRegionConfiguratorInitiliazed()
    return RegionConfig.getSharedSettings()["autolinkerDomains"]
  }

  static getPricelistEndpointForCurrentRegion(
    queryParams: { [key: string]: string } = {}
  ) {
    checkRegionConfiguratorInitiliazed()
    const productRegion = RegionConfig.getKeyForCurrentRegion("productRegion")
    const queryString = new URLSearchParams({
      ...queryParams,
      region: productRegion,
      cache_buster: "v3",
    }).toString()

    return RegionConfig.getEditorExternalUrl(
      `api/prices/list?${queryString}`,
      "eu"
    )
  }

  static getMinimalPricelistEndpointForCurrentRegion() {
    return RegionConfig.getEditorExternalUrl("api/prices/minimal", "eu")
  }

  static getMinimalPricelistForMinimalOrderEndpointForCurrentRegion() {
    return RegionConfig.getEditorExternalUrl(
      "api/prices/minimal_price_for_minimal_order",
      "eu"
    )
  }

  static getShipmentPriceslistEndpointForCurrentRegion() {
    return RegionConfig.getEditorExternalUrl(
      "api/prices/shipment_prices_list",
      "eu"
    )
  }

  static getShippmentConfig() {
    return RegionConfig.currentRegionConfig["shipmentsConfig"]
  }

  static getSegmentationEndpointForCurrentRegion() {
    return RegionConfig.getEditorExternalUrl("api/segmentation", "eu")
  }

  static getEditorDesignUrl(designId: string, orderId: string) {
    return RegionConfig.getEditorExternalUrlNoApp(
      `editor/product/design/${designId}/${orderId}/?mode=editor`
    )
  }

  static getOriginForRestApi() {
    checkRegionConfiguratorInitiliazed()
    let domain
    //@ts-ignore
    if (__IS_SSR__) {
      domain = "[ENV_EDITOR_DOMAIN]"
    } else {
      domain = RegionConfig.getDomain("eu", "app")
    }
    return domain
  }

  static getDatesFormatRules(format) {
    checkRegionConfiguratorInitiliazed()
    if (
      !RegionConfig.getKeyForCurrentRegion("datesFormattingRules")["frontend"][
        format
      ]
    ) {
      throw new Error(`Cannot find format for dictionary: ${format}`)
    }
    return RegionConfig.getKeyForCurrentRegion("datesFormattingRules")[
      "frontend"
    ][format]
  }

  static detectCurrentSettingsByHostname() {
    checkRegionConfiguratorInitiliazed()
    const LOCAL_CONFIG = {
      currentRegion: "eu",
      currentApplicationType: "app",
      currentEnv: "local",
    }

    // Only for test environment
    if (typeof window === "undefined" || !window.location.hostname) {
      return LOCAL_CONFIG
    }

    // Check for next-gen app and localhost development
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      return LOCAL_CONFIG
    }

    let currentApplicationType, currentRegion, regionData

    const hostname = window.location.hostname

    let tld = URI(window.location.href).tld()
    let path = URI(window.location.href).path()

    const currentEnv = getEnvVariable("Z_INSTANCE_ENV", "local")

    // DIRECTORY BASED DOMAINS
    const localeToRegionTable =
      RegionConfig.getSharedSettings()["localeToRegionTable"]

    RegionConfig.getPathBasedLocales().forEach((region: string) => {
      if (path.indexOf(region) > -1) {
        currentRegion = localeToRegionTable[region]
      }
    })

    if (!currentRegion) {
      // Check if hostname is in any region's customDomains key
      _each(configData, (regionConfig, regionCode) => {
        if (RegionConfig.hasRegionCustomDomains(regionConfig)) {
          if (
            //@ts-ignore
            _includes(objectLeafsList(regionConfig.customDomains), hostname)
          ) {
            currentRegion = regionCode
          }
        }
      })
    }
    // If not, find by tld (means it's not a custom domain
    if (!currentRegion) {
      //@ts-ignore
      currentRegion = _findKey(configData, {
        mainRegion: true,
        domain: { tld: tld },
      })
    }

    regionData = configData[currentRegion]

    if (RegionConfig.hasRegionCustomDomains(regionData)) {
      //@ts-ignore
      currentApplicationType = _findKey(regionData["customDomains"], {
        [currentEnv]: window.location.host,
      })
    }

    if (!currentApplicationType) {
      currentApplicationType = _startsWith(hostname, "app") ? "app" : "landing"
    }

    return {
      currentRegion,
      currentApplicationType,
      currentEnv,
    }
  }

  static translateServerEnvForRegionConfig(env) {
    checkRegionConfiguratorInitiliazed()
    if (env === "development") {
      return "local"
    }
    return env
  }

  static isCurrentCountryPartiallySupported(countryCode: string): boolean {
    checkRegionConfiguratorInitiliazed()
    return RegionConfig.getKeyForCurrentRegion(
      "partiallySupportedCountries"
    ).includes(countryCode.toUpperCase())
  }

  static isCurrentRegionUntranslatedInPackhelpPlus(): boolean {
    checkRegionConfiguratorInitiliazed()
    const untranslatedRegionsInPackhelpPlus = ["cz", "ro", "nl", "se"]
    const region = RegionConfig.getCurrentRegion()

    return untranslatedRegionsInPackhelpPlus.includes(region)
  }

  static isCurrentRegionUntranslatedInPackhelpPro(): boolean {
    const untranslatedRegionsInPackhelpPro = [...regions]
    const region = RegionConfig.getCurrentRegion()

    return untranslatedRegionsInPackhelpPro.includes(region)
  }

  static isVatEnabledForCurrentRegion(): boolean {
    return RegionConfig.getKeyForCurrentRegion("hasVat")
  }
}

type InitRegionConfigShape = {
  currentApplicationType: "landing" | "app"
  currentEnv: string
  currentRegion: PackhelpRegion
}

/**
 * getForcedRegionConfigSetting reads from session.
 * But params in method > session. More forced :D
 */
let rc = false
export function initRegionConfig(initialShape?: InitRegionConfigShape) {
  let { currentRegion, currentApplicationType, currentEnv } =
    RegionConfig.detectCurrentSettingsByHostname()

  if (initialShape) {
    currentRegion = initialShape.currentRegion
    currentApplicationType = initialShape.currentApplicationType
    currentEnv = initialShape.currentEnv
  }

  const forcedRegion = getForcedRegionConfigSetting("region")
  const forcedAppType = getForcedRegionConfigSetting("appType")

  if (currentRegion == null) {
    currentRegion = "eu"
  }

  if (forcedRegion) {
    currentRegion = forcedRegion
  }

  if (forcedAppType) {
    currentApplicationType = forcedAppType
  }

  RegionConfig.setCurrentEnv(currentEnv)
  RegionConfig.setCurrentApplicationType(currentApplicationType)
  RegionConfig.setCurrentRegion(currentRegion)
  rc = true
}
if (!rc) {
  initRegionConfig()
}

if (typeof window !== "undefined") {
  //@ts-ignore
  window.RegionConfig = RegionConfig
  //@ts-ignore
  if (window.Sentry) {
    //@ts-ignore
    Sentry.configureScope((scope) => {
      scope.setTag("locale", RegionConfig.getCurrentLocale())
    })
  }
}
