import React, { PropsWithChildren } from "react"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
// Assets
import styles from "./Utilities.module.scss"
import cxBinder from "classnames/bind"
const cx = cxBinder.bind(styles)

interface UtilitiesProps {
  size?: "fixed" | "auto"
}

interface UtilityItemProps {
  name: string
  onClick(): void
}

export const UtilityItem = ({ onClick, name }: UtilityItemProps) => (
  <li
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}
    className={styles.utils_item}
  >
    <Typography type={TType.Body15_350}>{name}</Typography>
  </li>
)

export const Utilities = ({
  children,
  size = "fixed",
}: PropsWithChildren<UtilitiesProps>) => (
  <div
    className={cx("utils_wrapper", {
      "utils_wrapper--auto-width": size === "auto",
    })}
  >
    <ul>{children}</ul>
  </div>
)

export { Utilities as default }
