import LabelProductSpec from "../../../../source/labelProductSpec/labelProductSpec";
import ConfigBuilder from "../../configBuilders/ConfigBuilder";
// Global product behaviors composers
import ProductionTimeManager from "../../behaviorsComposers/ProductionTimeManager";
import GrammagesManager from "../../behaviorsComposers/GrammagesManager";
import AssetsManager from "../../behaviorsComposers/AssetsManager";
import WeightManager from "../../behaviorsComposers/WeightManager";
import WizzardManager from "../../behaviorsComposers/WizzardManager";
import SiblingsManager from "../../behaviorsComposers/SiblingsManager";
import AddonsManager from "../../behaviorsComposers/AddonsManager";
import QuantitiesManager from "../../behaviorsComposers/QuantitiesManager";
// Label specific behaviors composers
import LabelVariantManager from "./behaviorsComposers/LabelVariantManager";
import LabelTranslationsManager from "./behaviorsComposers/LabelTranslationsManager";
import LabelRenderConfigManager from "./behaviorsComposers/LabelRendeConfigManager";
import LabelEditConfigManager from "./behaviorsComposers/LabelEditConfigManager";
import LabelDimensionsManager from "./behaviorsComposers/LabelDimensionsManager";
import ProductFactory from "../../ProductFactory";
var LabelProduct = /** @class */ (function () {
    function LabelProduct(sku, productName, productVariant) {
        this.config = this.builder(LabelProductSpec, sku, productName, productVariant);
        this.variantManager = new LabelVariantManager(this);
        this.assetsManager = new AssetsManager(this);
        this.grammagesManager = new GrammagesManager(this);
        this.productionTimeManager = new ProductionTimeManager(this);
        this.weightsManager = new WeightManager(this);
        this.wizzardManager = new WizzardManager(this);
        this.dimensionsManager = new LabelDimensionsManager(this);
        this.siblingsManager = new SiblingsManager(this);
        this.editConfigManager = new LabelEditConfigManager(this);
        this.translationsManager = new LabelTranslationsManager(this);
        this.addonsManager = new AddonsManager(this);
        this.renderConfigManager = new LabelRenderConfigManager(this);
        this.quantitiesManager = new QuantitiesManager(this);
    }
    LabelProduct.prototype.builder = function (spec, sku, productName, productVariant) {
        return ConfigBuilder(spec, sku, productName, productVariant);
    };
    LabelProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    LabelProduct.prototype.getExtraTechnicalDetails = function () {
        return undefined;
    };
    LabelProduct.prototype.getEditorBackgroundPath = function (side) {
        var material = this.variantManager.getMaterial();
        var productCodeName = this.variantManager.getCodeName();
        var name = "tape_".concat(productCodeName, "_").concat(material, "_").concat(side, ".png");
        return ("https://packhelp-files.s3.amazonaws.com/app/editor/backgrounds/tapes/" +
            name);
    };
    LabelProduct.prototype.createBaseProductSku = function () {
        return this.addonsManager.setDefaultAddons();
    };
    LabelProduct.prototype.getBaseProduct = function () {
        return ProductFactory(this.createBaseProductSku());
    };
    return LabelProduct;
}());
export default LabelProduct;
