import fabric from "../../../../libs/vendors/Fabric"
import FabricAssetsLoaderService from "../../../../libs/services/fabric-assets-loader-service"
import { ProductRenderPilot } from "../../../../libs/products-render-config/product-render-pilot"
import VirtualDielineEditor from "../virtual-dieline-editor"
import {
  PackhelpCanvas,
  PackhelpImage,
  VirtualDieline,
} from "../object-extensions/packhelp-objects"
import { EditContext } from "../../../../libs/products-render-config/types"
import { ee } from "../../../../stores/editor-events"
import { VirtualDielineLoader } from "../../dieline-manager/virtual-dieline.loader"
import EventEmitter from "eventemitter3"

export class VirtualDielineInitializer {
  private readonly virtualDielineLoader: VirtualDielineLoader

  constructor(
    private readonly productRenderPilot: ProductRenderPilot,
    private readonly canvasDimensions: { width: number; height: number },
    private readonly eventEmitter: EventEmitter
  ) {
    this.virtualDielineLoader = new VirtualDielineLoader(
      this.productRenderPilot,
      canvasDimensions.width
    )
  }

  public async init(
    svg: string,
    editContext: EditContext,
    mountPoint: HTMLDivElement
  ): Promise<VirtualDielineEditor> {
    const fabricCanvas = this.createFabricCanvas()
    const virtualDieline = await this.createDieline(svg, editContext)
    const texture = await this.createTexture(editContext)

    mountPoint.innerHTML = ""
    mountPoint.appendChild(fabricCanvas.wrapperEl)

    const virtualDielineEditor = new VirtualDielineEditor(
      this.canvasDimensions,
      editContext,
      this.eventEmitter,
      ee
    )

    return virtualDielineEditor.init({
      fabricCanvas,
      virtualDieline,
      productRenderPilot: this.productRenderPilot,
      texture,
    })
  }

  private createFabricCanvas(): PackhelpCanvas {
    const element = document.createElement("canvas")
    element.setAttribute("width", this.canvasDimensions.width + "")
    element.setAttribute("height", this.canvasDimensions.height + "")

    return new fabric.Canvas(element, {
      preserveObjectStacking: true,
      enableRetinaScaling: true,
      uniScaleKey: "none",
    }) as PackhelpCanvas
  }

  private async createDieline(
    svg: string,
    editContext: EditContext
  ): Promise<VirtualDieline> {
    return this.virtualDielineLoader.load(svg, editContext)
  }

  private async createTexture(
    editContext: EditContext
  ): Promise<PackhelpImage | undefined> {
    const path = this.productRenderPilot.getVirtualDielineTexture(
      editContext,
      this.productRenderPilot.uiConfig.editZone.zoom.activeByDefault
    )?.path

    if (!path) {
      return
    }

    return FabricAssetsLoaderService.loadAsset(path, {}, undefined, false)
  }
}
