import { FeatureFlags } from "@ph/feature-flags"
import { GetContainerFormat } from "iti"
import { AppConfig } from "../app-config/app.config"
import {
  AllowedEditorFeatureFlagNames,
  dynamicFeatureFlags,
  featureFlagDefaults,
} from "../services/feature-flags/feature-flag-client"
import {
  Debug,
  DebugLogger,
  ErrorLogger,
  InfoLogger,
  Logger,
} from "../services/logger"
import { UrlManipulatorProvider } from "../services/url-manipulator"
import { ee } from "../stores/editor-events"
import { NotificationStore } from "../stores/notification-store/notification.store"

export async function provideUtilEnvContainer() {
  const appConfig = new AppConfig(globalThis.ph)
  globalThis.appConfig = appConfig

  const uri = new UrlManipulatorProvider(appConfig)

  const notificationStore = new NotificationStore(ee)

  if (uri.withPreviousDesignId()) {
    notificationStore.toggleUniqueNotification({
      name: "productSwitched",
      isVisible: true,
    })
  }

  const featureFlagStore = new FeatureFlags<AllowedEditorFeatureFlagNames>({
    featureFlagDefaults: featureFlagDefaults,
    dynamicFeatureFlags: dynamicFeatureFlags,
    configCatKey: appConfig.integrations.configCat?.key,
    preloadFlags: true,
  })

  return {
    appConfig,
    logger: { DebugLogger, InfoLogger, ErrorLogger, Debug, Logger },
    ee,
    uri,
    featureFlagStore,
    notificationStore,
  }
}

export type UtilEnvContainer = GetContainerFormat<
  typeof provideUtilEnvContainer
>
