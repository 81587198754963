import { DesignerModeStore } from "../../designer-mode-stores/designer-mode-store"
import { ProductDriver } from "../../product-driver/product.driver"
import { DesignerModeControllable } from "./designer-mode-controllable.interface"
import { TemplateLoader } from "../../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-loader/template-loader"
import { TemplateLoaderOptions } from "../../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-loader/template-loader/types"
import { TemplateExporter } from "./template/template-exporter"
import { AssetsStore } from "../../assets-store/assets.store"
import { DesignTemplateData } from "../../../modules/design/types"
import { TemplateManager } from "../../../services/template-manager"
import VirtualDielineEditor from "../../../render-engine/modules/vd-editor/virtual-dieline-editor"
import { Template } from "../../../modules/predefined-assets/template-asset"
import { ee, eventTree } from "../../editor-events"

export class TemplateDesignerModeController
  implements DesignerModeControllable
{
  private isTemplateLoaded = false
  private template?: Template

  constructor(
    private readonly templateId: number,
    private readonly manager: TemplateManager,
    private readonly productDriver: ProductDriver,
    private readonly designerModeStore: DesignerModeStore,
    private readonly assetsStore: AssetsStore,
    private readonly templateLoaderOptions: TemplateLoaderOptions = {}
  ) {}

  public getName(): string {
    return `Template ${this.template?.name}`
  }

  public getTemplate(): Template | undefined {
    return this.template
  }

  public async loadTemplate(candidateId?: number | null): Promise<void> {
    if (this.isTemplateLoaded) {
      return
    }

    this.designerModeStore.setLoading(true)

    this.template = await this.manager.getById({
      id: this.templateId,
      candidateId,
    })
    await this.loadTemplateAssetData(this.template)
    this.isTemplateLoaded = true

    this.designerModeStore.setLoading(false)
  }

  public async saveAsTemplate(): Promise<void> {
    if (!this.isValidToSave()) {
      return
    }

    this.designerModeStore.setSaving(true)

    const exportedData = await this.exportData()
    await this.manager.save(this.templateId, exportedData)
    this.productDriver.setDesignTouched(false)

    this.designerModeStore.setSaving(false)
  }

  private isValidToSave(): boolean {
    const product = this.productDriver.state.productRenderPilot.getProduct()

    if (!product || !this.template) {
      return false
    }

    return this.template.isMatchMainProduct(product)
  }

  private async loadTemplateAssetData(template: Template): Promise<void> {
    const templateAssetData = await this.manager.getDesignTemplateData(template)

    if (!templateAssetData) {
      return
    }

    const templateRawLoader = new TemplateLoader(
      this.getVirtualDielineEditors(),
      this.productDriver.state.productRenderPilot,
      this.templateLoaderOptions
    )
    await templateRawLoader.load(templateAssetData, this.templateId)
    this.assetsStore.deserialize(
      templateAssetData.files.filter((file) => !file.meta.isSoftDeleted)
    )

    ee.emit(eventTree.templates.designerModeLoaded)
  }

  private async exportData(): Promise<DesignTemplateData> {
    const templateExporter = new TemplateExporter(
      this.getVirtualDielineEditors(),
      this.assetsStore
    )

    return await templateExporter.export()
  }

  private getVirtualDielineEditors(): VirtualDielineEditor[] {
    const { renderEngine } = this.productDriver.state

    return renderEngine!.getVirtualDielineEditors()
  }
}
