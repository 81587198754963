import React, { Fragment, useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { FormattedMessage } from "react-intl"
import _times from "lodash/times"
import Skeleton from "react-loading-skeleton"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"

import { ColorConfigurator } from "../configurators/ColorConfigurator/ColorConfigurator"
import { RootStore } from "../../../../stores/root.store"
import { RootStoreContext } from "../../../../stores/store-contexts"

import { Colour } from "../../../../libs/value-objects/colour"
import { EditorToolList } from "./EditorToolList"
import { Thumb88 } from "../../atoms/Thumb88/Thumb88"
import { CardTool } from "../../atoms/CardTool/CardTool"
import { EditorToolHeaderCheckbox } from "../../molecues/editor-tool-header-checkbox/EditorToolHeaderCheckbox"
import { CanvasObjectControllable } from "../../../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/canvas-object-controllable.interface"

import styles from "./EditorTools.module.scss"
import { ShapesStore } from "../../../../stores/shapes-store/shapes.store"
import { Shape } from "../../../../libs/value-objects/shape"
import { useTranslate } from "../../../hooks/useTranslate"
import ProductDriver from "../../../../stores/product-driver/product.driver"

interface EditorToolShapesHeaderProps {
  activeObjectController: CanvasObjectControllable
  isObjectActive: boolean
  isUniScalingLocked: boolean
  objectIdentified: string | null
  toggleUniScaling: () => void
}

export const EditorToolShapesHeader = ({
  activeObjectController,
  objectIdentified,
  isObjectActive,
  isUniScalingLocked,
  toggleUniScaling,
}: EditorToolShapesHeaderProps) => {
  const [baseShapeColor, setBaseShapeColor] = useState<Colour>(
    activeObjectController.getFill()
  )
  const [selectedShapeColor, selectShapeColor] = useState<Colour>(
    activeObjectController.getFill()
  )

  useEffect(() => {
    setBaseShapeColor(activeObjectController.getFill())
  }, [isObjectActive, objectIdentified])

  const onChangeShapeColor = (color: Colour) => {
    activeObjectController.setStyles({ fill: color })
    selectShapeColor(color)
  }

  const onBackClick = () => {
    onChangeShapeColor(baseShapeColor)
  }

  if (
    !activeObjectController.isColorModificationAvailable() &&
    activeObjectController.shouldKeepRatio()
  ) {
    return null
  }

  return (
    <div className={styles.header_nav}>
      {activeObjectController.isColorModificationAvailable() && (
        <ColorConfigurator
          itemName={
            <FormattedMessage id="component.editor-tool-shapes.colour-configurator.label" />
          }
          onColorSelect={onChangeShapeColor}
          onBackClick={onBackClick}
          selectedColor={selectedShapeColor}
        />
      )}

      {!activeObjectController.shouldKeepRatio() && (
        <EditorToolHeaderCheckbox
          isChecked={isUniScalingLocked}
          onChange={toggleUniScaling}
          optionLabelIntl="component.editor-tool-shapes.checkbox.keep-proportions"
        />
      )}
    </div>
  )
}

const ShapesList = ({
  shapesStore,
  onSelect,
}: {
  shapesStore: ShapesStore
  onSelect: (shape: Shape) => void
}) => {
  const t = useTranslate()
  if (shapesStore.isLoadingShapes) {
    return (
      <EditorToolList size="small">
        {_times(9, () => (
          <Skeleton width={"100%"} height={88} />
        ))}
      </EditorToolList>
    )
  }

  return (
    <>
      {shapesStore.categories?.map((category, categoryIndex) => {
        const shapesInType = shapesStore.shapes[category.name]

        if (!shapesInType || shapesInType.length === 0) {
          return
        }

        return (
          <Fragment key={category.name}>
            <Typography type={TType.Header17_500}>
              {category.translationKey
                ? t(category.translationKey)
                : category.name}
            </Typography>
            <EditorToolList size="small">
              {shapesInType.map((shape, shapeIndex) => {
                return (
                  <Thumb88
                    key={shapeIndex}
                    onSelect={() => {
                      onSelect(shape)
                    }}
                    imageURL={shape.src}
                    e2eTarget="shape"
                    e2eTargetName={`shape-${categoryIndex}-${shapeIndex}`}
                    imgLoading="lazy"
                  />
                )
              })}
            </EditorToolList>
          </Fragment>
        )
      })}
    </>
  )
}

export const EditorToolShapes = observer(() => {
  const rootStore = useContext<RootStore>(RootStoreContext)

  const { shapesStore, contextController, shapesController } = rootStore

  const addShapeOnDieline = async (shape) => {
    const space = await contextController.forceSpaceView()
    await shapesController.addShape(shape, space)
  }

  return (
    <CardTool sizeXs cardName="shapes">
      {<ShapesList shapesStore={shapesStore} onSelect={addShapeOnDieline} />}
    </CardTool>
  )
})

export const EditorToolShapesHeaderWired = observer(
  ({ productDriver }: { productDriver: ProductDriver }) => {
    const activeObjectController =
      productDriver.activeObjectDriver.activeObjectController
    const isObjectActive = productDriver.activeObjectDriver.isObjectActive
    const isUniScalingLocked =
      productDriver.activeObjectDriver.activeObjectComputable.isUniScalingLocked
    const objectIdentified = productDriver.activeObjectDriver.objectIdentified
    const { productRenderPilot } = productDriver.state

    if (!productRenderPilot || !activeObjectController) {
      return null
    }

    const toggleUniScaling = () => {
      productDriver.activeObjectDriver.toggleUniScaling()
    }

    return (
      <EditorToolShapesHeader
        activeObjectController={activeObjectController}
        objectIdentified={objectIdentified}
        isObjectActive={isObjectActive}
        isUniScalingLocked={isUniScalingLocked}
        toggleUniScaling={toggleUniScaling}
      />
    )
  }
)
