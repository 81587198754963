var _a;
import NCP_CONSTS from "../consts/ncpConsts";
var NCP_GRAMMAGES = (_a = {},
    _a[NCP_CONSTS.WOOD_WOOL] = {},
    _a[NCP_CONSTS.SELF_ADHESIVE_ENVELOPE] = {},
    _a[NCP_CONSTS.STICKER_CIRCLE] = {},
    _a[NCP_CONSTS.STICKER_RECTANGLE] = {},
    _a[NCP_CONSTS.THANK_YOU_CARD] = {},
    _a[NCP_CONSTS.PAPER_BUBBLE_WRAP] = {},
    _a);
export default NCP_GRAMMAGES;
