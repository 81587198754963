var _a, _b, _c;
import POLY_MAILER_CONSTS from "../generalConfig/consts/polyMailerConsts";
var POLY_MAILER_PRODUCTS_EDITOR_LIMITATIONS = {
    shared: {
        initialActiveSide: {
            activeSideId: "front",
            editedSideId: "front",
        },
        isPantoneColorsAvailable: true,
        isLimitedColorsModeActive: (_a = {},
            _a[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_BLACK] = true,
            _a[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_WHITE] = false,
            _a[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_TRANSPARENT] = false,
            _a[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_WHITE_BLACK_INSIDE] = false,
            _a),
        sidesNameCodesMap: {
            front: "A",
            back: "B",
        },
        isSafeZoneLight: (_b = {
                DEFAULT: false
            },
            _b[POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_BLACK] = true,
            _b),
        showSideExtraPrice: {
            back: true,
        },
    },
    products: (_c = {},
        _c[POLY_MAILER_CONSTS.POLY_MAILER] = {
            productEditorMode: "2D",
        },
        _c[POLY_MAILER_CONSTS.POLY_MAILER_BIO] = {
            productEditorMode: "2D",
        },
        _c[POLY_MAILER_CONSTS.POLY_MAILER_RECYCLED] = {
            productEditorMode: "2D",
        },
        _c[POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN] = {
            is3dEditorDisabled: true,
        },
        _c),
};
export default POLY_MAILER_PRODUCTS_EDITOR_LIMITATIONS;
