import React from "react"
import { observer } from "mobx-react-lite"

import cxBinder from "classnames/bind"
import styles from "./Rulers.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { Dimensions } from "../../atoms/dimensions/dimensions"

const cx = cxBinder.bind(styles)

export default observer(function ObjectRulers() {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.envUtil,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, envUtil } = containerSet
  const { productDriver } = designAndProductDriver
  const { appConfig } = envUtil
  const { activeObjectDriver } = productDriver
  const { activeContext, activeSpace, productRenderPilot } = productDriver.state

  if (!activeContext || !activeSpace || !activeObjectDriver.isObjectActive) {
    return null
  }

  const sceneElement = document.getElementById(
    `two-dimensional-scene-${activeContext}`
  )

  if (!sceneElement) {
    return null
  }

  const tempBackground = productDriver.getDielineNavigator().getTempBackground()

  if (
    typeof tempBackground === "undefined" ||
    typeof tempBackground.angle === "undefined" ||
    typeof tempBackground.width === "undefined" ||
    typeof tempBackground.height === "undefined"
  ) {
    return null
  }

  const { boundingTop, boundingLeft, boundingWidth, boundingHeight } =
    activeObjectDriver.activeObjectComputable

  const { widthCm, heightCm } = productRenderPilot.getSpaceDimensions(
    activeContext,
    activeSpace
  )

  const { width: sceneWidth, height: sceneHeight } =
    sceneElement.getBoundingClientRect()

  const editZoneSize = productDriver.getDielineNavigator().editZoneSize

  if (!editZoneSize) {
    return null
  }

  const { displayUnit } = appConfig.locale

  // Sizes
  const dashedLineOffset = 4
  const isRotated = Math.abs(tempBackground.angle) === 90
  const rulerWidth = isRotated ? editZoneSize.height : editZoneSize.width
  const rulerHeight = isRotated ? editZoneSize.width : editZoneSize.height
  const halfObjectWidth = boundingWidth / 2
  const halfObjectHeight = boundingHeight / 2
  const horizontalOffset = (sceneWidth - rulerWidth) / 2
  const verticalOffset = (sceneHeight - rulerHeight) / 2

  // Positions
  const topMarkerPosition = boundingTop - verticalOffset
  const leftMarkerPosition = boundingLeft - horizontalOffset
  const rightMarkerPosition = boundingLeft + boundingWidth - horizontalOffset
  const bottomMarkerPosition = boundingTop + boundingHeight - verticalOffset

  // Visibility
  const isVerticalLineVisible =
    leftMarkerPosition + halfObjectWidth > 0 &&
    leftMarkerPosition + halfObjectWidth < rulerWidth
  const isHorizontalLineVisible =
    topMarkerPosition + halfObjectHeight > 0 &&
    topMarkerPosition + halfObjectHeight < rulerHeight

  const controlsTheme = productRenderPilot.uiConfig.editZone.controlsTheme

  const labelClassName = cx({
    label: true,
    label_light: controlsTheme === "light",
  })

  return (
    <div className={styles.rulers_container}>
      <div
        style={{
          position: "absolute",
          top: `calc(50% - ${rulerHeight / 2}px)`,
          left: `calc(50% - ${rulerWidth / 2}px)`,
          width: rulerWidth,
          height: rulerHeight,
        }}
      >
        {isHorizontalLineVisible && (
          <div
            className={styles.object_ruler_wrapper + " " + styles.horizontal}
            style={{ top: topMarkerPosition + halfObjectHeight }}
          >
            <div
              className={styles.object_ruler_part}
              style={{ width: leftMarkerPosition - dashedLineOffset }}
            >
              <span className={labelClassName}>
                <Dimensions
                  dimensionsCm={[widthCm * (leftMarkerPosition / rulerWidth)]}
                  displayUnit={displayUnit}
                />
              </span>
              <div className={styles.background} />
            </div>

            <div
              className={styles.object_ruler_part}
              style={{
                width: rulerWidth - rightMarkerPosition - dashedLineOffset,
              }}
            >
              <span className={labelClassName}>
                <Dimensions
                  dimensionsCm={[
                    widthCm - widthCm * (rightMarkerPosition / rulerWidth),
                  ]}
                  displayUnit={displayUnit}
                />
              </span>
              <div className={styles.background} />
            </div>
          </div>
        )}

        {isVerticalLineVisible && (
          <div
            className={styles.object_ruler_wrapper + " " + styles.vertical}
            style={{ left: leftMarkerPosition + halfObjectWidth }}
          >
            <div
              className={styles.object_ruler_part}
              style={{ height: topMarkerPosition - dashedLineOffset }}
            >
              <span className={labelClassName}>
                <Dimensions
                  dimensionsCm={[heightCm * (topMarkerPosition / rulerHeight)]}
                  displayUnit={displayUnit}
                />
              </span>
              <div className={styles.background} />
            </div>

            <div
              className={styles.object_ruler_part}
              style={{
                height: rulerHeight - bottomMarkerPosition - dashedLineOffset,
              }}
            >
              <span className={labelClassName}>
                <Dimensions
                  dimensionsCm={[
                    heightCm - heightCm * (bottomMarkerPosition / rulerHeight),
                  ]}
                  displayUnit={displayUnit}
                />
              </span>
              <div className={styles.background} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
})
