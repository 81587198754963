import fabric from "../../../libs/vendors/Fabric"
import { PackhelpObject } from "../vd-editor/object-extensions/packhelp-objects"
import { CollisionPointsCalculator } from "./collision-points-calculator"

export class Intersection {
  public static intersect(
    firstObject: PackhelpObject,
    secondObject: PackhelpObject
  ): boolean {
    const intersection = fabric.Intersection.intersectPolygonPolygon(
      Intersection.getCollisionPoints(firstObject),
      Intersection.getCollisionPoints(secondObject)
    )

    return intersection.status === "Intersection"
  }

  private static getCollisionPoints(object: PackhelpObject): fabric.Point[] {
    return object.collisionPoints || CollisionPointsCalculator.calculate(object)
  }
}
