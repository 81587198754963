import { Product } from "@ph/product-api"

export enum PricingEvents {
  pricingLoaded = "ph.pricing.loaded",
  sendPricingDataToAuth = "ph.pricing.sendPricingDataToAuth",
}

export type PricingEventsTypes = {
  [PricingEvents.pricingLoaded]: (product: Product) => void
  [PricingEvents.sendPricingDataToAuth]: () => void
}
