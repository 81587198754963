import { BaseController } from "./base-controller"
import { MovementDirection } from "../types"
import {
  CanvasEvent,
  CanvasEventObjectModifiedAction,
  CanvasObjectEvent,
} from "../../../../../../../stores/_events/domain-events"

export class MovementController extends BaseController {
  public move(direction: MovementDirection, step = 1) {
    switch (direction) {
      case "up":
        this.canvasObject.set({
          top: this.canvasObject.top! - step,
        })
        break
      case "down":
        this.canvasObject.set({
          top: this.canvasObject.top! + step,
        })
        break
      case "left":
        this.canvasObject.set({
          left: this.canvasObject.left! - step,
        })
        break
      case "right":
        this.canvasObject.set({
          left: this.canvasObject.left! + step,
        })
        break
    }

    this.canvas.fire(CanvasEvent.objectModified, {
      target: this.canvasObject,
      action: CanvasEventObjectModifiedAction.move,
    })
    this.canvasObject.fire(CanvasObjectEvent.moved)

    this.rerender()
  }
}
