import {
  EditContext,
  ModelEditableSpaces,
} from "../../../../../../libs/products-render-config/types"
import { SpaceBoundingRect } from "../../dieline-navigator/services/translation-calculator"
import { DielineNavigator } from "../../dieline-navigator/dieline-navigator"
import { ProductRenderPilot } from "../../../../../../libs/products-render-config/product-render-pilot"
import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import { Size } from "../../../types/render-engine-types"

export abstract class AbstractCreator {
  constructor(
    protected readonly editContext: EditContext,
    protected readonly dielineNavigator: DielineNavigator,
    protected readonly productRenderPilot: ProductRenderPilot
  ) {}

  public abstract create(
    spaceId: ModelEditableSpaces,
    index: number
  ): Promise<PackhelpEditableObject>

  protected async cloneVdSpace(
    spaceId: ModelEditableSpaces
  ): Promise<PackhelpEditableObject> {
    const vdSpace = this.dielineNavigator.getVirtualDielineSpace(spaceId)

    return new Promise((resolve) => {
      vdSpace.clone(
        (clonedVdSpace) => {
          resolve(clonedVdSpace)
        },
        ["id", "rotation", "originSpaceArea"]
      )
    })
  }

  protected getVdSpaceBoundingRect(
    spaceId: ModelEditableSpaces
  ): SpaceBoundingRect {
    return this.dielineNavigator.getSpaceBoundingRect(spaceId)
  }

  protected getSafeZoneToArtworkRatio(spaceId: ModelEditableSpaces): {
    top: number
    left: number
    right: number
    bottom: number
  } {
    const artworkSpaceSize = this.productRenderPilot.getSpaceDimensions(
      this.editContext,
      spaceId
    )

    const artworkSpaceWidthMm =
      Math.max(artworkSpaceSize.widthCm, artworkSpaceSize.heightCm) * 10

    const safeZoneSizeMm =
      this.productRenderPilot.uiConfig.editZone.safeZoneSizeMm

    if (typeof safeZoneSizeMm === "number") {
      const safeZoneRatio = safeZoneSizeMm / artworkSpaceWidthMm
      return {
        top: safeZoneRatio,
        left: safeZoneRatio,
        right: safeZoneRatio,
        bottom: safeZoneRatio,
      }
    }

    return {
      top: safeZoneSizeMm.top / artworkSpaceWidthMm,
      left: safeZoneSizeMm.left / artworkSpaceWidthMm,
      right: safeZoneSizeMm.right / artworkSpaceWidthMm,
      bottom: safeZoneSizeMm.bottom / artworkSpaceWidthMm,
    }
  }

  protected getSafeZoneSize(
    spaceId: ModelEditableSpaces,
    vdSpaceBoundingRect: SpaceBoundingRect
  ): {
    top: number
    left: number
    right: number
    bottom: number
  } {
    const safeZoneSizeRatio = this.getSafeZoneToArtworkRatio(spaceId)

    const maxDimension = Math.max(
      vdSpaceBoundingRect.width,
      vdSpaceBoundingRect.height
    )

    return {
      top: safeZoneSizeRatio.top * maxDimension,
      left: safeZoneSizeRatio.left * maxDimension,
      right: safeZoneSizeRatio.right * maxDimension,
      bottom: safeZoneSizeRatio.bottom * maxDimension,
    }
  }

  protected getClipPathParams(
    spaceId: ModelEditableSpaces,
    vdSpaceBoundingRect: SpaceBoundingRect
  ): Size & {
    scaleX: number
    scaleY: number
    top: number
    left: number
    strokeWidth: number
    inverted: boolean
  } {
    const safeZoneSize = this.getSafeZoneSize(spaceId, vdSpaceBoundingRect)
    const width =
      vdSpaceBoundingRect.width - safeZoneSize.left - safeZoneSize.right
    const height =
      vdSpaceBoundingRect.height - safeZoneSize.top - safeZoneSize.bottom

    return {
      width,
      height,
      scaleX: width / vdSpaceBoundingRect.width,
      scaleY: height / vdSpaceBoundingRect.height,
      top: (vdSpaceBoundingRect.height / 2) * -1 + safeZoneSize.top,
      left: (vdSpaceBoundingRect.width / 2) * -1 + safeZoneSize.left,
      strokeWidth: 0,
      inverted: true,
    }
  }
}
