var _a;
import TAPE_CONSTS from "../generalConfig/consts/tapeConsts";
var TAPE_PRODUCTS_EDITOR_LIMITATIONS = {
    shared: {
        initialActiveSide: {
            activeSideId: "front",
            editedSideId: "front",
        },
        isPantoneColorsAvailable: true,
        isGlobalAssetDropDisabled: true,
    },
    products: (_a = {},
        _a[TAPE_CONSTS.TAPE_MODEL_PACKING_TAPE] = {
            productEditorMode: "2D",
        },
        _a[TAPE_CONSTS.TAPE_MODEL_KRAFT_TAPE] = {
            productEditorMode: "2D",
            reforestationSupport: {
                badge: false,
                trees: true,
            },
        },
        _a[TAPE_CONSTS.PRE_PRINTED_KRAFT_TAPE] = {
            is3dEditorDisabled: true,
        },
        _a),
};
export default TAPE_PRODUCTS_EDITOR_LIMITATIONS;
