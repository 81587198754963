import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"
import { useDropzone } from "react-dropzone"
import { TType } from "dsl/src/atoms/Typography/Typography"
import { CardTool } from "../../../atoms/CardTool/CardTool"
import { Upload } from "../../Upload/Upload"
import { UploadDropZone } from "../../Upload/UploadDropzone"
import { AssetsUploaderFileSpec } from "../../Upload/UploadFiletypesInfo"
import { TM } from "../../../../TypographyI18n"
import { EditorToolList } from "../EditorToolList"
import { useTranslate } from "../../../../hooks/useTranslate"
import { Thumb88 } from "../../../atoms/Thumb88/Thumb88"
import { Utilities, UtilityItem } from "../../../molecues/Utilities/Utilities"

import { ImageAssetItemStore } from "../../../../../stores/assets-store/image-asset-item.store"
import { Badge } from "dsl/src/atoms/Badge/Badge"
import { I18N } from "../../../../i18n"

import styles from "./EditorToolImagesUploader.module.scss"
import { UploadFailedFilePreview } from "../../Upload/UploadFailedFilePreview"
import { getTranslationKeyForAssetError } from "../../../../../services/asset-service/asset-errors"
import { useRootStore } from "../../../../hooks/useRootStore"
import { EditContext } from "../../../../../libs/products-render-config/types"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { DesignAndProductDriverContainer } from "../../../../../_containers/design-and-product-driver-container"
import { TemplatesMaybeContainer } from "../../../../../_containers/templates-maybe-container"

export const FailedAssetList = ({
  assets,
  onRemove,
}: {
  assets: ImageAssetItemStore[]
  onRemove: (imageAssetStore: ImageAssetItemStore) => void
}) => {
  const t = useTranslate()

  if (assets.length === 0) {
    return null
  }

  return (
    <>
      {assets.map((asset, key) => {
        return (
          <UploadFailedFilePreview
            name={asset.name}
            error={t(getTranslationKeyForAssetError(asset.error!))}
            onRemove={() => {
              onRemove(asset)
            }}
            key={key}
          />
        )
      })}
    </>
  )
}

export const AvailableAssetList = observer(
  ({
    title,
    assets,
    onSelect,
    isCaptionVisible = true,
    isDropdownVisible = true,
    isBadgeVisible = true,
  }: {
    title: string
    assets: ImageAssetItemStore[]
    onSelect: (imageAssetStore: ImageAssetItemStore) => void
    isCaptionVisible?: boolean
    isDropdownVisible?: boolean
    isBadgeVisible?: boolean
  }) => {
    if (assets.length === 0) {
      return null
    }

    return (
      <>
        <TM id={title} type={TType.Header17_500} className={styles.library} />
        <EditorToolList size="small">
          {assets.map((imageAssetStore, key) => {
            const isDisabled = imageAssetStore.error
              ? false
              : !imageAssetStore.hasTransformed

            return (
              <Thumb88
                badge={
                  isBadgeVisible && (
                    <ImageBadge imageAssetStore={imageAssetStore} />
                  )
                }
                key={imageAssetStore.name + key}
                onSelect={() => {
                  if (!imageAssetStore.hasTransformed) {
                    return
                  }
                  onSelect(imageAssetStore)
                }}
                disabled={isDisabled}
                imageURL={imageAssetStore.previewUri}
                caption={isCaptionVisible ? imageAssetStore.name : undefined}
                isLoading={
                  imageAssetStore.isTransforming || imageAssetStore.isUploading
                }
                error={imageAssetStore.error}
                tooltipItem={
                  isDropdownVisible && (
                    <ImageDropdown imageAssetStore={imageAssetStore} />
                  )
                }
                e2eTarget={
                  "uploaded-file-thumbnail" + (isDisabled ? "-disabled" : "")
                }
                e2eTargetName={key.toString()}
              />
            )
          })}
        </EditorToolList>
      </>
    )
  }
)

const ImageBadge = observer(
  ({ imageAssetStore }: { imageAssetStore: ImageAssetItemStore }) => {
    const t = useTranslate()
    const [containerSet] = useContainerSet((c) => [
      c.designAndProductDriver,
      c.backgroundImage,
    ])

    if (!containerSet) {
      return null
    }

    const { designAndProductDriver, backgroundImage } = containerSet
    const { backgroundImageUiController } = backgroundImage
    const { assetStore, productDriver } = designAndProductDriver
    const { productRenderPilot } = productDriver.state
    const logo = assetStore.logo
    const availableEditContexts = productRenderPilot.getAvailableEditContexts()
    const backgroundImageAssets =
      backgroundImageUiController.getBackgroundImageAssetStores()

    const i18n = I18N.component.assetsUploader

    const content: string[] = []

    if (imageAssetStore === logo) {
      content.push(t(i18n.labels.logo))
    }

    for (const editContext of availableEditContexts) {
      if (imageAssetStore === backgroundImageAssets[editContext]) {
        content.push(
          t(`component.assets-uploader.labels.background-image-${editContext}`)
        )
      }
    }

    if (content.length === 0) {
      return null
    }

    return <Badge>{content.join(",")}</Badge>
  }
)

const ImageDropdown = observer(
  ({ imageAssetStore }: { imageAssetStore: ImageAssetItemStore }) => {
    const { enablePrintingOptionModalController } = useRootStore()
    const t = useTranslate()

    const [containerSet] = useContainerSet((c) => [
      c.designAndProductDriver,
      c.backgroundImage,
    ])

    if (!containerSet) {
      return null
    }

    const { backgroundImage, designAndProductDriver } = containerSet

    const { backgroundImageUiController } = backgroundImage

    const { assetStore, productDriver, contextController } =
      designAndProductDriver

    const { productRenderPilot } = productDriver.state
    const logo = assetStore.logo
    const i18n = I18N.component.assetsUploader

    const isSettableAsLogo = imageAssetStore !== logo

    const availableEditContexts = productRenderPilot
      .getAvailableEditContexts()
      .filter((editContext) =>
        productRenderPilot.isPrintAvailableFor(editContext)
      )
    const backgroundImageAssets =
      backgroundImageUiController.getBackgroundImageAssetStores()

    const isAssetBackgroundImage = (
      editContext: EditContext,
      asset: ImageAssetItemStore
    ): boolean => {
      return asset === backgroundImageAssets[editContext]
    }

    return (
      <Utilities size="auto">
        {isSettableAsLogo && (
          <UtilityItem
            key="logo"
            name={t(i18n.setAsLogo)}
            onClick={async () => {
              await assetStore.setAssetAsLogo(imageAssetStore)
            }}
          />
        )}
        {productRenderPilot.uiConfig.features.backgroundImage && (
          <>
            {availableEditContexts.map((editContext, index) => {
              if (isAssetBackgroundImage(editContext, imageAssetStore)) {
                return null
              }

              return (
                <UtilityItem
                  key={`add-background-image-${index}`}
                  name={t(
                    `component.editor-tool.assets-uploader.add-as-background-image-${editContext}`
                  )}
                  onClick={async () => {
                    enablePrintingOptionModalController.showIfNecessary(
                      editContext,
                      async () => {
                        await backgroundImageUiController.applyBackgroundImage(
                          editContext,
                          imageAssetStore
                        )

                        contextController.setModelByEditContext(editContext)
                      }
                    )
                  }}
                />
              )
            })}
            {availableEditContexts.map((editContext, index) => {
              if (!isAssetBackgroundImage(editContext, imageAssetStore)) {
                return null
              }

              return (
                <UtilityItem
                  key={`remove-background-image-${index}`}
                  name={t(
                    `component.editor-tool.assets-uploader.remove-background-image-${editContext}`
                  )}
                  onClick={async () => {
                    await backgroundImageUiController.removeBackgroundImage(
                      editContext
                    )

                    contextController.setModelByEditContext(editContext)
                  }}
                />
              )
            })}
          </>
        )}
        <UtilityItem
          name={t(i18n.removeFile)}
          onClick={async () => {
            await backgroundImageUiController.removeBackgroundImagesByImageAssetStore(
              imageAssetStore
            )

            assetStore.removeAsset(imageAssetStore)
          }}
        />
      </Utilities>
    )
  }
)

export const EditorToolImagesUploaderWrapper = observer(() => {
  const [containerSet] = useContainerSet((c) => [
    c.templates,
    c.designAndProductDriver,
  ])

  if (!containerSet) {
    return null
  }

  const { templates, designAndProductDriver } = containerSet

  return (
    <EditorToolImagesUploader
      templates={templates}
      designAndProductDriver={designAndProductDriver}
    />
  )
})

export const EditorToolImagesUploader = observer(
  ({
    templates,
    designAndProductDriver,
  }: {
    templates: TemplatesMaybeContainer
    designAndProductDriver: DesignAndProductDriverContainer
  }) => {
    const { productDriver, assetStore } = designAndProductDriver
    const { templatesStore } = templates

    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
          assetStore.addAssetFromFile(file)
        })
      },
      [assetStore]
    )

    const { getRootProps, getInputProps } = useDropzone({
      onDrop: onDrop,
      multiple: true,
    })

    const onAssetRemove = (imageAssetStore: ImageAssetItemStore) => {
      assetStore.removeAsset(imageAssetStore)
    }

    const onYourLibraryAssetSelected = (
      imageAssetStore: ImageAssetItemStore
    ) => {
      productDriver.addImage(imageAssetStore.getImageAsset())
    }

    const onTemplateLibraryAssetSelected = (
      imageAssetStore: ImageAssetItemStore
    ) => {
      productDriver.addImage(imageAssetStore.getImageAsset(), {
        templateId: templatesStore.selectedTemplateId!,
      })
    }

    return (
      <>
        <CardTool sizeM>
          <TM
            className={styles.header}
            type={TType.Header17_500}
            id={I18N.editorTools.personalize.uploadImage}
          />
          <UploadDropZone
            inputProps={getInputProps()}
            rootProps={getRootProps()}
          >
            <Upload />
          </UploadDropZone>
          <AssetsUploaderFileSpec />
          <FailedAssetList
            assets={assetStore.failedAssets}
            onRemove={onAssetRemove}
          />
          <AvailableAssetList
            title={I18N.editorTools.assets.library}
            assets={assetStore.successUserAssets}
            onSelect={onYourLibraryAssetSelected}
          />
          <AvailableAssetList
            title={I18N.editorTools.assets.templateLibrary}
            assets={assetStore.successTemplateAssets}
            onSelect={onTemplateLibraryAssetSelected}
            isCaptionVisible={false}
            isBadgeVisible={false}
            isDropdownVisible={false}
          />
        </CardTool>
      </>
    )
  }
)
