import React, { useEffect, useReducer, useState } from "react"

import { DROPDOWN_ACTION, reducer } from "../../utils/wallet.state"
import { OutOfBoundsClickManager } from "../../../../../shared-components/OutOfBoundsClickManager"
import { WalletSidebar } from "../wallet-tooltip/WalletSidebar"
import { WalletButton } from "./WalletButton"
import { WalletDropdownProps } from "../../utils/wallet.types"
import {
  trackTooltipClicked,
  trackTooltipViewed,
  WalletTooltipClickedLocations,
} from "../../utils/wallet.segment"
import { getOrdersData } from "../../utils/wallet.helpers"
import styles from "./WalletDropdown.module.scss"

export const WalletDropdown = ({
  linkSegmentTrackClick,
  simpleWallet,
  totalPrice,
  walletData,
  forceOpen,
  buttonVersion = "v1",
}: WalletDropdownProps) => {
  const initialState = {
    isWalletOpened: Boolean(forceOpen),
    isShopOpened: false,
    isUserAreaOpened: false,
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const [ordersData, setOrdersData] = useState(null)

  const { isWalletOpened } = state

  useEffect(() => {
    let isMounted = true
    getOrdersData().then((data) => {
      if (isMounted && data !== null) {
        setOrdersData(data.orders)
      }
    })

    return () => {
      isMounted = false
    }
  }, [])

  const onWalletButtonClick = () => {
    trackTooltipViewed()
    dispatch(DROPDOWN_ACTION.TOGGLE_WALLET)
  }

  return (
    <div className={styles.wallet}>
      <WalletButton
        onClick={onWalletButtonClick}
        isWalletOpened={isWalletOpened}
        walletData={walletData}
        simpleWallet={simpleWallet || buttonVersion === "v2"}
        buttonVersion={buttonVersion}
      />
      <OutOfBoundsClickManager
        state={isWalletOpened}
        onOutsideClick={() => dispatch(DROPDOWN_ACTION.CLOSE_WALLET)}
        component={(ref) => (
          <div ref={ref}>
            {isWalletOpened && (
              <WalletSidebar
                linkSegmentTrackClick={
                  linkSegmentTrackClick
                    ? linkSegmentTrackClick
                    : () =>
                        trackTooltipClicked(
                          WalletTooltipClickedLocations.Navigation
                        )
                }
                onCloseButtonClick={() =>
                  dispatch(DROPDOWN_ACTION.CLOSE_WALLET)
                }
                walletData={walletData}
                totalPrice={totalPrice}
                orders={ordersData}
                type="wallet-balance"
              />
            )}
          </div>
        )}
      />
    </div>
  )
}
