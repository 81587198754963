var _a;
import PAPER_BAG_CONSTS from "../../bagProductSpec/generalConfig/consts/bagConsts";
var PAPER_BAG_RENDER_CONFIG = (_a = {},
    _a[PAPER_BAG_CONSTS.PAPER_BAG_MODEL_DEFAULT] = {
        B63: {
            width: 160,
            height: 390,
            length: 80,
            bagHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 105,
                        left: 30,
                        width: 100,
                        height: 260,
                    },
                    back: {
                        top: 105,
                        left: 30,
                        width: 100,
                        height: 260,
                    },
                },
            },
        },
        B66: {
            width: 240,
            height: 320,
            length: 100,
            bagHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 115,
                        left: 40,
                        width: 160,
                        height: 180,
                    },
                    back: {
                        top: 115,
                        left: 40,
                        width: 160,
                        height: 180,
                    },
                },
            },
        },
        B70: {
            width: 240,
            height: 360,
            length: 100,
            bagHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 115,
                        left: 40,
                        width: 160,
                        height: 220,
                    },
                    back: {
                        top: 115,
                        left: 40,
                        width: 160,
                        height: 220,
                    },
                },
            },
        },
        B82: {
            width: 305,
            height: 340,
            length: 170,
            bagHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 115,
                        left: 50,
                        width: 205,
                        height: 200,
                    },
                    back: {
                        top: 115,
                        left: 50,
                        width: 205,
                        height: 200,
                    },
                },
            },
        },
        B95: {
            width: 330,
            height: 500,
            length: 120,
            bagHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 110,
                        left: 50,
                        width: 230,
                        height: 350,
                    },
                    back: {
                        top: 110,
                        left: 50,
                        width: 230,
                        height: 350,
                    },
                },
            },
        },
        B107: {
            width: 500,
            height: 390,
            length: 180,
            bagHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 115,
                        left: 100,
                        width: 300,
                        height: 240,
                    },
                    back: {
                        top: 115,
                        left: 100,
                        width: 300,
                        height: 240,
                    },
                },
            },
        },
    },
    _a[PAPER_BAG_CONSTS.DOYPACK_BAG_MODEL] = {
        D35: {
            width: 110,
            height: 170,
            length: 70,
            bagHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 47.5,
                        left: 30,
                        width: 50,
                        height: 75,
                    },
                    //
                    back: {
                        top: 1,
                        left: 1,
                        width: 1,
                        height: 1,
                    },
                },
            },
        },
        D42: {
            width: 130,
            height: 210,
            length: 80,
            bagHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 42.5,
                        left: 27.5,
                        width: 75,
                        height: 125,
                    },
                    // bacause paper bags have no back, but bags have, so :/
                    back: {
                        top: 1,
                        left: 1,
                        width: 1,
                        height: 1,
                    },
                },
            },
        },
        D48: {
            width: 160,
            height: 230,
            length: 90,
            bagHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 52.5,
                        left: 42.5,
                        width: 75,
                        height: 125,
                    },
                    // bacause paper bags have no back, but bags have, so :/
                    back: {
                        top: 1,
                        left: 1,
                        width: 1,
                        height: 1,
                    },
                },
            },
        },
        D56: {
            width: 190,
            height: 260,
            length: 110,
            bagHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 67.5,
                        left: 57.5,
                        width: 75,
                        height: 125,
                    },
                    // bacause paper bags have no back, but bags have, so :/
                    back: {
                        top: 1,
                        left: 1,
                        width: 1,
                        height: 1,
                    },
                },
            },
        },
    },
    _a[PAPER_BAG_CONSTS.TOTE_BAG] = {
        TB79: {
            width: 110,
            height: 170,
            length: 70,
            bagHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 47.5,
                        left: 30,
                        width: 250,
                        height: 250,
                    },
                    back: {
                        top: 47.5,
                        left: 30,
                        width: 250,
                        height: 250,
                    },
                },
            },
        },
    },
    _a);
export default PAPER_BAG_RENDER_CONFIG;
