var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
import PRODUCTION_TIMES_CONSTS from "../../../productSharedConsts/productionTimeConsts";
import POLY_MAILER_PRODUCT from "../consts/polyMailerConsts";
var DEFAULT = PRODUCTION_TIMES_CONSTS.DEFAULT, EXPRESS = PRODUCTION_TIMES_CONSTS.EXPRESS, SUPEREXPRESS = PRODUCTION_TIMES_CONSTS.SUPEREXPRESS, PRODUCTION_TIME = PRODUCTION_TIMES_CONSTS.PRODUCTION_TIME, DTP_DEADLINE = PRODUCTION_TIMES_CONSTS.DTP_DEADLINE, HIGH_QUANTITY = PRODUCTION_TIMES_CONSTS.HIGH_QUANTITY;
var POLY_MAILER_PRODUCTION_TIME = (_a = {},
    _a[POLY_MAILER_PRODUCT.POLY_MAILER] = (_b = {},
        _b[POLY_MAILER_PRODUCT.POLY_MAILER_VARIANT_STANDARD] = (_c = {},
            _c[PRODUCTION_TIME] = (_d = {},
                _d[DEFAULT] = 16,
                _d[EXPRESS] = 11,
                _d[SUPEREXPRESS] = 5,
                _d[HIGH_QUANTITY] = (_e = {},
                    _e[5000] = { min: 17, max: 22 },
                    _e),
                _d),
            _c[DTP_DEADLINE] = (_f = {},
                _f[DEFAULT] = 1,
                _f),
            _c),
        _b),
    _a[POLY_MAILER_PRODUCT.POLY_MAILER_BIO] = (_g = {},
        _g[POLY_MAILER_PRODUCT.POLY_MAILER_BIO_VARIANT_STANDARD] = (_h = {},
            _h[PRODUCTION_TIME] = (_j = {},
                _j[DEFAULT] = 16,
                _j[EXPRESS] = 11,
                _j[SUPEREXPRESS] = 5,
                _j[HIGH_QUANTITY] = (_k = {},
                    _k[2500] = { min: 32, max: 37 },
                    _k),
                _j),
            _h[DTP_DEADLINE] = (_l = {},
                _l[DEFAULT] = 1,
                _l),
            _h),
        _g),
    _a[POLY_MAILER_PRODUCT.POLY_MAILER_BIO_PLAIN] = (_m = {},
        _m[POLY_MAILER_PRODUCT.POLY_MAILER_BIO_PLAIN_VARIANT_STANDARD] = (_o = {},
            _o[PRODUCTION_TIME] = (_p = {},
                _p[DEFAULT] = 3,
                _p[EXPRESS] = 3,
                _p[SUPEREXPRESS] = 3,
                _p[HIGH_QUANTITY] = (_q = {},
                    _q[2500] = { min: 17, max: 32 },
                    _q),
                _p),
            _o[DTP_DEADLINE] = (_r = {},
                _r[DEFAULT] = 0,
                _r),
            _o),
        _m),
    _a[POLY_MAILER_PRODUCT.POLY_MAILER_RECYCLED] = (_s = {},
        _s[POLY_MAILER_PRODUCT.POLY_MAILER_RECYCLED_VARIANT_STANDARD] = (_t = {},
            _t[PRODUCTION_TIME] = (_u = {},
                _u[DEFAULT] = 16,
                _u[EXPRESS] = 11,
                _u[SUPEREXPRESS] = 5,
                _u[HIGH_QUANTITY] = (_v = {},
                    _v[5000] = { max: 42 },
                    _v),
                _u),
            _t[DTP_DEADLINE] = (_w = {},
                _w[DEFAULT] = 1,
                _w),
            _t),
        _s),
    _a);
export default POLY_MAILER_PRODUCTION_TIME;
