import { User } from "./session.types"

export enum SessionEventNames {
  receivedToken = "ph.token.received",
  receivedUser = "ph.user.received",
}

export type SessionEventTypes = {
  [SessionEventNames.receivedToken]: (token) => void
  [SessionEventNames.receivedUser]: (user: User) => void
}
