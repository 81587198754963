import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"

import { useCurrency } from "../../../hooks/useCurrency"
import { Pattern } from "../../../../libs/value-objects/pattern"
import {
  ColoursPreset,
  MonochromeColours,
} from "../../../../libs/products-render-config/types"
import { paintSVGInDom } from "../../../../libs/helpers/paint-pattern-in-dom"

import { CurrencyLabel } from "../../atoms/CurrencyLabel/CurrencyLabel"
import { PatternThumbXLMemoized } from "../../atoms/ThumbXL/ThumbXL"
import { PatternsStore } from "../../../../stores/patterns-store/patterns.store"

type PatternTileBaseProps = {
  coloursPreset: ColoursPreset
  isSelected: boolean
  onPatternClick: () => void
  pattern: Pattern
  patternsStore: PatternsStore
}

type PatternTileProps = PatternTileBaseProps & {
  isPlaceholder?: boolean
}

enum PatternColours {
  monochromeWhiteInk = "#E6C9A1",
  monochromeBlackInk = "fff",
}

export const VirtualizedPatternTile = (props: PatternTileBaseProps) => {
  const [ref, inView] = useInView({
    threshold: 0,
  })

  return (
    <div ref={ref}>
      <PatternTile isPlaceholder={!inView} {...props} />
    </div>
  )
}

export const PatternTile = ({
  coloursPreset,
  isPlaceholder = false,
  isSelected,
  onPatternClick,
  pattern,
  patternsStore,
}: PatternTileProps) => {
  const [patternElement, setPattern] = useState<SVGElement | null>(null)
  const currency = useCurrency()
  useEffect(() => {
    if (patternElement && pattern) {
      paintSVGInDom(patternElement, pattern)
    }
  }, [patternElement, pattern])

  const patternData = patternsStore.getPatternPricing(pattern)

  const getPictureBackground = () => {
    if (coloursPreset.colourMonochrome === MonochromeColours.WHITE) {
      return PatternColours.monochromeWhiteInk
    } else {
      return PatternColours.monochromeBlackInk
    }
  }

  return (
    <PatternThumbXLMemoized
      currencyLabel={
        <CurrencyLabel
          isPerPiece={false}
          isAdditionalPrice={true}
          price={patternData.displayPrice}
          currency={currency}
        />
      }
      isPlaceholder={isPlaceholder}
      isSelected={isSelected}
      onClick={onPatternClick}
      pattern={pattern}
      pictureBackground={getPictureBackground()}
      setPattern={setPattern}
    />
  )
}
