import * as shared from "../shared/shared-consts"

const Region = {
  trustpilotId: shared.TRUSTPILOT.trustpilotId,
  trustpilotBusinessId: shared.TRUSTPILOT.trustpilotBusinessId,
  trustpilotInvitationTemplateId:
    shared.TRUSTPILOT.trustpilotInvitationTemplateId,

  googleapisKey: shared.GOOGLEAPIS_KEY,
  mainRegion: true,
  hasVat: true,
  languageTag: "it-IT",
  iterableLocale: "it-IT",
  locale: "it",
  phoneCountryCode: "+39",
  defaultShippingCountryCode: "IT",
  mainDomain: shared.PL_MAIN_DOMAIN,

  secondaryAnalyticsCode: "UA-71580330-13",
  productRegion: "it",
  countryCode: "it",
  emailAddress: "hello@packhelp.it",
  emailSenderName: "Packhelp.it",

  logoSVGIcon: "v3-packhelp_color_on_white",
  logoSVGIconWhite: "v3-packhelp_white_logo_on_black",

  additionalCustoms: 0,

  pageTitle: "Editor - Packhelp.it",
  footerLabel: "Packhelp",
  frontendBoxDesignRoute: "box/design",
  frontendAccountOrdersRoute: "account/orders",

  frontendTermsOfServiceRoute: "/terms_of_service/", // english is on purpose
  frontendPrivacyPolicyRoute: "/privacy_policy/", // english is on purpose

  domain: {
    basename: "packhelp",
    tld: "it",
  },
  externalLinks: {
    qrForm: "/quote-request",
    affiliates: "/diventa-un-affiliato/",
    fscCertification: "/certificazione-fsc/",
    helpPlantTrees: "/piantare-alberi/",
    ecoProperties: "/guida-alle-proprieta-ecologiche/",
    sustainability: "/sostenibilita/",
    designShowcase: "/nostri-design-in-vetrina/",
    holidays: "/festivita-natalizie/",
    inspirationsHandbook: "/inspirations-handbook/",
    mobileAppLandingPageUrl: "/app-per-dispositivi-mobili/",
    brief: "/brief/",
    artworkTipsDielines: "/suggerimenti-grafici-e-modelli/",
    help: "/aiuto-domande-frequenti-buone-pratiche/",
    thankYou: "/grazie/",
    designGuidelines: "/pomoc/tematy/editeur-de-boites/",
    about: "/chi-siamo/",
    career: "/carriera/",
    shop: "/negozio/",
    services: "/services/",
    industries: "/i/",
    bundles: "/set-di-packaging",
    inspirations: "/lasciati-ispirare/",
    impressum: "/impressum/",
    returnPolicy: "/aiuto/tema/posso-restituire-le-scatole-che-ho-ordinato/",
    blog: "/blog/",
    contact: "/contatti/",
    privacyPolicy: "/privacy_policy/",
    termsOfService: "/terms_of_service/",
    termsOfServicePlus: "/terms-of-services-plus/",
    accountSignOut: "/sign_out/",
    accountSignIn: "/sign_in/",
    accountRegister: "/registration",
    accountOrders: "/account/orders",
    accountOrderDetails: "/account/orders/:orderNumber",
    accountSettings: "/account/settings",
    accountSupportReforestation: "/account/support-reforestation",
    accountBoxes: "/account/boxes",
    accountWallet: "/account/packhelp-wallet",
    accountCart: "/cart/", // old checkout key - to be removed
    samples: "/sample-packs",
    customOffer: "/offer/:token/review",
    payment: "/order/:orderId/payment",
    paymentSummary: "/order/:orderId/payment/summary",
    quoteRequest: "account/quote_requests/:qrNumber",
    cart: "/cart/",
    orderDetails: "/order",
    crossSell: "/cart/recommended-products-and-services/s",
    fullAndEcoColorHelpSite:
      "/aiuto/tema/qual-e-la-differenza-tra-gli-standard-della-scatola-postale-colorata-e-della-scatola-postale-eco-colorata/",
    safeZonesHelpSite: "/aiuto/tema/tieniti-lontano-dai-bordi/",
    samplePacksLink:
      "/box/design?sku=box-fr--samples--samples--samples--samples--samples&dielineMode=false",
    termsAcceptedSite: "/termini-successo-accettate",
    packhelpWallet: "/packhelp-wallet-risparmiare-sul-packaging/",
    ecoBadge: "/eco-badge-packhelp/",
    leadership: "/la-leadership-packhelp/",
    press: "/stampa/",
    urlWhitelistedToBeFetchedFromUrl: shared.urlWhitelistedToBeFetchedFromUrl,
    products: {
      "mailer-box-plain": "/scatola-postale-senza-stampa/",
      "mailer-box-eco": "/scatola-postale-eco/",
      "mailer-box-eco-white": "/scatola-postale-eco-bianca/",
      "mailer-box-full-color": "/scatola-postale-colorata/",
      "mailer-box-eco-color": "/scatola-postale-eco-colorate/",
      "delivery-mailer-box": "/scatole-per-ecommerce/",
      "delivery-mailer-box-plain": "/scatole-per-ecommerce-senza-stampa/",
      "shipping-box-plain": "/scatola-per-spedizioni-senza-stampa/",
      "shipping-box": "/scatole-per-spedizioni/",
      "product-box": "/scatola-per-prodotti-classica/",
      "product-box-two-pieces": "/scatola-per-prodotti-in-due-pezzi/",
      "poly-mailer": "/sacchetti-postali-classiche/",
      "paper-mailer": "/buste-sacco/",
      "paper-bag": "/sacchetti-di-carta-con-manico-ritorto/",
      "rigid-envelope": "/buste-di-cartone-personalizzate/",
      "product-box-wine-box": "/scatole-per-bottiglie-personalizzate/",
      "tissue-paper": "/carta-velina-personalizzata/",
      "packing-paper": "/carta-kraft/",
      "packing-tape": "/nastri-adesivi-da-imballaggio/",
      "sleeved-mailer-box": "/scatole-con-fasce/",
      "kraft-tape": "/nastri-adesivi-kraft/",
      "bio-poly-mailer": "/sacchetti-postali-compostabli/",
      "paper-can": "/lattina-in-cartone/",
      "mailer-box-white-ink": "/scatole-postali-bianco-su-kraft/",
      "mailer-box-full-color-double-sided":
        "/scatola-postale-stampata-all-interno/",
      "rigid-box": "/scatola-rigida/",
      "rigid-box-two-pieces": "/scatola-rigida-coperchio/",
      "special-box": "/scatola-speciale-su-misura/",
      "magnetic-box": "/scatola-magnetica/",
      "carrier-box": "/scatola-in-cartone-con-maniglia/",
      "rigid-drawer-box": "/scatola-di-cartone-del-cassetto/",
      "cake-box": "/scatola-per-torta/",
      "bio-poly-mailer-plain": "/sacchetto-postale-compostabile-prestampato/",
      "pre-printed-mailer-box-eco": "/scatola-postale-eco-prestampata/",
      "pre-printed-mailer-box-full-colour":
        "/scatola-postale-colorata-prestampata/",
      "doypack-bag": "/busta-stand-up/",
      "square-label": "/etichette-personalizzate/",
      "tote-bag": "/borsa-di-stoffa/",
      "printed-card": "/biglietti-di-ringraziamento/",
      "pre-printed-kraft-tape": "/nastro-adesivo-kraft-prestampato/",
      "recycled-poly-mailer": "/sacchetto-postale-riciclato/",
    },
    categories: {
      "mailer-boxes": "/scatole-postali/",
      "custom-shipping-boxes": "/scatole-per-spedizioni-1/",
      "primary-packaging": "/scatole-per-prodotti/",
      "eco-friendly-packaging": "/packaging-ecosostenibile/",
      "luxury-packaging": "/scatole-rigide/",
    },
    fb_url: shared.EU_MAIN_DOMAIN,
    fb_sitename: "Packhelp",
    fb_image: "/share_image_1200x630.png",
    share_img: "/share_image_120x120.png", // Editor
    boxEditorPath: "/box/design",
    productEditor: "/editor/product/design",
    exampleDesign: "79485?language=it&templateMode=true",
    productQuotationSystem: "/quote",
    pqsSuccessPage: "/quote/success",
    plus: {
      catalogue: "/plus/catalogo/",
      successPage: "/plus/success",
      items: "/packhelp-plus/items",
      quotes: "/packhelp-plus/quotes",
      quoteShow: "/packhelp-plus/quotes/:token",
      orders: "/packhelp-plus/orders",
      orderShow: "/packhelp-plus/orders/:orderNumber/details",
      orderPlaced: "/packhelp-plus/orders/:orderNumber/placed",
      orderItems: "/packhelp-plus/orders/:orderNumber/items",
      orderPaymentSummary: "/packhelp-plus/orders/:orderNumber/payment-summary",
      "doypack-bag": "/plus/catalogo/buste-stand-up/",
      personalDetails: "/packhelp-plus/personal-details",
      "tote-bag": "/plus/catalogo/borsa-di-stoffa-personalizzata/",
      "product-boxes": "plus/catalogo/tipo-di-confezione/scatole-pieghevoli/",
      "mailer-boxes": "plus/catalogo/tipo-di-confezione/scatole-postali/",
    },
    pro: {
      features: "pro/features/",
      suppliers: "pro/for-suppliers/",
      termsOfService: "pro/terms-conditions/",
    },
    industriesListing: "/i/",
    whiteLabel: "/enterprise/",
  },

  outsidePackhelpDomainLinks: {
    ...shared.externalDomainPackhelpLinks,
  },

  ...shared.SHARED_ECOMMERCE_PROPERTIES.eu,
  ...shared.SHARED_CURRENCY_PROPERTIES.eur,
  allowedPaymentMethods: [
    "paypal",
    "check",
    "stripe",
    "p24",
    "ideal",
    "bancontact",
    "eps",
    "giropay",
    "card",
  ],

  remindMeOnDesktop: {
    remindme_form_url:
      "//packhelp.us12.list-manage.com/subscribe/post-json?u=2060c1de14ec30d28a9fab813&amp;id=489822501e&c=?",
    remindme_hidden_input_name: "b_2060c1de14ec30d28a9fab813_489822501e",
  },

  datesFormattingRules: shared.DATES_FORMATTING_RULES_EU,

  googleConversionId: "859901857",
  googleAnalytics: {
    primaryCode: "UA-71580330-2",
    secondaryCode: "UA-71580330-13",
    segmentCode: "uXDomf9GW2BCffFseKvLrarxNiGNLI5O",
  },
  googleAdWords: {
    remarketingCode: "39198",
    conversionCode: "859901857",
  },

  facebookAppId: "1660833110841347",
  supportsPlus: true,
}

export default Region
