import React, { FC } from "react"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import Link from "dsl/src/atoms/Link/Link"
import { ReactComponent as IconArrowLeft } from "../../../assets/svg/ArrowLeft.svg"

import styles from "./PickerHeader.module.scss"

export interface PickerHeaderProps {
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void
  title: string
}

const PickerHeader: FC<PickerHeaderProps> = ({ onClick, title }) => (
  <div className={styles.wrapper}>
    <Link href="#" underlined={false} onClick={onClick}>
      <IconArrowLeft className={styles.icon} />
      <Typography type={TType.Header15_500}>{title}</Typography>
    </Link>
  </div>
)

export { PickerHeader as default }
