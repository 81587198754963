export const toolbarPositionCalculator = (
  objectPosition: {
    boundingTop: number
    boundingLeft: number
    boundingHeight: number
  },
  playgroundNode,
  margin = 8
): { top: number; left: number } => {
  const playground = playgroundNode.getBoundingClientRect()
  const tooltip =
    playgroundNode.firstElementChild.firstElementChild.getBoundingClientRect()
  const object = {
    top: objectPosition.boundingTop,
    left: objectPosition.boundingLeft,
    height: objectPosition.boundingHeight,
  }

  let top = object.top + object.height + margin
  let left = object.left

  const isOutsideLeftEdge = object.left < 0
  const isOutsideRightEdge = object.left > playground.width - tooltip.width
  const isOutsideTopEdge = top < 0
  const isNearBottomEdge = top > playground.height - object.height

  if (isOutsideLeftEdge) left = 0
  if (isOutsideRightEdge) left = playground.width - tooltip.width
  if (isOutsideTopEdge) top = 0
  if (isNearBottomEdge) top = object.top - tooltip.height - margin

  const isOutsideBottomEdge = top > playground.height - tooltip.height
  if (isOutsideBottomEdge) top = playground.height - tooltip.height

  return { top, left }
}
