import Basil from "shared-libs/src/js/libs/storage/basil.js"
import _compact from "lodash/compact"
import _lowerCase from "lodash/lowerCase"
import _sortBy from "lodash/sortBy"
import URI from "urijs"
// don't refactor to import. Swift death will follow
const qs = require("query-string")

import RegionConfig from "../../../configs/region-config/interfaces/js/region-config"
import { setRegionCookie, getRegionCookie } from "./region-switcher.cookies"
import { trackEvent } from "../analytics/segment/segment-analytics.methods"
import { PartiallySupportedCountry } from "../../../configs/region-config/interfaces/js/region-config.types"
import { isCompileMode } from "./is-compile-mode"

export type RegionSwitcherSelectedParams = {
  new_region: string
  z_last_used_locale: string
  z_last_used_region: string
}

export const setRegionCookieOnFirstTouch = (region: string) => {
  if (!getRegionCookie()) {
    setRegionCookie(region)
  }

  // We need to check if region that was set last time is region supported by packhelp app.
  // If region is supported we need to change region for current passed region. This if prevents showing
  // Polish flag when entering Packhelp.com (example). We supporting 3 different options for changing regions and
  // there are some issues with domain, path based regions and region switcher combined.
  if (
    RegionConfig.isRegionConfigured(getRegionCookie()) &&
    RegionConfig.isRegionConfigured(region)
  ) {
    setRegionCookie(region)
  }
}

export const setAnonymousIdFromUrl = () => {
  const segment = window.analytics
  const anonymousIdFromUrl = new URL(window.location.href).searchParams.get(
    "anonymousId"
  )

  if (!anonymousIdFromUrl) return
  segment.setAnonymousId(anonymousIdFromUrl)
}

export const getRegionSwitcherSelectedParams = (
  country: string
): RegionSwitcherSelectedParams => ({
  new_region: country,
  z_last_used_locale: RegionConfig.countryToLocale(country),
  z_last_used_region: getLastUsedRegionForSegmentEvent(country),
})

export const sendEventToSegmentOnSelectLocale = (country: string) => {
  trackEvent({
    event: "Region Switcher Selected",
    properties: getRegionSwitcherSelectedParams(country),
  })
}

export const getUrlQueryParams = (url: string) => {
  return new URI(url).search(true)
}

export const parseQueryString = (queryString: string) => {
  if (!queryString) return {}
  const query = queryString.startsWith("?") ? queryString : `?${queryString}`

  return getUrlQueryParams(query)
}

export const constructUrlWithQuery = (url: string, queryObject: object) => {
  let rawUrl = url.includes("?") ? url.split("?")[0] : url

  let query = qs.stringify(queryObject)

  query = query ? `?${query}` : query

  rawUrl = `${rawUrl}${query}`

  return rawUrl
}

export const getLastUsedRegionForSegmentEvent = (country?: string): string => {
  if (!country) {
    country = getRegionCookie()
  }

  if (!country) {
    return RegionConfig.getCurrentRegion()!
  }

  const isUK = country === "uk"

  const isAConfiguredRegion = RegionConfig.isRegionConfigured(country)

  const partiallySupportedCountries: PartiallySupportedCountry[] =
    RegionConfig.getKeyForCurrentRegion("partiallySupportedCountries")

  const isPartiallySupported = partiallySupportedCountries.includes(
    country.toUpperCase() as PartiallySupportedCountry
  )

  if (isPartiallySupported || isAConfiguredRegion) {
    return country
  }

  if (isUK) {
    return "gb"
  }

  const locale = RegionConfig.countryToLocale(country)

  return RegionConfig.translateLocaleToRegionId(locale)
}

export const reload = () => {
  window.location.reload()
}

export const getFlagPath = (flag: string) => {
  const pathPrefix =
    !isCompileMode &&
    RegionConfig.getCurrentApplicationType() === "landing" &&
    "/img"

  return _compact([pathPrefix, `/images/eu_countries/${flag}.svg`]).join("")
}

export const getSavedCountryCode = () => {
  const currentUrl = new URL(window.location.href)

  return currentUrl.searchParams.get("rememberRegion") || getRegionCookie()
}

export const redirectToUrl = (
  url: string,
  { keepBrowsingHistory }: { keepBrowsingHistory: boolean }
) => {
  if (keepBrowsingHistory) {
    window.location.assign(url)
  } else {
    window.location.href = url
  }
}

export const getDomainSuffix = (url: string = window.location.href) => {
  let domainSuffix = URI(url).tld()

  if (domainSuffix === "to") {
    domainSuffix = "pl"
  }

  return domainSuffix
}

export const getKnownCountry = (country: string) => {
  return RegionConfig.isRegionConfigured(country)
}

export const isUsingRegionForDetectedCountry = (country: string) => {
  const tld = RegionConfig.getKeyForRegion(country, "domain")["tld"]

  const regionalDomain = tld === "to" ? "pl" : tld

  const currentDomain = getDomainSuffix()

  return (
    currentDomain === regionalDomain &&
    (regionalDomain !== "com" || country === "eu")
  )
}

export const reportRegionSwitcherViewed = (source: string) => {
  trackEvent({
    event: "Region Switcher Viewed",
    properties: { source },
  })
}

export const storeRegionInCookie = (region: string) => {
  const configuredRegion = getKnownCountry(region) ? region : "eu"

  const currentCookieDomain = RegionConfig.getDomainHostnameWithDot()
  const targetCookieDomain =
    RegionConfig.getDomainHostnameWithDot(configuredRegion)

  // Preventing setting cookies for the wrong domain
  if (currentCookieDomain === targetCookieDomain) {
    setRegionCookie(region)
  }
}

export const setNewRedirectDomain = (country: string) => {
  return RegionConfig.isRegionConfigured(country)
    ? RegionConfig.getDomain(country, "landing")
    : RegionConfig.getDomain("eu", "landing")
}

export const getRedirectUrlForLanding = (country: string) => {
  let url: string

  if (typeof window === "undefined") return

  // https://github.com/packhelp/packhelp/actions/runs/6639414085/job/18037999615?pr=16219
  if (
    country ==
    "dont-know-why-but-frontier-need-this-if-check-even-if-will-never-be-true"
  ) {
    return RegionConfig.getSharedSettings()["externalUnitedStatesPageLink"]
  }

  // if (country == "us") {
  //   return RegionConfig.getSharedSettings()["externalUnitedStatesPageLink"]
  // }

  const regionSwitcherCountryList = document.querySelector(
    ".ph-region-switcher__country__list"
  )

  if (!regionSwitcherCountryList) {
    const isPathBasedRegion = RegionConfig.pathBasedRegions().includes(country)

    const { host, pathname, protocol } = window.location || {}

    if (isPathBasedRegion) {
      const locale = RegionConfig.convertRegionToIso639Locale(country)
      const localeWithCountryCode = `${locale}-${country}`
      if (pathname.includes(localeWithCountryCode)) {
        return
      }

      url = `${protocol}//${host}/${localeWithCountryCode}${pathname}`
    } else {
      const newLocation = setNewRedirectDomain(country)
      const domainSuffix = getDomainSuffix()
      const newLocationDomainSuffix = getDomainSuffix(newLocation)

      if (domainSuffix === newLocationDomainSuffix) {
        return
      } else {
        const newHost = host.replace(domainSuffix, newLocationDomainSuffix)
        url = `${protocol}//${newHost}${pathname}`
      }
    }
  } else {
    const detectedCountryRefLinkElement =
      regionSwitcherCountryList.querySelector(
        `[data-country="${country.toLowerCase()}"]`
      ) || regionSwitcherCountryList.querySelector(`[data-country="eu"]`)

    url = detectedCountryRefLinkElement
      ? detectedCountryRefLinkElement.getAttribute("data-redirect")!
      : ""
  }

  return url
}

export const getSbjsData = () => {
  const data = {
    sbjs_current: Basil.cookie.get("sbjs_current"),
    sbjs_current_add: Basil.cookie.get("sbjs_current_add"),
    sbjs_first: Basil.cookie.get("sbjs_first"),
    sbjs_first_add: Basil.cookie.get("sbjs_first_add"),
    sbjs_migrations: Basil.cookie.get("sbjs_migrations"),
    sbjs_session: Basil.cookie.get("sbjs_session"),
    sbjs_udata: Basil.cookie.get("sbjs_udata"),
  }

  return JSON.stringify(data)
}

export const selectDetectedCountry = (country: string) => {
  storeRegionInCookie(country)

  const redirectUrl = getRedirectUrlForLanding(country)

  if (!redirectUrl) return

  const url = constructUrlWithQuery(redirectUrl, {
    ...getUrlQueryParams(window.location.href),
    rememberRegion: country,
    sbjs_data: getSbjsData(),
  })

  redirectToUrl(url, { keepBrowsingHistory: false })
}

export const getCountries = (intl: any): { label: string; value: string }[] => {
  const countries = RegionConfig.getKeyForRegion(
    "eu",
    "regionSwitcherAllowedCountries"
  )

  let values = countries.map((CC: string) => {
    const cc = _lowerCase(CC)

    return {
      label: intl.formatMessage({
        id: `modals.region-switcher.countries.${cc}`,
      }),
      value: cc,
    }
  })

  values = _sortBy(values, ["label"])

  values.push({
    label: intl.formatMessage({
      id: "modals.region-switcher.countries.eu",
    }),
    value: "eu",
  })

  return values
}

export const redirect = (region: string) => {
  if (region == "us") {
    const url = RegionConfig.getSharedSettings()["externalUnitedStatesPageLink"]

    redirectToUrl(url, { keepBrowsingHistory: true })
    return false
  }

  const newLocation = RegionConfig.isRegionConfigured(region)
    ? RegionConfig.getDomain(region, "landing")
    : RegionConfig.getDomain("eu", "landing")

  const domainSuffix = getDomainSuffix()

  const newLocationDomainSuffix = getDomainSuffix(newLocation)

  if (domainSuffix === newLocationDomainSuffix) {
    storeRegionInCookie(region)

    reload()
  } else {
    const url = constructUrlWithQuery(newLocation, {
      ...getUrlQueryParams(window.location.href),
      rememberRegion: region,
      sbjs_data: getSbjsData(),
    })

    redirectToUrl(url, { keepBrowsingHistory: false })
  }
}

export const getCountryCode = () => {
  const savedCountryCode = getSavedCountryCode()

  const selectedCountry = RegionConfig.getKeyForCurrentRegion("countryCode")

  return savedCountryCode || selectedCountry
}
