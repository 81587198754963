import React from "react"

export const WalletIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4 6.8H18c.88 0 1.6.72 1.6 1.6v10.4c0 .44-.359.8-.8.8H1.2c-.44 0-.8-.36-.8-.8V7.6c0-.44.335-.934.743-1.097L16.401.4v6.4Zm-1.6 0V2.763L4.708 6.8H14.8Zm0 8H18v-3.2h-3.2c-.882 0-1.6.717-1.6 1.6 0 .882.718 1.6 1.6 1.6Zm0-4.8H18V8.4H2V18h16v-1.6h-3.2a3.2 3.2 0 0 1 0-6.4Zm.001 4a.8.8 0 1 0 0-1.598.8.8 0 0 0 0 1.598Z"
      fill="currentColor"
    />
  </svg>
)
