import { observable, action, makeObservable } from "mobx"
import ProductDriver from "../../product-driver/product.driver"
import { ProductDesignStore } from "../../product-design-store/product-design.store"
import { AllEditorEventsEmitter, eventTree } from "../../editor-events"
import {
  ChangeProductControllable,
  ChangeType,
  OriginalDesignData,
} from "./change-product.interface"
import { DbyModeStore } from "../../dby-mode-store/dby-mode-store"
import { VariantCustomization } from "@ph/product-api"

export class ChangeProductDbyMode implements ChangeProductControllable {
  @observable public originalDesign?: OriginalDesignData
  private changeType: ChangeType = "product"

  constructor(
    private readonly services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
    },
    private readonly stores: {
      productDesignStore: ProductDesignStore
      dbyModeStore: DbyModeStore
    }
  ) {
    makeObservable(this)
  }

  public isChangeRisky(): boolean {
    return !!this.stores.dbyModeStore.uploadedFile
  }

  @action
  public async changeSku(
    type: ChangeType,
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    if (this.services.productDriver.state.isProductChanging) {
      return
    }

    this.changeType = type

    if (!this.originalDesign) {
      this.cacheOriginalDesign()
    }

    await this.services.productDriver.changeSku(sku, customization)
    this.services.ee.emit(eventTree.productDriver.productChanged, type)
    this.stores.dbyModeStore.removeUploadedFile()

    this.services.productDriver.setIsProductChanging(false)
  }

  @action
  public cacheOriginalDesign(): void {
    const { productSku, customization } =
      this.services.productDriver.productStore

    this.setOriginalDesign({
      sku: productSku,
      customization,
      file: this.stores.dbyModeStore.uploadedFile,
    })
  }

  @action
  public setOriginalDesign(originalDesign: OriginalDesignData): void {
    this.originalDesign = originalDesign
  }

  @action
  public clearOriginalDesign(): void {
    this.originalDesign = undefined
  }

  public async cancel(): Promise<void> {
    if (!this.originalDesign) {
      return
    }

    await this.changeSku(
      this.changeType,
      this.originalDesign.sku,
      this.originalDesign.customization
    )
    this.stores.dbyModeStore.setUploadedFile(this.originalDesign.file)
    this.clearOriginalDesign()
  }
}
