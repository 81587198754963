var _a, _b, _c;
import BOX_CONSTS from "../consts/boxConsts";
var DEFAULT = "default";
var WEIGHTS_PRODUCT_BOXES_WINE_BOXES = (_a = {},
    _a[DEFAULT] = {
        P48: 0.047,
    },
    _a);
export default (_b = {},
    _b[BOX_CONSTS.PRODUCT_BOX_WINE_BOX] = (_c = {},
        _c[BOX_CONSTS.PRODUCT_BOX_WINE_BOX_DEFAULT_VARIANT] = WEIGHTS_PRODUCT_BOXES_WINE_BOXES,
        _c),
    _b);
