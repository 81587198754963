import tapeProductSpec from "../../../../source/tapeProductSpec/tapeProductSpec";
import ConfigBuilder from "../../configBuilders/ConfigBuilder";
// Global product behaviors composers
import ProductionTimeManager from "../../behaviorsComposers/ProductionTimeManager";
import GrammagesManager from "../../behaviorsComposers/GrammagesManager";
import AssetsManager from "../../behaviorsComposers/AssetsManager";
import WeightManager from "../../behaviorsComposers/WeightManager";
import WizzardManager from "../../behaviorsComposers/WizzardManager";
import SiblingsManager from "../../behaviorsComposers/SiblingsManager";
import EditConfigManager from "../../behaviorsComposers/EditConfigManager";
import QuantitiesManager from "../../behaviorsComposers/QuantitiesManager";
// Tape specific behaviors composers
import TapeVariantManager from "./behaviorsComposers/TapeVariantManager";
import TapeTranslationsManager from "./behaviorsComposers/TapeTranslationsManager";
import TapeRenderConfigManager from "./behaviorsComposers/TapeRendeConfigManager";
import TapeAddonsManager from "./behaviorsComposers/TapeAddonsManager";
import TapeDimensionsManager from "./behaviorsComposers/TapeDimensionsManager";
import ProductFactory from "../../ProductFactory";
var TapeProduct = /** @class */ (function () {
    function TapeProduct(sku, productName, productVariant) {
        this.config = this.builder(tapeProductSpec, sku, productName, productVariant);
        this.assetsManager = new AssetsManager(this);
        this.variantManager = new TapeVariantManager(this);
        this.grammagesManager = new GrammagesManager(this);
        this.productionTimeManager = new ProductionTimeManager(this);
        this.weightsManager = new WeightManager(this);
        this.wizzardManager = new WizzardManager(this);
        this.dimensionsManager = new TapeDimensionsManager(this);
        this.siblingsManager = new SiblingsManager(this);
        this.editConfigManager = new EditConfigManager(this);
        this.quantitiesManager = new QuantitiesManager(this);
        this.translationsManager = new TapeTranslationsManager(this);
        this.addonsManager = new TapeAddonsManager(this);
        this.renderConfigManager = new TapeRenderConfigManager(this);
    }
    TapeProduct.prototype.builder = function (spec, sku, productName, productVariant) {
        return ConfigBuilder(spec, sku, productName, productVariant);
    };
    TapeProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    TapeProduct.prototype.getExtraTechnicalDetails = function () {
        return undefined;
    };
    TapeProduct.prototype.getEditorBackgroundPath = function (side) {
        var material = this.variantManager.getMaterial();
        var productCodeName = this.variantManager.getCodeName();
        var name = "tape_".concat(productCodeName, "_").concat(material, "_").concat(side, ".png");
        return ("https://packhelp-files.s3.amazonaws.com/app/editor/backgrounds/tapes/" +
            name);
    };
    TapeProduct.prototype.createBaseProductSku = function () {
        return this.addonsManager.setDefaultAddons();
    };
    TapeProduct.prototype.getBaseProduct = function () {
        return ProductFactory(this.createBaseProductSku());
    };
    return TapeProduct;
}());
export default TapeProduct;
