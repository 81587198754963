import { LocaleConfig } from "../../../../app-config/configs/locale.config"

export const reloadPage = () => {
  window.location.reload()
}

export const isLocalePolish = (localeConfig: LocaleConfig) => {
  return localeConfig.lang === "pl"
}

export const isDeviceTablet = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  )
}

export const isBrowserSafari = (): boolean => {
  return !!navigator.userAgent.match(
    /(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari/
  )
}

export const isViewportTooSmall = (width: number, height: number) =>
  width < 1024 || height < 500
