// USED ONLY BY EDITOR
import {
  MediaInfo,
  TransformedAssetMediaInfo,
} from "@ph/editor-assets/dist/transformation-config"

// USED ONLY BY EDITOR
import { getInfo } from "@ph/editor-assets"
import {
  PackhelpEditableGroup,
  PackhelpEditableObject,
  PackhelpGroupType,
  PackhelpEditableImage,
  PackhelpObject,
  PackhelpEditableText,
  PackhelpMaskObject,
  PackhelpActiveSelection,
  PackhelpGroup,
  PackhelpEditableSvgImage,
  PackhelpEditableShape,
  PackhelpEditableLogoPlaceholderSlot,
} from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import {
  EditContext,
  ModelEditableSpaces,
} from "../../libs/products-render-config/types"
import { BackgroundsLayers } from "../../render-engine/modules/vd-editor/modules/backgrounds-module/types"
import fabric from "../../libs/vendors/Fabric"
import { getTempLayerIds } from "../../render-engine/modules/vd-editor/types/render-engine-types"

// EDITOR ASSETS?
// src/services/asset-service/uploader.ts
// Tons of meta
type UploadedAssetMeta = {
  width: number
  height: number
  filestackHandle: string
  filestackUrl: string
  phHandle: string
  url: string
  md5: string
}

export type MetaInfo = ReturnType<typeof getInfo>

// USED ONLY BY EDITOR
export type OriginalAssetInfo = MediaInfo &
  UploadedAssetMeta & {
    originalFilename: string
    originalName: string
    metaInfo: MetaInfo
  }

// USED ONLY BY EDITOR
export type TransformedAssetInfo = TransformedAssetMediaInfo & UploadedAssetMeta

// USED ONLY BY EDITOR
export type ImageAssetMetaT = {
  original: OriginalAssetInfo
  converted: { [key: string]: TransformedAssetInfo }
}

// src/render-engine/modules/vd-editor/object-extensions/packhelp-objects.ts
// USED ONLY BY EDITOR
export enum EditableObjectTypes {
  assetText = "asset-text",
  assetObject = "asset-object",
  assetImage = "asset-image",
  assetGroup = "asset-group",
  assetLogoPlaceholderSlot = "asset-logo-placeholder-slot",
}

export function isAssetText(
  object: PackhelpObject
): object is PackhelpEditableText {
  return object.assetType === EditableObjectTypes.assetText
}

export function isAssetGroup(
  object: PackhelpObject
): object is PackhelpEditableGroup {
  return object.assetType === EditableObjectTypes.assetGroup
}

export function isAssetLogoPlaceholderSlot(
  object: PackhelpObject
): object is PackhelpEditableLogoPlaceholderSlot {
  return object.assetType === EditableObjectTypes.assetLogoPlaceholderSlot
}

export function isAssetLogoImage(
  object: PackhelpObject
): object is PackhelpEditableImage | PackhelpEditableSvgImage {
  return !!((isAssetImage(object) || isAssetSvgImage(object)) && object.isLogo)
}

export function isAssetImage(
  object: PackhelpObject
): object is PackhelpEditableImage {
  return object.assetType === EditableObjectTypes.assetImage
}

export function isAssetMask(
  object: PackhelpObject
): object is PackhelpMaskObject {
  return !!object.maskParentId
}

export function isAssetShape(
  object: PackhelpObject
): object is PackhelpEditableShape {
  return (
    object.assetType === EditableObjectTypes.assetObject &&
    !!object.assetObjectMeta
  )
}

export function isAssetSvgImage(
  object: PackhelpObject
): object is PackhelpEditableSvgImage {
  return (
    object.assetType === EditableObjectTypes.assetObject &&
    !!object.assetImageMeta
  )
}

export function isActiveSelection(
  object: PackhelpEditableObject
): object is PackhelpActiveSelection {
  return object.type === PackhelpGroupType.activeSelection
}

export function isEditableObject(
  object: PackhelpObject
): object is PackhelpEditableObject {
  const hasSpace = !!object.originSpaceArea
  const hasProperAssetType = !!(
    object.assetType &&
    Object.values(EditableObjectTypes).includes(object.assetType)
  )
  const isTempObject = getTempLayerIds().includes(object.id)

  return hasSpace && hasProperAssetType && !isTempObject
}

export function is2dInterfaceObject(object: PackhelpObject): boolean {
  return isAssetLogoPlaceholderSlot(object)
}

export function isBackgroundTexture(
  object: PackhelpObject
): object is PackhelpEditableGroup {
  return object.id === BackgroundsLayers.BACKGROUND_TEXTURE
}

export function isBackgroundImage(object: PackhelpObject): boolean {
  return object.id === BackgroundsLayers.BACKGROUND_IMAGE
}

export function isPattern(object: PackhelpObject): boolean {
  return object.id === BackgroundsLayers.GLOBAL_PATTERN
}

export function isGroup(
  object: PackhelpObject | fabric.Object
): object is PackhelpGroup {
  return object.hasOwnProperty("_objects")
}

export function isCircle(
  object: PackhelpObject | fabric.Object
): object is fabric.Circle {
  return object.type === "circle"
}

export function isReplicablePatternGroup(
  object: PackhelpObject
): object is PackhelpEditableGroup {
  return object.id === BackgroundsLayers.REPLICABLE_PATTERN
}

export function isObjectRect(object: fabric.Object): object is fabric.Rect {
  return object.type === "rect"
}

export function isObjectPath(object: fabric.Object): object is fabric.Path {
  return object.type === "path"
}

export function isStaticCanvas(object: any): object is fabric.StaticCanvas {
  return object instanceof fabric.StaticCanvas && !isInteractiveCanvas(object)
}

export function isInteractiveCanvas(object: any): object is fabric.Canvas {
  return object instanceof fabric.Canvas
}

// VIRTUAL DIELINE
// src/render-engine/modules/vd-editor/services/virtual-dieline-exporter
export type VirtualDielineObject = PackhelpObject & {
  objects?: VirtualDielineObject[]
  path?: number[][]
}

export type SpaceDataLessObject = {
  top: number
  left: number
  width: number
  height: number
  rotation: number
}

export type SpacesData = {
  [spaceId in ModelEditableSpaces]?: SpaceDataLessObject[]
}

// USED ONLY BY EDITOR
export type VirtualDielineDataLessObject = {
  objects: VirtualDielineObject[]
  preview?: string
  background?: string
  version?: string
}

export type VirtualDielineTemplateDataLessObject = Omit<
  VirtualDielineDataLessObject,
  "background"
> & {
  spaces: SpacesData
}

// src/render-engine/render-engine.ts
// USED ONLY BY EDITOR
export type VirtualDielinesData = {
  [editContext in EditContext]?: VirtualDielineDataLessObject
}

export type VirtualDielineTemplatesData = VirtualDielinesData & {
  [editContext in EditContext]?: VirtualDielineTemplateDataLessObject
}

export type AssetSource = "template"
export type AssetSection = "background-image" | "logo"

// src/stores/assets-store/image-asset-item.store.ts
// USED ONLY BY EDITOR
export type AssetMetaEnvelope = {
  name: string // remove this, it is silly
  isSoftDeleted: boolean
  source?: AssetSource
  sections?: AssetSection[]
}

// USED ONLY BY EDITOR
export type SerializedAssetWithMeta = ImageAssetMetaT & {
  meta: AssetMetaEnvelope
}
