import {
  PackhelpEditableGroup,
  PackhelpObject,
} from "../../../../object-extensions/packhelp-objects"
import fabric from "../../../../../../../libs/vendors/Fabric"
import { v4 as uuidv4 } from "uuid"
import { refreshGroup } from "../helpers"
import { TempEditableGroupObject } from "../types"

const STROKE_WIDTH = 0.5
const STROKE_COLOR = "#000000"

export class BorderController {
  private readonly borderObject: PackhelpObject

  constructor(private readonly group: PackhelpEditableGroup) {
    this.borderObject = new fabric.Rect() as PackhelpObject
    this.borderObject.set({
      id: `${TempEditableGroupObject.BORDER}_${uuidv4()}`,
      fill: "transparent",
      stroke: "transparent",
      strokeWidth: STROKE_WIDTH,
      strokeUniform: true,
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      hasRotatingPoint: false,
    })
  }

  public init() {
    this.borderObject.set(this.groupParams)
    this.group.insertAt(this.borderObject, 0, false)
    refreshGroup(this.group)
  }

  public getBorderObject(): PackhelpObject {
    return this.borderObject
  }

  public clear() {
    this.borderObject.set({
      width: 0,
      height: 0,
      scaleX: 1,
      scaleY: 1,
      left: undefined,
      top: undefined,
      stroke: "transparent",
    })

    refreshGroup(this.group)
  }

  public show() {
    this.borderObject.set({
      ...this.groupParams,
      stroke: STROKE_COLOR,
      strokeDashArray: [2.5, 2.5],
    })

    refreshGroup(this.group)
  }

  private get groupParams(): Partial<PackhelpObject> {
    return {
      width: this.group.width! - STROKE_WIDTH,
      height: this.group.height! - STROKE_WIDTH,
      scaleX: 1,
      scaleY: 1,
      left: -this.group.width! / 2,
      top: -this.group.height! / 2,
    }
  }
}
