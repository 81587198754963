import React from "react"

import ShareDesignForm from "./partials/ShareDesignForm"
import DesignNotShared from "./partials/DesignNotShared"

export interface ShareDesignProps {
  onClose: () => void
  onSendEmailShare: (email: string) => void
  shareDesign: () => void
  isSharedWithEmail: boolean
  isSharingWithEmail: boolean
  shareWithEmailError: null | string
  isPublic: boolean
  isSaving: boolean
  isSharing: boolean
}

const ShareDesign = ({
  isSharingWithEmail,
  isSharedWithEmail,
  shareWithEmailError,
  isSaving,
  isSharing,
  isPublic,
  onClose,
  shareDesign,
  onSendEmailShare,
}: ShareDesignProps) => {
  return isPublic && !isSharing ? (
    <ShareDesignForm
      isSharingWithEmail={isSharingWithEmail}
      isSharedWithEmail={isSharedWithEmail}
      shareWithEmailError={shareWithEmailError}
      onClose={onClose}
      onSend={onSendEmailShare}
    />
  ) : (
    <DesignNotShared
      onClose={onClose}
      isSaving={isSaving}
      shareDesign={shareDesign}
    />
  )
}

export { ShareDesign as default }
