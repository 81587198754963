import * as shared from "../shared/shared-consts"

const Region = {
  trustpilotId: shared.TRUSTPILOT.trustpilotId,
  trustpilotBusinessId: shared.TRUSTPILOT.trustpilotBusinessId,
  trustpilotInvitationTemplateId:
    shared.TRUSTPILOT.trustpilotInvitationTemplateId,

  googleapisKey: shared.GOOGLEAPIS_KEY,
  mainRegion: true,
  hasVat: true,
  languageTag: "cs-CZ",
  iterableLocale: "cs-CZ",
  locale: "cz",
  phoneCountryCode: "+420",
  defaultShippingCountryCode: "CS",
  mainDomain: shared.PL_MAIN_DOMAIN,

  secondaryAnalyticsCode: "UA-71580330-14",
  productRegion: "cz",
  countryCode: "cz",
  emailAddress: "hello@packhelp.cz",
  emailSenderName: "Packhelp.cz",
  logoSVGIcon: "v3-packhelp_color_on_white",
  logoSVGIconWhite: "v3-packhelp_white_logo_on_black",

  additionalCustoms: 0,

  pageTitle: "Editor - Packhelp.cz",
  footerLabel: "Packhelp",
  frontendBoxDesignRoute: "box/design",
  frontendAccountOrdersRoute: "account/orders",

  frontendTermsOfServiceRoute: "/terms_of_service/", // english is on purpose
  frontendPrivacyPolicyRoute: "/privacy_policy/", // english is on purpose

  domain: {
    basename: "packhelp",
    tld: "cz",
  },
  externalLinks: {
    qrForm: "/quote-request",
    fscCertification: "/certifikat-fsc/",
    helpPlantTrees: "/sazeni-stromu/",
    ecoProperties: "/pruvodce-po-ekologickych-vlastnostech/",
    sustainability: "/udrzitelnost/",
    designShowcase: "/design-showcase/",
    holidays: "/vanocni-sezona/",
    inspirationsHandbook: "/inspirations-handbook/",
    mobileAppLandingPageUrl: "/mobilni-aplikace/",
    brief: "/brief/",
    artworkTipsDielines: "/tipy-pro-vytvareni-grafiky-a-vyseky/",
    help: "/napoveda/",
    thankYou: "/dekuji/",
    designGuidelines: "/pomoc/tematy/box-editor/",
    about: "/o-nas/",
    career: "/kariera/",
    shop: "/obchod/",
    services: "/services/",
    industries: "/i/",
    bundles: "/balik",
    inspirations: "/inspirace/",
    impressum: "/impressum/",
    returnPolicy: "/napoveda/tema/mohu-vratit-krabice-ktere-jsem-si-objednal/",
    blog: "/blog/",
    contact: "/kontakt/",
    privacyPolicy: "/privacy_policy/",
    termsOfService: "/terms_of_service/",
    termsOfServicePlus: "/terms-of-services-plus/",
    accountSignOut: "/sign_out/",
    accountSignIn: "/sign_in/",
    accountRegister: "/registration/",
    accountOrders: "/account/orders",
    accountOrderDetails: "/account/orders/:orderNumber",
    accountSettings: "/account/settings",
    accountSupportReforestation: "/account/support-reforestation",
    accountBoxes: "/account/boxes",
    accountWallet: "/account/packhelp-wallet",
    accountCart: "/cart/", // old checkout key - to be removed
    samples: "/sample-packs/",
    customOffer: "/offer/:token/review",
    payment: "/order/:orderId/payment",
    paymentSummary: "/order/:orderId/payment/summary",
    quoteRequest: "account/quote_requests/:qrNumber",
    cart: "/cart/",
    orderDetails: "/order",
    crossSell: "/cart/recommended-products-and-services/s",
    fullAndEcoColorHelpSite:
      "/napoveda/tema/jaky-je-rozdil-mezi-plnobarevnou-a-barevnou-eco-krabici",
    safeZonesHelpSite: "/napoveda/tema/udrzujte-vzdalenost-od-okraju/",
    samplePacksLink:
      "/box/design?sku=box-eu--samples--samples--samples--samples--samples&dielineMode=false",
    termsAcceptedSite: "/podminky-uzivani-prijata/",
    leadership: "/leadership/",
    press: "/press/",
    urlWhitelistedToBeFetchedFromUrl: shared.urlWhitelistedToBeFetchedFromUrl,
    products: {
      "mailer-box-plain": "/postovni-krabice-bez-potisku/",
      "mailer-box-eco": "/eco-postovni-krabice/",
      "mailer-box-eco-white": "/eco-bila-postovni-krabice/",
      "mailer-box-full-color": "/plnobarevna-postovni-krabice/",
      "mailer-box-eco-color": "/barevne-eco-postovni-krabice/",
      "delivery-mailer-box": "/dorucovaci-krabice-pro-eshopy/",
      "delivery-mailer-box-plain": "/dorucovaci-krabice-bez-potisku/",
      "shipping-box-plain": "/klopove-krabice-bez-potisku/",
      "shipping-box": "/eco-klopova-krabice/",
      "product-box": "/klasicky-produktovy-box-skladaci/",
      "product-box-two-pieces": "/dvoudilny-produktovy-box-s-vikem/",
      "poly-mailer": "/plastove-obalky-klasik/",
      "paper-mailer": "/postovni-papirove-tasky/",
      "paper-bag": "/papirove-tasky-s-uchem/",
      "rigid-envelope": "/kartonove-obalky-s-potiskem/",
      "product-box-wine-box": "/krabice-na-vino-s-potiskem/",
      "tissue-paper": "/hedvabny-papir-s-potiskem/",
      "packing-paper": "/balici-papir-2/",
      "packing-tape": "/balici-pasky-s-potiskem/",
      "sleeved-mailer-box": "/krabice-s-navlekem/",
      "kraft-tape": "/kraft-lepici-pasky-s-potiskem/",
      "bio-poly-mailer": "/plastove-obalky-kompostovatelne/",
      "paper-can": "/papirove-plechovky/",
      "mailer-box-white-ink": "/postovni-krabice-bile-a-kraft/",
      "mailer-box-full-color-double-sided":
        "/postovni-krabice-s-oboustrannym-potiskem/",
      "rigid-box": "/krabice-z-odolneho-kartonu/",
      "rigid-box-two-pieces": "/pevna-krabicka-s-vikem/",
      "special-box": "/specialni-krabice/",
      "magnetic-box": "/krabice-s-magnetickym-uzaviranim/",
      "carrier-box": "/kartonove-krabice-s-rukojeti",
      "rigid-drawer-box": "/vysouvaci-krabice/",
      "cake-box": "/dortove-krabice/",
      "bio-poly-mailer-plain": "/pre-printed-bio-poly-mailer/",
      "pre-printed-kraft-tape": "/pre-printed-kraft-tape/",
      "recycled-poly-mailer": "/plastove-obalky-kompostovatelne/",
    },
    fb_url: shared.EU_MAIN_DOMAIN,
    fb_sitename: "Packhelp",
    fb_image: "/share_image_1200x630.png",
    share_img: "/share_image_120x120.png", // Editor
    boxEditorPath: "/box/design",
    productEditor: "/editor/product/design",
    exampleDesign: "79485?language=cz&templateMode=true",
    productQuotationSystem: "/quote",
    pqsSuccessPage: "/quote/success",
    plus: {
      catalogue: "/plus/catalogue/",
    },
    pro: {
      features: "pro/features/",
      suppliers: "pro/for-suppliers/",
      termsOfService: "pro/terms-conditions/",
    },
  },

  outsidePackhelpDomainLinks: {
    ...shared.externalDomainPackhelpLinks,
  },

  ...shared.SHARED_ECOMMERCE_PROPERTIES.eu,
  ...shared.SHARED_CURRENCY_PROPERTIES.eur,

  remindMeOnDesktop: {
    remindme_form_url:
      "//packhelp.us12.list-manage.com/subscribe/post-json?u=2060c1de14ec30d28a9fab813&amp;id=908b4d8a4f&c=?",
    remindme_hidden_input_name: "b_2060c1de14ec30d28a9fab813_908b4d8a4f",
  },

  datesFormattingRules: shared.DATES_FORMATTING_RULES_EU,

  googleConversionId: "859901857",
  googleAnalytics: {
    primaryCode: "UA-71580330-2",
    secondaryCode: "UA-71580330-14",
    segmentCode: "uXDomf9GW2BCffFseKvLrarxNiGNLI5O",
  },
  googleAdWords: {
    remarketingCode: "39198",
    conversionCode: "859901857",
  },

  facebookAppId: "1660833110841347",
  supportsPlus: false,
}

export default Region
