var _a;
import ENVELOPE_CONSTS from "../generalConfig/consts/envelopeConsts";
var ENVELOPE_PRODUCTS_EDITOR_LIMITATIONS = {
    shared: {
        initialActiveSide: {
            activeSideId: "front",
            editedSideId: "front",
        },
        isPantoneColorsAvailable: false,
        showSideExtraPrice: true,
        sidesNameCodesMap: {
            front: "A",
            back: "B",
        },
        reforestationSupport: {
            badge: true,
            trees: true,
        },
    },
    products: (_a = {},
        _a[ENVELOPE_CONSTS.ENVELOPE_MODEL_DEFAULT] = {
            productEditorMode: "2D",
        },
        _a),
};
export default ENVELOPE_PRODUCTS_EDITOR_LIMITATIONS;
