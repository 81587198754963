import React, { useRef } from "react"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import {
  PickerToggle,
  PickerToggleType,
} from "../../../atoms/PickerToggle/PickerToggle"
import { useTooltip } from "../../../../hooks/useTooltip"

import styles from "./ShapeConfigurator.module.scss"
import Picker from "../../Picker/Picker"
import { Shape } from "../../../../../libs/value-objects/shape"
import { Thumb88 } from "../../../atoms/Thumb88/Thumb88"
import { EditorToolList } from "../../tools/EditorToolList"

interface ShapeConfiguratorProps {
  itemName?: React.ReactNode
  preventHideClickOutside?: boolean
  selectedShape: Shape
  shapes: Shape[]
  onShapeSelect: (shape: Shape) => void
  onDoneClick: () => void
  onBackClick: () => void
  onClick?: () => void
}

export const ShapeConfigurator = ({
  onDoneClick,
  onBackClick,
  selectedShape,
  shapes,
  itemName,
  onShapeSelect,
  onClick,
}: ShapeConfiguratorProps) => {
  const wrapperRef = useRef<any>(null)
  const { openTooltip, closeTooltip, isOpen, Tooltip } = useTooltip({
    offsetLeft: -10,
    offsetTop: 50,
  })

  const onDone = () => {
    closeTooltip()
    onDoneClick()
  }

  const onBack = () => {
    closeTooltip()
    onBackClick()
  }

  const onPickerClick = (e) => {
    isOpen ? closeTooltip(e) : openTooltip(e)

    if (onClick) {
      onClick()
    }
  }

  const shapeImg = (
    <img
      src={selectedShape.src}
      title={selectedShape.title}
      loading="lazy"
      alt=""
    />
  )

  return (
    <div className={styles.shapepicker_wrapper} ref={wrapperRef}>
      <div className={styles.editor_header__shapepicker_nav}>
        {itemName && (
          <Typography type={TType.Body15_350}>{itemName}</Typography>
        )}
        <PickerToggle
          onClick={onPickerClick}
          type={PickerToggleType.rounded}
          content={shapeImg}
          e2eTargetName="shape-toggler"
        />
      </div>
      <div className={styles.editor_header__shapepicker}>
        {isOpen && (
          <Tooltip>
            <Picker onBackClick={onBack} onDoneClick={onDone}>
              <div className={styles.editor_header__shapepicker_content}>
                <EditorToolList size="small">
                  {shapes.map((shape, index) => {
                    return (
                      <Thumb88
                        key={index}
                        onSelect={() => {
                          onShapeSelect(shape)
                        }}
                        isActive={
                          selectedShape && selectedShape.id === shape.id
                        }
                        imageURL={shape.src}
                        e2eTarget="shape"
                        e2eTargetName={`mask-shape-${shape.id}`}
                        hoverIcon={null}
                        imgLoading="lazy"
                      />
                    )
                  })}
                </EditorToolList>
              </div>
            </Picker>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
