var _a, _b;
var assetsPath = "/product-key-visuals/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var customPath = "/product-custom-dimensions/";
import BOX_CONSTS from "../consts/boxConsts";
var sizeAssets = {
    P19: {
        desktop: desktopPath + "P19.png",
        mobile: mobilePath + "P19.png",
    },
    P27: {
        desktop: desktopPath + "P27.png",
        mobile: mobilePath + "P27.png",
    },
    P28: {
        desktop: desktopPath + "P28.png",
        mobile: mobilePath + "P28.png",
    },
    P37: {
        desktop: desktopPath + "P37.png",
        mobile: mobilePath + "P37.png",
    },
    P40: {
        desktop: desktopPath + "P40.png",
        mobile: mobilePath + "P40.png",
    },
    P41: {
        desktop: desktopPath + "P41.png",
        mobile: mobilePath + "P41.png",
    },
    custom: {
        desktop: customPath + "TwoPieceProductBox/Desktop/Custom/{locale}.png",
        mobile: customPath + "TwoPieceProductBox/Mobile/Custom/{locale}.png",
    },
};
export default (_a = {},
    _a[BOX_CONSTS.PRODUCT_BOX_TWO_PIECES] = (_b = {},
        _b[BOX_CONSTS.PRODUCT_BOX_TWO_PIECES_DEFAULT_VARIANT] = {
            keyVisual: [assetsPath + "nested_productbox_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _b),
    _a);
