var _a, _b;
import TAPE_CONSTS from "../generalConfig/consts/tapeConsts";
var GENERIC_PREFERED_COLORS = [
    {
        uid: 1,
        pantoneName: "010 White",
        color: "#FFFFFF",
        description: "box-editor.pantone-picker.color-descriptions.white",
    },
    {
        uid: 2,
        pantoneName: "Yellow 012 C",
        color: "#FFD719",
        description: "box-editor.pantone-picker.color-descriptions.yellow",
    },
    {
        uid: 3,
        pantoneName: "Black C",
        color: "#222223",
        description: "box-editor.pantone-picker.color-descriptions.black",
    },
    {
        uid: 4,
        pantoneName: "Orange 021 C",
        color: "#FF4700",
        description: "box-editor.pantone-picker.color-descriptions.orange",
    },
    {
        uid: 5,
        pantoneName: "212 C",
        color: "#DB4A82",
        description: "box-editor.pantone-picker.color-descriptions.pink",
    },
    {
        uid: 6,
        pantoneName: "Red 032 C",
        color: "#D8383C",
        description: "box-editor.pantone-picker.color-descriptions.red",
    },
    {
        uid: 7,
        pantoneName: "7738 C",
        color: "#45A53F",
        description: "box-editor.pantone-picker.color-descriptions.green",
    },
    {
        uid: 8,
        pantoneName: "Process Blue C",
        color: "#0078B0",
        description: "box-editor.pantone-picker.color-descriptions.blue",
    },
    {
        uid: 9,
        pantoneName: "Violet C",
        color: "#38276D",
        description: "box-editor.pantone-picker.color-descriptions.purple",
    },
];
var TAPE_COLORS_LIST = {
    GENERIC: GENERIC_PREFERED_COLORS,
};
var ProductPrederedColors = {
    COLORS_CONFIG: (_a = {
            SHARED: {
                EDITOR_DEFAULT_COLOR: {
                    DEFAULT: {
                        color: "rgb(0,120,176)",
                        pantoneName: "Process Blue C",
                    },
                },
            }
        },
        _a[TAPE_CONSTS.TAPE_MODEL_PACKING_TAPE] = {
            VARIANTS: (_b = {},
                _b[TAPE_CONSTS.TAPE_VARIANT_PACKING_TAPE] = {
                    PANTONE_COLORS: true,
                    LIMITED_COLORS: false,
                    LIMITED_COLORS_DESCRIPTION: false,
                },
                _b),
        },
        _a),
    COLORS_LISTS: TAPE_COLORS_LIST,
};
export default ProductPrederedColors;
