import { v4 as uuidv4 } from "uuid"
import VirtualDielineEditor from "../virtual-dieline-editor"
import {
  isAssetGroup,
  isAssetImage,
  isAssetLogoPlaceholderSlot,
  isReplicablePatternGroup,
  VirtualDielineDataLessObject,
} from "../../../../modules/ph-api/asset-types"
import { DesignVersion } from "../../../../modules/design/version"
import { VirtualDielineMigrator } from "./virtual-dieline-migrator"
import { VirtualDielineFilterer } from "./virtual-dieline-filterer"
import { ControlsHelper } from "../modules/assets-module/helpers/controls-helper"
import { FontHelper } from "../modules/assets-module/helpers/font-helper"
import { MaskLoader } from "../modules/assets-module/mask-controller/mask-loader"
import { EditableGroupController } from "../modules/assets-module/editable-group-controller/editable-group-controller"
import { LogoPlaceholderSlotObjectController } from "../modules/assets-module/canvas-object-controller/logo-placeholder-slot-object-controller"
import { ReplicablePatternLayerController } from "../modules/assets-module/pattern-replicator/replicable-pattern-layer-controller"
import { DesignStructureObjects } from "../types/render-engine-types"
import { isBrowserSafari } from "../../../../ui/theater/proscenium/utils/proscenium.helpers"

export class VirtualDielineImporter {
  constructor(
    private readonly vdEditor: VirtualDielineEditor,
    private readonly isStaticMode: boolean
  ) {}

  public async import(
    designData: VirtualDielineDataLessObject,
    designDataFormatVersion = DesignVersion.latest
  ): Promise<VirtualDielineEditor> {
    await this.loadData(designData, designDataFormatVersion)
    await this.refreshFiltersIfNeeded()
    await this.initControllers()

    return this.vdEditor
  }

  private async loadData(
    designData: VirtualDielineDataLessObject,
    designDataFormatVersion: DesignVersion
  ): Promise<void> {
    const { fabricCanvas } = this.vdEditor

    if (!designData) {
      designData = { objects: [] }
    }

    designData.objects = VirtualDielineFilterer.filterForImport(
      designData.objects
    )

    await FontHelper.preloadAndFixTextObjects(designData.objects)

    await new Promise((resolve) => {
      fabricCanvas.loadFromJSON(
        designData,
        async () => {
          const virtualDieline = fabricCanvas
            .getObjects()
            .find((obj) => obj.id === "virtual_dieline")

          if (virtualDieline) {
            fabricCanvas.remove(virtualDieline)
          }

          // <migration start>
          const virtualDielineMigrator = new VirtualDielineMigrator(
            this.vdEditor,
            designDataFormatVersion
          )
          await virtualDielineMigrator.migrate()
          // <migration end>

          resolve(this.vdEditor)
        },
        (obj, objInstance) => {
          if (this.isStaticMode) {
            return
          }

          ControlsHelper.setControls(objInstance)

          if (!objInstance.id && objInstance.originSpaceArea) {
            objInstance.set({
              id: uuidv4(),
            })
          }

          if (objInstance.id === DesignStructureObjects.VIRTUAL_DIELINE) {
            const oldGroup = objInstance.getObjects()
            const newGroup = this.vdEditor.virtualDieline.getObjects()

            oldGroup.forEach((obj) => {
              const object = newGroup.find((object) => object.id === obj.id)

              if (object) {
                object.set({
                  fill: obj.fill,
                })
              }
            })

            fabricCanvas.remove(objInstance)
          }
        }
      )
    })
  }

  private async initControllers(): Promise<void> {
    const maskLoader = new MaskLoader(this.vdEditor)
    await maskLoader.loadMasks()

    for (const object of this.vdEditor.fabricCanvas.getObjects()) {
      // Editable Groups
      if (isAssetGroup(object)) {
        const groupController = new EditableGroupController(this.vdEditor)
        await groupController.load(object)
      }

      if (isAssetLogoPlaceholderSlot(object)) {
        const controller = new LogoPlaceholderSlotObjectController(
          object,
          this.vdEditor
        )
        await controller.setSpaceClipping()
        controller.attachEventListeners()
      }

      if (isReplicablePatternGroup(object)) {
        await new ReplicablePatternLayerController(this.vdEditor).prepareLayer()
      }

      this.vdEditor.assetsModule.setObjectVisibility(
        object,
        this.vdEditor.productRenderPilot.isPrintActiveFor(
          this.vdEditor.editContext
        )
      )
    }
  }

  /**
   * https://packhelp.slack.com/archives/C0WM9QBR9/p1666165553574489
   *
   * There is probably a race condition in Safari 16 which results in incorrect display of raster images.
   *
   * DIRTY FIX: We need to synchronously force refresh image filters in Safari to prevent it.
   */
  private async refreshFiltersIfNeeded(): Promise<void> {
    if (!isBrowserSafari()) {
      return
    }

    for (const object of this.vdEditor.fabricCanvas.getObjects()) {
      if (isAssetImage(object)) {
        object.applyFilters()

        await new Promise((resolve) => setTimeout(resolve, 1))
      }
    }
  }
}
