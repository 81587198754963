var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BOX_CONSTS from "../../../../../source/boxProductSpec/generalConfig/consts/boxConsts";
import ProductFactory from "../../../ProductFactory";
var addBoxSiblingsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getSiblingsSkus = function () {
            var _this = this;
            var siblings = this.product.variantManager.getModelConfig().siblings;
            var currentSiblingGroupName = this.getProductSiblingGroupName();
            if (!siblings || !siblings[currentSiblingGroupName]) {
                return [];
            }
            return siblings[currentSiblingGroupName].map(function (variantInSiblingsGroup) {
                var variants = _this.product.variantManager.getModelConfig().variants;
                var variantConfig = variants[variantInSiblingsGroup];
                return _this.product.variantManager.buildSku({
                    material: variantConfig.materials[0],
                    colorMode: variantConfig.colorModes[0],
                    finish: variantConfig.finishes[0],
                });
            });
        };
        class_1.prototype.getSiblingProducts = function () {
            return this.getSiblingsSkus().map(function (sku) { return ProductFactory(sku); });
        };
        class_1.prototype.getProductSiblingGroupName = function () {
            var productVariant = this.product.variantManager.getVariant();
            if (this.isVariantMailerBoxEcoVsFullColour(productVariant)) {
                if (this.checkSilbingsForEcoVsFullColour()) {
                    return false;
                }
                else {
                    return BOX_CONSTS.MAILER_BOX_ECO_COLOR_AND_FULL_COLOR_GROUP;
                }
            }
            else if (this.isVariantMailerBoxEcoWhiteVsEco(productVariant)) {
                if (this.checkSilbingsForEcoWhiteVsEco()) {
                    return false;
                }
                else {
                    return BOX_CONSTS.MAILER_BOX_ECO_AND_ECO_WHITE_GROUP;
                }
            }
            else {
                return false;
            }
        };
        class_1.prototype.checkSilbingsForEcoVsFullColour = function () {
            return (this.checkIfExtraSize() &&
                !this.checkSiblingsExtraSizesCompatibility(BOX_CONSTS.MAILER_BOX_ECO_COLOR_VARIANT, BOX_CONSTS.MAILER_BOX_FULL_COLOR_VARIANT));
        };
        class_1.prototype.checkSilbingsForEcoWhiteVsEco = function () {
            return (this.checkIfExtraSize() &&
                !this.checkSiblingsExtraSizesCompatibility(BOX_CONSTS.MAILER_BOX_ECO_WHITE_VARIANT, BOX_CONSTS.MAILER_BOX_ECO_VARIANT));
        };
        class_1.prototype.checkIfExtraSize = function () {
            var productSize = this.product.variantManager.getSize();
            var extraSizes = this.product.variantManager.getExtraSizesArr();
            return extraSizes && extraSizes.indexOf(parseInt(productSize)) !== -1;
        };
        class_1.prototype.isVariantMailerBoxEcoVsFullColour = function (productVariant) {
            return (productVariant === BOX_CONSTS.MAILER_BOX_ECO_COLOR_VARIANT ||
                productVariant === BOX_CONSTS.MAILER_BOX_FULL_COLOR_VARIANT);
        };
        class_1.prototype.isVariantMailerBoxEcoWhiteVsEco = function (productVariant) {
            return (productVariant === BOX_CONSTS.MAILER_BOX_ECO_WHITE_VARIANT ||
                productVariant === BOX_CONSTS.MAILER_BOX_ECO_VARIANT);
        };
        class_1.prototype.checkSiblingsExtraSizesCompatibility = function (firstVariant, secondVariant) {
            var productVariantsMap = this.product.variantManager.getModelVariants();
            var productSize = this.product.variantManager.getSize();
            var firstVariantExtraSizes = productVariantsMap[firstVariant].extraSizes;
            var secondVariantExtraSizes = productVariantsMap[secondVariant].extraSizes;
            if (!firstVariantExtraSizes || !secondVariantExtraSizes) {
                return false;
            }
            if (firstVariantExtraSizes.indexOf(productSize) ===
                secondVariantExtraSizes.indexOf(productSize)) {
                return true;
            }
            else {
                return false;
            }
        };
        return class_1;
    }(superclass));
};
export default addBoxSiblingsBehaviors;
