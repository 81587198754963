var _a, _b, _c, _d, _e, _f;
import Product from "../consts/Product";
import ProductVariant from "../consts/ProductVariant";
import PRODUCTION_TIMES_CONSTS from "../../../productSharedConsts/productionTimeConsts";
var DEFAULT = PRODUCTION_TIMES_CONSTS.DEFAULT, EXPRESS = PRODUCTION_TIMES_CONSTS.EXPRESS, SUPEREXPRESS = PRODUCTION_TIMES_CONSTS.SUPEREXPRESS, PRODUCTION_TIME = PRODUCTION_TIMES_CONSTS.PRODUCTION_TIME, DTP_DEADLINE = PRODUCTION_TIMES_CONSTS.DTP_DEADLINE, HIGH_QUANTITY = PRODUCTION_TIMES_CONSTS.HIGH_QUANTITY;
export default (_a = {},
    _a[Product.PRODUCT_BOX_WINE_BOX] = (_b = {},
        _b[ProductVariant.PRODUCT_BOX_WINE_BOX_DEFAULT_VARIANT] = (_c = {},
            _c[PRODUCTION_TIME] = (_d = {},
                _d[DEFAULT] = 11,
                _d[EXPRESS] = 6,
                _d[SUPEREXPRESS] = 3,
                _d[HIGH_QUANTITY] = (_e = {},
                    _e[2500] = { min: 17, max: 22 },
                    _e),
                _d),
            _c[DTP_DEADLINE] = (_f = {},
                _f[DEFAULT] = 1,
                _f),
            _c),
        _b),
    _a);
