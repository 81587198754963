var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BOX_CONSTS from "../../../../../source/boxProductSpec/generalConfig/consts/boxConsts";
var addBoxPreferedColorsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getColorPickerNoColorObject = function () {
            return {
                uid: "reset",
                color: null,
                description: "box-editor.pantone-picker.color-descriptions.no-color",
            };
        };
        class_1.prototype.getPreferredColors = function (config) {
            if (config === void 0) { config = { isFontColors: false }; }
            var currentVariant = this.product.variantManager.getVariant();
            var finish = this.product.variantManager.getFinish();
            var colors;
            if (config &&
                config.isFontColors &&
                currentVariant === BOX_CONSTS.SLEEVED_MAILER_BOX_VARIANT &&
                finish === BOX_CONSTS.PRINT_FINISH_SLEEVE_KRAFT) {
                colors =
                    this.preferredColors.COLORS_LISTS.SLEEVE_MAILER_BOX_KRAFT_COLORS;
            }
            else {
                colors = _super.prototype.getPreferredColors.call(this);
            }
            if (!config.isFontColors) {
                var reset = this.getColorPickerNoColorObject();
                colors = colors.slice(1, colors.length);
                colors.unshift(reset);
            }
            return colors;
        };
        return class_1;
    }(superclass));
};
export default addBoxPreferedColorsBehaviors;
