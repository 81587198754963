var _a, _b, _c;
import BOX_CONSTS from "../consts/boxConsts";
var DEFAULT = "default";
var WEIGHTS_PRODUCT_BOXES_TWO_PIECES = (_a = {},
    _a[DEFAULT] = {
        P19: 0.027,
        P27: 0.032,
        P28: 0.079,
        P29: 0.079,
        P37: 0.052,
        P40: 0.173,
        P41: 0.096,
    },
    _a);
export default (_b = {},
    _b[BOX_CONSTS.PRODUCT_BOX_TWO_PIECES] = (_c = {},
        _c[BOX_CONSTS.PRODUCT_BOX_TWO_PIECES_DEFAULT_VARIANT] = WEIGHTS_PRODUCT_BOXES_TWO_PIECES,
        _c),
    _b);
