import { observer } from "mobx-react-lite"
import React from "react"
import { useTranslate } from "../../../hooks/useTranslate"
import { TType } from "shared-libs/src/js/shared-components/i18n/TypographyWithTranslate"
import Typography from "dsl/src/atoms/Typography/Typography"
import { I18N } from "../../../i18n"
import { TagIcon } from "../../atoms/TagIcon/TagIcon"
import { ReactComponent as IconChecked } from "../../../assets/svg/check.svg"

import cxBinder from "classnames/bind"
import styles from "./TemplateCard.module.scss"

const cx = cxBinder.bind(styles)

type TemplateProps = {
  isSelected: boolean
  onSelect: () => void
  inactive: boolean
}

export const TemplateEmptyCard = observer(
  ({ isSelected, onSelect, inactive }: TemplateProps) => {
    const t = useTranslate()

    return (
      <div
        className={cx("wrapper", "no_template_wrapper", {
          inactive: inactive && !isSelected,
          selected: isSelected,
        })}
        onClick={onSelect}
      >
        <div className={styles.header}>
          <Typography type={TType.Header13_500} className={styles.caption}>
            {t(I18N.editorTools.templates.noTemplates)}
          </Typography>
          <span className={styles.selection}>
            {isSelected ? (
              <TagIcon Icon={IconChecked} />
            ) : (
              <span className={styles.unchecked}></span>
            )}
          </span>
        </div>
      </div>
    )
  }
)
