import { isProductTypeCustom } from "../SkuLib";
import SamplePackProduct from "../Products/SamplePackProduct/SamplePackProduct";
import CustomSamplePackProduct from "../CustomProducts/CustomSamplePackProduct";
function SamplePackProductFactory(sku) {
    if (isProductTypeCustom(sku)) {
        return new CustomSamplePackProduct(sku);
    }
    else {
        return new SamplePackProduct(sku);
    }
}
export default SamplePackProductFactory;
