var _a, _b, _c, _d;
import POLY_MAILER_CONST from "../generalConfig/consts/polyMailerConsts";
var PRINT_MATERIAL_FOIL_BLACK = [
    {
        uid: 1,
        pantoneName: "010 White",
        color: "#FFFFFF",
        description: "box-editor.pantone-picker.color-descriptions.white",
    },
    {
        uid: 2,
        pantoneName: "Pantone 877 C",
        color: "#8A8D8F",
        linearGradient: "linear-gradient(270deg, #F3F3F3 0%, #B1B1B1 100%)",
    },
    {
        uid: 3,
        pantoneName: "Pantone 871 C",
        color: "#84754D",
        linearGradient: "linear-gradient(270deg, #FEF6B7 0%, #E0B043 100%)",
    },
];
var PRINT_MATERIAL_FOIL_TRANSPARENT = [
    {
        uid: 1,
        pantoneName: "010 White",
        color: "#FFFFFF",
        description: "box-editor.pantone-picker.color-descriptions.white",
    },
    {
        uid: 2,
        pantoneName: "Yellow 012 C",
        color: "#FFD719",
        description: "box-editor.pantone-picker.color-descriptions.yellow",
    },
    {
        uid: 3,
        pantoneName: "Black C",
        color: "#222223",
        description: "box-editor.pantone-picker.color-descriptions.black",
    },
    {
        uid: 4,
        pantoneName: "Orange 021 C",
        color: "#FF4700",
        description: "box-editor.pantone-picker.color-descriptions.orange",
    },
    {
        uid: 5,
        pantoneName: "212 C",
        color: "#DB4A82",
        description: "box-editor.pantone-picker.color-descriptions.pink",
    },
    {
        uid: 6,
        pantoneName: "Red 032 C",
        color: "#D8383C",
        description: "box-editor.pantone-picker.color-descriptions.red",
    },
    {
        uid: 7,
        pantoneName: "7738 C",
        color: "#45A53F",
        description: "box-editor.pantone-picker.color-descriptions.green",
    },
    {
        uid: 8,
        pantoneName: "Process Blue C",
        color: "#0078B0",
        description: "box-editor.pantone-picker.color-descriptions.blue",
    },
    {
        uid: 9,
        pantoneName: "Violet C",
        color: "#38276D",
        description: "box-editor.pantone-picker.color-descriptions.purple",
    },
];
var PRINT_MATERIAL_FOIL_WHITE = [
    {
        uid: 2,
        pantoneName: "Yellow 012 C",
        color: "#FFD719",
        description: "box-editor.pantone-picker.color-descriptions.yellow",
    },
    {
        uid: 3,
        pantoneName: "Black C",
        color: "#222223",
        description: "box-editor.pantone-picker.color-descriptions.black",
    },
    {
        uid: 4,
        pantoneName: "Orange 021 C",
        color: "#FF4700",
        description: "box-editor.pantone-picker.color-descriptions.orange",
    },
    {
        uid: 5,
        pantoneName: "212 C",
        color: "#DB4A82",
        description: "box-editor.pantone-picker.color-descriptions.pink",
    },
    {
        uid: 6,
        pantoneName: "Red 032 C",
        color: "#D8383C",
        description: "box-editor.pantone-picker.color-descriptions.red",
    },
    {
        uid: 7,
        pantoneName: "7738 C",
        color: "#45A53F",
        description: "box-editor.pantone-picker.color-descriptions.green",
    },
    {
        uid: 8,
        pantoneName: "Process Blue C",
        color: "#0078B0",
        description: "box-editor.pantone-picker.color-descriptions.blue",
    },
    {
        uid: 9,
        pantoneName: "Violet C",
        color: "#38276D",
        description: "box-editor.pantone-picker.color-descriptions.purple",
    },
];
var PRINT_MATERIAL_FOIL_WHITE_BLACK_INSIDE = [
    {
        uid: 2,
        pantoneName: "Yellow 012 C",
        color: "#FFD719",
        description: "box-editor.pantone-picker.color-descriptions.yellow",
    },
    {
        uid: 3,
        pantoneName: "Black C",
        color: "#222223",
        description: "box-editor.pantone-picker.color-descriptions.black",
    },
    {
        uid: 4,
        pantoneName: "Orange 021 C",
        color: "#FF4700",
        description: "box-editor.pantone-picker.color-descriptions.orange",
    },
    {
        uid: 5,
        pantoneName: "212 C",
        color: "#DB4A82",
        description: "box-editor.pantone-picker.color-descriptions.pink",
    },
    {
        uid: 6,
        pantoneName: "Red 032 C",
        color: "#D8383C",
        description: "box-editor.pantone-picker.color-descriptions.red",
    },
    {
        uid: 7,
        pantoneName: "7738 C",
        color: "#45A53F",
        description: "box-editor.pantone-picker.color-descriptions.green",
    },
    {
        uid: 8,
        pantoneName: "Process Blue C",
        color: "#0078B0",
        description: "box-editor.pantone-picker.color-descriptions.blue",
    },
    {
        uid: 9,
        pantoneName: "Violet C",
        color: "#38276D",
        description: "box-editor.pantone-picker.color-descriptions.purple",
    },
];
var POLY_MAILER_PER_MATERIAL_PRODUCT_PREFERED_COLORS = (_a = {},
    _a[POLY_MAILER_CONST.PRINT_MATERIAL_FOIL_BLACK] = PRINT_MATERIAL_FOIL_BLACK,
    _a[POLY_MAILER_CONST.PRINT_MATERIAL_FOIL_TRANSPARENT] = PRINT_MATERIAL_FOIL_TRANSPARENT,
    _a[POLY_MAILER_CONST.PRINT_MATERIAL_FOIL_WHITE] = PRINT_MATERIAL_FOIL_WHITE,
    _a[POLY_MAILER_CONST.PRINT_MATERIAL_FOIL_WHITE_BLACK_INSIDE] = PRINT_MATERIAL_FOIL_WHITE_BLACK_INSIDE,
    _a);
var ProductPrederedColors = {
    COLORS_CONFIG: (_b = {
            SHARED: {
                EDITOR_DEFAULT_COLOR: (_c = {
                        DEFAULT: {
                            color: "#222223",
                            pantoneName: "Black C",
                        }
                    },
                    _c[POLY_MAILER_CONST.PRINT_MATERIAL_FOIL_BLACK] = {
                        color: "#ffffff",
                        pantoneName: "White",
                    },
                    _c),
            }
        },
        _b[POLY_MAILER_CONST.POLY_MAILER] = {
            VARIANTS: (_d = {},
                _d[POLY_MAILER_CONST.POLY_MAILER_VARIANT_STANDARD] = {
                    PANTONE_COLORS: true,
                    LIMITED_COLORS: false,
                    LIMITED_COLORS_DESCRIPTION: true,
                },
                _d),
        },
        _b),
    COLORS_LISTS: POLY_MAILER_PER_MATERIAL_PRODUCT_PREFERED_COLORS,
};
export default ProductPrederedColors;
