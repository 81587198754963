import React, { PropsWithChildren, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import cxBinder from "classnames/bind"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { IconButton } from "../../../atoms/IconButton/IconButton"
import { ReactComponent as IconAlignVertical } from "../../../../assets/svg/align-vertically.svg"
import { ReactComponent as IconAlignHorizontal } from "../../../../assets/svg/align-horizontally.svg"
import { ReactComponent as IconBringFront } from "../../../../assets/svg/bring-front.svg"
import { ReactComponent as IconBringBack } from "../../../../assets/svg/bring-back.svg"
import { ReactComponent as IconDuplicate } from "../../../../assets/svg/duplicate.svg"
import { ReactComponent as IconTrash } from "../../../../assets/svg/trash.svg"
import { ReactComponent as IconGroup } from "../../../../assets/svg/group.svg"
import { ReactComponent as IconUngroup } from "../../../../assets/svg/ungroup.svg"
import { TooltipWrapper } from "../../../atoms/Tooltip/TooltipWrapper"
import { Tooltip, TooltipSideEnum } from "../../../atoms/Tooltip/Tooltip"
import { PackhelpGroupType } from "../../../../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { useTranslate } from "../../../../hooks/useTranslate"
import { Dropdown } from "../../Dropdown/Dropdown"
import { DropdownItem } from "../../Dropdown/DropdownItem"
import { CanvasObjectControllable } from "../../../../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/canvas-object-controllable.interface"
import { HeaderToolbarAction } from "./HeaderToolbarAction"
import { isAssetGroup } from "../../../../../modules/ph-api/asset-types"
import ProductDriver from "../../../../../stores/product-driver/product.driver"
import { UiActionTracker } from "../../../../../stores/_controllers/ui-action-tracker-controller"
import styles from "./EditorHeaderToolbar.module.scss"

const cx = cxBinder.bind(styles)

interface EditorHeaderToolbarItemProps {
  isActive?: boolean
  helperMessage: string
  onClick(): void
}

interface EditorHeaderToolbarItemRemoveProps {
  item: ToolbarItem
  hasDropdown: boolean
  handleAction: (action: HeaderToolbarAction) => void
}

enum i18n {
  group = "components.editor-header-toolbar.label.group",
  ungroup = "components.editor-header-toolbar.label.ungroup",
  vertical = "components.editor-header-toolbar.label.align-vertical",
  horizontal = "components.editor-header-toolbar.label.align-horizontal",
  front = "components.editor-header-toolbar.label.bring-front",
  back = "components.editor-header-toolbar.label.bring-back",
  duplicate = "components.editor-header-toolbar.label.duplicate",
  remove = "components.editor-header-toolbar.label.remove",
  removeGroup = "components.editor-header-toolbar.label.remove-group",
  removeItem = "components.editor-header-toolbar.label.remove-item",
}

interface ToolbarItem {
  translationIntl: string
  actionName: HeaderToolbarAction
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

const getEditorHeaderToolbarItems = (
  activeObjectController: CanvasObjectControllable
): ToolbarItem[] => {
  const activeObject = activeObjectController.getObject()
  const items: ToolbarItem[] = []

  if (
    activeObject.type === PackhelpGroupType.activeSelection &&
    activeObjectController.isGroupingAvailable()
  ) {
    items.push({
      translationIntl: i18n.group,
      actionName: HeaderToolbarAction.group,
      Icon: IconGroup,
    })
  }

  if (
    (isAssetGroup(activeObject) && activeObject.groupController) ||
    activeObjectController.hasGroup()
  ) {
    items.push({
      translationIntl: i18n.ungroup,
      actionName: HeaderToolbarAction.ungroup,
      Icon: IconUngroup,
    })
  }

  items.push({
    translationIntl: i18n.vertical,
    actionName: HeaderToolbarAction.alignVertically,
    Icon: IconAlignVertical,
  })

  items.push({
    translationIntl: i18n.horizontal,
    actionName: HeaderToolbarAction.alignHorizontally,
    Icon: IconAlignHorizontal,
  })

  if (activeObjectController.isMovingLayerUpDownAvailable()) {
    items.push({
      translationIntl: i18n.front,
      actionName: HeaderToolbarAction.bringFront,
      Icon: IconBringFront,
    })
    items.push({
      translationIntl: i18n.back,
      actionName: HeaderToolbarAction.bringBack,
      Icon: IconBringBack,
    })
  }

  if (activeObjectController.isDuplicatingAvailable()) {
    items.push({
      translationIntl: i18n.duplicate,
      actionName: HeaderToolbarAction.duplicate,
      Icon: IconDuplicate,
    })
  }

  items.push({
    translationIntl: i18n.remove,
    actionName: HeaderToolbarAction.remove,
    Icon: IconTrash,
  })

  return items
}

export const EditorHeaderToolbarItem = (
  props: PropsWithChildren<EditorHeaderToolbarItemProps>
) => (
  <TooltipWrapper
    toggleElement={
      <div
        className={cx({
          active: props.isActive,
        })}
        onClick={props.onClick}
      >
        {props.children}
      </div>
    }
  >
    <Tooltip
      side={
        i18n.remove ? TooltipSideEnum.BottomLeft : TooltipSideEnum.TopCenter
      }
    >
      <Typography type={TType.Body13_350} htmlElement="p">
        {props.helperMessage}
      </Typography>
    </Tooltip>
  </TooltipWrapper>
)

const EditorHeaderToolbarNav = (props: PropsWithChildren<{}>) => {
  return (
    <nav className={styles.editor_header_toolbar}>
      <ul>
        {React.Children.map(props.children, (child, idx) => {
          return <li key={idx}>{child}</li>
        })}
      </ul>
    </nav>
  )
}

const EditorHeaderToolbarItemRemove = ({
  item,
  hasDropdown,
  handleAction,
}: EditorHeaderToolbarItemRemoveProps) => {
  const t = useTranslate()
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false)

  const onItemClick = () => {
    if (hasDropdown) {
      return setDropdownOpen(true)
    }

    handleAction(HeaderToolbarAction.remove)
  }

  return (
    <>
      <EditorHeaderToolbarItem
        isActive={false}
        onClick={onItemClick}
        helperMessage={t(item.translationIntl)}
      >
        <IconButton
          Icon={item.Icon}
          e2eTargetName={item.actionName.toLowerCase()}
        />
      </EditorHeaderToolbarItem>

      {hasDropdown && isDropdownOpen && (
        <Dropdown
          onClose={() => {
            setDropdownOpen(false)
          }}
        >
          <DropdownItem
            name={t(i18n.removeGroup)}
            onClick={() => {
              handleAction(HeaderToolbarAction.removeGroup)
            }}
            e2eTarget="remove"
            e2eTargetName="remove-group"
          />
          <DropdownItem
            name={t(i18n.removeItem)}
            onClick={() => {
              handleAction(HeaderToolbarAction.remove)
            }}
            e2eTarget="remove"
            e2eTargetName="remove-item"
          />
        </Dropdown>
      )}
    </>
  )
}

export const EditorHeaderObjectsToolbar = observer(
  ({
    productDriver,
    actionTracker,
  }: {
    productDriver: ProductDriver
    actionTracker?: UiActionTracker
  }) => {
    const activeObjectDriver = productDriver.activeObjectDriver
    const activeObject = activeObjectDriver.activeObject
    const activeObjectController = activeObjectDriver.activeObjectController
    const objectIdentified = activeObjectDriver.objectIdentified

    if (!activeObject || !activeObjectController) {
      return null
    }

    const [items, setItems] = useState<ToolbarItem[]>([])

    useEffect(() => {
      setItems(getEditorHeaderToolbarItems(activeObjectController))
    }, [objectIdentified, activeObjectController])

    const t = useTranslate()

    const handleAction = (action: HeaderToolbarAction) => {
      actionTracker?.twoDimensionalUpperRightToolbarElementClicked(
        action,
        activeObject.type,
        activeObject.assetType
      )

      switch (action) {
        case HeaderToolbarAction.group:
          return activeObjectDriver.groupObject()
        case HeaderToolbarAction.ungroup:
          return activeObjectDriver.ungroupObject()
        case HeaderToolbarAction.clip:
          return activeObjectDriver.toggleCliping()
        case HeaderToolbarAction.remove:
          return activeObjectDriver.removeObject()
        case HeaderToolbarAction.removeGroup:
          return activeObjectDriver.removeObject(true)
        case HeaderToolbarAction.duplicate:
          return activeObjectDriver.duplicateObject()
        case HeaderToolbarAction.alignHorizontally:
          return activeObjectDriver.alignObjectHorizontal()
        case HeaderToolbarAction.alignVertically:
          return activeObjectDriver.alignObjectVertical()
        case HeaderToolbarAction.bringFront:
          return activeObjectDriver.moveObjectLayerUp()
        case HeaderToolbarAction.bringBack:
          return activeObjectDriver.moveObjectLayerDown()
      }
    }

    return (
      <EditorHeaderToolbarNav>
        {items.map((item, index) => (
          <div key={index}>
            {item.actionName === HeaderToolbarAction.remove ? (
              <EditorHeaderToolbarItemRemove
                item={item}
                handleAction={handleAction}
                hasDropdown={activeObjectController.hasGroup()}
              />
            ) : (
              <EditorHeaderToolbarItem
                isActive={false}
                onClick={() => {
                  handleAction(item.actionName)
                }}
                helperMessage={t(item.translationIntl)}
              >
                <IconButton
                  Icon={item.Icon}
                  e2eTargetName={item.actionName.toLowerCase()}
                />
              </EditorHeaderToolbarItem>
            )}
          </div>
        ))}
      </EditorHeaderToolbarNav>
    )
  }
)
