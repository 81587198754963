var CARD_PRODUCTS_EDITOR_LIMITATIONS = {
    shared: {
        initialActiveSide: {
            activeSideId: "front",
            editedSideId: "front",
        },
        isPantoneColorsAvailable: false,
        sidesNameCodesMap: {
            front: "A",
            back: "B",
        },
        reforestationSupport: {
            badge: true,
            trees: true,
        },
    },
    products: {},
};
export default CARD_PRODUCTS_EDITOR_LIMITATIONS;
