declare global {
  interface Window {
    analytics: any
  }
}

export const SegmentAnalyticsApi = () => {
  if (typeof window === "undefined") return

  return window.analytics
}
