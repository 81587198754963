import _cloneDeep from "lodash/cloneDeep"
import VirtualDielineEditor from "../virtual-dieline-editor"
import { VirtualDielineImporter } from "./virtual-dieline-importer"
import {
  VdEditorExportService,
  VdEditorExportServiceConfig,
} from "./create-service-vd-editor"
import { calcObjectAbsoluteValues } from "../modules/assets-module/editable-group-controller/helpers"
import { VirtualDielineFilterer } from "./virtual-dieline-filterer"
import {
  VirtualDielineDataLessObject,
  EditableObjectTypes,
} from "../../../../modules/ph-api/asset-types"

/**
 * If you want to include any of the params below in cloned object you should also add it to assetParamsToIncludeInClonedObject array in:
 * editor/src/render-engine/modules/vd-editor/modules/assets-module/types.ts
 */
const paramsToExport = [
  "id",
  "indexConfig",
  "colourSettings",
  "rotation",
  "maxWidth",
  "maxHeight",
  "assetType",
  "lockUniScaling",
  "clipMode",
  "originSpaceArea",
  "patternSizePx",
  "isOverscaled",
  "isEditable",
  "fillPantoneName",
  "borderRadius",
  "keepRatio",

  // Configs
  "patternSourceConfig",
  "replicablePatternConfig",
  "assetTextMeta",
  "assetObjectMeta",
  "assetImageMeta",

  // Mask params
  "maskParentId",
  "maskType",
  "clippingMask",
  "maskConfig",

  // Group params
  "groupId",

  // Predefined asset params
  "predefinedTextId", //  It is a good idea to export "predefinedTextId" for analytics
  "templateId",

  // Image params
  "isLogo",

  // LogoPlaceholderSlot asset params
  "enabled",

  // Non-print safe zone
  "hasPadding",

  // FSC params
  "isFsc",

  // Snapping
  "snapping",
]

// When user is editing virtual dieline in space
// mode we need to reset position of all objects placed on dieline.
// To do that we copy all data from one virtual dieline to another
// and normalized positions, rotations, paning and scaling
// to default position.

// 1. check vd position (space mode / default)
// 1. export data from base virtual dieline
// 2. create importer and import data from base vd to service vd
// 3. set service vd position and rotation
// 4. reset setted rotation
// 5. create exporter and export data from service vd

type VirtualDielineExporterOptions = {
  unpackGroups?: boolean
}

export class VirtualDielineExporter {
  constructor(
    private readonly originalEditor: VirtualDielineEditor,
    private readonly options: VirtualDielineExporterOptions = {
      unpackGroups: true,
    }
  ) {}

  public async export(): Promise<VirtualDielineDataLessObject> {
    const { dielineNavigator } = this.originalEditor

    const currentSpace = dielineNavigator.getActiveSpaceId()
    const currentRotation = dielineNavigator.getCurrentRotation()

    if (currentSpace || currentRotation) {
      const vdEditor = await this.cloneVd()
      const vdExporter = new VirtualDielineExporter(vdEditor, this.options)
      const exportedData = await vdExporter.export()

      vdEditor.dispose()

      return exportedData
    } else {
      return this.toDatalessJSON()
    }
  }

  /**
   * Used only for preview generation.
   * @returns
   */
  public async cloneVd(config?: VdEditorExportServiceConfig) {
    const newVdEditor = new VdEditorExportService(
      this.originalEditor,
      config
    ).createServiceVdEditor()

    const exportedData = this.toDatalessJSON()
    const virtualDielineImporter = new VirtualDielineImporter(newVdEditor, true)
    await virtualDielineImporter.import(exportedData)

    // We can't rotate them VdEditorExportService. Importer undoes the rotation?
    const rotation = this.originalEditor.dielineNavigator.getCurrentRotation()
    newVdEditor.dielineNavigator.setCurrentRotation(rotation)
    await newVdEditor.resetDielinePosition()

    return newVdEditor
  }

  private toDatalessJSON(): VirtualDielineDataLessObject {
    this.originalEditor.fabricCanvas.add(this.originalEditor.virtualDieline)

    //@ts-ignore
    const data: VirtualDielineDataLessObject =
      this.originalEditor.fabricCanvas.toDatalessJSON(paramsToExport)
    this.originalEditor.fabricCanvas.remove(this.originalEditor.virtualDieline)

    let objectsToExport = VirtualDielineFilterer.filterForExport(data.objects)

    if (this.options.unpackGroups) {
      objectsToExport = this.unpackGroups(objectsToExport)
    }

    return _cloneDeep({
      version: data.version,
      background: data.background,
      objects: objectsToExport,
    })
  }

  private unpackGroups(objects) {
    return objects
      .map((group) => {
        if (
          group.assetType !== EditableObjectTypes.assetGroup &&
          group.assetType !== EditableObjectTypes.assetLogoPlaceholderSlot
        ) {
          return group
        }

        group.objects = group.objects.filter((obj) => !!obj.assetType)

        const objectsToExport = [group]

        for (const groupObject of group.objects) {
          if (groupObject.assetType) {
            objectsToExport.push({
              ...groupObject,
              ...calcObjectAbsoluteValues({
                ...groupObject,
                group: group,
              }),
              templateId: group.templateId,
            })
          }
        }

        return objectsToExport
      })
      .flat()
  }
}
