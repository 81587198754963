import React from "react"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import ModalTemplateConfirm from "../partials/ModalTemplateConfirm"
import { Button } from "dsl/src/atoms/Button/Button"
import { useTranslate } from "../../../../hooks/useTranslate"
import { InnerSize, Modal } from "../Modal"
import { ReactComponent as EnablePrintOptionIcon } from "../../../../assets/svg/enable-print-option.svg"
import styles from "./enable-printing-option-modal.module.scss"
import { EditContext } from "../../../../../libs/products-render-config/types"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

function getI18n(editContext: EditContext) {
  return {
    button: {
      cancel: "modal.editor.turn-on-printing.button.cancel",
      confirm: "modal.editor.turn-on-printing.button.accept",
    },
    header: `modal.editor.turn-on-printing.${editContext}.header`,
    description: `modal.editor.turn-on-printing.${editContext}.description`,
    piece: "component.editor-tool.print-inside.piece",
  }
}

export const EnablePrintingOptionModal = observer(() => {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ecommerce,
  ])

  if (!containerSet) {
    return null
  }

  const { enablePrintingOptionModalController } =
    containerSet.designAndProductDriver

  if (
    !enablePrintingOptionModalController.isOpen ||
    !enablePrintingOptionModalController.editContext
  ) {
    return null
  }

  const i18n = getI18n(enablePrintingOptionModalController.editContext)

  return (
    <Modal
      onClose={() => {
        enablePrintingOptionModalController.close()
      }}
      maxWidth={InnerSize.small}
    >
      <ModalTemplateConfirm
        onClose={() => {
          enablePrintingOptionModalController.close()
        }}
        buttonComponent={
          <Button
            onClick={() => {
              enablePrintingOptionModalController.confirm()
            }}
          />
        }
        cancelLinkText={t(i18n.button.cancel)}
        withCancel
      >
        <div className={styles.content}>
          <EnablePrintOptionIcon />
          <Typography type={TType.Header32_400}>{t(i18n.header)}</Typography>
          <p>
            <Typography type={TType.Body15_350}>
              {t(i18n.description)}
            </Typography>
          </p>
        </div>
      </ModalTemplateConfirm>
    </Modal>
  )
})
