var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
import PRODUCTION_TIMES_CONSTS from "../../../productSharedConsts/productionTimeConsts";
import NCP_CONSTS from "../consts/ncpConsts";
var DEFAULT = PRODUCTION_TIMES_CONSTS.DEFAULT, EXPRESS = PRODUCTION_TIMES_CONSTS.EXPRESS, SUPEREXPRESS = PRODUCTION_TIMES_CONSTS.SUPEREXPRESS, PRODUCTION_TIME = PRODUCTION_TIMES_CONSTS.PRODUCTION_TIME, DTP_DEADLINE = PRODUCTION_TIMES_CONSTS.DTP_DEADLINE;
var NCP_PRODUCTION_TIMES = (_a = {},
    _a[NCP_CONSTS.WOOD_WOOL] = (_b = {},
        _b[NCP_CONSTS.WOOD_WOOL_VARIANT] = (_c = {},
            _c[PRODUCTION_TIME] = (_d = {},
                _d[DEFAULT] = 2,
                _d[EXPRESS] = 2,
                _d[SUPEREXPRESS] = 2,
                _d),
            _c[DTP_DEADLINE] = (_e = {},
                _e[DEFAULT] = 0,
                _e),
            _c),
        _b),
    _a[NCP_CONSTS.SELF_ADHESIVE_ENVELOPE] = (_f = {},
        _f[NCP_CONSTS.SELF_ADHESIVE_ENVELOPE_VARIANT] = (_g = {},
            _g[PRODUCTION_TIME] = (_h = {},
                _h[DEFAULT] = 3,
                _h[EXPRESS] = 3,
                _h[SUPEREXPRESS] = 3,
                _h),
            _g[DTP_DEADLINE] = (_j = {},
                _j[DEFAULT] = 0,
                _j),
            _g),
        _f),
    _a[NCP_CONSTS.STICKER_CIRCLE] = (_k = {},
        _k[NCP_CONSTS.STICKER_CIRCLE_VARIANT] = (_l = {},
            _l[PRODUCTION_TIME] = (_m = {},
                _m[DEFAULT] = 3,
                _m[EXPRESS] = 3,
                _m[SUPEREXPRESS] = 3,
                _m),
            _l[DTP_DEADLINE] = (_o = {},
                _o[DEFAULT] = 0,
                _o),
            _l),
        _k),
    _a[NCP_CONSTS.STICKER_RECTANGLE] = (_p = {},
        _p[NCP_CONSTS.STICKER_RECTANGLE_VARIANT] = (_q = {},
            _q[PRODUCTION_TIME] = (_r = {},
                _r[DEFAULT] = 3,
                _r[EXPRESS] = 3,
                _r[SUPEREXPRESS] = 3,
                _r),
            _q[DTP_DEADLINE] = (_s = {},
                _s[DEFAULT] = 1,
                _s),
            _q),
        _p),
    _a[NCP_CONSTS.THANK_YOU_CARD] = (_t = {},
        _t[NCP_CONSTS.THANK_YOU_CARD_VARIANT] = (_u = {},
            _u[PRODUCTION_TIME] = (_v = {},
                _v[DEFAULT] = 3,
                _v[EXPRESS] = 3,
                _v[SUPEREXPRESS] = 3,
                _v),
            _u[DTP_DEADLINE] = (_w = {},
                _w[DEFAULT] = 0,
                _w),
            _u),
        _t),
    _a[NCP_CONSTS.PAPER_BUBBLE_WRAP] = (_x = {},
        _x[NCP_CONSTS.PAPER_BUBBLE_WRAP_VARIANT] = (_y = {},
            _y[PRODUCTION_TIME] = (_z = {},
                _z[DEFAULT] = 5,
                _z[EXPRESS] = 5,
                _z[SUPEREXPRESS] = 5,
                _z),
            _y[DTP_DEADLINE] = (_0 = {},
                _0[DEFAULT] = 0,
                _0),
            _y),
        _x),
    _a);
export default NCP_PRODUCTION_TIMES;
