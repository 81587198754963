// Events
import { EventLocation } from "shared-libs/src/js/libs/analytics/segment/segment-analytics.consts"

export enum FscCertificateEvents {
  loaded = "ph.fsc.loaded",
  added = "ph.fsc.added",
  removed = "ph.fsc.removed",
  labelApplied = "ph.fsc.label.applied",
  labelRemoved = "ph.fsc.label.removed",
}

export type FscCertificateEventsTypes = {
  [FscCertificateEvents.loaded]: () => void
  [FscCertificateEvents.added]: (location: EventLocation) => void
  [FscCertificateEvents.removed]: (location: EventLocation) => void
  [FscCertificateEvents.labelApplied]: (name: string) => void
  [FscCertificateEvents.labelRemoved]: (name: string) => void
}
