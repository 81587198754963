import React from "react"
import {
  Notification,
  NotificationTypes
} from "../../../molecules/Notification/Notification"
import styles from "../SelectQty.module.scss"

type SelectQtyHeaderType = {
  text: string
}

const SelectQtyHeader = ({ text }: SelectQtyHeaderType) => {
  return (
    <div className={styles["header-banner"]}>
      <Notification type={NotificationTypes.Info}>
        <div className={styles["header__inner"]}>
          <span className={styles["header__text"]}>{text}</span>
        </div>
      </Notification>
    </div>
  )
}

export { SelectQtyHeader, SelectQtyHeader as default }
