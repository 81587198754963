var _a, _b, _c, _d, _e, _f;
import BOX_CONSTS from "../consts/boxConsts";
var DEFAULT = "default";
var WEIGHTS_SHIPPING_BOXES = (_a = {},
    _a[DEFAULT] = {
        K90: 0.35,
        K105: 0.52,
        K130: 1,
        K150: 1.5,
        K180: 2.5,
        K200: 3,
    },
    _a);
var WEIGHTS_SHIPPING_BOX_PLAIN_V2 = (_b = {},
    _b[DEFAULT] = {
        K1: 0.055,
        K2: 0.06,
        K3: 0.08,
        K4: 0.1,
        K5: 0.1,
        K6: 0.13,
        K7: 0.13,
        K8: 0.16,
        K9: 0.17,
        K10: 0.22,
        K11: 0.25,
        K12: 0.25,
        K13: 0.36,
        K14: 0.36,
        K15: 0.36,
        K16: 0.36,
        K17: 0.16,
        K18: 0.4,
        K19: 0.88,
        K20: 0.91,
        K21: 0.59,
        K22: 0.67,
    },
    _b);
export default (_c = {},
    _c[BOX_CONSTS.SHIPPING_BOX] = (_d = {},
        _d[BOX_CONSTS.SHIPPING_BOX_DEFAULT_VARIANT] = WEIGHTS_SHIPPING_BOXES,
        _d),
    _c[BOX_CONSTS.SHIPPING_BOX_PLAIN] = (_e = {},
        _e[BOX_CONSTS.SHIPPING_BOX_PLAIN_DEFAULT_VARIANT] = WEIGHTS_SHIPPING_BOXES,
        _e),
    _c[BOX_CONSTS.SHIPPING_BOX_PLAIN_V2] = (_f = {},
        _f[BOX_CONSTS.SHIPPING_BOX_PLAIN_V2_DEFAULT_VARIANT] = WEIGHTS_SHIPPING_BOX_PLAIN_V2,
        _f),
    _c);
