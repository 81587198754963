import { useEffect, useState, useContext } from "react"
import { RootStoreContext } from "../../stores/store-contexts"
import { AllowedEditorFeatureFlagNames } from "../../services/feature-flags/feature-flag-client"

export function useFeatureFlagWithPooling<K extends boolean | string | number>(
  flagName: AllowedEditorFeatureFlagNames,
  poolingInterval: number = 60000
) {
  const { featureFlagsStore } = useContext(RootStoreContext)
  const [flagValue, setFlagValue] = useState<K>()
  const [isReady, setFlagReady] = useState(false)

  const setFeatureFlag = () => {
    featureFlagsStore.getFlagValueFromConfigCat(flagName).then((value) => {
      if (value !== flagValue) {
        setFlagValue(value as K)
      }
      setFlagReady(true)
    })
  }

  useEffect(() => {
    setFeatureFlag()
    const interval = setInterval(setFeatureFlag, poolingInterval)
    return () => clearInterval(interval)
  }, [flagName, flagValue, featureFlagsStore])

  return [flagValue, isReady] as const
}
