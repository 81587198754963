import React from "react"
import { observer } from "mobx-react-lite"
import { TType } from "shared-libs/src/js/shared-components/i18n/TypographyWithTranslate"
import Typography from "dsl/src/atoms/Typography/Typography"
import { I18N } from "../../../../i18n"
import { useTranslate } from "../../../../hooks/useTranslate"
import { Switch } from "dsl/src/atoms/Switch/Switch"
import styles from "./EditorToolLogoPlaceholderSwitch.module.scss"

interface EditorToolLogoPlaceholderSwitchProps {
  isChecked: boolean
  onChange: (isChecked: boolean) => void
}

export const EditorToolLogoPlaceholderSwitch = observer(
  ({ isChecked, onChange }: EditorToolLogoPlaceholderSwitchProps) => {
    const t = useTranslate()

    return (
      <>
        <hr className={styles.divider} />
        <div className={styles.header}>
          <Typography type={TType.Header17_500}>
            {t(I18N.editorTools.logoPlaceholder.logoPlaceholder)}
          </Typography>
          <Switch
            disabled={false}
            checked={isChecked}
            onChange={() => {
              onChange(!isChecked)
            }}
          />
        </div>
        <Typography type={TType.Body13_350}>
          {t(I18N.editorTools.logoPlaceholder.description)}
        </Typography>
      </>
    )
  }
)
