var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Big from "big.js";
import _round from "lodash/round";
import _isEmpty from "lodash/isEmpty";
import _each from "lodash/each";
import BOX_CONST from "../../../../../../source/boxProductSpec/generalConfig/consts/boxConsts";
import SleeveShapeBuilder from "./SleeveShapeBuilder";
var DPI = 300;
var INCH_TO_MM = 25.4;
function mmToPixelCalculator(value) {
    return _round((value * DPI) / INCH_TO_MM);
}
var addSleeveMailerBoxRenderConfigBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.initSideEditor = function (sideEditor) {
            return __awaiter(this, void 0, void 0, function () {
                var defaultImageSize_1, fabricStatic_1;
                var _this = this;
                return __generator(this, function (_a) {
                    if (sideEditor.editedSide !== this.SIDES["left"] &&
                        sideEditor.editedSide !== this.SIDES["right"]) {
                        defaultImageSize_1 = 512;
                        fabricStatic_1 = sideEditor.fabricStatic;
                        return [2 /*return*/, Promise.all(this.getTexturesConfig().map(function (textureConfig) { return __awaiter(_this, void 0, void 0, function () {
                                var imgElement;
                                return __generator(this, function (_a) {
                                    imgElement = document.getElementById(textureConfig.id);
                                    return [2 /*return*/, new Promise(function (resolve) {
                                            new fabricStatic_1.Image.fromURL(
                                            //@ts-ignore
                                            imgElement.src, function (imgObj) {
                                                sideEditor.cachedTextures[textureConfig.materialId] = imgObj;
                                                resolve(sideEditor);
                                            }, {
                                                crossOrigin: "anonymous",
                                                width: defaultImageSize_1,
                                                height: defaultImageSize_1,
                                            });
                                        })];
                                });
                            }); })).then(function () {
                                if (_isEmpty(sideEditor.data)) {
                                    // If data are empty we need to init sleeve background
                                    return sideEditor.backgroundsManager.setSideBackgroundTexture(undefined);
                                }
                                else {
                                    return Promise.resolve(sideEditor);
                                }
                            })];
                    }
                    else {
                        return [2 /*return*/, Promise.resolve(sideEditor)];
                    }
                    return [2 /*return*/];
                });
            });
        };
        class_1.prototype.getEditableSides = function () {
            var sideFeatures = this.product.editConfigManager.getUserProductSpecificConfig()
                .sideFeatures;
            var availabeSidesArr = this.getAvailableSidesArr();
            return availabeSidesArr.filter(function (side) {
                return sideFeatures[side] ? !sideFeatures[side].disableSide : true;
            });
        };
        // Box have it own productZoneCoords per side. It takes a foldline width for top, back front sides
        // so sides have different productZoneCoords per side. We need to rollback foldline width from productZoneCoords
        // to calculate sleeve width to be equal on each side.
        class_1.prototype.getSleeveProductZoneCoords = function (sideId, productZoneCoords, sideEditDimensions) {
            var width = productZoneCoords.width, left = productZoneCoords.left, top = productZoneCoords.top, height = productZoneCoords.height;
            var _a = this.getRenderConfig(), foldLineWidth = _a.foldLineWidth, sideFoldWidth = _a.sideFoldWidth;
            var sideDimensionsMM = this.getSideDimensionsMilimeters(sideId);
            var ratio = new Big(sideEditDimensions.width).div(new Big(sideDimensionsMM.width));
            var foldLineWidthPx = parseFloat(new Big(foldLineWidth).times(ratio));
            if (sideId === this.SIDES.front || sideId === this.SIDES.back) {
                var offsetPx = new Big(sideFoldWidth).times(ratio);
                width += parseFloat(offsetPx.times(2));
                left -= parseFloat(offsetPx);
            }
            if (sideId === this.SIDES.top) {
                var offsetPx = new Big(foldLineWidthPx);
                width += parseFloat(offsetPx.times(2));
                left -= parseFloat(offsetPx);
            }
            return {
                width: width,
                left: left,
                top: top,
                height: height,
            };
        };
        class_1.prototype.isSafeZonesEnabled = function () {
            return false;
        };
        class_1.prototype.getSleeveSideDimensions = function (sideID) {
            var productRenderConfig = this.getRenderConfig();
            var realDimsMM = productRenderConfig.editZonePosition.side[sideID];
            return {
                widthPx: mmToPixelCalculator(realDimsMM.width),
                heightPx: mmToPixelCalculator(realDimsMM.width),
                widthCm: _round(realDimsMM.width / 10, 2),
                heightCm: _round(realDimsMM.width / 10, 2),
            };
        };
        class_1.prototype.getSidesInfoDimensions = function () {
            var _this = this;
            var sidesInfoDimensions = _super.prototype.getSidesInfoDimensions.call(this);
            var sidesDimsWithSleeveWidth = {};
            _each(sidesInfoDimensions, function (dims, sideId) {
                if ("foldLine" === sideId || "sideFold" === sideId) {
                    sidesDimsWithSleeveWidth[sideId] = dims;
                }
                else {
                    var sleeveDims = _this.getSleeveSideDimensions(sideId);
                    if (sleeveDims) {
                        sidesDimsWithSleeveWidth[sideId] = Object.assign({}, dims, {
                            sleeveDims: {
                                widthPx: sleeveDims.widthPx,
                                widthCm: sleeveDims.widthCm,
                            },
                        });
                    }
                    else {
                        sidesDimsWithSleeveWidth[sideId] = dims;
                    }
                }
            });
            return sidesDimsWithSleeveWidth;
        };
        class_1.prototype.getProductEditZones = function (sideID, productZoneCoords, sideEditDimensions) {
            var sideDimensions = this.getSideDimensionsMilimeters(sideID);
            var sleeveProductZoneCoords = this.getSleeveProductZoneCoords(sideID, productZoneCoords, sideEditDimensions);
            var productRenderConfig = this.getRenderConfig();
            var SIDES = this.getAvailableSides();
            var ratioWidth = sleeveProductZoneCoords.width / sideDimensions.width;
            var ratioHeight = sleeveProductZoneCoords.height / sideDimensions.height;
            var safeZoneWidth = this.getSafeZoneWidth() * ratioWidth;
            var foldLineWidth = productRenderConfig.foldLineWidth * ratioWidth;
            var sideEditZonePosition = productRenderConfig.editZonePosition.side[sideID];
            var editZones = {};
            // Left and right do not have sleeves
            if (sideID === SIDES.left || sideID === SIDES.right) {
                editZones["foldLine"] = {
                    zone: {
                        top: productZoneCoords.top,
                        left: productZoneCoords.left,
                        width: productZoneCoords.width,
                        height: foldLineWidth,
                    },
                    safeZone: {
                        top: productZoneCoords.top + safeZoneWidth,
                        left: productZoneCoords.left + safeZoneWidth,
                        width: productZoneCoords.width - safeZoneWidth,
                        height: foldLineWidth - safeZoneWidth,
                    },
                };
                editZones["side"] = {
                    zone: {
                        top: foldLineWidth + productZoneCoords.top,
                        left: productZoneCoords.left + sideEditZonePosition.left * ratioWidth,
                        width: productZoneCoords.width - sideEditZonePosition.width * ratioWidth,
                        height: sideEditZonePosition.height * ratioWidth,
                    },
                    safeZone: {
                        top: foldLineWidth + productZoneCoords.top + safeZoneWidth,
                        left: productZoneCoords.left + safeZoneWidth,
                        width: productZoneCoords.width - safeZoneWidth,
                        height: productZoneCoords.height - foldLineWidth - safeZoneWidth,
                    },
                };
            }
            else {
                var zone = {
                    top: sleeveProductZoneCoords.top,
                    left: sleeveProductZoneCoords.left +
                        sideEditZonePosition.left * ratioWidth,
                    width: parseFloat(new Big(sideEditZonePosition.width).times(ratioWidth)),
                    height: parseFloat(new Big(sideEditZonePosition.height).times(ratioHeight)),
                };
                editZones["side"] = {
                    zone: zone,
                    safeZone: {
                        top: zone.top + safeZoneWidth,
                        left: zone.left + safeZoneWidth,
                        width: zone.width - safeZoneWidth,
                        height: zone.height - safeZoneWidth,
                    },
                };
            }
            return editZones;
        };
        // See index.html where those textures are preloaded
        class_1.prototype.getTexturesConfig = function () {
            return [
                {
                    materialId: BOX_CONST.PRINT_FINISH_SLEEVE_KRAFT,
                    id: "zpkj-box-pattern-image-sleeved-for-mailer-box",
                },
                {
                    materialId: BOX_CONST.PRINT_FINISH_SLEEVE_WHITE,
                    id: "zpkj-box-pattern-image-mailer-box-eco-white-and-color",
                },
            ];
        };
        class_1.prototype.createBackgroundTexture = function (_a) {
            var color = _a.color, sideEditorInstance = _a.sideEditorInstance;
            return __awaiter(this, void 0, void 0, function () {
                var textureObject, isKraftSleec;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            textureObject = sideEditorInstance.cachedTextures[this.product.addonsManager.getFinish()];
                            isKraftSleec = this.product.addonsManager.getFinish() ===
                                BOX_CONST.PRINT_FINISH_SLEEVE_KRAFT;
                            if (!(color && isKraftSleec)) return [3 /*break*/, 2];
                            return [4 /*yield*/, sideEditorInstance.filtersManager.applyObjectFilter(textureObject, "Blend", {
                                    color: color,
                                    alpha: 0.8,
                                    mode: "multiply",
                                })];
                        case 1:
                            textureObject =
                                _b.sent();
                            return [3 /*break*/, 5];
                        case 2:
                            if (!color) return [3 /*break*/, 4];
                            return [4 /*yield*/, sideEditorInstance.filtersManager.applyObjectFilter(textureObject, "Tint", {
                                    color: color,
                                    opacity: 1,
                                })];
                        case 3:
                            textureObject =
                                _b.sent();
                            return [3 /*break*/, 5];
                        case 4:
                            textureObject.filters = [];
                            textureObject.applyFilters(function () {
                                sideEditorInstance.fabric.renderAll();
                            });
                            _b.label = 5;
                        case 5: return [2 /*return*/, new SleeveShapeBuilder({
                                textureObject: textureObject,
                                material: this.product.addonsManager.getFinish(),
                                sideEditorInstance: sideEditorInstance,
                            }).buildSleeveShape(color)];
                    }
                });
            });
        };
        return class_1;
    }(superclass));
};
export default addSleeveMailerBoxRenderConfigBehaviors;
