import { VirtualDielineMigration } from "./virtual-dieline-migration.interface"
import { DesignVersion } from "../../../../../modules/design/version"
import VirtualDielineEditor from "../../virtual-dieline-editor"
import { AvailableRotations } from "../../modules/dieline-navigator/services/translation-calculator"
import { PackhelpEditableObject } from "../../object-extensions/packhelp-objects"
import { getDefaultMaskAssetObjectMeta } from "../../modules/assets-module/mask-controller/helpers"

/**
 * Fixes a typo in "maskParrentId" param.
 *
 * Changes pattern mask params to be compatible with the new way of
 * masks calculation for rotated walls.
 *
 * Adds assetObjectMeta to mask params.
 */
export default class V223 implements VirtualDielineMigration {
  readonly designVersion = DesignVersion.v223

  migrate(virtualDielineEditor: VirtualDielineEditor) {
    const globalPattern = this.getGlobalPattern(virtualDielineEditor)

    if (!globalPattern || !globalPattern.clipPath) {
      return
    }

    //@ts-ignore
    globalPattern.clipPath.getObjects().forEach((clipPathElement) => {
      if (clipPathElement.maskParrentId) {
        clipPathElement.maskParentId = clipPathElement.maskParrentId
        delete clipPathElement.maskParrentId

        const maskParent = this.getObjectById(
          virtualDielineEditor,
          clipPathElement.maskParentId
        )

        const rotation = this.getRotationByOriginSpaceArea(
          maskParent!.originSpaceArea
        )

        if (
          rotation === AvailableRotations.verticalLeft ||
          rotation === AvailableRotations.verticalRight
        ) {
          clipPathElement.set({
            width: clipPathElement.height,
            height: clipPathElement.width,
            scaleX: clipPathElement.scaleY,
            scaleY: clipPathElement.scaleX,
          })
        }

        clipPathElement.angle -= rotation
      }

      if (!clipPathElement.assetObjectMeta) {
        clipPathElement.assetObjectMeta = getDefaultMaskAssetObjectMeta()
      }
    })
  }

  private getGlobalPattern(virtualDielineEditor: VirtualDielineEditor) {
    return this.getObjectById(virtualDielineEditor, "global_pattern")
  }

  private getObjectById(
    virtualDielineEditor: VirtualDielineEditor,
    id: string
  ) {
    return virtualDielineEditor.fabricCanvas
      .getObjects()
      .find((obj) => obj.id === id) as PackhelpEditableObject
  }

  private getRotationByOriginSpaceArea(
    originSpaceArea: string
  ): AvailableRotations {
    if (originSpaceArea === "left") {
      return AvailableRotations.verticalLeft
    }

    if (originSpaceArea === "right") {
      return AvailableRotations.verticalRight
    }

    if (originSpaceArea === "bottom" || originSpaceArea === "back") {
      return AvailableRotations.upsideDown
    }

    return 0
  }
}
