import fabric from "../../../../../../libs/vendors/Fabric"
import {
  PackhelpCanvas,
  PackhelpEditableGroup,
  PackhelpEditableObject,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import { CanvasObjectEvent } from "../../../../../../stores/_events/domain-events"
import { degreesToRadians } from "../../../../../../libs/maths/angle-calculators"
import { assetParamsToIncludeInClonedObject } from "../../assets-module/types"
import { v4 as uuidv4 } from "uuid"

export const getGroup = (
  object: PackhelpEditableObject
): PackhelpEditableGroup | undefined => {
  if (!object.groupId) {
    return
  }

  if (object.group) {
    return object.group as PackhelpEditableGroup
  }

  if (!object.canvas) {
    return
  }

  const canvas = object.canvas as PackhelpCanvas
  const group = canvas
    .getObjects()
    .find((canvasObject) => canvasObject.id === object.groupId)

  if (!group) {
    return
  }

  return group as PackhelpEditableGroup
}

export const refreshGroup = (group: PackhelpEditableGroup) => {
  const groupAngle = group.angle!
  const centerPoint = group.getCenterPoint()

  for (const object of group.getObjects()) {
    const newPosition = fabric.util.rotatePoint(
      new fabric.Point(object.left!, object.top!),
      new fabric.Point(0, 0),
      fabric.util.degreesToRadians(-groupAngle)
    )

    object.set({
      left: newPosition.x,
      top: newPosition.y,
      angle: object.angle! - groupAngle,
    })
  }

  group.addWithUpdate()

  const newPosition = fabric.util.rotatePoint(
    new fabric.Point(group.left!, group.top!),
    centerPoint,
    fabric.util.degreesToRadians(groupAngle)
  )

  group.set({
    left: newPosition.x,
    top: newPosition.y,
    angle: groupAngle,
  })

  group.fire(CanvasObjectEvent.changed)
}

export const cloneGroupObject = async (
  object: PackhelpEditableObject
): Promise<PackhelpEditableObject> => {
  return new Promise((resolve) => {
    object.clone(
      (clonedObject) => {
        clonedObject.set({
          ...calcObjectAbsoluteValues(object),
          id: uuidv4(),
          groupId: object.groupId,
        })

        resolve(clonedObject)
      },
      [
        ...assetParamsToIncludeInClonedObject,
        "isOverscaled",
        "maxWidth",
        "maxHeight",
      ]
    )
  })
}

export const calcObjectAbsoluteValues = (
  object: PackhelpObject
): Partial<PackhelpObject> => {
  if (!object.group) {
    return {
      left: object.left,
      top: object.top,
      angle: object.angle,
      scaleX: object.scaleX,
      scaleY: object.scaleY,
    }
  }

  const left =
    object.group.left! +
    object.group.width! / 2 +
    object.left! * object.group.scaleX!

  const top =
    object.group.top! +
    object.group.height! / 2 +
    object.top! * object.group.scaleY!

  const newPosition = fabric.util.rotatePoint(
    new fabric.Point(left, top),
    new fabric.Point(object.group.left!, object.group.top!),
    degreesToRadians(object.group.angle!)
  )

  const roundCoord = (val) => Math.round(val * 100) / 100

  return {
    left: roundCoord(newPosition.x),
    top: roundCoord(newPosition.y),
    angle: object.group.angle! + object.angle!,
    scaleX: object.group.scaleX! * object.scaleX!,
    scaleY: object.group.scaleY! * object.scaleY!,
  }
}
