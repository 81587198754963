import React from "react"
import { Modal } from "../Modal"
import { Details } from "shared-libs/src/js/shared-views/sustainability/planting-trees/reforestation-support/components/Details"

interface SustainabilityProps {
  onClose: () => void
}

export const SustainabilityModal = ({ onClose }: SustainabilityProps) => (
  <Modal onClose={onClose}>
    <Details />
  </Modal>
)
