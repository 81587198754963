import assets from "./assets/assets";
import dimensions from "./dimensions/dimensions";
import productionTimes from "./productionTimes/productionTimes";
import variants from "./variants/variants";
import weights from "./weights/weights";
import vendors from "./vendors/vendors";
import ecoProperties from "./ecoProperties/ecoProperties";
import consts from "./consts/ncpConsts";
import quantities from "./quantities";
import grammages from "./grammages/grammages";
// @ts-ignore
var NCP_PRODUCTS_CONFIG = {
    ASSETS: assets,
    DIMENSIONS: dimensions,
    PRODUCTION_TIMES: productionTimes,
    VARIANTS: variants,
    WEIGHTS: weights,
    VENDORS: vendors,
    CONSTS: consts,
    QUANTITIES: quantities,
    ECO_PROPERTIES: ecoProperties,
    GRAMMAGES: grammages,
};
export default NCP_PRODUCTS_CONFIG;
