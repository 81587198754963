var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import colorString from "color-string";
// clip to svg polygon path
// - tapes
// - envelopes
var addCreateClipObjectBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getProductEditZones = function (sideID, productZoneCoords) {
            var editZones = {};
            var sideEditZoneMM = this.getEditZonePositionBySide(sideID);
            var ratio = this.getMilimetersToPixelsRatio(productZoneCoords);
            if (sideEditZoneMM.foldline) {
                var foldlinePosition = sideEditZoneMM.foldline;
                editZones["foldline"] = {
                    zone: {
                        top: productZoneCoords.top + foldlinePosition.top,
                        left: productZoneCoords.left + foldlinePosition.left,
                        width: foldlinePosition.width * ratio,
                        height: foldlinePosition.height * ratio,
                    },
                };
            }
            editZones["side"] = {
                zone: {
                    top: productZoneCoords.top + sideEditZoneMM.top * ratio,
                    left: productZoneCoords.left + sideEditZoneMM.left * ratio,
                    width: sideEditZoneMM.width * ratio,
                    height: sideEditZoneMM.height * ratio,
                    shape: sideEditZoneMM.shape,
                    foldLine: sideEditZoneMM.foldLine,
                },
            };
            return editZones;
        };
        class_1.prototype.setFoldline = function (sideEditorInstance, foldLineID, img) {
            var _a = sideEditorInstance.productEditZones.side.zone, left = _a.left, width = _a.width, top = _a.top;
            var ratio = width / img.width;
            img.originX = "left";
            img.originY = "top";
            img.evented = false;
            img.selectable = false;
            img.width = img.width * ratio;
            img.height = img.height * ratio;
            img.setLeft(left);
            img.setTop(top);
            img.setAngle(180);
            img.set("id", foldLineID);
            return img;
        };
        class_1.prototype.createBackgroundShape = function (_a) {
            var sideEditorInstance = _a.sideEditorInstance, parsedColor = _a.parsedColor, editZone = _a.editZone, zoneName = _a.zoneName;
            var fabricStatic = sideEditorInstance.fabricStatic, productClipObject = sideEditorInstance.productClipObject;
            var zone = editZone.zone;
            var shapeOptions = {
                left: 0,
                top: 0,
                fill: colorString.to.rgb(parsedColor),
                width: zone.width,
                height: zone.height,
                id: "background_" + zoneName,
            };
            var clipShape = new fabricStatic.Rect(shapeOptions);
            shapeOptions = __assign(__assign({}, shapeOptions), { left: zone.left, top: zone.top, 
                //@ts-ignore
                productClipObject: productClipObject, clipTo: this.getClipingFunction() });
            return Promise.resolve({
                shape: clipShape,
                shapeOptions: shapeOptions,
            });
        };
        class_1.prototype.createClipObject = function (sideEditorInstance, params) {
            if (params === void 0) { params = { closeEnvelope: false }; }
            var productEditZones = sideEditorInstance.productEditZones, productClipZone = sideEditorInstance.productClipZone, fabricStatic = sideEditorInstance.fabricStatic;
            var closeEnvelope = params.closeEnvelope;
            if (closeEnvelope) {
                var closeEnvelopeClipShape_1 = this.getRenderConfig()["editZonePosition"]["side"]["back"]["shape"];
                return new Promise(function (resolve) {
                    fabricStatic.loadSVGFromString(closeEnvelopeClipShape_1, function (objects) {
                        var _a = objects[0], width = _a.width, height = _a.height;
                        var ratio = productEditZones.side.zone.width / width;
                        var points = objects[0].points.map(function (point) {
                            return {
                                x: ratio * point.x,
                                y: ratio * point.y,
                            };
                        });
                        var clipPolygon = new fabricStatic.Polygon(points, {
                            left: productEditZones.side.zone.left,
                            top: productEditZones.side.zone.top +
                                productEditZones.foldline.zone.height,
                            width: ratio * width,
                            height: ratio * height,
                            fill: "#DDD",
                            strokeWidth: 0,
                            selectable: false,
                            objectCaching: false,
                        });
                        resolve(clipPolygon);
                    });
                });
            }
            else {
                return new Promise(function (resolve) {
                    fabricStatic.loadSVGFromString(productClipZone.shape, function (objects) {
                        var _a = objects[0], width = _a.width, height = _a.height;
                        var ratio = productEditZones.side.zone.width / width;
                        var points = objects[0].points.map(function (point) {
                            return {
                                x: ratio * point.x,
                                y: ratio * point.y,
                            };
                        });
                        var clipPolygon = new fabricStatic.Polygon(points, {
                            left: productEditZones.side.zone.left,
                            top: productEditZones.side.zone.top,
                            width: ratio * width,
                            height: ratio * height,
                            fill: "#DDD",
                            strokeWidth: 0,
                            selectable: false,
                        });
                        resolve(clipPolygon);
                    });
                });
            }
        };
        class_1.prototype.getClipingFunction = function () {
            return function (ctx) {
                var _this = this;
                function degToRad(degrees) {
                    return degrees * (Math.PI / 180);
                }
                this.setCoords();
                var productClipRect = this.productClipObject;
                var scaleXTo1 = 1 / this.scaleX;
                var scaleYTo1 = 1 / this.scaleY;
                ctx.save();
                var ctxLeft = -(this.width / 2) + productClipRect.strokeWidth;
                var ctxTop = -(this.height / 2) + productClipRect.strokeWidth;
                ctx.translate(ctxLeft, ctxTop);
                ctx.rotate(degToRad(this.angle * -1));
                ctx.scale(scaleXTo1, scaleYTo1);
                ctx.beginPath();
                var points = productClipRect.points.map(function (point) {
                    return {
                        x: productClipRect.left + point.x - _this.oCoords.tl.x,
                        y: productClipRect.top + point.y - _this.oCoords.tl.y,
                    };
                });
                points.forEach(function (point) {
                    ctx.lineTo(point.x, point.y);
                });
                ctx.lineTo(points[0].x, points[0].y);
                ctx.closePath();
                ctx.restore();
            };
        };
        return class_1;
    }(superclass));
};
export default addCreateClipObjectBehaviors;
