import { NoneEffectController } from "./none-effect-controller"
import { ShadowEffectController } from "./shadow-effect-controller"
import { StrokeEffectController } from "./stroke-effect-controller"
import { EffectControllerMap, EffectType } from "../types"
import { TextObjectController } from "../../../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/text-object-controller"

export const getEffectControllers = (
  objectController: TextObjectController
): EffectControllerMap => {
  return {
    none: new NoneEffectController(),
    shadow: new ShadowEffectController(objectController),
    stroke: new StrokeEffectController(objectController),
  }
}

export const getInitialType = (
  objectController: TextObjectController
): EffectType => {
  if (objectController.hasShadow()) {
    return "shadow"
  }

  if (objectController.hasStroke()) {
    return "stroke"
  }

  return "none"
}
