import React, { useEffect, useState } from "react"

import { AsyncIntContainer } from "shared-libs/src/js/libs/AsyncI18nLocales"
import { Theater } from "./ui/theater/Theater"
import { RootStoreContext } from "./stores/store-contexts"
import { ErrorBoundary } from "./ErrorBoundary"
import { exposeApis } from "./services/expose-cli-api"
import { Loader } from "./ui/dsl/atoms/Loader/Loader"
import { LoadingStatesIntl } from "./ui/theater/curtain/Curtain"
import { Error, Unauthorized } from "./ui/theater/proscenium/views"
import { useContainer } from "./_containers-react/_editor-app-hooks"
import { EnsureEcommerceContainer } from "./_containers-react/EnsureEcommerce"

import "./App.css"

export const App = () => {
  const [classicRootStore, classicRootStoreErr] =
    useContainer().classicRootStore

  const [error, setError] = useState<any>(null)
  const [isUnauthorized, setIsUnauthorized] = useState<any>(false)
  const [rootStore, setRootStore] = useState<any>()

  useEffect(() => {
    if (classicRootStoreErr?.response?.status === 403) {
      setIsUnauthorized(true)
      return
    } else {
      setIsUnauthorized(false)
    }
    if (classicRootStoreErr != null) {
      console.error(classicRootStoreErr)

      if (window.Sentry) {
        window.Sentry.captureException(classicRootStoreErr)
        setError(classicRootStoreErr)
      }
    }
  }, [classicRootStoreErr])

  useEffect(() => {
    try {
      if (classicRootStore) {
        setRootStore(classicRootStore.rootStore)
        exposeApis()
      }
    } catch (e) {
      console.error(e)

      if (window.Sentry) {
        window.Sentry.captureException(e)
        setError(e)
      }
    }
  }, [classicRootStore])

  return (
    <div className="App">
      <AsyncIntContainer>
        {!!rootStore ? (
          <RootStoreContext.Provider value={rootStore}>
            <ErrorBoundary error={error}>
              <EnsureEcommerceContainer>
                <Theater />
              </EnsureEcommerceContainer>
            </ErrorBoundary>
          </RootStoreContext.Provider>
        ) : isUnauthorized ? (
          <Unauthorized />
        ) : error ? (
          <Error />
        ) : (
          <Loader formattedMessageID={LoadingStatesIntl.generic} />
        )}
      </AsyncIntContainer>
    </div>
  )
}
