import { VirtualDielinesData } from "../modules/ph-api/asset-types"
import { DesignVersion } from "../modules/design/version"
import { CameraSettings } from "../libs/products-render-config/types"

export const CANVAS_DIM = 1024

export type VirtualDielineDesign = {
  virtualDielines: VirtualDielinesData
  designDataFormatVersion?: DesignVersion
}

export type RenderEngineExport = {
  virtualDielines: VirtualDielinesData
  thumbnail: string
}

export type RenderEngineExportConfig = {
  previews: boolean
}

export type RendererMountPoints = {
  threeDimensionalRendererMountPoint: HTMLDivElement
  virtualDielineMountPoints: {
    [key: string]: HTMLDivElement
  }
}

export type TwoDimensionalMountPoints = {
  [key: string]: HTMLDivElement
}

export type ThumbnailGeneratorConfig = {
  width: number
  height: number
  mimeType: string
  quality: number
  cameraSettings?: CameraSettings
}
