import React from "react"
import cxBinder from "classnames/bind"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { useTranslate } from "../../../hooks/useTranslate"
import { ReactComponent as UploadIcon } from "../../../assets/svg/Upload.svg"
import styles from "./Upload.module.scss"
import { StateUploading } from "./ImageUpload"

const cx = cxBinder.bind(styles)

enum i18n {
  addFile = "editor.upload.add-file",
  dropFile = "editor.upload.add-file",
}

interface UploadProps {
  isUploading?: boolean
  onCancel?: () => void
}

export const Upload = ({ isUploading, onCancel }: UploadProps) => {
  return (
    <div
      className={cx({
        wrapper: true,
      })}
    >
      <div className={styles.upload_state}>
        {isUploading ? (
          <StateUploading onCancel={onCancel} />
        ) : (
          <StateInitial />
        )}
      </div>
    </div>
  )
}

const StateInitial = () => {
  const t = useTranslate()
  return (
    <>
      <UploadIcon className={cx("upload-icon")} />
      <p>
        <span className={cx("content__mobile", "highlight")}>
          {t(i18n.addFile)}
        </span>
        <span className={cx("content__desktop")}>
          <Typography type={TType.Body13_350}>
            <span className={cx("highlight")}>{t(i18n.dropFile)}</span>{" "}
          </Typography>
        </span>
      </p>
    </>
  )
}
