import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey, isProductTypeCustom, } from "../SkuLib";
import CustomPaperBagProduct from "../CustomProducts/CustomPaperBagProduct";
import PaperBagProduct from "../Products/PaperBagProduct/PaperBagProduct";
import PaperBagVariantManager from "../Products/PaperBagProduct/behaviorsComposers/PaperBagVariantManager";
function PaperBagProductFactory(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    var productName = decodedSKU.productName;
    var productVariant = PaperBagVariantManager.findVariant(productName);
    if (isProductTypeCustom(sku)) {
        return new CustomPaperBagProduct(sku, productName);
    }
    else {
        return new PaperBagProduct(sku, productName, productVariant);
    }
}
export default PaperBagProductFactory;
