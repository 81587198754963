import React, { FC } from "react"

import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { LinkAsButton } from "dsl/src/atoms/Link/Link"

import styles from "./ModalTemplateConfirm.module.scss"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"

interface ModalTemplateConfirmProps {
  onClose: () => void
  title?: string | React.ReactNode
  cancelLinkText?: string
  buttonComponent: React.ReactNode
  withCancel?: boolean
  buttonDisabled?: boolean
}

const ModalTemplateConfirm: FC<ModalTemplateConfirmProps> = ({
  children,
  onClose,
  title,
  cancelLinkText,
  buttonComponent,
  withCancel,
  buttonDisabled = false,
}) => {
  const t = useTranslate()

  return (
    <div
      className={styles.wrapper}
      e2e-target="modal"
      e2e-target-name="share-design"
    >
      {!!title && (
        <div className={styles.row}>
          <Typography type={TType.Header32_400}>{title}</Typography>
        </div>
      )}
      <div className={styles.row}>{children}</div>
      <div className={styles.row}>
        <div className={styles.row__actions}>
          {withCancel && (
            <LinkAsButton
              onClick={onClose}
              e2eTargetName="cancel"
              disabled={buttonDisabled}
            >
              {cancelLinkText || t(I18N.generic.cancel)}
            </LinkAsButton>
          )}
          {buttonComponent}
        </div>
      </div>
    </div>
  )
}

export { ModalTemplateConfirm as default }
