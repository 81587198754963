var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BOX_CONSTS from "../../../../../source/boxProductSpec/generalConfig/consts/boxConsts";
import PAPER_BAG_CONSTS from "../../../../../source/bagProductSpec/generalConfig/consts/bagConsts";
import TISSUE_PAPER_CONSTS from "../../../../../source/tissuePaperProductSpec/generalConfig/consts/tissuePaperConsts";
import POLY_MAILER_CONSTS from "../../../../../source/polyMailerProductSpec/generalConfig/consts/polyMailerConsts";
import TAPE_CONSTS from "../../../../../source/tapeProductSpec/generalConfig/consts/tapeConsts";
import ENVELOPE_CONSTS from "../../../../../source/envelopeProductSpec/generalConfig/consts/envelopeConsts";
import SAMPLE_PACKS_CONST from "../../../../../source/samplePackProductSpec/generalConfig/consts/samplePackConsts";
import PAPER_TUBE_CONSTS from "../../../../../source/paperTubeProductSpec/generalConfig/consts/paperTubeConsts";
import PAPER_MAILER from "../../../../../source/paperMailerProductSpec/generalConfig/consts/paperMailerConsts";
import NCP_CONSTS from "../../../../../source/ncpProductSpec/generalConfig/consts/ncpConsts";
import EXTENSION_PRODUCT_CONSTS from "../../../../../source/extensionProductSpec/generalConfig/extensionProductConsts";
import LABEL_CONST from "../../../../../source/labelProductSpec/generalConfig/consts/labelConsts";
import CARD_CONST from "../../../../../source/cardProductSpec/generalConfig/consts/cardConsts";
import TUBE_CONSTS from "../../../../../source/paperTubeProductSpec/generalConfig/consts/paperTubeConsts";
import { isProductTypeCustom, isProductExtension } from "../../../SkuLib";
var addWizzardBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.isProductBox = function () {
            return this.product.variantManager.getType() === BOX_CONSTS.PRODUCT_TYPE;
        };
        class_1.prototype.isPaperTube = function () {
            return (this.product.variantManager.getType() === PAPER_TUBE_CONSTS.PRODUCT_TYPE);
        };
        class_1.prototype.isProductTape = function () {
            return this.product.variantManager.getType() === TAPE_CONSTS.PRODUCT_TYPE;
        };
        class_1.prototype.isProductEnvelope = function () {
            return (this.product.variantManager.getType() === ENVELOPE_CONSTS.PRODUCT_TYPE);
        };
        class_1.prototype.isProductBag = function () {
            return (this.product.variantManager.getType() === PAPER_BAG_CONSTS.PRODUCT_TYPE);
        };
        class_1.prototype.isProductPaperBag = function () {
            return (this.product.variantManager.getName() === PAPER_BAG_CONSTS.PAPER_BAG);
        };
        class_1.prototype.isProductDoypack = function () {
            return (this.product.variantManager.getName() === PAPER_BAG_CONSTS.DOYPACK_BAG);
        };
        class_1.prototype.isProductToteBag = function () {
            return this.product.variantManager.getName() === PAPER_BAG_CONSTS.TOTE_BAG;
        };
        class_1.prototype.isProductPrintedCard = function () {
            return this.product.variantManager.getName() === CARD_CONST.PRINTED_CARD;
        };
        class_1.prototype.isProductTissuePaper = function () {
            return (this.product.variantManager.getType() ===
                TISSUE_PAPER_CONSTS.PRODUCT_TYPE);
        };
        class_1.prototype.isProductPackingPaper = function () {
            return (this.product.variantManager.getType() ===
                TISSUE_PAPER_CONSTS.PACKING_PAPER);
        };
        /**
         * No bio polymailers
         */
        class_1.prototype.isProductClassicPolyMailer = function () {
            return (this.isProductPolyMailer() &&
                this.product.variantManager.getName() ===
                    POLY_MAILER_CONSTS.POLY_MAILER_VARIANT_STANDARD);
        };
        class_1.prototype.isProductPolyMailer = function () {
            return (this.product.variantManager.getType() ===
                POLY_MAILER_CONSTS.PRODUCT_TYPE);
        };
        class_1.prototype.isProductPaperMailer = function () {
            return this.product.variantManager.getType() === PAPER_MAILER.PRODUCT_TYPE;
        };
        class_1.prototype.isProductLabel = function () {
            return this.product.variantManager.getType() === LABEL_CONST.PRODUCT_TYPE;
        };
        class_1.prototype.isProductSamplePack = function () {
            return (this.product.variantManager.getType() ===
                SAMPLE_PACKS_CONST.PRODUCT_TYPE);
        };
        class_1.prototype.isDigitalDesign = function () {
            var productName = this.product.variantManager.getName();
            return (productName ===
                EXTENSION_PRODUCT_CONSTS.EXTENSION_PRODUCT_DIGITAL_DESIGN);
        };
        class_1.prototype.isProductCustom = function () {
            return isProductTypeCustom(this.product.variantManager.getSku());
        };
        class_1.prototype.isProductExtension = function () {
            return isProductExtension(this.product.variantManager.getSku());
        };
        class_1.prototype.isProductNcp = function () {
            return this.product.variantManager.getType() === NCP_CONSTS.PRODUCT_TYPE;
        };
        class_1.prototype.isProductsCollection = function () {
            return this.isProductSamplePack();
        };
        class_1.prototype.isProductNoPrint = function () {
            var _this = this;
            var noPrintProducts = [
                BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR,
                BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO,
                BOX_CONSTS.SHIPPING_BOX_PLAIN,
                BOX_CONSTS.SHIPPING_BOX_PLAIN_V2,
                BOX_CONSTS.MAILER_BOX_PLAIN,
                BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN,
                POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN,
                TAPE_CONSTS.PRE_PRINTED_KRAFT_TAPE,
            ];
            return noPrintProducts.find(function (productName) {
                return productName === _this.product.variantManager.getName();
            });
        };
        class_1.prototype.isDoubleSidePrinted = function () {
            var productColoreMode = this.product.variantManager.getColorMode();
            return (productColoreMode === PAPER_BAG_CONSTS.PRINT_MODE_MONO_PANTONE_TWO_SIDES);
        };
        class_1.prototype.isProductMailerBox = function () {
            return (this.product.variantManager.getName() ===
                BOX_CONSTS.BOX_MODEL_MAILER_BOX);
        };
        class_1.prototype.isProductSleevedMailerBox = function () {
            return (this.product.variantManager.getName() === BOX_CONSTS.SLEEVED_MAILER_BOX);
        };
        class_1.prototype.isProductWhiteOnKraft = function () {
            return (this.product.variantManager.getName() ===
                BOX_CONSTS.MAILER_BOX_WHITE_INK);
        };
        class_1.prototype.isProductDeliveryMailerBox = function () {
            return (this.product.variantManager.getName() === BOX_CONSTS.DELIVERY_MAILER_BOX);
        };
        class_1.prototype.isProductDeliveryMailerBoxPlain = function () {
            return (this.product.variantManager.getName() ===
                BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN);
        };
        class_1.prototype.isProductPrePrinted = function () {
            var _this = this;
            var prePrintedProducts = [
                POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN,
                BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR,
                BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO,
                TAPE_CONSTS.PRE_PRINTED_KRAFT_TAPE,
            ];
            return prePrintedProducts.find(function (productName) {
                return productName === _this.product.variantManager.getName();
            });
        };
        class_1.prototype.isProductPlain = function () {
            var _this = this;
            var plainProducts = [
                BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN,
                BOX_CONSTS.MAILER_BOX_PLAIN,
                BOX_CONSTS.SHIPPING_BOX_PLAIN_V2,
            ];
            return plainProducts.find(function (productName) {
                return productName === _this.product.variantManager.getName();
            });
        };
        class_1.prototype.isProductProductBox = function () {
            return this.product.variantManager.getName() === BOX_CONSTS.PRODUCT_BOX;
        };
        class_1.prototype.isProductWineBox = function () {
            return (this.product.variantManager.getName() ===
                BOX_CONSTS.PRODUCT_BOX_WINE_BOX);
        };
        class_1.prototype.isClassicProductBox = function () {
            return this.product.variantManager.getName() === BOX_CONSTS.PRODUCT_BOX;
        };
        class_1.prototype.isProductKraftTape = function () {
            return this.product.variantManager.getName() === TAPE_CONSTS.KRAFT_TAPE;
        };
        class_1.prototype.isProductPackingTape = function () {
            return this.product.variantManager.getName() === TAPE_CONSTS.PACKING_TAPE;
        };
        class_1.prototype.isProductPaperCan = function () {
            return this.product.variantManager.getName() === TUBE_CONSTS.PAPER_CAN;
        };
        class_1.prototype.isProductShippingBox = function () {
            return this.product.variantManager.getName() === BOX_CONSTS.SHIPPING_BOX;
        };
        class_1.prototype.isProductShippingBoxPlainV2 = function () {
            return (this.product.variantManager.getName() ===
                BOX_CONSTS.SHIPPING_BOX_PLAIN_V2);
        };
        class_1.prototype.isDesignDupWithSizeAble = function () {
            if (this.isProductNoPrint() ||
                this.isProductEnvelope() ||
                this.isProductPaperMailer()) {
                return false;
            }
            return (this.isProductBox() || this.isProductPolyMailer() || this.isProductBag());
        };
        return class_1;
    }(superclass));
};
export default addWizzardBehaviors;
