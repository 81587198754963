import React from "react"
import { observer } from "mobx-react-lite"

import { WalletDropdown } from "shared-libs/src/js/shared-views/loyalty/wallet/components/wallet-dropdown/WalletDropdown"
import { WalletWrapper } from "shared-libs/src/js/shared-views/loyalty/wallet/components/wallet-wrapper/WalletWrapper"
import { trackTooltipInEditorClick } from "shared-libs/src/js/shared-views/loyalty/wallet/utils/wallet.segment"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

import styles from "./EditorHeaderMenuItemWallet.module.scss"

export const EditorHeaderMenuItemWallet = observer(() => {
  const [containerSet] = useContainerSet((c) => [c.ecommerce, c.apiSession])

  if (!containerSet || !containerSet.ecommerce.available) {
    return null
  }

  const totalPrice = containerSet.ecommerce.productPricingStore.totalPrice.value
  const isUserLoggedIn = !!containerSet.apiSession.sessionStore.user.email

  return (
    <div className={styles.editor_header_menu_item__wrapper}>
      <WalletWrapper isUserLoggedIn={isUserLoggedIn}>
        <WalletDropdown
          simpleWallet={true}
          totalPrice={totalPrice}
          linkSegmentTrackClick={trackTooltipInEditorClick}
        />
      </WalletWrapper>
    </div>
  )
})
