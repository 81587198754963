var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var ENVELOPE_GLUE_GROUP = "EnvelopeGlueGroup";
var addEnvelopeRenderConfigBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.initSideEditor = function (sideEditor) {
            return __awaiter(this, void 0, void 0, function () {
                var sideImgUrl;
                return __generator(this, function (_a) {
                    if (sideEditor.editedSide === "back") {
                        this.setEditorGluePicture(sideEditor);
                    }
                    sideImgUrl = sideEditor.product.getEditorBackgroundPath(sideEditor.editedSide);
                    return [2 /*return*/, sideEditor.setSideBackgroundImage(sideImgUrl)];
                });
            });
        };
        class_1.prototype.isCustomObjectCollisonEnabled = function () {
            return true;
        };
        class_1.prototype.getEnvelopeGlueGroup = function (objectsArray) {
            return objectsArray.find(function (object) {
                return object.id && object.id === ENVELOPE_GLUE_GROUP;
            });
        };
        class_1.prototype.getFabricObjectParameters = function () {
            return Object.assign({}, {
                originX: "left",
                originY: "top",
                selectable: false,
            }, this.product.getGlueStripObjectDetails());
        };
        class_1.prototype.removeEnvelopeGlueGroup = function (canvas) {
            var objectsArray = canvas.getObjects();
            objectsArray = this.getEnvelopeGlueGroup(objectsArray);
            if (!objectsArray || objectsArray.length == 0)
                return;
            canvas.remove(objectsArray);
        };
        class_1.prototype.setEditorGluePicture = function (sideEditor) {
            var _this = this;
            var objectUrl = sideEditor.product.getEditorGlueImage();
            if (!objectUrl)
                return;
            if (!sideEditor.fabric.getObjects())
                return;
            this.removeEnvelopeGlueGroup(sideEditor.fabric);
            sideEditor.fabricStatic.Image.fromURL(objectUrl, function (img) {
                img.set(_this.getFabricObjectParameters());
                _this.setEditorGluePictureSafeZones(sideEditor, img);
            }, {
                crossOrigin: "anonymous",
            });
        };
        class_1.prototype.setGlueTranslation = function (translation) {
            //@ts-ignore
            var objects = window.sideEditors.back.fabric.getObjects();
            var envelopeGlueGroup = this.getEnvelopeGlueGroup(objects);
            if (!envelopeGlueGroup)
                return;
            envelopeGlueGroup.getObjects().find(function (klass) {
                if (klass.id === "glueTextInfo") {
                    klass.text = translation;
                }
            });
        };
        class_1.prototype.setEditorGluePictureSafeZones = function (sideEditor, image) {
            var fabric = sideEditor.fabricStatic;
            var rect = new fabric.Rect(Object.assign({}, this.getFabricObjectParameters(), {
                fill: "rgba(238, 171, 29, 1)",
                stroke: "rgba(238, 171, 29, 1)",
                strokeWidth: 4,
                opacity: 1,
                id: "glueStrip",
                evented: false,
            }));
            var text = new fabric.Text("", Object.assign({}, this.getFabricObjectParameters(), {
                fontFamily: "Neuton",
                fontSize: "12",
                id: "glueTextInfo",
                left: rect.left + 10,
                top: rect.top + 10,
                fill: "#ffffff",
                opacity: 0,
            }));
            var group = new fabric.Group([image, rect, text]);
            group.selectable = false;
            group.hoverCursor = "default";
            group.id = ENVELOPE_GLUE_GROUP;
            sideEditor.fabric.add(group);
            sideEditor.fabric.bringToFront(sideEditor.fabric.getActiveGroup());
        };
        return class_1;
    }(superclass));
};
export default addEnvelopeRenderConfigBehaviors;
