import React from "react"
import cxBinder from "classnames/bind"
import {
  FontSettings,
  FontWeight,
  FontStyle,
} from "../../../../../libs/services/fonts-loader-service/fonts-loader.service"
import { ReactComponent as IconTextBold } from "../../../../assets/svg/text-bold.svg"
import { ReactComponent as IconTextItalic } from "../../../../assets/svg/text-italic.svg"
import { EditorHeaderToolbarItem } from "../../EditorHeader/EditorHeaderToolbar/EditorHeaderToolbar"
import { IconButton } from "../../../atoms/IconButton/IconButton"
import styles from "../EditorTools.module.scss"
import { useTranslate } from "../../../../hooks/useTranslate"

const cx = cxBinder.bind(styles)
type FontStyleAndWeightAttributes = FontStyle | FontWeight

interface EditorToolTextStyleOptionsProps {
  activeObjectController
  fontParams
  setFontParams
}

const editorToolTextStyleItems = [
  {
    fontAttribute: FontSettings.fontStyle,
    fontProperty: FontStyle.italic,
    translationIntl: "components.editor-tool-text.label.font-style",
    Icon: IconTextItalic,
  },
  {
    fontAttribute: FontSettings.fontWeight,
    fontProperty: FontWeight.bold,
    translationIntl: "components.editor-tool-text.label.font-weight",
    Icon: IconTextBold,
  },
]

export const EditorToolTextStyleOptions = ({
  activeObjectController,
  fontParams,
  setFontParams,
}: EditorToolTextStyleOptionsProps) => {
  const t = useTranslate()
  const activeObject = activeObjectController.getObject()

  const onStyleSelect = (
    fontParamKey: FontSettings.fontStyle | FontSettings.fontWeight,
    fontParam: FontStyleAndWeightAttributes
  ): void => {
    activeObjectController.setStyles({
      [fontParamKey]: fontParam,
    })
    setFontParams(Object.assign({}, fontParams, { [fontParamKey]: fontParam }))
  }

  const onFontWeightAndStyleSelect = (fontAttribute: FontSettings): void => {
    const currentFontStyle = activeObject.get("fontStyle")
    const currentFontWeight = activeObject.get("fontWeight")

    switch (fontAttribute) {
      case FontSettings.fontWeight:
        const fontWeight =
          FontWeight.bold === currentFontWeight
            ? FontWeight.normal
            : FontWeight.bold
        onStyleSelect(FontSettings.fontWeight, fontWeight)
        return

      case FontSettings.fontStyle:
        const fontStyle =
          FontStyle.italic === currentFontStyle
            ? FontStyle.normal
            : FontStyle.italic
        onStyleSelect(FontSettings.fontStyle, fontStyle)
        return
    }
  }

  return (
    <div className={styles.header_toolbar_list_group}>
      {editorToolTextStyleItems.map(
        ({ fontAttribute, translationIntl, Icon }, index) => (
          <li
            key={index}
            className={cx({
              header_toolbar_list_item: true,
              "header_toolbar_list_item--fonts_style": true,
            })}
          >
            <EditorHeaderToolbarItem
              onClick={() => {
                activeObjectController.isFontPropertyAvailable(fontAttribute) &&
                  onFontWeightAndStyleSelect(fontAttribute)
              }}
              helperMessage={t(translationIntl)}
            >
              <IconButton
                isActive={activeObjectController.isFontPropertyActive(
                  fontAttribute
                )}
                disabled={
                  !activeObjectController.isFontPropertyAvailable(fontAttribute)
                }
                Icon={Icon}
                e2eTargetName={fontAttribute}
              />
            </EditorHeaderToolbarItem>
          </li>
        )
      )}
    </div>
  )
}
