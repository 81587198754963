import { trackThroughBackend } from "../../../../libs/analytics/segment/segment-analytics.service"

export enum WalletSegmentEvents {
  ReadMoreViewed = "Wallet Read More Viewed",
  DiscountPageViewed = "Wallet Discount Page Viewed",
  TooltipViewed = "Wallet Tooltip Viewed",
  TooltipClicked = "Wallet Tooltip Clicked",
  TooltipClickedCart = "Wallet Cart Tooltip Clicked",
  TooltipClickedEditor = "Wallet Editor Tooltip Clicked",
}

export enum WalletTooltipClickedLocations {
  Navigation = "Navigation",
  Editor = "Editor",
  Cart = "Cart",
  Checkout = "Checkout",
  OrdersInUserArea = "Orders in User Area",
  PackhelpWalletInUserArea = "Packhelp Wallet in User Area",
  OrderComplete = "Order Complete",
}

export const trackSegment = ({
  message,
  properties = {},
  callback,
}: {
  message: WalletSegmentEvents
  properties?: {}
  callback?: () => void
}) => trackThroughBackend({ event: message, properties }, callback)

export const trackTooltipClicked = (
  location: WalletTooltipClickedLocations
) => {
  trackSegment({
    message: WalletSegmentEvents.TooltipClicked,
    properties: {
      location,
    },
  })
}

export const trackTooltipInCartClick = () => {
  trackSegment({ message: WalletSegmentEvents.TooltipClickedCart })
}

export const trackTooltipInEditorClick = () => {
  trackSegment({ message: WalletSegmentEvents.TooltipClickedEditor })
}

export const trackDiscountPageViewed = () => {
  trackSegment({ message: WalletSegmentEvents.DiscountPageViewed })
}

export const trackReadMoreViewed = () => {
  trackSegment({ message: WalletSegmentEvents.ReadMoreViewed })
}

export const trackTooltipViewed = () => {
  trackSegment({ message: WalletSegmentEvents.TooltipViewed })
}
