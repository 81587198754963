import VirtualDielineEditor from "../../../../virtual-dieline-editor"
import { calcObjectAbsoluteValues } from "../../editable-group-controller/helpers"
import { PackhelpEditableObject } from "../../../../object-extensions/packhelp-objects"
import { ScaleHelper } from "../../helpers/scale-helper"
import {
  SpaceDataLessObject,
  SpacesData,
} from "../../../../../../../modules/ph-api/asset-types"
import { RotationHelper } from "../../helpers/rotation-helper"
import fabric from "../../../../../../../libs/vendors/Fabric"
import { AvailableRotations } from "../../../dieline-navigator/services/translation-calculator"

type ObjectData = {
  width: number
  height: number
  centerPoint: fabric.Point
}

const HALF_SIZE = 0.5
const MARGIN_OF_ERROR = 0.05

export class TemplateResizer {
  public constructor(
    private readonly vdEditor: VirtualDielineEditor,
    private readonly templateSpaces: SpacesData
  ) {}

  public resize(object: PackhelpEditableObject) {
    const designSpace = this.getDesignSpace(object)
    const templateSpace = this.templateSpaces[object.originSpaceArea]

    const objectInitialAttrs = {
      width: object.getScaledWidth(),
      height: object.getScaledHeight(),
      centerPoint: object.getCenterPoint(),
    }

    this.scale(object, designSpace, templateSpace)
    this.move(object, designSpace, templateSpace, objectInitialAttrs)
  }

  private scale(
    object: PackhelpEditableObject,
    designSpace: SpaceDataLessObject,
    templateSpace: SpaceDataLessObject
  ) {
    const designSpaceToTemplateSpaceRatio =
      this.calcDesignSpaceToTemplateSpaceRatio(designSpace, templateSpace)
    const ratio = this.isVerticalRotation(designSpace.rotation)
      ? designSpaceToTemplateSpaceRatio.height
      : designSpaceToTemplateSpaceRatio.width

    ScaleHelper.scaleObjectWithLimit(this.vdEditor, object, ratio)
  }

  private move(
    object: PackhelpEditableObject,
    designSpace: SpaceDataLessObject,
    templateSpace: SpaceDataLessObject,
    objectInitialAttrs: ObjectData
  ) {
    const positionMultiplier = this.getPositionMultiplier(designSpace.rotation)
    const rotatedTemplateSpace = this.getRotatedSpace(templateSpace)
    const rotatedDesignSpace = this.getRotatedSpace(designSpace)

    const objectCenterPointToSpaceSizeRatio = this.calcPointToSpaceSizeRatio(
      objectInitialAttrs.centerPoint,
      rotatedTemplateSpace
    )

    const designSpaceToTemplateSpaceRatio =
      this.calcDesignSpaceToTemplateSpaceRatio(
        rotatedDesignSpace,
        rotatedTemplateSpace
      )

    const widthOffset = this.calcObjectSizeDiffOffset(
      objectCenterPointToSpaceSizeRatio.left,
      designSpaceToTemplateSpaceRatio.width,
      objectInitialAttrs.width,
      object.getScaledWidth()
    )

    const heightOffset = this.calcObjectSizeDiffOffset(
      objectCenterPointToSpaceSizeRatio.top,
      designSpaceToTemplateSpaceRatio.height,
      objectInitialAttrs.height,
      object.getScaledHeight()
    )

    let objectWidth
    let objectHeight
    let rotationMultiplier

    if (this.isVerticalRotation(designSpace.rotation)) {
      objectWidth = object.getScaledHeight()
      objectHeight = object.getScaledWidth()
      rotationMultiplier = -1
    } else {
      objectWidth = object.getScaledWidth()
      objectHeight = object.getScaledHeight()
      rotationMultiplier = 1
    }

    const left =
      rotatedDesignSpace.left +
      rotatedDesignSpace.width * objectCenterPointToSpaceSizeRatio.left -
      (positionMultiplier * objectWidth) / 2 +
      positionMultiplier * widthOffset
    const top =
      rotatedDesignSpace.top +
      rotatedDesignSpace.height * objectCenterPointToSpaceSizeRatio.top -
      (rotationMultiplier * positionMultiplier * objectHeight) / 2 +
      positionMultiplier * heightOffset

    const newPosition = this.rotatePointIfNeeded(
      new fabric.Point(left, top),
      object,
      designSpace
    )

    object.set({
      left: newPosition.x,
      top: newPosition.y,
    })
  }

  private calcDesignSpaceToTemplateSpaceRatio(
    designSpace: SpaceDataLessObject,
    templateSpace: SpaceDataLessObject
  ): { width: number; height: number } {
    return {
      width: designSpace.width / templateSpace.width,
      height: designSpace.height / templateSpace.height,
    }
  }

  private calcPointToSpaceSizeRatio(
    point: fabric.Point,
    space: SpaceDataLessObject
  ): { left: number; top: number } {
    return {
      left: (point.x - space.left) / space.width,
      top: (point.y - space.top) / space.height,
    }
  }

  private calcObjectSizeDiffOffset(
    centerPointToSpaceSizeRatio: number,
    designSpaceToTemplateSpaceRatio: number,
    templateObjectSize: number,
    designObjectSize: number
  ): number {
    const sizeDiff =
      templateObjectSize * Math.min(designSpaceToTemplateSpaceRatio, 1) -
      designObjectSize

    if (Math.abs(centerPointToSpaceSizeRatio) < HALF_SIZE - MARGIN_OF_ERROR) {
      return sizeDiff * HALF_SIZE * -1
    }

    if (Math.abs(centerPointToSpaceSizeRatio) > HALF_SIZE + MARGIN_OF_ERROR) {
      return sizeDiff * HALF_SIZE
    }

    return 0
  }

  private getPositionMultiplier(spaceRotation: AvailableRotations): number {
    if (
      [
        AvailableRotations.upsideDown,
        AvailableRotations.verticalRight,
      ].includes(spaceRotation)
    ) {
      return -1
    }

    return 1
  }

  private rotatePointIfNeeded(
    point: fabric.Point,
    object: PackhelpEditableObject,
    space: SpaceDataLessObject
  ): fabric.Point {
    const rotation = object.angle! + (space.rotation || 0)

    if (rotation) {
      return RotationHelper.rotatePoint(
        point,
        RotationHelper.calculateCenterPoint(
          point,
          { width: object.getScaledWidth(), height: object.getScaledHeight() },
          space.rotation
        ),
        rotation
      )
    }

    return point
  }

  private getRotatedSpace(space: SpaceDataLessObject): SpaceDataLessObject {
    let top = space.top
    let left = space.left

    if (space.rotation === AvailableRotations.verticalLeft) {
      top = space.top + space.height
    } else if (space.rotation === AvailableRotations.verticalRight) {
      left = space.left + space.width
    } else if (space.rotation === AvailableRotations.upsideDown) {
      left = space.left + space.width
      top = space.top + space.height
    }

    return {
      top,
      left,
      width: space.width,
      height: space.height,
      rotation: space.rotation,
    }
  }

  private getDesignSpace(object: PackhelpEditableObject) {
    const space = this.vdEditor.dielineNavigator.getVirtualDielineSpace(
      object.originSpaceArea
    )
    const { left, top } = calcObjectAbsoluteValues(space)

    return {
      top: top!,
      left: left!,
      width: space.getScaledWidth(),
      height: space.getScaledHeight(),
      rotation: Number(space.rotation),
    }
  }

  private isVerticalRotation(rotation: AvailableRotations): boolean {
    return [
      AvailableRotations.verticalLeft,
      AvailableRotations.verticalRight,
    ].includes(rotation)
  }
}
