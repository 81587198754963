import VirtualDielineEditor from "../../../virtual-dieline-editor"
import {
  PackhelpCanvas,
  PackhelpEditableGroup,
  PackhelpEditableObject,
} from "../../../object-extensions/packhelp-objects"
import { EditableObjectTypes } from "../../../../../../modules/ph-api/asset-types"
import { CanvasObjectEvent } from "../../../../../../stores/_events/domain-events"
import fabric from "../../../../../../libs/vendors/Fabric"
import { v4 as uuidv4 } from "uuid"
import { ModelEditableSpaces } from "../../../../../../libs/products-render-config/types"
import { ClipPathModes } from "../../../services/clip-path-generator"
import { GroupEvent } from "./types"
import { IEvent } from "fabric/fabric-impl"
import { BorderController } from "./controllers/border-controller"
import { EditModeController } from "./controllers/edit-mode-controller"
import { DuplicationController } from "./controllers/duplication-controller"
import { NotReadyError, NotValidOperationError } from "./errors"
import { getLayerIndex } from "../canvas-object-controller/helpers"
import { refreshGroup } from "./helpers"
import { SpaceClippingHelper } from "../helpers/space-clipping-helper"
import { DuplicationOptions } from "../canvas-object-controller/types"

export class EditableGroupController {
  private group?: PackhelpEditableGroup
  private objects: PackhelpEditableObject[] = []
  private borderController?: BorderController
  private duplicationController?: DuplicationController

  private readonly objectEventMap: GroupEvent[]

  constructor(private readonly virtualDielineEditor: VirtualDielineEditor) {
    this.objectEventMap = this.buildObjectEventMap()
  }

  public getGroup(): PackhelpEditableGroup {
    if (!this.group) {
      throw new NotReadyError("Call init() or load() first!")
    }

    return this.group
  }

  public getObjects(): PackhelpEditableObject[] {
    return this.objects
  }

  public async duplicate(
    options: DuplicationOptions = {
      withRender: true,
    }
  ): Promise<PackhelpEditableGroup> {
    if (!this.duplicationController) {
      throw new NotReadyError("Call init() or load() first!")
    }

    return this.duplicationController.duplicate(options)
  }

  public async init(
    spaceId: ModelEditableSpaces,
    objects: PackhelpEditableObject[]
  ): Promise<PackhelpEditableGroup> {
    if (this.group) {
      throw new NotValidOperationError("Group is already initialized!")
    }

    this.objects = objects
    this.group = new fabric.Group() as PackhelpEditableGroup
    this.group.set({
      ...this.defaultGroupParams,
      id: uuidv4(),
      originSpaceArea: spaceId,
      groupController: this,
    })

    for (const object of this.objects) {
      if (object.maskController) {
        object.maskController.dispose()
      }

      this.canvas.remove(object)

      object.set({
        groupId: this.group.id,
        originSpaceArea: spaceId,
      })

      this.group.addWithUpdate(object)
    }

    this.initControllers()
    this.attachEvents()

    await this.setSpaceClipping()

    return this.group
  }

  public async load(group: PackhelpEditableGroup) {
    if (this.group) {
      throw new NotValidOperationError("Group is already initialized!")
    }

    this.group = group
    this.objects = group.getObjects()

    this.group.set({
      ...this.defaultGroupParams,
      groupController: this,
    })

    this.initControllers()
    this.attachEvents()

    await this.setSpaceClipping()
  }

  public ungroup(): PackhelpEditableObject[] {
    if (!this.group) {
      throw new NotReadyError("Call init() or load() first!")
    }

    if (!this.group.canvas) {
      throw new NotValidOperationError("Group has been already ungrouped!")
    }

    let index = getLayerIndex(this.group, this.canvas)
    this.canvas.remove(this.group)

    for (const object of this.objects) {
      object.set({
        groupId: undefined,
        originSpaceArea: this.group.originSpaceArea,
      })

      if (this.group.templateId) {
        object.set({ templateId: this.group.templateId })
      }

      this.group.removeWithUpdate(object)
      this.canvas.add(object)
      this.canvas.moveTo(object, index)
      index++
    }

    this.group.set({
      groupController: undefined,
    })

    this.detachEvents()
    this.canvas.renderAll()

    return this.objects
  }

  public removeObject(objectId: string) {
    if (!this.group || !this.borderController) {
      throw new NotReadyError("Call init() or load() first!")
    }

    const objectToRemove = this.objects.find((obj) => obj.id === objectId)

    if (!objectToRemove) {
      return
    }

    this.objects = this.objects.filter((obj) => obj.id !== objectId)

    this.borderController.clear()
    this.group.remove(objectToRemove)
    refreshGroup(this.group)

    if (this.objects.length <= 1) {
      this.ungroup()
    }
  }

  private async onObjectClick(e: IEvent) {
    if (!this.group || !this.borderController) {
      throw new NotReadyError("Call init() or load() first!")
    }

    const object = e.subTargets && (e.subTargets[0] as PackhelpEditableObject)

    if (!object) {
      return
    }

    const editModeController = new EditModeController(
      this.group,
      this.borderController,
      this.virtualDielineEditor
    )
    await editModeController.init(object)
  }

  private attachEvents() {
    for (const object of this.objects) {
      for (const event of this.objectEventMap) {
        object.on(event.name, event.fn)
      }
    }
  }

  private detachEvents() {
    for (const object of this.objects) {
      for (const event of this.objectEventMap) {
        object.off(event.name, event.fn)
      }
    }
  }

  private buildObjectEventMap(): GroupEvent[] {
    return [
      {
        name: CanvasObjectEvent.mousedown,
        fn: this.onObjectClick.bind(this),
      },
    ]
  }

  private get canvas(): PackhelpCanvas {
    return this.virtualDielineEditor.fabricCanvas
  }

  private async setSpaceClipping() {
    if (!this.group) {
      throw new NotReadyError("Call init() or load() first!")
    }

    await SpaceClippingHelper.setSpaceClipping(
      this.virtualDielineEditor,
      this.group.originSpaceArea,
      this.group,
      ClipPathModes.FillMode
    )
  }

  private initControllers() {
    if (!this.group) {
      throw new NotReadyError("Call init() or load() first!")
    }

    this.borderController = new BorderController(this.group)
    this.borderController.init()

    this.duplicationController = new DuplicationController(
      this.group,
      this.virtualDielineEditor
    )
  }

  private get defaultGroupParams(): Partial<PackhelpEditableGroup> {
    return {
      assetType: EditableObjectTypes.assetGroup,
      lockUniScaling: true,
      subTargetCheck: true,
      clipPath: undefined,
      clipMode: ClipPathModes.FillMode,
      alphaModeClipping: false,
    }
  }
}
