import VirtualDielineEditor from "../../../../render-engine/modules/vd-editor/virtual-dieline-editor"
import { ModelEditableSpaces } from "../../../../libs/products-render-config/types"
import { DesignVersion } from "../../../../modules/design/version"
import { calcObjectAbsoluteValues } from "../../../../render-engine/modules/vd-editor/modules/assets-module/editable-group-controller/helpers"
import { VirtualDielineTemplateDataLessObject } from "../../../../modules/ph-api/asset-types"
import { AssetsStore } from "../../../assets-store/assets.store"
import { DesignTemplateData } from "../../../../modules/design/types"
import { VirtualDielineExporter } from "../../../../render-engine/modules/vd-editor/services/virtual-dieline-exporter"

export class TemplateExporter {
  public constructor(
    private readonly vdEditors: VirtualDielineEditor[],
    private readonly assetsStore?: AssetsStore
  ) {}

  public async export(): Promise<DesignTemplateData> {
    const vdExport = {}

    for (const vdEditor of this.vdEditors) {
      vdExport[vdEditor.editContext] = await this.exportVirtualDieline(vdEditor)
    }

    return {
      virtualDielines: vdExport,
      designDataFormatVersion: DesignVersion.latest,
      files: this.assetsStore?.serialize() || [],
    }
  }

  private async exportVirtualDieline(
    vdEditor: VirtualDielineEditor
  ): Promise<VirtualDielineTemplateDataLessObject> {
    const vdExporter = new VirtualDielineExporter(vdEditor, {
      unpackGroups: false,
    })

    const { version, objects } = await vdExporter.export()
    const spaces = {}

    vdEditor.virtualDieline
      .getObjects()
      .filter((space) => {
        const editableSpaces: string[] = Object.values(ModelEditableSpaces)

        return editableSpaces.includes(space.id)
      })
      .forEach((space) => {
        const { left, top } = calcObjectAbsoluteValues(space)

        spaces[space.id] = {
          top: top,
          left: left,
          width: space.getScaledWidth(),
          height: space.getScaledHeight(),
          rotation: space.rotation,
        }
      })

    return {
      version: version,
      objects: objects,
      spaces: spaces,
    }
  }
}
