import VirtualDielineEditor from "../../../virtual-dieline-editor"
import {
  PackhelpEditableGroup,
  PackhelpEditableObject,
  PackhelpInteractiveCanvas,
} from "../../../object-extensions/packhelp-objects"
import { CanvasObjectControllable } from "./canvas-object-controllable.interface"
import { CanvasObjectControllerFactory } from "./canvas-object-controller-factory"
import {
  CanvasEvent,
  CanvasEventObjectModifiedAction,
  CanvasObjectEvent,
} from "../../../../../../stores/_events/domain-events"
import fabric from "../../../../../../libs/vendors/Fabric"
import { EditableGroupCreator } from "../editable-group-controller/editable-group-creator"
import Colour from "../../../../../../libs/value-objects/colour"
import { AlignmentHelper } from "../helpers/alignment-helper"
import { ModelEditableSpaces } from "../../../../../../libs/products-render-config/types"
import { MovementDirection } from "./types"
import { MovementController } from "./object-controllers/movement-controller"
import { DuplicationOptions } from "./types"
import { isStaticCanvas } from "../../../../../../modules/ph-api/asset-types"

export class ActiveSelectionController implements CanvasObjectControllable {
  private readonly canvasObjectControllerFactory: CanvasObjectControllerFactory
  private readonly movementController: MovementController

  constructor(
    protected readonly canvasObject: PackhelpEditableGroup,
    protected readonly virtualDielineEditor: VirtualDielineEditor
  ) {
    this.canvasObject.set({
      originSpaceArea: this.originSpaceArea,
    })

    this.canvasObjectControllerFactory = new CanvasObjectControllerFactory(
      this.virtualDielineEditor
    )
    this.movementController = new MovementController(this.canvasObject)
  }

  public moveLayerUp() {
    const objects = this.getObjects()

    for (const object of objects.reverse()) {
      const controller =
        this.canvasObjectControllerFactory.getController(object)

      controller.moveLayerUp()
    }
  }

  public moveLayerDown() {
    const objects = this.getObjects()

    for (const object of objects) {
      const controller =
        this.canvasObjectControllerFactory.getController(object)

      controller.moveLayerDown()
    }
  }

  public move(direction: MovementDirection, step = 1) {
    this.movementController.move(direction, step)

    for (const object of this.getObjects()) {
      this.canvas.fire(CanvasEvent.objectModified, {
        target: object,
        action: CanvasEventObjectModifiedAction.move,
      })
      object.fire(CanvasObjectEvent.moved)
    }

    this.rerender()
  }

  public async duplicate(
    options: DuplicationOptions = {
      withRender: true,
    }
  ): Promise<PackhelpEditableGroup> {
    const objects = this.getObjects()

    if (options.withRender) {
      this.canvas.discardActiveObject()
    }

    const newSelection = new fabric.ActiveSelection([], {
      canvas: this.canvas,
      // @ts-ignore - i know, i know :(
      originSpaceArea: this.originSpaceArea,
    })

    for (const object of objects) {
      const controller =
        this.canvasObjectControllerFactory.getController(object)

      if (controller.isDuplicatingAvailable()) {
        const duplicatedObject = await controller.duplicate(options)
        newSelection.addWithUpdate(duplicatedObject)
      }
    }

    // @ts-ignore
    return newSelection
  }

  public alignHorizontal() {
    AlignmentHelper.alignHorizontal(
      this.virtualDielineEditor.dielineNavigator,
      this.canvasObject
    )

    for (const object of this.getObjects()) {
      this.canvas.fire(CanvasEvent.objectModified, {
        target: object,
        action: CanvasEventObjectModifiedAction.move,
      })
      object.fire(CanvasObjectEvent.moved)
    }

    this.rerender()
  }

  public alignVertical() {
    AlignmentHelper.alignVertical(
      this.virtualDielineEditor.dielineNavigator,
      this.canvasObject
    )

    for (const object of this.getObjects()) {
      this.canvas.fire(CanvasEvent.objectModified, {
        target: object,
        action: CanvasEventObjectModifiedAction.move,
      })
      object.fire(CanvasObjectEvent.moved)
    }

    this.rerender()
  }

  public remove() {
    for (const object of this.getObjects()) {
      const controller =
        this.canvasObjectControllerFactory.getController(object)

      controller.remove()
    }

    this.canvas.discardActiveObject()
    this.canvas.remove(this.canvasObject)
  }

  public async group(): Promise<PackhelpEditableGroup> {
    const objects = this.getGroupableElements()
    this.canvas.discardActiveObject()
    this.canvas.remove(this.canvasObject)

    const groupCreator = new EditableGroupCreator(this.virtualDielineEditor)
    const group = await groupCreator.create(
      this.canvasObject.originSpaceArea as ModelEditableSpaces,
      objects
    )

    this.virtualDielineEditor.addOnCanvas(group, true)

    return group
  }

  public getObject(): PackhelpEditableObject {
    return this.canvasObject
  }

  public getGroup(): PackhelpEditableGroup | undefined {
    return undefined
  }

  public hasGroup(): boolean {
    return false
  }

  public hasMask(): boolean {
    return false
  }

  public setStyles(styles) {}

  public getFill() {
    return new Colour()
  }

  protected get canvas(): PackhelpInteractiveCanvas {
    const canvas = this.virtualDielineEditor.fabricCanvas

    if (isStaticCanvas(canvas)) {
      throw new Error("Active selection is not available for Static Canvas")
    }

    return canvas
  }

  protected rerender() {
    this.canvas.renderAll()
  }

  private getObjects(): PackhelpEditableObject[] {
    return this.canvasObject.getObjects()
  }

  private get originSpaceArea(): string | undefined {
    const originSpaceArea =
      this.virtualDielineEditor.dielineNavigator.getActiveSpaceId()

    if (!originSpaceArea) {
      return
    }

    return originSpaceArea
  }

  public shouldKeepRatio(): boolean {
    return true
  }

  public isColorModificationAvailable(): boolean {
    return false
  }

  public isMovingLayerUpDownAvailable(): boolean {
    return true
  }

  public isDuplicatingAvailable(): boolean {
    return true
  }

  public isGroupingAvailable(): boolean {
    const objects = this.getGroupableElements()

    return objects.length > 1
  }

  private getGroupableElements(): PackhelpEditableObject[] {
    return this.getObjects().filter((object) => {
      const controller =
        this.canvasObjectControllerFactory.getController(object)

      return controller.isGroupingAvailable()
    })
  }
}
