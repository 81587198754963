var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addDeliveryMailerBoxRenderConfigBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getEditableSides = function () {
            var sideFeatures = this.product.editConfigManager.getUserProductSpecificConfig()
                .sideFeatures;
            var availabeSidesArr = this.getAvailableSidesArr();
            return availabeSidesArr.filter(function (side) {
                return sideFeatures[side] ? !sideFeatures[side].disableSide : true;
            });
        };
        class_1.prototype.getProductClipingZone = function (sideId, productEditZones) {
            var productClipingZone = _super.prototype.getProductClipingZone.call(this, sideId, productEditZones);
            var left = productClipingZone.left, top = productClipingZone.top, width = productClipingZone.width, height = productClipingZone.height;
            var clippingDecreaseValue = this.getClippingDecreaseValue();
            // We changing cliping zone for e-commerce delivery box, to show user unprintable corners in editor.
            // See: Clubhouse ch-4146
            return Object.assign(productClipingZone, {
                left: left + clippingDecreaseValue,
                top: top + clippingDecreaseValue,
                width: width - clippingDecreaseValue * 2,
                height: height - clippingDecreaseValue * 2,
            });
        };
        // This returns value how much we need to decrease clipping to match safe zones
        // See: Clubhouse ch-4146
        class_1.prototype.getClippingDecreaseValue = function () {
            var renderConfig = this.getRenderConfig();
            return renderConfig.clippingDecreaseValue;
        };
        class_1.prototype.getSafeZoneWidth = function () {
            var renderConfig = this.getRenderConfig();
            return renderConfig.safeZoneWidth;
        };
        return class_1;
    }(superclass));
};
export default addDeliveryMailerBoxRenderConfigBehaviors;
