import React, { PropsWithChildren } from "react"
import cxBinder from "classnames/bind"

import styles from "./Tooltip.module.scss"
const cx = cxBinder.bind(styles)

export enum TooltipSideEnum {
  TopRight = "top-right",
  TopLeft = "top-left",
  TopCenter = "bottom-center",
  BottomRight = "bottom-right",
  BottomLeft = "bottom-left",
  BottomCenter = "top-center",
  RightTop = "right-top",
  RightBottom = "right-bottom",
  RightCenter = "right-center",
  LeftTop = "left-top",
  LeftBottom = "left-bottom",
  LeftCenter = "left-center",
}

export interface TooltipProps {
  /** Top, bottom (default), left, right */
  side?: TooltipSideEnum
}

export const Tooltip = ({
  children,
  side = TooltipSideEnum.BottomRight,
}: PropsWithChildren<TooltipProps>) => (
  <div className={styles.wrapper}>
    <div
      className={cx("content", {
        "content--top-right": side === TooltipSideEnum.TopRight,
        "content--top-left": side === TooltipSideEnum.TopLeft,
        "content--top-center": side === TooltipSideEnum.TopCenter,
        "content--bottom-right": side === TooltipSideEnum.BottomRight,
        "content--bottom-left": side === TooltipSideEnum.BottomLeft,
        "content--bottom-center": side === TooltipSideEnum.BottomCenter,
        "content--right-top": side === TooltipSideEnum.RightTop,
        "content--right-bottom": side === TooltipSideEnum.RightBottom,
        "content--right-center": side === TooltipSideEnum.RightCenter,
        "content--left-top": side === TooltipSideEnum.LeftTop,
        "content--left-bottom": side === TooltipSideEnum.LeftBottom,
        "content--left-center": side === TooltipSideEnum.LeftCenter,
      })}
    >
      {children}
    </div>
  </div>
)
