import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey, isProductTypeCustom, } from "../SkuLib";
import CustomPolyMailerProduct from "../CustomProducts/CustomPolyMailerProduct";
import PolyMailerProduct from "../Products/PolyMailerProduct/PolyMailerProduct";
import PolyMailerVariantManager from "../Products/PolyMailerProduct/behaviorsComposers/PolyMailerVariantManager";
function PolyMailerProductFactory(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    var productVariant = PolyMailerVariantManager.findVariant(decodedSKU.productName);
    if (isProductTypeCustom(sku)) {
        return new CustomPolyMailerProduct(sku, decodedSKU.productName);
    }
    else {
        return new PolyMailerProduct(sku, decodedSKU.productName, productVariant);
    }
}
export default PolyMailerProductFactory;
