var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addUserInterfaceBehaviors = function (superclass) {
    var EDIT_MODE_2D = "2D";
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.getUserInterfaceConfig = function () {
                return _this.product.config.userInterfaceConfig.UI_CONFIG;
            };
            _this.getUserProductSpecificConfig = function () {
                var userInterfaceConfig = _this.getUserInterfaceConfig();
                return userInterfaceConfig.products[_this.product.variantManager.getName()];
            };
            _this.shouldShowLimitedColorsDescription = function () {
                return false;
            };
            _this.isEditorModeDisabledForProduct = function () {
                var productSpecificInterfaceConfig = _this.getUserProductSpecificConfig();
                if (!productSpecificInterfaceConfig) {
                    return false;
                }
                return productSpecificInterfaceConfig.is3dEditorDisabled;
            };
            _this.product = product;
            return _this;
        }
        class_1.prototype.getInitialEditorActiveSides = function () {
            var userIterfaceConfig = this.getUserInterfaceConfig();
            return userIterfaceConfig.shared.initialActiveSide;
        };
        class_1.prototype.isPatternsAvailable = function () {
            var editConfig = this.getUserInterfaceConfig();
            return editConfig.shared.isPatternsAvailable;
        };
        class_1.prototype.isGlobalDropAssetDisabled = function () {
            var editConfig = this.getUserInterfaceConfig();
            return editConfig.shared.isGlobalAssetDropDisabled;
        };
        class_1.prototype.isSupportingReforestation = function (element) {
            var productReforestationSupport = this.getUserProductSpecificConfig() &&
                this.getUserProductSpecificConfig()["reforestationSupport"];
            var productSharedReforestationSupport = this.getUserInterfaceConfig() &&
                this.getUserInterfaceConfig()["shared"]["reforestationSupport"];
            if (productReforestationSupport) {
                return element ? productReforestationSupport[element] : true;
            }
            else if (productSharedReforestationSupport) {
                return element ? productSharedReforestationSupport[element] : true;
            }
            return false;
        };
        class_1.prototype.isInsideColorEnabled = function () {
            var productEditConfig = this.getUserProductSpecificConfig();
            if (!productEditConfig) {
                return false;
            }
            return productEditConfig.extraFeatures
                ? productEditConfig.extraFeatures.colorInside
                : false;
        };
        class_1.prototype.isPantoneColorsAvailable = function () {
            var editConfig = this.getUserInterfaceConfig();
            return editConfig.shared.isPantoneColorsAvailable;
        };
        class_1.prototype.isLimitedColorsModeActive = function () {
            var editConfig = this.getUserInterfaceConfig();
            return editConfig.shared.isLimitedColorsModeActive;
        };
        class_1.prototype.isTextComponentDisabled = function () {
            var editConfig = this.getUserInterfaceConfig();
            return editConfig.shared.disableTextComponent;
        };
        class_1.prototype.isAssetComponentDisabled = function () {
            var editConfig = this.getUserInterfaceConfig();
            return editConfig.shared.disableImagesComponent;
        };
        class_1.prototype.getProductCustomColorPickerConfig = function () {
            return undefined;
        };
        class_1.prototype.isProductSafeZonesLight = function () {
            var editConfig = this.getUserInterfaceConfig();
            var material = this.product.variantManager.getMaterial();
            if (!editConfig.shared.isSafeZoneLight) {
                return false;
            }
            return editConfig.shared.isSafeZoneLight[material]
                ? editConfig.shared.isSafeZoneLight[material]
                : editConfig.shared.isSafeZoneLight.DEFAULT;
        };
        class_1.prototype.isAvailableForVisualizeApp = function () {
            var editConfig = this.getUserInterfaceConfig();
            var shareConfig = editConfig.shared;
            if (shareConfig.isAvailableForVisualizeApp) {
                return shareConfig.isAvailableForVisualizeApp[this.product.variantManager.getName()];
            }
            else {
                return false;
            }
        };
        class_1.prototype.isProduct2d = function () {
            var productEditConfig = this.getUserProductSpecificConfig();
            if (!productEditConfig) {
                return false;
            }
            return productEditConfig.productEditorMode === EDIT_MODE_2D;
        };
        class_1.prototype.isProduct3d = function () {
            return !this.isProduct2d();
        };
        class_1.prototype.showProductSidesControlButtonExtraPrice = function () {
            var editConfig = this.getUserInterfaceConfig();
            return editConfig.shared.showSideExtraPrice;
        };
        class_1.prototype.getSidesCodeNamesMap = function () {
            var editConfig = this.getUserInterfaceConfig();
            return editConfig.shared.sidesNameCodesMap
                ? editConfig.shared.sidesNameCodesMap
                : undefined;
        };
        return class_1;
    }(superclass));
};
export default addUserInterfaceBehaviors;
