import polyMailerProductSpec from "../../../../source/polyMailerProductSpec/polyMailerProductSpec";
import ConfigBuilder from "../../configBuilders/ConfigBuilder";
// Global product behaviors composers
import ProductionTimeManager from "../../behaviorsComposers/ProductionTimeManager";
import GrammagesManager from "../../behaviorsComposers/GrammagesManager";
import AssetsManager from "../../behaviorsComposers/AssetsManager";
import WeightManager from "../../behaviorsComposers/WeightManager";
import WizzardManager from "../../behaviorsComposers/WizzardManager";
import SiblingsManager from "../../behaviorsComposers/SiblingsManager";
import QuantitiesManager from "../../behaviorsComposers/QuantitiesManager";
// Poly Mailers specific behaviors composers
import PolyMailerTranslationsManager from "./behaviorsComposers/PolyMailerTranslationsManager";
import PolyMailerAddonsManager from "./behaviorsComposers/PolyMailerAddonsManager";
import PolyMailerVariantManager from "./behaviorsComposers/PolyMailerVariantManager";
import PolyMailerRenderConfigManager from "./behaviorsComposers/PolyMailerRenderConfigManager";
import PolyMailerEditConfigManager from "./behaviorsComposers/PolyMailerEditConfigManager";
import PolyMailerDimensionsManager from "./behaviorsComposers/PolyMailerDimensionsManager";
import ProductFactory from "../../ProductFactory";
var PolyMailerProduct = /** @class */ (function () {
    function PolyMailerProduct(sku, productName, productVariant) {
        this.config = this.builder(polyMailerProductSpec, sku, productName, productVariant);
        this.assetsManager = new AssetsManager(this);
        this.variantManager = new PolyMailerVariantManager(this);
        this.grammagesManager = new GrammagesManager(this);
        this.productionTimeManager = new ProductionTimeManager(this);
        this.weightsManager = new WeightManager(this);
        this.wizzardManager = new WizzardManager(this);
        this.editConfigManager = new PolyMailerEditConfigManager(this);
        this.translationsManager = new PolyMailerTranslationsManager(this);
        this.addonsManager = new PolyMailerAddonsManager(this);
        this.dimensionsManager = new PolyMailerDimensionsManager(this);
        this.renderConfigManager = new PolyMailerRenderConfigManager(this);
        this.siblingsManager = new SiblingsManager(this);
        this.quantitiesManager = new QuantitiesManager(this);
    }
    PolyMailerProduct.prototype.builder = function (spec, sku, productName, productVariant) {
        return ConfigBuilder(spec, sku, productName, productVariant);
    };
    PolyMailerProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    PolyMailerProduct.prototype.getExtraTechnicalDetails = function () {
        return [
            {
                titleId: "box-configurator.box-dimensions.material-color",
                iconSvg: "v5-material-color",
                descId: "box-configurator.box-dimensions.polymailer-materials",
            },
            {
                titleId: "box-configurator.box-dimensions.closing",
                iconSvg: "v5-closing-ico",
                descId: "box-configurator.box-dimensions.adhesive-tape",
            },
            {
                titleId: "box-configurator.box-dimensions.features",
                iconSvg: "v5-waterproof-ico",
                descId: "box-configurator.box-dimensions.water-resistant",
            },
        ];
    };
    PolyMailerProduct.prototype.getEditorBackgroundPath = function (side) {
        var productCodeName = this.variantManager.getCodeName();
        var material = "white";
        if (this.variantManager.getMaterial() ===
            this.config.const.PRINT_MATERIAL_FOIL_BLACK) {
            material = "black";
        }
        else if (this.variantManager.getMaterial() ===
            this.config.const.PRINT_MATERIAL_FOIL_TRANSPARENT) {
            material = "transparent";
        }
        var productName = "";
        if (this.variantManager.getVariant() ===
            this.config.const.BIO_POLY_MAILER_MODEL_DEFAULT) {
            productName = "bio-poly-mailers/bio-poly-mailer_".concat(productCodeName, "_").concat(material, "_").concat(side, ".png");
        }
        else {
            productName = "poly-mailers/poly-mailer_".concat(productCodeName, "_").concat(material, "_").concat(side, ".png");
        }
        return "https://packhelp-files.s3-eu-west-1.amazonaws.com/app/editor/backgrounds/".concat(productName);
    };
    PolyMailerProduct.prototype.createBaseProductSku = function () {
        return this.addonsManager.setDefaultAddons();
    };
    PolyMailerProduct.prototype.getBaseProduct = function () {
        return ProductFactory(this.createBaseProductSku());
    };
    return PolyMailerProduct;
}());
export default PolyMailerProduct;
