import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey, isProductExtension, isProductDesignItem, } from "./SkuLib";
import ProductConsts from "../../definitions/products/ProductConsts";
import BoxProductFactory from "./ProductFactories/BoxProductFactory";
import PaperTubeProductFactory from "./ProductFactories/PaperTubeProductFactory";
import PolyMailerProductFactory from "./ProductFactories/PolyMailerProductFactory";
import PaperMailerProductFactory from "./ProductFactories/PaperMailerProductFactory";
import PaperBagProductFactory from "./ProductFactories/PaperBagProductFactory";
import EnvelopeProductFactory from "./ProductFactories/EnvelopeProductFactory";
import TapeProductFactory from "./ProductFactories/TapeProductFactory";
import TissuePaperProductFactory from "./ProductFactories/TissuePaperProductFactory";
import SamplePackProductFactory from "./ProductFactories/SamplePackProductFactory";
import ExtensionProductFactory from "./ProductFactories/ExtensionProductFactory";
import NcpProductFactory from "./ProductFactories/NcpProductFactory";
import LabelProductFactory from "./ProductFactories/LabelProductFactory";
import CardProductFactory from "./ProductFactories/CardProductFactory";
import { DesignItemProductFactory } from "./ProductFactories/DesignAssetProductFactory";
export default function ProductFactory(sku) {
    return create(sku);
}
function create(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    if (isProductDesignItem(sku)) {
        return DesignItemProductFactory(sku);
    }
    switch (decodedSKU.productName) {
        case isProductExtension(decodedSKU.productName):
            return ExtensionProductFactory(sku);
        case ProductConsts["SamplePackProductConsts"].SAMPLE_PACK_PRODUCT:
            return SamplePackProductFactory(sku);
        case ProductConsts["TissuePaperProductConsts"].TISSUE_PAPER:
        case ProductConsts["TissuePaperProductConsts"].PACKING_PAPER:
            return TissuePaperProductFactory(sku);
        case ProductConsts["TapeProductConsts"].KRAFT_TAPE:
        case ProductConsts["TapeProductConsts"].PACKING_TAPE:
        case ProductConsts["TapeProductConsts"].PRE_PRINTED_KRAFT_TAPE:
            return TapeProductFactory(sku);
        case ProductConsts["EnvelopeProductConsts"].ENVELOPE:
            return EnvelopeProductFactory(sku);
        case ProductConsts["PaperTubeProductConsts"].PAPER_CAN:
            return PaperTubeProductFactory(sku);
        case ProductConsts["PaperBagProductConsts"].PAPER_BAG:
        case ProductConsts["PaperBagProductConsts"].DOYPACK_BAG:
        case ProductConsts["PaperBagProductConsts"].TOTE_BAG:
            return PaperBagProductFactory(sku);
        case ProductConsts["PolyMailerProductConsts"].POLY_MAILER:
        case ProductConsts["PolyMailerProductConsts"].POLY_MAILER_RECYCLED:
        case ProductConsts["PolyMailerProductConsts"].POLY_MAILER_BIO:
        case ProductConsts["PolyMailerProductConsts"].POLY_MAILER_BIO_PLAIN:
            return PolyMailerProductFactory(sku);
        case ProductConsts["PaperMailerProductConsts"].PAPER_MAILER:
            return PaperMailerProductFactory(sku);
        case ProductConsts["NcpProductConsts"].WOOD_WOOL:
        case ProductConsts["NcpProductConsts"].SELF_ADHESIVE_ENVELOPE:
        case ProductConsts["NcpProductConsts"].STICKER_CIRCLE:
        case ProductConsts["NcpProductConsts"].STICKER_RECTANGLE:
        case ProductConsts["NcpProductConsts"].THANK_YOU_CARD:
        case ProductConsts["NcpProductConsts"].PAPER_BUBBLE_WRAP:
            return NcpProductFactory(sku);
        case ProductConsts["LabelProductConsts"].SQUARE_LABEL:
        case ProductConsts["LabelProductConsts"].RECTANGLE_LABEL:
        case ProductConsts["LabelProductConsts"].CIRCLE_LABEL:
            return LabelProductFactory(sku);
        case ProductConsts["CardProductConsts"].PRINTED_CARD:
            return CardProductFactory(sku);
        default:
            return BoxProductFactory(sku);
    }
}
