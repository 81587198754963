import { IntegrationsEnvConfig } from "../types"

export class IntegrationsConfig {
  public readonly ga?: IntegrationsEnvConfig["ga"]
  public readonly segment?: IntegrationsEnvConfig["segment"]
  public readonly datadog?: IntegrationsEnvConfig["datadog"]
  public readonly sentry?: IntegrationsEnvConfig["sentry"]
  public readonly configCat?: IntegrationsEnvConfig["configCat"]

  constructor(config: IntegrationsEnvConfig = {}) {
    this.ga = config.ga
    this.segment = config.segment
    this.datadog = config.datadog
    this.sentry = config.sentry
    this.configCat = config.configCat
  }
}
