var _a;
import TAPE_CONSTS from "./consts/tapeConsts";
var QUANTITIES_SPEC = (_a = {},
    _a[TAPE_CONSTS.TAPE_MODEL_PACKING_TAPE] = {
        DEFAULT_QUANTITY: 36,
        QUANTITY_RANGES: [
            36, 72, 108, 144, 180, 216, 252, 288, 324, 360, 540, 720, 1080, 1440,
            2592, 5148,
        ],
        QUANTITY_DENSITY: 14,
        QUANTITY_STEP: 36,
    },
    _a[TAPE_CONSTS.TAPE_MODEL_KRAFT_TAPE] = {
        DEFAULT_QUANTITY: 36,
        QUANTITY_RANGES: [
            36, 72, 108, 144, 180, 216, 252, 288, 324, 360, 540, 720, 1080, 1440,
            2592, 5148,
        ],
        QUANTITY_DENSITY: 12,
        QUANTITY_STEP: 36,
    },
    _a[TAPE_CONSTS.TAPE_MODEL_PRE_PRINTED_KRAFT_TAPE] = {
        DEFAULT_QUANTITY: 4,
        QUANTITY_RANGES: [
            4, 8, 12, 16, 20, 24, 28, 32, 36, 72, 108, 144, 180, 216, 540, 720, 1080,
            2592, 5148,
        ],
        QUANTITY_DENSITY: 12,
        QUANTITY_STEP: 36,
    },
    _a);
export default QUANTITIES_SPEC;
