import React from "react"
import { useTranslate } from "shared-libs/src/js/shared-components/i18n/useTranslate"
import { Button } from "dsl/src/atoms/Button/Button"
import { observer } from "mobx-react-lite"
import { ButtonModes } from "dsl/src/atoms/Button"
import { I18N } from "../../../i18n"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

type EditorHeaderDesignLaterProps = {
  isDisabled: boolean
}

export const EditorHeaderDesignLater = observer(
  ({ isDisabled }: EditorHeaderDesignLaterProps) => {
    const t = useTranslate()

    const [headerSet, headerSetErrors] = useContainerSet((c) => [c.ecommerce])

    if (headerSet == null || headerSetErrors != null) return null
    const { ecommerce } = headerSet

    if (!ecommerce.available) {
      return null
    }

    const { designLaterController } = ecommerce

    return (
      <div>
        <Button
          disabled={isDisabled}
          onClick={() => designLaterController.addDraftToCart()}
          e2eTargetName="design-later"
          mode={ButtonModes.secondary}
        >
          {t(I18N.component.navbar.designLater)}
        </Button>
      </div>
    )
  }
)
