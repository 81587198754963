import * as shared from "../shared/shared-consts"

const Region = {
  trustpilotId: shared.TRUSTPILOT.trustpilotId,
  trustpilotBusinessId: shared.TRUSTPILOT.trustpilotBusinessId,
  trustpilotInvitationTemplateId:
    shared.TRUSTPILOT.trustpilotInvitationTemplateId,

  googleapisKey: shared.GOOGLEAPIS_KEY,

  secondaryAnalyticsCode: "UA-71580330-9",
  productRegion: "pl",
  mainRegion: true,
  hasVat: true,
  countryCode: "pl",
  languageTag: "pl-PL",
  iterableLocale: "pl-PL",
  locale: "pl",
  phoneCountryCode: "+48",
  defaultShippingCountryCode: "PL",
  emailAddress: "kontakt@zapakuj.to",
  emailSenderName: "[ zapakuj.to ]",
  phoneNumber: "12 211 31 38",

  additionalCustoms: 0,

  mainDomain: shared.PL_MAIN_DOMAIN,
  pageTitle: "Kreator - zapakuj.to",
  footerLabel: "zapakuj.to",
  logoSVGIcon: "zapakuj-to-logo",
  logoSVGIconWhite: "zapakuj-to-logo",
  frontendBoxDesignRoute: "pudelko",
  frontendAccountOrdersRoute: "konto/zamowienia",
  frontendTermsOfServiceRoute: "/regulamin/",
  frontendPrivacyPolicyRoute: "/polityka_prywatnosci/",
  domain: {
    basename: "zapakuj",
    tld: "to",
  },
  customDomains: {
    app: {
      dev: "app.dev.zapakuj.to",
      staging: "app.staging.zapakuj.to",
      production: "kreator.zapakuj.to",
    },
    landing: {
      dev: "dev.zapakuj.to",
      staging: "staging.zapakuj.to",
      production: "zapakuj.to",
    },
  },

  externalLinks: {
    qrForm: "/quote-request",
    affiliates: "/program-afiliacyjny/",
    fscCertification: "/certyfikacja-fsc/",
    helpPlantTrees: "/pomoz-sadzic-drzewa/",
    ecoProperties: "/eko-przewodnik/",
    sustainability: "/zrownowazony-rozwoj/",
    designShowcase: "/galeria-projektow/",
    holidays: "/sezon-swiateczny/",
    inspirationsHandbook: "/inspiracje-przewodnik/",
    mobileAppLandingPageUrl: "/aplikacja-mobilna/",
    brief: "/brief/",
    artworkTipsDielines: "/wskazowki-do-projektowania-na-wykrojniku/",
    help: "/pomoc/",
    thankYou: "/dziekujemy/",
    designGuidelines: "/pomoc/tematy/kreator/",
    about: "/o-nas/",
    career: "/kariera/",
    shop: "/oferta/",
    services: "/services/",
    industries: "/i/",
    bundles: "/pakiety",
    inspirations: "/inspiracje/",
    impressum: "/impressum/",
    returnPolicy: "/pomoc/temat/moge-zwrocic-zamowione-pudelka/",
    blog: "/blog/",
    contact: "/kontakt/",
    privacyPolicy: "/polityka_prywatnosci/",
    termsOfService: "/regulamin/",
    termsOfServicePlus: "/regulamin-packhelp-plus/",
    accountSignOut: "/wylogowanie/",
    accountSignIn: "/logowanie/",
    accountRegister: "/rejestracja",
    accountOrders: "/konto/zamowienia",
    accountOrderDetails: "/konto/zamowienia/:orderNumber",
    accountSettings: "/konto/ustawienia",
    accountSupportReforestation: "/konto/wspieraj-sadzenie-drzewek",
    accountBoxes: "/konto/pudelka",
    accountWallet: "/konto/portfel",
    accountCart: "/koszyk/", // old checkout key - to be removed
    samples: "/sample-packs/",
    customOffer: "/zamowienie/:token/oferta",
    payment: "/zamowienie/:orderId/platnosc",
    paymentSummary: "/zamowienie/:orderId/platnosc/podsumowanie",
    quoteRequest: "/konto/zapytania/:qrNumber",
    cart: "/koszyk",
    orderDetails: "/zamowienie",
    crossSell: "/koszyk/rekomendowane-produkty-i-uslugi/s",
    fullAndEcoColorHelpSite:
      "/pomoc/temat/czym-sie-rozni-standard-wykonczenia-full-color-od-eco-color/",
    safeZonesHelpSite: "/pomoc/temat/trzymaj-sie-z-dala-od-krawedzi/",
    samplePacksLink:
      "/pudelko?sku=box-pl--samples--samples--samples--samples--samples&dielineMode=false",
    termsAcceptedSite: "/zaakceptowano-warunki-korzystania",
    packhelpWallet: "/packhelp-wallet",
    ecoBadge: "/eko-odznaka/",
    leadership: "/leadership/",
    press: "/informacje-dla-mediow/",
    urlWhitelistedToBeFetchedFromUrl: [
      "/zamowienie/:orderNumber/dane",
      "/zamowienie/:orderNumber/platnosc",
    ],
    products: {
      "mailer-box-plain": "/pudelka-fasonowe-bez-nadruku/",
      "mailer-box-eco": "/pudelka-fasonowe-eco/",
      "mailer-box-eco-white": "/pudelka-fasonowe-eco-white/",
      "mailer-box-full-color": "/pudelka-fasonowe-full-color/",
      "mailer-box-eco-color": "/pudelka-fasonowe-eco-color/",
      "delivery-mailer-box": "/pudelka-wysylkowe-ecommerce/",
      "delivery-mailer-box-plain": "/pudelka-wysylkowe-bez-nadruku/",
      "shipping-box-plain": "/pudelka-klapowe-bez-nadruku/",
      "shipping-box": "/pudelka-klapowe-eco/",
      "product-box": "/pudelka-produktowe-klasyczne/",
      "product-box-two-pieces": "/pudelka-produktowe-z-wieczkiem/",
      "poly-mailer": "/foliopaki-klasyczne/",
      "paper-mailer": "/papieropak/",
      "paper-bag": "/torby-papierowe-z-raczka/",
      "rigid-envelope": "/koperty-kurierskie-z-nadrukiem/",
      "product-box-wine-box": "/pudelka-na-wino-z-nadrukiem/",
      "tissue-paper": "/papier-bibulkowy-z-nadrukiem/",
      "packing-paper": "/papier-pakowy-z-nadrukiem/",
      "packing-tape": "/tasmy-pakowe-z-nadrukiem/",
      "sleeved-mailer-box": "/obwoluty/",
      "kraft-tape": "/tasmy-kraft/",
      "bio-poly-mailer": "/biodegradowalne-foliopaki/",
      "paper-can": "/tuby-ozdobne/",
      "mailer-box-white-ink": "/pudelka-fasonowe-kraft-z-bialym-nadrukiem/",
      "mailer-box-full-color-double-sided":
        "/pudelka-fasonowe-z-obustronnym-nadrukiem/",
      "rigid-box": "/pudelka-z-tektury-litej/",
      "rigid-box-two-pieces": "/pudelko-z-wieczkiem/",
      "special-box": "/opakowania-specjalne-na-miare/",
      "magnetic-box": "/pudelka-na-magnes/",
      "carrier-box": "/pudelka-z-uchwytem/",
      "rigid-drawer-box": "/pudelka-wysuwane/",
      "cake-box": "/pudelka-na-ciasto/",
      "bio-poly-mailer-plain":
        "/biodegradowalne-foliopaki-z-gotowym-nadrukiem/",
      "pre-printed-mailer-box-eco": "/pudelka-fasonowe-eco-gotowy-nadruk/",
      "pre-printed-mailer-box-full-colour":
        "/pudelka-fasonowe-full-color-gotowy-nadruk/",
      "doypack-bag": "/opakowania-doypack/",
      "square-label": "/etykiety-samoprzylepne/",
      "tote-bag": "/torba-bawelniana/",
      "printed-card": "/kartki-paczki/",
      "pre-printed-kraft-tape": "/tasma-pakowa-kraft-gotowy-nadruk/",
      "recycled-poly-mailer": "/foliopak-z-recyklingu/",
    },
    categories: {
      "mailer-boxes": "/pudelka-fasonowe/",
      "custom-shipping-boxes": "/pudelka-do-wysylki/",
      "primary-packaging": "/pudelka-produktowe/",
      "eco-friendly-packaging": "/opakowania-ekologiczne/",
      "luxury-packaging": "/pudelka-lita-tektura/",
    },
    fb_url: shared.PL_MAIN_DOMAIN,
    fb_sitename: "Zapakuj.to",
    fb_image: "/share_image_1200x630.png",
    share_img: "/share_image_120x120.png", // Editor
    boxEditorPath: "pudelko",
    productEditor: "/editor/product/design",
    exampleDesign: "79485?language=pl&templateMode=true",
    productQuotationSystem: "/quote",
    pqsSuccessPage: "/quote/success",
    plus: {
      catalogue: "/plus/katalog/",
      successPage: "/plus/success",
      items: "/packhelp-plus/items",
      quotes: "/packhelp-plus/quotes",
      quoteShow: "/packhelp-plus/quotes/:token",
      orders: "/packhelp-plus/orders",
      orderShow: "/packhelp-plus/orders/:orderNumber/details",
      orderPlaced: "/packhelp-plus/orders/:orderNumber/placed",
      orderItems: "/packhelp-plus/orders/:orderNumber/items",
      orderPaymentSummary: "/packhelp-plus/orders/:orderNumber/payment-summary",
      "doypack-bag": "/plus/katalog/opakowania-doypack/",
      personalDetails: "/packhelp-plus/personal-details",
      "tote-bag": "/plus/katalog/personalizowane-torby-bawelniane/",
      "product-boxes": "plus/katalog/style-opakowan/pudelka-produktowe/",
      "mailer-boxes": "plus/katalog/style-opakowan/pudelka-fasonowe/",
    },
    pro: {
      features: "pro/features/",
      suppliers: "pro/for-suppliers/",
      termsOfService: "pro/terms-conditions/",
    },
    industriesListing: "/i/",
    whiteLabel: "/enterprise/",
  },

  outsidePackhelpDomainLinks: {
    ...shared.externalDomainPackhelpLinks,
    facebook: "https://www.facebook.com/zapakuj.to",
  },

  remindMeOnDesktop: {
    remindme_form_url:
      "//zapakuj.us12.list-manage.com/subscribe/post-json?u=2060c1de14ec30d28a9fab813&amp;id=44e983dd7b&c=?",
    remindme_hidden_input_name: "b_2060c1de14ec30d28a9fab813_44e983dd7b",
  },

  datesFormattingRules: shared.DATES_FORMATTING_RULES_PL,

  ...shared.SHARED_ECOMMERCE_PROPERTIES.pl,
  ...shared.SHARED_CURRENCY_PROPERTIES.pln,

  googleConversionId: "928430660",
  googleAnalytics: {
    primaryCode: "UA-71580330-2",
    secondaryCode: "UA-71580330-9",
    segmentCode: "uXDomf9GW2BCffFseKvLrarxNiGNLI5O",
  },
  googleAdWords: {
    remarketingCode: "39192",
    conversionCode: "928430660",
  },

  facebookAppId: "1660833110841347",
  supportsPlus: true,
}

export default Region
