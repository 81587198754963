/**
 * If you want to export any of the params below you should also add it to paramsToExport array in
 * editor/src/render-engine/modules/vd-editor/services/virtual-dieline-exporter.ts
 */
export const assetParamsToIncludeInClonedObject = [
  "assetType",
  "clipMode",
  "isSafeZoneTouched",
  "assetTextMeta",
  "assetObjectMeta",
  "assetImageMeta",
  "id",
  "lockUniScaling",
  "originSpaceArea",
  "fillPantoneName",
  "isLogo",
  "isFsc",
  "maskConfig",
  "keepRatio",
]
