import React, { forwardRef, Ref } from "react"
import cxBinder from "classnames/bind"
import styles from "./TextArea.module.scss"
const cx = cxBinder.bind(styles)

export interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  error?: boolean
  disabled?: boolean
  noResize?: boolean
  children?: never
  e2eTargetName?: string
  e2eTarget?: string
}

const TextArea = forwardRef(
  (props: TextAreaProps, ref?: Ref<HTMLTextAreaElement>) => {
    const {
      children,
      disabled,
      error,
      noResize,
      e2eTarget,
      e2eTargetName,
      ...other
    } = props
    const classNames = cx("default", {
      error,
      disabled,
      "default--no-resize": noResize
    })

    return (
      <textarea
        ref={ref}
        className={classNames}
        disabled={disabled}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
        {...other}
      >
        {children}
      </textarea>
    )
  }
)

export { TextArea, TextArea as default }
