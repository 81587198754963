import fabric from "../../../../libs/vendors/Fabric"
import { Size } from "../modules/dieline-navigator/services/translation-calculator"
import {
  PackhelpGroup,
  PackhelpObject,
} from "../object-extensions/packhelp-objects"
import {
  EditableObjectTypes,
  isGroup,
} from "../../../../modules/ph-api/asset-types"
import { CANVAS_DIM } from "../../../types"
import { ClippingHelper } from "../modules/assets-module/helpers/clipping-helper"

export enum ClipPathModes {
  AlphaMaskMode,
  FillMode,
}

export class ClipPathGenerator {
  constructor(
    private readonly clipPathObject: PackhelpObject,
    private readonly canvasDimensions: Size
  ) {}

  public generate(
    objectToClip: PackhelpObject,
    mode: ClipPathModes,
    clipPathId?: string
  ): PackhelpObject {
    if (mode === ClipPathModes.AlphaMaskMode) {
      return this.applyAlphaModeClipPath(objectToClip)
    }

    return this.applyFillModeClipPath(objectToClip, clipPathId)
  }

  private applyFillModeClipPath(
    objectToClip: PackhelpObject,
    clipPathId
  ): PackhelpObject {
    if (objectToClip.assetType === EditableObjectTypes.assetGroup) {
      return this.applyFillModeClipPathForGroup(
        objectToClip as PackhelpGroup,
        clipPathId
      )
    }

    this.clipPathObject.set({
      absolutePositioned: true,
      inverted: true,
    })

    if (!objectToClip.clipPath || !isGroup(objectToClip.clipPath)) {
      objectToClip.clipPath = ClippingHelper.buildClipPathGroup()
    }

    if (isGroup(objectToClip.clipPath)) {
      const currentItem = objectToClip.clipPath
        .getObjects()
        .find((obj) => obj.id === clipPathId)
      if (currentItem) {
        objectToClip.clipPath.remove(currentItem)
      }

      const rect = new fabric.Rect() as PackhelpObject

      rect.set({
        id: clipPathId,
        width: CANVAS_DIM,
        height: CANVAS_DIM,
        left: -CANVAS_DIM / 2,
        top: -CANVAS_DIM / 2,
        absolutePositioned: true,
        clipPath: this.clipPathObject,
      })

      objectToClip.clipPath.add(rect)
    }

    objectToClip.set({
      clipMode: ClipPathModes.FillMode,
      alphaModeClipping: false,
    })

    return objectToClip
  }

  private applyFillModeClipPathForGroup(
    groupToClip: PackhelpGroup,
    clipPathId: string
  ): PackhelpGroup {
    for (const object of groupToClip.getObjects()) {
      this.applyFillModeClipPath(object, clipPathId)
    }

    groupToClip.set({
      clipPath: undefined,
      clipMode: ClipPathModes.FillMode,
      alphaModeClipping: false,
      dirty: true,
    })

    return groupToClip
  }

  private applyAlphaModeClipPath(objectToClip: PackhelpObject): PackhelpObject {
    if (objectToClip.assetType === EditableObjectTypes.assetGroup) {
      return this.applyAlphaModeClipPathForGroup(objectToClip as PackhelpGroup)
    }

    const clipPathObject = this.clipPathObject

    clipPathObject.set({
      inverted: true,
      opacity: 1,
      visible: true,
      top: 0,
      left: (clipPathObject.width! / 2) * -1,
      alphaModeClipping: true,
    })

    const clipObject = new fabric.Rect({
      ...this.canvasDimensions,
      left: 0,
      top: 0,
      absolutePositioned: true,
      inverted: true,
      clipPath: this.clipPathObject,
    })

    objectToClip.set({
      clipMode: ClipPathModes.AlphaMaskMode,
      clipPath: clipObject,
      alphaModeClipping: true,
    })

    return objectToClip
  }

  private applyAlphaModeClipPathForGroup(
    groupToClip: PackhelpGroup
  ): PackhelpGroup {
    for (const object of groupToClip.getObjects()) {
      this.applyAlphaModeClipPath(object)
    }

    groupToClip.set({
      clipPath: undefined,
      clipMode: ClipPathModes.AlphaMaskMode,
      alphaModeClipping: true,
      dirty: true,
    })

    return groupToClip
  }
}
