import { colors } from "./color"

const defaultBrowserFontSize = "16px" // should be 16px as const value this is default browser default font-size... guessing
const defaultFamily =
  "var(--font-family-primary-editor-override), ABCFavoritVariable, Roboto, Arial, sans-serif, serif"

const sizes = {
  10: "10px",
  13: "13px",
  15: "15px",
  17: "17px",
  20: "20px",
  24: "24px",
  32: "32px",
  40: "40px",
  48: "48px",
  56: "56px",
  64: "64px"
}

const lh = {
  104: 1.04,
  108: 1.08,
  116: 1.16,
  120: 1.2,
  132: 1.32,
  124: 1.24,
  140: 1.4
}

const fontWeights = {
  book: 350,
  regular: 400,
  medium: 500
}

const letterSpacing = {
  negative: {
    2: -0.02,
    4: -0.04,
    5: -0.05,
    6: -0.06
  },
  positive: {}
}

const classname = (
  type: string,
  fz: string | number,
  fw: string | number,
  upperCase: boolean = false
) => {
  if (upperCase) {
    return `${type}-uc--${fz}-${fw}`
  }
  return `${type}--${fz}-${fw}`
}

const headers: [string, number, number, number, boolean?][] = [
  [sizes[64], fontWeights["medium"], lh[104], letterSpacing.negative[6]],
  [sizes[56], fontWeights["regular"], lh[104], letterSpacing.negative[6]],
  [sizes[48], fontWeights["regular"], lh[108], letterSpacing.negative[6]],
  [sizes[40], fontWeights["medium"], lh[108], letterSpacing.negative[5]],
  [sizes[40], fontWeights["regular"], lh[108], letterSpacing.negative[5]],
  [sizes[32], fontWeights["regular"], lh[108], letterSpacing.negative[5]],
  [sizes[24], fontWeights["regular"], lh[116], letterSpacing.negative[4]],
  [sizes[20], fontWeights["medium"], lh[116], letterSpacing.negative[2]],
  [sizes[20], fontWeights["regular"], lh[116], letterSpacing.negative[2]],
  [sizes[17], fontWeights["medium"], lh[116], letterSpacing.negative[2]],
  [sizes[17], fontWeights["regular"], lh[116], letterSpacing.negative[2]],
  [sizes[15], fontWeights["medium"], lh[116], letterSpacing.negative[2]],
  [sizes[13], fontWeights["regular"], lh[116], letterSpacing.negative[2]],
  [sizes[13], fontWeights["medium"], lh[116], letterSpacing.negative[2]]
]

const body: [string, number, number, number, boolean?][] = [
  [sizes[32], fontWeights["book"], lh[132], letterSpacing.negative[4]],
  [sizes[24], fontWeights["regular"], lh[132], letterSpacing.negative[2]],
  [sizes[24], fontWeights["book"], lh[132], letterSpacing.negative[2]],
  [sizes[20], fontWeights["book"], lh[132], letterSpacing.negative[2]],
  [sizes[17], fontWeights["book"], lh[132], letterSpacing.negative[2]],
  [sizes[15], fontWeights["book"], lh[132], letterSpacing.negative[2]],
  [sizes[13], fontWeights["book"], lh[132], letterSpacing.negative[2]],
  [sizes[10], fontWeights["book"], lh[132], letterSpacing.negative[2]],
  // uppercase
  [sizes[15], fontWeights["medium"], lh[108], letterSpacing.negative[2], true],
  [sizes[13], fontWeights["regular"], lh[108], letterSpacing.negative[2], true],
  [sizes[10], fontWeights["regular"], lh[108], letterSpacing.negative[2], true]
]

export const typography = {
  fontFamily: defaultFamily,
  fontSize: defaultBrowserFontSize,
  fontColors: {
    black: colors.primary["rich-blue"],
    "black-transparent": colors.transparent["rich-blue-70"],
    white: colors.primary.white,
    "white-transparent": colors.transparent["white-70"],
    blue: colors.primary["ph-blue"],
    red: colors.primary["red"],
    "grey-500": colors.primary["grey-500"],
    "grey-600": colors.primary["grey-600"]
  },
  fontWeights,
  letterSpacing,
  sizes,
  lh,
  headers,
  body,
  classname
}
