import _flow from "lodash/flow";
import paperMailerProductSpec from "../../../source/paperMailerProductSpec/paperMailerProductSpec";
import CustomProductConfigBuilder from "../configBuilders/CustomProductConfigBuilder";
import addCustomProductDimensionsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductDimensionsBehaviors";
import addCustomProductProductionTimesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductProductionTimesBehaviors";
import addCustomProductVariantBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductVariantBehaviors";
import addCustomProductEditConfigBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductEditConfigBehaviors";
import addCustomProductGrammagesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductGrammagesBehaviors";
import addCustomProductTranslationsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductTranslationsBehaviors";
import PaperMailerTranslationsManager from "../Products/PaperMailerProduct/behaviorsComposers/PaperMailerTranslationsManager";
import PaperMailerVariantManager from "../Products/PaperMailerProduct/behaviorsComposers/PaperMailerVariantManager";
import GrammagesManager from "../behaviorsComposers/GrammagesManager";
import WizzardManager from "../behaviorsComposers/WizzardManager";
import EditConfigManager from "../behaviorsComposers/EditConfigManager";
import ProductionTimeManager from "../behaviorsComposers/ProductionTimeManager";
import PaperMailerDimensionsManager from "../Products/PaperMailerProduct/behaviorsComposers/PaperMailerDimensionsManager";
var CustomProductionTimeManager = addCustomProductProductionTimesBehaviors(ProductionTimeManager);
var CustomProductGrammagesManager = addCustomProductGrammagesBehaviors(GrammagesManager);
var CustomProductDimmensionsManager = addCustomProductDimensionsBehaviors(PaperMailerDimensionsManager);
var CustomProductEditrConfigManager = addCustomProductEditConfigBehaviors(EditConfigManager);
var CustomProductTranslationsManager = addCustomProductTranslationsBehaviors(PaperMailerTranslationsManager);
var CustomProductVariantManager = _flow([addCustomProductVariantBehaviors])(PaperMailerVariantManager);
var CustomPaperMailerProduct = /** @class */ (function () {
    function CustomPaperMailerProduct(sku, productName) {
        this.config = CustomProductConfigBuilder(paperMailerProductSpec, sku, productName);
        this.wizzardManager = new WizzardManager(this);
        this.productionTimeManager = new CustomProductionTimeManager(this);
        this.editConfigManager = new CustomProductEditrConfigManager(this);
        this.variantManager = new CustomProductVariantManager(this);
        this.grammagesManager = new CustomProductGrammagesManager(this);
        this.dimensionsManager = new CustomProductDimmensionsManager(this);
        this.translationsManager = new CustomProductTranslationsManager(this);
    }
    CustomPaperMailerProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    CustomPaperMailerProduct.prototype.getMaterialDescription = function () {
        return null;
    };
    return CustomPaperMailerProduct;
}());
export default CustomPaperMailerProduct;
