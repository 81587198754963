import React from "react"
import { observer } from "mobx-react-lite"
import { I18N } from "../../../../i18n"
import { useTranslate } from "../../../../hooks/useTranslate"
import {
  HorizontalTabs,
  HorizontalTab,
} from "../../../atoms/HorizontalTabs/HorizontalTabs"
import { Button, ButtonSizes } from "dsl/src/atoms/Button"
import { ArtworkTab } from "./ArtworkTab"
import { TextTab } from "./TextTab"
import { AnalyticsController } from "../../../../../stores/_controllers/analytics-controller"
import { ReplicablePatternUiController } from "../../../../../stores/_controllers/replicable-pattern-ui-controller"
import { ReplicablePatternsStore } from "../../../../../stores/replicable-patterns-store/replicable-patterns.store"

interface EditorToolReplicablePatternsSimpleProps {
  onButtonClick: (x: boolean) => void
  replicablePatternUiController: ReplicablePatternUiController
  replicablePatternsStore: ReplicablePatternsStore
  analyticsController?: AnalyticsController
}

export const EditorToolReplicablePatternsSimple = observer(
  ({
    onButtonClick,
    replicablePatternUiController,
    replicablePatternsStore,
    analyticsController,
  }: EditorToolReplicablePatternsSimpleProps) => {
    const t = useTranslate()
    const activeTab = replicablePatternsStore.selectedMode

    const shouldRenderAdjustButton = () => {
      if (activeTab === "image") {
        return replicablePatternsStore.isArtworkReady
      }

      return true
    }

    return (
      <>
        <HorizontalTabs>
          <HorizontalTab
            onClick={() => replicablePatternUiController.selectMode("image")}
            active={activeTab === "image"}
          >
            {t(I18N.editorTools.patterns.artwork)}
          </HorizontalTab>
          <HorizontalTab
            onClick={() => replicablePatternUiController.selectMode("text")}
            active={activeTab === "text"}
          >
            {t(I18N.editorTools.patterns.text)}
          </HorizontalTab>
        </HorizontalTabs>
        {activeTab === "image" && (
          <ArtworkTab
            replicablePatternUiController={replicablePatternUiController}
            replicablePatternsStore={replicablePatternsStore}
          />
        )}
        {activeTab === "text" && <TextTab />}
        {shouldRenderAdjustButton() && (
          <Button
            type="submit"
            size={ButtonSizes.medium}
            fluid={true}
            onClick={(e) => {
              e.preventDefault()
              onButtonClick(true)
              analyticsController?.trackReplicablePatternAdjustFillClicked(
                activeTab
              )
            }}
          >
            {t(I18N.editorTools.patterns.adjust)}
          </Button>
        )}
      </>
    )
  }
)
