import hotkeys from "hotkeys-js"
import { ee, eventTree } from "../../stores/editor-events"
import { ActiveTabEvents } from "../../stores/_events/active-tab-events"
import { ActiveObjectEvents } from "../../stores/_events/active-object-events"

export type KeyboardScope = "all" | "active-object" | "active-tab"

export class KeyboardControls {
  private scopes: KeyboardScope[] = []

  private readonly keyEventMap: Record<
    KeyboardScope,
    Record<string, ActiveTabEvents | ActiveObjectEvents>
  > = {
    all: {
      "ctrl+v": eventTree.activeObject.paste,
      "cmd+v": eventTree.activeObject.paste,
    },
    "active-object": {
      "ctrl+c": eventTree.activeObject.copy,
      "cmd+c": eventTree.activeObject.copy,
      backspace: eventTree.activeObject.delete,
      delete: eventTree.activeObject.delete,
      up: eventTree.activeObject.moveUp,
      down: eventTree.activeObject.moveDown,
      left: eventTree.activeObject.moveLeft,
      right: eventTree.activeObject.moveRight,
      escape: eventTree.activeObject.deselect,
    },
    "active-tab": {
      up: eventTree.activeTab.up,
      down: eventTree.activeTab.down,
      enter: eventTree.activeTab.select,
      space: eventTree.activeTab.select,
      escape: eventTree.activeTab.close,
    },
  }

  constructor() {
    this.setEventListeners()
    this.setKeyboardListeners()
  }

  public setScope(scope: KeyboardScope) {
    this.scopes.push(scope)
    hotkeys.setScope(scope)
  }

  public clearScope(scope: KeyboardScope) {
    hotkeys.setScope("all")

    this.scopes = this.scopes.filter((s) => s !== scope)

    if (this.scopes.length > 0) {
      hotkeys.setScope(this.scopes[this.scopes.length - 1])
    }
  }

  private setEventListeners() {
    ee.on(eventTree.keyboard.setScope, this.setScope.bind(this))
    ee.on(eventTree.keyboard.clearScope, this.clearScope.bind(this))
  }

  private setKeyboardListeners() {
    for (const [scope, scopedKeyEventMap] of Object.entries(this.keyEventMap)) {
      for (const [key, event] of Object.entries(scopedKeyEventMap)) {
        hotkeys(key, scope, () => {
          ee.emit(event)
        })
      }
    }
  }
}
