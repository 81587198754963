import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { TType } from "dsl/src/atoms/Typography/Typography"
import { FormControlLabel } from "dsl/src/atoms/FormControlLabel/FormControlLabel"
import { Checkbox } from "dsl/src/atoms/Checkbox/Checkbox"
import { TM } from "../../../../../TypographyI18n"
import { MonochromeSlider } from "../../../../molecues/monochrome-slider/MonochromeSlider"
import { EditorToolHeaderCheckbox } from "../../../../molecues/editor-tool-header-checkbox/EditorToolHeaderCheckbox"

import { ColorConfigurator } from "../../../configurators/ColorConfigurator/ColorConfigurator"
import { Colour } from "../../../../../../libs/value-objects/colour"
import { AvailableColourModes } from "../../../../../../libs/products-render-config/types"
import ProductDriver from "../../../../../../stores/product-driver/product.driver"

import editorToolStyles from "../../EditorTools.module.scss"
import styles from "./EditorToolHeaderImageAssets.module.scss"

type ThresholdAdjuster = {
  onChangeInverse(): void
  onChangeThresholdLevel(lvl): void
  thresholdLevel: number
  isInverted: boolean
}

const ThresholdAdjuster = ({
  onChangeThresholdLevel,
  thresholdLevel,
  onChangeInverse,
  isInverted,
}: ThresholdAdjuster) => {
  return (
    <>
      <TM
        type={TType.Body15_350}
        id="component.editor-tools-assets.threshold"
      />
      <div className={styles.slider_wrapper}>
        <MonochromeSlider
          onChange={onChangeThresholdLevel}
          value={thresholdLevel}
        />
      </div>
      <FormControlLabel
        control={<Checkbox checked={isInverted} onChange={onChangeInverse} />}
      >
        <TM
          type={TType.Body15_350}
          id="component.editor-tools-assets.inverse"
        />
      </FormControlLabel>
    </>
  )
}

export const EditorToolHeaderImageAssets = observer(
  ({ productDriver }: { productDriver: ProductDriver }) => {
    const editorMode = productDriver.state.productRenderPilot.getEditorMode()

    const { activeObjectDriver } = productDriver
    const {
      isInverted,
      isUniScalingLocked,
      isImageBackgroundRemoved,
      threshold: thresholdLevel,
    } = activeObjectDriver.activeObjectComputable
    const activeObjectController =
      productDriver.activeObjectDriver.activeObjectController
    const { productRenderPilot } = productDriver.state

    if (!activeObjectController) {
      return null
    }

    const isMonochrome =
      productRenderPilot.getColorMode() === AvailableColourModes.MONOCHROME
    const isMonoPantone =
      productRenderPilot.getColorMode() === AvailableColourModes.MONO_PANTONE

    const isThresholdAvailable = isMonochrome || isMonoPantone

    const { isObjectActive, objectIdentified } =
      productDriver.activeObjectDriver

    const onChangeThresholdLevel = (thresholdlvl) => {
      activeObjectDriver.setThreshold(thresholdlvl)
    }

    const toggleUniScaling = () => {
      productDriver.activeObjectDriver.toggleUniScaling()
    }

    const toggleImageBackground = () => {
      productDriver.activeObjectDriver.toggleImageBackground()
    }

    const onChangeInverse = () => {
      activeObjectDriver.toggleMonochrome()
    }

    const [imageTintColor, setImageTintColor] = useState<Colour>(
      activeObjectController.getFill()
    )

    useEffect(() => {
      setImageTintColor(activeObjectController.getFill())
    }, [isObjectActive, objectIdentified, activeObjectController])

    const onChangeAssetColor = (color: Colour) => {
      activeObjectController.setStyles({ fill: color })
      setImageTintColor(color)
    }

    const onColorBackClick = () => {
      onChangeAssetColor(imageTintColor)
    }

    return (
      <div className={editorToolStyles.header_nav}>
        {isThresholdAvailable && (
          <ThresholdAdjuster
            onChangeThresholdLevel={onChangeThresholdLevel}
            thresholdLevel={thresholdLevel}
            onChangeInverse={onChangeInverse}
            isInverted={!isInverted}
          />
        )}

        {activeObjectController.isColorModificationAvailable() && (
          <ColorConfigurator
            onColorSelect={onChangeAssetColor}
            onBackClick={onColorBackClick}
            selectedColor={imageTintColor}
          />
        )}

        {editorMode === "designer" && (
          <EditorToolHeaderCheckbox
            isChecked={isImageBackgroundRemoved}
            onChange={toggleImageBackground}
            optionLabelIntl="component.editor-tool-shapes.checkbox.remove-white-background"
          />
        )}

        <EditorToolHeaderCheckbox
          isChecked={isUniScalingLocked}
          onChange={toggleUniScaling}
          optionLabelIntl="component.editor-tool-shapes.checkbox.keep-proportions"
        />
      </div>
    )
  }
)
