import React from "react"
import { observer } from "mobx-react-lite"
import { EditorHeaderProjectDetails } from "../../../dsl/organisms/EditorHeader/EditorHeaderProjectDetails/EditorHeaderProjectDetails"
import { EditorHeaderSpaceDetails } from "../../../dsl/organisms/EditorHeader/EditorHeaderProjectDetails/EditorHeaderSpaceDetails"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"

export const EditorHeaderInfo = observer(() => {
  const [designAndProductDriverContainer] =
    useContainer().designAndProductDriver

  if (!designAndProductDriverContainer) return null

  const { productRenderPilot, activeSpace } =
    designAndProductDriverContainer.productDriver.state
  const is3DProduct = productRenderPilot.is3DProduct()
  const is2DProduct = !is3DProduct
  const isDefaultView = is2DProduct || (is3DProduct && !activeSpace)

  if (isDefaultView) {
    return <EditorHeaderProjectDetails />
  }

  return <EditorHeaderSpaceDetails />
})
