import React, { useContext } from "react"
import { observer } from "mobx-react-lite"
import { RootStoreContext } from "../../../../../../stores/store-contexts"
import { RootStore } from "../../../../../../stores/root.store"
import { colorsListDemoWithBlankColor } from "../../../ColourPicker/colors-list"
import { Colour } from "../../../../../../libs/value-objects/colour"
import { I18N } from "../../../../../i18n"
import { ColorConfiguratorInline } from "../../../configurators/ColorConfigurator/ColorConfiguratorInline"
import { useTranslate } from "../../../../../hooks/useTranslate"

export const BackgroundColourPicker = observer(() => {
  const rootStore = useContext<RootStore>(RootStoreContext)
  const { productDriver } = rootStore
  const { activeContext, productRenderPilot } = productDriver.state
  const t = useTranslate()

  if (productDriver.state.isRendererLoading) {
    return null
  }

  const backgroundColor =
    productDriver.backgroundsDriver.backgroundColor[activeContext]

  const onColorSelect = async (color: Colour | undefined) => {
    if (color) {
      await setBackgroundColor(color)
    } else {
      await removeBackground()
    }
  }

  const setBackgroundColor = async (colour: Colour) => {
    await productDriver.backgroundsDriver.paintProductEditContext(
      colour,
      activeContext
    )
  }

  const removeBackground = async () => {
    await productDriver.backgroundsDriver.paintProductEditContext(
      null,
      activeContext
    )
  }

  return (
    <ColorConfiguratorInline
      itemName={t(
        "component.editor-tool-background.title.background-fill-color"
      )}
      onColorSelect={onColorSelect}
      onBackClick={() => {}}
      selectedColor={backgroundColor}
      buttonText={t(I18N.components.editorToolText.label.hideSwatch)}
      isInline={true}
      header={I18N.components.editorToolText.label.defaultColours}
      colorsList={colorsListDemoWithBlankColor}
      isOpenOnInit={true}
    />
  )
})
