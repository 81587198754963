var _a, _b, _c;
import TISSUE_PAPER_CONSTS from "../generalConfig/consts/tissuePaperConsts";
var TISSUE_PAPER_COLORS = [
    {
        uid: 1,
        tissuePaperId: "01",
        pantoneName: "White U",
        color: "#FFFFFF",
    },
    {
        uid: 3,
        tissuePaperId: "04",
        pantoneName: "Yellow U",
        color: "#FFE800",
    },
    {
        uid: 4,
        tissuePaperId: "05",
        pantoneName: "136 U",
        color: "#FFAD4A",
    },
    {
        uid: 5,
        tissuePaperId: "07",
        pantoneName: "RED 032",
        color: "#F5333F",
    },
    {
        uid: 8,
        tissuePaperId: "11",
        pantoneName: "232 U",
        color: "#F16AB7",
    },
    {
        uid: 12,
        tissuePaperId: "16",
        pantoneName: "2147 U",
        color: "#424F84",
    },
    {
        uid: 15,
        tissuePaperId: "20",
        pantoneName: "3105 U",
        color: "#4CCEDE",
    },
    {
        uid: 18,
        tissuePaperId: "24",
        pantoneName: "2418  U",
        color: "#008759",
    },
    {
        uid: 21,
        tissuePaperId: "29",
        pantoneName: "2162 U",
        color: "#9DA5B3",
    },
    {
        uid: 22,
        tissuePaperId: "30",
        pantoneName: "2162 U",
        color: "#464C59",
    },
];
var ARTWORK_PREFERED_COLORS = [
    {
        uid: 1,
        pantoneName: "010 White",
        color: "#FFFFFF",
        description: "box-editor.pantone-picker.color-descriptions.white",
    },
    {
        uid: 2,
        pantoneName: "Yellow 012 C",
        color: "#FFD719",
        description: "box-editor.pantone-picker.color-descriptions.yellow",
    },
    {
        uid: 3,
        pantoneName: "Black C",
        color: "#222223",
        description: "box-editor.pantone-picker.color-descriptions.black",
    },
    {
        uid: 4,
        pantoneName: "Orange 021 C",
        color: "#FF4700",
        description: "box-editor.pantone-picker.color-descriptions.orange",
    },
    {
        uid: 5,
        pantoneName: "212 C",
        color: "#DB4A82",
        description: "box-editor.pantone-picker.color-descriptions.pink",
    },
    {
        uid: 6,
        pantoneName: "Red 032 C",
        color: "#D8383C",
        description: "box-editor.pantone-picker.color-descriptions.red",
    },
    {
        uid: 7,
        pantoneName: "7738 C",
        color: "#45A53F",
        description: "box-editor.pantone-picker.color-descriptions.green",
    },
    {
        uid: 8,
        pantoneName: "Process Blue C",
        color: "#0078B0",
        description: "box-editor.pantone-picker.color-descriptions.blue",
    },
    {
        uid: 9,
        pantoneName: "Violet C",
        color: "#38276D",
        description: "box-editor.pantone-picker.color-descriptions.purple",
    },
];
var PACKING_PAPER_COLORS = [
    {
        uid: 1,
        tissuePaperId: "01",
        pantoneName: "7502 U",
        color: "#C7AF87",
    },
];
var TISSUE_PAPER_PRODUCT_PREFERED_COLORS = {
    ARTWORK_PREFERED_COLORS: ARTWORK_PREFERED_COLORS,
    PAPER_COLORS: TISSUE_PAPER_COLORS,
};
var PACKING_PAPER_PRODUCT_PREFERED_COLORS = {
    ARTWORK_PREFERED_COLORS: ARTWORK_PREFERED_COLORS,
    PAPER_COLORS: PACKING_PAPER_COLORS,
};
var ProductPrederedColors = {
    COLORS_CONFIG: (_a = {
            SHARED: {
                EDITOR_DEFAULT_COLOR: {
                    DEFAULT: {
                        color: "rgb(155, 203, 235)",
                        pantoneName: "Pantone - 291 C",
                    },
                },
            }
        },
        _a[TISSUE_PAPER_CONSTS.TISSUE_PAPER_MODEL_DEFAULT] = {
            VARIANTS: (_b = {},
                _b[TISSUE_PAPER_CONSTS.TISSUE_PAPER_VARIANT_STANDARD] = {
                    PANTONE_COLORS: false,
                    LIMITED_COLORS: true,
                    LIMITED_COLORS_DESCRIPTION: true,
                },
                _b),
        },
        _a),
    COLORS_LISTS: (_c = {},
        _c[TISSUE_PAPER_CONSTS.TISSUE_PAPER_MODEL_DEFAULT] = TISSUE_PAPER_PRODUCT_PREFERED_COLORS,
        _c[TISSUE_PAPER_CONSTS.PACKING_PAPER_MODEL_DEFAULT] = PACKING_PAPER_PRODUCT_PREFERED_COLORS,
        _c),
};
export default ProductPrederedColors;
