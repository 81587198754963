var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import POLY_MAILER_CONSTS from "../../../../../source/polyMailerProductSpec/generalConfig/consts/polyMailerConsts";
import { getOldVariantCodeName } from "../../../../../definitions/products/newProductVariantsToOld";
import { ProductType } from "../../../types";
import ProductConfigFactory from "../../../ProductFactory";
import PartMaterial from "../../../../../source/polyMailerProductSpec/generalConfig/consts/polyMailerPartsDefinitions/PartMaterial";
var addPolyMailerVariantBehaviors = function (superclass) {
    function findVariant(productName) {
        if (productName === POLY_MAILER_CONSTS.POLY_MAILER_BIO) {
            return POLY_MAILER_CONSTS.POLY_MAILER_BIO_VARIANT_STANDARD;
        }
        else if (productName === POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN) {
            return POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN_VARIANT_STANDARD;
        }
        else if (productName === POLY_MAILER_CONSTS.POLY_MAILER_RECYCLED) {
            return POLY_MAILER_CONSTS.POLY_MAILER_RECYCLED_VARIANT_STANDARD;
        }
        else {
            return POLY_MAILER_CONSTS.POLY_MAILER_VARIANT_STANDARD;
        }
    }
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            return _super.call(this, props) || this;
        }
        class_1.findVariant = function (productName) {
            return findVariant(productName);
        };
        class_1.prototype.getVariant = function () {
            var productName = this.getName();
            return findVariant(productName);
        };
        class_1.prototype.getVariantStructure = function () {
            return this.variant.variants[this.getVariant()];
        };
        class_1.prototype.getVariantDashed = function () {
            return getOldVariantCodeName(this.getVariant());
        };
        class_1.prototype.getProductType = function () {
            var productName = this.getName();
            switch (productName) {
                case POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN:
                    return ProductType.Plain;
                default:
                    return ProductType.Custom;
            }
        };
        class_1.prototype.getEditorRelatedProducts = function () {
            var _this = this;
            var material = this.getMaterial();
            var isMaterialBlack = material === PartMaterial.PRINT_MATERIAL_FOIL_BLACK;
            var isPrintInsideActive = this.product.addonsManager.isPrintInsideActive();
            var colorMode = isPrintInsideActive
                ? "mono-pantone-two-sides"
                : "mono-pantone-one-side";
            return [
                this.buildSku({
                    name: "poly-mailer",
                    material: isMaterialBlack ? "foil-black" : "foil-white",
                    colorMode: colorMode,
                    finish: "none",
                }),
                this.buildSku({
                    name: "bio-poly-mailer",
                    material: "foil-white",
                    colorMode: colorMode,
                    finish: "none",
                }),
                this.buildSku({
                    name: "recycled-poly-mailer",
                    material: isMaterialBlack ? "foil-black" : "foil-white-black-inside",
                    colorMode: colorMode,
                    finish: "none",
                }),
            ].map(function (sku) { return _this.mapToEditorRelatedProduct(ProductConfigFactory(sku)); });
        };
        return class_1;
    }(superclass));
};
export default addPolyMailerVariantBehaviors;
