import _filter from "lodash/filter";
import _includes from "lodash/includes";
import PatternsDefinitions from "./PatternsDefinitions";
var PatternsProvider = /** @class */ (function () {
    function PatternsProvider() {
        this.patternsDefinitions = PatternsDefinitions;
    }
    PatternsProvider.prototype.getSegregatedPatterns = function () {
        var angled = this.getPatternsByTag("angled");
        var straight = this.getPatternsByTag("straight", "single");
        var single = this.getPatternsByTag("single");
        return {
            angled: angled,
            straight: straight,
            single: single,
        };
    };
    PatternsProvider.prototype.getPatternById = function (id) {
        var pattern = this.patternsDefinitions.find(function (definition) { return definition.name === id; });
        if (!pattern) {
            throw new Error("We cant find pattern with id ".concat(id));
        }
        return pattern;
    };
    PatternsProvider.prototype.getPatterSourceConfigById = function (id) {
        return this.getPatternById(id);
    };
    PatternsProvider.prototype.getPatternsByTag = function (tag, notIncluded) {
        return _filter(this.patternsDefinitions, function (pattern) {
            return (_includes(pattern.tags, tag) && !_includes(pattern.tags, notIncluded));
        });
    };
    return PatternsProvider;
}());
export default PatternsProvider;
