import React, { PropsWithChildren } from "react"

import DiscardChanges, { DiscardChangesProps } from "./DiscardChanges"
import { InnerSize, Modal } from "../Modal"

const DiscardChangesModal = ({
  children,
  onClose,
  onDiscard,
}: PropsWithChildren<DiscardChangesProps>) => (
  <Modal
    e2eTargetName="start-again"
    onClose={onClose}
    maxWidth={InnerSize.small}
  >
    <DiscardChanges onClose={onClose} onDiscard={onDiscard}>
      {children}
    </DiscardChanges>
  </Modal>
)

export { DiscardChangesModal as default }
