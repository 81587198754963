import React, { useEffect, useState } from "react"
import styles from "./EditorToolTextEffects.module.scss"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { RangeOption } from "../RangeOption"
import { ThumbWithText } from "../../../atoms/ThumbWithText/ThumbWithText"
import { ColorConfiguratorInline } from "../../configurators/ColorConfigurator/ColorConfiguratorInline"
import Colour from "../../../../../libs/value-objects/colour"
import { TextObjectController } from "../../../../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/text-object-controller"
import { TextEffectsController } from "../../../../../stores/_controllers/text-effects/text-effects-controller"
import _defer from "lodash/defer"
import { EffectType } from "../../../../../stores/_controllers/text-effects/types"
import { observer } from "mobx-react-lite"
import { AnalyticsController } from "../../../../../stores/_controllers/analytics-controller"

interface EditorToolTextEffectsProps {
  activeObjectController: TextObjectController
  analyticsController?: AnalyticsController
}

export const EditorToolTextEffects = observer(
  ({
    activeObjectController,
    analyticsController,
  }: EditorToolTextEffectsProps) => {
    const t = useTranslate()
    const [textEffectsController, setTextController] =
      useState<TextEffectsController>()

    useEffect(() => {
      setTextController(new TextEffectsController(activeObjectController))
    }, [])

    if (!textEffectsController) {
      return null
    }

    const effectType = textEffectsController.type
    const effectOptions = textEffectsController.options
    const uiConfig = textEffectsController.uiConfig

    const onSelectEffectType = (type: EffectType) => {
      textEffectsController.setType(type)
      analyticsController?.trackEffectClicked(type)
    }

    const onChangeStyleColor = (color: Colour) => {
      textEffectsController.apply({
        color: color,
      })
    }

    const onChangeOffsetX = (value: number) => {
      textEffectsController.apply({
        offsetX: value,
      })
    }

    const onChangeOffsetY = (value: number) => {
      textEffectsController.apply({
        offsetY: value,
      })
    }

    const onChangeThickness = (value: number) => {
      textEffectsController.apply({
        thickness: value,
      })
    }

    const onClickColorSelector = () => {
      autoscroll()
      analyticsController?.trackEffectColorSelectorClicked()
    }

    const autoscroll = () => {
      _defer(() => {
        const wrapper = document.getElementById("color-picker")
        wrapper?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        })
      })
    }

    return (
      <>
        <div className={styles.title_wrapper}>
          {t(I18N.components.editorToolText.label.effects)}
        </div>
        <div className={styles.container}>
          <ThumbWithText
            isActive={effectType === "none"}
            overlayText="Ph"
            label={t(I18N.components.editorToolText.label.none)}
            onClick={() => onSelectEffectType("none")}
          />
          <ThumbWithText
            isActive={effectType === "shadow"}
            overlayText="Ph"
            extraStyle="shadow"
            label={t(I18N.components.editorToolText.label.shadow)}
            onClick={() => onSelectEffectType("shadow")}
          />
          <ThumbWithText
            isActive={effectType === "stroke"}
            overlayText="Ph"
            extraStyle="stroke"
            label={t(I18N.components.editorToolText.label.stroke)}
            onClick={() => onSelectEffectType("stroke")}
          />
        </div>

        {uiConfig.offsetX && (
          <RangeOption
            value={effectOptions.offsetX || 0}
            label={t(I18N.components.editorToolText.label.offsetX)}
            min={uiConfig.offsetX.min}
            max={uiConfig.offsetX.max}
            onChange={onChangeOffsetX}
            step={uiConfig.offsetX.step}
          />
        )}

        {uiConfig.offsetY && (
          <RangeOption
            value={effectOptions.offsetY || 0}
            label={t(I18N.components.editorToolText.label.offsetY)}
            min={uiConfig.offsetY.min}
            max={uiConfig.offsetY.max}
            onChange={onChangeOffsetY}
            step={uiConfig.offsetY.step}
          />
        )}

        {uiConfig.thickness && (
          <RangeOption
            value={effectOptions.thickness || 0}
            label={t(I18N.components.editorToolText.label.thickness)}
            min={uiConfig.thickness.min}
            max={uiConfig.thickness.max}
            onChange={onChangeThickness}
            step={uiConfig.thickness.step}
          />
        )}

        {uiConfig.color && (
          <div className={styles.container}>
            <ColorConfiguratorInline
              itemName={t(I18N.components.editorToolText.label.selectedColour)}
              onColorSelect={onChangeStyleColor}
              onBackClick={() => {}}
              selectedColor={textEffectsController.options.color}
              buttonText={t(I18N.components.editorToolText.label.hideSwatch)}
              isInline={true}
              header={I18N.components.editorToolText.label.defaultColours}
              onClick={onClickColorSelector}
            />
          </div>
        )}
      </>
    )
  }
)
