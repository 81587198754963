import React, { useEffect, useRef, useState } from "react"

type InlineSvgWithLoaderProps = {
  src: string
  svgMountCallback: (svgElement: SVGElement) => void
}

const useSvgLoader = (src, patternRef, svgMountCallback) => {
  const [patternSvg, setPattern] = useState("")
  const [isLoading, setPatternLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (!error && !isLoading && !isLoaded) {
      setPatternLoading(true)
      setError(false)
      setIsLoaded(false)
      fetch(src)
        .then((res) => {
          if (!res.ok) {
            throw Error(res.statusText)
          }
          return res.text()
        })
        .then((text) => {
          setPattern(text)
          setIsLoaded(true)
          setPatternLoading(false)
        })
        .catch((err) => {
          setError(true)
          setIsLoaded(false)
          setPatternLoading(false)
        })
    } else if (patternRef.current) {
      svgMountCallback(patternRef.current.firstElementChild)
    }
  }, [isLoading, error, patternSvg, src, svgMountCallback])

  return {
    isLoading,
    patternSvg,
    isLoaded,
    error,
  }
}
const InlineSvgWithLoader = React.memo(
  ({ src, svgMountCallback }: InlineSvgWithLoaderProps) => {
    const patternRef = useRef<HTMLDivElement>(null)
    const { patternSvg } = useSvgLoader(src, patternRef, svgMountCallback)

    return (
      <span ref={patternRef} dangerouslySetInnerHTML={{ __html: patternSvg }} />
    )
  },
  (nextProps, prevProps) => nextProps.src !== prevProps.src
)

export { InlineSvgWithLoader, useSvgLoader }

export default InlineSvgWithLoader
