var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _each from "lodash/each";
var addGetProductEditZonesBehaviors = function (superclass) {
    var MILLIMETERS_PER_INCH = 25.4;
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        class_1.prototype.getEditZonePositionBySide = function (sideId) {
            var perSideEditZonesPosition = this.getPerSideEditZonesPosition();
            return perSideEditZonesPosition.side[sideId];
        };
        class_1.prototype.getPerSideEditZonesPosition = function () {
            var _this = this;
            var productRenderConfig = this.getRenderConfig();
            var editZonePositions = productRenderConfig.editZonePosition;
            _each(editZonePositions.side, function (editZonePosition, sideId) {
                editZonePositions.side[sideId] = __assign(__assign({}, editZonePosition), { widthCm: editZonePosition.width / 10, heightCm: editZonePosition.height / 10, widthPx: Math.ceil(editZonePosition.width * (_this.DPI / MILLIMETERS_PER_INCH)), heightPx: Math.ceil(editZonePosition.height * (_this.DPI / MILLIMETERS_PER_INCH)) });
            });
            return editZonePositions;
        };
        class_1.prototype.getMilimetersToPixelsRatio = function (productZoneCoords) {
            var productRenderConfig = this.getRenderConfig();
            return productZoneCoords.width / productRenderConfig.width;
        };
        class_1.prototype.getProductEditZones = function (sideID, productZoneCoords) {
            var editZones = {};
            var sideEditZoneMM = this.getEditZonePositionBySide(sideID);
            var ratio = this.getMilimetersToPixelsRatio(productZoneCoords);
            editZones["side"] = {
                zone: {
                    top: productZoneCoords.top + sideEditZoneMM.top * ratio,
                    left: productZoneCoords.left + sideEditZoneMM.left * ratio,
                    width: sideEditZoneMM.width * ratio,
                    height: sideEditZoneMM.height * ratio,
                },
            };
            return editZones;
        };
        return class_1;
    }(superclass));
};
export default addGetProductEditZonesBehaviors;
