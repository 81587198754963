import React, { PropsWithChildren, useState } from "react"
import cxBinder from "classnames/bind"

import styles from "./TooltipWrapper.module.scss"
const cx = cxBinder.bind(styles)

export interface TooltipWrapperProps {
  toggleElement: React.ReactNode
}

export const TooltipWrapper = ({
  children,
  toggleElement,
}: PropsWithChildren<TooltipWrapperProps>) => {
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      className={styles.wrapper}
    >
      {toggleElement}
      <div className={cx("content", { "content--opened": isOpen })}>
        <div className={styles.content__inner}>{children}</div>
      </div>
    </div>
  )
}
