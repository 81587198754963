import React from "react"
import { observer } from "mobx-react-lite"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { I18N } from "../../../../i18n"
import { useTranslate } from "../../../../hooks/useTranslate"
import {
  HorizontalTab,
  HorizontalTabs,
} from "../../../atoms/HorizontalTabs/HorizontalTabs"
import { RangeOption, ThresholdRangeOption } from "../RangeOption"
import { ThumbXL } from "../../../atoms/ThumbXL/ThumbXL"
import { ReactComponent as IconArrowBack } from "../../../../assets/svg/ArrowLeft.svg"
import {
  LayoutName,
  PatternMode,
} from "../../../../../render-engine/modules/vd-editor/modules/assets-module/pattern-replicator/types"
import { ReplicablePatternUiController } from "../../../../../stores/_controllers/replicable-pattern-ui-controller"
import { ReplicablePatternsStore } from "../../../../../stores/replicable-patterns-store/replicable-patterns.store"

import styles from "./EditorToolReplicablePatterns.module.scss"

const ORIGIN_URL = "https://editor.s3.eu-central-1.amazonaws.com/"
const layoutsThumbsFilenames: Record<LayoutName, string> = {
  "straight-grid": "straight-grid.svg",
  "straight-alternating": "straight-alternating.svg",
  "angled-grid": "angled-grid.svg",
  "angled-alternating": "angled-alternating.svg",
}
const getLayoutThumbnailUrl = (mode: PatternMode, name: LayoutName) => {
  const modePath = mode === "image" ? "image" : "text"
  return `${ORIGIN_URL}replicable-patterns/layouts-thumbs/${modePath}/${layoutsThumbsFilenames[name]}`
}

interface EditorToolReplicablePatternsDetailsProps {
  onBackClick: (x: boolean) => void
  replicablePatternUiController: ReplicablePatternUiController
  replicablePatternsStore: ReplicablePatternsStore
}

export const EditorToolReplicablePatternsDetails = observer(
  ({
    onBackClick,
    replicablePatternUiController,
    replicablePatternsStore,
  }: EditorToolReplicablePatternsDetailsProps) => {
    const t = useTranslate()
    const activeTab = replicablePatternsStore.isSelectedLayoutAngled
      ? "angled"
      : "straight"

    const selectableLayoutNames =
      replicablePatternUiController.getSelectableLayoutNames(activeTab)

    const onStraightTabClick = () => {
      if (activeTab === "straight") return
      replicablePatternUiController.selectLayout("straight-grid")
    }

    const onAngledTabClick = () => {
      if (activeTab === "angled") return
      replicablePatternUiController.selectLayout("angled-grid")
    }

    const onChangeArtworkSize = (scale: number) => {
      replicablePatternUiController.setArtworkScale(scale)
    }

    const onChangeArtworkThreshold = (threshold: number) => {
      replicablePatternUiController.setArtworkThreshold(threshold)
    }

    return (
      <>
        <Typography
          type={TType.Header15_500}
          onClick={(e) => {
            e.preventDefault()
            onBackClick(false)
          }}
          className={styles.header}
        >
          <IconArrowBack className={styles.arrow} />
          {t(I18N.editorTools.patterns.backToPatterns)}
        </Typography>

        <HorizontalTabs>
          <HorizontalTab
            onClick={onStraightTabClick}
            active={activeTab === "straight"}
          >
            {t(I18N.editorTools.patterns.straight)}
          </HorizontalTab>
          <HorizontalTab
            onClick={onAngledTabClick}
            active={activeTab === "angled"}
          >
            {t(I18N.editorTools.patterns.angled)}
          </HorizontalTab>
        </HorizontalTabs>

        <div className={styles.sliders}>
          <RangeOption
            value={replicablePatternsStore.artworkScale}
            label={t(I18N.editorTools.patterns.artworkSize)}
            min={replicablePatternsStore.minArtworkScale}
            max={replicablePatternsStore.maxArtworkScale}
            step={replicablePatternsStore.artworkScaleStep}
            onChange={(value) => onChangeArtworkSize(value)}
            isPreviewDisabled
          />
          {replicablePatternUiController.isHorizontalSpacingRangeVisible() && (
            <RangeOption
              value={replicablePatternsStore.horizontalSpacing}
              label={t(I18N.editorTools.patterns.horizontalSpacing)}
              min={replicablePatternsStore.minHorizontalSpacing}
              max={replicablePatternsStore.maxHorizontalSpacing}
              step={1}
              onChange={(value) =>
                replicablePatternUiController.setHorizontalSpacing(value)
              }
              isPreviewDisabled
            />
          )}
          {replicablePatternUiController.isVerticalSpacingRangeVisible() && (
            <RangeOption
              value={replicablePatternsStore.verticalSpacing}
              label={t(I18N.editorTools.patterns.verticalSpacing)}
              min={replicablePatternsStore.minVerticalSpacing}
              max={replicablePatternsStore.maxVerticalSpacing}
              step={1}
              onChange={(value) =>
                replicablePatternUiController.setVerticalSpacing(value)
              }
              isPreviewDisabled
            />
          )}
          {replicablePatternsStore.isThresholdAvailable && (
            <>
              <hr />
              <ThresholdRangeOption
                value={replicablePatternsStore.imageThreshold}
                label={t(I18N.editorTools.patterns.artworkThreshold)}
                onChange={(value) => onChangeArtworkThreshold(value)}
                isPreviewDisabled
              />
            </>
          )}
        </div>

        {selectableLayoutNames.length > 1 && (
          <>
            <Typography type={TType.Header17_500}>
              {t(I18N.editorTools.patterns.layoutOptions)}
            </Typography>

            <div className={styles.gallery}>
              {selectableLayoutNames.map((name) => {
                return (
                  <ThumbXL
                    isSelected={
                      name === replicablePatternsStore.selectedLayoutName
                    }
                    imageURL={getLayoutThumbnailUrl(
                      replicablePatternsStore.selectedMode,
                      name
                    )}
                    label={null}
                    onClick={() =>
                      replicablePatternUiController.selectLayout(name)
                    }
                    key={name}
                  />
                )
              })}
            </div>
          </>
        )}
      </>
    )
  }
)
