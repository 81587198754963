import { observer } from "mobx-react-lite"
import { ThumbXL } from "../../../../atoms/ThumbXL/ThumbXL"
import React, { useState } from "react"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { ReactComponent as IconPlus } from "../../../../../assets/svg/plus-circle.svg"
import { IconTextTile } from "../../../../molecues/IconTextTile/IconTextTile"
import { EditorToolList } from "../../EditorToolList"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import styles from "../../../configurators/ColorConfigurator/ColorConfiguratorInline.module.scss"
import { AssetsUploaderFileSpec } from "../../../Upload/UploadFiletypesInfo"
import { UploadDropZone } from "../../../Upload/UploadDropzone"
import { useDropzone } from "react-dropzone"
import { UploadFailedFilePreview } from "../../../Upload/UploadFailedFilePreview"
import { getTranslationKeyForAssetError } from "../../../../../../services/asset-service/asset-errors"
import {
  Utilities,
  UtilityItem,
} from "../../../../molecues/Utilities/Utilities"
import { ReactComponent as IconTrash } from "../../../../../assets/svg/trash.svg"
import { ReactComponent as UploadFailedIcon } from "../../../../../assets/svg/warning.svg"
import { CardNotification } from "../../../../atoms/card-notification/card-notification"
import { FormattedMessage } from "react-intl"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { DesignAndProductDriverContainer } from "../../../../../../_containers/design-and-product-driver-container"
import { BackgroundImageContainer } from "../../../../../../_containers/background-image-container"

const i18n = {
  title: "component.editor-tool-background.title.background-image",
  noImageLabel: "component.editor-tool-background.background-image.no-image",
  addImage: "component.editor-tool-background.background-image.add-image",
  addNewImage:
    "component.editor-tool-background.background-image.add-new-image",
  uploading: "editor.upload.uploading",
  remove: {
    file: "component.editor-tool.assets-uploader.remove-file",
    outside:
      "component.editor-tool.assets-uploader.remove-background-image-outside",
    inside:
      "component.editor-tool.assets-uploader.remove-background-image-inside",
    front:
      "component.editor-tool.assets-uploader.remove-background-image-front",
    back: "component.editor-tool.assets-uploader.remove-background-image-back",
  },
}

const BackgroundImageOverscaledError = () => {
  return (
    <CardNotification Icon={UploadFailedIcon} type="error">
      <Typography type={TType.Body13_350} htmlElement="p">
        <FormattedMessage id="component.notification.low-quality-selected" />
      </Typography>
      <Typography type={TType.Body13_350} htmlElement="p">
        <FormattedMessage id="editor-tools.personalize.uploaded-file-spec" />
      </Typography>
    </CardNotification>
  )
}
export const BackgroundImagePickerWired = observer(() => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.backgroundImage,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, backgroundImage } = containerSet

  return (
    <BackgroundImagePicker
      designAndProductDriver={designAndProductDriver}
      backgroundImageContainer={backgroundImage}
    />
  )
})

export const BackgroundImagePicker = observer(
  ({
    designAndProductDriver,
    backgroundImageContainer,
  }: {
    designAndProductDriver: DesignAndProductDriverContainer
    backgroundImageContainer: BackgroundImageContainer
  }) => {
    const [isOpen, setIsOpen] = useState(true)

    const t = useTranslate()

    const { productDriver, assetStore } = designAndProductDriver
    const { backgroundImageUiController, backgroundImageStore } =
      backgroundImageContainer

    const { activeContext } = productDriver.state
    const { backgroundsDriver } = productDriver
    const backgroundImage = backgroundsDriver.backgroundImage[activeContext]
    const backgroundImageAsset =
      backgroundImageUiController.getBackgroundImageAssetStore(activeContext)

    const { getRootProps, getInputProps } = useDropzone({
      onDrop: async (acceptedFiles: File[]) => {
        await backgroundImageUiController.uploadAndApplyBackgroundImage(
          activeContext,
          acceptedFiles[0]
        )
      },
      multiple: false,
    })

    const successAssets = assetStore.successAssets.filter(
      (asset) => !asset.isUploading && asset.isInSection("background-image")
    )
    const failedAssets = assetStore.failedAssets.filter((asset) =>
      asset.isInSection("background-image")
    )

    return (
      <div>
        <Typography
          type={TType.Header17_500}
          className={styles.item_name}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          {t(i18n.title)}
        </Typography>
        {isOpen && (
          <>
            <EditorToolList>
              <ThumbXL
                isPlaceholderActive={true}
                isSelected={!backgroundImage}
                label={t(i18n.noImageLabel)}
                onClick={async () =>
                  await backgroundImageUiController.removeBackgroundImage(
                    activeContext
                  )
                }
                e2eTarget="background-image"
                e2eTargetName="remove-background-image-button"
              />

              <UploadDropZone
                inputProps={getInputProps()}
                rootProps={getRootProps()}
              >
                <IconTextTile
                  key="add-text-button"
                  onClick={() => {}}
                  Icon={IconPlus}
                  e2eTarget="background-image"
                  e2eTargetName="upload-background-image-button"
                  isLoading={backgroundImageStore.isUploading}
                  loadingText={t(i18n.uploading)}
                >
                  {t(
                    successAssets.length > 0 ? i18n.addImage : i18n.addNewImage
                  )}
                </IconTextTile>
              </UploadDropZone>

              {successAssets.map((asset, index) => {
                const isAssetSelected = asset === backgroundImageAsset

                return (
                  <ThumbXL
                    key={index}
                    isSelected={isAssetSelected}
                    isError={isAssetSelected && backgroundImage.isOverscaled}
                    label={asset.name}
                    imageURL={asset.previewUri}
                    onClick={() =>
                      backgroundImageUiController.applyBackgroundImage(
                        activeContext,
                        asset
                      )
                    }
                    e2eTarget="background-image"
                    e2eTargetName="select-background-image-button"
                    tooltipIcon={IconTrash}
                    tooltipItem={
                      <Utilities size="auto">
                        <UtilityItem
                          name={t(i18n.remove.file)}
                          onClick={async () => {
                            await backgroundImageUiController.removeBackgroundImagesByImageAssetStore(
                              asset
                            )

                            assetStore.removeAsset(asset)
                          }}
                        />
                        {isAssetSelected && (
                          <UtilityItem
                            name={t(i18n.remove[activeContext])}
                            onClick={async () => {
                              await backgroundImageUiController.removeBackgroundImage(
                                activeContext
                              )
                            }}
                          />
                        )}
                      </Utilities>
                    }
                  />
                )
              })}
            </EditorToolList>

            {failedAssets.map((asset, key) => {
              return (
                <UploadFailedFilePreview
                  name={asset.name}
                  error={t(getTranslationKeyForAssetError(asset.error!))}
                  onRemove={() => {
                    assetStore.removeAsset(asset)
                  }}
                  key={key}
                />
              )
            })}

            {backgroundImage?.isOverscaled ? (
              <BackgroundImageOverscaledError />
            ) : (
              <AssetsUploaderFileSpec />
            )}
          </>
        )}
      </div>
    )
  }
)
