var _a;
import POLY_MAILER_CONSTS from "../generalConfig/consts/polyMailerConsts";
var PolyMailerBioRenderConfigObject = {
    Y60: {
        width: 250,
        height: 350,
        length: 10,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        thumbnailCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 107,
                    left: 25,
                    width: 200,
                    height: 200,
                },
                back: {
                    top: 107,
                    left: 25,
                    width: 200,
                    height: 200,
                },
            },
        },
    },
    Y80: {
        width: 350,
        height: 450,
        length: 10,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        thumbnailCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 127,
                    left: 45,
                    width: 260,
                    height: 260,
                },
                back: {
                    top: 127,
                    left: 45,
                    width: 260,
                    height: 260,
                },
            },
        },
    },
    Y90: {
        width: 400,
        height: 500,
        length: 10,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        thumbnailCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 132,
                    left: 55,
                    width: 290,
                    height: 290,
                },
                back: {
                    top: 132,
                    left: 55,
                    width: 290,
                    height: 290,
                },
            },
        },
    },
};
var PolyMailerRenderConfigObject = {
    Y43: {
        width: 175,
        height: 255,
        length: 10,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        thumbnailCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 62,
                    left: 22.5,
                    width: 130,
                    height: 130,
                },
                back: {
                    top: 62,
                    left: 22.5,
                    width: 130,
                    height: 130,
                },
            },
        },
    },
    Y60: {
        width: 250,
        height: 350,
        length: 10,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        thumbnailCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 75,
                    left: 25,
                    width: 200,
                    height: 200,
                },
                back: {
                    top: 75,
                    left: 25,
                    width: 200,
                    height: 200,
                },
            },
        },
    },
    Y80: {
        width: 350,
        height: 450,
        length: 10,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        thumbnailCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 95,
                    left: 45,
                    width: 260,
                    height: 260,
                },
                back: {
                    top: 95,
                    left: 45,
                    width: 260,
                    height: 260,
                },
            },
        },
    },
    Y90: {
        width: 400,
        height: 500,
        length: 10,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        thumbnailCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 105,
                    left: 55,
                    width: 290,
                    height: 290,
                },
                back: {
                    top: 105,
                    left: 55,
                    width: 290,
                    height: 290,
                },
            },
        },
    },
    Y100: {
        width: 450,
        height: 550,
        length: 10,
        foldLineWidth: 0,
        sideFoldWidth: 0,
        thumbnailCameraZoom: 1,
        editZonePosition: {
            side: {
                front: {
                    top: 125,
                    left: 75,
                    width: 300,
                    height: 300,
                },
                back: {
                    top: 125,
                    left: 75,
                    width: 300,
                    height: 300,
                },
            },
        },
    },
};
var POLYMAILER_RENDER_CONFIG = (_a = {},
    _a[POLY_MAILER_CONSTS.POLY_MAILER] = PolyMailerRenderConfigObject,
    _a[POLY_MAILER_CONSTS.POLY_MAILER_RECYCLED] = PolyMailerRenderConfigObject,
    _a[POLY_MAILER_CONSTS.POLY_MAILER_BIO] = PolyMailerBioRenderConfigObject,
    _a[POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN] = PolyMailerBioRenderConfigObject,
    _a);
export default POLYMAILER_RENDER_CONFIG;
