import VirtualDielineEditor from "../../../../virtual-dieline-editor"
import {
  isAssetGroup,
  isAssetImage,
  isAssetLogoPlaceholderSlot,
  isBackgroundImage,
  isBackgroundTexture,
  isEditableObject,
  isPattern,
  VirtualDielineTemplateDataLessObject,
} from "../../../../../../../modules/ph-api/asset-types"
import { JsonToFabricTransformer } from "../../transformer/json-to-fabric-transformer"
import { VirtualDielineFilterer } from "../../../../services/virtual-dieline-filterer"
import { TemplateResizer } from "./template-resizer"
import {
  PackhelpEditableGroup,
  PackhelpEditableObject,
  PackhelpObject,
} from "../../../../object-extensions/packhelp-objects"
import { EditableGroupController } from "../../editable-group-controller/editable-group-controller"
import { LogoPlaceholderSlotObjectController } from "../../canvas-object-controller/logo-placeholder-slot-object-controller"
import { RasterizedSvgImageToSvgObjectTransformer } from "../../transformer/rasterized-svg-image-to-svg-object-transformer"
import { SpaceClippingHelper } from "../../helpers/space-clipping-helper"
import { ClipPathModes } from "../../../../services/clip-path-generator"
import { AssetColorController } from "../../asset-color-controller"
import { TemplateBackgroundLoader } from "./template-background-loader"
import { TemplateBackgroundImageLoader } from "./template-background-image-loader"
import { TemplateLoaderOptions } from "./types"
import { TemplateMaskLoader } from "./template-mask-loader"
import { DesignVersion } from "../../../../../../../modules/design/version"
import { VirtualDielineMigrator } from "../../../../services/virtual-dieline-migrator"
import { Pattern } from "../../../../../../../libs/value-objects/pattern"
import { ModelEditableSpaces } from "../../../../../../../libs/products-render-config/types"

export class TemplateVdLoader {
  private readonly templateResizer: TemplateResizer

  constructor(
    private readonly vdEditor: VirtualDielineEditor,
    private readonly vdTemplateData: VirtualDielineTemplateDataLessObject,
    private readonly designDataFormatVersion: DesignVersion,
    private readonly options: TemplateLoaderOptions = {}
  ) {
    this.templateResizer = new TemplateResizer(
      this.vdEditor,
      this.vdTemplateData.spaces
    )
  }

  public async load(templateId?: number) {
    const templateObjects =
      await JsonToFabricTransformer.transform<PackhelpEditableObject>(
        VirtualDielineFilterer.filterForImport(this.vdTemplateData.objects)
      )
    const editableSpaces = this.vdEditor.productRenderPilot
      .getContextEditableSpaces(this.vdEditor.editContext)
      .map((space) => space.spaceId)

    for (const object of templateObjects) {
      const spaceId = object.originSpaceArea as ModelEditableSpaces

      if (spaceId && !editableSpaces.includes(spaceId)) {
        continue
      }

      if (isBackgroundTexture(object)) {
        await this.loadBackground(object)
      }

      if (isBackgroundImage(object)) {
        await this.loadBackgroundImage(object)
      }

      if (isPattern(object)) {
        await this.loadPattern(object)
      }

      if (this.options.withTransformations) {
        await this.transform(object)
      }

      if (isEditableObject(object)) {
        this.templateResizer.resize(object)
        await this.addOnCanvas(object)
      }

      if (
        templateId &&
        (isEditableObject(object) || isBackgroundImage(object))
      ) {
        object.templateId = templateId
      }
    }

    await this.migrate()
    await this.loadControllers()
    await this.loadMasks()
    this.adjustToCurrentColorMode()
    this.vdEditor.sortObjectsOnCanvas()
  }

  private async transform(
    object: PackhelpEditableObject
  ): Promise<PackhelpEditableObject> {
    const imageTransformer = new RasterizedSvgImageToSvgObjectTransformer(
      this.vdEditor
    )

    if (isAssetImage(object)) {
      return await imageTransformer.transform(object)
    }

    if (isAssetGroup(object)) {
      return await imageTransformer.transformGroup(object)
    }

    return object
  }

  private async addOnCanvas(object: PackhelpEditableObject) {
    this.vdEditor.fabricCanvas.add(object)

    await SpaceClippingHelper.setSpaceClipping(
      this.vdEditor,
      object.originSpaceArea,
      object,
      ClipPathModes.FillMode
    )
  }

  private adjustToCurrentColorMode() {
    const colorController = new AssetColorController(this.vdEditor)
    colorController.resetDielineColorableObjectsColorToDefaultIfNeeded()
  }

  private async loadBackground(templateBackground: PackhelpEditableGroup) {
    const templateBackgroundLoader = new TemplateBackgroundLoader(
      this.vdEditor,
      this.vdEditor.productRenderPilot
    )
    await templateBackgroundLoader.load(templateBackground)
  }

  private async loadBackgroundImage(backgroundImage: PackhelpObject) {
    const templateBackgroundImageLoader = new TemplateBackgroundImageLoader(
      this.vdEditor
    )
    await templateBackgroundImageLoader.load(backgroundImage)
  }

  private async loadPattern(templatePattern: PackhelpObject) {
    const pattern = await this.vdEditor.backgroundsModule.createPattern(
      new Pattern(templatePattern.patternSourceConfig!).cloneWithColourPreset(
        this.vdEditor.productRenderPilot.getColoursPreset()
      )
    )
    pattern.clipPath = templatePattern.clipPath
    await this.vdEditor.backgroundsModule.applyPattern(pattern)
  }

  private async loadControllers() {
    for (const object of this.vdEditor.assetsModule.getEditableObjects()) {
      if (isAssetGroup(object)) {
        const groupController = new EditableGroupController(this.vdEditor)
        await groupController.load(object)
      }

      if (isAssetLogoPlaceholderSlot(object)) {
        const controller = new LogoPlaceholderSlotObjectController(
          object,
          this.vdEditor
        )
        await controller.setSpaceClipping()
        controller.attachEventListeners()
      }
    }
  }

  private async loadMasks() {
    const templateMaskLoader = new TemplateMaskLoader(
      this.vdEditor,
      this.vdTemplateData
    )
    await templateMaskLoader.load()
  }

  private async migrate() {
    const vdMigrator = new VirtualDielineMigrator(
      this.vdEditor,
      this.designDataFormatVersion
    )
    await vdMigrator.migrate()
  }
}
