import React from "react"
import { FormattedCurrency } from "../formatted-currency/FormattedCurrency"
import cxBinder from "classnames/bind"
import styles from "./PaymentAmountPresenter.module.scss"
import { useTranslate } from "../i18n/useTranslate"

const cx = cxBinder.bind(styles)

export type PaymentAmountPresenterProps = {
  currency: string
  amount: number
  customOfferPromoAmount?: number
  labelId: string
  small?: boolean
}

export const PaymentAmountPresenter = (props: PaymentAmountPresenterProps) => {
  const { currency, amount, customOfferPromoAmount, labelId, small } = props
  const finalCustomOfferAmount = amount - customOfferPromoAmount

  const t = useTranslate()

  return (
    <div className={cx("wrapper", { small })} e2e-target="payment-summary">
      <p>{t(labelId)}</p>
      <p e2e-target="payment-amount">
        {customOfferPromoAmount && amount > 0 ? (
          <>
            <div>
              <s>
                <FormattedCurrency value={amount} currency={currency} />
              </s>
            </div>
            <div>
              <FormattedCurrency
                value={finalCustomOfferAmount > 0 ? finalCustomOfferAmount : 1}
                currency={currency}
              />
            </div>
          </>
        ) : (
          <FormattedCurrency value={amount} currency={currency} />
        )}
      </p>
    </div>
  )
}
