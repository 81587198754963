import React from "react"
import { EditorHeaderMaskToolbar } from "../../../dsl/organisms/tools/EditorToolMask"
import styles from "./UniversalHeader.module.scss"
import { EditorHeaderObjectsToolbar } from "../../../dsl/organisms/EditorHeader/EditorHeaderToolbar/EditorHeaderToolbar"
import { EditorHeaderContextToolbar } from "./EditorHeaderContextToolbar"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

export const EditorHeaderToolbars = observer(() => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.assets,
    c.analytics,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, assets, analytics } = containerSet
  const { productDriver } = designAndProductDriver
  const { activeObjectDriver, state } = productDriver
  const { activeObjectType } = activeObjectDriver
  const { activeSpace } = state
  const { actionTracker } = analytics

  const showActiveObjectToolbar = !!activeSpace && !!activeObjectType

  return (
    <>
      <div className={styles.editor_toolbar_header_wrapper}>
        {showActiveObjectToolbar && (
          <EditorHeaderMaskToolbar
            designAndProductDriver={designAndProductDriver}
            assets={assets}
          />
        )}
        <EditorHeaderContextToolbar />
      </div>

      {showActiveObjectToolbar && (
        <EditorHeaderObjectsToolbar
          productDriver={productDriver}
          actionTracker={actionTracker}
        />
      )}
    </>
  )
})
