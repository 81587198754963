import fabric from "../../../../libs/vendors/Fabric"
import { PatternSourceObject } from "../../../../libs/value-objects/pattern"
import { PackhelpObject } from "./packhelp-objects"
import { ClipPathModes } from "../services/clip-path-generator"
import { Fonts } from "../../../../libs/services/fonts-loader-service/fonts-loader.config"

class PackhelpPatternContainer extends fabric.Rect implements PackhelpObject {
  public id: string
  public packhelpIndetifier: string
  public originSpaceArea: string
  public indexConfig
  public patternSizePx: number
  public patternSourceConfig: PatternSourceObject
  public rotation: number
  public clipMode: ClipPathModes
  public alphaModeClipping: boolean
  public fontFamily: Fonts
  public isEditable: boolean

  constructor(options) {
    super(options)
    this.id = options.id
    this.fontFamily = options.fontFamily
    this.packhelpIndetifier = "PackhelpPatternContainer"
    this.patternSourceConfig = options.patternSourceConfig
    this.patternSizePx = options.patternSizePx
    this.indexConfig = options.indexConfig
    this.originSpaceArea = options.originSpaceArea
    this.rotation = options.rotation
    this.clipMode = options.clipMode
    this.isEditable = options.isEditable
    this.alphaModeClipping = options.alphaModeClipping
  }

  getPatternSourceConfig() {
    return this.patternSourceConfig
  }
}

//@ts-ignore
fabric.PackhelpImage = fabric.util.createClass(fabric.Image, {
  type: "packhelp-image",
  async: true,
  initialize: function (imgObject, options) {
    options = options || {}
    this.id = options.id
    this.maxWidth = options.maxWidth
    this.callSuper("initialize", imgObject, options)
  },
  isBackgroundRemoved: function () {
    const filter = this.filters.find((filter) => filter.type === "RemoveColor")

    if (!filter) {
      return false
    }

    return filter.distance > 0
  },
  getImageThreshold: function () {
    const filter = this.filters.find((filter) => filter.type === "RemoveColor")

    if (!filter) {
      return 1
    }

    return filter.distance
  },
  isImageInverted: function () {
    if (typeof this.filters === "undefined") {
      return false
    }

    const filter = this.filters.find((filter) => filter.type === "Invert")
    return !!filter
  },
})

//@ts-ignore
fabric.PackhelpImage.fromObject = function (_object, callback) {
  const object = fabric.util.object.clone(_object)

  fabric.util.loadImage(
    object.src,
    //@ts-ignore
    function (img, isError) {
      if (isError) {
        callback && callback(null, true)
        return
      }
      //@ts-ignore
      fabric.Image.prototype._initFilters.call(
        object,
        object.filters,
        function (filters) {
          object.filters = filters || []
          //@ts-ignore
          fabric.Image.prototype._initFilters.call(
            object,
            [object.resizeFilter],
            function (resizeFilters) {
              object.resizeFilter = resizeFilters[0]

              //@ts-ignore
              fabric.util.enlivenObjectEnlivables(object, object, function () {
                //@ts-ignore
                const image = new fabric.PackhelpImage(img, object)
                callback(image, false)
              })
            }
          )
        }
      )
    },
    null,
    "Anonymous"
  )
}

//@ts-ignore
fabric.PackhelpImage.fromURL = function (url, callback, options) {
  fabric.util.loadImage(
    url,
    function (img) {
      //@ts-ignore
      callback(new fabric.PackhelpImage(img, options))
    },
    null,
    "Anonymous"
  )
}

export { PackhelpPatternContainer }
