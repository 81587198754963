import React from "react"
import { observer } from "mobx-react-lite"

import { EditContext } from "../../../../../libs/products-render-config/types"
import {
  HorizontalTab,
  HorizontalTabs,
} from "../../../atoms/HorizontalTabs/HorizontalTabs"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"

import { CardTool } from "../../../atoms/CardTool/CardTool"
import { BackgroundColourPicker } from "./BackgroundColourPicker/BackgroundColourPicker"

import styles from "./editor-tool-background.module.scss"
import { ContextController } from "../../../../../stores/_controllers/context-controller"
import { SwitchablePrintInsideBox } from "./PrintInsideBox/PrintInsideBox"
import { BackgroundImagePickerWired } from "./background-image-picker/background-image-picker"
import { PackhelpObject } from "../../../../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { InfoBox } from "../../../atoms/InfoBox/InfoBox"
import { useContainer } from "../../../../../_containers-react/_editor-app-hooks"

const i18n = I18N.component.cameraControlsToolbar

export const ThreeDimensionalProductEditContextSwitcher = observer(
  ({
    activeEditContext,
    contextController,
    backgroundImage,
    isDbyMode,
  }: {
    activeEditContext: EditContext
    contextController: ContextController
    backgroundImage: { [key in EditContext]?: PackhelpObject | null }
    isDbyMode?: boolean
  }) => {
    const t = useTranslate()

    const isPrintInsideTogglerAvailable =
      activeEditContext === EditContext.INSIDE

    if (isDbyMode)
      return (
        <>
          <InfoBox text={t(I18N.component.dbyUploader.configurationWarning)} />
          <SwitchablePrintInsideBox />
        </>
      )

    return (
      <div>
        <HorizontalTabs>
          <HorizontalTab
            active={activeEditContext === EditContext.OUTSIDE}
            onClick={() =>
              contextController.changeEditContext(EditContext.OUTSIDE)
            }
            e2eTarget="tab-context-switcher"
            e2eTargetName={EditContext.OUTSIDE}
            hasError={backgroundImage[EditContext.OUTSIDE]?.isOverscaled}
          >
            {t(i18n.outside)}
          </HorizontalTab>
          <HorizontalTab
            active={activeEditContext === EditContext.INSIDE}
            onClick={() =>
              contextController.changeEditContext(EditContext.INSIDE)
            }
            e2eTarget="tab-context-switcher"
            e2eTargetName={EditContext.INSIDE}
            hasError={backgroundImage[EditContext.INSIDE]?.isOverscaled}
          >
            {t(i18n.inside)}
          </HorizontalTab>
        </HorizontalTabs>
        {isPrintInsideTogglerAvailable && <SwitchablePrintInsideBox />}
      </div>
    )
  }
)

export const EditorToolBackground = observer(() => {
  const [designAndProductDriverContainer] =
    useContainer().designAndProductDriver

  if (!designAndProductDriverContainer) {
    return null
  }
  const { productDriver, contextController } = designAndProductDriverContainer
  const { activeContext, productRenderPilot } = productDriver.state
  const { backgroundImage } = productDriver.backgroundsDriver
  const isDbyMode = productRenderPilot.isDbyMode()

  const isContextSwitcherAvailable =
    productRenderPilot.isPrintAvailableFor(EditContext.INSIDE) ||
    (isDbyMode && productRenderPilot.isPrintAvailableFor(EditContext.BACK))

  const isPrintActive = productRenderPilot.isPrintActiveFor(activeContext)

  return (
    <CardTool e2eTargetName="background" cardName="background">
      <div className={styles.wrapper}>
        {isContextSwitcherAvailable && (
          <ThreeDimensionalProductEditContextSwitcher
            activeEditContext={activeContext}
            contextController={contextController}
            backgroundImage={backgroundImage}
            isDbyMode={isDbyMode}
          />
        )}

        {isPrintActive && !isDbyMode && (
          <>
            {productRenderPilot.uiConfig.features.backgroundImage && (
              <BackgroundImagePickerWired />
            )}

            {productRenderPilot.uiConfig.features.backgroundColor && (
              <BackgroundColourPicker />
            )}
          </>
        )}
      </div>
    </CardTool>
  )
})
