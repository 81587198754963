import type { DesignAndProductDriverContainer } from "./design-and-product-driver-container"
import type { UtilEnvContainer } from "./util-container"
import type { AnalyticsController } from "../stores/_controllers/analytics-controller"
import { EcommerceMaybeContainer } from "./ecommerce-maybe-container"
import type { SegmentErrorNotificator } from "shared-libs/src/js/libs/analytics/segment-error-notificator"
import { ApiSessionContainer } from "./api-session-container"
import { UiActionTracker } from "../stores/_controllers/ui-action-tracker-controller"

async function importDeps() {
  const { AnalyticsController } = await import(
    "../stores/_controllers/analytics-controller"
  )
  const { ProjectNames, SegmentErrorNotificator } = await import(
    "shared-libs/src/js/libs/analytics/segment-error-notificator"
  )
  const { getSegmentTrack } = await import("../libs/analytics/segment-track")

  return {
    AnalyticsController,
    ProjectNames,
    SegmentErrorNotificator,
    getSegmentTrack,
  }
}

export async function provideAnalyticsMaybeContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  designAndProduct: DesignAndProductDriverContainer,
  ecommerceContainer: EcommerceMaybeContainer
): Promise<AnalyticsMaybeContainer> {
  const { sessionStore } = apiSessionContainer
  const { ee, appConfig } = utilEnvContainer
  const { productDesignStore, productDriver } = designAndProduct
  const { productPricingStore, cartStore } = ecommerceContainer

  if (
    !appConfig.integrations.segment ||
    productDriver.state.productRenderPilot.getEditorMode() === "designer"
  ) {
    return {
      available: false,
      analyticsController: undefined,
      segmentErrorNotificator: undefined,
      actionTracker: undefined,
    }
  }

  const {
    AnalyticsController,
    ProjectNames,
    SegmentErrorNotificator,
    getSegmentTrack,
  } = await importDeps()

  const segmentTrack = getSegmentTrack(productDriver, productDesignStore)

  const analyticsController = new AnalyticsController(
    segmentTrack,
    appConfig,
    ee,
    productDriver.productStore,
    productDesignStore,
    sessionStore,
    productPricingStore,
    cartStore
  )

  let segmentErrorNotificator

  if (window) {
    segmentErrorNotificator = new SegmentErrorNotificator(
      ProjectNames.EDITOR,
      window,
      segmentTrack
    )
  }

  const actionTracker = new UiActionTracker(ee)

  return {
    available: true,
    analyticsController,
    segmentErrorNotificator,
    actionTracker,
  }
}

export type AnalyticsMaybeContainer =
  | {
      available: true
      analyticsController: AnalyticsController
      segmentErrorNotificator?: SegmentErrorNotificator
      actionTracker: UiActionTracker
    }
  | {
      available: false
      analyticsController: undefined
      segmentErrorNotificator: undefined
      actionTracker: undefined
    }
