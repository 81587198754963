var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addPreferedColorsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.preferredColors =
                product.config.userInterfaceConfig.productPreferredColors;
            return _this;
        }
        class_1.prototype.getPreferredColors = function () {
            var currentVariant = this.product.variantManager.getVariant();
            return this.preferredColors.COLORS_LISTS[currentVariant].PAPER_COLORS;
        };
        class_1.prototype.getProductCustomColorPickerConfig = function () {
            var currentVariant = this.product.variantManager.getVariant();
            var colors = this.preferredColors.COLORS_LISTS[currentVariant]
                .ARTWORK_PREFERED_COLORS;
            return {
                showLimitedColorsDescription: false,
                productPreferedColors: colors,
            };
        };
        class_1.prototype.shouldShowLimitedColorsDescription = function () {
            return false;
        };
        return class_1;
    }(superclass));
};
export default addPreferedColorsBehaviors;
