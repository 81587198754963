import React, { useLayoutEffect } from "react"

import { isViewportTooSmall } from "./utils/proscenium.helpers" // TODO: remove once tablets are ready
import { Unauthorized, ScreenSizeNotSupported } from "./views"
import { Orchestra } from "../orchestra/Orchestra"
import { useWindowSize } from "../../hooks/useWindowSize"
import { UrlManipulatorProvider } from "../../../services/url-manipulator"
import ProductDriver from "../../../stores/product-driver/product.driver"
import { EcommerceMaybeContainer } from "../../../_containers/ecommerce-maybe-container"

interface ProsceniumProps {
  is404: boolean
  isUnauthorized: boolean
  ecommerce: EcommerceMaybeContainer
  productDriver: ProductDriver
  uri: UrlManipulatorProvider
}

export const Proscenium = (props: ProsceniumProps) => {
  const { is404, isUnauthorized, uri, productDriver, ecommerce } = props
  const windowSize = useWindowSize()
  const isTooSmall =
    windowSize.width &&
    windowSize.height &&
    isViewportTooSmall(windowSize.width, windowSize.height)
  const isDtpPreviewMode = uri.isDtpPreviewMode()
  const shouldRenderPlaceholder = !!(
    isTooSmall &&
    !isDtpPreviewMode &&
    !ecommerce?.cartStore?.isInstantPurchase
  )

  useLayoutEffect(() => {
    if (shouldRenderPlaceholder) {
      productDriver.onDeregisterMountPoints()
    }
  }, [shouldRenderPlaceholder])

  if (isUnauthorized || is404) {
    return <Unauthorized />
  } else if (shouldRenderPlaceholder) {
    return <ScreenSizeNotSupported />
  } else {
    return <Orchestra />
  }
}
