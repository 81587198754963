export const initialState = {
  isShopOpened: false,
  isUserAreaOpened: false,
  isWalletOpened: false,
}

export interface StateType {
  isShopOpened: boolean
  isUserAreaOpened: boolean
  isWalletOpened: boolean
}

export enum DROPDOWN_ACTION {
  TOGGLE_SHOP = "toggleShop",
  TOGGLE_USER_AREA = "toggleUserArea",
  TOGGLE_WALLET = "toggleWallet",
  TOGGLE_ALL = "toggleAll",
  CLOSE_SHOP = "closeShop",
  CLOSE_USER_AREA = "closeUserArea",
  CLOSE_WALLET = "closeWallet",
}

export const reducer = (state: StateType, action: DROPDOWN_ACTION) => {
  const { isShopOpened, isUserAreaOpened, isWalletOpened } = state

  switch (action) {
    case DROPDOWN_ACTION.TOGGLE_SHOP:
      return {
        isShopOpened: !isShopOpened,
        isWalletOpened: false,
        isUserAreaOpened: false,
      }
    case DROPDOWN_ACTION.TOGGLE_USER_AREA:
      return {
        isUserAreaOpened: !isUserAreaOpened,
        isShopOpened: false,
        isWalletOpened: false,
      }
    case DROPDOWN_ACTION.TOGGLE_WALLET:
      return {
        isWalletOpened: !isWalletOpened,
        isShopOpened: false,
        isUserAreaOpened: false,
      }
    case DROPDOWN_ACTION.CLOSE_SHOP:
      return {
        ...state,
        isShopOpened: false,
      }
    case DROPDOWN_ACTION.CLOSE_USER_AREA:
      return {
        ...state,
        isUserAreaOpened: false,
      }
    case DROPDOWN_ACTION.CLOSE_WALLET:
      return {
        ...state,
        isWalletOpened: false,
      }
    default:
      return state
  }
}
