import { ProductRenderPilot } from "../product-render-pilot"
import { DesignVersion } from "../../../modules/design/version"
import {
  AvailableColourModes,
  ColoursPreset,
  EditContext,
  EditorMode,
  FinishUI,
  FullProductDescription,
  MaterialUI,
  ModelEditableSpaces,
  MonochromeColours,
  PantoneColorsPreset,
  RotationMap,
} from "../types"
import {
  PrintCoverage,
  Product,
  Variant,
  VariantEditorAssets,
} from "@ph/product-api"
import { PimRenderConfigBuilder } from "./pim-render-config-builder"
import { PimUiConfigBuilder } from "./pim-ui-config-builder"
import { isPrintAdditionallyPaidFor, isPrintAvailableFor } from "./helpers"
import Colour from "../../value-objects/colour"
import _compact from "lodash/compact"
import { AvailableRotations } from "../../../render-engine/modules/vd-editor/modules/dieline-navigator/services/translation-calculator"

export class PimProductRenderPilot extends ProductRenderPilot {
  constructor(
    protected readonly product: Product,
    public readonly designVersion: DesignVersion,
    public readonly editorMode: EditorMode
  ) {
    super(
      product,
      designVersion,
      editorMode,
      new PimRenderConfigBuilder(product).build(),
      new PimUiConfigBuilder(product, editorMode, false).build()
    )
  }

  public is3DProduct(): boolean {
    const renderType = this.variant.editorAssets.renderConfig?.type

    if (!renderType) {
      return super.is3DProduct()
    }

    return renderType === "3d"
  }

  public setAfterPurchaseEdit(isAfterPurchaseEdit: boolean) {
    this.isAfterPurchaseEdit = isAfterPurchaseEdit

    this.uiConfig = new PimUiConfigBuilder(
      this.product,
      this.editorMode,
      isAfterPurchaseEdit
    ).build()
  }

  public getColoursPreset(): ColoursPreset {
    const renderConfig = this.getPimRenderConfig()

    if (!renderConfig) {
      throw Error("Render config is not available!")
    }

    const { mode, blendFactor, defaultColor } = renderConfig.colorPreset

    const colorPreset = {
      mode: mode as AvailableColourModes,
      colourSettings: {
        blendFactor,
      },
    }

    if (mode === AvailableColourModes.MONO_PANTONE) {
      return {
        ...colorPreset,
        colorPantoneDefault: new Colour(defaultColor.hex, defaultColor.pantone),
      }
    }

    if (mode === AvailableColourModes.MONOCHROME) {
      return {
        ...colorPreset,
        colourMonochrome: defaultColor.hex as MonochromeColours,
      }
    }

    return colorPreset
  }

  public getFullProductDescription(): FullProductDescription {
    const description = super.getFullProductDescription()

    return {
      ...description,
      materialKey: this.product.variantManager.getMaterial(),
      finishKey: this.product.variantManager.getFinish(),
    }
  }

  public getAvailableFinishes(): FinishUI[] {
    return this.product.addonsManager.getAvailableFinishes()
  }

  public getAvailableMaterials(): MaterialUI[] {
    return this.product.addonsManager.getAvailableMaterials()
  }

  public isDbyOnly(): boolean {
    const renderConfig = this.getPimRenderConfig()

    if (!renderConfig) {
      return true
    }

    return renderConfig.type === "dby"
  }

  public isPrintActiveFor(editContext: EditContext): boolean {
    const colorMode = this.product.addonsManager.getColorMode()

    switch (editContext) {
      case EditContext.INSIDE:
        return colorMode === PrintCoverage.OutsideInside
      case EditContext.BACK:
        return colorMode === PrintCoverage.FrontBack
      default:
        return [
          EditContext.OUTSIDE,
          EditContext.FRONT,
          EditContext.SLEEVE,
        ].includes(editContext)
    }
  }

  public getAvailableSpaces(editContext: EditContext): ModelEditableSpaces[] {
    return this.configReader.getContextEditableSpaceIds(editContext)
  }

  public isPrintAvailableFor(editContext: EditContext): boolean {
    return isPrintAvailableFor(this.product, editContext, this.isDbyMode())
  }

  public isPrintAdditionallyPaidFor(editContext: EditContext): boolean {
    return isPrintAdditionallyPaidFor(this.product, editContext)
  }

  public getRotationMap(editContext: EditContext): RotationMap {
    const config = this.renderConfig.availableEditContextsMap[editContext]

    if (!config) {
      return {}
    }

    return Object.fromEntries(
      _compact(
        config.map((space) => space.rotation && [space.spaceId, space.rotation])
      )
    )
  }

  public getDielineRotation(editContext: EditContext): AvailableRotations {
    const configs = this.getPimRenderConfig()?.editContexts

    if (!configs) {
      return AvailableRotations.none
    }

    const editContextConfig = configs.find(
      (context) => context.name === editContext
    )

    return editContextConfig?.dielineRotation || AvailableRotations.none
  }

  public getPantoneColorsPreset(): PantoneColorsPreset | undefined {
    const palette =
      this.getPimRenderConfig()?.colorPreset?.pantonePalette?.default

    return palette as PantoneColorsPreset
  }

  public getBackgroundPantoneColorsPreset(): PantoneColorsPreset | undefined {
    const palette =
      this.getPimRenderConfig()?.colorPreset?.pantonePalette?.background

    return palette as PantoneColorsPreset
  }

  private getPimRenderConfig(): VariantEditorAssets["renderConfig"] {
    return this.variant.editorAssets.renderConfig
  }

  private get variant(): Variant {
    return this.product.getDefaultVariant()
  }
}
