import React from "react"
import { observer } from "mobx-react-lite"
import {
  CameraAngle,
  EditContext,
  ViewType,
  ViewMode,
} from "../../../../libs/products-render-config/types"
import { CameraControlsToolbar } from "../../../dsl/organisms/toolbars/CameraControlsToolbar/CameraControlsToolbar"
import { TwoDimmSimpleCameraToolbar } from "../../../dsl/organisms/toolbars/CameraControlsToolbar/TwoDimmSimpleCameraToolbar"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

export const SceneUIRightColumnController = observer(() => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.analytics,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, analytics } = containerSet
  const { productDriver } = designAndProductDriver
  const {
    activeContext,
    activeSpace,
    modelContext,
    activeViewType,
    productRenderPilot,
  } = productDriver.state
  const { assetsDriver } = productDriver

  const overscaledObjectsMap = assetsDriver.overscaledObjectsMap
  const isAnyAssetOverscaled = assetsDriver.isAnyAssetOverscaled

  const setCamera = async (
    viewType: ViewType,
    editContext: EditContext,
    editSpace: CameraAngle
  ) => {
    await productDriver.setView(viewType, editContext, editSpace)
  }

  const viewModes = productRenderPilot.getAvailableViewModes()
  const has3D =
    viewModes.includes(ViewMode.THREE_DIMENSIONAL) ||
    productRenderPilot.isFoldableProduct2D()

  if (has3D) {
    const isPrintInsideAvailable = productRenderPilot.isPrintAvailableFor(
      EditContext.INSIDE
    )

    const availableEditContexts = productRenderPilot.getAvailableEditContexts()

    const availableContextsWithSpaces = availableEditContexts.map((context) => {
      const availableSpaces = productRenderPilot.getAvailableSpaces(context)
      return { context, availableSpaces }
    })

    return (
      <CameraControlsToolbar
        isPrintInsideAvailable={isPrintInsideAvailable}
        isAnyAssetOverscaled={isAnyAssetOverscaled}
        overscaledObjectsMap={overscaledObjectsMap}
        onCameraSet={setCamera}
        currentViewType={activeViewType}
        currentSpace={activeSpace}
        currentEditContext={activeContext}
        currentModelContext={modelContext}
        availableContextsWithSpaces={availableContextsWithSpaces}
      />
    )
  }

  return (
    <TwoDimmSimpleCameraToolbar
      isAnyAssetOverscaled={isAnyAssetOverscaled}
      overscaledObjectsMap={overscaledObjectsMap}
      onCameraSet={setCamera}
      currentSpace={activeSpace}
      currentEditContext={activeContext}
      availableEditContexts={productRenderPilot.getAvailableEditContexts()}
      analyticsController={analytics.analyticsController}
    />
  )
})
