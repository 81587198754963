import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey, isProductTypeCustom, } from "../SkuLib";
import CustomNcpProduct from "../CustomProducts/CustomNcpProduct";
import NcpProduct from "../Products/NcpProduct/NcpProduct";
import NcpVariantManager from "../Products/NcpProduct/behaviorsComposers/NcpVariantManager";
function NcpProductFactory(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    var productVariant = NcpVariantManager.findVariant(decodedSKU.productName);
    if (isProductTypeCustom(sku)) {
        return new CustomNcpProduct(sku, decodedSKU.productName);
    }
    else {
        return new NcpProduct(sku, decodedSKU.productName, productVariant);
    }
}
export default NcpProductFactory;
