import { urls } from "../api-endpoint-urls"
import RegionConfig from "../../../../../configs/region-config/interfaces/js/region-config"
import { SupportedCurrency } from "../../../../../configs/region-config/interfaces/js/region-config.types"

// don't refactor to import. Swift death will follow
const qs = require("query-string")
const stringify = qs.stringify

export const getUserViesValidation = (vatNumber: string) => ({
  method: "get",
  url: `${urls.API_VIES_VALIDATION}?${stringify({
    mode: "no-cors",
    vat_id: vatNumber,
  })}`,
})

export const getUserMe = () => ({
  method: "get",
  url: `${urls.API_USERS}/${urls.API_USER_CURRENT}`,
})
export const getUserMeRfm = () => ({
  method: "get",
  url: `${urls.API_V1}/${urls.API_USER}/${urls.API_USER_CURRENT}/${urls.API_RFM}`,
})
export const getUserAnonymous = () => ({
  method: "get",
  url: `${urls.API_USERS}/${urls.API_USERS_CREATE_ANONYMOUS}`,
})

export const getUserFirstName = (segmentationToken) => ({
  method: "get",
  url: `${RegionConfig.getSegmentationEndpointForCurrentRegion()}/get_user_first_name?segmentation_token=${segmentationToken}`,
})

export const getUserOrdersManager = () => ({
  method: "get",
  url: `${urls.API_ORDERS_MANAGER}`,
})

export const getUserWallet = (currency: SupportedCurrency) => ({
  method: "get",
  url: `${urls.API_USER_WALLET}/?currency=${currency}`,
})

export const updateUserWallet = (walletData: any) => ({
  method: "put",
  url: urls.API_USER_WALLET,
  data: walletData,
})
