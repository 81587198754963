import VirtualDielineEditor from "../../../../virtual-dieline-editor"
import {
  PackhelpEditableGroup,
  VirtualDielineSpace,
} from "../../../../object-extensions/packhelp-objects"
import { ProductRenderPilot } from "../../../../../../../libs/products-render-config/product-render-pilot"
import { BackgroundsLayers } from "../../../backgrounds-module/types"

export class TemplateBackgroundLoader {
  public constructor(
    private readonly vdEditor: VirtualDielineEditor,
    private readonly productRenderPilot: ProductRenderPilot
  ) {}

  public async load(templateBackground: PackhelpEditableGroup) {
    if (!this.productRenderPilot.uiConfig.features.backgroundColor) {
      return
    }

    const background = this.getBackground()

    if (!background) {
      return
    }

    for (const templateBackgroundPart of templateBackground.getObjects()) {
      const coloredBackgroundPart = background
        .getObjects()
        .find(
          (backgroundPart) => backgroundPart.id === templateBackgroundPart.id
        )

      if (coloredBackgroundPart) {
        coloredBackgroundPart.set({
          fill: templateBackgroundPart.fill,
        })
      }

      const coloredSpace = this.getSpaces().find(
        (space) => space.id === templateBackgroundPart.id
      )

      if (coloredSpace) {
        coloredSpace.set({
          fill: templateBackgroundPart.fill,
        })
      }
    }

    await this.vdEditor.dielineNavigator.refreshTempBackgroundOnActiveSpace()
  }

  private getSpaces(): VirtualDielineSpace[] {
    return this.vdEditor.virtualDieline.getObjects()
  }

  private getBackground(): PackhelpEditableGroup | undefined {
    return this.vdEditor.getCanvasObjectById(
      BackgroundsLayers.BACKGROUND_TEXTURE
    ) as PackhelpEditableGroup
  }
}
