import React from "react"
import { WaitingForPaymentNotification } from "../waiting-for-payment-notification/WaitingForPaymentNotification"
import { MoneyWalletCard } from "../wallet-tiles/MoneyWalletCard"
import { WithoutMoneyWalletCard } from "../wallet-tiles/WithoutMoneyWalletCard"
import { WalletDataAttributes } from "../../utils/wallet.types"

type WalletTooltipBalanceInfoProps = {
  orders?: Array<{}> | null
  walletData?: WalletDataAttributes
  totalPrice?: number
}

export const WalletSidebarBalanceInfo = (
  props: WalletTooltipBalanceInfoProps
) => {
  const { orders, walletData, totalPrice } = props
  const showMoneyWalletCard =
    walletData && (walletData.amount > 0 || walletData.current_step > 0)
  return (
    <>
      {orders && <WaitingForPaymentNotification orders={orders} />}
      {showMoneyWalletCard ? (
        <MoneyWalletCard walletData={walletData} isHorizontal={false} />
      ) : (
        <WithoutMoneyWalletCard totalPrice={totalPrice} />
      )}
    </>
  )
}
