import React, { PropsWithChildren } from "react"

import cxBinder from "classnames/bind"
import styles from "./IconButton.module.scss"

const cx = cxBinder.bind(styles)

export interface IconButton
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean
  e2eTarget?: string
  e2eTargetName?: string
  Icon: React.ElementType
}

export const IconButton = ({
  Icon,
  disabled,
  isActive,
  onClick,
  children,
  e2eTarget = "button",
  e2eTargetName,
}: PropsWithChildren<IconButton>) => (
  <button
    className={cx({
      icon_button: true,
      active: isActive,
      disabled: disabled,
    })}
    onClick={onClick}
    disabled={disabled}
    e2e-target={e2eTarget}
    e2e-target-name={e2eTargetName}
  >
    <Icon />
    {children}
  </button>
)
