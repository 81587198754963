var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BOX_CONSTS from "../../../../../source/boxProductSpec/generalConfig/consts/boxConsts";
import { getOldVariantCodeName } from "../../../../../definitions/products/newProductVariantsToOld";
import { ProductType } from "../../../types";
import ProductConfigFactory from "../../../ProductFactory";
import { MailerBoxVariant, WhiteInkMailerBoxVariant, } from "../../../../../source/boxProductSpec/generalConfig/consts/ProductVariant";
import { translateBoxAttributeKeyToSkuVersion } from "../../../SkuLib";
var boxVariantBehaviors = function (superclass) {
    function findVariant(decodedSKU) {
        var productName = decodedSKU.productName, productMaterial = decodedSKU.productMaterial, productColorMode = decodedSKU.productColorMode;
        if (productName === BOX_CONSTS.SLEEVED_MAILER_BOX) {
            return BOX_CONSTS.SLEEVED_MAILER_BOX_VARIANT;
        }
        if (productName === BOX_CONSTS.MAILER_BOX) {
            if (productMaterial ===
                BOX_CONSTS.PRINT_MATERIAL_WHITE_ONE_SIDE_NATURAL_CARDBOARD) {
                return BOX_CONSTS.MAILER_BOX_LITE_COLOR_VARIANT;
            }
            if (productColorMode === BOX_CONSTS.PRINT_MODE_COLOR_HD) {
                return BOX_CONSTS.MAILER_BOX_FULL_COLOR_VARIANT;
            }
            if (productColorMode === BOX_CONSTS.PRINT_MODE_COLOR_ECO ||
                productColorMode === BOX_CONSTS.PRINT_MODE_COLOR_ECO_WITH_PRINT_INSIDE) {
                return BOX_CONSTS.MAILER_BOX_ECO_COLOR_VARIANT;
            }
            if (productColorMode === BOX_CONSTS.PRINT_MODE_COLOR_BUDGET) {
                return BOX_CONSTS.MAILER_BOX_OLD_COLOR_BUDGET_VARIANT;
            }
            if ((productColorMode === BOX_CONSTS.PRINT_MODE_MONOCHROME ||
                productColorMode ===
                    BOX_CONSTS.PRINT_MODE_MONOCHROME_WITH_PRINT_INSIDE ||
                productColorMode === BOX_CONSTS.PRINT_COLOR_MODE_NO_PRINT) &&
                (productMaterial ===
                    BOX_CONSTS.PRINT_MATERIAL_WHITE_ONE_SIDE_CARDBOARD ||
                    productMaterial ===
                        BOX_CONSTS.PRINT_MATERIAL_WHITE_TWO_SIDES_CARDBOARD)) {
                return BOX_CONSTS.MAILER_BOX_ECO_WHITE_VARIANT;
            }
            if ((productColorMode === BOX_CONSTS.PRINT_MODE_MONOCHROME ||
                productColorMode ===
                    BOX_CONSTS.PRINT_MODE_MONOCHROME_WITH_PRINT_INSIDE ||
                productColorMode === BOX_CONSTS.PRINT_COLOR_MODE_NO_PRINT) &&
                productMaterial === BOX_CONSTS.PRINT_MATERIAL_NATURAL_CARDBOARD) {
                return BOX_CONSTS.MAILER_BOX_ECO_VARIANT;
            }
        }
        if (productName === BOX_CONSTS.MAILER_BOX_WHITE_INK) {
            return BOX_CONSTS.MAILER_BOX_WHITE_ON_KRAFT_VARIANT;
        }
        if (productName === BOX_CONSTS.MAILER_BOX_PLAIN) {
            if (productMaterial === BOX_CONSTS.PRINT_MATERIAL_WHITE_CARDBOARD) {
                return BOX_CONSTS.MAILER_BOX_PLAIN_WHITE_VARIANT;
            }
            return BOX_CONSTS.MAILER_BOX_PLAIN_DEFAULT_VARIANT;
        }
        if (productName === BOX_CONSTS.DELIVERY_MAILER_BOX) {
            return BOX_CONSTS.DELIVERY_MAILER_BOX_VARIANT;
        }
        if (productName === BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN) {
            if (productMaterial === BOX_CONSTS.PRINT_MATERIAL_WHITE_CARDBOARD) {
                return BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN_WHITE_VARIANT;
            }
            return BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN_VARIANT;
        }
        if (productName === BOX_CONSTS.SHIPPING_BOX) {
            return BOX_CONSTS.SHIPPING_BOX_DEFAULT_VARIANT;
        }
        if (productName === BOX_CONSTS.SHIPPING_BOX_PLAIN) {
            return BOX_CONSTS.SHIPPING_BOX_PLAIN_DEFAULT_VARIANT;
        }
        if (productName === BOX_CONSTS.SHIPPING_BOX_PLAIN_V2) {
            return BOX_CONSTS.SHIPPING_BOX_PLAIN_V2_DEFAULT_VARIANT;
        }
        if (productName === BOX_CONSTS.PRODUCT_BOX) {
            return BOX_CONSTS.PRODUCT_BOX_DEFAULT_VARIANT;
        }
        if (productName === BOX_CONSTS.PRODUCT_BOX_TWO_PIECES) {
            return BOX_CONSTS.PRODUCT_BOX_TWO_PIECES_DEFAULT_VARIANT;
        }
        if (productName === BOX_CONSTS.PRODUCT_BOX_WINE_BOX) {
            return BOX_CONSTS.PRODUCT_BOX_WINE_BOX_DEFAULT_VARIANT;
        }
        if (productName === BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR) {
            return BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR_VARIANT;
        }
        if (productName === BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO) {
            return BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO_VARIANT;
        }
    }
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            return _super.call(this, props) || this;
        }
        class_1.prototype.getVariant = function () {
            return findVariant(this.skuData.decodedSKU);
        };
        //To work properly with analytics and translations
        // Should be removed in future!
        class_1.prototype.getVariantDashed = function () {
            return getOldVariantCodeName(this.getVariant());
        };
        class_1.prototype.getVariantStructure = function () {
            return this.variant.variants[this.getVariant()];
        };
        class_1.findVariant = function (decodedSKU) {
            return findVariant(decodedSKU);
        };
        class_1.prototype.isPrintInsideActive = function () {
            return (this.getMaterial() ===
                BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD_WHITE_INSIDE);
        };
        class_1.prototype.getProductType = function () {
            var productName = this.getName();
            switch (productName) {
                case BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR:
                case BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO:
                    return ProductType.PrePrinted;
                case BOX_CONSTS.SHIPPING_BOX_PLAIN:
                case BOX_CONSTS.SHIPPING_BOX_PLAIN_V2:
                case BOX_CONSTS.MAILER_BOX_PLAIN:
                case BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN:
                    return ProductType.Plain;
                default:
                    return ProductType.Custom;
            }
        };
        class_1.prototype.getEditorRelatedProducts = function () {
            var _this = this;
            var variant = this.getVariant();
            var isMailerBox = Object.values(MailerBoxVariant).includes(variant);
            var isWhiteOnKraft = Object.values(WhiteInkMailerBoxVariant).includes(variant);
            if (!isMailerBox && !isWhiteOnKraft) {
                return [];
            }
            var isPrintInsideActive = this.product.addonsManager.isPrintInsideActive();
            var finish = translateBoxAttributeKeyToSkuVersion(this.getFinish());
            var material = translateBoxAttributeKeyToSkuVersion(this.getMaterial());
            return [
                this.buildSku({
                    name: "mailer-box",
                    material: "cardboard-natural",
                    colorMode: isPrintInsideActive
                        ? "print-monochrome-with-print-inside"
                        : "print-monochrome",
                    finish: "foil-none",
                }),
                this.buildSku({
                    name: "mailer-box",
                    material: "cardboard-white-one-side",
                    colorMode: isPrintInsideActive
                        ? "print-monochrome-with-print-inside"
                        : "print-monochrome",
                    finish: "foil-none",
                }),
                this.buildSku({
                    name: "mailer-box",
                    material: "cardboard-natural-white-one-side",
                    colorMode: isPrintInsideActive
                        ? "print-eco-color-with-print-inside"
                        : "print-eco-color",
                    finish: "foil-none",
                }),
                this.buildSku({
                    name: "mailer-box",
                    material: "cardboard-white-one-side",
                    colorMode: isPrintInsideActive
                        ? "print-eco-color-with-print-inside"
                        : "print-eco-color",
                    finish: "foil-none",
                }),
                this.buildSku({
                    name: "mailer-box",
                    material: material === "cardboard-coated-white-inside"
                        ? "cardboard-coated-white-inside"
                        : "cardboard-coated",
                    colorMode: "print-color-hd",
                    finish: ["foil-glossy", "foil-matte"].includes(finish)
                        ? finish
                        : "foil-none",
                }),
                this.buildSku({
                    name: "mailer-box-white-ink",
                    material: "cardboard-natural",
                    colorMode: isPrintInsideActive
                        ? "print-monochrome-white-with-print-inside"
                        : "print-monochrome-white",
                    finish: "foil-none",
                }),
            ].map(function (sku) { return _this.mapToEditorRelatedProduct(ProductConfigFactory(sku)); });
        };
        return class_1;
    }(superclass));
};
export default boxVariantBehaviors;
