import { observable, action, makeObservable } from "mobx"
import {
  GroupedShapes,
  MaskShapeType,
  Shape,
  typesToExcludeOnList,
} from "../../libs/value-objects/shape"

import { ShapesManager } from "../../services/shapes-manager"
import { getDefaultMaskShape } from "../../render-engine/modules/vd-editor/modules/assets-module/mask-controller/helpers"
import { DirectusCategory } from "../../modules/directus-api/types"

export class ShapesStore {
  @observable public isLoadingShapes = false
  @observable public isLoadingSingleShape = false
  @observable public shapes: GroupedShapes = {}
  @observable public maskShapes: Shape[] = []
  @observable public categories: DirectusCategory[] = []

  constructor(private readonly shapesManager: ShapesManager) {
    makeObservable(this)
  }

  @action
  public setIsLoadingSingleShape(isLoading: boolean) {
    this.isLoadingSingleShape = isLoading
  }

  public async loadShapes() {
    this.setIsLoadingShapes(true)

    const { categories, groupedShapes } = await this.shapesManager.getShapes()

    const { list, masks } = this.mapShapes(groupedShapes)
    this.setCategories(categories)

    this.setShapes(list)
    this.setMaskShapes(masks)
    this.setIsLoadingShapes(false)
  }

  private mapShapes(shapes: GroupedShapes): {
    list: GroupedShapes
    masks: Shape[]
  } {
    const list: GroupedShapes = {}
    let masks: Shape[] = []

    for (const [type, listInType] of Object.entries(shapes)) {
      if (!typesToExcludeOnList.includes(type)) {
        list[type] = listInType
      }

      if (type === MaskShapeType) {
        masks = [getDefaultMaskShape(), ...listInType]
      }
    }

    return {
      list,
      masks,
    }
  }

  @action
  private setShapes(shapes: GroupedShapes) {
    this.shapes = shapes
  }

  @action
  private setMaskShapes(shapes: Shape[]) {
    this.maskShapes = shapes
  }

  @action
  private setIsLoadingShapes(isLoading: boolean) {
    this.isLoadingShapes = isLoading
  }

  @action
  private setCategories(categories: DirectusCategory[]) {
    this.categories = categories
  }
}
