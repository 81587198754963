import fabric from "../../../../../libs/vendors/Fabric"
import {
  IndexConfigFragments,
  PackhelpObject,
} from "../../object-extensions/packhelp-objects"
import {
  ModelEditableSpaces,
  EditContext,
} from "../../../../../libs/products-render-config/types"
import { SpaceBoundingRect } from "../dieline-navigator/services/translation-calculator"
import { DielineNavigator } from "../dieline-navigator/dieline-navigator"
import { ProductRenderPilot } from "../../../../../libs/products-render-config/product-render-pilot"
import EventEmitter from "eventemitter3"

const stripColor = "#FFD41D"
export const stripOpacity = 0.3
const stripHighlightedOpacity = 0.5

export class GlueStripShapeCreator {
  constructor(
    private readonly editContext: EditContext,
    private readonly dielineNavigator: DielineNavigator,
    private readonly productRenderPilot: ProductRenderPilot,
    private readonly eventEmitter: EventEmitter
  ) {}

  private getVdSpaceBoundingRect(
    spaceId: ModelEditableSpaces
  ): SpaceBoundingRect {
    return this.dielineNavigator.getSpaceBoundingRect(spaceId)
  }

  public async create(spaceId: ModelEditableSpaces, index: number) {
    const vdSpaceBoundingRect = this.getVdSpaceBoundingRect(spaceId)

    const glueStripWidth = this.getGlueStripWidth(spaceId, vdSpaceBoundingRect)
    const strip = new fabric.Rect() as PackhelpObject

    strip.set({
      selectable: false,
      hasControls: false,
      strokeWidth: 0,
      opacity: 0,
      visible: true,
      left: vdSpaceBoundingRect.left,
      top: vdSpaceBoundingRect.top,
      width: glueStripWidth,
      height: vdSpaceBoundingRect.height,
      fill: stripColor,
      id: `glue_strip_${spaceId}`,
      originSpaceArea: spaceId,
      indexConfig: {
        fragment: IndexConfigFragments.TOP,
        index: index,
      },
      hoverCursor: "default",
    })

    strip.on("mouseover", ({ e, target }) => {
      this.eventEmitter.emit("glueStripMouseOver", e)
      if (target) {
        target.set({ opacity: stripHighlightedOpacity })
        target.canvas?.renderAll()
      }
    })

    strip.on("mouseout", ({ target }) => {
      this.eventEmitter.emit("glueStripMouseOut")
      if (target) {
        target.set({ opacity: stripOpacity })
        target.canvas?.renderAll()
      }
    })

    return strip
  }

  private getGlueStripToEditZoneRatio(spaceId: ModelEditableSpaces): number {
    const editZoneSize = this.productRenderPilot.getSpaceDimensions(
      this.editContext,
      spaceId
    )

    const artworkSpaceWidthMm = editZoneSize.widthCm * 10

    const glueStripWidthMm =
      this.productRenderPilot.uiConfig.editZone.glueStripWidthMm!

    return glueStripWidthMm / artworkSpaceWidthMm
  }

  private getGlueStripWidth(
    spaceId: ModelEditableSpaces,
    vdSpaceBoundingRect: SpaceBoundingRect
  ): number {
    const ratio = this.getGlueStripToEditZoneRatio(spaceId)

    return vdSpaceBoundingRect.width * ratio
  }
}
