var _a;
import CARD_CONSTS from "../generalConfig/consts/cardConsts";
var CARD_RENDER_CONFIG = (_a = {},
    _a[CARD_CONSTS.PRINTED_CARD_MODEL] = {
        A6: {
            width: 148,
            height: 105,
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 148,
                        height: 105,
                    },
                    back: {
                        top: 0,
                        left: 0,
                        width: 148,
                        height: 105,
                    },
                },
            },
        },
        A7: {
            width: 105,
            height: 74,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 105,
                        height: 74,
                    },
                    back: {
                        top: 0,
                        left: 0,
                        width: 105,
                        height: 74,
                    },
                },
            },
        },
        DL: {
            width: 210,
            height: 99,
            /** ALWAYS ADD LENGHT. Otherwise you will waste ~4 hours on debug */
            length: 1,
            labelHandleOffset: 30,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 210,
                        height: 99,
                    },
                    back: {
                        top: 0,
                        left: 0,
                        width: 210,
                        height: 99,
                    },
                },
            },
        },
    },
    _a);
export default CARD_RENDER_CONFIG;
