import { useEffect } from "react"
import { EditorEventNames, ee } from "../../stores/editor-events"

export const useEditorEventListener = (
  editorEvent: EditorEventNames,
  callback: () => void
) => {
  useEffect(() => {
    ee.on(editorEvent, callback)

    return () => {
      ee.off(editorEvent, callback)
    }
  }, [])
}
