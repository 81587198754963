import {
  Image,
  Product,
  Variant,
  VariantEditorAssets,
  ModelConfig,
} from "@ph/product-api"
import {
  CameraSettings,
  EditableSpaceConfig,
  EditContext,
  RenderConfig,
  SpaceSceneDecoration,
  TextureConfig,
  TexturesConfig,
  ViewMode,
} from "../types"
import { buildEditContextsMap, isPrintAvailableFor } from "./helpers"
import { prepareImgixUrl } from "../../helpers/prepare-imgix-url"
import _compact from "lodash/compact"

export class PimRenderConfigBuilder {
  constructor(private readonly product: Product) {}

  public build(): Record<string, RenderConfig> {
    return {
      [this.product.variantManager.getSize()]: {
        virtualDielinesPaths: this.virtualDielinesPaths,
        texturesConfig: this.texturesConfig,
        scenesDecorations: this.scenesDecorations,
        availableEditContexts: this.availableEditContexts,
        availableViewModes: this.availableViewModes,
        availableEditContextsMap: this.availableEditContextsMap,
        objModelsPaths: this.objModelsPaths,
        thumbCameraSettings: this.thumbnailCameraSettings,
        cameraSettings: this.modelCameraSettings,
      },
    }
  }

  private get virtualDielinesPaths():
    | { [key in EditContext]?: string }
    | undefined {
    const virtualDielines = this.editorConfig.renderConfig?.virtualDielines

    if (!virtualDielines) {
      return
    }

    return Object.fromEntries(
      Object.keys(virtualDielines).map((editContext) => [
        editContext,
        virtualDielines[editContext].url,
      ])
    )
  }

  private get availableEditContexts(): EditContext[] {
    return this.editContexts.filter((editContext) =>
      isPrintAvailableFor(this.product, editContext, this.renderType === "dby")
    )
  }

  private get editContexts(): EditContext[] {
    return Object.keys(this.availableEditContextsMap) as EditContext[]
  }

  private get availableEditContextsMap(): {
    [key in EditContext]?: EditableSpaceConfig[]
  } {
    const editContexts = this.editorConfig.renderConfig?.editContexts

    if (!editContexts) {
      return {}
    }

    return buildEditContextsMap(
      editContexts,
      !!this.editorConfig.renderConfig?.dtp?.paddingZoneSize
    )
  }

  private get availableViewModes(): ViewMode[] {
    if (this.renderType === "3d") {
      return [ViewMode.THREE_DIMENSIONAL, ViewMode.TWO_DIMENSIONAL]
    }

    return [ViewMode.TWO_DIMENSIONAL]
  }

  private get texturesConfig(): TexturesConfig {
    const emptyConfig = {
      global: [],
      models: {},
    }

    const textures = this.editorConfig.renderConfig?.textures

    if (!textures) {
      return emptyConfig
    }

    const {
      editZone,
      global: { shadowPlane },
    } = textures

    const vdTextures = {}
    const vdZoomTextures = {}

    for (const [editContext, textures] of Object.entries(editZone)) {
      if (textures.default) {
        vdTextures[editContext] = {
          path: this.prepareImageUrl(textures.default),
          type: "plain_texture",
        }
      }

      if (textures.zoom) {
        vdZoomTextures[editContext] = {
          path: this.prepareImageUrl(textures.zoom),
          type: "plain_texture",
        }
      }
    }

    const global: TexturesConfig["global"] = []

    if (shadowPlane) {
      global.push({
        path: this.prepareImageUrl(shadowPlane),
        type: "Shadow_Plane",
      })
    }

    return {
      global,
      models: {
        [this.product.variantManager.getName()]: {
          variants: {
            [this.product.variantManager.getVariant()]: {
              materials: [
                {
                  id: this.product.variantManager.getMaterial(),
                  texturesPaths: {
                    virtualDielineTexture: vdTextures,
                    virtualDielineZoomTexture: vdZoomTextures,
                    rendererTextures: {
                      modelContexts: this.rendererTextures,
                    },
                  },
                },
              ],
              colorModes: [],
              finishes: [],
            },
          },
        },
      },
    }
  }

  private get rendererTextures(): Record<string, TextureConfig[]> {
    const models = this.editorConfig.renderConfig?.models

    if (!models) {
      return {}
    }

    const sizeCode = this.product.variantManager.getSize().split(" ")[0]

    return Object.fromEntries(
      models.map((model) => [
        model.context,
        _compact([
          this.prepareTextureConfig(
            `${model.context}_edge`,
            model.textures.edge
          ),
          // Backward compatibility start
          this.prepareTextureConfig(
            `${sizeCode}_${model.context}_edge`,
            model.textures.edge
          ),
          this.prepareTextureConfig(
            `${sizeCode.replace("FS", "F")}_edge`,
            model.textures.edge
          ),
          // Backward compatibility end
          this.prepareTextureConfig("outside", model.textures.outside),
          this.prepareTextureConfig("inside", model.textures.inside),
          this.prepareTextureConfig("sleeve", model.textures.sleeve),
          this.prepareEnvMapConfig(model.textures.envMap),
        ]),
      ])
    )
  }

  private prepareEnvMapConfig(
    envMap: ModelConfig["textures"]["envMap"]
  ): TextureConfig | undefined {
    const paths = _compact(Object.values(envMap)).map((image) =>
      this.prepareImageUrl(image)
    )

    if (paths.length === 0) {
      return
    }

    return {
      paths,
      type: "env_map",
    }
  }

  private prepareTextureConfig(
    type: string,
    image?: Image
  ): TextureConfig | undefined {
    if (!image) {
      return
    }

    return {
      path: this.prepareImageUrl(image),
      type,
    }
  }

  private get scenesDecorations():
    | Record<string, SpaceSceneDecoration>
    | undefined {
    const textures = this.editorConfig.renderConfig?.textures

    if (!textures) {
      return
    }

    const { background } = textures

    if (!background) {
      return
    }

    const decorations: Record<string, SpaceSceneDecoration> = {}

    for (const editContext of this.editContexts) {
      const texture = background[editContext]

      if (!texture) {
        continue
      }

      const sizeConfig =
        this.editorConfig.renderConfig?.editZone.size[editContext]

      const editZoneSize = sizeConfig || this.variantSize
      const editZoneToVariantRatio = Math.min(
        editZoneSize.width / this.variantSize.width,
        editZoneSize.height / this.variantSize.height,
        1
      )

      const zoom = sizeConfig?.zoom || 1

      decorations[editContext] = {
        backgroundTextures: [
          {
            materialId: this.product.variantManager.getMaterial(),
            textureUrl: this.prepareImageUrl(texture as Image),
          },
        ],
        editZone: {
          zoom: Math.min(editZoneToVariantRatio * zoom, 1),
        },
      }
    }

    return decorations
  }

  private get objModelsPaths(): Record<string, string> {
    const models = this.editorConfig.renderConfig?.models

    if (!models) {
      return {}
    }

    return Object.fromEntries(
      models.map((model) => [model.context, model.file.url])
    )
  }

  private get thumbnailCameraSettings(): Record<string, CameraSettings> {
    return this.getCameraSettings("thumbnail")
  }

  private get modelCameraSettings(): Record<string, CameraSettings> {
    return this.getCameraSettings("model")
  }

  private getCameraSettings(type: string): Record<string, CameraSettings> {
    const models = this.editorConfig.renderConfig?.models

    if (!models) {
      return {}
    }

    return Object.fromEntries(
      _compact(
        models.map((model) => {
          const cameraSettings = model.cameraSettings?.find(
            (t) => t.type === type
          )

          if (!cameraSettings) {
            return
          }

          return [
            model.context,
            {
              cameraPosition: {
                x: cameraSettings.cameraPosition.x,
                y: cameraSettings.cameraPosition.y,
                z: cameraSettings.cameraPosition.z,
              },
              lookAtPoint: {
                x: cameraSettings.lookAtPoint.x,
                y: cameraSettings.lookAtPoint.y,
                z: cameraSettings.lookAtPoint.z,
              },
            },
          ]
        })
      )
    )
  }

  private get variant(): Variant {
    return this.product.getDefaultVariant()
  }

  private get variantSize() {
    const { width, height, diameter } = this.variant.size

    return {
      width: Number(width || diameter),
      height: Number(height || diameter),
    }
  }

  private get editorConfig(): VariantEditorAssets {
    return this.variant.editorAssets
  }

  private get renderType(): string {
    return this.editorConfig.renderConfig?.type || "dby"
  }

  private prepareImageUrl(image: Image): string {
    const cdnUrl = image.optimized?.cdnUrl

    if (!cdnUrl) {
      return image.url
    }

    return prepareImgixUrl(cdnUrl, { auto: "format", q: 50 })
  }
}
