import React from "react"
import cxBinder from "classnames/bind"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { WalletButtonProps } from "../../utils/wallet.types"
import { FormattedCurrency } from "../../../../../shared-components/formatted-currency/FormattedCurrency"
import { WalletIcon } from "dsl/src/assets/_svgr/UI/Wallet"

import styles from "./WalletButton.module.scss"

const cx = cxBinder.bind(styles)

export const WalletButton = ({
  isWalletOpened,
  onClick,
  simpleWallet,
  walletData,
  buttonVersion,
}: WalletButtonProps) => (
  <div
    className={cx("wrapper", {
      active: isWalletOpened,
      segmentation: buttonVersion === "v2",
      "simple-wallet": simpleWallet,
    })}
    onClick={onClick}
    e2e-target="closed-wallet"
  >
    {walletData && !simpleWallet && (
      <Typography
        type={TType.Header15_500}
        htmlElement="span"
        className={styles.walletAmount}
      >
        <FormattedCurrency
          value={walletData.amount}
          currency={walletData.currency}
        />
      </Typography>
    )}
    <WalletIcon className={cx("wallet-icon")} />
  </div>
)
