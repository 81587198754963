import React from "react"
import { Alignment, InnerSize, Modal } from "../Modal"
import { DownloadOrPrint } from "./DownloadOrPrint"

interface DownloadOrPrintModalProps {
  onClose: () => void
  submitAction: () => void
}

export const DownloadOrPrintModal = ({
  onClose,
  submitAction,
}: DownloadOrPrintModalProps) => (
  <Modal
    e2eTargetName="review-and-purchase"
    onClose={onClose}
    maxWidth={InnerSize.auto}
    fullHeight
    align={Alignment.right}
  >
    <DownloadOrPrint submitAction={submitAction} onClose={onClose} />
  </Modal>
)
