import { PackhelpObject } from "../../../object-extensions/packhelp-objects"
import {
  ClipPathGenerator,
  ClipPathModes,
} from "../../../services/clip-path-generator"
import VirtualDielineEditor from "../../../virtual-dieline-editor"

export class SpaceClippingHelper {
  public static async evokeSpaceClipping(
    virtualDielineEditor: VirtualDielineEditor,
    spaceId,
    object
  ): Promise<PackhelpObject> {
    const clipPathMode =
      object.clipMode === ClipPathModes.FillMode
        ? ClipPathModes.FillMode
        : ClipPathModes.AlphaMaskMode

    return this.setSpaceClipping(
      virtualDielineEditor,
      spaceId,
      object,
      clipPathMode
    )
  }

  public static toggleSpaceClipping(
    virtualDielineEditor: VirtualDielineEditor,
    spaceId,
    object
  ): Promise<PackhelpObject> {
    const clipPathMode =
      object.clipMode === ClipPathModes.FillMode
        ? ClipPathModes.AlphaMaskMode
        : ClipPathModes.FillMode

    return this.setSpaceClipping(
      virtualDielineEditor,
      spaceId,
      object,
      clipPathMode
    )
  }

  public static async setSpaceClipping(
    virtualDielineEditor: VirtualDielineEditor,
    spaceId: string,
    object: PackhelpObject,
    clipPathMode: ClipPathModes
  ): Promise<PackhelpObject> {
    const clonedSpaceObject =
      await virtualDielineEditor.dielineNavigator.cloneSpaceToClipPath(spaceId)
    const clipPathGenerator = new ClipPathGenerator(
      clonedSpaceObject,
      virtualDielineEditor.getCanvasDimensions()
    )

    return clipPathGenerator.generate(object, clipPathMode, "space-clipping")
  }
}
