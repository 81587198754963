import type { ProductSwitcherUiController } from "../stores/_controllers/product-switcher-ui.controller"
import type { ListingStore } from "../stores/listing-store/listing.store"
import type { ApiSessionContainer } from "./api-session-container"
import type { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap-container"
import type { UtilEnvContainer } from "./util-container"

async function importDeps() {
  const { ListingStore } = await import("../stores/listing-store/listing.store")
  const { ProductSwitcherUiController } = await import(
    "../stores/_controllers/product-switcher-ui.controller"
  )

  return {
    ListingStore,
    ProductSwitcherUiController,
  }
}

type ProductSwitcherMaybeContainer =
  | {
      available: true
      listingStore: ListingStore
      productSwitcherUiController: ProductSwitcherUiController
    }
  | {
      available: false
      listingStore: undefined
      productSwitcherUiController: undefined
    }

export async function provideProductSwitcherMaybeContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  rootBootstrapper: BootstrapClassicRootStoreContainer
): Promise<ProductSwitcherMaybeContainer> {
  const { appConfig } = utilEnvContainer

  if (!appConfig.api.ecommerce.features.ecommerce.productSwitcher) {
    return {
      available: false,
      listingStore: undefined,
      productSwitcherUiController: undefined,
    }
  }

  const { uri } = utilEnvContainer
  const { listingApiService } = apiSessionContainer
  const { productStore } = rootBootstrapper

  const { ListingStore, ProductSwitcherUiController } = await importDeps()

  const listingStore = await ListingStore.init(appConfig, listingApiService)

  const productSwitcherUiController = new ProductSwitcherUiController(
    productStore,
    appConfig,
    uri
  )

  return {
    available: true,
    listingStore,
    productSwitcherUiController,
  }
}
