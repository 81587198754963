var _a;
import TISSUE_PAPER_CONSTS from "../consts/tissuePaperConsts";
var TISSUE_PAPER_PRODUCT_GRAMMAGES = (_a = {},
    _a[TISSUE_PAPER_CONSTS.TISSUE_PAPER] = {
        T120: {
            grammage: "20 g/m2",
            materialDescription: TISSUE_PAPER_CONSTS.MATERIAL_ID_PAPER,
        },
    },
    _a[TISSUE_PAPER_CONSTS.PACKING_PAPER] = {
        T120: {
            grammage: "25 g/m2",
            materialDescription: TISSUE_PAPER_CONSTS.MATERIAL_ID_PAPER,
        },
    },
    _a);
export default TISSUE_PAPER_PRODUCT_GRAMMAGES;
