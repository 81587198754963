import React, { PropsWithChildren } from "react"

import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import Button from "dsl/src/atoms/Button/Button"
import ModalTemplateConfirm from "../partials/ModalTemplateConfirm"

import { useTranslate } from "../../../../hooks/useTranslate"
import { ReactComponent as ModalIcon } from "../../../../assets/svg/circle-retry.svg"
import styles from "./DiscardChanges.module.scss"

export interface DiscardChangesProps {
  onClose: () => void
  onDiscard: () => void
}

const DiscardChanges = ({
  children,
  onClose,
  onDiscard,
}: PropsWithChildren<DiscardChangesProps>) => {
  const t = useTranslate()
  return (
    <ModalTemplateConfirm
      onClose={onClose}
      title=""
      cancelLinkText={t("generic.cancel")}
      buttonComponent={
        <Button e2eTargetName="start-again" onClick={onDiscard}>
          {t("modal.editor.discard-changes.button")}
        </Button>
      }
      withCancel
    >
      <div className={styles.content}>
        {children ? (
          children
        ) : (
          <>
            <ModalIcon />
            <Typography type={TType.Header32_400}>
              {t("modal.editor.discard-changes.header")}
            </Typography>
            <Typography type={TType.Body15_350}>
              {t("modal.editor.discard-changes.copy-p1")}
              <br />
              {t("modal.editor.discard-changes.copy-p2")}
            </Typography>
          </>
        )}
      </div>
    </ModalTemplateConfirm>
  )
}

export { DiscardChanges as default }
