import VirtualDielineEditor from "../../../../virtual-dieline-editor"
import {
  PackhelpEditableGroup,
  PackhelpEditableObject,
  PackhelpInteractiveCanvas,
} from "../../../../object-extensions/packhelp-objects"
import {
  EditableObjectTypes,
  isAssetImage,
  isAssetText,
  isStaticCanvas,
} from "../../../../../../../modules/ph-api/asset-types"
import { cloneGroupObject, refreshGroup } from "../helpers"
import {
  ActiveObjectEvent,
  CanvasEvent,
  CanvasObjectEvent,
} from "../../../../../../../stores/_events/domain-events"
import { BorderController } from "./border-controller"
import { getLayerIndex } from "../../canvas-object-controller/helpers"
import { GroupEvent, TempEditableGroupObject } from "../types"
import { degreesToRadians } from "../../../../../../../libs/maths/angle-calculators"
import fabric from "../../../../../../../libs/vendors/Fabric"
import { NotReadyError } from "../errors"
import { v4 as uuidv4 } from "uuid"

export class EditModeController {
  private activeObject?: PackhelpEditableObject
  private editedObject?: PackhelpEditableObject

  private readonly objectEventMap: GroupEvent[]
  private readonly groupEventMap: GroupEvent[]
  private readonly canvasEventMap: GroupEvent[]

  constructor(
    private readonly group: PackhelpEditableGroup,
    private readonly borderController: BorderController,
    private readonly virtualDielineEditor: VirtualDielineEditor
  ) {
    this.objectEventMap = this.buildObjectEventMap()
    this.groupEventMap = this.buildGroupEventMap()
    this.canvasEventMap = this.buildCanvasEventMap()
  }

  public async init(object: PackhelpEditableObject) {
    if (this.group.group) {
      return
    }

    this.clear()
    refreshGroup(this.group)
    this.borderController.show()

    this.activeObject = object
    this.editedObject = await cloneGroupObject(object)
    this.editedObject.id = `${TempEditableGroupObject.ASSET}_${uuidv4()}`
    this.applyEditedObjectParams(this.editedObject)
    this.addOnCanvas()

    this.hideActiveObject()
    this.attachEvents()
  }

  public clear() {
    this.borderController.clear()
    this.showActiveObject()

    if (this.editedObject) {
      this.canvas.remove(this.editedObject)
    }

    this.detachEvents()

    this.editedObject = undefined
    this.activeObject = undefined
  }

  private addOnCanvas() {
    if (!this.editedObject) {
      return
    }

    this.canvas.add(this.editedObject)
    this.canvas.moveTo(
      this.editedObject,
      getLayerIndex(this.group, this.canvas) + 1
    )
    this.canvas.setActiveObject(this.editedObject)
    this.virtualDielineEditor.eventEmitter.emit(
      ActiveObjectEvent.selected,
      this.editedObject
    )
  }

  private hideActiveObject() {
    if (!this.activeObject) {
      return
    }

    this.activeObject.set({
      opacity: 0,
    })
  }

  private showActiveObject() {
    if (!this.activeObject) {
      return
    }

    this.activeObject.set({
      opacity: 1,
    })
  }

  private attachEvents() {
    if (this.editedObject) {
      for (const event of this.objectEventMap) {
        this.editedObject.on(event.name, event.fn)
      }
    }

    for (const event of this.groupEventMap) {
      this.group.on(event.name, event.fn)
    }

    for (const event of this.canvasEventMap) {
      this.canvas.on(event.name, event.fn)
    }
  }

  private detachEvents() {
    if (this.editedObject) {
      for (const event of this.objectEventMap) {
        this.editedObject.off(event.name, event.fn)
      }
    }

    for (const event of this.groupEventMap) {
      this.group.off(event.name, event.fn)
    }

    for (const event of this.canvasEventMap) {
      this.canvas.off(event.name, event.fn)
    }
  }

  private onDeselected(e) {
    if (!this.editedObject) {
      return
    }

    const deselectedObject = e.deselected && e.deselected[0]

    if (!deselectedObject || deselectedObject.id !== this.editedObject.id) {
      return
    }

    this.clear()
  }

  private onChanged() {
    if (!this.activeObject || !this.editedObject) {
      return
    }

    this.borderController.clear()

    const left =
      this.editedObject.left! - (this.group.left! + this.group.width! / 2)
    const top =
      this.editedObject.top! - (this.group.top! + this.group.height! / 2)

    const newPosition = fabric.util.rotatePoint(
      new fabric.Point(left, top),
      new fabric.Point(-this.group.width! / 2, -this.group.height! / 2),
      degreesToRadians(-this.group.angle!)
    )

    this.activeObject.set({
      fill: this.editedObject.fill,
      fillPantoneName: this.editedObject.fillPantoneName,
      lockUniScaling: this.editedObject.lockUniScaling,
      left: newPosition.x,
      top: newPosition.y,
      scaleX: this.editedObject.scaleX,
      scaleY: this.editedObject.scaleY,
      angle: this.editedObject.angle! - this.group.angle!,
    })

    if (this.editedObject.filters && isAssetImage(this.activeObject)) {
      this.activeObject.set({ filters: this.editedObject.filters })
      this.activeObject.applyFilters()
    }

    if (isAssetText(this.activeObject) && isAssetText(this.editedObject)) {
      this.activeObject.set({
        text: this.editedObject.text,
        fontFamily: this.editedObject.fontFamily,
        fontSize: this.editedObject.fontSize,
        fontStyle: this.editedObject.fontStyle,
        fontWeight: this.editedObject.fontWeight,
        textAlign: this.editedObject.textAlign,
        lineHeight: this.editedObject.lineHeight,
        charSpacing: this.editedObject.charSpacing,
        shadow: this.editedObject.shadow,
        stroke: this.editedObject.stroke,
        strokeWidth: this.editedObject.strokeWidth,
      })
    }

    if (this.activeObject.assetType === EditableObjectTypes.assetImage) {
      this.activeObject.set({
        isOverscaled: this.editedObject.isOverscaled,
      })
    }

    refreshGroup(this.group)
    this.borderController.show()
  }

  private onGroupMoving() {
    this.clear()

    this.canvas.setActiveObject(this.group)
    this.virtualDielineEditor.eventEmitter.emit(
      ActiveObjectEvent.selected,
      this.group
    )
  }

  private async onGroupMoved() {
    if (!this.activeObject) {
      return
    }

    const activeObject = this.activeObject
    this.clear()
    await this.init(activeObject)
  }

  private async onGroupLayerChanged() {
    if (!this.editedObject) {
      return
    }

    const index = getLayerIndex(this.group, this.canvas)
    this.canvas.moveTo(this.editedObject, index)
  }

  private applyEditedObjectParams(editedObject: PackhelpEditableObject) {
    if (!this.activeObject) {
      throw new NotReadyError("Call init() first!")
    }

    editedObject.set({
      padding: -1,
      id: this.activeObject.id,
    })
  }

  private get canvas(): PackhelpInteractiveCanvas {
    const canvas = this.virtualDielineEditor.fabricCanvas

    if (isStaticCanvas(canvas)) {
      throw new Error("Edit mode is not available for Static Canvas")
    }

    return canvas
  }

  private buildObjectEventMap(): GroupEvent[] {
    return [
      {
        name: CanvasObjectEvent.changed,
        fn: this.onChanged.bind(this),
      },
      {
        name: CanvasObjectEvent.scaling,
        fn: this.onChanged.bind(this),
      },
      {
        name: CanvasObjectEvent.rotating,
        fn: this.onChanged.bind(this),
      },
      {
        name: CanvasObjectEvent.moving,
        fn: this.onChanged.bind(this),
      },
      {
        name: CanvasObjectEvent.moved,
        fn: this.onChanged.bind(this),
      },
    ]
  }

  private buildGroupEventMap(): GroupEvent[] {
    return [
      {
        name: CanvasObjectEvent.removed,
        fn: this.clear.bind(this),
      },
      {
        name: CanvasObjectEvent.moving,
        fn: this.onGroupMoving.bind(this),
      },
      {
        name: CanvasObjectEvent.moved,
        fn: this.onGroupMoved.bind(this),
      },
      {
        name: CanvasObjectEvent.beforeDuplicated,
        fn: this.clear.bind(this),
      },
      {
        name: CanvasObjectEvent.layerChanged,
        fn: this.onGroupLayerChanged.bind(this),
      },
    ]
  }

  private buildCanvasEventMap(): GroupEvent[] {
    return [
      {
        name: CanvasEvent.selectionCleared,
        fn: this.onDeselected.bind(this),
      },
      {
        name: CanvasEvent.selectionUpdated,
        fn: this.onDeselected.bind(this),
      },
    ]
  }
}
