import { action, observable, makeObservable } from "mobx"

export class AfterPurchaseEditStore {
  @observable public isAfterPurchaseEdit = false

  constructor({
    designStatus,
    isOrdered,
  }: {
    designStatus: string
    isOrdered: boolean
  }) {
    makeObservable(this)

    this.setIsAfterPurchaseEdit(designStatus === "draft" && isOrdered)
  }

  @action
  public setIsAfterPurchaseEdit(isAfterPurchaseEdit: boolean) {
    this.isAfterPurchaseEdit = isAfterPurchaseEdit
  }
}
