import React from "react"

import styles from "./TagIcon.module.scss"
import cxBinder from "classnames/bind"
const cx = cxBinder.bind(styles)

interface TagIconProps {
  Icon: React.ElementType
  onClick?(): void
  type?: "circle" | "square"
  size?: "normal" | "large"
}

export const TagIcon = ({
  Icon,
  onClick,
  type = "circle",
  size = "normal",
}: TagIconProps) => (
  <span
    onClick={onClick}
    className={cx("tag", {
      "tag--square": type === "square",
      "tag--large": size === "large",
    })}
  >
    <Icon />
  </span>
)
