import React from "react"
import { FormattedHTMLMessage } from "react-intl"
import cxBinder from "classnames/bind"
import { useFormatMessage } from "../../../../../libs/others/i18n"
import {
  defaultLevelPercentage,
  getFormattedFuturePrice,
  getUserCurrency,
} from "../../utils/wallet.helpers"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { WithoutMoneyWalletCardProps } from "../../utils/wallet.types"
import {
  walletI18n,
  walletMinimalStepDiscount,
} from "../../utils/wallet.translations"
import styles from "./WithoutMoneyWalletCard.module.scss"
import { UserBenefits } from "./UserBenefits"
import { FormattedCurrency } from "../../../../../shared-components/formatted-currency/FormattedCurrency"
import { TM } from "../../../../../shared-components/i18n/TypographyWithTranslate"

const cx = cxBinder.bind(styles)

export const WithoutMoneyWalletCard = ({
  discount,
  totalPrice,
  isHorizontal,
}: WithoutMoneyWalletCardProps) => {
  const translate = useFormatMessage()
  const formattedAmount = getFormattedFuturePrice(
    defaultLevelPercentage,
    totalPrice,
    getUserCurrency()
  )

  const userCurrency = getUserCurrency()
  return (
    <div
      className={cx({
        isHorizontal,
      })}
    >
      <div className={styles["balance-wrapper"]}>
        <div className={styles["balance-details"]}>
          <TM
            type={TType.Header13_500}
            id={walletI18n.yourBalance}
            className={styles.amount}
          />
          <FormattedCurrency currency={userCurrency} value={0} />
        </div>

        <div
          className={cx({
            "balance-content": !isHorizontal,
            "balance-content-horizontal": isHorizontal,
          })}
        >
          <div className={styles["connection-horizontal"]}>
            <div className={styles["without-money-wallet"]}>
              <Typography type={TType.Header17_500}>
                <span className={styles["discount"]}>
                  {discount ? discount : defaultLevelPercentage}%
                </span>
              </Typography>

              <Typography type={TType.Header15_500}>
                {totalPrice ? (
                  <FormattedHTMLMessage
                    id={walletI18n.newTooltipCashback}
                    values={{
                      amount: formattedAmount,
                    }}
                  />
                ) : (
                  translate({ id: walletI18n.tooltipCashback })
                )}
              </Typography>

              <Typography
                type={TType.Header15_500}
                className={styles["program-name"]}
              >
                {translate({ id: walletI18n.loyaltyProgram })}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <UserBenefits
        currentStep={0}
        isHorizontal={isHorizontal}
        nextOrderDiscount={walletMinimalStepDiscount}
      />
    </div>
  )
}
