import React from "react"
import { TType } from "dsl/src/atoms/Typography/Typography"
import { TM } from "../../../../TypographyI18n"
import { isDeviceTablet } from "../../utils/proscenium.helpers"

import styles from "./ScreenSizeNotSupported.module.scss"
import { ButtonAsLink } from "dsl/src/atoms/Button/Button"
import { Header } from "../components/Header"

enum i18n {
  change_header = "component.device-unsupported.mobile-unavailable.header",
  change_subheader = "component.device-unsupported.mobile-unavailable.subheader",
  rotate_screen = "component.device-unsupported.please-rotate",
  homepage = "component.device-unsupported.mobile-unavailable.go-to-homepage",
  switch_to_desktop = "component.device-unsupported.mobile-unavailable.switch-to-desktop",
}

export const ScreenSizeNotSupported = () => {
  // const tabletScreenTooNarrow = useMedia({ minWidth: breakpoint.MOBILE.MD })
  // const shouldRotate = isDeviceTablet() && tabletScreenTooNarrow TODO: use when tablets are ready

  return (
    <div className={styles.wrapper}>
      {/*
      // TODO: uncomment once tablets are ready
      // reference https://app.asana.com/0/1127001418811356/1175709146733596/f
      // https://packhelp.slack.com/archives/CJ77ZN95Y/p1589531251149600
      {shouldRotate ? <RotateScreen /> : <ChangeDevice />}
      */}
      <ChangeDevice />
    </div>
  )
}

const ChangeDevice = () => {
  return (
    <div className={styles["layout"]}>
      <Header />
      <div className={styles.header}>
        <TM id={i18n.change_header} type={TType.Header32_400} />
      </div>
      <div className={styles.subheader}>
        {/*
        // TODO: replace 'switch_to_desktop' with 'change_subheader', once tablets are ready
        <TM id={i18n.change_subheader} type={TType.Body17_350} />
        */}
        <TM id={i18n.switch_to_desktop} type={TType.Body17_350} />
      </div>
      <ButtonAsLink href="/" fluid={!isDeviceTablet()}>
        <TM id={i18n.homepage} type={TType.Header17_500} />
      </ButtonAsLink>
    </div>
  )
}

// const RotateScreen = () => {
//   return (
//     <div className={styles["layout-centered"]}>
//       <IconRotate />
//       <TM id={i18n.rotate_screen} type={TType.Header13_500} />
//     </div>
//   )
// }
