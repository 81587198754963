import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey, isProductTypeCustom, } from "../SkuLib";
import CustomTapeProduct from "../CustomProducts/CustomTapeProduct";
import TapeProduct from "../Products/TapeProduct/TapeProduct";
import TapeVariantManager from "../Products/TapeProduct/behaviorsComposers/TapeVariantManager";
function TapeProductFactory(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    var productVariant = TapeVariantManager.findVariant(decodedSKU);
    if (isProductTypeCustom(sku)) {
        return new CustomTapeProduct(sku, decodedSKU.productName);
    }
    else {
        return new TapeProduct(sku, decodedSKU.productName, productVariant);
    }
}
export default TapeProductFactory;
