var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _isEmpty from "lodash/isEmpty";
var addTapeRenderConfigBehaviors = function (superclass) {
    var ROTATION_STRAIGHT = "straight";
    var ROTATION_ANGLED = "angled";
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.getAvailableRotation = function (rotation) {
                var rotations = _this.getRenderConfigMetadata()["availableRotations"];
                if (rotation === ROTATION_ANGLED) {
                    return rotations[1];
                }
                return rotations[0];
            };
            _this.getAvailablePrintSizes = function () {
                var metadata = _this.getRenderConfigMetadata();
                return metadata["printSizes"];
            };
            _this.product = product;
            return _this;
        }
        class_1.prototype.initSideEditor = function (sideEditor) {
            return __awaiter(this, void 0, void 0, function () {
                var sideImgUrl, defaultPatternConfig, patternObject;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            sideImgUrl = sideEditor.product.getEditorBackgroundPath(sideEditor.editedSide);
                            return [4 /*yield*/, sideEditor.setSideBackgroundImage(sideImgUrl)];
                        case 1:
                            _a.sent();
                            if (!_isEmpty(sideEditor.data)) return [3 /*break*/, 3];
                            defaultPatternConfig = this.getDefaultPatternConfig();
                            return [4 /*yield*/, sideEditor.initPattern(defaultPatternConfig)];
                        case 2:
                            patternObject = _a.sent();
                            patternObject.productClipObject = sideEditor.productClipObject;
                            patternObject.clipTo =
                                sideEditor.product.renderConfigManager.getClipingFunction();
                            return [3 /*break*/, 4];
                        case 3: return [2 /*return*/, Promise.resolve()];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        };
        class_1.prototype.afterDataLoad = function (sideEditor) {
            return __awaiter(this, void 0, void 0, function () {
                var patternObject;
                return __generator(this, function (_a) {
                    patternObject = sideEditor.patternsManager.isPatternObjectAppliedOnPlayground();
                    if (patternObject) {
                        return [2 /*return*/, sideEditor.initPattern(patternObject.patternConfig)];
                    }
                    else {
                        return [2 /*return*/, Promise.resolve()];
                    }
                    return [2 /*return*/];
                });
            });
        };
        // model
        class_1.prototype.getDefaultPatternConfig = function () {
            var defaultColor = this.product.editConfigManager.getEditorDefaultColor();
            var defaultPrintSize = this.getAvailablePrintSizes()["XXX_LARGE"]["width"];
            return {
                assetData: {
                    type: "image",
                    filename: "cherries.svg",
                    url: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/assets/cherries.png",
                    mimeType: "image/png",
                },
                sourceConfig: {
                    distribution: {
                        printSize: defaultPrintSize,
                        id: null,
                    },
                    size: 1,
                    rotation: this.getAvailableRotation(ROTATION_STRAIGHT),
                },
                filters: {
                    threshold: 150,
                    color: {
                        hexColor: defaultColor.color,
                        pantoneName: defaultColor.pantoneName,
                    },
                },
            };
        };
        class_1.prototype.getRenderConfigMetadata = function () {
            var productRenderConfig = this.product.renderConfigManager.getRenderConfig();
            return productRenderConfig["metadata"];
        };
        return class_1;
    }(superclass));
};
export default addTapeRenderConfigBehaviors;
