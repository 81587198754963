import THREE from "../../../libs/vendors/THREE"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import {
  ModelContext,
  TextureDefinition,
} from "../../../libs/products-render-config/types"
import {
  isGroup,
  isMesh,
  isObjModelConfig,
  isSvgModelConfig,
  ModelConfig,
  RendererConfig,
  RendererSize,
  ThreeDimRendererEvent,
} from "./types"
import EventEmitter from "eventemitter3"
import { ModelStrategy } from "./strategies/model.strategy"
import { ObjModelStrategy } from "./strategies/obj-model.strategy"
import { SvgModelStrategy } from "./strategies/svg-model.strategy"
import { HighlightCalculator } from "./calculators/highlight.calculator"
import { HighlightDefinition } from "../vd-editor/modules/space-highlight-module"
import { v4 as uuidv4 } from "uuid"
import _throttle from "lodash/throttle"

const defaultConfig: RendererConfig = {
  mode: "default",
  size: {
    width: 640,
    height: 360,
  },
}

export class ThreeDimRenderer {
  private readonly scene: THREE.Scene
  private readonly camera: THREE.PerspectiveCamera
  private readonly orbitControls: OrbitControls
  private readonly renderer: THREE.WebGLRenderer
  private readonly config: RendererConfig

  private resizeObserver?: ResizeObserver
  private isEventListenersAttached = false
  private isCameraMoving = false

  private readonly highlightCalculator: HighlightCalculator
  private strategy: ModelStrategy
  private foldingAnimationId = uuidv4()

  constructor(
    protected readonly mountPoint: HTMLElement,
    protected readonly eventEmitter: EventEmitter,
    protected readonly vdCanvases: Record<string, HTMLCanvasElement>,
    config: Partial<RendererConfig> = {}
  ) {
    this.config = {
      ...defaultConfig,
      ...config,
    }

    this.scene = new THREE.Scene()
    this.scene.add(new THREE.HemisphereLight(0xe1e1e1, 0xe2e2e2, Math.PI))
    this.camera = new THREE.PerspectiveCamera(
      45,
      this.rendererSize.width / this.rendererSize.height,
      1,
      10000
    )
    this.camera.position.set(10, 10, -10)
    this.scene.add(this.camera)

    const light = new THREE.DirectionalLight(0xffffff, Math.PI * 0.25)
    light.position.set(50, 100, 50)
    light.target.position.set(0, 0, 0)
    light.castShadow = true
    light.shadow.mapSize.width = 2048
    light.shadow.mapSize.height = 2048
    light.shadow.camera.left = 100
    light.shadow.camera.right = -100
    light.shadow.camera.top = 100
    light.shadow.camera.bottom = -100
    this.scene.add(light)

    // Do not remove, helpful for debugging
    // const helper = new THREE.DirectionalLightHelper(light)
    // this.scene.add(helper)
    // const axesHelper = new THREE.AxesHelper(5000)
    // this.scene.add(axesHelper)

    this.orbitControls = new OrbitControls(this.camera, this.mountPoint)

    this.renderer = new THREE.WebGLRenderer({
      alpha: false,
      antialias: false,
      preserveDrawingBuffer: false,
      powerPreference: "high-performance",
    })
    this.renderer.setSize(this.rendererSize.width, this.rendererSize.height)
    this.renderer.shadowMap.enabled = true
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap // to antialias the shadow

    if (this.config.mode === "thumbnail") {
      this.renderer.setClearColor(0x000000, 0)
    } else {
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.scene.background = new THREE.Color("#f8f8f8")
    }

    if (this.config.mode !== "screenshot") {
      this.orbitControls.enablePan = false
      this.orbitControls.enableZoom = false
    }

    this.mountPoint.appendChild(this.renderer.domElement)

    this.highlightCalculator = new HighlightCalculator(
      this.mountPoint,
      this.camera
    )

    this.strategy = new ObjModelStrategy(this.vdCanvases, this.config.mode)
  }

  public async init(modelConfig: ModelConfig): Promise<ThreeDimRenderer> {
    modelConfig.settings.anisotropy =
      this.renderer.capabilities.getMaxAnisotropy()

    this.setStrategy(modelConfig)

    const model = await this.strategy.init(modelConfig)

    if (model.parent !== this.scene) {
      this.clearStage()
    }

    if (this.strategy.isFogRequired()) {
      this.scene.fog = new THREE.Fog(0xf8f8f8, 20, 80)
    }

    this.scene.add(model)

    this.strategy.fitModelToScreen(this.orbitControls)

    this.startAnimate()

    return this
  }

  public isFoldingSupported(): boolean {
    return this.strategy.isFoldingSupported()
  }

  public async changeContext(modelConfig: ModelConfig): Promise<void> {
    if (this.isFoldingSupported()) {
      this.foldingAnimationId = uuidv4()
      return this.animateFolding(modelConfig.context, this.foldingAnimationId)
    }

    await this.init(modelConfig)
  }

  private animateFolding(
    modelContext: ModelContext,
    animationId: string
  ): void {
    if (this.foldingAnimationId !== animationId) {
      return
    }

    const targetFoldingPercentage =
      this.strategy.getTargetFoldingPercentage(modelContext)
    const currentFoldingPercentage = this.strategy.getFoldingPercentage()
    const step = this.strategy.getFoldingStep()
    const direction =
      currentFoldingPercentage > targetFoldingPercentage ? -1 : 1

    this.strategy.setFoldingPercentage(
      currentFoldingPercentage + step * direction
    )

    this.strategy.fitModelToScreen(this.orbitControls)

    this.startAnimate()

    if (currentFoldingPercentage !== targetFoldingPercentage) {
      requestAnimationFrame(
        this.animateFolding.bind(this, modelContext, animationId)
      )
    }
  }

  public toDataURL(
    options: { mimeType?: string; quality?: number } = {}
  ): string {
    return this.renderer.domElement.toDataURL(options.mimeType, options.quality)
  }

  private clearStage() {
    this.scene.fog = null

    for (const object of this.scene.children) {
      if (isMesh(object) || isGroup(object)) {
        this.scene.remove(object)
      }
    }
  }

  public startAnimate(): ThreeDimRenderer {
    this.renderer.render(this.scene, this.camera)

    if (this.config.mode !== "thumbnail") {
      //TODO: this.emitCameraPos()
      this.attachResizeObserver()
      this.attachEventListeners()
    }

    return this
  }

  public stopAnimate() {
    this.detachEventListeners()
    this.detachResizeObserver()
  }

  public dispose() {
    this.stopAnimate()
    this.clearStage()
    this.strategy.dispose()
    this.mountPoint.innerHTML = ""
  }

  public async setModelTextures(
    textureDefinitions: TextureDefinition[]
  ): Promise<void> {
    await this.strategy.setModelTextures(textureDefinitions)

    this.touchTextures()
  }

  public touchTextures(): void {
    this.strategy.touchTextures()

    this.startAnimate()
  }

  protected get events(): ThreeDimRendererEvent[] {
    return [
      {
        object: this.mountPoint,
        name: "mousemove",
        fn: _throttle(this.onSceneMouseMove.bind(this), 100),
      },
      {
        object: this.mountPoint,
        name: "click",
        fn: this.onSceneClick.bind(this),
      },
      {
        object: this.mountPoint,
        name: "touchend",
        fn: this.onSceneTouchEnd.bind(this),
      },
      {
        object: this.orbitControls,
        name: "change",
        fn: this.onCameraChange.bind(this),
      },
      {
        object: this.orbitControls,
        name: "end",
        fn: this.onCameraEnd.bind(this),
      },
    ]
  }

  private onSceneMouseMove(e: MouseEvent): void {
    const highlightDefinition = this.getHighlightDefinition(e)

    if (!highlightDefinition) {
      return
    }

    this.eventEmitter.emit("onThreeDimMouseMove", highlightDefinition)
  }

  private onSceneClick(e: MouseEvent): void {
    const highlightDefinition = this.getHighlightDefinition(e)

    if (!highlightDefinition) {
      return
    }

    this.eventEmitter.emit("onThreeDimMouseClick", highlightDefinition)
  }

  private onSceneTouchEnd(e: TouchEvent): void {
    const highlightDefinition = this.getHighlightDefinition(e.changedTouches[0])

    if (!highlightDefinition) {
      return
    }

    this.eventEmitter.emit("onThreeDimTouchEnd", highlightDefinition)
  }

  private getHighlightDefinition(
    e: MouseEvent | Touch
  ): HighlightDefinition | undefined {
    const model = this.strategy.getModel()

    if (this.isCameraMoving || !model) {
      return
    }

    return this.highlightCalculator.call(model, e)
  }

  private onCameraChange(): void {
    this.isCameraMoving = true
    this.startAnimate()
  }

  private onCameraEnd(): void {
    setTimeout(() => {
      this.isCameraMoving = false
    }, 200)
  }

  private attachResizeObserver(): void {
    this.resizeObserver = new ResizeObserver((entries) => {
      const mountPoint = entries?.[0]

      if (!mountPoint) {
        return
      }

      const width = mountPoint.contentRect.width
      const height = mountPoint.contentRect.height

      this.camera.aspect = width / height
      this.camera.updateProjectionMatrix()

      this.renderer.setSize(width, height)
      this.renderer.render(this.scene, this.camera)
    })

    const { parentElement } = this.renderer.domElement

    if (parentElement) {
      this.resizeObserver.observe(parentElement)
    }
  }

  private detachResizeObserver(): void {
    this.resizeObserver?.disconnect()
  }

  private attachEventListeners(): void {
    if (this.isEventListenersAttached) {
      return
    }

    for (const event of this.events) {
      event.object.addEventListener(event.name, event.fn)
    }

    this.isEventListenersAttached = true
  }

  private detachEventListeners(): void {
    if (!this.isEventListenersAttached) {
      return
    }

    for (const event of this.events) {
      event.object.removeEventListener(event.name, event.fn)
    }

    this.isEventListenersAttached = false
  }

  private setStrategy(modelConfig: ModelConfig): void {
    if (isObjModelConfig(modelConfig)) {
      if (this.strategy instanceof ObjModelStrategy) {
        return
      }

      this.clearStage()
      this.strategy = new ObjModelStrategy(this.vdCanvases, this.config.mode)

      return
    }

    if (isSvgModelConfig(modelConfig)) {
      if (this.strategy instanceof SvgModelStrategy) {
        return
      }

      this.clearStage()
      this.strategy = new SvgModelStrategy(this.vdCanvases, this.config.mode)

      return
    }

    throw new Error("Unsupported model strategy")
  }

  private get rendererSize(): RendererSize {
    const width = this.mountPoint.offsetWidth
    const height = this.mountPoint.offsetHeight

    if (
      ["screenshot", "thumbnail"].includes(this.config.mode) ||
      !width ||
      !height
    ) {
      return this.config.size
    }

    return {
      width: this.mountPoint.offsetWidth,
      height: this.mountPoint.offsetHeight,
    }
  }
}
