import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { BackgroundsLayers } from "../types"
import {
  IndexConfigFragmentBottom,
  IndexConfigFragments,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import { BackgroundImageAdjuster } from "./background-image-adjuster"
import { VirtualDielineRotation } from "../../../../../../libs/products-render-config/types"

export class BackgroundImageController {
  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public async applyBackgroundImage(backgroundImage: PackhelpObject) {
    this.removeBackgroundImage()

    backgroundImage.set({
      id: BackgroundsLayers.BACKGROUND_IMAGE,
      evented: false,
      selectable: false,
      angle: 0,
      originSpaceArea: undefined,
      clipPath: undefined,
      indexConfig: {
        fragment: IndexConfigFragments.BOTTOM,
        index: IndexConfigFragmentBottom.BACKGROUND_IMAGE,
      },
      visible: !this.vdEditor.dielineNavigator.getActiveSpaceId(),
    })

    const vdRotation = VirtualDielineRotation[this.vdEditor.editContext]
    if (vdRotation) {
      backgroundImage.rotate(vdRotation)
    }

    const adjuster = new BackgroundImageAdjuster(this.vdEditor)
    await adjuster.adjust(backgroundImage)

    this.vdEditor.addOnCanvas(backgroundImage)
  }

  public isBackgroundImageActive(): boolean {
    return !!this.getBackgroundImage()
  }

  public getBackgroundImage(): PackhelpObject | undefined {
    return this.vdEditor.getCanvasObjectById(BackgroundsLayers.BACKGROUND_IMAGE)
  }

  public removeBackgroundImage() {
    const backgroundImage = this.getBackgroundImage()

    if (backgroundImage) {
      this.vdEditor.fabricCanvas.remove(backgroundImage)
      this.vdEditor.fabricCanvas.renderAll()
    }
  }
}
