var _a, _b;
import PAPER_MAILER_CONSTS from "../consts/paperMailerConsts";
var PAPER_MAILER_PRODUCT_VARIANTS = (_a = {},
    _a[PAPER_MAILER_CONSTS.PAPER_MAILER] = {
        sizes: [80, 90],
        prefix: "Y",
        productCategory: PAPER_MAILER_CONSTS.PAPER_MAILER,
        variants: (_b = {},
            _b[PAPER_MAILER_CONSTS.PAPER_MAILER_VARIANT_STANDARD] = {
                materials: [PAPER_MAILER_CONSTS.PRINT_MATERIAL_PAPER_KRAFT],
                colorModes: [
                    PAPER_MAILER_CONSTS.PRINT_MODE_MONO_PANTONE_ONE_SIDE,
                    PAPER_MAILER_CONSTS.PRINT_MODE_MONO_PANTONE_TWO_SIDES,
                ],
                finishes: [PAPER_MAILER_CONSTS.PRINT_FINISH_NONE],
                newPrice: true,
            },
            _b),
    },
    _a);
export default PAPER_MAILER_PRODUCT_VARIANTS;
