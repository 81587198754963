var _a, _b, _c, _d, _e;
import LABEL_CONSTS from "../consts/labelConsts";
import LabelProductVariant from "../consts/ProductVariant";
var assetsPath = "/product-key-visuals/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var LabelCircleSizeAssets = {
    SC2: {
        desktop: desktopPath + "SC2.png",
        mobile: mobilePath + "SC2.png",
    },
    SC4: {
        desktop: desktopPath + "SC4.png",
        mobile: mobilePath + "SC4.png",
    },
    SC5: {
        desktop: desktopPath + "SC5.png",
        mobile: mobilePath + "SC5.png",
    },
    SC6: {
        desktop: desktopPath + "SC6.png",
        mobile: mobilePath + "SC6.png",
    },
    SC7: {
        desktop: desktopPath + "SC7.png",
        mobile: mobilePath + "SC7.png",
    },
    SC10: {
        desktop: desktopPath + "SC10.png",
        mobile: mobilePath + "SC10.png",
    },
};
var LabelSquareSizeAssets = {
    SS5: {
        desktop: desktopPath + "SS5.png",
        mobile: mobilePath + "SS5.png",
    },
    SS10: {
        desktop: desktopPath + "SS10.png",
        mobile: mobilePath + "SS10.png",
    },
    SS13: {
        desktop: desktopPath + "SS13.png",
        mobile: mobilePath + "SS13.png",
    },
    SS15: {
        desktop: desktopPath + "SS15.png",
        mobile: mobilePath + "SS15.png",
    },
    SS20: {
        desktop: desktopPath + "SS20.png",
        mobile: mobilePath + "SS20.png",
    },
};
var LabelRectangleSizeAssets = {
    SR7: {
        desktop: desktopPath + "SR7.png",
        mobile: mobilePath + "SR7.png",
    },
    SR10i: {
        desktop: desktopPath + "SR10i.png",
        mobile: mobilePath + "SR10i.png",
    },
    SR10ii: {
        desktop: desktopPath + "SR10ii.png",
        mobile: mobilePath + "SR10ii.png",
    },
    SR11: {
        desktop: desktopPath + "SR11.png",
        mobile: mobilePath + "SR11.png",
    },
    SR12: {
        desktop: desktopPath + "SR12.png",
        mobile: mobilePath + "SR12.png",
    },
    SR12i: {
        desktop: desktopPath + "SR12i.png",
        mobile: mobilePath + "SR12i.png",
    },
    SR12ii: {
        desktop: desktopPath + "SR12ii.png",
        mobile: mobilePath + "SR12ii.png",
    },
    SR12iii: {
        desktop: desktopPath + "SR12iii.png",
        mobile: mobilePath + "SR12iii.png",
    },
    SR13: {
        desktop: desktopPath + "SR13.png",
        mobile: mobilePath + "SR13.png",
    },
    SR13i: {
        desktop: desktopPath + "SR13i.png",
        mobile: mobilePath + "SR13i.png",
    },
    SR14: {
        desktop: desktopPath + "SR14.png",
        mobile: mobilePath + "SR14.png",
    },
    SR14i: {
        desktop: desktopPath + "SR14i.png",
        mobile: mobilePath + "SR14i.png",
    },
    SR14ii: {
        desktop: desktopPath + "SR14ii.png",
        mobile: mobilePath + "SR14ii.png",
    },
    SR15: {
        desktop: desktopPath + "SR15.png",
        mobile: mobilePath + "SR15.png",
    },
    SR15i: {
        desktop: desktopPath + "SR15i.png",
        mobile: mobilePath + "SR15i.png",
    },
    SR15ii: {
        desktop: desktopPath + "SR15ii.png",
        mobile: mobilePath + "SR15ii.png",
    },
    SR15iii: {
        desktop: desktopPath + "SR15iii.png",
        mobile: mobilePath + "SR15iii.png",
    },
    SR16: {
        desktop: desktopPath + "SR16.png",
        mobile: mobilePath + "SR16.png",
    },
    SR16i: {
        desktop: desktopPath + "SR16i.png",
        mobile: mobilePath + "SR16i.png",
    },
    SR16ii: {
        desktop: desktopPath + "SR16ii.png",
        mobile: mobilePath + "SR16ii.png",
    },
    SR17: {
        desktop: desktopPath + "SR17.png",
        mobile: mobilePath + "SR17.png",
    },
    SR17i: {
        desktop: desktopPath + "SR17i.png",
        mobile: mobilePath + "SR17i.png",
    },
    SR17ii: {
        desktop: desktopPath + "SR17ii.png",
        mobile: mobilePath + "SR17ii.png",
    },
    SR18: {
        desktop: desktopPath + "SR18.png",
        mobile: mobilePath + "SR18.png",
    },
    SR18i: {
        desktop: desktopPath + "SR18i.png",
        mobile: mobilePath + "SR18i.png",
    },
    SR18ii: {
        desktop: desktopPath + "SR18ii.png",
        mobile: mobilePath + "SR18ii.png",
    },
    SR18iii: {
        desktop: desktopPath + "SR18iii.png",
        mobile: mobilePath + "SR18iii.png",
    },
    SR19: {
        desktop: desktopPath + "SR19.png",
        mobile: mobilePath + "SR19.png",
    },
    SR19i: {
        desktop: desktopPath + "SR19i.png",
        mobile: mobilePath + "SR19i.png",
    },
    SR19ii: {
        desktop: desktopPath + "SR19ii.png",
        mobile: mobilePath + "SR19ii.png",
    },
    SR19iii: {
        desktop: desktopPath + "SR19iii.png",
        mobile: mobilePath + "SR19iii.png",
    },
    SR20: {
        desktop: desktopPath + "SR20.png",
        mobile: mobilePath + "SR20.png",
    },
    SR20i: {
        desktop: desktopPath + "SR20i.png",
        mobile: mobilePath + "SR20i.png",
    },
    SR20ii: {
        desktop: desktopPath + "SR20ii.png",
        mobile: mobilePath + "SR20ii.png",
    },
    SR20iii: {
        desktop: desktopPath + "SR20iii.png",
        mobile: mobilePath + "SR20iii.png",
    },
    SR22: {
        desktop: desktopPath + "SR22.png",
        mobile: mobilePath + "SR22.png",
    },
    SR22i: {
        desktop: desktopPath + "SR22i.png",
        mobile: mobilePath + "SR22i.png",
    },
    SR23: {
        desktop: desktopPath + "SR23.png",
        mobile: mobilePath + "SR23.png",
    },
    SR23i: {
        desktop: desktopPath + "SR23i.png",
        mobile: mobilePath + "SR23i.png",
    },
    SR23ii: {
        desktop: desktopPath + "SR23ii.png",
        mobile: mobilePath + "SR23ii.png",
    },
    SR24: {
        desktop: desktopPath + "SR24.png",
        mobile: mobilePath + "SR24.png",
    },
    SR24i: {
        desktop: desktopPath + "SR24i.png",
        mobile: mobilePath + "SR24i.png",
    },
    SR25: {
        desktop: desktopPath + "SR25.png",
        mobile: mobilePath + "SR25.png",
    },
    SR25i: {
        desktop: desktopPath + "SR25i.png",
        mobile: mobilePath + "SR25i.png",
    },
    SR26: {
        desktop: desktopPath + "SR26.png",
        mobile: mobilePath + "SR26.png",
    },
    SR27: {
        desktop: desktopPath + "S27R.png",
        mobile: mobilePath + "SR27.png",
    },
    SR27i: {
        desktop: desktopPath + "SR27i.png",
        mobile: mobilePath + "SR27i.png",
    },
    SR29: {
        desktop: desktopPath + "SR29.png",
        mobile: mobilePath + "SR29.png",
    },
    SR29i: {
        desktop: desktopPath + "SR29i.png",
        mobile: mobilePath + "SR29i.png",
    },
    SR29ii: {
        desktop: desktopPath + "SR29ii.png",
        mobile: mobilePath + "SR29ii.png",
    },
    SR29iii: {
        desktop: desktopPath + "SR29iii.png",
        mobile: mobilePath + "SR29iii.png",
    },
    SR30: {
        desktop: desktopPath + "SR30.png",
        mobile: mobilePath + "SR30.png",
    },
    SR31: {
        desktop: desktopPath + "SR31.png",
        mobile: mobilePath + "SR31.png",
    },
    SR33: {
        desktop: desktopPath + "SR33.png",
        mobile: mobilePath + "SR33.png",
    },
    SR34: {
        desktop: desktopPath + "SR34.png",
        mobile: mobilePath + "SR34.png",
    },
    SR37: {
        desktop: desktopPath + "SR37.png",
        mobile: mobilePath + "SR37.png",
    },
    SR39: {
        desktop: desktopPath + "SR39.png",
        mobile: mobilePath + "SR39.png",
    },
    SR44: {
        desktop: desktopPath + "SR44.png",
        mobile: mobilePath + "SR44.png",
    },
};
var PRODUCT_ASSETS = (_a = {},
    _a[LABEL_CONSTS.CIRCLE_LABEL_MODEL] = (_b = {},
        _b[LABEL_CONSTS.CIRCLE_LABEL_VARIANT] = {
            keyVisual: [assetsPath + "labels_desktop.png"],
            sizeAssets: LabelCircleSizeAssets,
        },
        _b),
    _a[LABEL_CONSTS.SQUARE_LABEL_MODEL] = (_c = {},
        _c[LABEL_CONSTS.SQUARE_LABEL_VARIANT] = {
            keyVisual: [assetsPath + "labels_desktop.png"],
            sizeAssets: LabelSquareSizeAssets,
        },
        _c),
    _a[LABEL_CONSTS.RECTANGLE_LABEL_MODEL] = (_d = {},
        _d[LABEL_CONSTS.RECTANGLE_LABEL_VARIANT] = {
            keyVisual: [assetsPath + "labels_desktop.png"],
            sizeAssets: LabelRectangleSizeAssets,
        },
        _d),
    _a);
var EditorImage;
(function (EditorImage) {
    EditorImage["PAPER_MID_GLOSS"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-label-paper-mid-gloss.png";
    EditorImage["SQUARE"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/variants/square-label.png";
    EditorImage["RECTANGLE"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/variants/rectangle-label.png";
    EditorImage["CIRCLE"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/variants/circle-label.png";
})(EditorImage || (EditorImage = {}));
var editorImagePaths = (_e = {},
    _e[LABEL_CONSTS.PRINT_MATERIAL_PAPER_MID_GLOSS] = EditorImage.PAPER_MID_GLOSS,
    _e[LabelProductVariant.SQUARE_LABEL_VARIANT] = EditorImage.SQUARE,
    _e[LabelProductVariant.RECTANGLE_LABEL_VARIANT] = EditorImage.RECTANGLE,
    _e[LabelProductVariant.CIRCLE_LABEL_VARIANT] = EditorImage.CIRCLE,
    _e);
var LABEL_PRODUCT_ASSETS = {
    globalAssets: { editorImagePaths: editorImagePaths },
    productAssets: PRODUCT_ASSETS,
};
export default LABEL_PRODUCT_ASSETS;
