import React, { useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"

import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { FontFamilyPreview } from "../tools/FontFamilyPreview"
import {
  LOADABLE_FONTS_CONFIGS,
  Fonts,
} from "../../../../libs/services/fonts-loader-service/fonts-loader.config"
import { FontFamilyDefinition } from "../../../../libs/services/fonts-loader-service/fonts-loader.service"
import { useHighlightFont } from "../../../hooks/useHighlightFont"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"
interface FontListProps {
  onClick: (fontFamilyDefinition: FontFamilyDefinition) => void
  selectedFontFamily: Fonts
}

export const FontList = observer(
  ({ onClick, selectedFontFamily }: FontListProps) => {
    const { highlightedFontStore } = useHighlightFont({
      onClick,
      selectedFontFamily,
    })

    const selectedRef = useRef<HTMLDivElement>(null)

    const scrollToOnHighlight = (domElement: HTMLElement) => {
      domElement.scrollIntoView({
        behavior: "auto",
        block: "nearest",
      })
    }

    useEffect(() => {
      if (!selectedRef.current) {
        return
      }

      const scrollableContainer =
        selectedRef.current.parentElement?.parentElement

      if (!scrollableContainer) {
        return
      }

      scrollableContainer.scrollTop = selectedRef.current.offsetTop
    }, [])

    const [uiContainer] = useContainer().ui

    if (!uiContainer) {
      return null
    }
    const { fontsConfigStore } = uiContainer
    const fontFamiliesDefinitions = fontsConfigStore.fontFamiliesList

    return (
      <>
        {fontFamiliesDefinitions.map((fontFamilyDefinition) => {
          const checked = selectedFontFamily === fontFamilyDefinition.name
          const highlighted =
            highlightedFontStore.highlightedFont === fontFamilyDefinition

          return (
            <Typography
              type={TType.Header17_500}
              style={{
                fontFamily: fontFamilyDefinition.id,
              }}
              key={fontFamilyDefinition.id}
            >
              <FontFamilyPreview
                innerRef={checked ? selectedRef : undefined}
                label={LOADABLE_FONTS_CONFIGS[fontFamilyDefinition.id].name}
                checked={checked}
                highlighted={highlighted}
                onMouseEnter={() =>
                  highlightedFontStore.setHighlightedFont(fontFamilyDefinition)
                }
                onClick={() => {
                  onClick(fontFamilyDefinition)
                }}
                onHighlight={scrollToOnHighlight}
              />
            </Typography>
          )
        })}
      </>
    )
  }
)
