export type Size = {
  width: number
  height: number
}

export type CartesianCoords = {
  top: number
  left: number
}

export enum DesignStructureObjects {
  VIRTUAL_DIELINE = "virtual_dieline",
}

export enum TempLayers {
  TEMP_BACKGROUND = "temp_background",
  TEMP_PATTERN = "temp_pattern",
  TEMP_BACKGROUND_IMAGE = "temp_background_image",
  SAFE_ZONE = "safe_zone",
  SAFE_OPERATING_ZONE = "safe_operating_zone",
  EDIT_MODE_BACKGROUND_TEXTURE = "edit_mode_background_texture",
  CLIPPING_ZONE = "cliping_zone", // do not correct this typo, it can make as mess with old designs
  GLUE_STRIP = "glue_strip",
  NO_PRINT = "no_print",
  TEMP_DIELINE = "temp_dieline",
  TEMP_DIELINE_STROKE = "temp_dieline_stroke",
  TEMP_DIELINE_SHADOW = "temp_dieline_shadow",
}

export function getTempLayerIds(): string[] {
  return Object.values(TempLayers)
}

export function getDesignStructureObjectIds(): string[] {
  return Object.values(DesignStructureObjects)
}
