import React, { FC } from "react"
import { FieldProps } from "formik"
import TextArea from "dsl/src/atoms/TextArea/TextArea"
import BaseInput from "dsl/src/atoms/BaseInput/BaseInput"
import FormField from "../../molecues/form-field/FormField"

interface TextFieldProps {
  label?: string
  index?: number
  rows?: number
  children?: never
  errorMsg?: string
  hasError?: boolean
}

type TextFieldOwnProps = FieldProps<any> & TextFieldProps

export const FTextInput: FC<TextFieldOwnProps> = ({
  field,
  form: { touched, errors },
  index,
  label,
  errorMsg,
  hasError,
  ...other
}) => {
  const showError = touched[field.name] === true && errors[field.name] != null

  return (
    <FormField
      field={
        <BaseInput medium {...field} {...other} error={showError || hasError} />
      }
      label={label ? label : ""}
      error={((!!errorMsg && showError) || hasError) && errorMsg}
    />
  )
}

export const FTextArea: FC<TextFieldOwnProps> = ({
  field,
  form: { touched, errors },
  index,
  rows,
  label,
  errorMsg,
  hasError,
  ...other
}) => {
  const showError = touched[field.name] === true && errors[field.name] != null

  return (
    <FormField
      field={
        <TextArea
          type="multiline"
          rows={rows}
          {...field}
          {...other}
          error={showError}
        />
      }
      label={label ? label : ""}
      error={((!!errorMsg && showError) || hasError) && errorMsg}
    />
  )
}
