import { UniqueNotifications } from "./notification.store"

export enum NotificationEvents {
  designSaved = "ph.notification.designSaved",
  uniqueNotificationToggled = "ph.notification.uniqueNotificationToggled",
}

export type NotificationEventsTypes = {
  [NotificationEvents.designSaved]: () => void
  [NotificationEvents.uniqueNotificationToggled]: ({
    name,
    isVisible,
  }: {
    name: UniqueNotifications
    isVisible: boolean
  }) => void
}
