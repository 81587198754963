var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addTapeAddonsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getAvailableMaterials = function () {
            var availableMaterials = _super.prototype.getAvailableMaterials.call(this);
            var addonsInProduct = this.product.variantManager.getModelConfig().addonsInVariants;
            var materialImagesPaths = this.product.assetsManager.getGlobalInProductAssets().materialImagesPaths;
            // If material is added to addons, skip it
            var filteredMaterials = availableMaterials.filter(function (material) {
                return addonsInProduct.indexOf(material.id) == -1;
            });
            // added images to material object
            return filteredMaterials.map(function (material) {
                return Object.assign({}, material, {
                    pic: materialImagesPaths[material.id],
                    name: "product-config.tape.material.".concat(material.id, ".name"),
                });
            });
        };
        class_1.prototype.getMaterialsWithInkOptions = function () {
            return [
                {
                    intlTitle: "tape.color-style-controller.foilWhite-with-ink",
                    id: "foilWhite",
                },
                {
                    intlTitle: "tape.color-style-controller.foilTransparent-with-ink",
                    id: "foilTransparent",
                },
            ];
        };
        return class_1;
    }(superclass));
};
export default addTapeAddonsBehaviors;
