import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey, isProductTypeCustom, } from "../SkuLib";
import CustomPaperTubeProduct from "../CustomProducts/CustomPaperTubeProduct";
import PaperTubeProduct from "../Products/PaperTubeProduct/PaperTubeProduct";
import PaperCanVariantManager from "../Products/PaperTubeProduct/behaviorsComposers/PaperTubeVariantManager";
function PaperTubeProductFactory(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    var productVariant = PaperCanVariantManager.findVariant();
    if (isProductTypeCustom(sku)) {
        return new CustomPaperTubeProduct(sku, decodedSKU.productName);
    }
    else {
        return new PaperTubeProduct(sku, decodedSKU.productName, productVariant);
    }
}
export default PaperTubeProductFactory;
