import React from "react"
import {
  WalletDataAttributes,
  WalletLevelTypes,
} from "../../utils/wallet.types"
import { walletI18n } from "../../utils/wallet.translations"
import { useFormatMessage } from "../../../../../libs/others/i18n"
import { WalletIcon } from "dsl/src/assets/_svgr/UI/Wallet"
import { CloseIcon } from "dsl/src/assets/_svgr/icons/Close"
import styles from "./WalletSidebarHeader.module.scss"

type WalletHeaderProps = {
  walletData?: WalletDataAttributes
  onCloseButtonClick: () => void
}

const CloseButton = (props: { onClick?: () => void }) => (
  <span
    onClick={props.onClick}
    className={styles["close-button"]}
    e2e-target="wallet-button-close"
  >
    <CloseIcon className={styles["close-button__icon"]} />
  </span>
)

export const WalletSidebarHeader = (props: WalletHeaderProps) => {
  const translate = useFormatMessage()
  const { walletData, onCloseButtonClick } = props
  return (
    <div className={styles.title}>
      <span className={styles["title-content"]}>
        <WalletIcon className={styles["wallet-icon-black"]} />

        {translate({ id: walletI18n.genericTitle })}
        {walletData && walletData.current_step > 0 && (
          <>
            {" "}
            - {translate({ id: walletI18n.newLevel })} {walletData.current_step}{" "}
            / {WalletLevelTypes.LEVEL_4}
          </>
        )}
      </span>
      <CloseButton onClick={onCloseButtonClick} />
    </div>
  )
}
