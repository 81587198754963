import React from "react"
import styles from "./RangeOption.module.scss"
import TextField from "dsl/src/atoms/TextField/TextField"
import cxBinder from "classnames/bind"

import { MonochromeSlider } from "../../../molecues/monochrome-slider/MonochromeSlider"

const cx = cxBinder.bind(styles)

interface ThresholdRangeOptionProps {
  label: string
  value: number
  onChange: (value: number) => void
  isPreviewDisabled?: boolean
}
export const ThresholdRangeOption = ({
  value,
  label,
  onChange,
  isPreviewDisabled,
}: ThresholdRangeOptionProps) => {
  const humanReadableRangeValue = (Math.round(value * 10) / 10).toString()

  return (
    <div className={styles.row}>
      <div
        className={cx({
          label: true,
          label__long: isPreviewDisabled,
        })}
      >
        {label}
      </div>
      <div className={styles.slider}>
        <MonochromeSlider value={value} onChange={onChange} />
      </div>
      <div
        className={isPreviewDisabled ? styles.preview_hidden : styles.preview}
      >
        <TextField
          value={humanReadableRangeValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(parseFloat(e.target.value))
          }}
          disabled
        />
      </div>
    </div>
  )
}
