import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import cxBinder from "classnames/bind"
import ThreeDimensionalScene from "../../dsl/organisms/ThreeDimensionalScene/ThreeDimensionalScene"
import { TwoDimensionalScene } from "../../dsl/organisms/TwoDimensionalScene/TwoDimensionalScene"
import {
  RendererMountPoints,
  TwoDimensionalMountPoints,
} from "../../../render-engine/types"
import styles from "./ScenesRenderesController.module.scss"
import {
  EditContext,
  ModelEditableSpaces,
  ViewType,
} from "../../../libs/products-render-config/types"
import { useRootStore } from "../../hooks/useRootStore"
import { CanvasLoader } from "../../dsl/organisms/CanvasLoader/CanvasLoader"
import { useContainer } from "../../../_containers-react/_editor-app-hooks"

const cx = cxBinder.bind(styles)

export type RendererScenesControllerProps = {
  activeViewType: ViewType
  activeSpace: ModelEditableSpaces | null
  activeContext: EditContext
  is3DPreviewOn: boolean
  renderEngineDomMounter: (object: RendererMountPoints) => void
}

const RendererScenesController = ({
  activeViewType,
  activeSpace,
  activeContext,
  is3DPreviewOn,
  renderEngineDomMounter,
}: RendererScenesControllerProps) => {
  const [threeDimensionalMountPoint, setThreeDimensionalMountPoint] =
    useState<HTMLDivElement | null>(null)
  const [twoDimensionalMountPoints, setTwoDimensionalMountPoint] =
    useState<TwoDimensionalMountPoints | null>(null)

  useEffect(() => {
    if (threeDimensionalMountPoint && twoDimensionalMountPoints) {
      renderEngineDomMounter({
        threeDimensionalRendererMountPoint: threeDimensionalMountPoint,
        virtualDielineMountPoints: twoDimensionalMountPoints,
      })
    }
  }, [threeDimensionalMountPoint, twoDimensionalMountPoints])

  const [designAndProductDriver] = useContainer().designAndProductDriver
  if (!designAndProductDriver) return null

  const isModelView = is3DPreviewOn || activeViewType === ViewType.MODEL

  const onThreeDimensionalSceneMount = (mountpoint: HTMLDivElement) => {
    setThreeDimensionalMountPoint(mountpoint)
  }

  const onTwoDimensionalSceneMount = (mountpoints) => {
    setTwoDimensionalMountPoint(mountpoints)
  }

  const twoDimensionalSceneWrapper = (isModelView) => {
    if (!isModelView) {
      return cx({
        scene_wrapper: true,
        "scene_wrapper--two_dimensional": true,
        "scene_wrapper--two_dimensional--show": true,
      })
    }

    return cx({
      scene_wrapper: true,
      "scene_wrapper--two_dimensional": true,
    })
  }

  return (
    <div
      className={cx({
        scenes_renderer_controller: true,
      })}
    >
      <div
        className={cx({
          scene_wrapper: true,
          "scene_wrapper--three_dimensional--hide": isModelView,
        })}
      >
        <ThreeDimensionalScene onSceneMount={onThreeDimensionalSceneMount} />
      </div>
      <div className={twoDimensionalSceneWrapper(isModelView)}>
        <TwoDimensionalScene
          availableEditContexts={Object.values(EditContext)}
          activeContextId={activeContext}
          activeSpaceId={activeSpace}
          isModelView={isModelView}
          onSceneMount={onTwoDimensionalSceneMount}
        />
      </div>
      <CanvasLoader />
    </div>
  )
}

export const RendererScenesControllerWired = observer(() => {
  const { productDriver, productDesignStore, uri } = useRootStore()
  const { activeViewType, activeSpace, is3DPreviewOn, activeContext } =
    productDriver.state

  const { meta: productDesignMeta, isDesignInCart } = productDesignStore.state

  const registerMountpoints = (
    mountPointsToRegister: RendererMountPoints
  ): void => {
    productDriver.renderEngineDomMounter({
      ...mountPointsToRegister,
      isDesignPreviewMode: isDesignInCart || productDesignMeta.dataReadOnly,
      isDtpPreviewMode: uri.isDtpPreviewMode(),
    })
  }

  return (
    <RendererScenesController
      activeViewType={activeViewType}
      activeSpace={activeSpace}
      activeContext={activeContext}
      is3DPreviewOn={is3DPreviewOn}
      renderEngineDomMounter={registerMountpoints}
    />
  )
})
