import _flow from "lodash/flow";
import SamplePackConfigBuilder from "../configBuilders/SamplePackConfigBuilder";
import addCustomProductDimensionsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductDimensionsBehaviors";
import addCustomProductProductionTimesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductProductionTimesBehaviors";
import addCustomProductVariantBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductVariantBehaviors";
import addCustomProductEditConfigBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductEditConfigBehaviors";
import addCustomProductGrammagesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductGrammagesBehaviors";
import addCustomProductTranslationsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductTranslationsBehaviors";
import addSamplePackVariantBehaviors from "../behaviors/samplePackBehaviors/addSamplePackVariantBehaviors";
import addSamplePackTranslationsBehaviors from "../behaviors/samplePackBehaviors/addSamplePackTranslationsBehaviors";
import addSamplePackProductionTimeBehaviors from "../behaviors/samplePackBehaviors/addSamplePackProductionTimeBehaviors";
import addSamplePackEditConfigBehaviors from "../behaviors/samplePackBehaviors/addSamplePackEditConfigbBehaviors";
import addSamplePackDimensionsBehaviors from "../behaviors/samplePackBehaviors/addSamplePackDimensionsBehaviors";
import TranslationsManager from "../behaviorsComposers/TranslationsManager";
import VariantManager from "../behaviorsComposers/VariantsManager";
import GrammagesManager from "../behaviorsComposers/GrammagesManager";
import DimensionsManager from "../behaviorsComposers/DimensionsManager";
import WizzardManager from "../behaviorsComposers/WizzardManager";
import EditConfigManager from "../behaviorsComposers/EditConfigManager";
import ProductionTimeManager from "../behaviorsComposers/ProductionTimeManager";
var CustomSamplePackProductVariantManager = _flow([
    addSamplePackVariantBehaviors,
    addCustomProductVariantBehaviors,
])(VariantManager);
var CustomSamplePackProductionTimeManager = _flow([
    addCustomProductProductionTimesBehaviors,
    addSamplePackProductionTimeBehaviors,
])(ProductionTimeManager);
var CustomSamplePackDimensionsManager = _flow([
    addSamplePackDimensionsBehaviors,
    addCustomProductDimensionsBehaviors,
])(DimensionsManager);
var CustomSamplePackProductGrammagesManager = _flow(addCustomProductGrammagesBehaviors)(GrammagesManager);
var CustomSamplePackEditorConfigManager = _flow([
    addSamplePackEditConfigBehaviors,
    addCustomProductEditConfigBehaviors,
])(EditConfigManager);
var CustomSamplePackTranslationsManager = _flow([
    addSamplePackTranslationsBehaviors,
    addCustomProductTranslationsBehaviors,
])(TranslationsManager);
var CustomSamplePackProduct = /** @class */ (function () {
    function CustomSamplePackProduct(sku) {
        this.config = SamplePackConfigBuilder(sku);
        this.variantManager = new CustomSamplePackProductVariantManager(this);
        this.dimensionsManager = new CustomSamplePackDimensionsManager(this);
        this.translationsManager = new CustomSamplePackTranslationsManager(this);
        this.wizzardManager = new WizzardManager(this);
        this.editConfigManager = new CustomSamplePackEditorConfigManager(this);
        this.grammagesManager = new CustomSamplePackProductGrammagesManager(this);
        this.productionTimeManager = new CustomSamplePackProductionTimeManager(this);
    }
    return CustomSamplePackProduct;
}());
export default CustomSamplePackProduct;
