import { UrlManipulatorProvider } from "../../services/url-manipulator"
import { ProductDesignStore } from "../product-design-store/product-design.store"
import { EditorMode } from "../../libs/products-render-config/types"
import { ProductStore } from "../product-driver/product.store"
import { Cart } from "../cart-store/cart.store"

export class EditorModeController {
  constructor(
    private readonly productStore: ProductStore,
    private readonly productDesignStore: ProductDesignStore,
    private readonly uri: UrlManipulatorProvider,
    private readonly isAfterPurchaseEdit: boolean,
    private readonly cart?: Cart
  ) {}

  public switchToDbyMode() {
    this.switchToMode("dby")
  }

  public switchToEditorMode() {
    this.switchToMode("editor")
  }

  private switchToMode = (mode: EditorMode) => {
    const { quantity } = this.productDesignStore.state.meta

    const shouldDiscardDesignOnModeSwitch = !(
      this.isAfterPurchaseEdit || this.isDraftDesignAlreadyInCart
    )

    this.uri.switchToMode(
      this.productStore.productSku,
      quantity,
      mode,
      shouldDiscardDesignOnModeSwitch
    )
  }

  private get isDraftDesignAlreadyInCart(): boolean {
    if (!this.cart) {
      return false
    }

    return this.cart.line_items.some((item) => {
      if (!item.design) {
        return false
      }

      return item.design.id === this.productDesignStore.state.meta.id
    })
  }
}
