import React from "react"

import { Notification } from "dsl/src/molecules/Notification/Notification"

import { useTranslate } from "../i18n/useTranslate"
import styles from "./VasWarningBanner.module.scss"

type VasWarningBanner = {
  bannerTranslationKey: string
}

export const VasWarningBanner = ({
  bannerTranslationKey,
}: VasWarningBanner) => {
  const translate = useTranslate()

  return (
    <div
      e2e-target-name="vas-warning-banner"
      className={styles["vas-warning-banner-wrapper"]}
    >
      <Notification>{translate(bannerTranslationKey)}</Notification>
    </div>
  )
}
