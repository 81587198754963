import { datadogRum } from "@datadog/browser-rum"
import { DesignVersion } from "../../modules/design/version"
import { SESSION_COOKIE_NAME } from "@datadog/browser-core"

// Just to speed up tests of performance monitoring with DataDog, we can change it later to something lighter, if needed
import Cookies from "js-cookie"
import { DatadogEnvConfig } from "../../app-config/types"

// Landing allows to monitor only 5% of traffic.
// We want to monitor more, but we don't want to create new session
// each time user enters editor. One may want to refresh the page or
// switch to another product or size. We want him to use the same session id.
// If we remove cookie, we will create new session.
// That's why we replace value inside already existing cookie.
// Example of the cookie value: rum=1&id=9491435d-53c1-4055-90dc-9e197813c991&created=1623393080667&expire=1623394842142
const manipulateDatadogCookieIfNeeded = (sampleRate: number) => {
  const value = Cookies.get(SESSION_COOKIE_NAME) || ""

  const isEnabled = value.includes("rum=1")

  if (!isEnabled && sampleRate > 0) {
    const throwDiceResult = Math.random() * 100

    if (throwDiceResult <= sampleRate) {
      Cookies.set(SESSION_COOKIE_NAME, value.replace("rum=0", "rum=1"))
    }
  }
}

export const initDatadog = (config: DatadogEnvConfig) => {
  const sampleRate = config.sampleRate || 0
  manipulateDatadogCookieIfNeeded(sampleRate)

  datadogRum.init({
    applicationId: config.appId || "",
    clientToken: config.clientToken || "",
    site: "datadoghq.com",
    service: config.service || "packhelp-editor",
    env: config.env,
    version: DesignVersion.latest.toString(),
    sampleRate: Number(sampleRate),
    trackInteractions: true,
    enableExperimentalFeatures: ["view-renaming"],
  })
}
