import React from "react"
import styles from "./PatternsConfigurator.module.scss"
import { TType } from "dsl/src/atoms/Typography/Typography"
import { ColorConfigurator } from "../configurators/ColorConfigurator/ColorConfigurator"
import { PickerToggle } from "../../atoms/PickerToggle/PickerToggle"
import {
  getPatternEditableColorGroups,
  Pattern,
  PatternColours,
} from "../../../../libs/value-objects/pattern"
import { TM } from "../../../TypographyI18n"
import {
  AvailableColourModes,
  ColoursPreset,
} from "../../../../libs/products-render-config/types"

export type PatternConfiguratorHeaderProps = {
  currentPattern: Pattern | undefined
  setPatternsDefaultColours: () => void
  setPatternsInitialColours: () => void
  onPatternApproved: () => void
  onPatternReject: () => void
  arePatternsTouched: boolean
  onColoursSelect: (coloursMap: PatternColours) => void
  coloursPreset: ColoursPreset
}

type PatternColoursList = {
  colorPreset: ColoursPreset
  pattern: Pattern
  onPatternReject: () => void
  onPatternApproved: () => void
  onColoursSelect: (coloursMap: PatternColours) => void
}

const PatternColoursList = ({
  colorPreset,
  pattern,
  onPatternReject,
  onColoursSelect,
  onPatternApproved,
}: PatternColoursList) => {
  const coloursConfig = pattern.colorsConfig

  const onColourSelect = ({ id, colour }) => {
    const coloursMap = {}
    Object.keys(coloursConfig).forEach((key) => {
      if (id === key) {
        coloursMap[id] = {
          param: coloursConfig[id].param,
          colour: colour,
        }
      } else {
        coloursMap[key] = coloursConfig[key]
      }
    })
    onColoursSelect(coloursMap)
  }

  return (
    <nav className={styles.colorpicker_toggle_nav}>
      {getPatternEditableColorGroups(coloursConfig, colorPreset).map(
        (colourId, index) => {
          const config = coloursConfig[colourId]

          if (!config) {
            return null
          }

          return (
            <ColorConfigurator
              key={index}
              preventHideClickOutside={true}
              onColorSelect={(colour) => {
                onColourSelect({
                  id: colourId,
                  colour: colour,
                })
              }}
              onBackClick={onPatternReject}
              onDoneClick={onPatternApproved}
              selectedColor={config.colour}
            />
          )
        }
      )}
    </nav>
  )
}

export const PatternConfiguratorHeader = ({
  onPatternReject,
  onColoursSelect,
  onPatternApproved,
  setPatternsDefaultColours,
  setPatternsInitialColours,
  currentPattern,
  arePatternsTouched,
  coloursPreset,
}: PatternConfiguratorHeaderProps) => {
  const setPatternEditMode = () => {
    setPatternsDefaultColours()
  }

  const setPatternDefaultMode = () => {
    setPatternsInitialColours()
  }

  const isMonochrome = coloursPreset.mode === AvailableColourModes.MONOCHROME

  return (
    <div className={styles.patterns_header_sticky}>
      <TM
        className={styles.patterns_header}
        type={TType.Header17_500}
        htmlElement="h2"
        id="component.editor-tool-style.title.patterns"
      />

      {!isMonochrome && (
        <>
          <div className={styles.patterns_config_wrapper}>
            <TM
              className={styles.main_label}
              type={TType.Body15_350}
              htmlElement="p"
              id="component.pattern-configurator.colours"
            />
            {!arePatternsTouched && !currentPattern && <PickerToggle />}
            {currentPattern && !arePatternsTouched && (
              <TM
                onClick={() => setPatternEditMode()}
                className={styles.more_colours}
                id="component.patterns-configurator.header.apply-own-colours"
                type={TType.Body15_350}
                htmlElement="a"
                e2eTarget="button"
                e2eTargetName="apply-pattern-colors"
              />
            )}
            {currentPattern && arePatternsTouched && (
              <PatternColoursList
                colorPreset={coloursPreset}
                pattern={currentPattern}
                onPatternReject={onPatternReject}
                onColoursSelect={onColoursSelect}
                onPatternApproved={onPatternApproved}
              />
            )}
          </div>
          {currentPattern && arePatternsTouched && (
            <div className={styles["patterns_config_wrapper--reset-container"]}>
              <TM
                onClick={setPatternDefaultMode}
                className={styles.reset_colours}
                id="component.patterns-configurator.header.reset-to-default"
                type={TType.Body13_350}
                htmlElement="a"
                e2eTarget="button"
                e2eTargetName="reset-pattern-colors"
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}
