import React from "react"
import { Switch } from "dsl/src/atoms/Switch/Switch"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { EditorToolAvailabilityTooltip } from "../editor-tool-availability-tooltip/EditorToolAvailabilityTooltip"
import styles from "./EditContextSwitcher.module.scss"
import { I18N } from "../../../../../i18n"
import { useContainer } from "../../../../../../_containers-react/_editor-app-hooks"

export const i18n = I18N.component

export const EditContextSwitcherPureComponent = ({
  onChange,
  isPrintInsideActive,
}) => {
  const t = useTranslate()
  let titleIntl = i18n.printInside.title
  let labelSecondaryIntl = i18n.printInsideOverlay.labels.secondary

  const [designAndProductDriverContainer] =
    useContainer().designAndProductDriver

  if (!designAndProductDriverContainer) {
    return null
  }
  const { productRenderPilot } =
    designAndProductDriverContainer.productDriver.state

  if (!productRenderPilot.is3DProduct()) {
    titleIntl = i18n.printBack.title
    labelSecondaryIntl = i18n.printBackOverlay.labels.secondary
  }

  return (
    <div className={styles.tool}>
      <Switch
        onChange={onChange}
        checked={isPrintInsideActive}
        e2eTarget="edit-context-switcher"
      />
      {!isPrintInsideActive && (
        <EditorToolAvailabilityTooltip
          primaryLabel={t(i18n.printInsideOverlay.labels.primary)}
          secondaryLabel={t(labelSecondaryIntl)}
        />
      )}
      <span className={styles.tool__label}>{t(titleIntl)}</span>
    </div>
  )
}
