import FormControlLabel from "dsl/src/atoms/FormControlLabel/FormControlLabel"
import React from "react"
import { Checkbox } from "dsl/src/atoms/Checkbox/Checkbox"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { getFormattedPrice } from "shared-libs/src/js/shared-views/sustainability/planting-trees/reforestation-calculator/reforestation-calculator.utils"

import styles from "./CheckboxFormBlock.module.scss"
import { Loader, LoaderTypes } from "dsl/src/atoms/Loader/Loader"

export const CheckboxFormBlock = ({
  isChecked,
  isDisabled,
  onChange,
  title,
  price,
  currency,
  children,
  e2eTargetName,
  footer,
}: {
  isChecked: boolean
  isDisabled?: boolean
  onChange: () => void
  title: string
  price: number
  currency: string
  children?: React.ReactNode
  e2eTargetName: string
  footer?: React.ReactNode
}) => {
  return (
    <div className={styles.wrapper}>
      <FormControlLabel
        fluid
        control={
          <Checkbox
            checked={isChecked}
            disabled={isDisabled}
            onChange={onChange}
            e2eTargetName={e2eTargetName}
          />
        }
      >
        <div className={styles.label}>
          <Typography type={TType.Body15_350}>{title}</Typography>
          {price > 0 && (
            <Typography type={TType.Body15_350} className={styles.price}>
              + {getFormattedPrice(price, currency)}
            </Typography>
          )}
        </div>
      </FormControlLabel>
      {children && <div className={styles.content}>{children}</div>}
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  )
}
