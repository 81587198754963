import { UIConfig } from "../types"

export const getUiConfig = (): UIConfig => {
  return {
    features: {
      mask: false,
      textEffects: true,
      templates: false,
      patterns: false,
      replicablePatterns: false,
      plantingTrees: false,
      backgroundColor: false,
      backgroundImage: false,
      fsc: false,
    },
    tabs: {
      logo: false,
      logoPlaceholder: false,
      style: false,
      background: false,
      patterns: false,
      eco: false,
      images: true,
      text: true,
      shapes: true,
      templates: false,
      materialAndColor: false,
      replicablePatterns: false,
      fsc: false,
      productConfiguration: false,
    },
    fontSize: {
      dtp: {
        min: 14,
      },
      editor: {
        min: 14,
        max: 200,
      },
    },
    editZone: {
      rulers: {
        lines: "none",
        dimensions: false,
      },
      controlsTheme: "dark",
      safeZoneSizeMm: 0,
      zoom: {
        available: false,
        activeByDefault: false,
      },
    },
    globalDropzone: {
      mode: "images",
    },

    /**
     * probably doesn't really apply here
     */
    thumbnail: {
      backgroundTextureEnabled: false,
      editZoneBackgroundTextureEnabled: false,
    },
    bleedSizeMm: 0,
  }
}
