var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44;
import Product from "../consts/Product";
import ProductVariant from "../consts/ProductVariant";
import PRODUCTION_TIMES_CONSTS from "../../../productSharedConsts/productionTimeConsts";
var DEFAULT = PRODUCTION_TIMES_CONSTS.DEFAULT, EXPRESS = PRODUCTION_TIMES_CONSTS.EXPRESS, SUPEREXPRESS = PRODUCTION_TIMES_CONSTS.SUPEREXPRESS, PRODUCTION_TIME = PRODUCTION_TIMES_CONSTS.PRODUCTION_TIME, DTP_DEADLINE = PRODUCTION_TIMES_CONSTS.DTP_DEADLINE, HIGH_QUANTITY = PRODUCTION_TIMES_CONSTS.HIGH_QUANTITY;
export default (_a = {},
    _a[Product.PRE_PRINTED_MAILER_BOX_FULL_COLOUR] = (_b = {},
        _b[ProductVariant.PRE_PRINTED_MAILER_BOX_FULL_COLOUR_VARIANT] = (_c = {},
            _c[PRODUCTION_TIME] = (_d = {},
                _d[DEFAULT] = 3,
                _d[EXPRESS] = 3,
                _d[SUPEREXPRESS] = 3,
                _d[HIGH_QUANTITY] = (_e = {},
                    _e[2500] = { min: 17, max: 32 },
                    _e),
                _d),
            _c[DTP_DEADLINE] = (_f = {},
                _f[DEFAULT] = 0,
                _f),
            _c),
        _b),
    _a[Product.PRE_PRINTED_MAILER_BOX_ECO] = (_g = {},
        _g[ProductVariant.PRE_PRINTED_MAILER_BOX_ECO_VARIANT] = (_h = {},
            _h[PRODUCTION_TIME] = (_j = {},
                _j[DEFAULT] = 3,
                _j[EXPRESS] = 3,
                _j[SUPEREXPRESS] = 3,
                _j[HIGH_QUANTITY] = (_k = {},
                    _k[2500] = { min: 17, max: 32 },
                    _k),
                _j),
            _h[DTP_DEADLINE] = (_l = {},
                _l[DEFAULT] = 0,
                _l),
            _h),
        _g),
    _a[Product.MAILER_BOX] = (_m = {},
        _m[ProductVariant.MAILER_BOX_ECO_VARIANT] = (_o = {},
            _o[PRODUCTION_TIME] = (_p = {},
                _p[DEFAULT] = 16,
                _p[EXPRESS] = 11,
                _p[SUPEREXPRESS] = 4,
                _p[HIGH_QUANTITY] = (_q = {},
                    _q[2500] = { min: 12, max: 17 },
                    _q),
                _p),
            _o[DTP_DEADLINE] = (_r = {},
                _r[DEFAULT] = 1,
                _r),
            _o),
        _m[ProductVariant.MAILER_BOX_ECO_WHITE_VARIANT] = (_s = {},
            _s[PRODUCTION_TIME] = (_t = {},
                _t[DEFAULT] = 16,
                _t[EXPRESS] = 11,
                _t[SUPEREXPRESS] = 4,
                _t[HIGH_QUANTITY] = (_u = {},
                    _u[2500] = { min: 12, max: 17 },
                    _u),
                _t),
            _s[DTP_DEADLINE] = (_v = {},
                _v[DEFAULT] = 1,
                _v),
            _s),
        _m[ProductVariant.MAILER_BOX_ECO_COLOR_VARIANT] = (_w = {},
            _w[PRODUCTION_TIME] = (_x = {},
                _x[DEFAULT] = 16,
                _x[EXPRESS] = 11,
                _x[SUPEREXPRESS] = 7,
                _x[HIGH_QUANTITY] = (_y = {},
                    _y[1000] = { min: 17, max: 32 },
                    _y),
                _x),
            _w[DTP_DEADLINE] = (_z = {},
                _z[DEFAULT] = 1,
                _z),
            _w),
        _m[ProductVariant.MAILER_BOX_LITE_COLOR_VARIANT] = (_0 = {},
            _0[PRODUCTION_TIME] = (_1 = {},
                _1[DEFAULT] = 16,
                _1[EXPRESS] = 11,
                _1[SUPEREXPRESS] = 4,
                _1[HIGH_QUANTITY] = (_2 = {},
                    _2[2500] = { min: 12, max: 17 },
                    _2),
                _1),
            _0[DTP_DEADLINE] = (_3 = {},
                _3[DEFAULT] = 1,
                _3),
            _0),
        _m[ProductVariant.MAILER_BOX_OLD_COLOR_BUDGET_VARIANT] = (_4 = {},
            _4[PRODUCTION_TIME] = (_5 = {},
                _5[DEFAULT] = 16,
                _5[EXPRESS] = 11,
                _5[SUPEREXPRESS] = 11,
                _5[HIGH_QUANTITY] = (_6 = {},
                    _6[2500] = { min: 17, max: 32 },
                    _6),
                _5),
            _4[DTP_DEADLINE] = (_7 = {},
                _7[DEFAULT] = 1,
                _7),
            _4),
        _m[ProductVariant.MAILER_BOX_FULL_COLOR_VARIANT] = (_8 = {},
            _8[PRODUCTION_TIME] = (_9 = {},
                _9[DEFAULT] = 16,
                _9[EXPRESS] = 11,
                _9[SUPEREXPRESS] = 7,
                _9[HIGH_QUANTITY] = (_10 = {},
                    _10[2500] = { min: 17, max: 22 },
                    _10),
                _9),
            _8[DTP_DEADLINE] = (_11 = {},
                _11[DEFAULT] = 1,
                _11),
            _8),
        _m),
    _a[Product.MAILER_BOX_WHITE_INK] = (_12 = {},
        _12[ProductVariant.MAILER_BOX_WHITE_ON_KRAFT_VARIANT] = (_13 = {},
            _13[PRODUCTION_TIME] = (_14 = {},
                _14[DEFAULT] = 16,
                _14[EXPRESS] = 11,
                _14[SUPEREXPRESS] = 7,
                _14[HIGH_QUANTITY] = (_15 = {},
                    _15[2500] = { min: 12, max: 17 },
                    _15),
                _14),
            _13[DTP_DEADLINE] = (_16 = {},
                _16[DEFAULT] = 1,
                _16),
            _13),
        _12),
    _a[Product.MAILER_BOX_PLAIN] = (_17 = {},
        _17[ProductVariant.MAILER_BOX_PLAIN_DEFAULT_VARIANT] = (_18 = {},
            _18[PRODUCTION_TIME] = (_19 = {},
                _19[DEFAULT] = 6,
                _19[EXPRESS] = 6,
                _19[SUPEREXPRESS] = 6,
                _19[HIGH_QUANTITY] = (_20 = {},
                    _20[2500] = { max: 12 },
                    _20),
                _19),
            _18[DTP_DEADLINE] = (_21 = {},
                _21[DEFAULT] = 0,
                _21),
            _18),
        _17[ProductVariant.MAILER_BOX_PLAIN_WHITE_VARIANT] = (_22 = {},
            _22[PRODUCTION_TIME] = (_23 = {},
                _23[DEFAULT] = 6,
                _23[EXPRESS] = 6,
                _23[SUPEREXPRESS] = 6,
                _23[HIGH_QUANTITY] = (_24 = {},
                    _24[2500] = { max: 12 },
                    _24),
                _23),
            _22[DTP_DEADLINE] = (_25 = {},
                _25[DEFAULT] = 0,
                _25),
            _22),
        _17),
    _a[Product.SLEEVED_MAILER_BOX] = (_26 = {},
        _26[ProductVariant.SLEEVED_MAILER_BOX_VARIANT] = (_27 = {},
            _27[PRODUCTION_TIME] = (_28 = {},
                _28[DEFAULT] = 11,
                _28[EXPRESS] = 6,
                _28[SUPEREXPRESS] = 3,
                _28[HIGH_QUANTITY] = (_29 = {},
                    _29[2500] = { min: 17, max: 22 },
                    _29),
                _28),
            _27[DTP_DEADLINE] = (_30 = {},
                _30[DEFAULT] = 1,
                _30),
            _27),
        _26),
    _a[Product.DELIVERY_MAILER_BOX] = (_31 = {},
        _31[ProductVariant.DELIVERY_MAILER_BOX_VARIANT] = (_32 = {},
            _32[PRODUCTION_TIME] = (_33 = {},
                _33[DEFAULT] = 16,
                _33[EXPRESS] = 11,
                _33[SUPEREXPRESS] = 11,
                _33[HIGH_QUANTITY] = (_34 = {},
                    _34[2500] = { min: 12, max: 15 },
                    _34),
                _33),
            _32[DTP_DEADLINE] = (_35 = {},
                _35[DEFAULT] = 1,
                _35),
            _32),
        _31),
    _a[Product.DELIVERY_MAILER_BOX_PLAIN] = (_36 = {},
        _36[ProductVariant.DELIVERY_MAILER_BOX_PLAIN_VARIANT] = (_37 = {},
            _37[PRODUCTION_TIME] = (_38 = {},
                _38[DEFAULT] = 3,
                _38[EXPRESS] = 3,
                _38[SUPEREXPRESS] = 3,
                _38[HIGH_QUANTITY] = (_39 = {},
                    _39[2400] = { min: 12, max: 17 },
                    _39),
                _38),
            _37[DTP_DEADLINE] = (_40 = {},
                _40[DEFAULT] = 0,
                _40),
            _37),
        _36[ProductVariant.DELIVERY_MAILER_BOX_PLAIN_WHITE_VARIANT] = (_41 = {},
            _41[PRODUCTION_TIME] = (_42 = {},
                _42[DEFAULT] = 3,
                _42[EXPRESS] = 3,
                _42[SUPEREXPRESS] = 3,
                _42[HIGH_QUANTITY] = (_43 = {},
                    _43[2400] = { min: 12, max: 17 },
                    _43),
                _42),
            _41[DTP_DEADLINE] = (_44 = {},
                _44[DEFAULT] = 0,
                _44),
            _41),
        _36),
    _a);
