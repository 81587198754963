var _a;
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import TAPE_CONSTS from "../../../source/tapeProductSpec/generalConfig/consts/tapeConsts";
import BOX_CONSTS from "../../../source/boxProductSpec/generalConfig/consts/boxConsts";
dayjs.extend(isSameOrAfter);
// This helper was created to turn off functionality for specific products
// There's ongoing initiative to create special system for this type of requirements
// add product sku's that should not be reorderable
var notOrderableProductSkuList = [
    // paper mailer size 60
    "paper-mailer-eu--paper-mailer--60--paper-kraft--mono-pantone-one-side--none",
    "paper-mailer-eu--paper-mailer--60--paper-kraft--mono-pantone-two-sides--none",
    "paper-mailer-de--paper-mailer--60--paper-kraft--mono-pantone-one-side--none",
    "paper-mailer-de--paper-mailer--60--paper-kraft--mono-pantone-two-sides--none",
    "paper-mailer-pl--paper-mailer--60--paper-kraft--mono-pantone-one-side--none",
    "paper-mailer-pl--paper-mailer--60--paper-kraft--mono-pantone-two-sides--none",
    "paper-mailer-cz--paper-mailer--60--paper-kraft--mono-pantone-one-side--none",
    "paper-mailer-cz--paper-mailer--60--paper-kraft--mono-pantone-two-sides--none",
    "paper-mailer-it--paper-mailer--60--paper-kraft--mono-pantone-one-side--none",
    "paper-mailer-it--paper-mailer--60--paper-kraft--mono-pantone-two-sides--none",
    "paper-mailer-es--paper-mailer--60--paper-kraft--mono-pantone-one-side--none",
    "paper-mailer-es--paper-mailer--60--paper-kraft--mono-pantone-two-sides--none",
    "paper-mailer-fr--paper-mailer--60--paper-kraft--mono-pantone-one-side--none",
    "paper-mailer-fr--paper-mailer--60--paper-kraft--mono-pantone-two-sides--none",
    // paper bag size 63
    "bag-eu--paper-bag--63--paper-white--mono-pantone-one-side--none",
    "bag-eu--paper-bag--63--paper-white--mono-pantone-two-sides--none",
    "bag-de--paper-bag--63--paper-white--mono-pantone-one-side--none",
    "bag-de--paper-bag--63--paper-white--mono-pantone-two-sides--none",
    "bag-pl--paper-bag--63--paper-white--mono-pantone-one-side--none",
    "bag-pl--paper-bag--63--paper-white--mono-pantone-two-sides--none",
    "bag-cz--paper-bag--63--paper-white--mono-pantone-one-side--none",
    "bag-cz--paper-bag--63--paper-white--mono-pantone-two-sides--none",
    "bag-it--paper-bag--63--paper-white--mono-pantone-one-side--none",
    "bag-it--paper-bag--63--paper-white--mono-pantone-two-sides--none",
    "bag-es--paper-bag--63--paper-white--mono-pantone-one-side--none",
    "bag-es--paper-bag--63--paper-white--mono-pantone-two-sides--none",
    "bag-fr--paper-bag--63--paper-white--mono-pantone-one-side--none",
    "bag-fr--paper-bag--63--paper-white--mono-pantone-two-sides--none",
];
export var isProductVariantInNotOrderableList = function (sku) {
    return notOrderableProductSkuList.includes(sku);
};
export var designReorderBlockDates = (_a = {},
    _a[TAPE_CONSTS.KRAFT_TAPE] = "2020-06-26",
    // delivery boxes vendor breaking changes https://packhelp.slack.com/archives/C01LBDT1CTC/p1621429754005300
    // update: new date https://packhelp.slack.com/archives/C01LBDT1CTC/p1627559647013700?thread_ts=1627468001.004200&cid=C01LBDT1CTC
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX] = "2021-07-29",
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN] = "2021-07-29",
    _a);
// This turns off reorder for design after specific date
// Clubhouse: https://app.clubhouse.io/packhelp/story/13981/reorder-possible-only-new-size-old-size-is-blocked
// There's ongoing initiative to create special system for this type of requirements
export var isProductDesignOrderable = function (productDesign, productName, sku, now) {
    if (now === void 0) { now = Date.now(); }
    var blockDate = designReorderBlockDates[productName];
    if (!blockDate) {
        return !isProductVariantInNotOrderableList(sku);
    }
    else if (dayjs(blockDate).isAfter(now, "day")) {
        // in case if this feature gets released before the block date
        return true;
    }
    var designDate = productDesign && productDesign.created_at;
    return dayjs(designDate).isSameOrAfter(blockDate, "day");
};
export var getProductNameAndVariantTranslationKeys = function (product) {
    var productName = product.variantManager.getName();
    var productVariant = product.variantManager.getVariantDashed();
    var productNameKey = "box-type.".concat(productName, ".name");
    var productVariantKey = "box-product.name.".concat(productVariant);
    return {
        productNameKey: productNameKey,
        productVariantKey: productVariantKey,
    };
};
