import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"
import { GlobalDropZone } from "../../../dsl/organisms/Upload/GlobalDropzone"
import {
  EditorToolbarItem,
  EditorToolbarStore,
} from "../../../../stores/editor-toolbar-store"
import { AssetsStore } from "../../../../stores/assets-store/assets.store"
import ProductDriver from "../../../../stores/product-driver/product.driver"
import { ReplicablePatternUiController } from "../../../../stores/_controllers/replicable-pattern-ui-controller"
import { DbyModeController } from "../../../../stores/_controllers/dby-mode-controller"
import { AssetError } from "../../../../services/asset-service/asset-errors"
import { DbyModeStore } from "../../../../stores/dby-mode-store/dby-mode-store"
import { parseFileNaming } from "../../../../services/asset-service/utils"

interface SceneUIGlobalDropZoneControllerProps {
  assetStore: AssetsStore
  productDriver: ProductDriver
  replicablePatternUiController?: ReplicablePatternUiController
  editorToolbarStore: EditorToolbarStore
  dbyModeController: DbyModeController
  dbyModeStore: DbyModeStore
}
export const SceneUIGlobalDropZoneController = observer(
  ({
    assetStore,
    productDriver,
    replicablePatternUiController,
    editorToolbarStore,
    dbyModeController,
    dbyModeStore,
  }: SceneUIGlobalDropZoneControllerProps) => {
    const { activeSpace, activeContext, productRenderPilot } =
      productDriver.state

    const onDropInImagesMode = (acceptedFiles: File[]) => {
      acceptedFiles.map((file) => {
        assetStore.addAssetFromFile(file)
      })

      editorToolbarStore.selectTab(EditorToolbarItem.images)
    }

    const onDropInReplicablePatternMode = (acceptedFiles: File[]) => {
      const droppedFile = acceptedFiles[0]
      if (!droppedFile) return

      replicablePatternUiController?.selectArtwork(droppedFile)
      replicablePatternUiController?.selectMode("image")
      editorToolbarStore.selectTab(EditorToolbarItem.replicablePatterns)
    }

    const onDropInDbyUploadMode = (acceptedFiles: File[]) => {
      const droppedFile = acceptedFiles[0]
      if (!droppedFile) return

      const fileExtension = droppedFile.name.split(".").pop()

      if (fileExtension !== "ai" && fileExtension !== "pdf") {
        dbyModeStore.setUploadingFilename(parseFileNaming(droppedFile).fileName)
        dbyModeStore.setUploadError(AssetError.invalidData)
        return
      }

      dbyModeController?.uploadFile(droppedFile)
    }

    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        const isDbyMode = productRenderPilot.isDbyMode()
        const dropzoneMode = productRenderPilot.uiConfig.globalDropzone.mode

        if (isDbyMode) {
          onDropInDbyUploadMode(acceptedFiles)
          return
        }
        if (dropzoneMode === "images") {
          onDropInImagesMode(acceptedFiles)
          return
        }

        if (dropzoneMode === "replicablePattern") {
          onDropInReplicablePatternMode(acceptedFiles)
          return
        }
      },
      [
        assetStore,
        activeSpace,
        activeContext,
        productRenderPilot,
        replicablePatternUiController,
        dbyModeController,
        dbyModeStore,
      ]
    )

    return <GlobalDropZone onDrop={onDrop} />
  }
)
