import React, {
  PropsWithChildren,
  useEffect,
  useContext,
  useState,
} from "react"

import { PredefinedText } from "../../../../../modules/predefined-assets/text-asset"

import cxBinder from "classnames/bind"

import { observer } from "mobx-react-lite"
import { SelectItemData, SelectSizes } from "dsl/src/atoms/Select/types"
import { Select } from "dsl/src/atoms/Select/Select"

import { EditorToolList } from "../EditorToolList"

import { RootStoreContext } from "../../../../../stores/store-contexts"
import { FontWeightStyleConfig } from "../../../../../stores/fonts-config-store/fonts-config.store"

import { CardTool } from "../../../atoms/CardTool/CardTool"
import { Text } from "../../../../../libs/value-objects/text"
import { IconTextTile } from "../../../molecues/IconTextTile/IconTextTile"

import { ReactComponent as IconPlus } from "../../../../assets/svg/plus-circle.svg"

import {
  FontWeight,
  FontStyle,
  FontAlign,
  FontFamilyDefinition,
  FontSizeDefinition,
} from "../../../../../libs/services/fonts-loader-service/fonts-loader.service"

import { ColorConfigurator } from "../../configurators/ColorConfigurator/ColorConfigurator"
import { Colour } from "../../../../../libs/value-objects/colour"

import { EditableObjectTypes } from "../../../../../modules/ph-api/asset-types"
import { AvailableColourModes } from "../../../../../libs/products-render-config/types"

import styles from "../EditorTools.module.scss"
import { useTranslate } from "../../../../hooks/useTranslate"
import { EditorToolMoreTextOptions } from "./EditorToolMoreTextOptions"
import { EditorToolbarItem } from "../../../../../stores/editor-toolbar-store"
import { Fonts } from "../../../../../libs/services/fonts-loader-service/fonts-loader.config"

import { I18N } from "../../../../i18n"
import { useFontSizeCalculator } from "../../../../hooks/useFontSizeCalculator"
import _times from "lodash/times"
import Skeleton from "react-loading-skeleton"
import { ThumbXL } from "../../../atoms/ThumbXL/ThumbXL"
import { TextObjectController } from "../../../../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/text-object-controller"
import { EditorToolTextStyleOptions } from "./EditorToolTextStyleOptions"
import { EditorToolTextAlignOptions } from "./EditorToolTextAlignOptions"
import { UiContainer } from "../../../../../_containers/ui-container"
import ProductDriver from "../../../../../stores/product-driver/product.driver"

const cx = cxBinder.bind(styles)

const EditorToolTextWrapper = (props) => (
  <nav className={styles.header_nav}>
    <ul className={styles.header_toolbar_list} e2e-target="editor-tool-text">
      {props.children}
    </ul>
  </nav>
)

const EditorToolTextListItem = (props) => (
  <li
    key="type"
    className={cx({
      header_toolbar_list_item: true,
      "header_toolbar_list_item--fonts-families": true,
      white_space_no_wrap: true,
    })}
  >
    {props.children}
  </li>
)

export const EditorToolTextHeader = observer(
  ({
    productDriver,
    uiContainer,
  }: {
    productDriver: ProductDriver
    uiContainer: UiContainer
  }) => {
    const { fontsConfigStore, editorToolbarStore } = uiContainer

    const fontFamiliesDefinitions = fontsConfigStore.fontFamiliesList
    const { productRenderPilot } = productDriver.state
    const coloursPreset = productRenderPilot.getColoursPreset()
    const activeObjectController = productDriver.activeObjectDriver
      .activeObjectController as TextObjectController
    const isObjectActive = productDriver.activeObjectDriver.isObjectActive
    const activeObjectType = productDriver.activeObjectDriver.activeObjectType
    const activeObjectFontFamily =
      productDriver.activeObjectDriver.activeObjectComputable.fontFamily

    const objectIdentified = productDriver.activeObjectDriver.objectIdentified
    const isAllLoaded = fontsConfigStore.isAllLoaded

    const fontSizeCalculator = useFontSizeCalculator()
    const fontSizesList = fontSizeCalculator.generateFontSizeList()

    const activeObject = activeObjectController.getObject()

    const translate = useTranslate()

    const [fontParams, setFontParams] = useState<FontWeightStyleConfig>({
      fontStyle: FontStyle.normal,
      fontWeight: FontWeight.normal,
    })
    const [textAlign, setTextAlignment] = useState<FontAlign>(
      FontAlign.alignLeft
    )
    const [selectedFontColor, selectFontColor] = useState<Colour>(
      activeObjectController.getFill()
    )
    const [baseFontColor, selectBaseFontColor] = useState<Colour>(
      activeObjectController.getFill()
    )

    const [selectedFontFamilyDefinition, selectFontFamilyDefinition] =
      useState<FontFamilyDefinition>()
    const [selectedFontSize, selectFontSize] = useState<FontSizeDefinition>()

    useEffect(() => {
      const color = activeObjectController.getFill()
      const fontStyle = activeObject.get("fontStyle")
      const fontWeight = activeObject.get("fontWeight")
      const textAlign = activeObject.get("textAlign")

      const activeFontSize = activeObjectController.getFontSizeDefinition()
      const activeFontFamilyDefinition =
        activeObjectController.getFontFamilyDefinition()

      selectFontSize(activeFontSize)
      selectFontFamilyDefinition(activeFontFamilyDefinition)
      setFontParams({
        fontStyle: fontStyle,
        fontWeight: fontWeight,
      })
      selectFontColor(color)
      selectBaseFontColor(color)
      setTextAlignment(textAlign)
    }, [
      isObjectActive,
      activeObjectType,
      objectIdentified,
      activeObjectFontFamily,
    ])

    if (
      !activeObjectController ||
      !isAllLoaded ||
      activeObjectController.getObject().assetType !==
        EditableObjectTypes.assetText
    ) {
      return null
    }

    const onFontChange = (fontFamilyDefinition: FontFamilyDefinition): void => {
      activeObjectController.setStyles({
        fontFamily: fontFamilyDefinition.id as Fonts,
      })

      setFontParams({
        fontStyle: FontStyle.normal,
        fontWeight: FontWeight.normal,
      })
      selectFontFamilyDefinition(fontFamilyDefinition)
    }

    const onChangeFontSize = (fontSize): void => {
      activeObjectController.setStyles({
        fontSize: fontSize.id,
      })
      selectFontSize(fontSize)
    }

    const onChangeFontColor = (color: Colour): void => {
      activeObjectController.setStyles({ fill: color })
      selectFontColor(color)
    }

    const onBackClick = () => {
      onChangeFontColor(baseFontColor)
    }

    const selectedFontFamilyName = !!selectedFontFamilyDefinition
      ? selectedFontFamilyDefinition.config.name
      : ""

    const selectedItem = {
      ...selectedFontFamilyDefinition,
      name: selectedFontFamilyName,
    } as SelectItemData

    const selectItems = fontFamiliesDefinitions.map((fontFamily) => {
      return {
        ...fontFamily,
        name: fontFamily.config.name,
      }
    })

    const isFontSelectOpened = editorToolbarStore.isTabSelected(
      EditorToolbarItem.fonts
    )

    return (
      <EditorToolTextWrapper>
        <div className={styles.header_toolbar_list_group}>
          <EditorToolTextListItem>
            <Select
              id="editor-tool-text-font-family-select"
              handleSelectedItemChange={onFontChange}
              items={selectItems}
              size={SelectSizes.small}
              withMenuFluid
              placeholder={translate(
                I18N.components.editorToolText.label.selectFont
              )}
              disableNoResultsIndicator
              selectedItem={selectedItem}
              hideMenu
              isOpen={isFontSelectOpened}
              e2eTarget="editor-tool-text"
              e2eTargetName="font-family-dropdown"
              onClick={() =>
                editorToolbarStore.toggleTab(EditorToolbarItem.fonts)
              }
            />
          </EditorToolTextListItem>
          <li key="size" className={styles.header_toolbar_list_item}>
            <Select
              items={fontSizesList as unknown as SelectItemData[]}
              size={SelectSizes.small}
              selectedItem={selectedFontSize as unknown as SelectItemData}
              withMenuFluid
              placeholder="Select size"
              handleSelectedItemChange={onChangeFontSize}
              e2eTarget="editor-tool-text"
              e2eTargetName="font-size-dropdown"
            />
          </li>
        </div>
        {coloursPreset.mode !== AvailableColourModes.MONOCHROME && (
          <div className={styles.header_toolbar_list_group}>
            <li key="picker" className={styles.header_toolbar_list_item}>
              <ColorConfigurator
                onColorSelect={onChangeFontColor}
                onBackClick={onBackClick}
                selectedColor={selectedFontColor}
              />
            </li>
          </div>
        )}
        <EditorToolTextAlignOptions
          activeObjectController={activeObjectController}
          textAlign={textAlign}
          setTextAlignment={setTextAlignment}
        />
        <EditorToolTextStyleOptions
          activeObjectController={activeObjectController}
          fontParams={fontParams}
          setFontParams={setFontParams}
        />
        <li
          key="more"
          className={cx({
            header_toolbar_list_item: true,
            "header_toolbar_list_item--fonts_style": true,
          })}
        >
          <EditorToolMoreTextOptions
            activeObjectController={activeObjectController}
          />
        </li>
      </EditorToolTextWrapper>
    )
  }
)

type ThumbnailWrapperProps = {
  isMonochromatic: boolean
  isWhiteOnKraft: boolean
}

const ThumbnailWrapper = ({
  children,
  isMonochromatic,
  isWhiteOnKraft,
}: PropsWithChildren<ThumbnailWrapperProps>) => {
  return (
    <div
      className={cx({
        monochromatic_black_thumbnail: isMonochromatic && !isWhiteOnKraft,
        monochromatic_white_on_kraft_thumbnail:
          isMonochromatic && isWhiteOnKraft,
      })}
    >
      {children}
    </div>
  )
}

export const EditorToolText = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const t = useTranslate()
  const {
    productDriver,
    predefinedTextsStore,
    predefinedTextsController,
    contextController,
  } = rootStore

  const addText = async () => {
    const space = await contextController.forceSpaceView()
    await productDriver.addText(new Text(), space)
  }

  const addPredefinedText = async (text: PredefinedText) => {
    const space = await contextController.forceSpaceView()
    await predefinedTextsController.addPredefinedText(text, space)
  }

  useEffect(() => {
    predefinedTextsController.loadPredefinedTexts()
  }, [])

  const isMonochromatic = predefinedTextsController.isMonochromatic()
  const isWhiteOnKraft = predefinedTextsController.isWhiteOnKraft()

  return (
    <CardTool sizeM cardName="text">
      <EditorToolList>
        <IconTextTile
          key="add-text-button"
          onClick={addText}
          Icon={IconPlus}
          e2eTarget="predefined-text"
          e2eTargetName="add-text-button"
        >
          {t(I18N.editorTools.text.addText)}
        </IconTextTile>

        {predefinedTextsStore.isLoadingPredefinedTexts
          ? _times(9, () => <Skeleton width={158} height={158} />)
          : predefinedTextsStore.orderedPredefinedTexts.map((text) => {
              const imageUrl = predefinedTextsController.getPreviewUrl(text)

              return (
                <ThumbnailWrapper
                  isMonochromatic={isMonochromatic}
                  isWhiteOnKraft={isWhiteOnKraft}
                  key={text.slug}
                >
                  <ThumbXL
                    onClick={() => {
                      addPredefinedText(text)
                    }}
                    imageURL={imageUrl}
                    e2eTarget="predefined-text-item"
                    e2eTargetName={`${text.slug}`}
                    isSelected={false}
                    label=""
                    imgLoading="lazy"
                  />
                </ThumbnailWrapper>
              )
            })}
      </EditorToolList>
    </CardTool>
  )
})
