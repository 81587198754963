import BOX_CONSTS from "../consts/boxConsts";
// All box sizes with smaller number has the same config as the border point number specified as the key of the box type object,
// so all boxes from 1 to 105 have config specified in 105, all boxes from 106 to 200 have config specified in 200.
export default {
    AS80: {
        grammage: "390 g/m2",
        materialDescription: BOX_CONSTS.MATERIAL_CARDBOARD_WAVE_E,
    },
    AS91: {
        grammage: "395 g/m2",
        materialDescription: BOX_CONSTS.MATERIAL_CARDBOARD_WAVE_B,
    },
};
