import React, { PropsWithChildren } from "react"
import { observer } from "mobx-react-lite"
import cxBinder from "classnames/bind"
import { Toggle } from "dsl/src/atoms/Toggle/Toggle"

import { I18N } from "../../../i18n"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"
import { useTranslate } from "../../../hooks/useTranslate"

// Assets
import footerStyles from "./SceneFooter.module.scss"
import styles from "dsl/src/atoms/Toggle/Toggle.module.scss"

const cx = cxBinder.bind(styles)

const SceneFooter = (props: PropsWithChildren<{}>) => (
  <footer className={footerStyles.scene_footer}>{props.children}</footer>
)

const togglerI18N = I18N.component.footer.toggler

export const SceneFooter3DWired = observer(() => {
  const t = useTranslate()
  const [designAndProductDriverContainer] =
    useContainer().designAndProductDriver

  if (!designAndProductDriverContainer) {
    return null
  }
  const { productDriver, contextController } = designAndProductDriverContainer

  return (
    <SceneFooter>
      {productDriver.state.productRenderPilot.isModelOpenable() && (
        <Toggle
          checked={contextController.isModelOpen()}
          onChange={() => contextController.toggleModel()}
          labelItemLeft={
            <span className={cx("label", "label--left")}>
              {t(togglerI18N.close)}
            </span>
          }
          labelItemRight={
            <span className={cx("label", "label--right")}>
              {t(togglerI18N.open)}
            </span>
          }
          e2eTarget="3d-toggler"
        />
      )}
    </SceneFooter>
  )
})
