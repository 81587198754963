var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addPolyMailerTranslationsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getNameIntl = function () {
            return "product.poly-mailer.name";
        };
        class_1.prototype.getMaterialIntl = function () {
            var materialName = this.product.variantManager.getMaterial();
            return "product-config.polyMailer.material.".concat(materialName, ".name");
        };
        class_1.prototype.getLimitedColorsI18nDescription = function () {
            return "box-editor.pantone-picker.limited-colors-text";
        };
        class_1.prototype.getDoubleSidePrintedTranslationKey = function () {
            return "product-config.polyMailer.colorMode.printPantoneTwoSides.name";
        };
        return class_1;
    }(superclass));
};
export default addPolyMailerTranslationsBehaviors;
