var _a;
import PAPER_MAILER_CONSTS from "../consts/paperMailerConsts";
var PAPER_MAILER_DIMENSIONS = {
    Y60: {
        outside: {
            width: 250,
            length: 80,
            height: 350,
        },
    },
    Y80: {
        outside: {
            width: 350,
            length: 80,
            height: 450,
        },
    },
    Y90: {
        outside: {
            width: 400,
            length: 100,
            height: 500,
        },
    },
};
var PAPER_MAILER_PRODUCT_DIMENSIONS = (_a = {},
    _a[PAPER_MAILER_CONSTS.PAPER_MAILER] = PAPER_MAILER_DIMENSIONS,
    _a);
export default PAPER_MAILER_PRODUCT_DIMENSIONS;
