export default {
    F52: {
        outside: {
            width: 265,
            length: 195,
            height: 60,
        },
        inside: {
            width: 249,
            length: 190,
            height: 55,
        },
    },
    F71: {
        outside: {
            width: 350,
            length: 260,
            height: 100,
        },
        inside: {
            width: 337,
            length: 255,
            height: 95,
        },
    },
};
