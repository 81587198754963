import ExtensionConfigBuilder from "../../configBuilders/ExtensionConfigBuilder";
import addCustomAndExtensionProductProductionTimesBehaviors from "../../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductProductionTimesBehaviors";
import addCustomAndExtensionProductEditConfigBehaviors from "../../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductEditConfigBehaviors";
import addCustomAndExtensionProductGrammagesBehaviors from "../../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductGrammagesBehaviors";
import VariantManager from "../../behaviorsComposers/VariantsManager";
import GrammagesManager from "../../behaviorsComposers/GrammagesManager";
import DimensionsManager from "../../behaviorsComposers/DimensionsManager";
import WizzardManager from "../../behaviorsComposers/WizzardManager";
import EditConfigManager from "../../behaviorsComposers/EditConfigManager";
import ProductionTimeManager from "../../behaviorsComposers/ProductionTimeManager";
import TranslationsManager from "../../behaviorsComposers/TranslationsManager";
import addExtensionsProductTranslationsBehaviors from "../../behaviors/extensionProductsBehaviors/generalBehaviors/addExtensionsTranslationsbehaviors";
import addExtensionProductDimensionsBehaviors from "../../behaviors/extensionProductsBehaviors/generalBehaviors/addExtensionProductDimensionsBehaviors";
import addExtensionProductVariantBehaviors from "../../behaviors/extensionProductsBehaviors/generalBehaviors/addExtensionProductVariantBehavior";
var ExtensionProductionTimeManager = addCustomAndExtensionProductProductionTimesBehaviors(ProductionTimeManager);
var ExtensionProductGrammagesManager = addCustomAndExtensionProductGrammagesBehaviors(GrammagesManager);
var ExtensionProductEditConfigManager = addCustomAndExtensionProductEditConfigBehaviors(EditConfigManager);
var ExtensionProductDimmensionsManager = addExtensionProductDimensionsBehaviors(DimensionsManager);
var ExtensionProductTranslationsManager = addExtensionsProductTranslationsBehaviors(TranslationsManager);
var ExtensionProductVariantManager = addExtensionProductVariantBehaviors(VariantManager);
var ExtensionAssetProduct = /** @class */ (function () {
    function ExtensionAssetProduct(sku) {
        this.config = ExtensionConfigBuilder(sku);
        this.wizzardManager = new WizzardManager(this);
        this.productionTimeManager = new ExtensionProductionTimeManager(this);
        this.variantManager = new ExtensionProductVariantManager(this);
        this.grammagesManager = new ExtensionProductGrammagesManager(this);
        this.dimensionsManager = new ExtensionProductDimmensionsManager(this);
        this.editConfigManager = new ExtensionProductEditConfigManager(this);
        this.translationsManager = new ExtensionProductTranslationsManager(this);
    }
    ExtensionAssetProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    return ExtensionAssetProduct;
}());
export default ExtensionAssetProduct;
