import React from "react"
import { TType } from "dsl/src/atoms/Typography/Typography"
import { Button } from "dsl/src/atoms/Button/Button"
import { Link } from "dsl/src/atoms/Link/Link"

import {
  Notification,
  NotificationVariant,
} from "../../../dsl/atoms/Notification/Notification"
import { TM } from "../../../TypographyI18n"
import { useTranslate } from "../../../hooks/useTranslate"
import styles from "./SceneUIMessage.module.scss"
import { ButtonSizes } from "dsl/src/atoms/Button"

interface MessagePreviewPublicProps {
  onCopyDesignClick(): void
}

interface MessagePreviewCartProps extends MessagePreviewPublicProps {
  onToCart(): void
}

const i18n = {
  previewInfo: "editor.messages.public-preview-info",
  previewAction: "editor.messages.public-preview-action",
  previewActionSecondary: "editor.messages.public-preview-action-secondary",
  cartInfo: "editor.messages.cart-preview-info",
  cartAction: "editor.messages.cart-preview-action",
  cartGoTo: "editor.messages.cart-preview-go-to",
  cartActionCopy: "editor.messages.cart-preview-action-copy",
}

export const MessagePreviewPublic = (props: MessagePreviewPublicProps) => {
  const t = useTranslate()
  return (
    <Notification
      variant={NotificationVariant.info}
      e2eTargetName="preview-info"
    >
      <div className={styles.message_content}>
        <div>
          <TM id={i18n.previewInfo} type={TType.Body13_350} htmlElement="p" />
          <TM id={i18n.previewAction} type={TType.Body13_350} htmlElement="p" />
        </div>
        <nav>
          <Button onClick={props.onCopyDesignClick} size={ButtonSizes.small}>
            {t(i18n.previewActionSecondary)}
          </Button>
        </nav>
      </div>
    </Notification>
  )
}

export const MessagePreviewCart = (props: MessagePreviewCartProps) => {
  const t = useTranslate()
  return (
    <Notification variant={NotificationVariant.info}>
      <div className={styles.message_content}>
        <div>
          <TM id={i18n.cartInfo} type={TType.Body13_350} htmlElement="p" />
          <TM id={i18n.cartAction} type={TType.Body13_350} htmlElement="p" />
        </div>
        <nav>
          <Link onClick={props.onToCart} underlined>
            {t(i18n.cartGoTo)}
          </Link>
          <Button onClick={props.onCopyDesignClick} size={ButtonSizes.small}>
            {t(i18n.cartActionCopy)}
          </Button>
        </nav>
      </div>
    </Notification>
  )
}
