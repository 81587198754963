import React, { useEffect, useCallback, useState } from "react"
import { TType } from "shared-libs/src/js/shared-components/i18n/TypographyWithTranslate"
import Typography from "dsl/src/atoms/Typography/Typography"
import { useTranslate } from "shared-libs/src/js/shared-components/i18n/useTranslate"
import { I18N } from "../../../../i18n"
import { ProductDriver } from "../../../../../../src/stores/product-driver/product.driver"
import styles from "./NoPrintZoneTooltip.module.scss"

const navigationHeight = 170

interface NoPrintZoneTooltipProps {
  productDriver: ProductDriver
}

export const NoPrintZoneTooltip = ({
  productDriver,
}: NoPrintZoneTooltipProps) => {
  const [isShown, setIsShown] = useState(false)
  const [coords, setCoords] = useState<[number, number]>([0, 0])
  const t = useTranslate()

  const show = useCallback((event) => {
    setIsShown(true)
    setCoords([event.offsetX, event.offsetY])
  }, [])

  const hide = useCallback(() => {
    setIsShown(false)
  }, [])

  useEffect(() => {
    productDriver.eventEmitter.on("noPrintZoneMouseOver", show)
    productDriver.eventEmitter.on("noPrintZoneMouseOut", hide)

    return () => {
      productDriver.eventEmitter.off("noPrintZoneMouseOver", show)
      productDriver.eventEmitter.off("noPrintZoneMouseOut", hide)
    }
  }, [show, hide])

  const x = coords[0]
  const y = navigationHeight + coords[1] - window.innerHeight

  return (
    <div className={styles.scene_wrapper}>
      <div
        className={styles.wrapper}
        style={{
          transform: `translate(${x}px, ${y}px)`,
          display: isShown ? "block" : "none",
        }}
      >
        <Typography type={TType.Header15_500} className={styles.tooltip}>
          {t(I18N.component.cursorTooltip.noPrintZone)}
        </Typography>
      </div>
    </div>
  )
}
