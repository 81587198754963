import { observable, action, makeObservable, computed } from "mobx"
import _remove from "lodash/remove"
import EventEmitter from "eventemitter3"
import { Debug } from "../../services/logger"
import { DesignVersion } from "../../modules/design/version"
import {
  DesignMeta,
  EditorDesignDataWithThumbnail,
  DesignStatus,
  EcommerceDesignItem,
} from "../../modules/design/types"
import { AllEditorEventsEmitter, ee, eventTree } from "../editor-events"
import { RenderEngineExport } from "../../render-engine/types"
import { AssetsStore } from "../assets-store/assets.store"
import { Cart } from "../cart-store/cart.store"
import { ProductStore } from "../product-driver/product.store"

const debug = Debug("ph:editor:stores:product-design")

type ProductDesignStoreMeta = DesignMeta & {
  designItems: EcommerceDesignItem[]
}

type ProductDesignStoreState = {
  isProductDesignLoading: boolean
  isSharingWithEmail: boolean
  isSharedWithEmail: boolean
  isDesignTouched: boolean
  isProductDesignSaving: boolean
  isProductDesignSharing: boolean
  isUnauthorized: boolean
  is404: boolean
  shareWithEmailError: string | null
  isProductDesignDuplicating: boolean
  isDesignInCart: boolean
  isDraftDesignInCart: boolean
  isProductDesignLocking: boolean
  duplicateDesignError: string | null
  lockDesignError: string | null
  meta: ProductDesignStoreMeta
}

// OMG, here be dragons, very fragile code here
/**

  Be carefull when saving/reading data from the server.

  Use conflict free data handling patterns

  https://github.com/packhelp/packhelp/pull/6111

  Why? While data is saving on the server, a user can make a changes to a design.
  When a reply comes, there might be conflict.

  Scenrio 0:

  - Wait for a design save (get a design id)
  - Turn on Print Inside
  - Turn off Print Inside and quickly
  - (quickly) change quantity
  - (wait) quantity jumps back

  Scenrio 1:

  - Turn on any Pattern
  - Turn on Print Inside
  - Turn off Print Inside and quickly
  - (quickly) turn off a pattern
  - wait for save to the server (add text exit back 3D)
  - Refresh
  - CRASH

  In the second case, we add pattern to both virtual dieline and cart, the send it to the server.
  When we remove it locally, an update comes from the server, puting pattern “back to cart”.
  But it is not present on the design. Hence the “Pattern not found” error

 */
export class ProductDesignStore extends EventEmitter {
  public state: ProductDesignStoreState = {} as ProductDesignStoreState
  private editorDesignData: EditorDesignDataWithThumbnail | null = null

  /**
   * Use latest version by default
   */
  public static designFormatVersion: DesignVersion = DesignVersion.latest

  constructor(
    private readonly productStore: ProductStore,
    private readonly assetStore: AssetsStore,
    private readonly ee: AllEditorEventsEmitter,
    private readonly defaultDesignName: string,
    public thumbnailUrl = ""
  ) {
    super()
    makeObservable(this)
    this.ee = ee

    this.setDefaultStoreState()

    this.ee.on(
      eventTree.productDriver.touchDesign,
      this.setDesignTouched.bind(this, true)
    )
  }

  private setDefaultStoreState = (): void => {
    this.state = observable.object({
      isProductDesignLoading: true,
      isSharingDesign: false,
      isDesignTouched: false,
      isProductDesignSaving: false,
      isProductDesignSharing: false,
      isProductDesignLocking: false,
      shareWithEmailError: null,
      isProductDesignDuplicating: false,
      isSharingWithEmail: false,
      isSharedWithEmail: false,
      isDesignInCart: false,
      isDraftDesignInCart: false,
      isUnauthorized: false,
      is404: false,
      duplicateDesignError: null,
      lockDesignError: null,
      meta: {
        designItems: [],
        name: this.defaultDesignName,
        quantity: 0,
        dataReadOnly: false,
        public: false,
        designStatus: "draft",
      },
    })
  }

  @computed
  public get isEditMode(): boolean {
    return !this.isDesignReadOnly
  }

  @computed
  public get isDesignReadOnly(): boolean {
    return this.state.isDesignInCart || this.state.meta.dataReadOnly
  }

  @computed
  public get isDesignLocked(): boolean {
    return this.state.meta.designStatus === "locked"
  }

  @computed
  public get isDesignSaved(): boolean {
    return !!this.state.meta.id
  }

  /**
   * Attention!
   * This function might become overused.
   *
   * It is called in two flows with different inputs:
   * - update from render engine (thumbs + vd)
   * - deserializing state from the server (thumbs + vd + files + version)
   *
   * renderEngineExport - onExportData -> setDesignAttributes with vd and files
   * renderEngineExport - onExportData -> createDesign -> setDesignAttributes with vd and files
   *
   * loadDesignById -> setDesignAttributes with deserialized data
   */
  public setEditorDesignData(
    renderEngineExport: RenderEngineExport
  ): EditorDesignDataWithThumbnail {
    this.editorDesignData = {
      thumbnail: renderEngineExport.thumbnail,
      virtualDielines: renderEngineExport.virtualDielines,
      files: this.assetStore.serialize(),
      designDataFormatVersion: ProductDesignStore.designFormatVersion,
      type: "editor",
    }

    return this.editorDesignData
  }
  @action
  public setProductDesignStatus(designStatus: DesignStatus) {
    this.state.meta.designStatus = designStatus
  }

  @action
  private setIsDesignInCart(isInCart: boolean) {
    this.state.isDesignInCart = isInCart
  }

  @action
  public setIsDraftDesignInCart(isDraftInCart: boolean) {
    this.state.isDraftDesignInCart = isDraftInCart
  }

  @action
  public setIsProductDesignLocking(isProductDesignLocking: boolean) {
    this.state.isProductDesignLocking = isProductDesignLocking
  }
  @action
  public setIsProductDesignPublic(isPublic: boolean) {
    this.state.meta.public = isPublic
  }

  @action
  public setIsProductDesignSharing(isSharing: boolean) {
    this.state.isProductDesignSharing = isSharing
  }

  @action
  public setShareWithEmailError(error: string) {
    this.state.shareWithEmailError = error
  }

  @action
  public setIsSharingWithEmail(isSharingWithEmail: boolean) {
    this.state.isSharingWithEmail = isSharingWithEmail
  }

  @action
  public setIsSharedWithEmail(isSharedWithEmail: boolean) {
    this.state.isSharedWithEmail = isSharedWithEmail
  }

  private isDesignInCart(designMeta: DesignMeta, cart: Cart): boolean {
    if (!designMeta || !cart || !Array.isArray(cart.line_items)) {
      return false
    }

    return !!cart.line_items.find((line_item) => {
      if (!line_item.design) return false

      if (line_item.design.status === "draft") return false

      return line_item.design.id === designMeta.id
    })
  }

  private isDraftDesignInCart(designMeta: DesignMeta, cart: Cart): boolean {
    if (!designMeta || !cart || !Array.isArray(cart.line_items)) {
      return false
    }

    return !!cart.line_items.find((line_item) => {
      if (!line_item.design) return false

      return (
        line_item.design.status === "draft" &&
        line_item.design.id === designMeta.id
      )
    })
  }

  @action
  public setDuplicateDesignError(error: string) {
    this.state.duplicateDesignError = error
  }

  @action
  public setLockDesignError(error: string) {
    this.state.lockDesignError = error
  }

  @action
  public setIsProductDesignDuplicating(isDuplicating: boolean) {
    this.state.isProductDesignDuplicating = isDuplicating
  }

  @action
  public addDesignItem(designItem: EcommerceDesignItem) {
    _remove(
      this.state.meta.designItems,
      ({ variantType }) => variantType === designItem.variantType
    )
    this.state.meta.designItems.push(designItem)
  }

  @action
  public removeDesignItem(designItem: EcommerceDesignItem) {
    _remove(this.state.meta.designItems, ({ variantId, variantType }) => {
      return (
        variantId === designItem.variantId &&
        variantType === designItem.variantType
      )
    })
  }

  @action
  public setDesignItems(designItems: EcommerceDesignItem[]) {
    this.state.meta.designItems = designItems
  }

  public async forceDesignId(): Promise<DesignMeta["id"]> {
    if (this.state.meta.id) {
      return this.state.meta.id
    }

    return await new Promise((resolve) => {
      ee.once(eventTree.pd.saved, (designState) => {
        if (designState.id) resolve(designState.id)
      })
      ee.emit(eventTree.pd.saveTriggered, { force: true })
    })
  }

  @action
  public setUnauthorized(isUnauthorized: boolean) {
    this.state.isUnauthorized = isUnauthorized
  }

  @action
  public set404(is404: boolean) {
    this.state.is404 = is404
  }

  /**
   * Should only be used when loading "full" design id from the server
   */
  @action
  public setProductDesignMeta(meta: DesignMeta) {
    this.state.meta = {
      ...meta,
      designItems: [],
    }
  }

  /**
   * Update ids that "only" backend knows.
   * We don't update everything because a response from backend comes
   * with a huge delay. And a client can change stuff like designItems,
   * quantity etc.
   *
   * @param productDesign - from the server
   */
  @action
  public setProductDesignId(id: DesignMeta["id"]) {
    this.state.meta.id = id
  }

  @action
  public setProductDesignSaving(isSaving: boolean) {
    this.state.isProductDesignSaving = isSaving
  }

  @action
  public setProductQuantity = (quantity: number) => {
    const qty = Math.round(Math.abs(quantity))

    if (this.state.meta.quantity === qty) {
      return
    }

    this.state.meta.quantity = qty

    this.ee.emit(
      eventTree.pd.qtyChanged,
      qty,
      this.productStore.product,
      this.state.meta.designItems
    )
  }

  @action
  public setProductDesignLoading(isLoading: boolean) {
    this.state.isProductDesignLoading = isLoading
  }

  @action
  public setDesignName(name: string) {
    debug(`setting new design name to: ${name}`)
    this.state.meta.name = name
    this.setDesignTouched(true)
  }

  public getEditorDesignData(): EditorDesignDataWithThumbnail {
    if (!this.editorDesignData) {
      throw new Error("Editor design data is not available")
    }

    return this.editorDesignData
  }

  public static parseDesignVersionOrRevertToOldest(v?: number): DesignVersion {
    // Default pre versioned version
    if (v == null) {
      return DesignVersion.oldest
    }

    if (DesignVersion[v] == null) {
      // prettier-ignore
      throw new Error(`Design version unkown. ${v}. Avaliable: ${JSON.stringify(DesignVersion)}`)
    }

    return v
  }

  @action
  public setDesignTouched(isTouched: boolean) {
    this.state.isDesignTouched = isTouched

    if (isTouched) {
      this.ee.emit(eventTree.productDriver.designTouched)
    }
  }

  public updateDesignInCartState(cart: Cart) {
    if (this.state.meta) {
      const isDesignInCart = this.isDesignInCart(this.state.meta, cart)
      this.setIsDesignInCart(isDesignInCart)
    }
  }

  public updateDraftDesignInCartState(cart: Cart) {
    if (this.state.meta) {
      const isDraftDesignInCart = this.isDraftDesignInCart(
        this.state.meta,
        cart
      )
      this.setIsDraftDesignInCart(isDraftDesignInCart)
    }
  }

  @action
  public setThumbnailUrl(thumbnailUrl: string) {
    this.thumbnailUrl = thumbnailUrl
  }
}
