import {
  AvailableColourModes,
  ColoursPreset,
  ModelEditableSpaces,
  MonochromeColours,
} from "../../libs/products-render-config/types"
import { PredefinedText } from "../../modules/predefined-assets/text-asset"
import { PredefinedTextsStore } from "../predefined-texts-store/predefined-texts.store"
import ProductDriver from "../product-driver/product.driver"

export class PredefinedTextsController {
  constructor(
    private readonly productDriver: ProductDriver,
    private readonly predefinedTextsStore: PredefinedTextsStore
  ) {}

  public async addPredefinedText(
    predefinedText: PredefinedText,
    forcedSpace?: ModelEditableSpaces
  ): Promise<void> {
    this.predefinedTextsStore.setIsLoadingSinglePredefinedText(true)

    const asset = await predefinedText.getAssetData()

    if (asset) {
      await this.productDriver.addPredefinedText(
        asset,
        predefinedText,
        forcedSpace
      )
    } else {
      console.error(
        "The predefined text shouldn't be published, because it has no asset file!"
      )
    }

    this.predefinedTextsStore.setIsLoadingSinglePredefinedText(false)
  }

  public getColoursPreset(): ColoursPreset {
    return this.productDriver.state.productRenderPilot.getColoursPreset()
  }

  public loadPredefinedTexts(): void {
    this.predefinedTextsStore.loadPredefinedTexts()
  }

  public isMonochromatic(): boolean {
    return [
      AvailableColourModes.MONOCHROME,
      AvailableColourModes.MONO_PANTONE,
    ].includes(this.getColoursPreset().mode)
  }

  public isWhiteOnKraft(): boolean {
    return (
      this.isMonochromatic() &&
      this.getColoursPreset().colourMonochrome === MonochromeColours.WHITE
    )
  }

  public getPreviewUrl(text: PredefinedText) {
    const preview = text.previews[0]
    return preview && preview.formats
      ? preview.formats.thumbnail.url
      : undefined
  }
}
