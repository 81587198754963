var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _each from "lodash/each";
import _keys from "lodash/keys";
import _round from "lodash/round";
import Big from "big.js";
import BOX_CONST from "../../../../../source/boxProductSpec/generalConfig/consts/boxConsts";
var addBoxRenderConfigBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            _this.SIDES = _this.getAvailableSides();
            return _this;
        }
        class_1.prototype.initSideEditor = function (sideEditor) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, Promise.resolve(sideEditor)];
                });
            });
        };
        class_1.prototype.getFiltersConfigBuilder = function (sideEditorInstance) {
            var filters = _super.prototype.getFiltersConfigBuilder.call(this, sideEditorInstance);
            if (filters) {
                return filters;
            }
            if (this.product.variantManager.getFinish() ===
                BOX_CONST.PRINT_FINISH_SLEEVE_KRAFT) {
                return {
                    Noise: {
                        noise: 70,
                    },
                    Brightness: {
                        brightness: 30,
                    },
                    Contrast: {
                        contrast: 20,
                    },
                    Tint: {
                        color: "#C3936E",
                        opacity: 0.3,
                    },
                };
            }
        };
        class_1.prototype.finishDuplication = function (targetDesignEditors) {
            return __awaiter(this, void 0, void 0, function () {
                var foldLines;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            foldLines = {};
                            return [4 /*yield*/, Promise.all([this.SIDES.left, this.SIDES.right].map(function (side) {
                                    if (!targetDesignEditors[side]) {
                                        return;
                                    }
                                    var exportedImages = targetDesignEditors[side].exportImage();
                                    foldLines[side] = exportedImages.foldLine;
                                }))];
                        case 1:
                            _a.sent();
                            return [2 /*return*/, new Promise(function (resolve) {
                                    ;
                                    [_this.SIDES.top].map(function (side) {
                                        var designEditor = targetDesignEditors[side];
                                        if (!designEditor) {
                                            resolve(targetDesignEditors);
                                            return;
                                        }
                                        Promise.all([
                                            designEditor.setFoldlineImage(_this.SIDES.left, foldLines[_this.SIDES.left]),
                                            designEditor.setFoldlineImage(_this.SIDES.right, foldLines[_this.SIDES.right]),
                                        ]).then(function () {
                                            designEditor.fabric.renderAll();
                                            resolve(targetDesignEditors);
                                        });
                                    });
                                })];
                    }
                });
            });
        };
        class_1.prototype.getSafeZoneWidth = function () {
            return 5;
        };
        class_1.prototype.isColorSpaceMonochrome = function () {
            var colorMode = this.product.variantManager.getColorMode();
            return (colorMode === BOX_CONST.PRINT_MODE_MONOCHROME_WITH_PRINT_INSIDE ||
                colorMode === BOX_CONST.PRINT_MODE_MONOCHROME_WHITE_WITH_PRINT_INSIDE ||
                colorMode === BOX_CONST.PRINT_MODE_MONOCHROME_WHITE ||
                colorMode === BOX_CONST.PRINT_MODE_MONOCHROME);
        };
        class_1.prototype.getEditZoneDimensions = function (sideId) {
            return _super.prototype.getSideInfoDimensions.call(this, sideId);
        };
        class_1.prototype.getOffsetForSide = function (sideId) {
            var productAvailableSides = this.getAvailableSides();
            var productData = this.getProductData();
            switch (sideId) {
                case productAvailableSides.front:
                case productAvailableSides.back:
                    return productData[productAvailableSides.left].width / 2;
                case productAvailableSides.left:
                case productAvailableSides.right:
                    return productData[productAvailableSides.front].width / 2;
                case productAvailableSides.top:
                case productAvailableSides.bottom:
                    return productData[productAvailableSides.front].height / 2;
                default:
                    throw new Error("Unknown sideId:" + sideId);
            }
        };
        class_1.prototype.getFillStyleForSide = function (ctx) {
            var isKraft = this.product.variantManager.getMaterial() ===
                BOX_CONST.PRINT_MATERIAL_KRAFT;
            var isNaturalCardboard = this.product.variantManager.getMaterial() ===
                BOX_CONST.PRINT_MATERIAL_NATURAL_CARDBOARD;
            var isSleevedMailerBox = this.product.wizzardManager.isProductSleevedMailerBox();
            if (isSleevedMailerBox && isNaturalCardboard) {
                var pattern = document.getElementById("zpkj-box-pattern-image-sleeved-mailer-box");
                return ctx.createPattern(pattern, "repeat");
            }
            else if (isNaturalCardboard || isKraft) {
                var pattern = document.getElementById("zpkj-box-pattern-image");
                return ctx.createPattern(pattern, "repeat");
            }
            else {
                return "#fff";
            }
        };
        class_1.prototype.getTextureForSide = function (sideId) {
            var productName = this.product.variantManager.getName();
            var productRenderConfig = this.getRenderConfig();
            var textureDirectory = productRenderConfig.textureDirectory;
            var mappedTextures = this.createTexturesMapping({
                productName: productName,
                textureDirectory: textureDirectory,
            });
            return mappedTextures[sideId];
        };
        class_1.prototype.getProductDimensionsMillimeters = function () {
            var productRenderConfig = this.getRenderConfig();
            var width = productRenderConfig.width, height = productRenderConfig.height;
            return {
                width: width,
                height: height,
            };
        };
        class_1.prototype.getAllSidesDimensions = function () {
            var _this = this;
            var allSidesDimensions = {};
            var productAvailableSides = this.getAvailableSides();
            _each(_keys(productAvailableSides).concat(["foldLine", "sideFold"]), function (side) {
                var realDims = _this.getSideDimensionsMilimeters(side);
                allSidesDimensions[side] = {
                    widthMm: realDims.width,
                    heightMm: realDims.height,
                    widthPx: realDims.width * (_this.DPI / 25.4),
                    heightPx: realDims.height * (_this.DPI / 25.4),
                };
            });
            return allSidesDimensions;
        };
        class_1.prototype.getSidesInfoDimensions = function () {
            var _sizes = this.getAllSidesDimensions();
            var sizes = {};
            // Let's retrieve folds and side folds for further calculations
            var foldLineWidthMm = _sizes["foldLine"].widthMm;
            var foldLineWidthPx = _sizes["foldLine"].widthPx;
            var sideFoldWidthMm = _sizes["sideFold"].widthMm;
            var sideFoldWidthPx = _sizes["sideFold"].widthPx;
            var SIDES = this.getAvailableSides();
            _each(_sizes, function (size, sideId) {
                if (sideId === SIDES.top) {
                    sizes[sideId] = {
                        widthCm: _round((size["widthMm"] - 2 * foldLineWidthMm) / 10, 3),
                        heightCm: _round(size["heightMm"] / 10, 3),
                        widthPx: Math.ceil(size["widthPx"] - 2 * foldLineWidthPx),
                        heightPx: Math.ceil(size["heightPx"]),
                    };
                }
                else if ([SIDES.left, SIDES.right].indexOf(sideId) > -1) {
                    sizes[sideId] = {
                        widthCm: _round(size["widthMm"] / 10, 3),
                        heightCm: _round((size["heightMm"] + 1 * foldLineWidthMm) / 10, 3),
                        widthPx: Math.ceil(size["widthPx"]),
                        heightPx: Math.ceil(size["heightPx"] + 1 * foldLineWidthPx),
                    };
                }
                else if ([SIDES.front, SIDES.back].indexOf(sideId) > -1) {
                    sizes[sideId] = {
                        widthCm: _round((size["widthMm"] - 2 * sideFoldWidthMm) / 10, 3),
                        heightCm: _round(size["heightMm"] / 10, 3),
                        widthPx: Math.ceil(size["widthPx"] - 2 * sideFoldWidthPx),
                        heightPx: Math.ceil(size["heightPx"]),
                    };
                }
                else {
                    sizes[sideId] = {
                        widthCm: _round(size["widthMm"] / 10, 3),
                        heightCm: _round(size["heightMm"] / 10, 3),
                        widthPx: Math.ceil(size["widthPx"]),
                        heightPx: Math.ceil(size["heightPx"]),
                    };
                }
            });
            return sizes;
        };
        class_1.prototype.isMailerBoxOrWhiteInk = function (productName) {
            return (productName == BOX_CONST.BOX_MODEL_MAILER_BOX ||
                productName == BOX_CONST.BOX_MODEL_MAILER_BOX_WHITE_INK);
        };
        class_1.prototype.getProductEditZones = function (sideID, productZoneCoords) {
            var faceDimensions = this.getSideDimensionsMilimeters(sideID);
            var productSizeRenderConfig = this.getRenderConfig();
            var productName = this.product.variantManager.getName();
            var SIDES = this.getAvailableSides();
            var ratio = productZoneCoords.width / faceDimensions.width;
            var safeZoneWidth = this.getSafeZoneWidth() * ratio;
            var foldLineWidth = productSizeRenderConfig.foldLineWidth * ratio;
            var editZones = {};
            if ((sideID === SIDES.left || sideID === SIDES.right) &&
                this.isMailerBoxOrWhiteInk(productName)) {
                editZones["foldLine"] = {
                    zone: {
                        top: productZoneCoords.top,
                        left: productZoneCoords.left,
                        width: productZoneCoords.width,
                        height: foldLineWidth,
                    },
                    safeZone: {
                        top: productZoneCoords.top + safeZoneWidth,
                        left: productZoneCoords.left + safeZoneWidth,
                        width: productZoneCoords.width - safeZoneWidth,
                        height: foldLineWidth - safeZoneWidth,
                    },
                };
                editZones["side"] = {
                    zone: {
                        top: foldLineWidth + productZoneCoords.top,
                        left: productZoneCoords.left,
                        width: productZoneCoords.width,
                        height: productZoneCoords.height - foldLineWidth,
                    },
                    safeZone: {
                        top: foldLineWidth + productZoneCoords.top + safeZoneWidth,
                        left: productZoneCoords.left + safeZoneWidth,
                        width: productZoneCoords.width - safeZoneWidth,
                        height: productZoneCoords.height - foldLineWidth - safeZoneWidth,
                    },
                };
            }
            else {
                editZones["side"] = {
                    zone: productZoneCoords,
                    safeZone: {
                        top: productZoneCoords.top + safeZoneWidth,
                        left: productZoneCoords.left + safeZoneWidth,
                        width: productZoneCoords.width - safeZoneWidth,
                        height: productZoneCoords.height - safeZoneWidth,
                    },
                };
            }
            return editZones;
        };
        class_1.prototype.getObjectMovingZone = function (currentObject, productEditZones) {
            var productEditZonesCords = productEditZones.side.zone;
            var objectCords = currentObject.getBoundingRect();
            return {
                maxTop: productEditZonesCords.top + productEditZonesCords.height + 1,
                minTop: productEditZonesCords.top - objectCords.height - 1,
                maxLeft: productEditZonesCords.left + productEditZonesCords.width - 1,
                minLeft: productEditZonesCords.left - objectCords.width + 1,
            };
        };
        class_1.prototype.getProductClipingZone = function (sideId, productEditZones) {
            var productZone = productEditZones.side.zone;
            var SIDES = this.getAvailableSides();
            var productName = this.product.variantManager.getName();
            var left = productZone.left, top = productZone.top, width = productZone.width, height = productZone.height;
            if ((sideId === SIDES.left || sideId === SIDES.right) &&
                this.isMailerBoxOrWhiteInk(productName)) {
                var productFoldline = productEditZones.foldLine.zone;
                var foldlineHeight = productFoldline.height;
                height = parseFloat(new Big(height).plus(foldlineHeight));
                top = parseFloat(new Big(top).minus(foldlineHeight));
            }
            return {
                left: left,
                top: top,
                width: width,
                height: height,
                fill: "rgba(0,0,0,0)",
                transparentCorners: false,
            };
        };
        class_1.prototype.setFoldline = function (sideEditorInstance, foldLineID, img) {
            var leftFoldLine = sideEditorInstance.leftFoldLine, rightFoldLine = sideEditorInstance.rightFoldLine, fabric = sideEditorInstance.fabric, SideEditorConsts = sideEditorInstance.SideEditorConsts, ProductRendererService = sideEditorInstance.ProductRendererService, productEditZones = sideEditorInstance.productEditZones, editedSide = sideEditorInstance.editedSide;
            var SIDES = this.getAvailableSides();
            var productRenderConfig = this.getRenderConfig();
            img.originX = "left";
            img.originY = "top";
            img.evented = false;
            img.selectable = false;
            var realDims = ProductRendererService.getSideDimensionsMilimeters(editedSide);
            var sideEditDims = ProductRendererService.getSideEditDimensions(editedSide);
            var anotherSideEditRatio = img.height / productRenderConfig.foldLineWidth;
            var thisSideEditRatio = sideEditDims.height / realDims.height;
            img.width = sideEditDims.height;
            img.height = (img.height * thisSideEditRatio) / anotherSideEditRatio;
            var editWidth = sideEditDims.width;
            img.setAngle(90);
            if (foldLineID === SIDES.left) {
                if (leftFoldLine) {
                    leftFoldLine.remove();
                }
                img.setLeft((fabric.width - editWidth) / 2 + img.height);
                img.set("id", SideEditorConsts.LEFT_FOLDLINE);
                leftFoldLine = img;
            }
            else if (foldLineID === SIDES.right) {
                if (rightFoldLine) {
                    rightFoldLine.remove();
                }
                img.setLeft((fabric.width - editWidth) / 2 + editWidth);
                img.set("id", SideEditorConsts.RIGHT_FOLDLINE);
                rightFoldLine = img;
            }
            else {
                throw "error, unknown side id";
            }
            img.setTop(productEditZones.side.zone.top);
            fabric.deactivateAllWithDispatch().renderAll();
            if (foldLineID === SIDES.right) {
                img.setAngle(270);
            }
            return img;
        };
        class_1.prototype.createTexturesMapping = function (_a) {
            var productName = _a.productName, textureDirectory = _a.textureDirectory;
            var texturesPath = "/textures/transparent";
            var sides = ["front", "back", "top", "bottom", "left", "right"];
            var textures = {};
            // for each side create correspodonding side image
            _each(sides, function (sideName) {
                var fileName = sideName;
                if (sideName === "left" || sideName === "right") {
                    fileName = "side";
                }
                // There is smaller number of textures for the symmetrical shipping boxes
                if (productName === BOX_CONST.BOX_MODEL_SHIPPING_BOX) {
                    if (sideName === "front" || sideName === "back") {
                        fileName = "front_and_back";
                    }
                    if (sideName === "top" || sideName === "bottom") {
                        fileName = "top_and_bottom";
                    }
                    textures[sideName] =
                        texturesPath + "/" + productName + "/" + fileName + ".png";
                }
                else if (productName === BOX_CONST.BOX_MODEL_PRODUCT_BOX ||
                    productName === BOX_CONST.BOX_MODEL_PRODUCT_BOX_WINE_BOX) {
                    // Wine boxes and product boxes are using the same textures, so I've added "if" to use the same textures from one place,
                    // becouse I didn't wanted to copy whole images again and add more unneeded stuff
                    textures[sideName] =
                        texturesPath +
                            "/" +
                            BOX_CONST.BOX_MODEL_PRODUCT_BOX +
                            "/" +
                            textureDirectory +
                            "/" +
                            textureDirectory +
                            "_" +
                            fileName +
                            ".png";
                }
                else {
                    var productName_1 = BOX_CONST.BOX_MODEL_MAILER_BOX;
                    textures[sideName] =
                        texturesPath +
                            "/" +
                            productName_1 +
                            "/" +
                            textureDirectory +
                            "/" +
                            textureDirectory +
                            "_" +
                            fileName +
                            ".png";
                }
            });
            return textures;
        };
        return class_1;
    }(superclass));
};
export default addBoxRenderConfigBehaviors;
