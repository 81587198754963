import React from "react"
import styles from "./UploadFailedFilePreview.module.scss"
import { ReactComponent as UploadFailedIcon } from "../../../assets/svg/warning.svg"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { CardNotification } from "../../atoms/card-notification/card-notification"

export const UploadFailedFilePreview = ({
  name,
  error,
  onRemove,
}: {
  name?: string
  error: string
  onRemove: () => void
}) => {
  return (
    <CardNotification Icon={UploadFailedIcon} onClose={onRemove} type="error">
      {name && (
        <Typography type={TType.Body13_350} htmlElement="p">
          {name}
        </Typography>
      )}
      <Typography
        type={TType.Body13_350}
        htmlElement="p"
        className={styles.error}
      >
        {error}
      </Typography>
    </CardNotification>
  )
}
