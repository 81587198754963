var _a;
import BAG_CONSTS from "../consts/bagConsts";
var BAG_PRODUCT_DIMENSIONS = (_a = {},
    _a[BAG_CONSTS.PAPER_BAG] = {
        B63: {
            outside: {
                width: 160,
                height: 390,
                length: 80,
            },
        },
        B66: {
            outside: {
                width: 240,
                height: 320,
                length: 100,
            },
        },
        B70: {
            outside: {
                width: 240,
                height: 360,
                length: 100,
            },
        },
        B82: {
            outside: {
                width: 305,
                height: 340,
                length: 170,
            },
        },
        B95: {
            outside: {
                width: 330,
                height: 500,
                length: 120,
            },
        },
        B107: {
            outside: {
                width: 500,
                height: 390,
                length: 180,
            },
        },
    },
    _a[BAG_CONSTS.DOYPACK_BAG] = {
        D35: {
            outside: {
                width: 110,
                height: 70,
                length: 170,
            },
            capacity: 250,
        },
        D42: {
            outside: {
                width: 130,
                height: 80,
                length: 210,
            },
            capacity: 500,
        },
        D48: {
            outside: {
                width: 160,
                height: 90,
                length: 230,
            },
            capacity: 750,
        },
        D56: {
            outside: {
                width: 190,
                height: 110,
                length: 260,
            },
            capacity: 1000,
        },
    },
    _a[BAG_CONSTS.TOTE_BAG] = {
        TB79: {
            outside: {
                width: 380,
                height: 410,
            },
            handle: {
                min: 63,
                max: 72,
            },
        },
    },
    _a);
export default BAG_PRODUCT_DIMENSIONS;
