import React, { FC } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { FormattedMessage } from "react-intl"

import styles from "../ShareDesign.module.scss"

import ModalTemplateConfirm from "../../partials/ModalTemplateConfirm"
import { FTextInput } from "../../../../atoms/FormikFields/FormikifiedInputs"
import Button from "dsl/src/atoms/Button/Button"
import { useTranslate } from "../../../../../hooks/useTranslate"

export interface ShareDesignFormProps {
  onClose: () => void
  onSend: (email: string) => void
  isSharedWithEmail: boolean
  isSharingWithEmail: boolean
  shareWithEmailError: null | string
}

interface ShareDesignFormValues {
  shareDesignEmail: string
}

const ShareDesignSchema = Yup.object().shape({
  shareDesignEmail: Yup.string()
    .email("Invalid email")
    .required("Required")
    .trim(),
})

const ShareDesignForm: FC<ShareDesignFormProps> = ({
  shareWithEmailError,
  isSharedWithEmail,
  isSharingWithEmail,
  onClose,
  onSend,
}) => {
  const initialValues: ShareDesignFormValues = { shareDesignEmail: "" }
  const t = useTranslate()
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(props) => {
        onSend(props.shareDesignEmail)
      }}
      validationSchema={ShareDesignSchema}
    >
      {({ errors }) => (
        // @ts-ignore
        <Form translate="hack-ts-remove-next-node-or-yarn">
          <ModalTemplateConfirm
            onClose={onClose}
            title={t("modals.share-box-design.title")}
            buttonComponent={
              <Button isLoading={isSharingWithEmail}>
                {t("modals.share-box-design.send-design")}
              </Button>
            }
            withCancel
            cancelLinkText={t("modals.share-box-design.cancel")}
          >
            <h3 className={styles.heading}>
              {t("modals.share-box-design.via-link")}
            </h3>
            <p className={styles.desc}>
              <FormattedMessage
                id="modals.share-box-design.share-status.shared"
                description="Share product design: share status: shared."
                values={{
                  link: (
                    <input
                      className={styles["copy-input"]}
                      value={window.location.href}
                    />
                  ),
                }}
              />
            </p>
            <h3 className={styles.heading}>
              {t("modals.share-box-design.via-email")}
            </h3>
            <p className={styles.desc}>
              {t("modals.share-box-design.email-explainer")}
            </p>
            <div className={styles.field}>
              <Field
                type="email"
                name="shareDesignEmail"
                placeholder="e-mail@domain.com"
                errorMsg={errors.shareDesignEmail}
                component={FTextInput}
              />
              {isSharedWithEmail && (
                <p className={styles["success-message"]}>
                  {t("modals.share-box-design.email-share-success")}
                </p>
              )}
              {shareWithEmailError && (
                <p className={styles["error-message"]}>
                  {t("modals.share-box-design.email-share-error")}
                </p>
              )}
            </div>
          </ModalTemplateConfirm>
        </Form>
      )}
    </Formik>
  )
}

export { ShareDesignForm as default }
