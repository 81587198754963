import React from "react"
import cxBinder from "classnames/bind"

import {
  ArrowPlacements,
  BaseTooltip,
  TooltipDirections,
} from "dsl/src/atoms/BaseTooltip/BaseTooltip"

import { ReactComponent as TextIcon } from "../../../../../assets/svg/text.svg"
import { ReactComponent as ShapesIcon } from "../../../../../assets/svg/shapes.svg"
import { ReactComponent as ImageIcon } from "../../../../../assets/svg/image.svg"
import { ReactComponent as LeafIcon } from "../../../../../assets/svg/leaf.svg"

import styles from "./EditorToolAvailabilityTooltip.module.scss"

const cx = cxBinder.bind(styles)

export const EditorToolAvailabilityTooltip = ({
  primaryLabel,
  secondaryLabel,
}: {
  primaryLabel: string
  secondaryLabel: string
}) => {
  return (
    <BaseTooltip
      arrowPlacement={ArrowPlacements.right}
      className={styles.tooltip}
      direction={TooltipDirections.bottom}
    >
      <div className={styles.tooltip__content}>
        <div className={styles.tooltip__icons}>
          <TextIcon className={styles.tooltip__icon} />
          <ShapesIcon className={styles.tooltip__icon} />
          <ImageIcon className={styles.tooltip__icon} />
          <LeafIcon className={styles.tooltip__icon} />
        </div>
        <span className={styles.tooltip__label}>{primaryLabel}</span>
        <span className={cx("tooltip__label", "tooltip__label--secondary")}>
          {secondaryLabel}
        </span>
      </div>
    </BaseTooltip>
  )
}
