var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import SleeveShadowsConfig from "./SleeveShadowsConfig";
var SleeveShapeBuilder = /** @class */ (function () {
    function SleeveShapeBuilder(options) {
        this.sideEditorInstance = options.sideEditorInstance;
        this.shadowGradientConfig = SleeveShadowsConfig[options.material];
        this.textureObject = options.textureObject;
        (this.shapeZone = this.sideEditorInstance.productEditZones.side.zone),
            (this.sideId = this.sideEditorInstance.editedSide);
        this.textureWidth = this.textureObject.getWidth();
        this.textureHeight = this.textureObject.getWidth();
    }
    SleeveShapeBuilder.prototype.buildSleeveShape = function (color) {
        return __awaiter(this, void 0, void 0, function () {
            var sleeveObject, _a, shadowSpotlightContainer, shadowLeftContainer, shadowRightContainer, groupObjects, _b, shapeWidth, shapeHeight, shapeTopOffset, shapeLeftOffset, offset, fabricStatic;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.buildSleeveObject()];
                    case 1:
                        sleeveObject = _c.sent();
                        _a = this.getShadowsObjects(), shadowSpotlightContainer = _a.shadowSpotlightContainer, shadowLeftContainer = _a.shadowLeftContainer, shadowRightContainer = _a.shadowRightContainer;
                        groupObjects = [
                            shadowSpotlightContainer,
                            sleeveObject,
                            shadowLeftContainer,
                            shadowRightContainer,
                        ];
                        _b = this.getSleeveShapeParams(), shapeWidth = _b.shapeWidth, shapeHeight = _b.shapeHeight, shapeTopOffset = _b.shapeTopOffset, shapeLeftOffset = _b.shapeLeftOffset;
                        offset = shapeLeftOffset - shadowSpotlightContainer.getWidth();
                        if (this.sideId === "front" || this.sideId === "top") {
                            offset = shapeLeftOffset;
                        }
                        fabricStatic = this.sideEditorInstance.fabricStatic;
                        return [2 /*return*/, new fabricStatic.Group(groupObjects, {
                                width: shapeWidth + shadowSpotlightContainer.getWidth(),
                                height: shapeHeight,
                                left: offset,
                                top: shapeTopOffset,
                                evented: false,
                                selectable: false,
                                id: "background_side",
                                backgroundColor: color,
                            })];
                }
            });
        });
    };
    SleeveShapeBuilder.prototype.getSleeveShapeParams = function () {
        var _a = this.shapeZone, height = _a.height, left = _a.left, top = _a.top, width = _a.width;
        return {
            shapeWidth: width,
            shapeHeight: height,
            shapeLeftOffset: left,
            shapeTopOffset: top,
        };
    };
    SleeveShapeBuilder.prototype.buildSleeveObject = function () {
        return __awaiter(this, void 0, void 0, function () {
            var fabricStatic, fillPattern, _a, shapeWidth, shapeHeight, shapeTopOffset, shapeLeftOffset;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        fabricStatic = this.sideEditorInstance.fabricStatic;
                        return [4 /*yield*/, this.getPatternMaterial()];
                    case 1:
                        fillPattern = _b.sent();
                        _a = this.getSleeveShapeParams(), shapeWidth = _a.shapeWidth, shapeHeight = _a.shapeHeight, shapeTopOffset = _a.shapeTopOffset, shapeLeftOffset = _a.shapeLeftOffset;
                        return [2 /*return*/, new fabricStatic.Rect({
                                width: shapeWidth,
                                height: shapeHeight,
                                top: shapeTopOffset,
                                left: shapeLeftOffset,
                                fill: fillPattern,
                            })];
                }
            });
        });
    };
    SleeveShapeBuilder.prototype.getPatternMaterial = function () {
        var _this = this;
        var fabricStatic = this.sideEditorInstance.fabricStatic;
        return new Promise(function (resolve) {
            return new fabricStatic.Pattern({
                source: _this.textureObject.getElement(),
                repeat: "repeat",
            }, function (object) {
                _this.sideEditorInstance.fabric.renderAll();
                resolve(object);
            });
        });
    };
    SleeveShapeBuilder.prototype.getShadowsObjects = function () {
        var fabricStatic = this.sideEditorInstance.fabricStatic;
        var _a = this.getSleeveShapeParams(), shapeWidth = _a.shapeWidth, shapeHeight = _a.shapeHeight, shapeTopOffset = _a.shapeTopOffset, shapeLeftOffset = _a.shapeLeftOffset;
        var sidesShadowsWidth = 4;
        var shadowLeftContainer = new fabricStatic.Rect({
            width: sidesShadowsWidth,
            height: shapeHeight,
            left: shapeLeftOffset,
            top: shapeTopOffset,
        });
        shadowLeftContainer.setGradient("fill", {
            type: "linear",
            x1: sidesShadowsWidth * 2,
            y1: shapeHeight / 2,
            x2: sidesShadowsWidth * -sidesShadowsWidth,
            y2: shapeHeight / 2,
            colorStops: this.shadowGradientConfig["left"],
        });
        var shadowRightContainer = new fabricStatic.Rect({
            width: sidesShadowsWidth,
            height: shapeHeight,
            left: shapeLeftOffset + shapeWidth - sidesShadowsWidth,
            top: shapeTopOffset,
        });
        shadowRightContainer.setGradient("fill", {
            type: "linear",
            x1: sidesShadowsWidth * 2,
            y1: shapeHeight / 2,
            x2: sidesShadowsWidth * -sidesShadowsWidth,
            y2: shapeHeight / 2,
            colorStops: this.shadowGradientConfig["right"],
        });
        var _b = this.calcSpotlightContainer(shapeHeight), height = _b.height, width = _b.width, r1 = _b.r1, r2 = _b.r2, x1 = _b.x1, x2 = _b.x2, y1 = _b.y1, y2 = _b.y2;
        var spotlightOffset = shapeLeftOffset - width;
        var rotate = true;
        if (this.sideId === "front" || this.sideId === "top") {
            spotlightOffset = shapeLeftOffset + shapeWidth;
            rotate = false;
        }
        var shadowSpotlightContainer = new fabricStatic.Rect({
            width: width,
            height: height,
            left: spotlightOffset,
            top: shapeTopOffset,
            fill: "red",
        });
        shadowSpotlightContainer.setGradient("fill", {
            type: "radial",
            r1: r1,
            r2: r2,
            x1: x1,
            x2: x2,
            y1: y1,
            y2: y2,
            colorStops: this.shadowGradientConfig["spotlight"],
        });
        if (rotate) {
            shadowSpotlightContainer.rotate(180);
        }
        return {
            shadowSpotlightContainer: shadowSpotlightContainer,
            shadowLeftContainer: shadowLeftContainer,
            shadowRightContainer: shadowRightContainer,
        };
    };
    // Well the whole solution to create shadows in 2d and treat it as 3d is not cool
    // But until we introduce 2.0 editor this is the only solution - btw. effect is nice
    // Design team prepared base shadows for FS23 box and defaultShapeHeight
    // and defaultShapeWidth const exist to strech shadows based on those values accross
    // all sleeved mailer box sizes shadow should looks like, we do some calculations
    // and based on aspect ratio We can align shadow to all sides with good match
    SleeveShapeBuilder.prototype.calcSpotlightContainer = function (newShapeHeight) {
        var defaultShapeHeight = 395;
        var defaultShapeWidth = 76;
        var aspectRatio = defaultShapeHeight / defaultShapeWidth;
        var newShapeWidth = newShapeHeight / aspectRatio;
        var objects = this.calcGradientParams(newShapeHeight);
        return __assign({ width: newShapeWidth, height: newShapeHeight }, objects);
    };
    SleeveShapeBuilder.prototype.calcGradientParams = function (newShapeHeight) {
        var r1Correction = 5;
        var r2Correction = 7;
        var x2Correction = 8;
        // for boxes with height smaller than 100
        // we need to justify gradient coords
        if (newShapeHeight < 100) {
            r1Correction = 0;
            r2Correction = 5;
            x2Correction = 0;
        }
        return {
            r1: 3 *
                (newShapeHeight -
                    this.calcHeightWithPerc(newShapeHeight, r1Correction)),
            r2: 3 *
                (newShapeHeight +
                    this.calcHeightWithPerc(newShapeHeight, r2Correction)),
            x1: 3 * -newShapeHeight,
            y1: newShapeHeight / 2,
            x2: 3 *
                -(newShapeHeight - this.calcHeightWithPerc(newShapeHeight, x2Correction)),
            y2: newShapeHeight / 2,
        };
    };
    SleeveShapeBuilder.prototype.calcHeightWithPerc = function (newShapeHeight, value) {
        if (value == 0) {
            return 0;
        }
        return value * ((newShapeHeight - value) / newShapeHeight);
    };
    return SleeveShapeBuilder;
}());
export default SleeveShapeBuilder;
