import React from "react"
import { observer } from "mobx-react-lite"
import { EditContextSwitcherPureComponent } from "./EditContextSwitcherPureComponent"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"

export const DoubleSidePrintSwitcher = observer(() => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ecommerce,
    c.analytics,
  ])

  if (!containerSet) {
    return null
  }

  const { contextController, productConfigurationUiController } =
    containerSet.designAndProductDriver
  const { actionTracker } = containerSet.analytics

  const isDoubleSidedPrintActive =
    contextController.isPrintActiveForActiveContext()

  const onChangePrintInside = async () => {
    await productConfigurationUiController.toggleDoubleSidedPrintingOption()
    actionTracker?.printInsideSwitched(!isDoubleSidedPrintActive, "side_view")
  }

  return (
    <EditContextSwitcherPureComponent
      isPrintInsideActive={isDoubleSidedPrintActive}
      onChange={onChangePrintInside}
    />
  )
})
