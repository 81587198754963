import React from "react"

import Typography from "dsl/src/atoms/Typography/Typography"

import styles from "./UserBenefit.module.scss"

export const UserBenefit = ({ type, description }) => {
  return (
    <div className={styles["benefits"]}>
      <div className={styles["check-mark"]} />
      <Typography type={type} className={styles["separator-one-side"]}>
        {description}
      </Typography>
    </div>
  )
}
