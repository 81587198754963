import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { MaskController } from "./mask-controller"
import { DuplicationOptions } from "../canvas-object-controller/types"

export class MaskDuplicationController {
  constructor(
    private readonly originalObject: PackhelpEditableObject,
    private readonly vdEditor: VirtualDielineEditor
  ) {}

  public async duplicateForIfNeeded(
    newObject: PackhelpEditableObject,
    options: DuplicationOptions
  ): Promise<void> {
    if (!this.isMaskAvailable()) {
      newObject.maskConfig = undefined

      return
    }

    const maskController = new MaskController(newObject, this.vdEditor)
    await maskController.init(this.originalObject.maskController!.getConfig())

    if (!options.withRender) {
      maskController.clear()
    }
  }

  private isMaskAvailable(): boolean {
    if (!this.originalObject.maskController) {
      return false
    }

    const isMaskTypeCovering =
      this.originalObject.maskController.getType() === "covering"
    const isClippingMaskAvailable =
      this.vdEditor.backgroundsModule.isClippingMaskAvailable()

    return isMaskTypeCovering || isClippingMaskAvailable
  }
}
