import { observer } from "mobx-react-lite"
import React from "react"
import styles from "./ZoomToggler.module.scss"
import { useTranslate } from "../../../hooks/useTranslate"
import { Chip } from "dsl/src/atoms/Chip/Chip"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

export const ZoomToggler = observer(() => {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ui,
    c.analytics,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, ui, analytics } = containerSet
  const { editorToolbarStore } = ui
  const { productDriver, contextController } = designAndProductDriver
  const { is3DPreviewOn, activeSpace, productRenderPilot } = productDriver.state

  if (
    is3DPreviewOn ||
    productRenderPilot.is3DProduct() ||
    !activeSpace ||
    !productRenderPilot.uiConfig.editZone.zoom.available
  ) {
    return null
  }

  const onChange = () => {
    editorToolbarStore.closeTab()
    contextController.toggleZoom()
    analytics.analyticsController?.trackZoomTogglerClicked(
      contextController.isZoomActive()
    )
  }

  return (
    <div className={styles.wrapper}>
      <Chip onClick={onChange} e2eTarget="zoom-toggler">
        {contextController.isZoomActive() ? (
          <>
            {t("component.zoom.zoom-out")}
            <span className={styles.icon}>-</span>
          </>
        ) : (
          <>
            {t("component.zoom.zoom-in")}
            <span className={styles.icon}>+</span>
          </>
        )}
      </Chip>
    </div>
  )
})
