import EventEmitter from "eventemitter3"
import FabricAssetsLoaderService from "../../../libs/services/fabric-assets-loader-service"
import { VirtualDielineImporter } from "./services/virtual-dieline-importer"
import { VirtualDielineExporter } from "./services/virtual-dieline-exporter"
import {
  isAssetGroup,
  isInteractiveCanvas,
  VirtualDielineDataLessObject,
} from "../../../modules/ph-api/asset-types"
import { Size, TempLayers } from "./types/render-engine-types"
import {
  PackhelpCanvas,
  PackhelpGroup,
  PackhelpImage,
  PackhelpObject,
  VirtualDieline,
} from "./object-extensions/packhelp-objects"
import { DielineNavigator } from "./modules/dieline-navigator/dieline-navigator"
import { SafeZonesModule } from "./modules/safe-zones-module"
import { SpaceHighlightModule } from "./modules/space-highlight-module"
import { GlueStripModule } from "./modules/glue-strip-module"
import { BackgroundsModule } from "./modules/backgrounds-module"
import AssetsModule from "./modules/assets-module"
import {
  EditContext,
  ModelEditableSpaces,
  ThumbnailConfig,
} from "../../../libs/products-render-config/types"
import { ProductRenderPilot } from "../../../libs/products-render-config/product-render-pilot"
import { SpaceClippingHelper } from "./modules/assets-module/helpers/space-clipping-helper"
import { CanvasObjectControllerFactory } from "./modules/assets-module/canvas-object-controller/canvas-object-controller-factory"
import {
  AllEditorEventsEmitter,
  eventTree,
} from "../../../stores/editor-events"
import { DesignVersion } from "../../../modules/design/version"
import { LayerHelper } from "./modules/assets-module/helpers/layer-helper"
import { CanvasPreviewPreparator } from "./services/canvas-preview-preparator"
import { NoPrintModule } from "./modules/no-print-module/no-print-module"
import { TempDielineCreator } from "./services/temp-dieline.creator"
import _debounce from "lodash/debounce"

export type SceneDimensions = {
  width: number
  height: number
  centerX: number
  centerY: number
}

class VirtualDielineEditor {
  public isEditMode = false
  public fabricCanvas!: PackhelpCanvas
  public productRenderPilot!: ProductRenderPilot
  public dielineNavigator!: DielineNavigator
  public safeZonesModule!: SafeZonesModule
  public spaceHighlightModule!: SpaceHighlightModule
  public glueStripModule!: GlueStripModule
  public noPrintModule!: NoPrintModule
  public backgroundsModule!: BackgroundsModule
  public assetsModule!: AssetsModule

  private isDisposed: boolean = false
  public texture?: PackhelpImage
  public virtualDieline!: VirtualDieline

  private debouncedRefreshDieline = _debounce(this.refreshDieline.bind(this))

  constructor(
    private readonly canvasDimensions: Size,
    public readonly editContext: EditContext,
    //TODO: we should move those events to AllEditorEventsEmitter
    public readonly eventEmitter: EventEmitter,
    public readonly ee: AllEditorEventsEmitter
  ) {
    this.debug(this.editContext)
  }

  debug(context) {
    if (!globalThis.virtualDielineEditor) {
      globalThis.virtualDielineEditor = {}
    }

    if (!globalThis.virtualDielineEditor[context]) {
      globalThis.virtualDielineEditor[context] = this
    }
  }

  public async init({
    fabricCanvas,
    virtualDieline,
    productRenderPilot,
    texture,
  }: {
    fabricCanvas: PackhelpCanvas
    virtualDieline: VirtualDieline
    productRenderPilot: ProductRenderPilot
    texture?: PackhelpImage
  }): Promise<VirtualDielineEditor> {
    this.fabricCanvas = fabricCanvas
    this.isDisposed = false
    this.virtualDieline = virtualDieline
    this.texture = texture
    this.productRenderPilot = productRenderPilot

    this.virtualDieline.set({
      visible: true,
      left: (this.canvasDimensions.width - this.virtualDieline.width!) / 2,
    })
    this.safeZonesModule = new SafeZonesModule(this)
    this.spaceHighlightModule = new SpaceHighlightModule(this)
    this.glueStripModule = new GlueStripModule(this)
    this.noPrintModule = new NoPrintModule(this)
    this.backgroundsModule = new BackgroundsModule(this)
    this.assetsModule = new AssetsModule(this)
    this.dielineNavigator = new DielineNavigator(
      this,
      this.backgroundsModule,
      this.assetsModule,
      this.productRenderPilot,
      this.editContext
    )
    const backgroundTexture =
      await this.backgroundsModule.prepareTextureForThreeDimensionalVisualization()
    this.fabricCanvas.backgroundColor = undefined
    this.addOnCanvas(backgroundTexture)
    this.listenToResize()

    return this
  }

  public setVirtualDieline(virtualDieline: VirtualDieline) {
    this.virtualDieline = virtualDieline

    this.virtualDieline.set({
      visible: true,
      left: (this.canvasDimensions.width - this.virtualDieline.width) / 2,
    })
  }

  public async prepareEditing(): Promise<void> {
    const contextEditableSpaces =
      this.productRenderPilot.getContextEditableSpaces(this.editContext)

    await Promise.all([
      this.safeZonesModule.createSafeZones(contextEditableSpaces),
      this.spaceHighlightModule.createHighlights(contextEditableSpaces),
      this.glueStripModule.createGlueStrip(contextEditableSpaces),
      this.noPrintModule.createNoPrintZone(contextEditableSpaces),
    ])
  }

  private listenToResize() {
    const parentElement = this.fabricCanvas?.wrapperEl?.parentElement

    if (!parentElement) {
      return
    }

    new ResizeObserver((entries) => {
      const entry = entries[0]

      if (entry) {
        this.dielineNavigator.onResize()
        this.eventEmitter.emit("vdEditorResized")
      }
    }).observe(parentElement)
  }

  public setProductRenderPilot(productRenderPilot: ProductRenderPilot): void {
    this.productRenderPilot = productRenderPilot
    this.dielineNavigator.setProductRenderPilot(productRenderPilot)
  }

  public getSceneDimensions(): SceneDimensions {
    return this.calculateSceneDimensions()
  }

  public getEditContext(): EditContext {
    return this.editContext
  }

  public dispose(): void {
    if (!this.isDisposed) {
      this.fabricCanvas.dispose()
      this.isDisposed = true
    }
  }

  public async import(
    designData: VirtualDielineDataLessObject,
    designDataFormatVersion?: DesignVersion
  ): Promise<void> {
    const virtualDielineImporter = new VirtualDielineImporter(this, false)
    await virtualDielineImporter.import(designData, designDataFormatVersion)
  }

  /**
   * This disables editing in preview mode
   */
  public setPreviewMode() {
    if (!isInteractiveCanvas(this.fabricCanvas)) {
      return
    }

    this.fabricCanvas.selection = false

    for (const object of this.fabricCanvas.getObjects()) {
      object.set({
        selectable: false,
        evented: false,
      })

      if (isAssetGroup(object)) {
        object.set({
          subTargetCheck: false,
        })
      }
    }

    this.assetsModule.set2dInterfaceObjectsVisibility(false)
  }

  public async export(): Promise<VirtualDielineDataLessObject> {
    const virtualDielineExporter = new VirtualDielineExporter(this)
    return virtualDielineExporter.export()
  }

  public async showSpace(spaceId: ModelEditableSpaces): Promise<void> {
    await this.hideDieline()

    this.ee.emit(eventTree.productDriver.showSpaceStarted)

    const isPrintActive = this.productRenderPilot.isPrintActiveFor(
      this.editContext
    )

    this.showCanvas(false)
    this.showGlobalLayers(false)

    this.assetsModule.clearActiveObject()
    await this.evokeAssetsClipPaths()
    await this.dielineNavigator.resetPanning()
    await this.assetsModule.showAssetsNotInSpace(spaceId, false)
    this.assetsModule.set2dInterfaceObjectsVisibility(isPrintActive, spaceId)
    this.assetsModule.showAssetsInSpace(spaceId, isPrintActive)
    this.assetsModule.showAssetsNotInSpace(spaceId, false)
    this.glueStripModule.showGlueStripInSpace(spaceId)
    await this.dielineNavigator.panToSpace(spaceId)
    await this.revokeAssetsClipPaths()

    this.fabricCanvas.renderAll()
    this.showCanvas(true)

    this.ee.emit(eventTree.productDriver.showSpaceEnded, spaceId)
  }

  public async resetDielinePosition(): Promise<void> {
    await this.dielineNavigator.resetPanning()
    await this.revokeAssetsClipPaths()

    const isPrintActive = this.productRenderPilot.isPrintActiveFor(
      this.editContext
    )
    this.assetsModule.setEditableObjectsVisibility(isPrintActive)
    this.assetsModule.set2dInterfaceObjectsVisibility(false)
    this.showGlobalLayers(isPrintActive)

    this.fabricCanvas.renderAll()
  }

  public async escapeEditMode(): Promise<void> {
    if (!this.isEditMode || !isInteractiveCanvas(this.fabricCanvas)) {
      return
    }

    this.isEditMode = false
    this.fabricCanvas.selection = false

    this.assetsModule.clearActiveObject()
    this.switchOffCanvasEventsRegistry()
    this.glueStripModule.hideGlueStripInSpace(
      this.dielineNavigator.getActiveSpaceId()!
    )

    this.ee.emit(eventTree.productDriver.escapeEditModeEnded)
  }

  public async enterEditMode(): Promise<void> {
    if (this.isEditMode || !isInteractiveCanvas(this.fabricCanvas)) {
      return
    }

    this.isEditMode = true
    this.fabricCanvas.selection = true

    this.spaceHighlightModule.offHighlightSpace()
    this.switchOnCanvasEventsRegistry()
  }

  private revokeAssetsClipPaths() {
    return Promise.all(
      this.assetsModule.getEditableObjects().map((object) => {
        return SpaceClippingHelper.setSpaceClipping(
          this,
          object.originSpaceArea,
          object,
          object.clipMode
        )
      })
    )
  }

  private evokeAssetsClipPaths() {
    return Promise.all(
      this.assetsModule.getEditableObjects().map((object) => {
        return SpaceClippingHelper.evokeSpaceClipping(
          this,
          object.originSpaceArea,
          object
        )
      })
    )
  }

  public async showDieline(): Promise<void> {
    this.showCanvas(false)
    this.showGlobalLayers(false)

    const { dieline, stroke, clipPath } = await new TempDielineCreator(
      this.getProductVirtualDieline(),
      {
        texture: this.texture,
        background: this.backgroundsModule.getGlobalBackground(),
      }
    ).call()

    this.fabricCanvas.clipPath = clipPath

    this.addOnCanvas(dieline)
    this.addOnCanvas(stroke)

    this.setPreviewMode()
    this.dielineNavigator.panToDieline()

    this.showCanvas(true)
    this.showGlobalLayers(true)

    this.ee.on(
      eventTree.productDriver.backgroundChanged,
      this.debouncedRefreshDieline
    )
  }

  public async refreshDieline(): Promise<void> {
    const currentDieline = this.getCanvasObjectById<PackhelpGroup>(
      TempLayers.TEMP_DIELINE
    )
    const background = this.backgroundsModule.getGlobalBackground()

    if (!currentDieline || !background) {
      return
    }

    this.dielineNavigator.resetRotation()

    const { dieline } = await new TempDielineCreator(
      this.getProductVirtualDieline(),
      {
        texture: this.texture,
        background,
      }
    ).call()

    this.fabricCanvas.remove(currentDieline)
    this.addOnCanvas(dieline)

    this.dielineNavigator.panToDieline()
  }

  public async hideDieline(): Promise<void> {
    const dieline = this.dielineNavigator.getTempDieline()

    if (!dieline) {
      return
    }

    const stroke = this.getCanvasObjectById(TempLayers.TEMP_DIELINE_STROKE)

    await this.dielineNavigator.resetPanning()

    for (const tempObject of [dieline, stroke]) {
      tempObject && this.fabricCanvas.remove(tempObject)
    }

    this.fabricCanvas.renderAll()

    this.ee.off(
      eventTree.productDriver.backgroundChanged,
      this.debouncedRefreshDieline
    )
  }

  private showCanvas(shouldShow: boolean): void {
    if (this.fabricCanvas.wrapperEl) {
      this.fabricCanvas.wrapperEl.style.opacity = shouldShow ? "1" : "0"
    }
  }

  public showGlobalLayers(shouldShow: boolean): void {
    const globalBackground = this.backgroundsModule.getGlobalBackground()
    const globalPattern = this.backgroundsModule.getGlobalPattern()
    const globalBackgroundImage =
      this.backgroundsModule.getGlobalBackgroundImage()

    if (globalBackground) {
      globalBackground.set({
        visible: shouldShow,
      })
    }

    if (globalPattern) {
      globalPattern.set({
        visible: shouldShow,
      })
    }

    if (globalBackgroundImage) {
      globalBackgroundImage.set({
        visible: shouldShow,
      })
    }
  }

  public async setTexture(textureConfig?: { path?: string }): Promise<void> {
    const path = textureConfig?.path

    if (path === this.texture?.getSrc()) {
      return
    }

    this.texture = path
      ? await FabricAssetsLoaderService.loadAsset(path)
      : undefined
  }

  public getTexture() {
    return this.texture
  }

  public getCanvasTexture() {
    return this.fabricCanvas.lowerCanvasEl
  }

  /**
   * Heads up, this is CPU intesive function. It consumes ~100ms on M1.
   *
   * We could try to optimize this by calling `decorateSceneWithBackground`
   * on "non-active" VD, but this leads to race conditions and needs further
   * debugging
   *
   * For thumbnails we need background
   * For DTP we don't need that bg. product texture
   */

  public async getCanvasToPreview(
    config: ThumbnailConfig,
    space?: ModelEditableSpaces
  ): Promise<HTMLCanvasElement> {
    const preparator = new CanvasPreviewPreparator(this)
    return preparator.getCanvasToPreview(config, space)
  }

  public getCanvasDimensions() {
    return this.canvasDimensions
  }

  public getProductVirtualDieline(): VirtualDieline {
    return this.virtualDieline
  }

  public addOnCanvas(object, withRender = true) {
    this.fabricCanvas.add(object)
    this.sortObjectsOnCanvas()

    if (withRender) {
      this.fabricCanvas.renderAll()
    }

    this.eventEmitter.emit("onVirtualDielineObjectAdded", object)
  }

  public sortObjectsOnCanvas() {
    const objects = this.fabricCanvas.getObjects()

    const bottomLayerObjects = LayerHelper.getBottomLayerObjects(objects)
    const middleLayerObjects = LayerHelper.getMiddleLayerObjects(objects)
    const topLayerObjects = LayerHelper.getTopLayerObjects(objects)

    bottomLayerObjects.sort(LayerHelper.compareLayers)
    middleLayerObjects.sort(LayerHelper.compareLayers)
    topLayerObjects.sort(LayerHelper.compareLayers)

    const merged = [
      ...bottomLayerObjects,
      ...middleLayerObjects,
      ...topLayerObjects,
    ]

    merged.forEach((obj, index) => this.fabricCanvas.moveTo(obj, index))

    this.fabricCanvas.getObjects().sort(LayerHelper.compareLayers)
  }

  public getCanvasObjectById<T = PackhelpObject>(id: string): T | undefined {
    return this.fabricCanvas
      .getObjects()
      .find((object) => object.id === id) as T
  }

  public clearEditableObjects() {
    for (const object of this.assetsModule.getEditableObjects()) {
      const objectController = new CanvasObjectControllerFactory(
        this
      ).getController(object)
      objectController.remove()
    }

    this.fabricCanvas.renderAll()
  }

  private switchOnCanvasEventsRegistry() {
    this.assetsModule.switchOnAssetsModuleEvents()
    this.safeZonesModule.switchOnSafeZonesModuleEvents()
    this.glueStripModule.switchOnEvents()
    this.noPrintModule.switchOnEvents()
  }

  private switchOffCanvasEventsRegistry() {
    this.assetsModule.switchOffAssetsModuleEvents()
    this.safeZonesModule.switchOffSafeZonesModuleEvents()
    this.glueStripModule.switchOffEvents()
    this.noPrintModule.switchOffEvents()
  }

  private calculateSceneDimensions(): SceneDimensions {
    let { width, height } = this.canvasDimensions

    if (this.fabricCanvas.wrapperEl?.parentElement) {
      const { width: containerWidth, height: containerHeight } =
        this.fabricCanvas.wrapperEl.parentElement.getBoundingClientRect()

      /**
       * If container width or height is equal to 0 it means that the canvas is unmounted or in static mode.
       * In this case we need to use default values (e.g. for proper thumb generation).
       */
      if (containerWidth > 0 && containerHeight > 0) {
        width = containerWidth
        height = containerHeight
      }
    }

    return {
      width,
      height,
      centerX: width / 2,
      centerY: height / 2,
    }
  }
}

export default VirtualDielineEditor
