import ProductDriver from "../product-driver/product.driver"
import {
  EditContext,
  ModelContext,
  ModelEditableSpaces,
  ViewType,
} from "../../libs/products-render-config/types"
import { ProductRenderPilot } from "../../libs/products-render-config/product-render-pilot"
import { AllEditorEventsEmitter, eventTree } from "../editor-events"

export class ContextController {
  constructor(
    private productDriver: ProductDriver,
    private ee: AllEditorEventsEmitter
  ) {}

  public async changeEditContext(editContext: EditContext): Promise<void> {
    if (this.isModelView()) {
      return this.setModelByEditContext(editContext)
    }

    if (this.isDielineView()) {
      return this.productDriver.setView(ViewType.DIELINE, editContext, null)
    }

    if (this.isSpaceView()) {
      return this.productDriver.setView(
        ViewType.SPACE,
        editContext,
        this.productRenderPilot.getDefaultSpace(editContext)
      )
    }
  }

  public openModel() {
    this.setModelByEditContext(EditContext.INSIDE)
  }

  public closeModel() {
    this.setModelByEditContext(EditContext.OUTSIDE)
  }

  public setModelByEditContext(editContext: EditContext) {
    const isDbyMode = this.productRenderPilot.isDbyMode()

    if (isDbyMode) {
      return
    }

    const modelContext =
      this.productDriver.state.productRenderPilot.getModelContextByEditContext(
        editContext
      )
    this.setActiveEditContext(editContext)

    if (!this.isModelView()) {
      return
    }

    this.setActiveModelContext(modelContext)
  }

  public toggleModel() {
    if (this.getActiveEditSpace()) {
      return
    }

    this.isModelOpen() ? this.closeModel() : this.openModel()
    this.ee.emit(eventTree.productDriver.modeChanged, {
      modelContext: this.getActiveModelContext(),
    })
  }

  public isModelOpen(): boolean {
    return this.getActiveModelContext() === ModelContext.OPENED
  }

  public isModelClose(): boolean {
    return this.getActiveModelContext() === ModelContext.CLOSED
  }

  public getActiveModelContext(): ModelContext {
    return this.productDriver.state.modelContext
  }

  public getActiveEditContext(): EditContext {
    return this.productDriver.state.activeContext
  }

  public getActiveEditSpace(): ModelEditableSpaces | null {
    return this.productDriver.state.activeSpace
  }

  public isModelView(): boolean {
    return this.productDriver.state.activeViewType === ViewType.MODEL
  }

  public isDielineView(): boolean {
    return this.productDriver.state.activeViewType === ViewType.DIELINE
  }

  public isSpaceView(): boolean {
    return this.productDriver.state.activeViewType === ViewType.SPACE
  }

  public async forceSpaceView(): Promise<ModelEditableSpaces> {
    if (!this.isSpaceView()) {
      await this.switchToDefaultSpaceView()
    }

    return this.getActiveEditSpace()!
  }

  public async switchToDefaultSpaceView(): Promise<void> {
    return this.productDriver.switchToDefaultSpace()
  }

  public async switchToLastPreview(): Promise<void> {
    return this.productDriver.switchToLastPreview()
  }

  public isPrintAvailableForActiveContext(): boolean {
    return this.productRenderPilot.isPrintAvailableFor(
      this.getActiveEditContext()
    )
  }

  public isPrintActiveForActiveContext(): boolean {
    return this.productRenderPilot.isPrintActiveFor(this.getActiveEditContext())
  }

  public isSceneDisabled(): boolean {
    if (this.productRenderPilot.getEditorMode() !== "editor") {
      return false
    }

    return (
      this.productRenderPilot.isEditContextDisabled(
        this.getActiveEditContext()
      ) && !this.isModelView()
    )
  }

  public isZoomActive(): boolean {
    return this.productDriver.state.isZoomActive
  }

  public setDefaultZoom() {
    this.productDriver.setIsZoomActive(
      this.productRenderPilot.uiConfig.editZone.zoom.activeByDefault
    )
  }

  public toggleZoom() {
    this.productDriver.setIsZoomActive(!this.isZoomActive())
  }

  private setActiveEditContext(editContext: EditContext) {
    const isAvailable = this.productRenderPilot.isPrintAvailableFor(editContext)

    if (!isAvailable || editContext === this.getActiveEditContext()) {
      return
    }

    this.productDriver.setEditContext(editContext)
  }

  private setActiveModelContext(modelContext: ModelContext) {
    if (modelContext === this.getActiveModelContext()) {
      return
    }

    this.productDriver.changeModelContext(modelContext)
  }

  private get productRenderPilot(): ProductRenderPilot {
    return this.productDriver.state.productRenderPilot
  }
}
