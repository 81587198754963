import { ObjectController } from "./object-controller"
import VirtualDielineEditor from "../../../virtual-dieline-editor"

import {
  DEFAULT_REMOVE_WHITE_COLOR_FILTER_DISTANCE,
  PackhelpEditableImage,
} from "../../../object-extensions/packhelp-objects"
import { isAssetImage } from "../../../../../../modules/ph-api/asset-types"
import { Colour } from "../../../../../../libs/value-objects/colour"
import fabric from "../../../../../../libs/vendors/Fabric"
import {
  AvailableColourModes,
  MonochromeColours,
} from "../../../../../../libs/products-render-config/types"
import {
  CanvasEvent,
  CanvasObjectEvent,
} from "../../../../../../stores/_events/domain-events"
import FiltersModule from "../../filters-module"

export class ImageObjectController extends ObjectController {
  constructor(
    protected readonly canvasObject: PackhelpEditableImage,
    protected readonly virtualDielineEditor: VirtualDielineEditor
  ) {
    super(canvasObject, virtualDielineEditor)

    if (!isAssetImage(this.canvasObject)) {
      throw new Error("Type not supported")
    }
  }

  public getObject(): PackhelpEditableImage {
    return this.canvasObject
  }

  public getFill(): Colour {
    const filters = this.canvasObject.filters
    if (filters) {
      const filter: fabric.IBlendColorFilter | undefined = filters.find(
        (filter) => filter["mode"] === "tint"
      )

      if (filter !== undefined) {
        return new Colour(filter.color, this.canvasObject.fillPantoneName)
      }
    }

    return this.assetColorController.getDefaultColor()
  }

  public setThreshold(threshold: number) {
    FiltersModule.changeAssetThreshold(this.canvasObject, threshold)

    this.canvasObject.fire(CanvasObjectEvent.changed)
    this.canvas.fire(CanvasEvent.objectModified, {
      target: this.canvasObject,
    })

    this.rerender()
  }

  public getThreshold() {
    const filter = this.getFilter("RemoveColor")

    if (!filter) {
      return 0
    }

    return filter.distance
  }

  public toggleImageBackground() {
    let filter = this.getFilter("RemoveColor")

    if (!filter) {
      filter = FiltersModule.setRemoveColorFilter(
        this.canvasObject,
        0,
        new Colour(MonochromeColours.WHITE)
      )
    }

    if (filter.distance > 0) {
      filter.distance = 0
    } else {
      filter.distance = DEFAULT_REMOVE_WHITE_COLOR_FILTER_DISTANCE
    }

    this.canvasObject.applyFilters()
    this.rerender()
  }

  public isColorModificationAvailable(): boolean {
    const colorsPreset =
      this.virtualDielineEditor.productRenderPilot.getColoursPreset()

    if (colorsPreset.mode === AvailableColourModes.MONO_PANTONE) {
      return true
    }

    return false
  }

  public isGroupingAvailable(): boolean {
    if (this.canvasObject.isLogo) {
      return false
    }

    return super.isGroupingAvailable()
  }

  private getFilter(type: string) {
    return this.canvasObject.filters?.find((filter) => filter.type === type)
  }
}
