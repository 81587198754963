import VirtualDielineEditor from "../../virtual-dieline-editor"
import {
  IndexConfigFragmentBottom,
  IndexConfigFragments,
  PackhelpObject,
} from "../../object-extensions/packhelp-objects"
import { BackgroundsLayers } from "../backgrounds-module/types"
import { PackhelpPatternContainer } from "../../object-extensions/packhelp-base-objects"
import { BackgroundLayerClippingHelper } from "../assets-module/helpers/background-layer-clippinng-helper"

export class PatternController {
  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public async applyPattern(pattern: PackhelpPatternContainer) {
    this.removePattern()

    pattern.set({
      id: BackgroundsLayers.GLOBAL_PATTERN,
      evented: false,
      selectable: false,
      indexConfig: {
        fragment: IndexConfigFragments.BOTTOM,
        index: IndexConfigFragmentBottom.GLOBAL_PATTERN,
      },
      visible: !this.vdEditor.dielineNavigator.getActiveSpaceId(),
    })

    await BackgroundLayerClippingHelper.addBackgroundPaddingClipPathIfNeeded(
      this.vdEditor,
      pattern as PackhelpObject
    )

    this.vdEditor.addOnCanvas(pattern)
  }

  public isPatternActive(): boolean {
    return !!this.getPattern()
  }

  public getPattern(): PackhelpObject | undefined {
    return this.vdEditor.getCanvasObjectById(BackgroundsLayers.GLOBAL_PATTERN)
  }

  public removePattern() {
    const pattern = this.getPattern()

    if (pattern) {
      this.vdEditor.fabricCanvas.remove(pattern)
      this.vdEditor.fabricCanvas.renderAll()
    }
  }
}
