import React from "react"
import cxBinder from "classnames/bind"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { TagIcon } from "../TagIcon/TagIcon"
import { ReactComponent as UploadFailedIcon } from "../../../assets/svg/error.svg"
import { ReactComponent as UploadSpinnerIcon } from "../../../assets/svg/spinner.svg"

// Assets
import { ReactComponent as IconPlus } from "../../../assets/svg/plus.svg"
import { ReactComponent as IconMore } from "../../../assets/svg/more.svg"
import styles from "./Thumb88.module.scss"
import { useTooltip } from "../../../hooks/useTooltip"
import { ReactComponent as IconChecked } from "../../../assets/svg/check.svg"

const cx = cxBinder.bind(styles)

type LoadingAttribute = "eager" | "lazy"
type BadgePosition = "top" | "bottom"

interface ThumbProps {
  caption?: string
  truncateCaption?: boolean
  imageURL?: string
  isActive?: boolean
  disabled?: boolean
  isLoading?: boolean
  error?: string | Error | null
  badge?: React.ReactNode
  badgePosition?: BadgePosition
  tooltipItem?: React.ReactNode
  onSelect?(): void
  e2eTarget?: string
  e2eTargetName?: string
  hoverIcon?: React.ElementType | null
  imgLoading?: LoadingAttribute
}

export const Thumb88 = ({
  caption,
  truncateCaption = true,
  imageURL,
  badge,
  isActive,
  onSelect,
  disabled,
  error,
  tooltipItem,
  isLoading,
  e2eTarget,
  e2eTargetName,
  hoverIcon = IconPlus,
  imgLoading,
  badgePosition = "top",
}: ThumbProps) => {
  const { isOpen, closeTooltip, openTooltip, Tooltip } = useTooltip()

  // DumbFix
  /**
   * Thumb88 needs a better api for handling previews of files like .ai
   * and .pdf before they are ready. And since it will have ui fixes, leaving this out
   */
  const assetIsOk = !!imageURL
  const err = typeof error === "string" ? error : undefined
  return (
    <figure aria-label={caption} className={styles.thumb_wrapper} title={err}>
      <picture
        onClick={tooltipItem ? undefined : onSelect}
        className={cx({
          thumb: !disabled,
          thumb_default: disabled,
          active: isActive,
          disabled: disabled || error,
          highlighted: isOpen,
        })}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        {assetIsOk && (
          <img
            className={styles.fullWidth}
            src={imageURL}
            alt={caption}
            loading={imgLoading}
          />
        )}
        {isLoading && !error && (
          <div
            className={cx("thumb_status_container", {
              ["thumb_status_container--loading"]: true,
            })}
          >
            <div className={styles.thumb_loading}>
              <UploadSpinnerIcon />
            </div>
          </div>
        )}
        {error && (
          <div
            className={cx("thumb_status_container", {
              ["thumb_status_container--error"]: true,
            })}
          >
            <div>
              <UploadFailedIcon />
            </div>
          </div>
        )}
        {!isLoading && !error && hoverIcon && (
          <div
            className={styles.thumb_status_container}
            onClick={tooltipItem ? onSelect : undefined}
          >
            <div className={styles.thumb_status_icon}>
              <TagIcon Icon={hoverIcon} />
            </div>
          </div>
        )}
        {tooltipItem && (
          <div
            onClick={isOpen ? closeTooltip : openTooltip}
            className={styles.thumb_more}
          >
            <TagIcon Icon={IconMore} />
            {isOpen && <Tooltip>{tooltipItem}</Tooltip>}
          </div>
        )}
        {badge && badgePosition === "top" && (
          <div className={styles.badge_container}>{badge}</div>
        )}
      </picture>
      {caption && (
        <Typography
          className={cx(styles.figcaption, {
            disabled: disabled,
            truncated: truncateCaption,
          })}
          type={TType.Header13_500}
          htmlElement="figcaption"
        >
          {caption}
        </Typography>
      )}
      {badge && badgePosition === "bottom" && (
        <div className={styles.badge_container}>{badge}</div>
      )}
      {isActive && !tooltipItem && (
        <span className={styles.checked}>
          <TagIcon Icon={IconChecked} />
        </span>
      )}
    </figure>
  )
}
