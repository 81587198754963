import { ListingApiAbstract } from "./listing-api-abstract"
import {
  GenericListingType,
  ListingDataPayload,
} from "../utils/listing-services.types"

export class ListingApiService extends ListingApiAbstract<GenericListingType> {
  public getDataForParameters = async (
    data: ListingDataPayload
  ): Promise<GenericListingType> => {
    const response = await this.ax.get("/listing", { params: data })

    return response.data
  }
}
