import React, {
  PropsWithChildren,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react"
import styles from "./Picker.module.scss"
import PickerHeader from "./PickerHeader"
import PickerFooter, { PickerFooterProps } from "./PickerFooter"
import { useTranslate } from "../../../hooks/useTranslate"
import { FormattedMessage } from "react-intl"
import { TType } from "shared-libs/src/js/shared-components/i18n/TypographyWithTranslate"
import Typography from "dsl/src/atoms/Typography/Typography"

export interface PickerProps extends PickerFooterProps {
  headerLinkText?: string
  onBackClick: (event: React.MouseEvent<HTMLAnchorElement>) => void
  onDoneClick: () => void
  singleContent?: boolean
  isInline?: boolean
  header?: string
  Icon?: React.ElementType
}

const Picker = ({
  children,
  onLinkClick,
  onDoneClick,
  onBackClick,
  headerLinkText,
  buttonText,
  linkText,
  singleContent,
  isInline,
  header,
  Icon,
}: PropsWithChildren<PickerProps>) => {
  const t = useTranslate()
  const pickerBodyRef = useRef<HTMLDivElement>(null)

  const scrollPosition = useMemo(() => {
    if (pickerBodyRef.current) {
      return pickerBodyRef.current.scrollTop
    }

    return 0
  }, [children])

  useLayoutEffect(() => {
    setTimeout(() => {
      if (pickerBodyRef.current) {
        pickerBodyRef.current.scrollTop = scrollPosition
      }
    })
  }, [children])

  const pickerContent = (
    <>
      {!isInline && (
        <PickerHeader
          title={headerLinkText || t("generic.back")}
          onClick={onBackClick}
        />
      )}
      {isInline && header && (
        <Typography type={TType.Body15_350} className={styles.header}>
          <FormattedMessage id={header} />
        </Typography>
      )}
      <div
        className={isInline ? styles.body_inline : styles.body}
        ref={pickerBodyRef}
      >
        {children}
      </div>
      <PickerFooter
        onDoneClick={onDoneClick}
        onLinkClick={onLinkClick}
        linkText={linkText}
        buttonText={buttonText || t("generic.done")}
        isInline={isInline}
        Icon={Icon}
      />
    </>
  )

  return singleContent ? (
    pickerContent
  ) : (
    <div
      className={isInline ? styles.inline_wrapper : styles.wrapper}
      e2e-target="color-picker"
      id="color-picker"
    >
      {pickerContent}
    </div>
  )
}

export { Picker, Picker as default }
