import React from "react"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { useTranslate } from "../../../../hooks/useTranslate"
import { ReactComponent as ChangeIcon } from "../../../../assets/svg/shape-icons/change.svg"
import { I18N } from "../../../../i18n"

import { ActionConfirmModal } from "../action-confirm-modal/action-confirm-modal"

export interface ModalProps {
  onClose: () => void
  onConfirm: () => void
  isDbyMode: boolean
}

export const ChangeProductConfirmationModal = ({
  onClose,
  onConfirm,
  isDbyMode,
}: ModalProps) => {
  const t = useTranslate()

  const i18n = isDbyMode
    ? I18N.modals.changeDbyProduct
    : I18N.modals.changeEditorProduct

  return (
    <ActionConfirmModal
      onClose={onClose}
      onConfirm={onConfirm}
      title={t(i18n.title)}
      confirmText={t(i18n.confirm)}
      Icon={ChangeIcon}
    >
      <Typography type={TType.Body15_350}>{t(i18n.messagePart1)}</Typography>
      <br />
      <Typography type={TType.Body15_350}>{t(i18n.messagePart2)}</Typography>
    </ActionConfirmModal>
  )
}
