var _a, _b, _c, _d, _e, _f, _g;
import BAG_CONSTS from "../consts/bagConsts";
var DEFAULT = "default";
var BAG_PRODUCTS_WEIGHTS = (_a = {},
    _a[BAG_CONSTS.PAPER_BAG] = (_b = {},
        _b[BAG_CONSTS.PAPER_BAG_VARIANT_STANDARD] = (_c = {},
            _c[BAG_CONSTS.SKU_MATERIAL_PAPER_NATURAL] = {
                B63: 0.028,
                B66: 0.034,
                B70: 0.036,
                B82: 0.05,
                B95: 0.06,
                B107: 0.072,
            },
            _c[BAG_CONSTS.SKU_MATERIAL_PAPER_WHITE] = {
                B63: 0.03,
                B66: 0.036,
                B70: 0.04,
                B82: 0.054,
                B95: 0.064,
                B107: 0.08,
            },
            _c[DEFAULT] = {
                B63: 0.03,
                B66: 0.036,
                B70: 0.04,
                B82: 0.054,
                B95: 0.064,
                B107: 0.08,
            },
            _c),
        _b),
    _a[BAG_CONSTS.DOYPACK_BAG] = (_d = {},
        /**
         * Product Specs / Products / Stand Up Pouches with Label
         * https://www.notion.so/packhelp/Stand-Up-Pouches-with-Label-2f58bf51f4b343b88295842b72474b1d
         */
        _d[BAG_CONSTS.DOYPACK_BAG_VARIANT] = (_e = {},
            _e[BAG_CONSTS.SKU_MATERIAL_BLACK] = {
                D35: 0.006,
                D42: 0.011,
                D48: 0.0135,
                D56: 0.018,
            },
            _e[BAG_CONSTS.SKU_MATERIAL_KRAFT] = {
                D35: 0.005,
                D42: 0.0075,
                D48: 0.0135,
                D56: 0.016,
            },
            _e[BAG_CONSTS.SKU_MATERIAL_WHITE] = {
                D35: 0.0045,
                D42: 0.0065,
                D48: 0.008,
                D56: 0.014,
            },
            _e[DEFAULT] = {
                D35: 0.006,
                D42: 0.011,
                D48: 0.0135,
                D56: 0.018,
            },
            _e),
        _d),
    _a[BAG_CONSTS.TOTE_BAG] = (_f = {},
        _f[BAG_CONSTS.TOTE_BAG_VARIANT] = (_g = {},
            _g[BAG_CONSTS.SKU_PRINT_MODE_MONO_PANTONE_ONE_SIDE] = {
                TB79: 0.07,
            },
            _g[BAG_CONSTS.SKU_PRINT_MODE_MONO_PANTONE_TWO_SIDES] = {
                TB79: 0.09,
            },
            _g[DEFAULT] = {
                TB79: 0.09,
            },
            _g),
        _f),
    _a);
export default BAG_PRODUCTS_WEIGHTS;
