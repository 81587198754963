var _a, _b;
import PAPER_TUBE_CONSTS from "../consts/paperTubeConsts";
var PAPER_CAN_PRODUCT_VARIANTS = (_a = {},
    _a[PAPER_TUBE_CONSTS.PAPER_CAN] = {
        sizes: [95, 100, 120, 215, 280, 350],
        prefix: "T",
        productCategory: PAPER_TUBE_CONSTS.PAPER_CAN,
        variants: (_b = {},
            _b[PAPER_TUBE_CONSTS.PAPER_CAN_PRODUCT_VARIANT_STANDARD] = {
                materials: [PAPER_TUBE_CONSTS.PRINT_MATERIAL_CARDSTOCK_COATED],
                colorModes: [PAPER_TUBE_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR],
                finishes: [PAPER_TUBE_CONSTS.PRINT_FINISH_NONE],
            },
            _b),
    },
    _a);
export default PAPER_CAN_PRODUCT_VARIANTS;
