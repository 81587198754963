import React from "react"
import cxBinder from "classnames/bind"
import styles from "./Line.module.scss"
const cx = cxBinder.bind(styles)

export interface LineProps {
  vertical?: boolean
  verticalSpacing?: "spacing-1" | "spacing-2" | "spacing-3" | "spacing-4"
}

const Line = (props: LineProps) => {
  const { vertical, verticalSpacing } = props

  return (
    <hr
      className={cx("base", {
        vertical,
        "spacing-1": verticalSpacing === "spacing-1",
        "spacing-2": verticalSpacing === "spacing-2",
        "spacing-3": verticalSpacing === "spacing-3",
        "spacing-4": verticalSpacing === "spacing-4"
      })}
    />
  )
}

export { Line, Line as default }
