import React, { PropsWithChildren } from "react"
import { LowQualityNotification } from "./LowQualityNotification"
import { CameraControlsToolbarElement } from "./CameraControlsToolbarElement/CameraControlsToolbarElement"
import {
  CameraAngle,
  EditContext,
  ModelEditableSpaces,
  ViewType,
} from "../../../../../libs/products-render-config/types"
import { OverscaledObjectsMap } from "../../../../../stores/product-driver/assets.driver"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"

import styles from "./CameraControlsToolbar.module.scss"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { AnalyticsController } from "../../../../../stores/_controllers/analytics-controller"
import { ZoomToggler } from "../../ZoomToggler/ZoomToggler"

const i18n = I18N.component.cameraControlsToolbar

interface CameraControlsToolbarProps {
  currentSpace: CameraAngle
  currentEditContext: EditContext | null
  onCameraSet(
    viewType: ViewType,
    editContext: EditContext,
    editSpace: CameraAngle
  ): void
  isAnyAssetOverscaled: boolean
  overscaledObjectsMap: OverscaledObjectsMap | {}
  availableEditContexts: EditContext[]
  analyticsController?: AnalyticsController
}

export const CameraControlsToolbarList = (props: PropsWithChildren<{}>) => (
  <nav
    className={styles.toolbar}
    e2e-target="toolbar"
    e2e-target-name="camera-control"
  >
    <ul className={styles.toolbar__list_horizontal}>{props.children}</ul>
  </nav>
)

export const TwoDimmSimpleCameraToolbar = ({
  currentSpace = null,
  currentEditContext = null,
  isAnyAssetOverscaled = false,
  overscaledObjectsMap = {},
  onCameraSet,
  availableEditContexts,
  analyticsController,
}: CameraControlsToolbarProps) => {
  const t = useTranslate()

  const handleSetCameraAngle =
    (editContext: EditContext, editSpace: CameraAngle) => () => {
      if (currentEditContext !== editContext || currentSpace !== editSpace) {
        if (editSpace != null) {
          analyticsController?.trackSideOrCameraClicked(editContext, editSpace)
        }

        onCameraSet(ViewType.SPACE, editContext, editSpace)
      }
    }

  const isSpaceHasLowQualityAssets = (
    editContext: EditContext,
    editableSpace: CameraAngle
  ): boolean => {
    const contextMap = overscaledObjectsMap[editContext]

    if (!editableSpace || !contextMap || !contextMap[editableSpace]) {
      return false
    }

    return contextMap[editableSpace].length > 0
  }

  const cameraProps = (editContext: EditContext, angle: CameraAngle) => ({
    isActive: editContext === currentEditContext && angle === currentSpace,
    isAlert: isSpaceHasLowQualityAssets(editContext, angle),
    onClick: handleSetCameraAngle(editContext, angle),
    e2eTargetName: `${editContext}-${angle}`,
  })

  return (
    <>
      <LowQualityNotification isVisible={isAnyAssetOverscaled} />
      {availableEditContexts.length > 1 && (
        <CameraControlsToolbarList>
          {availableEditContexts.map((editContext, key) => {
            return (
              <CameraControlsToolbarElement
                {...cameraProps(editContext, ModelEditableSpaces.DEFAULT)}
                key={key}
              >
                <Typography
                  type={TType.Header13_500}
                  htmlElement="span"
                  className={styles.element_text_centered}
                >
                  {t(i18n[editContext])}
                </Typography>
              </CameraControlsToolbarElement>
            )
          })}
        </CameraControlsToolbarList>
      )}
      <ZoomToggler></ZoomToggler>
    </>
  )
}
