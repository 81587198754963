import { action, observable, makeObservable } from "mobx"
import { ShapesManager } from "../../services/shapes-manager"
import {
  ecoTypesToExcludeOnList,
  GroupedShapes,
  PlantingTreesBadgeType,
  Shape,
} from "../../libs/value-objects/shape"
import ProductDriver from "../product-driver/product.driver"

export class EcoShapesStore {
  @observable public isLoadingEcoShapes = false
  @observable public isLoadingSingleEcoShape = false
  @observable public ecoShapes: GroupedShapes = {}
  @observable public plantingTreesBadges: Shape[] = []

  constructor(
    private readonly shapesManager: ShapesManager,
    private readonly productDriver: ProductDriver
  ) {
    makeObservable(this)
  }

  @action
  public setIsLoadingSingleEcoShape(isLoading: boolean) {
    this.isLoadingSingleEcoShape = isLoading
  }

  public get treeBadgesDownloadUrl(): string {
    return this.shapesManager.getTreeBadgesDownloadUrl()
  }

  public get ecoShapesDownloadUrl(): string {
    return this.shapesManager.getEcoShapesDownloadUrl({
      sku: this.productDriver.productStore.productSku,
    })
  }

  public async loadEcoShapes() {
    this.setIsLoadingEcoShapes(true)

    const ecoShapes = await this.shapesManager.getEcoShapes({
      sku: this.productDriver.productStore.productSku,
    })

    const { list, badges } = this.mapEcoShapes(ecoShapes)

    this.setEcoShapes(list)
    this.setPlantingTreesBadge(badges)
    this.setIsLoadingEcoShapes(false)
  }

  private mapEcoShapes(ecoShapes: GroupedShapes): {
    list: GroupedShapes
    badges: Shape[]
  } {
    const list: GroupedShapes = {}
    let badges: Shape[] = []

    for (const [type, listInType] of Object.entries(ecoShapes)) {
      if (!ecoTypesToExcludeOnList.includes(type)) {
        list[type] = listInType
      }

      if (type === PlantingTreesBadgeType) {
        badges = listInType
      }
    }

    return {
      list,
      badges,
    }
  }

  @action
  private setIsLoadingEcoShapes(isLoading: boolean) {
    this.isLoadingEcoShapes = isLoading
  }

  @action
  private setEcoShapes(ecoShapes: GroupedShapes) {
    this.ecoShapes = ecoShapes
  }

  @action
  private setPlantingTreesBadge(shapes: Shape[]) {
    this.plantingTreesBadges = shapes
  }
}
