var _a, _b, _c;
import PAPER_TUBE_CONSTS from "../consts/paperTubeConsts";
var assetsPath = "/product-key-visuals/";
var customPath = "/product-custom-dimensions/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var sizeAssets = {
    T95: {
        desktop: desktopPath + "T95.png",
        mobile: mobilePath + "T95.png",
    },
    T100: {
        desktop: desktopPath + "T100.png",
        mobile: mobilePath + "T100.png",
    },
    T120: {
        desktop: desktopPath + "T120_2.png",
        mobile: mobilePath + "T120_2.png",
    },
    T215: {
        desktop: desktopPath + "T215.png",
        mobile: mobilePath + "T215.png",
    },
    T280: {
        desktop: desktopPath + "T280.png",
        mobile: mobilePath + "T280.png",
    },
    T350: {
        desktop: desktopPath + "T350.png",
        mobile: mobilePath + "T350.png",
    },
    custom: {
        desktop: customPath + "PaperCan/Desktop/Custom/{locale}.png",
        mobile: customPath + "PaperCan/Mobile/Custom/{locale}.png",
    },
};
var EditorMaterialImage;
(function (EditorMaterialImage) {
    EditorMaterialImage["CARDSTOCK_COATED"] = "https://packhelp-editor-assets-staging.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/can-material-cardstock-coated.png";
})(EditorMaterialImage || (EditorMaterialImage = {}));
var PAPER_CAN_PRODUCT_ASSETS = (_a = {},
    _a[PAPER_TUBE_CONSTS.PAPER_CAN] = (_b = {},
        _b[PAPER_TUBE_CONSTS.PAPER_CAN_PRODUCT_VARIANT_STANDARD] = {
            keyVisual: [assetsPath + "paper_can_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _b),
    _a);
var editorImagePaths = (_c = {},
    _c[PAPER_TUBE_CONSTS.PRINT_MATERIAL_CARDSTOCK_COATED] = EditorMaterialImage.CARDSTOCK_COATED,
    _c);
var productAssets = {
    globalAssets: { editorImagePaths: editorImagePaths },
    productAssets: PAPER_CAN_PRODUCT_ASSETS,
};
export default productAssets;
