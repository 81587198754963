import React, { FC } from "react"
import { FormattedMessage } from "react-intl"
import cxBinder from "classnames/bind"

import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { FormattedCurrency } from "shared-libs/src/js/shared-components/formatted-currency/FormattedCurrency"

import styles from "./CurrencyLabel.module.scss"

const cx = cxBinder.bind(styles)

interface CurrencyLabelProps {
  price: number
  currency: string
  isPerPiece: boolean
  isAdditionalPrice?: boolean
  isFloating?: boolean
}

const PriceSign = ({ price }: { price: number }) => {
  return <span>{price < 0 ? "-" : "+"} </span>
}

export const CurrencyLabel: FC<CurrencyLabelProps> = ({
  price,
  currency,
  isPerPiece,
  isAdditionalPrice,
  isFloating = true,
  ...props
}) => {
  const isFree = price === 0

  const priceLabel = (
    <>
      {isAdditionalPrice && <PriceSign price={price} />}
      <FormattedCurrency value={Math.abs(price)} currency={currency} />
      {isPerPiece && (
        <>
          {" "}
          <FormattedMessage id="cart.single-order-view.order.piece-caption" />
        </>
      )}
    </>
  )

  return (
    <Typography
      className={cx("price_tag", {
        "price_tag--free": isFree,
        "price_tag--floating": isFloating,
      })}
      type={TType.Body10_400UC}
      htmlElement="span"
      {...props}
    >
      {isFree ? (
        <FormattedMessage id="component.currency-label.free" />
      ) : (
        priceLabel
      )}
    </Typography>
  )
}
