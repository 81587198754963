var _a;
import NCP_CONSTS from "./consts/ncpConsts";
export default (_a = {
        default: {
            DEFAULT_QUANTITY: 1,
            QUANTITY_RANGES: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 50, 75, 100],
            QUANTITY_DENSITY: 100,
            QUANTITY_STEP: 1,
        }
    },
    _a[NCP_CONSTS.STICKER_CIRCLE] = {
        DEFAULT_QUANTITY: 1,
        QUANTITY_RANGES: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        QUANTITY_DENSITY: 2,
        QUANTITY_STEP: 1,
    },
    _a[NCP_CONSTS.STICKER_RECTANGLE] = {
        DEFAULT_QUANTITY: 1,
        QUANTITY_RANGES: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        QUANTITY_DENSITY: 2,
        QUANTITY_STEP: 1,
    },
    _a[NCP_CONSTS.THANK_YOU_CARD] = {
        DEFAULT_QUANTITY: 1,
        QUANTITY_RANGES: [1, 2, 3, 4, 5, 7, 10, 15, 25],
        QUANTITY_DENSITY: 2,
        QUANTITY_STEP: 1,
    },
    _a[NCP_CONSTS.PAPER_BUBBLE_WRAP] = {
        DEFAULT_QUANTITY: 1,
        QUANTITY_RANGES: [1, 2, 3, 4, 5, 10, 25, 50],
        QUANTITY_DENSITY: 2,
        QUANTITY_STEP: 1,
    },
    _a);
