var _a, _b, _c;
import TISSUE_PAPER_CONSTS from "../consts/tissuePaperConsts";
var TISSUE_PAPER_PRODUCT_VARIANTS = (_a = {},
    _a[TISSUE_PAPER_CONSTS.TISSUE_PAPER_MODEL_DEFAULT] = {
        sizes: [120],
        prefix: "T",
        addonsInVariants: [],
        productCategory: TISSUE_PAPER_CONSTS.TISSUE_PAPER,
        variants: (_b = {},
            _b[TISSUE_PAPER_CONSTS.TISSUE_PAPER_VARIANT_STANDARD] = {
                materials: [TISSUE_PAPER_CONSTS.PRINT_MATERIAL_TISSUE],
                colorModes: [TISSUE_PAPER_CONSTS.PRINT_MODE_MONO_PANTONE_ONE_SIDE],
                finishes: [TISSUE_PAPER_CONSTS.PRINT_FINISH_NONE],
            },
            _b),
    },
    _a[TISSUE_PAPER_CONSTS.PACKING_PAPER_MODEL_DEFAULT] = {
        sizes: [120],
        prefix: "T",
        addonsInVariants: [],
        productCategory: TISSUE_PAPER_CONSTS.PACKING_PAPER,
        variants: (_c = {},
            _c[TISSUE_PAPER_CONSTS.PACKING_PAPER_VARIANT_STANDARD] = {
                materials: [TISSUE_PAPER_CONSTS.PRINT_MATERIAL_TISSUE],
                colorModes: [TISSUE_PAPER_CONSTS.PRINT_MODE_MONO_PANTONE_ONE_SIDE],
                finishes: [TISSUE_PAPER_CONSTS.PRINT_FINISH_NONE],
            },
            _c),
    },
    _a);
export default TISSUE_PAPER_PRODUCT_VARIANTS;
