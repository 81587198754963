import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { EditorToolHeaderCheckbox } from "../../../../molecues/editor-tool-header-checkbox/EditorToolHeaderCheckbox"

import styles from "../../EditorTools.module.scss"
import { ColorConfigurator } from "../../../configurators/ColorConfigurator/ColorConfigurator"
import { Colour } from "../../../../../../libs/value-objects/colour"
import ProductDriver from "../../../../../../stores/product-driver/product.driver"

export const EditorToolHeaderSvgImageAssets = observer(
  ({ productDriver }: { productDriver: ProductDriver }) => {
    const { activeObjectDriver } = productDriver
    const { isUniScalingLocked } = activeObjectDriver.activeObjectComputable
    const activeObjectController = activeObjectDriver.activeObjectController

    if (!activeObjectController) {
      return null
    }

    const { isObjectActive, objectIdentified } = activeObjectDriver

    const toggleUniScaling = () => {
      activeObjectDriver.toggleUniScaling()
    }

    const [imageColor, setImageColor] = useState<Colour>(
      activeObjectController.getFill()
    )

    useEffect(() => {
      setImageColor(activeObjectController.getFill())
    }, [isObjectActive, objectIdentified, activeObjectController])

    const onChangeAssetColor = (color: Colour) => {
      activeObjectController.setStyles({ fill: color })
      setImageColor(color)
    }

    const onColorBackClick = () => {
      onChangeAssetColor(imageColor)
    }

    return (
      <div className={styles.header_nav}>
        {activeObjectController.isColorModificationAvailable() && (
          <ColorConfigurator
            onColorSelect={onChangeAssetColor}
            onBackClick={onColorBackClick}
            selectedColor={imageColor}
          />
        )}

        <EditorToolHeaderCheckbox
          isChecked={isUniScalingLocked}
          onChange={toggleUniScaling}
          optionLabelIntl="component.editor-tool-shapes.checkbox.keep-proportions"
        />
      </div>
    )
  }
)
