var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addGrammagesBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            _this.grammages = product.config.grammages;
            return _this;
        }
        class_1.prototype.trimCodeName = function (stringToSlice) {
            return stringToSlice.slice(1, stringToSlice.length);
        };
        class_1.prototype.getGrammageForSize = function () {
            var _this = this;
            var productSize = this.product.variantManager.getSize();
            var compartment = Object.keys(this.grammages).find(function (element) {
                return productSize <= parseInt(_this.trimCodeName(element));
            });
            return this.grammages[compartment];
        };
        class_1.prototype.getMaterialDescription = function () {
            var productGrammage = this.getGrammageForSize();
            if (!productGrammage) {
                return null;
            }
            return productGrammage["materialDescription"];
        };
        class_1.prototype.getGrammage = function () {
            var productGrammage = this.getGrammageForSize();
            if (!productGrammage) {
                return null;
            }
            else if (productGrammage.addons) {
                return productGrammage.addons[this.product.variantManager.getVariant()];
            }
            else {
                return productGrammage.grammage;
            }
        };
        return class_1;
    }(superclass));
};
export default addGrammagesBehaviors;
