import React from "react"
import { TType } from "dsl/src/atoms/Typography/Typography"
import { TM } from "../../../../../TypographyI18n"
import cxBinder from "classnames/bind"
import styles from "./DetailsItem.module.scss"

const cx = cxBinder.bind(styles)

interface DetailsItemProps {
  title: string
  icon?: React.ReactNode
  value?: React.ReactNode
  withBorder?: boolean
}

export const DetailsItem = (props: DetailsItemProps) => {
  const { title, icon, value, withBorder } = props
  return (
    <div
      className={cx("item", {
        "with-border": withBorder,
      })}
    >
      <div className={styles.title}>
        {icon}
        <TM id={title} type={TType.Header13_500} />
      </div>
      {value}
    </div>
  )
}
