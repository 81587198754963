import { action, observable, makeObservable } from "mobx"
import {
  ObjectDpiCalculator,
  Size,
} from "../../libs/calculators/object-dpi.calculator"
import { ClipPathModes } from "../../render-engine/modules/vd-editor/services/clip-path-generator"
import {
  ActiveObjectType,
  PackhelpCanvas,
  PackhelpGroupType,
} from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import ProductDriver from "./product.driver"
import { ModelEditableSpaces } from "../../libs/products-render-config/types"
import { AllEditorEventsEmitter, eventTree } from "../editor-events"
import {
  ActiveObjectEvent,
  CanvasEvent,
  CanvasObjectEvent,
  GraphicAssetEvents,
} from "../_events/domain-events"
import { CanvasObjectControllerFactory } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/canvas-object-controller-factory"
import { CanvasObjectControllable } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/canvas-object-controllable.interface"
import VirtualDielineEditor from "../../render-engine/modules/vd-editor/virtual-dieline-editor"
import { ActiveSelectionController } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/active-selection-controller"
import { fabric } from "fabric"
import {
  ImageAssetMetaT,
  EditableObjectTypes,
  isInteractiveCanvas,
} from "../../modules/ph-api/asset-types"
import { SpaceClippingHelper } from "../../render-engine/modules/vd-editor/modules/assets-module/helpers/space-clipping-helper"
import {
  isImageObjectController,
  MovementDirection,
} from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/types"
import FiltersModule from "../../render-engine/modules/vd-editor/modules/filters-module"

class ActiveObjectDriver {
  @observable public isObjectActive: boolean = false
  @observable public activeObjectType?: ActiveObjectType = undefined
  @observable public objectIdentified: string | null = null
  public activeObjectComputable
  public activeObject
  public activeObjectController?: CanvasObjectControllable
  private eventEmitter

  constructor(
    private readonly productDriver: ProductDriver,
    private readonly ee: AllEditorEventsEmitter
  ) {
    makeObservable(this)
    this.eventEmitter = productDriver.eventEmitter
    this.activeObject = null
    const getImageSizeForDTP = this.getImageSizeForDTP.bind(this)
    this.activeObjectComputable = observable({
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      isOverscaled: false,
      minScaleLimitReached: false,
      isSafeZoneTouched: false,
      isInverted: false,
      isUniScalingLocked: true,
      threshold: 1,
      clipMode: ClipPathModes,
      isImageBackgroundRemoved: true,
      get cmSizeValues() {
        return getImageSizeForDTP({
          width: this.width,
          height: this.height,
        })
      },
    })

    this.attachEventListeners()
  }

  private attachEventListeners() {
    this.eventEmitter.on(ActiveObjectEvent.selected, this.setActiveObject)
    this.eventEmitter.on(ActiveObjectEvent.deselected, this.clearActiveObject)
    this.eventEmitter.on(ActiveObjectEvent.modified, () => {
      this.mapActiveObjectSizeToState()
      this.productDriver.setDesignTouched(true)
    })
    this.eventEmitter.on("vdEditorResized", () =>
      this.mapActiveObjectSizeToState()
    )

    this.eventEmitter.on(ActiveObjectEvent.resized, () => {
      if (
        this.activeObject &&
        this.activeObject.assetType === EditableObjectTypes.assetImage
      ) {
        const asset: ImageAssetMetaT = this.activeObject.assetImageMeta
        const isOverscaled: boolean = this.activeObject.isOverscaled

        this.ee.emit(GraphicAssetEvents.assetResized, asset, isOverscaled)
      }
    })

    this.ee.on(eventTree.activeObject.delete, this.removeObject.bind(this))
    this.ee.on(eventTree.activeObject.moveUp, this.moveObject.bind(this, "up"))
    this.ee.on(
      eventTree.activeObject.moveDown,
      this.moveObject.bind(this, "down")
    )
    this.ee.on(
      eventTree.activeObject.moveLeft,
      this.moveObject.bind(this, "left")
    )
    this.ee.on(
      eventTree.activeObject.moveRight,
      this.moveObject.bind(this, "right")
    )
    this.ee.on(eventTree.activeObject.deselect, () => {
      this.vdEditor.assetsModule.clearActiveObject()
    })
  }

  @action
  public removeObject(withGroup = false) {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    if (withGroup && this.activeObjectController.hasGroup()) {
      this.setActiveObject(this.activeObjectController.getGroup())
    }

    if (this.activeObject.type === PackhelpGroupType.activeSelection) {
      for (const object of this.activeObject.getObjects()) {
        this.ee.emit(eventTree.productDriver.objectRemoved, object)
      }
    } else {
      this.ee.emit(eventTree.productDriver.objectRemoved, this.activeObject)
    }

    this.activeObjectController.remove()

    this.activeObject = null
    this.activeObjectController = undefined
    this.activeObjectType = undefined
    this.isObjectActive = false

    this.ee.emit(eventTree.keyboard.clearScope, "active-object")
  }

  @action
  public async duplicateObject() {
    if (
      !isInteractiveCanvas(this.canvas) ||
      !this.activeObject ||
      !this.activeObjectController ||
      !this.activeObjectController.isDuplicatingAvailable()
    ) {
      return
    }

    const clonedObject = await this.activeObjectController.duplicate()
    this.canvas.setActiveObject(clonedObject)
    this.setActiveObject(clonedObject)
    this.activeObjectController.alignVertical()
    this.mapActiveObjectSizeToState()
  }

  @action
  public async groupObject() {
    if (
      !isInteractiveCanvas(this.canvas) ||
      !this.activeObject ||
      !this.activeObjectController ||
      this.activeObjectType !== PackhelpGroupType.activeSelection
    ) {
      return
    }

    const controller = this.activeObjectController as ActiveSelectionController
    const group = await controller.group()

    this.canvas.setActiveObject(group)
    this.canvas.requestRenderAll()
    this.setActiveObject(group)
  }

  @action
  public async ungroupObject() {
    if (
      !isInteractiveCanvas(this.canvas) ||
      !this.activeObject ||
      !this.activeObjectController
    ) {
      return
    }

    if (
      !this.activeObject.groupController &&
      !this.activeObjectController.hasGroup()
    ) {
      return
    }

    if (this.activeObjectController.hasGroup()) {
      this.setActiveObject(this.activeObjectController.getGroup())
    }

    const newSelection = new fabric.ActiveSelection([], {
      canvas: this.canvas,
    })

    const objects = this.activeObject.groupController.getObjects()
    this.activeObject.groupController.ungroup()

    for (const object of objects) {
      newSelection.addWithUpdate(object)
    }

    this.canvas.setActiveObject(newSelection)
    this.canvas.requestRenderAll()
    this.setActiveObject(newSelection)
  }

  @action
  public toggleUniScaling() {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.activeObjectController.setStyles({
      lockUniScaling: !this.activeObject.lockUniScaling,
    })

    this.mapActiveObjectSizeToState()
  }

  @action
  public toggleImageBackground() {
    if (
      !this.activeObjectController ||
      !isImageObjectController(this.activeObjectController)
    ) {
      return
    }

    this.activeObjectController.toggleImageBackground()

    this.mapActiveObjectSizeToState()
  }

  @action
  public setThreshold(thresholdLvl: number) {
    if (
      !this.activeObjectController ||
      !isImageObjectController(this.activeObjectController)
    ) {
      return
    }

    this.activeObjectController.setThreshold(thresholdLvl)
    this.mapActiveObjectSizeToState()
  }

  @action
  public toggleMonochrome() {
    FiltersModule.toggleMonochrome(this.activeObject)
    this.canvas.fire(CanvasEvent.objectModified, {
      target: this.activeObject,
    })
    this.activeObject.fire(CanvasObjectEvent.changed)
    this.canvas.renderAll()
    this.mapActiveObjectSizeToState()
  }

  @action
  public async toggleCliping() {
    const { activeSpace } = this.productDriver.state

    return SpaceClippingHelper.toggleSpaceClipping(
      this.vdEditor,
      activeSpace,
      this.activeObject
    )
  }

  @action
  public alignObjectHorizontal() {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.activeObjectController.alignHorizontal()
    this.mapActiveObjectSizeToState()
  }

  @action
  public alignObjectVertical() {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.activeObjectController.alignVertical()
    this.mapActiveObjectSizeToState()
  }

  @action
  public moveObjectLayerUp() {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.activeObjectController.moveLayerUp()
    this.mapActiveObjectSizeToState()
  }

  @action
  public moveObjectLayerDown() {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.activeObjectController.moveLayerDown()
    this.mapActiveObjectSizeToState()
  }

  private moveObject(direction: MovementDirection, step = 1) {
    if (!this.activeObjectController) {
      return
    }

    this.activeObjectController.move(direction, step)
    this.mapActiveObjectSizeToState()
  }

  private getImageSizeForDTP(imageSize: Size) {
    if (!this.activeObject) {
      return {
        cmWidth: 0,
        cmHeight: 0,
      }
    }

    const { activeContext, activeSpace, productRenderPilot } =
      this.productDriver.state

    const spaceId =
      activeSpace || productRenderPilot.getDefaultSpace(activeContext)
    const vdSpace =
      this.vdEditor.dielineNavigator.getVirtualDielineSpace(spaceId)

    const editZoneDimensions = productRenderPilot.getSpaceDimensions(
      activeContext,
      vdSpace.id as ModelEditableSpaces
    )

    const editZoneSizeApx = {
      widthApx: editZoneDimensions.widthPx,
      heightApx: editZoneDimensions.heightPx,
    }

    const objectSizeInDtpMM = ObjectDpiCalculator.getObjectRealSizeMm(
      vdSpace,
      ObjectDpiCalculator.sizeToVdpx(imageSize),
      editZoneSizeApx
    )

    return {
      cmWidth: Math.round((objectSizeInDtpMM.widthMm / 10) * 100) / 100,
      cmHeight: Math.round((objectSizeInDtpMM.heightMm / 10) * 100) / 100,
    }
  }

  @action
  private setActiveObject = (obj) => {
    if (!obj) {
      return this.clearActiveObject()
    }

    if (obj.maskParentId) {
      obj = obj.canvas.getObjects().find((o) => o.id === obj.maskParentId)

      if (!obj) {
        throw new Error("Can't find Parent object")
      }
    }

    const matrixCacheKey = obj.ownMatrixCache && obj.ownMatrixCache.key
    this.objectIdentified = `${obj.id}_${matrixCacheKey}`
    this.activeObjectType = obj.assetType || obj.type
    this.activeObject = obj
    this.setIsActiveObject(true)

    const factory = new CanvasObjectControllerFactory(this.vdEditor)
    this.activeObjectController = factory.getController(this.activeObject)

    this.mapActiveObjectSizeToState()

    this.ee.emit(eventTree.keyboard.setScope, "active-object")
  }

  @action
  private clearActiveObject = () => {
    if (this.activeObject === null) {
      return
    }

    this.objectIdentified = null
    this.activeObject = null
    this.activeObjectController = undefined
    this.activeObjectType = undefined
    this.setIsActiveObject(false)
    this.mapActiveObjectSizeToState()

    this.eventEmitter.emit(ActiveObjectEvent.cleared)
    this.ee.emit(eventTree.keyboard.clearScope, "active-object")
  }

  @action
  private setIsActiveObject(isActive: boolean) {
    this.isObjectActive = isActive
  }

  @action
  private mapActiveObjectSizeToState = () => {
    if (!this.activeObject || !this.activeObjectController) {
      this.activeObjectComputable.top = 1000000
      this.activeObjectComputable.left = 1000000
      this.activeObjectComputable.height = 0
      this.activeObjectComputable.width = 0
      this.activeObjectComputable.clipMode = false
      this.activeObjectComputable.isOverscaled = false
      this.activeObjectComputable.minScaleLimitReached = false
      this.activeObjectComputable.threshold = 1
      this.activeObjectComputable.isSafeZoneTouched = false
      this.activeObjectComputable.isInverted = false
      this.activeObjectComputable.isUniScalingLocked = false

      return
    }

    this.activeObject.setCoords()
    const objectBoundingBoxPosition = this.activeObject.getBoundingRect()
    this.activeObjectComputable.boundingTop = objectBoundingBoxPosition.top
    this.activeObjectComputable.boundingLeft = objectBoundingBoxPosition.left
    this.activeObjectComputable.boundingHeight =
      objectBoundingBoxPosition.height
    this.activeObjectComputable.boundingWidth = objectBoundingBoxPosition.width
    this.activeObjectComputable.height = this.activeObject.getScaledHeight()
    this.activeObjectComputable.width = this.activeObject.getScaledWidth()
    this.activeObjectComputable.isOverscaled =
      this.activeObject.get("isOverscaled")
    this.activeObjectComputable.minScaleLimitReached = this.activeObject.get(
      "minScaleLimitReached"
    )
    this.activeObjectComputable.isSafeZoneTouched =
      this.activeObject.get("isSafeZoneTouched")
    this.activeObjectComputable.isUniScalingLocked =
      this.activeObject.get("lockUniScaling")
    this.activeObjectComputable.threshold = this.activeObject.getImageThreshold
      ? this.activeObject.getImageThreshold()
      : 1
    this.activeObjectComputable.clipMode = this.activeObject.clipMode
      ? this.activeObject.clipMode
      : true
    this.activeObjectComputable.isInverted = this.activeObject.isImageInverted
      ? this.activeObject.isImageInverted()
      : false
    this.activeObjectComputable.fontFamily = this.activeObject.get("fontFamily")
    this.activeObjectComputable.isImageBackgroundRemoved = this.activeObject
      .isBackgroundRemoved
      ? this.activeObject.isBackgroundRemoved()
      : true
  }

  private get vdEditor(): VirtualDielineEditor {
    const { renderEngine, activeContext } = this.productDriver.state

    if (!renderEngine) {
      throw new Error("Render engine is not ready")
    }

    return renderEngine.getVirtualDielineEditor(activeContext)
  }

  private get canvas(): PackhelpCanvas {
    return this.vdEditor.fabricCanvas
  }
}

export default ActiveObjectDriver
