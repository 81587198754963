import _flow from "lodash/flow";
import tissuePaperProductSpec from "../../../source/tissuePaperProductSpec/tissuePaperProductSpec";
import CustomProductConfigBuilder from "../configBuilders/CustomProductConfigBuilder";
import addCustomProductDimensionsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductDimensionsBehaviors";
import addCustomProductProductionTimesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductProductionTimesBehaviors";
import addCustomProductVariantBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductVariantBehaviors";
import addCustomProductEditConfigBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductEditConfigBehaviors";
import addCustomProductGrammagesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductGrammagesBehaviors";
import addCustomProductTranslationsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductTranslationsBehaviors";
import TissuePaperTranslationsManager from "../Products/TissuePaperProduct/behaviorsComposers/TissuePaperTranslationsManager";
import TissuePaperVariantManager from "../Products/TissuePaperProduct/behaviorsComposers/TissuePaperVariantManager";
import GrammagesManager from "../behaviorsComposers/GrammagesManager";
import WizzardManager from "../behaviorsComposers/WizzardManager";
import EditConfigManager from "../behaviorsComposers/EditConfigManager";
import ProductionTimeManager from "../behaviorsComposers/ProductionTimeManager";
import TissuePaperDimensionsManager from "../Products/TissuePaperProduct/behaviorsComposers/TissuePaperDimensionsManager";
var CustomProductionTimeManager = addCustomProductProductionTimesBehaviors(ProductionTimeManager);
var CustomProductGrammagesManager = addCustomProductGrammagesBehaviors(GrammagesManager);
var CustomProductDimmensionsManager = addCustomProductDimensionsBehaviors(TissuePaperDimensionsManager);
var CustomProductEditorConfigManager = addCustomProductEditConfigBehaviors(EditConfigManager);
var CustomProductTranslationsManager = addCustomProductTranslationsBehaviors(TissuePaperTranslationsManager);
var CustomProductVariantManager = _flow([addCustomProductVariantBehaviors])(TissuePaperVariantManager);
var CustomTissuePaperProduct = /** @class */ (function () {
    function CustomTissuePaperProduct(sku, productName) {
        this.config = CustomProductConfigBuilder(tissuePaperProductSpec, sku, productName);
        this.wizzardManager = new WizzardManager(this);
        this.productionTimeManager = new CustomProductionTimeManager(this);
        this.editConfigManager = new CustomProductEditorConfigManager(this);
        this.variantManager = new CustomProductVariantManager(this);
        this.grammagesManager = new CustomProductGrammagesManager(this);
        this.dimensionsManager = new CustomProductDimmensionsManager(this);
        this.translationsManager = new CustomProductTranslationsManager(this);
    }
    CustomTissuePaperProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    CustomTissuePaperProduct.prototype.getMaterialDescription = function () {
        return null;
    };
    return CustomTissuePaperProduct;
}());
export default CustomTissuePaperProduct;
