var _a;
import TISSUE_PAPER_CONSTS from "../consts/tissuePaperConsts";
var BAG_PRODUCT_DIMENSIONS = (_a = {},
    _a[TISSUE_PAPER_CONSTS.TISSUE_PAPER] = {
        T120: {
            outside: {
                width: 700,
                height: 500,
            },
        },
    },
    _a[TISSUE_PAPER_CONSTS.PACKING_PAPER] = {
        T120: {
            outside: {
                width: 700,
                height: 500,
            },
        },
    },
    _a);
export default BAG_PRODUCT_DIMENSIONS;
