var _a;
import productBoxesDimensions from "./productBoxesDimensions";
import mailerBoxesDimensions from "./mailerBoxesDimensions";
import prePrintedMailerBoxesDimensions from "./prePrintedMailerBoxesDimensions";
import productBoxesTwoPiecesDimensions from "./productBoxesTwoPiecesDimensions";
import productWineBoxesDimensions from "./productWineBoxesDimensions";
import mailerBoxesWithSleevesDimensions from "./mailerBoxesWithSleevesDimensions";
import mailerBoxesDeliveryDimensions from "./mailerBoxesDeliveryDimensions";
import BOX_CONSTS from "../consts/boxConsts";
import { shippingBoxesDimensions, shippingBoxPlainV2Dimensions, } from "./shippingBoxesDimensions";
var BoxesDimensions = (_a = {},
    _a[BOX_CONSTS.SHIPPING_BOX] = shippingBoxesDimensions,
    _a[BOX_CONSTS.SHIPPING_BOX_PLAIN] = shippingBoxesDimensions,
    _a[BOX_CONSTS.SHIPPING_BOX_PLAIN_V2] = shippingBoxPlainV2Dimensions,
    _a[BOX_CONSTS.MAILER_BOX] = mailerBoxesDimensions,
    _a[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO] = prePrintedMailerBoxesDimensions,
    _a[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR] = prePrintedMailerBoxesDimensions,
    _a[BOX_CONSTS.SLEEVED_MAILER_BOX] = mailerBoxesWithSleevesDimensions,
    _a[BOX_CONSTS.MAILER_BOX_PLAIN] = mailerBoxesDimensions,
    _a[BOX_CONSTS.MAILER_BOX_WHITE_INK] = mailerBoxesDimensions,
    _a[BOX_CONSTS.PRODUCT_BOX] = productBoxesDimensions,
    _a[BOX_CONSTS.PRODUCT_BOX_TWO_PIECES] = productBoxesTwoPiecesDimensions,
    _a[BOX_CONSTS.PRODUCT_BOX_WINE_BOX] = productWineBoxesDimensions,
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX] = mailerBoxesDeliveryDimensions,
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN] = mailerBoxesDeliveryDimensions,
    _a);
export default BoxesDimensions;
