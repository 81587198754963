import {
  PackhelpObject,
  IndexConfigFragments,
  IndexConfigFragmentBottom,
  PackhelpImage,
} from "../../../object-extensions/packhelp-objects"
import { TempLayers } from "../../../types/render-engine-types"
import FiltersModule from "../../filters-module"
import FabricAssetsLoaderService from "../../../../../../libs/services/fabric-assets-loader-service"
import Colour from "../../../../../../libs/value-objects/colour"
import fabric from "../../../../../../libs/vendors/Fabric"
import { CANVAS_DIM } from "../../../../../types"
import { EditableObjectTypes } from "../../../../../../modules/ph-api/asset-types"

export class CreateTempBackgroundService {
  private readonly scaleRatio: number

  constructor(
    private readonly dielineSpace: PackhelpObject,
    private readonly config: {
      color?: Colour
      shadow?: fabric.IShadowOptions
      texture?: PackhelpImage
    } = {}
  ) {
    this.scaleRatio = this.getScaleRatio()
  }

  public async generate(): Promise<PackhelpObject> {
    this.dielineSpace.scaleToWidth(Math.round(this.dielineSpace.width!))
    this.dielineSpace.set({
      opacity: 1,
      fill: "#fff",
      visible: true,
    })
    this.dielineSpace.setCoords()

    const blendedTempBackground = await this.blendDieline()

    blendedTempBackground.set({
      top: this.dielineSpace.top,
      left: this.dielineSpace.left,
      selectable: false,
      evented: false,
      id: TempLayers.TEMP_BACKGROUND,
      shadow: this.prepareShadow(),
      indexConfig: {
        fragment: IndexConfigFragments.BOTTOM,
        index: IndexConfigFragmentBottom.TEMP_BACKGROUND,
      },
      assetType: EditableObjectTypes.assetImage,
    })

    return blendedTempBackground
  }

  private async blendDieline(): Promise<PackhelpObject> {
    const image = await FabricAssetsLoaderService.loadAsset(
      this.dielineSpace.toDataURL({ multiplier: this.scaleRatio }),
      {
        scaleX: 1 / this.scaleRatio,
        scaleY: 1 / this.scaleRatio,
      }
    )

    if (!this.config.texture) {
      image.set({
        visible: false,
        opacity: 0,
      })

      return image
    }

    if (this.config.color) {
      await FiltersModule.setTintFilter(image, this.config.color)
    }

    await FiltersModule.setBlendImageFilter(image, this.config.texture)

    image.set({
      visible: true,
      opacity: 1,
    })

    return image
  }

  private prepareShadow(): fabric.Shadow | undefined {
    if (!this.config.shadow) {
      return
    }

    const scaleAttr = (value?: number): number => {
      if (!value) {
        return 0
      }

      return value * this.scaleRatio
    }

    return new fabric.Shadow({
      ...this.config.shadow,
      blur: scaleAttr(this.config.shadow.blur),
      offsetX: scaleAttr(this.config.shadow.offsetX),
      offsetY: scaleAttr(this.config.shadow.offsetY),
    })
  }

  private getScaleRatio(): number {
    const rotation = this.dielineSpace.rotation || 0
    const width = this.dielineSpace.getScaledWidth()
    const height = this.dielineSpace.getScaledHeight()

    if (Math.abs(rotation) === 90 || width < height) {
      return CANVAS_DIM / height
    }

    return CANVAS_DIM / width
  }
}
