var _a;
import TISSUE_PAPER_CONSTS from "../generalConfig/consts/tissuePaperConsts";
var TISSUE_PAPER_SPEC = {
    width: 700,
    height: 500,
    length: 1,
    foldLineWidth: 0,
    sideFoldWidth: 0,
    thumbnailCameraZoom: 1,
    editZonePosition: {
        side: {
            front: {
                top: 0,
                left: 0,
                width: 700,
                height: 500,
            },
        },
    },
};
var PAPER_CONFIG = (_a = {},
    _a[TISSUE_PAPER_CONSTS.TISSUE_PAPER] = {
        T120: TISSUE_PAPER_SPEC,
    },
    _a[TISSUE_PAPER_CONSTS.PACKING_PAPER] = {
        T120: TISSUE_PAPER_SPEC,
    },
    _a);
export default PAPER_CONFIG;
