import React from "react"
import cxBinder from "classnames/bind"

import { useFormatMessage } from "../../../../../libs/others/i18n"
import { FormattedCurrency } from "../../../../../shared-components/formatted-currency/FormattedCurrency"
import {
  OutliningPanel,
  Decoration,
  PanelBackground,
  PanelPaddingType,
} from "dsl/src/components/OutliningPanel/OutliningPanel"
import { WalletPanelProps } from "../../utils/wallet.types"
import { walletI18n } from "../../utils/wallet.translations"
import { FormattedMessage } from "react-intl"

import { WalletIcon } from "../WalletIcon"
import { ReactComponent as InfoIcon } from "dsl/src/assets/icons/UI/Info.svg"

import {
  TooltipDirections,
  ArrowPlacements,
} from "dsl/src/atoms/BaseTooltip/BaseTooltip"
import Tooltip from "dsl/src/molecules/Tooltip/Tooltip"

import styles from "./WalletPanel.module.scss"
const cx = cxBinder.bind(styles)

export const WalletPanel = (props: WalletPanelProps) => {
  const {
    amount,
    percentageLimit,
    currency,
    panelDecoration = Decoration.DarkBlue,
    iconColor = "blue",
  } = props

  const translate = useFormatMessage()
  const tooltipValue = (
    <div className={styles["tooltip-value"]}>
      <FormattedMessage
        id={walletI18n.limitInfo}
        values={{ amount: percentageLimit * 100 }}
      />
    </div>
  )

  return (
    <div className={styles.wrapper} e2e-target="wallet-panel">
      <OutliningPanel
        decoration={panelDecoration}
        background={PanelBackground.Grey}
        paddingType={PanelPaddingType.Slim}
      >
        <div className={styles.content}>
          <div className={styles.side}>
            <WalletIcon
              className={cx("icon", {
                "icon--blue": iconColor === "blue",
                "icon--black": iconColor === "black",
              })}
            />
            <span className={styles.title}>
              {translate({ id: walletI18n.genericTitle })}
            </span>
            <Tooltip
              value={tooltipValue}
              direction={TooltipDirections.bottom}
              arrowPlacement={ArrowPlacements.left}
            >
              <span>
                <InfoIcon
                  className={cx("icon", "icon--black", "icon--margin")}
                />
              </span>
            </Tooltip>
          </div>
          <div className={styles.side}>
            <span>{"-"}</span>
            <FormattedCurrency
              value={amount}
              currency={currency}
              e2eTargetName="wallet-value"
            />
          </div>
        </div>
      </OutliningPanel>
    </div>
  )
}
