import { PackhelpObject } from "../../object-extensions/packhelp-objects"
import type VirtualDielineEditor from "../../virtual-dieline-editor"
import { GlueStripShapeCreator, stripOpacity } from "./glue-strip-shape-creator"
import {
  EditableSpaceConfig,
  ModelEditableSpaces,
} from "../../../../../libs/products-render-config/types"

export class GlueStripModule {
  constructor(private readonly virtualDielineEditor: VirtualDielineEditor) {}

  public async createGlueStrip(
    spaceConfigs: EditableSpaceConfig[]
  ): Promise<void> {
    this.clearExistingGlueStrips()

    if (
      !this.virtualDielineEditor.productRenderPilot.uiConfig.editZone
        .glueStripWidthMm
    ) {
      return
    }

    const glueStripShapeCreator = new GlueStripShapeCreator(
      this.virtualDielineEditor.editContext,
      this.virtualDielineEditor.dielineNavigator,
      this.virtualDielineEditor.productRenderPilot,
      this.virtualDielineEditor.eventEmitter
    )

    await Promise.all(
      spaceConfigs.map(async (config, index) => {
        this.virtualDielineEditor.addOnCanvas(
          await glueStripShapeCreator.create(config.spaceId, index)
        )

        this.virtualDielineEditor.fabricCanvas.requestRenderAll()
      })
    )
  }

  public switchOnEvents() {
    this.virtualDielineEditor.eventEmitter.on(
      "safeZonesToggled",
      this.onSafeZonesToggled
    )
  }

  private onSafeZonesToggled = (safeZonesActive: boolean) => {
    const activeSpaceId =
      this.virtualDielineEditor.dielineNavigator.getActiveSpaceId()
    if (safeZonesActive) {
      this.hideGlueStripInSpace(activeSpaceId!)
    } else if (activeSpaceId) {
      this.showGlueStripInSpace(activeSpaceId)
    }
  }

  public switchOffEvents() {
    this.virtualDielineEditor.eventEmitter.off(
      "safeZonesToggled",
      this.onSafeZonesToggled
    )
  }

  private getGlueStrips(): PackhelpObject[] {
    return this.virtualDielineEditor.fabricCanvas
      .getObjects()
      .filter((obj) => obj.id && obj.id.includes("glue_strip_"))
  }

  private getGlueStripInSpace(
    spaceId: ModelEditableSpaces
  ): PackhelpObject | undefined {
    return this.getGlueStrips().find((obj) => obj.originSpaceArea === spaceId)
  }

  public showGlueStripInSpace(spaceId: ModelEditableSpaces) {
    const glueStrip = this.getGlueStripInSpace(spaceId)

    if (glueStrip) {
      glueStrip.set({
        evented: true,
        opacity: stripOpacity,
      })
    }
  }

  public hideGlueStripInSpace(spaceId: ModelEditableSpaces) {
    const glueStrip = this.getGlueStripInSpace(spaceId)

    if (glueStrip) {
      glueStrip.set({
        evented: false,
        opacity: 0,
      })
    }
  }

  private clearExistingGlueStrips() {
    for (const glueStrip of this.getGlueStrips()) {
      this.virtualDielineEditor.fabricCanvas.remove(glueStrip)
    }
  }
}
