import type {
  Design,
  DesignDataWithThumbnail,
  DesignMeta,
} from "../../../modules/design/types"
import { ProductDesignStore } from "../../../stores/product-design-store/product-design.store"
import type { DesignDto } from "@ph/design-api"
import { ProductDesignDtoParser } from "../../parsers/product-design/product-design-dto.parser"

export class ProductDesignDtoToStateTransformer {
  constructor(private readonly dto: DesignDto) {}

  public transform(): Design {
    new ProductDesignDtoParser(this.dto).parse()

    ProductDesignStore.designFormatVersion =
      ProductDesignStore.parseDesignVersionOrRevertToOldest(
        this.dto.design_data.design_data_format_version
      )

    // @ts-ignore
    return {
      data: this.prepareDesignData(),
      meta: this.prepareDesignMeta(),
    }
  }

  private prepareDesignMeta(): DesignMeta {
    const { design_items } = this.dto

    return {
      id: this.dto.id,
      name: this.dto.name,
      public: this.dto.public,
      dataReadOnly: this.dto.read_only,
      quantity: this.dto.prechosen_quantity,
      designStatus: this.dto.status,
      designItems: design_items.map((designItem) => ({
        variantId: designItem.variant_id,
        variantType: designItem.variant_type,
      })),
    }
  }

  private prepareDesignData(): DesignDataWithThumbnail {
    const { design_data, thumbnail } = this.dto

    // TODO: We need more validation
    if (!design_data.files) {
      throw new Error("Design data response is corrupted")
    }

    if (design_data.type === "dby") {
      return {
        files: design_data.files,
        thumbnail,
        type: "dby",
        virtualDielines: {},
      }
    }

    if (!design_data.virtual_dielines) {
      throw new Error("Editor design data response is corrupted")
    }

    return {
      virtualDielines: design_data.virtual_dielines,
      files: design_data.files,
      thumbnail: thumbnail,
      designDataFormatVersion: ProductDesignStore.designFormatVersion,
      type: "editor",
    }
  }
}
