import React, { PropsWithChildren, useRef } from "react"
import styles from "./Dropdown.module.scss"
import { useOutsideClick } from "../../../hooks/useOutsideClick"

interface DropdownProps {
  onClose: () => void
}

export const Dropdown = ({
  children,
  onClose,
}: PropsWithChildren<DropdownProps>) => {
  const dropdownRef = useRef(null)
  useOutsideClick(dropdownRef, onClose)

  return (
    <div className={styles.wrapper} ref={dropdownRef}>
      <div className={styles.body}>{children}</div>
    </div>
  )
}
