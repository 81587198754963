import React from "react"

import styles from "./CameraControlsToolbar.module.scss"

import {
  NotificationVariant,
  Notification,
} from "../../../atoms/Notification/Notification"
import { useTranslate } from "../../../../hooks/useTranslate"

interface LowQualityNotificationProps {
  isVisible: boolean
}

export const LowQualityNotification = ({
  isVisible,
}: LowQualityNotificationProps) => {
  const t = useTranslate()

  if (!isVisible) {
    return null
  }

  return (
    <div className={styles.toolbar__notification}>
      <Notification
        variant={NotificationVariant.error}
        halfWidth
        e2eTargetName="low-quality"
      >
        {t("component.notification.low-quality")}
      </Notification>
    </div>
  )
}
