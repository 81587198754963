var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BOX_CONSTS from "../../../../../source/boxProductSpec/generalConfig/consts/boxConsts";
var addBoxTranslationsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getEditorFloatingToolbarSafeZonesIntl = function () {
            var productName = this.product.variantManager.getName();
            if (productName === BOX_CONSTS.BOX_MODEL_DELIVERY_MAILER_BOX) {
                return "box-editor.notifications.error.safe-zones--ecommerce-delivery-box";
            }
            else {
                return _super.prototype.getEditorFloatingToolbarSafeZonesIntl.call(this);
            }
        };
        class_1.prototype.getNameIntl = function () {
            var productName = this.product.variantManager.getName();
            if (productName === "packhelp-warranty") {
                return "packhelp-warranty.name";
            }
            return "box-type.".concat(productName, ".name");
        };
        class_1.prototype.getMaterialIntl = function () {
            var materialName = this.product.variantManager.getMaterial();
            return "box-configurator.material-switcher.material.".concat(materialName, ".name");
        };
        class_1.prototype.getModelVariantNameIntl = function () {
            return "product.model-variant.name.".concat(this.product.variantManager.getVariantDashed());
        };
        return class_1;
    }(superclass));
};
export default addBoxTranslationsBehaviors;
