var _a, _b;
import BOX_CONSTS from "../consts/boxConsts";
export default (_a = {},
    _a[BOX_CONSTS.PRODUCT_BOX] = {
        sizes: [
            "13",
            "15",
            "15i",
            "18",
            "18i",
            "18ii",
            "20",
            "21",
            "21i",
            "24",
            "24i",
            "24ii",
            "24iii",
            "25",
            "26",
            "28i",
            "29",
            "30",
            "34",
            "35",
            "36",
            "39",
            "40i",
            "43",
            "45",
        ],
        defaultSize: 24,
        prefix: "P",
        productCategory: BOX_CONSTS.PRODUCT_BOX,
        addonsInVariants: [
            BOX_CONSTS.PRINT_FINISH_FOIL_GLOSSY,
            BOX_CONSTS.PRINT_FINISH_FOIL_MATTE,
            BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD_WHITE_INSIDE,
            BOX_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE,
        ],
        variants: (_b = {},
            _b[BOX_CONSTS.PRODUCT_BOX_DEFAULT_VARIANT] = {
                materials: [
                    BOX_CONSTS.PRINT_MATERIAL_CARDSTOCK,
                    BOX_CONSTS.PRINT_MATERIAL_KRAFT,
                    BOX_CONSTS.PRINT_MATERIAL_WHITE,
                ],
                colorModes: [
                    BOX_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR,
                    BOX_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE,
                ],
                finishes: [
                    BOX_CONSTS.PRINT_FINISH_NONE,
                    BOX_CONSTS.PRINT_FINISH_FOIL_MATTE,
                ],
            },
            _b),
    },
    _a);
