import React, { FunctionComponent } from "react"
import cxBinder from "classnames/bind"
import styles from "./RadioButton.module.scss"

const cx = cxBinder.bind(styles)

export enum RadioSizes {
  small = "small",
  mediumOnMobile = "medium-on-mobile",
  default = "default"
}

interface RadioButtonProps {
  onChange?: any
  classes?: string
  selected?: boolean
  disabled?: boolean
  value?: string
  model?: any
  size?: RadioSizes
  id?: string
  e2eTargetName?: string
}

const RadioButton: FunctionComponent<RadioButtonProps> = (props) => {
  const {
    children,
    value,
    model,
    onChange,
    selected,
    disabled,
    classes = "",
    size,
    e2eTargetName,
    ...other
  } = props

  const handleChange = () => {
    if (
      typeof onChange === "function" &&
      (typeof disabled === "undefined" || !disabled) &&
      (typeof model === "undefined" || model) &&
      value
    ) {
      onChange(value)
    }
  }

  const isSelected = () => {
    if (model) {
      return model === value
    }
    return selected
  }

  return (
    <div className={cx("wrapper")}>
      <label
        htmlFor="radio"
        {...other}
        onClick={handleChange}
        style={{
          position: "relative"
        }}
      >
        <span
          className={cx(
            "base",
            {
              [`${classes}`]: true,
              small: size == RadioSizes.small,
              ["medium-on-mobile"]: size == RadioSizes.mediumOnMobile
            },
            {
              selected: isSelected(),
              disabled
            }
          )}
          e2e-target="radio-button"
          e2e-target-name={e2eTargetName}
        />
        <input
          className={cx("input")}
          type="radio"
          disabled={disabled}
          checked={isSelected()}
          value={value}
          readOnly
        />
      </label>
    </div>
  )
}

export { RadioButton, RadioButton as default }
