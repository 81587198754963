import { PackhelpObject } from "../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../virtual-dieline-editor"
import { HighlightBackgroundShapeCreator } from "./highlight-background-shape-creator"
import {
  EditableSpaceConfig,
  EditContext,
  ModelEditableSpaces,
} from "../../../../../libs/products-render-config/types"
const HIGHLIGHT_SPACE_COLOUR = "rgba(51, 83, 216, 0.7)"

export interface HighlightDefinition {
  editContext?: EditContext
  spaceId?: ModelEditableSpaces
  x?: number
  y?: number
}

export class SpaceHighlightModule {
  private highlightedSpace?: PackhelpObject

  constructor(private readonly virtualDielineEditor: VirtualDielineEditor) {}

  public async createHighlights(
    spaceConfigs: EditableSpaceConfig[]
  ): Promise<void> {
    this.clearExistingHighlights()

    const highlightBackgroundShapeCreator = new HighlightBackgroundShapeCreator(
      this.virtualDielineEditor.dielineNavigator
    )

    for (let index = 0; index < spaceConfigs.length; index++) {
      const config = spaceConfigs[index]

      this.virtualDielineEditor.addOnCanvas(
        await highlightBackgroundShapeCreator.create(config.spaceId, index)
      )

      this.virtualDielineEditor.fabricCanvas.requestRenderAll()
    }
  }

  public findSpace({
    spaceId,
    x,
    y,
  }: HighlightDefinition): PackhelpObject | undefined {
    if (spaceId) {
      return this.findSpaceById(spaceId)
    }

    if (x !== undefined && y !== undefined) {
      return this.findSpaceByCoords(x, y)
    }
  }

  public findSpaceById(spaceId: string): PackhelpObject | undefined {
    return this.getHighlightBackgrounds().find(
      (side) => side.originSpaceArea === spaceId
    )
  }

  public findSpaceByCoords(x: number, y: number): PackhelpObject | undefined {
    const canvasDimensions = this.virtualDielineEditor.getCanvasDimensions()

    x = x * canvasDimensions.width
    y = y * canvasDimensions.height

    return this.getHighlightBackgrounds().find((space) => {
      const angle = space.angle!
      const left = space.left!
      const top = space.top!
      const width = space.width!
      const height = space.height!

      if (Math.abs(angle) === 180) {
        return left > x && left - width < x && top > y && top - height < y
      }

      return left < x && left + width > x && top < y && top + height > y
    })
  }

  public isSpaceHighlighted(): boolean {
    return !!this.highlightedSpace
  }

  public offHighlightSpace(): void {
    if (!this.highlightedSpace) {
      return
    }

    this.highlightedSpace.set({
      fill: HIGHLIGHT_SPACE_COLOUR,
      opacity: 0,
      stroke: "rgba(238, 171, 29, 0.5)",
      evented: false,
    })
    this.highlightedSpace = undefined
    this.virtualDielineEditor.fabricCanvas.renderAll()
  }

  public toggleHighlightedSpace(cursorDefinition: HighlightDefinition): void {
    const highlightedSpace = this.findSpace(cursorDefinition)

    if (
      !highlightedSpace ||
      highlightedSpace.id === this.highlightedSpace?.id
    ) {
      return
    }

    this.highlightedSpace = highlightedSpace
    this.highlightedSpace.set({
      fill: HIGHLIGHT_SPACE_COLOUR,
      opacity: 1,
      stroke: "rgba(238, 171, 29, 0)",
      evented: true,
      hoverCursor: "pointer",
    })
    this.virtualDielineEditor.fabricCanvas.renderAll()
  }

  public clearExistingHighlights(): void {
    for (const highlightBackground of this.getHighlightBackgrounds()) {
      this.virtualDielineEditor.fabricCanvas.remove(highlightBackground)
    }
  }

  private getHighlightBackgrounds(): PackhelpObject[] {
    return this.virtualDielineEditor.fabricCanvas
      .getObjects()
      .filter((obj) => obj.id && obj.id.includes("temp_background_"))
  }
}
