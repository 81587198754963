import VirtualDielineEditor from "../../../virtual-dieline-editor"
import {
  PackhelpEditableGroup,
  PackhelpEditableObject,
} from "../../../object-extensions/packhelp-objects"
import { EditableGroupController } from "./editable-group-controller"
import { ModelEditableSpaces } from "../../../../../../libs/products-render-config/types"
import { isAssetGroup } from "../../../../../../modules/ph-api/asset-types"
import { CanvasObjectControllerFactory } from "../canvas-object-controller/canvas-object-controller-factory"

export class EditableGroupCreator {
  private readonly controllerFactory: CanvasObjectControllerFactory

  constructor(private readonly vdEditor: VirtualDielineEditor) {
    this.controllerFactory = new CanvasObjectControllerFactory(this.vdEditor)
  }

  public async create(
    spaceId: ModelEditableSpaces,
    objects: PackhelpEditableObject[]
  ): Promise<PackhelpEditableGroup> {
    const objectsToInclude = objects
      .map((object) => {
        if (isAssetGroup(object) && object.groupController) {
          return object.groupController.ungroup()
        }

        return object
      })
      .flat()
      .filter((object) => {
        const controller = this.controllerFactory.getController(object)
        return controller.isGroupingAvailable()
      })

    const groupController = new EditableGroupController(this.vdEditor)
    return groupController.init(spaceId, objectsToInclude)
  }
}
