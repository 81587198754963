var _a;
import BAG_CONSTS from "../generalConfig/consts/bagConsts";
var BAG_PRODUCTS_EDITOR_LIMITATIONS = {
    shared: {
        initialActiveSide: {
            activeSideId: "front",
            editedSideId: "front",
        },
        isPantoneColorsAvailable: true,
        showSideExtraPrice: {
            back: true,
        },
        sidesNameCodesMap: {
            front: "A",
            back: "B",
        },
        reforestationSupport: {
            badge: true,
            trees: true,
        },
    },
    products: (_a = {},
        _a[BAG_CONSTS.PAPER_BAG_VARIANT_STANDARD] = {
            productEditorMode: "2D",
            is3dEditorDisabled: true,
        },
        _a),
};
export default BAG_PRODUCTS_EDITOR_LIMITATIONS;
