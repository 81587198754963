var _a;
import BAG_CONSTS from "./consts/bagConsts";
export default (_a = {
        default: {
            DEFAULT_QUANTITY: 30,
            QUANTITY_RANGES: [
                30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500, 3000, 4000, 5000,
            ],
            QUANTITY_DENSITY: 500,
            QUANTITY_STEP: 10,
        }
    },
    _a[BAG_CONSTS.DOYPACK_BAG] = {
        DEFAULT_QUANTITY: 250,
        QUANTITY_RANGES: [
            250, 500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 5000,
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 250,
    },
    _a[BAG_CONSTS.TOTE_BAG] = {
        DEFAULT_QUANTITY: 50,
        QUANTITY_RANGES: [50, 100, 250, 500, 750, 1000, 1500, 2000, 2500, 5000],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a);
