import { EcoShapesStore } from "../eco-shapes-store/eco-shapes.store"
import ProductDriver from "../product-driver/product.driver"
import { ShapesManager } from "../../services/shapes-manager"
import { ProductDesignStore } from "../product-design-store/product-design.store"
import { ModelEditableSpaces } from "../../libs/products-render-config/types"
import { isEcoQrCode, Shape } from "../../libs/value-objects/shape"
import { ShapesStore } from "../shapes-store/shapes.store"
import { AllEditorEventsEmitter, eventTree } from "../editor-events"

export class ShapesController {
  constructor(
    private readonly ecoShapesStore: EcoShapesStore,
    private readonly shapesStore: ShapesStore,
    private readonly productDriver: ProductDriver,
    private readonly shapesManager: ShapesManager,
    private readonly productDesignStore: ProductDesignStore,
    private readonly ee: AllEditorEventsEmitter
  ) {}

  public async addShape(
    shape: Shape,
    spaceId: ModelEditableSpaces
  ): Promise<void> {
    this.shapesStore.setIsLoadingSingleShape(true)
    await this.addShapeOnCanvas(shape, spaceId)
    this.shapesStore.setIsLoadingSingleShape(false)
  }

  public async addEcoShape(
    shape: Shape,
    spaceId: ModelEditableSpaces
  ): Promise<void> {
    this.ecoShapesStore.setIsLoadingSingleEcoShape(true)
    const ecoShape = await this.prepareEcoShape(shape)
    await this.addShapeOnCanvas(ecoShape, spaceId)
    this.ecoShapesStore.setIsLoadingSingleEcoShape(false)
  }

  private async prepareEcoShape(shape: Shape): Promise<Shape> {
    if (isEcoQrCode(shape)) {
      return await this.shapesManager.generateEcoQrShape(shape, {
        designId: await this.getDesignId(),
      })
    }

    return shape
  }

  private async addShapeOnCanvas(shape: Shape, spaceId: ModelEditableSpaces) {
    const vdEditor = this.productDriver.getVdEditor()
    const canvasObject = await vdEditor.assetsModule.addShape(shape, {
      spaceId,
      shouldSelect: true,
      shouldApplyClippingMask: true,
    })

    this.productDriver.setDesignTouched(true)

    const isMaskApplied = !!canvasObject.maskController
    const designId = isEcoQrCode(shape) ? await this.getDesignId() : undefined

    this.ee.emit(
      eventTree.productDriver.shapeAdded,
      shape,
      isMaskApplied,
      designId
    )
  }

  private async getDesignId(): Promise<number | undefined> {
    const editorMode =
      this.productDriver.state.productRenderPilot.getEditorMode()

    if (editorMode === "designer") {
      return
    }

    return await this.productDesignStore.forceDesignId()
  }
}
