var _a, _b;
import BOX_CONSTS from "../consts/boxConsts";
export default (_a = {},
    _a[BOX_CONSTS.PRODUCT_BOX_WINE_BOX] = {
        sizes: [48],
        prefix: "P",
        productCategory: BOX_CONSTS.PRODUCT_BOX,
        addonsInVariants: [
            BOX_CONSTS.PRINT_FINISH_FOIL_GLOSSY,
            BOX_CONSTS.PRINT_FINISH_FOIL_MATTE,
            BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD_WHITE_INSIDE,
            BOX_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE,
        ],
        variants: (_b = {},
            _b[BOX_CONSTS.PRODUCT_BOX_WINE_BOX_DEFAULT_VARIANT] = {
                materials: [
                    BOX_CONSTS.PRINT_MATERIAL_CARDSTOCK,
                    BOX_CONSTS.PRINT_MATERIAL_KRAFT,
                ],
                colorModes: [
                    BOX_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR,
                    BOX_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE,
                ],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
            },
            _b),
    },
    _a);
