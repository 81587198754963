import { Debug } from "../../services/logger"
import { findProductPropertyBySku } from "../../libs/products-render-config/helpers"
import { ProductDriver } from "../product-driver/product.driver"
import { EditContext } from "../../libs/products-render-config/types"

const debug = Debug("ph:controllers:product-configuration")

export class ProductConfigurationUiController {
  constructor(private productDriver: ProductDriver) {}

  public async enablePrintingOption(editContext: EditContext) {
    const { productRenderPilot } = this.productDriver.state

    if (productRenderPilot.isPrintActiveFor(editContext)) {
      return
    }

    await this.toggleDoubleSidedPrintingOption()
  }

  public async toggleDoubleSidedPrintingOption() {
    debug("change print-inside started")
    const { productRenderPilot } = this.productDriver.state

    const colorModes = productRenderPilot.getAvailableColorModes()
    const currentColorMode = findProductPropertyBySku(
      colorModes,
      this.productDriver.productStore.productSku
    )

    const newColorMode = colorModes.find(
      (colorMode) => colorMode.id !== currentColorMode?.id
    )

    if (!newColorMode) {
      throw new Error("This configuration is not available")
    }

    debug("changing sku to enable/disable printInside")

    await this.productDriver.changeSku(
      newColorMode.sku,
      this.productDriver.productStore.customization
    )
  }
}
