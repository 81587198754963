import { useCallback, useEffect, useState } from "react"
import ResizeObserver from "resize-observer-polyfill"
import { useMatchQuery } from "dsl/src/common/hooks"

import { waitForWidget } from "./live-chat-services"

export const useSetChatPosition = () => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState<boolean>(false)
  const [targetPosition, setTargetPosition] = useState<string[] | string>()
  const isMobile = useMatchQuery()

  const changeChatWidgetPosition = useCallback((bottom = "0", right = "0") => {
    const chatWidget = document.getElementById(
      "hubspot-messages-iframe-container"
    )
    if (!chatWidget) return null
    chatWidget.style.transform = `translate(-${right}, -${bottom})`
  }, [])

  const setChatPosition = useCallback(
    async (liveChatPosition?: string | string[]) => {
      const isWidgetLoaded = await waitForWidget().catch(() => false)
      setIsWidgetLoaded(isWidgetLoaded)
      if (!isWidgetLoaded) return null
      liveChatPosition && setTargetPosition(liveChatPosition)

      const liveChatPositionArgs =
        typeof liveChatPosition === "string"
          ? [liveChatPosition]
          : liveChatPosition

      liveChatPositionArgs
        ? changeChatWidgetPosition(...liveChatPositionArgs)
        : changeChatWidgetPosition()
    },
    [changeChatWidgetPosition]
  )

  const onResize = useCallback(
    (entries) => {
      const entry = entries[0]
      if (isMobile && entry.contentRect.height > 100) {
        setChatPosition()
      } else if (isMobile) {
        setChatPosition(targetPosition)
      }
    },
    [targetPosition, setChatPosition, setIsWidgetLoaded]
  )

  useEffect(() => {
    if (!isWidgetLoaded) return
    const widget = document.getElementById("hubspot-messages-iframe-container")

    if (!widget) return
    const resizeObserver = new ResizeObserver(onResize)
    resizeObserver.observe(widget)
  }, [isMobile, isWidgetLoaded, onResize])

  return {
    setChatPosition,
  }
}
