import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../hooks/useTranslate"
import { I18N } from "../../../i18n"
import styles from "./CanvasLoader.module.scss"
import Loader, { LoaderSizes, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import React from "react"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

export const CanvasLoader = observer(() => {
  const t = useTranslate()
  const [containerSet] = useContainerSet((c) => [
    c.fscCertificate,
    c.designAndProductDriver,
    c.assets,
    c.designerMode,
    c.ecommerce,
    c.patterns,
    c.templates,
    c.logo,
    c.backgroundImage,
  ])

  if (!containerSet) {
    return null
  }

  const {
    designAndProductDriver,
    assets,
    templates,
    logo,
    backgroundImage,
    designerMode,
    patterns,
    fscCertificate,
    ecommerce,
  } = containerSet

  const productDriver = designAndProductDriver.productDriver
  const { shapesStore, ecoShapesStore, predefinedTextsStore } = assets
  const { templatesStore } = templates
  const { logoStore } = logo
  const { backgroundImageStore } = backgroundImage
  const designerModeStore =
    designerMode.designerModeNeeded && designerMode.designerModeStore

  const isLoadingPattern =
    patterns.patternsNeeded && patterns.patternsStore?.isSinglePatternLoading
  const isLoadingDesignerMode = designerModeStore && designerModeStore.isLoading
  const isLoadingSingleTemplate = templatesStore?.isLoadingSingleTemplate
  const isLoadingBackgroundImage = backgroundImageStore.isLoading
  const isLoadingSingleEcoShape = ecoShapesStore.isLoadingSingleEcoShape
  const isLoadingSingleShape = shapesStore.isLoadingSingleShape
  const isLoadingSinglePredefinedText =
    predefinedTextsStore.isLoadingSinglePredefinedText
  const isLoadingLogo = logoStore.isLoading
  const is3DView = !productDriver.state.activeSpace
  const isLoadingRenderer = productDriver.state.isRendererLoading
  const isLoadingVirtualDieline = productDriver.state.isVirtualDielineLoading
  const isLoading3DModel = productDriver.state.isLoading3DModel
  const isLoadingSingleFscShape =
    fscCertificate.available &&
    fscCertificate.fscCertificateStore?.isLoadingSingleShape
  const isProductChanging = productDriver.state.isProductChanging
  const isLoadingMorePricing = ecommerce.productPricingStore?.isLoadingPricing

  if (
    !isLoadingDesignerMode &&
    !isLoadingSingleTemplate &&
    !isLoadingBackgroundImage &&
    !isLoadingSingleEcoShape &&
    !isLoadingSingleShape &&
    !isLoadingSinglePredefinedText &&
    !(isLoadingLogo && is3DView) &&
    !isLoadingRenderer &&
    !isLoadingVirtualDieline &&
    !isLoading3DModel &&
    !isLoadingPattern &&
    !isLoadingSingleFscShape &&
    !isProductChanging &&
    !isLoadingMorePricing
  ) {
    return null
  }

  const getText = () => {
    if (isLoadingSingleTemplate) {
      return t(I18N.editorTools.templates.loadingTemplate)
    }

    return t(I18N.global.loading)
  }

  return (
    <div
      className={styles.overlay}
      e2e-target="loader"
      e2e-target-name="canvas-loader"
    >
      <div className={styles.loader}>
        <Loader type={LoaderTypes.circular} size={LoaderSizes.medium} />
        <Typography type={TType.Header13_500} className={styles.caption}>
          {getText()}
        </Typography>
      </div>
    </div>
  )
})
