import { MailerBoxVariant, ProductBoxVariant, ProductBoxTwoPiecesVariant, ProductBoxWineBoxVariant, ShippingBoxVariant, SleevedMailerBoxVariant, WhiteInkMailerBoxVariant, DeliveryMailerBoxVariant, } from "../../source/boxProductSpec/generalConfig/consts/ProductVariant";
import { PaperBagProductVariant } from "../../source/bagProductSpec/generalConfig/consts/ProductVariant";
import { EnvelopeProductVariant } from "../../source/envelopeProductSpec/generalConfig/consts/ProductVariant";
import { PolyMailerProductVariant, PolyMailerBioProductVariant, PolyMailerRecycledProductVariant, } from "../../source/polyMailerProductSpec/generalConfig/consts/ProductVariant";
import { PaperMailerProductVariant } from "../../source/paperMailerProductSpec/generalConfig/consts/ProductVariant";
import { PaperProductVariant } from "../../source/tissuePaperProductSpec/generalConfig/consts/ProductVariant";
import { TapeProductVariant } from "../../source/tapeProductSpec/generalConfig/consts/ProductVariant";
import { PaperCanProductVariant } from "../../source/paperTubeProductSpec/generalConfig/consts/ProductVariant";
import { WoodWoolVariant, } from "../../source/ncpProductSpec/generalConfig/consts/ProductVariant";
import { ComingSoonBoxProductVariant } from "../../interfaces/js/ComingSoonProducts/BoxProducts/BoxProduct";
import { LabelProductVariant } from "../../source/labelProductSpec/generalConfig/consts/ProductVariant";
import { CardProductVariant } from "../../source/cardProductSpec/generalConfig/consts/ProductVariant";
export { ComingSoonBoxProductVariant, PolyMailerProductVariant, PolyMailerBioProductVariant, PolyMailerRecycledProductVariant, PaperMailerProductVariant, PaperProductVariant, PaperBagProductVariant, TapeProductVariant, EnvelopeProductVariant, MailerBoxVariant, ProductBoxVariant, ProductBoxTwoPiecesVariant, ProductBoxWineBoxVariant, ShippingBoxVariant, PaperCanProductVariant, SleevedMailerBoxVariant, WhiteInkMailerBoxVariant, DeliveryMailerBoxVariant, WoodWoolVariant, LabelProductVariant, CardProductVariant, };
