import fabric from "editor/src/libs/vendors/Fabric"
import { AvailableRotations } from "../../../dieline-navigator/services/translation-calculator"
import { PackhelpObject } from "../../../../object-extensions/packhelp-objects"
import { MaskPosition } from "../types"
import { CANVAS_DIM } from "../../../../../../types"
import { RotationHelper } from "../../helpers/rotation-helper"

export class GlobalLayerClippingMaskCalculator {
  public calcPosition(
    mask: PackhelpObject,
    rotation: AvailableRotations
  ): MaskPosition {
    const newPosition = RotationHelper.rotatePoint(
      new fabric.Point(mask.left!, mask.top!),
      new fabric.Point(CANVAS_DIM / 2, CANVAS_DIM / 2),
      -rotation
    )

    return {
      left: newPosition.x - CANVAS_DIM / 2,
      top: newPosition.y - CANVAS_DIM / 2,
    }
  }
}
