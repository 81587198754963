import { DeveloperModeController } from "../../libs/helpers/developer-mode-controller"
import { useRootStore } from "./useRootStore"

type DeveloperModeProperties = {
  enabled: boolean
  setEnabled: (state: boolean) => void
  handleKeyboardShortcut: (
    e: React.KeyboardEvent<HTMLElement> | KeyboardEvent
  ) => void
}

export function useDeveloperMode(): DeveloperModeProperties {
  const rootStore = useRootStore()
  const { productDriver, isDeveloperMode } = rootStore

  const developerMode = new DeveloperModeController(productDriver)

  return {
    enabled: isDeveloperMode,
    setEnabled: (state: boolean) => {
      console.log(`Turn developer mode ${state ? "ON" : "OFF"}`)
      rootStore.setDeveloperMode(state)
    },
    handleKeyboardShortcut: (
      e: React.KeyboardEvent<HTMLElement> | KeyboardEvent
    ) => {
      developerMode.handleKeyboardShortcut(e)
    },
  }
}
