import React, { FunctionComponent } from "react"
import cxBinder from "classnames/bind"

import { Button } from "../../atoms/Button/Button"
import { ButtonSizes } from "../../atoms/Button/types"

import styles from "./Notification.module.scss"

const cx = cxBinder.bind(styles)

export enum NotificationTypes {
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning",
  Black = "black",
  Yellow = "yellow",
  Red = "red",
  Send = "send",
  LightYellow = "light-yellow",
  WildSand = "wild-sand"
}

type NotificationTypeString =
  | "success"
  | "error"
  | "info"
  | "warning"
  | "black"
  | "yellow"
  | "red"
  | "send"
  | "light-yellow"
  | "wild-sand"

export interface NotificationProps extends React.HTMLProps<HTMLDivElement> {
  type?: NotificationTypes | NotificationTypeString
  buttonPresent?: boolean
  icon?: boolean
  header?: boolean
  buttonContent?: any
  rightSideSlot?: React.ReactNode | string
  disabled?: boolean
  twoLinesPresent?: boolean
  onClick?: () => void
  onIconClick?: () => void
  title?: string
  subtitle?: string
  simple?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

const Notification: FunctionComponent<NotificationProps> = (props) => {
  const {
    type = NotificationTypes.Success,
    buttonPresent,
    icon,
    children,
    onClick,
    onIconClick,
    disabled,
    twoLinesPresent,
    header,
    simple,
    e2eTarget = "notification",
    e2eTargetName,
    ...other
  } = props
  const buttonEnabled = buttonPresent && type === NotificationTypes.Info
  let { rightSideSlot } = props
  let childrenEl = <span className={styles.text}>{children}</span>

  if (typeof rightSideSlot !== "undefined") {
    rightSideSlot = (
      <section className={styles["buttonEnabled"]}>
        {twoLinesPresent ? <TwoLinesNotification {...other} /> : childrenEl}
        {typeof rightSideSlot === "string" ? (
          <Button
            size={ButtonSizes.small}
            onClick={onClick}
            disabled={disabled}
          >
            {rightSideSlot}
          </Button>
        ) : (
          rightSideSlot
        )}
      </section>
    )
  }

  return (
    <div
      className={cx("wrapper", {
        success: type === NotificationTypes.Success,
        error: type === NotificationTypes.Error,
        info: type === NotificationTypes.Info,
        warning: type === NotificationTypes.Warning,
        black: type === NotificationTypes.Black,
        yellow: type === NotificationTypes.Yellow,
        red: type === NotificationTypes.Red,
        send: type === NotificationTypes.Send,
        "wild-sand": type === NotificationTypes.WildSand,
        "light-yellow": type === NotificationTypes.LightYellow,
        header,
        icon,
        buttonPresent: buttonEnabled,
        "wrapper--simple": simple
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {buttonEnabled ? rightSideSlot : childrenEl}
      {icon && <span className={styles.icon} onClick={onIconClick} />}
    </div>
  )
}

export interface TwoLinesNotificationProps extends NotificationProps {}

const TwoLinesNotification = (props: TwoLinesNotificationProps) => {
  const { title, subtitle } = props

  return (
    <div className={styles["text-block"]}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  )
}

export { Notification, Notification as default }
