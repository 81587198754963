import { ModelEditableSpaces } from "../../../../../../libs/products-render-config/types"
import { PackhelpEditableText } from "../../../object-extensions/packhelp-objects"
import { v4 as uuidv4 } from "uuid"
import { EditableObjectTypes } from "../../../../../../modules/ph-api/asset-types"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { ClipPathModes } from "../../../services/clip-path-generator"
import { AlignmentHelper } from "../helpers/alignment-helper"
import { SpaceClippingHelper } from "../helpers/space-clipping-helper"
import { ScaleHelper } from "../helpers/scale-helper"
import { ColorHelper } from "../helpers/color-helper"
import { Text } from "../../../../../../libs/value-objects/text"
import { FontHelper } from "../helpers/font-helper"
import fabric from "../../../../../../libs/vendors/Fabric"

export class TextCreator {
  public constructor(
    private readonly virtualDielineEditor: VirtualDielineEditor
  ) {}

  public async create(
    text: Text,
    spaceId: ModelEditableSpaces
  ): Promise<PackhelpEditableText> {
    await this.preloadFont(text)

    //@ts-ignore
    const object: PackhelpEditableText = new fabric.IText(text.getText())

    if (
      typeof object.width === "undefined" ||
      typeof object.height === "undefined"
    ) {
      throw new Error("object text is not initialize properly")
    }

    object.set({
      lockUniScaling: true,
      originSpaceArea: spaceId,
      fontFamily: text.getFontFamily(),
      assetTextMeta: text.toSource(),
      id: uuidv4(),
      assetType: EditableObjectTypes.assetText,
    })

    const MANUALLY_ADJUSTED_SIZE = 512
    ScaleHelper.scaleObjectToSpace(
      this.virtualDielineEditor,
      object,
      {
        width: MANUALLY_ADJUSTED_SIZE,
        height: MANUALLY_ADJUSTED_SIZE,
      },
      spaceId
    )

    AlignmentHelper.alignCenter(
      this.virtualDielineEditor.dielineNavigator,
      object
    )
    ColorHelper.applyDefaultColor(this.virtualDielineEditor, object)
    await SpaceClippingHelper.setSpaceClipping(
      this.virtualDielineEditor,
      spaceId,
      object,
      ClipPathModes.FillMode
    )

    return object
  }

  private async preloadFont(text: Text): Promise<void> {
    const fontFamily = text.getFontFamily()
    await FontHelper.preloadFont({ fontFamily })
    FontHelper.clearFabricFontCache(fontFamily)
  }
}
