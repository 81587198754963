var _a;
import BOX_CONST from "../../../../../../source/boxProductSpec/generalConfig/consts/boxConsts";
export default (_a = {},
    _a[BOX_CONST.PRINT_FINISH_SLEEVE_KRAFT] = {
        left: {
            0: "rgba(255,255,255,0)",
            1: "rgba(255, 255, 255, 1)",
        },
        right: {
            0: "rgba(255,255,255,0)",
            1: "rgba(255, 255, 255, 1)",
        },
        bottom: {
            0: "rgba(238,238,238,0)",
            1: "rgba(0,0,0,1)",
        },
        top: {
            0: "rgba(238,238,238,0)",
            1: "rgba(0,0,0,1)",
        },
        spotlight: {
            0: "rgba(0,0,0,0.2)",
            1: "rgba(255,255,255,0)",
        },
    },
    _a[BOX_CONST.PRINT_FINISH_SLEEVE_WHITE] = {
        left: {
            0: "rgba(255,255,255,0)",
            1: "#FFFFFF",
        },
        right: {
            0: "rgba(255,255,255,0)",
            1: "#FFFFFF",
        },
        bottom: {
            0: "rgba(238,238,238,0)",
            1: "#000000",
        },
        top: {
            0: "rgba(238,238,238, 0)",
            0.71: "rgba(122,122,122,0.3)",
            1: "rgba(255, 255, 255, 1)",
        },
        spotlight: {
            0: "rgba(0,0,0,0.2)",
            1: "rgba(255,255,255,0)",
        },
    },
    _a);
