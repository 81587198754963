var _a, _b, _c, _d, _e;
import POLY_MAILER_CONSTS from "../consts/polyMailerConsts";
var POLY_MAILER_PRODUCT_VARIANTS = (_a = {},
    _a[POLY_MAILER_CONSTS.POLY_MAILER] = {
        sizes: [43, 60, 80, 90, 100],
        prefix: "Y",
        addonsInVariants: [],
        productCategory: POLY_MAILER_CONSTS.POLY_MAILER,
        variants: (_b = {},
            _b[POLY_MAILER_CONSTS.POLY_MAILER_VARIANT_STANDARD] = {
                materials: [
                    POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_WHITE,
                    POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_BLACK,
                    POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_TRANSPARENT,
                ],
                colorModes: [
                    POLY_MAILER_CONSTS.PRINT_MODE_MONO_PANTONE_ONE_SIDE,
                    POLY_MAILER_CONSTS.PRINT_MODE_MONO_PANTONE_TWO_SIDES,
                ],
                finishes: [POLY_MAILER_CONSTS.PRINT_FINISH_NONE],
            },
            _b),
    },
    _a[POLY_MAILER_CONSTS.POLY_MAILER_BIO] = {
        sizes: [60, 80, 90],
        prefix: "Y",
        productCategory: POLY_MAILER_CONSTS.POLY_MAILER_BIO,
        variants: (_c = {},
            _c[POLY_MAILER_CONSTS.POLY_MAILER_BIO_VARIANT_STANDARD] = {
                materials: [POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_WHITE],
                colorModes: [
                    POLY_MAILER_CONSTS.PRINT_MODE_MONO_PANTONE_ONE_SIDE,
                    POLY_MAILER_CONSTS.PRINT_MODE_MONO_PANTONE_TWO_SIDES,
                ],
                finishes: [POLY_MAILER_CONSTS.PRINT_FINISH_NONE],
            },
            _c),
    },
    _a[POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN] = {
        sizes: [60, 80, 90],
        prefix: "Y",
        productCategory: POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN,
        variants: (_d = {},
            _d[POLY_MAILER_CONSTS.POLY_MAILER_BIO_PLAIN_VARIANT_STANDARD] = {
                materials: [POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_WHITE],
                colorModes: [POLY_MAILER_CONSTS.PRINT_MODE_NO_PRINT],
                finishes: [POLY_MAILER_CONSTS.PRINT_FINISH_NONE],
            },
            _d),
    },
    _a[POLY_MAILER_CONSTS.POLY_MAILER_RECYCLED] = {
        sizes: [43, 60, 80, 90, 100],
        prefix: "Y",
        productCategory: POLY_MAILER_CONSTS.POLY_MAILER_RECYCLED,
        variants: (_e = {},
            _e[POLY_MAILER_CONSTS.POLY_MAILER_RECYCLED_VARIANT_STANDARD] = {
                materials: [
                    POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_WHITE_BLACK_INSIDE,
                    POLY_MAILER_CONSTS.PRINT_MATERIAL_FOIL_BLACK,
                ],
                colorModes: [
                    POLY_MAILER_CONSTS.PRINT_MODE_MONO_PANTONE_ONE_SIDE,
                    POLY_MAILER_CONSTS.PRINT_MODE_MONO_PANTONE_TWO_SIDES,
                ],
                finishes: [POLY_MAILER_CONSTS.PRINT_FINISH_NONE],
            },
            _e),
    },
    _a);
export default POLY_MAILER_PRODUCT_VARIANTS;
