import { AxiosInstance } from "axios"
import { DirectusCertificateShape } from "../types"
import { AvailableColourModes } from "../../../libs/products-render-config/types"

interface GetCertificateShapesOptions {
  colorMode: AvailableColourModes
  type?: string | string[]
}

export class DirectusCertificateShapeApi {
  constructor(private ax: AxiosInstance) {}

  public getCertificateShapesDownloadUrl(
    options: GetCertificateShapesOptions
  ): string {
    return this.ax.getUri({
      url: `${this.ax.defaults.baseURL}/certificate-shapes`,
      params: {
        type: options.type,
        format: "zip",
        limit: -1,
      },
    })
  }

  public async getCertificateShapes(
    options: GetCertificateShapesOptions
  ): Promise<DirectusCertificateShape[]> {
    const response = await this.ax.get<{ data: DirectusCertificateShape[] }>(
      "certificate-shapes",
      {
        params: {
          type: options.type,
          limit: -1,
        },
      }
    )

    return response.data.data.filter((shapeData) => {
      if (shapeData.tags.length === 0) {
        return true
      }

      if (
        options.colorMode === AvailableColourModes.MONOCHROME ||
        options.colorMode === AvailableColourModes.MONO_PANTONE
      ) {
        return shapeData.tags.some((tag) => tag.name === "monochrome")
      }

      return shapeData.tags.some((tag) => tag.name === "color")
    })
  }
}
