import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { CardTool } from "../../atoms/CardTool/CardTool"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"
import { EditorToolList } from "./EditorToolList"
import _times from "lodash/times"
import Skeleton from "react-loading-skeleton"
import { ThumbXL } from "../../atoms/ThumbXL/ThumbXL"
import { Shape } from "../../../../libs/value-objects/shape"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { TM } from "../../../TypographyI18n"
import { I18N } from "../../../i18n"
import {
  FscActionConfirmModal,
  FscActionConfirmModalType,
} from "../Modal/fsc-action-confirm-modal/fsc-action-confirm-modal"
import { ReactComponent as InfoIcon } from "../../../assets/svg/info.svg"
import { ReactComponent as InfoHighlightedIcon } from "../../../assets/svg/info-highlighted.svg"
import styles from "./EditorToolFsc.module.scss"
import { AboutFscModal } from "../Modal/about-fsc-modal/about-fsc-modal"
import { AddFscFormBlock } from "../AddFscFormBlock/AddFscFormBlock"
import { CardNotification } from "../../atoms/card-notification/card-notification"
import { FormattedMessage } from "react-intl"

const i18n = I18N.editorTools.fsc

const FscShapesList = ({
  shapes,
  isFscActive,
  isLoadingShapes,
  isLoadingSingleShape,
  onShapeClick,
}: {
  shapes: Shape[]
  isFscActive: boolean
  isLoadingShapes: boolean
  isLoadingSingleShape: boolean
  onShapeClick: (shape: Shape) => void
}) => {
  if (isLoadingShapes) {
    return (
      <EditorToolList>
        {_times(6, () => (
          <Skeleton width={"100%"} height={158} />
        ))}
      </EditorToolList>
    )
  }

  return (
    <>
      <TM
        id={i18n.shapesSection.title}
        type={TType.Header17_500}
        className={styles.header_wrapper}
      />
      <EditorToolList>
        {shapes.map((shape, index) => {
          return (
            <ThumbXL
              key={index}
              isSelected={false}
              isDisabled={!isFscActive || isLoadingSingleShape}
              label={null}
              onClick={() => {
                onShapeClick(shape)
              }}
              imageURL={shape.src}
              imgLoading="lazy"
              e2eTargetName="tool-list-fsc-badge"
            />
          )
        })}
      </EditorToolList>
    </>
  )
}

export const EditorToolFsc = observer(() => {
  const container = useContainer()
  const [fscContainer] = container.fscCertificate
  const [ecommerceContainer] = container.ecommerce
  const [designAndProductDriverContainer] = container.designAndProductDriver

  const [confirmModalOptions, setConfirmModalOptions] = useState<{
    mode: FscActionConfirmModalType
    onConfirm: () => void
  } | null>(null)
  const [isAboutModalOpen, setAboutModalOpen] = useState(false)

  if (
    !fscContainer?.available ||
    !ecommerceContainer ||
    !designAndProductDriverContainer
  ) {
    return null
  }

  const { fscCertificateStore, fscCertificateUiController } = fscContainer
  const { activeSpace, productRenderPilot } =
    designAndProductDriverContainer.productDriver.state
  const isDbyMode = productRenderPilot.isDbyMode()

  if (!fscCertificateStore.fscCertificate) {
    return null
  }

  const shouldShowList =
    fscCertificateStore.isLoadingShapes || fscCertificateStore.shapes

  const isSpaceAvailableForFscShape =
    !activeSpace ||
    fscCertificateUiController.isSpaceAvailableForFscCertificateShape(
      activeSpace
    )

  const openConfirmModalIfNeeded = (
    modalType: FscActionConfirmModalType,
    shouldBeConfirmed: boolean,
    onConfirm: () => void
  ) => {
    if (shouldBeConfirmed) {
      setConfirmModalOptions({
        mode: modalType,
        onConfirm: () => {
          onConfirm()
          setConfirmModalOptions(null)
        },
      })
    } else {
      onConfirm()
    }
  }

  const addShapeOnDieline = (shape: Shape) => {
    openConfirmModalIfNeeded(
      "changeShape",
      fscCertificateUiController.shouldShowReplaceConfirmationModal(),
      async () => {
        await fscCertificateUiController.addFscCertificateShape(shape)
      }
    )
  }

  const toggleFsc = async () => {
    openConfirmModalIfNeeded(
      "removeFsc",
      fscCertificateUiController.shouldShowRemoveConfirmationModal(),
      async () => {
        await fscCertificateUiController.toggleFscCertificate()
      }
    )
  }

  return (
    <CardTool sizeXs cardName="fsc">
      <header className={styles.header}>
        <TM id={i18n.title} type={TType.Header17_500} />
        <InfoIcon
          role="button"
          onClick={() => {
            setAboutModalOpen(true)
          }}
        />
      </header>

      {isSpaceAvailableForFscShape ? (
        <>
          <AddFscFormBlock
            isChecked={fscCertificateStore.isSelected}
            isDisabled={fscCertificateStore.isLoadingSingleShape}
            onChange={toggleFsc}
            price={
              ecommerceContainer.taxStore.getPrice(
                fscCertificateStore.fscCertificate,
                designAndProductDriverContainer.productDesignStore.state.meta
                  .quantity
              ).displayPrice
            }
          />
          {shouldShowList && !isDbyMode && (
            <FscShapesList
              shapes={fscCertificateStore.shapes}
              isLoadingShapes={fscCertificateStore.isLoadingShapes}
              isLoadingSingleShape={fscCertificateStore.isLoadingSingleShape}
              onShapeClick={addShapeOnDieline}
              isFscActive={fscCertificateStore.isSelected}
            />
          )}
        </>
      ) : (
        <div className={styles.warning_wrapper}>
          <CardNotification Icon={InfoHighlightedIcon} type="info-highlighted">
            <Typography type={TType.Body13_350}>
              <FormattedMessage id={i18n.spaceUnavailable} />
            </Typography>
          </CardNotification>
        </div>
      )}

      {!!confirmModalOptions && (
        <FscActionConfirmModal
          mode={confirmModalOptions.mode}
          onConfirm={confirmModalOptions.onConfirm}
          onClose={() => {
            setConfirmModalOptions(null)
          }}
        />
      )}

      {isAboutModalOpen && (
        <AboutFscModal
          learnMoreUrl={fscCertificateUiController.getFscCertificationUrl()}
          onClose={() => {
            setAboutModalOpen(false)
          }}
        />
      )}
    </CardTool>
  )
})
