import React from "react"
import cxBinder from "classnames/bind"
import styles from "./Highlight.module.scss"
const cx = cxBinder.bind(styles)

export enum HighlightTypes {
  Black = "wrapper--black",
  Green = "wrapper--green",
  LightishBlue = "wrapper--lightish-blue",
  Squash = "wrapper--squash",
  BrownGrey = "wrapper--brown-grey",
  WhiteFour = "wrapper--white-four",
  WhiteThree = "wrapper--white-three",
  BubblegumPink = "wrapper--bubblegum-pink",
  Ultramarine = "wrapper--ultramarine",
  Jagger = "wrapper--jagger",
  BrightCyan = "wrapper--bright-cyan",
  PurpleBlue = "wrapper--purple-blue",
  Mariner = "wrapper--mariner",
  FrenchPass = "wrapper--french-pass",
  PaleRed = "wrapper--pale-red",
  VeryLightPink = "wrapper--very-light-pink",
  VeryLightPinkTwo = "wrapper--very-light-pink-two",
  MonteCarlo = "wrapper--monte-carlo",
  Manhattan = "wrapper--manhattan",
  HummingBird = "wrapper--humming-bird"
}

export interface HighlightProps extends React.HTMLProps<HTMLDivElement> {
  color?: HighlightTypes
}

const Highlight = (props: HighlightProps) => {
  const { color = HighlightTypes.VeryLightPinkTwo, children, ...other } = props

  return (
    <div className={cx("wrapper", { [color]: color })} {...other}>
      {children}
    </div>
  )
}

export { Highlight, Highlight as default }
