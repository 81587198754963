import React, { useState } from "react"
import cxBinder from "classnames/bind"
import styles from "./ColourPicker.module.scss"
import PickerColorPalette from "dsl/src/organisms/PickerColorPalette/PickerColorPalette"
import PickerColor from "dsl/src/organisms/PickerColor/PickerColor"
import { ColorListArray } from "dsl/src/organisms/PickerColor/PickerColorTypes"

import Picker, { PickerProps } from "../Picker/Picker"
import { useTranslate } from "../../../hooks/useTranslate"
import { Colour } from "../../../../libs/value-objects/colour"
import { ReactComponent as IconArrowUp } from "editor/src/ui/assets/svg/ArrowUp.svg"

const cx = cxBinder.bind(styles)

// type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type PickerWithoutLinkClick = Omit<PickerProps, "onLinkClick">

export interface ColourPickerProps extends PickerWithoutLinkClick {
  colorsList: ColorListArray[]
  onColorSelect: (color: Colour) => void
  onDoneClick: () => void
  selectedColor?: string
  singleContent?: boolean
  onClickOutside?: () => void
}

const ColourPicker = ({
  colorsList,
  onColorSelect,
  selectedColor,
  onBackClick,
  onDoneClick,
  ...other
}: ColourPickerProps) => {
  const [moreColorsVisible, showMoreColors] = useState<boolean>(false)

  const onFooterLinkClick = (e) => {
    e.preventDefault()
    showMoreColors(!moreColorsVisible)
  }

  const changeColor = (color) => {
    if (typeof color === "string") {
      onColorSelect(new Colour(color))
    } else {
      onColorSelect(new Colour(color.color))
    }
  }

  const t = useTranslate()

  return (
    <Picker
      onBackClick={onBackClick}
      onLinkClick={onFooterLinkClick}
      onDoneClick={onDoneClick}
      linkText={
        moreColorsVisible
          ? t("component.colour-picker.less-colours")
          : t("component.colour-picker.more-colours")
      }
      Icon={IconArrowUp}
      {...other}
    >
      {!moreColorsVisible ? (
        <div className={cx({ wrapper: true, wrapper_narrow: other.isInline })}>
          <PickerColorPalette
            collapsed
            selectedColor={selectedColor}
            onColorSelect={changeColor}
            colorsList={colorsList}
            isNarrow={other.isInline}
          />
        </div>
      ) : (
        <PickerColor color={selectedColor} onChange={changeColor} />
      )}
    </Picker>
  )
}

export { ColourPicker as default }
