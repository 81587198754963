import React, {
  createContext,
  useRef,
  useState,
  useEffect,
  useContext,
  FC,
} from "react"
import cxBinder from "classnames/bind"
import ReactDOM from "react-dom"

import Card, { CardType, HeightType } from "dsl/src/atoms/Card/Card"
import { ReactComponent as IconClose } from "../../../assets/svg/Close.svg"

import styles from "./Modal.module.scss"
const cx = cxBinder.bind(styles)

const ModalContext = createContext<HTMLDivElement | null>(null)

const ModalProvider = ({ children }) => {
  const modalRef = useRef(null)
  const [context, setContext] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    setContext(modalRef.current)
  }, [])

  return (
    <>
      <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
      <div ref={modalRef} />
    </>
  )
}

export enum InnerSize {
  default = "default",
  extraSmall = "extra-small",
  small = "small",
  auto = "auto",
  large = "large",
}

export enum Alignment {
  left = "left",
  right = "right",
}

interface ModalProps {
  onClose: () => void
  maxWidth?: InnerSize
  align?: Alignment
  fullHeight?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

export const Modal: FC<ModalProps> = ({
  onClose,
  maxWidth,
  fullHeight,
  e2eTarget = "modal",
  e2eTargetName,
  align,
  children,
}) => {
  const modalNode = useContext(ModalContext)

  return modalNode
    ? ReactDOM.createPortal(
        <div className={styles.wrapper}>
          <div
            className={cx("wrapper__inner", {
              "wrapper__inner--default": maxWidth === InnerSize.default,
              "wrapper__inner--small": maxWidth === InnerSize.small,
              "wrapper__inner--extra-small": maxWidth === InnerSize.extraSmall,
              "wrapper__inner--auto": maxWidth === InnerSize.auto,
              "wrapper__inner--large": maxWidth === InnerSize.large,
              align__right: align === Alignment.right,
              align__left: align === Alignment.left,
              "full-height": fullHeight,
            })}
            e2e-target={e2eTarget}
            e2e-target-name={e2eTargetName}
          >
            <Card
              type={CardType.Round}
              fluid
              withShadow
              {...(fullHeight && { heightType: HeightType.High })}
            >
              <div
                className={cx("content", {
                  "full-height": fullHeight,
                })}
              >
                <button
                  className={styles.close}
                  e2e-target="button"
                  e2e-target-name="close"
                >
                  <IconClose onClick={onClose} className={styles.close__icon} />
                </button>
                {children}
              </div>
            </Card>
          </div>
        </div>,
        modalNode
      )
    : null
}

export { ModalProvider, Modal as default }
