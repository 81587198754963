import { PredefinedTextRenderPilot } from "./predefined-text-render-config/predefined-text-render-pilot"
import { EditorMode } from "./types"
import { DesignVersion } from "../../modules/design/version"
import { PimProductRenderPilot } from "./pim-product-render-config/pim-product-render-pilot"
import { ProductRenderPilot } from "./product-render-pilot"
import { Product } from "@ph/product-api"

export async function ProductRenderPilotFactory(
  product: Product,
  designVersion: DesignVersion,
  editorMode: EditorMode
): Promise<ProductRenderPilot> {
  const sku = product.variantManager.getSku()

  if (sku === "designer-mode-fake-sku" && editorMode === "designer") {
    return new PredefinedTextRenderPilot(editorMode)
  }

  return new PimProductRenderPilot(product, designVersion, editorMode)
}
