import React, { ReactElement, useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { TType } from "dsl/src/atoms/Typography/Typography"
import cxBinder from "classnames/bind"
import { UploadDropZone } from "./UploadDropzone"
import styles from "./Upload.module.scss"
import { TM } from "../../../TypographyI18n"

const cx = cxBinder.bind(styles)

interface GlobalDropZoneProps {
  onDrop: (files: File[]) => void
}

export const GlobalDropZone = (props: GlobalDropZoneProps): ReactElement => {
  const { onDrop } = props
  const [isDropzoneActive, setDropzoneActive] = useState(false)

  const enterFile = useCallback((e: DragEvent) => {
    const dataUrl =
      e.dataTransfer &&
      (e.dataTransfer.getData("URL") || e.dataTransfer.getData("text"))
    e.preventDefault()
    if (!dataUrl) {
      setDropzoneActive(true)
    }
  }, [])

  const doDrop = useCallback(
    (acceptedFiles: File[]) => {
      onDrop(acceptedFiles)
      setDropzoneActive(false)
    },
    [onDrop]
  )

  useEffect(() => {
    document.addEventListener("dragstart", enterFile, false)
    document.addEventListener("dragover", enterFile, false)
    document.addEventListener("dragenter", enterFile, false)
    return (): void => {
      document.removeEventListener("dragstart", enterFile, false)
      document.removeEventListener("dragover", enterFile, false)
      document.removeEventListener("dragenter", enterFile, false)
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: doDrop,
    onDragLeave: () => {
      setDropzoneActive(false)
    },
    multiple: true,
  })

  return (
    <div
      className={cx({
        global_dropzone: true,
        active: isDropzoneActive,
      })}
    >
      <UploadDropZone inputProps={getInputProps()} rootProps={getRootProps()}>
        <div className={styles.global_dropzone_container}>
          <div className={styles.background} />
          <TM
            id="components.global-dropzone.drop-prompt"
            type={TType.Header13_500}
          />
        </div>
      </UploadDropZone>
    </div>
  )
}

export default GlobalDropZone
