import type { DesignAndProductDriverContainer } from "./design-and-product-driver-container"
import type { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap-container"
import type { ReplicablePatternsStore } from "../stores/replicable-patterns-store/replicable-patterns.store"
import type { ReplicablePatternUiController } from "../stores/_controllers/replicable-pattern-ui-controller"
import type { UtilEnvContainer } from "./util-container"

async function importDeps() {
  const { ReplicablePatternsStore } = await import(
    "../stores/replicable-patterns-store/replicable-patterns.store"
  )
  const { ReplicablePatternUiController } = await import(
    "../stores/_controllers/replicable-pattern-ui-controller"
  )

  return { ReplicablePatternsStore, ReplicablePatternUiController }
}

export async function provideReplicablePatternMaybeContainer(
  utilEnvContainer: UtilEnvContainer,
  rootBootstrapper: BootstrapClassicRootStoreContainer,
  designAndProduct: DesignAndProductDriverContainer
): Promise<ReplicablePatternMaybeContainer> {
  const { ee } = utilEnvContainer
  const { productRenderPilot } = rootBootstrapper
  const { assetStore, productDriver } = designAndProduct

  if (productRenderPilot.uiConfig.features.replicablePatterns) {
    const { ReplicablePatternsStore, ReplicablePatternUiController } =
      await importDeps()

    const replicablePatternsStore = new ReplicablePatternsStore(productDriver)

    const replicablePatternUiController = new ReplicablePatternUiController(
      replicablePatternsStore,
      productDriver,
      assetStore,
      ee
    )

    return {
      available: true,
      replicablePatternsStore,
      replicablePatternUiController,
    }
  }

  return {
    available: false,
  }
}

export type ReplicablePatternMaybeContainer =
  | {
      available: true
      replicablePatternsStore: ReplicablePatternsStore
      replicablePatternUiController: ReplicablePatternUiController
    }
  | {
      available: false
    }
