import React from "react"
import { observer } from "mobx-react-lite"
import { CardTool } from "../../../atoms/CardTool/CardTool"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { ChangeProductConfirmationModal } from "../../Modal/change-product-confirmation-modal/ChangeSizeConfirmationModal"
import { useTranslate } from "shared-libs/src/js/shared-components/i18n/useTranslate"
import { I18N } from "../../../../i18n"
import { SizeOptionsList } from "./SizeOptionsList/SizeOptionsList"
import { ReactComponent as ArrowLeftIcon } from "../../../../assets/svg/ArrowLeft.svg"
import styles from "./EditorToolChangeSize.module.scss"
import { VariantCustomization } from "@ph/product-api"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

const i18n = I18N.editorTools.changeSize

export const EditorToolChangeSize = observer(() => {
  const [containerSet, containerSetError] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ecommerce,
  ])
  const t = useTranslate()

  if (!containerSet || containerSetError) {
    return null
  }

  const { changeProductController, productDriver } =
    containerSet.designAndProductDriver
  const { productRenderPilot } = productDriver.state
  const { productStore } = productDriver
  const { ecommerceStore } = containerSet.ecommerce

  const availableSizes = productRenderPilot.getAvailableSizes()
  const isDbyMode = productRenderPilot.isDbyMode()

  return (
    <CardTool sizeXs cardName="changeSize">
      <div className={styles.editor_tool_change_size}>
        <div className={styles.editor_tool_change_size__headline}>
          <button
            className={styles.editor_tool_change_size__back_button}
            onClick={() => changeProductController.onCloseClick()}
            disabled={changeProductController.isSelectingDisabled}
          >
            <ArrowLeftIcon />
          </button>
          <Typography type={TType.Header17_500} htmlElement="h2">
            {t(i18n.changeSize)}
          </Typography>
        </div>

        <div className={styles.editor_tool_change_size__body}>
          <SizeOptionsList
            availableSizes={availableSizes}
            product={productStore.product}
            onSelectOption={(
              sku: string,
              customization?: VariantCustomization
            ) =>
              changeProductController.changeProduct("size", sku, customization)
            }
            isDisabled={
              ecommerceStore?.isDynamicVariantLoading ||
              changeProductController.isSelectingDisabled
            }
            isCustomSizeEnabled={productStore.isCustomSizeEnabled}
            customSizeError={ecommerceStore?.dynamicVariantError}
          />
        </div>
        {changeProductController.isConfirmationModalOpened && (
          <ChangeProductConfirmationModal
            onClose={() => changeProductController.onConfirmationModalClose()}
            onConfirm={() => changeProductController.confirmChange()}
            isDbyMode={isDbyMode}
          />
        )}
      </div>
    </CardTool>
  )
})
