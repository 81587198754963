import React from "react"
import { observer } from "mobx-react-lite"
import { MessagePreviewCart, MessagePreviewPublic } from "./SceneUIMessage"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

export const SceneUIMessageController = observer(() => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.envUtil,
    c.analytics,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, envUtil, analytics } = containerSet

  const { uri } = envUtil
  const { productDesignUiController, productDesignStore, productDriver } =
    designAndProductDriver
  const { isDesignInCart, meta: productDesignMeta } = productDesignStore.state

  const isReadOnly = productDesignMeta && productDesignMeta.dataReadOnly
  const isDtpPreviewMode = uri.isDtpPreviewMode()

  const onDesignDuplicateClick = async (designType: "private" | "public") => {
    const sku = productDriver.productStore.productSku
    const description =
      productDriver.state.productRenderPilot.getFullProductDescription()

    await analytics.analyticsController?.trackDuplicatePublicDesignClicked({
      designId: productDesignMeta.id,
      name: productDesignMeta.name,
      sku,
      size: description.codeName,
      designType,
    })

    await productDesignUiController.duplicate()
  }

  if (isDesignInCart) {
    return (
      <MessagePreviewCart
        onCopyDesignClick={() => onDesignDuplicateClick("private")}
        onToCart={() => envUtil.uri.redirectToCart()}
      />
    )
  }

  if (isReadOnly && !isDtpPreviewMode) {
    return (
      <MessagePreviewPublic
        onCopyDesignClick={() => onDesignDuplicateClick("public")}
      />
    )
  }

  return null
})
