import React from "react"
import { WalletTooltipProps } from "../../utils/wallet.types"
import { WalletSidebarHeader } from "./WalletSidebarHeader"
import { WalletSidebarLearnAboutWallet } from "./WalletSidebarLearnAboutWallet"
import { WalletSidebarLogin } from "./WalletSidebarLogin"
import { WalletSidebarBalanceInfo } from "./WalletSidebarBalanceInfo"
import { useTranslate } from "../../../../../shared-components/i18n/useTranslate"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { walletI18n } from "../../utils/wallet.translations"
import { WalletSidebarValueProposition } from "./WalletSidebarValueProposition"
import styles from "./WalletSidebar.module.scss"

export const WalletSidebar = React.forwardRef(
  (props: WalletTooltipProps, ref: any) => {
    const {
      onCloseButtonClick,
      walletData,
      totalPrice,
      linkSegmentTrackClick,
      orders,
      type = "wallet-balance",
    } = props
    const t = useTranslate()

    return (
      <div className={styles.container}>
        <div className={styles["view-blocker"]} onClick={onCloseButtonClick} />
        <div className={styles.wrapper} e2e-target="opened-wallet" ref={ref}>
          <WalletSidebarHeader
            walletData={walletData}
            onCloseButtonClick={onCloseButtonClick}
          />
          {type === "value-proposition" ? (
            <>
              <Typography type={TType.Header32_400}>
                {t(walletI18n.valuePropositionSignInAndSaveWithPackhelpWallet)}
              </Typography>
              <br />
              <WalletSidebarLearnAboutWallet
                linkSegmentTrackClick={linkSegmentTrackClick}
              />
              <div className={styles.divider} />
              <WalletSidebarValueProposition />
              <div className={styles.divider} />
              <WalletSidebarLogin />
            </>
          ) : (
            <>
              <WalletSidebarBalanceInfo
                orders={orders}
                walletData={walletData}
                totalPrice={totalPrice}
              />
              <WalletSidebarLearnAboutWallet
                linkSegmentTrackClick={linkSegmentTrackClick}
              />
            </>
          )}
        </div>
      </div>
    )
  }
)
