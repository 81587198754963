import _flow from "lodash/flow";
import boxProductSpec from "../../../source/boxProductSpec/boxProductSpec";
import BOX_CONSTS from "../../../source/boxProductSpec/generalConfig/consts/boxConsts";
import CustomProductConfigBuilder from "../configBuilders/CustomProductConfigBuilder";
import addCustomProductDimensionsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductDimensionsBehaviors";
import addCustomProductProductionTimesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductProductionTimesBehaviors";
import addCustomProductVariantBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductVariantBehaviors";
import addCustomProductEditConfigBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductEditConfigBehaviors";
import addCustomProductGrammagesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductGrammagesBehaviors";
import addCustomProductTranslationsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductTranslationsBehaviors";
import BoxTranslationsManager from "../Products/BoxProduct/behaviorsComposers/BoxTranslationsManager";
import BoxVariantManager from "../Products/BoxProduct/behaviorsComposers/BoxVariantManager";
import { OriginBoxDimensionsManager, SleevedMailerDimensionsManager, } from "../Products/BoxProduct/behaviorsComposers/BoxDimensionsManager";
import GrammagesManager from "../behaviorsComposers/GrammagesManager";
import WizzardManager from "../behaviorsComposers/WizzardManager";
import EditConfigManager from "../behaviorsComposers/EditConfigManager";
import ProductionTimeManager from "../behaviorsComposers/ProductionTimeManager";
var CustomProductionTimeManager = _flow([
    addCustomProductProductionTimesBehaviors,
])(ProductionTimeManager);
var CustomProductGrammagesManager = _flow([
    addCustomProductGrammagesBehaviors,
])(GrammagesManager);
var CustomProductVariantManager = _flow([addCustomProductVariantBehaviors])(BoxVariantManager);
var CustomOriginProductDimmensionsManager = _flow([
    addCustomProductDimensionsBehaviors,
])(OriginBoxDimensionsManager);
var CustomSleevedMailerDimensionsManager = _flow([
    addCustomProductDimensionsBehaviors,
])(SleevedMailerDimensionsManager);
var CustomProductTranslationsManager = _flow([
    addCustomProductTranslationsBehaviors,
])(BoxTranslationsManager);
var CustomProductEditrConfigManager = _flow([
    addCustomProductEditConfigBehaviors,
])(EditConfigManager);
var DimensionsManagerFactory = function (productName, productInstance) {
    if (productName === BOX_CONSTS.SLEEVED_MAILER_BOX) {
        return new CustomSleevedMailerDimensionsManager(productInstance);
    }
    else {
        return new CustomOriginProductDimmensionsManager(productInstance);
    }
};
var CustomBoxProduct = /** @class */ (function () {
    function CustomBoxProduct(sku, productName) {
        this.config = CustomProductConfigBuilder(boxProductSpec, sku, productName);
        this.wizzardManager = new WizzardManager(this);
        this.productionTimeManager = new CustomProductionTimeManager(this);
        this.editConfigManager = new CustomProductEditrConfigManager(this);
        this.variantManager = new CustomProductVariantManager(this);
        this.grammagesManager = new CustomProductGrammagesManager(this);
        this.dimensionsManager = DimensionsManagerFactory(productName, this);
        this.translationsManager = new CustomProductTranslationsManager(this);
    }
    CustomBoxProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    CustomBoxProduct.prototype.getMaterialDescription = function () {
        return null;
    };
    return CustomBoxProduct;
}());
export default CustomBoxProduct;
