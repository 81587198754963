import { ObjectController } from "./object-controller"
import { AvailableColourModes } from "../../../../../../libs/products-render-config/types"
import { PackhelpEditableSvgImage } from "../../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { isAssetSvgImage } from "../../../../../../modules/ph-api/asset-types"

export class SvgImageObjectController extends ObjectController {
  constructor(
    protected readonly canvasObject: PackhelpEditableSvgImage,
    protected readonly virtualDielineEditor: VirtualDielineEditor
  ) {
    super(canvasObject, virtualDielineEditor)

    if (!isAssetSvgImage(canvasObject)) {
      throw new Error("Type not supported")
    }
  }

  public isColorModificationAvailable(): boolean {
    const colorsPreset =
      this.virtualDielineEditor.productRenderPilot.getColoursPreset()

    if (colorsPreset.mode === AvailableColourModes.MONO_PANTONE) {
      return true
    }

    return false
  }

  public isGroupingAvailable(): boolean {
    if (this.canvasObject.isLogo) {
      return false
    }

    return super.isGroupingAvailable()
  }
}
