var _a, _b, _c, _d, _e, _f, _g, _h;
import BAG_CONSTS from "../consts/bagConsts";
var assetsPath = "/product-key-visuals/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var customPath = "/product-custom-dimensions";
var customDesktopPath = "/PaperBag/Desktop";
var customMobilePath = "/PaperBag/Mobile";
var custom = "/Custom";
var customPredefined = "/Predefined";
var toteBagCustomDesktopPath = "/ToteBag/Desktop";
var toteBagCustomMobilePath = "/ToteBag/Mobile";
var sizeAssets = {
    B63: {
        desktop: desktopPath + "B63.png",
        mobile: mobilePath + "B63.png",
    },
    B66: {
        desktop: desktopPath + "B66.png",
        mobile: mobilePath + "B66.png",
    },
    B70: {
        desktop: desktopPath + "B70.png",
        mobile: mobilePath + "B70.png",
    },
    B82: {
        desktop: desktopPath + "B82.png",
        mobile: mobilePath + "B82.png",
    },
    B95: {
        desktop: desktopPath + "B95.png",
        mobile: mobilePath + "B95.png",
    },
    B107: {
        desktop: desktopPath + "B107.png",
        mobile: mobilePath + "B107.png",
    },
    custom: {
        desktop: "".concat(customPath).concat(customDesktopPath).concat(custom, "/{locale}.png"),
        mobile: "".concat(customPath).concat(customMobilePath).concat(custom, "/{locale}.png"),
    },
    custom47: {
        desktop: "".concat(customPath).concat(customDesktopPath).concat(customPredefined, "/custom47.png"),
        mobile: "".concat(customPath).concat(customMobilePath).concat(customPredefined, "/custom47.png"),
    },
    custom68: {
        desktop: "".concat(customPath).concat(customDesktopPath).concat(customPredefined, "/custom68.png"),
        mobile: "".concat(customPath).concat(customMobilePath).concat(customPredefined, "/custom68.png"),
    },
    custom73: {
        desktop: "".concat(customPath).concat(customDesktopPath).concat(customPredefined, "/custom73.png"),
        mobile: "".concat(customPath).concat(customMobilePath).concat(customPredefined, "/custom73.png"),
    },
    custom84: {
        desktop: "".concat(customPath).concat(customDesktopPath).concat(customPredefined, "/custom84.png"),
        mobile: "".concat(customPath).concat(customMobilePath).concat(customPredefined, "/custom84.png"),
    },
    custom88: {
        desktop: "".concat(customPath).concat(customDesktopPath).concat(customPredefined, "/custom88.png"),
        mobile: "".concat(customPath).concat(customMobilePath).concat(customPredefined, "/custom88.png"),
    },
    custom89: {
        desktop: "".concat(customPath).concat(customDesktopPath).concat(customPredefined, "/custom89.png"),
        mobile: "".concat(customPath).concat(customMobilePath).concat(customPredefined, "/custom89.png"),
    },
    custom109: {
        desktop: "".concat(customPath).concat(customDesktopPath).concat(customPredefined, "/custom109.png"),
        mobile: "".concat(customPath).concat(customMobilePath).concat(customPredefined, "/custom109.png"),
    },
    custom112: {
        desktop: "".concat(customPath).concat(customDesktopPath).concat(customPredefined, "/custom112.png"),
        mobile: "".concat(customPath).concat(customMobilePath).concat(customPredefined, "/custom112.png"),
    },
};
var doypackSizeAssets = {
    D35: {
        desktop: desktopPath + "D35.png",
        mobile: mobilePath + "D35.png",
    },
    D42: {
        desktop: desktopPath + "D42.png",
        mobile: mobilePath + "D42.png",
    },
    D48: {
        desktop: desktopPath + "D48.png",
        mobile: mobilePath + "D48.png",
    },
    D56: {
        desktop: desktopPath + "D56.png",
        mobile: mobilePath + "D56.png",
    },
};
var toteBagSizeAssets = {
    TB79: {
        desktop: desktopPath + "TB79.png",
        mobile: mobilePath + "TB79.png",
    },
    custom: {
        desktop: "".concat(customPath).concat(toteBagCustomDesktopPath).concat(custom, "/{locale}.png"),
        mobile: "".concat(customPath).concat(toteBagCustomMobilePath).concat(custom, "/{locale}.png"),
    },
};
var COLOR_MODES_SVG_ICON_NAMES = (_a = {},
    _a[BAG_CONSTS.PRINT_MODE_MONO_PANTONE_ONE_SIDE] = "v5-print-one-site",
    _a[BAG_CONSTS.PRINT_MODE_MONO_PANTONE_TWO_SIDES] = "v5-print-two-sites",
    _a[BAG_CONSTS.SKU_PRINT_MODE_NO_PRINT] = "v5-print-two-sites",
    _a);
var imageNaturalCardboard = "/product-material-picker/natural-cardboard.png";
var imageWhiteCardboard = "/product-material-picker/white-cardboard.jpg";
var imageBlackPlastic = "/product-material-picker/black-plastic.png";
var imageRecycledCotton = "/product-material-picker/recycled-cotton.png";
var imageOrganicCotton = "/product-material-picker/organic-cotton.png";
var EditorMaterialImage;
(function (EditorMaterialImage) {
    EditorMaterialImage["BLACK"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-doypack-black.png";
    EditorMaterialImage["KRAFT"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-doypack-kraft.png";
    EditorMaterialImage["WHITE"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-doypack-white.png";
    EditorMaterialImage["PAPER_KRAFT"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-tape-kraft.png";
    EditorMaterialImage["PAPER_WHITE"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-tape-white.png";
    EditorMaterialImage["ORGANIC_COTTON"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-cotton-organic.png";
    EditorMaterialImage["RECYCLED_COTTON"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-cotton-recycled.png";
})(EditorMaterialImage || (EditorMaterialImage = {}));
var MATERIAL_IMAGES = (_b = {},
    _b[BAG_CONSTS.PRINT_MATERIAL_PAPER_NATURAL] = imageNaturalCardboard,
    _b[BAG_CONSTS.PRINT_MATERIAL_PAPER_WHITE] = imageWhiteCardboard,
    _b[BAG_CONSTS.PRINT_MATERIAL_WHITE] = imageWhiteCardboard,
    _b[BAG_CONSTS.PRINT_MATERIAL_KRAFT] = imageNaturalCardboard,
    _b[BAG_CONSTS.PRINT_MATERIAL_BLACK] = imageBlackPlastic,
    _b[BAG_CONSTS.PRINT_MATERIAL_RECYCLED_COTTON] = imageRecycledCotton,
    _b[BAG_CONSTS.PRINT_MATERIAL_ORGANIC_COTTON] = imageOrganicCotton,
    _b);
var editorImagePaths = (_c = {},
    _c[BAG_CONSTS.PRINT_MATERIAL_PAPER_NATURAL] = EditorMaterialImage.PAPER_KRAFT,
    _c[BAG_CONSTS.PRINT_MATERIAL_PAPER_WHITE] = EditorMaterialImage.PAPER_WHITE,
    _c[BAG_CONSTS.PRINT_MATERIAL_WHITE] = EditorMaterialImage.WHITE,
    _c[BAG_CONSTS.PRINT_MATERIAL_KRAFT] = EditorMaterialImage.KRAFT,
    _c[BAG_CONSTS.PRINT_MATERIAL_BLACK] = EditorMaterialImage.BLACK,
    _c[BAG_CONSTS.PRINT_MATERIAL_RECYCLED_COTTON] = EditorMaterialImage.RECYCLED_COTTON,
    _c[BAG_CONSTS.PRINT_MATERIAL_ORGANIC_COTTON] = EditorMaterialImage.ORGANIC_COTTON,
    _c);
var GLOBAL_ASSETS = Object.assign({}, { colorModesImagesPaths: COLOR_MODES_SVG_ICON_NAMES }, { materialImagesPaths: MATERIAL_IMAGES }, { editorImagePaths: editorImagePaths });
var BAG_PRODUCT_ASSETS = (_d = {},
    _d[BAG_CONSTS.PAPER_BAG] = (_e = {},
        _e[BAG_CONSTS.PAPER_BAG_VARIANT_STANDARD] = {
            keyVisual: [assetsPath + "bags_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _e),
    _d[BAG_CONSTS.DOYPACK_BAG_MODEL] = (_f = {},
        _f[BAG_CONSTS.DOYPACK_BAG_VARIANT] = {
            keyVisual: [assetsPath + "doypacks_desktop.png"],
            sizeAssets: doypackSizeAssets,
        },
        _f),
    _d[BAG_CONSTS.DOYPACK_BAG] = (_g = {},
        _g[BAG_CONSTS.DOYPACK_BAG_VARIANT] = {
            keyVisual: [assetsPath + "doypacks_desktop.png"],
            sizeAssets: doypackSizeAssets,
        },
        _g),
    _d[BAG_CONSTS.TOTE_BAG] = (_h = {},
        _h[BAG_CONSTS.TOTE_BAG_VARIANT] = {
            keyVisual: [assetsPath + "tote_bags_desktop.png"],
            sizeAssets: toteBagSizeAssets,
        },
        _h),
    _d);
var PRODUCT_ASSETS_CONFIG = {
    globalAssets: GLOBAL_ASSETS,
    productAssets: BAG_PRODUCT_ASSETS,
};
export default PRODUCT_ASSETS_CONFIG;
