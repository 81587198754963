var _a, _b, _c, _d;
import ENVELOPE_CONSTS from "../consts/envelopeConsts";
var assetsPath = "/product-key-visuals/";
var customPath = "/product-custom-dimensions/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var sizeAssets = {
    C4: {
        desktop: desktopPath + "C4.png",
        mobile: mobilePath + "C4.png",
    },
    C5: {
        desktop: desktopPath + "C5.png",
        mobile: mobilePath + "C5.png",
    },
    custom: {
        desktop: customPath + "CardboardEnvelope/Desktop/Custom/{locale}.png",
        mobile: customPath + "CardboardEnvelope/Mobile/Custom/{locale}.png",
    },
};
var imageNaturalCardboard = "/product-material-picker/natural-cardboard.png";
var imageWhiteCardboard = "/product-material-picker/white-cardboard.png";
var EditorMaterialImage;
(function (EditorMaterialImage) {
    EditorMaterialImage["KRAFT"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-tape-kraft.png";
    EditorMaterialImage["WHITE"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-tape-white.png";
})(EditorMaterialImage || (EditorMaterialImage = {}));
var editorImagePaths = (_a = {},
    _a[ENVELOPE_CONSTS.PRINT_MATERIAL_CARDBOARD_CARDSTOCK] = EditorMaterialImage.WHITE,
    _a[ENVELOPE_CONSTS.PRINT_MATERIAL_KRAFT] = EditorMaterialImage.KRAFT,
    _a);
var GLOBAL_ASSETS = {
    materialImagesPaths: (_b = {},
        _b[ENVELOPE_CONSTS.PRINT_MATERIAL_CARDBOARD_CARDSTOCK] = imageWhiteCardboard,
        _b[ENVELOPE_CONSTS.PRINT_MATERIAL_KRAFT] = imageNaturalCardboard,
        _b),
    editorImagePaths: editorImagePaths,
};
var ENVELOPE_PRODUCT_ASSETS = (_c = {},
    _c[ENVELOPE_CONSTS.ENVELOPE] = (_d = {},
        _d[ENVELOPE_CONSTS.ENVELOPE_VARIANT_STANDARD] = {
            keyVisual: [assetsPath + "envelopes_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _d),
    _c);
var PRODUCT_ASSETS_CONFIG = {
    globalAssets: GLOBAL_ASSETS,
    productAssets: ENVELOPE_PRODUCT_ASSETS,
};
export default PRODUCT_ASSETS_CONFIG;
