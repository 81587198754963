import { EcommerceStore } from "../ecommerce.store"
import { LocalePayload, VariantApi } from "../../modules/ecommerce-api/types"
import {
  convertCustomSizeUnitToMm,
  VariantCustomization,
} from "@ph/product-api"
import { ProductPricingStore } from "../pricing-store/product-pricing.store"
import { AllEditorEventsEmitter, eventTree } from "../editor-events"
import _cloneDeep from "lodash/cloneDeep"
import { ProductStore } from "../product-driver/product.store"
import { LocaleConfig } from "../../app-config/configs/locale.config"
import { SessionStore } from "../session-store/session.store"
import { UrlManipulatorProvider } from "../../services/url-manipulator"

export class EcommerceController {
  constructor(
    private readonly services: {
      variantApi: VariantApi
      ee: AllEditorEventsEmitter
      localeConfig: LocaleConfig
      uri: UrlManipulatorProvider
    },
    private readonly stores: {
      ecommerceStore: EcommerceStore
      productStore: ProductStore
      productPricingStore: ProductPricingStore
      sessionStore: SessionStore
    }
  ) {
    this.services.ee.on(eventTree.pd.qtyChanged, this.onQtyChanged.bind(this))
    this.services.ee.on(
      eventTree.pd.skuChangeEnded,
      this.onSkuChanged.bind(this)
    )
  }

  public init(isInstantPurchase: boolean): void {
    const { productStore, ecommerceStore } = this.stores

    const process = () => {
      if (!isInstantPurchase) {
        this.loadDynamicVariant(
          productStore.productSku,
          productStore.customization
        ).catch(() => {
          // Do not throw error when no dynamic variant found for saved design.
          // Quote Request button will be shown as fallback.
          ecommerceStore.setDynamicVariantError()
        })
      }

      if (!this.stores.ecommerceStore.selectedQuantity) {
        this.stores.ecommerceStore.setSelectedQuantity(
          Math.max(
            this.services.uri.getQtyFromUrl() || 0,
            this.stores.productPricingStore.getMinQty(productStore.product)
          )
        )
      }
    }

    if (!this.stores.productPricingStore.isLoadingPricing) {
      return process()
    }

    this.services.ee.once(eventTree.pricing.pricingLoaded, process)
  }

  public clearDynamicVariant(): void {
    this.stores.ecommerceStore.setDynamicVariantSku()
    this.stores.productPricingStore.clearCustomPricing()
  }

  public async loadDynamicVariant(
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    const { productStore, ecommerceStore } = this.stores

    this.clearDynamicVariant()
    ecommerceStore.setDynamicVariantError()

    const { product } = productStore
    const { isEditorCustomSizeEnabled, isDynamicPricingEnabled } = product
    const { selectedQuantity } = ecommerceStore
    const variant = product.getDefaultVariant()

    if (
      (customization && !isEditorCustomSizeEnabled) ||
      !isDynamicPricingEnabled ||
      this.isPricingLoaded(selectedQuantity, sku, !!customization)
    ) {
      return
    }

    this.stores.ecommerceStore.setIsDynamicVariantLoading(true)

    return this.services.variantApi
      .getDynamicVariant(
        sku,
        this.prepareDynamicVariantPayload(customization),
        this.prepareDynamicVariantLocalePayload(),
        this.stores.productPricingStore
          .getDynamicPricingQuantityRanges(product)
          .map((qty) => qty * variant.piecesPerUnit)
      )
      .then((customVariant) => {
        this.stores.ecommerceStore.setDynamicVariantSku(customVariant.sku)
        this.stores.productPricingStore.setDynamicPricing(
          customVariant.dynamic_prices.map((price) => ({
            ...price,
            range: {
              start: price.range.start / variant.piecesPerUnit,
              end: price.range.end / variant.piecesPerUnit,
            },
          })),
          sku,
          !!customization
        )
      })
      .catch((e) => {
        this.clearDynamicVariant()
        this.stores.ecommerceStore.setDynamicVariantError(e.message)

        throw e
      })
      .finally(() => {
        this.stores.ecommerceStore.setIsDynamicVariantLoading(false)
      })
  }

  public redirectToQuoteRequestForm(): void {
    const { quoteRequestFormUrl } = this.stores.ecommerceStore

    if (!quoteRequestFormUrl) {
      throw new Error("Quote Request Form url is not provided")
    }

    this.services.ee.once(eventTree.pd.saved, () => {
      window.location.href = quoteRequestFormUrl
    })
    this.services.ee.emit(eventTree.pd.saveTriggered)
  }

  private prepareDynamicVariantPayload(
    customization?: VariantCustomization
  ): VariantCustomization {
    if (!customization) {
      return {}
    }

    const payload = _cloneDeep(customization)
    const unit =
      this.stores.productStore.product.getDefaultVariant().size.units.length

    if (payload.size) {
      payload.size = convertCustomSizeUnitToMm(payload.size, unit)
    }

    return payload
  }

  private prepareDynamicVariantLocalePayload(): LocalePayload {
    const { user } = this.stores.sessionStore
    const region = this.services.localeConfig.productRegion
    const countryIso = user.last_used_country?.iso
    const zipcode = user.last_used_zip_code

    if (!countryIso || !zipcode) {
      return { region }
    }

    return {
      region,
      zipcode,
      country_iso: countryIso,
    }
  }

  private async onQtyChanged(qty: number): Promise<void> {
    const { productSku, customization } = this.stores.productStore

    if (this.isPricingLoaded(qty, productSku, !!customization)) {
      return
    }

    await this.loadDynamicVariant(productSku, customization)
  }

  private isPricingLoaded(
    quantity: number,
    sku: string,
    isCustom: boolean
  ): boolean {
    const { productStore, productPricingStore, ecommerceStore } = this.stores
    const { product } = productStore
    const { selectedQuantity } = ecommerceStore

    const isQuantityInDynamicRange = productPricingStore
      .getDynamicPricingQuantityRanges(product)
      .includes(selectedQuantity)

    if (!isCustom && !isQuantityInDynamicRange) {
      return true
    }

    const price = this.stores.productPricingStore.getUnitPrice(
      quantity,
      sku,
      isCustom
    )

    return !!price.value || !!price.type
  }

  private async onSkuChanged(): Promise<void> {
    const { productStore, productPricingStore, ecommerceStore } = this.stores
    const { product } = productStore

    const minQuantity = productPricingStore.getMinQty(product)
    const quantity = ecommerceStore.selectedQuantity

    if (quantity < minQuantity) {
      ecommerceStore.setSelectedQuantity(minQuantity)
    }
  }
}
