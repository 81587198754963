import {
  PackhelpEditableObject,
  PackhelpGroupType,
  PackhelpObject,
} from "../../../../object-extensions/packhelp-objects"
import { BaseController } from "./base-controller"
import {
  CanvasEvent,
  CanvasObjectEvent,
} from "../../../../../../../stores/_events/domain-events"
import { LayerHelper } from "../../helpers/layer-helper"
import _findLastIndex from "lodash/findLastIndex"

export class LayerController extends BaseController {
  public moveLayerUp() {
    const currentObject = this.getCurrentObject()
    const { bottomLayerObjects, middleLayerObjects, allLayersObjects } =
      this.getLayers()

    const maxMiddleLayerIndex = this.findMaxMiddleLayerIndex(
      middleLayerObjects,
      currentObject
    )
    const maxIndex = bottomLayerObjects.length + maxMiddleLayerIndex

    const currentObjectIndex = this.getObjectIndex(
      currentObject,
      allLayersObjects
    )

    if (currentObjectIndex >= maxIndex) {
      return
    }

    let newObjectIndex = currentObjectIndex + 1
    const nextObject = allLayersObjects[currentObjectIndex + 1]

    if (
      this.inActiveSelection(currentObject) &&
      this.inActiveSelection(nextObject)
    ) {
      return
    }

    // If the next object on layers' stack has a groupId
    // it's a temporary object (for edit mode purposes)
    if (nextObject.groupId) {
      // If current index is max index we have to stop,
      // cause the current element is the last element on layers' stack
      if (newObjectIndex === maxIndex) {
        return
      }

      // We need to move the current object one extra level up,
      // cause we want to skip the temporary object
      newObjectIndex += 1
    }

    // If the next object on layers' stack is a mask
    // we need to move the current object one extra level up,
    // cause elements with masks occupy two layers
    if (nextObject.maskParentId) {
      newObjectIndex += 1
    }

    // Moves the current object to the new layer
    this.moveTo(currentObject, newObjectIndex)

    if (currentObject.maskController) {
      const mask = currentObject.maskController.getMask()

      if (mask) {
        // Moves the current object's mask to the new layer (just before the current object)
        this.moveTo(mask, newObjectIndex - 1)
      }
    }

    this.rerender()

    this.canvas.fire(CanvasEvent.objectModified, { target: currentObject })
    currentObject.fire(CanvasObjectEvent.layerChanged)
  }

  public moveLayerDown() {
    const currentObject = this.getCurrentObject()
    const { bottomLayerObjects, middleLayerObjects, allLayersObjects } =
      this.getLayers()

    const minMiddleLayerIndex = this.findMinMiddleLayerIndex(
      middleLayerObjects,
      currentObject
    )
    const minIndex = bottomLayerObjects.length + minMiddleLayerIndex
    let currentObjectIndex = this.getObjectIndex(
      currentObject,
      allLayersObjects
    )

    // If the current object has a mask applied we need to decrease the current index,
    // cause elements with masks occupy two layers
    if (currentObject.maskController) {
      currentObjectIndex -= 1
    }

    if (currentObjectIndex <= minIndex) {
      return
    }

    let newObjectIndex = currentObjectIndex - 1
    const prevObject = allLayersObjects[currentObjectIndex - 1]

    if (
      this.inActiveSelection(currentObject) &&
      this.inActiveSelection(prevObject)
    ) {
      return
    }

    // If the previous object on layers' stack has a mask applied
    // we need to move the current object one extra layer down,
    // cause elements with masks occupy two layers
    if (prevObject.maskController) {
      newObjectIndex -= 1
    }

    // Moves the current object to the new layer
    this.moveTo(currentObject, newObjectIndex)

    if (currentObject.maskController) {
      const mask = currentObject.maskController.getMask()

      if (mask) {
        // Moves the current object's mask to the new layer
        // Puts it on the same layer (it automatically moves the parent object one layer higher)
        this.moveTo(mask, newObjectIndex)
      }
    }

    this.rerender()

    this.canvas.fire(CanvasEvent.objectModified, { target: currentObject })
    currentObject.fire(CanvasObjectEvent.layerChanged)
  }

  private inActiveSelection(object: PackhelpEditableObject): boolean {
    if (object.maskParentId) {
      const maskParent = this.canvas
        .getObjects()
        .find((obj) => obj.id === object.maskParentId)

      return (
        !!maskParent &&
        !!maskParent.group &&
        maskParent.group.type === PackhelpGroupType.activeSelection
      )
    }

    return (
      !!object.group && object.group.type === PackhelpGroupType.activeSelection
    )
  }

  private moveTo(object: PackhelpObject, index: number) {
    this.canvas.moveTo(object, index)
  }

  private getLayers(): {
    bottomLayerObjects: PackhelpEditableObject[]
    middleLayerObjects: PackhelpEditableObject[]
    allLayersObjects: PackhelpEditableObject[]
  } {
    const objects = this.canvas.getObjects()
    const bottomLayerObjects = LayerHelper.getBottomLayerObjects(
      objects
    ) as PackhelpEditableObject[]
    const middleLayerObjects = LayerHelper.getMiddleLayerObjects(
      objects
    ) as PackhelpEditableObject[]

    return {
      bottomLayerObjects,
      middleLayerObjects,
      allLayersObjects: [...bottomLayerObjects, ...middleLayerObjects],
    }
  }

  private findMaxMiddleLayerIndex(
    middleLayerObjects: PackhelpObject[],
    currentObject: PackhelpObject
  ): number {
    let maxIndex = middleLayerObjects.findIndex((object) => {
      if (currentObject.id === object.id) {
        return false
      }

      if (!!currentObject.indexConfig && !!object.indexConfig) {
        return currentObject.indexConfig.index < object.indexConfig.index
      }

      if (!currentObject.indexConfig && !!object.indexConfig) {
        return true
      }

      return false
    })

    if (maxIndex < 0) {
      maxIndex = middleLayerObjects.length
    }

    return maxIndex - 1
  }

  private findMinMiddleLayerIndex(
    middleLayerObjects: PackhelpObject[],
    currentObject: PackhelpObject
  ): number {
    const minIndex = _findLastIndex(middleLayerObjects, (object) => {
      if (currentObject.id === object.id || object.maskParentId) {
        return false
      }

      if (!!currentObject.indexConfig && !!object.indexConfig) {
        return currentObject.indexConfig.index > object.indexConfig.index
      }

      if (!!currentObject.indexConfig && !object.indexConfig) {
        return true
      }

      return false
    })

    if (minIndex < 0) {
      return 0
    }

    return minIndex + 1
  }

  private getObjectIndex(
    object: PackhelpEditableObject,
    layers: PackhelpEditableObject[]
  ) {
    return layers.findIndex((layerObject) => layerObject === object)
  }
}
