export const I18N = {
  global: {
    loading: "global.loading-text",
  },
  generic: {
    cancel: "generic.cancel",
    notAvailable: {
      forSelectedSize: "generic.not-available-for-selected-size",
    },
    saving: "box-editor.top.actions.label.saving",
    getQuote: "box-configurator.z-box-configurator.get-a-quote",
    getQuoteMinQty:
      "box-configurator.z-box-configurator.get-a-quote-minimum-quantity",
    dimensions: {
      width: "generic.dimensions.width",
      height: "generic.dimensions.height",
      length: "generic.dimensions.length",
    },
    min: "box-configurator.box-dimensions.min",
    max: "box-configurator.box-dimensions.max",
  },
  confirmations: {
    closeWindow: {
      savingInProgress:
        "box-editor.window-confirm.confirm-losing-data--saving-data",
      unsavedData:
        "box-editor.window-confirm.confirm-losing-data--unsaved-data",
    },
  },
  notifications: {
    productSwitched: {
      productChanged: "notifications.product-switched.product-changed",
      goBackLink: "notifications.product-switched.go-back-i",
      goBackTo: "notifications.product-switched.go-back-ii",
    },
  },
  tabs: {
    logo: "components.universal-editor.tab-name.logo",
    logoPlaceholder: "components.universal-editor.tab-name.logo-placeholder",
    templates: "components.universal-editor.tab-name.templates",
    patterns: "components.universal-editor.tab-name.patterns",
    materialAndColor: "components.universal-editor.tab-name.material-and-color",
    background: "components.universal-editor.tab-name.background",
    materials: "components.universal-editor.tab-name.materials",
    text: "components.universal-editor.tab-name.text",
    shapes: "components.universal-editor.tab-name.shapes",
    images: "components.universal-editor.tab-name.images",
    eco: "components.universal-editor.tab-name.eco",
    printOptions: "components.universal-editor.tab-name.print-options",
    productConfiguration:
      "components.universal-editor.tab-name.product-configuration",
    productSwitcher: "components.universal-editor.tab-name.product-switcher",
  },
  product: {
    printMode: {
      insideOutside: "component.editor-tool.print-inside-outside.title",
      frontBack: "component.editor-tool.print-front-back.title",
      outside: "component.editor-tool.print-outside.title",
      front: "component.editor-tool.print-front.title",
    },
    finish: {
      doubleStrip: "product.finish.doubleStrip.name",
      sleeve: "box-type.sleeve.name",
      foilMatte: "product.finish.foilMatte.name",
      foilGlossy: "product.finish.foilGlossy.name",
    },
    finishTitle: {
      closure: "modal.download-or-print.closure",
    },
    infoText: {
      segmentPreview:
        "box-editor.two-dimensional-scene.preview-of-tape-segment",
    },
    material: {
      whiteInside: "product.material.coatedCardboardWhiteInside.name-inside",
    },
  },
  editorTools: {
    assets: {
      library: "editor-tools.assets.library",
      templateLibrary: "editor-tools.assets.template-library",
    },
    eco: {
      plantTrees: "editor-tools.eco.plant-trees",
      supportForest: "editor-tools.eco.support-forest",
      addBadge: "editor-tools.eco.add-badge",
      learnMore: "editor-tools.eco.learn-more",
    },
    personalize: {
      uploadImage: "editor-tools.personalize.upload-image",
    },
    text: {
      addText: "components.two-dimensional-editor.text-tool.add-text",
    },
    templates: {
      templates: "components.two-dimensional-editor.tab-name.templates",
      noTemplates: "editor-tools.no-templates",
      doubleSided: "editor-tool.template.type.double-sided",
      loadingTemplate: "editor-tool.loading-template",
      free: "component.currency-label.free",
    },
    materialAndColor: {
      patternColor: "component.editor-tool-material-and-color.title.pattern",
      backgroundColor:
        "component.editor-tool-material-and-color.title.background",
      defaultColors: "component.editor-tool-material-and-color.colors.default",
      lessColors: "component.editor-tool-material-and-color.colors.less",
      moreColors: "component.editor-tool-material-and-color.colors.more",
      hideSwatch: "component.editor-tool-material-and-color.colors.hide",
      search: {
        placeholder: "component.editor-tool-material-and-color.colors.search",
        noResults: "component.editor-tool-material-and-color.colors.not-found",
      },
      materials: "component.editor-tool-materials.title.material",
    },
    patterns: {
      artwork: "editor-tools.patterns.artwork",
      text: "editor-tools.patterns.text",
      yourText: "editor-tools.patterns.your-text",
      adjust: "editor-tools.patterns.adjust-fill-and-size",
      uploadNew: "editor-tools.patterns.upload-new",
      remove: "editor-tools.patterns.remove",
      straight: "editor-tools.patterns.straight",
      angled: "editor-tools.patterns.angled",
      artworkSize: "editor-tools.patterns.artwork-fill",
      artworkThreshold: "component.editor-tools-assets.threshold",
      horizontalSpacing: "editor-tools.patterns.horizontal-spacing",
      verticalSpacing: "editor-tools.patterns.vertical-spacing",
      layoutOptions: "editor-tools.patterns.layout-options",
      backToPatterns: "editor-tools.patterns.back-to-patterns",
    },
    logoPlaceholder: {
      logoPlaceholder: "editor-tools.logo-placeholder.logo-placeholder",
      description: "editor-tools.logo-placeholder.description",
      addLogoAndEdit: "editor-tools.logo-placeholder.add-logo-and-edit",
      editComposition: "editor-tools.logo-placeholder.edit-composition",
      addLogo: "editor-tools.logo-placeholder.add-logo",
      header: "editor-tools.logo-placeholder.header",
      subheader: "editor-tools.logo-placeholder.subheader",
      loading: "components.curtain.loading-states.generic-loading",
    },
    fsc: {
      title: "editor-tools.fsc.title",
      shapesSection: {
        title: "editor-tools.fsc.shapes-section.title",
      },
      addFscFormBlock: {
        title: "editor-tools.fsc.checkbox-section.title",
        description: "editor-tools.fsc.checkbox-section.description",
        learnMore: "editor-tools.fsc.checkbox-section.learn-more",
        addToDesign: "editor-tools.fsc.checkbox-section.add-to-design",
      },
      spaceUnavailable: "editor-tools.fsc.space-unavailable",
    },
    changeSize: {
      changeSize: "editor-tools.change-size.title",
      chooseShape: "editor-tools.change-size.choose-shape",
      cancel: "editor-tools.change-size.cancel",
      apply: "editor-tools.change-size.apply",
      customSize: {
        title: "editor-tools.change-size.custom-size.title",
        button: "editor-tools.change-size.custom-size.button",
        info: "editor-tools.change-size.custom-size.info",
      },
    },
    productSwitcher: {
      category: "site-search.filters.category.title",
      noResults: "listings.page.empty-page.title",
      clearFilters: "listings.filters.clear-all",
      productPage: "generic.product-page",
    },
  },
  components: {
    editorToolText: {
      label: {
        moreOptions: "components.editor-tool-text.label.more-options",
        fontLineHeight: "components.editor-tool-text.label.font-line-height",
        fontLetterSpacing:
          "components.editor-tool-text.label.font-letter-spacing",
        effects: "components.editor-tool-text.label.effects",
        none: "components.editor-tool-text.label.none",
        shadow: "components.editor-tool-text.label.shadow",
        stroke: "components.editor-tool-text.label.stroke",
        offsetX: "components.editor-tool-text.label.offsetX",
        offsetY: "components.editor-tool-text.label.offsetY",
        colour: "components.editor-tool-text.label.colour",
        thickness: "components.editor-tool-text.label.thickness",
        selectedColour: "components.editor-tool-text.label.selected-colour",
        defaultColours: "components.editor-tool-text.label.default-colours",
        hideSwatch: "components.editor-tool-text.label.hide-swatch",
        selectFont: "components.editor-tool-text.label.select-font",
      },
    },
    floatingToolbar: {
      imageQuality: "components.floating-toolbar.image-quality",
      imageQualityInfo: "components.floating-toolbar.image-quality-info",
      placementInfo: "components.floating-toolbar.placement-info",
      sizeInfo: "components.floating-toolbar.size-info",
      minScaleLimitInfo: {
        group: "components.floating-toolbar.min-scale-limit-info.group",
        text: "components.floating-toolbar.min-scale-limit-info.text",
        shape: "components.floating-toolbar.min-scale-limit-info.shape",
      },
    },
  },
  component: {
    footer: {
      toggleView: "editor.footer.toggle-view",
      side: "editor.footer.side",
      threeDim: "editor.footer.3d",
      toggler: {
        close: "component.scene-footer-3d.toggler.close",
        open: "component.scene-footer-3d.toggler.open",
      },
    },
    navbar: {
      defaultDesignName: "editor.messages.design-name-untitled",
      designLater: "generic.design-later-and-add-to-cart",
    },
    assetsUploader: {
      setAsLogo: "component.editor-tool.assets-uploader.set-as-logo",
      remove: "component.editor-tool.assets-uploader.remove",
      removeFile: "component.editor-tool.assets-uploader.remove-file",
      labels: {
        logo: "component.assets-uploader.labels.logo",
      },
    },
    cameraControlsToolbar: {
      overview: "component.editor-tool.camera-controls.overview",
      flatView: "component.editor-tool.camera-controls.flat-view",
      outside: "component.editor-tool.camera-controls.outside",
      inside: "component.editor-tool.camera-controls.inside",
      sleeve: "component.editor-tool.camera-controls.sleeve",
      front: "sides.front",
      back: "sides.back",
      limitedSpacesWarning:
        "component.editor-tool.camera-controls.limitedSpacesWarning",
    },
    colorInside: {
      title: "component.editor-tool.color-inside.title",
      piece: "component.editor-tool.print-inside.piece",
      description: "component.editor-tool.color-inside.description",
    },
    printInside: {
      title: "component.editor-tool.print-inside.title",
      piece: "component.editor-tool.print-inside.piece",
      description: "component.editor-tool.print-inside.description",
    },
    printInsideOverlay: {
      labels: {
        primary: "component.editor-tool.print-inside-overlay.labels.primary",
        secondary:
          "component.editor-tool.print-inside-overlay.labels.secondary",
      },
    },
    printBack: {
      title: "component.editor-tool.print-back.title",
      piece: "component.editor-tool.print-inside.piece",
      description: "component.editor-tool.print-back.description",
    },
    printBackOverlay: {
      labels: {
        secondary: "component.editor-tool.print-back-overlay.labels.secondary",
      },
    },
    colorPicker: {
      pantone: {
        search: "component.editor-tool.color-picker.pantone.search",
        noResults: "component.editor-tool.color-picker.pantone.noResults",
      },
    },
    cursorTooltip: {
      glueStrip: "component.editor-tool.cursor-tooltip.glue-strip",
      noPrintZone: "component.editor-tool.cursor-tooltip.no-print-zone",
    },
    dbyUploader: {
      dropHere: "editor.dby-upload.drop-here",
      preview: "editor.dby-upload.preview",
      chooseFile: "editor.dby-upload.choose-file",
      notReady1: "editor.dby-upload.not-ready-part1",
      notReady2: "editor.dby-upload.not-ready-part2",
      downloadDielines: "editor.dby-upload.download-dielines",
      or: "generic.or",
      designOnline: "editor.dby-upload.design-online",
      uploading: "editor.dby-upload.uploading",
      errorMessage: "editor.dby-upload.invalid-format",
      chooseDifferent: "editor.dby-upload.choose-different",
      uploaded: "editor.dby-upload.uploaded",
      replace: "editor.dby-upload.replace",
      delete: "editor.dby-upload.delete",
      formatGuidelines: "editor.dby-upload.format-guidelines",
      needHelp: "editor.dby-upload.need-help",
      downloadGuidelines: "editor.dby-upload.download-guidelines",
      configurationWarning: "editor-tools.common.dby-warning",
    },
  },
  modals: {
    aboutFsc: {
      title: "modals.about-fsc.title",
      description: "modals.about-fsc.description",
      learnMore: "modals.about-fsc.learn-more",
      priceDependsOnProductPrice:
        "cross-selling.price-of-service-depends-on-product-price",
    },
    fscActionConfirmation: {
      changeShape: {
        title: "modals.fsc-action-confirmation.change-shape.title",
        description: "modals.fsc-action-confirmation.change-shape.description",
        confirm: "modals.fsc-action-confirmation.change-shape.confirm",
      },
      removeFsc: {
        title: "modals.fsc-action-confirmation.remove.title",
        description: "modals.fsc-action-confirmation.remove.description",
        confirm: "modals.fsc-action-confirmation.remove.confirm",
      },
    },
    changeEditorProduct: {
      confirm: "modals.change-size.confirm",
      title: "modals.change-size.title",
      messagePart1: "modals.change-size.message-1",
      messagePart2: "modals.change-size.message-2",
    },
    changeDbyProduct: {
      confirm: "modals.change-size.confirm",
      title: "modals.change-size-dby.title",
      messagePart1: "modals.change-size-dby.message-1",
      messagePart2: "modals.change-size-dby.message-2",
    },
    customSize: {
      confirm: "modals.custom-size.confirm",
      title: "modals.custom-size.title",
      message: "modals.custom-size.message",
    },
    downloadDielines: {
      title: "modals.download-dielines.title",
      headerInstructions: "modals.download-dielines.header-instructions",
      instructions: {
        1: "modals.download-dielines.instruction-1",
        2: "modals.download-dielines.instruction-2",
        3: "modals.download-dielines.instruction-3",
        dbyMode: "modals.download-dielines.instruction-dby-mode",
      },
      headerGuidelines: "modals.download-dielines.header-guidelines",
      downloadAI: "modals.download-dielines.download-ai",
      generatingFile: "modals.download-dielines.generating-file",
      downloadPDF: "modals.download-dielines.download-pdf",
      headerResources: "modals.download-dielines.header-resources",
      resourcesEcoBadges: "modals.download-dielines.resources-eco-badges",
      ecoBadgesMessage: "modals.download-dielines.eco-badges-message",
      resourcesFscLabels: "modals.download-dielines.resources-fsc-labels",
      fscLabelsMessage: "modals.download-dielines.fsc-labels-message",
      resourcesPlantTree: "modals.download-dielines.resources-plant-trees",
      plantTreeMessage: "modals.download-dielines.plant-trees-message",
      resourcesEcoProps: "modals.download-dielines.resources-eco-props",
      ecoPropsMessage: "modals.download-dielines.eco-props-message",
      yourConfiguration: "modals.download-dielines.your-configuration",
    },
    bndl: {
      title: "modals.bndl.title",
      description: "modals.bndl.description",
      confirm: "modals.bndl.confirm",
      finishDesign: "modals.bndl.finish-design",
    },
  },
  assetError: {
    unsupportedFormatDby:
      "component.editor-tool.assets-uploader.error.unsupported-file-format-dby",
    unsupportedFormat:
      "component.editor-tool.assets-uploader.error.unsupported-file-format",
    tranformationFailed:
      "component.editor-tool.assets-uploader.error.asset-transformation-failed",
    emptyData: "component.editor-tool.assets-uploader.error.empty-data",
    noPreview: "component.editor-tool.assets-uploader.error.no-preview",
    invalidConfig:
      "component.editor-tool.assets-uploader.error.invalid-s3-config",
    uploadFailed: "component.editor-tool.assets-uploader.error.upload-failed",
  },
}
