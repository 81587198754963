import React, { FunctionComponent } from "react"
import cxBinder from "classnames/bind"
import styles from "./PickerColorPalette.module.scss"
import tinycolor from "tinycolor2"
import {
  ColorListArray,
  SideEditorColorObject
} from "../PickerColor/PickerColorTypes"

const DEFAULT_BOX_SHADOW = `0 0 0 1px transparent`

const cx = cxBinder.bind(styles)

export interface PickerColorPaletteProps {
  onColorSelect: (color: SideEditorColorObject) => void
  selectedColor?: string
  colorsList: ColorListArray[]
  collapsed?: boolean
  isNarrow?: boolean
}

const PickerColorPalette: FunctionComponent<PickerColorPaletteProps> = (
  props
) => {
  const prepareColorHex = (color?: string) => {
    if (!color) {
      return
    }

    return tinycolor(color).toHexString().toUpperCase()
  }

  const prepareColorsMap = () => {
    const { onColorSelect, selectedColor, colorsList } = props

    const colorsMap: ColorListArray[] = colorsList
    const selectedColorHex = prepareColorHex(selectedColor)

    return colorsMap.map((object: ColorListArray, index: number) => {
      const objectColorHex = prepareColorHex(object.color)

      const selected = selectedColorHex === object.color
      const styleColor = object.color ? object.color : "#000"

      const style: any = {
        boxShadow:
          selectedColorHex === objectColorHex
            ? `0 0 0 1px ${styleColor}`
            : DEFAULT_BOX_SHADOW
      }

      if (selected) {
        style["borderColor"] = styleColor
      }

      return (
        <li
          key={index}
          className={cx({
            list__item: true,
            list__item_narrow: props.isNarrow
          })}
        >
          <div
            className={cx("color", {
              selected: selected,
              reset: object.uid === "reset"
            })}
            style={style}
            onClick={() => onColorSelect(object)}
          >
            <div
              className={cx({
                "color-inner": true,
                "color-inner--transparent": !object.color
              })}
              style={{
                backgroundColor: object.color
              }}
              e2e-target="button"
              e2e-target-name="color"
            />
          </div>
        </li>
      )
    })
  }

  return (
    <div
      className={cx(
        "wrapper",
        { "wrapper--no-padding": props.collapsed },
        { "wrapper--narrow": props.isNarrow }
      )}
    >
      <ul className={styles.list}>{prepareColorsMap()}</ul>
    </div>
  )
}

export { PickerColorPalette, PickerColorPalette as default }
