import React from "react"
import { Error } from "./ui/theater/proscenium/views"

export class ErrorBoundary extends React.Component<
  { error: any },
  { isError: boolean }
> {
  constructor(props) {
    super(props)
    this.state = { isError: false }
  }

  static getDerivedStateFromError(error) {
    if (error) {
      return { isError: true }
    }
  }

  render() {
    const error = this.props.error
    if (this.state.isError || error) {
      return <Error />
    }
    return this.props.children
  }
}
