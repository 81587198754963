import React, { PropsWithChildren } from "react"

import styles from "./card-notification.module.scss"
import { ReactComponent as IconClose } from "../../../assets/svg/Close.svg"
import cxBinder from "classnames/bind"

const cx = cxBinder.bind(styles)

type CardNotificationProps = {
  Icon: React.ElementType
  onClose?: () => void
  type?: "info" | "info-highlighted" | "error"
}

export const CardNotification = ({
  Icon,
  children,
  onClose,
  type = "info",
}: PropsWithChildren<CardNotificationProps>) => {
  return (
    <div
      className={cx({
        wrapper: true,
        "wrapper--with-close-button": !!onClose,
        "wrapper--error": type === "error",
        "wrapper--info-highlighted": type === "info-highlighted",
      })}
    >
      <div className={styles.icon}>
        <Icon />
      </div>
      {children}
      {onClose && (
        <button type="button" className={styles.close_button} onClick={onClose}>
          <IconClose />
        </button>
      )}
    </div>
  )
}
