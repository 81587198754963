export default [
    {
        name: "brick-straight-small",
        tags: ["brick", "straight", "small"],
        source: [
            {
                left: 0.12,
                top: 0.12,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.37,
                top: 0.12,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.62,
                top: 0.12,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.87,
                top: 0.12,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0,
                top: 0.375,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.25,
                top: 0.375,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.5,
                top: 0.375,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.75,
                top: 0.375,
                width: 0.11,
                angle: 0,
            },
            {
                left: 1,
                top: 0.375,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.12,
                top: 0.625,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.37,
                top: 0.625,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.62,
                top: 0.625,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.87,
                top: 0.625,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0,
                top: 0.875,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.25,
                top: 0.875,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.5,
                top: 0.875,
                width: 0.11,
                angle: 0,
            },
            {
                left: 0.75,
                top: 0.875,
                width: 0.11,
                angle: 0,
            },
            {
                left: 1,
                top: 0.875,
                width: 0.11,
                angle: 0,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-brick-straight-small.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "brick-straight-medium",
        tags: ["brick", "straight", "medium"],
        source: [
            {
                left: 0.12,
                top: 0.12,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.37,
                top: 0.12,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.62,
                top: 0.12,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.87,
                top: 0.12,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0,
                top: 0.375,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.25,
                top: 0.375,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.5,
                top: 0.375,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.75,
                top: 0.375,
                width: 0.175,
                angle: 0,
            },
            {
                left: 1,
                top: 0.375,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.12,
                top: 0.625,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.37,
                top: 0.625,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.625,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.87,
                top: 0.625,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0,
                top: 0.875,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.25,
                top: 0.875,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.5,
                top: 0.875,
                width: 0.175,
                angle: 0,
            },
            {
                left: 0.75,
                top: 0.875,
                width: 0.175,
                angle: 0,
            },
            {
                left: 1,
                top: 0.875,
                width: 0.175,
                angle: 0,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-brick-straight-medium.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "brick-straight-large",
        tags: ["brick", "straight", "large"],
        source: [
            {
                left: 0.125,
                top: 0.125,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.125,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.125,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.125,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0,
                top: 0.375,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.25,
                top: 0.375,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.5,
                top: 0.375,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.75,
                top: 0.375,
                width: 0.25,
                angle: 0,
            },
            {
                left: 1,
                top: 0.375,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.125,
                top: 0.625,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.625,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.625,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.625,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0,
                top: 0.875,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.25,
                top: 0.875,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.5,
                top: 0.875,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.75,
                top: 0.875,
                width: 0.25,
                angle: 0,
            },
            {
                left: 1,
                top: 0.875,
                width: 0.25,
                angle: 0,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-brick-straight-large.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "brick-angled-small",
        tags: ["brick", "angled", "small"],
        source: [
            {
                left: 0,
                top: 0,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.333,
                top: 0,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.666,
                top: 0,
                width: 0.06,
                angle: 45,
            },
            {
                left: 1,
                top: 0,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.165,
                top: 0.165,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.5,
                top: 0.165,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.835,
                top: 0.165,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0,
                top: 0.333,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.333,
                top: 0.333,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.666,
                top: 0.333,
                width: 0.06,
                angle: 45,
            },
            {
                left: 1,
                top: 0.333,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.165,
                top: 0.5,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.5,
                top: 0.5,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.835,
                top: 0.5,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0,
                top: 0.666,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.333,
                top: 0.666,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.666,
                top: 0.666,
                width: 0.06,
                angle: 45,
            },
            {
                left: 1,
                top: 0.666,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.165,
                top: 0.825,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.5,
                top: 0.825,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.825,
                top: 0.825,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0,
                top: 1,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.333,
                top: 1,
                width: 0.06,
                angle: 45,
            },
            {
                left: 0.666,
                top: 1,
                width: 0.06,
                angle: 45,
            },
            {
                left: 1,
                top: 1,
                width: 0.06,
                angle: 45,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-brick-angled-small.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "brick-angled-medium",
        tags: ["brick", "angled", "medium"],
        source: [
            {
                left: 0,
                top: 0,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.333,
                top: 0,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.666,
                top: 0,
                width: 0.12,
                angle: 45,
            },
            {
                left: 1,
                top: 0,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.165,
                top: 0.165,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.5,
                top: 0.165,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.835,
                top: 0.165,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0,
                top: 0.333,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.333,
                top: 0.333,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.666,
                top: 0.333,
                width: 0.12,
                angle: 45,
            },
            {
                left: 1,
                top: 0.333,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.165,
                top: 0.5,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.5,
                top: 0.5,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.835,
                top: 0.5,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0,
                top: 0.666,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.333,
                top: 0.666,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.666,
                top: 0.666,
                width: 0.12,
                angle: 45,
            },
            {
                left: 1,
                top: 0.666,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.165,
                top: 0.835,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.5,
                top: 0.835,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.835,
                top: 0.835,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0,
                top: 1,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.333,
                top: 1,
                width: 0.12,
                angle: 45,
            },
            {
                left: 0.666,
                top: 1,
                width: 0.12,
                angle: 45,
            },
            {
                left: 1,
                top: 1,
                width: 0.12,
                angle: 45,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-brick-angled-medium.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "brick-angled-large",
        tags: ["brick", "angled", "large"],
        source: [
            {
                left: 0.165,
                top: 0,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.5,
                top: 0,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.835,
                top: 0,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0,
                top: 0.165,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.333,
                top: 0.165,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.666,
                top: 0.165,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 1,
                top: 0.165,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.165,
                top: 0.333,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.5,
                top: 0.333,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.835,
                top: 0.333,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0,
                top: 0.5,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.333,
                top: 0.5,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.666,
                top: 0.5,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 1,
                top: 0.5,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.165,
                top: 0.666,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.5,
                top: 0.666,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.835,
                top: 0.666,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0,
                top: 0.835,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.333,
                top: 0.835,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.666,
                top: 0.835,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 1,
                top: 0.835,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.165,
                top: 1,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.5,
                top: 1,
                width: 0.2375,
                angle: 45,
            },
            {
                left: 0.835,
                top: 1,
                width: 0.2375,
                angle: 45,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-brick-angled-large.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "grid-straight-small",
        tags: ["grid", "straight", "small"],
        source: [
            {
                left: 0.125,
                top: 0.125,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.125,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.125,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.125,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.125,
                top: 0.375,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.375,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.375,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.375,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.125,
                top: 0.625,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.625,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.625,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.625,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.125,
                top: 0.875,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.875,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.875,
                width: 0.06,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.875,
                width: 0.06,
                angle: 0,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-grid-straight-small.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "grid-straight-medium",
        tags: ["grid", "straight", "medium"],
        source: [
            {
                left: 0.125,
                top: 0.125,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.125,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.125,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.125,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.125,
                top: 0.375,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.375,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.375,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.375,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.125,
                top: 0.625,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.625,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.625,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.625,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.125,
                top: 0.875,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.875,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.875,
                width: 0.125,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.875,
                width: 0.125,
                angle: 0,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-grid-straight-medium.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "grid-straight-large",
        tags: ["grid", "straight", "large"],
        source: [
            {
                left: 0.125,
                top: 0.125,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.125,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.125,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.125,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.125,
                top: 0.375,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.375,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.375,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.375,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.125,
                top: 0.625,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.625,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.625,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.625,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.125,
                top: 0.875,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.375,
                top: 0.875,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.625,
                top: 0.875,
                width: 0.25,
                angle: 0,
            },
            {
                left: 0.875,
                top: 0.875,
                width: 0.25,
                angle: 0,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-grid-straight-large.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "grid-angled-small",
        tags: ["grid", "angled", "small"],
        source: [
            {
                left: 0.125,
                top: 0.125,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.375,
                top: 0.125,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.625,
                top: 0.125,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.875,
                top: 0.125,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.125,
                top: 0.375,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.375,
                top: 0.375,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.625,
                top: 0.375,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.875,
                top: 0.375,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.125,
                top: 0.625,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.375,
                top: 0.625,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.625,
                top: 0.625,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.875,
                top: 0.625,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.125,
                top: 0.875,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.375,
                top: 0.875,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.625,
                top: 0.875,
                width: 0.1,
                angle: 45,
            },
            {
                left: 0.875,
                top: 0.875,
                width: 0.1,
                angle: 45,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-grid-angled-small.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "grid-angled-medium",
        tags: ["grid", "angled", "medium"],
        source: [
            {
                left: 0.125,
                top: 0.125,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.375,
                top: 0.125,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.625,
                top: 0.125,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.875,
                top: 0.125,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.125,
                top: 0.375,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.375,
                top: 0.375,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.625,
                top: 0.375,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.875,
                top: 0.375,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.125,
                top: 0.625,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.375,
                top: 0.625,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.625,
                top: 0.625,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.875,
                top: 0.625,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.125,
                top: 0.875,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.375,
                top: 0.875,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.625,
                top: 0.875,
                width: 0.15,
                angle: 45,
            },
            {
                left: 0.875,
                top: 0.875,
                width: 0.15,
                angle: 45,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-grid-angled-medium.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "grid-angled-large",
        tags: ["grid", "angled", "large"],
        source: [
            {
                left: 0.165,
                top: 0.165,
                width: 0.235,
                angle: 45,
            },
            {
                left: 0.5,
                top: 0.165,
                width: 0.235,
                angle: 45,
            },
            {
                left: 0.835,
                top: 0.165,
                width: 0.235,
                angle: 45,
            },
            {
                left: 0.165,
                top: 0.5,
                width: 0.235,
                angle: 45,
            },
            {
                left: 0.5,
                top: 0.5,
                width: 0.235,
                angle: 45,
            },
            {
                left: 0.835,
                top: 0.5,
                width: 0.235,
                angle: 45,
            },
            {
                left: 0.165,
                top: 0.835,
                width: 0.235,
                angle: 45,
            },
            {
                left: 0.5,
                top: 0.835,
                width: 0.235,
                angle: 45,
            },
            {
                left: 0.835,
                top: 0.835,
                width: 0.235,
                angle: 45,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-grid-angled-large.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "random-small",
        tags: ["random", "small"],
        source: [
            {
                left: -0.025,
                top: 0.15,
                width: 0.07,
                angle: 349,
            },
            {
                left: 0.13,
                top: 0.33,
                width: 0.07,
                angle: 54,
            },
            {
                left: 0.145,
                top: 0.01,
                width: 0.07,
                angle: 334,
            },
            {
                left: 0.145,
                top: 1.01,
                width: 0.07,
                angle: 334,
            },
            {
                left: 0.18,
                top: 0.55,
                width: 0.07,
                angle: 306,
            },
            {
                left: 0.18,
                top: 0.785,
                width: 0.07,
                angle: 274,
            },
            {
                left: 0.295,
                top: 0.185,
                width: 0.07,
                angle: 134,
            },
            {
                left: 0.39,
                top: 0.64,
                width: 0.07,
                angle: 315,
            },
            {
                left: 0.4,
                top: 0.88,
                width: 0.07,
                angle: 283,
            },
            {
                left: 0.55,
                top: 0.375,
                width: 0.07,
                angle: 325,
            },
            {
                left: 0.56,
                top: 0.125,
                width: 0.07,
                angle: 216,
            },
            {
                left: 0.615,
                top: 0.885,
                width: 0.07,
                angle: 27,
            },
            {
                left: 0.7,
                top: 0.645,
                width: 0.07,
                angle: 12,
            },
            {
                left: 0.75,
                top: 0.24,
                width: 0.07,
                angle: 4,
            },
            {
                left: 0.845,
                top: 0.9,
                width: 0.07,
                angle: 324,
            },
            {
                left: 0.845,
                top: -0.05,
                width: 0.07,
                angle: 324,
            },
            {
                left: 0.89,
                top: 0.72,
                width: 0.07,
                angle: 24,
            },
            {
                left: 0.9,
                top: 0.44,
                width: 0.07,
                angle: 354,
            },
            {
                left: 0.975,
                top: 0.15,
                width: 0.07,
                angle: 349,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-random-small.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "random-large",
        tags: ["random", "large"],
        source: [
            {
                left: -0.025,
                top: 0.15,
                width: 0.175,
                angle: 349,
            },
            {
                left: 0.13,
                top: 0.33,
                width: 0.175,
                angle: 54,
            },
            {
                left: 0.145,
                top: 0.01,
                width: 0.175,
                angle: 334,
            },
            {
                left: 0.145,
                top: 1.01,
                width: 0.175,
                angle: 334,
            },
            {
                left: 0.18,
                top: 0.55,
                width: 0.175,
                angle: 306,
            },
            {
                left: 0.18,
                top: 0.785,
                width: 0.175,
                angle: 274,
            },
            {
                left: 0.295,
                top: 0.185,
                width: 0.175,
                angle: 134,
            },
            {
                left: 0.39,
                top: 0.64,
                width: 0.175,
                angle: 315,
            },
            {
                left: 0.4,
                top: 0.88,
                width: 0.175,
                angle: 283,
            },
            {
                left: 0.55,
                top: 0.375,
                width: 0.175,
                angle: 325,
            },
            {
                left: 0.56,
                top: 0.125,
                width: 0.175,
                angle: 216,
            },
            {
                left: 0.615,
                top: 0.885,
                width: 0.175,
                angle: 27,
            },
            {
                left: 0.7,
                top: 0.645,
                width: 0.175,
                angle: 12,
            },
            {
                left: 0.75,
                top: 0.24,
                width: 0.175,
                angle: 4,
            },
            {
                left: 0.845,
                top: 0.9,
                width: 0.175,
                angle: 324,
            },
            {
                left: 0.845,
                top: -0.05,
                width: 0.175,
                angle: 324,
            },
            {
                left: 0.89,
                top: 0.72,
                width: 0.175,
                angle: 24,
            },
            {
                left: 0.9,
                top: 0.44,
                width: 0.175,
                angle: 354,
            },
            {
                left: 0.975,
                top: 0.15,
                width: 0.175,
                angle: 349,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-random-large.svg",
        repeat: true,
        centered: false,
    },
    {
        name: "single-straight-small",
        tags: ["single", "straight", "small"],
        source: [
            {
                left: 0.5,
                top: 0.5,
                width: 0.125,
                angle: 0,
            },
        ],
        imgSrc: "https://packhelp-files.s3.amazonaws.com/app/editor/patterns/pattern-single-straight-small.svg",
        repeat: false,
        centered: true,
    },
];
