var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import PatternsProvider from "./PatternsProvider";
var addPatternsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            var _this = _super.call(this, props) || this;
            _this.patternsProvider = new PatternsProvider();
            return _this;
        }
        class_1.prototype.getPatterContainerParams = function (productClipObject) {
            var top = productClipObject.top, left = productClipObject.left, width = productClipObject.width, height = productClipObject.height;
            return {
                top: top,
                left: left,
                width: width,
                height: height,
            };
        };
        class_1.prototype.getFiltersConfigBuilder = function (params) {
            if (this.product.renderConfigManager.isColorSpaceMonochrome()) {
                return {
                    Contrast: {
                        contrast: 255,
                    },
                    RemoveWhite: {
                        threshold: 210,
                        distance: 255,
                    },
                    Tint: {
                        color: params.monochromInkColorSettings.hexColor,
                    },
                };
            }
            else {
                return;
            }
        };
        class_1.prototype.getDefaultPatternConfig = function () {
            return undefined;
        };
        class_1.prototype.getPatternTypeConfigById = function (patternId) {
            return {
                id: patternId,
                config: this.getPatterSourceConfigById(patternId),
            };
        };
        class_1.prototype.getPatterSourceConfigById = function (patternId) {
            return this.patternsProvider.getPatterSourceConfigById(patternId);
        };
        return class_1;
    }(superclass));
};
export default addPatternsBehaviors;
