import _flow from "lodash/flow";
import polyMailerProductSpec from "../../../source/polyMailerProductSpec/polyMailerProductSpec";
import CustomProductConfigBuilder from "../configBuilders/CustomProductConfigBuilder";
import addCustomProductDimensionsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductDimensionsBehaviors";
import addCustomProductProductionTimesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductProductionTimesBehaviors";
import addCustomProductVariantBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductVariantBehaviors";
import addCustomProductEditConfigBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductEditConfigBehaviors";
import addCustomProductGrammagesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductGrammagesBehaviors";
import addCustomProductTranslationsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductTranslationsBehaviors";
import PolyMailerTranslationsManager from "../Products/PolyMailerProduct/behaviorsComposers/PolyMailerTranslationsManager";
import PolyMailerVariantManager from "../Products/PolyMailerProduct/behaviorsComposers/PolyMailerVariantManager";
import GrammagesManager from "../behaviorsComposers/GrammagesManager";
import WizzardManager from "../behaviorsComposers/WizzardManager";
import EditConfigManager from "../behaviorsComposers/EditConfigManager";
import ProductionTimeManager from "../behaviorsComposers/ProductionTimeManager";
import PolyMailerDimensionsManager from "../Products/PolyMailerProduct/behaviorsComposers/PolyMailerDimensionsManager";
var CustomProductionTimeManager = addCustomProductProductionTimesBehaviors(ProductionTimeManager);
var CustomProductGrammagesManager = addCustomProductGrammagesBehaviors(GrammagesManager);
var CustomProductDimmensionsManager = addCustomProductDimensionsBehaviors(PolyMailerDimensionsManager);
var CustomProductEditrConfigManager = addCustomProductEditConfigBehaviors(EditConfigManager);
var CustomProductTranslationsManager = addCustomProductTranslationsBehaviors(PolyMailerTranslationsManager);
var CustomProductVariantManager = _flow([addCustomProductVariantBehaviors])(PolyMailerVariantManager);
var CustomPolyMailerProduct = /** @class */ (function () {
    function CustomPolyMailerProduct(sku, productName) {
        this.config = CustomProductConfigBuilder(polyMailerProductSpec, sku, productName);
        this.wizzardManager = new WizzardManager(this);
        this.productionTimeManager = new CustomProductionTimeManager(this);
        this.editConfigManager = new CustomProductEditrConfigManager(this);
        this.variantManager = new CustomProductVariantManager(this);
        this.grammagesManager = new CustomProductGrammagesManager(this);
        this.dimensionsManager = new CustomProductDimmensionsManager(this);
        this.translationsManager = new CustomProductTranslationsManager(this);
    }
    CustomPolyMailerProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    CustomPolyMailerProduct.prototype.getMaterialDescription = function () {
        return null;
    };
    return CustomPolyMailerProduct;
}());
export default CustomPolyMailerProduct;
