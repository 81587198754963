import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey } from "../SkuLib";
var ConfigBuilder = function (productConfig, sku, productName, productVariant) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    return {
        skuData: {
            sku: sku,
            decodedSKU: decodedSKU,
        },
        assets: {
            global: productConfig.ASSETS.globalAssets,
            product: productConfig.ASSETS.productAssets[productName][productVariant],
        },
        quantitiesSpec: productConfig.QUANTITIES,
        const: productConfig.CONSTS,
        dimensions: productConfig.DIMENSIONS[productName],
        grammages: productConfig.GRAMMAGES[productName],
        productionTimes: productConfig.PRODUCTION_TIMES[productName][productVariant],
        renderSpec: {
            productRenderConfig: productConfig.RENDER_SPEC.PRODUCTS[productName],
            sidesMapping: productConfig.RENDER_SPEC.SIDES_MAPPING,
        },
        userInterfaceConfig: {
            UI_CONFIG: productConfig.UI_CONFIG,
            productPreferredColors: productConfig.PRODUCT_PREFERED_COLORS,
        },
        variant: productConfig.VARIANTS[productName],
        weights: productConfig.WEIGHTS[productName][productVariant],
    };
};
export default ConfigBuilder;
