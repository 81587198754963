var _a, _b, _c, _d;
var assetsPath = "/product-key-visuals/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var customPath = "/product-custom-dimensions/";
import BOX_CONSTS from "../consts/boxConsts";
var sizeAssets = {
    K90: {
        desktop: desktopPath + "K90.png",
        mobile: mobilePath + "K90.png",
    },
    K105: {
        desktop: desktopPath + "K105.png",
        mobile: mobilePath + "K105.png",
    },
    K130: {
        desktop: desktopPath + "K130.png",
        mobile: mobilePath + "K130.png",
    },
    K150: {
        desktop: desktopPath + "K150.png",
        mobile: mobilePath + "K150.png",
    },
    K180: {
        desktop: desktopPath + "K180.png",
        mobile: mobilePath + "K180.png",
    },
    K200: {
        desktop: desktopPath + "K200.png",
        mobile: mobilePath + "K200.png",
    },
    custom: {
        desktop: customPath + "ShippingBox/Desktop/Custom/{locale}.png",
        mobile: customPath + "ShippingBox/Mobile/Custom/{locale}.png",
    },
};
var sizeAssetsPlainV2 = {
    K1: {
        desktop: desktopPath + "K1.png",
        mobile: mobilePath + "K1.png",
    },
    K2: {
        desktop: desktopPath + "K2.png",
        mobile: mobilePath + "K2.png",
    },
    K3: {
        desktop: desktopPath + "K3.png",
        mobile: mobilePath + "K3.png",
    },
    K4: {
        desktop: desktopPath + "K4.png",
        mobile: mobilePath + "K4.png",
    },
    K5: {
        desktop: desktopPath + "K5.png",
        mobile: mobilePath + "K5.png",
    },
    K6: {
        desktop: desktopPath + "K6.png",
        mobile: mobilePath + "K6.png",
    },
    K7: {
        desktop: desktopPath + "K7.png",
        mobile: mobilePath + "K7.png",
    },
    K8: {
        desktop: desktopPath + "K8.png",
        mobile: mobilePath + "K8.png",
    },
    K9: {
        desktop: desktopPath + "K9.png",
        mobile: mobilePath + "K9.png",
    },
    K10: {
        desktop: desktopPath + "K10.png",
        mobile: mobilePath + "K10.png",
    },
    K11: {
        desktop: desktopPath + "K11.png",
        mobile: mobilePath + "K11.png",
    },
    K12: {
        desktop: desktopPath + "K12.png",
        mobile: mobilePath + "K12.png",
    },
    K13: {
        desktop: desktopPath + "K13.png",
        mobile: mobilePath + "K13.png",
    },
    K14: {
        desktop: desktopPath + "K14.png",
        mobile: mobilePath + "K14.png",
    },
    K15: {
        desktop: desktopPath + "K15.png",
        mobile: mobilePath + "K15.png",
    },
    K16: {
        desktop: desktopPath + "K16.png",
        mobile: mobilePath + "K16.png",
    },
    K17: {
        desktop: desktopPath + "K17.png",
        mobile: mobilePath + "K17.png",
    },
    K18: {
        desktop: desktopPath + "K18.png",
        mobile: mobilePath + "K18.png",
    },
    K19: {
        desktop: desktopPath + "K19.png",
        mobile: mobilePath + "K19.png",
    },
    K20: {
        desktop: desktopPath + "K20.png",
        mobile: mobilePath + "K20.png",
    },
    K21: {
        desktop: desktopPath + "K21.png",
        mobile: mobilePath + "K21.png",
    },
    K22: {
        desktop: desktopPath + "K22.png",
        mobile: mobilePath + "K22.png",
    },
    custom: {
        desktop: customPath + "ShippingBox/Desktop/Custom/{locale}.png",
        mobile: customPath + "ShippingBox/Mobile/Custom/{locale}.png",
    },
};
export default (_a = {},
    _a[BOX_CONSTS.SHIPPING_BOX] = (_b = {},
        _b[BOX_CONSTS.SHIPPING_BOX_DEFAULT_VARIANT] = {
            keyVisual: [assetsPath + "shippingbox_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _b),
    _a[BOX_CONSTS.SHIPPING_BOX_PLAIN] = (_c = {},
        _c[BOX_CONSTS.SHIPPING_BOX_PLAIN_DEFAULT_VARIANT] = {
            keyVisual: [assetsPath + "shippingbox_plain_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _c),
    _a[BOX_CONSTS.SHIPPING_BOX_PLAIN_V2] = (_d = {},
        _d[BOX_CONSTS.SHIPPING_BOX_PLAIN_V2_DEFAULT_VARIANT] = {
            keyVisual: [assetsPath + "shippingbox_plain_desktop.png"],
            sizeAssets: sizeAssetsPlainV2,
        },
        _d),
    _a);
