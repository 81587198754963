import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey, isProductTypeCustom, } from "../SkuLib";
import CustomEnvelopeProduct from "../CustomProducts/CustomEnvelopeProduct";
import EnvelopeProduct from "../Products/EnvelopeProduct/EnvelopeProduct";
import EnvelopeVariantManager from "../Products/EnvelopeProduct/behaviorsComposers/EnvelopeVariantManager";
function EnvelopeProductFactory(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    var productName = decodedSKU.productName;
    var productVariant = EnvelopeVariantManager.findVariant();
    if (isProductTypeCustom(sku)) {
        return new CustomEnvelopeProduct(sku, productName);
    }
    else {
        return new EnvelopeProduct(sku, productName, productVariant);
    }
}
export default EnvelopeProductFactory;
