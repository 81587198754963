import { includes } from "shared-libs/src/js/libs/helpers/includes"
import { UIConfig } from "../../libs/products-render-config/types"
import { UrlManipulatorProvider } from "../../services/url-manipulator"
import { EditorToolbarItem, EditorToolbarStore } from "../editor-toolbar-store"
import ProductDriver from "../product-driver/product.driver"

export class EditorToolbarController {
  constructor(
    private editorToolbarStore: EditorToolbarStore,
    private productDriver: ProductDriver,
    private uiConfig: UIConfig,
    private uri: UrlManipulatorProvider
  ) {}

  private getInitialTab(): EditorToolbarItem | undefined {
    if (this.uri.isDesignerMode() || this.uri.isDtpTestEcommerceMode()) {
      return
    }

    if (this.uri.hasForcedLogoUploaderInUrl()) {
      if (this.uiConfig.tabs.logo) {
        return EditorToolbarItem.logo
      }

      if (this.uiConfig.tabs.replicablePatterns) {
        return EditorToolbarItem.replicablePatterns
      }
    }

    const forcedTab = this.uri.getForcedTab()

    if (forcedTab && includes(Object.values(EditorToolbarItem), forcedTab)) {
      return forcedTab as EditorToolbarItem
    }

    if (this.productDriver.state.productRenderPilot.isDbyMode()) {
      return EditorToolbarItem.productConfiguration
    }

    if (!this.uri.getSkuFromUrl() && !this.uri.getDesignIdParam()) {
      return EditorToolbarItem.productSwitcher
    }

    const isIn3dView = !this.productDriver.state.activeSpace
    const is3dProduct = this.productDriver.state.renderEngine?.has3D

    if (is3dProduct && !isIn3dView) {
      return
    }

    if (this.uiConfig.tabs.templates) {
      return EditorToolbarItem.templates
    }

    if (this.uiConfig.tabs.patterns) {
      return EditorToolbarItem.patterns
    }

    if (this.uiConfig.tabs.logo) {
      return EditorToolbarItem.logo
    }

    if (this.uiConfig.tabs.productConfiguration) {
      return EditorToolbarItem.productConfiguration
    }
  }

  selectInitialTab() {
    const initialTab = this.getInitialTab()

    if (!initialTab) {
      return
    }

    this.editorToolbarStore.selectTab(initialTab)
  }
}
