import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey, isProductTypeCustom, } from "../SkuLib";
import CustomLabelProduct from "../CustomProducts/CustomLabelProduct";
import LabelProduct from "../Products/LabelProduct/LabelProduct";
import LabelVariantManager from "../Products/LabelProduct/behaviorsComposers/LabelVariantManager";
function LabelProductFactory(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    var productName = decodedSKU.productName;
    var productVariant = LabelVariantManager.findVariant(productName);
    if (isProductTypeCustom(sku)) {
        return new CustomLabelProduct(sku, productName);
    }
    else {
        return new LabelProduct(sku, productName, productVariant);
    }
}
export default LabelProductFactory;
