import paperMailerProductSpec from "../../../../source/paperMailerProductSpec/paperMailerProductSpec";
import ConfigBuilder from "../../configBuilders/ConfigBuilder";
// Global product behaviors composers
import ProductionTimeManager from "../../behaviorsComposers/ProductionTimeManager";
import GrammagesManager from "../../behaviorsComposers/GrammagesManager";
import AssetsManager from "../../behaviorsComposers/AssetsManager";
import WeightManager from "../../behaviorsComposers/WeightManager";
import WizzardManager from "../../behaviorsComposers/WizzardManager";
import SiblingsManager from "../../behaviorsComposers/SiblingsManager";
import QuantitiesManager from "../../behaviorsComposers/QuantitiesManager";
// Poly Mailers specific behaviors composers
import PaperMailerTranslationsManager from "./behaviorsComposers/PaperMailerTranslationsManager";
import PaperMailerAddonsManager from "./behaviorsComposers/PaperMailerAddonsManager";
import PaperMailerVariantManager from "./behaviorsComposers/PaperMailerVariantManager";
import PaperMailerRenderConfigManager from "./behaviorsComposers/PaperMailerRenderConfigManager";
import PaperMailerEditConfigManager from "./behaviorsComposers/PaperMailerEditConfigManager";
import PaperMailerDimensionsManager from "./behaviorsComposers/PaperMailerDimensionsManager";
import ProductFactory from "../../ProductFactory";
var PaperMailerProduct = /** @class */ (function () {
    function PaperMailerProduct(sku, productName, productVariant) {
        this.config = this.builder(paperMailerProductSpec, sku, productName, productVariant);
        this.assetsManager = new AssetsManager(this);
        this.variantManager = new PaperMailerVariantManager(this);
        this.grammagesManager = new GrammagesManager(this);
        this.productionTimeManager = new ProductionTimeManager(this);
        this.weightsManager = new WeightManager(this);
        this.wizzardManager = new WizzardManager(this);
        this.editConfigManager = new PaperMailerEditConfigManager(this);
        this.translationsManager = new PaperMailerTranslationsManager(this);
        this.addonsManager = new PaperMailerAddonsManager(this);
        this.dimensionsManager = new PaperMailerDimensionsManager(this);
        this.renderConfigManager = new PaperMailerRenderConfigManager(this);
        this.siblingsManager = new SiblingsManager(this);
        this.quantitiesManager = new QuantitiesManager(this);
    }
    PaperMailerProduct.prototype.builder = function (spec, sku, productName, productVariant) {
        return ConfigBuilder(spec, sku, productName, productVariant);
    };
    PaperMailerProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    PaperMailerProduct.prototype.getEditorBackgroundPath = function (side) {
        var productCodeName = this.variantManager.getCodeName();
        var productName = "paper-mailers/paper-mailer_".concat(productCodeName, "_kraft_").concat(side, ".png");
        return "https://packhelp-files.s3-eu-west-1.amazonaws.com/app/editor/backgrounds/".concat(productName);
    };
    PaperMailerProduct.prototype.createBaseProductSku = function () {
        return this.addonsManager.setDefaultAddons();
    };
    PaperMailerProduct.prototype.getBaseProduct = function () {
        return ProductFactory(this.createBaseProductSku());
    };
    return PaperMailerProduct;
}());
export default PaperMailerProduct;
