import { getRoutingConfiguration } from "../services/_routing"
import type { CartStore } from "../stores/cart-store/cart.store"
import type { PlantingTreesStore } from "../stores/planting-trees-store/planting-trees.store"
import type { CurrencyStore } from "../stores/pricing-store/currency.store"
import type { ProductPricingStore } from "../stores/pricing-store/product-pricing.store"
import type { TaxStore } from "../stores/pricing-store/tax.store"
import type { ApiSessionContainer } from "./api-session-container"
import type { DesignAndProductDriverContainer } from "./design-and-product-driver-container"
import type { UtilEnvContainer } from "./util-container"
import { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap-container"
import { DesignLaterController } from "../stores/_controllers/design-later-controller"
import { AuthMaybeContainer } from "./auth-maybe-container"
import { CartDto } from "../modules/ecommerce-api/types"
import { AfterPurchaseEditStore } from "../stores/after-purchase-edit-store/after-purchase-edit-store"
import { EcommerceStore } from "../stores/ecommerce.store"
import { EcommerceController } from "../stores/_controllers/ecommerce.controller"

async function importDeps() {
  const { CurrencyStore } = await import(
    "../stores/pricing-store/currency.store"
  )
  const { TaxStore } = await import("../stores/pricing-store/tax.store")
  const { DesignLaterController } = await import(
    "../stores/_controllers/design-later-controller"
  )
  const { PricingManager } = await import("../services/pricing-manager")
  const { ProductPricingStore } = await import(
    "../stores/pricing-store/product-pricing.store"
  )
  const { PlantingTreesStore } = await import(
    "../stores/planting-trees-store/planting-trees.store"
  )
  const { CartStore } = await import("../stores/cart-store/cart.store")

  return {
    CurrencyStore,
    TaxStore,
    DesignLaterController,
    PricingManager,
    ProductPricingStore,
    PlantingTreesStore,
    CartStore,
  }
}

export async function provideEcommerceMaybeContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  designAndProduct: DesignAndProductDriverContainer,
  rootBootstrapper: BootstrapClassicRootStoreContainer,
  authContainer: AuthMaybeContainer
): Promise<EcommerceMaybeContainer> {
  const { uri, appConfig, ee } = utilEnvContainer
  const { apiService, designApi, ecommerceApi, sessionStore } =
    apiSessionContainer
  const { productDesignStore, productDriver, changeProductController } =
    designAndProduct
  const { productStore } = rootBootstrapper
  const { authController } = authContainer

  if (
    !appConfig.api.ecommerce.features.ecommerce.basic ||
    uri.isDesignerMode() ||
    uri.isDtpPreviewMode()
  ) {
    return {
      available: false,
      currencyStore: undefined,
      taxStore: undefined,
      plantingTreesStore: undefined,
      productPricingStore: undefined,
      cartStore: undefined,
      cart: undefined,
      designLaterController: undefined,
      ecommerceStore: undefined,
      ecommerceController: undefined,
      afterPurchaseEditStore: undefined,
    }
  }

  const {
    CurrencyStore,
    TaxStore,
    DesignLaterController,
    PricingManager,
    ProductPricingStore,
    PlantingTreesStore,
    CartStore,
  } = await importDeps()

  const cart = await ecommerceApi.cart.get()
  const afterPurchaseEditStore = new AfterPurchaseEditStore(
    rootBootstrapper.designMeta
  )

  productDriver.state.productRenderPilot.setAfterPurchaseEdit(
    afterPurchaseEditStore.isAfterPurchaseEdit
  )

  // DOMAIN: TAX, CURRENCY, TREES PRICING
  const currencyStore = new CurrencyStore(appConfig.locale)
  const taxStore = new TaxStore(
    getRoutingConfiguration().taxConfiguration,
    appConfig.locale
  )

  const plantingTreesStore = new PlantingTreesStore(currencyStore, taxStore)
  const pricingManager = new PricingManager(
    ecommerceApi.pricing,
    taxStore,
    appConfig.locale.productRegion
  )

  const productPricingStore = new ProductPricingStore(
    pricingManager,
    productDriver.productStore,
    productDesignStore,
    plantingTreesStore,
    taxStore,
    authController?.authEventEmitter
  )

  const ecommerceStore = new EcommerceStore(
    appConfig,
    productStore,
    productPricingStore,
    productDesignStore
  )

  const ecommerceController = new EcommerceController(
    {
      variantApi: ecommerceApi.variant,
      ee,
      localeConfig: appConfig.locale,
      uri,
    },
    {
      ecommerceStore,
      productPricingStore,
      productStore,
      sessionStore,
    }
  )

  changeProductController.setEcommerceController(ecommerceController)

  const cartStore = new CartStore(
    ecommerceApi.cart,
    apiService,
    designApi,
    plantingTreesStore,
    ecommerceStore
  )

  const designLaterController = new DesignLaterController(uri, ecommerceStore)

  const isInstantPurchase = (() => {
    const isDtpPreviewMode = uri.isDtpPreviewMode()

    if (isDtpPreviewMode) {
      return false
    }

    const isDbyMode = productDriver.state.productRenderPilot.isDbyMode()

    if (!isDbyMode) {
      return false
    }

    return uri.hasDesignLaterInUrl()
  })()

  cartStore.setIsInstantPurchase(isInstantPurchase)

  productPricingStore.init(isInstantPurchase)
  ecommerceController.init(isInstantPurchase)

  return {
    available: true,
    currencyStore,
    taxStore,
    plantingTreesStore,
    productPricingStore,
    cartStore,
    cart,
    designLaterController,
    ecommerceStore,
    ecommerceController,
    afterPurchaseEditStore,
  }
}

export type EcommerceMaybeContainer =
  | {
      available: true
      currencyStore: CurrencyStore
      taxStore: TaxStore
      plantingTreesStore: PlantingTreesStore
      productPricingStore: ProductPricingStore
      cartStore: CartStore
      cart: CartDto
      designLaterController: DesignLaterController
      afterPurchaseEditStore: AfterPurchaseEditStore
      ecommerceStore: EcommerceStore
      ecommerceController: EcommerceController
    }
  | {
      available: false
      currencyStore: undefined
      taxStore: undefined
      plantingTreesStore: undefined
      productPricingStore: undefined
      cartStore: undefined
      cart: undefined
      designLaterController: undefined
      ecommerceStore: undefined
      ecommerceController: undefined
      afterPurchaseEditStore: undefined
    }
