export default {
    front: {
        w: "boxX",
        h: "boxY",
    },
    back: {
        w: "boxX",
        h: "boxY",
    },
    x: "width",
    z: "length",
    y: "height",
    availableSides: {
        front: "front",
    },
};
