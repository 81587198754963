import { PackhelpApi } from "../packhelp-api"
import { PricesSane } from "./product-pricing.types"

export interface GetTemplatesRequestOptions {
  region: string
  taxCountryISO?: string
  templateIds?: number[]
}

interface TemplatePricingDto {
  id: string
  attributes: {
    sku: string
    config: {
      id: string
    }
  } & Omit<PricesSane, "range">
  type: "design_asset"
}

interface GetTemplatesRequestResponse {
  data: TemplatePricingDto[]
}

export interface TemplatePricing {
  variantId: number
  cmsId: string
  netPrice?: number
  grossPrice?: number
  taxRate?: string | number
}

function parseGetTemplatesRequestResponse(
  response: GetTemplatesRequestResponse
): TemplatePricing[] {
  return response.data.map((templatePricing) => ({
    variantId: Number(templatePricing.id),
    cmsId: templatePricing.attributes.config.id,
    netPrice: templatePricing.attributes.net_price,
    grossPrice: templatePricing.attributes.gross_price,
    taxRate: templatePricing.attributes.tax_rate,
  }))
}

export class TemplatesApi {
  constructor(private api: PackhelpApi) {}

  public async getTemplates(options: GetTemplatesRequestOptions) {
    const response = await this.api.ax.get<GetTemplatesRequestResponse>(
      `v2/products/design_templates`,
      {
        params: {
          region: options.region,
          tax_country_iso: options.taxCountryISO,
          cms_ids: options.templateIds,
        },
        ...this.api.withAuthToken(),
      }
    )
    return parseGetTemplatesRequestResponse(response.data)
  }
}
