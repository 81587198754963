import React from "react"
import cxBinder from "classnames/bind"
import styles from "./dot-alert.module.scss"
const cx = cxBinder.bind(styles)

export const DotAlert = ({
  size = "normal",
}: {
  size?: "normal" | "large"
}) => {
  return (
    <div
      className={cx("alert", {
        "alert--large": size === "large",
      })}
    />
  )
}
