import ncpProductSpec from "../../../../source/ncpProductSpec/ncpProductSpec";
import NcpConfigBuilder from "../../configBuilders/NcpConfigBuilder";
import addCustomProductGrammagesBehaviors from "../../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductGrammagesBehaviors";
// Global product behaviors composers
import ProductionTimeManager from "../../behaviorsComposers/ProductionTimeManager";
import AssetsManager from "../../behaviorsComposers/AssetsManager";
import WeightManager from "../../behaviorsComposers/WeightManager";
import WizzardManager from "../../behaviorsComposers/WizzardManager";
import VariantsManager from "./behaviorsComposers/NcpVariantManager";
import AddonsManager from "../../behaviorsComposers/AddonsManager";
import GrammagesManager from "../../behaviorsComposers/GrammagesManager";
import TranslationsManager from "./behaviorsComposers/NcpTranslationsManager";
import NcpDimensionsManager from "./behaviorsComposers/NcpDimensionsManager";
import QuantitiesManager from "../../behaviorsComposers/QuantitiesManager";
var CustomProductGrammagesManager = addCustomProductGrammagesBehaviors(GrammagesManager);
var NcpProduct = /** @class */ (function () {
    function NcpProduct(sku, productName, productVariant) {
        this.config = this.builder(ncpProductSpec, sku, productName, productVariant);
        this.assetsManager = new AssetsManager(this);
        this.variantManager = new VariantsManager(this);
        this.productionTimeManager = new ProductionTimeManager(this);
        this.weightsManager = new WeightManager(this);
        this.wizzardManager = new WizzardManager(this);
        this.translationsManager = new TranslationsManager(this);
        this.addonsManager = new AddonsManager(this);
        this.dimensionsManager = new NcpDimensionsManager(this);
        this.quantitiesManager = new QuantitiesManager(this);
        this.grammagesManager = new CustomProductGrammagesManager(this);
    }
    NcpProduct.prototype.builder = function (spec, sku, productName, productVariant) {
        return NcpConfigBuilder(spec, sku, productName, productVariant);
    };
    NcpProduct.prototype.getProductExtraSizesLabels = function (size) {
        var customLabels = {
            5: "".concat(this.weightsManager.getWeight(), "kg"),
        };
        return customLabels[size];
    };
    NcpProduct.prototype.getProductEcoProperties = function (product) {
        return product.config.ecoProperties;
    };
    return NcpProduct;
}());
export default NcpProduct;
