/** @prettier */
import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import RegionConfig, {
  Locale,
  PackhelpRegion,
} from "shared-libs/src/configs/region-config/interfaces/js/region-config"
import { ListingDataPayload } from "../utils/listing-services.types"
import { DefaultItemsPerPage, MinPageNumber } from "../utils/consts"
import { ListingType } from "../../../../shared-views/listings/utils/listing.types"

export type ListingServiceProps = {
  apiUrl: string
  token?: string
}

export abstract class ListingApiAbstract<DataShape> {
  protected readonly ax: AxiosInstance
  protected readonly apiUrl: string
  protected readonly token?: string

  constructor({ apiUrl, token }: ListingServiceProps) {
    this.apiUrl = apiUrl
    this.token = token

    this.ax = this.getAxiosInstance()
  }

  private getAxiosInstance(): AxiosInstance {
    const params: AxiosRequestConfig = {
      baseURL: this.apiUrl,
    }

    if (this.token) {
      params.headers = {
        Authorization: `Bearer ${this.token}`,
      }
    }

    return axios.create(params)
  }

  public abstract getDataForParameters(
    data: ListingDataPayload
  ): Promise<DataShape>

  public getAllData = async (params: {
    locale: Locale
    region: PackhelpRegion
    listingType: ListingType
  }): Promise<DataShape> => {
    const {
      locale = RegionConfig.getCurrentLocaleIso639(),
      region = RegionConfig.getCurrentRegion(),
      listingType,
    } = params
    const requestParams: ListingDataPayload = {
      filters: {},
      category: null,
      languageId: locale,
      page: MinPageNumber,
      itemsPerPage: DefaultItemsPerPage,
      listingType: listingType,
      region: region!,
    }

    return await this.getDataForParameters(requestParams)
  }

  public getAvailableQueryStringParams = (): Promise<string[]> => {
    const region = RegionConfig.getCurrentRegion() as PackhelpRegion
    const locale = RegionConfig.getCurrentLocaleIso639()

    return this.ax
      .get(`/filters?region=${region}&lang=${locale}`)
      .then((response) => response.data.data.map((filter) => filter.slug))
  }
}
