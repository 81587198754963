import {
  PackhelpEditableObject,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import { ScaleLimitCalculator } from "../canvas-object-controller/calculators/scale-limit-calculator"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { ModelEditableSpaces } from "../../../../../../libs/products-render-config/types"

type Dimensions = {
  width: number
  height: number
}

export class ScaleHelper {
  public static scaleObjectToSpace(
    virtualDielineEditor: VirtualDielineEditor,
    object: PackhelpEditableObject,
    originalCanvasDimensions: Dimensions,
    spaceId: ModelEditableSpaces
  ) {
    const spaceBoundaries =
      virtualDielineEditor.dielineNavigator.getSpaceBoundries(spaceId)
    const scaleFactor = this.getScalingRatio(
      originalCanvasDimensions,
      spaceBoundaries
    )

    this.scaleObjectWithLimit(virtualDielineEditor, object, scaleFactor)
  }

  public static scaleObjectWithLimit(
    virtualDielineEditor: VirtualDielineEditor,
    object: PackhelpEditableObject,
    scaleFactor: number
  ) {
    const minScale = this.calcMinScaleLimit(
      virtualDielineEditor,
      object,
      object.originSpaceArea as ModelEditableSpaces
    )

    const scaleX = object.scaleX || 1
    const newScaleX = scaleX * scaleFactor

    if (minScale > newScaleX) {
      object.set({
        scaleX: minScale,
        scaleY: minScale,
      })

      return
    }

    const scaleY = object.scaleY || 1
    const newScaleY = scaleY * scaleFactor

    object.set({
      scaleX: newScaleX,
      scaleY: newScaleY,
    })
  }

  public static calcMinScaleLimit(
    virtualDielineEditor: VirtualDielineEditor,
    object: PackhelpEditableObject,
    spaceId: ModelEditableSpaces
  ) {
    const scaleLimitCalculator = new ScaleLimitCalculator(
      object,
      virtualDielineEditor.productRenderPilot,
      virtualDielineEditor.editContext,
      virtualDielineEditor.dielineNavigator.getVirtualDielineSpace(spaceId)
    )

    return scaleLimitCalculator.calcMin()
  }

  public static getScalingRatio(
    canvasDimensions: Dimensions,
    spaceBoundries: Dimensions
  ): number {
    const PADDING = 0.1

    if (spaceBoundries.width < spaceBoundries.height) {
      return (spaceBoundries.width * (1 - PADDING)) / canvasDimensions.width
    } else {
      return (spaceBoundries.height * (1 - PADDING)) / canvasDimensions.height
    }
  }

  public static isOverscaled(object: PackhelpObject): boolean {
    const { maxWidth, maxHeight } = object

    if (maxWidth && object.getScaledWidth() > maxWidth) {
      return true
    }

    if (maxHeight && object.getScaledHeight() > maxHeight) {
      return true
    }

    return false
  }
}
