import React, { FunctionComponent } from "react"
import cxBinder from "classnames/bind"
import styles from "./Toggle.module.scss"
const cx = cxBinder.bind(styles)

export interface ToggleProps extends React.HTMLProps<HTMLInputElement> {
  disabled?: boolean
  checked: boolean
  onChange?: () => void
  labelItemLeft?: React.ReactNode
  labelItemRight?: React.ReactNode
  e2eTarget?: string
  e2eTargetName?: string
}

const Toggle = (props: ToggleProps) => {
  const { labelItemLeft, labelItemRight, e2eTarget, e2eTargetName, ...other } =
    props
  return (
    <div className={styles.wrapper}>
      {labelItemLeft}
      <div className={styles.action}>
        <input
          type="checkbox"
          className={styles.input}
          e2e-target={e2eTarget}
          e2e-target-name={e2eTargetName}
          {...other}
        />
        <span className={styles.toggler}>
          <span className={styles.toggler__mark} />
        </span>
      </div>
      {labelItemRight}
    </div>
  )
}

export { Toggle, Toggle as default }
