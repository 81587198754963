import React, { FC } from "react"
import cxBinder from "classnames/bind"
import styles from "./PickerFooter.module.scss"

import Link from "dsl/src/atoms/Link/Link"
import { Button, ButtonSizes } from "dsl/src/atoms/Button"
const cx = cxBinder.bind(styles)

export interface PickerFooterProps {
  onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  onDoneClick: () => void
  linkText?: string
  buttonText?: string
  isInline?: boolean
  Icon?: React.ElementType
}

const PickerFooter: FC<PickerFooterProps> = ({
  onLinkClick,
  onDoneClick,
  linkText,
  buttonText,
  isInline,
  Icon,
}) => (
  <div className={cx({ wrapper: true, wrapper_inline: isInline })}>
    {onLinkClick && linkText && (
      <div className={styles.col}>
        <Link href="#" onClick={onLinkClick}>
          {linkText}
        </Link>
      </div>
    )}
    <div className={styles.col}>
      {isInline ? (
        <>
          <Link href="#" onClick={onDoneClick}>
            {buttonText}
            {Icon && <Icon className={styles.icon} />}
          </Link>
        </>
      ) : (
        <Button size={ButtonSizes.small} onClick={onDoneClick}>
          {buttonText}
        </Button>
      )}
    </div>
  </div>
)

export { PickerFooter as default }
