import {
  loaderEventTree,
  eventTree,
  EEWithLoaderEvents,
} from "../editor-events"

export class LoadedStateController {
  private isALoaded: boolean = false
  private isBLoaded: boolean = false
  private isCLoaded: boolean = false

  public isEverythingLoaded: boolean = false

  constructor(private ee: EEWithLoaderEvents) {
    this.subscribeToEditorEvents()
  }

  private subscribeToEditorEvents() {
    this.ee.on(loaderEventTree.fonts.loadingStateChanged, (newState) => {
      this.isALoaded = newState
      this.isBLoaded = newState
      this.changeEditorLoadedState()
    })

    this.ee.on(loaderEventTree.cart.cartReceived, (newState) => {
      if (newState != null) {
        this.isCLoaded = true
      }
      this.changeEditorLoadedState()
    })

    // NOTE: Code is for a demo only
    this.ee.on(eventTree.font.loadingStateChanged, (newState) => {
      this.isBLoaded = newState
    })

    // this.ee.on(eventTree.token.receivedNew, (newState) => {
    //   this.isBLoaded = newState
    // })
  }

  private changeEditorLoadedState() {
    const states = [this.isALoaded, this.isBLoaded, this.isCLoaded]
    this.isEverythingLoaded = states.every((v) => v === true)
  }
}
