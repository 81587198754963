import React, { FC, useEffect, useRef } from "react"
import { PlaceholderOverview } from "../PlaceholderOverview/PlaceholderOverview"

import styles from "./ThreeDimensionalScene.module.scss"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"

type ThreeDimensionalScene = {
  onSceneMount: (mountPoint: HTMLDivElement) => void
}

const ScreenshotRulers = () => {
  return <div className={styles.screenshot_rulers}></div>
}

const ThreeDimensionalScene: FC<ThreeDimensionalScene> = (props) => {
  const threeDimensionalRendererRef = useRef<HTMLDivElement>(null)
  const [toolingContainer] = useContainer().tooling

  useEffect(() => {
    if (threeDimensionalRendererRef && threeDimensionalRendererRef.current) {
      props.onSceneMount(threeDimensionalRendererRef.current)
    }
  }, [])

  const isScreenshotMode = (): boolean => {
    return !!(
      localStorage?.getItem("screenshot_mode") || toolingContainer?.available
    )
  }

  return (
    <div className={styles.three_dimensional_renderer_scene_container}>
      <div
        className={styles.three_dimensional_renderer}
        ref={threeDimensionalRendererRef}
      />
      {isScreenshotMode() && <ScreenshotRulers />}
      <PlaceholderOverview />
    </div>
  )
}

export default ThreeDimensionalScene
