import { useDeveloperMode } from "./useDeveloperMode"

type GlobalKeyboardShorcuts = {
  handleGlobalKeyboardShortcuts: (
    e: React.KeyboardEvent<HTMLElement> | KeyboardEvent
  ) => void
}

export const useGlobalKeyboardShortcuts = (): GlobalKeyboardShorcuts => {
  const {
    enabled: enabledDeveloperMode,
    setEnabled: enableDeveloperMode,
    handleKeyboardShortcut,
  } = useDeveloperMode()

  const handleGlobalKeyboardShortcuts = (
    e: React.KeyboardEvent<HTMLElement> | KeyboardEvent
  ) => {
    const key = e.key.toLowerCase()

    if (enabledDeveloperMode) {
      handleKeyboardShortcut(e)
    }

    if (e.altKey && e.shiftKey && key === "d") {
      enableDeveloperMode(!enabledDeveloperMode)
    }
  }

  return { handleGlobalKeyboardShortcuts }
}
