import {
  AvailableColourModes,
  CameraSettings,
  ColorModeUI,
  ColoursPreset,
  DbyDielineType,
  EditableSpaceConfig,
  EditContext,
  EditorMode,
  FinishUI,
  FullProductDescription,
  MaterialUI,
  ModelContext,
  ModelEditableSpaces,
  ModelNonEditableSpaces,
  PantoneColorsPreset,
  RenderConfig,
  RotationMap,
  SpaceDimensions,
  TextureDefinition,
  UIConfig,
  ViewMode,
  ViewType,
} from "./types"
import { DesignVersion } from "../../modules/design/version"
import { TexturesController } from "./controllers/textures-controller"
import { RenderConfigReader } from "./controllers/render-config-reader"
import { loadProductConfig } from "./helpers"
import { I18N } from "../../ui/i18n"
import { AvailableSize, Product } from "@ph/product-api"
import { AvailableRotations } from "../../render-engine/modules/vd-editor/modules/dieline-navigator/services/translation-calculator"

export abstract class ProductRenderPilot {
  public texturesController: TexturesController | undefined

  public renderConfig: RenderConfig
  public configReader: RenderConfigReader
  public isAfterPurchaseEdit = false

  public abstract setAfterPurchaseEdit(isAfterPurchaseEdit: boolean)

  constructor(
    protected readonly product: Product | undefined,
    public readonly designVersion: DesignVersion | undefined,
    protected readonly editorMode: EditorMode,
    protected renderConfigMap,
    public uiConfig: UIConfig
  ) {
    if (!this.product) {
      this.renderConfig = renderConfigMap
    } else {
      this.renderConfig = loadProductConfig(this.product, renderConfigMap)

      this.texturesController = new TexturesController(
        this.product,
        this.renderConfig
      )
    }

    this.configReader = new RenderConfigReader(this.renderConfig)
  }

  public getModelContextByEditContext(editContext: EditContext): ModelContext {
    const modelContext =
      editContext === EditContext.INSIDE
        ? ModelContext.OPENED
        : ModelContext.CLOSED

    const hasObjModel = this.hasObjModel()

    if (!hasObjModel || this.hasObjModel(modelContext)) {
      return modelContext
    }

    return this.getDefaultModelContext()
  }

  public getAvailableSizes(): AvailableSize[] {
    if (!this.product) {
      return []
    }

    return this.product.variantManager.getAvailableSizesForProduct()
  }

  public getProduct(): Product | undefined {
    return this.product
  }

  public getProductVariantName(): string | undefined {
    return this.product?.variantManager.getVariant()
  }

  public getConfig(): RenderConfig {
    return this.renderConfig
  }

  public isViewModeAvailable(viewMode: ViewMode): boolean {
    return this.getAvailableViewModes().includes(viewMode)
  }

  public getAvailableViewModes(): ViewMode[] {
    return this.configReader.getAvailableViewModes()
  }

  public getColoursPreset(): ColoursPreset {
    return {
      mode: AvailableColourModes.FULL_COLOR,
      colourSettings: {
        blendFactor: 1,
      },
    }
  }

  public getColorMode(): AvailableColourModes {
    return this.getColoursPreset().mode
  }

  public getPantoneColorsPreset(): undefined | PantoneColorsPreset {
    return undefined
  }

  public getRotationMap(editContext: EditContext): RotationMap {
    return {}
  }

  public getVirtualDielineTexture(
    editContext: EditContext,
    isZoomActive: boolean
  ): TextureDefinition | undefined {
    return this.texturesController?.getVirtualDielineTexture(
      editContext,
      isZoomActive
    )
  }

  public getThumbCameraSettings(
    modelContext: ModelContext
  ): CameraSettings | undefined {
    return this.configReader.getThumbCameraSettings(modelContext)
  }

  public getCameraSettings(modelContext: ModelContext) {
    return this.configReader.getCameraSettings(modelContext)
  }

  public getObjModelPath(modelContext: ModelContext): string {
    const modelPath = this.configReader.getObjModelPath(modelContext)

    if (!modelPath) {
      throw new Error(`No model path for ${modelContext}`)
    }

    return modelPath
  }

  public getObjModelTextures(modelContext: ModelContext) {
    return this.texturesController!.getObjModelTextures(modelContext)
  }

  public isModelOpenable(): boolean {
    if (this.hasObjModel()) {
      return [ModelContext.CLOSED, ModelContext.OPENED].every((context) =>
        this.configReader.hasObjModel(context)
      )
    }

    return true
  }

  public getContextEditableSpaces(editContext: EditContext) {
    if (!this.isPrintAvailableFor(editContext)) {
      return []
    }

    return this.configReader.getContextEditableSpaceConfigs(editContext)
  }

  public getDefaultSpace(editContext: EditContext): ModelEditableSpaces {
    return this.configReader.getDefaultSpace(editContext)
  }

  public getDefaultEditContext() {
    return this.configReader.getDefaultEditContext()
  }

  public getDefaultViewType(): ViewType {
    if (this.isDbyOnly()) {
      return ViewType.DBY
    }

    if (this.isViewModeAvailable(ViewMode.THREE_DIMENSIONAL)) {
      return ViewType.MODEL
    }

    if (this.isDielineViewAvailable(this.getDefaultEditContext())) {
      return ViewType.DIELINE
    }

    return ViewType.SPACE
  }

  public isDielineViewAvailable(editContext: EditContext): boolean {
    if (!this.is3DProduct()) {
      return false
    }

    return this.getAvailableSpaces(editContext).length > 1
  }

  public getDefaultView(): {
    viewType: ViewType
    editContext: EditContext
    spaceId: ModelEditableSpaces | null
  } {
    const defaultEditContext = this.getDefaultEditContext()

    return {
      viewType: this.getDefaultViewType(),
      editContext: defaultEditContext,
      spaceId: this.isDbyOnly()
        ? null
        : this.getDefaultSpace(defaultEditContext),
    }
  }

  public getSpaceConfig(
    editContext: EditContext,
    spaceId: ModelEditableSpaces | ModelNonEditableSpaces
  ): EditableSpaceConfig {
    return this.configReader.getSpaceConfig(editContext, spaceId)
  }

  public getAvailableEditContexts() {
    return this.configReader.getAvailableEditContexts()
  }

  public getAvailableSpaces(editContext: EditContext): ModelEditableSpaces[] {
    if (editContext === EditContext.FRONT) {
      return [ModelEditableSpaces.DEFAULT]
    }

    return []
  }

  public getPrintModeSummary(): string {
    const availableEditContexts = this.getAvailableEditContexts()

    if (availableEditContexts.length <= 1) {
      return ""
    }

    const isDoubleSidedPrintActive =
      this.isPrintActiveFor(EditContext.INSIDE) ||
      this.isPrintActiveFor(EditContext.BACK)
    const is3DProduct = this.is3DProduct()

    if (isDoubleSidedPrintActive) {
      if (is3DProduct) {
        return I18N.product.printMode.insideOutside
      }

      return I18N.product.printMode.frontBack
    }

    if (is3DProduct) {
      return I18N.product.printMode.outside
    }

    return I18N.product.printMode.front
  }

  public getFullProductDescription(): FullProductDescription {
    const codeName = this.product?.variantManager.getCodeName()
    const dimensions =
      this.product?.dimensionsManager.getOutsideSizeDimensionFormatted()
    const size = codeName ? `${codeName} - ${dimensions}` : dimensions
    const material = this.product?.variantManager.getMaterial()
    const finish = this.product?.variantManager.getFinish()
    const variantNameKey =
      this.product?.translationsManager.getModelVariantNameIntl()
    const printModeSummaryKey = this.getPrintModeSummary()

    return {
      codeName,
      size,
      variantNameKey,
      printModeSummaryKey,
      materialKey: `product.material.${material}.name`,
      finishKey: !["none", "foilNone"].includes(finish)
        ? `product.finish.${finish}.name`
        : "",
    }
  }

  public getSceneBackgroundTextureUrl(editContext: EditContext) {
    return this.texturesController!.getSceneBackgroundTextureUrl(editContext)
  }

  public getProductVariants(): ColorModeUI[] {
    return this.product?.addonsManager.getAvailableColorModes()
  }

  public getEditorMode(): EditorMode {
    return this.editorMode
  }

  public isDbyMode(): boolean {
    return this.editorMode === "dby"
  }

  public getBackgroundPantoneColorsPreset(): undefined | PantoneColorsPreset {
    return undefined
  }

  public getContextSpaces(editContext: EditContext) {
    return this.configReader.getContextSpaceConfigs(editContext)
  }

  public isBackgroundAssetToggleAvailableForSpace(
    editContext: EditContext
  ): boolean {
    return this.configReader.getContextEditableSpaceIds(editContext).length > 1
  }

  public isPrintActiveFor(editContext: EditContext) {
    if (editContext === EditContext.FRONT) {
      return true
    }

    return false
  }

  public isPrintAvailableFor(editContext: EditContext) {
    return [EditContext.FRONT, EditContext.BACK].includes(editContext)
  }

  public isPrintAdditionallyPaidFor(editContext: EditContext) {
    return editContext === EditContext.BACK
  }

  public is3DProduct(): boolean {
    return this.product.dimensionsManager.is3D()
  }

  public hasObjModel(modelContext?: ModelContext): boolean {
    const modelViewAvailable = this.renderConfig.availableViewModes.includes(
      ViewMode.THREE_DIMENSIONAL
    )
    const modelAvailable = this.configReader.hasObjModel(
      modelContext || this.getDefaultModelContext()
    )

    return modelViewAvailable && modelAvailable
  }

  public getDefaultModelContext(): ModelContext {
    return ModelContext.CLOSED
  }

  public getAvailableColorModes(): ColorModeUI[] {
    return this.product?.addonsManager.getAvailableColorModes()
  }

  public getAvailableFinishes(): FinishUI[] {
    return this.product?.addonsManager
      .getAvailableConfigurablePropertiesGroupedByType()
      .find((group) => group.type === "finishes")
  }

  public getAvailableMaterials(): MaterialUI[] {
    return this.product?.addonsManager
      .getAvailableConfigurablePropertiesGroupedByType()
      .find((group) => group.type === "materials")
  }

  public getSpaceDimensions(
    editContext: EditContext,
    spaceId: ModelEditableSpaces
  ): SpaceDimensions {
    return this.product?.renderConfigManager.calculateSpaceSize(
      spaceId === ModelEditableSpaces.DEFAULT ? editContext : spaceId
    )
  }

  public isEditContextDisabled(editContext: EditContext): boolean {
    return (
      !this.isPrintAvailableFor(editContext) ||
      !this.isPrintActiveFor(editContext)
    )
  }

  public getPredefinedVirtualDielineUrl(
    editContext: EditContext
  ): string | undefined {
    return this.renderConfig.virtualDielinesPaths?.[editContext]
  }

  public getPredefinedDbyDielineUrl(
    type: DbyDielineType,
    region = "eu"
  ): string | undefined {
    return this.product.variantManager.getDielineUrl(type, region)
  }

  public isDbyOnly(): boolean {
    return false
  }

  protected get sku(): string | undefined {
    return this.product?.variantManager.getSku()
  }

  public isFoldableProduct2D(): boolean {
    return (
      !this.is3DProduct() &&
      this.getAvailableEditContexts().includes(EditContext.OUTSIDE)
    )
  }

  public getDielineRotation(_editContext: EditContext): AvailableRotations {
    return AvailableRotations.none
  }
}
