import React, { PropsWithChildren } from "react"

import styles from "./EditorMainTopBar.module.scss"

export const EditorMainTopBar = (props: PropsWithChildren<{}>) => (
  <header className={styles.editor_header} e2e-target="editor-order-flow-bar">
    {props.children}
  </header>
)

export default EditorMainTopBar
