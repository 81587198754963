import type { DesignAndProductDriverContainer } from "./design-and-product-driver-container"
import type { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap-container"
import type { ApiSessionContainer } from "./api-session-container"
import type { EcommerceMaybeContainer } from "./ecommerce-maybe-container"
import type { UtilEnvContainer } from "./util-container"
import type { PatternsStore } from "../stores/patterns-store/patterns.store"
import type { PatternController } from "../stores/_controllers/patterns-controller"

async function importDeps() {
  const { PatternService } = await import(
    "../stores/patterns-store/pattern-service"
  )
  const { PatternsStore } = await import(
    "../stores/patterns-store/patterns.store"
  )
  const { PatternController } = await import(
    "../stores/_controllers/patterns-controller"
  )
  const { PatternManager } = await import("../services/pattern-manager")
  const { DesignUrlManipulator } = await import(
    "../services/design-url-manipulator"
  )

  return {
    PatternService,
    PatternManager,
    PatternsStore,
    PatternController,
    DesignUrlManipulator,
  }
}

export async function providePatternMaybeContainer(
  rootBootstrapper: BootstrapClassicRootStoreContainer,
  designAndProduct: DesignAndProductDriverContainer,
  envUtil: UtilEnvContainer,
  apiSession: ApiSessionContainer,
  ecommerce: EcommerceMaybeContainer
): Promise<PatternMaybeContainer> {
  const { productRenderPilot } = rootBootstrapper
  const { appConfig } = envUtil

  if (
    !ecommerce.available ||
    !appConfig.api.ecommerce.features.ecommerce.patterns ||
    !productRenderPilot.uiConfig.features.patterns
  ) {
    return {
      available: false,
    }
  }

  const { productDriver, productDesignStore } = designAndProduct
  const { apiService, directusApiService } = apiSession
  const { taxStore } = ecommerce
  const { ee, uri } = envUtil

  const {
    PatternService,
    PatternManager,
    PatternsStore,
    PatternController,
    DesignUrlManipulator,
  } = await importDeps()

  const patternService = new PatternService(directusApiService)
  const directusPatterns = await patternService.loadPatterns()

  const patternManager = new PatternManager(
    apiService,
    taxStore,
    directusPatterns,
    appConfig.locale.productRegion
  )

  const patternsStore = new PatternsStore(
    productDriver,
    taxStore,
    patternManager,
    directusPatterns
  )

  patternsStore.init(productRenderPilot.getColoursPreset())

  const patternController = new PatternController(
    patternsStore,
    productDriver,
    productDesignStore,
    uri,
    ee
  )

  new DesignUrlManipulator(productDriver)

  return {
    available: true,
    patternsStore: patternsStore,
    patternController: patternController,
  }
}

export type PatternMaybeContainer =
  | {
      available: true
      patternsStore: PatternsStore
      patternController: PatternController
    }
  | {
      available: false
    }
