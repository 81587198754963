import React, { createRef, useEffect } from "react"
import { ReactComponent as IconChecked } from "../../../assets/svg/check.svg"

import cxBinder from "classnames/bind"
import styles from "./EditorTools.module.scss"
const cx = cxBinder.bind(styles)

export const FontFamilyPreview = ({
  label,
  onClick,
  checked,
  highlighted,
  innerRef,
  onMouseEnter,
  onHighlight,
}: {
  label: string
  onClick: () => void
  checked: boolean
  highlighted: boolean
  innerRef?: React.RefObject<HTMLDivElement>
  onMouseEnter?: () => void
  onHighlight?: (domElement: HTMLElement) => void
}) => {
  const ref = innerRef || createRef()

  useEffect(() => {
    if (highlighted && !!ref.current && !!onHighlight) {
      onHighlight(ref.current)
    }
  }, [highlighted])

  return (
    <div
      ref={ref}
      className={cx({ font_family_preview: true, highlighted })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <span>{label}</span>
      {checked && <IconChecked className={styles.check_icon} />}
    </div>
  )
}
