import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import Notification, {
  NotificationTypes,
} from "dsl/src/molecules/Notification/Notification"
import { observer } from "mobx-react-lite"
import React, { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { FormattedMessage } from "react-intl"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { DesignAndProductDriverContainer } from "../../../../../_containers/design-and-product-driver-container"
import { LogoContainer } from "../../../../../_containers/logo-container"
import { getTranslationKeyForAssetError } from "../../../../../services/asset-service/asset-errors"
import { ImageAssetItemStore } from "../../../../../stores/assets-store/image-asset-item.store"
import { NotificationStore } from "../../../../../stores/notification-store/notification.store"
import { useTranslate } from "../../../../hooks/useTranslate"
import { CardTool } from "../../../atoms/CardTool/CardTool"
import { ImageUpload } from "../../Upload/ImageUpload"
import { UploadDropZone } from "../../Upload/UploadDropzone"
import { UploadFailedFilePreview } from "../../Upload/UploadFailedFilePreview"
import { AssetsUploaderFileSpec } from "../../Upload/UploadFiletypesInfo"
import { EditorToolLogoPlaceholderSwitch } from "./EditorToolLogoPlaceholderSwitch"
import styles from "./EditorToolLogoUploader.module.scss"

const LogoUploaderHeader = () => {
  return (
    <Typography type={TType.Header17_500} className={styles.header}>
      <FormattedMessage id="editor-tools.personalize.upload-logo" />
    </Typography>
  )
}

const LogoUploaderActions = ({ handleUploadFile, handleRemoveFile }) => {
  return (
    <nav className={styles.personalize_nav}>
      <button className={styles.personalize_nav_btn} onClick={handleUploadFile}>
        <FormattedMessage id="editor-tools.personalize.upload-button" />
      </button>
      <button className={styles.personalize_nav_btn} onClick={handleRemoveFile}>
        <FormattedMessage id="editor-tools.personalize.remove-button" />
      </button>
    </nav>
  )
}

const EditorToolLogoUploader = ({
  isSetAsLogo,
  onImageSelected,
  onDrop,
  handleRemoveFile,
  logo,
  shouldRenderReuploadNotification,
  onNotificationClose,
  isLogoPlaceholdersEnabled,
  onIsLogoPlaceholdersEnabledChange,
  isLogoPlaceholdersAvailable,
}) => {
  const t = useTranslate()
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDrop,
    noClick: !!logo,
    noDrag: !!logo,
    multiple: false,
  })

  return (
    <div className={styles.wrapper}>
      <CardTool>
        <LogoUploaderHeader />
        <UploadDropZone inputProps={getInputProps()} rootProps={getRootProps()}>
          <ImageUpload
            isSetAsLogo={isSetAsLogo}
            onSelectImage={onImageSelected}
            image={logo}
            onRetry={handleRemoveFile}
            onCancel={handleRemoveFile}
          />
        </UploadDropZone>
        {isSetAsLogo && (
          <LogoUploaderActions
            handleUploadFile={open}
            handleRemoveFile={handleRemoveFile}
          />
        )}
        <AssetsUploaderFileSpec />
        {logo && logo.error && (
          <UploadFailedFilePreview
            name={logo.name}
            error={t(getTranslationKeyForAssetError(logo.error))}
            onRemove={() => {
              handleRemoveFile()
            }}
          />
        )}
        {isLogoPlaceholdersAvailable && (
          <EditorToolLogoPlaceholderSwitch
            isChecked={isLogoPlaceholdersEnabled}
            onChange={onIsLogoPlaceholdersEnabledChange}
          />
        )}
      </CardTool>
      {shouldRenderReuploadNotification && (
        <div className={styles.notification}>
          <Notification
            type={NotificationTypes.Warning}
            icon
            onIconClick={onNotificationClose}
          >
            <span className={styles.notification_content}>
              <span className={styles.notification_title}>
                {t("component.editor-tool.assets-uploader.reupload.title")}
              </span>
              {t("component.editor-tool.assets-uploader.reupload.subtitle")}
            </span>
          </Notification>
        </div>
      )}
    </div>
  )
}

export const EditorToolLogoUploaderWrapper = observer(() => {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.logo,
    c.envUtil,
  ])

  if (!containerSet) {
    return null
  }

  const {
    designAndProductDriver,
    logo,
    envUtil: { notificationStore },
  } = containerSet

  return (
    <EditorToolLogoUploaderWired
      designAndProductDriver={designAndProductDriver}
      logo={logo}
      notificationStore={notificationStore}
    />
  )
})

export const EditorToolLogoUploaderWired = observer(
  ({
    logo,
    designAndProductDriver,
    notificationStore,
  }: {
    logo: LogoContainer
    designAndProductDriver: DesignAndProductDriverContainer
    notificationStore: NotificationStore
  }) => {
    const [isUploadingPending, setIsUploadingPending] = useState(false)
    const { logoStore, logoUiController } = logo
    const { productDriver, assetStore, contextController } =
      designAndProductDriver

    const isRendererLoading = productDriver.state.isRendererLoading
    const logoAsset = assetStore.logo
    const hasTransformed = logoAsset ? logoAsset.hasTransformed : false
    const hasUploaded = logoAsset ? logoAsset.hasUploaded : false
    const isSettingLogo = assetStore.isSettingLogo
    const isSetAsLogo = assetStore.isSetAsLogo
    const shouldRenderReuploadNotification =
      notificationStore.uniqueNotificationStates.reupload && !logoAsset

    const onReuploadNotificationClose = () => {
      notificationStore.toggleUniqueNotification({
        name: "reupload",
        isVisible: false,
      })
    }

    useEffect(() => {
      logoAsset && onReuploadNotificationClose()
    }, [logoAsset])

    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        acceptedFiles.forEach(async (file) => {
          setIsUploadingPending(true)
          await assetStore.addLogo(file as any)
        })
      },
      [assetStore]
    )

    useEffect(() => {
      if (isUploadingPending && isSetAsLogo && assetStore.logo) {
        if (!logoStore.isLogoPlaceholdersPresent) {
          onImageSelected(assetStore.logo)
        }
        setIsUploadingPending(false)
      }
    }, [isSetAsLogo])

    const onChangeLogoPlaceholdersEnabled = (isEnabled: boolean) => {
      if (isEnabled) {
        logoUiController.enableLogo()
      } else {
        logoUiController.disableLogo()
      }
    }

    const handleRemoveLogo = () => {
      if (logoAsset && (logoAsset.error || isSettingLogo)) {
        assetStore.removeAsset(logoAsset)
      } else {
        assetStore.removeAssetAsLogo()
      }
    }

    const onImageSelected = async (imageAssetStore: ImageAssetItemStore) => {
      if (isRendererLoading) {
        return
      }

      if (contextController.isModelView()) {
        contextController.closeModel()
      }

      await productDriver.addImage(imageAssetStore.getImageAsset())
    }

    return (
      <EditorToolLogoUploader
        onNotificationClose={onReuploadNotificationClose}
        shouldRenderReuploadNotification={shouldRenderReuploadNotification}
        logo={logoAsset}
        onDrop={onDrop}
        isSetAsLogo={
          hasTransformed &&
          hasUploaded &&
          !isSettingLogo &&
          logoAsset &&
          !logoAsset.error
        }
        onImageSelected={onImageSelected}
        handleRemoveFile={handleRemoveLogo}
        onIsLogoPlaceholdersEnabledChange={onChangeLogoPlaceholdersEnabled}
        isLogoPlaceholdersEnabled={logoStore.isLogoPlaceholdersEnabled}
        isLogoPlaceholdersAvailable={logoStore.isLogoPlaceholdersAvailable}
      />
    )
  }
)
