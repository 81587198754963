import { v4 as uuidv4 } from "uuid"

import { SpaceClippingHelper } from "../helpers/space-clipping-helper"
import { ClipPathModes } from "../../../services/clip-path-generator"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { PackhelpEditableLogoPlaceholderSlot } from "../../../object-extensions/packhelp-objects"
import { ObjectController } from "./object-controller"
import { EditableObjectTypes } from "../../../../../../modules/ph-api/asset-types"
import { MaskController } from "../mask-controller/mask-controller"
import { LogoPlaceholderSlotCreator } from "../canvas-object-creator/logo-placeholder-slot-creator/logo-placeholder-slot-creator"
import { ModelEditableSpaces } from "../../../../../../libs/products-render-config/types"
import { ee, eventTree } from "../../../../../../stores/editor-events"
import { DuplicationOptions } from "./types"

export class LogoPlaceholderSlotObjectController extends ObjectController {
  constructor(
    protected readonly canvasObject: PackhelpEditableLogoPlaceholderSlot,
    protected readonly virtualDielineEditor: VirtualDielineEditor
  ) {
    super(canvasObject, virtualDielineEditor)

    if (
      this.canvasObject.assetType !==
      EditableObjectTypes.assetLogoPlaceholderSlot
    ) {
      throw new Error("Type not supported")
    }
  }

  public async setSpaceClipping() {
    await SpaceClippingHelper.setSpaceClipping(
      this.virtualDielineEditor,
      this.canvasObject.originSpaceArea,
      this.canvasObject,
      ClipPathModes.FillMode
    )
  }

  public plusClicked() {
    ee.emit(eventTree.logo.plusClicked)
  }

  public attachEventListeners() {
    this.canvasObject.attachEventListeners({
      onPlusClicked: () => {
        const controller = new LogoPlaceholderSlotObjectController(
          this.canvasObject,
          this.virtualDielineEditor
        )
        controller.plusClicked()
      },
      onTrashClicked: () => {
        const controller = new LogoPlaceholderSlotObjectController(
          this.canvasObject,
          this.virtualDielineEditor
        )
        controller.remove()
      },
    })
  }

  public async duplicate(
    options: DuplicationOptions = {
      withRender: true,
    }
  ): Promise<PackhelpEditableLogoPlaceholderSlot> {
    const creator = new LogoPlaceholderSlotCreator(this.virtualDielineEditor)
    const duplicatedObject = await creator.create(
      options.newSpaceId ||
        (this.canvasObject.originSpaceArea as ModelEditableSpaces)
    )
    duplicatedObject.set({
      id: uuidv4(),
      enabled: this.canvasObject.enabled,
      width: this.canvasObject.width,
      height: this.canvasObject.height,
      top: this.canvasObject.top,
      left: this.canvasObject.left,
      scaleX: this.canvasObject.scaleX,
      scaleY: this.canvasObject.scaleY,
      angle: this.canvasObject.angle,
    })

    if (this.canvasObject.maskController) {
      const maskController = new MaskController(
        duplicatedObject,
        this.virtualDielineEditor
      )
      await maskController.init(this.canvasObject.maskController.getConfig())

      if (!options.withRender) {
        maskController.clear()
      }
    }

    if (options.withRender) {
      this.virtualDielineEditor.addOnCanvas(duplicatedObject)
    }

    return duplicatedObject
  }

  public isColorModificationAvailable(): boolean {
    return false
  }

  public isGroupingAvailable(): boolean {
    return false
  }
}
