import { PantonePickerColorObject } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import _PANTONE_COLORS_MAP from "dsl/src/organisms/PickerPantone/PantoneColorsMap"
import _uniqBy from "lodash/uniqBy"
import { PantoneColorsPreset } from "../../libs/products-render-config/types"
import Colour from "../../libs/value-objects/colour"
import {
  PANTONE_COLORS_DB_METALLIC,
  PANTONE_COLORS_DB_TISSUE_BACKGROUND,
  PANTONE_COLORS_REPLICATOR_PATTERN,
} from "./pantone-custom-palettes"
import _uniq from "lodash/uniq"

/** standard supported colors, i.e. regular colors without fancy Pantone like gold, silver, neon etc. */
export const PANTONE_COLORS_DB_STANDARD = _uniqBy(
  _PANTONE_COLORS_MAP,
  (color) => color.pantoneName
)

/** all Pantone colors ever, standard set + metallic */
export const PANTONE_COLORS_DB_ALL = _uniq([
  ...PANTONE_COLORS_DB_STANDARD,
  ...PANTONE_COLORS_DB_METALLIC,
  ...PANTONE_COLORS_DB_TISSUE_BACKGROUND,
  ...PANTONE_COLORS_REPLICATOR_PATTERN,
])

export const PANTONE_COLORS_DB_WHITE = PANTONE_COLORS_DB_STANDARD.find(
  (value) => value.pantoneName === "White"
)

if (PANTONE_COLORS_DB_WHITE === undefined)
  throw new Error("White Pantone Color not found")

/** list of Pantone colors that are able to cover black foil */
export const PANTONE_COLORS_DB_INK_COVERING_BLACK_FOIL = [
  PANTONE_COLORS_DB_WHITE,
  ...PANTONE_COLORS_DB_METALLIC,
]

/** tissue paper available colors */
export const PANTONE_COLORS_DB_TISSUE_BACKGROUND_ALL = [
  PANTONE_COLORS_DB_WHITE,
  ...PANTONE_COLORS_DB_TISSUE_BACKGROUND,
]

export class PantoneColorsDatabase {
  public getByPreset(preset: PantoneColorsPreset): PantonePickerColorObject[] {
    switch (preset) {
      case PantoneColorsPreset.ALL:
        return PANTONE_COLORS_DB_ALL
      case PantoneColorsPreset.STANDARD:
        return PANTONE_COLORS_DB_STANDARD
      case PantoneColorsPreset.METALLIC:
        return PANTONE_COLORS_DB_METALLIC
      case PantoneColorsPreset.COVERING_BLACK_FOIL:
        return PANTONE_COLORS_DB_INK_COVERING_BLACK_FOIL
      case PantoneColorsPreset.TISSUE_BACKGROUND:
        return PANTONE_COLORS_DB_TISSUE_BACKGROUND_ALL
      case PantoneColorsPreset.PATTERN_REPLICATOR_PALETTE:
        return PANTONE_COLORS_REPLICATOR_PATTERN
    }
  }

  private getSearchList = (preset?: PantoneColorsPreset) =>
    preset !== undefined ? this.getByPreset(preset) : PANTONE_COLORS_DB_ALL

  public getByPantoneName = (
    name: string,
    searchInPreset?: PantoneColorsPreset
  ): PantonePickerColorObject | undefined =>
    this.getSearchList(searchInPreset).find(
      (pantoneColor) => pantoneColor.pantoneName === name
    )

  public getByHex = (
    hex: string,
    searchInPreset?: PantoneColorsPreset
  ): PantonePickerColorObject | undefined =>
    this.getSearchList(searchInPreset).find(
      (pantoneColor) => pantoneColor.color === hex
    )

  public getByColour = (
    colour: Colour,
    searchInPreset?: PantoneColorsPreset
  ): PantonePickerColorObject | undefined => {
    let pantone: PantonePickerColorObject | undefined = undefined
    const name = colour.getPantoneName()
    if (name) pantone = this.getByPantoneName(name, searchInPreset)
    return pantone || this.getByHex(colour.getHex(), searchInPreset)
  }
}
