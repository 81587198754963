var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import BOX_CONSTS from "../consts/boxConsts";
var MAILER_BOX_SIZES_FULL_RANGE = [
    23, 33, 44, 45, 46, 52, 56, 59, 62, 65, 71, 79, 93, 112,
];
export default (_a = {},
    _a[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR] = {
        sizes: [52, 71],
        defaultSize: 52,
        prefix: "F",
        productCategory: BOX_CONSTS.MAILER_BOX,
        addonsInVariants: [],
        variants: (_b = {},
            _b[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_NATURAL_CARDBOARD_WHITE_INSIDE],
                colorModes: [BOX_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [
                    BOX_CONSTS.PRINT_FINISH_FOIL_GLOSSY,
                    BOX_CONSTS.PRINT_FINISH_FOIL_MATTE,
                ],
            },
            _b),
    },
    _a[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO] = {
        sizes: [52, 71],
        defaultSize: 52,
        prefix: "F",
        productCategory: BOX_CONSTS.MAILER_BOX,
        addonsInVariants: [],
        variants: (_c = {},
            _c[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_NATURAL_CARDBOARD],
                colorModes: [BOX_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
            },
            _c),
    },
    _a[BOX_CONSTS.MAILER_BOX] = {
        sizes: [23, 33, 44, 45, 52, 56, 59, 62, 71, 79, 93, 112],
        defaultSize: 52,
        prefix: "F",
        productCategory: BOX_CONSTS.MAILER_BOX,
        addonsInVariants: [
            BOX_CONSTS.PRINT_FINISH_FOIL_GLOSSY,
            BOX_CONSTS.PRINT_FINISH_FOIL_MATTE,
            BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD_WHITE_INSIDE,
            BOX_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE,
        ],
        variants: (_d = {},
            _d[BOX_CONSTS.MAILER_BOX_ECO_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_NATURAL_CARDBOARD],
                colorModes: [
                    BOX_CONSTS.PRINT_MODE_MONOCHROME,
                    BOX_CONSTS.PRINT_MODE_MONOCHROME_WITH_PRINT_INSIDE,
                ],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
                extraSizes: [46, 65],
                fscCertificate: true,
                newPrice: true,
            },
            _d[BOX_CONSTS.MAILER_BOX_ECO_WHITE_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_WHITE_ONE_SIDE_CARDBOARD],
                colorModes: [
                    BOX_CONSTS.PRINT_MODE_MONOCHROME,
                    BOX_CONSTS.PRINT_MODE_MONOCHROME_WITH_PRINT_INSIDE,
                ],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
                extraSizes: [46, 65],
                fscCertificate: true,
                newPrice: true,
            },
            _d[BOX_CONSTS.MAILER_BOX_ECO_COLOR_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_WHITE_ONE_SIDE_CARDBOARD],
                colorModes: [
                    BOX_CONSTS.PRINT_MODE_COLOR_ECO,
                    BOX_CONSTS.PRINT_MODE_COLOR_ECO_WITH_PRINT_INSIDE,
                ],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
                extraSizes: [46, 65],
                defaultSize: 44,
                fscCertificate: true,
                newPrice: true,
            },
            _d[BOX_CONSTS.MAILER_BOX_OLD_COLOR_BUDGET_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD],
                colorModes: [BOX_CONSTS.PRINT_MODE_COLOR_BUDGET],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
            },
            _d[BOX_CONSTS.MAILER_BOX_FULL_COLOR_VARIANT] = {
                materials: [
                    BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD,
                    BOX_CONSTS.PRINT_MATERIAL_COATED_CARDBOARD_WHITE_INSIDE,
                ],
                colorModes: [BOX_CONSTS.PRINT_MODE_COLOR_HD],
                finishes: [
                    BOX_CONSTS.PRINT_FINISH_NONE,
                    BOX_CONSTS.PRINT_FINISH_FOIL_GLOSSY,
                    BOX_CONSTS.PRINT_FINISH_FOIL_MATTE,
                ],
                defaultSize: 62,
                newPrice: true,
            },
            _d[BOX_CONSTS.MAILER_BOX_LITE_COLOR_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_WHITE_ONE_SIDE_NATURAL_CARDBOARD],
                colorModes: [
                    BOX_CONSTS.PRINT_MODE_COLOR_ECO,
                    BOX_CONSTS.PRINT_MODE_COLOR_ECO_WITH_PRINT_INSIDE,
                ],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
                extraSizes: [46, 65],
                fscCertificate: false,
                newPrice: true,
            },
            _d),
        siblings: (_e = {},
            _e[BOX_CONSTS.MAILER_BOX_ECO_AND_ECO_WHITE_GROUP] = [
                BOX_CONSTS.MAILER_BOX_ECO_VARIANT,
                BOX_CONSTS.MAILER_BOX_ECO_WHITE_VARIANT,
            ],
            _e[BOX_CONSTS.MAILER_BOX_ECO_COLOR_AND_FULL_COLOR_GROUP] = [
                BOX_CONSTS.MAILER_BOX_ECO_COLOR_VARIANT,
                BOX_CONSTS.MAILER_BOX_FULL_COLOR_VARIANT,
            ],
            _e),
    },
    _a[BOX_CONSTS.MAILER_BOX_WHITE_ON_KRAFT_VARIANT] = {
        sizes: MAILER_BOX_SIZES_FULL_RANGE,
        defaultSize: 52,
        prefix: "F",
        productCategory: BOX_CONSTS.MAILER_BOX,
        addonsInVariants: [],
        variants: (_f = {},
            _f[BOX_CONSTS.MAILER_BOX_WHITE_ON_KRAFT_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_NATURAL_CARDBOARD],
                colorModes: [
                    BOX_CONSTS.PRINT_MODE_MONOCHROME_WHITE,
                    BOX_CONSTS.PRINT_MODE_MONOCHROME_WHITE_WITH_PRINT_INSIDE,
                ],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
                fscCertificate: true,
            },
            _f),
    },
    _a[BOX_CONSTS.MAILER_BOX_PLAIN] = {
        sizes: MAILER_BOX_SIZES_FULL_RANGE,
        defaultSize: 52,
        prefix: "F",
        productCategory: BOX_CONSTS.MAILER_BOX,
        addonsInVariants: [],
        variants: (_g = {},
            _g[BOX_CONSTS.MAILER_BOX_PLAIN_DEFAULT_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_NATURAL_CARDBOARD],
                colorModes: [BOX_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
                newPrice: true,
            },
            _g[BOX_CONSTS.MAILER_BOX_PLAIN_WHITE_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_WHITE_ONE_SIDE_CARDBOARD],
                colorModes: [BOX_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [BOX_CONSTS.PRINT_FINISH_NONE],
                newPrice: true,
            },
            _g),
    },
    _a[BOX_CONSTS.SLEEVED_MAILER_BOX] = {
        sizes: [23, 33, 44, 45, 46, 52, 56, 59, 62, 65, 71, 79],
        defaultSize: 52,
        prefix: "FS",
        productCategory: BOX_CONSTS.SLEEVED_MAILER_BOX,
        addonsInVariants: [],
        variants: (_h = {},
            _h[BOX_CONSTS.SLEEVED_MAILER_BOX_VARIANT] = {
                materials: [
                    BOX_CONSTS.PRINT_MATERIAL_NATURAL_CARDBOARD,
                    BOX_CONSTS.PRINT_MATERIAL_WHITE_ONE_SIDE_CARDBOARD,
                ],
                colorModes: [BOX_CONSTS.PRINT_COLOR_MODE_INDIGO_COLOR],
                finishes: [
                    BOX_CONSTS.PRINT_FINISH_SLEEVE_WHITE,
                    BOX_CONSTS.PRINT_FINISH_SLEEVE_KRAFT,
                ],
            },
            _h),
    },
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX] = {
        sizes: [55, 73, 80, 91],
        defaultSize: 73,
        prefix: "AS",
        productCategory: BOX_CONSTS.DELIVERY_MAILER_BOX,
        addonsInVariants: [
            BOX_CONSTS.PRINT_FINISH_DOUBLE_STRIP,
            BOX_CONSTS.PRINT_FINISH_SINGLE_STRIP,
        ],
        variants: (_j = {},
            _j[BOX_CONSTS.DELIVERY_MAILER_BOX_VARIANT] = {
                materials: [
                    BOX_CONSTS.PRINT_MATERIAL_WHITE_CARDBOARD,
                    BOX_CONSTS.PRINT_MATERIAL_KRAFT,
                ],
                colorModes: [BOX_CONSTS.PRINT_MODE_MONOCHROME],
                finishes: [
                    BOX_CONSTS.PRINT_FINISH_DOUBLE_STRIP,
                    BOX_CONSTS.PRINT_FINISH_SINGLE_STRIP,
                ],
                fscCertificate: true,
                newPrice: true,
            },
            _j),
    },
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN] = {
        sizes: [55, 73, 80, 91],
        defaultSize: 73,
        prefix: "AS",
        productCategory: BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN,
        addonsInVariants: [
            BOX_CONSTS.PRINT_FINISH_DOUBLE_STRIP,
            BOX_CONSTS.PRINT_FINISH_SINGLE_STRIP,
        ],
        variants: (_k = {},
            _k[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_KRAFT],
                colorModes: [BOX_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [
                    BOX_CONSTS.PRINT_FINISH_DOUBLE_STRIP,
                    BOX_CONSTS.PRINT_FINISH_SINGLE_STRIP,
                ],
                newPrice: true,
            },
            _k[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN_WHITE_VARIANT] = {
                materials: [BOX_CONSTS.PRINT_MATERIAL_WHITE_CARDBOARD],
                colorModes: [BOX_CONSTS.PRINT_COLOR_MODE_NO_PRINT],
                finishes: [
                    BOX_CONSTS.PRINT_FINISH_DOUBLE_STRIP,
                    BOX_CONSTS.PRINT_FINISH_SINGLE_STRIP,
                ],
                newPrice: true,
            },
            _k),
    },
    _a);
