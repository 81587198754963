var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addProductionTimeBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.isHighQuantityProduction = function (quantity) {
            var highQuantityProductionTimes = this.product.config.productionTimes.productionTime.highQuantity;
            if (!highQuantityProductionTimes) {
                return false;
            }
            var productionQuantity = Math.min.apply(Math, Object.keys(highQuantityProductionTimes).map(function (key) { return parseInt(key); }));
            return quantity > productionQuantity;
        };
        class_1.prototype.getProductionTimes = function () {
            return this.product.config.productionTimes.productionTime;
        };
        class_1.prototype.getProductionTime = function (isExpressDelivery) {
            var productionTime = this.getProductionTimes();
            if (isExpressDelivery && !productionTime.express) {
                throw new Error("Express production is not available!");
            }
            return isExpressDelivery ? productionTime.express : productionTime.default;
        };
        return class_1;
    }(superclass));
};
export default addProductionTimeBehaviors;
