import React, { useRef, FC, useEffect } from "react"
import cxBinder from "classnames/bind"
import { FloatingToolbarWired } from "../toolbars/FloatingToolbar/FloatingToolbar"
import { GlueStripCursorTooltip } from "../toolbars/GlueStripCursorTooltip"
import EditZoneRulers from "../Rulers/EditZoneRulers"
import ObjectRulers from "../Rulers/ObjectRulers"
import {
  EditContext,
  ModelEditableSpaces,
} from "../../../../libs/products-render-config/types"
import { observer } from "mobx-react-lite"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { useRootStore } from "../../../hooks/useRootStore"
import { useTranslate } from "../../../hooks/useTranslate"
import { NoPrintZoneTooltip } from "../tools/NoPrintZoneTooltip/NoPrintZoneTooltip"
import styles from "./TwoDimensionalScene.module.scss"

const cx = cxBinder.bind(styles)

type TwoDimensionalSceneProps = {
  onSceneMount: (mountPoints) => void
  availableEditContexts: EditContext[]
  activeContextId: EditContext
  activeSpaceId: ModelEditableSpaces | null
  isModelView: boolean
}

type TwoDimensionalSceneMountpointProps = {
  editContext: EditContext
  getRef: (el) => void
  classNames: string
}

const TwoDimensionalSceneMountpoint = ({
  editContext,
  getRef,
  classNames,
}: TwoDimensionalSceneMountpointProps) => {
  return (
    <div
      ref={getRef}
      className={classNames}
      id={`two-dimensional-scene-${editContext}`}
      e2e-target="canvas-container"
      e2e-target-name={editContext}
    />
  )
}

const DevToolsPositioner = () => {
  return <div className={styles.positioner} />
}

export const TwoDimensionalScene: FC<TwoDimensionalSceneProps> = observer(
  ({
    availableEditContexts,
    activeContextId,
    activeSpaceId,
    isModelView,
    onSceneMount,
  }) => {
    const { productDriver } = useRootStore()
    const t = useTranslate()

    const createRefs = () => {
      const refs = {}

      availableEditContexts.map((context) => {
        refs[context] = undefined
      })

      return refs
    }

    const itemsRef = useRef(createRefs())

    useEffect(() => {
      onSceneMount(itemsRef.current)
    }, [activeContextId])

    const createTwoDimensionalScenes = () => {
      return availableEditContexts.map((context, index) => {
        let virtualDielineClassName = styles.two_dimensional_renderer

        if (activeContextId === context) {
          virtualDielineClassName = cx(
            "two_dimensional_renderer",
            "two_dimensional_renderer--show"
          )
        }

        return (
          <TwoDimensionalSceneMountpoint
            key={index}
            editContext={context}
            getRef={(el) => (itemsRef.current[context] = el)}
            classNames={virtualDielineClassName}
          />
        )
      })
    }

    const shouldShowDevTools =
      activeSpaceId &&
      localStorage &&
      localStorage.getItem("devToolsPositioner")

    const twoDimensialClassNames = cx("two_dimensional_renderer_container", {
      "two_dimensional_renderer_container--show": !isModelView,
    })

    const infoText =
      productDriver.state.productRenderPilot.getFullProductDescription()
        .infoTextKey

    return (
      <div className={twoDimensialClassNames}>
        {createTwoDimensionalScenes()}

        <div className={styles.two_dimensional_helper_zone}>
          <EditZoneRulers />
          <ObjectRulers />
          {shouldShowDevTools && <DevToolsPositioner />}
          {activeSpaceId && <FloatingToolbarWired />}
          {!!infoText && (
            <Typography type={TType.Body17_350} className={styles.info_text}>
              {t(infoText)}
            </Typography>
          )}
          <GlueStripCursorTooltip />
          <NoPrintZoneTooltip productDriver={productDriver} />
        </div>
      </div>
    )
  }
)

export default TwoDimensionalScene
