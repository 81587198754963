import React from "react"
import { Button } from "dsl/src/atoms/Button/Button"
import { useTranslate } from "../../../hooks/useTranslate"
import styles from "./EditorHeaderAddToCart.module.scss"
import { Loader, LoaderColors, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import { I18N } from "../../../i18n"

interface EditorHeaderGetQuoteProps {
  isDisabled: boolean
  isProductDesignSaving: boolean
  openQuoteRequestForm: () => void
}

export const EditorHeaderGetQuote = ({
  isDisabled,
  isProductDesignSaving,
  openQuoteRequestForm,
}: EditorHeaderGetQuoteProps) => {
  const t = useTranslate()

  return (
    <Button
      onClick={openQuoteRequestForm}
      e2eTargetName="get-a-quote"
      disabled={isDisabled}
    >
      {isProductDesignSaving ? (
        <>
          <div className={styles.loader_wrapper}>
            <Loader type={LoaderTypes.circular} color={LoaderColors.gray} />
          </div>
          {t(I18N.generic.saving)}
        </>
      ) : (
        <>{t(I18N.generic.getQuote)}</>
      )}
    </Button>
  )
}
