import React, { PropsWithChildren } from "react"
import cxBinder from "classnames/bind"

import styles from "./EditorTools.module.scss"

const cx = cxBinder.bind(styles)

interface EditorToolListProps {
  size?: "normal" | "small" | "full"
}

export const EditorToolList = ({
  size = "normal",
  children,
}: PropsWithChildren<EditorToolListProps>) => {
  return (
    <ul
      className={cx({
        tools_items_list: size === "normal",
        tools_items_list__small: size === "small",
        tools_items_list__full: size === "full",
      })}
    >
      {React.Children.map(children, (child) => {
        return <li>{child}</li>
      })}
    </ul>
  )
}
