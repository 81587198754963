var _a;
import NCP_CONSTS from "../consts/ncpConsts";
var WOOD_WOOL_DIMENSIONS = {
    W5: {
        outside: {
            width: 810,
            height: 510,
            length: 510,
        },
    },
};
var SELF_ADHESIVE_ENVELOPE_DIMENSIONS = {
    C6: {
        outside: {
            width: 175,
            height: 115,
        },
    },
};
var STICKERS_CIR_DIMENSIONS = {
    SC4: {
        outside: {
            width: 40,
            height: 40,
        },
    },
};
var STICKERS_REC_DIMENSIONS = {
    SR14: {
        outside: {
            width: 85,
            height: 50,
        },
    },
};
var THANK_YOU_CARD_DIMENSIONS = {
    A6: {
        outside: {
            width: 105,
            height: 148,
        },
    },
};
var PAPER_BUBBLE_WRAP_DIMENSIONS = {
    E40: {
        outside: {
            width: 400,
            height: 100000,
        },
    },
};
var NcpDimensions = (_a = {},
    _a[NCP_CONSTS.WOOD_WOOL] = WOOD_WOOL_DIMENSIONS,
    _a[NCP_CONSTS.SELF_ADHESIVE_ENVELOPE] = SELF_ADHESIVE_ENVELOPE_DIMENSIONS,
    _a[NCP_CONSTS.STICKER_CIRCLE] = STICKERS_CIR_DIMENSIONS,
    _a[NCP_CONSTS.STICKER_RECTANGLE] = STICKERS_REC_DIMENSIONS,
    _a[NCP_CONSTS.THANK_YOU_CARD] = THANK_YOU_CARD_DIMENSIONS,
    _a[NCP_CONSTS.PAPER_BUBBLE_WRAP] = PAPER_BUBBLE_WRAP_DIMENSIONS,
    _a);
export default NcpDimensions;
