import React, { FunctionComponent, ReactNode } from "react"
import cxBinder from "classnames/bind"
import styles from "./FormControlLabel.module.scss"
const cx = cxBinder.bind(styles)

export interface FormControlLabelProps
  extends React.HTMLProps<HTMLLabelElement> {
  control: ReactNode
  hiddenRadio?: boolean
  e2eTarget?: string
  e2eTargetName?: string
  fluid?: boolean
}

const FormControlLabel: FunctionComponent<FormControlLabelProps> = (props) => {
  const {
    children,
    control,
    hiddenRadio,
    e2eTarget,
    e2eTargetName,
    fluid,
    ...other
  } = props
  return (
    <label
      className={cx("wrapper", {
        "wrapper--hidden-radio": hiddenRadio,
        "wrapper--fluid": fluid
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...other}
    >
      {control}
      {children}
    </label>
  )
}

export { FormControlLabel, FormControlLabel as default }
