import React from "react"
import { useTranslate } from "../../../../hooks/useTranslate"
import { ActionConfirmModal } from "../action-confirm-modal/action-confirm-modal"
import { ReactComponent as ChangeIcon } from "../../../../assets/svg/shape-icons/change.svg"
import { ReactComponent as TrashIcon } from "../../../../assets/svg/shape-icons/trash.svg"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { I18N } from "../../../../i18n"

export type FscActionConfirmModalType = "changeShape" | "removeFsc"

const getCopy = (mode: FscActionConfirmModalType) => {
  return I18N.modals.fscActionConfirmation[mode]
}

const getIcon = (mode: FscActionConfirmModalType) => {
  const icons = {
    changeShape: ChangeIcon,
    removeFsc: TrashIcon,
  }

  return icons[mode]
}

export const FscActionConfirmModal = ({
  onClose,
  onConfirm,
  mode,
}: {
  onClose: () => void
  onConfirm: () => void
  mode: FscActionConfirmModalType
}) => {
  const t = useTranslate()
  const copy = getCopy(mode)

  return (
    <ActionConfirmModal
      onClose={onClose}
      onConfirm={onConfirm}
      title={t(copy.title)}
      confirmText={t(copy.confirm)}
      Icon={getIcon(mode)}
    >
      <Typography type={TType.Body15_350}>{t(copy.description)}</Typography>
    </ActionConfirmModal>
  )
}
