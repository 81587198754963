import { observer } from "mobx-react-lite"
import React from "react"
import { TType } from "shared-libs/src/js/shared-components/i18n/TypographyWithTranslate"
import Typography from "dsl/src/atoms/Typography/Typography"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

import cxBinder from "classnames/bind"
import styles from "./TemplateCard.module.scss"

const cx = cxBinder.bind(styles)

export const TemplateSkeletonCard = observer(() => {
  return (
    <div className={cx("wrapper")}>
      <div className={styles.header}>
        <div>
          <Typography type={TType.Header13_500}>
            <Skeleton width={180} />
          </Typography>
          <Typography type={TType.Body15_350}>
            <Skeleton width={50} />
          </Typography>
        </div>
        <span className={styles.selection}>
          <span className={styles.unchecked}></span>
        </span>
      </div>

      <div className={styles.carousel}>
        <div className={styles.thumb}>
          <Skeleton width={140} height={140} />
          <Typography type={TType.Body10_400UC}>
            <Skeleton width={50} />
          </Typography>
        </div>
        <div className={styles.thumb}>
          <Skeleton width={140} height={140} />
          <Typography type={TType.Body10_400UC}>
            <Skeleton width={50} />
          </Typography>
        </div>
      </div>
    </div>
  )
})
