import React, { useState } from "react"
import cxBinder from "classnames/bind"

import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { ReactComponent as IconArrowUp } from "../../../../../assets/svg/ArrowUp.svg"
import { ReactComponent as IconArrowDown } from "../../../../../assets/svg/ArrowDown.svg"
import { ReactComponent as IconChecked } from "../../../../../assets/svg/check.svg"
import { TagIcon } from "../../../../atoms/TagIcon/TagIcon"
import { useTranslate } from "shared-libs/src/js/shared-components/i18n/useTranslate"

import styles from "./SizeOption.module.scss"

const cx = cxBinder.bind(styles)

const SeePreview = () => {
  const t = useTranslate()

  return (
    <>
      <Typography type={TType.Body13_350}>
        {t("editor-tools.change-size.see-preview")}
      </Typography>
      <IconArrowDown className={styles.preview_toggle__icon} />
    </>
  )
}

const HidePreview = () => {
  const t = useTranslate()

  return (
    <>
      <Typography type={TType.Body13_350}>
        {t("editor-tools.change-size.hide-preview")}
      </Typography>
      <IconArrowUp className={styles.preview_toggle__icon} />
    </>
  )
}

interface SizeOptionPreviewProps {
  isOpened: boolean
  onToggle: (isOpened: boolean) => void
  imageUrl: string
}

const SizeOptionPreview = ({
  isOpened,
  onToggle,
  imageUrl,
}: SizeOptionPreviewProps) => {
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onToggle(!isOpened)
  }

  return (
    <div className={styles.size_option_preview}>
      <span className={styles.preview_toggle} onClick={onClick}>
        {isOpened ? <HidePreview /> : <SeePreview />}
      </span>
      {isOpened && (
        <img className={styles.preview_image} src={imageUrl} alt="" />
      )}
    </div>
  )
}

interface SizeOptionProps {
  variantCodeName?: string
  outsideDimensions: string
  insideDimensions?: string
  onSelect: () => void
  isSelected: boolean
  isDisabled: boolean
  imageUrl?: string
}

export const SizeOption = ({
  variantCodeName,
  outsideDimensions,
  insideDimensions,
  onSelect,
  isSelected,
  isDisabled,
  imageUrl,
}: SizeOptionProps) => {
  const t = useTranslate()
  const [isPreviewShown, setIsPreviewShown] = useState(false)

  const onClick = () => {
    if (!isSelected && !isDisabled) {
      onSelect()
    }
  }

  return (
    <div
      className={cx({
        size_option: true,
        "size_option--selected": isSelected,
        "size_option--disabled": isDisabled,
      })}
      onClick={onClick}
    >
      <div className={styles.size_option__details}>
        <div>
          <div>
            <Typography type={TType.Header15_500} htmlElement="span">
              {variantCodeName ? (
                <>
                  {variantCodeName} - {outsideDimensions}
                </>
              ) : (
                <>{outsideDimensions}</>
              )}
            </Typography>
            <Typography
              type={TType.Body13_350}
              className={styles.size_option__label}
            >
              {t("editor-tools.change-size.outside")}
            </Typography>
          </div>
          {!!insideDimensions && (
            <div>
              <Typography type={TType.Body13_350}>
                {insideDimensions}
              </Typography>
              <Typography
                type={TType.Body13_350}
                className={styles.size_option__label}
              >
                {t("editor-tools.change-size.inside")}
              </Typography>
            </div>
          )}
        </div>
        {isSelected ? (
          <TagIcon size="normal" Icon={IconChecked} />
        ) : (
          <span
            className={cx(
              styles.size_option__indicator,
              styles["size_option__indicator--unchecked"]
            )}
          />
        )}
      </div>
      {!!imageUrl && (
        <SizeOptionPreview
          imageUrl={`/build/shared-images${imageUrl}`}
          isOpened={isPreviewShown}
          onToggle={setIsPreviewShown}
        />
      )}
    </div>
  )
}
