var _a;
import BOX_CONSTS from "../../generalConfig/consts/boxConsts";
var PRODUCT_BOXES_RENDER_CONFIG = (_a = {},
    _a[BOX_CONSTS.PRODUCT_BOX_DEFAULT_VARIANT] = {
        P24: {
            width: 70,
            length: 50,
            height: 120,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 0.7,
            textureDirectory: "common",
            editorCameraZoom: 1,
        },
        P30: {
            width: 100,
            length: 100,
            height: 100,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 0.7,
            textureDirectory: "common",
            editorCameraZoom: 1,
        },
        P43: {
            width: 180,
            length: 30,
            height: 220,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 0.7,
            textureDirectory: "common",
            editorCameraZoom: 1,
        },
    },
    _a[BOX_CONSTS.PRODUCT_BOX_TWO_PIECES_DEFAULT_VARIANT] = {
        P19: {
            width: 80,
            length: 80,
            height: 29,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            textureDirectory: "33",
            thumbnailCameraZoom: 1,
            editorCameraZoom: 1,
        },
        P27: {
            width: 115,
            length: 115,
            height: 48,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            textureDirectory: "33",
            thumbnailCameraZoom: 1,
            editorCameraZoom: 1,
        },
        P28: {
            width: 155,
            length: 69,
            height: 59,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            textureDirectory: "33",
            thumbnailCameraZoom: 1,
            editorCameraZoom: 1,
        },
        P37: {
            width: 170,
            length: 170,
            height: 24,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            textureDirectory: "33",
            thumbnailCameraZoom: 1,
            editorCameraZoom: 1,
        },
        P40: {
            width: 169,
            length: 169,
            height: 80,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            textureDirectory: "33",
            thumbnailCameraZoom: 1,
            editorCameraZoom: 1,
        },
        P41: {
            width: 245,
            length: 114,
            height: 45,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            textureDirectory: "33",
            thumbnailCameraZoom: 1,
            editorCameraZoom: 1,
        },
    },
    _a[BOX_CONSTS.PRODUCT_BOX_WINE_BOX_DEFAULT_VARIANT] = {
        P48: {
            width: 80,
            length: 80,
            height: 320,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            textureDirectory: "common",
            thumbnailCameraZoom: 0.7,
            editorCameraZoom: 1,
        },
    },
    _a);
export default PRODUCT_BOXES_RENDER_CONFIG;
