import React from "react"
import {
  Typography,
  TypographyProps,
} from "dsl/src/atoms/Typography/Typography"
import { useTranslate } from "./hooks/useTranslate"

// Please also check before editing

// shared-libs/src/js/libs/others/i18n.tsx
// shared-libs/src/js/libs/AsyncI18nLocales.tsx
// editor/src/user-interface/hooks/useTranslate.ts
// editor/src/user-interface/dsl/organisms/Upload/Upload.tsx - Live usage and playground ^_^
interface TypographiedMessageWithI18n extends TypographyProps {
  id: string
  values?: any
}

// <TranslateMessage>
export function TM({ id, values, ...other }: TypographiedMessageWithI18n) {
  const t = useTranslate()
  return <Typography {...other}>{t(id, values)}</Typography>
}
