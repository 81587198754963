import { AbstractCreator } from "./abstract-creator"
import { ModelEditableSpaces } from "../../../../../../libs/products-render-config/types"
import {
  IndexConfigFragments,
  PackhelpEditableObject,
  PackhelpEditableRect,
} from "../../../object-extensions/packhelp-objects"
import { SpaceBoundingRect } from "../../dieline-navigator/services/translation-calculator"
import fabric from "../../../../../../libs/vendors/Fabric/index"
import {
  isObjectPath,
  isObjectRect,
} from "../../../../../../modules/ph-api/asset-types"

/**
 * Creates a safe zone object for display purposes.
 *
 * Safe zone object dimensions == Space dimensions.
 * Additionally, we create an inverted clip path (to simulate a safe zone border/stroke):
 * Clip path dimensions = {Space dimensions} - 2 * {Safe zone border/stroke width}
 *
 * E.g.
 * - Space = { left: 0, top: 0, width: 100, height: 100 }
 * - Safe zone size = 5
 *
 * - Safe zone object = { left: 0, top: 0, width: 100, height: 100 }
 * - Clip path = { left: 5, top: 5, width: 90, height: 90, inverted: true }
 */
export class SafeZoneForDisplayCreator extends AbstractCreator {
  public async create(
    spaceId: ModelEditableSpaces,
    index: number
  ): Promise<PackhelpEditableObject> {
    const vdSpace = this.dielineNavigator.getVirtualDielineSpace(spaceId)

    if (isObjectRect(vdSpace)) {
      return this.createForRect(spaceId, index)
    }

    if (isObjectPath(vdSpace)) {
      return this.createForPath(spaceId, index)
    }

    return this.createForPolygon(spaceId, index)
  }

  private async createForRect(
    spaceId: ModelEditableSpaces,
    index: number
  ): Promise<PackhelpEditableObject> {
    const safeZone = (await this.cloneVdSpace(spaceId)) as PackhelpEditableRect
    const vdSpaceBoundingRect = this.getVdSpaceBoundingRect(spaceId)
    const params = this.getParams(spaceId, vdSpaceBoundingRect, index)
    const { top, left, width, height, strokeWidth, inverted } =
      this.getClipPathParams(spaceId, vdSpaceBoundingRect)

    const clipPath = new fabric.Rect({
      top,
      left,
      width,
      height,
      strokeWidth,
      inverted,
      rx: (width / vdSpaceBoundingRect.width) * safeZone.rx!,
      ry: (height / vdSpaceBoundingRect.height) * safeZone.ry!,
    })

    safeZone.set({
      ...params,
      clipPath: clipPath,
    })

    return safeZone
  }

  private async createForPath(
    spaceId: ModelEditableSpaces,
    index: number
  ): Promise<PackhelpEditableObject> {
    const safeZone = await this.cloneVdSpace(spaceId)
    const vdSpaceBoundingRect = this.getVdSpaceBoundingRect(spaceId)
    const params = this.getParams(spaceId, vdSpaceBoundingRect, index)
    const { top, left, inverted, strokeWidth, scaleX, scaleY } =
      this.getClipPathParams(spaceId, vdSpaceBoundingRect)

    const clipPath = await this.cloneVdSpace(spaceId)
    clipPath.set({
      inverted,
      strokeWidth,
      scaleX,
      scaleY,
      top,
      left,
    })

    safeZone.set({
      ...params,
      clipPath: clipPath,
    })

    return safeZone
  }

  private async createForPolygon(
    spaceId: ModelEditableSpaces,
    index: number
  ): Promise<PackhelpEditableObject> {
    const safeZone = await this.cloneVdSpace(spaceId)
    const vdSpaceBoundingRect = this.getVdSpaceBoundingRect(spaceId)
    const params = this.getParams(spaceId, vdSpaceBoundingRect, index)
    const { width, height, top, left, inverted, strokeWidth, scaleX, scaleY } =
      this.getClipPathParams(spaceId, vdSpaceBoundingRect)

    const points = safeZone.points!.map((point) => {
      return {
        x: Math.round(point.x * scaleX),
        y: Math.round(point.y * scaleY),
      }
    })

    const clipPath = new fabric.Polygon(points, {
      width,
      height,
      top,
      left,
      inverted,
      strokeWidth,
    })

    safeZone.set({
      ...params,
      clipPath: clipPath,
    })

    return safeZone
  }

  private getParams(
    spaceId: ModelEditableSpaces,
    vdSpaceBoundingRect: SpaceBoundingRect,
    index: number
  ) {
    return {
      width: vdSpaceBoundingRect.width,
      height: vdSpaceBoundingRect.height,
      left: vdSpaceBoundingRect.left,
      top: vdSpaceBoundingRect.top,
      strokeWidth: 0,
      indexConfig: {
        fragment: IndexConfigFragments.TOP,
        index: index,
      },
      evented: false,
      selectable: false,
      opacity: 0,
      id: `safe_zone_${spaceId}`,
      originSpaceArea: spaceId,
      fill: "rgba(238, 171, 29, 0.5)",
    }
  }
}
