import _flow from "lodash/flow";
import RenderConfigManager from "../../../behaviorsComposers/RenderConfigManger";
import getGeneralNonBoxRenderBehaviors from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/getGeneralNonBoxRenderBehaviors";
import addGetOffsetForSideBehavior from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/getOffsetForSide";
import addGetProductEditZonesBehaviors from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/getProductEditZones";
import addLabelRenderConfigBehaviors from "../../../behaviors/labelBehaviors/renderConfigBehaviors/LabelRenderConfigBehaviors";
export default _flow([
    addGetOffsetForSideBehavior,
    addGetProductEditZonesBehaviors,
    getGeneralNonBoxRenderBehaviors,
    addLabelRenderConfigBehaviors,
])(RenderConfigManager);
