import { PantonePickerColorObject } from "../PickerColor/PickerColorTypes"

const PANTONE_COLOURS_MAP: PantonePickerColorObject[] = [
  {
    pantoneName: "White",
    color: "#FFFFFF",
    r: 255,
    g: 255,
    b: 255,
    h: 1,
    s: 0,
    v: 0.8
  },
  {
    pantoneName: "Cool Gray 1 C",
    color: "#D9D9D6",
    r: 217,
    g: 217,
    b: 214,
    h: 0.166666672,
    s: 0.0138248838,
    v: 0.8509804
  },
  {
    pantoneName: "Cool Gray 1 C",
    color: "#D9D9D6",
    r: 217,
    g: 217,
    b: 214,
    h: 0.166666672,
    s: 0.0138248838,
    v: 0.8509804
  },
  {
    pantoneName: "Cool Gray 1 C",
    color: "#D9D9D6",
    r: 217,
    g: 217,
    b: 214,
    h: 0.166666672,
    s: 0.0138248838,
    v: 0.8509804
  },
  {
    pantoneName: "Cool Gray 1 C",
    color: "#D9D9D6",
    r: 217,
    g: 217,
    b: 214,
    h: 0.166666672,
    s: 0.0138248838,
    v: 0.8509804
  },
  {
    pantoneName: "Cool Gray 1 C",
    color: "#D9D9D6",
    r: 217,
    g: 217,
    b: 214,
    h: 0.166666672,
    s: 0.0138248838,
    v: 0.8509804
  },
  {
    pantoneName: "Cool Gray 1 C",
    color: "#D9D9D6",
    r: 217,
    g: 217,
    b: 214,
    h: 0.166666672,
    s: 0.0138248838,
    v: 0.8509804
  },
  {
    pantoneName: "Cool Gray 1 C",
    color: "#D9D9D6",
    r: 217,
    g: 217,
    b: 214,
    h: 0.166666672,
    s: 0.0138248838,
    v: 0.8509804
  },
  {
    pantoneName: "Cool Gray 1 C",
    color: "#D9D9D6",
    r: 217,
    g: 217,
    b: 214,
    h: 0.166666672,
    s: 0.0138248838,
    v: 0.8509804
  },
  {
    pantoneName: "Cool Gray 1 C",
    color: "#D9D9D6",
    r: 217,
    g: 217,
    b: 214,
    h: 0.166666672,
    s: 0.0138248838,
    v: 0.8509804
  },
  {
    pantoneName: "Cool Gray 1 C",
    color: "#D9D9D6",
    r: 217,
    g: 217,
    b: 214,
    h: 0.166666672,
    s: 0.0138248838,
    v: 0.8509804
  },
  {
    pantoneName: "Cool Gray 2 C",
    color: "#D0D0CE",
    r: 208,
    g: 208,
    b: 206,
    h: 0.166666672,
    s: 0.009615384,
    v: 0.8156863
  },
  {
    pantoneName: "Cool Gray 3 C",
    color: "#C8C9C7",
    r: 200,
    g: 201,
    b: 199,
    h: 0.25,
    s: 0.009950248,
    v: 0.7882353
  },
  {
    pantoneName: "Cool Gray 4 C",
    color: "#BBBCBC",
    r: 187,
    g: 188,
    b: 188,
    h: 0.5,
    s: 0.0053191483,
    v: 0.7372549
  },
  {
    pantoneName: "Cool Gray 5 C",
    color: "#B1B3B3",
    r: 177,
    g: 179,
    b: 179,
    h: 0.5,
    s: 0.0111731831,
    v: 0.7019608
  },
  {
    pantoneName: "422 C",
    color: "#9EA2A2",
    r: 158,
    g: 162,
    b: 162,
    h: 0.5,
    s: 0.0246913563,
    v: 0.635294139
  },
  {
    pantoneName: "Cool Gray 7 C",
    color: "#97999B",
    r: 151,
    g: 153,
    b: 155,
    h: 0.5833333,
    s: 0.02580645,
    v: 0.607843161
  },
  {
    pantoneName: "423 C",
    color: "#898D8D",
    r: 137,
    g: 141,
    b: 141,
    h: 0.5,
    s: 0.0283687916,
    v: 0.5529412
  },
  {
    pantoneName: "424 C",
    color: "#707372",
    r: 112,
    g: 115,
    b: 114,
    h: 0.444444448,
    s: 0.0260869544,
    v: 0.4509804
  },
  {
    pantoneName: "424 C",
    color: "#707372",
    r: 112,
    g: 115,
    b: 114,
    h: 0.444444448,
    s: 0.0260869544,
    v: 0.4509804
  },
  {
    pantoneName: "425 C",
    color: "#54585A",
    r: 84,
    g: 88,
    b: 90,
    h: 0.5555555,
    s: 0.06666666,
    v: 0.3529412
  },
  {
    pantoneName: "446 C",
    color: "#3F4444",
    r: 63,
    g: 68,
    b: 68,
    h: 0.5,
    s: 0.07352946,
    v: 0.266666681
  },
  {
    pantoneName: "447 C",
    color: "#373A36",
    r: 55,
    g: 58,
    b: 54,
    h: 0.291666657,
    s: 0.06896551,
    v: 0.227450982
  },
  {
    pantoneName: "Neutral Black C",
    color: "#222223",
    r: 34,
    g: 34,
    b: 35,
    h: 0.6666667,
    s: 0.028571425,
    v: 0.137254909
  },
  {
    pantoneName: "Neutral Black C",
    color: "#222223",
    r: 34,
    g: 34,
    b: 35,
    h: 0.6666667,
    s: 0.028571425,
    v: 0.137254909
  },
  {
    pantoneName: "185 C",
    color: "#E4002B",
    r: 228,
    g: 0,
    b: 43,
    h: 0.968567252,
    s: 1.0,
    v: 0.894117653
  },
  {
    pantoneName: "Warm Red C",
    color: "#F9423A",
    r: 249,
    g: 66,
    b: 58,
    h: 0.006980805,
    s: 0.767068267,
    v: 0.9764706
  },
  {
    pantoneName: "178 C",
    color: "#FF585D",
    r: 255,
    g: 88,
    b: 93,
    h: 0.995009959,
    s: 0.654902,
    v: 1.0
  },
  {
    pantoneName: "1775 C",
    color: "#FF8DA1",
    r: 255,
    g: 141,
    b: 161,
    h: 0.9707602,
    s: 0.4470588,
    v: 1.0
  },
  {
    pantoneName: "7422 C",
    color: "#F4CDD4",
    r: 244,
    g: 205,
    b: 212,
    h: 0.970085442,
    s: 0.159836054,
    v: 0.956862748
  },
  {
    pantoneName: "185 C",
    color: "#E4002B",
    r: 228,
    g: 0,
    b: 43,
    h: 0.968567252,
    s: 1.0,
    v: 0.894117653
  },
  {
    pantoneName: "1795 C",
    color: "#D22630",
    r: 210,
    g: 38,
    b: 48,
    h: 0.9903101,
    s: 0.81904763,
    v: 0.8235294
  },
  {
    pantoneName: "7418 C",
    color: "#CD545B",
    r: 205,
    g: 84,
    b: 91,
    h: 0.9903581,
    s: 0.5902439,
    v: 0.8039216
  },
  {
    pantoneName: "7606 C",
    color: "#D6938A",
    r: 214,
    g: 147,
    b: 138,
    h: 0.0197368432,
    s: 0.35514015,
    v: 0.8392157
  },
  {
    pantoneName: "501 C",
    color: "#DAA5AD",
    r: 218,
    g: 165,
    b: 173,
    h: 0.9748428,
    s: 0.243119255,
    v: 0.854901969
  },
  {
    pantoneName: "200 C",
    color: "#BA0C2F",
    r: 186,
    g: 12,
    b: 47,
    h: 0.96647507,
    s: 0.9354839,
    v: 0.7294118
  },
  {
    pantoneName: "7621 C",
    color: "#AB2328",
    r: 171,
    g: 35,
    b: 40,
    h: 0.9938725,
    s: 0.795321643,
    v: 0.670588255
  },
  {
    pantoneName: "7608 C",
    color: "#A4493D",
    r: 164,
    g: 73,
    b: 61,
    h: 0.0194174778,
    s: 0.628048837,
    v: 0.6431373
  },
  {
    pantoneName: "4995 C",
    color: "#9C6169",
    r: 156,
    g: 97,
    b: 105,
    h: 0.977401137,
    s: 0.37820515,
    v: 0.6117647
  },
  {
    pantoneName: "7530 C",
    color: "#A39382",
    r: 163,
    g: 147,
    b: 130,
    h: 0.08585858,
    s: 0.202453971,
    v: 0.6392157
  },
  {
    pantoneName: "1815 C",
    color: "#7C2529",
    r: 124,
    g: 37,
    b: 41,
    h: 0.992337167,
    s: 0.7016129,
    v: 0.4862745
  },
  {
    pantoneName: "1815 C",
    color: "#7C2529",
    r: 124,
    g: 37,
    b: 41,
    h: 0.992337167,
    s: 0.7016129,
    v: 0.4862745
  },
  {
    pantoneName: "7609 C",
    color: "#823B34",
    r: 130,
    g: 59,
    b: 52,
    h: 0.0149572613,
    s: 0.6,
    v: 0.509803951
  },
  {
    pantoneName: "4985 C",
    color: "#874B52",
    r: 135,
    g: 75,
    b: 82,
    h: 0.980555534,
    s: 0.444444448,
    v: 0.5294118
  },
  {
    pantoneName: "7615 C",
    color: "#866761",
    r: 134,
    g: 103,
    b: 97,
    h: 0.02702702,
    s: 0.276119441,
    v: 0.5254902
  },
  {
    pantoneName: "4625 C",
    color: "#4F2C1D",
    r: 79,
    g: 44,
    b: 29,
    h: 0.049999997,
    s: 0.632911444,
    v: 0.309803933
  },
  {
    pantoneName: "4625 C",
    color: "#4F2C1D",
    r: 79,
    g: 44,
    b: 29,
    h: 0.049999997,
    s: 0.632911444,
    v: 0.309803933
  },
  {
    pantoneName: "490 C",
    color: "#5D2A2C",
    r: 93,
    g: 42,
    b: 44,
    h: 0.993464053,
    s: 0.5483871,
    v: 0.3647059
  },
  {
    pantoneName: "7596 C",
    color: "#5C3D31",
    r: 92,
    g: 61,
    b: 49,
    h: 0.046511624,
    s: 0.4673913,
    v: 0.360784322
  },
  {
    pantoneName: "438 C",
    color: "#584446",
    r: 88,
    g: 68,
    b: 70,
    h: 0.983333349,
    s: 0.2272727,
    v: 0.345098048
  },
  {
    pantoneName: "151 C",
    color: "#FF8200",
    r: 255,
    g: 130,
    b: 0,
    h: 0.08496732,
    s: 1.0,
    v: 1.0
  },
  {
    pantoneName: "715 C",
    color: "#F68D2E",
    r: 246,
    g: 141,
    b: 46,
    h: 0.07916667,
    s: 0.8130081,
    v: 0.9647059
  },
  {
    pantoneName: "1485 C",
    color: "#FFAE62",
    r: 255,
    g: 174,
    b: 98,
    h: 0.08067941,
    s: 0.6156863,
    v: 1.0
  },
  {
    pantoneName: "712 C",
    color: "#FCC89B",
    r: 252,
    g: 200,
    b: 155,
    h: 0.0773195848,
    s: 0.3849206,
    v: 0.9882353
  },
  {
    pantoneName: "705 C",
    color: "#F5DADF",
    r: 245,
    g: 218,
    b: 223,
    h: 0.9691358,
    s: 0.110204078,
    v: 0.9607843
  },
  {
    pantoneName: "1385 C",
    color: "#D57800",
    r: 213,
    g: 120,
    b: 0,
    h: 0.09389672,
    s: 1.0,
    v: 0.8352941
  },
  {
    pantoneName: "7570 C",
    color: "#D3832B",
    r: 211,
    g: 131,
    b: 43,
    h: 0.08730159,
    s: 0.79620856,
    v: 0.827451
  },
  {
    pantoneName: "7407 C",
    color: "#CBA052",
    r: 203,
    g: 160,
    b: 82,
    h: 0.10743802,
    s: 0.5960591,
    v: 0.796078444
  },
  {
    pantoneName: "7514 C",
    color: "#D5A286",
    r: 213,
    g: 162,
    b: 134,
    h: 0.05907173,
    s: 0.370892,
    v: 0.8352941
  },
  {
    pantoneName: "481 C",
    color: "#D3BBA8",
    r: 211,
    g: 187,
    b: 168,
    h: 0.07364341,
    s: 0.203791454,
    v: 0.827451
  },
  {
    pantoneName: "718 C",
    color: "#BE4D00",
    r: 190,
    g: 77,
    b: 0,
    h: 0.06754386,
    s: 1.0,
    v: 0.745098054
  },
  {
    pantoneName: "723 C",
    color: "#B36924",
    r: 179,
    g: 105,
    b: 36,
    h: 0.08041958,
    s: 0.7988827,
    v: 0.7019608
  },
  {
    pantoneName: "729 C",
    color: "#B58150",
    r: 181,
    g: 129,
    b: 80,
    h: 0.08085809,
    s: 0.558011055,
    v: 0.709803939
  },
  {
    pantoneName: "479 C",
    color: "#AA8066",
    r: 170,
    g: 128,
    b: 102,
    h: 0.0637255,
    s: 0.4,
    v: 0.6666667
  },
  {
    pantoneName: "7536 C",
    color: "#A69F88",
    r: 166,
    g: 159,
    b: 136,
    h: 0.12777777,
    s: 0.180722877,
    v: 0.6509804
  },
  {
    pantoneName: "1535 C",
    color: "#94450B",
    r: 148,
    g: 69,
    b: 11,
    h: 0.07055961,
    s: 0.9256757,
    v: 0.5803922
  },
  {
    pantoneName: "1615 C",
    color: "#8B4720",
    r: 139,
    g: 71,
    b: 32,
    h: 0.0607476644,
    s: 0.7697842,
    v: 0.545098066
  },
  {
    pantoneName: "7575 C",
    color: "#835D32",
    r: 131,
    g: 93,
    b: 50,
    h: 0.08847735,
    s: 0.618320644,
    v: 0.5137255
  },
  {
    pantoneName: "7531 C",
    color: "#7A6855",
    r: 122,
    g: 104,
    b: 85,
    h: 0.08558559,
    s: 0.303278655,
    v: 0.478431374
  },
  {
    pantoneName: "Warm Gray 9 C",
    color: "#83786F",
    r: 131,
    g: 120,
    b: 111,
    h: 0.07499997,
    s: 0.1526718,
    v: 0.5137255
  },
  {
    pantoneName: "732 C",
    color: "#623412",
    r: 98,
    g: 52,
    b: 18,
    h: 0.07083333,
    s: 0.816326559,
    v: 0.384313732
  },
  {
    pantoneName: "732 C",
    color: "#623412",
    r: 98,
    g: 52,
    b: 18,
    h: 0.07083333,
    s: 0.816326559,
    v: 0.384313732
  },
  {
    pantoneName: "7553 C",
    color: "#5A4522",
    r: 90,
    g: 69,
    b: 34,
    h: 0.104166664,
    s: 0.6222222,
    v: 0.3529412
  },
  {
    pantoneName: "7589 C",
    color: "#5C4738",
    r: 92,
    g: 71,
    b: 56,
    h: 0.0694444552,
    s: 0.391304344,
    v: 0.360784322
  },
  {
    pantoneName: "411 C",
    color: "#5E514D",
    r: 94,
    g: 81,
    b: 77,
    h: 0.0392156877,
    s: 0.180851042,
    v: 0.368627459
  },
  {
    pantoneName: "3945 C",
    color: "#F3E500",
    r: 243,
    g: 229,
    b: 0,
    h: 0.157064468,
    s: 1.0,
    v: 0.9529412
  },
  {
    pantoneName: "394 C",
    color: "#EDE939",
    r: 237,
    g: 233,
    b: 57,
    h: 0.162962958,
    s: 0.7594937,
    v: 0.929411769
  },
  {
    pantoneName: "3935 C",
    color: "#F3EA5D",
    r: 243,
    g: 234,
    b: 93,
    h: 0.156666681,
    s: 0.61728394,
    v: 0.9529412
  },
  {
    pantoneName: "600 C",
    color: "#F1EB9C",
    r: 241,
    g: 235,
    b: 156,
    h: 0.154901966,
    s: 0.352697074,
    v: 0.945098042
  },
  {
    pantoneName: "7499 C",
    color: "#F1E6B2",
    r: 241,
    g: 230,
    b: 178,
    h: 0.137566134,
    s: 0.261410773,
    v: 0.945098042
  },
  {
    pantoneName: "381 C",
    color: "#CEDC00",
    r: 206,
    g: 220,
    b: 0,
    h: 0.177272722,
    s: 1.0,
    v: 0.8627451
  },
  {
    pantoneName: "611 C",
    color: "#D7C826",
    r: 215,
    g: 200,
    b: 38,
    h: 0.152542368,
    s: 0.823255837,
    v: 0.843137264
  },
  {
    pantoneName: "584 C",
    color: "#D2D755",
    r: 210,
    g: 215,
    b: 85,
    h: 0.173076913,
    s: 0.6046511,
    v: 0.843137264
  },
  {
    pantoneName: "615 C",
    color: "#D6CF8D",
    r: 214,
    g: 207,
    b: 141,
    h: 0.150684938,
    s: 0.341121465,
    v: 0.8392157
  },
  {
    pantoneName: "5807 C",
    color: "#D0D1AB",
    r: 208,
    g: 209,
    b: 171,
    h: 0.171052635,
    s: 0.181818172,
    v: 0.819607854
  },
  {
    pantoneName: "383 C",
    color: "#A8AD00",
    r: 168,
    g: 173,
    b: 0,
    h: 0.171483621,
    s: 1.0,
    v: 0.6784314
  },
  {
    pantoneName: "7745 C",
    color: "#ABAD23",
    r: 171,
    g: 173,
    b: 35,
    h: 0.16908212,
    s: 0.7976879,
    v: 0.6784314
  },
  {
    pantoneName: "618 C",
    color: "#AC9F3C",
    r: 172,
    g: 159,
    b: 60,
    h: 0.147321418,
    s: 0.6511628,
    v: 0.6745098
  },
  {
    pantoneName: "5845 C",
    color: "#AFA96E",
    r: 175,
    g: 169,
    b: 110,
    h: 0.151282057,
    s: 0.371428579,
    v: 0.6862745
  },
  {
    pantoneName: "5783 C",
    color: "#A3AA83",
    r: 163,
    g: 170,
    b: 131,
    h: 0.1965812,
    s: 0.229411751,
    v: 0.6666667
  },
  {
    pantoneName: "392 C",
    color: "#827A04",
    r: 130,
    g: 122,
    b: 4,
    h: 0.156084642,
    s: 0.9692308,
    v: 0.509803951
  },
  {
    pantoneName: "620 C",
    color: "#8A7B19",
    r: 138,
    g: 123,
    b: 25,
    h: 0.144542769,
    s: 0.8188406,
    v: 0.5411765
  },
  {
    pantoneName: "5825 C",
    color: "#89813D",
    r: 137,
    g: 129,
    b: 61,
    h: 0.1491228,
    s: 0.5547446,
    v: 0.5372549
  },
  {
    pantoneName: "5767 C",
    color: "#8A8D4A",
    r: 138,
    g: 141,
    b: 74,
    h: 0.174129352,
    s: 0.475177318,
    v: 0.5529412
  },
  {
    pantoneName: "5773 C",
    color: "#899064",
    r: 137,
    g: 144,
    b: 100,
    h: 0.193181813,
    s: 0.305555582,
    v: 0.5647059
  },
  {
    pantoneName: "378 C",
    color: "#59621D",
    r: 89,
    g: 98,
    b: 29,
    h: 0.1884058,
    s: 0.704081655,
    v: 0.384313732
  },
  {
    pantoneName: "378 C",
    color: "#59621D",
    r: 89,
    g: 98,
    b: 29,
    h: 0.1884058,
    s: 0.704081655,
    v: 0.384313732
  },
  {
    pantoneName: "581 C",
    color: "#625D20",
    r: 98,
    g: 93,
    b: 32,
    h: 0.154040411,
    s: 0.673469365,
    v: 0.384313732
  },
  {
    pantoneName: "7763 C",
    color: "#585C3B",
    r: 88,
    g: 92,
    b: 59,
    h: 0.186868683,
    s: 0.358695656,
    v: 0.360784322
  },
  {
    pantoneName: "411 C",
    color: "#5E514D",
    r: 94,
    g: 81,
    b: 77,
    h: 0.0392156877,
    s: 0.180851042,
    v: 0.368627459
  },
  {
    pantoneName: "375 C",
    color: "#97D700",
    r: 151,
    g: 215,
    b: 0,
    h: 0.216279075,
    s: 1.0,
    v: 0.843137264
  },
  {
    pantoneName: "7488 C",
    color: "#78D64B",
    r: 120,
    g: 214,
    b: 75,
    h: 0.2793765,
    s: 0.649532735,
    v: 0.8392157
  },
  {
    pantoneName: "374 C",
    color: "#C5E86C",
    r: 197,
    g: 232,
    b: 108,
    h: 0.213709667,
    s: 0.5344828,
    v: 0.9098039
  },
  {
    pantoneName: "372 C",
    color: "#D4EB8E",
    r: 212,
    g: 235,
    b: 142,
    h: 0.20788531,
    s: 0.395744652,
    v: 0.921568632
  },
  {
    pantoneName: "7499 C",
    color: "#F1E6B2",
    r: 241,
    g: 230,
    b: 178,
    h: 0.137566134,
    s: 0.261410773,
    v: 0.945098042
  },
  {
    pantoneName: "376 C",
    color: "#84BD00",
    r: 132,
    g: 189,
    b: 0,
    h: 0.216931209,
    s: 1.0,
    v: 0.7411765
  },
  {
    pantoneName: "368 C",
    color: "#78BE20",
    r: 120,
    g: 190,
    b: 32,
    h: 0.240506336,
    s: 0.83157897,
    v: 0.745098054
  },
  {
    pantoneName: "367 C",
    color: "#A4D65E",
    r: 164,
    g: 214,
    b: 94,
    h: 0.2361111,
    s: 0.5607477,
    v: 0.8392157
  },
  {
    pantoneName: "359 C",
    color: "#A1D884",
    r: 161,
    g: 216,
    b: 132,
    h: 0.275793642,
    s: 0.388888866,
    v: 0.847058833
  },
  {
    pantoneName: "580 C",
    color: "#C4D6A4",
    r: 196,
    g: 214,
    b: 164,
    h: 0.226666674,
    s: 0.233644843,
    v: 0.8392157
  },
  {
    pantoneName: "369 C",
    color: "#64A70B",
    r: 100,
    g: 167,
    b: 11,
    h: 0.238247871,
    s: 0.934131742,
    v: 0.654902
  },
  {
    pantoneName: "368 C",
    color: "#78BE20",
    r: 120,
    g: 190,
    b: 32,
    h: 0.240506336,
    s: 0.83157897,
    v: 0.745098054
  },
  {
    pantoneName: "7489 C",
    color: "#74AA50",
    r: 116,
    g: 170,
    b: 80,
    h: 0.266666681,
    s: 0.529411733,
    v: 0.6666667
  },
  {
    pantoneName: "5777 C",
    color: "#A2A569",
    r: 162,
    g: 165,
    b: 105,
    h: 0.175,
    s: 0.363636374,
    v: 0.647058845
  },
  {
    pantoneName: "7494 C",
    color: "#9CAF88",
    r: 156,
    g: 175,
    b: 136,
    h: 0.247863248,
    s: 0.222857118,
    v: 0.6862745
  },
  {
    pantoneName: "364 C",
    color: "#4A7729",
    r: 74,
    g: 119,
    b: 41,
    h: 0.2628205,
    s: 0.655462146,
    v: 0.466666669
  },
  {
    pantoneName: "363 C",
    color: "#4C8C2B",
    r: 76,
    g: 140,
    b: 43,
    h: 0.2766323,
    s: 0.6928572,
    v: 0.549019635
  },
  {
    pantoneName: "575 C",
    color: "#67823A",
    r: 103,
    g: 130,
    b: 58,
    h: 0.229166687,
    s: 0.5538462,
    v: 0.509803951
  },
  {
    pantoneName: "5763 C",
    color: "#737B4C",
    r: 115,
    g: 123,
    b: 76,
    h: 0.195035458,
    s: 0.382113785,
    v: 0.482352942
  },
  {
    pantoneName: "5625 C",
    color: "#708573",
    r: 112,
    g: 133,
    b: 115,
    h: 0.357142836,
    s: 0.157894775,
    v: 0.521568656
  },
  {
    pantoneName: "5747 C",
    color: "#3D441E",
    r: 61,
    g: 68,
    b: 30,
    h: 0.197368443,
    s: 0.5588236,
    v: 0.266666681
  },
  {
    pantoneName: "5747 C",
    color: "#3D441E",
    r: 61,
    g: 68,
    b: 30,
    h: 0.197368443,
    s: 0.5588236,
    v: 0.266666681
  },
  {
    pantoneName: "574 C",
    color: "#4E5B31",
    r: 78,
    g: 91,
    b: 49,
    h: 0.21825397,
    s: 0.461538464,
    v: 0.356862754
  },
  {
    pantoneName: "574 C",
    color: "#4E5B31",
    r: 78,
    g: 91,
    b: 49,
    h: 0.21825397,
    s: 0.461538464,
    v: 0.356862754
  },
  {
    pantoneName: "418 C",
    color: "#51534A",
    r: 81,
    g: 83,
    b: 74,
    h: 0.2037037,
    s: 0.108433723,
    v: 0.3254902
  },
  {
    pantoneName: "354 C",
    color: "#00B140",
    r: 0,
    g: 177,
    b: 64,
    h: 0.393597,
    s: 1.0,
    v: 0.694117665
  },
  {
    pantoneName: "361 C",
    color: "#43B02A",
    r: 67,
    g: 176,
    b: 42,
    h: 0.3022388,
    s: 0.7613637,
    v: 0.6901961
  },
  {
    pantoneName: "7488 C",
    color: "#78D64B",
    r: 120,
    g: 214,
    b: 75,
    h: 0.2793765,
    s: 0.649532735,
    v: 0.8392157
  },
  {
    pantoneName: "352 C",
    color: "#8FE2B0",
    r: 143,
    g: 226,
    b: 176,
    h: 0.3995984,
    s: 0.3672566,
    v: 0.8862745
  },
  {
    pantoneName: "621 C",
    color: "#D1E0D7",
    r: 209,
    g: 224,
    b: 215,
    h: 0.4,
    s: 0.06696428,
    v: 0.8784314
  },
  {
    pantoneName: "354 C",
    color: "#00B140",
    r: 0,
    g: 177,
    b: 64,
    h: 0.393597,
    s: 1.0,
    v: 0.694117665
  },
  {
    pantoneName: "361 C",
    color: "#43B02A",
    r: 67,
    g: 176,
    b: 42,
    h: 0.3022388,
    s: 0.7613637,
    v: 0.6901961
  },
  {
    pantoneName: "360 C",
    color: "#6CC24A",
    r: 108,
    g: 194,
    b: 74,
    h: 0.286111116,
    s: 0.6185567,
    v: 0.7607843
  },
  {
    pantoneName: "346 C",
    color: "#71CC98",
    r: 113,
    g: 204,
    b: 152,
    h: 0.4047619,
    s: 0.446078449,
    v: 0.8
  },
  {
    pantoneName: "344 C",
    color: "#A0DAB3",
    r: 160,
    g: 218,
    b: 179,
    h: 0.387931019,
    s: 0.266055018,
    v: 0.854901969
  },
  {
    pantoneName: "355 C",
    color: "#009639",
    r: 0,
    g: 150,
    b: 57,
    h: 0.396666646,
    s: 1.0,
    v: 0.5882353
  },
  {
    pantoneName: "361 C",
    color: "#43B02A",
    r: 67,
    g: 176,
    b: 42,
    h: 0.3022388,
    s: 0.7613637,
    v: 0.6901961
  },
  {
    pantoneName: "7738 C",
    color: "#48A23F",
    r: 72,
    g: 162,
    b: 63,
    h: 0.3181818,
    s: 0.611111164,
    v: 0.635294139
  },
  {
    pantoneName: "7489 C",
    color: "#74AA50",
    r: 116,
    g: 170,
    b: 80,
    h: 0.266666681,
    s: 0.529411733,
    v: 0.6666667
  },
  {
    pantoneName: "5635 C",
    color: "#94A596",
    r: 148,
    g: 165,
    b: 150,
    h: 0.3529412,
    s: 0.103030294,
    v: 0.647058845
  },
  {
    pantoneName: "356 C",
    color: "#007A33",
    r: 0,
    g: 122,
    b: 51,
    h: 0.403005481,
    s: 1.0,
    v: 0.478431374
  },
  {
    pantoneName: "356 C",
    color: "#007A33",
    r: 0,
    g: 122,
    b: 51,
    h: 0.403005481,
    s: 1.0,
    v: 0.478431374
  },
  {
    pantoneName: "7740 C",
    color: "#3A913F",
    r: 58,
    g: 145,
    b: 63,
    h: 0.342911869,
    s: 0.6,
    v: 0.5686275
  },
  {
    pantoneName: "7741 C",
    color: "#44883E",
    r: 68,
    g: 136,
    b: 62,
    h: 0.3198198,
    s: 0.5441177,
    v: 0.533333361
  },
  {
    pantoneName: "5625 C",
    color: "#708573",
    r: 112,
    g: 133,
    b: 115,
    h: 0.357142836,
    s: 0.157894775,
    v: 0.521568656
  },
  {
    pantoneName: "349 C",
    color: "#046A38",
    r: 4,
    g: 106,
    b: 56,
    h: 0.418300658,
    s: 0.9622642,
    v: 0.41568628
  },
  {
    pantoneName: "357 C",
    color: "#215732",
    r: 33,
    g: 87,
    b: 50,
    h: 0.385802478,
    s: 0.620689631,
    v: 0.34117648
  },
  {
    pantoneName: "357 C",
    color: "#215732",
    r: 33,
    g: 87,
    b: 50,
    h: 0.385802478,
    s: 0.620689631,
    v: 0.34117648
  },
  {
    pantoneName: "7735 C",
    color: "#36573B",
    r: 54,
    g: 87,
    b: 59,
    h: 0.358585864,
    s: 0.379310369,
    v: 0.34117648
  },
  {
    pantoneName: "418 C",
    color: "#51534A",
    r: 81,
    g: 83,
    b: 74,
    h: 0.2037037,
    s: 0.108433723,
    v: 0.3254902
  },
  {
    pantoneName: "7479 C",
    color: "#26D07C",
    r: 38,
    g: 208,
    b: 124,
    h: 0.417647034,
    s: 0.8173077,
    v: 0.8156863
  },
  {
    pantoneName: "3385 C",
    color: "#47D7AC",
    r: 71,
    g: 215,
    b: 172,
    h: 0.450231463,
    s: 0.6697674,
    v: 0.843137264
  },
  {
    pantoneName: "3375 C",
    color: "#7AE1BF",
    r: 122,
    g: 225,
    b: 191,
    h: 0.44498384,
    s: 0.457777768,
    v: 0.882352948
  },
  {
    pantoneName: "331 C",
    color: "#A7E6D7",
    r: 167,
    g: 230,
    b: 215,
    h: 0.460317463,
    s: 0.273913026,
    v: 0.9019608
  },
  {
    pantoneName: "656 C",
    color: "#DDE5ED",
    r: 221,
    g: 229,
    b: 237,
    h: 0.5833333,
    s: 0.0675105453,
    v: 0.929411769
  },
  {
    pantoneName: "7480 C",
    color: "#00BF6F",
    r: 0,
    g: 191,
    b: 111,
    h: 0.430191964,
    s: 1.0,
    v: 0.7490196
  },
  {
    pantoneName: "7479 C",
    color: "#26D07C",
    r: 38,
    g: 208,
    b: 124,
    h: 0.417647034,
    s: 0.8173077,
    v: 0.8156863
  },
  {
    pantoneName: "3385 C",
    color: "#47D7AC",
    r: 71,
    g: 215,
    b: 172,
    h: 0.450231463,
    s: 0.6697674,
    v: 0.843137264
  },
  {
    pantoneName: "353 C",
    color: "#80E0A7",
    r: 128,
    g: 224,
    b: 167,
    h: 0.401041657,
    s: 0.4285714,
    v: 0.8784314
  },
  {
    pantoneName: "565 C",
    color: "#A1D6CA",
    r: 161,
    g: 214,
    b: 202,
    h: 0.46226415,
    s: 0.247663528,
    v: 0.8392157
  },
  {
    pantoneName: "7481 C",
    color: "#00B74F",
    r: 0,
    g: 183,
    b: 79,
    h: 0.405282348,
    s: 1.0,
    v: 0.7176471
  },
  {
    pantoneName: "3405 C",
    color: "#00AF66",
    r: 0,
    g: 175,
    b: 102,
    h: 0.4304762,
    s: 1.0,
    v: 0.6862745
  },
  {
    pantoneName: "7723 C",
    color: "#50A684",
    r: 80,
    g: 166,
    b: 132,
    h: 0.434108526,
    s: 0.5180723,
    v: 0.6509804
  },
  {
    pantoneName: "556 C",
    color: "#6FA287",
    r: 111,
    g: 162,
    b: 135,
    h: 0.4117647,
    s: 0.314814836,
    v: 0.635294139
  },
  {
    pantoneName: "557 C",
    color: "#85B09A",
    r: 133,
    g: 176,
    b: 154,
    h: 0.414728671,
    s: 0.244318157,
    v: 0.6901961
  },
  {
    pantoneName: "348 C",
    color: "#00843D",
    r: 0,
    g: 132,
    b: 61,
    h: 0.410353541,
    s: 1.0,
    v: 0.5176471
  },
  {
    pantoneName: "7731 C",
    color: "#228848",
    r: 34,
    g: 136,
    b: 72,
    h: 0.395424843,
    s: 0.75,
    v: 0.533333361
  },
  {
    pantoneName: "555 C",
    color: "#28724F",
    r: 40,
    g: 114,
    b: 79,
    h: 0.4211712,
    s: 0.6491228,
    v: 0.447058827
  },
  {
    pantoneName: "625 C",
    color: "#507F70",
    r: 80,
    g: 127,
    b: 112,
    h: 0.4468085,
    s: 0.3700787,
    v: 0.498039216
  },
  {
    pantoneName: "5625 C",
    color: "#708573",
    r: 112,
    g: 133,
    b: 115,
    h: 0.357142836,
    s: 0.157894775,
    v: 0.521568656
  },
  {
    pantoneName: "349 C",
    color: "#046A38",
    r: 4,
    g: 106,
    b: 56,
    h: 0.418300658,
    s: 0.9622642,
    v: 0.41568628
  },
  {
    pantoneName: "343 C",
    color: "#115740",
    r: 17,
    g: 87,
    b: 64,
    h: 0.4452381,
    s: 0.804597735,
    v: 0.34117648
  },
  {
    pantoneName: "554 C",
    color: "#205C40",
    r: 32,
    g: 92,
    b: 64,
    h: 0.422222227,
    s: 0.6521739,
    v: 0.360784322
  },
  {
    pantoneName: "7736 C",
    color: "#395542",
    r: 57,
    g: 85,
    b: 66,
    h: 0.3869048,
    s: 0.329411775,
    v: 0.333333343
  },
  {
    pantoneName: "445 C",
    color: "#505759",
    r: 80,
    g: 87,
    b: 89,
    h: 0.5370371,
    s: 0.101123586,
    v: 0.349019617
  },
  {
    pantoneName: "311 C",
    color: "#05C3DE",
    r: 5,
    g: 195,
    b: 222,
    h: 0.52073735,
    s: 0.9774775,
    v: 0.870588243
  },
  {
    pantoneName: "319 C",
    color: "#2DCCD3",
    r: 45,
    g: 204,
    b: 211,
    h: 0.5070281,
    s: 0.7867299,
    v: 0.827451
  },
  {
    pantoneName: "310 C",
    color: "#6AD1E3",
    r: 106,
    g: 209,
    b: 227,
    h: 0.5247934,
    s: 0.5330396,
    v: 0.8901961
  },
  {
    pantoneName: "304 C",
    color: "#9ADBE8",
    r: 154,
    g: 219,
    b: 232,
    h: 0.5277778,
    s: 0.336206883,
    v: 0.9098039
  },
  {
    pantoneName: "656 C",
    color: "#DDE5ED",
    r: 221,
    g: 229,
    b: 237,
    h: 0.5833333,
    s: 0.0675105453,
    v: 0.929411769
  },
  {
    pantoneName: "3115 C",
    color: "#00C1D5",
    r: 0,
    g: 193,
    b: 213,
    h: 0.515649438,
    s: 1.0,
    v: 0.8352941
  },
  {
    pantoneName: "319 C",
    color: "#2DCCD3",
    r: 45,
    g: 204,
    b: 211,
    h: 0.5070281,
    s: 0.7867299,
    v: 0.827451
  },
  {
    pantoneName: "3105 C",
    color: "#68D2DF",
    r: 104,
    g: 210,
    b: 223,
    h: 0.518207252,
    s: 0.5336323,
    v: 0.8745098
  },
  {
    pantoneName: "7471 C",
    color: "#7EDDD3",
    r: 126,
    g: 221,
    b: 211,
    h: 0.482456118,
    s: 0.429864258,
    v: 0.8666667
  },
  {
    pantoneName: "572 C",
    color: "#A5DFD3",
    r: 165,
    g: 223,
    b: 211,
    h: 0.465517253,
    s: 0.260089666,
    v: 0.8745098
  },
  {
    pantoneName: "326 C",
    color: "#00B2A9",
    r: 0,
    g: 178,
    b: 169,
    h: 0.491573,
    s: 1.0,
    v: 0.698039234
  },
  {
    pantoneName: "7465 C",
    color: "#40C1AC",
    r: 64,
    g: 193,
    b: 172,
    h: 0.4728682,
    s: 0.6683938,
    v: 0.75686276
  },
  {
    pantoneName: "7465 C",
    color: "#40C1AC",
    r: 64,
    g: 193,
    b: 172,
    h: 0.4728682,
    s: 0.6683938,
    v: 0.75686276
  },
  {
    pantoneName: "563 C",
    color: "#6BBBAE",
    r: 107,
    g: 187,
    b: 174,
    h: 0.4729167,
    s: 0.42780748,
    v: 0.733333349
  },
  {
    pantoneName: "5493 C",
    color: "#7FA9AE",
    r: 127,
    g: 169,
    b: 174,
    h: 0.5177305,
    s: 0.270114958,
    v: 0.68235296
  },
  {
    pantoneName: "7717 C",
    color: "#00857D",
    r: 0,
    g: 133,
    b: 125,
    h: 0.489974946,
    s: 1.0,
    v: 0.521568656
  },
  {
    pantoneName: "7473 C",
    color: "#279989",
    r: 39,
    g: 153,
    b: 137,
    h: 0.4766082,
    s: 0.745098054,
    v: 0.6
  },
  {
    pantoneName: "7698 C",
    color: "#41748D",
    r: 65,
    g: 116,
    b: 141,
    h: 0.5548246,
    s: 0.539007068,
    v: 0.5529412
  },
  {
    pantoneName: "5483 C",
    color: "#4F868E",
    r: 79,
    g: 134,
    b: 142,
    h: 0.521164,
    s: 0.443662,
    v: 0.5568628
  },
  {
    pantoneName: "444 C",
    color: "#717C7D",
    r: 113,
    g: 124,
    b: 125,
    h: 0.5138889,
    s: 0.09599999,
    v: 0.490196079
  },
  {
    pantoneName: "7721 C",
    color: "#005E5D",
    r: 0,
    g: 94,
    b: 93,
    h: 0.49822697,
    s: 1.0,
    v: 0.368627459
  },
  {
    pantoneName: "5473 C",
    color: "#115E67",
    r: 17,
    g: 94,
    b: 103,
    h: 0.517441869,
    s: 0.83495146,
    v: 0.403921574
  },
  {
    pantoneName: "626 C",
    color: "#285C4D",
    r: 40,
    g: 92,
    b: 77,
    h: 0.4519231,
    s: 0.5652174,
    v: 0.360784322
  },
  {
    pantoneName: "5477 C",
    color: "#3E5D58",
    r: 62,
    g: 93,
    b: 88,
    h: 0.473118275,
    s: 0.333333343,
    v: 0.3647059
  },
  {
    pantoneName: "445 C",
    color: "#505759",
    r: 80,
    g: 87,
    b: 89,
    h: 0.5370371,
    s: 0.101123586,
    v: 0.349019617
  },
  {
    pantoneName: "2925 C",
    color: "#009CDE",
    r: 0,
    g: 156,
    b: 222,
    h: 0.5495495,
    s: 1.0,
    v: 0.870588243
  },
  {
    pantoneName: "279 C",
    color: "#418FDE",
    r: 65,
    g: 143,
    b: 222,
    h: 0.583864152,
    s: 0.7072072,
    v: 0.870588243
  },
  {
    pantoneName: "292 C",
    color: "#69B3E7",
    r: 105,
    g: 179,
    b: 231,
    h: 0.568783045,
    s: 0.545454562,
    v: 0.905882359
  },
  {
    pantoneName: "291 C",
    color: "#9BCBEB",
    r: 155,
    g: 203,
    b: 235,
    h: 0.566666663,
    s: 0.340425521,
    v: 0.921568632
  },
  {
    pantoneName: "2707 C",
    color: "#C3D7EE",
    r: 195,
    g: 215,
    b: 238,
    h: 0.58914727,
    s: 0.180672258,
    v: 0.933333337
  },
  {
    pantoneName: "285 C",
    color: "#0072CE",
    r: 0,
    g: 114,
    b: 206,
    h: 0.5744336,
    s: 1.0,
    v: 0.807843149
  },
  {
    pantoneName: "2727 C",
    color: "#307FE2",
    r: 48,
    g: 127,
    b: 226,
    h: 0.5926966,
    s: 0.78761065,
    v: 0.8862745
  },
  {
    pantoneName: "2718 C",
    color: "#5C88DA",
    r: 92,
    g: 136,
    b: 218,
    h: 0.6084656,
    s: 0.577981651,
    v: 0.854901969
  },
  {
    pantoneName: "542 C",
    color: "#7BAFD4",
    r: 123,
    g: 175,
    b: 212,
    h: 0.5692884,
    s: 0.419811338,
    v: 0.831372559
  },
  {
    pantoneName: "651 C",
    color: "#A7BCD6",
    r: 167,
    g: 188,
    b: 214,
    h: 0.592198551,
    s: 0.219626158,
    v: 0.8392157
  },
  {
    pantoneName: "2935 C",
    color: "#0057B8",
    r: 0,
    g: 87,
    b: 184,
    h: 0.5878623,
    s: 1.0,
    v: 0.721568644
  },
  {
    pantoneName: "7455 C",
    color: "#3A5DAE",
    r: 58,
    g: 93,
    b: 174,
    h: 0.6163793,
    s: 0.6666667,
    v: 0.68235296
  },
  {
    pantoneName: "7683 C",
    color: "#426DA9",
    r: 66,
    g: 109,
    b: 169,
    h: 0.5970874,
    s: 0.609467447,
    v: 0.6627451
  },
  {
    pantoneName: "7682 C",
    color: "#6787B7",
    r: 103,
    g: 135,
    b: 183,
    h: 0.599999964,
    s: 0.437158465,
    v: 0.7176471
  },
  {
    pantoneName: "7543 C",
    color: "#98A4AE",
    r: 152,
    g: 164,
    b: 174,
    h: 0.575757563,
    s: 0.12643677,
    v: 0.68235296
  },
  {
    pantoneName: "301 C",
    color: "#004B87",
    r: 0,
    g: 75,
    b: 135,
    h: 0.5740741,
    s: 1.0,
    v: 0.5294118
  },
  {
    pantoneName: "7686 C",
    color: "#1D4F91",
    r: 29,
    g: 79,
    b: 145,
    h: 0.5948276,
    s: 0.8,
    v: 0.5686275
  },
  {
    pantoneName: "7699 C",
    color: "#34657F",
    r: 52,
    g: 101,
    b: 127,
    h: 0.557777762,
    s: 0.590551138,
    v: 0.498039216
  },
  {
    pantoneName: "5405 C",
    color: "#4F758B",
    r: 79,
    g: 117,
    b: 139,
    h: 0.561111152,
    s: 0.4316547,
    v: 0.545098066
  },
  {
    pantoneName: "444 C",
    color: "#717C7D",
    r: 113,
    g: 124,
    b: 125,
    h: 0.5138889,
    s: 0.09599999,
    v: 0.490196079
  },
  {
    pantoneName: "648 C",
    color: "#002E5D",
    r: 0,
    g: 46,
    b: 93,
    h: 0.5842294,
    s: 1.0,
    v: 0.3647059
  },
  {
    pantoneName: "534 C",
    color: "#1B365D",
    r: 27,
    g: 54,
    b: 93,
    h: 0.5984848,
    s: 0.709677458,
    v: 0.3647059
  },
  {
    pantoneName: "7477 C",
    color: "#244C5A",
    r: 36,
    g: 76,
    b: 90,
    h: 0.543209851,
    s: 0.599999964,
    v: 0.3529412
  },
  {
    pantoneName: "7545 C",
    color: "#425563",
    r: 66,
    g: 85,
    b: 99,
    h: 0.5707071,
    s: 0.3333333,
    v: 0.3882353
  },
  {
    pantoneName: "445 C",
    color: "#505759",
    r: 80,
    g: 87,
    b: 89,
    h: 0.5370371,
    s: 0.101123586,
    v: 0.349019617
  },
  {
    pantoneName: "2736 C",
    color: "#1E22AA",
    r: 30,
    g: 34,
    b: 170,
    h: 0.661904752,
    s: 0.8235294,
    v: 0.6666667
  },
  {
    pantoneName: "2726 C",
    color: "#485CC7",
    r: 72,
    g: 92,
    b: 199,
    h: 0.64041996,
    s: 0.6381909,
    v: 0.78039217
  },
  {
    pantoneName: "2718 C",
    color: "#5C88DA",
    r: 92,
    g: 136,
    b: 218,
    h: 0.6084656,
    s: 0.577981651,
    v: 0.854901969
  },
  {
    pantoneName: "2716 C",
    color: "#9FAEE5",
    r: 159,
    g: 174,
    b: 229,
    h: 0.6309524,
    s: 0.305676848,
    v: 0.8980392
  },
  {
    pantoneName: "2706 C",
    color: "#CBD3EB",
    r: 203,
    g: 211,
    b: 235,
    h: 0.625,
    s: 0.136170208,
    v: 0.921568632
  },
  {
    pantoneName: "2736 C",
    color: "#1E22AA",
    r: 30,
    g: 34,
    b: 170,
    h: 0.661904752,
    s: 0.8235294,
    v: 0.6666667
  },
  {
    pantoneName: "2736 C",
    color: "#1E22AA",
    r: 30,
    g: 34,
    b: 170,
    h: 0.661904752,
    s: 0.8235294,
    v: 0.6666667
  },
  {
    pantoneName: "2726 C",
    color: "#485CC7",
    r: 72,
    g: 92,
    b: 199,
    h: 0.64041996,
    s: 0.6381909,
    v: 0.78039217
  },
  {
    pantoneName: "2715 C",
    color: "#8B84D7",
    r: 139,
    g: 132,
    b: 215,
    h: 0.6807229,
    s: 0.3860465,
    v: 0.843137264
  },
  {
    pantoneName: "2705 C",
    color: "#A7A4E0",
    r: 167,
    g: 164,
    b: 224,
    h: 0.675,
    s: 0.267857134,
    v: 0.8784314
  },
  {
    pantoneName: "2738 C",
    color: "#06038D",
    r: 6,
    g: 3,
    b: 141,
    h: 0.6702898,
    s: 0.9787234,
    v: 0.5529412
  },
  {
    pantoneName: "2736 C",
    color: "#1E22AA",
    r: 30,
    g: 34,
    b: 170,
    h: 0.661904752,
    s: 0.8235294,
    v: 0.6666667
  },
  {
    pantoneName: "7455 C",
    color: "#3A5DAE",
    r: 58,
    g: 93,
    b: 174,
    h: 0.6163793,
    s: 0.6666667,
    v: 0.68235296
  },
  {
    pantoneName: "7456 C",
    color: "#606EB2",
    r: 96,
    g: 110,
    b: 178,
    h: 0.63821137,
    s: 0.460674167,
    v: 0.698039234
  },
  {
    pantoneName: "7674 C",
    color: "#878CB4",
    r: 135,
    g: 140,
    b: 180,
    h: 0.6481481,
    s: 0.249999985,
    v: 0.7058824
  },
  {
    pantoneName: "2738 C",
    color: "#06038D",
    r: 6,
    g: 3,
    b: 141,
    h: 0.6702898,
    s: 0.9787234,
    v: 0.5529412
  },
  {
    pantoneName: "2746 C",
    color: "#171C8F",
    r: 23,
    g: 28,
    b: 143,
    h: 0.6597222,
    s: 0.83916086,
    v: 0.56078434
  },
  {
    pantoneName: "7672 C",
    color: "#4C4184",
    r: 76,
    g: 65,
    b: 132,
    h: 0.6940298,
    s: 0.50757575,
    v: 0.5176471
  },
  {
    pantoneName: "7673 C",
    color: "#535486",
    r: 83,
    g: 84,
    b: 134,
    h: 0.6633987,
    s: 0.380597025,
    v: 0.5254902
  },
  {
    pantoneName: "Cool Gray 9 C",
    color: "#75787B",
    r: 117,
    g: 120,
    b: 123,
    h: 0.5833333,
    s: 0.048780486,
    v: 0.482352942
  },
  {
    pantoneName: "2757 C",
    color: "#001E60",
    r: 0,
    g: 30,
    b: 96,
    h: 0.6145833,
    s: 1.0,
    v: 0.3764706
  },
  {
    pantoneName: "2766 C",
    color: "#141B4D",
    r: 20,
    g: 27,
    b: 77,
    h: 0.6461988,
    s: 0.740259767,
    v: 0.3019608
  },
  {
    pantoneName: "273 C",
    color: "#24135F",
    r: 36,
    g: 19,
    b: 95,
    h: 0.703947365,
    s: 0.8,
    v: 0.372549027
  },
  {
    pantoneName: "5265 C",
    color: "#403A60",
    r: 64,
    g: 58,
    b: 96,
    h: 0.692982435,
    s: 0.395833343,
    v: 0.3764706
  },
  {
    pantoneName: "7540 C",
    color: "#4B4F54",
    r: 75,
    g: 79,
    b: 84,
    h: 0.5925926,
    s: 0.107142851,
    v: 0.329411775
  },
  {
    pantoneName: "2592 C",
    color: "#9B26B6",
    r: 155,
    g: 38,
    b: 182,
    h: 0.8020833,
    s: 0.7912088,
    v: 0.7137255
  },
  {
    pantoneName: "2582 C",
    color: "#AC4FC6",
    r: 172,
    g: 79,
    b: 198,
    h: 0.7969188,
    s: 0.6010101,
    v: 0.7764706
  },
  {
    pantoneName: "528 C",
    color: "#B580D1",
    r: 181,
    g: 128,
    b: 209,
    h: 0.7757202,
    s: 0.387559772,
    v: 0.819607854
  },
  {
    pantoneName: "264 C",
    color: "#C1A7E2",
    r: 193,
    g: 167,
    b: 226,
    h: 0.740113,
    s: 0.261061937,
    v: 0.8862745
  },
  {
    pantoneName: "531 C",
    color: "#DFC8E7",
    r: 223,
    g: 200,
    b: 231,
    h: 0.790322542,
    s: 0.134199128,
    v: 0.905882359
  },
  {
    pantoneName: "266 C",
    color: "#753BBD",
    r: 117,
    g: 59,
    b: 189,
    h: 0.7410256,
    s: 0.6878307,
    v: 0.7411765
  },
  {
    pantoneName: "266 C",
    color: "#753BBD",
    r: 117,
    g: 59,
    b: 189,
    h: 0.7410256,
    s: 0.6878307,
    v: 0.7411765
  },
  {
    pantoneName: "265 C",
    color: "#9063CD",
    r: 144,
    g: 99,
    b: 205,
    h: 0.737421334,
    s: 0.517073154,
    v: 0.8039216
  },
  {
    pantoneName: "528 C",
    color: "#B580D1",
    r: 181,
    g: 128,
    b: 209,
    h: 0.7757202,
    s: 0.387559772,
    v: 0.819607854
  },
  {
    pantoneName: "523 C",
    color: "#C9B1D0",
    r: 201,
    g: 177,
    b: 208,
    h: 0.795698941,
    s: 0.149038449,
    v: 0.8156863
  },
  {
    pantoneName: "Violet C",
    color: "#440099",
    r: 68,
    g: 0,
    b: 153,
    h: 0.7407408,
    s: 1.0,
    v: 0.6
  },
  {
    pantoneName: "267 C",
    color: "#5F259F",
    r: 95,
    g: 37,
    b: 159,
    h: 0.745901644,
    s: 0.7672956,
    v: 0.623529434
  },
  {
    pantoneName: "2587 C",
    color: "#8246AF",
    r: 130,
    g: 70,
    b: 175,
    h: 0.7619048,
    s: 0.599999964,
    v: 0.6862745
  },
  {
    pantoneName: "7661 C",
    color: "#8D6E97",
    r: 141,
    g: 110,
    b: 151,
    h: 0.792682946,
    s: 0.2715232,
    v: 0.5921569
  },
  {
    pantoneName: "666 C",
    color: "#A192B2",
    r: 161,
    g: 146,
    b: 178,
    h: 0.7447917,
    s: 0.179775268,
    v: 0.698039234
  },
  {
    pantoneName: "Medium Purple C",
    color: "#4E008E",
    r: 78,
    g: 0,
    b: 142,
    h: 0.758215964,
    s: 1.0,
    v: 0.5568628
  },
  {
    pantoneName: "268 C",
    color: "#582C83",
    r: 88,
    g: 44,
    b: 131,
    h: 0.7509579,
    s: 0.664122164,
    v: 0.5137255
  },
  {
    pantoneName: "268 C",
    color: "#582C83",
    r: 88,
    g: 44,
    b: 131,
    h: 0.7509579,
    s: 0.664122164,
    v: 0.5137255
  },
  {
    pantoneName: "7678 C",
    color: "#68478D",
    r: 104,
    g: 71,
    b: 141,
    h: 0.745238066,
    s: 0.4964539,
    v: 0.5529412
  },
  {
    pantoneName: "667 C",
    color: "#7C6992",
    r: 124,
    g: 105,
    b: 146,
    h: 0.743902445,
    s: 0.280821949,
    v: 0.572549045
  },
  {
    pantoneName: "2755 C",
    color: "#250E62",
    r: 37,
    g: 14,
    b: 98,
    h: 0.712301552,
    s: 0.857142866,
    v: 0.384313732
  },
  {
    pantoneName: "2627 C",
    color: "#3C1053",
    r: 60,
    g: 16,
    b: 83,
    h: 0.7761194,
    s: 0.8072289,
    v: 0.3254902
  },
  {
    pantoneName: "669 C",
    color: "#3F2A56",
    r: 63,
    g: 42,
    b: 86,
    h: 0.746212065,
    s: 0.5116279,
    v: 0.3372549
  },
  {
    pantoneName: "5265 C",
    color: "#403A60",
    r: 64,
    g: 58,
    b: 96,
    h: 0.692982435,
    s: 0.395833343,
    v: 0.3764706
  },
  {
    pantoneName: "7666 C",
    color: "#5C4E63",
    r: 92,
    g: 78,
    b: 99,
    h: 0.777777731,
    s: 0.212121189,
    v: 0.3882353
  },
  {
    pantoneName: "2385 C",
    color: "#D539B5",
    r: 213,
    g: 57,
    b: 181,
    h: 0.867521346,
    s: 0.7323944,
    v: 0.8352941
  },
  {
    pantoneName: "238 C",
    color: "#E45DBF",
    r: 228,
    g: 93,
    b: 191,
    h: 0.879012346,
    s: 0.592105269,
    v: 0.894117653
  },
  {
    pantoneName: "237 C",
    color: "#EC86D0",
    r: 236,
    g: 134,
    b: 208,
    h: 0.879084945,
    s: 0.432203352,
    v: 0.9254902
  },
  {
    pantoneName: "236 C",
    color: "#F1A7DC",
    r: 241,
    g: 167,
    b: 220,
    h: 0.8806306,
    s: 0.307053924,
    v: 0.945098042
  },
  {
    pantoneName: "7436 C",
    color: "#EEDAEA",
    r: 238,
    g: 218,
    b: 234,
    h: 0.8666667,
    s: 0.08403361,
    v: 0.933333337
  },
  {
    pantoneName: "246 C",
    color: "#C724B1",
    r: 199,
    g: 36,
    b: 177,
    h: 0.8558282,
    s: 0.8190955,
    v: 0.78039217
  },
  {
    pantoneName: "2385 C",
    color: "#D539B5",
    r: 213,
    g: 57,
    b: 181,
    h: 0.867521346,
    s: 0.7323944,
    v: 0.8352941
  },
  {
    pantoneName: "252 C",
    color: "#C964CF",
    r: 201,
    g: 100,
    b: 207,
    h: 0.823987544,
    s: 0.5169082,
    v: 0.8117647
  },
  {
    pantoneName: "245 C",
    color: "#DD7FD3",
    r: 221,
    g: 127,
    b: 211,
    h: 0.851063848,
    s: 0.425339371,
    v: 0.8666667
  },
  {
    pantoneName: "7437 C",
    color: "#CCAED0",
    r: 204,
    g: 174,
    b: 208,
    h: 0.8137255,
    s: 0.163461521,
    v: 0.8156863
  },
  {
    pantoneName: "253 C",
    color: "#AD1AAC",
    r: 173,
    g: 26,
    b: 172,
    h: 0.8344671,
    s: 0.849711,
    v: 0.6784314
  },
  {
    pantoneName: "253 C",
    color: "#AD1AAC",
    r: 173,
    g: 26,
    b: 172,
    h: 0.8344671,
    s: 0.849711,
    v: 0.6784314
  },
  {
    pantoneName: "2582 C",
    color: "#AC4FC6",
    r: 172,
    g: 79,
    b: 198,
    h: 0.7969188,
    s: 0.6010101,
    v: 0.7764706
  },
  {
    pantoneName: "2583 C",
    color: "#A05EB5",
    r: 160,
    g: 94,
    b: 181,
    h: 0.793103456,
    s: 0.480662972,
    v: 0.709803939
  },
  {
    pantoneName: "5215 C",
    color: "#AF95A6",
    r: 175,
    g: 149,
    b: 166,
    h: 0.891025662,
    s: 0.148571417,
    v: 0.6862745
  },
  {
    pantoneName: "2415 C",
    color: "#9E007E",
    r: 158,
    g: 0,
    b: 126,
    h: 0.8670886,
    s: 1.0,
    v: 0.619607866
  },
  {
    pantoneName: "2612 C",
    color: "#772583",
    r: 119,
    g: 37,
    b: 131,
    h: 0.8120567,
    s: 0.7175573,
    v: 0.5137255
  },
  {
    pantoneName: "7656 C",
    color: "#8E3A80",
    r: 142,
    g: 58,
    b: 128,
    h: 0.8611111,
    s: 0.591549337,
    v: 0.5568628
  },
  {
    pantoneName: "5135 C",
    color: "#7E5475",
    r: 126,
    g: 84,
    b: 117,
    h: 0.869047642,
    s: 0.3333333,
    v: 0.494117647
  },
  {
    pantoneName: "5205 C",
    color: "#86647A",
    r: 134,
    g: 100,
    b: 122,
    h: 0.8921569,
    s: 0.25373137,
    v: 0.5254902
  },
  {
    pantoneName: "2617 C",
    color: "#470A68",
    r: 71,
    g: 10,
    b: 104,
    h: 0.7748227,
    s: 0.903846145,
    v: 0.407843143
  },
  {
    pantoneName: "2623 C",
    color: "#5F2167",
    r: 95,
    g: 33,
    b: 103,
    h: 0.814285755,
    s: 0.6796116,
    v: 0.403921574
  },
  {
    pantoneName: "261 C",
    color: "#5D285F",
    r: 93,
    g: 40,
    b: 95,
    h: 0.8272727,
    s: 0.578947365,
    v: 0.372549027
  },
  {
    pantoneName: "519 C",
    color: "#59315F",
    r: 89,
    g: 49,
    b: 95,
    h: 0.8115942,
    s: 0.484210521,
    v: 0.372549027
  },
  {
    pantoneName: "7666 C",
    color: "#5C4E63",
    r: 92,
    g: 78,
    b: 99,
    h: 0.777777731,
    s: 0.212121189,
    v: 0.3882353
  },
  {
    pantoneName: "Rhodamine Red C",
    color: "#E10098",
    r: 225,
    g: 0,
    b: 152,
    h: 0.8874074,
    s: 1.0,
    v: 0.882352948
  },
  {
    pantoneName: "232 C",
    color: "#E93CAC",
    r: 233,
    g: 60,
    b: 172,
    h: 0.8921002,
    s: 0.7424893,
    v: 0.9137255
  },
  {
    pantoneName: "224 C",
    color: "#EB6FBD",
    r: 235,
    g: 111,
    b: 189,
    h: 0.8951613,
    s: 0.5276596,
    v: 0.921568632
  },
  {
    pantoneName: "210 C",
    color: "#F99FC9",
    r: 249,
    g: 159,
    b: 201,
    h: 0.9222222,
    s: 0.361445755,
    v: 0.9764706
  },
  {
    pantoneName: "706 C",
    color: "#F7CED7",
    r: 247,
    g: 206,
    b: 215,
    h: 0.9634146,
    s: 0.165991887,
    v: 0.968627453
  },
  {
    pantoneName: "226 C",
    color: "#D0006F",
    r: 208,
    g: 0,
    b: 111,
    h: 0.9110577,
    s: 1.0,
    v: 0.8156863
  },
  {
    pantoneName: "219 C",
    color: "#DA1884",
    r: 218,
    g: 24,
    b: 132,
    h: 0.9072165,
    s: 0.889908254,
    v: 0.854901969
  },
  {
    pantoneName: "674 C",
    color: "#C6579A",
    r: 198,
    g: 87,
    b: 154,
    h: 0.8993994,
    s: 0.560606062,
    v: 0.7764706
  },
  {
    pantoneName: "673 C",
    color: "#D986BA",
    r: 217,
    g: 134,
    b: 186,
    h: 0.8955823,
    s: 0.382488459,
    v: 0.8509804
  },
  {
    pantoneName: "7430 C",
    color: "#DCA9BF",
    r: 220,
    g: 169,
    b: 191,
    h: 0.9281046,
    s: 0.231818169,
    v: 0.8627451
  },
  {
    pantoneName: "227 C",
    color: "#AA0061",
    r: 170,
    g: 0,
    b: 97,
    h: 0.904902,
    s: 1.0,
    v: 0.6666667
  },
  {
    pantoneName: "675 C",
    color: "#AE2573",
    r: 174,
    g: 37,
    b: 115,
    h: 0.9051095,
    s: 0.7873563,
    v: 0.68235296
  },
  {
    pantoneName: "7647 C",
    color: "#A83D72",
    r: 168,
    g: 61,
    b: 114,
    h: 0.9174455,
    s: 0.6369048,
    v: 0.65882355
  },
  {
    pantoneName: "688 C",
    color: "#A76389",
    r: 167,
    g: 99,
    b: 137,
    h: 0.906862736,
    s: 0.407185644,
    v: 0.654902
  },
  {
    pantoneName: "436 C",
    color: "#AB989D",
    r: 171,
    g: 152,
    b: 157,
    h: 0.956140339,
    s: 0.111111104,
    v: 0.670588255
  },
  {
    pantoneName: "221 C",
    color: "#910048",
    r: 145,
    g: 0,
    b: 72,
    h: 0.9172414,
    s: 1.0,
    v: 0.5686275
  },
  {
    pantoneName: "7435 C",
    color: "#872651",
    r: 135,
    g: 38,
    b: 81,
    h: 0.9261168,
    s: 0.718518555,
    v: 0.5294118
  },
  {
    pantoneName: "689 C",
    color: "#893B67",
    r: 137,
    g: 59,
    b: 103,
    h: 0.9059829,
    s: 0.5693431,
    v: 0.5372549
  },
  {
    pantoneName: "5135 C",
    color: "#7E5475",
    r: 126,
    g: 84,
    b: 117,
    h: 0.869047642,
    s: 0.3333333,
    v: 0.494117647
  },
  {
    pantoneName: "5205 C",
    color: "#86647A",
    r: 134,
    g: 100,
    b: 122,
    h: 0.8921569,
    s: 0.25373137,
    v: 0.5254902
  },
  {
    pantoneName: "7421 C",
    color: "#651D32",
    r: 101,
    g: 29,
    b: 50,
    h: 0.9513889,
    s: 0.7128713,
    v: 0.396078438
  },
  {
    pantoneName: "7421 C",
    color: "#651D32",
    r: 101,
    g: 29,
    b: 50,
    h: 0.9513889,
    s: 0.7128713,
    v: 0.396078438
  },
  {
    pantoneName: "690 C",
    color: "#612141",
    r: 97,
    g: 33,
    b: 65,
    h: 0.9166666,
    s: 0.659793735,
    v: 0.380392164
  },
  {
    pantoneName: "7659 C",
    color: "#5D3754",
    r: 93,
    g: 55,
    b: 84,
    h: 0.872807,
    s: 0.408602148,
    v: 0.3647059
  },
  {
    pantoneName: "411 C",
    color: "#5E514D",
    r: 94,
    g: 81,
    b: 77,
    h: 0.0392156877,
    s: 0.180851042,
    v: 0.368627459
  },
  {
    pantoneName: "185 C",
    color: "#E4002B",
    r: 228,
    g: 0,
    b: 43,
    h: 0.968567252,
    s: 1.0,
    v: 0.894117653
  },
  {
    pantoneName: "Warm Red C",
    color: "#F9423A",
    r: 249,
    g: 66,
    b: 58,
    h: 0.006980805,
    s: 0.767068267,
    v: 0.9764706
  },
  {
    pantoneName: "178 C",
    color: "#FF585D",
    r: 255,
    g: 88,
    b: 93,
    h: 0.995009959,
    s: 0.654902,
    v: 1.0
  },
  {
    pantoneName: "1775 C",
    color: "#FF8DA1",
    r: 255,
    g: 141,
    b: 161,
    h: 0.9707602,
    s: 0.4470588,
    v: 1.0
  },
  {
    pantoneName: "7422 C",
    color: "#F4CDD4",
    r: 244,
    g: 205,
    b: 212,
    h: 0.970085442,
    s: 0.159836054,
    v: 0.956862748
  },
  {
    pantoneName: "185 C",
    color: "#E4002B",
    r: 228,
    g: 0,
    b: 43,
    h: 0.968567252,
    s: 1.0,
    v: 0.894117653
  },
  {
    pantoneName: "1795 C",
    color: "#D22630",
    r: 210,
    g: 38,
    b: 48,
    h: 0.9903101,
    s: 0.81904763,
    v: 0.8235294
  },
  {
    pantoneName: "7418 C",
    color: "#CD545B",
    r: 205,
    g: 84,
    b: 91,
    h: 0.9903581,
    s: 0.5902439,
    v: 0.8039216
  },
  {
    pantoneName: "7606 C",
    color: "#D6938A",
    r: 214,
    g: 147,
    b: 138,
    h: 0.0197368432,
    s: 0.35514015,
    v: 0.8392157
  },
  {
    pantoneName: "501 C",
    color: "#DAA5AD",
    r: 218,
    g: 165,
    b: 173,
    h: 0.9748428,
    s: 0.243119255,
    v: 0.854901969
  },
  {
    pantoneName: "200 C",
    color: "#BA0C2F",
    r: 186,
    g: 12,
    b: 47,
    h: 0.96647507,
    s: 0.9354839,
    v: 0.7294118
  },
  {
    pantoneName: "7621 C",
    color: "#AB2328",
    r: 171,
    g: 35,
    b: 40,
    h: 0.9938725,
    s: 0.795321643,
    v: 0.670588255
  },
  {
    pantoneName: "7608 C",
    color: "#A4493D",
    r: 164,
    g: 73,
    b: 61,
    h: 0.0194174778,
    s: 0.628048837,
    v: 0.6431373
  },
  {
    pantoneName: "4995 C",
    color: "#9C6169",
    r: 156,
    g: 97,
    b: 105,
    h: 0.977401137,
    s: 0.37820515,
    v: 0.6117647
  },
  {
    pantoneName: "7530 C",
    color: "#A39382",
    r: 163,
    g: 147,
    b: 130,
    h: 0.08585858,
    s: 0.202453971,
    v: 0.6392157
  },
  {
    pantoneName: "1815 C",
    color: "#7C2529",
    r: 124,
    g: 37,
    b: 41,
    h: 0.992337167,
    s: 0.7016129,
    v: 0.4862745
  },
  {
    pantoneName: "1815 C",
    color: "#7C2529",
    r: 124,
    g: 37,
    b: 41,
    h: 0.992337167,
    s: 0.7016129,
    v: 0.4862745
  },
  {
    pantoneName: "7609 C",
    color: "#823B34",
    r: 130,
    g: 59,
    b: 52,
    h: 0.0149572613,
    s: 0.6,
    v: 0.509803951
  },
  {
    pantoneName: "4985 C",
    color: "#874B52",
    r: 135,
    g: 75,
    b: 82,
    h: 0.980555534,
    s: 0.444444448,
    v: 0.5294118
  },
  {
    pantoneName: "7615 C",
    color: "#866761",
    r: 134,
    g: 103,
    b: 97,
    h: 0.02702702,
    s: 0.276119441,
    v: 0.5254902
  },
  {
    pantoneName: "4625 C",
    color: "#4F2C1D",
    r: 79,
    g: 44,
    b: 29,
    h: 0.049999997,
    s: 0.632911444,
    v: 0.309803933
  },
  {
    pantoneName: "4625 C",
    color: "#4F2C1D",
    r: 79,
    g: 44,
    b: 29,
    h: 0.049999997,
    s: 0.632911444,
    v: 0.309803933
  },
  {
    pantoneName: "490 C",
    color: "#5D2A2C",
    r: 93,
    g: 42,
    b: 44,
    h: 0.993464053,
    s: 0.5483871,
    v: 0.3647059
  },
  {
    pantoneName: "7596 C",
    color: "#5C3D31",
    r: 92,
    g: 61,
    b: 49,
    h: 0.046511624,
    s: 0.4673913,
    v: 0.360784322
  },
  {
    pantoneName: "438 C",
    color: "#584446",
    r: 88,
    g: 68,
    b: 70,
    h: 0.983333349,
    s: 0.2272727,
    v: 0.345098048
  }
]

export default PANTONE_COLOURS_MAP
