import React, { useMemo } from "react"

import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { getFormattedExpiryDate } from "../../utils/wallet.helpers"
import { walletI18n } from "../../utils/wallet.translations"
import { useFormatMessage } from "../../../../../libs/others/i18n"

import cxBinder from "classnames/bind"
import { FormattedHTMLMessage } from "react-intl"

import styles from "./SortedAccounts.module.scss"
import { FormattedCurrency } from "../../../../../shared-components/formatted-currency/FormattedCurrency"

const cx = cxBinder.bind(styles)

export const SortedAccounts = ({ accounts, isHorizontal }) => {
  const translate = useFormatMessage()
  const sortedAccounts = useMemo(
    () =>
      accounts &&
      accounts.sort(({ expires_at: a }, { expires_at: b }) => (a > b ? 1 : -1)),
    [accounts]
  )

  return (
    <>
      {sortedAccounts &&
        sortedAccounts.map((account, index) => {
          const parsedAmount = parseInt(account.amount)

          if (!parsedAmount) return

          const key = `${account.expires_at}_${index}`

          const formattedExpiryDate = getFormattedExpiryDate(account.expires_at)

          return (
            <div
              key={key}
              className={cx({
                connection: !isHorizontal,
                "connection-horizontal": isHorizontal,
              })}
            >
              <div className={styles["money-wallet"]}>
                <div className={styles["content"]}>
                  <FormattedCurrency
                    currency={account.currency}
                    value={parsedAmount}
                  />

                  <Typography
                    type={TType.Header15_500}
                    e2e-target="wallet-expiry-date"
                  >
                    <FormattedHTMLMessage
                      id={walletI18n.expires}
                      values={{ date: formattedExpiryDate }}
                    />
                  </Typography>

                  <Typography
                    type={TType.Header15_500}
                    className={styles["program-name"]}
                  >
                    {account.name ||
                      translate({ id: walletI18n.loyaltyProgram })}
                  </Typography>
                </div>
              </div>
            </div>
          )
        })}
    </>
  )
}
