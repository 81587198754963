import { Session } from "../stores/session-store/session.types"
import { getApiTokenFromCookieWithFallback } from "shared-libs/src/js/libs/helpers/api-token.helpers"

export class SessionService {
  constructor(private readonly isUserDataFetchable: boolean) {}

  public async getSession(): Promise<Session> {
    if (this.isUserDataFetchable) {
      return this.getSessionFromApi()
    }

    return {
      token: await getApiTokenFromCookieWithFallback(),
      user: {},
    }
  }

  private async getSessionFromApi(): Promise<Session> {
    const { default: sharedStoresService } = await import(
      "shared-libs/src/js/libs/services/shared-stores-service/SharedStoresService.js"
    )
    const { user } = await sharedStoresService.userRepository.getUser()

    return {
      token: user.spree_api_key,
      user,
    }
  }
}
