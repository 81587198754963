import React from "react"
import { App } from "./App"
import { EditorSnowAppWrapper } from "./_containers-react/_AppBootstrap"

export const AppPreload = () => {
  return (
    <EditorSnowAppWrapper>
      <App />
    </EditorSnowAppWrapper>
  )
}
