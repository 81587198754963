var _a;
import PAPER_TUBE_CONSTS from "../generalConfig/consts/paperTubeConsts";
var POLY_MAILER_PRODUCTS_EDITOR_LIMITATIONS = {
    shared: {
        initialActiveSide: {
            activeSideId: "front",
            editedSideId: "front",
        },
        isPantoneColorsAvailable: true,
        showSideExtraPrice: true,
        sidesNameCodesMap: {
            front: "A",
            back: "B",
        },
        reforestationSupport: {
            badge: true,
            trees: true,
        },
    },
    products: (_a = {},
        _a[PAPER_TUBE_CONSTS.PAPER_CAN] = {
            productEditorMode: "2D",
            is3dEditorDisabled: false,
        },
        _a),
};
export default POLY_MAILER_PRODUCTS_EDITOR_LIMITATIONS;
