import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { SceneUIMessageController } from "./messages-bar/MessagesBarController"
import { SceneUIRightColumnController } from "./right-column/RightColumnController"
import { SceneUIFooterController } from "./footer/FooterController"
import { SceneUIRightMessageController } from "./right-messages-bar/RightMessagesBarController"
import { SceneUIGlobalDropZoneController } from "./flying-dropzone/GlobalDropZoneController"
import styles from "./SceneUIController.module.scss"
import { UniversalHeader } from "./header/UniversalHeader"
import cxBinder from "classnames/bind"
import { EditorSecondaryTopBar } from "../../dsl/organisms/EditorHeader/EditorSecondaryTopBar"
import { DbyUploader } from "./dby-uploader/DbyUploader"
import { useContainerSet } from "../../../_containers-react/_editor-app-hooks"
import { UniversalEditorToolbarWired } from "../../dsl/organisms/toolbars/UniversalEditorToolbar.tsx/UniversalEditorToolbar"
import { DesignAndProductDriverContainer } from "../../../_containers/design-and-product-driver-container"
import { UiContainer } from "../../../_containers/ui-container"
import { AnalyticsMaybeContainer } from "../../../_containers/analytics-maybe-container"
import { ViewMode } from "../../../libs/products-render-config/types"
import { filterMenuItems } from "../../dsl/organisms/toolbars/toolbar-item-functions"
import { availableEditorToolbarItems } from "./available-editor-toolbar-items.config"

const cx = cxBinder.bind(styles)

interface MemoizedSpaceControllerProps {
  is3DPreviewOn: boolean
  isEditMode: boolean
}

const MemoizedSpaceController = observer(
  ({ is3DPreviewOn, isEditMode }: MemoizedSpaceControllerProps) => {
    const [headerSet, headerSetErrors] = useContainerSet((c) => [
      c.designAndProductDriver,
      c.envUtil,
      c.replicablePatterns,
      c.dbyMode,
      c.ui,
      c.tooling,
    ])

    if (headerSet == null || headerSetErrors != null) return null

    const {
      designAndProductDriver,
      tooling,
      envUtil,
      replicablePatterns,
      dbyMode,
      ui,
    } = headerSet

    const { editorToolbarStore, editorModeController } = ui

    const { productDriver, assetStore, contextController } =
      designAndProductDriver
    const replicablePatternUiController =
      replicablePatterns.available &&
      replicablePatterns.replicablePatternUiController

    const { productRenderPilot } = productDriver.state
    const { dbyModeController, dbyModeStore } = dbyMode

    const { uri } = envUtil

    const isSceneDisabled = contextController.isSceneDisabled()
    const isDtpPreviewMode = uri.isDtpPreviewMode()
    const isDbyMode = productDriver.state.productRenderPilot.isDbyMode()
    const isProductDbyOnly = productDriver.state.productRenderPilot.isDbyOnly()
    const devToolsEnabled = !!tooling?.available

    const { activeSpace, activeContext, activeViewType } = productDriver.state

    const menuItems = filterMenuItems({
      items: availableEditorToolbarItems.filter((menuItem) =>
        ui.editorToolbarStore.availableToolbarItems.includes(menuItem.name)
      ),
      productRenderPilot,
      activeViewType,
      activeContext,
      activeSpace,
      isDevToolsEnabled: devToolsEnabled,
    })
    return (
      <div className={styles.scene_ui_controller}>
        {!is3DPreviewOn && (
          <>
            <div className={styles.header_row}>
              <UniversalHeader />
              <SceneUIMessageController />
            </div>

            <div className={styles.secondary_bar_row}>
              <EditorSecondaryTopBar />
            </div>

            <div
              className={cx({
                scene_row: true,
                "scene_row--preview_mode": !isEditMode,
              })}
            >
              {isEditMode && (
                <>
                  {isSceneDisabled && (
                    <div className={styles["prevent-editing_overlay"]} />
                  )}

                  <UniversalEditorToolbarWired />
                </>
              )}
              {!isDbyMode && (
                <div className={styles.right_ui_column}>
                  {isEditMode && <SceneUIRightMessageController />}

                  {(isEditMode || isDtpPreviewMode) && (
                    <SceneUIRightColumnController />
                  )}
                </div>
              )}
              {isDbyMode && (
                <DbyUploader
                  menuItemsAvailable={menuItems.length > 0}
                  dbyMode={dbyMode}
                  isEditMode={isEditMode}
                  editorModeController={editorModeController}
                  isProductDbyOnly={isProductDbyOnly}
                  productRenderPilot={productRenderPilot}
                />
              )}
            </div>
          </>
        )}

        {productRenderPilot.isViewModeAvailable(ViewMode.THREE_DIMENSIONAL) && (
          <SceneUIFooterController />
        )}

        {isEditMode && (
          <SceneUIGlobalDropZoneController
            productDriver={productDriver}
            assetStore={assetStore}
            replicablePatternUiController={
              replicablePatternUiController || undefined
            }
            editorToolbarStore={editorToolbarStore}
            dbyModeController={dbyModeController}
            dbyModeStore={dbyModeStore}
          />
        )}
      </div>
    )
  }
)

export const UISceneController = observer(
  ({
    designAndProductDriverContainer,
    uiContainer,
    analyticsContainer,
  }: {
    designAndProductDriverContainer: DesignAndProductDriverContainer
    uiContainer: UiContainer
    analyticsContainer: AnalyticsMaybeContainer
  }) => {
    const { productDesignStore, productDriver } =
      designAndProductDriverContainer
    const { editorToolbarController } = uiContainer
    const isEditMode = productDesignStore.isEditMode
    const is3DPreviewOn = productDriver.state.is3DPreviewOn
    const { actionTracker } = analyticsContainer

    useEffect(() => {
      actionTracker?.trackEditorViewed(window.location.href)
    }, []) // window.location.href should not be a hook dep here

    useEffect(() => {
      editorToolbarController.selectInitialTab()
    }, [])

    return (
      <MemoizedSpaceController
        is3DPreviewOn={is3DPreviewOn}
        isEditMode={isEditMode}
      />
    )
  }
)
