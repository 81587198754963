import React from "react"
import { TType } from "dsl/src/atoms/Typography/Typography"
import { Button } from "dsl/src/atoms/Button/Button"

import styles from "./Unauthorized.module.scss"
import { Header } from "../components/Header"

import { TM } from "../../../../TypographyI18n"
import { useTranslate } from "../../../../hooks/useTranslate"
import { useContainer } from "../../../../../_containers-react/_editor-app-hooks"

export const Unauthorized = () => {
  const t = useTranslate()
  const [envUtil] = useContainer().envUtil

  if (!envUtil) {
    return null
  }

  return (
    <div className={styles.unauthorized}>
      <Header />
      <div>
        <div className={styles.content}>
          <div className={styles.title}>
            <TM
              id={"component.unauthorized.header"}
              type={TType.Header40_400}
            />
          </div>
          <div className={styles.description}>
            <TM
              id={"component.unauthorized.description"}
              type={TType.Body17_350}
            />
          </div>
          <div className={styles.button}>
            <Button onClick={() => envUtil.uri.redirectToHomepage()}>
              {t("component.unauthorized.go-to-home-page")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Unauthorized
