var _a, _b, _c, _d, _e;
import TISSUE_PAPER_CONSTS from "../consts/tissuePaperConsts";
var DEFAULT = "default";
var BAG_PRODUCTS_WEIGHTS = (_a = {},
    _a[TISSUE_PAPER_CONSTS.TISSUE_PAPER] = (_b = {},
        _b[TISSUE_PAPER_CONSTS.TISSUE_PAPER_VARIANT_STANDARD] = (_c = {},
            _c[DEFAULT] = {
                T120: 0.065,
            },
            _c),
        _b),
    _a[TISSUE_PAPER_CONSTS.PACKING_PAPER] = (_d = {},
        _d[TISSUE_PAPER_CONSTS.PACKING_PAPER_VARIANT_STANDARD] = (_e = {},
            _e[DEFAULT] = {
                T120: 0.065,
            },
            _e),
        _d),
    _a);
export default BAG_PRODUCTS_WEIGHTS;
