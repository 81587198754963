var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import editorConfig from "./editorConfig/editorConfig";
import generalConfig from "./generalConfig/generalConfig";
import renderConfig from "./renderConfig/renderConfig";
var ENVELOPE_CONFIG_SOURCE = __assign(__assign(__assign({}, editorConfig), generalConfig), renderConfig);
export default ENVELOPE_CONFIG_SOURCE;
