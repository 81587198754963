import { Sentry } from "shared-libs/src/js/libs/services/sentry-service"
import * as dayjs from "dayjs"

import("dayjs/locale/de")
export const localeMap = new Map<any, () => Promise<any>>([
  // @ts-ignore
  ["pl", () => import("dayjs/locale/pl")],
  // @ts-ignore
  ["cs", () => import("dayjs/locale/cs")],
  // @ts-ignore
  ["de", () => import("dayjs/locale/de")],
  // @ts-ignore
  ["es", () => import("dayjs/locale/es")],
  // @ts-ignore
  ["fr", () => import("dayjs/locale/fr")],
  // @ts-ignore
  ["it", () => import("dayjs/locale/it")],
  // @ts-ignore
  ["nl", () => import("dayjs/locale/nl")],
  // @ts-ignore
  ["sv", () => import("dayjs/locale/sv")],
  // @ts-ignore
  ["ro", () => import("dayjs/locale/ro")],
])

export const loadDayJsLocale = async (locale) => {
  if (locale === "en") return
  const loadLocale = localeMap.get(locale)

  if (!loadLocale) return
  try {
    await loadLocale()
    dayjs.locale(locale)
  } catch (e) {
    Sentry.captureException(e)
  }
}
