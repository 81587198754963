import React from "react"
import Modal, { InnerSize } from "../Modal"
import ShareDesign, { ShareDesignProps } from "./ShareDesign"

const ShareDesignModal = ({
  onClose,
  onSendEmailShare,
  shareDesign,
  isSaving,
  isSharing,
  isSharedWithEmail,
  isSharingWithEmail,
  shareWithEmailError,
  isPublic,
}: ShareDesignProps) => (
  <Modal
    onClose={onClose}
    e2eTargetName="share-design"
    maxWidth={InnerSize.small}
  >
    <ShareDesign
      onClose={onClose}
      isPublic={isPublic}
      shareWithEmailError={shareWithEmailError}
      isSharingWithEmail={isSharingWithEmail}
      isSharedWithEmail={isSharedWithEmail}
      onSendEmailShare={onSendEmailShare}
      shareDesign={shareDesign}
      isSaving={isSaving}
      isSharing={isSharing}
    />
  </Modal>
)

export { ShareDesignModal as default }
