import React from "react"
import cxBinder from "classnames/bind"
import styles from "./Loader.module.scss"
const cx = cxBinder.bind(styles)

export enum LoaderTypes {
  circular = "circular",
  vanilla = "vanilla" // funny looking square no1 likes, which will be here long after we are dust
}

export enum LoaderSizes {
  small = "small",
  medium = "medium",
  large = "large"
}

export enum LoaderColors {
  blue = "blue",
  gray = "gray"
}

interface LoaderProps {
  type: LoaderTypes
  size?: LoaderSizes
  light?: boolean
  color?: LoaderColors
}

const Loader = (props: LoaderProps) => {
  const {
    type,
    size = LoaderSizes.small,
    light,
    color = LoaderColors.blue
  } = props

  return (
    <span
      className={cx(`loader-${type}`, `size-${size}`, `color-${color}`, {
        "loader--light": light
      })}
    />
  )
}

export { Loader, Loader as default }
