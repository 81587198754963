var _a;
import TAPE_CONSTS from "../consts/tapeConsts";
var BAG_PRODUCT_DIMENSIONS = (_a = {},
    _a[TAPE_CONSTS.TAPE_MODEL_PACKING_TAPE] = {
        X48: {
            outside: {
                width: 60350,
                height: 48,
            },
        },
    },
    _a[TAPE_CONSTS.TAPE_MODEL_KRAFT_TAPE] = {
        X50: {
            outside: {
                width: 50000,
                height: 48,
                heightPossibleRange: {
                    min: 48,
                    max: 50,
                },
            },
        },
    },
    _a[TAPE_CONSTS.TAPE_MODEL_PRE_PRINTED_KRAFT_TAPE] = {
        X50: {
            outside: {
                width: 50000,
                height: 48,
                heightPossibleRange: {
                    min: 48,
                    max: 50,
                },
            },
        },
    },
    _a);
export default BAG_PRODUCT_DIMENSIONS;
