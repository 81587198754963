import React from "react"
import cxBinder from "classnames/bind"
import {
  walletI18n,
  walletMinimalStepDiscount,
} from "../../utils/wallet.translations"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { TM } from "../../../../../shared-components/i18n/TypographyWithTranslate"
import styles from "./NoCurrentStep.module.scss"

const cx = cxBinder.bind(styles)

export const NoCurrentStep = ({ isHorizontal }) => {
  return (
    <div
      className={cx({
        connection: !isHorizontal,
        "connection-horizontal": isHorizontal,
      })}
    >
      <div className={styles["money-wallet"]}>
        <div className={styles["content"]}>
          <Typography type={TType.Header17_500} htmlElement="span">
            {walletMinimalStepDiscount}%
          </Typography>

          <TM type={TType.Header15_500} id={walletI18n.tooltipCashback} />

          <TM
            type={TType.Header15_500}
            id={walletI18n.loyaltyProgram}
            className={styles["program-name"]}
          />
        </div>
      </div>
    </div>
  )
}
