import React, { useRef, useState } from "react"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import ColourPicker, {
  ColourPickerProps,
} from "../../ColourPicker/ColourPicker"
import { PickerToggle } from "../../../atoms/PickerToggle/PickerToggle"

import styles from "./ColorConfiguratorInline.module.scss"
import PantonePicker from "../../PantonePicker/PantonePicker"
import {
  ColorListArray,
  OnApplyColor,
  PantonePickerColorObject,
} from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { Colour } from "../../../../../libs/value-objects/colour"
import { colorsListDemo } from "../../ColourPicker/colors-list"
import { PantoneColorsDatabase } from "../../../../../modules/colors/pantone-colors-database"
import {
  AvailableColourModes,
  PantoneColorsPreset,
} from "../../../../../libs/products-render-config/types"
import { useContainer } from "../../../../../_containers-react/_editor-app-hooks"

const defaultColorsList = colorsListDemo.map((color) => {
  return {
    color: new Colour(color.color).getHex(),
    uid: color.uid,
    description: "muu",
    pantoneName: new Colour(color.color).getPantoneName(),
  }
})

interface ColorConfiguratorProps
  extends Omit<
    ColourPickerProps,
    "selectedColor" | "colorsList" | "onDoneClick"
  > {
  itemName?: React.ReactNode
  preventHideClickOutside?: boolean
  selectedColor: Colour | undefined
  onClick?: () => void
  colorsList?: ColorListArray[]
  isInline?: boolean
  buttonText?: string
  header?: string
  isOpenOnInit?: boolean
  pantoneColorsPreset?: PantoneColorsPreset
}

interface PantoneConfiguratorProps {
  selectedColor: Colour | undefined
  onColorSelect: (color: Colour) => void
  onBack: (e) => void
  onDone: () => void
  pantoneColorsPreset?: PantoneColorsPreset
  isInline?: boolean
  header?: string
  buttonText?: string
}
export const PantoneConfigurator = ({
  selectedColor,
  onColorSelect,
  onBack,
  onDone,
  pantoneColorsPreset,
  buttonText,
  header,
  isInline,
}: PantoneConfiguratorProps) => {
  const pantoneDb = new PantoneColorsDatabase()

  const getSelectedPantoneColor = (): PantonePickerColorObject | undefined => {
    if (!selectedColor) return undefined
    return pantoneDb.getByColour(selectedColor)
  }

  return (
    <PantonePicker
      selectedColor={getSelectedPantoneColor()}
      onApplyColor={(pantoneColor: OnApplyColor) => {
        onColorSelect(new Colour(pantoneColor.color, pantoneColor.pantoneName))
      }}
      onBackClick={onBack}
      onDoneClick={onDone}
      pantoneColorsPreset={pantoneColorsPreset}
      buttonText={buttonText}
      isInline={isInline}
      header={header}
    />
  )
}

const HexConfigurator = ({
  selectedColor,
  onColorSelect,
  onBack,
  onDone,
  colorsList = defaultColorsList,
  buttonText,
  isInline,
  header,
}: {
  selectedColor: Colour | undefined
  onColorSelect: (color: Colour) => void
  onBack: (e) => void
  onDone: () => void
  colorsList?: ColorListArray[]
  buttonText?: string
  isInline?: boolean
  header?: string
}) => {
  return (
    <ColourPicker
      onDoneClick={onDone}
      onColorSelect={onColorSelect}
      onBackClick={onBack}
      selectedColor={selectedColor ? selectedColor.getHex() : undefined}
      colorsList={colorsList}
      buttonText={buttonText}
      isInline={isInline}
      header={header}
    />
  )
}

export const ColorConfiguratorInline = ({
  onBackClick,
  selectedColor,
  colorsList,
  itemName,
  onColorSelect,
  onClick,
  isInline,
  header,
  buttonText,
  isOpenOnInit = false,
  pantoneColorsPreset,
}: ColorConfiguratorProps) => {
  const wrapperRef = useRef<any>(null)
  const [isOpen, setIsOpen] = useState(isOpenOnInit)

  const [designAndProductDriverContainer] =
    useContainer().designAndProductDriver

  if (!designAndProductDriverContainer) {
    return null
  }
  const { productDriver } = designAndProductDriverContainer

  const { productRenderPilot } = productDriver.state

  const isMonoPantone =
    productRenderPilot.getColorMode() === AvailableColourModes.MONO_PANTONE

  const onPickerClick = (e) => {
    setIsOpen(!isOpen)

    if (onClick) {
      onClick()
    }
  }

  return (
    <div className={styles.colorpicker_wrapper} ref={wrapperRef}>
      <div className={styles.editor_header__colorpicker_nav}>
        {itemName && (
          <Typography
            type={TType.Header17_500}
            onClick={onPickerClick}
            className={styles.item_name}
          >
            {itemName}
          </Typography>
        )}
        <PickerToggle
          background={selectedColor ? selectedColor.getHex() : undefined}
          onClick={onPickerClick}
          e2eTargetName="overrided-pattern-color"
          isHighlited={isOpen}
        />
      </div>
      <div>
        {isOpen && (
          <>
            {isMonoPantone ? (
              <>
                <PantoneConfigurator
                  selectedColor={selectedColor}
                  onColorSelect={onColorSelect}
                  onBack={onBackClick}
                  onDone={() => {
                    setIsOpen(!isOpen)
                  }}
                  pantoneColorsPreset={
                    pantoneColorsPreset ||
                    productRenderPilot.getPantoneColorsPreset()
                  }
                  isInline={isInline}
                  header={header}
                  buttonText={buttonText}
                />
              </>
            ) : (
              <>
                <HexConfigurator
                  selectedColor={selectedColor}
                  onColorSelect={onColorSelect}
                  onBack={onBackClick}
                  onDone={() => {
                    setIsOpen(!isOpen)
                  }}
                  colorsList={colorsList}
                  buttonText={buttonText}
                  isInline={isInline}
                  header={header}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}
