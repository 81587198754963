import React, { FC } from "react"

import styles from "../ShareDesign.module.scss"

import ModalTemplateConfirm from "../../partials/ModalTemplateConfirm"
import Button from "dsl/src/atoms/Button/Button"
import { useTranslate } from "../../../../../hooks/useTranslate"

export interface DesignNotSharedProps {
  onClose: () => void
  isSaving: boolean
  shareDesign: () => void
}

const DesignNotShared: FC<DesignNotSharedProps> = ({
  onClose,
  shareDesign,
  isSaving,
}) => {
  const t = useTranslate()
  return (
    <ModalTemplateConfirm
      onClose={onClose}
      title={t("modals.share-box-design.title")}
      buttonComponent={
        <Button
          isLoading={isSaving}
          onClick={() => {
            shareDesign()
          }}
        >
          {t("modals.share-box-design.share-c2a")}
        </Button>
      }
      withCancel
      cancelLinkText={t("modals.share-box-design.cancel")}
    >
      <p className={styles.desc}>
        {t("modals.share-box-design.share-status.not-shared")}
      </p>
      <p className={styles.desc}>
        {t("modals.share-box-design.share-status.not-shared-explainer")}
      </p>
    </ModalTemplateConfirm>
  )
}

export { DesignNotShared as default }
