// noinspection JSStringConcatenationToES6Template
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
var assetsPath = "/product-key-visuals/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var customPath = "/product-custom-dimensions/";
import BOX_CONSTS from "../consts/boxConsts";
var prePrintedSizeAssets = {
    F52: {
        desktop: desktopPath + "F52.png",
        mobile: mobilePath + "F52.png",
    },
    F71: {
        desktop: desktopPath + "F71.png",
        mobile: mobilePath + "F71.png",
    },
};
var sizeAssets = {
    F23: {
        desktop: desktopPath + "F23.png",
        mobile: mobilePath + "F23.png",
    },
    F33: {
        desktop: desktopPath + "F33.png",
        mobile: mobilePath + "F33.png",
    },
    F44: {
        desktop: desktopPath + "F44.png",
        mobile: mobilePath + "F44.png",
    },
    F45: {
        desktop: desktopPath + "F45.png",
        mobile: mobilePath + "F45.png",
    },
    F46: {
        desktop: desktopPath + "F46.png",
        mobile: mobilePath + "F46.png",
    },
    F52: {
        desktop: desktopPath + "F52.png",
        mobile: mobilePath + "F52.png",
    },
    F56: {
        desktop: desktopPath + "F56.png",
        mobile: mobilePath + "F56.png",
    },
    F59: {
        desktop: desktopPath + "F59.png",
        mobile: mobilePath + "F59.png",
    },
    F62: {
        desktop: desktopPath + "F62.png",
        mobile: mobilePath + "F62.png",
    },
    F65: {
        desktop: desktopPath + "F65.png",
        mobile: mobilePath + "F65.png",
    },
    F71: {
        desktop: desktopPath + "F71.png",
        mobile: mobilePath + "F71.png",
    },
    F79: {
        desktop: desktopPath + "F79.png",
        mobile: mobilePath + "F79.png",
    },
    F93: {
        desktop: desktopPath + "F93.png",
        mobile: mobilePath + "F93.png",
    },
    F112: {
        desktop: desktopPath + "F112.png",
        mobile: mobilePath + "F112.png",
    },
    custom: {
        desktop: customPath + "MailerBox/Desktop/Custom/{locale}.png",
        mobile: customPath + "MailerBox/Mobile/Custom/{locale}.png",
    },
};
var sleevedMailerBoxesSizeAssets = {
    FS23: {
        desktop: desktopPath + "FS23.png",
        mobile: mobilePath + "FS23.png",
    },
    FS33: {
        desktop: desktopPath + "FS33.png",
        mobile: mobilePath + "FS33.png",
    },
    FS44: {
        desktop: desktopPath + "FS44.png",
        mobile: mobilePath + "FS44.png",
    },
    FS45: {
        desktop: desktopPath + "FS45.png",
        mobile: mobilePath + "FS45.png",
    },
    FS46: {
        desktop: desktopPath + "FS46.png",
        mobile: mobilePath + "FS46.png",
    },
    FS52: {
        desktop: desktopPath + "FS52.png",
        mobile: mobilePath + "FS52.png",
    },
    FS56: {
        desktop: desktopPath + "FS56.png",
        mobile: mobilePath + "FS56.png",
    },
    FS59: {
        desktop: desktopPath + "FS59.png",
        mobile: mobilePath + "FS59.png",
    },
    FS62: {
        desktop: desktopPath + "FS62.png",
        mobile: mobilePath + "FS62.png",
    },
    FS65: {
        desktop: desktopPath + "FS65.png",
        mobile: mobilePath + "FS65.png",
    },
    FS71: {
        desktop: desktopPath + "FS71.png",
        mobile: mobilePath + "FS71.png",
    },
    FS79: {
        desktop: desktopPath + "FS79.png",
        mobile: mobilePath + "FS79.png",
    },
    custom: {
        desktop: customPath + "SleevedMailerBox/Desktop/Custom/{locale}.png",
        mobile: customPath + "SleevedMailerBox/Mobile/Custom/{locale}.png",
    },
};
var deliveryMailerBoxAssets = {
    AS55: {
        desktop: desktopPath + "AS55.png",
        mobile: mobilePath + "AS55.png",
    },
    AS73: {
        desktop: desktopPath + "AS73.png",
        mobile: mobilePath + "AS73.png",
    },
    AS80: {
        desktop: desktopPath + "AS80.png",
        mobile: mobilePath + "AS80.png",
    },
    AS91: {
        desktop: desktopPath + "AS91.png",
        mobile: mobilePath + "AS91.png",
    },
    custom: {
        desktop: customPath + "MailerBox/Desktop/Custom/{locale}.png",
        mobile: customPath + "MailerBox/Mobile/Custom/{locale}.png",
    },
};
export default (_a = {},
    _a[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR] = (_b = {},
        _b[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR_VARIANT] = {
            keyVisual: [assetsPath + "pre_printed_mailer_box_full_colour.png"],
            sizeAssets: prePrintedSizeAssets,
        },
        _b),
    _a[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO] = (_c = {},
        _c[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO_VARIANT] = {
            keyVisual: [assetsPath + "pre_printed_mailer_box_eco.png"],
            sizeAssets: prePrintedSizeAssets,
        },
        _c),
    _a[BOX_CONSTS.MAILER_BOX] = (_d = {},
        _d[BOX_CONSTS.MAILER_BOX_ECO_VARIANT] = {
            keyVisual: [assetsPath + "mailerbox_eco_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _d[BOX_CONSTS.MAILER_BOX_ECO_WHITE_VARIANT] = {
            keyVisual: [assetsPath + "mailerbox_ecowhite_desktop_one_box.png"],
            sizeAssets: sizeAssets,
        },
        _d[BOX_CONSTS.MAILER_BOX_ECO_COLOR_VARIANT] = {
            keyVisual: [assetsPath + "mailerbox_eco_color_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _d[BOX_CONSTS.MAILER_BOX_FULL_COLOR_VARIANT] = {
            keyVisual: [assetsPath + "mailerbox_full_color_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _d[BOX_CONSTS.MAILER_BOX_LITE_COLOR] = {
            keyVisual: [assetsPath + "mailerbox_lite_color_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _d),
    _a[BOX_CONSTS.MAILER_BOX_WHITE_INK] = (_e = {},
        _e[BOX_CONSTS.MAILER_BOX_WHITE_ON_KRAFT_VARIANT] = {
            keyVisual: [assetsPath + "mailerbox_white_ink.png"],
            sizeAssets: sizeAssets,
        },
        _e),
    _a[BOX_CONSTS.MAILER_BOX_PLAIN] = (_f = {},
        _f[BOX_CONSTS.MAILER_BOX_PLAIN_DEFAULT_VARIANT] = {
            keyVisual: [assetsPath + "mailerbox_plain_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _f[BOX_CONSTS.MAILER_BOX_PLAIN_WHITE_VARIANT] = {
            keyVisual: [assetsPath + "mailerbox_plainwhite_desktop.png"],
            sizeAssets: sizeAssets,
        },
        _f),
    _a[BOX_CONSTS.SLEEVED_MAILER_BOX] = (_g = {},
        _g[BOX_CONSTS.SLEEVED_MAILER_BOX_VARIANT] = {
            keyVisual: [assetsPath + "box_sleeve_desktop.png"],
            sizeAssets: sleevedMailerBoxesSizeAssets,
        },
        _g),
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX] = (_h = {},
        _h[BOX_CONSTS.DELIVERY_MAILER_BOX_VARIANT] = {
            keyVisual: [assetsPath + "delivery_mailerbox.png"],
            sizeAssets: deliveryMailerBoxAssets,
        },
        _h),
    _a[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN] = (_j = {},
        _j[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN_VARIANT] = {
            keyVisual: [assetsPath + "delivery_mailerbox.png"],
            sizeAssets: deliveryMailerBoxAssets,
        },
        _j[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN_WHITE_VARIANT] = {
            keyVisual: [assetsPath + "delivery_mailerbox.png"],
            sizeAssets: deliveryMailerBoxAssets,
        },
        _j),
    _a);
