import type { DbyModeStore } from "../stores/dby-mode-store/dby-mode-store"
import { DbyModeController } from "../stores/_controllers/dby-mode-controller"
import { UtilEnvContainer } from "./util-container"

async function importDeps() {
  const { DbyModeStore } = await import(
    "../stores/dby-mode-store/dby-mode-store"
  )
  const { DbyModeController } = await import(
    "../stores/_controllers/dby-mode-controller"
  )
  return { DbyModeController, DbyModeStore }
}

export async function provideDbyModeContainer(
  utilEnvContainer: UtilEnvContainer
): Promise<DbyModeContainer> {
  const { DbyModeController, DbyModeStore } = await importDeps()
  const { ee } = utilEnvContainer

  const dbyModeStore = new DbyModeStore()
  const dbyModeController = new DbyModeController(dbyModeStore, ee)

  return {
    dbyModeStore,
    dbyModeController,
  }
}

export type DbyModeContainer = {
  dbyModeStore: DbyModeStore
  dbyModeController: DbyModeController
}
