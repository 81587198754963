import { CoveringMaskConfigExport, MaskConfig, MaskType } from "../types"
import { MaskCalculator } from "../calculators/mask-calculator"
import { BaseMaskController } from "./base-mask-controller"
import { PackhelpMaskObject } from "../../../../object-extensions/packhelp-objects"

export class CoveringMaskController extends BaseMaskController {
  public getConfigExport(): CoveringMaskConfigExport | undefined {
    if (!this.mask || !this.mask.canvas) {
      return
    }

    return {
      type: MaskType.covering,
      maskObjectId: this.mask.id,
      assetClipping: this.config.assetClipping,
      minimalMargin: this.config.minimalMargin,
      isEditingDisabled: this.config.isEditingDisabled,
    }
  }

  public getType(): MaskType {
    return MaskType.covering
  }

  public async init(config: Partial<MaskConfig> = {}) {
    this.updateConfig(config)
    await this.initMask()
  }

  public async load(config: MaskConfig) {
    this.updateConfig(config)
    await this.initMask(config.shape)
  }

  protected async initMask(mask?: PackhelpMaskObject) {
    if (!mask) {
      this.mask = await this.maskBuilder.buildMask(
        this.config,
        MaskType.covering
      )
      this.showMaskBorder()
      await this.applyMask(this.mask)
    } else {
      this.mask = mask
      this.mask.set(this.maskBuilder.UIParams)
    }

    this.mask.set({
      lockUniScaling: !!this.mask.keepRatio,
      indexConfig: this.maskParent.indexConfig,
    })

    this.maskCalculator = new MaskCalculator(this.maskParent, this.mask)

    this.setMaskVisibility(this.maskParent.visible!)
    this.recalculateMargins()
    this.setMaskScale()
    this.setMaskRotationAndPosition()
    this.attachMaskEvents()

    this.maskParent.maskConfig = this.getConfigExport()
  }
}
