import ConfigBuilder from "../../configBuilders/ConfigBuilder";
import BOX_CONSTS from "../../../../source/boxProductSpec/generalConfig/consts/boxConsts";
// Global product behaviors composers
import ProductionTimeManager from "../../behaviorsComposers/ProductionTimeManager";
import GrammagesManager from "../../behaviorsComposers/GrammagesManager";
import AssetsManager from "../../behaviorsComposers/AssetsManager";
import WeightManager from "../../behaviorsComposers/WeightManager";
import WizzardManager from "../../behaviorsComposers/WizzardManager";
import QuantitiesManager from "../../behaviorsComposers/QuantitiesManager";
// Box specific behaviors composers
import BoxVariantManager from "./behaviorsComposers/BoxVariantManager";
import BoxAddonsManager from "./behaviorsComposers/BoxAddonsManager";
import BoxSiblingsManager from "./behaviorsComposers/BoxSiblingsManager";
import BoxTranslationsManager from "./behaviorsComposers/BoxTranslationsManager";
import BoxEditConfigManager from "./behaviorsComposers/BoxEditConfigManager";
import { OriginBoxesRenderConfigManager, SleevedMailerBoxRenderConfigManager, DeliveryMailerBoxRenderConfigManager, } from "./behaviorsComposers/BoxRenderConfigManager";
import { OriginBoxDimensionsManager, SleevedMailerDimensionsManager, } from "./behaviorsComposers/BoxDimensionsManager";
import ProductFactory from "../../ProductFactory";
var RenderConfigManagerFactory = function (productName, productInstance) {
    if (productName === BOX_CONSTS.SLEEVED_MAILER_BOX) {
        return new SleevedMailerBoxRenderConfigManager(productInstance);
    }
    else if (productName === BOX_CONSTS.DELIVERY_MAILER_BOX) {
        return new DeliveryMailerBoxRenderConfigManager(productInstance);
    }
    else {
        return new OriginBoxesRenderConfigManager(productInstance);
    }
};
var DimensionsManagerFactory = function (productName, productInstance) {
    if (productName === BOX_CONSTS.SLEEVED_MAILER_BOX) {
        return new SleevedMailerDimensionsManager(productInstance);
    }
    else {
        return new OriginBoxDimensionsManager(productInstance);
    }
};
var BoxProduct = /** @class */ (function () {
    function BoxProduct(sku, productName, productVariant, boxProductSpec, config) {
        if (config === void 0) { config = null; }
        if (config == null) {
            this.config = this.builder(boxProductSpec, sku, productName, productVariant);
        }
        else {
            this.config = config;
        }
        this.assetsManager = new AssetsManager(this);
        this.grammagesManager = new GrammagesManager(this);
        this.productionTimeManager = new ProductionTimeManager(this);
        this.weightsManager = new WeightManager(this);
        this.wizzardManager = new WizzardManager(this);
        this.editConfigManager = new BoxEditConfigManager(this);
        this.translationsManager = new BoxTranslationsManager(this);
        this.variantManager = new BoxVariantManager(this);
        this.addonsManager = new BoxAddonsManager(this);
        this.dimensionsManager = DimensionsManagerFactory(productName, this);
        this.renderConfigManager = RenderConfigManagerFactory(productName, this);
        this.siblingsManager = new BoxSiblingsManager(this);
        this.quantitiesManager = new QuantitiesManager(this);
    }
    BoxProduct.prototype.builder = function (spec, sku, productName, productVariant) {
        return ConfigBuilder(spec, sku, productName, productVariant);
    };
    BoxProduct.prototype.getProductExtraSizesLabels = function (size) {
        var region = this.config.skuData.decodedSKU.productRegion;
        var customLabels = {
            de: {
                65: "Maxibrief",
            },
            gb: {
                46: "Letterbox",
            },
        };
        var customLabelsForRegion = customLabels[region] || {};
        return customLabelsForRegion[size];
    };
    BoxProduct.prototype.getExtraTechnicalDetails = function () {
        return undefined;
    };
    BoxProduct.prototype.createBaseProductSku = function () {
        return this.addonsManager.setDefaultAddons();
    };
    BoxProduct.prototype.getBaseProduct = function () {
        return ProductFactory(this.createBaseProductSku());
    };
    return BoxProduct;
}());
export default BoxProduct;
