import _mapValues from "lodash/mapValues";
import boxProductSpec from "../../../source/boxProductSpec/boxProductSpec";
import ConfigBuilder from "../configBuilders/ConfigBuilder";
import { getSkuData, translateSkuPartToAttributeKey, isProductTypeCustom, } from "../SkuLib";
import BoxProduct from "../Products/BoxProduct/BoxProduct";
import CustomBoxProduct from "../CustomProducts/CustomBoxProduct";
import BoxVariantManager from "../Products/BoxProduct/behaviorsComposers/BoxVariantManager";
function BoxProductFactory(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    var productName = decodedSKU.productName;
    if (isProductTypeCustom(sku)) {
        return new CustomBoxProduct(sku, productName);
    }
    else {
        var productVariant = BoxVariantManager.findVariant(decodedSKU);
        return new BoxProduct(sku, productName, productVariant, boxProductSpec);
    }
}
export function BoxProductFactoryAsync(sku, config) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    var productName = decodedSKU.productName;
    if (isProductTypeCustom(sku)) {
        return new CustomBoxProduct(sku, productName);
    }
    else {
        var productVariant = BoxVariantManager.findVariant(decodedSKU);
        config = config || boxProductSpec;
        var boxConfig = ConfigBuilder(config, sku, productName, productVariant);
        return new BoxProduct(sku, productName, null, null, boxConfig);
    }
}
export default BoxProductFactory;
