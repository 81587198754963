import React from "react"
import { ButtonAsLink } from "dsl/src/atoms/Button/Button"
import { useTranslate } from "../../../../../shared-components/i18n/useTranslate"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { walletI18n } from "../../utils/wallet.translations"
import Link from "dsl/src/atoms/Link/Link"
import { getUserAuthUrl } from "../../utils/wallet.helpers"
import s from "./WalletSidebarLogin.module.scss"

export const WalletSidebarLogin = () => {
  const t = useTranslate()
  return (
    <>
      <ButtonAsLink fluid href={getUserAuthUrl()}>
        {t(walletI18n.valuePropositionSignInAndSave)}
      </ButtonAsLink>
      <div className={s.register}>
        <Typography type={TType.Body15_350}>
          {t(walletI18n.newToPackhelp)}{" "}
        </Typography>

        <Link href={getUserAuthUrl("register")}>
          <Typography type={TType.Body15_350}>
            {t(walletI18n.createAnAccount)}
          </Typography>
        </Link>
      </div>
    </>
  )
}
