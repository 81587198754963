import React from "react"
import cxBinder from "classnames/bind"

import styles from "./PickerToggle.module.scss"
const cx = cxBinder.bind(styles)

export enum PickerToggleType {
  oval = "oval",
  rounded = "rounded",
}

interface PickerToggleProps {
  type?: PickerToggleType
  background?: string
  content?: React.ReactNode
  onClick?: (e) => void
  e2eTarget?: string
  e2eTargetName?: string
  isHighlited?: boolean
}

export const PickerToggle = ({
  type = PickerToggleType.oval,
  background,
  content,
  onClick,
  e2eTarget = "button",
  e2eTargetName,
  isHighlited,
}: PickerToggleProps) => {
  const hasBackground = background && background !== "transparent"
  const hasContent = !!content

  return (
    <div
      className={cx({
        wrapper: type === PickerToggleType.oval,
        wrapper_highlited: isHighlited,
      })}
    >
      <div
        style={hasBackground ? { background: background } : {}}
        className={cx({
          toggle: true,
          [`toggle--${type}`]: true,
          ["base--empty"]: !hasBackground && !hasContent,
          [`toggle--empty`]: !hasBackground,
        })}
        onClick={onClick && onClick}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        {content}
      </div>
    </div>
  )
}
