var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
import NCP_CONSTS from "../consts/ncpConsts";
var DEFAULT = "default";
var NCP_PRODUCTS_WEIGHTS = (_a = {},
    _a[NCP_CONSTS.WOOD_WOOL] = (_b = {},
        _b[NCP_CONSTS.WOOD_WOOL_VARIANT] = (_c = {},
            _c[DEFAULT] = {
                W5: 5,
            },
            _c),
        _b),
    _a[NCP_CONSTS.SELF_ADHESIVE_ENVELOPE] = (_d = {},
        _d[NCP_CONSTS.SELF_ADHESIVE_ENVELOPE_VARIANT] = (_e = {},
            _e[DEFAULT] = {
                C6: 1.053,
            },
            _e),
        _d),
    _a[NCP_CONSTS.STICKER_CIRCLE] = (_f = {},
        _f[NCP_CONSTS.STICKER_CIRCLE_VARIANT] = (_g = {},
            _g[DEFAULT] = {
                SC4: 0.1686,
            },
            _g),
        _f),
    _a[NCP_CONSTS.STICKER_RECTANGLE] = (_h = {},
        _h[NCP_CONSTS.STICKER_RECTANGLE_VARIANT] = (_j = {},
            _j[DEFAULT] = {
                SR14: 0.3189,
            },
            _j),
        _h),
    _a[NCP_CONSTS.THANK_YOU_CARD] = (_k = {},
        _k[NCP_CONSTS.THANK_YOU_CARD_VARIANT] = (_l = {},
            _l[DEFAULT] = {
                A6: 0.45,
            },
            _l),
        _k),
    _a[NCP_CONSTS.PAPER_BUBBLE_WRAP] = (_m = {},
        _m[NCP_CONSTS.PAPER_BUBBLE_WRAP_VARIANT] = (_o = {},
            _o[DEFAULT] = {
                E40: 3.9,
            },
            _o),
        _m),
    _a);
export default NCP_PRODUCTS_WEIGHTS;
