var _a;
import _invert from "lodash/invert";
import ProductConsts from "./ProductConsts";
// TO STAY IN SYNC WITH ANALYTICS AND TRANSLATIONS
// SHOULD BE REMOVED ASAP
var newVariantCodeNameToOld = (_a = {},
    // Boxes
    _a[ProductConsts["BoxProductConsts"]
        .PRE_PRINTED_MAILER_BOX_FULL_COLOUR_VARIANT] = "pre-printed-mailer-box-full-colour",
    _a[ProductConsts["BoxProductConsts"].PRE_PRINTED_MAILER_BOX_ECO_VARIANT] = "pre-printed-mailer-box-eco",
    _a[ProductConsts["BoxProductConsts"].MAILER_BOX_PLAIN_DEFAULT_VARIANT] = "mailer-box-plain",
    _a[ProductConsts["BoxProductConsts"].MAILER_BOX_PLAIN_WHITE_VARIANT] = "mailer-box-plain-white",
    _a[ProductConsts["BoxProductConsts"].MAILER_BOX_ECO_VARIANT] = "mailer-box-eco",
    _a[ProductConsts["BoxProductConsts"].SLEEVED_MAILER_BOX_VARIANT] = "box-sleeve",
    _a[ProductConsts["BoxProductConsts"].SLEEVED_MAILER_BOX_VARIANT] = "sleeved-mailer-box",
    _a[ProductConsts["BoxProductConsts"].MAILER_BOX_ECO_WHITE_VARIANT] = "mailer-box-eco-white",
    _a[ProductConsts["BoxProductConsts"].MAILER_BOX_ECO_COLOR_VARIANT] = "mailer-box-eco-color",
    _a[ProductConsts["BoxProductConsts"].MAILER_BOX_FULL_COLOR_VARIANT] = "mailer-box-full-color",
    _a[ProductConsts["BoxProductConsts"].MAILER_BOX_LITE_COLOR_VARIANT] = "mailer-box-lite-color",
    _a[ProductConsts["BoxProductConsts"].MAILER_BOX_OLD_COLOR_BUDGET_VARIANT] = "mailer-box-old-color-budget",
    _a[ProductConsts["BoxProductConsts"].PRODUCT_BOX_DEFAULT_VARIANT] = "product-box",
    _a[ProductConsts["BoxProductConsts"].PRODUCT_BOX_TWO_PIECES_DEFAULT_VARIANT] = "product-box-two-pieces",
    _a[ProductConsts["BoxProductConsts"].PRODUCT_BOX_WINE_BOX_DEFAULT_VARIANT] = "product-box-wine-box",
    _a[ProductConsts["BoxProductConsts"].SHIPPING_BOX_DEFAULT_VARIANT] = "shipping-box",
    _a[ProductConsts["BoxProductConsts"].SHIPPING_BOX_PLAIN_DEFAULT_VARIANT] = "shipping-box-plain",
    _a[ProductConsts["BoxProductConsts"].SHIPPING_BOX_PLAIN_V2_DEFAULT_VARIANT] = "shipping-box-plain-v-2",
    _a[ProductConsts["BoxProductConsts"].MAILER_BOX_WHITE_ON_KRAFT_VARIANT] = "mailer-box-white-ink",
    _a[ProductConsts["BoxProductConsts"].DELIVERY_MAILER_BOX_VARIANT] = "delivery-mailer-box",
    _a[ProductConsts["BoxProductConsts"].DELIVERY_MAILER_BOX_PLAIN_VARIANT] = "delivery-mailer-box-plain",
    _a[ProductConsts["BoxProductConsts"].DELIVERY_MAILER_BOX_PLAIN_WHITE_VARIANT] = "delivery-mailer-box-plain-white",
    // Polymailers
    _a[ProductConsts["PolyMailerProductConsts"].POLY_MAILER_VARIANT_STANDARD] = "poly-mailer",
    _a[ProductConsts["PolyMailerProductConsts"].POLY_MAILER_BIO_VARIANT_STANDARD] = "bio-poly-mailer",
    _a[ProductConsts["PolyMailerProductConsts"]
        .POLY_MAILER_BIO_PLAIN_VARIANT_STANDARD] = "bio-poly-mailer-plain",
    _a[ProductConsts["PolyMailerProductConsts"]
        .POLY_MAILER_RECYCLED_VARIANT_STANDARD] = "recycled-poly-mailer",
    // Paper Mailer
    _a[ProductConsts["PaperMailerProductConsts"].PAPER_MAILER_VARIANT_STANDARD] = "paper-mailer",
    // Paper can
    _a[ProductConsts["PaperTubeProductConsts"].PAPER_CAN_PRODUCT_VARIANT_STANDARD] = "paper-can",
    // Bags
    _a[ProductConsts["PaperBagProductConsts"].PAPER_BAG_VARIANT_STANDARD] = "paper-bag",
    _a[ProductConsts["PaperBagProductConsts"].DOYPACK_BAG_VARIANT] = "doypack-bag",
    _a[ProductConsts["PaperBagProductConsts"].TOTE_BAG_VARIANT] = "tote-bag",
    //Tapes
    _a[ProductConsts["TapeProductConsts"].TAPE_VARIANT_PACKING_TAPE] = "packing-tape",
    _a[ProductConsts["TapeProductConsts"].TAPE_VARIANT_KRAFT_TAPE] = "kraft-tape",
    _a[ProductConsts["TapeProductConsts"].PRE_PRINTED_KRAFT_TAPE_VARIANT] = "pre-printed-kraft-tape",
    // Tissue paper
    _a[ProductConsts["TissuePaperProductConsts"].TISSUE_PAPER_VARIANT_STANDARD] = "tissue-paper",
    _a[ProductConsts["TissuePaperProductConsts"].PACKING_PAPER_VARIANT_STANDARD] = "packing-paper",
    // Envelopes
    _a[ProductConsts["EnvelopeProductConsts"].ENVELOPE_VARIANT_STANDARD] = "rigid-envelope",
    // Sample packs
    _a[ProductConsts["SamplePackProductConsts"].SAMPLE_PACK_DEFAULT_VARIANT] = "sample-pack",
    // Coming soons
    _a[ProductConsts["ComingSoonBoxProductConsts"].MUSLIN_BAG_DEFAULT_VARIANT] = "muslin-bag",
    _a[ProductConsts["ComingSoonBoxProductConsts"].DOYPACK_DEFAULT_VARIANT] = "doypack",
    _a[ProductConsts["ComingSoonBoxProductConsts"].CAKE_BOX_DEFAULT_VARIANT] = "cake-box",
    _a[ProductConsts["ComingSoonBoxProductConsts"]
        .RIGID_DRAWER_BOX_DEFAULT_VARIANT] = "rigid-drawer-box",
    _a[ProductConsts["ComingSoonBoxProductConsts"]
        .CARDBOARD_DRAWER_BOX_DEFAULT_VARIANT] = "cardboard-drawer-box",
    _a[ProductConsts["ComingSoonBoxProductConsts"].MAGNETIC_BOX_DEFAULT_VARIANT] = "magnetic-box",
    _a[ProductConsts["ComingSoonBoxProductConsts"].RIGID_BOX_DEFAULT_VARIANT] = "rigid-box",
    _a[ProductConsts["ComingSoonBoxProductConsts"]
        .RIGID_BOX_TWO_PIECES_DEFAULT_VARIANT] = "rigid-box-two-pieces",
    _a[ProductConsts["ComingSoonBoxProductConsts"].SPECIAL_BOX_DEFAULT_VARIANT] = "special-box",
    _a[ProductConsts["ComingSoonBoxProductConsts"].CARRIER_BOX_DEFAULT_VARIANT] = "carrier-box",
    _a[ProductConsts["ComingSoonBoxProductConsts"]
        .MAILER_BOX_FULL_COLOR_DOUBLE_SIDED_DEFAULT_VARIANT] = "mailer-box-full-color-double-sided",
    _a[ProductConsts["ComingSoonBoxProductConsts"].PADDED_ENVELOPE_DEFAULT_VARIANT] = "padded-envelope",
    _a[ProductConsts["ComingSoonBoxProductConsts"]
        .PADDED_ENVELOPE_ECO_DEFAULT_VARIANT] = "padded-envelope-eco",
    //Labels
    _a[ProductConsts["LabelProductConsts"].CIRCLE_LABEL_VARIANT] = "circle-label",
    _a[ProductConsts["LabelProductConsts"].RECTANGLE_LABEL_VARIANT] = "rectangle-label",
    _a[ProductConsts["LabelProductConsts"].SQUARE_LABEL_VARIANT] = "square-label",
    //Cards
    _a[ProductConsts["CardProductConsts"].PRINTED_CARD_VARIANT] = "printed-card",
    // Ncp Products
    _a[ProductConsts["NcpProductConsts"].WOOD_WOOL_VARIANT] = "wood-wool",
    _a[ProductConsts["NcpProductConsts"].SELF_ADHESIVE_ENVELOPE_VARIANT] = "self-adhesive-envelope",
    _a[ProductConsts["NcpProductConsts"].STICKER_CIRCLE_VARIANT] = "sticker-circle",
    _a[ProductConsts["NcpProductConsts"].STICKER_RECTANGLE_VARIANT] = "sticker-rectangle",
    _a[ProductConsts["NcpProductConsts"].THANK_YOU_CARD_VARIANT] = "thank-you-card",
    _a[ProductConsts["NcpProductConsts"].PAPER_BUBBLE_WRAP_VARIANT] = "paper-bubble-wrap",
    _a);
export function getOldVariantCodeName(newVariantName) {
    if (newVariantName === "custom")
        return newVariantName;
    if (!newVariantCodeNameToOld[newVariantName]) {
        throw new Error("Variant is not provided");
    }
    return newVariantCodeNameToOld[newVariantName];
}
export function getNewVariantName(oldVariantName) {
    var oldVariantToNew = _invert(newVariantCodeNameToOld);
    if (!oldVariantToNew[oldVariantName]) {
        throw new Error("Variant is not provided");
    }
    return oldVariantToNew[oldVariantName];
}
