import React from "react"
import { Checkbox } from "dsl/src/atoms/Checkbox/Checkbox"
import { FormControlLabel } from "dsl/src/atoms/FormControlLabel/FormControlLabel"
import { TType } from "dsl/src/atoms/Typography/Typography"

import { TM } from "../../../TypographyI18n"
import styles from "./EditorToolHeaderCheckbox.module.scss"

interface EditorToolHeaderCheckboxProps {
  onChange: () => void
  isChecked: boolean
  optionLabelIntl: string
  e2eTarget?: string
  e2eTargetName?: string
}

export const EditorToolHeaderCheckbox = ({
  onChange,
  isChecked,
  optionLabelIntl,
  e2eTarget,
  e2eTargetName,
}: EditorToolHeaderCheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          onChange={onChange}
          e2eTarget={e2eTarget}
          e2eTargetName={e2eTargetName}
        />
      }
    >
      <div className={styles.eco_checkbox_label}>
        <TM id={optionLabelIntl} type={TType.Body15_350} />
      </div>
    </FormControlLabel>
  )
}
