import assets from "./assets/assets";
import grammages from "./grammages/grammages";
import productionTimes from "./productionTimes/productionTimes";
import variants from "./variants/variants";
import consts from "./consts/paperTubeConsts";
import quantities from "./quantities";
import dimensions from "./dimensions/dimensions";
import weights from "./weights/weights";
var PAPER_TUBE_PRODUCTS_CONFIG = {
    ASSETS: assets,
    GRAMMAGES: grammages,
    PRODUCTION_TIMES: productionTimes,
    VARIANTS: variants,
    WEIGHTS: weights,
    VENDORS: {},
    DIMENSIONS: dimensions,
    CONSTS: consts,
    QUANTITIES: quantities,
};
export default PAPER_TUBE_PRODUCTS_CONFIG;
