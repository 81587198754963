import { getSkuData } from "@ph/product-config/dist/interfaces/js/SkuLib"

export class SkuCleaner {
  public constructor(private sku: string) {}

  public call(): string {
    this.cleanQueryParams()
    this.cleanRegion()

    return this.sku
  }

  private cleanRegion(): void {
    const region = this.getRegion()

    if (!region) {
      return
    }

    this.sku = this.sku.replace(`-${region}--`, "--")
  }

  private getRegion(): string | undefined {
    const skuData = getSkuData(this.sku)

    return skuData.productRegion
  }

  private cleanQueryParams(): void {
    this.sku = this.sku.split("?")[0]
  }
}
