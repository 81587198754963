var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ProductType } from "../../../types";
var addCustomProductVariantBehaviors = function (superclass) {
    var CUSTOM = "custom";
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getCodeName = function () {
            if (_super.prototype.getPrefix.call(this) === CUSTOM || _super.prototype.getSize.call(this) === CUSTOM) {
                return CUSTOM;
            }
            return _super.prototype.getCodeName.call(this);
        };
        class_1.prototype.getVariant = function () {
            var codeName = _super.prototype.getVariant.call(this);
            if (!codeName) {
                return CUSTOM;
            }
            return codeName;
        };
        class_1.prototype.getFinish = function () {
            var value = _super.prototype.getFinish.call(this);
            return value === CUSTOM ? null : value;
        };
        class_1.prototype.getProductName = function () {
            var value = _super.prototype.getProductName.call(this);
            return value === CUSTOM ? null : value;
        };
        class_1.prototype.getColorMode = function () {
            var value = _super.prototype.getColorMode.call(this);
            return value === CUSTOM ? null : value;
        };
        class_1.prototype.getMaterial = function () {
            var value = _super.prototype.getMaterial.call(this);
            return value === CUSTOM ? null : value;
        };
        class_1.prototype.getProductType = function () {
            return ProductType.Other;
        };
        return class_1;
    }(superclass));
};
export default addCustomProductVariantBehaviors;
