import SamplePacksStructure from "../../../../source/samplePackProductSpec/generalConfig/samplePackStructure";
import ProductFactory from "../../ProductFactory";
import SamplePackConfigBuilder from "../../configBuilders/SamplePackConfigBuilder";
import SamplePackEditConfigManager from "./behaviorsComposers/SamplePackEditConfigManager";
import SamplePackVariantManager from "./behaviorsComposers/SamplePackVariantManager";
import SamplePackRenderConfigManager from "./behaviorsComposers/SamplePackRenderConfigManager";
import SamplePackProductionTimeManager from "./behaviorsComposers/SamplePackProductionTimeManager";
import SamplePackTranslationsManager from "./behaviorsComposers/SamplePackTranslationsManager";
import SamplePackDimensionsManager from "./behaviorsComposers/SamplePackDimensionsManager";
import WizzardManager from "../../behaviorsComposers/WizzardManager";
// @ts-nocheck
var SamplePackProduct = /** @class */ (function () {
    function SamplePackProduct(sku) {
        this.config = SamplePackConfigBuilder(sku);
        this.variantManager = new SamplePackVariantManager(this);
        this.dimensionsManager = new SamplePackDimensionsManager(this);
        this.translationsManager = new SamplePackTranslationsManager(this);
        this.editConfigManager = new SamplePackEditConfigManager(this);
        this.wizzardManager = new WizzardManager(this);
        this.productionTimeManager = new SamplePackProductionTimeManager(this);
        this.renderConfigManager = new SamplePackRenderConfigManager(this);
        this.samplePackDefinition = SamplePackProduct.getSamplePackSpecificConfig(sku, "products");
        this.metadata = SamplePackProduct.getSamplePackSpecificConfig(sku, "metadata");
        this.samplePackCollection = this.createSamplePackCollection();
    }
    SamplePackProduct.prototype.createSamplePackCollection = function () {
        return this.samplePackDefinition.map(function (sku) {
            return ProductFactory(sku);
        });
    };
    SamplePackProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    SamplePackProduct.prototype.getProductCollectionNames = function () {
        return this.samplePackCollection.map(function (collectionItem) {
            return {
                productName: collectionItem.variantManager.getName(),
                productType: collectionItem.variantManager.getType(),
                productVariant: collectionItem.variantManager.getVariant(),
                productCodeName: collectionItem.variantManager.getCodeName(),
                productTranslation: collectionItem.translationsManager.getNameIntl(),
            };
        });
    };
    SamplePackProduct.getSamplePackSpecificConfig = function (sku, config) {
        if (!SamplePacksStructure[sku]) {
            throw new Error("Sample pack structure not found");
        }
        return SamplePacksStructure[sku][config];
    };
    return SamplePackProduct;
}());
export default SamplePackProduct;
