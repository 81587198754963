import { Cart } from "./cart.store"
import {
  SegmentLineItem,
  SegmentOrder,
} from "../_controllers/analytics-controller"

// Events
export enum CartEvents {
  addedDesignToCart = "ph.cart.addedDesignToCart",
  cartReceived = "ph.cart.received",
}

export type CartEventsTypes = {
  [CartEvents.addedDesignToCart]: (data?: {
    id: number
    currentCart: SegmentOrder
    newLineItem: SegmentLineItem
  }) => void
  [CartEvents.cartReceived]: (cart: Cart) => void
}
