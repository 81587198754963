import { useEcommerceContext } from "../../_containers-react/EnsureEcommerce"
import { SupportedCurrency } from "shared-libs/src/configs/region-config/interfaces/js/region-config.types"

export function useCurrency(): SupportedCurrency | undefined {
  const { ecommerce } = useEcommerceContext()

  if (!ecommerce.available) {
    return
  }

  return ecommerce.currencyStore.currency
}
