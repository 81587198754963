import _flow from "lodash/flow";
import tapeProductSpec from "../../../source/tapeProductSpec/tapeProductSpec";
import CustomProductConfigBuilder from "../configBuilders/CustomProductConfigBuilder";
import addCustomProductDimensionsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductDimensionsBehaviors";
import addCustomProductProductionTimesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductProductionTimesBehaviors";
import addCustomProductVariantBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductVariantBehaviors";
import addCustomProductEditConfigBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductEditConfigBehaviors";
import addCustomProductGrammagesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductGrammagesBehaviors";
import addCustomProductTranslationsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductTranslationsBehaviors";
import TapeTranslationsManager from "../Products/TapeProduct/behaviorsComposers/TapeTranslationsManager";
import TapeVariantManager from "../Products/TapeProduct/behaviorsComposers/TapeVariantManager";
import TapeDimensionsManager from "../Products/TapeProduct/behaviorsComposers/TapeDimensionsManager";
import GrammagesManager from "../behaviorsComposers/GrammagesManager";
import WizzardManager from "../behaviorsComposers/WizzardManager";
import EditConfigManager from "../behaviorsComposers/EditConfigManager";
import ProductionTimeManager from "../behaviorsComposers/ProductionTimeManager";
var CustomProductProductionTimeManager = addCustomProductProductionTimesBehaviors(ProductionTimeManager);
var CustomProductGrammagesManager = addCustomProductGrammagesBehaviors(GrammagesManager);
var CustomProductDimmensionsManager = addCustomProductDimensionsBehaviors(TapeDimensionsManager);
var CustomProductEditrConfigManager = addCustomProductEditConfigBehaviors(EditConfigManager);
var CustomProductTranslationsManager = addCustomProductTranslationsBehaviors(TapeTranslationsManager);
var CustomProductVariantManager = _flow([addCustomProductVariantBehaviors])(TapeVariantManager);
var CustomTapeProduct = /** @class */ (function () {
    function CustomTapeProduct(sku, productName) {
        this.config = CustomProductConfigBuilder(tapeProductSpec, sku, productName);
        this.wizzardManager = new WizzardManager(this);
        this.productionTimeManager = new CustomProductProductionTimeManager(this);
        this.editConfigManager = new CustomProductEditrConfigManager(this);
        this.variantManager = new CustomProductVariantManager(this);
        this.grammagesManager = new CustomProductGrammagesManager(this);
        this.dimensionsManager = new CustomProductDimmensionsManager(this);
        this.translationsManager = new CustomProductTranslationsManager(this);
    }
    CustomTapeProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    CustomTapeProduct.prototype.getMaterialDescription = function () {
        return null;
    };
    return CustomTapeProduct;
}());
export default CustomTapeProduct;
