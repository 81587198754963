import React, { useState } from "react"
import { useTooltip } from "../../../../hooks/useTooltip"

import styles from "./EditorToolMoreTextOptions.module.scss"
import { useTranslate } from "../../../../hooks/useTranslate"
import { TextObjectController } from "../../../../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/text-object-controller"
import { EditorHeaderToolbarItem } from "../../EditorHeader/EditorHeaderToolbar/EditorHeaderToolbar"
import { I18N } from "../../../../i18n"
import { RangeOption } from "../RangeOption"
import { EditorToolTextEffects } from "./EditorToolTextEffects"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

const MANUALLY_ADJUSTED_FACTOR = 10

interface MoreTextOptionsProps {
  activeObjectController: TextObjectController
}

export const EditorToolMoreTextOptions = ({
  activeObjectController,
}: MoreTextOptionsProps) => {
  const { openTooltip, closeTooltip, isOpen, Tooltip } = useTooltip(
    { offsetLeft: 0, offsetTop: 50 },
    90 //fixed to left
  )

  const [lineHeight, setLineHeight] = useState(
    activeObjectController.getLineHeight()
  )
  const [letterSpacing, setLetterSpacing] = useState(
    activeObjectController.getCharSpacing() / MANUALLY_ADJUSTED_FACTOR
  )
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.analytics,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, analytics } = containerSet
  const { productRenderPilot } = designAndProductDriver.productDriver.state

  const onClick = (e: React.MouseEvent) => {
    isOpen ? closeTooltip(e) : openTooltip(e)
    analytics.analyticsController?.trackMoreTextOptionsClicked()
  }

  const onChangeLineHeight = (value: number) => {
    activeObjectController.setStyles({
      lineHeight: Math.round(value * 100) / 100,
    })
    setLineHeight(value)
    analytics.analyticsController?.trackLineHeightModified()
  }

  const onChangeLetterSpacing = (value: number) => {
    activeObjectController.setStyles({
      charSpacing: value * MANUALLY_ADJUSTED_FACTOR,
    })
    setLetterSpacing(value)
    analytics.analyticsController?.trackLetterSpacingModified()
  }

  return (
    <>
      <EditorHeaderToolbarItem
        onClick={() => {}}
        helperMessage={t(I18N.components.editorToolText.label.moreOptions)}
      >
        <Typography
          className={styles.toggler}
          type={TType.Header15_500}
          onClick={onClick}
        >
          {t(I18N.components.editorToolText.label.effects)}
        </Typography>
      </EditorHeaderToolbarItem>

      {isOpen && (
        <Tooltip>
          <div className={styles.wrapper}>
            <RangeOption
              value={lineHeight}
              label={t(I18N.components.editorToolText.label.fontLineHeight)}
              min={0.1}
              max={3}
              onChange={onChangeLineHeight}
            />
            <RangeOption
              value={parseFloat(letterSpacing.toString())}
              label={t(I18N.components.editorToolText.label.fontLetterSpacing)}
              min={-30}
              max={200}
              step={1}
              onChange={onChangeLetterSpacing}
            />
            {productRenderPilot.uiConfig.features.textEffects && (
              <EditorToolTextEffects
                activeObjectController={activeObjectController}
                analyticsController={analytics.analyticsController}
              />
            )}
          </div>
        </Tooltip>
      )}
    </>
  )
}
