import * as Joi from "joi"

export const schema = Joi.object({
  locale: Joi.object({
    region: Joi.string().required(),
    productRegion: Joi.string().required(),
    country: Joi.string().required(),
    lang: Joi.string().required(),
    currency: Joi.string().required(),
    salesChannelId: Joi.when("/api.ecommerce.mode", {
      is: "injected",
      then: Joi.string().required(),
    }),
    forcedTax: Joi.boolean().required(),
    displayUnit: Joi.string().valid("cm", "in").optional(),
    urls: Joi.object({
      baseUrl: Joi.string().required(),
    }).required(),
  }).required(),
  api: Joi.object({
    base: Joi.object({
      baseUrl: Joi.string().required(),
    }).required(),
    design: Joi.object({
      baseUrl: Joi.string().required(),
    }).required(),
    ecommerce: Joi.object({
      mode: Joi.string().valid("rest", "injected").optional(),
      baseUrl: Joi.when("mode", {
        is: Joi.string().valid("rest").optional(),
        then: Joi.string().required(),
      }),
      service: Joi.when("mode", {
        is: Joi.string().valid("injected").required(),
        then: Joi.object({
          setToken: Joi.function().required(),
          user: Joi.object({
            assignDesign: Joi.function().required(),
            updateDesign: Joi.function().required(),
            duplicateDesign: Joi.function().required(),
          }).required(),
          logo: Joi.when("/api.ecommerce.features.user.logo", {
            is: true,
            then: Joi.object({
              get: Joi.function().required(),
              upsert: Joi.function().required(),
              delete: Joi.function().required(),
            }).required(),
          }),
          cart: Joi.when("/api.ecommerce.features.ecommerce.basic", {
            is: true,
            then: Joi.object({
              get: Joi.function().required(),
              addLineItem: Joi.function().required(),
            }).required(),
          }),
          order: Joi.when("/api.ecommerce.features.ecommerce.basic", {
            is: true,
            then: Joi.object({
              get: Joi.function().required(),
            }).required(),
          }),
          pricing: Joi.when("/api.ecommerce.features.ecommerce.basic", {
            is: true,
            then: Joi.object({
              get: Joi.function().required(),
            }).required(),
          }),
          qrCode: Joi.when("/api.ecommerce.features.ecommerce.dynamicQrCode", {
            is: true,
            then: Joi.object({
              create: Joi.function().required(),
            }).required(),
          }),
        }).required(),
      }),
      features: Joi.object({
        auth: Joi.boolean().optional(),
        ecommerce: Joi.object({
          basic: Joi.bool().optional(),
          quantityPicker: Joi.bool().optional(),
          apiCustomersMode: Joi.bool().optional(),
          designLater: Joi.bool().optional(),
          dynamicQrCode: Joi.bool().optional(),
          fsc: Joi.bool().optional(),
          trees: Joi.bool().optional(),
          patterns: Joi.bool().optional(),
          templates: Joi.bool().optional(),
          productSwitcher: Joi.bool().optional(),
          productConfiguration: Joi.bool().optional(),
        }).optional(),
        user: Joi.object({
          data: Joi.bool().optional(),
          logo: Joi.bool().optional(),
        }).optional(),
      }).optional(),
    }).required(),
    assets: Joi.object({
      baseUrl: Joi.string().required(),
    }).required(),
    pim: Joi.object({
      baseUrl: Joi.string().required(),
      token: Joi.string().required(),
    }).required(),
    strapi: Joi.object({
      baseUrl: Joi.string().required(),
    }).required(),
  }).required(),
  integrations: Joi.object({
    ga: Joi.object({
      code: Joi.string().required(),
    }).optional(),
    segment: Joi.object({
      code: Joi.string().required(),
    }).optional(),
    datadog: Joi.object({
      appId: Joi.string().required(),
      clientToken: Joi.string().required(),
      service: Joi.string().required(),
      sampleRate: Joi.number().required(),
      env: Joi.string().required(),
    }).optional(),
    sentry: Joi.object({
      organisation: Joi.string().optional(),
      dsn: Joi.string().required(),
      environment: Joi.string().optional(),
      release: Joi.string().optional(),
      instance: Joi.string().optional(),
    }).optional(),
    configCat: Joi.object({
      key: Joi.string().required(),
    }).optional(),
  }).optional(),
  socialAuth: Joi.object({
    facebook: Joi.optional(),
    google: Joi.optional(),
  }).optional(),
  branding: Joi.object({
    logo: Joi.optional(),
  }).optional(),
}).options({ allowUnknown: true })
