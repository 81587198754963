import {
  observable,
  action,
  computed,
  toJS,
  runInAction,
  makeObservable,
} from "mobx"
import { Debug } from "../../services/logger"
import { getInfo } from "@ph/editor-assets"
import { ImageUploader, InputFile } from "../../services/asset-service/uploader"
import {
  ImageAssetMetaT,
  SerializedAssetWithMeta,
  AssetMetaEnvelope,
  AssetSource,
  AssetSection,
} from "../../modules/ph-api/asset-types"
import { ImageAsset } from "../../libs/value-objects/image-asset"
import {
  RuntimeImageAssetError,
  AssetError,
} from "../../services/asset-service/asset-errors"
import { isFile, isNamedFile } from "../../services/asset-service/utils"

const debug = Debug("ph:editor:assets:item")

export class ImageAssetItemStore {
  @observable public isUploading = false
  @observable public hasUploaded = false
  @observable public isTransforming = false
  @observable public hasTransformed = false
  @observable public error: AssetError | null = null

  private localTempPreviewUri: string | null = null
  private uploadCb: (ImageAssetItemStore) => void

  // Here we store all the raw asset data. Dimensions, sizes, urls etc.
  public data: ImageAsset | undefined

  // Metainformation -- all asset related stuff. This is serializeble envelope
  @observable public meta: AssetMetaEnvelope
  @observable public name: string

  constructor(imageAssetData?: SerializedAssetWithMeta) {
    makeObservable(this)
    // both names should exist
    this.name = "unknown-image"
    this.meta = {
      name: "unknown-image",
      isSoftDeleted: false,
    }

    // Deserialize
    if (!!imageAssetData) {
      this.migrateSerializedAssetToCurrentFormat(imageAssetData)

      this.data = new ImageAsset(imageAssetData)
      this.name = this.getNameFromImageAssetData(imageAssetData)

      if (imageAssetData.meta) {
        this.meta = imageAssetData.meta
      } else {
        this.meta.name = this.name
      }

      this.setCompleted()
    }

    // Safety
    this.uploadCb = () => this

    return this
  }

  public async createFromFile(file: InputFile, uploadCb?) {
    if ((isFile(file) || isNamedFile(file)) && !!file.name) {
      this.setName(file.name)
    }

    this.tryToCreateValidPreview(file)
    this.uploadCb = uploadCb

    await this.uploadStuff(file)
  }

  /**
   * We can have a nice preview for normal raster and svg
   * @param file - raw browser file object
   */
  private tryToCreateValidPreview(file: InputFile) {
    // @ts-ignore
    const mimeType = file.type

    if (typeof mimeType === "string") {
      try {
        const i = getInfo(mimeType)
        if (i.isRaster || i.isSvg) {
          this.setPreview(URL.createObjectURL(file))
        } else {
          this.setPreview("")
        }
      } catch (e) {
        debug("No valid type for preview will be empty", e)
        this.setPreview("")
      }
    }
  }

  public getImageAsset(): ImageAsset {
    if (!this.data) {
      throw new Error("not allowed")
    }
    return this.data
  }

  @action
  public setPreview(uri: string) {
    this.localTempPreviewUri = uri
  }

  public getSource(): ImageAssetMetaT {
    if (!this.data) {
      throw new Error("not allowed")
    }
    return this.data.toSource()
  }

  // getImageAsset
  public serializeAsset(): SerializedAssetWithMeta {
    if (!this.data) {
      throw new Error("not allowed")
    }
    if (this.error != null) {
      throw new Error("You are trying to serialize an invalid asset. Stop")
    }
    const assetData = this.data.toSource()

    return Object.assign({}, assetData, {
      meta: toJS(this.meta),
    })
  }

  @computed
  public get previewUri(): string {
    if (this.hasTransformed === true && this.error == null) {
      if (this.data == null) {
        throw new RuntimeImageAssetError(AssetError.emptyData)
      }

      // Should work for both Vector and a raster asset
      return this.data.getBasicScaledConfig().url || ""
    }

    if (this.hasTransformed === false && this.localTempPreviewUri !== null) {
      return this.localTempPreviewUri
    }

    return ""
    // TODO this is a correct approach but i can't properly fix it
    // throw new RuntimeImageAssetError(AssetError.noPreviewImage)
  }

  @action
  public softDelete() {
    this.meta.isSoftDeleted = true
  }

  public isSoftDeleted(): boolean {
    return this.meta.isSoftDeleted
  }

  public get source(): AssetSource | undefined {
    return this.meta.source
  }

  @action
  public setSections(sections: AssetSection[]) {
    this.meta.sections = sections
  }

  @action
  public addSection(section: AssetSection) {
    const sections = this.meta.sections || []

    if (sections.includes(section)) {
      return
    }

    this.meta.sections = [...sections, section]
  }

  @action
  public removeSection(section: AssetSection) {
    const sections = this.meta.sections || []

    this.meta.sections = sections.filter(
      (currentSection) => currentSection !== section
    )
  }

  public get sections(): AssetSection[] {
    return this.meta.sections || []
  }

  public isInSection(section: AssetSection): boolean {
    return this.sections.includes(section)
  }

  // TODO add a private getter for local preview url that will throw errors
  // if it has been removed from ram

  @action
  private async uploadStuff(file: InputFile) {
    this.setUploading()
    const up = new ImageUploader(file)
    try {
      const imageData = await up.uploadAndConvertFiles()

      runInAction(() => {
        this.name = up.getClientNaming().fileName
        this.data = new ImageAsset(imageData)
      })
    } catch (e: any) {
      this.setError(e.message)

      if (!(e instanceof RuntimeImageAssetError)) {
        throw e
      }
    }

    if (this.localTempPreviewUri) {
      URL.revokeObjectURL(this.localTempPreviewUri)
    }

    if (this.uploadCb) {
      this.uploadCb(this)
    }

    this.setCompleted()
  }

  @action
  private setName(name: string) {
    this.name = name
    this.meta.name = name
  }

  @action
  private setError(err: AssetError) {
    this.error = err
    console.log("setting error", err)
  }

  @action
  private setUploading() {
    this.isUploading = true
    this.isTransforming = true
  }

  @action
  private setCompleted() {
    this.isUploading = false
    this.isTransforming = false
    this.hasUploaded = true
    this.hasTransformed = true
  }

  private getNameFromImageAssetData(
    imageAssetData: SerializedAssetWithMeta
  ): string {
    if (imageAssetData.meta) {
      return imageAssetData.meta.name
    }

    return imageAssetData.original.originalFilename
  }

  private migrateSerializedAssetToCurrentFormat(
    assetMeta: SerializedAssetWithMeta
  ) {
    if (assetMeta.meta && assetMeta.meta["category"]) {
      assetMeta.meta.source = assetMeta.meta["category"]
      delete assetMeta.meta["category"]
    }

    return assetMeta
  }
}
