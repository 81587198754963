import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey } from "../SkuLib";
var CustomProductConfigBuilder = function (productConfig, sku, productName, productVariant) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    return {
        skuData: {
            sku: sku,
            decodedSKU: decodedSKU,
        },
        assets: {
            global: productConfig.ASSETS.globalAssets,
            product: checkVariant(productConfig.ASSETS.productAssets, productName, productVariant),
        },
        quantitiesSpec: {},
        const: productConfig.CONSTS,
        dimensions: productConfig.DIMENSIONS[productName],
        grammages: productConfig.GRAMMAGES[productName],
        productionTimes: checkVariant(productConfig.PRODUCTION_TIMES, productName, productVariant),
        renderSpec: productConfig.RENDER_SPEC
            ? {
                productRenderConfig: productConfig.RENDER_SPEC.PRODUCTS[productName],
                sidesMapping: productConfig.RENDER_SPEC.SIDES_MAPPING,
            }
            : {},
        userInterfaceConfig: {
            UI_CONFIG: productConfig.UI_CONFIG,
            productPreferredColors: productConfig.PRODUCT_PREFERED_COLORS,
        },
        variant: productConfig.VARIANTS[productName] || {
            prefix: "custom",
        },
        weights: checkVariant(productConfig.WEIGHTS, productName, productVariant),
    };
};
function checkVariant(configPath, productName, productVariant) {
    if (!configPath) {
        return {};
    }
    var productModelConfig = configPath[productName];
    if (productVariant === "custom") {
        return productModelConfig ? productModelConfig : {};
    }
    else if (productModelConfig) {
        return productModelConfig[productVariant];
    }
    else {
        return {};
    }
}
export default CustomProductConfigBuilder;
