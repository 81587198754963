import React, { FunctionComponent, useEffect, useRef } from "react"

interface DropdownManagerProps {
  component: any
  state: boolean
  onOutsideClick: () => void
}

export const OutOfBoundsClickManager: FunctionComponent<
  DropdownManagerProps
> = (props) => {
  const { component, state, onOutsideClick } = props

  const ref = useRef(null)

  const hideDropdownOnOutsideClick = (e) => {
    // @ts-ignore
    if (ref.current.contains(e.target)) {
      return
    } else {
      onOutsideClick()
    }
  }

  const isDropdownOpened = state

  useEffect(() => {
    isDropdownOpened &&
      document.addEventListener("click", hideDropdownOnOutsideClick)
    return () => {
      document.removeEventListener("click", hideDropdownOnOutsideClick)
    }
  }, [hideDropdownOnOutsideClick])

  return component(ref)
}
