import React from "react"
import cxBinder from "classnames/bind"
import styles from "./Chip.module.scss"

const cx = cxBinder.bind(styles)

export enum ChipStatus {
  disabled = "disabled",
  active = "active"
}

export enum ChipType {
  rectangle = "rectangle",
  bento = "bento"
}

export enum ChipSize {
  small = "small",
  medium = "medium",
  large = "large"
}

interface ChipProps extends React.HTMLAttributes<HTMLButtonElement> {
  status?: ChipStatus
  type?: ChipType
  icon?: React.ReactElement<any>
  onClick?: () => void
  fluid?: boolean
  e2eTarget?: string
  e2eTargetName?: string
  size?: ChipSize
}

const Chip = (props: ChipProps) => {
  const {
    children,
    status,
    type,
    icon,
    onClick,
    fluid,
    e2eTarget,
    e2eTargetName,
    size,
    ...other
  } = props

  return (
    <button
      type="button"
      className={cx("base", {
        disabled: status === ChipStatus.disabled,
        active: status === ChipStatus.active,
        fluid: fluid,
        large: size === ChipSize.large,
        small: size === ChipSize.small,
        rectangle: type === ChipType.rectangle,
        bento: type === ChipType.bento
      })}
      onClick={onClick}
      e2e-target={e2eTarget || "tag"}
      e2e-target-name={e2eTargetName}
      {...other}
    >
      {icon && <span className={cx("icon-wrapper")}>{icon}</span>}
      {children}
    </button>
  )
}

export { Chip, Chip as default }
