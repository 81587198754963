// Fonts

import {
  EditableObjectTypes,
  ImageAssetMetaT,
} from "../../modules/ph-api/asset-types"
import { EditorToolbarItem } from "../editor-toolbar-store"
import { HeaderToolbarAction } from "../../ui/dsl/organisms/EditorHeader/EditorHeaderToolbar/HeaderToolbarAction"
import { ImageAsset } from "../../libs/value-objects/image-asset"

export enum FontLoaderEvents {
  loadingStateChanged = "ph.fonts.loading-state.changed",
}

export type FontLoaderTypes = {
  [FontLoaderEvents.loadingStateChanged]: (state: boolean) => void
}

export enum ActiveObjectEvent {
  selected = "activeObject:selected",
  modified = "activeObject:modified",
  resized = "activeObject:resized",
  deselected = "activeObject:deselected",
  cleared = "activeObject:cleared",
}

export enum CanvasEvent {
  selectionCreated = "selection:created",
  selectionUpdated = "selection:updated",
  selectionCleared = "selection:cleared",
  objectMoving = "object:moving",
  objectRotating = "object:rotating",
  objectScaling = "object:scaling",
  textChanged = "text:changed",
  objectRestyled = "object:restyled",
  objectRecolored = "object:recolored",
  objectModified = "object:modified",
  objectAdded = "object:added",
  objectRemoved = "object:removed",
}

export enum CanvasEventObjectModifiedAction {
  scale = "scale",
  move = "move",
  drag = "drag",
}

export enum CanvasObjectEvent {
  selected = "selected",
  deselected = "deselected",
  scaling = "scaling",
  moving = "moving",
  changed = "changed",
  rotating = "rotating",
  removed = "removed",
  mousedown = "mousedown",
  moved = "moved",
  editingExited = "editing:exited",
  beforeDuplicated = "before:duplicated",
  layerChanged = "layer:changed",
}

// UI

export enum EditorUIViewEvents {
  MainUISceneWithSKUViewed = "ph.ui.main-scene.viewed.with-new-sku.uniq",
  ecoFormBlockClicked = "ph.ui.eco.plant-trees-section.clicked",
  EditorToolbarElementClicked = "ph.ui.editor-toolbar-element.clicked",
  printInsideSwitched = "ph.ui.print-inside.switched",
  EditorUpperRightToolbarClicked = "ph.ui.editor-upper-right-toolbar.clicked",
  DownloadArtworkClicked = "ph.ui.editor-download-artwork.clicked",
}

export type EditorUIViewTypes = {
  [EditorUIViewEvents.MainUISceneWithSKUViewed]: (config: {
    url: string
  }) => void

  [EditorUIViewEvents.ecoFormBlockClicked]: () => void

  [EditorUIViewEvents.EditorToolbarElementClicked]: (
    element: EditorToolbarItem
  ) => void

  [EditorUIViewEvents.printInsideSwitched]: ({
    active,
    location,
  }: {
    active: boolean
    location: "style_tab" | "side_view"
  }) => void

  [EditorUIViewEvents.EditorUpperRightToolbarClicked]: (
    action: HeaderToolbarAction,
    objectType: string,
    assetType: EditableObjectTypes
  ) => void

  [EditorUIViewEvents.DownloadArtworkClicked]: (
    quantity: number,
    sku: string
  ) => void
}

// Graphic Assets and logo
// NB: JSdoc'ing events will show in autocomplete and typedoc!
export enum GraphicAssetEvents {
  /**
   * An asset has been sucessfully uploaded by a user
   */
  assetUploaded = "ph.graphics.asset.uploaded",

  /**
   * A logo has been sucessfully uploaded and attached to a User vir API confirmation
   */
  logoSet = "ph.graphics.logo.was-set",

  logoUploadStarted = "ph.graphics.logo.upload-started",
  logoUploadFinished = "ph.graphics.logo.upload-finished",

  /**
   * Asset that was set as logo is not logo anymore
   */
  logoRemoved = "ph.graphics.logo.was-removed",

  // TBC
  // assetRemoved = "ph.graphics.asset.removed",

  assetResized = "ph.graphics.asset.resized",
}

export type GraphicAssetTypes = {
  [GraphicAssetEvents.assetUploaded]: (asset: ImageAssetMetaT) => void
  [GraphicAssetEvents.logoSet]: (asset: ImageAsset) => void
  [GraphicAssetEvents.logoUploadStarted]: () => void
  [GraphicAssetEvents.logoUploadFinished]: (withSuccess: boolean) => void
  [GraphicAssetEvents.logoRemoved]: () => void
  [GraphicAssetEvents.assetResized]: (
    asset: ImageAssetMetaT,
    isOversized: boolean
  ) => void
}
