import { AxiosInstance } from "axios"
import {
  CustomVariantDto,
  CustomVariantRawDto,
  LocalePayload,
  VolumePriceDto,
} from "../../types"
import { parseResponse } from "../helpers/parse-response"
import { VariantCustomization } from "@ph/product-api"

export class VariantApi {
  constructor(private readonly api: AxiosInstance) {}

  public async getDynamicVariant(
    sku: string,
    customization: VariantCustomization,
    locale: LocalePayload,
    quantities: number[]
  ): Promise<CustomVariantDto> {
    try {
      const response = await this.api.post("/variants", {
        ...locale,
        sku,
        quantities,
        custom_size: customization.size,
      })

      const parsedResponse = parseResponse<CustomVariantRawDto>(response)
      const dynamicPrices = parsedResponse.dynamic_prices.map<VolumePriceDto>(
        (price) => {
          return {
            ...price,
            type: price.estimation ? "estimation" : "price",
          }
        }
      )

      this.attachMissingQuantities(quantities, dynamicPrices)

      return {
        sku: parsedResponse.external_pim_sku,
        dynamic_prices: dynamicPrices,
      }
    } catch (e: any) {
      const { response } = e

      if (!response) {
        throw e
      }

      const { status, data } = response

      //TODO: Better error handling (waiting for proxy endpoint)

      if (status === 400) {
        const message = data.error || data.errors[0]?.detail

        throw new Error(message)
      }

      return {
        sku,
        dynamic_prices: this.attachMissingQuantities(quantities, []),
      }
    }
  }

  private attachMissingQuantities(
    requestedQuantities: number[],
    dynamicPrices: VolumePriceDto[]
  ): VolumePriceDto[] {
    for (const quantity of requestedQuantities) {
      const isPriceFetchedForQuantity = dynamicPrices.find(
        (price) => price.range.start === quantity
      )

      if (!isPriceFetchedForQuantity) {
        dynamicPrices.push({
          net_price: 0,
          gross_price: 0,
          type: "qr",
          range: {
            start: quantity,
            end: quantity,
          },
        })
      }
    }

    return dynamicPrices
  }
}
