import React from "react"
import { observer } from "mobx-react-lite"
import { SceneFooter3DWired as SceneFooter3D } from "./SceneFooter3D"
import { SceneFooter2DWired as SceneFooter2D } from "./SceneFooter2D"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"
import { ViewType } from "../../../../libs/products-render-config/types"

export const SceneUIFooterController = observer(() => {
  const [designAndProductDriver] = useContainer().designAndProductDriver

  if (!designAndProductDriver) return null
  const { state } = designAndProductDriver.productDriver
  const { is3DPreviewOn, activeViewType } = state
  const isDbyMode = state.productRenderPilot.isDbyMode()

  if (isDbyMode) return null

  if (!is3DPreviewOn && activeViewType === ViewType.MODEL) {
    return <SceneFooter3D />
  } else {
    return <SceneFooter2D />
  }
})
