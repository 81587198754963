var _a, _b, _c, _d, _e;
import PAPER_MAILER_CONSTS from "../consts/paperMailerConsts";
var assetsPath = "/product-key-visuals/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var customPath = "/product-custom-dimensions/";
var sizeAssetsPaperMailer = {
    Y60: {
        desktop: desktopPath + "Y60_paper.png",
        mobile: mobilePath + "Y60_paper.png",
    },
    Y80: {
        desktop: desktopPath + "Y80_paper.png",
        mobile: mobilePath + "Y80_paper.png",
    },
    Y90: {
        desktop: desktopPath + "Y90_paper.png",
        mobile: mobilePath + "Y90_paper.png",
    },
    custom: {
        desktop: customPath + "PaperMailer/Desktop/Custom/{locale}.png",
        mobile: customPath + "PaperMailer/Mobile/Custom/{locale}.png",
    },
};
var COLOR_MODES_IMAGES_PATHS = (_a = {},
    _a[PAPER_MAILER_CONSTS.PRINT_MODE_MONO_PANTONE_ONE_SIDE] = "v5-print-one-site",
    _a[PAPER_MAILER_CONSTS.PRINT_MODE_MONO_PANTONE_TWO_SIDES] = "v5-print-two-sites",
    _a);
var MATERIALS_IMAGES_PATHS = (_b = {},
    _b[PAPER_MAILER_CONSTS.PRINT_MATERIAL_PAPER_KRAFT] = "/product-material-picker/swatch_white.png",
    _b);
var EditorMaterialImage;
(function (EditorMaterialImage) {
    EditorMaterialImage["KRAFT"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-tape-kraft.png";
})(EditorMaterialImage || (EditorMaterialImage = {}));
var editorImagePaths = (_c = {},
    _c[PAPER_MAILER_CONSTS.PRINT_MATERIAL_PAPER_KRAFT] = EditorMaterialImage.KRAFT,
    _c);
var GLOBAL_ASSETS = Object.assign({}, { colorModesImagesPaths: COLOR_MODES_IMAGES_PATHS }, { materialImagesPaths: MATERIALS_IMAGES_PATHS }, { editorImagePaths: editorImagePaths });
var PAPER_MAILER_PRODUCT_ASSETS = (_d = {},
    _d[PAPER_MAILER_CONSTS.PAPER_MAILER] = (_e = {},
        _e[PAPER_MAILER_CONSTS.PAPER_MAILER_VARIANT_STANDARD] = {
            keyVisual: [assetsPath + "papermailer_desktop.png"],
            sizeAssets: sizeAssetsPaperMailer,
        },
        _e),
    _d);
var productAssets = {
    globalAssets: GLOBAL_ASSETS,
    productAssets: PAPER_MAILER_PRODUCT_ASSETS,
};
export default productAssets;
