import React from "react"
import { observer } from "mobx-react-lite"
import { isShippingCountryComingSoon } from "shared-libs/src/js/libs/helpers/region-config.helpers"
import { EditorHeaderAddToCart } from "./EditorHeaderAddToCart"
import { EditorHeaderDesignerModeActions } from "./EditorHeaderDesignerModeActions"
import { EditorHeaderFinishDesign } from "./EditorHeaderFinishDesign"
import { EditorHeaderDesignLater } from "./EditorHeaderDesignLater"
import styles from "./EditorHeaderProductActions.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { QuantityPicker } from "../../../dsl/organisms/QuantityPicker/QuantityPicker"
import { EditorHeaderMenuItemWallet } from "../../../dsl/organisms/EditorHeader/EditorHeaderMenu/EditorHeaderMenuItemWallet"
import { EditorHeaderGetQuote } from "./EditorHeaderGetQuote"

export const EditorHeaderProductActions = observer(() => {
  const [headerSet, headerSetErrors] = useContainerSet((c) => [
    c.apiSession,
    c.designAndProductDriver,
    c.ecommerce,
    c.dbyMode,
    c.envUtil,
  ])

  if (headerSet == null || headerSetErrors != null) return null
  const { apiSession, designAndProductDriver, ecommerce, dbyMode, envUtil } =
    headerSet

  const { productDesignStore, productDriver } = designAndProductDriver
  const { sessionStore } = apiSession
  const { isRendererLoading, productRenderPilot } = productDriver.state
  const isAnyAssetOverscaled = productDriver.assetsDriver.isAnyAssetOverscaled
  const { isDesignInCart, isProductDesignSaving } = productDesignStore.state
  const sku = productDriver.productStore.productSku
  const { uri } = envUtil
  const isAfterPurchaseEdit = productRenderPilot.isAfterPurchaseEdit

  if (!sku || uri.isDtpPreviewMode()) {
    return null
  }

  if (uri.isDesignerMode()) {
    return <EditorHeaderDesignerModeActions />
  }

  if (isAfterPurchaseEdit) {
    return (
      <li>
        <EditorHeaderFinishDesign />
      </li>
    )
  }

  if (!ecommerce.available) {
    return null
  }

  const isDataReadOnly = productDesignStore.state.meta.dataReadOnly
  const { isDesignLocked } = productDesignStore

  const addToCart = async () => {
    await ecommerce.cartStore.triggerAddingLineItemToCart({
      shouldLockDesign:
        !ecommerce.ecommerceStore.isApiCustomersMode && !isDesignLocked,
    })
  }

  const openQuoteRequestForm = () => {
    ecommerce.ecommerceController.redirectToQuoteRequestForm()
  }

  const { activeObjectDriver } = designAndProductDriver.productDriver
  const { activeObjectType } = activeObjectDriver

  const { isDynamicVariantLoading } = ecommerce.ecommerceStore
  const readonly = isDesignInCart || isDataReadOnly
  const isEditorLoading =
    isRendererLoading || isProductDesignSaving || isDynamicVariantLoading
  const { isLoadingPricing } = ecommerce.productPricingStore
  const isDbyMode = productRenderPilot.isDbyMode()
  const isDbyModeFileNotUploaded = !dbyMode.dbyModeStore.uploadedFile
  const isDbyModeFileUploadFailed = !!dbyMode.dbyModeStore.uploadError
  const isAddingToCartLocked =
    isEditorLoading ||
    isLoadingPricing ||
    (!readonly && isAnyAssetOverscaled) ||
    (isDbyMode && (isDbyModeFileNotUploaded || isDbyModeFileUploadFailed))
  const isUserAnonymous = !sessionStore.user?.email

  const isCartFlowAvailable = ecommerce.ecommerceStore.isCartFlowAvailable
  const isQrFlowAvailable = ecommerce.ecommerceStore.isQrFlowAvailable
  const isDesignLaterAvailable = ecommerce.ecommerceStore.isDesignLaterAvailable
  const isQuantityPickerVisible =
    ecommerce.ecommerceStore.isQuantityPickerVisible

  const showQtyPickerAndWallet =
    !activeObjectType &&
    !isShippingCountryComingSoon() &&
    isQuantityPickerVisible

  return (
    <ul className={styles.editor_header__menu}>
      {showQtyPickerAndWallet && (
        <>
          {!isUserAnonymous && (
            <li>
              <EditorHeaderMenuItemWallet />
            </li>
          )}
          <li>
            <QuantityPicker />
          </li>
        </>
      )}

      {isDesignLaterAvailable && (
        <li>
          <EditorHeaderDesignLater
            isDisabled={isEditorLoading || isDesignInCart}
          />
        </li>
      )}

      {isQrFlowAvailable && (
        <li>
          <EditorHeaderGetQuote
            isDisabled={isEditorLoading || isProductDesignSaving}
            isProductDesignSaving={isProductDesignSaving}
            openQuoteRequestForm={openQuoteRequestForm}
          />
        </li>
      )}

      {isCartFlowAvailable && (
        <li>
          <EditorHeaderAddToCart
            isAddingToCartLocked={isAddingToCartLocked || isDesignInCart}
            addToCart={addToCart}
          />
        </li>
      )}
    </ul>
  )
})
