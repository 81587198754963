import { PredefinedTextAssetDto } from "../../../../../../modules/predefined-assets/asset-types"
import { PredefinedText } from "../../../../../../modules/predefined-assets/text-asset"
import { ModelEditableSpaces } from "../../../../../../libs/products-render-config/types"
import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import { JsonToFabricTransformer } from "../transformer/json-to-fabric-transformer"
import { v4 as uuidv4 } from "uuid"
import {
  isAssetGroup,
  VirtualDielineObject,
} from "../../../../../../modules/ph-api/asset-types"
import { EditableGroupCreator } from "../editable-group-controller/editable-group-creator"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { ClipPathModes } from "../../../services/clip-path-generator"
import { AlignmentHelper } from "../helpers/alignment-helper"
import { SpaceClippingHelper } from "../helpers/space-clipping-helper"
import { ScaleHelper } from "../helpers/scale-helper"
import { ColorHelper } from "../helpers/color-helper"

export class PredefinedTextCreator {
  public constructor(
    private readonly virtualDielineEditor: VirtualDielineEditor
  ) {}

  public async create(
    asset: PredefinedTextAssetDto,
    predefinedText: PredefinedText,
    spaceId: ModelEditableSpaces
  ): Promise<PackhelpEditableObject> {
    const object = await this.transformJsonToPackhelpObject(
      predefinedText,
      asset.objects,
      spaceId
    )

    ScaleHelper.scaleObjectToSpace(
      this.virtualDielineEditor,
      object,
      asset.canvasDimensions,
      spaceId
    )
    AlignmentHelper.alignCenter(
      this.virtualDielineEditor.dielineNavigator,
      object
    )

    if (!isAssetGroup(object)) {
      await SpaceClippingHelper.setSpaceClipping(
        this.virtualDielineEditor,
        spaceId,
        object,
        ClipPathModes.FillMode
      )
    }

    return object
  }

  private async transformJsonToPackhelpObject(
    predefinedText: PredefinedText,
    predefinedTextObjects: VirtualDielineObject[],
    spaceId: ModelEditableSpaces
  ): Promise<PackhelpEditableObject> {
    const objects =
      await JsonToFabricTransformer.transform<PackhelpEditableObject>(
        predefinedTextObjects
      )
    this.postTransformProcess(predefinedText, objects, spaceId)

    if (objects.length === 1) {
      return objects[0]
    }

    const groupCreator = new EditableGroupCreator(this.virtualDielineEditor)
    return await groupCreator.create(spaceId, objects)
  }

  private postTransformProcess(
    predefinedText: PredefinedText,
    objects: PackhelpEditableObject[],
    spaceId: ModelEditableSpaces
  ) {
    for (const object of objects) {
      object.originSpaceArea = spaceId
      object.id = uuidv4()
      object.predefinedTextId = predefinedText.id

      ColorHelper.applyDefaultColor(this.virtualDielineEditor, object)
    }
  }
}
