import React from "react"
import { observer } from "mobx-react-lite"
import { ReactComponent as IconPlus } from "editor/src/ui/assets/svg/logo-placeholder-plus.svg"
import cxBinder from "classnames/bind"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { EditorToolbarItem } from "../../../../stores/editor-toolbar-store"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

import styles from "./PlaceholderOverview.module.scss"

const cx = cxBinder.bind(styles)

export const PlaceholderOverview = observer(() => {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.ui,
    c.templates,
    c.designAndProductDriver,
    c.logo,
  ])

  if (!containerSet) {
    return null
  }

  const { templates, designAndProductDriver, ui, logo } = containerSet
  const { productDriver } = designAndProductDriver
  const { productRenderPilot } = productDriver.state
  const { editorToolbarStore } = ui
  const { logoStore } = logo

  const editorMode = productRenderPilot.getEditorMode()
  const productHasLogo = logoStore.logoPlaceholderSpaces
  const activeContext = productDriver.state.activeContext
  const isLoadingSingleTemplate =
    templates.templatesStore?.isLoadingSingleTemplate
  const isLogoLoading = logoStore.isLoading
  const isDesignerMode = editorMode === "designer"
  const isPreviewMode = productDriver.state.isDesignPreviewMode
  if (
    isPreviewMode ||
    isDesignerMode ||
    !logoStore.isLogoPlaceholdersEnabled ||
    !productHasLogo ||
    !logoStore.logoPlaceholderSpaces[activeContext] ||
    isLoadingSingleTemplate ||
    isLogoLoading
  ) {
    return null
  }

  return (
    <div
      className={cx({ wrapper: true })}
      onClick={() => {
        editorToolbarStore.selectTab(EditorToolbarItem.logo)
      }}
    >
      <div className={styles.icon}>
        <IconPlus />
      </div>
      <Typography type={TType.Header13_500} className={styles.caption}>
        {t(I18N.editorTools.logoPlaceholder.addLogoAndEdit)}
      </Typography>
    </div>
  )
})
