import React, { useContext, FC } from "react"
import _isString from "lodash/isString"
import _first from "lodash/first"
import _split from "lodash/split"
import "intl"

import {
  loadIntlMessages,
  getLanguageTag,
} from "shared-libs/src/js/libs/AsyncI18nLocales"

import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  InjectedIntl,
  IntlProvider,
} from "react-intl"

/**
 * Z (apakuj.to) (supported) Language
 */
export type ZLanguage =
  | "en"
  | "de"
  | "cs"
  | "fr"
  | "es"
  | "pl"
  | "it"
  | "se"
  | "ro"
  | "nl"

/**
 * A locale is a language-COUNTRY(REGION) combination
 *
 * Please note that a language (see also ZLanguaue type)
 * can be native/active for multiple countries,
 * regions, territories, e.g. for "spanish" / "es" language the
 * list is as follows:
 *
 * es-AR
 * es-BO
 * es-CL
 * es-CO
 * es-CR
 * es-DO
 * es-EC
 * es-ES
 * es-GT
 * es-HN
 * es-MX
 * es-NI
 * es-PA
 * es-PE
 * es-PR
 * es-PY
 * es-SV
 * es-US
 * es-UY
 * es-VE
 */
type ZLocale =
  | "pl-PL"
  | "en-US"
  | "de-DE"
  | "cs-CZ"
  | "fr-FR"
  | "it-IT"
  | "es-ES"
  | "sv-SE"
  | "ro-RO"
  | "nl-NL"

/**
 * In our case, a languageTag the same as the locale
 */
export type ZLanguageTag = ZLocale

/**
 * Translation context with React hooks support
 */
export const IntlContext = React.createContext<InjectedIntl>({} as InjectedIntl)
export const InjectIntlContext = injectIntl(({ intl, children }) => {
  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>
})

export const getFormatMessage = async () => {
  const loadMessages = async () => loadIntlMessages

  const messages = await loadMessages()
  const intlProvider = new IntlProvider({ locale: getLanguageTag(), messages })
  const { intl } = intlProvider.getChildContext()

  return intl.formatMessage
}

export const useFormatMessage = () => {
  const intl = useContext(IntlContext)
  return intl.formatMessage
}

export const useFormatMessageWithValues = (id, values) => {
  return <FormattedMessage id={id} values={values} />
}

export const useReactIntlObject = () => {
  const intl = useContext(IntlContext)
  return intl
}

export const BareFormattedMessage: FC<{ id: string; values?: any }> = ({
  id,
  values,
}) => {
  const translate = useFormatMessage()
  return <>{translate({ id: id }, values)}</>
}

// -- End of translation context for React Hooks

interface IntlData {
  locales: ZLanguageTag[]
  messages: { [k in ZLanguageTag]?: any }
}

export function getIntlData() {
  return {
    locales: [
      "pl-PL",
      "en-US",
      "de-DE",
      "cs-CZ",
      "fr-FR",
      "it-IT",
      "es-ES",
      "nl-NL",
      "ro-RO",
      "sv-SE",
    ],
    messages: {
      // "pl-PL": plPL,
      // "en-US": enUS,
      // "de-DE": deDE
      // "cs-CZ": csCZ,
      // "fr-FR": frFR,
      // "it-IT": itIT,
      // "es-ES": esES
    },
  } as IntlData
}

export function translateMsgOrTextWithValues(labeli18n, i18nValues?: any) {
  return <FormattedHTMLMessage id={labeli18n} values={i18nValues} />
}

export function translatedMsgOrText(labeli18n, labelText) {
  let label

  if (_isString(labeli18n)) {
    label = <FormattedMessage id={labeli18n} />
  } else {
    label = labelText
  }
  return label
}

/**
 * Converts a locale (tag) (something like 'en-US')
 * to a language (something like 'en')
 */
export const getLanguageOfLanguageTag = (
  languageTag: ZLanguageTag
): ZLanguage => {
  const language = _first(_split(languageTag, "-"))
  switch (language) {
    case "cs":
    case "de":
    case "en":
    case "es":
    case "fr":
    case "it":
    case "pl":
    case "nl":
    case "ro":
    case "se":
      return language
    default:
      throw new Error(
        `Unknown language ${language} of language-tag ${languageTag}`
      )
  }
}
