var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import PAPER_TUBE_CONSTS from "../../../../../source/paperTubeProductSpec/generalConfig/consts/paperTubeConsts";
import { getOldVariantCodeName } from "../../../../../definitions/products/newProductVariantsToOld";
import { ProductType } from "../../../types";
var addPaperTubeVariantBehaviors = function (superclass) {
    function findVariant() {
        return PAPER_TUBE_CONSTS.PAPER_CAN_PRODUCT_VARIANT_STANDARD;
    }
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            return _super.call(this, props) || this;
        }
        class_1.findVariant = function () {
            return findVariant();
        };
        class_1.prototype.getVariant = function () {
            return findVariant();
        };
        class_1.prototype.getVariantStructure = function () {
            return this.variant.variants[this.getVariant()];
        };
        class_1.prototype.getVariantDashed = function () {
            return getOldVariantCodeName(this.getVariant());
        };
        class_1.prototype.getProductType = function () {
            return ProductType.Custom;
        };
        return class_1;
    }(superclass));
};
export default addPaperTubeVariantBehaviors;
