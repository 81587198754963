import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey, isProductTypeCustom, } from "../SkuLib";
import CustomTissuePaperProduct from "../CustomProducts/CustomTissuePaperProduct";
import TissuePaperProduct from "../Products/TissuePaperProduct/TissuePaperProduct";
import TissuePaperVariantManager from "../Products/TissuePaperProduct/behaviorsComposers/TissuePaperVariantManager";
function TissuePaperProductFactory(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    var productName = decodedSKU.productName;
    var productVariant = TissuePaperVariantManager.findVariant(productName);
    if (isProductTypeCustom(sku)) {
        return new CustomTissuePaperProduct(sku, productName);
    }
    else {
        return new TissuePaperProduct(sku, productName, productVariant);
    }
}
export default TissuePaperProductFactory;
