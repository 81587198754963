import CardProductSpec from "../../../../source/cardProductSpec/cardProductSpec";
import ConfigBuilder from "../../configBuilders/ConfigBuilder";
// Global product behaviors composers
import ProductionTimeManager from "../../behaviorsComposers/ProductionTimeManager";
import GrammagesManager from "../../behaviorsComposers/GrammagesManager";
import AssetsManager from "../../behaviorsComposers/AssetsManager";
import WeightManager from "../../behaviorsComposers/WeightManager";
import WizzardManager from "../../behaviorsComposers/WizzardManager";
import SiblingsManager from "../../behaviorsComposers/SiblingsManager";
import QuantitiesManager from "../../behaviorsComposers/QuantitiesManager";
// Card specific behaviors composers
import CardVariantManager from "./behaviorsComposers/CardVariantManager";
import CardTranslationsManager from "./behaviorsComposers/CardTranslationsManager";
import CardRenderConfigManager from "./behaviorsComposers/CardRendeConfigManager";
import CardEditConfigManager from "./behaviorsComposers/CardEditConfigManager";
import CardDimensionsManager from "./behaviorsComposers/CardDimensionsManager";
import CardAddonsManager from "./behaviorsComposers/CardAddonsManager";
import ProductFactory from "../../ProductFactory";
var CardProduct = /** @class */ (function () {
    function CardProduct(sku, productName, productVariant) {
        this.config = this.builder(CardProductSpec, sku, productName, productVariant);
        this.variantManager = new CardVariantManager(this);
        this.assetsManager = new AssetsManager(this);
        this.grammagesManager = new GrammagesManager(this);
        this.productionTimeManager = new ProductionTimeManager(this);
        this.weightsManager = new WeightManager(this);
        this.wizzardManager = new WizzardManager(this);
        this.dimensionsManager = new CardDimensionsManager(this);
        this.siblingsManager = new SiblingsManager(this);
        this.editConfigManager = new CardEditConfigManager(this);
        this.translationsManager = new CardTranslationsManager(this);
        this.addonsManager = new CardAddonsManager(this);
        this.renderConfigManager = new CardRenderConfigManager(this);
        this.quantitiesManager = new QuantitiesManager(this);
    }
    CardProduct.prototype.builder = function (spec, sku, productName, productVariant) {
        return ConfigBuilder(spec, sku, productName, productVariant);
    };
    CardProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    CardProduct.prototype.getExtraTechnicalDetails = function () {
        return undefined;
    };
    CardProduct.prototype.getEditorBackgroundPath = function (side) {
        return "";
    };
    CardProduct.prototype.createBaseProductSku = function () {
        return this.addonsManager.setDefaultAddons();
    };
    CardProduct.prototype.getBaseProduct = function () {
        return ProductFactory(this.createBaseProductSku());
    };
    return CardProduct;
}());
export default CardProduct;
