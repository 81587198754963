import React from "react"
import { observer } from "mobx-react-lite"
import cxBinder from "classnames/bind"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { Link } from "dsl/src/atoms/Link/Link"
import { TM } from "../../../TypographyI18n"
import { TagIcon } from "../../atoms/TagIcon/TagIcon"
import { ReactComponent as IconPlus } from "../../../assets/svg/plus.svg"

import { ReactComponent as UploadIcon } from "../../../assets/svg/Upload.svg"
import { ReactComponent as UploadFailedIcon } from "../../../assets/svg/error.svg"
import { ReactComponent as UploadSpinnerIcon } from "../../../assets/svg/spinner.svg"

import { useTranslate } from "../../../hooks/useTranslate"

import styles from "./Upload.module.scss"
import { getTranslationKeyForAssetError } from "../../../../services/asset-service/asset-errors"
import { ImageAssetItemStore } from "../../../../stores/assets-store/image-asset-item.store"

const cx = cxBinder.bind(styles)

interface StateInitialProps {
  addImageLabel: string
}

const StateInitial = ({ addImageLabel }: StateInitialProps) => {
  const t = useTranslate()
  return (
    <>
      <UploadIcon className={cx("upload-icon")} />
      <p>
        <span className={cx("content__mobile", "highlight")}>
          {t(addImageLabel)}
        </span>
        <span className={cx("content__desktop")}>
          <Typography type={TType.Body13_350}>
            <span className={cx("highlight")}>{t(addImageLabel)}</span>{" "}
          </Typography>
        </span>
      </p>
    </>
  )
}

type StateUploadingProps = {
  onCancel?(): void
  disabled?: boolean
  labels?: {
    uploading: string
    cancel: string
  }
}

export const StateUploading = ({
  onCancel,
  labels: { uploading, cancel } = { ...defaultLabels },
}: StateUploadingProps) => {
  const t = useTranslate()
  return (
    <>
      <UploadSpinnerIcon className={styles.loading_circle} />
      <TM
        className={styles.content__desktop}
        id={uploading}
        type={TType.Body13_350}
      />
      {onCancel && (
        <Link underlined onClick={onCancel}>
          {t(cancel)}
        </Link>
      )}
    </>
  )
}

interface StateErrorProps {
  error?: React.ReactNode
  onRetry?(): void
  errorLabel: string
}

const StateError = ({ onRetry, errorLabel }: StateErrorProps) => {
  const t = useTranslate()
  return (
    <>
      <UploadFailedIcon />
      <TM
        className={styles.content__desktop}
        id={errorLabel}
        type={TType.Body13_350}
      />
      <Link underlined onClick={onRetry}>
        {t("editor.upload.retry")}
      </Link>
    </>
  )
}

export const StateLogoUploaded = ({
  imageURL,
  onClick,
  caption = "user logo",
}) => (
  <div onClick={onClick} className={styles.logo_preview_wrapper}>
    <img
      className={cx({
        logo_preview: true,
      })}
      src={imageURL}
      alt={caption}
    />
    <TagIcon Icon={IconPlus} />
  </div>
)

const defaultLabels = {
  addImage: "editor.upload.add-logo",
  dropFile: "editor.upload.add-file",
  uploading: "editor.upload.uploading",
  cancel: "editor.upload.cancel",
  error: "editor.upload.error",
}
interface ImageUploadProps {
  isSetAsLogo: boolean
  onSelectImage: (image: ImageAssetItemStore) => void
  onRetry?: () => void
  onCancel?: () => void
  image: ImageAssetItemStore
  labels?: {
    addImage: string
    dropFile: string
    uploading: string
    cancel: string
    error: string
  }
}

export const ImageUpload = observer(
  ({
    isSetAsLogo,
    onSelectImage,
    onRetry,
    onCancel,
    image,
    labels = defaultLabels,
  }: ImageUploadProps) => {
    const t = useTranslate()

    let isUploading,
      isTransforming,
      hasTransformed,
      hasUploaded,
      error,
      previewUri

    if (image) {
      isUploading = image.isUploading
      isTransforming = image.isTransforming
      hasTransformed = image.hasTransformed
      hasUploaded = image.hasUploaded
      error = image.error
        ? t(getTranslationKeyForAssetError(image.error))
        : null
      previewUri = image.previewUri
    }

    const displayUploadState = () => {
      if ((isUploading || isTransforming) && !isSetAsLogo) {
        return (
          <StateUploading
            onCancel={onCancel}
            labels={{ uploading: labels.uploading, cancel: labels.cancel }}
          />
        )
      }

      if (error) {
        return <StateError onRetry={onRetry} errorLabel={labels.error} />
      }

      if (hasTransformed && hasUploaded) {
        return (
          <StateLogoUploaded
            onClick={() => onSelectImage(image)}
            imageURL={previewUri}
          />
        )
      }

      return <StateInitial addImageLabel={defaultLabels.addImage} />
    }

    return (
      <div
        className={cx({
          wrapper: true,
          error: error,
          success: hasUploaded,
          success_logo: hasUploaded,
          uploading: isUploading,
        })}
      >
        <div className={styles.upload_state}>{displayUploadState()}</div>
      </div>
    )
  }
)
