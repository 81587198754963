var _a;
import POLY_MAILER_CONSTS from "./consts/polyMailerConsts";
export default (_a = {
        default: {
            DEFAULT_QUANTITY: 100,
            QUANTITY_RANGES: [100, 200, 500, 1000, 2000, 5000, 10000],
            QUANTITY_DENSITY: 500,
            QUANTITY_STEP: 10,
        }
    },
    _a[POLY_MAILER_CONSTS.BIO_POLY_MAILER_PLAIN_MODEL_DEFAULT] = {
        DEFAULT_QUANTITY: 100,
        QUANTITY_RANGES: [
            100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
            1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400, 2500, 5000,
            10000,
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 100,
    },
    _a[POLY_MAILER_CONSTS.BIO_POLY_MAILER_MODEL_DEFAULT] = {
        DEFAULT_QUANTITY: 100,
        QUANTITY_RANGES: [
            100, 200, 500, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 10000,
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a[POLY_MAILER_CONSTS.RECYCLED_POLY_MAILER_MODEL_DEFAULT] = {
        DEFAULT_QUANTITY: 100,
        QUANTITY_RANGES: [
            100, 200, 300, 400, 500, 750, 1000, 1500, 2000, 5000, 10000,
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a[POLY_MAILER_CONSTS.POLY_MAILER_MODEL_DEFAULT] = {
        DEFAULT_QUANTITY: 100,
        QUANTITY_RANGES: [100, 200, 500, 1000, 2000, 5000, 10000],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a);
