import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import { Button } from "dsl/src/atoms/Button/Button"
import { ModalTemplate } from "../partials/ModalTemplate"
import { TType } from "dsl/src/atoms/Typography/Typography"
import { TM } from "../../../../TypographyI18n"
import { i18n } from "./utils/download-or-print.translations"
import { useTranslate } from "../../../../hooks/useTranslate"
import { isShippingCountryComingSoon } from "shared-libs/src/js/libs/helpers/region-config.helpers"
import { DetailsPanelPrint } from "./components/DetailsPanelPrint"
import {
  Notification,
  NotificationVariant,
} from "../../../atoms/Notification/Notification"

import styles from "../download-or-print/DownloadOrPrint.module.scss"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

interface DownloadOrPrintProps {
  submitAction: () => void
  onClose: () => void
}

export const DownloadOrPrint = observer(
  ({ submitAction, onClose }: DownloadOrPrintProps) => {
    const t = useTranslate()

    const [isNotificationVisible, setIsNotificationVisible] = useState(false)
    const [containerSet] = useContainerSet((c) => [c.analytics])

    if (!containerSet) {
      return null
    }

    const { analytics } = containerSet

    const onAddProductToCart = () => {
      analytics.analyticsController?.trackPrintWithUsCtaClicked()

      if (isShippingCountryComingSoon()) {
        setIsNotificationVisible(true)
      } else {
        submitAction()
      }
    }

    return (
      <ModalTemplate
        header={<TM id={i18n.review_and_purchase} type={TType.Header32_400} />}
        footer={
          <>
            {isNotificationVisible && (
              <div className={styles.notification}>
                <Notification variant={NotificationVariant.warning}>
                  {t(i18n.not_available_in_your_country)}
                </Notification>
              </div>
            )}
            <Button fluid onClick={onAddProductToCart}>
              <TM id={i18n.add_to_cart} type={TType.Header17_500} />
            </Button>
          </>
        }
      >
        <div className={styles.content}>
          <DetailsPanelPrint onClose={onClose} />
        </div>
      </ModalTemplate>
    )
  }
)
