import _flow from "lodash/flow";
import RenderConfigManager from "../../../behaviorsComposers/RenderConfigManger";
import addBoxRenderConfigBehaviors from "../../../behaviors/boxBehaviors/renderConfigBehaviors/BoxRenderConfigBehaviors";
import addSleeveMailerBoxRenderConfigBehaviors from "../../../behaviors/boxBehaviors/renderConfigBehaviors/SleevedMailerBoxRenderConfigBehaviors/SleeveMailerBoxRenderConfigBehaviors";
import addDeliveryMailerBoxRenderConfigBehaviors from "../../../behaviors/boxBehaviors/renderConfigBehaviors/DeliveryMailerBoxRenderConfig";
export default addBoxRenderConfigBehaviors(RenderConfigManager);
var OriginBoxesRenderConfigManager = _flow([addBoxRenderConfigBehaviors])(RenderConfigManager);
var SleevedMailerBoxRenderConfigManager = _flow([
    addSleeveMailerBoxRenderConfigBehaviors,
])(OriginBoxesRenderConfigManager);
var DeliveryMailerBoxRenderConfigManager = _flow([
    addDeliveryMailerBoxRenderConfigBehaviors,
])(OriginBoxesRenderConfigManager);
export { OriginBoxesRenderConfigManager, SleevedMailerBoxRenderConfigManager, DeliveryMailerBoxRenderConfigManager, };
