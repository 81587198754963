import _flow from "lodash/flow";
import labelProductSpec from "../../../source/labelProductSpec/labelProductSpec";
import CustomProductConfigBuilder from "../configBuilders/CustomProductConfigBuilder";
import addCustomProductDimensionsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductDimensionsBehaviors";
import addCustomProductProductionTimesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductProductionTimesBehaviors";
import addCustomProductVariantBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductVariantBehaviors";
import addCustomProductEditConfigBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductEditConfigBehaviors";
import addCustomProductGrammagesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductGrammagesBehaviors";
import addCustomProductTranslationsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductTranslationsBehaviors";
import LabelTranslationsManager from "../Products/LabelProduct/behaviorsComposers/LabelTranslationsManager";
import LabelVariantManager from "../Products/LabelProduct/behaviorsComposers/LabelVariantManager";
import GrammagesManager from "../behaviorsComposers/GrammagesManager";
import WizzardManager from "../behaviorsComposers/WizzardManager";
import EditConfigManager from "../behaviorsComposers/EditConfigManager";
import ProductionTimeManager from "../behaviorsComposers/ProductionTimeManager";
import LabelDimensionsManager from "../Products/LabelProduct/behaviorsComposers/LabelDimensionsManager";
var CustomProductionTimeManager = addCustomProductProductionTimesBehaviors(ProductionTimeManager);
var CustomProductGrammagesManager = addCustomProductGrammagesBehaviors(GrammagesManager);
var CustomProductDimmensionsManager = addCustomProductDimensionsBehaviors(LabelDimensionsManager);
var CustomProductEditorConfigManager = addCustomProductEditConfigBehaviors(EditConfigManager);
var CustomProductTranslationsManager = addCustomProductTranslationsBehaviors(LabelTranslationsManager);
var CustomProductVariantManager = _flow([addCustomProductVariantBehaviors])(LabelVariantManager);
var CustomLabelProduct = /** @class */ (function () {
    function CustomLabelProduct(sku, productName) {
        this.config = CustomProductConfigBuilder(labelProductSpec, sku, productName);
        this.wizzardManager = new WizzardManager(this);
        this.productionTimeManager = new CustomProductionTimeManager(this);
        this.editConfigManager = new CustomProductEditorConfigManager(this);
        this.variantManager = new CustomProductVariantManager(this);
        this.grammagesManager = new CustomProductGrammagesManager(this);
        this.dimensionsManager = new CustomProductDimmensionsManager(this);
        this.translationsManager = new CustomProductTranslationsManager(this);
    }
    CustomLabelProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    CustomLabelProduct.prototype.getMaterialDescription = function () {
        return null;
    };
    return CustomLabelProduct;
}());
export default CustomLabelProduct;
