import React, { PropsWithChildren, useContext } from "react"
import { observer } from "mobx-react-lite"
import cxBinder from "classnames/bind"

import {
  Notification,
  NotificationVariant,
} from "../../../dsl/atoms/Notification/Notification"

import { RootStoreContext } from "../../../../stores/store-contexts"
import { RootStore } from "../../../../stores/root.store"

import { EditableObjectTypes } from "../../../../modules/ph-api/asset-types"
import {
  KeyboardEventKey,
  useToggleOnKeyPress,
} from "../../../hooks/useToggleOnKeyPress.hook"

import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import footerStyles from "./SceneFooter.module.scss"
const cx = cxBinder.bind(footerStyles)

interface SceneFooter2DProps {
  is3DView: boolean
  isHidden: boolean
}

const SceneFooter = (props: PropsWithChildren<{}>) => (
  <footer
    className={cx({
      scene_footer: true,
      "scene_footer--footer-2d": true,
    })}
  >
    {props.children}
  </footer>
)
const footerI18N = I18N.component.footer
export const SceneFooter2D = ({ isHidden, is3DView }: SceneFooter2DProps) => {
  const t = useTranslate()
  const toggleMsg = t(footerI18N.toggleView)
  const spaceMsg = is3DView ? t(footerI18N.side) : t(footerI18N.threeDim)
  return (
    <SceneFooter>
      {!isHidden ? (
        <Notification
          textCenter
          halfWidth
          variant={NotificationVariant.info}
          e2eTargetName="side-info"
        >
          {`${toggleMsg} ${spaceMsg}`}
        </Notification>
      ) : null}
    </SceneFooter>
  )
}

export const SceneFooter2DWired = observer(() => {
  const { productDriver } = useContext<RootStore>(RootStoreContext)
  const { is3DPreviewOn, isVirtualDielineLoading } = productDriver.state
  const activeObjectType = productDriver.activeObjectDriver.activeObjectType

  const toggle3DPreview = () => {
    productDriver.toggle3DPreview()
  }

  const timeout = is3DPreviewOn ? undefined : 5000
  const { isHidden } = useToggleOnKeyPress(
    toggle3DPreview,
    activeObjectType === EditableObjectTypes.assetText ||
      isVirtualDielineLoading,
    KeyboardEventKey.space,
    timeout
  )

  return <SceneFooter2D isHidden={isHidden} is3DView={is3DPreviewOn} />
})
