import React, { FC } from "react"
import Slider from "dsl/src/atoms/Slider/Slider"
import styles from "./MonochromeSlider.module.scss"

export interface MonochromeSliderProps {
  onChange: (thresholdlvl: any) => void
  value: any
}

const thresholdTiles: number[] = [...Array(10).keys()].reverse()

export const MonochromeSlider: FC<MonochromeSliderProps> = ({
  onChange,
  value,
}) => {
  return (
    <div className={styles.slider}>
      <div className={styles.slider__input}>
        <Slider
          min={1}
          max={10}
          step={1}
          value={value * 10}
          onChange={(sliderValue) => onChange(sliderValue / 10)}
          hideTrack
          bigSlider
        />
      </div>
      <ul className={styles["steps-list"]}>
        {thresholdTiles.map((tile) => (
          <li key={tile} className={styles["steps-list__item"]}>
            <div className={styles.step} />
          </li>
        ))}
      </ul>
    </div>
  )
}
