var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import PRODUCTION_TIMES_CONSTS from "../../../productSharedConsts/productionTimeConsts";
import TISSUE_PAPER_CONST from "../consts/tissuePaperConsts";
var DEFAULT = PRODUCTION_TIMES_CONSTS.DEFAULT, EXPRESS = PRODUCTION_TIMES_CONSTS.EXPRESS, SUPEREXPRESS = PRODUCTION_TIMES_CONSTS.SUPEREXPRESS, PRODUCTION_TIME = PRODUCTION_TIMES_CONSTS.PRODUCTION_TIME, DTP_DEADLINE = PRODUCTION_TIMES_CONSTS.DTP_DEADLINE, HIGH_QUANTITY = PRODUCTION_TIMES_CONSTS.HIGH_QUANTITY;
var PAPER_PRODUCTION_TIME = (_a = {},
    _a[TISSUE_PAPER_CONST.TISSUE_PAPER] = (_b = {},
        _b[TISSUE_PAPER_CONST.TISSUE_PAPER_VARIANT_STANDARD] = (_c = {},
            _c[PRODUCTION_TIME] = (_d = {},
                _d[DEFAULT] = 16,
                _d[EXPRESS] = 11,
                _d[SUPEREXPRESS] = 5,
                _d[HIGH_QUANTITY] = (_e = {},
                    _e[2500] = { max: 32 },
                    _e),
                _d),
            _c[DTP_DEADLINE] = (_f = {},
                _f[DEFAULT] = 1,
                _f),
            _c),
        _b),
    _a[TISSUE_PAPER_CONST.PACKING_PAPER] = (_g = {},
        _g[TISSUE_PAPER_CONST.PACKING_PAPER_VARIANT_STANDARD] = (_h = {},
            _h[PRODUCTION_TIME] = (_j = {},
                _j[DEFAULT] = 16,
                _j[EXPRESS] = 11,
                _j[SUPEREXPRESS] = 5,
                _j[HIGH_QUANTITY] = (_k = {},
                    _k[2500] = { min: 17, max: 22 },
                    _k),
                _j),
            _h[DTP_DEADLINE] = (_l = {},
                _l[DEFAULT] = 1,
                _l),
            _h),
        _g),
    _a);
export default PAPER_PRODUCTION_TIME;
