var _a, _b, _c, _d, _e;
import CARD_CONSTS from "../consts/cardConsts";
var assetsPath = "/product-key-visuals/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var PrintedCardSizeAssets = {
    A6: {
        desktop: desktopPath + "A6.png",
        mobile: mobilePath + "A6.png",
    },
    A7: {
        desktop: desktopPath + "A7.png",
        mobile: mobilePath + "A7.png",
    },
    DL: {
        desktop: desktopPath + "DL.png",
        mobile: mobilePath + "DL.png",
    },
};
var COLOR_MODES_SVG_ICON_NAMES = (_a = {},
    _a[CARD_CONSTS.PRINT_MODE_MONO_PANTONE_ONE_SIDE] = "v5-print-one-site",
    _a[CARD_CONSTS.PRINT_MODE_MONO_PANTONE_TWO_SIDES] = "v5-print-two-sites",
    _a);
var imageKraft = "/product-material-picker/kraft.png";
var imageWhiteMatt = "/product-material-picker/white-matt.png";
var EditorMaterialImage;
(function (EditorMaterialImage) {
    EditorMaterialImage["WHITE_MATT"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-printed-card-white-matt.png";
    EditorMaterialImage["KRAFT"] = "https://editor.s3.eu-central-1.amazonaws.com/product-traits-assets/materials/material-printed-card-kraft.png";
})(EditorMaterialImage || (EditorMaterialImage = {}));
var editorImagePaths = (_b = {},
    _b[CARD_CONSTS.PRINT_MATERIAL_WHITE_MATT] = EditorMaterialImage.WHITE_MATT,
    _b[CARD_CONSTS.PRINT_MATERIAL_KRAFT] = EditorMaterialImage.KRAFT,
    _b);
var GLOBAL_ASSETS = {
    materialImagesPaths: (_c = {},
        _c[CARD_CONSTS.PRINT_MATERIAL_KRAFT] = imageKraft,
        _c[CARD_CONSTS.PRINT_MATERIAL_WHITE_MATT] = imageWhiteMatt,
        _c),
    editorImagePaths: editorImagePaths,
};
var PRODUCT_ASSETS = (_d = {},
    _d[CARD_CONSTS.PRINTED_CARD_MODEL] = (_e = {},
        _e[CARD_CONSTS.PRINTED_CARD_VARIANT] = {
            keyVisual: [assetsPath + "cards_desktop.png"],
            sizeAssets: PrintedCardSizeAssets,
        },
        _e),
    _d);
var CARD_PRODUCT_ASSETS = {
    globalAssets: GLOBAL_ASSETS,
    productAssets: PRODUCT_ASSETS,
};
export default CARD_PRODUCT_ASSETS;
