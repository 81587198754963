import ProductDriver from "../../stores/product-driver/product.driver"

const DEVELOPER_MODE_DIELINE_PREVIEW_ELEMENT_ID =
  "developer-mode-dieline-preview"

export class DeveloperModeController {
  private isDielinePreviewVisible: boolean = false

  constructor(private productDriver: ProductDriver) {}

  public handleKeyboardShortcut = (
    e: React.KeyboardEvent<HTMLElement> | KeyboardEvent
  ) => {
    this.logKeyboardShortcut(e)

    const key = e.key.toLowerCase()

    if (e.ctrlKey && key === "d") {
      this.toggleDielinePreview(e.currentTarget! as HTMLElement)
    }
  }

  private logKeyboardShortcut = (
    e: React.KeyboardEvent<HTMLElement> | KeyboardEvent
  ) => {
    console.log(`Key: ${e.key}`)
    console.log(
      `CTRL=${e.ctrlKey ? "ON" : "OFF"} | ` +
        `ALT: ${e.altKey ? "ON" : "OFF"} | ` +
        `SHIFT: ${e.shiftKey ? "ON" : "OFF"} | ` +
        `META: ${e.metaKey ? "ON" : "OFF"}`
    )
  }

  private toggleDielinePreview = async (element: HTMLElement) => {
    console.log(
      `Dieline preview is currently ` +
        `${this.isDielinePreviewVisible ? "ON" : "OFF"}`
    )
    console.log("Toggle dieline preview")

    if (!this.isDielinePreviewVisible) {
      const image = await this.productDriver.generateDielinePreview(
        this.productDriver.state.activeContext
      )

      image && this.displayDielinePreview(image, element)
    } else {
      this.removeDielinePreview()
    }

    this.isDielinePreviewVisible = !this.isDielinePreviewVisible
  }

  private displayDielinePreview = (
    imageSource: string,
    wrapper: HTMLElement
  ) => {
    const imageContainer = document.createElement("div")
    imageContainer.id = DEVELOPER_MODE_DIELINE_PREVIEW_ELEMENT_ID

    imageContainer.style.backgroundColor = "white"
    imageContainer.style.position = "absolute"
    imageContainer.style.zIndex = "999999999"
    imageContainer.style.top = "0"
    imageContainer.style.left = "0"
    imageContainer.style.right = "0"
    imageContainer.style.bottom = "0"
    imageContainer.style.display = "flex"
    imageContainer.style.justifyContent = "center"
    imageContainer.style.alignItems = "center"
    imageContainer.style.pointerEvents = "none"

    const imageElement = document.createElement("img")
    imageElement.src = imageSource

    imageElement.style.maxHeight = "100vh"
    imageElement.style.pointerEvents = "none"

    imageContainer.append(imageElement)

    wrapper.append(imageContainer)
  }

  private removeDielinePreview = () => {
    const element = document.getElementById(
      DEVELOPER_MODE_DIELINE_PREVIEW_ELEMENT_ID
    )
    if (!!element) {
      element.remove()
    }
  }
}
