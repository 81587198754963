import React from "react"
import Link from "dsl/src/atoms/Link/Link"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { getLandingWalletBlogPage } from "../../utils/wallet.helpers"
import { useTranslate } from "../../../../../shared-components/i18n/useTranslate"
import { walletI18n } from "../../utils/wallet.translations"

type WalletTooltipLearnAboutWalletProps = {
  linkSegmentTrackClick: () => void
}

export const WalletSidebarLearnAboutWallet = (
  props: WalletTooltipLearnAboutWalletProps
) => {
  const { linkSegmentTrackClick } = props
  const walletBlogPostLink = getLandingWalletBlogPage()
  const t = useTranslate()
  return (
    <>
      <Typography type={TType.Body13_350}>
        {t(walletI18n.newTooltipConcern)}
      </Typography>

      {walletBlogPostLink && (
        <Link
          href={walletBlogPostLink}
          target="_blank"
          onClick={() => linkSegmentTrackClick()}
        >
          <Typography type={TType.Body13_350}>
            {t(walletI18n.newTooltipDiscoverButton)}
          </Typography>
        </Link>
      )}
    </>
  )
}
