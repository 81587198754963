var _a, _b, _c;
import BOX_CONSTS from "../generalConfig/consts/boxConsts";
var SLEEVE_MAILER_BOX_KRAFT_COLORS = [
    {
        uid: 2,
        color: "#FFEE04",
        description: "box-editor.pantone-picker.color-descriptions.yellow",
    },
    {
        uid: 3,
        color: "#000000",
        description: "box-editor.pantone-picker.color-descriptions.black",
    },
    {
        uid: 4,
        color: "#DF5C25",
        description: "box-editor.pantone-picker.color-descriptions.orange",
    },
    {
        uid: 5,
        color: "#D31272",
        description: "box-editor.pantone-picker.color-descriptions.pink",
    },
    {
        uid: 6,
        color: "#D31F26",
        description: "box-editor.pantone-picker.color-descriptions.red",
    },
    {
        uid: 7,
        color: "#CCD52A",
        description: "box-editor.pantone-picker.color-descriptions.green",
    },
    {
        uid: 8,
        color: "#005C9B",
        description: "box-editor.pantone-picker.color-descriptions.blue",
    },
    {
        uid: 9,
        color: "#752771",
        description: "box-editor.pantone-picker.color-descriptions.purple",
    },
];
var GENERIC_COLORS = [
    {
        uid: 1,
        color: "#FFFFFF",
        description: "box-editor.pantone-picker.color-descriptions.white",
    },
    {
        uid: 2,
        color: "#FFEE04",
        description: "box-editor.pantone-picker.color-descriptions.yellow",
    },
    {
        uid: 3,
        color: "#000000",
        description: "box-editor.pantone-picker.color-descriptions.black",
    },
    {
        uid: 4,
        color: "#DF5C25",
        description: "box-editor.pantone-picker.color-descriptions.orange",
    },
    {
        uid: 5,
        color: "#D31272",
        description: "box-editor.pantone-picker.color-descriptions.pink",
    },
    {
        uid: 6,
        color: "#D31F26",
        description: "box-editor.pantone-picker.color-descriptions.red",
    },
    {
        uid: 7,
        color: "#CCD52A",
        description: "box-editor.pantone-picker.color-descriptions.green",
    },
    {
        uid: 8,
        color: "#005C9B",
        description: "box-editor.pantone-picker.color-descriptions.blue",
    },
    {
        uid: 9,
        color: "#752771",
        description: "box-editor.pantone-picker.color-descriptions.purple",
    },
];
var ProductPrederedColors = {
    COLORS_CONFIG: (_a = {
            SHARED: {
                EDITOR_DEFAULT_COLOR: (_b = {
                        DEFAULT: {
                            color: "#000",
                        }
                    },
                    _b[BOX_CONSTS.MAILER_BOX_WHITE_ON_KRAFT_VARIANT] = {
                        color: "#FFF",
                    },
                    _b),
            }
        },
        _a[BOX_CONSTS.MAILER_BOX] = {
            VARIANTS: (_c = {},
                _c[BOX_CONSTS.MAILER_BOX_ECO_VARIANT] = {
                    PANTONE_COLORS: false,
                    LIMITED_COLORS: false,
                    LIMITED_COLORS_DESCRIPTION: false,
                },
                _c),
        },
        _a),
    COLORS_LISTS: {
        GENERIC: GENERIC_COLORS,
        SLEEVE_MAILER_BOX_KRAFT_COLORS: SLEEVE_MAILER_BOX_KRAFT_COLORS,
    },
};
export default ProductPrederedColors;
