var _a, _b, _c;
import ENVELOPE_CONSTS from "../consts/envelopeConsts";
var DEFAULT = "default";
var ENVELOPE_PRODUCTS_WEIGHTS = (_a = {},
    _a[ENVELOPE_CONSTS.ENVELOPE] = (_b = {},
        _b[ENVELOPE_CONSTS.ENVELOPE_VARIANT_STANDARD] = (_c = {},
            _c[DEFAULT] = {
                C4: 0.054,
                C5: 0.03,
            },
            _c),
        _b),
    _a);
export default ENVELOPE_PRODUCTS_WEIGHTS;
