import _flow from "lodash/flow";
import bagProductSpec from "../../../source/bagProductSpec/bagProductSpec";
import CustomProductConfigBuilder from "../configBuilders/CustomProductConfigBuilder";
import addCustomProductDimensionsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductDimensionsBehaviors";
import addCustomProductProductionTimesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductProductionTimesBehaviors";
import addCustomProductVariantBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductVariantBehaviors";
import addCustomProductEditConfigBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductEditConfigBehaviors";
import addCustomProductGrammagesBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductGrammagesBehaviors";
import addCustomProductTranslationsBehaviors from "../behaviors/customAndExtensionProductsBehaviors/generalBehaviors/addCustomProductTranslationsBehaviors";
import PaperBagTranslationsManager from "../Products/PaperBagProduct/behaviorsComposers/PaperBagTranslationsManager";
import PaperBagVariantManager from "../Products/PaperBagProduct/behaviorsComposers/PaperBagVariantManager";
import GrammagesManager from "../behaviorsComposers/GrammagesManager";
import WizzardManager from "../behaviorsComposers/WizzardManager";
import EditConfigManager from "../behaviorsComposers/EditConfigManager";
import ProductionTimeManager from "../behaviorsComposers/ProductionTimeManager";
import PaperBagDimensionsManager from "../Products/PaperBagProduct/behaviorsComposers/PaperBagDimensionsManager";
var CustomProductionTimeManager = addCustomProductProductionTimesBehaviors(ProductionTimeManager);
var CustomProductGrammagesManager = addCustomProductGrammagesBehaviors(GrammagesManager);
var CustomProductDimmensionsManager = addCustomProductDimensionsBehaviors(PaperBagDimensionsManager);
var CustomProductEditrConfigManager = addCustomProductEditConfigBehaviors(EditConfigManager);
var CustomProductTranslationsManager = addCustomProductTranslationsBehaviors(PaperBagTranslationsManager);
var CustomProductVariantManager = _flow([addCustomProductVariantBehaviors])(PaperBagVariantManager);
var CustomPaperBagProduct = /** @class */ (function () {
    function CustomPaperBagProduct(sku, productName) {
        this.config = CustomProductConfigBuilder(bagProductSpec, sku, productName);
        this.wizzardManager = new WizzardManager(this);
        this.productionTimeManager = new CustomProductionTimeManager(this);
        this.editConfigManager = new CustomProductEditrConfigManager(this);
        this.variantManager = new CustomProductVariantManager(this);
        this.grammagesManager = new CustomProductGrammagesManager(this);
        this.dimensionsManager = new CustomProductDimmensionsManager(this);
        this.translationsManager = new CustomProductTranslationsManager(this);
    }
    CustomPaperBagProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    CustomPaperBagProduct.prototype.getMaterialDescription = function () {
        return null;
    };
    return CustomPaperBagProduct;
}());
export default CustomPaperBagProduct;
