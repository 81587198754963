export enum HeaderToolbarAction {
  group = "GROUP",
  ungroup = "UNGROUP",
  alignVertically = "ALIGN VERTICALLY",
  alignHorizontally = "ALIGN HORIZONTALLY",
  bringFront = "BRING FRONT",
  bringBack = "BRING BACK",
  duplicate = "DUPLICATE",
  remove = "REMOVE",
  removeGroup = "REMOVE GROUP",
  clip = "clip",
}
