var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import EXTENSIONS_CONSTS from "../../../../../source/extensionProductSpec/generalConfig/extensionProductConsts";
var addExtensionsTranslationsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            return _this;
        }
        class_1.prototype.getNameIntl = function () {
            var productName = this.product.variantManager.getName();
            switch (productName) {
                case EXTENSIONS_CONSTS.EXTENSION_PRODUCT_ASSET_DIELINE:
                    return "box-type.asset-dieline.name";
                case EXTENSIONS_CONSTS.EXTENSION_PRODUCT_ASSET_PRINTING_MATRIX:
                    return "box-type.asset-printing-matrix.name";
                case EXTENSIONS_CONSTS.EXTENSION_PRODUCT_DIGITAL_DESIGN:
                    return "box-type.asset-digital-design";
                case EXTENSIONS_CONSTS.EXTENSION_PRODUCT_OTHER:
                    return "box-type.other.name";
                case EXTENSIONS_CONSTS.EXTENSION_PRODUCT_SERVICE_CO_PACKING:
                    return "box-type.service-co-packing.name";
                case EXTENSIONS_CONSTS.EXTENSION_PRODUCT_SERVICE_DESIGN:
                    return "box-type.service-design.name";
                case EXTENSIONS_CONSTS.EXTENSION_PRODUCT_SERVICE_FULFILMENT:
                    return "box-type.service-fulfillment.name";
                case EXTENSIONS_CONSTS.EXTENSION_PRODUCT_SERVICE_PROOF:
                    return "box-type.service-proof.name";
                case EXTENSIONS_CONSTS.EXTENSION_PRODUCT_SERVICE_SHIPMENT:
                    return "box-type.service-shipment.name";
                case EXTENSIONS_CONSTS.EXTENSION_PRODUCT_SERVICE_SUBSCRIPTION:
                    return "box-type.service-subscription.name";
                case EXTENSIONS_CONSTS.EXTENSION_PRODUCT_SERVICE_TAXES:
                    return "box-type.service-taxes.name";
                default:
                    throw new Error("No translation for product name: ".concat(productName));
            }
        };
        return class_1;
    }(superclass));
};
export default addExtensionsTranslationsBehaviors;
