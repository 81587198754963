import usePortal from "react-useportal"

export const useTooltip = (
  { ...config } = { offsetTop: 0, offsetLeft: 0 },
  fixedLeft?: number
) => {
  const { isOpen, openPortal, closePortal, Portal } = usePortal({
    onOpen({ portal, targetEl: hoveredEl }) {
      const { top, left } = hoveredEl.current.getBoundingClientRect()
      const constantCSS = `
      position: absolute;
      z-index: 5;
      `
      const leftSettings = fixedLeft
        ? fixedLeft
        : left + (config.offsetLeft || 0)
      portal.current.style.cssText = constantCSS
      portal.current.style.cssText = `
      ${constantCSS}
      top: ${top + (config.offsetTop || 0)}px;
      left: ${leftSettings}px;
      `

      portal.current.dataset.type = "tooltip"
    },
    onClose: ({ portal }) => portal.current.removeAttribute("style"),
    ...config,
  })

  return {
    Tooltip: Portal,
    openTooltip: openPortal,
    closeTooltip: closePortal,
    isOpen,
  }
}
