import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { EditorToolReplicablePatternsDetails } from "./EditorToolReplicablePatternsDetails"
import { EditorToolReplicablePatternsSimple } from "./EditorToolReplicablePatternsSimple"
import { CardTool } from "../../../atoms/CardTool/CardTool"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

export const EditorToolReplicablePatterns = observer(() => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  const [containerSet] = useContainerSet((c) => [
    c.replicablePatterns,
    c.analytics,
  ])

  if (!containerSet || !containerSet.replicablePatterns.available) {
    return null
  }

  const { replicablePatterns, analytics } = containerSet
  const { replicablePatternsStore, replicablePatternUiController } =
    replicablePatterns

  const renderCardContent = () => {
    if (!replicablePatternsStore.isControllerInitialized) {
      return null
    }

    if (isDetailsOpen) {
      return (
        <EditorToolReplicablePatternsDetails
          onBackClick={setIsDetailsOpen}
          replicablePatternUiController={replicablePatternUiController}
          replicablePatternsStore={replicablePatternsStore}
        />
      )
    }

    return (
      <EditorToolReplicablePatternsSimple
        onButtonClick={setIsDetailsOpen}
        replicablePatternUiController={replicablePatternUiController}
        replicablePatternsStore={replicablePatternsStore}
        analyticsController={analytics.analyticsController}
      />
    )
  }

  return (
    <CardTool sizeXs cardName="replicablePatterns">
      {renderCardContent()}
    </CardTool>
  )
})
