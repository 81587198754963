var _a, _b, _c;
import TISSUE_PAPER_CONSTS from "../consts/tissuePaperConsts";
var assetsPath = "/product-key-visuals/";
var desktopPath = "/product-dimensions/desktop/1280x800_";
var mobilePath = "/product-dimensions/mobile/640x600_";
var customPath = "/product-custom-dimensions/";
var tissuePaperSizeAssets = {
    T120: {
        desktop: desktopPath + "T120.png",
        mobile: mobilePath + "T120.png",
    },
    custom: {
        desktop: customPath + "TissuePaper/Desktop/Custom/{locale}.png",
        mobile: customPath + "TissuePaper/Mobile/Custom/{locale}.png",
    },
};
var packingPaperSizeAssets = {
    T120: {
        desktop: desktopPath + "T120.png",
        mobile: mobilePath + "T120.png",
    },
};
var PRODUCT_ASSETS = (_a = {},
    _a[TISSUE_PAPER_CONSTS.TISSUE_PAPER_MODEL_DEFAULT] = (_b = {},
        _b[TISSUE_PAPER_CONSTS.TISSUE_PAPER_VARIANT_STANDARD] = {
            keyVisual: [assetsPath + "tissuepaper_desktop.png"],
            sizeAssets: tissuePaperSizeAssets,
        },
        _b),
    _a[TISSUE_PAPER_CONSTS.PACKING_PAPER_MODEL_DEFAULT] = (_c = {},
        _c[TISSUE_PAPER_CONSTS.PACKING_PAPER_VARIANT_STANDARD] = {
            keyVisual: [assetsPath + "packing-paper.png"],
            sizeAssets: packingPaperSizeAssets,
        },
        _c),
    _a);
var TISSUE_PAPER_PRODUCT_ASSETS = {
    globalAssets: {},
    productAssets: PRODUCT_ASSETS,
};
export default TISSUE_PAPER_PRODUCT_ASSETS;
