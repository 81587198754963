import React from "react"
import cxBinder from "classnames/bind"

import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { TagIcon } from "../TagIcon/TagIcon"
import { Pattern } from "../../../../libs/value-objects/pattern"
import InlineSvgWithLoader from "../../molecues/inline-svg-with-loader/InlineSvgWithLoader"

import { ReactComponent as IconChecked } from "../../../assets/svg/check.svg"

import styles from "./ThumbXL.module.scss"
import { useTooltip } from "../../../hooks/useTooltip"
import { ReactComponent as IconMore } from "../../../assets/svg/more.svg"

const cx = cxBinder.bind(styles)

type LoadingAttribute = "eager" | "lazy"

interface ThumbXLProps {
  isSelected: boolean
  isDisabled?: boolean
  isPlaceholderActive?: boolean
  label: React.ReactNode
  imageURL?: string
  background?: string
  currencyLabel?: React.ReactNode
  onClick(): void
  e2eTarget?: string
  e2eTargetName?: string
  imgLoading?: LoadingAttribute
  isError?: boolean
  tooltipIcon?: React.ElementType
  tooltipItem?: React.ReactNode
}

const ThumbXLPlaceholder = () => {
  return <span className={styles["thumb_picture--reset"]} />
}

export const ThumbXL = ({
  isSelected,
  isDisabled,
  isPlaceholderActive = false,
  background,
  imageURL,
  label,
  onClick,
  currencyLabel,
  e2eTarget,
  e2eTargetName,
  imgLoading,
  isError,
  tooltipIcon,
  tooltipItem,
}: ThumbXLProps) => {
  const { isOpen, closeTooltip, openTooltip, Tooltip } = useTooltip({
    offsetLeft: -16,
    offsetTop: 38,
  })

  let imgAttributes: { loading?: LoadingAttribute } = {}
  if (imgLoading) {
    imgAttributes.loading = imgLoading
  }

  return (
    <figure className={styles.thumb_wrapper}>
      <div
        className={cx({
          thumb: true,
          active: isSelected,
          error: isError,
          highlighted: isOpen,
          disabled: isDisabled,
        })}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        <picture
          className={cx({
            thumb_picture: true,
          })}
          style={{ background }}
          onClick={() => {
            !isDisabled && onClick()
          }}
        >
          {imageURL && <img src={imageURL} alt="" {...imgAttributes} />}
          {isPlaceholderActive && <ThumbXLPlaceholder />}
          {currencyLabel}
          {isSelected && (
            <span className={styles.checked}>
              <TagIcon Icon={IconChecked} />
            </span>
          )}
        </picture>
        {tooltipItem && !isDisabled && (
          <div
            onClick={isOpen ? closeTooltip : openTooltip}
            className={styles.thumb_more}
          >
            <TagIcon
              Icon={tooltipIcon ? tooltipIcon : IconMore}
              type="square"
              size="large"
            />
            {isOpen && <Tooltip>{tooltipItem}</Tooltip>}
          </div>
        )}
      </div>
      <Typography type={TType.Header13_500} htmlElement="figcaption">
        {label}
      </Typography>
    </figure>
  )
}

interface PatternThumbXLProps {
  currencyLabel?: React.ReactNode
  isPlaceholder?: boolean
  isSelected: boolean
  label?: JSX.Element
  onClick(): void
  pattern: Pattern
  pictureBackground?: string
  setPattern: (svg: SVGElement) => void
}

export const PatternThumbXL = ({
  currencyLabel,
  isPlaceholder = false,
  isSelected,
  label,
  onClick,
  pattern,
  pictureBackground = "#fff",
  setPattern,
}: PatternThumbXLProps) => {
  return (
    <figure className={styles.thumb_wrapper}>
      <div
        className={cx({
          thumb: true,
          active: isSelected,
        })}
        onClick={onClick}
        e2e-target="pattern"
        e2e-target-name={pattern.modelName}
      >
        <div
          style={{ backgroundColor: pictureBackground }}
          className={cx({
            thumb_picture: true,
            contain_svg: true,
          })}
        >
          {!isPlaceholder && (
            <InlineSvgWithLoader
              src={pattern.url}
              svgMountCallback={setPattern}
            />
          )}
          {currencyLabel}
          {isSelected && (
            <span className={styles.checked}>
              <TagIcon Icon={IconChecked} />
            </span>
          )}
        </div>
      </div>
      <Typography type={TType.Header13_500} htmlElement="figcaption">
        {label ? label : pattern.name}
      </Typography>
    </figure>
  )
}

export const PatternThumbXLMemoized = React.memo(PatternThumbXL)
