var _a, _b;
import BOX_CONSTS from "../generalConfig/consts/boxConsts";
var BOX_PRODUCTS_EDITOR_LIMITATIONS = {
    shared: {
        initialActiveSide: {
            activeSideId: undefined,
            editedSideId: undefined,
        },
        isAvailableForVisualizeApp: (_a = {},
            _a[BOX_CONSTS.MAILER_BOX] = true,
            _a[BOX_CONSTS.PRODUCT_BOX] = true,
            _a[BOX_CONSTS.SHIPPING_BOX_PLAIN] = true,
            _a[BOX_CONSTS.MAILER_BOX_PLAIN] = true,
            _a[BOX_CONSTS.SHIPPING_BOX] = true,
            _a[BOX_CONSTS.PRODUCT_BOX_WINE_BOX] = true,
            _a[BOX_CONSTS.PRODUCT_BOX_TWO_PIECES] = true,
            _a[BOX_CONSTS.SLEEVED_MAILER_BOX] = false,
            _a[BOX_CONSTS.DELIVERY_MAILER_BOX] = false,
            _a[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN] = false,
            _a),
        reforestationSupport: {
            badge: true,
            trees: true,
        },
    },
    products: (_b = {},
        _b[BOX_CONSTS.PRODUCT_BOX] = {
            extraFeatures: {
                colorInside: true,
                openingAnimation: true,
            },
            sideFeatures: {
                bottom: {
                    disableText: true,
                    disableImages: true,
                },
            },
        },
        _b[BOX_CONSTS.PRODUCT_BOX_WINE_BOX] = {
            extraFeatures: {
                colorInside: true,
                openingAnimation: true,
            },
            sideFeatures: {
                bottom: {
                    disableText: true,
                    disableImages: true,
                },
            },
        },
        _b[BOX_CONSTS.PRODUCT_BOX_TWO_PIECES] = {
            is3dEditorDisabled: true,
        },
        _b[BOX_CONSTS.SLEEVED_MAILER_BOX] = {
            sideFeatures: {
                left: {
                    disableSide: true,
                },
                right: {
                    disableSide: true,
                },
            },
        },
        _b[BOX_CONSTS.DELIVERY_MAILER_BOX] = {
            sideFeatures: {
                left: {
                    disableSide: true,
                },
                right: {
                    disableSide: true,
                },
                bottom: {
                    disableSide: true,
                },
                front: {
                    disableSide: true,
                },
                back: {
                    disableSide: true,
                },
            },
        },
        _b[BOX_CONSTS.MAILER_BOX_PLAIN] = {
            is3dEditorDisabled: true,
        },
        _b[BOX_CONSTS.DELIVERY_MAILER_BOX_PLAIN] = {
            is3dEditorDisabled: true,
        },
        _b[BOX_CONSTS.SHIPPING_BOX_PLAIN] = {
            is3dEditorDisabled: true,
        },
        _b[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_FULL_COLOUR] = {
            is3dEditorDisabled: true,
        },
        _b[BOX_CONSTS.PRE_PRINTED_MAILER_BOX_ECO] = {
            is3dEditorDisabled: true,
        },
        _b),
};
export default BOX_PRODUCTS_EDITOR_LIMITATIONS;
