import React from "react"

import { InnerSize, Modal } from "../Modal"
import { ModalTemplate } from "../partials/ModalTemplate"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"

import styles from "./DbyUploadModal.module.scss"
import { I18N } from "../../../../i18n"
import { useTranslate } from "../../../../hooks/useTranslate"

interface DbyUploadModalProps {
  onClose: () => void
  imageUrl?: string
}

export const DbyUploadModal = ({ onClose, imageUrl }: DbyUploadModalProps) => {
  const t = useTranslate()
  return (
    <Modal
      e2eTargetName="dby-upload-preview-modal"
      onClose={onClose}
      maxWidth={InnerSize.default}
    >
      <ModalTemplate
        header={
          <Typography
            type={TType.Header32_400}
            className={styles.dby_upload_modal__title}
          >
            {t(I18N.component.dbyUploader.preview)}
          </Typography>
        }
        footer={null}
      >
        <div>
          <img
            src={imageUrl}
            className={styles.dby_upload_modal__image}
            alt=""
          />
        </div>
      </ModalTemplate>
    </Modal>
  )
}
