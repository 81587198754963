import React from "react"
import { observer } from "mobx-react-lite"
import { CardTool } from "../../atoms/CardTool/CardTool"
import { Colour } from "../../../../libs/value-objects/colour"
import { useTranslate } from "../../../hooks/useTranslate"
import { I18N } from "../../../i18n"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import styles from "./EditorToolMaterialAndColors.module.scss"
import { ColorConfiguratorInline } from "../configurators/ColorConfigurator/ColorConfiguratorInline"
import { EditContext } from "../../../../libs/products-render-config/types"

const EditorToolMaterialAndColors = observer(() => {
  const t = useTranslate()
  const i18nMaterialAndColor = I18N.editorTools.materialAndColor

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.replicablePatterns,
    c.ecommerce,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, replicablePatterns } = containerSet

  if (!replicablePatterns.available) {
    return null
  }

  const { replicablePatternsStore, replicablePatternUiController } =
    replicablePatterns

  const { productDriver } = designAndProductDriver
  const { productRenderPilot, activeContext } = productDriver.state

  const backgroundColor =
    productDriver.backgroundsDriver.backgroundColor[activeContext]
  const patternColor = replicablePatternsStore.patternColor

  const onPatternColorSelect = (selectedColor: Colour) =>
    replicablePatternUiController.setPatternColor(selectedColor)
  const onBackgroundColorSelect = async (selectedColor: Colour) => {
    await productDriver.backgroundsDriver.paintProductEditContext(
      selectedColor,
      activeContext
    )
  }

  const isBackgroundColorAvailable =
    productRenderPilot.uiConfig.features.backgroundColor

  const parseColorForPalette = (color: Colour) => ({
    color: color.getHex(),
    uid: color.getHex(),
    pantoneName: color.getPantoneName(),
  })

  const backgroundPantoneColors =
    replicablePatternsStore.backgroundColors?.map(parseColorForPalette)
  const patternPantoneColors =
    replicablePatternsStore.patternColors?.map(parseColorForPalette)

  return (
    <CardTool cardName="materialAndColor">
      <div className={styles.content}>
        {activeContext === EditContext.FRONT && (
          <ColorConfiguratorInline
            itemName={t(i18nMaterialAndColor.patternColor)}
            header={I18N.components.editorToolText.label.defaultColours}
            buttonText={t(I18N.components.editorToolText.label.hideSwatch)}
            isOpenOnInit={true}
            onColorSelect={onPatternColorSelect}
            selectedColor={patternColor}
            isInline={true}
            onBackClick={() => {}}
            colorsList={patternPantoneColors}
          />
        )}

        {isBackgroundColorAvailable && (
          <ColorConfiguratorInline
            itemName={t(i18nMaterialAndColor.backgroundColor)}
            header={I18N.components.editorToolText.label.defaultColours}
            buttonText={t(I18N.components.editorToolText.label.hideSwatch)}
            isOpenOnInit={true}
            onColorSelect={onBackgroundColorSelect}
            selectedColor={backgroundColor}
            isInline={true}
            onBackClick={() => {}}
            colorsList={backgroundPantoneColors}
            pantoneColorsPreset={productRenderPilot.getBackgroundPantoneColorsPreset()}
          />
        )}
      </div>
    </CardTool>
  )
})

export { EditorToolMaterialAndColors }
