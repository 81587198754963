import {
  DirectusApi,
  DirectusShape,
  DirectusCategory,
} from "../modules/directus-api"
import {
  Shape,
  GroupedShapes,
  DEFAULT_COLOR_CONFIG,
  ecoQrShapeTitles,
} from "../libs/value-objects/shape"
import { QrCodeApi } from "../modules/ecommerce-api/rest-api/endpoints/qr-code-api"

const ECO_QR_CODE_LABEL = ["UNPACK", "MY ECO", "PROPERTIES"]
const ecoQrShapeLabelPosition = {
  [ecoQrShapeTitles.QR_CODE_VERTICAL]: "top",
  [ecoQrShapeTitles.QR_CODE_HORIZONTAL]: "right",
} as const

type GetEcoShapesOptions = {
  sku: string
}

export class ShapesManager {
  constructor(
    private readonly directusApi: DirectusApi,
    private readonly dynamicQrConfig?: {
      api: QrCodeApi
      url: string
    }
  ) {}

  public getTreeBadgesDownloadUrl(): string {
    return this.directusApi.ecoShape.getEcoShapesDownloadUrl({
      type: "planting_trees_badges",
    })
  }

  public getEcoShapesDownloadUrl(options: GetEcoShapesOptions): string {
    return this.directusApi.ecoShape.getEcoShapesDownloadUrl({
      ...options,
      type: ["symbols", "properties"],
    })
  }

  public async getEcoShapes(
    options: GetEcoShapesOptions
  ): Promise<GroupedShapes> {
    const ecoShapes = await this.directusApi.ecoShape.getEcoShapes(options)
    return this.groupEcoShapes(ecoShapes)
  }

  public async getShapes(): Promise<{
    categories: DirectusCategory[]
    groupedShapes: Record<string, Shape[]>
  }> {
    const shapes = await this.directusApi.shape.getShapes()
    return this.groupShapes(shapes)
  }

  public async generateEcoQrShape(
    shape: Shape,
    options?: {
      designId?: number
    }
  ): Promise<Shape> {
    if (!this.dynamicQrConfig) {
      return shape
    }

    let url = this.dynamicQrConfig.url

    if (options?.designId) {
      url = `${url}?designId=${options.designId}`
    }

    const qrCodeDto = await this.dynamicQrConfig.api.create({
      url: url,
      label_position: ecoQrShapeLabelPosition[shape.title],
      label_rows: ECO_QR_CODE_LABEL,
    })

    return new Shape({
      ...shape.toSource(),
      src: qrCodeDto.url,
    })
  }

  private groupEcoShapes(shapes: DirectusShape[]): GroupedShapes {
    const groupedShapes = {}

    shapes.forEach((shapeData) => {
      if (!groupedShapes[shapeData.type!]) {
        groupedShapes[shapeData.type!] = []
      }

      groupedShapes[shapeData.type!].push(this.createShape(shapeData))
    })

    return groupedShapes
  }

  private otherCategory: DirectusCategory = {
    name: "other",
    translationKey: "editor-assets.patterns.categories.other",
    position: 9999,
    id: 9999,
  }

  private groupShapes(shapes: DirectusShape[]): {
    categories: DirectusCategory[]
    groupedShapes: GroupedShapes
  } {
    const categories: DirectusCategory[] = []
    const groupedShapes: GroupedShapes = {}

    shapes.forEach((shapeData) => {
      if (shapeData.categories.length === 0) {
        this.pushShapeToCategory(
          groupedShapes,
          this.otherCategory,
          shapeData,
          categories
        )
        return
      }

      shapeData.categories.forEach((category) => {
        this.pushShapeToCategory(groupedShapes, category, shapeData, categories)
      })
    })

    categories.sort((a, b) => a.position - b.position)
    return { categories, groupedShapes }
  }

  private pushShapeToCategory(
    groupedShapes: GroupedShapes,
    category: DirectusCategory,
    shapeData: DirectusShape,
    categories: DirectusCategory[]
  ) {
    if (!groupedShapes[category.name]) {
      groupedShapes[category.name] = []
      categories.push(category)
    }

    groupedShapes[category.name].push(this.createShape(shapeData))
  }

  private createShape(shapeData: DirectusShape): Shape {
    return new Shape({
      id: String(shapeData.id),
      title: shapeData.name,
      src: shapeData.image.optimized.cdnUrl,
      colourConfig: DEFAULT_COLOR_CONFIG,
      keepRatio: shapeData.keepRatio,
      minHeightMm: shapeData.minHeightMm,
    })
  }
}
