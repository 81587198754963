import assets from "./assets/assets";
import dimensions from "./dimensions/dimensions";
import grammages from "./grammages/grammages";
import productionTimes from "./productionTimes/productionTimes";
import variants from "./variants/variants";
import weights from "./weights/weights";
import consts from "./consts/tapeConsts";
import quantities from "./quantities";
var TAPE_PRODUCTS_CONFIG = {
    ASSETS: assets,
    DIMENSIONS: dimensions,
    GRAMMAGES: grammages,
    PRODUCTION_TIMES: productionTimes,
    VARIANTS: variants,
    WEIGHTS: weights,
    VENDORS: {},
    CONSTS: consts,
    QUANTITIES: quantities,
};
export default TAPE_PRODUCTS_CONFIG;
