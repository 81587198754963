export default {
    AS55: {
        outside: {
            width: 300,
            length: 190,
            height: 75,
        },
        inside: {
            width: 290,
            length: 185,
            height: 70,
        },
    },
    AS73: {
        outside: {
            width: 390,
            length: 300,
            height: 75,
        },
        inside: {
            width: 370,
            length: 290,
            height: 70,
        },
    },
    AS80: {
        outside: {
            width: 390,
            length: 300,
            height: 145,
        },
        inside: {
            width: 370,
            length: 290,
            height: 140,
        },
    },
    AS91: {
        outside: {
            width: 433,
            length: 377,
            height: 122,
        },
        inside: {
            width: 420,
            length: 370,
            height: 120,
        },
    },
};
