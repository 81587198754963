import { Shape } from "../../../../../../libs/value-objects/shape"
import { PackhelpEditableShape } from "../../../object-extensions/packhelp-objects"
import FabricAssetsLoaderService from "../../../../../../libs/services/fabric-assets-loader-service"

export class ShapeHelper {
  public static async shapeToPackhelpObject(
    shape: Shape
  ): Promise<PackhelpEditableShape> {
    const object = (await FabricAssetsLoaderService.loadAssetSvg(shape.src, {
      id: shape.id,
      assetObjectMeta: shape.toSource(),
    })) as PackhelpEditableShape

    if (shape.keepRatio) {
      object.set({
        keepRatio: true,
        lockUniScaling: true,
      })
    }

    return object
  }
}
