import { observer } from "mobx-react-lite"
import React from "react"
import { TType } from "shared-libs/src/js/shared-components/i18n/TypographyWithTranslate"
import Typography from "dsl/src/atoms/Typography/Typography"
import { TagIcon } from "../../atoms/TagIcon/TagIcon"
import {
  TemplateDesignItem,
  TemplateType,
} from "../../../../modules/predefined-assets/template-asset"
import { ReactComponent as IconChecked } from "../../../assets/svg/check.svg"
import cxBinder from "classnames/bind"
import { FormattedCurrency } from "shared-libs/src/js/shared-components/formatted-currency/FormattedCurrency"
import { useCurrency } from "../../../hooks/useCurrency"
import { useTranslate } from "../../../hooks/useTranslate"

import styles from "./TemplateCard.module.scss"
import { I18N } from "../../../i18n"
import Badge, { BadgeColorTypes } from "dsl/src/atoms/Badge/Badge"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"

const cx = cxBinder.bind(styles)

type TemplateProps = {
  template: TemplateDesignItem
  isSelected: boolean
  onSelect: () => void
  inactive: boolean
}

const PriceBadge = ({
  price,
  currency,
}: {
  price: number
  currency: string
}) => {
  const t = useTranslate()
  const isFree = price === 0

  if (isFree) {
    return (
      <Badge badgeColor={BadgeColorTypes.Green} noMargin>
        {t(I18N.editorTools.templates.free)}
      </Badge>
    )
  }

  return (
    <Badge badgeColor={BadgeColorTypes.WhiteFour} noMargin>
      <FormattedCurrency value={price} currency={currency} />
    </Badge>
  )
}

const TypeBadge = ({ type }: { type: TemplateType }) => {
  const t = useTranslate()

  if (type === "double_sided") {
    return (
      <Badge badgeColor={BadgeColorTypes.Squash} noMargin>
        {t(I18N.editorTools.templates.doubleSided)}
      </Badge>
    )
  }

  return null
}

export const TemplateCard = observer(
  ({ template, isSelected, onSelect, inactive }: TemplateProps) => {
    const imageUrls = template.getAllImageUrls()
    const currency = useCurrency()
    const [ecommerceContainer] = useContainer().ecommerce

    if (!ecommerceContainer) {
      return null
    }
    const { taxStore } = ecommerceContainer
    const templatePrice = taxStore.getPrice(template).displayPrice

    return (
      <div
        className={cx("wrapper", {
          inactive: inactive && !isSelected,
          selected: isSelected,
        })}
        onClick={!isSelected ? onSelect : () => {}}
      >
        <div className={styles.header}>
          <div>
            <Typography type={TType.Header13_500}>{template.name}</Typography>
            <div className={styles.badges}>
              <PriceBadge price={templatePrice} currency={currency} />
              <TypeBadge type={template.type} />
            </div>
          </div>

          <span className={styles.selection}>
            {isSelected ? (
              <TagIcon Icon={IconChecked} />
            ) : (
              <span className={styles.unchecked}></span>
            )}
          </span>
        </div>

        <div className={styles.carousel}>
          {imageUrls.map((imageUrl, index) => {
            return (
              <div className={styles.thumb} key={index}>
                <img
                  src={imageUrl}
                  className={styles.image}
                  loading="lazy"
                  alt=""
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
)
