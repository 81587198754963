import envelopeProductSpec from "../../../../source/envelopeProductSpec/envelopeProductSpec";
import ConfigBuilder from "../../configBuilders/ConfigBuilder";
import ProductConsts from "../../../../definitions/products/ProductConsts";
// Global product behaviors composers
import ProductionTimeManager from "../../behaviorsComposers/ProductionTimeManager";
import GrammagesManager from "../../behaviorsComposers/GrammagesManager";
import AssetsManager from "../../behaviorsComposers/AssetsManager";
import WeightManager from "../../behaviorsComposers/WeightManager";
import WizzardManager from "../../behaviorsComposers/WizzardManager";
import SiblingsManager from "../../behaviorsComposers/SiblingsManager";
import QuantitiesManager from "../../behaviorsComposers/QuantitiesManager";
// Envelope specific behaviors composers
import EnvelopeTranslationsManager from "./behaviorsComposers/EnvelopeTranslationsManager";
import EnvelopeDimensionsManager from "./behaviorsComposers/EnvelopeDimensionsManager";
import EnvelopeRenderConfigManager from "./behaviorsComposers/EnvelopeRenderConfigManager";
import EnvelopeVariantManager from "./behaviorsComposers/EnvelopeVariantManager";
import EnvelopeAddonsManager from "./behaviorsComposers/EnvelopeAddonsManager";
import EnvelopeEditConfigManager from "./behaviorsComposers/EnvelopeEditConfigManager";
import ProductFactory from "../../ProductFactory";
var s3Path = "https://packhelp-files.s3-eu-west-1.amazonaws.com/app/editor/assets/envelopes";
var EnvelopeProduct = /** @class */ (function () {
    function EnvelopeProduct(sku, productName, productVariant) {
        this.config = this.builder(envelopeProductSpec, sku, productName, productVariant);
        this.assetsManager = new AssetsManager(this);
        this.variantManager = new EnvelopeVariantManager(this);
        this.grammagesManager = new GrammagesManager(this);
        this.productionTimeManager = new ProductionTimeManager(this);
        this.weightsManager = new WeightManager(this);
        this.wizzardManager = new WizzardManager(this);
        this.siblingsManager = new SiblingsManager(this);
        this.dimensionsManager = new EnvelopeDimensionsManager(this);
        this.editConfigManager = new EnvelopeEditConfigManager(this);
        this.translationsManager = new EnvelopeTranslationsManager(this);
        this.addonsManager = new EnvelopeAddonsManager(this);
        this.renderConfigManager = new EnvelopeRenderConfigManager(this);
        this.quantitiesManager = new QuantitiesManager(this);
        this.metaData = {
            hasOpenClosedState: true,
        };
    }
    EnvelopeProduct.prototype.builder = function (spec, sku, productName, productVariant) {
        return ConfigBuilder(spec, sku, productName, productVariant);
    };
    EnvelopeProduct.prototype.getProductExtraSizesLabels = function () {
        return null;
    };
    EnvelopeProduct.prototype.getExtraTechnicalDetails = function () {
        return [
            {
                titleId: "box-configurator.box-dimensions.material-color",
                iconSvg: "v5-material-color",
                descId: "box-configurator.box-dimensions.envelope-materials",
            },
            {
                titleId: "box-configurator.box-dimensions.closing",
                iconSvg: "v5-closing-ico",
                descId: "box-configurator.box-dimensions.adhesive-tape",
            },
        ];
    };
    EnvelopeProduct.prototype.getEditorBackgroundPath = function (side, isClosed) {
        var productCodeName = this.variantManager.getCodeName();
        var material = this.variantManager.getMaterial();
        var name = "/envelope_".concat(productCodeName, "_").concat(material, "_").concat(side, "_open.png");
        if (isClosed) {
            name = "/envelope_".concat(productCodeName, "_").concat(material, "_").concat(side, "_closed.png");
        }
        return ("https://packhelp-files.s3.amazonaws.com/app/editor/backgrounds/envelopes_test" +
            name);
    };
    EnvelopeProduct.prototype.getEditorGlueImage = function () {
        if (this.addonsManager.material ===
            ProductConsts["EnvelopeProductConsts"].PRINT_MATERIAL_KRAFT) {
            if (this.addonsManager.productSize ===
                ProductConsts["EnvelopeProductConsts"].sizeC5) {
                return s3Path + "/C5_kraft.png";
            }
            if (this.addonsManager.productSize ===
                ProductConsts["EnvelopeProductConsts"].sizeC4) {
                return s3Path + "/C4_kraft.png";
            }
        }
        if (this.addonsManager.material ===
            ProductConsts["EnvelopeProductConsts"].PRINT_MATERIAL_CARDBOARD_CARDSTOCK) {
            if (this.addonsManager.productSize ===
                ProductConsts["EnvelopeProductConsts"].sizeC5) {
                return s3Path + "/C5_white.png";
            }
            if (this.addonsManager.productSize ===
                ProductConsts["EnvelopeProductConsts"].sizeC4) {
                return s3Path + "/C4_white.png";
            }
        }
    };
    EnvelopeProduct.prototype.getGlueStripObjectDetails = function () {
        if (this.addonsManager.productSize ===
            ProductConsts["EnvelopeProductConsts"].sizeC5) {
            return {
                width: 425,
                height: 30,
                left: 175,
                top: 235,
            };
        }
        if (this.addonsManager.productSize ===
            ProductConsts["EnvelopeProductConsts"].sizeC4) {
            return {
                width: 485,
                height: 30,
                left: 146,
                top: 184,
            };
        }
    };
    EnvelopeProduct.prototype.createBaseProductSku = function () {
        return this.addonsManager.setDefaultAddons();
    };
    EnvelopeProduct.prototype.getBaseProduct = function () {
        return ProductFactory(this.createBaseProductSku());
    };
    return EnvelopeProduct;
}());
export default EnvelopeProduct;
