export default {
    front: {
        w: "boxX",
        h: "boxY",
    },
    back: {
        w: "boxX",
        h: "boxY",
    },
    right: {
        w: "boxZ",
        h: "boxY",
    },
    left: {
        w: "boxZ",
        h: "boxY",
    },
    top: {
        w: "boxX",
        h: "boxZ",
    },
    bottom: {
        w: "boxX",
        h: "boxZ",
    },
    foldLine: {
        h: "boxZ",
    },
    sideFold: {
        h: "boxY",
    },
    x: "width",
    z: "length",
    y: "height",
    availableSides: {
        front: "front",
        top: "top",
        back: "back",
        left: "left",
        right: "right",
        bottom: "bottom",
    },
};
