import { Sentry } from "../../services/sentry-service"
import Basil from "shared-libs/src/js/libs/storage/basil.js"
import RegionConfig from "../../../../configs/region-config/interfaces/js/region-config"
import { isServerProduction } from "../../../../configs/region-config/interfaces/js/env-config"
import _mapKeys from "lodash/mapKeys"

interface GtagData {
  session_id: string
  session_number: number
  client_id: string
}

interface GtagInitialConfig {
  session_id?: string
  session_number?: number
  client_id?: string
  debug_mode?: boolean
}

function isString(val: unknown): val is string {
  return typeof val === "string"
}

function isNumber(val: unknown): val is number {
  return typeof val === "number"
}

function wait(ms: number): Promise<undefined> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
async function backoff(
  maxRetries: number,
  delay: number,
  totalDelay,
  fn: (r: number, d: number) => Promise<any>
): Promise<any> {
  let currentRetry = 0
  return new Promise((resolve, reject) => {
    const retryOperation = async (): Promise<any> => {
      const currentDelay = Math.round(delay * Math.pow(1.4, currentRetry))
      try {
        await wait(currentDelay)
        return await fn(currentRetry, currentDelay)
      } catch (error) {
        currentRetry++
        if (currentRetry > maxRetries) {
          throw new Error("Max retries reached")
        }
        return retryOperation()
      }
    }

    retryOperation().then(resolve).catch(reject)
  })
}

function isSSR(): boolean {
  return (
    // @ts-ignore
    !!__IS_SSR__ ||
    typeof window === "undefined" ||
    (typeof navigator === "object" && navigator.userAgent.includes("jsdom"))
  )
}

export class GtagNotAvailableError extends Error {}
export class GtagDataNotReadyError extends Error {}

export class Ga4Service {
  private static isGtagDisabled = false
  private static gtag?: Gtag.Gtag

  public static getGtagId(): string {
    const gtagIds = RegionConfig.getSharedSettings().gtagId

    if (isServerProduction()) {
      return gtagIds.production
    }

    return gtagIds.nonProduction
  }

  /**
   * To set cookie run in console:
   *
   * Basil.cookie.set('ga4_debug_mode_enabled', 1, { expireDays: 1, path: "/" })
   *
   * or
   *
   * var d = new Date()
   * d.setTime(d.getTime() + 60 * 60 * 1000) // Set cookie to expire in 1 hour
   * document.cookie = "ga4_debug_mode_enabled=1; expires=" + d.toUTCString() + "; path=/"
   */
  public static shouldEnableDebugMode(): boolean {
    const debugMode = Basil.cookie.get("ga4_debug_mode_enabled")

    if (debugMode == null) return false
    if (debugMode == 0) return false
    if (debugMode == "false") return false

    return true
  }

  public static async decorateHeadersWithGtagData(
    headers: Object = {}
  ): Promise<Object> {
    const gtagData = await Ga4Service.getGtagData()

    if (gtagData) {
      headers["GA_CLIENT_ID"] = "GA1.2." + gtagData.client_id
    }

    return headers
  }

  public static async decorateObjectWithGtagData(object: Object, prefix = "") {
    const gtagData = await Ga4Service.getGtagData()

    if (gtagData) {
      const prefixedGtagData = _mapKeys(gtagData, function (_value, key) {
        if (!prefix) {
          return key
        }

        return `${prefix}_${key}`
      })

      Object.assign(object, prefixedGtagData)
    }

    if (this.shouldEnableDebugMode()) {
      Object.assign(object, {
        debug_mode: 1,
      })
    }
  }

  public static async addGtagDataToUrl(url: URL) {
    const gtagData = await Ga4Service.getGtagData()

    if (gtagData) {
      for (const [key, value] of Object.entries(gtagData)) {
        url.searchParams.set(key, value)
      }
    }
  }

  public static getCrossDomainsList(): string[] {
    const currentDomain = window.location.host
    const possibleDomains = RegionConfig.getAutolinkerDomains()

    return possibleDomains.filter((domain) => domain !== currentDomain)
  }

  public static getGtagInitialConfig(): GtagInitialConfig {
    const urlParams = new URLSearchParams(window.location.search)

    const config: GtagInitialConfig = {}

    for (const paramName of ["client_id", "session_id", "session_number"]) {
      const paramValue = urlParams.get(paramName)

      if (paramValue) {
        config[paramName] = paramValue
      }
    }

    // Sending  { debug_mode: false } fill still enable debug mode :D
    if (this.shouldEnableDebugMode()) {
      config.debug_mode = true
    }

    return config
  }

  private static async getGtagDataLowLevel(
    gtagId: string
  ): Promise<GtagData | undefined> {
    const gtag = await Ga4Service.getGtagWhenReadyWithTimeout()

    if (!gtag) {
      return
    }

    const [sessionId, sessionNumber, clientId] = await Promise.all([
      Promise.race([
        wait(1000),
        new Promise((res) => gtag("get", gtagId, "session_id", res)),
      ]),
      Promise.race([
        wait(1000),
        new Promise((res) => gtag("get", gtagId, "session_number", res)),
      ]),
      Promise.race([
        wait(1000),
        new Promise((res) => gtag("get", gtagId, "client_id", res)),
      ]),
    ])
    let gtagData: GtagData | undefined = undefined

    if (isString(sessionId) && isNumber(sessionNumber) && isString(clientId)) {
      gtagData = {
        session_id: sessionId,
        session_number: sessionNumber,
        client_id: clientId,
      }
    }

    if (!gtagData) {
      console.error(
        "Session data is corrupted, but might recover",
        sessionId,
        sessionNumber,
        clientId
      )
      throw new Error("Session data is corrupted")
    }
    return gtagData
  }

  public static async getGtagDataWithBackoff(
    retries = 30,
    initialDelay = 20,
    totalDelay = 10 * 1000
  ): Promise<GtagData | undefined> {
    const gtag = await Ga4Service.getGtagWhenReadyWithTimeout()

    if (!gtag) {
      return
    }

    const gtagId = this.getGtagId()
    const gtagData = await backoff(
      retries,
      initialDelay,
      totalDelay,
      (r, d) => {
        return Ga4Service.getGtagDataLowLevel(gtagId)
      }
    )

    return gtagData
  }

  public static async getGtagData(): Promise<GtagData | undefined> {
    /**
     * WARNING: Do not store gtag data in here (in RAM)
     *
     * This might lead to unstable session.
     *
     * If some event launches before we initialize gtag.
     * We check if gtag is in window every 50ms. So if there is a race condition
     * and someone sends and event before that, we will get another session_id when
     * we do init gtag.
     * No to this code
     * if (this.gtagData) {
     *   return this.gtagData
     *  }
     */
    //

    const gtag = await Ga4Service.getGtagWhenReadyWithTimeout()

    if (!gtag) {
      return
    }

    let gtagData: GtagData | undefined = undefined

    try {
      // 30 retries, 10ms initial delay, 10s total delay
      gtagData = await Ga4Service.getGtagDataWithBackoff()
    } catch (e) {
      console.error("We tried to get data with backoff but failed")
      console.error(e)
    }

    if (!gtagData) {
      Sentry.captureException(
        new GtagDataNotReadyError("Gtag data is not ready or is not valid"),
        true
      )
    }

    return gtagData
  }

  public static async getGtagWhenReadyWithTimeout(
    ms: number = 10 * 1000 // wait 10 seconds
  ): Promise<Gtag.Gtag | undefined> {
    if (this.gtag) {
      return this.gtag
    }

    if (this.isGtagDisabled || isSSR()) {
      return
    }

    this.gtag = await Promise.race([
      wait(ms),
      this.getGtagWhenReady().catch((_e) => undefined),
    ])

    if (!this.gtag) {
      this.isGtagDisabled = true

      console.error("Gtag is not available")
      Sentry.captureException(
        new GtagNotAvailableError(
          "Gtag is not available (probably an Adblock)"
        ),
        true
      )
    }

    return this.gtag
  }

  private static async getGtagWhenReady(): Promise<Gtag.Gtag> {
    if (this.gtag) {
      return this.gtag
    }

    return new Promise((resolve, reject) => {
      if (typeof window === "undefined") {
        return reject()
      }

      if (window.gtag && typeof window.gtag === "function") {
        return resolve(window.gtag)
      }

      setTimeout(() => {
        this.getGtagWhenReady().then(resolve).catch(reject)
      }, 71) // primary number
    })
  }

  public static async trackEvent(name: string, params: Object = {}) {
    const gtag = await Ga4Service.getGtagWhenReadyWithTimeout()

    if (!gtag) {
      return
    }

    gtag("event", name, params)
  }
  /**
   * Caution: If you send manual pageviews without disabling pageview measurement, you may end up with duplicate pageviews.
   * https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag
   */
  public static async trackPageView(url: string, title: string | undefined) {
    const gtag = await Ga4Service.getGtagWhenReadyWithTimeout()

    if (!gtag) {
      return
    }

    gtag("event", "page_view", {
      page_location: url,
      page_title: title,
    })
  }
}

;(function init() {
  let initialized = false

  return async function () {
    if (initialized) {
      return
    }

    initialized = true

    const gtag = await Ga4Service.getGtagWhenReadyWithTimeout()

    if (!gtag) {
      return
    }

    // must run some config setup before gtag("js") call
    // https://developers.google.com/tag-platform/gtagjs/install
    const config = Ga4Service.getGtagInitialConfig()
    const gtagId = Ga4Service.getGtagId()

    // Avoid next.js and tests
    if (typeof gtagId !== "string") return

    // linker goes before anything (!)
    // https://developers.google.com/tag-platform/devguides/cross-domain#set_up_cross-domain_linking
    gtag("set", "linker", { domains: Ga4Service.getCrossDomainsList() })
    gtag("js", new Date())

    if (Object.keys(config).length > 0) {
      gtag("config", gtagId, config)
    } else {
      gtag("config", gtagId)
    }

    if (Ga4Service.shouldEnableDebugMode()) {
      // DEBUG START
      console.log("Data from query params", config)

      gtag("get", gtagId, "client_id", function (client_id) {
        console.log("client_id", client_id)
      })

      gtag("get", gtagId, "session_id", function (session_id) {
        console.log("session_id", session_id)
      })

      gtag("get", gtagId, "session_number", function (session_number) {
        console.log("session_number", session_number)
      })
      // DEBUG END
    }
  }
})()()

// Remove this block after Sep 2024. This is a debugger code to test session ids
// interface localDB {
//   sessions: string[]
// }

// function getLocalDB(): localDB {
//   const db_raw = window.localStorage.getItem("_z_ph_debug_session_id")
//   if (!db_raw) {
//     window.localStorage.setItem(
//       "_z_ph_debug_session_id",
//       JSON.stringify({ sessions: [] })
//     )
//     return { sessions: [] }
//   } else {
//     const db: localDB = JSON.parse(db_raw) as any
//     return db
//   }
// }
// async function getSessionId(): Promise<string> {
//   return new Promise((resolve, reject) => {
//     // next is stupid, need to disable for builds
//     // @ts-ignore
//     gtag("get", gtagId, "session_id", function (session_id) {
//       if (typeof session_id !== "string") {
//         console.error("MISSING SESSION ID", session_id)
//         reject("MISSING SESSION ID")
//       }
//       resolve(session_id as any)
//     })
//   })
// }
// function save(db) {
//   window.localStorage.setItem("_z_ph_debug_session_id", JSON.stringify(db))
// }
// async function printSessionIdOnStartAndIfChanged() {
//   // Part 2: Normal flow
//   const db: localDB = getLocalDB()
//   // @ts-ignore
//   window._zSessionDb = db
//   const sessionId = await getSessionId()

//   // A Flow. Simply record first session
//   if (db.sessions.length == 0) {
//     console.log("FIRST SESSION ID", sessionId)
//     db.sessions.push(sessionId)
//     save(db)
//     return
//   }

//   // B Flow: nothing changed
//   if (db.sessions.includes(sessionId)) {
//     // console.log("nothing changed", sessionId)
//     return
//   }

//   // C Flow: new session detected
//   db.sessions.push(sessionId)
//   save(db)

//   // Alert world about new session
//   console.log(db)
//   console.error("NEW SESSION ID", sessionId)
//   Sentry.captureException("GA4 new Session detected: " + sessionId)
// }
// run  session with exponential backoff
// function runWithExponentialBackoff(backoff = 10) {
//   printSessionIdOnStartAndIfChanged()
//   const newBackoff = Math.round((backoff + 10) * 1.1)
//   setTimeout(() => {
//     runWithExponentialBackoff(newBackoff)
//   }, newBackoff)
// }
// runWithExponentialBackoff()
