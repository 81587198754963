var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
import PRODUCTION_TIMES_CONSTS from "../../../productSharedConsts/productionTimeConsts";
import TAPE_CONSTS from "../consts/tapeConsts";
var DEFAULT = PRODUCTION_TIMES_CONSTS.DEFAULT, EXPRESS = PRODUCTION_TIMES_CONSTS.EXPRESS, SUPEREXPRESS = PRODUCTION_TIMES_CONSTS.SUPEREXPRESS, PRODUCTION_TIME = PRODUCTION_TIMES_CONSTS.PRODUCTION_TIME, DTP_DEADLINE = PRODUCTION_TIMES_CONSTS.DTP_DEADLINE, HIGH_QUANTITY = PRODUCTION_TIMES_CONSTS.HIGH_QUANTITY;
var TAPE_PRODUCTION_TIME = (_a = {},
    _a[TAPE_CONSTS.TAPE_MODEL_PACKING_TAPE] = (_b = {},
        _b[TAPE_CONSTS.TAPE_VARIANT_PACKING_TAPE] = (_c = {},
            _c[PRODUCTION_TIME] = (_d = {},
                _d[DEFAULT] = 18,
                _d[EXPRESS] = 18,
                _d[SUPEREXPRESS] = 18,
                _d[HIGH_QUANTITY] = (_e = {},
                    _e[5184] = { max: 10 },
                    _e),
                _d),
            _c[DTP_DEADLINE] = (_f = {},
                _f[DEFAULT] = 1,
                _f),
            _c),
        _b),
    _a[TAPE_CONSTS.TAPE_MODEL_KRAFT_TAPE] = (_g = {},
        _g[TAPE_CONSTS.TAPE_VARIANT_KRAFT_TAPE] = (_h = {},
            _h[PRODUCTION_TIME] = (_j = {},
                _j[DEFAULT] = 18,
                _j[EXPRESS] = 18,
                _j[SUPEREXPRESS] = 18,
                _j[HIGH_QUANTITY] = (_k = {},
                    _k[2592] = { max: 10 },
                    _k),
                _j),
            _h[DTP_DEADLINE] = (_l = {},
                _l[DEFAULT] = 1,
                _l),
            _h),
        _g),
    _a[TAPE_CONSTS.TAPE_MODEL_PRE_PRINTED_KRAFT_TAPE] = (_m = {},
        _m[TAPE_CONSTS.PRE_PRINTED_KRAFT_TAPE_VARIANT] = (_o = {},
            _o[PRODUCTION_TIME] = (_p = {},
                _p[DEFAULT] = 5,
                _p),
            _o[DTP_DEADLINE] = (_q = {},
                _q[DEFAULT] = 1,
                _q),
            _o),
        _m),
    _a);
export default TAPE_PRODUCTION_TIME;
