import {
  PackhelpMaskObject,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import fabric from "../../../../../../libs/vendors/Fabric"
import { DEFAULT_INITIAL_MARGIN, MaskConfig, MaskSize, MaskType } from "./types"
import { getS3Config } from "../../../../../../services/env-defaults"
import Colour from "../../../../../../libs/value-objects/colour"
import {
  Shape,
  ShapeConfigParams,
} from "../../../../../../libs/value-objects/shape"

const s3 = getS3Config().editorAssets

export const getDefaultColorByMaskType = (maskType: MaskType): Colour => {
  if (maskType === MaskType.covering) {
    return new Colour("#A6ABB5")
  }

  return new Colour()
}

export const getDefaultMaskShape = () => {
  return new Shape(getDefaultMaskAssetObjectMeta())
}

export const getDefaultMaskAssetObjectMeta = (): ShapeConfigParams => {
  return {
    title: "Rectangle",
    id: "rectangle",
    src: `${s3}/shapes/mask-shapes/rectangle.svg`,
    colourConfig: {
      colourParam: "fill",
      colour: {
        hex: "#000",
      },
    },
  }
}

export const getDefaultMaskConfig = (
  maskParent: PackhelpObject
): MaskConfig => {
  const shape = new fabric.Rect({ width: 44, height: 20 }) as PackhelpMaskObject
  shape.assetObjectMeta = getDefaultMaskAssetObjectMeta()

  return {
    shape: shape,
    color: getDefaultColorByMaskType(MaskType.clipping),
    size: getDefaultMaskSize(maskParent),
    assetClipping: false,
  }
}

export const getDefaultMaskSize = (
  maskParent: PackhelpObject,
  initialMargin = DEFAULT_INITIAL_MARGIN
): MaskSize => {
  return {
    width: maskParent.getScaledWidth() + initialMargin,
    height: maskParent.getScaledHeight() + initialMargin,
  }
}
