import { computed, action, observable, makeObservable } from "mobx"
import { Product } from "@ph/product-api"
import { ProductPricingStore } from "./pricing-store/product-pricing.store"
import { ProductStore } from "./product-driver/product.store"
import { ProductDesignStore } from "./product-design-store/product-design.store"
import { AppConfig } from "../app-config/app.config"
import { QuoteRequestFormUrlProvider } from "../services/quote-request-form-url.provider"

export class EcommerceStore {
  @observable public isDynamicVariantLoading = false
  @observable public dynamicVariantError?: string

  @observable private dynamicVariantSku?: string

  constructor(
    private readonly appConfig: AppConfig,
    private readonly productStore: ProductStore,
    private readonly productPricingStore: ProductPricingStore,
    private readonly productDesignStore: ProductDesignStore
  ) {
    makeObservable(this)
  }

  public setSelectedQuantity(quantity: number): void {
    this.productDesignStore.setProductQuantity(quantity)
  }

  public get estimationId(): number | undefined {
    const { productSku, customization } = this.productStore
    const unitPrice = this.productPricingStore.getUnitPrice(
      this.selectedQuantity,
      productSku,
      !!customization
    )

    if (unitPrice.type !== "estimation") {
      return
    }

    return unitPrice.id
  }

  @computed
  public get selectedQuantity(): number {
    return this.productDesignStore.state.meta.quantity
  }

  @computed
  public get ecommerceSku(): string {
    return this.dynamicVariantSku || this.productStore.productSku
  }

  @action
  public setDynamicVariantSku(sku?: string): void {
    this.dynamicVariantSku = sku
  }

  @action
  public setIsDynamicVariantLoading(isLoading: boolean): void {
    this.isDynamicVariantLoading = isLoading
  }

  @action
  public setDynamicVariantError(error?: string): void {
    this.dynamicVariantError = error
  }

  @computed
  public get isCartFlowAvailable(): boolean {
    if (this.productPricingStore.isLoadingPricing) {
      return true
    }

    if (this.isApiCustomersMode) {
      return true
    }

    const { value, type } = this.productPricingStore.totalPrice

    return !!value && type === "price"
  }

  @computed
  public get isQrFlowAvailable(): boolean {
    if (!this.isCartFlowAvailable) {
      return true
    }

    const { qrConfig } = this.product.getDefaultVariant()

    if (!qrConfig) {
      return false
    }

    return (
      this.selectedQuantity >= qrConfig.min &&
      this.selectedQuantity <= qrConfig.max
    )
  }

  @computed
  public get isDesignLaterAvailable(): boolean {
    return (
      this.isCartFlowAvailable &&
      !this.productDesignStore.state.isDraftDesignInCart &&
      this.appConfig.api.ecommerce.features.ecommerce.designLater
    )
  }

  @computed
  public get isQuantityPickerVisible(): boolean {
    return this.appConfig.api.ecommerce.features.ecommerce.quantityPicker
  }

  @computed
  public get isApiCustomersMode(): boolean {
    return this.appConfig.api.ecommerce.features.ecommerce.apiCustomersMode
  }

  public get quoteRequestFormUrl(): string | undefined {
    return new QuoteRequestFormUrlProvider(this.appConfig, this.product).call({
      quantity: this.selectedQuantity,
      designId: this.productDesignStore.state.meta.id,
      estimationId: this.estimationId,
    })
  }

  public get productPageUrl(): string | undefined {
    return this.appConfig.locale.getUrl(
      "products",
      this.product.variantManager.getVariantDashed()
    )
  }

  private get product(): Product {
    return this.productStore.product
  }
}
