var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import ProductFactory from "../../../ProductFactory";
import { buildSku } from "../../../SkuLib";
import _flatten from "lodash/flatten";
import BOX_CONSTS from "../../../../../source/boxProductSpec/generalConfig/consts/boxConsts";
var addOriginVariantBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.product = product;
            _this.skuData = product.config.skuData;
            _this.variant = product.config.variant;
            _this.const = product.config.const;
            return _this;
        }
        class_1.prototype.getDielineUrl = function (type, region) {
            var sku = region ? this.buildSku({ region: region }) : this.getSku();
            return "https://dielines.packhelp.com/sku/".concat(sku, ".").concat(type);
        };
        class_1.prototype.getPiecesPerUnit = function () {
            return 1;
        };
        class_1.prototype.isFscCertificateAvailable = function () {
            var variantSpec = this.getVariantSpec();
            /**
             * FSC is temporarily disabled for UK.
             * Please also check validations on backend side: creator/app/services/zpkj/value_added_services/
             */
            var productRegion = this.skuData.decodedSKU.productRegion;
            var isRegionUK = productRegion === "gb";
            return !!variantSpec.fscCertificate && !isRegionUK;
        };
        class_1.prototype.hasNewPrice = function () {
            var variantSpec = this.getVariantSpec();
            return !!variantSpec.newPrice;
        };
        class_1.prototype.getSkuData = function () {
            return this.skuData;
        };
        class_1.prototype.getSize = function () {
            return this.skuData.decodedSKU.productSize;
        };
        class_1.prototype.getName = function () {
            return this.skuData.decodedSKU.productName;
        };
        class_1.prototype.getType = function () {
            return this.const.PRODUCT_TYPE;
        };
        class_1.prototype.getExtraSizesArr = function () {
            var variantSpec = this.getVariantSpec();
            return variantSpec.extraSizes ? variantSpec.extraSizes : [];
        };
        class_1.prototype.getDefaultSizesArr = function () {
            return this.variant.sizes;
        };
        class_1.prototype.getModelConfig = function () {
            return this.variant;
        };
        class_1.prototype.getModelVariants = function () {
            return this.variant.variants;
        };
        class_1.prototype.getDecodedSku = function () {
            return this.skuData.decodedSKU;
        };
        class_1.prototype.getSku = function () {
            return this.skuData.sku;
        };
        class_1.prototype.getMaterial = function () {
            return this.skuData.decodedSKU.productMaterial;
        };
        class_1.prototype.getSleeveMaterial = function () {
            var name = this.getName();
            if (name === BOX_CONSTS.SLEEVED_MAILER_BOX) {
                return this.getFinish();
            }
            return null;
        };
        class_1.prototype.getColorMode = function () {
            return this.skuData.decodedSKU.productColorMode;
        };
        class_1.prototype.getFinish = function () {
            return this.skuData.decodedSKU.productFinish;
        };
        class_1.prototype.getCodeName = function () {
            return this.getPrefix() + this.getSize();
        };
        class_1.prototype.getPrefix = function () {
            return this.variant.prefix;
        };
        class_1.prototype.getDefaultSize = function () {
            var defaultSize = this.getModelConfig().defaultSize;
            return defaultSize || this.getDefaultSizesArr()[0];
        };
        class_1.prototype.getAllVariantsBaseSku = function () {
            var _this = this;
            var variants = this.getModelConfig().variants;
            return _flatten(Object.keys(variants).map(function (variant) {
                var currentVariant = variants[variant];
                var region = _this.skuData.decodedSKU.productRegion;
                var skus = [];
                for (var _i = 0, _a = currentVariant.materials; _i < _a.length; _i++) {
                    var material = _a[_i];
                    for (var _b = 0, _c = currentVariant.colorModes; _b < _c.length; _b++) {
                        var colorMode = _c[_b];
                        for (var _d = 0, _e = currentVariant.finishes; _d < _e.length; _d++) {
                            var finish = _e[_d];
                            var product = ProductFactory(_this.buildSku({
                                region: region,
                                material: material,
                                colorMode: colorMode,
                                finish: finish,
                            }));
                            skus.push(product.variantManager.buildSku({
                                size: product.variantManager.getDefaultSize(),
                            }));
                        }
                    }
                }
                return skus;
            }));
        };
        class_1.prototype.getAvailableSizesForProduct = function () {
            var _this = this;
            var extraSizes = this.getExtraSizesArr();
            var availableSizes = this.getDefaultSizesArr();
            if (extraSizes) {
                availableSizes = availableSizes.concat(extraSizes).sort(function (a, b) {
                    return a - b;
                });
            }
            return availableSizes.map(function (size) {
                var newProductSku = _this.product.addonsManager.setProductSize(size);
                var product = ProductFactory(newProductSku);
                // @ts-ignore
                var images = product.assetsManager
                    ? // @ts-ignore
                        product.assetsManager.getDimensionsImages()
                    : null;
                if (!product.dimensionsManager && !product.variantManager) {
                    return {};
                }
                else {
                    var labelObject = {
                        productVariantCodeName: product.variantManager.getCodeName(),
                        productVariantSize: product.variantManager.getSize(),
                        // @ts-ignore
                        productVariantWeight: product.weightsManager.getWeight(),
                        productVariantDimensions: product.dimensionsManager.getOutsideSizeDimensionFormatted(),
                        productVariantDimensionsInside: product.dimensionsManager.getInsideSizeDimensionFormatted(),
                        productVariantDimensionsImages: images,
                        productVariantOutsideDimensions: product.dimensionsManager.getOutsideDimensions(),
                        productVariantSleeveDimensions: product.dimensionsManager.getSleeveDimensions(),
                        sku: newProductSku,
                    };
                    if (
                    //@ts-ignore
                    product.getProductExtraSizesLabels &&
                        //@ts-ignore
                        product.getProductExtraSizesLabels(size)) {
                        // @ts-ignore
                        labelObject["productVariantExtraLabel"
                        //@ts-ignore
                        ] = product.getProductExtraSizesLabels(size);
                    }
                    return labelObject;
                }
            });
        };
        class_1.prototype.buildSku = function (_a) {
            var material = _a.material, colorMode = _a.colorMode, finish = _a.finish, region = _a.region, name = _a.name, size = _a.size;
            var _b = this.skuData.decodedSKU, productRegion = _b.productRegion, productMaterial = _b.productMaterial, productFinish = _b.productFinish, productColorMode = _b.productColorMode;
            var skuParams = {
                productRegion: region || productRegion,
                productName: name || this.getName(),
                productSize: size || this.getSize(),
                productMaterial: material || productMaterial,
                productColorMode: colorMode || productColorMode,
                productFinish: finish || productFinish,
            };
            return buildSku(skuParams);
        };
        class_1.prototype.getVariantSpec = function () {
            var productVariant = this.getVariant();
            return this.product.config.variant.variants[productVariant];
        };
        class_1.prototype.getEditorRelatedProducts = function () {
            return [];
        };
        class_1.prototype.getPssRepresentation = function () {
            return {
                meta: {},
                attributes: {},
            };
        };
        class_1.prototype.getProductId = function () {
            return this.getVariant();
        };
        class_1.prototype.mapToEditorRelatedProduct = function (product) {
            return {
                id: product.variantManager.getProductId(),
                title: product.translationsManager.getModelVariantNameIntl(),
                currentVariant: {
                    sku: product.variantManager.getSku(),
                    piecesPerUnit: product.variantManager.getPiecesPerUnit(),
                },
                imageUrl: product.assetsManager.getVariantEditorPreview(),
                variants: product.variantManager.getAllVariantsBaseSku().map(function (sku) { return ({
                    sku: sku,
                }); }),
            };
        };
        class_1.prototype.getStatus = function () {
            return "published";
        };
        class_1.prototype.isDynamic = function () {
            return false;
        };
        return class_1;
    }(superclass));
};
export default addOriginVariantBehaviors;
