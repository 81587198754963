import {
  PaperBagProductVariant,
  ShippingBoxVariant,
} from "@ph/product-config/dist/definitions/products/ProductVariant"

// Experiment: Pricing experiments - display DMS Discount promotion on selected products
// Remove once the experiment is completed

export const shouldDisplayFixedDmsPromo = (
  productVariant: string,
  region: string
): boolean => {
  const isSweden = region === "se"
  const isItaly = region === "it"

  const isToteBag = productVariant === PaperBagProductVariant.TOTE_BAG_VARIANT
  const isShippingBox =
    productVariant === ShippingBoxVariant.SHIPPING_BOX_DEFAULT_VARIANT

  return (isShippingBox || isToteBag) && !isSweden && !isItaly
}
