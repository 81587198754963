var _a;
import ENVELOPE_CONSTS from "../generalConfig/consts/envelopeConsts";
var ENVELOPE_RENDER_CONFIG = (_a = {},
    _a[ENVELOPE_CONSTS.ENVELOPE_MODEL_DEFAULT] = {
        C4: {
            width: 324,
            height: 274,
            length: 10,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 324,
                        height: 274,
                        foldline: {
                            top: 0,
                            left: 0,
                            width: 324,
                            height: 45,
                        },
                        shape: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="diecut" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 918.4 782.3" style="enable-background:new 0 0 918.4 782.3;" xml:space="preserve"><polygon points="915.6,56.7 885.7,56.7 861.1,0 57.2,0 32.7,56.7 2.8,56.7 2.8,56.7 2.8,90.7 16.3,90.7 0,133.2 0,782.3 918.4,782.3 918.4,133.2 902,90.7 915.6,90.7 915.6,90.7 "/></svg>',
                    },
                    back: {
                        top: 45,
                        left: 0,
                        width: 324,
                        height: 229,
                        shape: '<?xml version="1.0" encoding="utf-8"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 918.4 649.1"><g id="diecut"><polygon class="cls-1" points="0 0 0 649.1 918.4 649.1 918.4 0 0 0"/></g></svg>',
                    },
                },
            },
        },
        C5: {
            width: 229,
            height: 207,
            length: 10,
            foldLineWidth: 0,
            sideFoldWidth: 0,
            thumbnailCameraZoom: 1,
            editZonePosition: {
                side: {
                    front: {
                        top: 0,
                        left: 0,
                        width: 229,
                        height: 207,
                        foldline: {
                            top: 0,
                            left: 0,
                            width: 229,
                            height: 45,
                        },
                        shape: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 651.1 594.4" xml:space="preserve"><polygon points="650.1,593.4 1,593.4 1,134.2 17.3,91.7 3.8,91.7 3.8,57.7 33.7,57.7 58.2,1 592.8,1 617.4,57.7 647.3,57.7 647.3,91.7 633.7,91.7 650.1,134.2 650.1,593.4 1,593.4 "/></svg>',
                    },
                    back: {
                        top: 45,
                        left: 0,
                        width: 229,
                        height: 162,
                        shape: '<?xml version="1.0" encoding="utf-8"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 918.4 649.1"><g id="diecut"><polygon  points="0 0 0 649.1 918.4 649.1 918.4 0 0 0"/></g></svg>',
                    },
                },
            },
        },
    },
    _a);
export default ENVELOPE_RENDER_CONFIG;
