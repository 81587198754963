var _a, _b, _c, _d;
import LABEL_CONSTS from "../consts/labelConsts";
var LABEL_PRODUCT_VARIANTS = (_a = {},
    _a[LABEL_CONSTS.SQUARE_LABEL] = {
        sizes: [5, 10, 13, 15, 20],
        prefix: "SS",
        addonsInVariants: [],
        productCategory: LABEL_CONSTS.SQUARE_LABEL,
        variants: (_b = {},
            _b[LABEL_CONSTS.SQUARE_LABEL_VARIANT] = {
                materials: [LABEL_CONSTS.PRINT_MATERIAL_PAPER_MID_GLOSS],
                colorModes: [LABEL_CONSTS.PRINT_MODE_INDIGO_COLOR],
                finishes: [LABEL_CONSTS.PRINT_FINISH_NONE],
            },
            _b),
    },
    _a[LABEL_CONSTS.CIRCLE_LABEL] = {
        sizes: [2, 4, 5, 6, 7, 10],
        prefix: "SC",
        addonsInVariants: [],
        productCategory: LABEL_CONSTS.CIRCLE_LABEL,
        variants: (_c = {},
            _c[LABEL_CONSTS.CIRCLE_LABEL_VARIANT] = {
                materials: [LABEL_CONSTS.PRINT_MATERIAL_PAPER_MID_GLOSS],
                colorModes: [LABEL_CONSTS.PRINT_MODE_INDIGO_COLOR],
                finishes: [LABEL_CONSTS.PRINT_FINISH_NONE],
            },
            _c),
    },
    _a[LABEL_CONSTS.RECTANGLE_LABEL] = {
        sizes: [
            "7",
            "10i",
            "10ii",
            "11",
            "12",
            "12i",
            "12ii",
            "12iii",
            "13",
            "13i",
            "14",
            "14i",
            "14ii",
            "15",
            "15i",
            "15ii",
            "15iii",
            "16",
            "16i",
            "16ii",
            "17",
            "17i",
            "17ii",
            "18",
            "18i",
            "18ii",
            "18iii",
            "19",
            "19i",
            "19ii",
            "19iii",
            "20",
            "20i",
            "20ii",
            "20iii",
            "22",
            "22i",
            "23",
            "23i",
            "23ii",
            "24",
            "24i",
            "25",
            "25i",
            "26",
            "27",
            "27i",
            "29",
            "29i",
            "29ii",
            "29iii",
            "30",
            "31",
            "33",
            "34",
            "37",
            "39",
            "44",
        ],
        prefix: "SR",
        addonsInVariants: [],
        productCategory: LABEL_CONSTS.RECTANGLE_LABEL,
        variants: (_d = {},
            _d[LABEL_CONSTS.RECTANGLE_LABEL_VARIANT] = {
                materials: [LABEL_CONSTS.PRINT_MATERIAL_PAPER_MID_GLOSS],
                colorModes: [LABEL_CONSTS.PRINT_MODE_INDIGO_COLOR],
                finishes: [LABEL_CONSTS.PRINT_FINISH_NONE],
            },
            _d),
    },
    _a);
export default LABEL_PRODUCT_VARIANTS;
