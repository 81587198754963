var _a, _b, _c, _d;
import TAPE_CONSTS from "../consts/tapeConsts";
var TAPE_PRODUCT_VARIANTS = (_a = {},
    _a[TAPE_CONSTS.TAPE_MODEL_PACKING_TAPE] = {
        sizes: [48],
        prefix: "X",
        addonsInVariants: [],
        productCategory: TAPE_CONSTS.PACKING_TAPE,
        variants: (_b = {},
            _b[TAPE_CONSTS.TAPE_VARIANT_PACKING_TAPE] = {
                materials: [
                    TAPE_CONSTS.PRINT_MATERIAL_FOIL_WHITE,
                    TAPE_CONSTS.PRINT_MATERIAL_FOIL_TRANSPARENT,
                ],
                colorModes: [TAPE_CONSTS.PRINT_MODE_FLEXO_COLOR],
                finishes: [TAPE_CONSTS.PRINT_FINISH_NONE],
            },
            _b),
    },
    _a[TAPE_CONSTS.TAPE_MODEL_KRAFT_TAPE] = {
        sizes: [50],
        prefix: "X",
        addonsInVariants: [],
        productCategory: TAPE_CONSTS.KRAFT_TAPE,
        variants: (_c = {},
            _c[TAPE_CONSTS.TAPE_VARIANT_KRAFT_TAPE] = {
                materials: [TAPE_CONSTS.PRINT_MATERIAL_PAPER_KRAFT],
                colorModes: [TAPE_CONSTS.PRINT_MODE_FLEXO_COLOR],
                finishes: [TAPE_CONSTS.PRINT_FINISH_NONE],
            },
            _c),
    },
    _a[TAPE_CONSTS.TAPE_MODEL_PRE_PRINTED_KRAFT_TAPE] = {
        sizes: [50],
        prefix: "X",
        addonsInVariants: [],
        productCategory: TAPE_CONSTS.PRE_PRINTED_KRAFT_TAPE,
        variants: (_d = {},
            _d[TAPE_CONSTS.PRE_PRINTED_KRAFT_TAPE_VARIANT] = {
                materials: [TAPE_CONSTS.PRINT_MATERIAL_KRAFT],
                colorModes: [TAPE_CONSTS.PRINT_MODE_WHITE_PRINT],
                finishes: [
                    TAPE_CONSTS.PRINT_FINISH_FRAGILE,
                    TAPE_CONSTS.PRINT_FINISH_PATTERN,
                ],
            },
            _d),
    },
    _a);
export default TAPE_PRODUCT_VARIANTS;
