var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var addPreferedColorsBehaviors = function (superclass) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(product) {
            var _this = _super.call(this, product) || this;
            _this.preferredColors =
                product.config.userInterfaceConfig.productPreferredColors;
            return _this;
        }
        class_1.prototype.getPreferredColors = function () {
            return this.preferredColors.COLORS_LISTS.GENERIC;
        };
        class_1.prototype.getEditorDefaultColor = function () {
            var product = this.product;
            var material = product.variantManager.getMaterial();
            var variant = product.variantManager.getVariant();
            var editorConfig = this.preferredColors.COLORS_CONFIG.SHARED.EDITOR_DEFAULT_COLOR;
            if (editorConfig[material]) {
                return editorConfig[material];
            }
            else if (editorConfig[variant]) {
                return editorConfig[variant];
            }
            else {
                return editorConfig.DEFAULT;
            }
        };
        return class_1;
    }(superclass));
};
export default addPreferedColorsBehaviors;
