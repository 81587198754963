import axios, { AxiosInstance } from "axios"
import { PredefinedText } from "../../modules/predefined-assets/text-asset"
import {
  PredefinedTextDto,
  ColorScheme,
} from "../../modules/predefined-assets/asset-types"
import { StrapiApiEnvConfig } from "../../app-config/types"
import { Product } from "@ph/product-api"

export class StrapiPredefinedTextAssetsProvider {
  private api: AxiosInstance

  constructor(apiConfig: StrapiApiEnvConfig) {
    this.api = axios.create({
      baseURL: apiConfig.baseUrl,
    })
  }

  public async getTextAssets(
    colorScheme: ColorScheme,
    product?: Product
  ): Promise<PredefinedText[]> {
    const response = await this.api.get<PredefinedTextDto[]>(
      "/predefined-texts"
    )
    const assets = response.data

    return this.createAssetsFrom(
      this.filterByProduct(
        this.filterByColorScheme(assets, colorScheme),
        product
      )
    )
  }

  private filterByProduct(assets: PredefinedTextDto[], product?: Product) {
    const includedTags = ["product-all"]

    if (!!product) {
      includedTags.push(`product-${product.variantManager.getVariantDashed()}`)
    }

    return assets.filter((asset) => {
      const tags = asset.tags.map((tag) => tag.tag)
      return tags.find((tag) => includedTags.includes(tag))
    })
  }

  private filterByColorScheme(
    assets: PredefinedTextDto[],
    colourScheme: ColorScheme
  ) {
    return assets.filter((asset) => {
      const tags = asset.tags.map((tag) => tag.tag)
      return tags.includes(colourScheme)
    })
  }

  private createAssetsFrom(assets: PredefinedTextDto[]): PredefinedText[] {
    return assets
      .filter((asset) => this.isAssetValid(asset))
      .map((asset) => new PredefinedText(asset))
  }

  private isAssetValid(asset: PredefinedTextDto): boolean {
    if (asset.assetFile == null || typeof asset.assetFile.url !== "string") {
      console.error(
        `The asset (ID: ${asset.id}) does not have a valid assetFile.`
      )
      return false
    }

    return true
  }
}
