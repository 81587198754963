import _flow from "lodash/flow";
import RenderConfigManager from "../../../behaviorsComposers/RenderConfigManger";
import getGeneralNonBoxRenderBehaviors from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/getGeneralNonBoxRenderBehaviors";
import addGetOffsetForSideBehavior from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/getOffsetForSide";
import addGetProductEditZonesBehaviors from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/getProductEditZones";
import addClipObjectBehaviors from "../../../behaviors/nonBoxProductsBehaviors/renderConfigBehaviors/addClipObjectBehaviors";
import addTapesRenderConfigBehaviors from "../../../behaviors/tapeBehaviors/renderConfigBehaviors/TapeRenderConfigBehaviors";
export default _flow([
    addGetOffsetForSideBehavior,
    addGetProductEditZonesBehaviors,
    getGeneralNonBoxRenderBehaviors,
    addClipObjectBehaviors,
    addTapesRenderConfigBehaviors,
])(RenderConfigManager);
