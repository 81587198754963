var _a, _b;
import PAPER_TUBE_CONSTS from "../generalConfig/consts/paperTubeConsts";
var ProductPrederedColors = {
    COLORS_CONFIG: (_a = {
            SHARED: {
                EDITOR_DEFAULT_COLOR: {
                    DEFAULT: {
                        color: "#222223",
                        pantoneName: "Black C",
                    },
                },
            }
        },
        _a[PAPER_TUBE_CONSTS.PAPER_CAN] = {
            VARIANTS: (_b = {},
                _b[PAPER_TUBE_CONSTS.PAPER_CAN_PRODUCT_VARIANT_STANDARD] = {
                    PANTONE_COLORS: true,
                    LIMITED_COLORS: false,
                    LIMITED_COLORS_DESCRIPTION: true,
                },
                _b),
        },
        _a),
    COLORS_LISTS: [],
};
export default ProductPrederedColors;
