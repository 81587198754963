/**
 * As click outside toolbar implementation is bad,
 * we need to check if click outside event is allowed to be fired.
 *
 * TODO: In the long run, we need to rewrite click outside toolbar feature.
 */
export function isClickOutsideToolbarAllowed(
  e: MouseEvent,
  toolbarRef: React.RefObject<HTMLDivElement>
) {
  const target = e.target as HTMLElement
  const isColorPickerClicked = !!target.closest("#color-picker")
  const isModalClicked = !!target.closest("[class^='Modal']")
  const isFontSelectClicked = !!target.closest(
    "#editor-tool-text-font-family-select ~ div"
  )
  const isTooltipClicked = !!target.closest("[data-type='tooltip']")
  const isToolbarClicked = !!toolbarRef?.current?.contains(e.target as Element)

  return (
    !isColorPickerClicked &&
    !isModalClicked &&
    !isFontSelectClicked &&
    !isTooltipClicked &&
    !isToolbarClicked
  )
}
