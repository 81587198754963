import { GetContainerFormat } from "iti"
import { AssetsStore } from "../stores/assets-store/assets.store"
import { ee } from "../stores/editor-events"
import { ProductDesignStore } from "../stores/product-design-store/product-design.store"
import ProductDriver from "../stores/product-driver/product.driver"
import type { ApiSessionContainer } from "./api-session-container"
import type { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap-container"
import { ObjectClipboardStore } from "../stores/product-driver/object-clipboard.store"
import { ProductDesignSaveController } from "../stores/_controllers/product-design/product-design-save.controller"
import { ProductDesignUiController } from "../stores/_controllers/product-design/product-design-ui.controller"
import type { UtilEnvContainer } from "./util-container"
import { ContextController } from "../stores/_controllers/context-controller"
import { ChangeProductController } from "../stores/_controllers/change-product-controller/change-product.controller"
import { DbyModeContainer } from "./dby-mode-container"
import { LoadProductDesignController } from "../stores/_controllers/product-design/load-product-design.controller"
import { getFormatMessage } from "shared-libs/src/js/libs/others/i18n"
import { I18N } from "../ui/i18n"
import Basil from "basil.js"
import { ProductConfigurationUiController } from "../stores/_controllers/product-configuration-ui.controller"
import { EnablePrintingOptionModalController } from "../stores/_controllers/modal/enable-printing-option-modal-controller"
import { RenderEngineBuilder } from "../render-engine/services/render-engine-builder"
import EventEmitter from "eventemitter3"
import { DielineGenerator } from "../render-engine/modules/dieline-manager/dieline.generator"
import { DielineStore } from "../stores/product-design-store/dieline.store"
import { ModelConfigProvider } from "../render-engine/modules/3d-renderer/model-config.provider"

export async function provideDesignAndProductDriverContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  rootBootstrapper: BootstrapClassicRootStoreContainer,
  dbyContainer: DbyModeContainer
) {
  const { uri, appConfig } = utilEnvContainer
  const { designApi, ecommerceApi, pqsApi } = apiSessionContainer
  const { productRenderPilot, productStore } = rootBootstrapper
  const editorMode = productRenderPilot.getEditorMode()
  const { dbyModeStore } = dbyContainer

  const assetStore = new AssetsStore({
    logoApi: appConfig.api.ecommerce.features.user.logo
      ? ecommerceApi.logo
      : undefined,
    ee,
    config: {
      softDelete: editorMode !== "designer",
    },
  })

  const formatMessage = await getFormatMessage()
  const defaultDesignName = formatMessage({
    id: I18N.component.navbar.defaultDesignName,
  })

  const productDesignStore = new ProductDesignStore(
    productStore,
    assetStore,
    ee,
    defaultDesignName
  )

  const dielineGenerator = new DielineGenerator(pqsApi.dielines)

  const dielineStore = new DielineStore(
    dielineGenerator,
    appConfig.locale.productRegion
  )
  await dielineStore.loadDielineUrls(productRenderPilot)

  const modelConfigProvider = new ModelConfigProvider(dielineStore)

  const eventEmitter = new EventEmitter()
  const renderEngineBuilder = new RenderEngineBuilder(
    dielineStore,
    modelConfigProvider,
    eventEmitter
  )
  const productDriver = new ProductDriver(
    renderEngineBuilder,
    productRenderPilot,
    productStore,
    productDesignStore,
    dielineStore,
    eventEmitter,
    ee
  )

  /**
   * TODO:
   *
   * We can move ProductDesignSaveController & ProductDesignUiController to EcommerceContainer in the future
   * as there is no need to init them in the designer mode (and then we can remove editorMode checkers from ProductDesignSaveController).
   *
   * Anyway, we can skip it for now because EcommerceContainer is always initialized (it will be changed later, step by step ;)).
   */
  const productDesignSaveController = new ProductDesignSaveController(
    productDriver,
    productDesignStore,
    dbyModeStore,
    designApi,
    ecommerceApi,
    ee,
    editorMode,
    !Basil.cookie.get("editor_no_auto_save")
  )

  const productDesignUiController = new ProductDesignUiController(
    productDriver,
    productDesignStore,
    productDesignSaveController,
    designApi,
    ecommerceApi,
    uri
  )

  const contextController = new ContextController(productDriver, ee)

  const changeProductController = new ChangeProductController(
    editorMode,
    {
      productDriver,
      ee,
    },
    {
      productDesignStore,
      dielineStore,
      dbyModeStore,
    }
  )

  new ObjectClipboardStore(productDriver, ee)

  const loadProductDesignController = new LoadProductDesignController(
    designApi,
    productDesignStore,
    dbyModeStore,
    assetStore,
    productRenderPilot.getEditorMode()
  )

  const productConfigurationUiController = new ProductConfigurationUiController(
    productDriver
  )
  const enablePrintingOptionModalController =
    new EnablePrintingOptionModalController(
      productDriver,
      productConfigurationUiController
    )

  return {
    assetStore,
    productDesignStore,
    productDriver,
    productDesignUiController,
    contextController,
    changeProductController,
    loadProductDesignController,
    productConfigurationUiController,
    enablePrintingOptionModalController,
    ee,
    dielineStore,
  }
}

export type DesignAndProductDriverContainer = GetContainerFormat<
  typeof provideDesignAndProductDriverContainer
>
