import {
  IndexConfigFragments,
  PackhelpEditableObject,
} from "../../object-extensions/packhelp-objects"
import { ModelEditableSpaces } from "../../../../../libs/products-render-config/types"
import { SpaceBoundingRect } from "../dieline-navigator/services/translation-calculator"
import { DielineNavigator } from "../dieline-navigator/dieline-navigator"
import { RotationHelper } from "../assets-module/helpers/rotation-helper"

export class HighlightBackgroundShapeCreator {
  constructor(private readonly dielineNavigator: DielineNavigator) {}

  private async cloneVdSpace(
    spaceId: ModelEditableSpaces
  ): Promise<PackhelpEditableObject> {
    const vdSpace = this.dielineNavigator.getVirtualDielineSpace(spaceId)

    return new Promise((resolve) => {
      vdSpace.clone(
        (clonedVdSpace) => {
          resolve(clonedVdSpace)
        },
        ["id", "rotation", "originSpaceArea"]
      )
    })
  }

  private getVdSpaceBoundingRect(
    spaceId: ModelEditableSpaces
  ): SpaceBoundingRect {
    return this.dielineNavigator.getSpaceBoundingRect(spaceId)
  }

  public async create(
    spaceId: ModelEditableSpaces,
    index: number
  ): Promise<PackhelpEditableObject> {
    const highlightBgShape = await this.cloneVdSpace(spaceId)
    const vdSpaceBoundingRect = this.getVdSpaceBoundingRect(spaceId)

    highlightBgShape.set({
      evented: false,
      strokeWidth: 0,
      opacity: 0,
      selectable: false,
      visible: true,
      left: vdSpaceBoundingRect.left,
      top: vdSpaceBoundingRect.top,
      width: vdSpaceBoundingRect.width,
      height: vdSpaceBoundingRect.height,
      id: `temp_background_${spaceId}`,
      indexConfig: {
        fragment: IndexConfigFragments.TOP,
        index: index,
      },
      originSpaceArea: spaceId,
    })

    RotationHelper.rotateObjectAroundCanvasCenter(
      highlightBgShape,
      this.dielineNavigator.getCurrentRotation()
    )

    return highlightBgShape
  }
}
