var _a, _b, _c;
import CARD_CONSTS from "../consts/cardConsts";
var DEFAULT = "default";
var CARD_PRODUCTS_WEIGHTS = (_a = {},
    _a[CARD_CONSTS.PRINTED_CARD] = (_b = {},
        _b[CARD_CONSTS.PRINTED_CARD_VARIANT] = (_c = {},
            _c[DEFAULT] = {
                A6: 0.005,
                A7: 0.003,
                DL: 0.006,
            },
            _c),
        _b),
    _a);
export default CARD_PRODUCTS_WEIGHTS;
