import {
  Pattern,
  PatternSourceObject,
} from "../../../../../../libs/value-objects/pattern"
import { PatternGenerator } from "../../../../../../libs/services/patterns-generator/patterns-generator"
import FabricAssetsLoaderService from "../../../../../../libs/services/fabric-assets-loader-service"
import { TempLayers } from "../../../types/render-engine-types"
import {
  IndexConfigFragmentBottom,
  IndexConfigFragments,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { ClipPathModes } from "../../../services/clip-path-generator"
import { SpaceClippingHelper } from "../../assets-module/helpers/space-clipping-helper"
import { ModelEditableSpaces } from "../../../../../../libs/products-render-config/types"

export type PatternIterations = {
  iterationsInHeight: number
  iterationsInWidth: number
}

export type ObjectPosition = {
  left: number
  top: number
}

export type ObjectBoundingRect = ObjectPosition & {
  width: number
  height: number
}

export class CreateTempPatternService {
  private readonly patternGenerator: PatternGenerator

  constructor(
    private readonly vdEditor: VirtualDielineEditor,
    patternSourceConfig: PatternSourceObject,
    private readonly patternSizePx: number
  ) {
    this.patternGenerator = new PatternGenerator(
      new Pattern(patternSourceConfig)
    )
  }

  public async generate(
    space: PackhelpObject,
    zoomRatio: number
  ): Promise<PackhelpObject> {
    const patternFabricSvg = await this.patternGenerator.loadPattern()
    patternFabricSvg.scaleToWidth(this.patternSizePx)
    const patternSourceWidth = patternFabricSvg.getScaledWidth()

    if (!patternSourceWidth) {
      throw new Error("Source pattern svg wrong format")
    }

    const scaledPatternSourceWidth = patternSourceWidth * zoomRatio
    const spaceBoundingRect = {
      top: space.top!,
      left: space.left!,
      width: space.width!,
      height: space.height!,
    }

    const patternImage: PackhelpObject = await this.createTempPattern(
      this.calculatePatternIterations(
        spaceBoundingRect,
        scaledPatternSourceWidth,
        zoomRatio
      ),
      scaledPatternSourceWidth,
      zoomRatio
    )

    if (typeof patternImage.width === "undefined") {
      throw new Error("Temp pattern is not created")
    }

    await SpaceClippingHelper.setSpaceClipping(
      this.vdEditor,
      space.originSpaceArea!,
      patternImage,
      ClipPathModes.FillMode
    )

    const patternPosition = this.calculatePatternPosition(
      space.originSpaceArea as ModelEditableSpaces,
      spaceBoundingRect,
      scaledPatternSourceWidth,
      zoomRatio
    )

    patternImage.scaleToWidth(patternImage.width / zoomRatio)

    patternImage.set({
      strokeWidth: 0,
      left: patternPosition.left / zoomRatio,
      top: patternPosition.top / zoomRatio,
      selectable: false,
      evented: false,
      id: TempLayers.TEMP_PATTERN,
      indexConfig: {
        fragment: IndexConfigFragments.BOTTOM,
        index: IndexConfigFragmentBottom.TEMP_PATTERN,
      },
    })

    return patternImage
  }

  private async createTempPattern(
    { iterationsInWidth, iterationsInHeight }: PatternIterations,
    patternRectSourceWidth: number,
    zoomRatio: number
  ): Promise<PackhelpObject> {
    const { patternContainer } = await this.patternGenerator.process({
      patternSizePx: this.patternSizePx,
      scaleFactor: zoomRatio,
      containerSize: {
        width: Math.round(iterationsInWidth * patternRectSourceWidth),
        height: Math.round(iterationsInHeight * patternRectSourceWidth),
      },
    })

    patternContainer.set({
      strokeWidth: 0,
    })

    return await FabricAssetsLoaderService.loadAsset(
      patternContainer.toDataURL({})
    )
  }

  private calculatePatternPosition(
    spaceId: ModelEditableSpaces,
    spaceBoundingRect: ObjectBoundingRect,
    patternRectSourceWidth: number,
    zoomRatio: number
  ): ObjectPosition {
    const calculatePosition = (spacePosition: number): number => {
      /**
       * Pattern is positioned from Point(0, 0), so we need to set 0 as minimal value.
       */
      const position = Math.max(0, spacePosition * zoomRatio)

      // Products with one space per context
      if (spaceId === ModelEditableSpaces.DEFAULT) {
        return position
      }

      return (
        Math.floor(position / patternRectSourceWidth) * patternRectSourceWidth
      )
    }

    return {
      left: calculatePosition(spaceBoundingRect.left),
      top: calculatePosition(spaceBoundingRect.top),
    }
  }

  private calculatePatternIterations(
    spaceBoundingRect: ObjectBoundingRect,
    patternRectSourceWidth: number,
    zoomRatio: number
  ): PatternIterations {
    const objectStartTop = spaceBoundingRect.top * zoomRatio
    const objectHeight = spaceBoundingRect.height * zoomRatio
    const objectStartLeft = spaceBoundingRect.left * zoomRatio
    const objectWidth = spaceBoundingRect.width * zoomRatio

    let modifierHeight = 0
    let modifierWidth = 0

    if (objectStartTop + objectHeight > patternRectSourceWidth) {
      modifierHeight = 1
    }

    if (objectStartLeft + objectWidth > patternRectSourceWidth) {
      modifierWidth = 1
    }

    const iterationsInHeight =
      modifierHeight + Math.ceil(objectHeight / patternRectSourceWidth)
    const iterationInWidth =
      modifierWidth + Math.ceil(objectWidth / patternRectSourceWidth)

    return {
      iterationsInWidth: iterationInWidth,
      iterationsInHeight: iterationsInHeight,
    }
  }
}
