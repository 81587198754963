import THREE from "editor/src/libs/vendors/THREE"
import { Debug } from "../../services/logger"

const debug = Debug("ph:editor:services:three-texture-loader")

class ThreeTextureLoaderService {
  static loadTexture(url: string): Promise<THREE.Texture | undefined> {
    const textureLoader = new THREE.TextureLoader()

    const load = (preparedUrl: string): Promise<THREE.Texture> => {
      return new Promise<THREE.Texture>((resolve, reject) => {
        textureLoader.load(
          preparedUrl,
          (texture) => {
            resolve(texture)
          },
          undefined,
          reject
        )
      })
    }

    return load(url).catch(() => {
      /**
       * Cloudflare caches imgix responses that return 424 error code.
       * In case of error we need to retry the request with a new cache buster.
       */
      const preparedUrl = new URL(url)
      preparedUrl.searchParams.append("cb", String(Date.now()))

      return load(preparedUrl.toString()).catch(() => {
        debug("Error loading texture", { url })

        window.Sentry?.captureException(new Error("Can't load texture"))

        return undefined
      })
    })
  }

  static loadCubeTexture(
    paths: string[]
  ): Promise<THREE.CubeTexture | undefined> {
    const cubeTextureLoader = new THREE.CubeTextureLoader()

    return new Promise<THREE.CubeTexture>((resolve, reject) => {
      cubeTextureLoader.load(
        paths,
        (texture) => {
          resolve(texture)
        },
        undefined,
        (_error) => {
          debug("Error loading paths", { paths })

          window.Sentry?.captureException(new Error("Can't load texture paths"))

          reject()
        }
      )
    })
  }
}

export default ThreeTextureLoaderService
