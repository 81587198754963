import React from "react"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { useTranslate } from "../../../../hooks/useTranslate"
import { ReactComponent as ChangeTemplatesIcon } from "../../../../assets/svg/shape-icons/change.svg"
import { ActionConfirmModal } from "../action-confirm-modal/action-confirm-modal"

export type ChangeTemplateModalMode =
  | "applyTemplate"
  | "changeTemplate"
  | "removeTemplate"

const getCopy = (mode: ChangeTemplateModalMode) => {
  switch (mode) {
    case "applyTemplate":
      return {
        areYouSure: "modals.change-template.are-you-sure.apply",
        changesLost: "modals.change-template.design-changes-lost",
        elementsRemoved: "modals.change-template.elements-removed",
        confirm: "modals.change-template.confirm.apply",
      }
    case "changeTemplate":
      return {
        areYouSure: "modals.change-template.are-you-sure.change",
        changesLost: "modals.change-template.template-changes-lost",
        elementsRemoved: "modals.change-template.elements-removed",
        confirm: "modals.change-template.confirm.change",
      }
    case "removeTemplate":
      return {
        areYouSure: "modals.change-template.are-you-sure.remove",
        changesLost: "modals.change-template.template-changes-lost",
        elementsRemoved: "modals.change-template.elements-removed",
        confirm: "modals.change-template.confirm.remove",
      }
  }
}

export interface ChangeTemplateModalProps {
  onClose: () => void
  onConfirm: () => void
  mode: ChangeTemplateModalMode
}

export const ChangeTemplateModal = ({
  onClose,
  onConfirm,
  mode,
}: ChangeTemplateModalProps) => {
  const t = useTranslate()

  const copy = getCopy(mode)

  return (
    <ActionConfirmModal
      onClose={onClose}
      onConfirm={onConfirm}
      title={t(copy.areYouSure)}
      confirmText={t(copy.confirm)}
      Icon={ChangeTemplatesIcon}
    >
      <Typography type={TType.Body15_350}>{t(copy.changesLost)}</Typography>
      <br />
      <Typography type={TType.Body15_350}>{t(copy.elementsRemoved)}</Typography>
    </ActionConfirmModal>
  )
}
