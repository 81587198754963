import React from "react"

import { FormattedHTMLMessage, FormattedMessage } from "react-intl"

import ModalTemplateConfirm from "../partials/ModalTemplateConfirm"
import { ButtonAsLink } from "dsl/src/atoms/Button/Button"

import styles from "./Guidelines.module.scss"
import { ProductRenderPilot } from "../../../../../libs/products-render-config/product-render-pilot"
import { useTranslate } from "../../../../hooks/useTranslate"
import {
  EditContext,
  ModelEditableSpaces,
  SpaceDimensions,
} from "../../../../../libs/products-render-config/types"
import { useContainer } from "../../../../../_containers-react/_editor-app-hooks"
import { Dimensions } from "../../../atoms/dimensions/dimensions"

export interface GuidelinesProps {
  onClose: () => void
  productRenderPilot: ProductRenderPilot
}

interface ItemProps {
  id: string
  name: string
  dimensions: SpaceDimensions
  unit: string
}

const EXCLUDED_EDIT_CONTEXTS = [EditContext.INSIDE]
const EXCLUDED_EDIT_SPACES = [ModelEditableSpaces.FRONT_INSIDE]

const Item = ({ name, dimensions, unit }: ItemProps) => {
  return (
    <li className={styles.list__item}>
      <div className={styles.dimensions}>
        <h4 className={styles.dimensions__side}>{name}</h4>
        <p className={styles.dimensions__size}>
          <Dimensions
            dimensionsCm={[dimensions.widthCm, dimensions.heightCm]}
            displayUnit={unit}
          />{" "}
          ({dimensions.widthPx} x {dimensions.heightPx} px)
        </p>
      </div>
    </li>
  )
}

const Guidelines = ({ onClose, productRenderPilot }: GuidelinesProps) => {
  const t = useTranslate()
  const [envUtilContainer] = useContainer().envUtil

  if (!envUtilContainer) {
    return null
  }

  const { appConfig } = envUtilContainer
  const items: ItemProps[] = []

  const editContexts = productRenderPilot
    .getAvailableEditContexts()
    .filter((editContext) => !EXCLUDED_EDIT_CONTEXTS.includes(editContext))

  for (const editContext of editContexts) {
    const spaces = productRenderPilot
      .getAvailableSpaces(editContext)
      .filter((editSpace) => !EXCLUDED_EDIT_SPACES.includes(editSpace))

    for (const space of spaces) {
      const nameKey =
        space === ModelEditableSpaces.DEFAULT ? editContext : space

      items.push({
        id: `${editContext}-${space}`,
        name: t(`sides.${nameKey}`),
        dimensions: productRenderPilot.getSpaceDimensions(editContext, space),
        unit: appConfig.locale.displayUnit,
      })
    }
  }

  const designGuidelinesHelp = appConfig.locale.getUrl("designGuidelines")

  return (
    <ModalTemplateConfirm
      onClose={onClose}
      title={
        <FormattedHTMLMessage id="modals.guidelines.section-titles.modal" />
      }
      buttonComponent={
        <ButtonAsLink
          e2eTargetName="help"
          href={designGuidelinesHelp ? designGuidelinesHelp : ""}
          target="_blank"
        >
          <FormattedMessage id="modals.guidelines.help-button" />
        </ButtonAsLink>
      }
      withCancel
    >
      <h3 className={styles.heading}>
        <FormattedHTMLMessage id="modals.guidelines.section-titles.sizes" />
      </h3>
      <p className={styles.desc}>
        <FormattedHTMLMessage id="modals.guidelines.img-instructions" />
      </p>
      <div className={styles.box}>
        <div className={styles.box__inner}>
          <ul className={styles.list}>
            {items.map(({ id, name, dimensions, unit }) => (
              <Item
                id={id}
                name={name}
                dimensions={dimensions}
                key={id}
                unit={unit}
              />
            ))}
          </ul>
        </div>
      </div>
      <h3 className={styles.heading}>
        <FormattedHTMLMessage id="modals.guidelines.section-titles.help-site" />
      </h3>
      <p className={styles.desc}>
        <FormattedHTMLMessage
          id="modals.guidelines.more-instructions"
          values={{
            link: appConfig.locale.getUrl("help"),
          }}
        />
      </p>
    </ModalTemplateConfirm>
  )
}

export { Guidelines as default }
