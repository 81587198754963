import React, { FunctionComponent } from "react"
import cxBinder from "classnames/bind"
import styles from "./Banner.module.scss"
const cx = cxBinder.bind(styles)

export interface BannerProps extends React.HTMLProps<HTMLDivElement> {
  countDown?: boolean
  light?: boolean
  plusBlue?: boolean
}

const Banner: FunctionComponent<BannerProps> = (props) => {
  const { children, countDown, light, plusBlue } = props
  return (
    <div className={cx("wrapper", { countDown, light, plusBlue })}>
      {children}
    </div>
  )
}

export { Banner, Banner as default }
