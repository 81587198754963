import { CanvasObjectControllable } from "./canvas-object-controllable.interface"
import { ImageObjectController } from "./image-object-controller"
import { ModelEditableSpaces } from "../../../../../../libs/products-render-config/types"

export function isImageObjectController(
  controller: CanvasObjectControllable
): controller is ImageObjectController {
  return controller instanceof ImageObjectController
}

export type MovementDirection = "up" | "down" | "left" | "right"

export type DuplicationOptions = {
  withRender: boolean
  newSpaceId?: ModelEditableSpaces
}
