import React from "react"
import cxBinder from "classnames/bind"
import Thumb, { SizeEnumType } from "dsl/src/atoms/Thumb/Thumb"
import styles from "./ThumbWithText.module.scss"
const cx = cxBinder.bind(styles)

interface ThumbProps {
  isActive?: boolean
  label?: string
  overlayText?: string
  extraStyle?: "stroke" | "shadow"
  onClick?(): void
}

export const ThumbWithText = ({
  overlayText,
  isActive,
  label,
  extraStyle,
  onClick,
}: ThumbProps) => {
  return (
    <div className={styles.thumb_wrapper} onClick={onClick}>
      <Thumb size={SizeEnumType.MediumLarge} active={isActive}>
        <div className={cx("overlay_text", extraStyle)}>{overlayText}</div>
      </Thumb>
      {label && <span>{label}</span>}
    </div>
  )
}
