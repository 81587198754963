import _throttle from "lodash/throttle"
import { PackhelpObject } from "../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../virtual-dieline-editor"
import {
  ActiveObjectEvent,
  CanvasEvent,
} from "../../../../../stores/_events/domain-events"
import { Intersection } from "../../../collision-detection/intersection"
import { SafeZoneCreator } from "./../safe-zones-module/safe-zone-creator"
import { EditableSpaceConfig } from "../../../../../libs/products-render-config/types"

export class SafeZonesModule {
  private isSafeZonesActive: boolean
  private readonly throttledToggleSafeZones: (event: any) => void

  constructor(private readonly virtualDielineEditor: VirtualDielineEditor) {
    this.isSafeZonesActive = false
    this.throttledToggleSafeZones = _throttle(this.toggleSafeZones, 100)
  }

  public switchOnSafeZonesModuleEvents() {
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.objectMoving,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.objectScaling,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.objectRotating,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.textChanged,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.objectRestyled,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.selectionCreated,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.selectionUpdated,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.on(
      CanvasEvent.selectionCleared,
      this.offSafeZones
    )
  }

  public switchOffSafeZonesModuleEvents() {
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.objectMoving,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.objectScaling,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.objectRotating,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.textChanged,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.objectRestyled,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.selectionCreated,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.selectionUpdated,
      this.throttledToggleSafeZones
    )
    this.virtualDielineEditor.fabricCanvas.off(
      CanvasEvent.selectionCleared,
      this.offSafeZones
    )
  }

  private toggleOpacityCurrentSpaceSafeZone(isActive) {
    if (this.isSafeZonesActive === isActive) {
      return
    }
    this.virtualDielineEditor.eventEmitter.emit("safeZonesToggled", isActive)
    const safeZone = this.getSafeZones().find(
      (obj) =>
        obj.originSpaceArea ===
        this.virtualDielineEditor.dielineNavigator.getActiveSpaceId()
    )

    if (safeZone) {
      safeZone.set({
        opacity: isActive ? 1 : 0,
      })
    }

    this.isSafeZonesActive = isActive
  }

  private toggleSafeZones = (event) => {
    const obj = event.target

    if (!obj || !obj.canvas) {
      this.toggleOpacityCurrentSpaceSafeZone(false)

      return
    }

    const isSafeZoneTouched = this.isObjectOutsideSafeOperatingZones(obj)
    this.toggleOpacityCurrentSpaceSafeZone(isSafeZoneTouched)

    if (obj.isSafeZoneTouched !== isSafeZoneTouched) {
      obj.set({
        isSafeZoneTouched: isSafeZoneTouched,
      })

      this.virtualDielineEditor.eventEmitter.emit(ActiveObjectEvent.modified)
      this.virtualDielineEditor.fabricCanvas.requestRenderAll()
    }
  }

  private offSafeZones = (event) => {
    this.toggleOpacityCurrentSpaceSafeZone(false)
  }

  private isObjectOutsideSafeOperatingZones(obj: PackhelpObject): boolean {
    const safeOperatingZones = this.getSafeOperatingZones()

    if (safeOperatingZones.length === 0 || obj.maskParentId) {
      return false
    }

    const activeSpaceId =
      this.virtualDielineEditor.dielineNavigator.getActiveSpaceId()
    obj.setCoords()

    for (const safeOperatingZone of safeOperatingZones) {
      if (
        safeOperatingZone.originSpaceArea === activeSpaceId &&
        Intersection.intersect(obj, safeOperatingZone)
      ) {
        return true
      }
    }

    return false
  }

  public async createSafeZones(
    spaceConfigs: EditableSpaceConfig[]
  ): Promise<void> {
    this.clearExistingSafeZones()

    const safeZoneCreator = new SafeZoneCreator(
      this.virtualDielineEditor.editContext,
      this.virtualDielineEditor.dielineNavigator,
      this.virtualDielineEditor.productRenderPilot
    )

    for (let index = 0; index < spaceConfigs.length; index++) {
      const config = spaceConfigs[index]

      this.virtualDielineEditor.addOnCanvas(
        await safeZoneCreator.createSafeZoneForDisplay(config.spaceId, index)
      )

      this.virtualDielineEditor.addOnCanvas(
        await safeZoneCreator.createSafeOperatingZoneForCalculation(
          config.spaceId,
          index
        )
      )

      this.virtualDielineEditor.fabricCanvas.requestRenderAll()
    }
  }

  public getSafeZones(): PackhelpObject[] {
    return this.virtualDielineEditor.fabricCanvas
      .getObjects()
      .filter((obj) => obj.id && obj.id.includes("safe_zone_"))
  }

  private getSafeOperatingZones(): PackhelpObject[] {
    return this.virtualDielineEditor.fabricCanvas
      .getObjects()
      .filter((obj) => obj.id && obj.id.includes("safe_operating_zone_"))
  }

  private clearExistingSafeZones() {
    for (const safeZone of this.getSafeZones()) {
      this.virtualDielineEditor.fabricCanvas.remove(safeZone)
    }

    for (const safeOperatingZone of this.getSafeOperatingZones()) {
      this.virtualDielineEditor.fabricCanvas.remove(safeOperatingZone)
    }
  }
}
