import React from "react"
import { Banner } from "../../../molecules/Banner/Banner"
import { Link } from "../../../atoms/Link/Link"
import styles from "../SelectQty.module.scss"

type SelectQtyFooterType = {
  outOfTheBoxText: string
  outOfTheBoxLinkUrl: string
  outOfTheBoxLinkText: string
}

const SelectQtyFooter = ({
  outOfTheBoxText,
  outOfTheBoxLinkUrl,
  outOfTheBoxLinkText
}: SelectQtyFooterType) => (
  <div className={styles["out-of-the-box"]}>
    <Banner>
      <div className={styles["out-of-the-box__inner"]}>
        <span className={styles["out-of-the-box__text"]}>
          {outOfTheBoxText}&nbsp;
        </span>
        <Link
          href={outOfTheBoxLinkUrl}
          className={styles["out-of-the-box__link"]}
        >
          {outOfTheBoxLinkText}
        </Link>
      </div>
    </Banner>
  </div>
)

export { SelectQtyFooter, SelectQtyFooter as default }
