var _a;
import PAPER_MAILER_CONSTS from "./consts/paperMailerConsts";
export default (_a = {
        default: {
            DEFAULT_QUANTITY: 30,
            QUANTITY_RANGES: [
                30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500, 3000, 4000, 5000,
            ],
            QUANTITY_DENSITY: 500,
            QUANTITY_STEP: 10,
        }
    },
    _a[PAPER_MAILER_CONSTS.PAPER_MAILER_MODEL_DEFAULT] = {
        DEFAULT_QUANTITY: 30,
        QUANTITY_RANGES: [
            30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500, 3000, 4000, 5000,
        ],
        QUANTITY_DENSITY: 500,
        QUANTITY_STEP: 10,
    },
    _a);
