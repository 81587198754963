import _mapValues from "lodash/mapValues";
import { getSkuData, translateSkuPartToAttributeKey, isExtensionServiceVariants, isExtensionAssetVariants, } from "../SkuLib";
import ExtensionServiceProduct from "../Products/ExtensionProduct/ExtensionServiceProduct";
import ExtensionAssetProduct from "../Products/ExtensionProduct/ExtensionAssetProduct";
import ExtensionOtherProduct from "../Products/ExtensionProduct/ExtensionOtherProduct";
function ExtensionsProductFactory(sku) {
    var decodedSKU = _mapValues(getSkuData(sku), translateSkuPartToAttributeKey);
    switch (decodedSKU.productName) {
        case isExtensionServiceVariants(decodedSKU.productName):
            return new ExtensionServiceProduct(sku);
        case isExtensionAssetVariants(decodedSKU.productName):
            return new ExtensionAssetProduct(sku);
        default:
            return new ExtensionOtherProduct(sku);
    }
}
export default ExtensionsProductFactory;
